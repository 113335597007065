import { ObjectType } from '../types/ObjectRef';
import { XClone, XObject } from '../XObject';
import { db } from '../db';
import { appState, memoryAppState } from './appState';
import { pushEdge } from '../getAllEdges';

export function createEntity(obj, fromEntityId) {
  const edges: {
    from
    to
    directed
  }[] = obj.$edges;
  delete obj.$edges;
  if (obj.$from && !fromEntityId) {
    fromEntityId = obj.$from;
  }
  delete obj.$from;

  obj = XObject.obj(obj);
  const fromEntity = fromEntityId && db.entities.findById(fromEntityId);
  obj.meta = {
    creation: {
      user: memoryAppState.user,
      timestamp: new Date(),
    }
  }
  if (!obj.space) {
    if (fromEntity?.space) {
      obj.space = fromEntity?.space;      
    }
    else if (appState.currentMode) {
      const mode = db.modes.findById(appState.currentMode);
      if (mode?.useAsSpace) {
        obj.space = {
          type: ObjectType.mode,
          id: appState.currentMode,
        }  
      }
      else if (mode?.space) {
        obj.space = XClone(mode.space);
      }
    }
  }
  db.entities.push(obj);

  if (edges) {
    for (const edge of edges) {
      if (edge.from) {
        pushEdge(XObject.obj({
          entities: [edge.from, obj._id],
          directed: edge.directed,
        }));
      }
      else if (edge.to) {
        pushEdge(XObject.obj({
          entities: [obj._id, edge.to],
          directed: edge.directed,
        }));
      }
    }
  }
  return obj;
}


export function getEntityById(id) {
  const t = theTransientEntities.find(e => e._id == id);
  if (t) return t;
  return db.entities.findById(id);
}

export const theTransientEntities = [];

export function getAllEntities() {
  // eslint-disable-next-line
  db.entities.length;
  return [...db.entities, ...theTransientEntities];
}

export function getAllNondeletedEntities() {
  // eslint-disable-next-line
  db.entities.length;
  return [...db.entities.filter(e => !e.__deleted), ...theTransientEntities];
}