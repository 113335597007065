import { ObjectType } from '../types/ObjectRef';
import { db } from '../db';
import { ControlType } from '../MyNotionDocument/ControlType';

function getCurrentAccessor(tree) {
  for (let i = tree.length - 1; i >= 0; i--) {
    const node = tree[i];
    if (node.ruleName == 'Primary_accessor') {
      return tree.slice(0, i + 1);
    }
  }
}

export function getContext(tree) {
  for (let i = tree.length - 1; i >= 0; i--) {
    const node = tree[i];
    if (node.ruleName == 'Primary_accessor' || node.ruleName == 'FunctionCall') {
      return tree.slice(0, i + 1);
    }
  }
}


export function getStr(tree: any[], input: string) {
  let pos = 0;
  for (let i = 0; i < tree.length - 1; ++i) {
    const node = tree[i];

    const child = tree[i + 1];
    const childIndex = node.children.indexOf(child);
    if (!child.isIteration()) {
      pos += node.childOffsets[childIndex];
    }
  }

  const node = tree[tree.length - 1];
  if (node) {
    return input.slice(pos, pos + node.matchLength);
  }
}
function _getNode(currentNode, currentPosition, position, tree, debug): any[] {
  if (!currentNode.children?.length) {
    return tree;
  }
  const matches = [];

  for (let i = 0; i < currentNode.children.length; i++) {
    const child = currentNode.children[i];
    const offset = currentNode.childOffsets[i];
    const length = child.matchLength;

    if (position >= currentPosition + offset && position <= currentPosition + offset + length) {
      const n = _getNode(child, currentPosition + (child.isIteration() ? 0 : offset), position, tree.concat(child), debug);
      if (n) matches.push(n);
    }
  }


  if (matches.length) {
    if (matches.length > 1) {
      console.log(matches);
    }
    let deepest;
    for (const match of matches) {
      if (!deepest || match.length > deepest.length) {
        deepest = match;
      }
    }
    return deepest;

  }
  else {
    if (debug) {
      console.log('no matches', tree, currentPosition, position);

    }
  }


  return tree;
}

export function getNode(root, position, debug?) {
  return _getNode(root, 0, position, [root], debug);
}

export abstract class Type2 {
  abstract name();
  properties(): Property[] {
    return [];
  }
}

export interface Property {
  name: string;
  type: Type2;
}
class EntityType extends Type2 {
  name() {
    return 'entity';
  }
  properties() {
    return [
      {
        name: 'Name',
        type: new StringType(),
      },
      // {
      //   name: 'Name2',
      //   type: new StringType(),
      // },
    ];
  }
}
class QueryType extends Type2 {
  name() {
    return 'query';
  }
  properties() {
    return [
      {
        name: 'Length',
        type: new NumberType(),
      },
      {
        name: 'Find',
        type: new FunctionType('FindByName', [
          {
            name: 'predicate',
            type: new StringType(),
          }
        ], new EntityType()),
      },
      {
        name: 'FindByName',
        type: new FunctionType('FindByName', [
          {
            name: 'name',
            type: new StringType(),
          }
        ], new EntityType()),
      },
    ];
  }
}
class AttributeType extends Type2 {
  name() {
    return 'attribute';
  }
  properties() {
    return [
      {
        name: 'Options',
        type: new ArrayType,
      },
    ];
  }
}
class ArrayType extends Type2 {
  name() {
    return 'array';
  }
  properties() {
    return [
      {
        name: 'Map',
        type: new FunctionType('Map', [], new DummyType('adsf')),
      }
    ];
  }
}
class StringType extends Type2 {
  name() {
    return 'string';
  }
  properties() {
    return [
      {
        name: 'Length',
        type: new NumberType(),
      }
    ];
  }
}
class NumberType {
  name() {
    return 'number';
  }
  properties() {
    return [
      {
        name: 'Length',
        type: new NumberType(),
      }
    ];
  }
}
class FunctionType extends Type2 {
  constructor(public funcName, public params, public returnType) {
    super();

  }
  name() {
    return 'function';
  }

  properties() {
    return [
      {
        name: 'Length',
        type: new NumberType(),
      }
    ];
  }
}
class DummyType extends Type2 {
  constructor(private adf) {
    super();
  }
  name() {
    return 'dummy: ' + this.adf;
  }
  properties() {
    return [
      {
        name: 'Length',
        type: new NumberType(),
      }
    ];
  }
}
class UserList extends Type2 {
  name() {
    return 'User List';
  }
  properties(): Property[] {
    return [
      {
        name: 'Count',
        type: new NumberType
      }
    ];
  }
}

export function getPrimaryType(input: string, nodeTree: any[]) {
  const node = nodeTree[nodeTree.length - 1].children[0];
  const childTree = nodeTree.concat(node);
  if (node.ruleName == 'ObjectRef') {
    const str = getStr(childTree, input);
    const parts = str.slice(2, -2).split(':');
    if (parts[0] == ObjectType.query) {
      return new QueryType;
    }
    if (parts[0] == ObjectType.attribute) {
      return new AttributeType;
    }
    else if (parts[0] == ObjectType.entity) {
      return new EntityType;
    }
    else if (parts[0] == ObjectType.identifier) {
      const identifier = db.identifiers.findById(parts[1]);
      if (identifier.controlType == ControlType.reaction) {
        return new UserList;
      }
    }
  }
  else if (node.ruleName == 'Primary_accessor') {
    const primaryTree = childTree.concat(node.children[0]);
    const accessorTree = childTree.concat(node.children[2]);

    const primary = getStr(primaryTree, input);
    const accessor = getStr(accessorTree, input);

    const primaryType = getPrimaryType(input, primaryTree);
    if (primaryType) {
      const prop = primaryType.properties().find(p => p.name == accessor);
      if (prop) {
        return prop.type;
      }
      else {
        return new DummyType('no prop ' + accessor);
      }

    }


    // if (primaryType instanceof QueryType) {
    //   if (accessor == 'Name') {
    //     return new StringType;
    //   }
    // }
    // else if (primaryType instanceof StringType) {
    //   if (accessor == 'Length') {
    //     return new NumberType;
    //   }
    // }
    // return primaryType + '->' + accessor;
    return new DummyType(primaryType + '->' + accessor);
  }
  else if (node.ruleName == 'FunctionCall') {
    const primaryTree = childTree.concat(node.children[0]);
    const type = getPrimaryType(input, primaryTree);
    console.log(primaryTree);
    if (type instanceof FunctionType) {
      return type.returnType;
    }
    else {
      return new DummyType('invalid primay');

    }
  }
}
