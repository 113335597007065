import { db } from "../db";
import { getAllEdges } from "../getAllEdges";

const index = {};
export function getEdgesForEntity(id: string) {
  // return getAllEdges().filter(x => x.entities.includes(id));

  // eslint-disable-next-line
  db.edges.length;
  return index[id] || [];
}

export function deleteEdge(id) {
  const index = getAllEdges().findIndex(x => x._id === id);
  if (index !== -1) {
    deleteEdgeByIndex(index);
  }
}

export function deleteEdgeByIndex(index) {
  const edge = getAllEdges()[index];
  indexEdges(edge);
  const i = db.edges.indexOf(edge);
  if (i != -1) {
    db.edges.splice(index, 1);
  }
}

export function indexEdges(without?) {
  console.log('index edges');
  for (const key in index) {
    delete index[key];
  }
  for (const edge of getAllEdges()) {
    if (edge === without) continue;

    // if (edge.entities[1]) {
    //   const entity = getEntityById(edge.entities[1]);
    //   if (!entity || entity.__deleted) continue;
    // }

    for (const id of edge.entities) {
      if (!index[id]) {
        index[id] = [];
      }
      index[id].push(edge);
    }
  }
}
