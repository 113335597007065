import React, { Component } from "react";
import cx from 'classnames';
import { component, styled } from "../component";
import { closeImage } from "./notionDatabase/icons";
import { Svg } from "./Svg";


@component
export class Tag extends Component<{ className?; icon?; text; _onClick?; onClickX?; color?; }> {
  static styles = styled.div`
    height: 17px;
    border-radius: 5px;
    padding-left: 4px;
    padding-right: 0px;
    font-size: 11px;
    line-height: 120%;
    color: #565656;
    background: #cde2ff;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    svg {
      width: 10px;
      height: 10px;
      margin-right: 4px;
    }

    .close {
      margin-left: 2px;
      margin-right: 2px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    &:not(.hasClose) {
      padding-right: 4px;
    }
  `;

  render(Container?) {
    return (
      <Container onClick={this.props._onClick} className={cx(this.props.onClickX && 'hasClose', this.props.className)} style={{ background: this.props.color }}>
        {this.props.icon && <Svg name={this.props.icon} />}
        {this.props.text}
        {this.props.onClickX && (
          <div className="close"
            onClick={this.props.onClickX}
          >
            {closeImage}
          </div>

        )}
      </Container>
    );
  }
}
