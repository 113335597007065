import React, { Component } from 'react';
import { component } from '../component2';
import { SystemContext, SystemContextProps } from '../etc/SystemContext';
import { PaneType } from '../types/PaneType';
import { EventOccurrence, EventsList } from './EventsRoot';
import { db } from '../db';
import { appState } from '../etc/appState';
import { ObjectType } from '../types/ObjectRef';
import { getLastOccurrence } from './getCurrentOccurrence';

@component
export class TrackScreen extends Component {
  context: SystemContextProps;
  static contextType = SystemContext;
  render() {
    const mode = db.modes.findById(appState.currentMode);
    const lastOccurrence = getLastOccurrence();

    return (
      <>
        <button
          onClick={() => {
            this.context.navigate({
              type: PaneType.events,
            });
          }}
        >Browse</button>

        {lastOccurrence && <EventOccurrence id={lastOccurrence._id} />}



        <EventsList
          events={mode.favorites?.map?.(entry => {
            if (entry.type == ObjectType.event) {
              return db.events.findById(entry.id);
            }
          })?.filter?.(Boolean)}
        />
      </>
    );
  }
}
