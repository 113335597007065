import ReactDOM from 'react-dom';
import React, { Component, ReactNode } from 'react';
import cx from 'classnames';
import _ from 'lodash';
import jQuery from 'jquery';
import { component, styled } from './component2';
import { db } from './db';
import { X, XClone, XGuard, XInit, XObject, XTouch, x } from './XObject';
import { PropertyField } from './components/PropertyField';
import { ScriptWrapper } from './MyNotionDocument/ScriptWrapper';
import { ScriptEditor } from './MyNotionDocument/ScriptEditor';
import { AnyType, buildModel, getBlock, getParentBlock, getScope, getType } from "./MyNotionDocument/getBlock";
import { presentMenu } from './components/richTextEditor/MenuPresenter';
import { substitute } from './shorthandEditor/substitute';
import { Type } from './richTextHelpers';
import { renderEl, scriptEmpty } from './shorthand/formula';
import { computeStyles } from './computeStyles';
import { NotionDocument2 } from './components/notionDocument/NotionDocument2';
import { RouterContext } from './RouterContext';
import { isComponent } from './shorthand/componentInstanceDiag';
import { DesignComponentEditor } from './DesignComponentEditor';
import { ComponentDiag } from './ComponentDiag';
import { componentTypeId } from './componentTypeId';
import { DevRuntime } from './DevRuntime';
import { showContextMenu } from './helpers';
import { StyleManager } from './StyleManager';
import { highlightEls } from './highlightOutputStylesManager';
import { Related } from './ImportMenu';
import { CompType, DevProjectType, getDevProject } from './DevProject.1';
import { generateAppTree } from './generateAppTree';
import { renderWrapperBuilderNode } from './BuilderComponentEditor';
import { getEntityZIndex } from './glue/structs/renderAttributeValue';
import { DevProjectScriptEditor } from './DevProjectScriptEditor';
import { Svg } from './components/Svg';




function objectRefResolver(devRuntime: DevRuntime, objectRef, components) {
  const [,id] = objectRef.split(':');
  const comp = components.find(c => c._id == id);

  const model = buildModel(x(comp.blocks), devRuntime.__types());

  const lastBlock = model[model.length - 1];
  const scope = getScope(lastBlock._id, 0, model, false);
  const type = getType(lastBlock.cst, model, scope, {
    objectRefTypeResolver: objectRef => objectRefResolver(devRuntime, objectRef, components),
  }, false);

  return type;

  // return new AnyType(`ObjectRef[${id}]!!`);
    
}


export function scriptType(devRuntime: DevRuntime, blocks) {

  const model = buildModel(x(blocks), devRuntime.__types());

  const lastBlock = model[model.length - 1];
  if (!lastBlock) return new AnyType();
  const scope = getScope(lastBlock._id, 0, model, false);
  const type = getType(lastBlock.cst, model, scope, {}, false);

  return type;
}


export function componentType(devRuntime: DevRuntime, comp, components) {

  const model = buildModel(x(comp.blocks), devRuntime.__types());

  const lastBlock = model[model.length - 1];
  if (!lastBlock) return new AnyType();
  const scope = getScope(lastBlock._id, 0, model, false);
  const type = getType(lastBlock.cst, model, scope, {
    objectRefTypeResolver: objectRef => objectRefResolver(devRuntime, objectRef, components),
  }, false);

  return type;
}

@component
export class ComponentEditor extends Component<{
  devRuntime: DevRuntime,
  client,
  component,
  state,
  importedComponents,
  createComponent?
  rootId?,
  onClickComponentBadge,
  contextMenuItems?,
  renderBlockArgs?
}> {
  scriptWrapper: ScriptWrapper

  constructor(props) {
    super(props);
    const {component} = this.props;

    if (this.props.devRuntime) {
      this.scriptWrapper = this.props.devRuntime.getScriptWrapper(component);
    }

  }

  cache = {}



  objectRefResolver(objectRef) {
    const [,id] = objectRef.split(':');
    const comp = this.props.importedComponents.find(c => c._id == id);

    const model = buildModel(x(comp.blocks), this.props.devRuntime.__types());

    const lastBlock = model[model.length - 1];
    const scope = getScope(lastBlock._id, 0, model, false);
    const type = getType(lastBlock.cst, model, scope, {
      objectRefTypeResolver: objectRef => this.objectRefResolver(objectRef),
    }, false);

    return type;
  }

  additionalTypes() {
    
  }


  render() {
    return (
      <>
        <DevProjectScriptEditor
          fadeOutNoDebugData
        devRuntime={this.props.devRuntime}
        importedComponents={this.props.importedComponents}
          // rootBlock={this.props.rootId}
          client={this.props.client}
          scriptWrapper={this.scriptWrapper}
          // debugPane={false}
          state={this.props.state}
          // objectRefTypeResolver={objectRef => this.objectRefResolver(objectRef)}
          // additionalTypes={this.props.devRuntime.__types()}
          // additionalMenuIniters={{
            
          //   $: args => {
          //     let comps = this.props.importedComponents.filter(c => c.parent == this.props.component._id);
          //     if (this.props.component.imported) {
          //       comps = comps.concat(this.props.component.imported.map(id => this.props.importedComponents.find(c => c._id == id)));
          //     }

          //     return presentMenu({
          //       ...args,
          //       menuIniters: {

          //         $: this.props.devRuntime.__menu(args, {
          //           importedComponents: comps,
          //         }),
          //         /*$: (filter) => {
          //           let comps = this.props.importedComponents.filter(c => c.parent == this.props.component._id);
          //           if (this.props.component.imported) {
          //             comps = comps.concat(this.props.component.imported.map(id => this.props.importedComponents.find(c => c._id == id)));
          //           }
          //           let options = comps.map(c => ({
          //             label: c.name,
          //             key: c._id,
          //             action: () => {
          //               substitute(args, [c._id, 'component']);
          //             }
          //           }));

          //           options = options.filter(o => o.label.toLowerCase().includes(filter.toLowerCase()));

          //           if (filter && this.props.createComponent) {
          //             options = options.concat({
          //               label: `Create ${filter}`,
          //               key: 'create',
          //               action: () => {
          //                 const comp = this.props.createComponent(filter);
          //                 substitute(args, [comp._id, 'component']);
          //               },
          //             });
          //           }

          //           return options;
          //         }*/
          //       }
          //     })
          //   }
          // }}
          contextMenuItems={this.props.contextMenuItems}
          renderBlockArgs={this.props.renderBlockArgs}
        />
      </>
    )
  }
}

export const borderColor = '#dadada';

@component
class Adsfsdf extends Component<{ devRuntime: DevRuntime, rootId }> {
  render() {
    const devProject = this.props.devRuntime.devProject;
    const components: CompType[] = XObject.get(devProject, 'components', []);

    const indexComponent = components.find(c => this.props.rootId == c._id);// c.name == 'index');

    const Comp = this.props.devRuntime.executeComponent(x(indexComponent));

    return (
      <RouterContext.Provider value={{
        path: devProject.editorState.url,
        push: path => devProject.editorState.url = path,
      }}>
      <Comp data-pb-path={[]} />
    </RouterContext.Provider>
    
    )
  }
}

@component
export class DevProjectOutput extends Component<{
  parent,
  rootId,
  devRuntime: DevRuntime,
  id,
  state,
  layers?

  navState
  navNext
  navPrev
}> {
  static debug = true;
  static debounce = false;

  static styles = styled.div`
    > .outputArea {
      z-index: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width:100%;

      &.hasActiveComp > .output {
        bottom: 300px;
      }

      > .output {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        > .--navBar {
          /* box-shadow: 0 0 3px #00000036; */
          background: #f3f3f3;
          border-bottom: 1px solid #cfcfcf;
          padding: 4px;
          display: flex;
          .navButtons {
            display: flex;
            margin-right: 4px;
            > * {
              width: 24px;
              height: 24px;
              display: flex;
              align-items: center;
              display: flex;
              justify-content: center;
              cursor: pointer;
              border-radius: 3px;

              :not(.disabled) {
                &:hover {
                  background-color: #f3f3f3;
                }

              }
              &.disabled {
                cursor: default;
                svg {
                  opacity: .5;
                }
              }
              svg {
                width: 16px;
              }
            }

            .next {
              svg {
                transform: rotate(180deg);
              }
            }
            .left {

            }
          }
          input {
            width: 100%;
            box-sizing: border-box;
            box-shadow: none;
            border-radius: 35px;
            border-color: #e3e3e3;
            padding: 3px 11px;
            border-width: 1px;
            border-style: solid;
            /* box-shadow: inset 0 0 3px #00000036; */
          }
        }

        > .renderedOutput {
          flex: 1 1 auto;
          overflow: auto;
          position: relative;
          /* > [data-dev-project-layer] {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            pointer-events: none;
            * {
              pointer-events: auto;
            }
          } */
        }
      }

      /* > .editor {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 300px;
        border-top: 1px solid ${borderColor};
        box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.12);
        > .code {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 300px;
          display: flex;
          flex-direction: column;
          > .componentName {

          font-size: 12px;
          display: block;
          padding: 2px 31px;
          font-family: monaco;
          background: #f6f6f6ba;
          z-index: 10;

          position: sticky;
          top: 0;


          }

          > .componentEditor {
            flex: 1 1 auto;
            overflow: auto;
          }

          > .elTree {
          height: 22px;
          border-top: 1px solid #e7e7e7;

          display: flex;
          flex: 0 0 auto;
          overflow: auto;
          .elTreeComp {
            padding: 0 8px;
            cursor: pointer;

            &.active {
              background-color: #e9e9e9;
            }
          }
        }
        }

        > .styles {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          width: 300px;
          border-left: 1px solid ${borderColor};
          overflow: auto;
        }
      } */

      /* .styles {
        display: flex;
        flex-direction: column;
        > .tabs {
          height: 20px;
          display: flex;
          gap: 8px;
          background-color: #e0e0e0;
          padding: 0 8px;
          .tab {
            padding: 0 4px;
            cursor: pointer;
            border-bottom: 2px solid transparent;
            &.active {
              border-bottom: 2px solid #8e8e8e;
            }
          }
        }

        > .content {
          flex: 1 1 auto;
          overflow: auto;
          > .stylesBlock {
            border-bottom: 1px solid ${borderColor};  
          }


          .stylesBlock {
            .componentName {
              font-size: 12px;
              display: block;
              padding: 2px 6px;
              font-family: monaco;
              background: #f6f6f6ba;
              z-index: 10;

              position: sticky;
              top: 0;

            }
            .propertyLine {
              padding-left: 16px;
              ${NotionDocument2} {
                padding-bottom: 0;
                > .wrapper {
                  padding: 0;
                }

                .grip {
                  display: none;
                }
              }
            }
            .children {

              border-left: 4px solid #e0e0e0;

            }
            .stylesBlock {
            }


            .blockTitle {
              font-family: Monaco;
              font-size: 12px;
              padding-left: 4px;

            }
          }
        }



      } */

    }
  `;
  constructor(props) {
    super(props);
    const devProject = getDevProject(this._id());

    const components: CompType[] = XObject.get(devProject, 'components', []);

    const indexComponent = components.find(c => this.props.rootId == c._id);// c.name == 'index');

    if (indexComponent) {
      if (!scriptEmpty(indexComponent.initialState)) {
        this.outputState = X(this.props.devRuntime.executeBlocks(indexComponent.initialState));
      }
  
    }

  }
  cache = {}
  rerender

  outputState = X({});


  state = XInit(class {
    currentComponentInstance
  })
  _id() {
    return this.props.id;
  }

  _state() {
    return this.props.state;
  }

  rootNavigate(id) {
    const state = this._state();
    state.activeComponent = id;
    state.columns = X([XObject.obj({
      type: 'root',
      activeComp: id,
    }), XObject.obj({
      type: 'references',
      id: id,
    })])

  }

  components() {
    const devProject = getDevProject(this._id());
    const components: CompType[] = XObject.get(devProject, 'components', []);

    return components;
  }


  prevEl

  id='asdf';
    
  highlightTagStyles = new StyleManager;
  updateTagHighlight(ids) {
    const css = `
    ${ids.map((id, i) => {

      return `
        [data-id="${this.id}"] [data-identifier-category="tag"][data-id="${id}"] {
          background-color: ${i == 0 ? '#458e4579' : '#e2e2e2'};
        }

        /*[data-id="${this.id}"] [data-identifier-category="tag"][data-id="${id}"] .--badge-- {
          background-color: ${i == 0 ? '#458e4579' : '#969696'};
        }*/

        [data-id="${this.id}"] [data-script-block-ids*="${id}"] {
          outline: 2px solid ${i == 0 ? 'green' : '#00000042'};
        }
      `
    }).join('\n')}
    
    `

    this.highlightTagStyles.setStyles(css);


  }


  cleanupFuncs = []

  componentDidMount(): void {
    const rootEl = document.querySelector('.renderedOutput')?.firstChild;
    if (!rootEl) {
      console.log('nooo')
      return;
    }
    const tree = generateAppTree(this.props.devRuntime, rootEl);
    this.props.parent.state.tree = XGuard(tree);

    const obs = new MutationObserver(() => {
      const rootEl = document.querySelector('.renderedOutput')?.firstChild;

      console.log('rendered output changed');
      const tree = generateAppTree(this.props.devRuntime, rootEl);
      this.props.state.tree = XGuard(tree);
    });
    if (document.querySelector('.renderedOutput')) {
      obs.observe(document.querySelector('.renderedOutput'), { childList: true, subtree: true });      
    }

    this.cleanupFuncs.push(() => {
      obs.disconnect();      
    });
  }

  componentWillUnmount(): void {
    for (const func of this.cleanupFuncs) {
      func();
    }
  }

  activeBlock
  inspectingEls = []

  currentStyles = {}


  uiComponentName(id) {
    for (const comp of this.components()) {
      if (getBlock(id, comp.blocks)) {
        return comp.name;
      }
    }
  }

  inspectingElsIndex

  componentCache = {}

  renderOutput() {

    const hasNext = this.props.navState.navPointer < this.props.navState.navStack.length - 1;
    const hasPrev = this.props.navState.navPointer > 0;

    const devProject = getDevProject(this._id());
    const components: CompType[] = XObject.get(devProject, 'components', []);
    const state = this._state();

    const indexComponent = components.find(c => this.props.rootId == c._id);// c.name == 'index');
    // if (!indexComponent) return '';




    let tab = state.tab || 'styles';
    if (tab == 'runtime' && !isComponent(this.state.currentComponentInstance?.[1])) tab = 'styles';

    if (indexComponent.type == 'builderComponent') {
      // const Comp = this.props.devRuntime.executeComponent(x(indexComponent));
      let wrapperComp;

      if (devProject.wrapperComponent) {
        wrapperComp = this.props.devRuntime.getComponent(devProject.wrapperComponent);
      }

      const render = children => {

        return (
          <>
            {wrapperComp && renderWrapperBuilderNode(this.props.devRuntime, x(wrapperComp), x(wrapperComp).root, 
                children, 'renderedOutput', undefined, {
                'data-offset-cont': true,
              })}
              {!wrapperComp && <div className="renderedOutput">{children}</div>}
          </>
        )
      }

      return (
        <div className={cx('outputArea', { hasActiveComp: false })}>
          <div className={cx('output')}>
            <div className="--navBar">
              <div className="navButtons">
                <span className={cx("nav prev", { disabled: !hasPrev })}
                
                  onClick={() => {
                    this.props.navPrev();
                  }}
                ><Svg name="arrow-left" /></span>
                <span className={cx("nav next", { disabled: !hasNext })}
                
                onClick={() => {
                  this.props.navNext();
                }}><Svg name="arrow-left" /></span>
              </div>
              <input
                type="text"
                value={devProject.editorState.url}
                onChange={e => {
                  devProject.editorState.url = e.target.value;
                }}
              />
            </div>
            {render(
              <>
                <Adsfsdf
                  devRuntime={this.props.devRuntime}
                  rootId={this.props.rootId}
                />

                <div id="layers">

                </div>
              </>
            )}

          </div>
        </div>
      )
    }
    else {
      const r = this.props.devRuntime.executeComponent(indexComponent);
      if (_.isFunction(r)) {
        const components = this.components();
        let activeComp;
        for (const component of components) {
          const block = getBlock(this.activeBlock, component.blocks);
          if (block) {
            activeComp = component;
            break;
          }
        }
        try {
          return (
            <div className={cx('outputArea', { hasActiveComp: activeComp })}>
              <div className={cx('output')}>
                <div className="--navBar">
                <input type="text" value={devProject.editorState.url} onChange={e => {
                  devProject.editorState.url = e.target.value;
                }} />
                </div>
                <div className="renderedOutput" data-offset-cont>
                <RouterContext.Provider value={{
                  path: devProject.editorState.url,
                  push: path => devProject.editorState.url = path,
                }}>
                  {renderEl(r(), {
                    evaluate() {},
                    scriptEvaluate() {},
                  })}
                </RouterContext.Provider>
                </div>
              </div>



              {activeComp && 
                <div className="editor">
                  <div className="code">
                    <span className="componentName"
                      onClick={() => {
                        this.rootNavigate(activeComp._id);
                      }}
                    >{activeComp.name}
                    </span>
                    <div className="componentEditor">
                      <ComponentEditor
                        devRuntime={this.props.devRuntime}
                        client={'preview'}
                        key={this.activeBlock}
                        component={activeComp}
                        importedComponents={this.components()}
                        state={this._state()}
                        rootId={this.activeBlock}
                        onClickComponentBadge={comp => {
                          console.log(x(comp));
                        }}
                      />
                    </div>
                    <div className="elTree">
                      {this.inspectingEls?.map?.((el: Element, i) => {
                        const getName = () => {
                          let tag = el.getAttribute('data-identifier') || el.tagName.toLowerCase();

                          const filteredCs = () => {
                            const classList = [...el.classList];
                            const index = classList.findIndex(cs => cs.startsWith('sc-'))
                            if (index != -1) {
                              return classList.slice(0, index).concat(classList.slice(index + 2));
                            }
                            else {
                              return classList;
                            }
                          }
                          const asdf = name => {
                            if (filteredCs().length) {
                              return `${name}.${filteredCs().join('.')}`;
                            }
                            else {
                              return name;
                            }
                          }
                          if (el.getAttribute('data-component-block')) {
                            const name =  this.uiComponentName(el.getAttribute('data-component-block'));
                            return asdf(name);
                          }
                          const match = tag.match(/^_\$.*?:(.*?)\$_$/)
                          if (match) {
                            return asdf(components.find(c => c._id == match[1]).name);
                          }
                          else {
                            return asdf(tag);
                          }
                          // else if (tag[0] == tag[0].toLowerCase() && el.classList.length) {
                          //   return `${tag}.${[...el.classList].join('.')}`;
                          // }
                          // else {
                          //   return tag;
                          // }
                        }
                        return (
                          <span className={cx('elTreeComp', { active: i == this.inspectingElsIndex })}
                            onClick={() => {
                              this.inspectingElsIndex = i;
                              this.activeBlock = this.inspectingEls[i].getAttribute('data-script-block-id');
                              this.updateCurrentStyles();
                            }}
                          >
                            {getName()}
                          </span>
                        )
                      })}
                    </div>
                  </div>


                  <div className="styles">
                    <div className="tabs">
                      <span className={cx('tab', { active: tab == 'styles' })} onClick={() => state.tab = 'styles'}>Styles</span>
                      {isComponent(this.state.currentComponentInstance?.[1]) && <span className={cx('tab', { active: tab == 'runtime' })} onClick={() => state.tab = 'runtime'}>Component</span>}
                    </div>
                    <div className="content">
                    {(() => {
                      if (tab == 'runtime') return <ComponentDiag id={this.state.currentComponentInstance?.[1]} />;
                      if (tab == 'styles') if (this.currentStyles) {
                        const getComp = id => {
                          for (const comp of this.components()) {
                            if (getBlock(id, comp.blocks)) return comp;
                          }
                        }
                        const getModel = id => {
                          const model = buildModel(getComp(id).blocks, this.props.devRuntime.__types());

                          return model;
                        }



                        const blockMap = {};

                        const addToBlock = (prop, id) => {
                          initBlock(id);
                          blockMap[id].props.push(prop);
                        }

                        const initBlock = id => {
                          if (!blockMap[id]) {
                            blockMap[id] = {
                              id,
                              props: [],
                              children: [],
                            }

                            if (id != 'root') {
                              const block = getBlock(id, getModel(id));
                              if (block) {
                                if (!block.type) {
                                  const parentBlock = getParentBlock(id, getModel(id));
                                  if (parentBlock) {
                                    addBlockToParent(id, parentBlock._id);
                                  }
                                  else {
                                    addBlockToParent(id, 'root');
                                  }
                                }
                                else {
                                  addBlockToParent(id, 'root');
                                }  
                              }

                            }
                          }
                        }

                        const addBlockToParent = (id, parentId) => {
                          initBlock(parentId);
                          blockMap[parentId].children.push(id);
                        }

                        for (const name in this.currentStyles) {
                          addToBlock(name, this.currentStyles[name].stylesId);
                        }

                        const renderBlocks = (blocks, root) => {
                          return blocks.map(blockId => {
                            const block = blockMap[blockId];
                            const b = getBlock(block.id, getModel(blockId));
                            return (
                              <div key={block.id} className="stylesBlock">
                                {root && (
                                  <>
                                    <span className="componentName"
                                      onClick={() => {
                                        this.rootNavigate(getComp(block.id)._id);
                                      }}
                                    >{getComp(block.id).name}</span>
                                  </>
                                )}
                                <div className="blockTitle" dangerouslySetInnerHTML={{__html:b.source.replace(/_\$(.*?)\$_/g, (match) => {
                                  const [,id] = match.match(/_\$.*?:(.*?)\$_/);
                                  return `<span style="border-bottom:1px dashed #afafafb0">${components.find(c => c._id == id).name}</span>`;
                                })}}></div>
                                {block.props.map(prop => {
                                  const styleId = this.currentStyles[prop].propId;
                                  if (!styleId) return;
                                  return (
                                    <div key={prop} className="propertyLine" data-style-id={styleId}>
                                    {/* <span className="name">{prop}</span>: <span className="value">{this.currentStyles[prop].value}</span> */}

                                    <ScriptEditor
                                    rootBlock={styleId}
                                        client={styleId}
                                        scriptWrapper={this.props.devRuntime.getScriptWrapper(getComp(block.id))}
                                        // highlightBlock={el}
                                      />
                                    </div>
                                  )
                                })}

                                {block.children.length > 0 &&<div className="children">
                                {renderBlocks(block.children, false)}
                                </div>}
                              </div>
                            )
              
                          })
                        }

                        // return'';
                        return renderBlocks(blockMap['root']?.children || [], true);




                      }
                    })()}
                    </div>
                  </div>
                </div>
              }
            </div>
          )
        }
        catch (e) {
          console.log(e);
        }
      }
      else {
        console.log(r);
        
      }
    }
  }

  render(Container?) {
    console.log('output');
    return (
      <Container data-id={this.id} id="protobuilder-output-area">
        {this.renderOutput()}
      </Container>
    )
  }

  highlightComponentStyles

  updateCurrentStyles() {
    // console.log(this.inspectingEls,this.inspectingElsIndex, this.inspectingEls[this.inspectingElsIndex])
    this.currentStyles = computeStyles(this.inspectingEls[this.inspectingElsIndex]).source;
    // console.log(this.inspectingEls, this.inspectingEls[this.inspectingElsIndex], this.currentStyles);


    delete this.state.currentComponentInstance;
    for (let i = this.inspectingElsIndex; i >= 0; -- i) {
      if (this.inspectingEls[i].getAttribute('data-component-block')) {
        this.state.currentComponentInstance = [this.inspectingEls[i].getAttribute('data-component-block'), this.inspectingEls[i].getAttribute('data-component-instance')]  
        break;
      }
      

    }

    // if (this.state.currentComponentInstance) {
    //   this.highlightComponentStyles.setStyles(`
    //   [data-id="${this.id}"] [data-component-instance="${this.state.currentComponentInstance[1]}"] {
    //     outline: 2px solid #ec6bbd !important;
    //   }
    //   `)
    // }
    // else {
    //   this.highlightComponentStyles.setStyles();
    // }


    this.forceUpdate();

  }
}

@component
export class Entry extends Component<{ i, comp, st, devRuntime: DevRuntime, devProject, components, active, config?; nodeDesignerChanged }> {
  static styles = styled.div`
    position: relative;
    border-bottom: 1px solid ${borderColor};
    padding-bottom: 6px;

    > .top {
      font-size: 12px;
      display: flex;
      padding: 2px 31px;
      font-family: monaco;
      background: #f6f6f6ba;
      z-index: 10;
      position: sticky;
      top: 0;

      > .componentName {
        margin-right: 8px;
      }

      .parent {
        color: #9a9a9a;
      }

      .related {
        margin-left: auto;

      }
    }
    

    &.active {
      background-color: #f4f4f4;
      > .top {
        background: #e5e5e5ba;
      }
    }
  `;
  render(Container?) {
    const i = this.props.i;
    const renderEntry = (comp) => {
      if (!comp) return;
      let c;

      const { st, components, active } = this.props;
      if (comp.type == 'designComponent') {
        c = (
          <>
            <DesignComponentEditor
              obj={comp}
              state={st}
            />
          </>
        )
      }
      else if (comp.type == 'builderComponent') {
        throw new Error();
//         c = (
//           <BuilderComponentCanvasEditor devRuntime={this.props.devRuntime} builderComponent={comp} devProject={this.props.devProject} config={this.props.config}
// nodeDesignerChanged={this.props.nodeDesignerChanged}
//             className={this.props.i == 0 && 'fullHeight'}

//           />
//         )
      }
      else {
        c = (
          <>
            <ComponentEditor
              devRuntime={this.props.devRuntime}
              client={comp._id + i}
              component={comp}
              state={st}
              importedComponents={components}
              createComponent={name => {
                const comp = XObject.obj({
                  name: name,
                  parent: this.props.comp._id,
                });
                components.push(comp);
    
                return comp;
    
              }}
              onClickComponentBadge={comp => {
                // console.log(x(comp));
              }}
              // renderBlockArgs={{
              //   scriptBadgesArgs: {
              //     render: block => {
              //       return;
              //       if (this.state.currentComponentInstance) {
              //         const model = buildModel(x(comp.blocks), {
              //           component: {
              //             text(id) {
              //               return `_$${componentTypeId}:${id}$_`;
              //             },
              //           }
                    
              //         });
      
              //         const path = getBlockPath(block._id, model);
              //         const b = path[path.length - 1];
      
                      
              //         if (b.type == 'declare') {
              //           for (let i = path.length - 1; i >= 0; --i) {
              //             if (scriptBlockDiagInfo[path[i]._id]?.count) {
              //               const instanceId = this.state.currentComponentInstance[1];
              //               if (componentInstanceDiag[instanceId]?.comp?.blockId == path[i]._id) {
              //                 const diag = getComponentInstanceDiag(instanceId);
              //                 if (diag?.vars?.[b.identifier]) {
              //                   const scope = componentInstanceDiagScopes[instanceId + b.identifier];
              //                   XTouch(scope[b.identifier])
              //                   return <Badge text={JSON.stringify(x(scope[b.identifier]))} />;
              //                 }
              //               }
              //             }
              //           }
              //         }
    
              //       }
    
              //       const diagInfo = scriptBlockDiagInfo[block._id];
              //       if (diagInfo) {
              //         if (diagInfo.count) {
              //           return <Badge text={diagInfo.count} />
              //         }
              //       }
              //     }
              //   }
              // }}
              contextMenuItems={block => {
                return [
                  {
                    text: 'Debug2',
                    onClick: () => {
                      const model = buildModel(x(comp.blocks), this.props.devRuntime.__types());
    
                      const b = getBlock(block._id, model);
                      
                      if (b.type == 'declare') {
                        console.log(b.identifier);
                      }
                    },
                  },
                  {
                    text: 'Move to new editor',
                    onClick: () => {
                      console.log(x(block));
    
                      components.push(XObject.obj({
                        name: 'new component',
                        blocks: [
                          XClone(block),
                        ]
                      }));
                    },
                  }
                ]
              }}
            /> 
          </>
        )
      }

      const ref = React.createRef<any>();

      return (
        <Container
          className={cx('componentEntry', {
            active,
          })}
          data-comp-id={comp._id}
          style={{
            height: this.props.i == 0 ? 'auto' : undefined,
          }}
        >
          {/* <div className="top">
            <PropertyField
              dblClickEdit
              object={comp}
              property="name"
              className="componentName"
            />
            <PropertyField
              className="parent"
              placeholder="(root)"
              type="selector"
              entries={this.props.devRuntime._components().map(c => {
                return {
                  key: c._id,
                  display: c.name,
                }
              })}
              object={comp}
              property="parent"
            />

            <div className="related">


              <Related devProject={this.props.devProject} comp={comp} />
            </div>

          </div> */}
          {c}
        </Container>
      );
    }

    return renderEntry(this.props.comp);
  }
}


@component
export class ComponentsTree extends Component<{
  devProject,
  state,
  active,
  onNavigate,
  radioChecked?,
  onRadioClick?,
  root?
  setRoot?
  setWrapper?
  wrapper?
}> {
  static styles = styled.div`
  
    &:not(:hover) {
      .line:not(.highlight) {
        display: none;
      }
    }

    .component {
      opacity: .5;
      cursor: pointer;
      &:hover {
        background-color: #f3f3f3;
      }
      position: relative;

      &.root {
        font-weight: bold;
      }

      &.wrapper {
        text-decoration: underline;
      }

      &.active {
        background-color: #e3e3e3;
        .bullet {
          background-color: #cfcfcf;

        }
      }

      .line {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: #eeeeee;

        &.highlight {
          background-color: #acacac;
        }
      }

      &.showingChildren {
          > .inner > .toggle {
            transform: rotate(45deg);
          }
        }
    }

    .checkbox {
      position: absolute;
      left: 0;
    }

    .inner {
      position: relative;
      padding-left: 20px;


      .bullet {
        width: 4px;
        height: 4px;
        position: absolute;
            left: 7px;
            top: 9px;

            border-radius: 50%;
            background-color: #e2e2e2;
      }
      .toggle {
        transform: rotate(-45deg);
        pointer-events: auto;

        width: 6px;
        height: 6px;
        cursor: pointer;
        border-right: 1px solid #c4c4c4;
        border-bottom: 1px solid #c4c4c4;

        &:hover {
          border-color: black;
        }

        position: absolute;
        left: 6px;
        top: 6px;
      }

      /* padding-left: 40px; */
      /* margin-left: 20px; */
      /* .bullet {
        left: 27px;
      }
      .toggle {
        left: 26px;
      }
      .checkbox {
        position: absolute;
        left: 0px;
      } */
    }

  `;

  render() {
    const { devProject, active } = this.props;
    const components = XObject.get(devProject, 'components', []);
    const sidebarState = this.props.state;

    const items = [];

    const genItems = (comps, level, lineStyles={}) => {
      for (const component of comps) {
        const children = components.filter(c => c.parent == component._id);
        const childActive = children.find(c => active == c._id);
        items.push([component, {level,lineStyles,hasChildren:!!children.length}])
        if (sidebarState[component._id]) {
          genItems(children, level+1, {
            ...lineStyles,
            ...(childActive ? {[level]: 'highlight'} : {}),
          });
        }
      }
    }
    genItems([...components.filter(c => !c.parent)], 0);

    return (
      <>
        {items.map(([component,{level,lineStyles,hasChildren}]) => {
          return (
            <div className={cx('component', { 
              active: active == component._id,
              root: this.props.root == component._id,
              wrapper: this.props.wrapper == component._id,
              
              showingChildren: sidebarState[component._id]})}
              key={component._id}
              style={{
                paddingLeft: level * 20,
              }}

              onMouseOver={() => {
                highlightEls([component._id]);
              }}
              onMouseOut={() => {
                highlightEls([]);
              }}

              data-input-id={component._id}
              
              onClick={() => {
                this.props.onNavigate(component._id);
              }}

              onContextMenu={e => {
                e.preventDefault();
                showContextMenu(e, [
                  {
                    text: 'Delete',
                    onClick: () => {
                      components.splice(components.indexOf(component), 1);
                    },
                  },
                  {
                    text: 'Set root',
                    onClick: () => {
                      this.props.setRoot(component._id);
                    }
                  },
                  {
                    text: 'Set wrapper',
                    onClick: () => {
                      this.props.setWrapper(component._id);
                    },
                  }
                ])
              }}            
            >

            {[...Array(level)].map((__, i) => {
              return <span className={cx('line', lineStyles[i])}
              style={{
                left: i*20 + 9,
              }}
              />
            })}
                {/* <input type="radio" className="checkbox" 
                checked={this.props.radioChecked == component._id}
                  onClick={e => {
                    e.stopPropagation();
                    if (this.props.radioChecked == component._id) {
                      this.props.onRadioClick(null)
                      
                    }
                    else {
                      this.props.onRadioClick(component._id)

                    }
                  }}
                /> */}

              <div className="inner">
                {hasChildren && <span className="toggle"
                  onClick={e => {
                    e.stopPropagation();
                    sidebarState[component._id] = !sidebarState[component._id]
                  }}
                />}

                {!hasChildren && <span className="bullet" />}
                {component.name}
              </div>
            </div>
          )
        })}
      </>
    )
  }
}

@component
export class Sidebar extends Component<{rootNavigate, sidebarState, state, devProject: DevProjectType}> {
  static styles = styled.div`
    /* > .componentsSidebar {
      overflow: auto;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 200px;
      border-right: 1px solid ${borderColor};
      box-sizing: border-box;
      padding: 8px;
      .component {
        position: relative;
        .backing {
          height: 20px;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          &:hover {
            background-color: gray;
          }
        }
        &.showingChildren {
          > .name > .toggle {
            transform: rotate(45deg);
          }
        }
        .name {
          pointer-events: none;
          height: 20px;
          padding-left: 20px;
          position: relative;

          .toggle {
            transform: rotate(-45deg);
            pointer-events: auto;

            width: 6px;
            height: 6px;
            cursor: pointer;
            border-right: 1px solid #c4c4c4;
            border-bottom: 1px solid #c4c4c4;

            &:hover {
              border-color: black;
            }

            position: absolute;
            left: 6px;
            top: 6px;
          }
        }
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        .children {
          padding-left: 10px;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 9px;
            width: 1px;
            background-color: #c4c4c4;
            pointer-events: none;
          }
        }
      }

      .designComponent {
        &.active {
          font-weight: bold;
        }
      }

      .type {
        font-size: 10px;
        color: gray;
      }
    } */

      overflow: auto;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 200px;
      border-right: 1px solid ${borderColor};
      box-sizing: border-box;

  `;
  render() {

    const { devProject, sidebarState, state}= this.props;
    const components = XObject.get(devProject, 'components', []);
    const assets = XObject.get(devProject, 'assets', []);


    const items = [];

    return <ComponentsTree 
    active={state.activeComponent}
    devProject={devProject}
    onNavigate={id => {
      this.props.rootNavigate(id);
    }}
    state={state}
  />

    const genItems = (comps, level, lineStyles={}) => {
      for (const component of comps) {
        const children = components.filter(c => c.parent == component._id);
        const childActive = children.find(c => state.activeComponent == c._id);
        items.push([component, {level,lineStyles,hasChildren:!!children.length}])
        if (sidebarState[component._id]) {
          genItems(children, level+1, {
            ...lineStyles,
            ...(childActive ? {[level]: 'highlight'} : {}),
          });
        }
      }
    }
    genItems([...components.filter(c => !c.parent)], 0);

    return (
      <>
        {items.map(([component,{level,lineStyles,hasChildren}]) => {
          
          return (
            <div className={cx('component', { 
              active: state.activeComponent == component._id,
              
              showingChildren: sidebarState[component._id]})}
              key={component._id}
              style={{
                paddingLeft: level * 20,
              }}

              
                onClick={() => {
                  this.props.rootNavigate(component._id);
                }}

                onContextMenu={e => {
                  e.preventDefault();
                  showContextMenu(e, [
                    {
                      text: 'Delete',
                      onClick: () => {
                        components.splice(components.indexOf(component), 1);
                      },
                    }
                  ])
                }}

            
            >

            {[...Array(level)].map((__, i) => {
              return <span className={cx('line', lineStyles[i])}
              style={{
                left: i*20 + 9,
              }}
              />
            })}

              <div className="inner">
                {hasChildren && <span className="toggle"
                              onClick={e => {
                                e.stopPropagation();
                                sidebarState[component._id] = !sidebarState[component._id]
                              }}

                  
                
                />}

                {!hasChildren && <span className="bullet" />}
                {component.name}
              </div>
            </div>
          )
        })}

        <button
          onClick={e => {
            showContextMenu(e, [
              {
                text: 'Script Component',
                onClick: () => {
                  components.push(XObject.obj({
                    name: 'Untitled',
                  }))
                },
              },
              {
                text: 'Builder Component',
                onClick: () => {
                  components.push(XObject.obj({
                    type: 'builderComponent',
                    name: 'Untitled',
                    root: XObject.obj({
                      type: 'el',
                    }),
                  }))
                },
              }
            ])
          }}
        >+</button>
        {/* <div className="componentsSidebar">
          <div>
            {[...components.filter(c => !c.parent)].sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1).map(comp => {
              return renderSidebarItem(comp, 0);
            })}
          </div>
          <div>
            <h2>Assets</h2>
            {assets.map(asset => {
              return (
                <div key={asset._id}
                  onContextMenu={e => {
                    e.preventDefault();
                    showContextMenu(e, [
                      { text: 'Copy URL', onClick: () => {
                        copy(uploadedFileUrl(asset.file));
                      }}
                    ])
                  }}
                >
                  <PropertyField object={asset} property="name" />
                </div>
              )
            })}


            <FileUpload
              onUpload={id => {
                assets.push(XObject.obj({
                  name: 'untitled',
                  file: id,
                }))

              }}
            >
            <button
              onClick={() => {
                assets.push(XObject.obj({
                  name: 'untitled',
                }))
              }}
            >+</button>

            </FileUpload>
          </div>
        </div> */}
      </>
    );
  }
}

@component
export class DevProjectEditor extends Component<{ id, state, devRuntime, hideSidebar, activeComponent?, rootConfig? }> {
  static styles = styled.div`
    > .editArea {
      position: absolute;
      top: 0;
      left: 200px;
      bottom: 0;
      right: 0;

      > .scriptEditor {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: auto;

        > .columns {
          display: flex;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          overflow: auto;
          > .column {
            width: 627px;
            border-right: 1px solid ${borderColor};
            flex: 0 0 auto;
            overflow: auto;
            box-sizing: border-box;
          }
        }
      }
    }

    &.hideSidebar {
      ${Sidebar} {
        display: none;
      }
      > .editArea {
        left: 0;
      }
    }
  `;

  constructor(props) {
    super(props);

    if (this.props.activeComponent) {
      this.rootNavigate(this.props.activeComponent);
    }
  }

  _id() {
    return this.props.id;
  }

  _state() {
    return this.props.state;
  }

  id = 'asdf';
  rootNavigate(id) {
    const state = this._state();
    delete state.activeDesignComponent;
    state.activeComponent = id;
    state.columns = X([XObject.obj({
      type: 'root',
      activeComp: id,
    }), XObject.obj({
      type: 'references',
      id: id,
    })])
  }

  componentDidMount(): void {
    jQuery(ReactDOM.findDOMNode(this)).on('mousedown', '[data-type="component"]', e => {
      e.preventDefault();
      e.stopPropagation();
      // console.log(e);
      const data = JSON.parse(atob(e.currentTarget.getAttribute('data-component-data')));
      const columnEl = jQuery(e.currentTarget).parents('.column')[0];
      const entryEl = jQuery(e.currentTarget).parents('.componentEntry')[0];
      console.log(data, jQuery(columnEl).index());
      const i = jQuery(columnEl).index();

      const state = this._state();

      const columns = state.columns;

      const column = columns[i];


      const compId = entryEl.getAttribute('data-comp-id');

      column.activeComp = compId;

      state.columns = X(columns.slice(0, i + 1).concat(XObject.obj({
        type: 'references',
        id: compId,
        activeComp: data,
      }))).concat(XObject.obj({
        type: 'references',
        id: data,
      }));


    });
  }

  render(Container?) {
    const devProject = getDevProject(this._id());
    const components = XObject.get(devProject, 'components', []);
    const state = this._state();

    const assets = XObject.get(devProject, 'assets', []);
    const designComponents = XObject.get(devProject, 'designComponents', []);

    const columns = XObject.get(state, 'columns', []);

    if (!columns.length) {
      columns.push(XObject.obj({
        type: 'root',
      }));
    }

    const sidebarState = XObject.get(state, 'sidebarState', {});

    const renderEntry = (comp, i) => {
      if (!comp) return;
      let c;
      const st = XObject.get(state, 'editorState' + comp._id, {});

      return <Entry

      nodeDesignerChanged={() => {
        throw new Error();
      }}
        config={i == 0 && this.props.rootConfig}
        devRuntime={this.props.devRuntime}
        devProject={devProject}
        key={comp._id}
        comp={comp}
        i={i}
        active={columns[i].activeComp == comp._id}
        components={components}
        st={st}
      />;
    }


    return (
      <Container data-id={this.id}
        className={cx({
          hideSidebar: this.props.hideSidebar,
        })}
      
      >
        <Sidebar 
          rootNavigate={id => this.rootNavigate(id)}
          devProject={devProject}
          sidebarState={sidebarState}
          state={state}
        />


        <div className="editArea">
          {state.activeComponent && <div className="scriptEditor">
            <div className="columns">
              {columns.map((c, i) => {
                let comps = []
                if (c.type == 'root') {
                  comps = [state.activeComponent];
                }
                else {
                  const comp = components.find(comp => comp._id == c.id);
                  if (!comp) return;

                  const refs = {};
                  const collectRefs = blocks => {
                    for (const block of blocks) {
                      if (_.isArray(x(block.data))) {
                        for (const el of block.data) {
                          if (_.isArray(x(el))) {
                            if (el[1] == 'component') {
                              refs[el[0]] = true;
                            }
                          }
                        }
                      }

                      collectRefs(block.children);
                    }
                  }

                  if (comp.type == 'builderComponent') {
                    const findNode = (id, start) => {
                      if (id == start._id) return start;
                      if (start.children) {
                        for (const child of start.children) {
                          const r = findNode(id, child);
                          if (r) return r;
                        }
                      }
                    };

                    if (comp.editorState?.selectedNode) {
                      const node = findNode(comp.editorState.selectedNode, comp.root);
                      if (node) {
                        collectRefs(node.script || []);
                        collectRefs(node.styles || []);
                      }
                    }
                    else {
                      collectRefs(comp.db || []);
                    }
                  }
                  else {
                    collectRefs(comp?.blocks || []);
                  }
                  comps = Object.keys(refs);
                }
                return (
                  <div key={c._id} className="column">
                    {comps.map(id => {
                      return (
                        renderEntry(components.find(c => c._id == id), i)
                      )
                    })}
                  </div>
                );
              })}
            </div>
          </div>}

          {state.activeDesignComponent && <DesignComponentEditor obj={designComponents.find(x => x._id == state.activeDesignComponent)}
          
            state={XObject.get(state, 'designComponentState', {})}
          />}
        </div>
      </Container>
    );
  }
}
