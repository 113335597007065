import React, { Component } from 'react';
import { component } from './component2';
import { RenderData } from './MyNotionDocument/RenderData';
import { highlight } from './MyNotionDocument/highlight';


@component
export class SyntaxHighlighter extends Component<{ className?; code; }> {
  // static styles = styled.div`
  //   font-family: Monaco;
  // `;
  render() {
    return <RenderData
      className={this.props.className}
      ctx={{}}
      data={this.props.code}
      highlighter={(el, pos) => {
        highlight({
          ctx: {},
          el,
          getIdentifierId: () => { },
          getType: () => { },
          pos,
          type: null,
          // getTagId
        });
      }} />;

  }
}
