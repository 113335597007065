import { Component } from 'react';
import { component } from '../component2';
import { styled } from '../component';
import { PropertyField } from './PropertyField';
import { codaIcons } from '../codaIcons';



@component
export class IconSelector extends Component<{ get; set; }> {
  static styles = styled.span`
    .icon {
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      display: inline-block;
    }

  `;
  render() {
    return (
      <>
        <PropertyField
          maxEntries={10}
          get={this.props.get} set={this.props.set} type="selector" entries={codaIcons.map(icon => ({
            display: icon.name,
            key: icon.name,
          }))} />

        {this.props.get() && <span className="icon" style={{
          backgroundImage: `url(${codaIcons.find(i => i.name == this.props.get()).url('color')})`,
        }} />}
      </>
    );
  }
}
