import { x } from '../XObject';
import { OSContextType } from './main';



export class Runtime {
  paramStore: { [key: string]: string; } = {};
  stateStore;
  path = [];
  valueRegistry = {};

  depth = [];

  constructor(public context: OSContextType, opts: { stateStore?; } = {}) {
    if (opts.stateStore)
      this.stateStore = opts.stateStore;
  }

  clone() {
    const rt = new Runtime(this.context);
    rt.stateStore = this.stateStore;
    rt.path = this.path;
    rt.paramStore = this.paramStore;
    rt.valueRegistry = this.valueRegistry;
    rt.depth = this.depth;
    return rt;
  }

  addDepth(id) {
    const rt = this.clone();
    rt.depth = rt.depth.concat(id);
    return rt;
  }

  pushMemory(memory: { [id: string]: string; }): Runtime {
    // console.log('pushMemory', memory);
    const rt = this.clone();
    rt.paramStore = Object.assign({}, this.paramStore, memory);
    return rt;
  }

  get api() {
    return this.context.api;
  }

  // newContext(config, block, key?) {
  //   return (
  //     <OSContext.Provider key={key} value={_.merge({}, this.context, config)}>
  //       {block()}
  //     </OSContext.Provider>
  //   );
  // }
  appendPath(pathComps) {
    const rt = this.clone();
    rt.path = this.path.concat(pathComps);
    return rt;
  }

  registerSubValues(ids: string[]) {
    if (ids.length) {
      const rt = this.clone();
      rt.valueRegistry = Object.assign({}, this.valueRegistry);
      for (const id of ids) {
        rt.valueRegistry[id] = this.path;
      }
      return rt;
    }
    else {
      return this;
    }
  }

  private stateKey(id) {
    return (this.valueRegistry[id] || []).concat(id).join('/');
  }

  setState(id, value) {
    this.stateStore[this.stateKey(id)] = value;
  }

  getState(id) {
    return this.stateStore[this.stateKey(id)];
  }

  hasState(id) {
    return this.stateStore && this.stateKey(id) in x(this.stateStore);
  }

}
