import _ from 'lodash';
import cx from 'classnames';
import { EntityRow } from "../../components/EntityRow";
import { GraphView } from "../../components/Graph";
import { db } from "../../db";
import { getGraphParent, queryGraphBasic } from "../../etc/queryGraph";
import { styled } from "../../component2";
import { evaluate, execute, mapStructure } from "../main";
import { structRenderers } from "../structRenderers";
import { typeRegistry } from "../typeRegistry.1";
import { doEntityQuery } from "./$EntityQuery";
import { useContext } from "react";
import { SystemContext } from "../../etc/SystemContext";
import { AttributeType } from "../../components/AttributeType";
import { renderAttributeValue } from './renderAttributeValue';
import { entitySchema } from '../../components/attributesForType';
import { executeCreate } from './executeCreate';
import { $EntityTemplate } from './$EntityTemplate';
import { NotionButton } from '../../components/AddButton';
import { groupById } from './groupById';
import { getEntityById } from '../../etc/createEntity';

export const $EntityQueryView = typeRegistry.registerType({
  $: '199072bf-7212-55bd-bdd8-da3de0bcfb45',
  Query: '4614a32b-92a0-5c68-8800-a2add1c740a9',
  Name: '7dea5659-9a95-5add-92d3-39d74f19c09e',
  ShowGraph: 'e99e5a88-6ee3-5d3e-81e3-71ce9557e768',
  GroupBy: 'ac97a408-745b-571e-8cd0-a2ba173c9794',
  Add: '85c9b08e-a80e-5f6b-9dc7-b64947c44f73',
}, ids => ({
  _id: ids.$,
  name: 'Entity Query View',
  definition: [
    {
      id: ids.Name,
      name: 'Name',
      type: [],
      property: 'name',
    },
    {
      id: ids.Add,
      name: 'Add',
      type: [],
      property: 'add',
    },
    {
      id: ids.Query,
      name: 'Query',
      type: [],
      property: 'query',
    },
    {
      id: ids.ShowGraph,
      name: 'Show Graph',
      type: [],
      property: 'showGraph',
    },
    {
      id: ids.GroupBy,
      name: 'Group By',
      type: [],
      property: 'groupBy',
    },
  ]
}));

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;



  ${GraphView} {
    flex: 0 0 auto;
    position: relative;
    width: 400px;

    border-right: 1px solid #ccc;

  }

  &.stack {
    overflow: auto;
  }

  .list {
    .top {
      display: flex;
      justify-content: flex-end;
    }
    flex: 0 0 auto;
    padding: 6px;
    box-sizing: border-box;

    width: 600px;
    max-height: 100%;
    border-right: 1px solid #ccc;

    overflow: auto;

    .group {
      margin-bottom: 8px;

      .groupName {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  &.browser .list {
    flex: 1 1 auto;
  }
`;

export const getAttributeOrder = (attrId, key, value) => {
  const attr = db.attributeTypes.findById(attrId);
  if (attr.type == AttributeType.valuePoint || attr.type == AttributeType.switch) {
    const evaluted = evaluate(execute(key || attr.valuePoint));
    let i = 0;
    if (evaluted.groups) for (const group of evaluted.groups) {
      for (const entry of group.entries) {
        if (entry._id == value) {
          return i;
        }
        i++;
      }
    }
  }

  return -1;
}


structRenderers[$EntityQueryView.$] = (value, map, state) => {
  const mapped = mapStructure(value);
  const r = doEntityQuery(mapped.query, map);

  const inGraph = {};

  for (const id of r) {
    const parent = getGraphParent(null, id);
    if (parent) {
      inGraph[parent] = true;
    }
  }

  let list;

  if (state.selected) {
    const gadsf = queryGraphBasic(state.selected, false).map(r => r.entity);
    list = r.filter(r => gadsf.includes(r));
  }
  else {
    list = r;
  }

  list = _.uniq(list)

  const context = useContext(SystemContext);

  let c;

  if (mapped.groupBy?.content) {
    /*const groups = {};

    for (const id of list) {
      const entity = getEntityById(id);
      let group;
      if (entity?.attributes?.[mapped.groupBy.content]) {
        group = JSON.stringify([ getAttributeKey(mapped.groupBy.content, id), entity.attributes[mapped.groupBy.content] ]);
      }
      else {
        group = JSON.stringify([null, 'None']);
      }

      if (!groups[group]) {
        groups[group] = [];
      }

      groups[group].push(id);
    }

    // sort groups by attribute order
    const groupsSorted = Object.keys(groups).sort((a, b) => {
      const aOrder = (getAttributeOrder as any)(mapped.groupBy.content, ...JSON.parse(a));
      const bOrder = (getAttributeOrder as any)(mapped.groupBy.content, ...JSON.parse(b));
      console.log(aOrder, bOrder)
      return aOrder - bOrder;
    });

    c = groupsSorted.map(a => {
      const [ key, group ] = JSON.parse(a);
      return (
        <div key={group} className="group">
          <div className="groupName">{group == 'None' ? 'None' : renderAttributeValue(mapped.groupBy.content, key, group)}</div>
          {groups[a].map(id => (
            <EntityRow key={id} id={id} path />
          ))}
        </div>
      )
    });*/

    c = groupById(list, mapped.groupBy.content).map(({ key, group, entities }) => (
      <div key={group} className="group">
        <div className="groupName">{group == 'None' ? 'None' : renderAttributeValue(mapped.groupBy.content, key, group)}</div>
        {entities.map(id => (
          <EntityRow key={id} id={id} path />
        ))}
      </div>
    ));
  }
  else {
    c = list.map(id => (
      <EntityRow key={id} id={id} path />
    ));
  }
  
  return (
    <Wrapper
      data-value-point={value._id}
      className={cx(context?.viewType, { showGraph: mapped.showGraph?.context })}
      >
      {mapped.showGraph?.content && <GraphView
        _state={state}
        root={null}
        data={Object.keys(inGraph).map(id => {
          const gadsf = queryGraphBasic(id, false).map(r => r.entity);
          const children = r.filter(r => gadsf.includes(r));
          const schema = entitySchema(id);
          let contextMenu;

          if (schema.actions?.content) {
            contextMenu = schema.actions.content.map(a => {
              const mapped = mapStructure(a);
              return {
                text: mapped.name.content,
                onClick: () => {
                  executeCreate(mapped.action, {
                    [$EntityTemplate.Entity]: id,
                  })
                }
              }
            });
          }

          return {
            id,
            name: `${getEntityById(id)?.name} (${children.length})`,
            meta: {
              parent: getGraphParent(null, id),
              contextMenu,
            }
          }
        })}
      />}
      <div className="list">
        <div className="top">
          {mapped.add?.content && <NotionButton text="Add" onClick={() => {
            const entity = executeCreate(mapped.add, map)
            context?.navigate?.({
              type: 'entity',
              id: entity._id,
            })
          }} />}
        </div>
        {/* {state.selected} */}
        {c}
      </div>

    </Wrapper>
  )
}