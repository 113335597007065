import React, { Component } from 'react';
import _ from 'lodash';
import { db } from '../db';
import { component } from '../component2';
import { x, XObject } from '../XObject';
import { ObjectDisplay } from './ObjectDisplay';
import { appState } from '../etc/appState';
import { SystemContext, SystemContextProps } from '../etc/SystemContext';
import { ObjectType } from '../types/ObjectRef';
import { PaneType } from '../types/PaneType';
import { inspectObj } from '../inspectObj';
import { WithContextAction } from './WithContextAction';
import { Cell } from './Cell';
import { EntityCellType } from './cells';
import { objectName } from './objectFuncs';
import { PropertyField } from './PropertyField';
import Sugar from 'sugar';
import juration from '../juration';
import { getGraphParent } from '../etc/queryGraph';
import { styled } from '../component';
import { ViewsList } from './ViewsList';
import { Tag } from './Tag';
import { entityDisplayName } from './entityDisplayName';
import { EntityPath } from './EntityPath';
import { Svg } from './Svg';
import { createEventOccurrence, startEvent, userEntity } from './startEvent';
import { getCurrentOccurrence } from './getCurrentOccurrence';
import { TrackRoot } from './TrackRoot';
import { AttributeField } from './AttributeField';
import { AttributeType } from './AttributeType';
import { getEntityById } from '../etc/createEntity';

@component
export class OccurrenceBar extends Component {
  static styles = styled.div`
    display: flex;
    align-items: center;
    padding: 0 10px;
  `;

  timerId
  componentDidMount(): void {
    this.timerId = setInterval(() => {
      this.forceUpdate();
    }, 1000);
  }

  static contextType = SystemContext;
  context: SystemContextProps;

  render(Container?) {
    const currentOccurrence = getCurrentOccurrence();

    if (!currentOccurrence) {
      return null;
    }

    const event = db.events.findById(currentOccurrence.event);
    // if (!event) {
    //   return <Container>no event</Container>
    // }

    const parameterizedEvent = currentOccurrence.parameterizedEvent && db.parameterizedEvents.findById(currentOccurrence.parameterizedEvent);


    const args = parameterizedEvent?.arguments;

    const duration = currentOccurrence.stop ? currentOccurrence.stop.getTime() - currentOccurrence.start.getTime() : Date.now() - currentOccurrence.start.getTime();

    return (
      <Container>
        <span
          onClick={() => {
            console.log('asdf');
            this.context.navigate({
              type: PaneType.eventOccurrence,
              id: currentOccurrence._id,
            })
          }}
        >{event?.name} {args && objectName({ type: ObjectType.entity, id: args })} ({juration.stringify(duration/1000)})</span> <button onClick={() => {
          currentOccurrence.stop = new Date();
        }}>Stop</button>

        <button
          onClick={() => {
            db.eventOccurrences.splice(db.eventOccurrences.indexOf(currentOccurrence), 1);
          }}
        >X</button>
      </Container>
    )
  }
}


@component
class EventItem extends Component<{ id; }> {
  static contextType = SystemContext;
  context: SystemContextProps;
  render() {
    const event = db.events.findById(this.props.id);
    const occurrences = db.eventOccurrences.filter(occurrence => occurrence.event == event._id && !occurrence.parameterizedEvent);
    const current = occurrences.find(occurrence => occurrence.start && !occurrence.stop);
    const parameterizedEvents = db.parameterizedEvents.filter(parameterizedEvent => !parameterizedEvent.done && parameterizedEvent.event == event._id);

    return (
      <>
        <WithContextAction
          menu={[
            {
              text: 'Edit',
              onClick: () => {
                inspectObj({
                  type: ObjectType.event,
                  id: event._id
                });
              },
            },
            {
              text: 'Add derived event',
              onClick: () => {
                db.events.push(XObject.obj({
                  name: 'New event',
                  kind: event._id,
                  parent: {
                    type: ObjectType.mode,
                    id: appState.currentMode,
                  }
                }));

              }
            },
            {
              text: 'Add parameterized event',
              onClick: () => {
                db.parameterizedEvents.push(XObject.obj({
                  event: event._id,
                }));
              }
            }
          ]}
        >
          <ObjectDisplay
            _onClick={() => {
              this.context.navigate?.({
                type: PaneType.event,
                id: event._id,
              });
            }}
            obj={{
              type: ObjectType.event,
              id: event._id
            }} /> {event.kind && <> ({db.events.findById(event.kind).name})s</>} {current ?
              <button
                onClick={() => {
                  current.stop = new Date();
                }}
              >
                End
              </button>
              : <button
                onClick={() => {
                  startEvent({
                    event: event._id,
                  })
                }}
              >Start</button>} <button
                onClick={() => {
                  createEventOccurrence({
                    event: event._id,
                    timestamp: new Date(),
                  });
                }}
              >Log</button>
        </WithContextAction>

        {parameterizedEvents.length > 0 && (
          <>
            <ul>
              {parameterizedEvents.map((parameterizedEvent, i) => {
                const occurrences = db.eventOccurrences.filter(occurrence => occurrence.parameterizedEvent == parameterizedEvent._id);
                const current = occurrences.find(occurrence => occurrence.start && !occurrence.stop);
                return (
                  <li key={i}>
                    <span
                      onClick={() => {
                        this.context.navigate?.({
                          type: PaneType.parameterizedEvent,
                          id: parameterizedEvent._id,
                        });
                      }}
                    >
                      {objectName({
                        type: ObjectType.parameterizedEvent,
                        id: parameterizedEvent._id,
                      })}
                    </span>
                    <Cell
                      title='Entity'
                      cell={new EntityCellType({})}
                      get={() => parameterizedEvent.arguments}
                      set={value => {
                        parameterizedEvent.arguments = value;
                      }} />

                    {current ?
                      <button
                        onClick={() => {
                          current.stop = new Date();
                        }}
                      >
                        End
                      </button>
                      : <button
                        onClick={() => {
                          createEventOccurrence({
                            event: event._id,
                            parameterizedEvent: parameterizedEvent._id,
                            start: new Date(),
                          });
                        }}
                      >Start</button>} <button
                        onClick={() => {
                          createEventOccurrence({
                            user: userEntity(),
                            event: event._id,
                            parameterizedEvent: parameterizedEvent._id,
                            timestamp: new Date(),
                          });

                        }}
                      >Log</button>
                  </li>
                );
              })}
            </ul>
          </>
        )}
      </>
    );
  }
}

@component
class Buttons extends Component<{ event?, parameterizedEvent?, extraProps? }> {
  static styles = styled.div`
    display: flex;
    align-items: center;

    .button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      border-radius: 4px;
      height: 24px;
      padding: 0px 6px;
      background: #f2f2f2;


      font-size: 8px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      color: #bcbcbc;
      margin-left: 6px;

      background: #f2f2f2;
      cursor: pointer;
    }
  `;
  render() {
    const { event, parameterizedEvent } = this.props;
    const occurrences = event ?
      db.eventOccurrences.filter(occurrence => occurrence.event == event._id && !occurrence.parameterizedEvent) :
      db.eventOccurrences.filter(occurrence => occurrence.parameterizedEvent == parameterizedEvent._id);
    const current = occurrences.find(occurrence => occurrence.start && !occurrence.stop);

    return (
      <>
      {current ?
              <span className="button"
                onClick={e => {
                  e.stopPropagation();
                  current.stop = new Date();
                }}
              >
                End
              </span>
              : <span className="button"
                onClick={e => {
                  e.stopPropagation();

                  createEventOccurrence({
                    event: event._id,
                    start: new Date(),
                    ...(this.props.extraProps || {}),
                  });
                }}
              >Start</span>} <span className="button"
                onClick={e=> {
                  e.stopPropagation();

                  createEventOccurrence({
                    event: event._id,
                    timestamp: new Date(),
                    ...(this.props.extraProps || {}),
                  });

                }}
              >Log</span>
      </>
    )
  }
}

@component
export class EventsList extends Component<{ events, extraProps? }> {
  static styles = styled.div`
    .item {
      display: flex;
      height: 32px;
      padding: 0px 12px;
      border-radius: 3px;
      align-items: center;
      cursor: pointer;
      ${Tag} {
        margin-left: 4px;
      }

      ${Buttons} {
        margin-left: auto;
      }

      &:hover {
        background: #f8f8f8;
      }
    }
    .parameterizedEvents {
      padding-left: 6px;
      border-left: 3px solid rgb(238, 238, 238);
      margin-left: 7px;
    }
  `
  static contextType = SystemContext;
  context: SystemContextProps;
  render() {
    return (
      <>
        <div>
          {this.props.events.map((event, i) => {
            const parameterizedEvents = db.parameterizedEvents.filter(parameterizedEvent => !parameterizedEvent.done && parameterizedEvent.event == event._id);
            const occurrences = db.eventOccurrences.filter(occurrence => occurrence.event == event._id && !occurrence.parameterizedEvent);
            const current = occurrences.find(occurrence => occurrence.start && !occurrence.stop);
        
            return (
              <div key={i} className="itemWrapper">
                <WithContextAction
                  menu={[
                    {
                      text: 'Edit',
                      onClick: () => {
                        inspectObj({
                          type: ObjectType.event,
                          id: event._id
                        });
                      },
                    },
                    {
                      text: 'Add derived event',
                      onClick: () => {
                        db.events.push(XObject.obj({
                          name: 'New event',
                          kind: event._id,
                          parent: {
                            type: ObjectType.mode,
                            id: appState.currentMode,
                          }
                        }));

                      }
                    },
                    {
                      text: 'Add parameterized event',
                      onClick: () => {
                        db.parameterizedEvents.push(XObject.obj({
                          event: event._id,
                        }));
                      }
                    },
                    {
                      text: 'Delete',
                      onClick: () => {
                        db.events.splice(db.events.indexOf(event), 1);
                      }
                    }
                  ]}
                >
                  <div
                    className="item"
                    onClick={() => {
                      this.context.navigate({
                        type: PaneType.event,
                        id: event._id,
                      });
                    }}
                  >
                    {event.name}
                    <Buttons event={event} extraProps={this.props.extraProps} />
                  </div>
                </WithContextAction>
                <div className="parameterizedEvents">
                  {parameterizedEvents.map((parameterizedEvent, i) => {
                    const entity = getEntityById(parameterizedEvent.arguments);
                    return (
                      <WithContextAction
                      key={i}
                      menu={[

                        {
                          text: 'Delete',
                          onClick: () => {
                            db.parameterizedEvents.splice(db.parameterizedEvents.indexOf(parameterizedEvent), 1);
                          }
                        }
                      ]}
                    >
                      <div
                        
                        className="item"
                        onClick={() => {
                          this.context.navigate({
                            type: PaneType.parameterizedEvent,
                            id: parameterizedEvent._id,
                          })
                        }}
                      >
                        {event.name} <Tag text={entity ? entityDisplayName(entity._id) : 'deleted'} />
                        <Buttons parameterizedEvent={parameterizedEvent} />
                      </div>
                    </WithContextAction>
                    )
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </>
    )
  }
}

@component
export class EventOccurrence extends Component<{ id; }> {
  static contextType = SystemContext;
  context: SystemContextProps;
  static styles = styled.div`
    &:hover {
      background: #f8f8f8;
    }
    .wrapper {
      display: flex;
      align-items: center;

    }

    ${EntityPath} {
      font-size: 8px;
      color: gray;
    }

    .entity {
      cursor: pointer;
      white-space: nowrap;
      flex: 1 1 auto;
      width: 0;
      padding-right: 8px;
      margin-right: 8px;
    }

    .event {
      margin-right: 8px;
      font-size: 10px;
      font-weight: bold;
      white-space: nowrap;
    }
    .user {
      font-size: 10px;
      margin-right: 8px;
    }
    .timeInfo {
      margin-left: auto;
      display: flex;
      justify-content: flex-end;
      font-size: 11px;
      flex: 0 0 auto;

      .duration {
        font-weight: 500;
      }
    }

    .startTime {
      width: 106px;
      text-align: right;
    }
  `;
  render() {
    const occurrence = db.eventOccurrences.findById(this.props.id);
    const event = db.events.findById(occurrence.event);
    let c;

    let timeC;

    if (occurrence.timestamp) {
      timeC = <span className="startTime">{occurrence.timestamp.format('{yyyy}-{MM}-{dd} {hh}:{mm}')}</span>;

    }
    else if (occurrence.start) {
      const duration = occurrence.stop ? occurrence.stop.getTime() - occurrence.start.getTime() : Date.now() - occurrence.start.getTime();
      timeC = <><span className="duration">{juration.stringify(duration/1000)}</span> <span className="startTime">{occurrence.start.format('{yyyy}-{MM}-{dd} {hh}:{mm}')}</span></>;
    }

    let argument;
    if (occurrence.parameterizedEvent) {
      const parameterizedEvent = db.parameterizedEvents.findById(occurrence.parameterizedEvent);
      if (parameterizedEvent.arguments) {
        argument = entityDisplayName(parameterizedEvent.arguments);
      }
    }
    else if (occurrence.arguments) {
      argument = entityDisplayName(occurrence.arguments);
    }

    c = (
      <div
        onClick={() => {
          this.context.navigate({
            type: PaneType.eventOccurrence,
            id: occurrence._id,
          })
        }}
      >
        <div className="wrapper">
          {occurrence.description && <span className="description">"{occurrence.description}"</span>}
          <span className="event">{event.name}</span> {occurrence.arguments && <span className="entity"
            onClick={e => {
              e.stopPropagation();
              this.context.navigate?.({
                type: 'entity',
                id: occurrence.arguments,
              });
            }}      
          ><Tag text={argument} /></span>} {Object.keys(occurrence.attributes || {}).map(id => {
            return (
              <span key={id}><Tag text={occurrence.attributes[id]} /></span>
            )
          })} <span className="timeInfo">{timeC}</span>
        </div>
        <div className="path">
          <EntityPath entity={occurrence.arguments} />
        </div>
      </div>
    );

    return (
      <>

      <WithContextAction
        mobileInvoke="longPress"
        menu={[
          {
            text: 'Delete',
            onClick: () => {
              db.eventOccurrences.splice(db.eventOccurrences.indexOf(occurrence), 1);
            }
          }
        ]}
      >
        {c}
        </WithContextAction>
        
        {/* <button
          onClick={() => {
            const index = db.eventOccurrences.indexOf(occurrence);
            db.eventOccurrences.splice(index, 1);
          }}
        >X</button> */}
      </>
    );
  }
}

@component
export class EventsRoot extends Component {
  static contextType = SystemContext;
  context: SystemContextProps;

  state = XObject.get(appState, 'eventsState', {});
  static styles = styled.div`
    ${ViewsList}.inner {
      font-size: 10px;
    }

    ${EventOccurrence} {
      margin-bottom: 6px;
    }

    .byEvent {
      .time {
        font-weight: 500;
      }

      .eventName {
        cursor: pointer;
      }

      .userName {
        cursor: pointer;
      }
    }
    
    .byPerson {
      .time {
        font-weight: 500;
      }

      .eventName {
        cursor: pointer;
      }

      .userName {
        cursor: pointer;
      }
    }

    .entityBreakdown {
      .entityName {
        cursor: pointer;
      }
      .entityTime {
        font-weight: 500;
      }
      .eventBreakdowns {
        margin-left: 8px;
      }
      .eventBreakdown {
        font-size: 10px;
        &:not(:last-child) {
          margin-right: 8px;
        }
        color: gray;

        .eventName {
          cursor: pointer;

        }

        .eventTime {
          font-weight: 500;
        }
      }
    }

    .toolbar {
      display: flex;

      .add {
        fill:rgb(136, 164, 255);
        width: 16px;
        height: 16px;
      }

      .right {
        margin-left: auto;
      }
    }

    ${EventsList} {
      margin-top: 8px;
    }

    .filter {
      margin-bottom: 8px;
      .placeholder {
        color: #d1d1d1;
      }
    }
  `;

  render() {
    let eventOccurrences;

    const events = db.events.filter(event => event.parent.id == appState.currentMode);

    if (this.state.startTime || this.state.endTime) {
      const startTime = this.state.startTime && Sugar.Date.create(this.state.startTime);
      const endTime = this.state.endTime && Sugar.Date.create(this.state.endTime);
      eventOccurrences = db.eventOccurrences.filter(occurrence => {
        if (!events.find(event => event._id == occurrence.event)) return false;

        const time = occurrence.stop || occurrence.start || occurrence.timestamp;
       
        if (startTime && time.getTime() < startTime.getTime()) {
          return false;
        }

        if (endTime && time.getTime() > endTime.getTime()) {
          return false;
        }

        return true;
      });
    }
    else {
      eventOccurrences = db.eventOccurrences.filter(occurrence => events.find(event => event._id == occurrence.event));
    }

    // sort by time
    eventOccurrences.sort((a, b) => {
      const aTime = a.start || a.timestamp;
      const bTime = b.start || b.timestamp;

      if (!aTime || !bTime) return -1;

      return aTime.getTime() - bTime.getTime();
    });

    // total time
    const byEvent: {
      [eventId: string]: {
        total: number,
        byPerson: {
          [userId: string]: number,
        }
    } } = {};

    for (const occurrence of eventOccurrences) {
      const event = db.events.findById(occurrence.event);
      if (!event) continue;

      byEvent[event._id] = byEvent[event._id] || {
        total: 0,
        byPerson: {},
      };

      const time = occurrence.stop ? occurrence.stop.getTime() - occurrence.start.getTime() : 0;
      byEvent[event._id].total += time;

      byEvent[event._id].byPerson[occurrence.user] = byEvent[event._id].byPerson[occurrence.user] || 0;
      byEvent[event._id].byPerson[occurrence.user] += time;
    }

    const byPerson: {
      [userId: string]: {
        total: number,
        byEvent: {
          [eventId: string]: number,
        }
    } } = {};

    for (const occurrence of eventOccurrences) {
      const event = db.events.findById(occurrence.event);
      if (!event) continue;

      byPerson[occurrence.user] = byPerson[occurrence.user] || {
        total: 0,
        byEvent: {},
      };

      const time = occurrence.stop ? occurrence.stop.getTime() - occurrence.start.getTime() : 0;
      byPerson[occurrence.user].total += time;

      byPerson[occurrence.user].byEvent[event._id] = byPerson[occurrence.user].byEvent[event._id] || 0;
      byPerson[occurrence.user].byEvent[event._id] += time;
    }

    const entityChildren = {};

    const byEntity: {
      [entityId: string]: {
        total: number,
        byEvent: {
          [eventId: string]: number,
        }
      }
    } = {};

    const addAncestors = (entityId: string) => {
      const entity = getEntityById(entityId);
      if (!entity) return;

      const parent = getGraphParent(null, entityId) || 'root';
      entityChildren[parent] = entityChildren[parent] || {};
      entityChildren[parent][entityId] = true;

      if (parent != 'root')
        addAncestors(parent);

    }
    for (const occurrence of eventOccurrences) {
      if (occurrence.arguments) {
        if (!byEntity[occurrence.arguments]) byEntity[occurrence.arguments] = {
          total: 0,
          byEvent: {},
        }

        byEntity[occurrence.arguments].total += occurrence.stop ? occurrence.stop.getTime() - occurrence.start.getTime() : 0;

        const event = db.events.findById(occurrence.event);
        if (event) {
          byEntity[occurrence.arguments].byEvent[event._id] = byEntity[occurrence.arguments].byEvent[event._id] || 0;
          byEntity[occurrence.arguments].byEvent[event._id] += occurrence.stop ? occurrence.stop.getTime() - occurrence.start.getTime() : 0;
        }

        const parent = getGraphParent(null, occurrence.arguments) || 'root';
        if (parent != 'root') addAncestors(parent);
        entityChildren[parent] = entityChildren[parent] || {};
        entityChildren[parent][occurrence.arguments] = true;
      }
    }

    const renderEntityTime = (total, byEvent) => {
      return (
        <>
          <span className="entityTime">{total && juration.stringify(total/1000)}</span> <span className="eventBreakdowns">{Object.keys(byEvent || {}).map((eventId, i) => {
            const event = db.events.findById(eventId);
            if (!event) return null;

            return (
              <span key={i} className="eventBreakdown">
                <span><span className="eventName"
                
                  onClick={() => {
                    this.context.navigate({
                      type: PaneType.event,
                      id: event._id,
                    })
                  }}
                >{event.name}</span>: <span className="eventTime">{juration.stringify(byEvent[eventId]/1000)}</span></span>
              </span>
            )
          })}</span>
        </>
      )
    }

    const renderChildren = (entityId: string) => {
      return (
        <ul className="entityBreakdown">
          {Object.keys(entityChildren[entityId] || {}).map((entity, i) => {
            let totalTime = byEntity[entity]?.total || 0;
            const totalByEvent = _.clone(byEntity[entity]?.byEvent || {});

            const addTime = (entityId: string) => {
              for (const child of Object.keys(entityChildren[entityId] || {})) {
                totalTime += byEntity[child]?.total || 0;
                for (const eventId of Object.keys(byEntity[child]?.byEvent || {})) {
                  totalByEvent[eventId] = totalByEvent[eventId] || 0;
                  totalByEvent[eventId] += byEntity[child].byEvent[eventId];
                }
                addTime(child);
              }
            }

            addTime(entity);

            let timeC;

            if (totalTime != byEntity[entity]?.total) {
              if (byEntity[entity]) {
                timeC = <span>{renderEntityTime(totalTime, totalByEvent)} ({renderEntityTime(byEntity[entity].total, byEntity[entity].byEvent)})</span>;
              }
              else {
                timeC = <span>{renderEntityTime(totalTime, totalByEvent)}</span>;
              }
            }
            else if (byEntity[entity]) {
              timeC = renderEntityTime(byEntity[entity].total, byEntity[entity].byEvent);
            }


            return (
              <li key={i}>
                <span
                >
                  <span className="entityName"
                                    onClick={() => {
                    this.context.navigate?.({
                      type: 'entity',
                      id: entity,
                    });
                  }}

                  >{objectName({
                    type: ObjectType.entity,
                    id: entity,
                  })}</span>{timeC && <>: {timeC}</>}
                </span>
                {renderChildren(entity)}
              </li>
            );
          })}
        </ul>
      )
    }

    const renderFilter = () => {
        return <div
          className="filter"
        ><PropertyField placeholder="Start" object={this.state} property="startTime" type="time" /> - <PropertyField placeholder="End" object={this.state} property="endTime" type="time" /></div>;
    }

    return (
      <>
        <ViewsList
          active={this.state.tab1 || 'events'}
          contextMenu={null}
          onAdd={null}
          onClickView={id => this.state.tab1 = id}
          onDelete={null}
          views={[
            {
              _id: 'events',
              icon: null,
              name: 'Events',
            },
            {
              _id: 'occurrences',
              icon: null,
              name: 'Occurrences',
            },
            {
              _id: 'track',
              icon: null,
              name: 'Track',
            }
          ]}
        />
        {(this.state.tab1 == 'events' || !this.state.tab1) && (
          <>
            <div className="toolbar">
              <ViewsList
                className="inner"
                active={this.state.eventsTab || 'current'}
                contextMenu={null}
                onAdd={null}
                onClickView={id => this.state.eventsTab = id}
                onDelete={null}
                views={[
                  {
                    _id: 'current',
                    icon: null,
                    name: 'Current',
                  },
                  {
                    _id: 'all',
                    icon: null,
                    name: 'All',
                  }
                ]}
              />
              <div className="right">
                <Svg name="icons8-create (2)" className="add"
                  onClick={() => {
                    db.events.push(XObject.obj({
                      name: 'New event',
                      parent: {
                        type: ObjectType.mode,
                        id: appState.currentMode,
                      }
                    }));
        
                  }}
                />
              </div>
            </div>
          {(this.state.eventsTab == 'current' || !this.state.eventsTab) && (
            <>
            
            </>
            )}

          {this.state.eventsTab == 'all' && (
            <>

              <EventsList events={events.filter(e => e.parent.id == appState.currentMode)} />
            </>
            )}

          </>  
        )}
        {this.state.tab1 == 'occurrences' && (
          <>
            <ViewsList
              className="inner"
              active={this.state.occurrencesTab || 'breakdown'}
              contextMenu={null}
              onAdd={null}
              onClickView={id => this.state.occurrencesTab = id}
              onDelete={null}
              views={[
                {
                  _id: 'breakdown',
                  icon: null,
                  name: 'Breakdown',
                },
                {
                  _id: 'list',
                  icon: null,
                  name: 'List',
                }
              ]}
            />
            {(this.state.occurrencesTab == 'breakdown' || !this.state.occurrencesTab) && (
              <>
                {renderFilter()}
                <div className="byEvent">
                  <h3>By Event</h3>
                  <ul>
                    {Object.keys(byEvent).map((eventId, i) => {
                      const event = db.events.findById(eventId);

                      return (
                        <li key={i}>
                          <span className="eventName"
                            onClick={() => {
                              this.context.navigate?.({
                                type: PaneType.event,
                                id: eventId,
                              });
                            }}
                          >{event.name}</span>: <span className="time">{juration.stringify(byEvent[eventId].total/1000)}</span>

                          <ul>
                            {Object.keys(byEvent[eventId].byPerson).map((userId, i) => {
                              const user = getEntityById(userId);

                              return (
                                <li key={i}>
                                  <span className="userName"
                                    onClick={() => {
                                      this.context.navigate?.({
                                        type: 'entity',
                                        id: userId,
                                      });
                                    }}
                                  >{user?.name}</span>: <span className="time">{juration.stringify(byEvent[eventId].byPerson[userId]/1000)}</span>
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      );

                    })}
                  </ul>
                </div>
                <div className="byPerson">
                  <h3>By Person</h3>
                  <ul>
                    {Object.keys(byPerson).map((userId, i) => {
                      const user = getEntityById(userId);

                      return (
                        <li key={i}>
                          <span className="userName"
                            onClick={() => {
                              this.context.navigate?.({
                                type: 'entity',
                                id: userId,
                              });
                            }}
                          >{user?.name}</span>: <span className="time">{juration.stringify(byPerson[userId].total/1000)}</span>

                          <ul>
                            {Object.keys(byPerson[userId].byEvent).map((eventId, i) => {
                              const event = db.events.findById(eventId);

                              return (
                                <li key={i}>
                                  <span className="eventName"
                                    onClick={() => {
                                      this.context.navigate?.({
                                        type: PaneType.event,
                                        id: eventId,
                                      });
                                    }}
                                  >{event.name}</span>: <span className="time">{juration.stringify(byPerson[userId].byEvent[eventId]/1000)}</span>
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      );

                    })}
                  </ul>
                </div>
                <h3>By Entity</h3>
                {renderChildren('root')}
              </>
            )}
            {this.state.occurrencesTab == 'list' && (
              <>
                {renderFilter()}
                <div>
                  {eventOccurrences?.map((occurrence, i) => {
                    return (
                      <div key={i}>
                        <EventOccurrence id={occurrence._id} />
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </>
        )}
        {this.state.tab1 == 'track' && (
          <>
            <TrackRoot />!!
          </>
        )}
      </>
    );
  }
}

@component
export class EventView extends Component<{ id; }> {
  static styles = styled.div`
    ${EventOccurrence} {
      margin-bottom: 6px;
    }
  `;
  render() {
    const event = db.events.findById(this.props.id);
    const occurrences = db.eventOccurrences.filter(occurrence => occurrence.event == this.props.id);

    let totalTime = 0;
    for (const occurrence of occurrences) {
      if (occurrence.start && occurrence.stop) {
        totalTime += occurrence.stop.getTime() - occurrence.start.getTime();
      }
    }



    return (
      <>

        <div>
          <b>Total Time: {juration.stringify(totalTime/1000)}</b>
          {occurrences.map((occurrence, i) => {
            return (
              <div key={occurrence._id}>
                <EventOccurrence id={occurrence._id} />
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

@component
export class ParameterizedEventView extends Component<{ id; }> {
  render() {
    const occurrences = db.eventOccurrences.filter(occurrence => occurrence.parameterizedEvent == this.props.id);
    const parameterizedEvent = db.parameterizedEvents.findById(this.props.id);
    
    return (
      <>
            
        <AttributeField
          title={'Parameter'}
          type={AttributeType.entity}
          field={<Cell
            cell={new EntityCellType({})}
            get={() => parameterizedEvent.arguments}
            set={v => parameterizedEvent.arguments = v}
            title="Entity" />
          }
        />

        <div>
          {occurrences.map((occurrence, i) => {
            return (
              <div key={occurrence._id}>
                <EventOccurrence id={occurrence._id} />
              </div>
            );
          })}
        </div>
      </>
    );
  }
}
