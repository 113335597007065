import axios from 'axios';
import { configApiServer } from './db';
import md5 from 'md5';
import { getMany, setMany, get, set } from 'idb-keyval';
import { X, x } from './XObject';

const reactiveCache = {};
const reactiveTick = X({});

const getting = {}

export function getEmbeddingsReactive(text: string[], model='text-embedding-ada-002') {
  const ids = text.map(t => model + '.' + md5(t));

  const missing = [];
  for (let i = 0; i < text.length; ++ i) {
    if (!(ids[i] in reactiveCache)) {
      missing.push(i);
    }
    else {

    }
  }

  for (const id of ids) {
    // eslint-disable-next-line
    reactiveTick[id];
  }

  if (missing.length) {
    const key = md5(missing.map(i => ids[i]).join(''));
    if (!getting[key]) {
      getting[key] = true;
      getEmbeddings(missing.map(i => text[i]), model).then(r => {
        for (let i = 0; i < r.length; ++ i) {
          const id = ids[missing[i]];
          reactiveCache[id] = r[i];
          reactiveTick[id] = true;
        }
        getting[key] = false;
      })
    }
    return;
  }
  else {
    return ids.map(id => reactiveCache[id]);
  }
}



export async function getEmbeddings(text: string[], model='text-embedding-ada-002') {
  const ids = text.map(t => model + '.' + md5(t));
  const cached = await getMany(ids);
  const toGenerate = [];
  const result = [];
  for (let i = 0; i < text.length; ++i) {
    if (cached[i]) {
      result[i] = cached[i];
    }
    else {
      toGenerate.push({
        i,
        text: text[i],
      });
    }
  }

  if (toGenerate.length) {
    const r = (await axios.post(`${configApiServer()}embeddings`, JSON.stringify({
      text: toGenerate.map(i => i.text || 'null'),
      model,
    }), { headers: { 'Content-Type': 'application/json' } })).data;


    const cacheUpdate = [];

    for (let i = 0; i < toGenerate.length; ++i) {
      result[toGenerate[i].i] = r.data[i].embedding;
      cacheUpdate.push([model + '.' + md5(toGenerate[i].text), r.data[i].embedding]);
    }

    if (cacheUpdate.length) await setMany(cacheUpdate);

  }

  return result;

}


function summaryKey(text: string[]) {
  return md5(text.join('\n')) + 'v2' + defaultModel;
}

const defaultModel = 'gpt-4';

export async function getSummary(text: string[], model=defaultModel) {
  const key = summaryKey(text);
  const r = await get(key);
  if (r) {
    return r;
  }

  const rr = (await axios.post(`${configApiServer()}completion`, JSON.stringify({
    model,
    messages: [
      { role: 'system', content: 'The user will provide a list of notes, thoughts, or excerpts from podcasts. Your job is to do your best to provide a summary of a couple words that best describes the topics covered. It should be a summary of all the notes, not a summary for each note. Try to more specifically capture the nuance of the topic.' },
      { role: 'user', content: text.join('\n') },
    ],
  }), { headers: { 'Content-Type': 'application/json' }})).data;

  const response: string = rr[0].message.content;

  await set(key, response);

  return response;

}

const summaryCache = X({});
const summaryCache_getting = {};

export function getSummaryReact(text: string[], model=defaultModel) {
  const key = summaryKey(text);

  if (key in x(summaryCache)) {
    return summaryCache[key];
  }

  // eslint-disable-next-line
  summaryCache[key];

  if (!summaryCache_getting[key]) {
    summaryCache_getting[key] = true;
    getSummary(text, model).then(r => {
      summaryCache[key] = r;
    });
  }
}