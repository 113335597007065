import { FormulaObjectWrapper } from "../../FormulaObjectWrapper";
import { db } from "../../db";
import { memoryAppState } from "../../etc/appState";
import { ObjectRefClass, ObjectType } from "../../types/ObjectRef";
import { ValueType } from "../ValueType";
import { evaluate, mapStructure } from "../main";
import { structEvalutors } from "../structRenderers";
import { typeRegistry } from "../typeRegistry.1";
import Sugar from 'sugar';
import { $EventOccurrencesByEvent } from "./$Table";
import { executeEventOccurencesByEvent } from "./executeEventOccurencesByEvent";
import { modeOccurrences } from "../../components/getCurrentOccurrence";
import { getToday } from '../../components/allDays';

const $Time = typeRegistry.registerType({
  $: '8901634f-51ab-5a6d-94f2-a44ab5b07d73',
  Between: {
    $: '63d610a7-fd4a-587c-a069-d088ef796490',
    $$: 'fdc6812c-8f81-5f4f-b439-c4855b0e7de2',
    Start: 'd4f0ebb8-f6c1-5de2-b5b4-1ed6d994c7b4',
    End: 'd3bdf61a-f654-54ed-9089-f8538c143c68',
  }
}, ids => ({
  _id: ids.$,
  name: '[Event Occurrences Query] Time',
  definition: [
    {
      id: ids.Between.$,
      name: 'Between',
      property: 'between',
      type: [ValueType.Structure, ids.Between.$$, [
        {
          id: ids.Between.Start,
          name: 'Start',
          type: [],
          property: 'start',
        },
        {
          id: ids.Between.End,
          name: 'End',
          type: [],
          property: 'end',
        },
      ]],
    }
  ],
}));

const $Argument = typeRegistry.registerType({
  $: 'bd19e0d6-544a-5f29-9182-c2bc35ae167c',
  Value: '825cabe9-b81f-52f1-91f1-7792bf4e5fc2',
}, ids => ({
  _id: ids.$,
  name: '[Event Occurrences Query] Argument',
  definition: [
    {
      id: ids.Value,
      name: 'Value',
      type: [],
      property: 'value',
    }
  ],
}));

const $Todays = typeRegistry.registerType({
  $: '46114282-a8c5-5eb6-b395-574437131ad4',

}, ids => ({
  _id: ids.$,
  name: '[Event Occurrences Query] Today\'s',
  definition: [

  ],
}));

export function doEventOccurrencesQuery(value, map={}) {
  if (value.type[1] == $EventOccurrencesQuery.$) {
    const mapped = mapStructure(value);
    let results = [];
    let i = 0;
    for (const entry of mapped.entries.content || []) {
      if (entry.type[0] == ValueType.Event) {
        if (i == 0) {
          results = db.eventOccurrences.filter(x => x.event == entry.content).map(x => x._id);
        }
        else {
          results = results.filter(x => db.eventOccurrences.findById(x).event == entry.content);
        }
      }
      else if (entry.type[1] == $Todays.$) {
        if (i == 0) {
          const today = getToday();
          const start = today.start.clone();  
          results = modeOccurrences().filter(xx => {
            return xx.timestamp >= start// && xx.timestamp <= end;
          }).map(x => x._id);
        }
      }
      else if (entry.type[1] == $Time.$) {
        const mapped = mapStructure(entry);
        if (mapped.between) {
          const between = evaluate(mapped.between, map);
          let start, end;
          if (between instanceof ObjectRefClass) {
            if (between.type == ObjectType.day) {
              const day = db.days.findById(between.id);
              start = day.start;
              end = day.end;
            }
          }
          else {
            start = Sugar.Date.create(between.start).beginningOfDay();
            end = Sugar.Date.create(between.end).endOfDay();
          }
          results = results.filter(x => {
            const xx = db.eventOccurrences.findById(x);
            return xx.timestamp >= start && (xx.timestamp <= end || !end);
          })
        }
      }
      else if (entry.type[1] == $Argument.$) {
        const mapped = mapStructure(entry);
        const arg = evaluate(mapped.value, map);
        if (i == 0) {
          throw new Error();
        }
        else {
          results = results.filter(x => {
            const xx = db.eventOccurrences.findById(x);
            return xx.arguments == arg;
          })
        }
      }
      ++ i;
    }
    return results.map(id => new ObjectRefClass(ObjectType.eventOccurrence, id));
  }
  else if (value.type[1] == $EventOccurrencesByEvent.$) {
    return executeEventOccurencesByEvent(value);
  }
  else if (value.type[0] == ValueType.Formula) {
    return evaluate(value, map);
  }
}

export const $EventOccurrencesQuery = typeRegistry.registerType({
  $: '84a18724-5d7a-5132-a2b4-1c5b9e5d9d6e',
  Entries: 'abce8edf-b708-533e-91b3-c1b397f9e8f6',
}, ids => ({
  _id: ids.$,
  name: 'Event Occurrences Query',
  definition: [
    {
      id: ids.Entries,
      name: 'Entries',
      type: [ValueType.Array, []],
      property: 'entries',
    }
  ],
}));


structEvalutors[$EventOccurrencesQuery.$] = (value, map) => {
  return doEventOccurrencesQuery(value);
}