import { X } from '../XObject';
import { db } from '../db';

const access = X({})

export function getDataSlot(key) {
  return {
    get: () => {
      // eslint-disable-next-line
      access[key];
      return db.dataSlots.findById(key)?.value;
    },
    set: value => {
      access[key] = value;
      let slot = db.dataSlots.findById(key);
      if (!slot) {
        slot = X({ _id: key });
        db.dataSlots.push(slot);
      }
      slot.value = value;
    }
  };
}
