import React from 'react';
import { XObject, x } from './XObject';
import _styled from 'styled-components';
import _ from 'lodash';
import md5 from 'md5';





export class StillLoading extends Error {
  
}


export class Asdf {

  constructor(func, catchErrors?) {
    this._call = func;
    this.catchErrors = catchErrors;
  }

  observing;
  observingMap;
  __versions;
  catchErrors;

  _call;

  trackObserver(obj, prop, observer, tag) {
    if (!this.observing) this.observing = [];
    if (!this.observing.find(o => o.obj === obj && o.prop === prop)) {
      if (!obj[XObject._removeObserverSymbol]) {
        console.log(obj);
        throw new Error();
      }
      this.observing.push({ obj, prop, observer, tag });
      return true;
    }

  }


  __timerId;
  call(_obj?) {
    // let timerId;
    let render = this._call;

    if (render) {

      let resetObservers;

      const captured = [];


      const r = XObject.captureAccesses(() => {
        if ((this.catchErrors)) {
          try {
            const r = render.call(this, _obj);
            if (r !== false) {
              resetObservers = true;
            }
            if (r === undefined)
              return null;
            return r;
          }
          catch (e) {
            if (e instanceof StillLoading) {
              // console.log(e);
              return <span>Loading...</span>;
            }
            console.log('ERRROR', e);
            return <span>error</span>;
          }
        }
        else {
          try {
            const r = render.call(this, _obj);
            if (r !== false) {
              resetObservers = true;
            }
            if (r === undefined)
              return null;
            return r;
          }
          catch (e) {
            if (e instanceof StillLoading) {
              // console.log(e);
              return <span>Loading...</span>;
            }
            else {
              console.log('ERRROR', e);
              return 'error';
            }
          }
        }
      }, (obj, prop) => {
        captured.push({ obj, prop });

      });

      if (resetObservers) {
        if (this.observing) {
          for (const { obj, prop, observer } of this.observing) {
            XObject.removeObserver(obj, prop, observer);
          }
        }
        this.observing = [];
        this.observingMap = {};
        this.__versions = {};

        const observer = (obj) => {

          clearTimeout(this.__timerId);
          this.__timerId = setTimeout(() => {
            // ++ updateCount;
            this.call(obj);
            // try {
            //   // console.log(this, ReactDOM.findDOMNode(this), args);
            //   // this.__reactiveMeta.timestamp = Date.now();
            //   // this.__reactiveMeta.updateCount++;
            // }
            // catch (e) {
            //   console.log('failed to update', this);
            // }
          }, 100);
        };

        for (const { obj, prop } of captured) {
          const ob = () => observer(obj);
          if (this.trackObserver(obj, prop, ob, 1)) {
            XObject.observe(obj, prop, ob);
          }
        } 
      }


      return r;
    }
    else {
      return null;
    }

  }
}


export function img(name) {
  // if (!_.isString(name)) {
  //   console.log(name);
  //   throw new Error('name not string');
  // }
  // return `url(${require(`@/images/${name}`)})`;
  return null;
}

export function w<T, TT>(a?, b?: T, c?: TT): React.ComponentType<any> & TT {
  return Object.assign(b, c) as any;
}


export function css(strings, ...interpolations) {
  for (let i of interpolations) {
    if (_.isFunction(i) && !(i as any).styledComponentId || _.isArray(i)) {
      return [ ...arguments ];
    }
  }

  let str = '';
  for (let i = 0; i < strings.length; ++i) {
    str += strings[i] + (interpolations[i] === undefined || interpolations[i] === false ? '' : interpolations[i]);
  }
  return str;
}

css.switch = (prop, cases, defaultValue) => {
  return props => { return cases[_.get(props, prop) || defaultValue]; }
}

css.img = img;
css.svg = name => css.img(`${name}.svg`);

css.breakpoint = new Proxy({}, {
  get(__, breakpoint) {
    return st => (props => props.theme.breakpoint == breakpoint && (_.isFunction(st) ? st(props) : st))
  }
}) as {
  desktop: Function;
  mobile: Function;
};


// export const styled = new Proxy(() => {}, {
//   apply(__, thisArg, args) {
//     return _styled(...args);
//   },
//   get(__, tag) {
//     return new Proxy(() => {}, {
//       apply(__, thisArg, args) {
//         return _styled[tag](...args);
//       },
//       get(__, param) {
//         return _styled[tag][param];
//       }
//     })
//   }
// }) as any;

export const styled: any = _styled;

export function aliasStyledComponent(comp, styledComponent) {
  comp.toString = () => styledComponent.toString();
  comp[Symbol.toPrimitive] = () => styledComponent.toString();
  comp.valueOf = () => styledComponent.toString();
  comp.styledComponentId = styledComponent.styledComponentId;

  return comp;
}

function genStyled(tag, styles) {
  return _styled[tag](...(styles || ['']));
}

function makeStyled(constructor, StyledComp) {
  aliasStyledComponent(constructor, StyledComp);
  const { render, ___render } = constructor.prototype;

  if (!render) {
    // if (___render) {
    //   // console.log('helo')
    //   // return <StyledComp {...this.props}>{___render.call(this)}</StyledComp>;
    // }
    // else {
      constructor.prototype.render = function() {
        return <StyledComp {...this.props} />;
      }  
    // }
  }
  else if (render.length == 1 || ___render?.length == 1) {
    if (render != constructor.prototype['__proto__']?.render || !___render) {
      constructor.prototype.___render = render;
      constructor.prototype.render = function() {
        return render.call(this, StyledComp);
      }
    }
    else if (___render) {
      constructor.prototype.render = function() {
        return ___render.call(this, StyledComp);
      }
    }
    else {
      throw new Error();
    }
  }
  else {
    if (render != constructor.prototype['__proto__']?.render || !___render) {
      constructor.prototype.___render = render;
      constructor.prototype.render = function() {
        return <StyledComp {...this.props}>{render.call(this)}</StyledComp>;
      }  
    }
    else if (___render) {
      constructor.prototype.render = function() {
        return <StyledComp {...this.props}>{___render.call(this)}</StyledComp>;
      }
    }
    else {
      throw new Error();
    }
  }
}

function makeReactive(constructor) {
  const { render, componentWillUnmount, componentDidMount } = constructor.prototype;
  let timerId;
  constructor.prototype.trackObserver = function(obj, prop, observer, tag) {
    if (!this.observing) this.observing = [];
    if (!this.observing.find(o => o.obj === obj && o.prop === prop)) {
      if (!obj[XObject._removeObserverSymbol]) {
        console.log(obj);
        throw new Error();
      }
      this.observing.push({ obj, prop, observer, tag });
      return true;
    }
  }

  constructor.prototype._wrapReactive = function(f) {
    return XObject.captureAccesses(() => {
      if (this.catchErrors || this.props.catchErrors) {
        try {
          return f();
        }
        catch (e) {
          if (e instanceof StillLoading) {
            return null;
          }
          console.log(e);
          // Sentry.captureException(e);
          return <span>error</span>;
        }
      }
      else {
        try {
          return f();
        }
        catch (e) {
          if (e instanceof StillLoading) {
            return null;
          }
          else {
            throw e;
          }
        }
      }
    }, (obj, prop) => {
      if (prop == 'constructor') {
        console.log(this, obj, prop);
        throw new Error();
      }
      let observer = () => { if (this.__mounted) {
        if (constructor.delayedUpdate) {
          clearTimeout(timerId);
          timerId = setTimeout(() => {
            console.log('delayed update');
            this.forceUpdate();
          }, constructor.delayedUpdate === true ? 10 : constructor.delayedUpdate);
        }
        else {
          this.forceUpdate();
        }
      }
      }
      if (this.trackObserver(obj, prop, observer, 1)) {
        XObject.observe(obj, prop, observer);
      }
    });
  }

  constructor.prototype.render = function() {
    return this.props.__reactive === false ? render.call(this) : this._wrapReactive(() => render.call(this));
    // return XObject.captureAccesses(() => {
    //   if (this.catchErrors || this.props.catchErrors) {
    //     try {
    //       return render.call(this);
    //     }
    //     catch (e) {
    //       console.log(e);
    //       return <span>error</span>;
    //     }
    //   }
    //   else {
    //     try {
    //       return render?.call?.(this);
    //     }
    //     catch (e) {
    //       if (e instanceof StillLoading) {
    //         return null;
    //       }
    //       else {
    //         throw e;
    //       }
    //     }
    //   }
    // }, (obj, prop) => {
    //   if (prop == 'constructor') {
    //     console.log(this, obj, prop);
    //     throw new Error();
    //   }
    //   let observer = () => { if (this.__mounted) {
    //     if (constructor.delayedUpdate) {
    //       clearTimeout(timerId);
    //       timerId = setTimeout(() => {
    //         console.log('delayed update');
    //         this.forceUpdate();
    //       }, 10);
    //     }
    //     else {
    //       this.forceUpdate();
    //     }
    //   }
    //   }
    //   if (this.trackObserver(obj, prop, observer, 1)) {
    //     XObject.observe(obj, prop, observer);
    //   }
    // });
  }

  constructor.prototype.componentDidMount = function() {
    this.__mounted = true;
    if (componentDidMount) componentDidMount.call(this);
  }

  constructor.prototype.componentWillUnmount = function() {
    this.__mounted = false;
    if (this.observing) {
      for (let { obj, prop, observer, tag } of this.observing) {
        if (!XObject.removeObserver(obj, prop, observer)) {
          console.log('failed to remove', x(obj), prop, observer, tag);
        }
      }
      delete this.observing;
    }

    if (componentWillUnmount) componentWillUnmount.call(this);
  }
}


export function generateClassName(name='') {
  return name + '_' + md5(Math.random().toString());
}

function generateClassNames(c) {
  const generated = {};
  for (const name in c) {
    if (c[name] == '') generated[name] = generateClassName(name);
    else if (_.isPlainObject(c[name])) {
      generated[name] = Object.assign(generateClassName(name), generateClassNames(c[name]))
    }
    else generated[name] = c[name];
  }
  return generated;
}


export const component = new Proxy(() => {}, {
  apply(target, thisArg, args) {
    if (args[0] instanceof Function) {
      const [ constructor ] = args;
      // constructor.error = new Error();
      if (constructor.c) {
        constructor.c = generateClassNames(constructor.c);
      }
      if (constructor.styles) {
        if (_.isFunction(constructor.styles)) {
          constructor.styles = constructor.styles(constructor.classes);
        }
        makeStyled(constructor, constructor.styles);
      }
      if (constructor.reactive !== false) {

        makeReactive(constructor);
      }
      return constructor;
    }
    else {
      let [ styles ] = args;
      return constructor => {
        if (styles.styledComponentId) {
          makeStyled(constructor, styles);
        }
        else {
          makeStyled(constructor, genStyled('div', styles));
        }  

        makeReactive(constructor);
      }
    }
  },
  get(target, tag) {
    return styles => {
      return constructor => {
        makeStyled(constructor, genStyled(tag, styles));
        makeReactive(constructor);
      }
    }
  }
}) as (<T>(constructor: T) => T);

export function scoped<T>(gen: () => T): T {
  return gen();
}
