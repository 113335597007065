import _ from 'lodash';
import React, { Component } from 'react';
import styled from 'styled-components';
import { component } from '../component2';
import { x, XObject } from '../XObject';
import { SortableCont, SortableEl, showContextMenu } from '../helpers';
import { Svg } from './Svg';
import classNames from 'classnames';
import { SystemContext, SystemContextProps } from '../etc/SystemContext';
import { cssImg } from '../img';
import { getSidebar } from '../etc/getSidebar';
import { SidebarItemType } from '../types/SidebarItemType';
import { ObjectType } from '../types/ObjectRef';
import { color } from "../color";
import { isMobile } from '../isMobile';
import { Tag } from './Tag';
import { WithContextAction } from './WithContextAction';


@component
class SidebarItemComp extends Component<{ item: SidebarItem; indent; state; contextMenu?; ignoreChildren?: boolean }> {
  static styles = styled.div`
    user-select: none;
    &.expanded > .item > .wrapper > .toggle {
      transform: rotate(0deg);
    }
    > .item {
      cursor: pointer;
      font-size: 13px;
      margin: 0 8px;
      margin-bottom: 2px;
      padding: 2px 3px 2px 3px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      color: #808080;
      font-weight: 500;

      &:hover {
        background: rgba(0, 0, 0, 0.04);
      }
      &.active {
        background: #eceff9;
      }

      > .wrapper {
        position: relative;
        padding-left: 36px;
        display: flex;
        max-width: 100%;
        width: 100%;
        box-sizing: border-box;
        align-items: center;

        > .text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          flex: 1 1 auto;
          margin-right: 4px;
        }

        > .toggle {
          position: absolute;
          left: 0px;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 12px;
          height: 12px;
          transform: rotate(-90deg);
          fill: #bababa;
          padding: 2px;
          border-radius: 2px;

          &:hover {
            background: #8b8b8b14;
          }
        }
        > .icon {
          width: 13px;
          height: 13px;
          background: no-repeat center;
          background-size: contain;
          position: absolute;
          left: 19px;
          top: 0;
          bottom: 0;
          margin: auto;

        }

        ${Tag} {
          flex: 0 0 auto;
          margin-left: auto;
        }
      }




    }

    &.ignoreChildren > .item > .wrapper {
      > .icon {
        left: 8px;
      }
      padding-left: calc(8px + 13px + 4px);
    }

    > .children {
      margin-left: 0 !important;
      margin-top: 0 !important;

    }
  `;
  static contextType = SystemContext;
  context: SystemContextProps;
  render(Container?) {
    try {
      const { item } = this.props;
      const color = item.color();
      const children = item.getChildren();
      const state = XObject.get(this.props.state, item.getId(), {});
      const badge = item.getBadge();

      let items = item.contextMenu();
      if (this.props.contextMenu) {
        if (!items)
          items = [];
        items = items.concat(_.isFunction(this.props.contextMenu) ? this.props.contextMenu() : this.props.contextMenu);
      }


      return (
        <Container indent={this.props.indent} 
        

        className={classNames({
          expanded: state.expanded,
          ignoreChildren: this.props.ignoreChildren,
        })}>

          <WithContextAction
            menu={items}
          >
          <div className={classNames('item', {
              active: item.clickAction == 'navigate' && _.isMatch(x(this.context.next()), item.getNav()),
            })}
            onClick={e => {
              item.onClick(e);
              if (this.props.item.clickAction == 'navigate') {
                this.context?.navigate?.(item.getNav());
              }
            }}
            onContextMenu={e => {
              e.preventDefault();
            }}
          >
            <span className="wrapper"
              style={{
                marginLeft: this.props.indent * 12,
              }}
            >
              {!this.props.ignoreChildren && children.length > 0 && (
                <Svg
                  name="chevron"
                  className="toggle"
                  onClick={e => {
                    e.stopPropagation();
                    state.expanded = !state.expanded;
                  }}
                />
              )}
              <span className="icon"
                style={{
                  backgroundImage: item.getIcon(),
                }}
              />
              <span className="text"
                      style={{
                        color: color,
                      }}
              >
                {item.getTitle() || '---'}
              </span>
              {badge && <Tag text={badge} />}
            </span>
          </div>
          </WithContextAction>
          {!this.props.ignoreChildren && state.expanded && children.length > 0 && (
            <div className="children">
              {children.filter(child => !child.isDeleted()).map(child => (
                <SidebarItemComp key={child.getId()} item={child} indent={this.props.indent + 1} state={this.props.state} />
              ))}
            </div>
          )}
        </Container>
      );

    }

    catch (e) {
      console.log(e);

      return (
        <Container indent={this.props.indent} className={classNames({
          expanded: false,
        })}>
          <div
            onContextMenu={e => {
              e.preventDefault();
              let items = [];
              if (this.props.contextMenu) {
                if (!items)
                  items = [];
                items = items.concat(this.props.contextMenu);
              }
              if (items.length) {
                showContextMenu(e, items);
              }
            }}

            className={classNames('item', {
              // active: _.isMatch(x(this.context.next()), item.getNav()),
            })}>
            <span className="wrapper" style={{
              marginLeft: this.props.indent * 12,
            }}>
              Error
            </span>
          </div>

        </Container>


      );
    }

  }
}

@component
export class Sidebar extends Component<{ ignoreChildren?; rootItem: SidebarItem; state; onRemove? }> {
  static styles = styled.div``;
  render(Container?) {

    return (
      <>
        <Container
          onDragOver={e => {
            e.preventDefault();
          }}
          onDrop={e => {
            e.preventDefault();
            const droppedEntity = JSON.parse(e.dataTransfer.getData('text/plain'));

            if (droppedEntity.type == ObjectType.query) {
              getSidebar().children.push(XObject.obj({
                type: SidebarItemType.query,
                id: droppedEntity.id,
              }));
            }
            else if (droppedEntity.type == ObjectType.space) {
              getSidebar().children.push(XObject.obj({
                type: SidebarItemType.space,
                space: droppedEntity.id,
              }));
            }
            else if (droppedEntity.type == ObjectType.document) {
              getSidebar().children.push(XObject.obj({
                type: SidebarItemType.document,
                id: droppedEntity.id,
              }));
            }
            else if (droppedEntity.type == ObjectType.canvas) {
              getSidebar().children.push(XObject.obj({
                type: SidebarItemType.canvas,
                id: droppedEntity.id,
              }));
            }
          }}
        >
          <SortableCont
            tag="div"
            distance={isMobile() ? undefined : 20}
            pressDelay={isMobile() ? 200 : undefined}
            onSortEnd={(sortEnd, sortEvent) => {
              console.log(sortEnd, sortEvent);
              this.props.rootItem.moveChild(sortEnd.oldIndex, sortEnd.newIndex);
            }}
          >
            {this.props.rootItem.getChildren().map((item, i) => {
              return (
                !item.isDeleted() && <SortableEl index={i} key={item.getId() + i}>
                  <SidebarItemComp
                    ignoreChildren={this.props.ignoreChildren}
                    key={item.getId() + i}
                    item={item}
                    indent={0}
                    state={this.props.state}
                    contextMenu={[
                      {
                        text: 'Remove from sidebar',
                        onClick: () => {
                          this.props.rootItem.deleteItem(i);
                        }
                      },
                    ]} />
                </SortableEl>
              )
            })}

          </SortableCont>
        </Container>
      </>
    );
  }
}

export class SidebarItem {
  clickAction: 'handleClick' | 'navigate' = 'navigate';
  ignoreChildren() {
    return false;
  }
  constructor(public data) { }
  getChildren(): SidebarItem[] {
    return [];
  }

  color(): any {

  }


  isDeleted() {
    return false;
  }

  moveChild(oldIndex, newIndex) {
  }

  getBadge() {
    return null;
  }
  onClick(e?) { }

  getIcon() {
    return null;
  }

  getId(): string {
    return null;
  }

  getTitle(): string {
    return null;
  }

  getNav(): any {
  }

  deleteItem(i) {
  }

  contextMenu(): any {
    return [];
  }



}

