


export class CComponent {
  constructor(public obj) { }
}
export class Node {
  constructor(public obj) { }
}
export class StyleNode {
  constructor(public obj) { }
}
export class Block {
  constructor(public obj, public prop) { }
}
export class StyleNodeProperty {
  constructor(public property) { }
}
