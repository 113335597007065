import React, { Component } from 'react';
import { db } from '../db';
import { component } from '../component2';
import { XObject, x } from '../XObject';
import { objectName } from "../components/objectFuncs";
import { ObjectType } from '../types/ObjectRef';
import { componentSystem } from '../componentSystem';
import { appState, setAppInspect } from '../etc/appState';
import { InspectState } from '../components/InspectState';
import { ObjectPicker } from '../components/ObjectPicker';
import { ObjectField } from '../components/ObjectField';
import { createRootValuePoint } from '../glue/main';
import { NavItem } from '../components/QueryViewEditor';
import { TypeEditor } from '../components/TypeEditor';


@component
export class EditDocumentWindow extends Component<{ window; }> {
  render() {
    const page = db.notionDocuments.findById(this.props.window.document);
    return (
      <>
        <div>
          <input type="checkbox"
            checked={page.createsEdges}
            onChange={e => {
              page.createsEdges = e.target.checked;
            }}
            /> Graph
        </div>
        <div>
          <input type="checkbox"
            checked={page.scopedEntities}
            onChange={e => {
              page.scopedEntities = e.target.checked;
            }}
            /> Scoped Entities
        </div> 

        <div>
          <input type="checkbox"
            checked={page.transientEntities}
            onChange={e => {
              page.transientEntities = e.target.checked;
            }}
            /> Transient Entities
        </div> 

        <div>
          <TypeEditor
            onChange={e => {
              // console.log(e);
              page.transientEntityType = e; 
            }}
            value={page.transientEntityType}
            scopeObj={{
              type: ObjectType.document,
              id: page._id,
            }}
          />
        </div>


        <b>Highlighter</b>
        {!page.highlighter && (
              <NavItem
                label="Init"
                onClick={() => {
                  const vp = createRootValuePoint();
                  page.highlighter = vp._id;
                  setAppInspect({
                    mode: InspectState.valuePoint,
                    id: page.highlighter,
                  });
                }}
              />
            )}

            {page.highlighter && (
              <NavItem
                label="Edit"
                onClick={() => {
                  setAppInspect({
                    mode: InspectState.valuePoint,
                    id: page.highlighter,
                  });
                }}
              />
            )}

      <b>Nav Handler</b>
        {!page.navHandler && (
              <NavItem
                label="Init"
                onClick={() => {
                  const vp = createRootValuePoint();
                  page.navHandler = vp._id;
                  setAppInspect({
                    mode: InspectState.valuePoint,
                    id: page.navHandler,
                  });
                }}
              />
            )}

            {page.navHandler && (
              <NavItem
                label="Edit"
                onClick={() => {
                  setAppInspect({
                    mode: InspectState.valuePoint,
                    id: page.navHandler,
                  });
                }}
              />
            )}

        
        {/* <ul>
          {page?.blockCodeComponents?.map?.((comp, i) => {
            return (
              <li key={comp}
                onClick={() => {
                  console.log(x(db.codeComponents.findById(comp)))
                  // setAppInspect({
                  //   mode: InspectState.code,
                  //   component: x
                  // });
                }}
              >
                {db.codeComponents.findById(comp)?.name}
              </li>
            )
          })}
        </ul>
        <button
          onClick={() => {
            const codeComponent = componentSystem.createComponent();
            XObject.push(page, 'blockCodeComponents', codeComponent._id);
          }}
        >+</button> */}

<ul>
          {page?.blockValuePoints?.map?.((comp, i) => {
            return (
              <li key={comp}
                onClick={() => {
                  setAppInspect({
                    mode: InspectState.valuePoint,
                    id: comp,
                  });
                }}
              >
                {objectName({ type: ObjectType.valuePoint, id: comp })}
              </li>
            )
          })}
        </ul>
        <button
          onClick={() => {
            const codeComponent = createRootValuePoint();
            XObject.push(page, 'blockValuePoints', codeComponent._id);
          }}
        >+</button>
        <ul>
          {page.imports?.map?.((x, i) => {
            return (
              <li key={x._id}>
                {objectName(x)} <button
                  onClick={() => {
                    page.imports.splice(i, 1);
                  }}
                >-</button>
              </li>
            );
          })}
        </ul>
        <select
          onChange={e => {
            XObject.push(page, 'imports', XObject.obj({
              type: ObjectType.library,
              id: e.target.value,
            }));
            e.target.value = '';
          }}
        >
          <option />
          {db.libraries.map(library => {
            return (
              <option key={library._id} value={library._id}>{library.name}</option>
            );
          }
          )}
        </select>
        {page.tableData && (() => {
          const columns = page.tableData.columns;

          return (
            <>
            <button
              onClick={() => {
                delete page.tableData;
              }}
            >Make page</button>
            
            <select
              value={page.tableData.galleryProperty}
              onChange={e => page.tableData.galleryProperty = e.target.value}
            >
              <option />
              {columns?.map?.(column => (
                <option key={column._id} value={column._id}>{column.title}</option>
              ))}
            </select>
    
            </>
          )

        })()}

        <h3>Context Variables</h3>
        <ul>
          {page.contextVariables?.map?.(cv => {
            return (
              <li key={cv._id}>
                <ObjectField value={cv.identifier} setValue={v => cv.identifier = v} />: <ObjectField value={cv.value} setValue={v => cv.value = v} />
              </li>
            );
          })}
          <li><button
            onClick={() => {
              XObject.push(page, 'contextVariables', XObject.obj());
            }}
          >+</button></li>
        </ul>
      </>
    );
  }
}
