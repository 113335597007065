import { Component } from 'react';
import { component, styled } from '../component2';
import _ from 'lodash';
import { X, x, XObject } from '../XObject';
import { defaultWorkspace } from '../etc/defaultWorkspace';
import { Root } from './Root';
import { EntityDisplay } from './EntityDisplay';
import { SortableCont, SortableEl } from '../helpers';
import { resolvePath } from '../etc/queryGraph';
import { db } from '../db';
import { appState } from '../etc/appState';
import { getEntityById } from '../etc/createEntity';



@component
export class QueueWindow extends Component<{ window; }> {
  static styles = styled.div`
    .path {
      font-size: 9px;
      color: gray;
    }
  `;
  render() {
    const workspace = defaultWorkspace();
    const queue = workspace.queues?.find?.((x) => x._id === this.props.window.queue);
    return (
      <>
        <Root
          root={{
            children: () => {
              return queue.list;
            },
            render: (args) => {

              return (
                <SortableCont
                // useDragHandle
                tag={'ul'}
                distance={10}
                onSortEnd={(sortEnd, sortEvent) => {
                  const list = _.clone(x(queue.list));
                  const id = list[sortEnd.oldIndex];
                  list.splice(sortEnd.oldIndex, 1);
                  list.splice(sortEnd.newIndex, 0, id);
                  queue.list = X(list);
      
                  // console.log(sortEnd, sortEvent);
                }}
              >
      
                  {queue.list.map((id, i) => {
                    const path = (() => {
                        const g = resolvePath('63e969838f513756f28239f7', queue.entity, id);
                        return g.slice(1);
    
                    })();
                    
                    return (
                      <SortableEl index={i} key={id}>
                      <li 

                        data-value={id}
                        onClick={() => {
                          args.onInspect(id);
                        }}>
                        <EntityDisplay id={id} root={queue.entity} /> <button
                          onClick={() => {
                            // args.onSelectChild(id);
                            appState.inspecting = {
                              type: 'entity',
                              id,
                            }

                          }}
                        >&gt;</button>
                        <div className="path">{path && path.map(id => getEntityById(id).name).join(' / ')}</div>

                        {/* {getEntityById(id)?.name} <span
                                              style={{
                                                fontSize: '9px',
                                                color: 'gray',
                                              }}
                                            >{renderAttributes(getEntityById(id))}</span>
                                             */}
                      </li>
                      </SortableEl>
                    );
                  })}
                </SortableCont>
              );
            },
            // widt:h: ;
          }}
          drillDownState={XObject.get(queue, 'drillDownState', {})}
          graph={{
            graph: '63e969838f513756f28239f7',
            root: queue.entity,
          }} />
      </>
    );
  }
}
