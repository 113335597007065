import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Component, useContext } from "react";
import { Selector } from "../components/Selector";
import { entityDisplayName } from "../components/entityDisplayName";
import { El } from "../shorthand/El";
import { renderEl, transformAttrs } from "../shorthand/formula";
import { ObjectRefClass, ObjectType } from "../types/ObjectRef";
import { registerScriptComponent } from "./registerScriptComponent";
import { SystemContext } from "../etc/SystemContext";
import { db } from "../db";
import { component } from "../@component";
import { getEmbeddings } from '../getEmbeddings';
import { NodeHtmlMarkdown, NodeHtmlMarkdownOptions } from 'node-html-markdown'
import * as ml from 'ml-distance';
import { styled } from '../component';
import stringSimilarity from 'string-similarity';
import { convert } from 'html-to-text';
import { showContextMenu } from '../helpers';
import { getEntityById } from '../etc/createEntity';
import _, { findIndex } from 'lodash';
import { NotionDatabase } from '../components/notionDatabase/NotionDatabase';
import { MyColManager, MyRowManager } from '../glue/structs/$Table';
import juration from '../juration';


const { RegExpMarker } = require('react-mark.js');


registerScriptComponent('72d4ede6-93cb-59bd-8f0d-0cdedaec7c30', {
  name: 'LinearSidebar',
  render(el, rt) {
    const newEl = new El('div', el.attributes, el.children);
    return renderEl(newEl, rt);

  },
  childComponents: {
    item: {
      render(el) {
      },
    }
  },
});


registerScriptComponent('50ca67a2-21dc-5abc-b552-34ceb0405eff', {
  name: 'EntitySearchInput',
  render(el, rt) {
    const attributes = transformAttrs(el.attributes);
    return (
      <>
        {/* <input type="text" /> */}
        <Selector
          entries={attributes.entities.map(e => {
            return {
              key: e.id,
              display: entityDisplayName(e.id),
            }
          })}
          onSelected={(value, clear) => {
            console.log(value);
            attributes.select?.(new ObjectRefClass(ObjectType.entity, value));
            clear();

          }}
        />
        {/* <button
          onClick={() => {
            console.log(el, rt, transformAttrs(el.attributes));
          }}
        >.</button> */}
      </>
    );
    // const newEl = new El('div', el.attributes, el.children);
    // return renderEl(newEl, rt);

  },
  childComponents: {
    item: {
      render(el) {
      },
    }
  },
});

export function findRefs(text, entities: string[]) {
  const phrases:{phrase, entity}[] = [];
  for (const id of entities) {
    const entity = getEntityById(id);
    phrases.push({
      phrase: entityDisplayName(id).trim(),
      entity,
    })

    if (entity.attributes?.['655589ac344447c5e6445724']) {
      for (const name of entity.attributes?.['655589ac344447c5e6445724']) {
        phrases.push({
          phrase: name,
          entity,
        })
      }
    }
  }

  const parts = text.split(/\s+/);

  const map = {};

  for (const phrase of phrases) {
    const words = phrase.phrase.split(' ');
    chunkLoop: for (let i = 0; i < parts.length; ++ i) {
      const chunk = parts.slice(i, i + words.length).filter(Boolean);
      
      if (chunk.length == words.length) {
        for (let j = 0; j < chunk.length; ++ j) {
          const word = chunk[j];
          if (j != 0 && !word[0].match(/[a-zA-Z0-9]/)) {
            continue chunkLoop;
          }
          if (j != chunk.length - 1 && !word[word.length - 1].match(/[a-zA-Z0-9]/)) {
            continue chunkLoop;
          }
        }

        const a = chunk.map(x => x.replace(/[^a-zA-Z0-9']/g, '')).join(' ')

        const s = stringSimilarity.compareTwoStrings(phrase.phrase.toLowerCase(), a.toLowerCase());
        if (map[a]) {
          if (map[a][0] < s) {
            map[a] = [s, phrase];
          }
        }
        else if (s >= .8) {
          map[a] = [s, phrase];
        }
      }
    }
  }

  const entityReferences: {reference:string, id:string}[] = [];
  for (const r in map) {
    entityReferences.push({
      reference: r,
      id: map[r][1].entity._id,
    })
  }

  return entityReferences;
}

@component
class EntityReferencer extends Component<{ entity?, className, entities, attribute?, onClickRef, html?, children? }> {
  entityReferences: {
    reference
    id
  }[]
  topic
  entityLinks = []
  static styles = styled.div`
    .links {
      > span {
        margin-right: 8px;
        color: blue;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  `;
  static contextType = SystemContext;

  async update() {
    const el = ReactDOM.findDOMNode(this.ref.current) as Element;
    if (!el.textContent) return;
    const phrases:{phrase, entity}[] = [];
    for (const entity of this.props.entities) {
      phrases.push({
        phrase: entityDisplayName(entity._id).trim(),
        entity,
      })

      if (entity.attributes?.['655589ac344447c5e6445724']) {
        for (const name of entity.attributes?.['655589ac344447c5e6445724']) {
          phrases.push({
            phrase: name,
            entity,
          })
        }
      }
    }

    const parts = convert(el.innerHTML).split(/\s+/);

    const map = {};

    for (const phrase of phrases) {
      const words = phrase.phrase.split(' ');
      chunkLoop: for (let i = 0; i < parts.length; ++ i) {
        const chunk = parts.slice(i, i + words.length);
        if (chunk.length == words.length) {
          for (let j = 0; j < chunk.length; ++ j) {
            const word = chunk[j];
            if (j != 0 && !word[0].match(/[a-zA-Z0-9]/)) {
              continue chunkLoop;
            }
            if (j != chunk.length - 1 && !word[word.length - 1].match(/[a-zA-Z0-9]/)) {
              continue chunkLoop;
            }
          }

          const a = chunk.map(x => x.replace(/[^a-zA-Z0-9']/g, '')).join(' ')

          const s = stringSimilarity.compareTwoStrings(phrase.phrase.toLowerCase(), a.toLowerCase());
          if (map[a]) {
            if (map[a][0] < s) {
              map[a] = [s, phrase];
            }
          }
          else if (s >= .8) {
            map[a] = [s, phrase];
          }
        }
      }
    }

    this.entityReferences = [];
    for (const r in map) {
      this.entityReferences.push({
        reference: r,
        id: map[r][1].entity._id,
      })
    }
    
    const hasText = this.props.entities.filter(e => (!this.props.entity || this.props.entity._id != e._id) && e.attributes?.[this.props.attribute]);

    const entityText = hasText.map(e => NodeHtmlMarkdown.translate(e.attributes?.[this.props.attribute]));
    const model = undefined;

    const [thisEmbedding, ...entityEmbeddings] = await getEmbeddings([el.textContent, ...entityText], model);

    const comp = [];
    for (let i = 0; i < hasText.length; ++ i) {
      comp.push([entityDisplayName(hasText[i]._id), hasText[i]._id, ml.similarity.cosine(thisEmbedding, entityEmbeddings[i])]);
    }




    comp.sort((a, b) => b[2] - a[2])
    // console.log(comp);

    this.entityLinks = [];
    for (const el of comp) {
      this.entityLinks.push({
        entity: el[1],
        match: el[2],
      })
    }

    const topics = [
      'Biology', 
      'Law',
      'Science',
      'Chemistry',
      'Geography',
      'People',
      'Religion',
    ]

    const topicEmbeddings = await getEmbeddings(topics.map(t => `The topic of ${t}`), model);

    const topicMatches = [];
    for (let i = 0; i < topics.length; ++ i) {
      topicMatches.push([topics[i], ml.similarity.cosine(thisEmbedding, topicEmbeddings[i])]);
    }
    topicMatches.sort((a, b) => b[1] - a[1])

    // console.log(topicMatches);

    this.topic = topicMatches[0][0];
    this.forceUpdate();
  }

  componentDidMount(): void {
    this.update();
  }

  ref= React.createRef<any>();

  render() {
    const { entities, html, onClickRef, className } = this.props;
    const ref = this.ref;
    const c = <>
              {html && <div ref={ref} dangerouslySetInnerHTML={{__html:html}} />}
          {!html && <div ref={ref}>{this.props.children}</div>}
        </>

    return (
      <>
      <div className={className}
        onContextMenu={e => {
          e.preventDefault();
          showContextMenu(e, [
            { text: 'Update', onClick: () => this.update() },
          ])
        }}
      >
      {/* <button
          onClick={async () => {

          }}
        >Test</button> */}
      {/* {this.topic && <div><b>{this.topic}</b></div>} */}

        {this.entityReferences && (

                <RegExpMarker
          // mark={new RegExp(`\\b(${entities.map(e => {
          //   return entityDisplayName(e._id)
          // }).join('|')})\\b`, 'i')}

          mark={new RegExp(`\\b(${
            this.entityReferences.map(r => r.reference).join('|')
          })\\b`, 'i')}


          options={{
            each: el => {
              const thisRef = el.textContent.toLowerCase();

              const ref = this.entityReferences.find(r => {
                return r.reference.toLowerCase() == thisRef;
              });


              if (!ref || ref.id != this.props.entity?._id) {
                el.style.color = 'rgb(126, 126, 216)';
                el.style.cursor = 'pointer';
                el.onclick = () => {
                  // const thisRef = el.textContent.toLowerCase();
                  // const ref = this.entityReferences.find(r => {
                  //   return r.reference.toLowerCase() == thisRef;
                  // });
                  if (ref) {
                    const entity = getEntityById(ref.id);
                    onClickRef?.(new ObjectRefClass(ObjectType.entity, entity._id));
                  }
                }
              }

            },
            element: 'span',
          }}
        >
          {c}  
        </RegExpMarker>
        )}
        {!this.entityReferences && c}


        {/* {this.entityLinks.length > 0 && <div className="links">
        {this.entityLinks.map(e => <span className="link"
          onClick={() => {
            onClickRef?.(new ObjectRefClass(ObjectType.entity, e.entity));
          }}
        >{entityDisplayName(e.entity)} ({e.match.toFixed(3)})</span>)}
        </div>} */}


        </div>
      </>
    )
  }
}

registerScriptComponent('d811195b-1f27-56ba-bdbe-e4a62a9a5a66', {
  name: 'EntityReferencer',
  render(el, rt) {
    const attributes = transformAttrs(el.attributes);
    // return <button
    //   onClick={() => {
    //     console.log(attributes);
    //   }}
    // >.</button>
    const ctx = useContext(SystemContext);
    const entities = attributes?.entities?.map?.(x => {
      return getEntityById(x.id);
    });
    if (!entities) return null;
    return <EntityReferencer
      entities={entities}
      entity={attributes.entity && getEntityById(attributes.entity.id)}
      onClickRef={attributes.onClick}
      className={attributes.className}
      html={attributes.dangerouslySetInnerHTML?.__html || 'EMPTY'}
      attribute={attributes.attribute?.ref?.id}
    >
      {!attributes.dangerouslySetInnerHTML && renderEl(el.children, rt)}
    </EntityReferencer>
    return (
      <>
        <RegExpMarker
          mark={new RegExp(`\\b(${entities.map(e => {
            return entityDisplayName(e._id)
          }).join('|')})\\b`, 'i')}
          options={{
            each: el => {
              el.style.color = 'blue';
              el.style.cursor = 'pointer';
              el.onclick = () => {
                const entity = entities.find(e => el.textContent.toLowerCase() == entityDisplayName(e._id).toLowerCase())
                console.log(el.textContent, entity);
                // ctx.navigate({});
                if (entity) {
                  attributes.onClick?.(new ObjectRefClass(ObjectType.entity, entity._id));
                }
              }
            },
            element: 'span',
          }}
        >
          {attributes.dangerouslySetInnerHTML && <div className={attributes.className} dangerouslySetInnerHTML={attributes.dangerouslySetInnerHTML} />}
          {!attributes.dangerouslySetInnerHTML && renderEl(el.children, rt)}
          
        </RegExpMarker>
        {/* <button
          onClick={() => {
            console.log(el, rt);
          }}
        >.</button> */}
      </>
    );
    // const newEl = new El('div', el.attributes, el.children);
    // return renderEl(newEl, rt);

  },
  childComponents: {
    item: {
      render(el) {
      },
    }
  },
});

function resolveEls(array) {
  const r = [];
  for (const el of array) {
    if (_.isArray(el)) {
      r.push(...el);
    }
    else {
      r.push(el);
    }
  }
  return r;
}


registerScriptComponent('fa5f887b-79d7-5a64-9fb3-6a25be7ca67a', {
  name: 'Table Comp',
  render(el, rt) {
    const context = useContext(SystemContext);
    const columns = el.children.find(el => el.tag == 'columns').children.map(el => {
      const attrs = transformAttrs(el.attributes);
      return attrs;
    })
    const rows = resolveEls(el.children.find(el => el.tag == 'rows').children).map(el => {
      const attrs = transformAttrs(el.attributes);
      return attrs;
    }).filter(row => !_.isEmpty(row));

    const columnDefs = columns.map((col, i) => ({
      key: col.name,
      title: col.title,
      width: col.width,
      showOpenButton: col.open,
      getter: (row) => {
        const value = row[i];
        if (col.type == 'event') {
          return db.events.findById(value)?.name || '(null)';
        }
        else if (col.type == 'duration') {
          return juration.stringify(value);
        }
        else if (col.type == 'text') {
          if (React.isValidElement(value) || _.isString(value) || _.isNumber(value) || _.isNil(value)) {
            return value;
          }
          else {
            return   <button
            onClick={() => {
              console.log(value);
            }}
          >.</button>
          }
        }
        else if (col.type == 'entity') {
          return <span className="link2"
            onClick={() => {
              // context.navigate({
              //   type: 'entity',
              //   id: value
              // })
            }}
          >{entityDisplayName(value)}</span>
        }
      },
      setter: () => {},
    }))

    const colIndexes = {};
    for (let i = 0; i < columns.length; ++ i) {
      colIndexes[columns[i].name] = i;
    }

    const rowDefs = rows.map(row => {
      const data = [];
      for (const name in row.columns) {
        data[colIndexes[name]] = row.columns[name];
      }
      return data;
    })


    let active;

    if (context?.next?.()?.id) {
      const openCol = columns.find(c => c.open);
      const next = context?.next?.()?.id;
      if (openCol) {
        active = rows.findIndex(row => {
          return row.columns?.[openCol.name] == next;
        })  
      }
    }
    return (
      <NotionDatabase
        activeRowId={active}
        columnManager={new MyColManager(columnDefs)}
        rowManager={new MyRowManager(rowDefs, columnDefs)}
        onClickRow={row => {
          const openCol = columns.find(c => c.open);

          // console.log()
          context.navigate({
            type: 'entity',
            id: rows[row].columns[openCol.name],
          })
        }}
      />
    )

    // return <button
    //   onClick={()=> {
    //     console.log(rowDefs);
    //   }}
    // >.</button>
  },
  childComponents: {

  }
})