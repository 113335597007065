import ReactDOM from 'react-dom';
import _ from 'lodash';
import jQuery from 'jquery';
import { showContextMenu } from '../helpers';
import { Component } from 'react';
import { component } from '../component';
import { isMobile } from '../isMobile';


@component
export class WithContextAction extends Component<{
  contextPosition?
  mobileInvoke?: 'longPress' | 'press';
  menu?;
  children?;
}> {

  listeners = [];
  addEventListener(el, event, listener) {
    el.addEventListener(event, listener);
    this.listeners.push({ el, event, listener });
  }

  listen() {
    for (const { el, event, listener } of this.listeners) {
      el.removeEventListener(event, listener);
    }
    this.listeners = [];

    let menu;
    if (_.isFunction(this.props.menu)) {
      menu = this.props.menu();
    }
    else {
      menu = this.props.menu;
    }

    const el = ReactDOM.findDOMNode(this) as HTMLElement;
    if (isMobile()) {
      let timerId;

      if (!this.props.mobileInvoke || this.props.mobileInvoke == 'longPress') {
        this.addEventListener(el, 'touchstart', (e) => {
          const listener = () => {
            clearTimeout(timerId);
            jQuery(window).unbind('touchmove', listener);
          }

          jQuery(window).bind('touchmove', listener);
          const pos = {
            clientX: e.touches[0].clientX,
            clientY: e.touches[0].clientY,
          };
          timerId = setTimeout(() => {
            console.log('hello');
            if (menu) {
              showContextMenu(pos, _.clone(menu), this.props.contextPosition);
            }
          }, 500);
        });

        this.addEventListener(el, 'contextmenu', (e) => {
          e.preventDefault();
          e.stopPropagation(); // not necessary in my case, could leave in case stopImmediateProp isn't available? 
          e.stopImmediatePropagation();
      
        });

        this.addEventListener(el, 'touchend', (e) => {
          clearTimeout(timerId);
          console.log('touchend');
        });

      }
      else if (this.props.mobileInvoke == 'press') {

        this.addEventListener(el, 'click', (e) => {
          const pos = {
            clientX: e.clientX,
            clientY: e.clientY,
          };
          if (this.props.menu) {
            console.log('test');
            showContextMenu(pos, _.clone(this.props.menu), this.props.contextPosition);
          }
        });
      }

    }
    else {
      this.addEventListener(el, 'contextmenu', (e) => {
        e.preventDefault();
        // console.log('menu', this.props.menu, _.clone(this.props.menu));
        showContextMenu(e, _.clone(menu), this.props.contextPosition);
      });
    }

  }

  componentDidMount() {
    this.listen();
  }

  componentDidUpdate(prevProps: Readonly<{ menu?: any; children?: any; }>, prevState: Readonly<{}>, snapshot?: any): void {
    this.listen();
  }
  render() {
    return this.props.children;
  }
}
