import React, { Component } from 'react';
import styled from 'styled-components';
import { component } from '../component2';
import { Svg } from './Svg';
import { SystemContext, SystemContextProps } from '../etc/SystemContext';
import { PaneType } from '../types/PaneType';
import Sugar from 'sugar';
import { createDay, getToday } from './allDays';


@component
export class CurrentDay extends Component {
  static styles = styled.div`
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    font-size: 14px;
    cursor: pointer;
    svg {
      width: 18px;
      height: 18px;
      margin-right: 4px;
    }

    .newDay {
      margin-left: auto;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      border-radius: 4px;
      height: 24px;
      padding: 0px 6px;
      background: #f2f2f2;


      font-size: 8px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      color: #bcbcbc;


      background: #f2f2f2;
      cursor: pointer;
    }
  `;

  static contextType = SystemContext;
  context: SystemContextProps;

  render(Container?) {
    const today = getToday();
    return (
      <Container
        onClick={() => {
          this.context.navigate({
            type: PaneType.day,
            id: today._id,
          });
        }}
      >
        <Svg name="icons8-day" /> {(Sugar.Date.create(today.date) as any).format('{Mon} {do}')}, {today.start.format('{h}:{mm} {TT}')}

        <span className="newDay"
          onClick={e => {
            e.stopPropagation();
            createDay({
              start: new Date(),
              date: new Date().toISOString().split('T')[0],
            });
          }}
        >New Day</span>
      </Container>
    );
  }
}
