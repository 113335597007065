import React from 'react';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import ReactDOMClient from "react-dom/client";
import jQuery from 'jquery';
import { Component } from "react";
import { component, styled } from "../../component";
import { XInit, XObject, x } from "../../XObject";
import { RenderData } from "../../MyNotionDocument/RenderData";
import { types } from "../../MyNotionDocument/types";
import { concatData, dataLength, dataToString, extractFromEl, getPositionInDataEditor, sliceData } from '../../richTextHelpers';
import { Menu } from '../notionDocument/Menu';
import { db } from '../../db';
import { SystemContext, SystemContextProps } from '../../etc/SystemContext';
import { resolveOffset } from "../notionDocument/resolveOffset";
import { createEntity, getAllEntities } from '../../etc/createEntity';

const ctx = {
  types: types,
}

@component
export class RichTextEditor extends Component<{ readOnly?, value, setValue; _onFocus?; _onBlur?;_onMouseOver?; _onMouseOut?; actions?; onEntityChosen?; autoFocus?; onEnter? }> {
  static reactive = false;

  static styles = styled.div`
    min-height: 20px;
    min-width: 20px;
    cursor: text;g
    > div {
      min-height: 20px;
      min-width: 20px;
    }

    .data-editor {
      &:focus {
        outline: none;
      }
    }

    .--entity-- {
      cursor: pointer;
    }
  `;

  state = XInit(class {
    // data = [];
  });

  showMenu

  getEl() {
    const el = ReactDOM.findDOMNode(this);
    return jQuery(el.firstChild);
  }
  static contextType = SystemContext;
  context: SystemContextProps;


  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }

  observe() {
    const el = ReactDOM.findDOMNode(this);

    jQuery(el).on('click', '[data-type="entity"]', (e) => {
      const id = JSON.parse(atob(e.target.getAttribute('data-entity-data')));
      this.context?.navigate?.({
        type: 'entity',
        id
      })
    });

    jQuery(el).on('input', '[contenteditable]', e => {
      if (this.showMenu) {
        console.log('showing menu');
        return;
      }
      const type = e.originalEvent.inputType;
      if (type.startsWith('format') || (type.startsWith('insert') || type.startsWith('delete')) && !this.showMenu) {
        this.save('input');
      }
    });

    jQuery(el).on('paste', '[contenteditable]',  (e) => {
      e.preventDefault();
      const d = e.originalEvent.clipboardData.getData('text/plain');
      this.props.setValue(d);
      this.forceUpdate();
    });
  }

  componentDidMount(): void {
    this.observe();
    XObject.observe(this.props.value(), () => {
      if (!this.focused) {
        ++this.tick;
        this.forceUpdate();
      }
    });
    if (this.props.autoFocus) {
      const [el, pos] = resolveOffset(ctx, this.getEl()[0], dataLength(ctx, this.props.value()));
      console.log(el, pos);
      window.getSelection().setBaseAndExtent(el, pos, el, pos);
    }
  }

  componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any): void {
    this.observe();
  }

  save(from) {
    const editor = this.getEl();
    const data = extractFromEl(ctx, editor[0] as any);
    this.props.setValue(data);
  }

  menuRef = React.createRef<Menu>();

  options() {
    const options = [
      {
        label: 'Insert entity',
        action: (menuPos) => {
          const data = this.props.value();
          const position = menuPos;
          const length = dataLength(ctx, data);
          const firstPart = sliceData(ctx, data, 0, position);
          const secondPart = sliceData(ctx, data, position, length);
          const e = [['6435a0493239f5c3f494c1d6', 'entity']];
          this.props.setValue(concatData(ctx, concatData(ctx, firstPart, e), secondPart));
        }
      },
      {
        label: 'Insert code',
        action: (menuPos) => {
          // const position = menuPos;
          // const data = block.getContent();
          // const length = dataLength(this.ctx, data);
          // const firstPart = sliceData(this.ctx, data, 0, position);
          // const secondPart = sliceData(this.ctx, data, position, length);
          // const component = componentSystem.createComponent();
          // const e = [[{
          //   id: XObject.id(),
          //   component: component._id,
          // }, 'code']];
          // block.setContent(concatData(this.ctx, concatData(this.ctx, firstPart, e), secondPart));
          // appState.appInspect = {
          //   mode: InspectState.code,
          //   component: component._id,
          // }
        },
      },

      ...(this.props.actions || []),
    ]

    return options;
  }

  entitySelectOptions(filter, action) {
    const r =  getAllEntities().filter(e => {
      if (!_.isString(e.name)) return false;
      return e.name.toLowerCase().includes(filter.toLowerCase());
    }).slice(0, 10).map(e => ({
      key: e._id,
      label: e.name,
      action: (menuPos) => {
        action(menuPos, e);
      }
    }))

    return r.concat({
      label: `Create entity "${filter}"`,
      action: (menuPos) => {
        const e = XObject.obj({
          name: filter,
        });
        createEntity(e, null);
        action(menuPos, e);

      }
    } as any)
  }

  ignoreEnter
  presentMenu({ left, top, endLength, position, type }) {
    const cont = jQuery('<div />').css({
      position: 'absolute',
      left,
      top,
      zIndex: 9999999,
    });
    jQuery('body').append(cont);
    const root = ReactDOMClient.createRoot(cont[0]);

    root.render(
      <Menu
        ref={this.menuRef}
        type={type}
        menuIniters={{
          '/': this.options(),
          '@': filter => this.entitySelectOptions(filter, (menuPos, e) => {
            const data = this.props.value();
            const position = menuPos;
            const length = dataLength(ctx, data);
            const firstPart = sliceData(ctx, data, 0, position);
            const secondPart = sliceData(ctx, data, position, length);
            this.props.setValue(concatData(ctx, concatData(ctx, firstPart, [[e._id, 'entity']]), secondPart));    
          }),
          '$': filter => this.entitySelectOptions(filter, (menuPos, e) => {
            this.props.onEntityChosen?.(e);
          }),
        }}
        onChooseAction={async option => {
          if (option) {
            await (option.action as any)(this.menuPos);
            delete this.menuPos;
          }

          const selection = this.showMenu.selection;
          this.ignoreEnter = true;
          
          this.tick++;
          this.forceUpdate();
          setTimeout(() => {
            // setCaretToBlockSelection(this.ctx, selection);
          }, 1)


          this.closeMenu();
        }}
      />
    );
    this.showMenu = {
      root,
      cont,
      // selection: getBlockSelection(this.ctx),
      position,
      endLength,
    }
  }

  closeMenu() {
    this.menuRef.current.close(() => {
      this.showMenu.root.unmount();
      this.showMenu.cont.remove();
      delete this.showMenu;
      console.log('closed')
    })
  }

  menuPos
  letNextInsert = false;
  tick = 0;

  focused = false;


  render(Container?) {
    const attrs = {
      suppressContentEditableWarning: true,
      contentEditable: true,
      spellCheck: false,
      autoFocus: this.props.autoFocus,

      onKeyDown: async e => {
        const el = this.getEl();
        if (!this.showMenu) {
          if (['/', '@', '$'].includes(e.key)) {
            let left, top, height;
            const position = getPositionInDataEditor(el[0], ctx)
            this.menuPos = position;
            if (position > 0) {
              ({ left, top, height } = window.getSelection().getRangeAt(0).getBoundingClientRect());
  
            }
            else {
              left = el.offset().left;
              top = el.offset().top;
              height = el.height();
            }
  

            const data = extractFromEl(ctx, el[0])

            this.presentMenu({
              left,
              top: top + height,
              position,
              endLength: dataLength(ctx, data) - position,
              type: e.key,
            });
            this.letNextInsert = true;
          }
          else if (this.props.onEnter && e.key == 'Enter' && !e.altKey) {
            e.preventDefault();
            this.props.onEnter?.();
          }
        }
        else {
          if (e.key == 'Escape') {
            this.save('input');
            this.menuRef.current.close(() => {
              this.showMenu.root.unmount();
              this.showMenu.cont.remove();
              delete this.showMenu;  
            });
          }
          else if (e.key == 'Backspace') {
            if (!_.isNil(this.menuPos) && _.isEqual(getPositionInDataEditor(el[0], ctx) - 1, this.menuPos)) {
              this.closeMenu();
            }
          }
          else if (e.key == 'Enter') {
            e.preventDefault();
            const option = this.menuRef.current.enter();
            if (option) {
              await (option.action as any)(this.menuPos);
              delete this.menuPos;
            }
  
            const selection = this.showMenu.selection;
            this.ignoreEnter = true;
            
            this.tick++;
            this.forceUpdate();
            setTimeout(() => {
              // setCaretToBlockSelection(this.ctx, selection);
            }, 1)
  
  
            this.closeMenu();
  
          }
          else if (e.key === 'ArrowDown') {
            this.menuRef.current.down();
            e.preventDefault();
          }
          else if (e.key === 'ArrowUp') {
            this.menuRef.current.up();
            e.preventDefault();
          }
        }
      },
      onKeyUp: e => {
        const el = this.getEl();
        if (this.showMenu) {
          const data = extractFromEl(ctx, el[0]);
          this.menuRef.current.setFilter(dataToString(ctx, sliceData(ctx, data, this.showMenu.position + 1, dataLength(ctx, data) - this.showMenu.endLength)));

          // const text = el.text();
          // this.menuRef.current.setFilter(text.slice(this.showMenu.position + 1, text.length - this.showMenu.endLength));
        }
      },

      onFocus: () => {
        this.props._onFocus?.();
        this.focused = true;
      },
      onBlur: () => {
        this.props._onBlur?.();
        this.focused = false;
      },
      onMouseDown: e => {
        e.stopPropagation();
      },
      onMouseDownCapture: e => {
        e.stopPropagation();
      },

      onMouseOver: this.props._onMouseOver,
      onMouseOut: this.props._onMouseOut,
    }

    return (
      <Container key={this.tick + this.props.readOnly}>
        <RenderData
          key={this.tick}
          tag="div"
          attrs={{
            className: 'data-editor',
            ...(this.props.readOnly ? {} : attrs),
          }}
          ctx={ctx}
          data={this.props.value()}
        />
      </Container>
    )
  }
}
