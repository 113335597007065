import React, { Component } from 'react';
import { PropertyField } from '../components/PropertyField';
import { component } from '../component2';
import { defaultWorkspace } from '../etc/defaultWorkspace';



@component
export class SystemConfigWindow extends Component<{ window; }> {
  render() {
    return (
      <>
        <div>Entity Template Supplier: <PropertyField object={defaultWorkspace()} property="entityTemplateSupplier" /></div>
        <div>Sidebar: <PropertyField object={defaultWorkspace()} property="sidebar" /></div>
        
      </>
    );
  }
}
