import { Component, useContext } from "react";
import _ from 'lodash';
import { ScriptEditor } from "../../MyNotionDocument/ScriptEditor";
import { XObject, x } from "../../XObject";
import { component } from "../../component";
import { renderEl } from "../../shorthand/formula";
import { Comp } from "../Comp";
import { ValueType } from "../ValueType";
import { registerType } from "../__typeRegistry";
import { CompiledValuePoint, ReferenceType, ValuePointProps, ValuePointSwitch, getScopeForValuePoint, values } from "../main";
import { registerTypeRegister } from "../typeRegistering";
import { additionalMenuIniters, additionalTypes } from "./additionalTypes";
import { hooks } from "./hooks";
import { pullValues } from "./pullValues";
import { ObjectType } from "../../types/ObjectRef";
import { executeScriptFromData } from "../../shorthand/executeScriptFromData";
import { SystemContext } from "../../etc/SystemContext";
import { ScriptWrapper, createScriptWrapper } from "../../MyNotionDocument/ScriptWrapper";

@component
class Sidebar extends Component<{ state }> {
  render() {
    const reffedValues = collectValues(this.props.state.content.blocks);
    const childrenValues = values().filter(value => value.location == this.props.state._id).map(value => value._id)
    return (
      <>
        {reffedValues.filter(x => !childrenValues.includes(x)).map(id => {
          return (
            <ValuePointSwitch
              key={id}
              id={id}
            />
          )
        })}
      </>
    )
  }
}

@component
class DoThing extends Component<{ value, map, rt }> {
  func
  render() {
    const { value, map, rt } = this.props;

    if (this.func) {
      return renderEl(this.func(), rt);
    }
    else {
      const ctx = useContext(SystemContext);
  
      const r = executeScriptFromData(
        value.content.blocks,
        hooks(value.content.values, map, {}, rt),
        {
          __rt: value.rt,
          __ctx: ctx,
        }
      )
  
      if (_.isFunction(r)) {
        this.func = r;
        this.forceUpdate();
      }
      else {
        return renderEl(r, rt);
      }
    }

  }
}

@component
class ScriptValuePoint extends Comp<ValuePointProps> {
  scriptWrapper: ScriptWrapper
  constructor(props) {
    super(props);
    this.scriptWrapper = createScriptWrapper({
      baseEntity:null,
      extendEntity: null,
      blocks: () => {
        const state = XObject.get(this.props.state, 'content', {});
        
        return XObject.get(state, 'blocks', []);
      },
      setBlocks: b => {
    const state = XObject.get(this.props.state, 'content', {});

    state.blocks = b
      }
    })
  }

  render() {
    const state = XObject.get(this.props.state, 'content', {});
    const scope = getScopeForValuePoint(this.props.state._id);

    const structsReferenced = extractScriptValues(state.blocks).filter(x => {
      return x.type == ValueType.Structure;
    }).map(x => x.id);

    return (
      <>
        <ScriptEditor
          scriptWrapper={this.scriptWrapper}
          additionalTypes={additionalTypes()}
          additionalMenuIniters={additionalMenuIniters(scope, structsReferenced)}
          parent={{
            type: ObjectType.valuePoint,
            id: this.props.state._id,
          }}
        />
      </>
    )
  }
}

function collectValues(script) {
  const values = [];
  for (const block of script) {
    for (const el of block.data || []) {
      if (el[1] == 'glue') {
        if (el[0].type == ReferenceType.Value) {
          values.push(el[0].id);
        }
      }
    }
    if (block.children?.length) {
      values.push(...collectValues(block.children));
    }
  }

  return _.uniq(values);
}



function pullScriptValues(script, rt, values) {
  for (const block of script) {
    pullValues(block.data, rt, values);
    if (block.children?.length) {
      pullScriptValues(block.children, rt, values);
    }
  }
}


function extractValues(formula) {
  const values = [];
  for (const el of formula || []) {
    if (el[1] == 'glue') {
      values.push(x(el[0]));
    }
  }
  return values;
}

function extractScriptValues(script) {
  const values = [];
  if (script) for (const block of script) {
    values.push(...extractValues(block.data));
    if (block.children?.length) {
      values.push(...extractScriptValues(block.children));
    }
  }

  return _.uniqWith(values, _.isEqual);
}


const registerScriptType = () => {
  registerType(ValueType.Script, {
    editorComp: ScriptValuePoint,
    sideBarComp: Sidebar,
    evaluateWithParams: ({value, map, rt}) => {
      return executeScriptFromData(
        value.content.blocks,
        hooks(value.content.values, map, {}, rt),
        {
          __rt: value.rt,
          ...(map.__env || {}),
        }
      )
    },
    execute: (value, rt) => {
      const values = {};
      if (value.content?.blocks) pullScriptValues(value.content.blocks, rt, values);
      return {
        _id: value._id,
        type: value.type,
        content: {
          blocks: value.content.blocks,
          values,
        },
        isState: false,
        presentation: value.presentation,
        rt,
      }
    },
    renderWithParams: ({value, map, rt }) => {
      return <DoThing value={value} map={map} rt={rt} />;
      const ctx = useContext(SystemContext);

      const r = executeScriptFromData(
        value.content.blocks,
        hooks(value.content.values, map, {}, rt),
        {
          __rt: value.rt,
          __ctx: ctx,
        }
      )
      return renderEl(r, rt);
    },
    isBlock() {
      return true;
    }
  })
}

registerTypeRegister(registerScriptType);