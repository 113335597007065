import React, { Component } from 'react';
import { db } from '../db';
import { openWindow } from '../osHelpers';
import { PropertyField } from '../components/PropertyField';
import { component } from '../component2';
import { defaultWorkspace } from '../etc/defaultWorkspace';
import { showContextMenu } from '../helpers';
import { WindowType } from '../etc/WindowType';
import { SystemContext, SystemContextProps } from '../etc/SystemContext';
import { getEntityById } from '../etc/createEntity';

@component
export class QueuesWindow extends Component<{ window; }> {
  static contextType = SystemContext;
  context: SystemContextProps;
  render() {
    const workspace = defaultWorkspace();
    return (
      <ul>
        {workspace.queues?.map?.((x, i) => (
          <li key={i}
            onContextMenu={(e) => {
              e.preventDefault();
              showContextMenu(e, [
                {
                  text: 'Delete',
                  onClick: () => {
                    workspace.queues.splice(i, 1);
                  }
                }
              ]);
            }}
          >
            <span
              onClick={() => {
                (this.context?.navigate || openWindow)({
                  type: WindowType.EntityQueues,
                  entity: x.entity,
                });
              }}
            >{getEntityById(x.entity)?.name}</span> -- <i><PropertyField object={x} property="name" /></i> ({x.list?.length} items)
            <button
              onClick={() => {
                (this.context?.navigate || openWindow)({
                  type: WindowType.Queue,
                  queue: x._id,
                });
              }}
            >View</button>
          </li>
        ))}
      </ul>
    );
  }
}
