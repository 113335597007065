import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { db } from '../db';
import { component } from '../component2';
import { XInit, XObject } from '../XObject';
import { appState } from '../etc/appState';
import { ObjectType } from '../types/ObjectRef';
import { PropertyField } from './PropertyField';
import { styled } from '../component';
import * as go from 'gojs';
import { ReactDiagram } from 'gojs-react';
import jQuery from 'jquery';
import { showContextMenu } from '../helpers';
import { DragSource, useDrop } from 'react-dnd';

@component
class _Entity extends Component<{ id; children?; connectDragSource; }> {
  static styles = styled.span``;
  render() {
    return this.props.connectDragSource((
      <span>
        {this.props.children}
      </span>
    ));
  }
}

export const EntityHandle = DragSource('object', {
  beginDrag(props: any) {
    return {
      type: 'entity',
      entity: props.id,
    };
  },
  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      return;
    }
    const item = monitor.getItem();
    const dropResult = monitor.getDropResult();
  }
}, (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
})(_Entity);



@component
export class CardsPane extends Component {
  state = XInit(class {
    shown = {};
    selected
    filter
  });

  static styles = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .left {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 50%;
      border-right: 1px solid black;
      .diagram-component {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    .right {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 50%;
    }
  `;


  diagram
  diagramRef = React.createRef<ReactDiagram>();

  render() {
    const selectedCategory = this.state.selected && db.dataObjects.findById(this.state.selected);
    const descendents = [];
    const getDescendents = catId => {
      const children = db.dataObjects.filter(x => x.parentCategory == catId);
      for (const child of children) {
        descendents.push(child._id);
        getDescendents(child._id);
      }
    }

    if (this.state.selected) {
      descendents.push(this.state.selected)
      getDescendents(this.state.selected);
    }


    const cards = db.dataObjects.filter(x => {
      if (this.state.selected && !descendents.includes(x.parentCategory)) return false;
      if (this.state.filter && x.parentCategory) {
        const cat = db.dataObjects.findById(x.parentCategory);
        if (!cat.name.match(new RegExp('\\b' + this.state.filter, 'i'))) return false;
      }
      return x.type == ObjectType.card && x.mode == appState.currentMode;
    });
    cards.sort((a, b) => a.parentCategory < b.parentCategory ? -1 : 1);

    const categories = db.dataObjects.filter(x => x.type == ObjectType.cardCategory && x.mode == appState.currentMode);

    if (categories.length == 0) {
      const obj = XObject.obj({
        type: ObjectType.cardCategory,
        mode: appState.currentMode,
        name: 'Root',
        parent: {
          type: ObjectType.mode,
          id: appState.currentMode,
        }
      });
      db.dataObjects.push(obj);
    }

    const initGraphDiagram = () => {

      const $ = go.GraphObject.make;

      const myDiagram = $(go.Diagram,
        {
          initialAutoScale: go.Diagram.UniformToFill,
          // define the layout for the diagram
          layout: $(go.TreeLayout, { nodeSpacing: 5, layerSpacing: 30 }),
          model: $(go.GraphLinksModel,
            {
              linkKeyProperty: 'key',

              // positive keys for nodes
              makeUniqueKeyFunction: (m: go.Model, data: any) => {
                let k = data.key || 1;
                while (m.findNodeDataForKey(k))
                  k++;
                data.key = k;
                return k;
              },
              // negative keys for links
              makeUniqueLinkKeyFunction: (m: go.GraphLinksModel, data: any) => {
                let k = data.key || -1;
                while (m.findLinkDataForKey(k))
                  k--;
                data.key = k;
                return k;
              }
            })
        });

      myDiagram.nodeTemplate =
        $(go.Node, "Horizontal",
          {
            selectionChanged: (a) => {


                if (a.isSelected) {
                  this.state.selected = a.data.key;
                }
                else {
                  this.state.selected = null;
                }


            },

            mouseDragEnter: (e, node: any, prev) => {
              const shape = node.findObject("BODY");
              if (shape) {
                shape._prevFill = shape.fill; // remember the original brush
                shape.fill = "darkred";

              }
            },
            mouseDragLeave: (e, node: any, next) => {
              const shape = node.findObject("BODY");
              if (shape && shape._prevFill) {
                shape.fill = shape._prevFill; // restore the original brush
              }
            },

            doubleClick: (e, node: any) => {
              // console.log(node.data.entity);
              // this.navigate({ type: 'entity', props: { entity: node.data.entity } }, undefined, { present: 'sidePeek' });
            },

            mouseDrop: (e, dropTargetNode: any) => {
              const diagram = dropTargetNode.diagram;
              // const selnode = diagram.selection; // assume just one Node in selection
              const selectedNodes = [];
              for (const node of diagram.selection.toArray()) {
                const currentParent = node.findTreeParentLink()?.fromNode?.data?.key;
                const nextParent = dropTargetNode.data.key;
                const id = node.data.key;
                console.log(id, currentParent, nextParent)

                db.dataObjects.findById(id).parentCategory = nextParent;
              }
              console.log('mouseDrop', dropTargetNode.data, selectedNodes);
            },
            contextMenu: $(go.HTMLInfo, {
              show: async (obj: any, diag, tool) => {
                const pos = { x: diag.lastInput.viewPoint.x + jQuery(diag.div).offset().left, y: diag.lastInput.viewPoint.y + jQuery(diag.div).offset().top };

                showContextMenu({ clientX: pos.x, clientY: pos.y }, [
                  {
                    text: 'Add',
                    onClick: () => {
                      const o = XObject.obj({
                        type: ObjectType.cardCategory,
                        mode: appState.currentMode,

                        parent: {
                          type: ObjectType.mode,
                          id: appState.currentMode,
                        },
                        parentCategory: obj.data.key,
                        name: window.prompt('Name'),
                      });
                      db.dataObjects.push(o);
                    },
                  },
                  {
                    text: 'Rename',
                    onClick: () => {
                      const cat = db.dataObjects.findById(obj.data.key);
                      cat.name = window.prompt('Name');
                    }
                  },

                  {
                    text: 'Delete',
                    onClick: () => {
                      const cat = db.dataObjects.findById(obj.data.key);

                      const index = db.dataObjects.indexOf(cat);
                      db.dataObjects.splice(index, 1);
                    },
                  }
                ]);
              },
              hide: () => {
              }
            }),
          },
          $(go.Panel, "Auto",
            $(go.Shape, { fill: "#1F4963", stroke: null, name: 'BODY' }),
            $(go.TextBlock,
              {
                font: "bold 13px Helvetica, bold Arial, sans-serif",
                stroke: "white", margin: 3
              },
              new go.Binding("text", "text"))
          ),
          $("TreeExpanderButton")
        );

      
      myDiagram.linkTemplate =
        $(go.Link,
          { selectable: false },
          $(go.Shape)); // the link shape


        this.diagram = myDiagram;


      return myDiagram;

    };

    const nodes = [];
    const links = [];

    for (const cat of categories) {
      nodes.push({
        key: cat._id,
        text: cat.name,
      })

      if (cat.parentCategory) {
        links.push({
          key: cat._id+'parent',
          from: cat.parentCategory,
          to: cat._id,
        })
      }
    }

    const onDrop = e => {
      console.log(e);
      const droppedEntity = e.dataTransfer.getData('text/plain');
      console.log(droppedEntity)
      if (!droppedEntity) return;

      // console.log(e, e.dataTransfer.items);
      // return;
      onHighlight(this.diagram, null);

      const myDiagram = this.diagram;
      // console.log(this.diagram);
      // const event = e.originalEvent;
      const event = e
      const can = (ReactDOM.findDOMNode(this) as any).querySelector('.diagram-component canvas');

      const pixelratio = myDiagram.computePixelRatio();

      // if the target is not the canvas, we may have trouble, so just quit:
      if (!(can instanceof HTMLCanvasElement)) return;

      const bbox = can.getBoundingClientRect();
      let bbw = bbox.width;
      if (bbw === 0) bbw = 0.001;
      let bbh = bbox.height;
      if (bbh === 0) bbh = 0.001;
      const mx = event.clientX - bbox.left * ((can.width / pixelratio) / bbw);
      const my = event.clientY - bbox.top * ((can.height / pixelratio) / bbh);
      const point = myDiagram.transformViewToDoc(new go.Point(mx, my));
      const part = myDiagram.findPartAt(point, true);

      console.log(part?.data, );

      const card = db.dataObjects.findById(droppedEntity);
      card.parentCategory = part?.data.key;


      if (part?.data) {
        // this.props._onDropExternal(part?.data.entity, droppedEntity);
        // // const r = await entityMatch(this.context, part?.data?.entity, this.props.matches.map(m => ({
        // //   match: m.match,
        // //   value: m,
        // //   _id: m._id,
        // // })));
        // // if (r?.[0]?.onDrop) {
        // //   const rr = await this.context.api.procedure[r[0].onDrop](part?.data?.entity, (item as any).entity);
        // // }
      }
    }

    const [, drop] = useDrop({
      accept: 'object',
      drop: async (item, monitor) => {
        onHighlight(this.diagram, null);

        const myDiagram = this.diagram;
        // console.log(this.diagram);
        // const event = e.originalEvent;
        const event = (window as any)._g_dropEvent;
        const can = (ReactDOM.findDOMNode(this) as any).querySelector('.diagram-component canvas');
  
        const pixelratio = myDiagram.computePixelRatio();
  
        // if the target is not the canvas, we may have trouble, so just quit:
        if (!(can instanceof HTMLCanvasElement)) return;
  
        const bbox = can.getBoundingClientRect();
        let bbw = bbox.width;
        if (bbw === 0) bbw = 0.001;
        let bbh = bbox.height;
        if (bbh === 0) bbh = 0.001;
        const mx = event.clientX - bbox.left * ((can.width / pixelratio) / bbw);
        const my = event.clientY - bbox.top * ((can.height / pixelratio) / bbh);
        const point = myDiagram.transformViewToDoc(new go.Point(mx, my));
        const part = myDiagram.findPartAt(point, true);
  
        if (part?.data) {
          // console.log(part?.data, item);
          // this.props._onDropExternal(part?.data.entity, (item as any).entity);
          // const r = await entityMatch(this.context, part?.data?.entity, this.props.matches.map(m => ({
          //   match: m.match,
          //   value: m,
          //   _id: m._id,
          // })));
          // if (r?.[0]?.onDrop) {
          //   const rr = await this.context.api.procedure[r[0].onDrop](part?.data?.entity, (item as any).entity);
          // }
        }
      }
    });


    const highlightColor = '#95badc';

    const onHighlight = (diagram, part) => {  // may be null
      // console.log(part);
      const myDiagram = diagram;
      const oldskips = myDiagram.skipsUndoManager;
      myDiagram.skipsUndoManager = true;
      myDiagram.startTransaction("highlight");
      if (part) {
        myDiagram.highlight(part);
      } else {
        myDiagram.clearHighlighteds();
      }
      myDiagram.commitTransaction("highlight");
      myDiagram.skipsUndoManager = oldskips;
    }

    const el = ReactDOM.findDOMNode(this);
    const doThing = (e) => {
      // console.log(e);
      const myDiagram = this.diagram;
      // console.log(this.diagram);
      const event = e.originalEvent;
      const can = event.target;

      const pixelratio = myDiagram.computePixelRatio();

      // if the target is not the canvas, we may have trouble, so just quit:
      if (!(can instanceof HTMLCanvasElement)) return;

      const bbox = can.getBoundingClientRect();
      let bbw = bbox.width;
      if (bbw === 0) bbw = 0.001;
      let bbh = bbox.height;
      if (bbh === 0) bbh = 0.001;
      const mx = event.clientX - bbox.left * ((can.width / pixelratio) / bbw);
      const my = event.clientY - bbox.top * ((can.height / pixelratio) / bbh);
      const point = myDiagram.transformViewToDoc(new go.Point(mx, my));
      const part = myDiagram.findPartAt(point, true);

      onHighlight(this.diagram, part);
    }

    jQuery(el).find('.diagram-component canvas').on('dragover', (e) => {
      e.preventDefault();
      e.stopPropagation();
      doThing(e);
    });

    jQuery(el).find('.diagram-component canvas').on('dragleave', (e) => {
      onHighlight(this.diagram, null);
    });

    jQuery(el).find('.diagram-component canvas').on('drop', (e) => {
      console.log(e);
      onHighlight(this.diagram, null);
    });




    return (
      <>
        <div className="left"
        
        ref={drop}
        onDragOver={e => {
          e.preventDefault();
          // console.log(e);
        }}
        onDrop={e => {
          e.preventDefault();
          // console.log(e);
          onDrop(e);
        }}
        >
          <ReactDiagram
            ref={this.diagramRef}
            initDiagram={initGraphDiagram}
            divClassName='diagram-component'
            nodeDataArray={nodes}
            linkDataArray={links}
          />
        </div>
        <div className="right">
          <input type="text" defaultValue={this.state.filter} onChange={ e=> this.state.filter =e.target.value} />
          <table border={1}>
            <thead>
              <tr>
                <th />
                <th>Obj</th>
                <th>Front</th>
                {/* <th>Object</th>
                <th>Prop</th> */}
                <th>Back</th>
              </tr>
            </thead>
            <tbody>
              {cards.map(x => {
                const cat = x.parentCategory&& db.dataObjects.findById(x.parentCategory)
                return (
                  <tr key={x._id}>
                    <td><span
                    draggable
                            onDragStart={(e) => {
                              e.dataTransfer.setData('text/plain', x._id);
                            }}
                    id={x._id}>X</span></td>
                    <td style={{ color: 'gray'}}>{cat?.name}</td>
                    <td>
                      <PropertyField object={x} property="front" />
                    </td>
                    {/* <td style={{fontWeight: 'bold'}}>
                      <PropertyField object={x} property="obj" />
                    </td>
                    <td>
                      <PropertyField object={x} property="prop" />
                    </td> */}
                    <td>
                      {(this.state.shown[x._id] || !x.back) && <PropertyField textarea object={x} property="back" />}
                      {x.back && <button
                        onClick={() => {
                          this.state.shown[x._id] = !this.state.shown[x._id];
                        }}
                      >Toggle</button>}

                      {x.back && <button
                        onClick={() => {
                          alert(x.back);
                        }}
                      >Show</button>}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <button
            onClick={() => {
              const obj = XObject.obj({
                type: ObjectType.card,
                mode: appState.currentMode,
                parent: {
                  type: ObjectType.mode,
                  id: appState.currentMode,
                },
                parentCategory: this.state.selected,
                front: prompt(''),
              });
              db.dataObjects.push(obj);
              this.state.shown[obj._id] = true;
            }}
          >+</button>
        </div>
      </>
    );
  }
}
