import { RuntimeContext, RuntimeTrace } from "./RuntimeTrace";

export class El {
  constructor(
    public tag,
    public attributes,
    public children = [],
    public blockId?,
    public instanceId?,
    public identifier?,
    public scopeId?,
    public cs?: RuntimeTrace,
    public runtimeContext?: RuntimeContext
  ) { }
}
