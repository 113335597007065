import { Component } from 'react';
import { component } from './component';
import {ResponsiveMasonry} from "react-responsive-masonry"
import { ObjectType } from './types/ObjectRef';
import { PinBoard } from './PinBoard';

@component
export class PinBoardWindow extends Component<{ window; }> {
  render() {
    return <PinBoard />;
  }
}
