import React, { Component } from 'react';
import { db } from '../db';
import { component } from '../component2';
import { X, XInit, XObject, x } from '../XObject';
import { appState, setAppInspect } from '../etc/appState';
import { SystemContext, SystemContextProps } from '../etc/SystemContext';
import { ObjectRefClass, ObjectType } from '../types/ObjectRef';
import { ObjectPageFrame } from './ObjectPageFrame';
import { PaneType } from '../types/PaneType';
import { PropertyField } from './PropertyField';
import { NotionDocumentWrapper } from './NotionDocumentWrapper';
import juration from '../juration';
import { Cell } from './Cell';
import { DurationCellType, EventCellType, TextCellType, TimeCellType } from './cells';
import { allDays, createDay } from './allDays';
import { Blocks } from './BlockSystem';
import { GlueView, createRootValuePoint } from '../glue/main';
import { objectResource } from './objectResource';
import { NotionTable2 } from './NotionTable2';
import { triggerInspectObject } from '../osHelpers';
import { explicitInspectObj } from '../inspectObj';
import { InspectState } from './InspectState';
import { registerContextVar } from '../glue/typeRegistering';
import { WithContextArgs } from './WithContextArgs';
import { AttributeField } from './AttributeField';
import { AttributeType } from './AttributeType';

const DayContextVar = 'e681360c-84df-58fa-8608-5a74f06a42bc';

registerContextVar(DayContextVar, 'Day');

function getSystemPage(systemBlocks, mode) {
  const key = 'abd28c24-d68f-5115-9489-41c61ed69e11' + mode;
  let systemPage = db.pages.find(p => p.key == key);
  if (!systemPage) {
    systemPage = XObject.obj({
      key,
      blocks: systemBlocks.map(b => XObject.obj(b)),
    });
    db.pages.push(systemPage);
  }
  return systemPage;
}

enum BlockType {
  table = 'ac1e9fb9-3980-581c-a679-ed534ff85430',
  glue = '2d514502-8ac9-59e5-a713-e58a2b980c78',
}



function occurrences(event, args, begin, end?) {
  const parameterizedEvents = db.parameterizedEvents.filter(x => x.event === event._id && x.arguments === args).map(x => x._id);
  const ocurrences = db.eventOccurrences.filter(x => {
    if (x.event != event) return false;
    if (x.arguments && x.arguments != args) return false;
    if (args && x.parameterizedEvent && !parameterizedEvents.includes(x.parameterizedEvent)) return false;

    if (begin && x.start < begin) return false;
    if (end && x.stop > end) return false;
    return true;
  });
  return ocurrences;
}

function eventTime(event, args, begin, end) {
  const ocurrences = occurrences(event, args, begin, end);
  let total = 0;
  for (const ocurrence of ocurrences) {
    total += (ocurrence.stop || new Date()).getTime() - ocurrence.start.getTime();
  }
  return total;

}


function wrapContextArgs(comp, func) {
  return null;
}

@component
export class DaysRoot extends Component {
  static contextType = SystemContext;
  context: SystemContextProps;
  render() {
    return (
      <ObjectPageFrame
        icon={'icons8-day'}
        obj={null}
        path="Days"
        pathId={null}
      >
        <ul>
          {allDays().map(day => {
            return (
              <li key={day._id}>
                <span onClick={() => {
                  this.context.navigate({
                    type: PaneType.day,
                    id: day._id,
                  });
                }}>{day.date}</span>

                <button
                  onClick={() => {
                    db.days.splice(db.days.indexOf(day), 1);
                  }}
                >X</button>
              </li>
            );
          })}
        </ul>
        <button
          onClick={() => {
            createDay({
              date: new Date().toISOString().split('T')[0],
            });
          }}
        >+</button>
      </ObjectPageFrame>
    );
  }
}

@component
export class DayPage extends Component<{ id: string; }> {
  timerId
  componentDidMount(): void {
    this.timerId = setInterval(() => {
      this.forceUpdate();
    }, 1000);
  }

  state = XInit(class {
    addingEventPlan
  });
  static contextType = SystemContext;
  context: SystemContextProps;
  render() {
    const day = db.days.findById(this.props.id);
    const data = XObject.get(XObject.get(day, 'modeData', {}), appState.currentMode, {});

    let doc;
    if (!data.page) {
      doc = XObject.obj({
        relative: true,
        key: 'de1d107e-2c6e-5866-ab36-b6684b0df0bf',
        parent: {
          type: ObjectType.day,
          id: this.props.id,
        },
        blocks: [],
      });
      db.notionDocuments.push(doc);
      data.page = doc._id;
    }
    else {
      doc = db.notionDocuments.findById(data.page);
    }

    const timeLeft = day.plannedEnd && (day.plannedEnd.getTime() - new Date().getTime())/1000;

    const dayLength = day.plannedEnd && (day.plannedEnd.getTime() - day.start.getTime())/1000;

    const systemPage = getSystemPage([], appState.currentMode);





    return (
      <>
        <ObjectPageFrame
          icon={'icons8-day'}
          obj={null}
          path={day.date}
          pathId={null}
        >
          {/* <div>
            Date: <PropertyField object={day} property="date" />
          </div> */}

          <AttributeField
            title={'Date'}
            type={AttributeType.text}
            field={<Cell
              cell={new TextCellType({})}
              get={() => day.date}
              set={v => { if (v) day.date = v } }
              title="Date"
            />}
          />

          <AttributeField
            title={'Start'}
            type={AttributeType.datetime}
            field={<Cell
              cell={new TimeCellType({})}
              get={() => day.start}
              set={v => { if (v) day.start = v } }
              title="Start"
            />}
          />

          {/* <div>
            Start: <PropertyField object={day} property="start" type="time" />
          </div> */}

          <AttributeField
            title={'Planned End'}
            type={AttributeType.datetime}
            field={<Cell
              cell={new TimeCellType({})}
              get={() => day.plannedEnd}
              set={v => { day.plannedEnd = v } }
              title="Planned End"
            />}
          />
          {/* <div>
            Planned End: <PropertyField object={day} property="plannedEnd" type="time" />
          </div> */}

          <AttributeField
            title={'End'}
            type={AttributeType.datetime}
            field={<Cell
              cell={new TimeCellType({})}
              get={() => day.end}
              set={v => { day.end = v } }
              title="End"
            />}
          />

          {/* <div>
            End: <PropertyField object={day} property="end" type="time" />
          </div> */}

          {/* <div>
            Length: {juration.stringify(dayLength)}
          </div> */}
              <AttributeField
                title={'Length'}
                type={AttributeType.duration}
                field={<Cell
                  cell={new DurationCellType({})}
                  get={() => dayLength}
                  set={v => { } }
                  title="Length"
                />}
              />

          {/* <div>
            Time Left: {juration.stringify(timeLeft)}
          </div> */}

          <AttributeField
                title={'Lime Left'}
                type={AttributeType.duration}
                field={<Cell
                  cell={new DurationCellType({})}
                  get={() => timeLeft}
                  set={v => { } }
                  title="Length"
                />}
              />

          {/* <ul>
            {day.plan?.map?.(plan => {
              const event = db.events.findById(plan.event);
              return (
                <li key={plan._id}>
                  {event.name} ({juration.stringify(eventTime(event._id, plan.arguments, day.start, day.end)/1000)}/<PropertyField object={plan} property="time" type="duration" placeholder="Time" />) 
                </li>
              );
            })}
            <li>
              <Cell
                cell={new EventCellType({})}
                get={() => {}}
                set={event => {
                  XObject.push(day, 'plan', XObject.obj({
                    event,
                  }))
                }}
                title="Event"
              />
            </li>
          </ul> */}


          {/* <NotionDocumentWrapper
            key={appState.currentMode}
            scopes={[
              {
                type: ObjectType.mode,
                id: appState.currentMode,
              }
            ]}
            docId={doc._id}
            entity={null}
            inline
            blocks={doc.blocks}
            setBlocks={blocks => {
              doc.blocks = blocks;
            }}
            configId={null}
            name={null}
            configMap={{
              // [$Document.Entity]: n,
            }}
          /> */}

          <WithContextArgs
            args={{
              [DayContextVar]: new ObjectRefClass(ObjectType.day, day._id),
            }}
          >
            <Blocks
              blockMatcher={b => {
                if (b.match) {
                  if (b.match[0] == 'f6c2c157-201b-5063-a786-ebb4fd3ca504') return true;
                  // if (b.match[0] == '2344e714-fe21-5df6-8423-ae3adf33083f' && b.match[1] == occurrence.event) {
                    // return true;
                  // }
                }
                return false;
              }}
              contextMenu={({ block, blockManager, i }) => {
                if (!block.match) {
                  return [
                    // {
                    //   text: 'Type match',
                    //   onClick: () => {
                    //     const b = x(block);
                    //     blockManager.delete(i);
                    //     setTimeout(() => {
                    //       b.match = ['2344e714-fe21-5df6-8423-ae3adf33083f', occurrence.event];
                    //       blockManager.insertBlock({
                    //         block: b,
                    //         personal: false,
                    //       }, i);
                    //     }, 1000);
                    //   },
                    // },
                    {
                      text: 'Global',
                      onClick: () => {
                        const b = x(block);
                        blockManager.delete(i);
                        setTimeout(() => {
                          b.match = ['f6c2c157-201b-5063-a786-ebb4fd3ca504'];
                          blockManager.insertBlock({
                            block: b,
                            personal: false,
                          }, i);
                        }, 1000);
                      },

                    }
                  ]
                }
                return [];
              }}
              insertionBlocks={{
                get: () => {
                  return XObject.get(data, 'blocks', []);
                },
                set: (v) => {
                  data.blocks = X(v);
                },
              }}
              page={systemPage}
              renderBlock={block => {
                if (!block.type) {
                  return (
                    [<Blocks.t.init>
                      <span>Block type:</span>
                      <button
                        onClick={() => {
                          const handle = XObject.obj({
                            type: ObjectType.table,
                            parent: {
                              type: ObjectType.day,
                              id: day._id,
                            }
                          });
                          db.objectHandles.push(handle);

                          block.handle = handle._id;
                          block.type = BlockType.table;
                        }}
                      >Table</button>

                      <button
                        onClick={() => {
                          const vp = createRootValuePoint();
                          block.valuePoint = vp._id;
                          block.type = BlockType.glue;
                        }}
                      >Glue</button>
                    </Blocks.t.init>]
                  )
                }

                if (block.type == BlockType.table) {
                  const table = objectResource({ id: day._id, type: ObjectType.day }, block.handle);

                  if (table) {
                    return [
                      <NotionTable2
                        active={null}
                        state={this.state}
                        table={table}
                        editView={id => {
                          triggerInspectObject({
                            type: 'f803b269-1725-57ba-a8c6-dac2fa140e06',
                            args: {
                              table: table._id,
                              view: id,
                            },
                          });
                        }}
                      />, [
                        {
                          text: 'Edit object handle',
                          onClick: () => {
                            explicitInspectObj({
                              type: ObjectType.objectHandle,
                              id: block.handle,
                            })
                          },
                        }
                      ]
                    ]
                  }
                  return (
                    [<>
                      Test
                    </>]
                  )
                }

                if (block.type == BlockType.glue) {
                  return [
                    <GlueView
                      id={block.valuePoint}
                      args={{
                      }}
                      state={{}}
                    />,
                    [
                      {
                        text: 'Edit value point',
                        onClick: () => {
                          setAppInspect({
                            mode: InspectState.valuePoint,
                            id: block.valuePoint,
                          });
          
                        },
                      }
                    ]
                  ]
                }

                return [];
              }}
            />

          </WithContextArgs>

        </ObjectPageFrame>
      </>
    );
  }
}
