import _ from 'lodash';
import { getCst } from "./getCst";
import { ScriptBlock, flexGrammar } from "../shorthand/formula";
import { expandFormula } from "../shorthand/formula";
import { unwrapCst } from "./unwrapCst";


class Type {
  getProperties() {
    return [];
  }

  getProperty(name) {
    return this.getProperties().find(p => p.name == name)?.type;
  }

  humanString() {
    return '?!';
  }

  hasProperty(name) {
    return this.getProperties().find(p => p.name == name);
  }
}
export class FunctionType extends Type {
  constructor(args: {
    returnType?: Type; params?: {
      name: string;
      type: Type;
    }[];
  }) {
    super();
    this.returnType = args.returnType || new AnyType();
    this.params = args.params || [];
  }
  returnType: Type;
  params: {
    name: string;
    type: Type;
  }[];

  humanString() {
    const params = this.params.map(p => {
      return `${p.name}: ${p.type?.humanString?.() || 'any'}`;
    }).join(', ');
    return `${params.length == 0 ? '() ' : `(${params}) `}=> ${this.returnType?.humanString?.() || 'any'}`;
  }

  getClosureArgType(functionParamIndex, closureParamIndex) {
    const t = this.params[functionParamIndex]?.type;
    if (t instanceof FunctionType) {
      return t.params?.[closureParamIndex]?.type || new AnyType('2');
    }

    return new AnyType('1');
  }
}
class BooleanType extends Type {
  humanString() {
    return 'boolean';
  }
}
export class StructType extends Type {
  constructor(public args: {
    properties: {
      name: string
      type: Type
    }[],
    name?
  }) {
    super();
    this.properties = args.properties;
  }
  properties;

  humanString(): string {
    return this.args.name
  }

  getProperties() {
    return this.properties;
  }

}
export class ArrayType extends Type {
  constructor(args: { elType?; }) {
    super();
    this.elType = args.elType;
  }

  elType;

  humanString() {
    return `${this.elType?.humanString?.() || 'any'}[]`;
  }


  getProperties(): any[] {
    return [
      {
        name: 'map',
        type: new FunctionType({
          returnType: new ArrayType({
            elType: new AnyType,
          }),
          params: [
            {
              name: 'mapFunc',
              type: new FunctionType({
                params: [
                  {
                    name: 'el',
                    type: this.elType,
                  },
                  {
                    name: 'i',
                    type: new NumberType(),
                  }
                ]
              })
            },
          ]
        })
      },
      {
        name: 'filter',
        type: new FunctionType({
          returnType: new ArrayType({
            elType: this.elType,
          }),
          params: [
            {
              name: 'predicate',
              type: new FunctionType({
                params: [
                  {
                    name: 'el',
                    type: this.elType,
                  },
                  {
                    name: 'i',
                    type: new NumberType,
                  }
                ]
              })
            }
          ]
        })
      },
      {
        name: 'push',
        type: new FunctionType({}),
      },
      {
        name: 'find',
        type: new FunctionType({
          returnType: this.elType,
          params: [
            {
              name: 'predicate',
              type: new FunctionType({
                params: [
                  {
                    name: 'el',
                    type: this.elType,
                  },
                  {
                    name: 'i',
                    type: new NumberType,
                  }
                ]
              }),
            },
            {
              name: 'test',
              type: new AnyType(),
            }
          ]
        })
      }
    ];
  }

}
class NumberType extends Type {
  humanString() {
    return 'number';
  }
}
export class StringType extends Type {
  humanString() {
    return 'string';
  }

  getProperties(): any[] {
    return [
      { name: 'length', type: new NumberType() },
    ];
  }
}

export class StyledComponentType extends Type {
  constructor(public debug?) {
    super();
  }
  humanString() {
    return 'StyledComponent';
  }
}

export class AnyType extends Type {
  constructor(public debug?) {
    super();
  }
  humanString() {
    return 'any';
    // return this.debug ? `any(${this.debug})` : 'any';
  }
}

export class ElementType extends Type {
  humanString(): string {
    return 'Element';
  }
}

export const globalTypes = () => {
  const types =  {
    string: new StringType(),
    any: new AnyType(),
    Function: new FunctionType({

    })
  }

  for (const asdf of additionalGlobalTypes) {
    const asdfd = asdf();
    for (const name in asdfd) {
      types[name] = asdfd[name];
    }

  }
  return types;
}

export const additionalGlobalTypes = [];

export const getScope = (blockId, col, model, excludeBlock, _globals={}) => {
  const collect = (blocks, vars) => {
    const g = {};

    for (const name in {...globals, ..._globals }) {
      g[name] = name;
    }

    const newVars = { ...g, ...vars };

    for (const block of blocks) {
      if (block.type == 'declare' && (!excludeBlock || blockId != block._id)) {
        newVars[block.identifier] = `${block._id}:${block.identifierCol}`;
      }
    }

    for (const block of blocks) {
      if (blockId == block._id) {
        const additionaVars = {};
        if (block.type == 'for-of') {
          if (col >= block.identifierCol && col <= block.identifierColEnd) {
            additionaVars[block.identifier] = `${block._id}:${block.identifierCol}`;
          }
        }
        else if (block.blockClosure) {
          const closure = block.blockClosure;
          if (col >= closure.col && col <= closure.colEnd) {
            for (const param of closure.params) {
              additionaVars[param.name] = `${block._id}:${param.col}`;
            }
          }
        }

        if (block.embeddedClosures?.length) {
          for (const { closure } of block.embeddedClosures) {
            if (col >= closure.col && col <= closure.colEnd) {
              for (const param of closure.params) {
                additionaVars[param.name] = `${block._id}:${param.col}`;
              }
            }
          }
        }
        return { ...newVars, ...additionaVars };
      }
      else {
        const additionaVars = {};
        if (block.type == 'for-of') {
          additionaVars[block.identifier] = `${block._id}:${block.identifierCol}`;
        }
        else if (block.blockClosure) {
          for (const param of block.blockClosure.params) {
            additionaVars[param.name] = `${block._id}:${param.col}`;
          }
        }
        const r = collect(block.children, { ...newVars, ...additionaVars });
        if (r) return r;
      }
    }
  };

  return collect(model, {});
};
export const getType = (cst, model: any[], scope, args: Args, debug: boolean) => {
  if (cst) {
    if (cst.ruleName == 'identifier') {
      if (cst.source in scope) {
        return getVarType(scope[cst.source], model, args, debug);
      }
      else {
        return globals[cst.source];
      }
    }
    else if (cst.ruleName == 'string' || cst.ruleName == 'InterpolatedString') {
      return new StringType;
    }
    else if (cst.ruleName == 'integer') {
      return new NumberType();
    }
    else if (cst.ruleName?.startsWith?.('Element_')) {
      return new ElementType();
    }
    else if (cst.ruleName == 'Primary_accessor') {
      const type = getType(cst.children[0], model, scope, args, debug);
      if (cst.children[2]?.source) {
        const t = type?.getProperty?.(cst.children[2].source) || new AnyType('asdfasdf')
        // if (debug) {
        //   console.log(cst.children[0].source, type, cst.children[2].source, t);
        // }
        return t;
      }

      return new AnyType('dddd');

    }
    else if (cst.ruleName?.startsWith?.('FunctionCall_')) {
      const functionType = getType(cst.children[0], model, scope, args, debug);
      if (functionType instanceof FunctionType) {
        return functionType.returnType;
      }
      else {
        return new AnyType;
      }
    }
    else if (cst.ruleName == 'string') {
      return new StringType;
    }
    else if (cst.ruleName?.startsWith?.('Closure_')) {
      return new FunctionType({});
    }
    else if (cst.ruleName?.startsWith?.('Array_')) {
      return new ArrayType({});
    }
    else if (cst.ruleName == 'ObjectRef') {
      if (args?.objectRefTypeResolver) {
        return args.objectRefTypeResolver(cst.children[1].source);
      }
      else {
        return new AnyType(`ObjectRef[${cst.children[1].source}]`);
      }
    }
    else if (cst.ruleName == 'StyledComponent') {
      return new StyledComponentType();
    }
    else {
      return new AnyType(cst.ruleName);
    }
  }
};
export const getBlock = (id, blocks) => {
  if (!blocks) return null;
  for (const block of blocks) {
    if (block._id == id) {
      return block;
    }
    else {
      const r = getBlock(id, block.children);
      if (r) return r;
    }
  }
};

export const getBlockPath = (id, blocks, path=[]) => {
  if (!blocks) return null;
  for (const block of blocks) {
    if (block._id == id) {
      return path.concat(block);
    }
    else {
      const r = getBlockPath(id, block.children, path.concat(block));
      if (r) return r;
    }
  }
};


export const getParentBlock = (id, blocks, parent?) => {
  for (const block of blocks) {
    if (block._id == id) {
      return parent;
    }
    else {
      const r = getParentBlock(id, block.children, block);
      if (r) return r;
    }
  }

};
export const getVarName = (varId, model) => {
  const [blockId, col] = varId.split(':');
  const block = getBlock(blockId, model);
  if (!block) return null;

  if (block.type == 'declare') {
    if (col == block.identifierCol) {
      return block.identifier;
    }
  }
  else if (block.type == 'for-of') {
    if (col == block.identifierCol) {
      return block.identifier;
    }
  }

  if (block.blockClosure) {
    for (const param of block.blockClosure.params) {
      if (param.col == col) {
        return param.name;
      }
    }
  }

  if (block.embeddedClosures) for (const { closure } of block.embeddedClosures) {
    for (const param of closure.params) {
      if (param.col == col) {
        return param.name;
      }
    }
  }
  return '?';

};

interface Args {
  objectRefTypeResolver?
  globals?
}

export const getVarType = (varId: string, model, args: Args, debug) => {
  if (args.globals && (varId in args.globals)) {
    return args.globals[varId];
  }
  if (varId in globals) {
    return globals[varId];
  }


  if (_.isString(varId)) {
    const [blockId, col] = varId?.split?.(':') || [];
    const block = getBlock(blockId, model);
    if (!block) return null;
  
    if (block.type == 'declare') {
      if (col == block.identifierCol) {
        const scope = getScope(block._id, block.cst.childOffsets[2], model, false, args.globals);
        const type = getType(block.cst.children[2], model, scope, args, debug);
        if (type instanceof FunctionType) {
          if (block.children.length) {
            return type.returnType;
          }
        }
        return type;
      }
    }
    else if (block.type == 'for-of') {
      if (col == block.identifierCol) {
        const scope = getScope(block._id, block.cst.childOffsets[3], model, false, args.globals);
        const type = getType(block.cst.children[3], model, scope, args, debug);
        return type?.elType || new AnyType();
      }
    }
  
    if (block.blockClosure) {
      for (const param of block.blockClosure.params) {
        if (param.col == col) {
          if (block.blockClosure.col == 0) {
            const parentBlock = getParentBlock(block._id, model);
            if (parentBlock) {
              if (parentBlock.type == 'declare') {
                const type = getType(parentBlock.cst.children[2], model, getScope(parentBlock._id, 0, model, false, args.globals), args, debug);
                if (type instanceof FunctionType) {
                  return type.getClosureArgType(parentBlock.children.indexOf(block), 0);
                }
              }
              else {
                if (parentBlock.cst.ruleName == 'PipedLine') {
                  const content = parentBlock.cst.children[1];
                  if (content.ruleName == 'Labeled') {
                    const value = content.children[2];
                    const type = getType(value, model, getScope(parentBlock._id, 0, model, false, args.globals), args, debug);
                    if (type instanceof FunctionType) {
                      return type.getClosureArgType(parentBlock.children.indexOf(block), 0);
                    }
  
                  }
                }
                else {
                  const type = getType(parentBlock.cst, model, getScope(parentBlock._id, 0, model, false, args.globals), args, debug);
                  if (type instanceof FunctionType) {
                    return type.getClosureArgType(parentBlock.children.indexOf(block), 0);
                  }
  
                }
  
              }
            }
          }
          return new AnyType('asdf');
        }
      }
    }
  
    if (block.embeddedClosures) for (const { closure } of block.embeddedClosures) {
      for (const param of closure.params) {
        if (param.col == col) {
          const tree = getCst(block.cst, col);
          if (tree[tree.length - 4]?.[0]?.ruleName?.startsWith?.('FunctionCall_')) {
            // console.log();
            const functionCall = tree[tree.length - 4][0];
            const scope = getScope(block._id, functionCall.absoluteOffset, model, false, args.globals);
            const functionType = getType(functionCall.children[0], model, scope, args, true);
  
            if (functionType instanceof FunctionType) {
              return functionType.getClosureArgType(tree[tree.length - 2][1], tree[tree.length - 1][1]);
  
            }
            else {
              return new AnyType;
            }
  
          }
        }
      }
    }
  }
  else {
    console.log(varId);

    return null;
  }

};
enum DefinedTypes {
  Document = '498d1dd5-81ae-53e3-835d-cf9e443dc95c',
  Block = 'bbcabb5e-eac8-5f08-b8d4-747bffc8a716',
  Entity = 'bb2de013-511c-5261-8715-1b8e9eef9c43'
}
class DefinedType extends Type {
  constructor(args: { id: string; }) {
    super();
    this.id = args.id;
  }

  id;

  humanString() {
    return _.invert(DefinedTypes)[this.id];
  }

  // getProperty(name) {
  //   return definedTypes[this.id].properties.find(p => p.name == name)?.type || new AnyType;
  // }
  getProperties() {
    return definedTypes[this.id].properties;
  }
}
const definedTypes = {
  [DefinedTypes.Document]: new StructType({
    properties: [
      {
        name: 'blocks',
        type: new FunctionType({
          returnType: new ArrayType({
            elType: new DefinedType({ id: DefinedTypes.Block }),
          }),
        }),
      },
      {
        name: 'block',
        type: new FunctionType({
          returnType: new DefinedType({ id: DefinedTypes.Block }),
        }),
      },
    ],
  }),
  [DefinedTypes.Block]: new StructType({
    properties: [
      {
        name: 'attributes',
        type: new AnyType(),
      },
      {
        name: 'checked',
        type: new BooleanType(),
      }
    ],
  }),
  [DefinedTypes.Entity]: new StructType({
    properties: [
      {
        name: 'children',
        type: new ArrayType({
          elType: new DefinedType({
            id: DefinedTypes.Entity
          })
        })
      },
      {
        name: 'getFormattedAttr',
        type: new FunctionType({
          returnType: new AnyType,
        })
      },
      {
        name: 'type',
        type: new AnyType,
      },
      {
        name: 'id',
        type: new StringType,
      },
    ],
  }),
};
export const globals = {
  // props: new AnyType(),
  navigate: new StructType({
    name: 'Navigate',
    properties: [
      {
        name: 'push',
        type: new AnyType(),
      },
      {
        name: 'location',
        type: new AnyType(),
      }
    ],
  }),
  component: new FunctionType({}),
  Now: new FunctionType({}),
  this: new AnyType(),
  db: new AnyType(),
  router: new FunctionType({}),
  Document: new FunctionType({
    returnType: new DefinedType({ id: DefinedTypes.Document }),
    params: [
      {
        name: 'id',
        type: new StringType(),
      }
    ]
  }),
  Entities: new FunctionType({
    returnType: new ArrayType({
      elType: new DefinedType({
        id: DefinedTypes.Entity
      })
    })
  }),
  Entity: new FunctionType({
    returnType: new DefinedType({
      id: DefinedTypes.Entity
    }),
    params: [
      {
        name: 'id',
        type: new StringType(),
      }
    ]
  }),
  ParseSeconds: new FunctionType({
    returnType: new NumberType,
  }),
};

export const buildModel = (blocks=[], additionalTypes) => {
  const iter = (blocks: ScriptBlock[], parentType?) => {
    const m = [];
    if (blocks) for (const block of blocks) {
      if (!block) continue;
      if (parentType == 'comment') {
        const b = {
          _id: block._id,
          type: 'comment',
          children: iter(block.children, 'comment'),
        };
        m.push(b);
      }
      else {
        /*const nodeType = (cst, pos=0) => {
          if (!cst) return;
          let r;
          if (cst.ruleName == 'DeclareConst') {
            r = {
              type: 'declare',
              identifier: text.slice(pos, pos + cst.children[0].matchLength),
              identifierCol: pos,
              
              varType: nodeType(cst.children[2].children[0], pos + cst.childOffsets[2])?.[1],
            };
          }
          else if (cst.ruleName == 'Expr_for') {
            r = {
              type: 'for-of',
              identifier: text.slice(cst.childOffsets[1], cst.childOffsets[1] + cst.children[1].matchLength),
              identifierCol: cst.childOffsets[1],
              identifierColEnd: cst.childOffsets[1] + cst.children[1].matchLength,
            };
          }
          else if (cst.ruleName == 'Closure_singleIdentifier') {
            const start = pos + cst.childOffsets[0];
            r = {
              type: 'closure',
              params: [
                { name: text.slice(start, start + cst.children[0].matchLength),
                col: start, colEnd: start + cst.children[0].matchLength
                }
              ],
            };
          }
          else if (cst.ruleName == 'Closure_identifierList') {
            const paramCsts = resolveListOf(cst.children[2]);
            const start = pos + cst.childOffsets[2];
 
            r = { type: 'closure',
            params: paramCsts.map(([offset, cst]) => {
              return {
                name: text.slice(start + offset, start + offset + cst.matchLength),
                col: start + offset,
              }
            }),
            // params: [
            //   { name: text.slice(start, start + cst.children[0].matchLength),
            //   col: start, colEnd: start + cst.children[0].matchLength
            //   }
            // ],
            };
          }
          else if (cst.children?.length == 1) {
            return nodeType(cst.children[0], pos);
          }
 
          return [cst, r]
        }*/
        const text = expandFormula(block.data, {
          types: additionalTypes
        });

        let type;
        let childrenType;
        let attrs:any = {};
        let blockType;

        if (text[0] == '#') {
          type = 'comment';
        }
        else if (text[0] == '~') {
          type = 'styledComponent';
          const wrappedCst = flexGrammar.match(text)['_cst'];
          const cst = unwrapCst(wrappedCst, text);
          attrs.cst = cst;
          // blockType = 'styledComponent';
        }

        else {
          const wrappedCst = flexGrammar.match(text)['_cst'];
          if (wrappedCst) {
            const cst = unwrapCst(wrappedCst, text);
            if (!cst) {
              console.log(text, wrappedCst);
            }

            let r: any = {};
            const pos = 0;

            const getClosure = (cst, start) => {
              let params = [];

              if (cst.ruleName == 'Closure_singleIdentifier') {
                params.push({
                  name: text.slice(start, start + cst.children[0].matchLength),
                  col: start,
                  colEnd: start + cst.children[0].matchLength,
                });
              }
              else if (cst.ruleName == 'Closure_identifierList') {
                params = cst.children[2].map(param => {
                  const pos = start + param[0] + cst.childOffsets[2];
                  return {
                    name: text.slice(pos, pos + param[1].matchLength),
                    col: pos,
                    colEnd: pos + param[1].matchLength,
                  };
                });
              }
              return {
                col: start,
                colEnd: start + cst.matchLength,
                params,
              };
            };

            const getEmbeddedClosures = (cst, start) => {
              const closures = [];
              const iter = (cst, pos) => {
                if (_.isArray(cst)) {
                  for (const el of cst) {
                    iter(el[1], pos + el[0]);
                  }
                }
                else {
                  if (!cst) return;
                  if (cst?.ruleName?.startsWith?.('Closure')) {
                    closures.push({ pos, closure: getClosure(cst, pos), cst });
                  }
                  else if (cst.children) {
                    for (let i = 0; i < cst.children.length; ++i) {
                      iter(cst.children[i], pos + cst.childOffsets[i]);
                    }
                  }
                }
              };

              iter(cst, start);

              return closures;

            };

            if (cst.ruleName == 'DeclareConst') {
              type = 'declare';
              r = {
                identifier: text.slice(pos, pos + cst.children[0].matchLength),
                identifierCol: pos,
              };

              if (cst.children[2]?.ruleName?.startsWith?.('Closure')) {
                r.blockClosure = getClosure(cst.children[2], cst.childOffsets[2]);
              }
              else {
                r.embeddedClosures = getEmbeddedClosures(cst.children[2], cst.childOffsets[2]);
              }
            }
            else if (cst.ruleName == 'Labeled') {
              if (cst.children[2]?.ruleName?.startsWith?.('Closure')) {
                r.blockClosure = getClosure(cst.children[2], cst.childOffsets[2]);
              }
              else {
                r.embeddedClosures = getEmbeddedClosures(cst.children[3], cst.childOffsets[3]);
              }
            }
            else if (cst.ruleName == 'PipedLine') {
              if (cst.children[1]?.ruleName == 'Labeled') {
                const labeldCst = cst.children[1];
                if (labeldCst.children[2]?.ruleName?.startsWith?.('Closure')) {
                  r.blockClosure = getClosure(labeldCst.children[2], cst.childOffsets[1] + labeldCst.childOffsets[2]);
                }
                else {
                  r.embeddedClosures = getEmbeddedClosures(labeldCst.children[3], labeldCst.childOffsets[3]);
                }
              }
              else if (cst.children[1]?.ruleName == 'Binding') {
                const bindingCst = cst.children[1];
                if (bindingCst.children[3]?.ruleName?.startsWith?.('Closure')) {
                  r.blockClosure = getClosure(bindingCst.children[3], cst.childOffsets[1] + bindingCst.childOffsets[3]);
                }
                else {
                  // r.embeddedClosures = getEmbeddedClosures(bindingCst.children[3], bindingCst.childOffsets[3]);
                }
              }
            }
            else if (cst.ruleName == 'Expr_for') {
              type = 'for-of';
              r = {
                identifier: cst.children[1] && text.slice(cst.childOffsets[1], cst.childOffsets[1] + cst.children[1].matchLength),
                identifierCol: cst.children[1] && cst.childOffsets[1],
                identifierColEnd: cst.children[1] && cst.childOffsets[1] + cst.children[1].matchLength,
                embeddedClosures: getEmbeddedClosures(cst.children[3], cst.childOffsets[3]),
              };
            }
            else if (cst.ruleName?.startsWith?.('Closure')) {
              r.blockClosure = getClosure(cst, 0);
            }
            else {
              r = {
                embeddedClosures: getEmbeddedClosures(cst, 0),
              };
            }

            attrs = {
              cst,
              ...r,
            };
          }
        }

        if (text.includes('~')) {
          blockType = 'styledComponent';
        }

        const b = {
          _id: block._id,
          type,
          blockType,
          children: iter(block.children, type),
          source: text,
          ...attrs,
        };

        m.push(b);
      }

    }
    return m;
  };
  const model = iter(blocks);
  return model;
};

