import React, { Component } from 'react';
import { component } from './component2';
import CytoscapeComponent from 'react-cytoscapejs';
import cytoscape from 'cytoscape';
import euler from 'cytoscape-euler';
import d3Force from 'cytoscape-d3-force';
import { styled } from './component';
import { DevRuntime } from './DevRuntime';
import { componentTypeId } from './componentTypeId';
import { XObject, x } from './XObject';

@component
export class Graph extends Component<{ devRuntime: DevRuntime }> {
  static styles = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  `;
  render() {
    const { devRuntime } = this.props;
    const componentReferences = {};

    const allNodes = [];
    for (const comp of devRuntime._components()) {
      if (comp.type == 'builderComponent') {
        allNodes.push(comp._id);
        componentReferences[comp._id] = [];
        const iterBlocks = (blocks) => {
          for (const block of blocks) {
            if (XObject.isArray(block.data)) {
              for (const el of block.data) {
                if (el[1] == 'component') {
                  componentReferences[comp._id].push(el[0])
                }
              }  
            }
            if (block.children) {
              iterBlocks(block.children);
            }
          }
        }
        const iterNodes = (node) => {
          if (node.script) {
            iterBlocks(node.script);
          }
          if (node.children) {
            for (const child of node.children) {
              iterNodes(child);
            }
          }
        }

        iterNodes(comp.root);

      }
      else if (!comp.type) {
        componentReferences[comp._id] = [];

        allNodes.push(comp._id);
        const iterBlocks = (blocks) => {
          for (const block of blocks) {
            if (XObject.isArray(block.data)) {
              for (const el of block.data) {
                if (el[1] == 'component') {
                  componentReferences[comp._id].push(el[0])
                }
              }  
            }
            if (block.children) {
              iterBlocks(block.children);
            }
          }
        }

        iterBlocks(comp.blocks);

        

      }
    }

    console.log(componentReferences);



    const elements: {
      data: {
        id?: string;
        type?: 'entity' | 'edge' | 'value' | 'statement';
        label?: string;
        source?: string;
        target?: string;
        active?
        directed?
        core?
        // metadata?

        connectionType?: 'property' | 'edge' | 'pageLink';

        entityType?
      }
    }[] = [
    ];

    for (const comp of this.props.devRuntime._components()) {
      const id = comp._id;
      const type = this.props.devRuntime.devProject.builderComponentTypes.find(t => t._id == comp.builderComponentType)
      elements.push({
        data: {
          id: id,
          // label: !type ? comp.name : `${comp.name} (${type.name})`,
          label: comp.name,
          type: 'entity',
        }
      });


      if (componentReferences[id]) {
        for (const ref of componentReferences[id]) {
          elements.push({
            data: {
              id: id + ref,
              type: 'edge',
              source: id,
              target: ref,
              directed: true,
            }
          })
        }
  
      }
    }



    return (
      <>
        <CytoscapeComponent
          style={ { width: '100%', height: '100%' } }
          elements={elements}
          stylesheet={[
            {
              selector: 'node',
              style: {
                'background-color': '#666',
                'label': 'data(label)',
              }
            },
            {
              selector: 'edge[?directed]',
              style: {
                'curve-style': 'straight',
                'target-arrow-shape': 'triangle',
                'target-arrow-color': 'red',

                // 'line-color': 'red',

              }
            },

          ]}
          layout={
            {
              name: 'd3-force',
              animate: true,
              maxIterations: 1000,
              fixedAfterDragging: false,
              linkId: function id(d) {
                return d.id;
              },
              linkDistance: 80,
              manyBodyStrength: -300,
              ready: function(){},
              stop: function(){},
              tick: function(progress) {
                // console.log('progress - ', progress);
              },
              randomize: false,
              infinite: true
              // some more options here...

            } as any
          }
        />
      </>
    );
  }
}
