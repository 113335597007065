import { db } from "../../db";
import { evaluate } from "../main";
import { XObject } from "../../XObject";
import { createEntity } from "../../etc/createEntity";
import { pushEdge } from "../../getAllEdges";

export function executeCreate(value, map) {
  const create = evaluate(value, map);
  const newEntity = XObject.obj({
    type: create.type,
    space: create.space,
  });

  createEntity(newEntity, create.parent);

  if (create.parent) {
    pushEdge(XObject.obj({
      entities: [create.parent, newEntity._id],
      directed: true,
    }));
  }

  return newEntity;
}
