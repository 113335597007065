import jQuery from 'jquery';
import React from 'react';
import { ObjectType } from '../types/ObjectRef';
import { concatData, dataLength, sliceData } from '../richTextHelpers';
import ReactDOMClient from "react-dom/client";
import { substitute } from './substitute';
import { ObjectSelector } from '../ObjectSelector';

export function presentObjectMenu(args, parent) {
  const cont = jQuery('<div />').css({
    position: 'absolute',
    left: 0,
    right: 0,
    top: args.top,
    bottom: 0,
    zIndex: 9999999,
  });

  jQuery('body').append(cont);
  const root = ReactDOMClient.createRoot(cont[0]);
  const ref = React.createRef<ObjectSelector>();

  root.render(
    <ObjectSelector
      parent={parent}
      ref={ref}
      onSelect={obj => {
        const ctx = args.ctx;
        const setValue = args.setValue;
        const e = obj.data;
        const data = args.value();
        const position = args.menuPos;
        const length = dataLength(ctx, data);
        const firstPart = sliceData(ctx, data, 0, position);
        const secondPart = sliceData(ctx, data, position, length);
        if (e.type == ObjectType.entity) {
          setValue(concatData(ctx, concatData(ctx, firstPart, [[e.id, 'entity']]), secondPart));
        }
        else if (e.type == ObjectType.document || e.type == ObjectType.event || e.type == ObjectType.database || e.type == ObjectType.type) {
          setValue(concatData(ctx, concatData(ctx, firstPart, [[e, 'object']]), secondPart));
        }
        else if (e.type == ObjectType.query) {
          setValue(concatData(ctx, concatData(ctx, firstPart, [[e.id, 'query']]), secondPart));
        }
        else if (e.type == ObjectType.identifier) {
          setValue(concatData(ctx, concatData(ctx, firstPart, [[e.id, 'identifier']]), secondPart));
        }
        else if (e.type == ObjectType.attribute) {
          substitute(args, [e.id, 'attribute']);
        }
        else if (e.type == ObjectType.objectHandle) {
          substitute(args, [e.id, 'objectHandle']);
        }
        else if (e.type == ObjectType.valuePoint) {
          setValue(concatData(ctx, concatData(ctx, firstPart, [[e.id, 'valuePoint']]), secondPart));
        }

        args.actionChosen();
      }} />
  );

  return {
    closeMenu() {
      return new Promise<void>(resolve => {
        root.unmount();
        cont.remove();
        resolve();
      });
    },
    enter() {
      ref.current.enter();
    },
    down() {
      ref.current.down();
    },
    up() {
      ref.current.up();
    },
    setFilter(filter) {
      ref.current.setFilter(filter);
    },
  };
}
