import React from 'react';
import {component} from '../../component2';
import {db} from '../../db';
import {Comp} from '../Comp';
import {ValuePointProps} from '../main';
import {registerType} from "../__typeRegistry";
import {ValueType} from "../ValueType";
import { registerTypeRegister } from '../typeRegistering';

@component
export class RelativeQueryValuePoint extends Comp<ValuePointProps> {
  render() {
    return (
      <>
        <select
          value={this.props.state.content || ''}
          onChange={(e) => {
            this.props.state.content = e.target.value || null;
          }}
        >
          <option value="">Any</option>
          {db.queries.filter(q => q.relative).map((x, i) => <option key={i} value={x._id}>{x.name}</option>)}
        </select>
      </>
    );
  }
}

export const registerRelativeQueryType = () => {
  registerType(ValueType.RelativeQuery, {
    evaluate: (value, map) => {
      return value.content;
    },
    editorComp: RelativeQueryValuePoint,
  });
};

registerTypeRegister(registerRelativeQueryType)