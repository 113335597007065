import React, { Component } from 'react';
import styled from 'styled-components';
import { component } from '../../component2';
import { XObject, x } from '../../XObject';
import { PropertyField } from '../../components/PropertyField';
import { db } from '../../db';
import { defaultWorkspace } from '../../etc/defaultWorkspace';
import { EntityGraphView } from '../../components/EntityGraphView';
import { resolvePath } from '../../etc/queryGraph';
import { Root } from '../../components/Root';
import { entityMetaStates } from '../../etc/entityMatch';
import { EntityHandle } from '../../components/EntityHandle';
import { renderLinearIssuesLinear } from '../../etc/renderLinearIssues';
import { linearData } from '../../etc/linearData';
import { renderQueuePositions } from '../getQueue';
import { EntityDisplay } from '../../components/EntityDisplay';
import { entityTypeName } from '../../etc/entityTypeName';
import { ValueType } from '../ValueType';
import { structRenderers } from '../structRenderers';
import { mapStructure } from '../main';
import { doEntityQuery } from "../doEntityQuery";
import { typeRegistry } from "../typeRegistry.1";
import { appState } from '../../etc/appState';
import { LinearToolbar, filterLinearIssues } from '../../components/LinearWindow';
import { getAllEntities, getEntityById } from '../../etc/createEntity';

const $Browser = typeRegistry.registerType({
  $: '711739e2-4869-5261-9d90-518b836b11ed',
  RootEntity: '0f7216ba-d5a2-542e-9ca2-dc0640119421',
  OverviewGraph: 'ecb1aec7-0002-577f-b800-bd3c58b130bf',
  OverviewCount: '080f345a-fafe-50c6-b3f1-f9fe4d70c3ff',
  ContentsGraph: '23631ae8-30ff-5939-9731-5b7f3f9b0aa1',
  PathGraph: '1cc2f36e-0f0c-525b-b033-33f1d3233525',
}, ids => ({
  _id: ids.$,
  name: 'Browser',
  definition: [
    {
      id: ids.RootEntity,
      name: 'Root Entity',
      type: [ValueType.Entity],
      property: 'rootEntity',
    },
    {
      id: ids.OverviewGraph,
      name: 'Overview Graph',
      type: [],
      property: 'overviewGraph',
    },
    {
      id: ids.ContentsGraph,
      name: 'Contents Graph',
      type: [],
      property: 'contentsGraph',
    },
    {
      id: ids.OverviewCount,
      name: 'Overview Count',
      type: [],
      property: 'overviewCount',
    },
    {
      id: ids.PathGraph,
      name: 'Path Graph',
      type: [],
      property: 'pathGraph',
    }
  ]
}));
const Wrapper = styled.div`

  .metaStates {

  }

  .left {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    border-right: 1px solid #ccc;
  }

  .right {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    overflow: auto;

    .filters {
      > span {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        margin-right: 6px;
      }
      border-bottom: 1px solid #ccc;
      padding: 4px 6px;
    }

    .entity {
      position: relative;
      /* .metaStates {
        position: absolute;
        right: 0;
        top: 0;
      } */
    }
  }

  .path {
    font-size: 0.8em;
    color: gray;
  }

  .type {
    font-size: 0.8em;
    color: gray;
  }
`;
export function renderSplitGraph({ entity: rootEntity, overviewGraph, contentsGraph, pathGraph, state, overviewCount = undefined }) {
  const workspace = defaultWorkspace();
  const content = state.selected ? doEntityQuery(contentsGraph, state.selected) : [];

  const c = overviewCount || contentsGraph;

  return (
    <Wrapper>
      <div className="left">
        <EntityGraphView
          onSelect={id => {
            // args.onInspect(id);
          }}
          graph={overviewGraph}
          rootEntity={rootEntity}
          state={state}
          label={entity => {
            if (c) {
              const count = doEntityQuery(c, entity).length;
              return getEntityById(entity).name + ' (' + count + ')';
            }
            else {
              return getEntityById(entity).name;
            }
          }} />
      </div>
      <div className="right">
        <ul>
          {content.map((c, i) => {
            const path = resolvePath(pathGraph, rootEntity, c.entity);
            const entity = getEntityById(c.entity);
            const type = entity.type && db.entityTypes.findById(entity.type);
            return <li className="entity" style={{ marginBottom: '4px' }} onClick={() => {
              // args.onInspect(c.entity);
            }} data-value={c.entity} key={i}>
              <div className="type">{type && entityTypeName(type._id)}</div>
              <PropertyField object={entity} property="name" /> <EntityHandle id={c.entity}><button onClick={() => {
                // args.onSelectChild(c.entity);
              }}>&gt;</button></EntityHandle>
              <div className="path">{path.map(id => getEntityById(id).name).join(' / ')}</div>

              <div className="metaStates">{entityMetaStates(c.entity).map(id => workspace.metaStates.find(i => i._id == id).name).join(', ')}</div>
            </li>;
          })}
        </ul>
      </div>
    </Wrapper>
  );
}


@component
export class LinearIssues extends Component<{
  toolbar?
  ids;
  onClick;
  renderPath?;
  disableCols?;
  active?;
  rootEntity?
  graph?
  sort?
  filter?
}> {

  render() {
    const data = linearData();
    if (!data) {
      return null;
    }
    const issues = this.props.ids.map(id => data.issues.find(i => i.id == id)).filter(Boolean);

    return (
      <>
        {this.props.toolbar && (
          <>
            <LinearToolbar issues={issues} state={this.props.toolbar} />
          </>
        )}
        {renderLinearIssuesLinear({
          issues: this.props.filter ? filterLinearIssues(issues, this.props.filter) : issues,
          data,
          active: this.props.active,
          onClick: this.props.onClick,
          renderPath: this.props.renderPath,
          disableCols: this.props.disableCols,
          groupByState: this.props.toolbar.groupByState,
          sort: this.props.sort,
          rootEntity: this.props.rootEntity,
          graph: this.props.graph
        })}
      </>
    )
  }
}


structRenderers[$Browser.$] = (value, map, state) => {

  if (state.statefulFirst === undefined) {
    state.statefulFirst = true;
  }

  const mapped = mapStructure(value);
  let content = state.selected ? doEntityQuery(mapped.contentsGraph, state.selected) : [];

  if (state.onlyQueued) {
    const workspace = defaultWorkspace();
    const queues = workspace.queues.filter(q => q.entity == mapped.rootEntity.content);
    content = content.filter(c => {
      if (c.entity == state.selected)
        return true;
      if (queues.find(q => q.list.includes(c.entity)))
        return true;
      return false;
    });
  }

  if (state.hideQueued) {
    const workspace = defaultWorkspace();
    const queues = workspace.queues.filter(q => q.entity == mapped.rootEntity.content);
    content = content.filter(c => {
      if (c.entity == state.selected)
        return true;

      if (queues.find(q => q.list.includes(c.entity)))
        return false;
      return true;
    });

  }

  if (state.hideStateless) {
    content = content.filter(c => {
      if (c.entity == state.selected)
        return true;
      const entity = getEntityById(c.entity);
      return entity.stateful;
    });
  }

  if (state.statefulFirst) {
    // sort content so that stateful entities (entity.stateful) are first
    content.sort((a, b) => {
      // state.selected is always first
      if (a.entity == state.selected)
        return -1;
      if (b.entity == state.selected)
        return 1;

      const aStateful = getEntityById(a.entity).stateful;
      const bStateful = getEntityById(b.entity).stateful;
      if (aStateful && !bStateful)
        return -1;
      if (!aStateful && bStateful)
        return 1;
      return 0;
    });

  }


  const rootEntity = (() => {
    if (mapped.rootEntity.type?.[0] == ValueType.Entity) {
      return mapped.rootEntity.content;
    }
    else if (mapped.rootEntity.type?.[0] == ValueType.Param) {
      return map[mapped.rootEntity.content.id];
    }
  })();

  console.log('rootEntity', map, rootEntity, x(mapped.rootEntity));


  return (
    <Root
      drillDownState={XObject.get(state, 'drillDownState', {})}
      hideInspectPane
      graph={{
        graph: mapped.pathGraph.content,
        root: rootEntity,
      }}
      root={{
        dontShowLine: true,
        width: state.showLinearIssues ? '900px' : '900px',

        children: () => {
          if (state.showLinearIssues) {
            return content.map(c => {
              const entity = getEntityById(c.entity);
              if (entity.linearId) {
                return entity._id;
              }
            }).filter(Boolean);
          }
          else {
            return content.map((c, i) => {
              return c.entity;
            });

          }
        },
        render: args => {
          const workspace = defaultWorkspace();
          return (
            <Wrapper>
              <div className="left">
                <EntityGraphView
                  onSelect={id => {
                    // args.onInspect(id);
                    appState.inspecting = {
                      type: 'entity',
                      id: id,
                    }
                  }}
                  graph={mapped.overviewGraph.content}
                  rootEntity={rootEntity}
                  state={state}
                  label={entity => {
                    if (mapped.overviewCount) {
                      let c = doEntityQuery(mapped.overviewCount, entity);
                      c = c.filter(e => {
                        // if (e.entity == state.selected) return false;
                        return true;
                      });

                      if (state.onlyQueued) {
                        const queues = workspace.queues.filter(q => q.entity == rootEntity);
                        c = c.filter(e => {
                          if (queues.find(q => q.list.includes(e.entity)))
                            return true;
                          return false;
                        });
                      }

                      if (state.hideQueued) {
                        const queues = workspace.queues.filter(q => q.entity == rootEntity);
                        c = c.filter(e => {
                          if (queues.find(q => q.list.includes(e.entity)))
                            return false;
                          return true;
                        });
                      }

                      if (state.hideStateless) {
                        c = c.filter(e => {
                          return getEntityById(e.entity).stateful;
                        });
                      }




                      const count = c.length;
                      return getEntityById(entity).name + ' (' + count + ')';
                    }
                    else {
                      return getEntityById(entity).name;
                    }
                  }} />
              </div>
              <div className="right">

                <div className="filters">
                  <span
                    onClick={() => {
                      state.showLinearIssues = !state.showLinearIssues;
                    }}
                  >
                    <input type="checkbox" checked={state.showLinearIssues} /> Linear issues</span>

                  <span
                    onClick={() => {
                      state.statefulFirst = !state.statefulFirst;
                    }}
                  ><input type="checkbox" checked={state.statefulFirst} /> Stateful first</span>

                  <span
                    onClick={() => {
                      state.hideStateless = !state.hideStateless;
                    }}
                  ><input type="checkbox" checked={state.hideStateless} /> Only stateful</span>

                  <span
                    onClick={() => {
                      state.hideQueued = !state.hideQueued;
                      if (state.hideQueued) {
                        state.onlyQueued = false;
                      }
                    }}><input type="checkbox" checked={state.hideQueued} /> Only unqueued</span>

                  <span

                    onClick={() => {
                      state.onlyQueued = !state.onlyQueued;
                      if (state.onlyQueued) {
                        state.hideQueued = false;
                      }
                    }}
                  ><input type="checkbox" checked={state.onlyQueued} /> Only queued</span>


                </div>


                {state.showLinearIssues && (() => {

                  const ids = content.map(c => {
                    const entity = getEntityById(c.entity);
                    return entity.linearId;
                  }).filter(Boolean);

                  return <LinearIssues ids={ids} onClick={linearId => {
                    const entity = getAllEntities().find(e => e.linearId == linearId);
                    args.onSelectChild(entity._id);
                    appState.inspecting = {
                      type: 'entity',
                      id: entity._id,
                    }
                  }}
                    renderPath={entityId => {
                      const path = resolvePath(mapped.pathGraph.content, rootEntity, entityId);

                      return <div><span className="path">{path.map(id => getEntityById(id).name).join(' / ')}</span> {renderQueuePositions(rootEntity, entityId)}</div>;
                    }}

                    disableCols={{
                      state: true,
                      entity: true,
                      parent: true,
                    }} />;
                })()}

                {!state.showLinearIssues && (
                  <ul>
                    {content.map((c, i) => {
                      const path = resolvePath(mapped.pathGraph.content, rootEntity, c.entity);
                      const entity = getEntityById(c.entity);
                      const type = entity.type && db.entityTypes.findById(entity.type);
                      return <li className="entity" data-inspect-id={c.entity} style={{ marginBottom: '4px' }} onClick={() => {
                        // args.onInspect(c.entity);
                        appState.inspecting = {
                          type: 'entity',
                          id: c.entity,
                        }
                      }} data-value={c.entity} key={i}>
                        {/* <div className="type">{type?.name}</div>
                                                  <PropertyField object={entity} property="name" /> {getQueuePosition(mapped.rootEntity.content, c.entity)} */}

                        <EntityDisplay id={c.entity} root={rootEntity} />

                        <button onClick={() => {
                          args.onSelectChild(c.entity);
                        }}>&gt;</button>
                        <div className="path" data-graph={mapped.pathGraph.content} data-root-entity={rootEntity}>{path.map(id => getEntityById(id).name).join(' / ')}</div>

                        {/* <div className="metaStates">{entityMetaStates(c.entity).map(id => workspace.metaStates.find(i => i._id == id).name).join(', ')}</div> */}
                      </li>;
                    })}
                  </ul>
                )}
              </div>
            </Wrapper>
          );
        }
      }} />

  );
};
