import { getAllNondeletedEntities, getEntityById } from '../etc/createEntity';
import { entityDisplayName } from './entityDisplayName';
import { getEmbeddingsReactive } from '../getEmbeddings';
import * as ml from 'ml-distance';



export function relatedEntities(entityId, min = 0) {
  const entity = getEntityById(entityId);
  if (entity.type) {
    const entities = getAllNondeletedEntities().filter(e => e._id != entity._id && e.type == entity.type);
    const text = entities.map(e => entityDisplayName(e._id));
    const embeddings = getEmbeddingsReactive([entityDisplayName(entity._id), ...text]);
    if (embeddings) {
      const sims = [];
      const thisEmbedding = embeddings[0];
      for (let i = 1; i < embeddings.length; ++i) {
        const sim = ml.similarity.cosine(thisEmbedding, embeddings[i]);
        sims.push([entities[i - 1], sim]);
      }

      sims.sort((a, b) => b[1] - a[1]);

      return sims.filter(x => x[1] >= min);
    }
  }
  return [];
}
