import { db } from '../db';
import { appState } from '../etc/appState';

export function modeOccurrences() {
  return db.eventOccurrences.filter(occurrence => occurrence.mode == appState.currentMode);
}

export function getCurrentOccurrence() {
  return db.eventOccurrences.find(occurrence => occurrence.mode == appState.currentMode && occurrence.start && !occurrence.stop);
}

export function getLastOccurrence() {
  const occurrences = modeOccurrences();
  return occurrences[occurrences.length - 1];
}

export function occurranceArguments(occurrence) {
  return occurrence.arguments || occurrence.parameterizedEvent && db.parameterizedEvents.findById(occurrence.parameterizedEvent).arguments;
}
