import { Component } from "react";
import { Type } from "../richTextHelpers";
import { component } from "../component";
import { appState, setAppInspect } from "../etc/appState";
import { InspectState } from "../components/InspectState";
import { GlueView, getValuePoint } from "../glue/main";
import { X } from "../XObject";
import { types } from "./types";
import { explicitInspectObj } from "../inspectObj";
import { ObjectType } from "../types/ObjectRef";
import { SystemContext } from "../etc/SystemContext";



@component
class ValuePointMount extends Component<{ id; args }> {
  state = X({});
  render() {
    const vp = getValuePoint(this.props.id);

    return (
      <span
        onContextMenu={e => {
          e.preventDefault();
          setAppInspect({
            mode: InspectState.valuePoint,
            id: this.props.id,
          });

          // explicitInspectObj({
          //   type: ObjectType.valuePoint,
          //   id: this.props.id,
          // })
        }}

      >
        <SystemContext.Provider value={{
          navigate: () => {},
          contextArgs: {
            [vp.parameters[0]?._id]: this.props.args?.pageId,
          },
        }}>
          <GlueView id={this.props.id} args={{}} state={this.state} />
        </SystemContext.Provider>
        
      </span>
    );
  }
}
types['valuePoint'] = {
  type: Type.atomic,
  html: (id, comps, args) => {
    const n = Math.random();
    comps[n] = {
      mount: <ValuePointMount id={id.valuePoint} args={args} />
    };
    return `<span class="--valuePoint--" contenteditable="false" data-type="valuePoint" data-valuePoint-data="${btoa(JSON.stringify(id))}"><span data-mount-point="${n}"></span></span>`;
  },
  part: el => {
    const data = JSON.parse(atob(el.getAttribute('data-valuePoint-data')));
    return [data, 'valuePoint'];
  }
};
