import { structRenderers } from '../structRenderers';
import { typeRegistry } from '../typeRegistry.1';
import { evaluate, mapStructure } from '../main';
import { EntityGraphView } from '../../components/EntityGraphView';
import { doEntityQuery } from '../doEntityQuery';
import { defaultWorkspace } from '../../etc/defaultWorkspace';
import { db } from '../../db';
import { resolvePath } from '../../etc/queryGraph';
import { styled } from '../../component';
import { XObject } from '../../XObject';
import { useContext } from 'react';
import { SystemContext } from '../../etc/SystemContext';
import { LinearIssues } from './$Browser';
import { renderQueuePositions } from '../getQueue';
import { LinearToolbar, defaultState } from '../../components/LinearWindow';
import { linearData } from '../../etc/linearData';
import { appState } from '../../etc/appState';
import { createEntity, getAllEntities, getEntityById } from '../../etc/createEntity';
import { EntityRow } from '../../components/EntityRow';
import { groupById } from './groupById';
import { getEntityZIndex, renderAttributeValue } from './renderAttributeValue';
import { NotionButton } from '../../components/AddButton';
import { pushEdge } from '../../getAllEdges';

const $Browser2 = typeRegistry.registerType({
  $: '71a8890d-cf6e-5de8-b492-69c8f52bb800',
  OverviewGraph: '4ebc4193-d644-5992-abb2-576776901c8b',
  PathGraph: 'd4200358-5a25-5fd0-a6fe-e59706559f72',
  Contents: 'b2d51303-ee4d-5d34-8ffc-d257160cd042',
  RootEntity: '3c12449b-1455-5730-80b8-e3d8704caa05',
  GroupBy: '0785e644-b6ca-555c-80b5-257a6e511d98',
}, ids => ({
  _id: ids.$,
  name: 'Browser 2',
  definition: [
    {
      id: ids.OverviewGraph,
      name: 'Graph',
      type: [],
      property: 'overviewGraph',
    },
    {
      id: ids.Contents,
      name: 'Contents',
      type: [],
      property: 'contents',
    },
    {
      id: ids.GroupBy,
      name: 'Group By',
      type: [],
      property: 'groupBy',
    },
    {
      id: ids.RootEntity,
      name: 'Root Entity',
      type: [],
      property: 'rootEntity',
    },
    {
      id: ids.PathGraph,
      name: 'Path Graph',
      type: [],
      property: 'pathGraph',
    },
  ]
}));

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;

  .metaStates {

  }


    .group {
      margin-bottom: 8px;

      .groupName {
        font-size: 14px;
        font-weight: bold;
      }
    }

  > * {
    flex: 0 0 auto;
  }

  .left {
    position: relative;
    width: 400px;

    border-right: 1px solid #ccc;
  }



  .right {
    width: 600px;
    max-height: 100%;
    border-right: 1px solid #ccc;

    overflow: auto;

    .top {
      display: flex;
      align-items: center;
      padding: 4px 6px;
      border-bottom: 1px solid #ccc;
      ${NotionButton} {
        margin-left: auto;
      }

    }

    .filters {
      > span {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        margin-right: 6px;
      }
      border-bottom: 1px solid #ccc;
      padding: 4px 6px;
    }

    .entities {
      padding: 8px;
      .entity {
        display: flex;
        height: 44px;
        align-items: center;
        border-bottom: 1px solid #ccc;
        padding: 0 16px;


        .col {
          &:not(:last-child) {
            margin-right: 9px;
          }

          white-space: nowrap;

          &.name {
            display: flex;
            align-items: center;
            min-width: 0;
            flex-shrink: 1;
            > .name {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            > .path {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: #9d9d9d;
              margin-left: 4px;
              font-size: 8px;
              .component {
                margin-right: 4px;
                &:before {
                  content: "›";
                  margin-right: 4px;
                }
              }
            }
          }

          &.spacer {
            margin-left: auto;
          }

          &.type {
            white-space: nowrap;
            height: 20px;
            border-radius: 3px;
            padding-left: 6px;
            padding-right: 0px;
            font-size: 14px;
            line-height: 120%;
            color: rgb(50, 48, 44);
            background: rgb(250, 222, 201);
            display: inline-flex;
            font-size: 14px;
            align-items: center;
            padding-right: 6px;

          }
        }

        &.active {
          background: #eee;
        }
        position: relative;
      }

    }

  }

  &.browser {
    overflow: hidden;
  }

  &.browser .right {
    flex: 1 1 auto;
    width: auto;
  }

  .inspectingPanel {
    width: 600px;
    position: relative;
    overflow: auto;
    flex: 0 0 auto;
  }

  .path {
    font-size: 0.8em;
    color: gray;
  }

  .type {
    font-size: 0.8em;
    color: gray;
  }
`;
export function renderSplitGraph({ groupBy = undefined, value, entity: rootEntity, overviewGraph, contentsGraph, pathGraph, state, overviewCount = undefined, navigate = undefined }) {
  const workspace = defaultWorkspace();
  let content = state.selected ? doEntityQuery(contentsGraph, state.selected, false, state.onlySelected) : [];

  const c = overviewCount || contentsGraph;
  const data = linearData();
  const context = useContext(SystemContext);

  let zIndexes = {};
  for (const entity of content) {
    const zIndex = getEntityZIndex(entity.entity);
    if (!zIndexes[zIndex]) {
      zIndexes[zIndex] = 0;
    }
    zIndexes[zIndex]++;
  }

  // zIndex keys sorted
  // const zIndexKeys = Object.keys(zIndexes).sort((a, b) => {
  //   return parseInt(a) - parseInt(b);
  // });

  const zIndexKeys = [-2, -1, 0, 1, 2];

  let filteringByZIndex = false;
  if (state.zIndex) {
    for (const zIndex in state.zIndex) {
      if (state.zIndex[zIndex]) {
        filteringByZIndex = true;
        break;
      }
    }
  }
  

  // if (filteringByZIndex) {
  //   content = content.filter(entity => {
  //     const zIndex = getEntityZIndex(entity.entity);
  //     if (state.zIndex[zIndex]) {
  //       return true;
  //     }
  //   });
  // }

  const filterEntities = entities => {
    if (filteringByZIndex) {
      return entities.filter(entity => {
        const zIndex = getEntityZIndex(entity.entity);
        if (state.zIndex[zIndex]) {
          return true;
        }
      });
    }

    return entities;
  
  }

  content = filterEntities(content);

  return (
    <Wrapper data-value-point={value._id} className={context?.viewType}>
      <div className="left">
        <EntityGraphView
          onSelect={id => {
            // args.onInspect(id);
            appState.inspecting = {
              type: 'entity',
              id,
            }
          }}
          graph={overviewGraph}
          rootEntity={rootEntity}
          state={state}
          label={entity => {
            if (c) {
              let entities = doEntityQuery(c, entity, false);
              if (state.linearIssues) {
                entities = entities.filter(e => getEntityById(e.entity).linearId);
              }

              entities = filterEntities(entities);
              const count = entities.length;
              return getEntityById(entity).name + ' (' + count + ')';
            }
            else {
              return getEntityById(entity).name;
            }
          }} />
      </div>
      <div className="right">
        <div className="top">

          {/* <div>
            <input type="checkbox" checked={state.linearIssues} onChange={e => {
              state.linearIssues = e.target.checked;
            }} />
            <label>Linear Issues</label>
          </div> */}

          {/* <div>
            <input type="checkbox" checked={state.onlySelected} onChange={e => {
              state.onlySelected = e.target.checked;
            }} />
            <label>Ony selected level</label>
          </div> */}

          {groupBy && <div>
            <input type="checkbox" checked={state.group} onChange={e => {
              state.group = e.target.checked;
            }} />
            Group
          </div>}


          ||ZIndex: {
            zIndexKeys.map(zIndex => {

              return (
                <div>
                  <input type="checkbox" checked={state.zIndex?.[zIndex]} onChange={e => {
                    XObject.get(state, 'zIndex', {})[zIndex] = e.target.checked;
                  }} />
                  <label>{zIndex} ({zIndexes[zIndex] || 0})</label>
                </div>
              )
            })
          }
                    <NotionButton
            text={"Add"}
            onClick={() => {
              const newEntity = XObject.obj({
                stateful: true,
              });
              createEntity(newEntity, rootEntity);
              pushEdge(XObject.obj({
                directed: true,
                entities: [state.selected, newEntity._id],
              }));

              state.inspecting = newEntity._id;

              appState.inspecting = {
                type: 'entity',
                id: newEntity._id,
              }

              navigate?.({
                type: 'entity',
                id: newEntity._id,
              })

            }}
          />

        </div>
        {state.linearIssues && (() => {
          const ids = content.map(c => {
            const entity = getEntityById(c.entity);
            return entity.linearId;
          }).filter(Boolean);
          return (
            <>
            <LinearIssues
              toolbar={XObject.get(state, 'linearToolbar', () => {
                const content =  doEntityQuery(contentsGraph, rootEntity, false, false);

                const ids = content.map(c => {
                  const entity = getEntityById(c.entity);
                  return entity.linearId;
                }).filter(Boolean);
      

                const issues = ids.map(id => data.issues.find(i => i.id == id)).filter(Boolean);

                return {
                  filter: defaultState(data.states, issues),
                  sort: 'createdAt',
                }

              })}
              ids={ids}
              onClick={linearId => {
                const entity = getAllEntities().find(e => e.linearId == linearId);
                state.inspecting = entity._id;
                navigate?.({
                  type: 'entity',
                  id: entity._id,
                })
                // args.onSelectChild(entity._id);
                // appState.inspecting = {
                //   type: 'entity',
                //   id: entity._id,
                // }
              }}
              renderPath={entityId => {
                const path = resolvePath(pathGraph, rootEntity, c.entity);
                const entity = getEntityById(c.entity);
                // const type = entity.type && db.entityTypes.findById(entity.type);

                // const path = resolvePath(mapped.pathGraph.content, rootEntity, entityId);

                return <div><span className="path">{path.map(id => getEntityById(id).name).join(' / ')}</span> {renderQueuePositions(rootEntity, entityId)}</div>;
              }}
              active={state.inspecting && getEntityById(state.inspecting).linearId}
              disableCols={{
                state: true,
                entity: true,
                parent: true,
              }}
              rootEntity={rootEntity}
              graph={contentsGraph}
              sort={state.linearToolbar?.sort}
              filter={state.linearToolbar?.filter}
            />
            </>
          );
        })()}

          {!state.linearIssues && (
            <div className="entities">

              {state.group && (() => {
                return groupById(content.map(c => c.entity), groupBy).map(({ key, group, entities }) => (
      <div key={group} className="group">
        <div className="groupName">{group == 'None' ? 'None' : renderAttributeValue(groupBy, key, group)}</div>
        {entities.map(id => (
          <EntityRow key={id} id={id} path />
        ))}
      </div>
    ))
              })()}

              {!state.group && (() => {
                return content.map((c, i) => {
                  return <EntityRow id={c.entity} key={c.entity} path={state.selected} />
                })
              })()}


            </div>
          )}
      </div>
      {/* {state.inspecting && (
        <div className="inspectingPanel">

          <EntityView embedded key={state.inspecting} id={state.inspecting} state={XObject.get(state, 'viewState', {})} />
        </div>
      )} */}
    </Wrapper>
  );
}

structRenderers[$Browser2.$] = (value, map, state) => {
  const mapped = mapStructure(value);
  const context = useContext(SystemContext);

  return renderSplitGraph({
    groupBy: mapped.groupBy?.content,
    value,
    overviewGraph: mapped.overviewGraph.content,
    contentsGraph: mapped.contents,
    entity: evaluate(mapped.rootEntity, map),
    pathGraph: mapped.pathGraph.content,
    state,
    navigate: config => {
      console.log('navigate', config);
      context.navigate(config);

    }
  });
};
