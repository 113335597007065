import React, { Component } from 'react';
import _ from 'lodash';
import { StyleManager } from './StyleManager';
import { DevRuntime } from './DevRuntime';
import { CComponent } from './Node';
import { Tag } from './components/Tag';
import { component } from './component';
import { styled } from './component2';

@component
class TextEntry extends Component<{ devRuntime: DevRuntime, id }> {
  static styles = styled.div`
    white-space: nowrap;
    display: flex;
    align-items: center;
    ${Tag} {
      font-size: 7px;
      height: 13px;
      margin-left: 4px;
    }

  `;

  render() {
    // const f = this.props.devRuntime.findBlock(this.props.id);
    const comp = null;//f.find(f => f instanceof CComponent);

    return (
      <>
<><span style={{ color: 'gray' }}>&lt;</span><span style={{ color: 'purple' }}>text</span><span style={{ color: 'gray' }}>&gt;</span> {comp && <Tag text={comp.obj.name} />}</>
      </>
    )
  }
}

@component
class ComponentEntry extends Component<{ devRuntime: DevRuntime, component }> {
  static styles = styled.div`
    white-space: nowrap;
    display: flex;
    align-items: center;
    ${Tag} {
      font-size: 7px;
      height: 13px;
      margin-left: 4px;
    }

    &.detachedNode {
      opacity: .6;
    }
  `;
  render(Container?) {
    const comp = this.props.component;
    const type = this.props.devRuntime.devProject.builderComponentTypes.find(t => t._id == comp.builderComponentType);


    return (
      <Container className={comp.detachedNode && 'detachedNode'}>{comp.name} {type && <Tag text={type.name} />}</Container>
    )
  }
}
const styleManager = new StyleManager();
export function generateAppTree(devRuntime: DevRuntime, rootEl, rootPath = [], setAttrs = true) {



  const rendered = {};

  function mapEl(el, path: string[] = []) {
    if (el?.getAttribute?.('data-text-block-id')) {

      el.setAttribute('data-path', path.concat(`text:${el.getAttribute('data-text-block-id')}`).join('/'));
      return {
        el,
        type: 'text',
        id: el.getAttribute('data-text-block-id'),
        pathKey: `text:${el.getAttribute('data-text-block-id')}`,
        path: path.concat(`text:${el.getAttribute('data-text-block-id')}`),
        key: el.getAttribute('data-text-block-id'),
        children: [],

      }
    }


    const componentId = el.getAttribute('data-component-id');
    const nodeId = el.getAttribute('data-node-id');
    const blockId = el.getAttribute('data-script-block-ids');


    if (componentId) rendered[componentId] = true;
    if (nodeId) rendered[nodeId] = true;
    if (blockId) {
      for (const id of blockId.split(',')) {
        rendered[id] = true;
      }
    }

    let obj: any = {
      el,
      key: el.getAttribute('data-key'),
      instanceId: el.getAttribute('data-instance-id'),
      path,
    };


    if (componentId) {
      obj.type = 'component';
      obj.id = componentId;
      obj.blockIds = el.getAttribute('data-script-block-ids');
    }
    else if (nodeId) {
      obj.type = 'node';
      obj.id = nodeId;
    }
    else if (blockId) {
      obj.type = 'block';
      obj.id = blockId;
    }

    if (obj.type) {
      const pathKey = [obj.type, (blockId || nodeId || componentId), obj.key].filter(Boolean).join(':');
      obj.pathKey = pathKey;
      obj.path.push(obj.pathKey);
      if (setAttrs) {
        el.setAttribute('data-path-key', pathKey);
        el.setAttribute('data-path', obj.path.join('/'));
        // console.log(el)
      }
      obj.children = [...el.children].map((child) => mapEl(child, [...path])).filter(Boolean);

      obj.children = _.uniqBy(obj.children, n => (n as any).pathKey);

      return obj;
    }
  }


  const mapToTree = node => {
    let text, styles;
    if (node.type == 'component') {
      const comp = devRuntime.devProject.components.find(c => c._id == node.id);
      // text = <>{comp.name} <Tag text="Text" /></>;
      text = <ComponentEntry component={comp} devRuntime={devRuntime} />
      styles = { color: '#008bff' };
    }
    else if (node.type == 'node') {
      const [n] = devRuntime.findNode(node.id) || [];
      styles = { color: 'green', fontSize: '80%' };

      if (n?.obj?.data) {
        text = n.obj.data;
      }
      else {
        text = node.el.tagName.toLowerCase();
        if (n?.obj?.class) {
          text += `.${n.obj.class}`;
        }
      }
    }
    else if (node.type == 'text') {
      text = <TextEntry devRuntime={devRuntime} id={node.id} />
      // text = <><span style={{ color: 'gray' }}>&lt;</span><span style={{ color: 'purple' }}>text</span><span style={{ color: 'gray' }}>&gt;</span> <Tag text={comp.obj.name} /></>;

    }
    else {
      text = <><span style={{ color: 'gray' }}>&lt;</span><span style={{ color: 'purple' }}>{node.el.tagName.toLowerCase()}</span><span style={{ color: 'gray' }}>&gt;</span></>;
      // styles = { color: 'purple' }
    }

    return {
      node,
      key: node.path.join('/'),
      elId: node.id,
      // text: `${text} (${node.type})`,
      text,
      styles,
      children: node.children.map(child => mapToTree(child)),
      contextMenu: [
          {
            text: 'Debug',
            onClick: () => {
              console.log(node)
            }
          }
        ]

    };
  };


  let r;
  const rootNode = mapEl(rootEl, rootPath);
  if (rootNode) {
    r = mapToTree(rootNode);
  }


  if (setAttrs) {
    styleManager.setStyles(`
    
    ${Object.keys(rendered).map(id => `
      [data-input-id="${id}"] {
        --rendered: true;
        opacity: 1 !important;
      }

      [data-identifier-category="tag"][data-id="${id}"] {
        opacity: 1 !important;
      }
    
    `).join('\n')}


  `);
  }


  return r;


}
