import { ValuePointType } from "./main";
import { EvaluateParams, RenderParams } from "./RenderParams";
import { Runtime } from "./Runtime";
import { sideBarComps, valuePointComps } from "./valuePointComps";

/*function retrieve(asdf: string, rt: Runtime) {
  let vv;
  let value;
  if (asdf in rt.paramStore) {
    value = rt.paramStore[asdf]
  }

  if (!value) {
    if (rt.hasState(asdf)) {
      value = rt.getState(asdf);
    }
  }

  if (isId(value)) {
    value = { type: 'entity', content: value };
  }
  else if (value?._id) {
    value = execute(value._id, rt);
    if (value.type[0] == ValueType.Entity) {
      value = { type: 'entity', content: value.content };
    }
  }

  vv = value;

  return vv;

}*/
export const __typeRegistry = {};
export function registerType(type, funcs: {
  execute?: (value, rt: Runtime) => any
  render?: (value, map, state, renderType) => any
  renderWithParams?: (params: RenderParams) => any

  evaluate?: (value, map) => any
  evaluateWithParams?: (params: EvaluateParams) => any
  scriptEvaluate?: (params: EvaluateParams) => any

  editorComp?: any
  sideBarComp?: any;
  isBlock?(value: ValuePointType): boolean
  findValue?
  iterate?: (value, parent, func, set) => any
  scope?
  addStructToMap?
  
}) {
  __typeRegistry[type] = funcs;
  if (funcs.editorComp) {
    valuePointComps[type] = funcs.editorComp;
  }
  if (funcs.sideBarComp) {
    sideBarComps[type] = funcs.sideBarComp;
  }
}
