import _ from 'lodash';
import { styled } from "../../component";
import { CVP, evaluate, getRootValuePoint, mapStructure, render } from "../main";
import { structParamRenderers, structRenderers, structStyledCont } from "../structRenderers";
import { typeRegistry } from "../typeRegistry.1";
import { Pass, renderEl } from '../../shorthand/formula';
import { ValueType } from '../ValueType';
import { RenderData } from '../../MyNotionDocument/RenderData';
import { types } from '../../MyNotionDocument/types';

export const $Card = typeRegistry.registerType({
  $: 'dff53580-5b5c-5e42-9ca0-34c1bc7a7a34',
  Title: 'df040cb0-6c51-5753-8629-2fc4883e4760',
  Body: 'c4d7ee73-3c77-537d-bf00-e9822c7908d9',
}, ids => ({
  _id: ids.$,
  name: 'Card',
  definition: [
    {
      id: ids.Title,
      name: 'Title',
      type: [],
      property: 'title',
    },
    {
      id: ids.Body,
      name: 'Body',
      type: [],
      property: 'body',
    },
  ],
}));

const $CardList = typeRegistry.registerType({
  $: 'f7a1b43e-2041-5697-b040-8bad2c04585f',
  Title: 'ede1fff1-5542-5959-8a4c-af369e354c82',
  Cards: 'f6b79cd8-4852-5c89-adea-66beee811185',
}, ids => ({
  _id: ids.$,
  name: 'Card List',
  definition: [
    {
      id: ids.Title,
      name: 'Title',
      type: [],
      property: 'title',
    },
    {
      id: ids.Cards,
      name: 'Cards',
      type: [ValueType.Array, []],
      property: 'cards',
    }
  ],
}));

const Wrapper = styled.div`
  .title {
    border-bottom: 1px solid #e1e1e1;
    padding: 0 8px;
    font-size: 12px;
    background-color: #f1f1f1;
  }
  .body {
    padding: 8px;
  }

  overflow: hidden;
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 2px 4px;
  border-radius: 3px;
  background: white;
  transition: background 100ms;

`;


structParamRenderers[$Card.$] = ({value, map, rt}) => {
  const mapped = mapStructure(value);
  let title = evaluate(mapped.title, map);
  let body = evaluate(mapped.body, map);

  if (title instanceof CVP || title instanceof Pass || (!_.isString(title) && !_.isArray(title))) title = '';  
  // if (body instanceof CVP || body instanceof Pass || !_.isString(body)) body = '';  

  
  return (
   <Wrapper>
      <div className="title"><RenderData data={title} ctx={{ types: types }} /></div>
      <div className="body">
        {/* {body instanceof CVP ? '' : body} */}
        {renderEl(body, rt)}
      </div>
   </Wrapper>
  )
}


const CardList = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  > .title {
    font-weight: bold;
  }
`;

structStyledCont[$CardList.$] = CardList;


structParamRenderers[$CardList.$] = ({value, map, rt}) => {
  const mapped = mapStructure(value);
  let title = evaluate(mapped.title, map);
  if (title instanceof CVP || title instanceof Pass || (!_.isString(title) && !_.isArray(title))) title = '';  
 

  return (
    <CardList>
      <div className="title"><RenderData data={title} ctx={{ types: types }} /></div>
      {mapped.cards.content?.map?.(c => {
        return (
          <div key={c._id}>
            {render(c, map)}
          </div>
        )
      })}
    </CardList>
  )
};