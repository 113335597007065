export enum SidebarItemType {
  app = '7c89f25a-30b7-595d-96d2-0d7c2588fba8',
  canvas = 'e9951ef6-fdad-5df4-8a6e-e8db9d5eb9c0',
  document = 'd66ebe4b-0658-5b28-811e-1757e11af112',
  entity = '9d86458d-6850-5c2e-aea9-9fa60be4c1eb',
  error = '3564b35b-e8cf-549d-adb1-b6a27f3bb88d',
  folder = 'a2f3a7a4-d80e-5e65-9717-1480c2037d8d',
  library = '579b6b96-1fc6-526f-80f0-a132bd2beed7',
  page = 'fb2c9a82-6f40-5507-a605-6128d75b6b00',
  pageEntities = '6d953be9-5232-5cb8-98cb-24a918c2fb84',
  query = '3df9f1c2-9f33-5af1-8882-fdc3a30f8a81',
  space = 'a83b2867-1217-59af-adee-d0545da0d847',
  table = '5b652a07-72ad-5d8f-9929-792a131e263c',
  workspace = '597dc7ee-7636-5719-8ce6-ad59e5eb27ca',
  devProject = 'eff662db-9ac5-53f5-91ea-7176a0fec5e0',
}
