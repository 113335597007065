export class LoopBlock {
  iterations: {
    i, el, cs
  }[] = []
  pushIteration(i, el, cs) {
    this.iterations.push({ i, el, cs })
  }

  order = order++
}

export class ValueBlock {
  constructor(public value) {}
  order = order++
}

export class CallBlock {
  constructor(public blockId, public instanceId, public func, public args, public cs, public debug?) {

  }

  returnValue
  order = order++
}


export const enable = true;


let id = 0;

let order = 0;

export class RuntimeTrace {
  // constructor(init?, public args?, public log?) {
  constructor(opts?: { init?, args, log?, instanceId? }) {
    if (opts?.init) {
      this.callStack.push(opts.init);
    }

    this.args = opts?.args;
    this.log = opts?.log;
    this.instanceId = opts?.instanceId;


  }

  order = order++

  args
  log: any

  id = ++id;

  debug

  callStack = [];

  children: RuntimeTrace[] = [];

  calls: {
    [key: string]: {
      id
      func
      cs
      returnValue
      blockId
      args
    }[]
  } = {};

  parent: RuntimeTrace

  devRuntime

  instanceId


  mutations: {
    [key: string]: {
      blockId
      runtimeContext,
      mutation
      type
      extra,
      order
    }
  } = {}

  signals = []
  captureSignal(signal) {
    this.signals.push(signal);
  }

  captureMutation(block, runtimeContext, mutation, type, extra?) {
    if (!runtimeContext) {
      throw new Error();
    }
    this.mutations[block] = {
      blockId: block,
      runtimeContext,
      mutation,
      type,
      extra,
      order: order++,
    }
  }

  

  create(comp) {
    if (!this.instanceId) {
      throw new Error();
    }
    const cs = new RuntimeTrace();
    cs.callStack = this.callStack.concat(comp);
    cs.args = this.args;
    cs.parent = this;
    cs.instanceId = this.instanceId;

    return cs;

  }

  isAttached() {
    let current: RuntimeTrace = this;
    while (current.parent) {
      current = current.parent;
    }

    if (current == current.devRuntime?.cs) {
      return true;
    }
  }

  pushEventLayer(cs, cb) {
    this.args?.pushEventLayer?.(cs, cb);
  }


  push(comp) {
    if (!enable) return;
    const cs = this.create(comp);
    this.children.push(cs);
    return cs;
  }

  blocks = {};

  capture(block, value) {
    if (!enable) return;

    // if (this.log) {
    //   console.log(block, value);
    // }

    if (!this.blocks[block]) {
      this.blocks[block] = new ValueBlock(value);
    }
  }


  logFunctionCall(block, instanceId, funcId, args): CallBlock {
    if (!enable || !funcId) return;
    const cs = this.create(`${block} (call ${funcId})`);
    if (instanceId) {
      cs.instanceId = instanceId;

    }
    this.blocks[block] = new CallBlock(block, instanceId, funcId, args, cs);
    return this.blocks[block];
  }



  logFunctionEntry(blockId, func, args, key=Date.now()) {

    const cs = this.create(`${blockId} (call ${func})`);

    const call = {
      blockId,
      func,
      args,
      cs,
      returnValue: undefined,
      id: blockId + key,
      order: order++,
    };

    if (!this.calls[blockId]) {
      this.calls[blockId] = [];
    }
    this.calls[blockId].push(call);

    return call;
  }

  pushForLoop(block, i, el) {
    if (!enable) return;
    if (!this.blocks[block]) {
      this.blocks[block] = new LoopBlock();
    }

    const cs = this.create(`${block} (for/${i})`);

    this.blocks[block].pushIteration(i, el, cs);

    // this.args.captureForLoop(block, i, el);
    return cs;
  }
}

export class RuntimeTraceCont {
  constructor() {
    this.eventLayers = [];
  }
  main: RuntimeTrace;
  eventLayers: RuntimeTrace[];
}

export class RuntimeContext {
  constructor(public context) {
    
  }
}