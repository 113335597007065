import { css } from './component';

export const sectionStyles = css`
  > .sectionHeader {
    height: 24px;
    font-weight: 700;
    display: flex;
    align-items: center;
    padding: 0 4px;
    background-color: #dedede;
    /* position: sticky; */
    top: 0;
    z-index: 99999;
  }
  > .section {
    position: relative;
    > .header {
      height: 24px;
      font-weight: 600;
      display: flex;
      align-items: center;
      padding: 0 4px;
      background-color: #f4f4f4;
      /* position: sticky; */
      z-index: 99999;
      top: 0;
    }



    > .content {
      padding: 4px;
    }
  }
`;
