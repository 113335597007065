import { Component } from 'react';
import { component } from './component';
import { db } from './db';
import { XObject } from './XObject';
import { PropertyField } from './components/PropertyField';
import { openWindow } from './osHelpers';
import { WindowType } from './etc/WindowType';
import { createRootValuePoint } from './glue/main';
import { setAppInspect } from './etc/appState';
import { InspectState } from './components/InspectState';

export enum WidgetType {
  glue = '23f6d924-8107-5ff3-8c95-0d01ba0b14f5',
}

@component
export class WidgetWindow extends Component<{ window }> {
  render() {
    const widget = db.widgets.findById(this.props.window.widget);
    return (
      <>
        <div>
          ID: {widget._id}
        </div>
        <div>
          Name: <PropertyField object={widget} property="name" />
        </div>

        <div>
          Type: <select
            value={widget.type}
            onChange={e => {
              widget.type = e.target.value;
            }}
          >
            <option />
            <option value={WidgetType.glue}>Glue</option>
          </select>
        </div>

        {widget.type == WidgetType.glue && (
          <>
            <div>
              <h3>Glue</h3>
              {!widget.valuePoint && <button
                onClick={() => {
                  const vp = createRootValuePoint();
                  widget.valuePoint = vp._id;
                  setAppInspect({
                    mode: InspectState.valuePoint,
                    id: widget.valuePoint,
                  });

                }}
              >Init</button>}
              {widget.valuePoint && <button
                onClick={() => {
                  setAppInspect({
                    mode: InspectState.valuePoint,
                    id: widget.valuePoint,
                  });

                }}
              >Edit</button>}
            </div>
          </>
        )}
      </>
    )
  }
}

@component
export class WidgetsWindow extends Component<{ window; }> {
  render() {
    return (
      <>
        <ul>
          {db.widgets.map(w => {
            return (
              <li key={w._id}>
                <PropertyField object={w} property="name" /> <button
                  onClick={() => {
                    openWindow({
                      type: WindowType.Widget,
                      widget: w._id,
                    });
                  }}
                >View</button>
              </li>
            )
          })}
          <li><button
            onClick={() => {
              db.widgets.push(XObject.obj({
                name: 'Untitled widget',
              }))
            }}
          >+</button></li>
        </ul>
      </>
    )
  }
}
