import { db } from '../db';
import { memoryAppState } from './appState';
import { getReferences } from "../components/getReferences";
import { getEdgesForEntity } from './getEdgesForEntity';
import { getEntityById } from './createEntity';


export function getInbox() {
  const user = db.users.findById(memoryAppState.user);
  let references = getReferences(user.entity).map(x => {
    return x.sourceEntity;
  }).filter(x => {
    const entity = getEntityById(x);
    if (!entity.meta?.creation) {
      return false;
    }
    return true;
  }).map(x => {
    return {
      type: 'referenced',
      entity: x,
      date: getEntityById(x).meta.creation.timestamp,
    };
  });

  if (user.subscriptions)
    for (const id in user.subscriptions) {
      const children = getEdgesForEntity(id).filter(e => {
        return e.directed && e.entities[0] == id;
      }).map(e => {
        return e.entities[1];
      }).filter(e => {
        const entity = getEntityById(e);
        if (!entity.meta?.creation) {
          return false;
        }
        return true;
      });

      references = references.concat(children.map(x => {
        return {
          type: 'activity',
          entity: x,
          date: getEntityById(x).meta.creation.timestamp,
        };
      }));
    }

  references.sort((a, b) => {
    return b.date.getTime() - a.date.getTime();
  });

  return references;
}
