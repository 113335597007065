import React, { Component } from 'react';
import { component } from '../component2';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ColumnManager, RowManager } from './notionDatabase/ColumnManager';


@component
export class ChartView extends Component<{
  columnManager: ColumnManager
  rowManager: RowManager
  view: { groupingProperty, horizontalAxis, segmentBy, chartType: 'bar' | 'pie' }
}> {
  render() {

    const options = () => {
      if (this.props.view.chartType == 'bar') {
        const grouped = {};
        for (const row of this.props.rowManager.rows()) {
          const value = row.value(this.props.view.horizontalAxis) || 'BLANK';
          if (value) {
            if (!grouped[value]) grouped[value] = [];
            grouped[value].push(row);
          }
        }
    
        const categories = [];

        const series = [];
        for (const value in grouped) {
          categories.push(value == 'BLANK' ? 'BLANK' : this.props.columnManager.displayValue(this.props.view.horizontalAxis, value));
        }

        if (this.props.view.segmentBy) {
          let segments: any = {};
          for (const row of this.props.rowManager.rows()) {
            const value = row.value(this.props.view.segmentBy);
            if (value) {
              segments[value] = true;
            }
          }

          segments = Object.keys(segments);

          for (const segment of segments) {
            series.push({
              type: 'column',
              name: this.props.columnManager.displayValue(this.props.view.segmentBy, segment),
              data: [],
            });
          }
          
          for (const value in grouped) {
            for (let i = 0; i < segments.length; ++ i) {
              const segmentValue = segments[i];
              const rows = grouped[value].filter(r => r.value(this.props.view.segmentBy) == segmentValue);
              series[i].data.push(rows.length);
            }
          }
        }
        else {
          const data = [];   
          for (const value in grouped) {
            data.push(grouped[value].length);
          }
          series.push(
            { 
              type: 'column',
              data: data,
              name: this.props.columnManager.columns().find(c => c.id() == this.props.view.horizontalAxis).title(),
            }
          );
        }

    

        const options: Highcharts.Options = {
          title: {
            text: null,
          },
          // xAxis: {
          //   categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
          // },
          // series: [{
          //   name: 'Installation',
          //   data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4]
          // }],
          xAxis: {
            categories: categories,
          },
          yAxis: {
            tickInterval: 1,
          },
          series,
    
          // plotOptions: {
          //   line: {
          //     dataLabels: {
          //       enabled: true
          //     },
          //     enableMouseTracking: false
          //   }
          // },
    
        };
  
        return options;
      }
      if (this.props.view.chartType == 'pie') {
        const grouped = {};
        for (const row of this.props.rowManager.rows()) {
          const value = row.value(this.props.view.groupingProperty) || 'BLANK';
          if (value) {
            if (!grouped[value]) grouped[value] = [];
            grouped[value].push(row);
          }
        }
        
        const options: Highcharts.Options = {
          title: {
            text: null,
          },
          // xAxis: {
          //   categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
          // },
          // series: [{
          //   name: 'Installation',
          //   data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4]
          // }],

          series: [
            { 
              type: 'pie',
              data: Object.keys(grouped).map(key => ({
                name: key == 'BLANK' ? 'BLANK' : this.props.columnManager.displayValue(this.props.view.groupingProperty, key),
                y: grouped[key].length,
              })),
              name: this.props.columnManager.columns().find(c => c.id() == this.props.view.groupingProperty).title(),
            }
          ],
    
          // plotOptions: {
          //   line: {
          //     dataLabels: {
          //       enabled: true
          //     },
          //     enableMouseTracking: false
          //   }
          // },
    
        };
  
        return options;
      }

      return {};
    }

    return (
      <>
        <HighchartsReact
          highcharts={Highcharts}
          options={options()}
        />
      </>
    );
  }
}
