import React from 'react';
import { XObject } from "./XObject";
import { component } from './component2';
import { Component } from 'react';
import { isId, showContextMenu } from './helpers';
import { styled } from './component';
import { ColorPicker } from './ColorPicker';
import { baseStyles } from './baseStyles';


@component
export class ColorEditor extends Component<{ devRuntime; value; setValue; }> {
  static styles = styled.div`
    ${baseStyles}
    padding: 8px;
    border: 1px solid #d2d2d2;
    border-radius: 3px;

    background-color: white;

    .top {
      display: flex;
      margin-bottom: 8px;
      align-items: center;
    }

    .left {
      width: 100%;
      box-sizing: border-box;
    }

    .selector {
      display: block;
      position: relative;
      width: 16px;
      height: 16px;
      margin-left: 6px;
      flex: 0 0 auto;
      cursor: pointer;

      &:before {
        content: '';
        width: 6px;
        height: 6px;
        border-right: 1px solid;
        border-bottom: 1px solid;
        border-color: #b6b6b6;
        transform: rotate(45deg);
        position: absolute;
        margin: auto;
        top: 3px;
        left: 3px;
      }
    }

    .sketch-picker {
      padding: 0 !important;
      box-shadow: none !important;
      border: none !important;
    }
  `;
  static debounce = false;
  render() {


    const getColor = () => {
      if (isId(this.props.value)) {
        return this.props.devRuntime.devProject.colors.find(c => c._id == this.props.value).color;
      }
      else {
        return this.props.value;
      }
    };



    const setColor = (v) => {
      if (isId(this.props.value)) {
        this.props.devRuntime.devProject.colors.find(c => c._id == this.props.value).color = v;
      }
      else {
        this.props.setValue(v);
      }

    };

    return (
      <>

        <div className="top">
          {isId(this.props.value) && (
            <input className="left" type="text"

              value={this.props.devRuntime.devProject.colors.find(c => c._id == this.props.value).name}
              onChange={e => {
                this.props.devRuntime.devProject.colors.find(c => c._id == this.props.value).name = e.target.value;
              }} />
          )}
          {!isId(this.props.value) && (
            <button className="left"
              onClick={() => {
                const c = XObject.obj({
                  name: this.props.value,
                  color: this.props.value,
                });
                this.props.devRuntime.devProject.colors.push(c);
                this.props.setValue(c._id);
              }}
            >Create preset</button>
          )}

          <span className="selector"
            onClick={e => {
              showContextMenu(e, [
                {
                  text: <span
                    style={{
                      fontWeight: !isId(this.props.value) ? 'bold' : undefined,
                    }}
                  >Custom color</span>,
                  onClick: () => {
                    if (isId(this.props.value)) {
                      this.props.setValue(this.props.devRuntime.devProject.colors.find(c => c._id == this.props.value).color);
                    }
                  },
                }
              ].concat(this.props.devRuntime.devProject.colors.map(c => {
                return {
                  text: <span
                    style={{
                      fontWeight: this.props.value == c._id ? 'bold' : undefined,
                    }}
                  ><span
                      style={{
                        width: 8,
                        height: 8,
                        display: 'inline-block',
                        marginRight: 4,
                        backgroundColor: c.color,
                        marginTop: 3,
                      }} /> {c.name}</span>,
                  onClick: () => {
                    this.props.setValue(c._id);
                  },
                };
              })));
            }} />
        </div>


        <ColorPicker
          color={getColor()}
          onColorChange={setColor} />
      </>
    );

  }
}
