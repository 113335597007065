import React, { Component } from "react";
import _ from "lodash";
import ReactDOM from "react-dom";
import ReactDOMClient from "react-dom/client";
import cx from "classnames";
import jQuery from "jquery";
import { ThemeProvider } from 'styled-components';
import { component, styled } from "../../component";
import { X, XClone, XInit, XObject, x } from "../../XObject";
import { showContextMenu } from "../../etc/showContextMenu";
import { BlocksList, BlockManager, Block, BlockColumns } from "./BlockManager";
import { Data, getPositionInDataEditor } from "../../richTextHelpers";
import { color } from "./color";
import { Menu } from "./Menu";
import { Type } from "../../richTextHelpers";
import { DataCtx, dataLength, sliceData, concatData, extractFromEl, dataToString, createChunked } from "../../richTextHelpers";
import { isMobile } from "../../isMobile";
import { Svg } from "../Svg";
import { MenuPresenter, MenuType, presentMenu } from "../richTextEditor/MenuPresenter";
import { Wrapper } from "../../MyNotionDocument/renderBlock2";
import { resolveOffset as _resolveOffset } from './resolveOffset';

interface UIAPI {
  getBlock(id): any
}

@component
class Inspector extends Component<{ blockManager, ctx }> {
  static styles = styled.div`

  `;

  componentDidMount(): void {
    setInterval(() => {
      this.forceUpdate();
    }, 1000);
  }

  render() {
    const el = window.getSelection()?.focusNode?.parentElement && jQuery(window.getSelection().focusNode.parentElement).closest('[data-block-id]')
    const blockId = el && el.data('block-id');
    const block = blockId && findBlock(this.props.blockManager, blockId);

    return (
      <>
        <div>Position: {getPositionInBlock(this.props.ctx)}</div>
        <div>Width: {block && dataLength(this.props.ctx, block.getContent())}</div>
      </>
    )
  }
}

// BLOCK FUNCS
function findBlockParent(blockManager: BlockManager, id, parent=null) {
  return blockManager.findBlockParent(id);
}

function unindentBlock(uiApi: UIAPI, blockManager: BlockManager, id) {
  const parent = blockManager.findBlockParent(id);
  const block = blockManager.findBlock(id);
  if (parent) {
    const grandparent = parent.getParent();
    if (grandparent) {
      const index = grandparent.getChildren().indexOf(parent);
      grandparent.getChildren().splice(index + 1, 0, block);
      parent.getChildren().splice(parent.getChildren().indexOf(block), 1);
      clearSelection()
      setTimeout(() => {
        // const el = jQuery(`[data-block-id="${block.getId()}"]`);
        const el = uiApi.getBlock(block.getId());
        const editorEl = el.find('.editor');
        setCaret(editorEl[0])
      }, 0);
    }
    else {
      const index = blockManager.getRootBlocks().indexOf(parent);
      blockManager.getRootBlocks().splice(index + 1, 0, block);
      parent.getChildren().splice(parent.getChildren().indexOf(block), 1);
      clearSelection()

      setTimeout(() => {
        // const el = jQuery(`[data-block-id="${block.getId()}"]`);
        const el = uiApi.getBlock(block.getId());
        const editorEl = el.find('.editor');
        if (editorEl[0]) {
          setCaret(editorEl[0])
        }
        else {
          console.log('cant find', block.getId());
        }
      }, 0);
    }
  }
}

function indentBlock(uiApi: UIAPI, blockManager: BlockManager, id) {
  const parent = findBlockParent(blockManager, id);
  const block = findBlock(blockManager, id);
  let containingList: BlocksList;
  if (parent) {
    containingList = parent.getChildren();
  }
  else {
    containingList = blockManager.getRootBlocks();
  }

  const index = containingList.indexOf(block);
  if (index > 0) {
    const prevBlock = containingList.get(index - 1);
    if (prevBlock) {
      prevBlock.getChildren().push(block);
      containingList.splice(index, 1);
      clearSelection();
  
      setTimeout(() => {
        const el = uiApi.getBlock(block.getId());
        const editorEl = el.find('.editor');
        if (editorEl[0]) {
          setCaret(editorEl[0]);
        }
        else {
          console.log('no editor el for block', block.getId());
        }
      }, 0);
    }  
  }
}


export function findBlock(blockManager: BlockManager, id, errorOnNotFound=false) {
  return blockManager.findBlock(id, errorOnNotFound);
  // for (const block of blocks) {
  //   if (block._id === id) {
  //     return block;
  //   }
  //   if (block.children) {
  //     const p = findBlock(block.children, id);
  //     if (p) {
  //       return p;
  //     }
  //   }
  // }
  // if (errorOnNotFound) {
  //   throw new Error(`Block with id ${id} not found`);
  // }
  // return null;
}


/*function findBlockMatching(blocks, predicate) {
  for (const block of blocks) {
    if (predicate(block)) {
      return block;
    }
    if (block.children) {
      const p = findBlockMatching(block.children, predicate);
      if (p) {
        return p;
      }
    }
  }
  return null;
}*/


function findBlocksMatching(blocks: BlocksList, predicate) {
  const results = [];
  for (const block of blocks.getArray()) {
    if (predicate(block)) {
      results.push(block);
    }
    if (block.hasChildren()) {
      results.push(...findBlocksMatching(block.getChildren(), predicate));
    }
  }
  return results;
}
function removeBlock(blockManager: BlockManager, id, fixColumns=false) {
  const parent = findBlockParent(blockManager, id);
  const theseBlocks = parent ? parent.getChildren() : blockManager.getRootBlocks();
  const index = theseBlocks.indexOfId(id);
  if (index != -1) {
    const col = fixColumns && blockManager.getBlockColumn(id, true);
    if (col) {
      console.log(1);
      const blockColumns = col[0];
      const column = blockColumns.getColumns()[col[1]];
      if (column.getChildren().getLength() == 1) {
        console.log(2, blockColumns.getColumns().length);
        blockColumns.removeColumn(col[1]);
        console.log(22, blockColumns.getColumns().length);
        if (blockColumns.getColumns().length == 1) {
          console.log(3);
          const list = blockColumns.getColumns()[0].getChildren();
  
          const rootBlocks = blockManager.getRootBlocks();
          const index = rootBlocks.indexOf(blockColumns as any);
          rootBlocks.splice(index, 1, ...list.getArray());
        }
  
      }
      else {
        theseBlocks.splice(index, 1);
      }
    
      // const rootBlocks = blockManager.getRootBlocks();
      // const index = rootBlocks.indexOf(blockColumns as any);

    }
    else {
      theseBlocks.splice(index, 1);
    }
    return true;
  }
  else {
    return false;
  }

}

function getBlockIndentation(blockManager: BlockManager, id) {
  const parent = findBlockParent(blockManager, id);
  if (parent && parent instanceof Block) {
    return getBlockIndentation(blockManager, parent.getId()) + 1;
  }
  else {
    return 0;
  }
}

function canIndentBlock(blockManager: BlockManager, id) {
  const parent = findBlockParent(blockManager, id);
  let containingList: BlocksList;
  if (parent) {
    containingList = parent.getChildren();
  }
  else {
    containingList = blockManager.getRootBlocks();
  }
  const index = containingList.indexOfId(id);
  return index > 0;
}

function canUnindentBlock(blockManager: BlockManager, id) {
  return getBlockIndentation(blockManager, id) > 0;
}

// SELECTION FUNCS
function setCaret(el) {
  const range = document.createRange()
  const sel = window.getSelection()
  
  const node = el.childNodes[el.childNodes.length - 1];
  if (node) {
    range.setStart(node, node?.length || 0)
  }
  else {
    range.setStart(el, 0)
  }
  range.collapse(true)
  
  sel.removeAllRanges()
  sel.addRange(range)
}

function clearSelection() {
  if (window.getSelection) {window.getSelection().removeAllRanges();}
  else if ((document as any).selection) {(document as any).selection.empty();}
}


// TODO: update to support entities
// iterator through a tree of nodes
export function resolveOffset(ctx: DataCtx, el, position): [Node, number] {
  return _resolveOffset(ctx, el, position);
  const { types } = ctx;
  let offset = 0;
  let node = el.firstChild;
  while (node && !node.getAttribute?.('data-terminal')) {
    if (node.nodeType === Node.TEXT_NODE) {
      if (offset + node.length >= position) {
        return [node, position - offset];
      }
      offset += node.length;
    }
    else if (types[node.getAttribute('data-type')]?.type == Type.atomic) {
      offset += 1;
    }
    else if (node.nodeType === Node.ELEMENT_NODE) {
      const [foundNode, foundOffset] = resolveOffset(ctx, node, position - offset);
      if (foundNode) {
        return [foundNode, foundOffset];
      }
      offset += node.textContent.length;
    }
    node = node.nextSibling;
  }
  return [el, position];
}

function createRangeArrayFromBlockSelection(uiApi: UIAPI, ctx: DataCtx, blockSelection): [Node, number, Node, number] {
  const [start, end] = blockSelection;
  // const startBlock = document.querySelector(`[data-block-id="${start.blockId}"] .editor`);
  // const endBlock = document.querySelector(`[data-block-id="${end.blockId}"] .editor`);
  const startBlock = uiApi.getBlock(start.blockId)?.find?.(`.editor`)?.[0];
  const endBlock = uiApi.getBlock(end.blockId)?.find?.(`.editor`)?.[0];

  if (!startBlock || !endBlock) {

    console.log('cant find block', blockSelection);
    return;
  }


  return [
    ...resolveOffset(ctx, startBlock, start.position),
    ...resolveOffset(ctx, endBlock, end.position)
  ]

}

/*function createRangeFromBockSelection(uiApi: UIAPI, ctx: DataCtx, blockSelection) {
  const [start, end] = blockSelection;
  const startBlock = document.querySelector(`[data-block-id="${start.blockId}"] .editor`);
  const endBlock = document.querySelector(`[data-block-id="${end.blockId}"] .editor`);

  const range = document.createRange();

  range.setStart(...resolveOffset(ctx, startBlock, start.position));
  range.setEnd(...resolveOffset(ctx, endBlock, end.position));
  return range;
}*/


function setCaretToBlockSelection(uiApi: UIAPI, ctx: DataCtx, blockSelection) {
  if (blockSelection.length > 0) {
    const range = createRangeArrayFromBlockSelection(uiApi, ctx, blockSelection);
    if (!range) {
      console.log('no range', blockSelection);
      return;
    }
    try {
      console.log('set pos')
      window.getSelection().setBaseAndExtent(...range);
    }
    catch (e) {
      console.log(blockSelection, range);
      console.log('setCaretToBlockSelection failed', e);
      throw new Error();
    }
  }
}

export function getBlockSelection(ctx: DataCtx, sorted=false): [{blockId, position}, {blockId, position}] | [] {
  if (sorted) {
    const selection = getBlockSelection(ctx);
    const blockIds = getSelectedBlockIds();
    const firstId = blockIds[0];
    const lastId = blockIds[blockIds.length - 1];
    const first = selection.find(s => s.blockId === firstId);
    const last = selection.find(s => s.blockId === lastId);
    return [first, last];
  }
  else {
    const selection = window.getSelection();
    if (!selection.anchorNode) return [];
    const anchor = _getPositionInBlock(ctx, 'anchor');
    const focus = _getPositionInBlock(ctx, 'focus');
    const anchorNode = selection.anchorNode;
    const focusNode = selection.focusNode;
    const anchorBlock = jQuery(anchorNode).closest('[data-block-id]');
    const focusBlock = jQuery(focusNode).closest('[data-block-id]');
    const anchorBlockId = anchorBlock.attr('data-block-id');
    const focusBlockId = focusBlock.attr('data-block-id');
  
    if (!anchorBlockId || !focusBlockId) return [];
  
  
    return [{
      blockId: anchorBlockId,
      position: anchor
    }, {
      blockId: focusBlockId,
      position: focus
    }]
  }

}

function getSelectionHtml() {
  var html = "";
  if (typeof window.getSelection != "undefined") {
    var sel = window.getSelection ();
    if (sel.rangeCount) {
      var container = document.createElement ("div");
      for (var i = 0, len = sel.rangeCount; i < len; ++i) {
        container.appendChild (sel.getRangeAt (i).cloneContents ());
      }
      html = container.innerHTML;
    }
  } else if (typeof (document as any).selection != "undefined") {
    if ((document as any).selection.type == "Text") {
      html = (document as any).selection.createRange ().htmlText;
    }
  }
  return html;
}


function _getPositionInBlock(ctx: DataCtx, which) {
  const { types } = ctx;
  const selection = window.getSelection();
  if (!selection[which + 'Node']) return -1;
  let position = selection[which + 'Offset'];

  const node = selection[which + 'Node'];
  // if (node.getAttribute?.('data-type') === 'blockData') {
  //   return position;
  // }

  if (!node) return 0;


  let rootNode = node;
  while (rootNode && rootNode.getAttribute?.('data-type') !== 'blockData') {
    rootNode = rootNode.parentNode;
  }
  if (!rootNode) return 0;

  return getPositionInDataEditor(rootNode, ctx, which);

  let pos = 0;

  const findNode = n => {
    if (n == node) {
      pos += position;
      return true;
    }
    else if (types[n.getAttribute?.('data-type')]?.type == Type.atomic) {
      pos += 1;
    }
    else if (n.nodeType == Node.TEXT_NODE) {
      pos += n.textContent?.length || 0;
    }
    else if (n.nodeType == Node.ELEMENT_NODE) {
      if (n.childNodes) {
        for (let i = 0; i < n.childNodes.length; i++) {
          if (findNode(n.childNodes[i]) === true) {
            return true;
          }
        }
      }
    }
  }

  findNode(rootNode);

  return pos;


}


function getPositionInBlock(ctx: DataCtx) {
  return _getPositionInBlock(ctx, 'anchor');
}

function getContainingList(block: Block) {
  const parent = block.blockManager.findBlockParent(block.getId());
  if (parent) {
    return parent.getChildren();
  }
  else {
    return block.blockManager.getRootBlocks();
  }
}

function getSelectedBlockIds() {
  const el = document.createElement('div');
  el.innerHTML = getSelectionHtml();
  const blockEls = jQuery(el).find('[data-block-id]');
  return blockEls.toArray().map((el) => el.getAttribute('data-block-id'));
}

function getFirstPos(ctx: DataCtx) {
  if (window.getSelection().isCollapsed) {
    return getPositionInBlock(ctx);
  }
  else {
    const firstBlock = getSelectedBlockIds()[0];
    return getBlockSelection(ctx).find((s) => s.blockId === firstBlock).position;  
  }
}


function getFirstBlockInSelection(ctx: DataCtx) {
  if (isMultiBlockSelection()) {
    const firstBlock = getSelectedBlockIds()[0];
    return getBlockSelection(ctx).find(b => b.blockId == firstBlock);  
  }
  else {
    return getBlockSelection(ctx)[0];
  }
}

function isMultiBlockSelection() {
  if (!window.getSelection().isCollapsed) {
    const el = document.createElement('div');
    el.innerHTML = getSelectionHtml();
    const blockEls = jQuery(el).find('[data-block-id]').toArray();
    return blockEls.length > 1;
  }

  return false;
}

export function isFullSelection(ctx: DataCtx, blockManager: BlockManager) {
  const el = document.createElement('div');
  el.innerHTML = getSelectionHtml();
  const blockEls = jQuery(el).find('[data-block-id]').toArray();

  if (blockEls.length) {
    const sortedSel = getBlockSelection(ctx, true);
    if (sortedSel.length != 2) return false;
    // console.log(sortedSel[0].position, dataLength(findBlock(blocks, sortedSel[1].blockId).data), sortedSel[1].position);
    if (sortedSel[0].position == 0 && dataLength(ctx, findBlock(blockManager, sortedSel[1].blockId)?.getContent?.() || []) == sortedSel[1].position) {
      console.log('full delete');
      return true;
    }
  }
}

// MISC
function deleteSelection(ctx: DataCtx, blockManager: BlockManager, replacementChar?) {
  const el = document.createElement('div');
  el.innerHTML = getSelectionHtml();
  const blockEls = jQuery(el).find('[data-block-id]').toArray();

  if (blockEls.length) {
    const blockIds = blockEls.map(el => el.getAttribute('data-block-id'));

    if (isFullSelection(ctx, blockManager)) {

      // TODO: fix
      const fl = flatList(null, blockManager).filter(({ _id }) => !blockIds.includes(_id));
      blockManager.setRootBlocks(constructTree(blockManager, fl));
      // setBlocks(constructTree(fl));
      return;
    }

    console.log('multiple blocks selected')
    const anchor = _getPositionInBlock(ctx, 'anchor');
    const focus = _getPositionInBlock(ctx, 'focus');

    const selection = getBlockSelection(ctx);


    console.log(anchor, focus, selection);

    // const copiedBlocks = [];

    const firstBlockId = blockIds[0];
    const firstBlock = findBlock(blockManager, firstBlockId);
    const firstBlockPos = selection.find(({ blockId }) => blockId == firstBlockId).position;
    // const firstBlockLength = dataLength(firstBlock.data);
    const firstSlice = sliceData(ctx, firstBlock.getContent(), 0, firstBlockPos);

    const lastBlockId = blockIds[blockIds.length - 1];

    const lastBlockPos = selection.find(({ blockId }) => blockId == lastBlockId).position;


    const lastBlock = findBlock(blockManager, lastBlockId);
    const lastBlockLength = dataLength(ctx, lastBlock.getContent());
    
    // console.log(lastBlockPos, lastBlockLength - lastBlockPos + 1, lastBlockLength, lastBlockId, dataToString();

    const lastSlice = sliceData(ctx, lastBlock.getContent(), lastBlockPos, lastBlockLength);

    const newData = replacementChar !== undefined ? concatData(ctx, concatData(ctx, firstSlice, [replacementChar]), lastSlice) : concatData(ctx, firstSlice, lastSlice);


    if (lastBlock.syncing()) {
      const lastBlockEl = jQuery(el).find(`[data-block-id="${lastBlock.getId()}"]`)[0];
      const data = extractFromEl(ctx, jQuery(lastBlockEl).find('[data-type="blockData"]')[0]);
      
      updateBlockData(lastBlock, X(data));
    }

    for (let i = blockIds.length - 1; i >= 1; i--) {
      removeBlock(blockManager, blockIds[i]);
    }
    // firstBlock.data = XClone(newData);
    firstBlock.setContent(newData, false);

  
    // firstBlock.children = X(x(lastBlock.children || []).concat(x(firstBlock.children) || []));
    firstBlock.setChildren(lastBlock.getChildren().concat(firstBlock.getChildren()));
    
    
    clearSelection();
  }
  else {
    document.execCommand('delete');
    return true;
  }
}


function flatList(block: Block, blockManager: BlockManager): FlatList {
  const list: FlatList = [];
  const add = (blocks: BlocksList, parent=null, indentationLevel = 0) => {
    for (const block of blocks.getArray()) {
      list.push({ ...block.serialize(), indentationLevel });
      if (block.hasChildren()) {
        add(block.getChildren(), block.getId(), indentationLevel + 1);
      }
    }
  }

  add(block.getRootBlockList());

  return list;
}

type FlatList = {
  _id: string,
  content: Data
  data
  indentationLevel: number,
  position?
}[];


// TODO: i think this dups children
function constructTree(blockManager: BlockManager, flatList: FlatList): BlocksList {
  const findParent = (fromI, currentIndentation) => {
    for (let i = fromI - 1; i >= 0; -- i) {
      const block = flatList[i];
      if (block.indentationLevel < currentIndentation) {
        return block;
      }
    }
    return null;
  }

  const rootBlocks = blockManager.newBlocksList();
  const blocksMap: {
    [key: string]: Block,
  } = {};
  for (let i = 0; i < flatList.length; ++ i) {
    const block = flatList[i];

    const newBlock = blockManager.newBlock(block._id);
    newBlock.deserialize(block);

    blocksMap[block._id] = newBlock;
    const parent = findParent(i, block.indentationLevel);
    if (parent) {
      const parentBlock = blocksMap[parent._id];
      parentBlock.getChildren().push(newBlock);
    } else {
      rootBlocks.push(newBlock);
    }
  }

  return rootBlocks;
}


function isOnTitle() {
  return jQuery(window.getSelection().focusNode).parents('[data-type="title"]').length || jQuery(window.getSelection().focusNode).is('[data-type="title"]');
}

function paste() {

}

function copy(ctx: DataCtx, blockManager: BlockManager, e) {
  const el = document.createElement('div');
  el.innerHTML = getSelectionHtml();
  const blockEls = jQuery(el).find('[data-block-id]');
  if (blockEls.length) {
    const copiedBlocks: FlatList = [];
    let i = 0;
    for (const blockEl of blockEls) {
      const id = blockEl.getAttribute('data-block-id');
      const data = extractFromEl(ctx, jQuery(blockEl).find('[data-type="blockData"]')[0]);
      const indentation = getBlockIndentation(blockManager, id);
      const block = findBlock(blockManager, id, true);
      let position;
      if (i == 0) {
        position = getFirstPos(ctx);
      }
      else {
        position = 0;
      }

      copiedBlocks.push({
        ...block.serialize(),
        // content: data,
        // data: block.serializeData(),
        position,
        indentationLevel: indentation,
      });
      ++ i;
    }
    e.originalEvent.clipboardData.setData('text/_blocks', JSON.stringify(copiedBlocks));
    console.log(copiedBlocks)
  }
  else {
    // e.originalEvent.clipboardData.setData('text/test', 'test');
    const data = extractFromEl(ctx, el);
    console.log(el, data);
    e.originalEvent.clipboardData.setData('text/_blockSegment', JSON.stringify(data));
    e.originalEvent.clipboardData.setData('text/plain', dataToString(ctx, data));
  }
}

function executeEnter(ctx: DataCtx, blockManager: BlockManager, blockId, pos, e: {altKey}) {
  const block = findBlock(blockManager, blockId);
  let newBlock: Block;

  if (pos == 0 && dataLength(ctx, block.getContent()) > 0) {
    newBlock = blockManager.newBlock();

    const parent = findBlockParent(blockManager, blockId);

    let blocks: BlocksList;
    if (parent) {
      blocks = parent.getChildren();
    }
    else {
      blocks = blockManager.getRootBlocks();
    }

    blocks.splice(blocks.indexOf(block), 0, newBlock);
    
    return block;
  }
  else {
    const position = pos;
    const length = dataLength(ctx, block.getContent());
    const firstPart = sliceData(ctx, block.getContent(), 0, position);
    const secondPart = sliceData(ctx, block.getContent(), position, length);

    updateBlockData(block, firstPart);
    
    newBlock = e.altKey ? blockManager.newBlock() : block.createBlock();

    updateBlockData(newBlock, secondPart);
    
    if (block.hasChildren() && !block.isCollapsed()) {
      block.getChildren().splice(0, 0, newBlock);  
    }
    else {
      const parent = findBlockParent(blockManager, blockId);

      let blocks: BlocksList;
      if (parent) {
        blocks = parent.getChildren();
      }
      else {
        blocks = blockManager.getRootBlocks();
      }

      blocks.splice(blocks.indexOf(block) + 1, 0, newBlock);
    }
  }

  return newBlock;
}

function updateBlockSyncedData(block: Block, data: Data) {
  // TODO: implement
  // if (block.id) {
  //   const entity = getEntityById(block.id);
  //   if (entity) {
  //     XObject.withPass({ internal: block._id }, () => {
  //       entity.name = dataToString(block.data || []);
  //     });
  //   }
  // }
  // else if (block.record) {
  //   // const db = new DB(this.props.database);
  //   const titleCol = db.titleCol();
  //   const record = db.getRecord(block.record);
  //   if (record) {
  //     XObject.withPass({ internal: block._id }, () => {
  //       record.data[titleCol._id] = X(x(block.data))[0] || '';
  //     });
  //   }
  // }

}

function updateBlockData(block: Block, data: Data) {
  block.setContent(XClone(data), false);

  updateBlockSyncedData(block, data);
}

function moveUp(blockManager: BlockManager, blockId: string) {
  const block = blockManager.findBlock(blockId);
  const fl = x(flatList(block, blockManager));
  const index = fl.findIndex(b => b._id == blockId);
  if (index > 0) {
    const prev = fl[index - 1];
    const prevBlock = blockManager.findBlock(prev._id);
    
    const containingList = getContainingList(prevBlock);
    const prevIndex = containingList.indexOf(prevBlock);

    removeBlock(blockManager, blockId);
    containingList.splice(prevIndex, 0, block);
  }
}

function moveDown(blockManager: BlockManager, blockId: string) {
  const block = blockManager.findBlock(blockId);
  const fl = flatList(block, blockManager);
  const index = fl.findIndex(b => b._id == blockId);
  console.log(index, 0);
  console.log(fl);
  
  let nextIndex = index + 1;
  while (fl[nextIndex] && fl[nextIndex].indentationLevel > fl[index].indentationLevel) {
    ++ nextIndex;
  }
  if (nextIndex > index) {
    console.log(nextIndex, 1);
    console.log(fl[index].indentationLevel, fl[nextIndex].indentationLevel);
    const next = fl[nextIndex];
    const nextBlock = blockManager.findBlock(next._id);
    removeBlock(blockManager, blockId);
  
    if (nextBlock.hasChildren()) {
      const containingList = nextBlock.getChildren();
      containingList.splice(0, 0, block);
    }
    else {
      const containingList = getContainingList(nextBlock);
      const nextIndex = containingList.indexOf(nextBlock);
      console.log(nextIndex, 2);
      containingList.splice(nextIndex + 1, 0, block);
    }
  
  }
}


@component
class MobileMenu extends Component<{
  notionDocument: NotionDocument2,
}> {
  static styles = styled.div`
    border-radius: 4px;
    box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px, rgba(15, 15, 15, 0.2) 0px 3px 6px 0px, rgba(15, 15, 15, 0.4) 0px 9px 24px 0px;
    outline-color: rgb(55, 53, 47);
    height: 42px;
    background-color: white;
    display: flex;
    align-items: center;
    width: 100%;
    button {
      height: 100%;
    }

    .actions {
      display: flex;
      flex: 1 1 auto;
      height: 100%;
      border-right: 1px solid #f0f0f0;
    }
    .action.close {
      flex: 0 0 auto;
      border-right: none;
    }

    display: flex;
    .section {
      display: flex;
      height: 100%;
      border-right: 1px solid #f0f0f0;
      .action {
        border-right: none;
      }
    }

    .action {
      width: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #f0f0f0;
      height: 100%;

      svg {
        width: 18px;
        height: 18px;
        fill: #3131319c;
      }

      &.up {
        svg {
          transform: rotate(90deg);
        }
      }

      &.down {
        svg {
          transform: rotate(-90deg);
        }
      }

    }
  `;
  render(Container?) {
    return (
      <Container>
        <div className="actions">
          <div className="action"
            onClick={() => {
              this.props.notionDocument.enter();
            }}
          >
            <Svg name="icons8-enter" />
          </div>
          <div className="section">
            <span className="action unindent"
              onClick={() => {
                unindentBlock(null, this.props.notionDocument.blockManager, this.props.notionDocument.state.activeBlock);
                this.props.notionDocument.forceUpdate();
              }}
            >
              <Svg name="icons8-indent" />
            </span>
            <span className="action indent"
              onClick={() => {
                indentBlock(null, this.props.notionDocument.blockManager, this.props.notionDocument.state.activeBlock);
                this.props.notionDocument.forceUpdate();
              }}
            >
              <Svg name="icons8-outdent" />
            </span>

            <span className="action up"
              onClick={() => {
                this.props.notionDocument.moveUp(this.props.notionDocument.state.activeBlock);
              }}
            >
              <Svg name="chevron-left" />
            </span>

            <span className="action down"
              onClick={() => {
                this.props.notionDocument.moveDown(this.props.notionDocument.state.activeBlock);
              }}
            >
              <Svg name="chevron-left" />
            </span>
          </div>
        </div>
        <span className="action close"
          onClick={() => {
            jQuery(this.props.notionDocument.mobileMenuRef.current).fadeOut();
          }}
        >
          <Svg name="icons8-close" />
        </span>
      </Container>
    )
  }
}


@component
class ColumnResizer extends Component<{
  onResizeEnd
}> {
  static styles = styled.div`
    width: 5px;
    cursor: ew-resize;
    &:before {
      content: '';
      position: absolute;
      background-color: black;
      top: 0;
      bottom: 0;
      width: 2px;
      margin: auto;
    }
  `;

  handler;
  dragging

  pos
  componentDidMount(): void {
    this.handler = e => {
      if (this.dragging) {
        this.pos = {
          x: e.clientX,
          y: e.clientY,
        }
      }
    }

    jQuery(window).mousemove(this.handler);
  }

  componentWillUnmount(): void {
    jQuery(window).off('mousemove', this.handler);
  }

  render(Container?) {
    return (
      <Container
        onMouseDown={e => {
          e.preventDefault();
          this.dragging = true;
          jQuery(window).one('mouseup', () => {
            this.dragging = false;
            this.props.onResizeEnd(this.pos);
          })
        }}
      >
      </Container>
    )
  }
}


const Columns = styled.div``;
const Column = styled.div`
  position: relative;

  ${ColumnResizer} {
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }



  ${ColumnResizer} {
    position: absolute;
    top: 0;
    right: -13px;
    bottom: 0;
  }
`;

const DragArea = styled.div`
  /* pointer-events: none; */
  z-index: 5;
  &.hovering {
    &.above {
      border-top: 3px solid #9fd4ee;
    }

    &.below {
      border-bottom: 3px solid #9fd4ee;
    }

    &.left {
      border-left: 3px solid #9fd4ee;
    }

    &.right {
      border-right: 3px solid #9fd4ee;
    }
  }
`;

function prev() {

  const selection = window.getSelection();

  // Check if there's an actual selection and it's collapsed (caret, not selection range)
  if (selection.rangeCount > 0 && selection.isCollapsed) {
      const range = selection.getRangeAt(0);
      const node = range.startContainer;

      let cont: Node;
      if (jQuery(range.startContainer).is('[data-closed-edges]')) {
        cont = range.startContainer;
      }
      else if (jQuery(range.startContainer).parents('[data-closed-edges]').length) {
        cont = jQuery(range.startContainer).parents('[data-closed-edges]')[0]
      }

      if (cont) {
        console.log(cont, cont.textContent.length, range.startOffset, cont.nextSibling);

        if (range.startOffset == cont.textContent.length) {
          return cont;
        }
      }
      

      // Check if the caret is at the start of a node and the previous node is an element (like a link)
      /*if (range.startOffset === 0 && node.previousSibling && node.previousSibling.nodeType === Node.ELEMENT_NODE) {
        console.log(node.previousSibling)

          // event.preventDefault(); // Prevent the default character input

          // // Insert the character without inheriting style
          // const textNode = document.createTextNode(event.key);
          // node.parentNode.insertBefore(textNode, node);

          // // Move the caret to after the newly inserted character
          // range.setStartAfter(textNode);
          // range.collapse(true);
          // selection.removeAllRanges();
          // selection.addRange(range);
      }*/
  }


}

const replace = (ctx, block: Block, position, amount, newPart) => {
  const data = block.getContent();
  console.log(data, position, amount, newPart);
  const firstPart = sliceData(ctx, data, 0, position);
  const secondPart = sliceData(ctx, data, position + amount, dataLength(ctx, data));


  const suffix = '';

  const newFirstPart = concatData(ctx,
    concatData(ctx, firstPart, newPart),
    suffix
  );
  const newStr = concatData(ctx, newFirstPart, secondPart);

  block.setContent(newStr, false);
}


function getCaretPosition(create=true) {
  const selection = window.getSelection();
  if (!selection.rangeCount) return null;

  const range = selection.getRangeAt(0);
  let rect;

  // Check if the selection is collapsed (caret is showing without a selection range)
  if (range.collapsed && create) {
    // Create a temporary span element and insert it into the range to get its position
    
    const span = document.createElement("span");
    span.style.display = 'inline-block';
    span.style.height = '100%';
    range.insertNode(span);
    rect = span.getBoundingClientRect();

    // Remove the temporary span element after measuring
    span.parentNode.removeChild(span);

    // Normalize the range by collapsing it to the end to not disrupt the caret position
    range.collapse(false);
  } else {
    rect = range.getBoundingClientRect();
  }

  return rect;
}

function createRangeForFirstCharacter(element) {
  if (!element) {
    console.error("Element is not defined");
    return null;
  }

  let range = document.createRange();
  let firstTextNode = getFirstTextNode(element);

  if (firstTextNode) {
    range.setStart(firstTextNode, 0);
    range.setEnd(firstTextNode, 1);
  } else {
    console.error("No text node found in the element");
    return null;
  }

  return range;
}

function getFirstTextNode(element) {
  // Helper function to recursively gather all text nodes
  function collectTextNodes(node, nodes) {
    node.childNodes.forEach(child => {
      if (child.nodeType === Node.TEXT_NODE) {
        nodes.push(child);
      } else {
        collectTextNodes(child, nodes);
      }
    });
  }

  let textNodes = [];
  collectTextNodes(element, textNodes);

  // Iterate through the list of text nodes to find the first non-empty one
  for (let i = 0; i < textNodes.length; i++) {
    if (textNodes[i].nodeValue.trim().length > 0) {
      return textNodes[i];
    }
  }

  return null; // Return null if no suitable text node is found
}



function createRangeForLastCharacter(element) {
  if (!element) {
    console.error("Element is not defined");
    return null;
  }

  let range = document.createRange();
  let lastTextNode = getLastTextNode(element);

  if (lastTextNode) {
    let length = lastTextNode.length;
    range.setStart(lastTextNode, length - 1);
    range.setEnd(lastTextNode, length);
  } else {
    console.error("No text node found in the element");
    return null;
  }

  return range;
}

function getLastTextNode(element) {
  // Helper function to recursively gather all text nodes
  function collectTextNodes(node, nodes) {
    node.childNodes.forEach(child => {
      if (child.nodeType === Node.TEXT_NODE) {
        nodes.push(child);
      } else {
        collectTextNodes(child, nodes);
      }
    });
  }

  let textNodes = [];
  collectTextNodes(element, textNodes);

  // Now iterate backwards through the list of text nodes to find the last non-empty one
  for (let i = textNodes.length - 1; i >= 0; i--) {
    if (textNodes[i].nodeValue.trim().length > 0) {
      return textNodes[i];
    }
  }

  return null; // Return null if no suitable text node is found
}

class BlockComponent extends Component<{ block, parent: NotionDocument2, depth, onDeleted }> {
  componentDidMount(): void {

  }

  onUpdate
  componentDidUpdate(prevProps: Readonly<{ block: any; parent: NotionDocument2; depth: any; }>, prevState: Readonly<{}>, snapshot?: any): void {
    if (this.onUpdate) {
      this.onUpdate();
      delete this.onUpdate;
    }
  }
  
  render() {
    const b = this.props.block;

    const p = this.props.parent;
    const depth = this.props.depth;

    return (
      <Wrapper
        key={p.blockTicks[b.getId()]}
        data-type="blockCont"
        data-depth={depth}
        className={cx({
          dragging: b.getId() == p.dragging?._id,
          mobile: isMobile(),
          collapsed: b.isCollapsed(),
        })}
      >
        {p.props.renderBlock(b, {
          depth: this.props.depth,
          ctx: p.ctx,
          onMouseDownGrip: (e, b) => {
            p.dragStart = b;
            e.preventDefault();
          },
          draggingId: p.dragging?._id,
          beforeChange: () => {
            p.saveState('beforeChange');
          },
          changed: () => {
            console.log('changed', p._tick)
            // this.tick++;
            p.forceUpdate()
          },
          activeBlock: p.state.activeBlock,
          onContextMenu: (e, block) => {
            e.preventDefault();
            showContextMenu(e, [
              {
                text: 'Delete',
                onClick: () => {
                  removeBlock(p.blockManager, block._id);
                  p.forceUpdate();
                  this.props.onDeleted?.();
                },
              },
              {
                text: 'Debug',
                onClick: () => {
                  console.log(x(block));
                },
              },


              ...(p.props.contextMenuItems?.(block) || []),
            ])
          },
        }, {
          highlighter: p.props.highlighter,
          ...(p.props.renderBlockArgs || {}),
        })}

        {!b.isCollapsed() && (
          <div className="children" data-type="blockChildren">
            {b.hasChildren() && b.getChildren().map(b => p.renderBlock(b, depth+1))}
          </div>
        )}
      </Wrapper>
    )
  }
}

class MenuWrapper extends Component<{ children, onMount? }> {
  componentDidMount(): void {
    this.props.onMount?.();
  }
  componentDidUpdate(prevProps: Readonly<{ children: any; onMount: any; }>, prevState: Readonly<{}>, snapshot?: any): void {

  }
  render() {
    return this.props.children;
  }
}

@component
export class NotionDocument2 extends Component<{
  blockManager: BlockManager,
  title?,
  setTitle?,
  renderBlock
  menuIniters?
  extState?
  types,
  renderBlockArgs?
  insidePositionContext?: boolean,
  onBlockSelected?
  ctxArgs?
  readOnly?
  onDeleted?

  client?

  menuPresenter?: MenuPresenter
  menuIniterKeys?

  highlighter?

  autoComplete?

  contextMenuItems?

}> {
  static styles = styled.div`
    background-color: ${color('bg')};
    color: ${color('text')};
    padding-bottom: 50px;
    position: relative;

    .formatMenu {
      background-color: white;
      border: 1px solid black;
    }

    > .wrapper {
      &.hideOverlays .grip {
        opacity: 0;
      }
      cursor: text;
      padding-left: 32px;
      z-index: 0;
      transform: translate(0, 0);
      overflow-x: auto;
      overflow-y: hidden;

      /* padding-top: 8px; */
      /* padding-bottom: 8px; */

      &:focus {
        outline: none;
      }

      > .title {
        margin-bottom: 10px;
        min-height: 31px;
        font-weight: 700;
        line-height: 1.2;
        font-weight: 600;
        font-size: 26px;

        &:empty::before {
          content: 'Untitled';
          color: #373737; 
        }
        &:empty:focus::before {
            content: "";
        }
      }

      > .mobileMenu {
        position: absolute;
        left: 0;
        width: 100%;
        z-index: 999999;
      }
    }

    &.mobile {
      > .wrapper {
        padding-left: 0;
        padding-right: 0;
      }
    }

    ${Inspector} {
      position: absolute;
      right: 0;
      top: 0;
    }

    ${Columns} {
      display: flex;
      padding: 10px 0;
      ${Column} {
        flex: 1 1 auto;
        width: 0;

        &:not(:first-child) {
          margin-left: 20px;
        }
      }
    }

    .toggle {
      position: absolute;
      /* left: -17px;
      top: 11px; */
      width: 6px;
      height: 6px;
      transform: rotate(45deg);
      cursor: pointer;
      border-right: 1px solid #c4c4c4;
      border-bottom: 1px solid #c4c4c4;
      &:hover {
        border-color: #b0b0b0;
      }

      &.collapsed {
        transform: rotate(315deg);
      margin-left: -2px;
      border-color: #595959;

      }
    }

    > .gutter {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 20px;
      background-color: white;
      z-index: 1;
    }
  `;

  static reactive = false;

  title

  showMenu

  history = [];
  historyIndex = 0;

  // menuRef = React.createRef<Menu>();

  currentScroll
  selection
  menuPos

  blockManager: BlockManager;

  dragging
  dragStart

  wrapperEl = {current: null}
  _tick = 0

  hideOverays = false;

  ignoreEnter

  letNextInsert

  position

  initial

  ctx: DataCtx;


  state = XInit(class {
    activeBlock
  });

  constructor(props) {
    super(props);
    this.title = this.props.title;
    this.blockManager = this.props.blockManager;
    this.ctx = {
      types: this.props.types,
      args: this.props.ctxArgs,
    }

    window['g_notionDocument2'] = this;
  }
  
  doUpdateAreas() {
    this.updateAreas();
    this.forceUpdate();   
  }

  uiApi(): UIAPI {
    return {
      getBlock: id => {
        try {
          return jQuery(ReactDOM.findDOMNode(this)).find(`[data-block-id="${id}"]`);
        }
        catch (e) {
          console.log('error', e, id);
        }
      }
    }
  }

  timerId2
  componentDidMount() {
    const el = ReactDOM.findDOMNode(this);
    if (!this.props.readOnly) {
      jQuery(el).on('cut', '[contenteditable]', this.collectFunc((e) => {
        this.saveState('cut');
        e.preventDefault();
        copy(this.ctx, this.blockManager, e);
        this.deleteSelection();
        this.forceUpdate();  
      }));
  
      jQuery(el).on('copy', '[contenteditable]',  this.collectFunc((e) => {
        copy(this.ctx, this.blockManager, e);
        e.preventDefault();
      }));
      
      jQuery(el).on('paste', '[contenteditable]',  this.collectFunc((e) => {
        const d = e.originalEvent.clipboardData.getData('text/_blocks')
        e.preventDefault();
        if (d) {
          this.saveState('paste');
          const pastedData: FlatList = JSON.parse(d);
          const a = getFirstBlockInSelection(this.ctx);
          const currentBlockId = a.blockId;
          const position = a.position;
  
          if (!window.getSelection().isCollapsed) {
            if (this.deleteSelection()) {
              this.save('paste');
            }
          }
  
          // console.log(pastedData, currentBlockId);
          const fullCopy = pastedData[0].position == 0;
  
          const flatList = this.flatList(this.blockManager.findBlock(currentBlockId));
          const currentBlockIndex = flatList.findIndex((block) => block._id === currentBlockId);
          const currentBlockIndentation = flatList[currentBlockIndex].indentationLevel;
  
          const newBlocks: FlatList = [];
          let firstIndentation = null;
          let lastSlice;
  
          let i = 0;
          for (const pastedBlock of pastedData) {
            if (firstIndentation === null) {
              firstIndentation = pastedBlock.indentationLevel;
  
              if (!fullCopy) {
                const c = flatList.find((block) => block._id === currentBlockId);
  
  
                const length = createChunked(this.ctx, c.content).length;
  
                const firstSlice = sliceData(this.ctx, c.content, 0, position);
                lastSlice = sliceData(this.ctx, c.content, position, length);
  
                c.content = concatData(this.ctx, firstSlice, pastedBlock.content);
              }
              else {
                newBlocks.push({
                  _id: XObject.id(),
                  content: pastedBlock.content,
                  data: pastedBlock.data,
                  indentationLevel: pastedBlock.indentationLevel - firstIndentation + currentBlockIndentation,
                })
    
              }
  
            }
            else {
  
              let data = pastedBlock.content;
              if (!fullCopy && i === pastedData.length - 1) {
                data = concatData(this.ctx, data, lastSlice);
              }
              newBlocks.push({
                _id: XObject.id(),
                data: pastedBlock.data,
                content: data,
                indentationLevel: pastedBlock.indentationLevel - firstIndentation + currentBlockIndentation,
              })
            }
  
            ++i;
  
          }
  
          if (!fullCopy) {
            const last = newBlocks[newBlocks.length - 1];
            // TODO: huh?
            // if (last.id) {
            //   updateBlockSyncedData(last, last.data);
            // }  
          }
  
          if (fullCopy && dataLength(this.ctx, flatList[currentBlockIndex].content) == 0) {
            flatList.splice(currentBlockIndex, 1, ...newBlocks);
          }
          else {
            flatList.splice(currentBlockIndex + 1, 0, ...newBlocks);
          }
  
          const tree = this.constructTree(flatList);
  
          // TODO: make work in columns
          this.blockManager.setRootBlocks(tree);
          this.doTick();
        }
        else {
          let d = e.originalEvent.clipboardData.getData('text/_blockSegment')
          let pasted;
          if (d) {
            d = JSON.parse(d);
            pasted = d;
          }
          else {
            d = e.originalEvent.clipboardData.getData('text/plain');
            if (d) {
              pasted = [d];
            }
          }
  
          if (pasted) {
            this.saveState('paste');
            const block = findBlock(this.blockManager, getBlockSelection(this.ctx)[0].blockId);
            const position = getBlockSelection(this.ctx)[0].position;
            const length = createChunked(this.ctx, block.getContent()).length;
            const firstSlice = sliceData(this.ctx, block.getContent(), 0, position);
            const lastSlice = sliceData(this.ctx, block.getContent(), position, length);
            block.setContent(X(concatData(this.ctx, concatData(this.ctx, firstSlice, pasted), lastSlice)), false);

            const el = jQuery(window.getSelection().focusNode).closest('[data-block-id]')

            const blockId = el.data('block-id');
            const editorEl = el.find('.editor');
        
            const pos = (editorEl.length > 0 ? getPositionInDataEditor(editorEl[0], this.ctx) : null);
        
            this.updateBlock(block.getId(), pos + d.length);
          }
        }
      }));
  
      jQuery(el).on('beforeinput', '[contenteditable]', (e) => {
        if (this.showMenu) return;
        if (isOnTitle()) {
          if (e.originalEvent.inputType == 'insertParagraph') {
            e.preventDefault();
            const newBlock = this.blockManager.newBlock();
            this.blockManager.getRootBlocks().splice(0, 0, newBlock);
            this.doTick();
  
            setTimeout(() => {
              setCaretToBlockSelection(this.uiApi(), this.ctx, [
                {
                  blockId: newBlock.getId(),
                  position: 0,
                },
                {
                  blockId: newBlock.getId(),
                  position: 0,
                }
              ])
            }, 0)
  
            return;
          }
        }
        const type = e.originalEvent.inputType;
        if (type.startsWith('format') || type.startsWith('insert') || type.startsWith('delete')) {
          this.saveState(type);
        }
      });
  
      jQuery(el).on('input', '[contenteditable]', e => {
        if (!jQuery(e.target).is('[contenteditable]')) return;
        
        if (this.showMenu) return;
        if (isOnTitle()) {
          const titleEl = jQuery(window.getSelection().focusNode).closest('[data-type="title"]')
          if (!titleEl.text()) titleEl.text('');
          this.props.setTitle(titleEl.text());
          this.title = titleEl.text();
        }
        else {
          const type = e.originalEvent.inputType;
  
          if (type.startsWith('format') || (type.startsWith('insert') || type.startsWith('delete')) && !this.showMenu) {
            this.save('input');
          }
        }
      });
  
      document.addEventListener('selectionchange', e => {
        const sel = window.getSelection();
  
        if (!sel.isCollapsed) {
          // console.log('hello', );
          const rect = sel.getRangeAt(0).getBoundingClientRect()
  
          jQuery(this.formatMenuRef.current).css({
            display: 'block',
            left: rect.left,
            top: rect.top - 40,
            position: 'fixed',
          })
        }
        else {
          jQuery(this.formatMenuRef.current).css({
            display: 'none',
          })
        }
  
        const b = getBlockSelection(this.ctx);
        const blockId = b?.[0]?.blockId;
  
        if (!blockId) return;
  
        if (isMobile()) {
          jQuery(this.mobileMenuRef.current).fadeIn();
        }
  
        if (blockId != this.state.activeBlock) {
          this.state.activeBlock = blockId;
          if (this.props.extState) {
            this.props.extState.activeBlock = blockId;
          }
          const block = findBlock(this.blockManager, blockId);
          this.props.onBlockSelected?.(block);
  
          jQuery(this.wrapperEl.current).find('.activeBlock').removeClass('activeBlock');
          jQuery(this.wrapperEl.current).find(`[data-block-id="${this.state.activeBlock}"]`).addClass('activeBlock');  
        }
      });
  
      jQuery(window).mousemove(e => {
        if (this.dragStart) {
          this.dragging = this.dragStart;
          delete this.dragStart;
          this.updateAreas();
          this.forceUpdate();
          jQuery('html').addClass('dragging');
        }
        if (this.dragging) {

          // find area under cursor
          const area = jQuery(document.elementFromPoint(e.clientX, e.clientY)).closest('.dragArea');
          jQuery('.dragArea.hovering').removeClass('hovering');
          area.addClass('hovering');
        }
      })
  
      jQuery(window).mouseup(e => {
        delete this.dragStart;
        if (this.dragging) {
          const areaEl = jQuery(document.elementFromPoint(e.clientX, e.clientY)).closest('.dragArea');
          
          const area = this.areas[areaEl.attr('data-index')];
          
          // console.log(area);
          this.saveState('drop');
          area.action(this.blockManager.findBlock(this.dragging._id));
          this.dragging = false;
          jQuery('.dragArea.hovering').removeClass('hovering');
          jQuery('html').removeClass('dragging');
        }
      });

      this.updateArrows();
  
    }


    const updateMetaPos = () => {
      this.updateMetaPos();
    }

    const updateMobileToolbarPos = () => {
      if (isMobile()) {
        const el = jQuery(ReactDOM.findDOMNode(this)).find(`[data-block-id=${this.state.activeBlock}]`)
        if (this.mobileMenuRef.current && el[0]) {
          this.mobileMenuRef.current.style.top = `${el.position().top + el.outerHeight() + 16}px`;
        }  
      }
    }

    this.timerId2 = setInterval(() => {
      updateMetaPos();
      updateMobileToolbarPos();
    }, 10);
    updateMetaPos();
    updateMobileToolbarPos();

    jQuery(el).click(e => {
      if (this.mobileMenuRef.current && jQuery.contains(this.mobileMenuRef.current, e.target)) return;

      const wrapper = jQuery(el).children('.wrapper')[0];
      const rect = wrapper.getBoundingClientRect();

      if (e.clientY > rect.bottom) {
        const lastBlock = this.blockManager.getRootBlocks().get(this.blockManager.getRootBlocks().getLength() - 1);
        if (!lastBlock || !(lastBlock instanceof Block) || !lastBlock.hasContent() || dataLength(this.ctx, lastBlock.getContent()) > 0) {
          const newBlock = this.blockManager.newBlock();
          this.blockManager.getRootBlocks().push(newBlock);
          this.doTick();
          setTimeout(() => {
            setCaretToBlockSelection(this.uiApi(), this.ctx, [
              { blockId: newBlock.getId(), position: 0 },
              { blockId: newBlock.getId(), position: 0}
            ]);
          }, 0);
        }
        else {
          setTimeout(() => {
            setCaretToBlockSelection(this.uiApi(), this.ctx, [
              { blockId: lastBlock.getId(), position: 0 },
              { blockId: lastBlock.getId(), position: 0}
            ]);
          }, 0);
        }
      }
    });

    // // setTimeout(() => {
      // this.updateAreas();
      // this.forceUpdate();   
    // // }, 100);
  }

  componentDidUpdate(): void {
    if (this.selection) {
      setCaretToBlockSelection(this.uiApi(), this.ctx, this.selection);
      this.selection = null;
    }

    if (this.restorePos) {
      setCaretToBlockSelection(this.uiApi(), this.ctx, this.restorePos);
      this.restorePos = null;
    }


    this.updateArrows();
  }

  // getSnapshotBeforeUpdate(prevProps: Readonly<{ blockManager: BlockManager; title?: any; setTitle?: any; renderBlock: any; menuIniters?: any; extState?: any; types: any; renderBlockArgs?: any; insidePositionContext?: boolean; onBlockSelected?: any; }>, prevState: Readonly<{}>) {
  // }

  shouldComponentUpdate(nextProps, nextState) {
    // TODO: reimplement
    // return nextProps.blocks != this.props.blocks;
    if (this.props.readOnly) return true;
    return false;
  }

  moveUp(block: string) {
    // const blockSelection = getBlockSelection(this.ctx);
    moveUp(this.blockManager, block);
    this.forceUpdate();
    // setTimeout(() => {
    //   console.log('moveUp', blockSelection);
    //   setCaretToBlockSelection(this.ctx, blockSelection);
    // }, 5);

  }


  moveDown(block: string) {
    moveDown(this.blockManager, block);
    this.forceUpdate();
  }

  transaction(block) {
    block();
  }

  blockRefs = {}

  blockTicks = {};

  renderBlock(b: Block, depth=0) {

    if (!this.blockRefs[b.getId()]) {
      this.blockRefs[b.getId()] = React.createRef();
    }

    return <BlockComponent
    onDeleted={() => {
      this.props.onDeleted?.(b.getId());
    }}
    // _key={b.getId() + this.blockTicks[b.getId()]}
    ref={this.blockRefs[b.getId()]}
    key={b.getId() + this.blockTicks[b.getId()]}
    depth={depth}
      block={b}
      parent={this}
    />

    return (
      <Wrapper
        
        data-type="blockCont"
        data-depth={depth}
        className={cx({
          dragging: b.getId() == this.dragging?._id,
          mobile: isMobile(),
          collapsed: b.isCollapsed(),
        })}
      >
        {this.props.renderBlock(b, {
          depth,
          ctx: this.ctx,
          onMouseDownGrip: (e, b) => {
            this.dragStart = b;
            e.preventDefault();
          },
          draggingId: this.dragging?._id,
          beforeChange: () => {
            this.saveState('beforeChange');
          },
          changed: () => {
            console.log('changed', this._tick)
            // this.tick++;
            this.forceUpdate()
          },
          activeBlock: this.state.activeBlock,
          onContextMenu: (e, block) => {
            e.preventDefault();
            showContextMenu(e, [
              {
                text: 'Delete',
                onClick: () => {
                  removeBlock(this.blockManager, block._id);
                  this.forceUpdate();
                },
              },


              ...(this.props.contextMenuItems?.(block) || []),
            ])
          },
        }, {
          highlighter: this.props.highlighter,
          ...(this.props.renderBlockArgs || {}),
        })}

        {!b.isCollapsed() && (
          <div className="children" data-type="blockChildren">
            {b.hasChildren() && b.getChildren().map(b => this.renderBlock(b, depth+1))}
          </div>
        )}
      </Wrapper>
    )
  }

  funcs = [];
  collectFunc(func) {
    this.funcs.push(func);
    return func;
  }

  flatList(block: Block) {
    return flatList(block, this.blockManager);
  }

  constructTree(flatList) {
    return constructTree(this.blockManager, flatList);
  }

  getBlock(id) {
    return findBlock(this.blockManager, id);
  }

  currentBlock() {
    const el = jQuery(window.getSelection().focusNode.parentElement).closest('[data-block-id]')
    const blockId = el.data('block-id');
    return findBlock(this.blockManager, blockId);
  }

  deleteSelection(replaceChar?) {
    return deleteSelection(this.ctx, this.blockManager, replaceChar);
  }

  lastPoses = {};
  updateMetaPos() {
    const lastPoses = this.lastPoses;
    for (const el of jQuery(this.wrapperEl.current).find('.meta')) {
      let top, left;
      const blockEl = jQuery(el).parent();
      const blockId = blockEl.attr('data-block-id');
      const editorEl = jQuery(blockEl).find('.editor');
      if (editorEl.find('[data-unmounted]').length) continue;
      const range = document.createRange();
      range.selectNodeContents(editorEl[0]);
      let rect: {top, right} = range.getBoundingClientRect();
      if (!rect.top && !rect.right) {
        rect = {
          top: editorEl[0].getBoundingClientRect().top,
          right: editorEl[0].getBoundingClientRect().left,
        }
      }
      const relativeTo = blockEl[0].getBoundingClientRect();
      top = rect.top - relativeTo.top;
      left = rect.right - relativeTo.left;

      if (lastPoses[blockId]?.left != left || lastPoses[blockId]?.top != top) {
        lastPoses[blockId] = {left, top};
        jQuery(el).css({
          visibility: '', 
          position: 'absolute',
          left: left + 6,
          top: top + 2,
        })
      }
    }
  }

  areas: {
    block: Block,
    side: string,
    action: (block: Block) => void,
    top,
    left, width, height,
  }[] = []
  updateAreas() {
    function getOffset(el): {
      top
      left
      width
      height
    } {
      return {
        top: jQuery(el)[0].offsetTop,
        left: jQuery(el)[0].offsetLeft,

        width: jQuery(el).width(),
        height: jQuery(el).height(),
      }
    }

    this.areas = [];
    const iterate = (list: BlocksList, parent) => {
      for (let i = 0; i < list.getLength(); ++ i) {
        const block: Block | BlockColumns = list.get(i) as any;
        if (block instanceof Block) {
          const el = jQuery(ReactDOM.findDOMNode(this)).find(`[data-block-id="${block.getId()}"]`)[0];
          if (!el) continue;

          // above
          if (i == 0) {
            const offset = getOffset(el);
            this.areas.push({
              block: parent,
              side: 'above',
              action: dropped => {
                console.log(dropped);
                removeBlock(this.blockManager, dropped.getId(), true);
                const parent = findBlockParent(this.blockManager, block.getId());
                const blocks = parent ? parent.getChildren() : this.blockManager.getRootBlocks();
                const index = blocks.indexOf(block);
                blocks.splice(index, 0, dropped);
                this.dragging = null;
  
                this.forceUpdate();
              },
              top: offset.top + 1,
              left: offset.left + 1,
              width: offset.width - 2,
              height: offset.height/2 - 2,
            })
          }
  
          // below
          {
            const blockCont = jQuery(el).parents('[data-type="blockCont"]');
            const offset = getOffset(el);
            this.areas.push({
              block: parent,
              side: 'below',
              action: b => {
                removeBlock(this.blockManager, b.getId(), true);
                const parent = findBlockParent(this.blockManager, block.getId());
                const blocks = parent ? parent.getChildren() : this.blockManager.getRootBlocks();
                const index = blocks.indexOf(block);
                blocks.splice(index + 1, 0, b);
                this.dragging = null;
                this.forceUpdate();
              },
  
              top: offset.top + jQuery(blockCont).outerHeight()/2 + 1,
              left: offset.left + 1,
              width: jQuery(el).width() - 2,
              height: jQuery(blockCont).outerHeight()/2 - 2,
            });
          }
  
          // child
          if (!block.isCollapsed()) {
            const offset = getOffset(el);
            this.areas.push({
              block,
              side: 'below',
              action: b => {
                removeBlock(this.blockManager, b.getId(), true);
                const blocks = block.getChildren();
                blocks.splice(0, 0, b);
                this.dragging = null;
                this.forceUpdate();
              },
  
              top: offset.top + 30/2 + 1,
              left: offset.left + 1 + 24,
              width: jQuery(el).width() - 2 - 24,
              height: 30/2 - 2,
            });
            block.hasChildren() && iterate(block.getChildren(), block);
          }
  
  
          // left
          if (!parent) {
            const blockCont = jQuery(el).parents('[data-type="blockCont"]');
            const offset = getOffset(blockCont);
            this.areas.push({
              block: parent,
              side: 'left',
              action: b => {
                removeBlock(this.blockManager, b.getId(), true);
                const blocks = this.blockManager.getRootBlocks();
                blocks.createColumns(block, b, 'left');
                this.dragging = null;
                this.forceUpdate();
              },
  
              top: offset.top,
              left: offset.left - 10,
              width: 10,
              height: blockCont.height(),
            });
          }
  
          // right
          if (!parent) {
            const blockCont = jQuery(el).parents('[data-type="blockCont"]');
            const offset = getOffset(blockCont);
            this.areas.push({
              block: parent,
              side: 'right',
              action: b => {
                removeBlock(this.blockManager, b.getId(), true);
                const blocks = this.blockManager.getRootBlocks();
                blocks.createColumns(block, b, 'right');
                this.dragging = null;
                this.forceUpdate();
  
              },
  
              top: offset.top,
              left: offset.left + blockCont.width(),
              width: 10,
              height: blockCont.height(),
            });
          }
        }
        else if (block instanceof BlockColumns) {
          const el = jQuery(`[data-block-columns-id="${block.getId()}"]`)[0];
          console.log(el);

          // above
          if (i == 0) {
            const offset = getOffset(el);
            this.areas.push({
              block: parent,
              side: 'above',
              action: dropped => {
                let index;
                const col = this.blockManager.getBlockColumn(dropped.getId());
                const blocks = this.blockManager.getRootBlocks();

                if (col?.[0]?.getId?.() === block.getId()) {
                  index = blocks.indexOf(block as any);
                }

                removeBlock(this.blockManager, dropped.getId(), true);

                if (_.isNil(index)) index = blocks.indexOf(block as any);
                blocks.splice(index, 0, dropped);
                this.dragging = null;
  
                this.forceUpdate();
              },
              top: offset.top + 1,
              left: offset.left + 1,
              width: jQuery(el).width() - 2,
              height: 10,
            })
          }
  
          // below
          {
            const offset = getOffset(el);
            this.areas.push({
              block: parent,
              side: 'below',
              action: dropped => {
                let index;
                const col = this.blockManager.getBlockColumn(dropped.getId());
                const blocks = this.blockManager.getRootBlocks();

                if (col?.[0]?.getId?.() === block.getId()) {
                  index = blocks.indexOf(block as any);
                }

                removeBlock(this.blockManager, dropped.getId(), true);

                if (_.isNil(index)) index = blocks.indexOf(block as any);
                blocks.splice(index + 1, 0, dropped);
                this.dragging = null;
  
                this.forceUpdate();

              },
  
              top: offset.top + jQuery(el).outerHeight() - 10,
              left: offset.left + 1,
              width: jQuery(el).width() - 2,
              height: 10,
            });
          }

          // left
          if (!parent) {
            // const blockCont = jQuery(el).parents('[data-type="blockCont"]');
            const offset = getOffset(el);
            this.areas.push({
              block: parent,
              side: 'left',
              action: b => {
                removeBlock(this.blockManager, b.getId(), true);
                block.insertColumn(0, b);
                this.dragging = null;
                this.forceUpdate();
              },

              top: offset.top,
              left: offset.left - 10,
              width: 20,
              height: jQuery(el).height(),
            });
          }
          
          // right
          if (!parent) {
            // const blockCont = jQuery(el).parents('[data-type="blockCont"]');
            const offset = getOffset(el);
            this.areas.push({
              block: parent,
              side: 'right',
              action: b => {
                removeBlock(this.blockManager, b.getId(), true);
                block.insertColumn(block.getColumns().length, b);
                this.dragging = null;
                this.forceUpdate();
              },

              top: offset.top,
              left: offset.left + jQuery(el).width(),
              width: 10,
              height: jQuery(el).height(),
            });
          }

          for (const col of block.getColumns()) {
            iterate(col.getChildren(), col);
          }
        }
      }

    }
    iterate(this.blockManager.getRootBlocks(), null);
    // console.log(this.areas);
  }

  getCaretPosition(): {
    left,
    top,
    height
  } {
    const pos = getPositionInBlock(this.ctx);
    const el = jQuery(window.getSelection().focusNode).closest('[data-block-id]')
    const blockId = el.data('block-id');
    const block = this.blockManager.findBlock(blockId);

    if (!block) {
      return;
    }

    if (dataLength(this.ctx, block.getContent()) == 0) {
      return el.find('.editor')[0].getBoundingClientRect();
    }
    else if (pos == 0 || pos == dataLength(this.ctx, block.getContent())) {
      if (pos == 0) {
        const range = createRangeForFirstCharacter(el.find('.editor')[0]);
        if (range) {
          const rect = range.getBoundingClientRect();
          return {
            left: rect.left,
            top: rect.top,
            height: rect.height,
          }
        }
        else {
          console.log('no range')
        }
      }
      else if (pos == dataLength(this.ctx, block.getContent())) {
        const range = createRangeForLastCharacter(el.find('.editor')[0]);
        if (range) {
          const rect = range.getBoundingClientRect();
          return {
            left: rect.right,
            top: rect.top,
            height: rect.height,
          }
        }
        else {
          console.log('no range')
        }

      }
      return getCaretPosition(false);
    }
    else {
      return getCaretPosition(false);
    }
  }

  updateBlocks() {
    const el = jQuery(window.getSelection().focusNode).closest('[data-block-id]')

    const blockId = el.data('block-id');
    const editorEl = el.find('.editor');

    const pos = editorEl.length > 0 ? getPositionInDataEditor(editorEl[0], this.ctx) : null;

    for (const id in this.blockRefs) {
      this.updateBlock(id);
    }

    if (!_.isNil(pos) && pos >= 0) {
      setTimeout(() => {
        const newBlockEl = jQuery(`[data-block-id=${blockId}]`, ReactDOM.findDOMNode(this));
        const newEditorEl = newBlockEl.find('.editor');
        if (newEditorEl.length) {
          const offset = resolveOffset(this.ctx, newEditorEl[0], pos);
          if (offset[1] >= 0) {
            try {
              window.getSelection().setBaseAndExtent(offset[0], offset[1], offset[0], offset[1]);
            }
            catch (e) {
              console.log(e);
            }
          }
          else {
            console.log('invalid offset');
          }
        }  
      }, 0)
    }
  }

  doTick(force?) {
    const el = jQuery(window.getSelection().focusNode).closest('[data-block-id]')

    const blockId = el.data('block-id');
    const editorEl = el.find('.editor');

    const pos = editorEl.length > 0 ? getPositionInDataEditor(editorEl[0], this.ctx) : null;

    this.props.blockManager.tick();
    if (force) {
      this._tick ++;

    }
    this.hideOverays = true;
    this.forceUpdate();
    this.updateAreas();
    this.forceUpdate();

    if (!_.isNil(pos) && pos >= 0) {
      setTimeout(() => {
        const newBlockEl = jQuery(`[data-block-id=${blockId}]`, ReactDOM.findDOMNode(this));
        const newEditorEl = newBlockEl.find('.editor');
        if (newEditorEl.length) {
          const offset = resolveOffset(this.ctx, newEditorEl[0], pos);
          if (offset[1] >= 0) {
            try {
              window.getSelection().setBaseAndExtent(offset[0], offset[1], offset[0], offset[1]);
            }
            catch (e) {
              console.log(e);
            }
          }
          else {
            console.log('invalid offset');
          }
        }  
      }, 0)
    }

  }

  saveState(action, e?) {
    // TODO: reimplement

    // this.history.push({
    //   _id: XObject.id(),
    //   blocks: _.cloneDeep(x(this.getBlocks())),
    //   selection: getBlockSelection(),
    //   action,
    //   e,
    // })

    // console.log('saveState', this.historyIndex = this.history.length - 1, e?.key);
  }

  timerId
  undo() {

    // TODO: reimplement
    /*if (!this.history[this.historyIndex]) {
      console.log('no undo history');
      return;
    }


    const entry = this.history[this.historyIndex];
    console.log('undo', entry.action);

    this.setBlocks(X(entry.blocks));
    const selection = entry.selection;
    this.doTick();
    this.historyIndex --;

    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      try {
        setCaretToBlockSelection(selection);
      }
      catch (e) {
        console.log(entry);
        console.log(selection);
        console.log(e);
      }
    }, 1);*/
  }

  menu: MenuType

  menuPresenter() {
    return this.props.menuPresenter || presentMenu;
  }

  asdfTimerId
  autoCompleteMenuState = XInit(class {
    position
  })
  showCompleteMenu
  menuRef = React.createRef<Menu>();

  
  updasdf() {
    if (this.showCompleteMenu?.cont) {
      try {
        let left, top, height;
        const p = this.getCaretPosition();
        if (p) {
          ({ left, top, height } = p);



          this.showCompleteMenu.cont.css({
            left,
            top: top + height,
            bottom: '',
          })
          clearTimeout(this.asdfTimerId);


            if (this.showCompleteMenu.cont.offset().top + this.showCompleteMenu.cont.outerHeight() > jQuery(window).height()) {
              this.showCompleteMenu.cont.css({
                top: '',
                bottom: jQuery(window).height() - top + 10,
              })
            }

            if (this.showCompleteMenu.cont.offset().left + this.showCompleteMenu.cont.outerWidth() > jQuery(window).width()) {
              this.showCompleteMenu.cont.css({
                left: jQuery(window).width() - this.showCompleteMenu.cont.outerWidth(),
              })
            }
    
          
          // this.asdfTimerId = setTimeout(() => {
            this.autoCompleteMenuState.position = this.getPositionInBlock();
      
      
          // }, 500);
        }
        

      }
      catch (e) {
        console.log(e);
      }
    }
    else {
      console.log('no autocomplete menu');
    }


  }

  closeAutocompleteMenu() {
    delete this.explicitAutoCompleteMenu;
    this.menuRef.current?.close?.(() => {
      this.showCompleteMenu.root.unmount();
      this.showCompleteMenu.cont.remove();
      delete this.showCompleteMenu;
    })
  }

  explicitAutoCompleteMenu = false;

  showingMenu

  updateBlock(id, newPos?) {
    if (!this.blockTicks[id]) {
      this.blockTicks[id] = 0;
    }
    this.blockTicks[id]++;

    const func = this.saveCaretPos2(newPos);

    this.blockRefs[id].current.onUpdate = () => {
      func();
    }
    this.blockRefs[id].current.forceUpdate();
  }


  saveCaretPos2(newPos?) {
    const el = jQuery(window.getSelection().focusNode).closest('[data-block-id]')

    const blockId = el.data('block-id');
    const editorEl = el.find('.editor');

    const pos = !_.isNil(newPos) ?newPos : (editorEl.length > 0 ? getPositionInDataEditor(editorEl[0], this.ctx) : null);

    
    return () => {
      if (!_.isNil(pos) && pos >= 0) {

          const newBlockEl = jQuery(`[data-block-id=${blockId}]`, ReactDOM.findDOMNode(this));
          const newEditorEl = newBlockEl.find('.editor');
          if (newEditorEl.length) {
            const offset = resolveOffset(this.ctx, newEditorEl[0], pos);
            if (offset[1] >= 0) {
              try {
                window.getSelection().setBaseAndExtent(offset[0], offset[1], offset[0], offset[1]);
              }
              catch (e) {
                console.log(e);
              }
            }
            else {
              console.log('invalid offset');
            }
          }  
      }
    }


  }

  saveCaretPos(func) {
    const el = jQuery(window.getSelection().focusNode).closest('[data-block-id]')

    const blockId = el.data('block-id');
    const editorEl = el.find('.editor');

    const pos = editorEl.length > 0 ? getPositionInDataEditor(editorEl[0], this.ctx) : null;

    func();

    if (!_.isNil(pos) && pos >= 0) {
      setTimeout(() => {
        const newBlockEl = jQuery(`[data-block-id=${blockId}]`, ReactDOM.findDOMNode(this));
        const newEditorEl = newBlockEl.find('.editor');
        if (newEditorEl.length) {
          const offset = resolveOffset(this.ctx, newEditorEl[0], pos);
          if (offset[1] >= 0) {
            try {
              window.getSelection().setBaseAndExtent(offset[0], offset[1], offset[0], offset[1]);
            }
            catch (e) {
              console.log(e);
            }
          }
          else {
            console.log('invalid offset');
          }
        }  
      }, 0)
    }

  }

  presentAutoCompleteMenu({ left, top }) {
    if (!this.props.autoComplete) return;
    if (!this.showCompleteMenu?.cont) {
      const cont = jQuery('<div />').css({
        position: 'absolute',
        left,
        top,
        zIndex: 9999999999999,
      });
      jQuery('body').append(cont);
      const root = ReactDOMClient.createRoot(cont[0], {

      })
      root.render(
        <MenuWrapper
          onMount={() => {

          }}
        >
          <Menu
            onMount={() => {
              if (cont.offset().top + cont.outerHeight() > jQuery(window).height()) {
                cont.css({
                  top: '',
                  bottom: jQuery(window).height() - top + 20,
                })
              }
      
              if (cont.offset().left + cont.outerWidth() > jQuery(window).width()) {
                cont.css({
                  left: jQuery(window).width() - cont.outerWidth(),
                })
              }
            }}

            onUpdate={() => {
              if (cont.offset().top + cont.outerHeight() > jQuery(window).height()) {
                cont.css({
                  top: '',
                  bottom: jQuery(window).height() - top + 20,
                })
              }
      
              if (cont.offset().left + cont.outerWidth() > jQuery(window).width()) {
                cont.css({
                  left: jQuery(window).width() - cont.outerWidth(),
                })
              }
            }}
            ref={this.menuRef}
            hideEmpty
            type={''}
            menuIniters={{
              '': () => {
                const block = this.showCompleteMenu.block;

                const matchLetter = letter => {

                }
                let terminatingChar;
    
                const endingWords = (str: string, pointer = str.length - 1, count: number) => {
                  // const regExp = /\s|\./;
                  // const terminatingRegExp = /\s|\./;
                  const terminatingRegExp = /[^a-zA-Z0-9_]/;

                  
                  let wordsFound = 0;
                  let mode: 'word' | 'spaces' = 'word';
                  let currentStr = str;
                  // let pointer = str.length - 1;
                  const matches = [];
                  const end = pointer + 1;
    
                  while (pointer >= 0) {
                    if (mode == 'word') {
                      if (currentStr[pointer]?.match?.(terminatingRegExp)) {
                        terminatingChar = currentStr[pointer];
                        ++wordsFound;
                        matches.unshift(currentStr.slice(pointer + 1, end));
                        if (wordsFound == count) {
                          return matches;
                        }
                        // else {
                        //   mode = 'spaces';
                        // }
                      }
                    }
                    else if (mode == 'spaces') {
                      if (!currentStr[pointer].match(terminatingRegExp)) {
                        mode = 'word';
                      }
                    }
    
                    --pointer;
                  }
                  matches.unshift(str.slice(0, end));
                  return matches;
                }
    
                const replace = (data: Data, position, amount, newPart) => {
                  console.log(data, position, amount, newPart);
                  const firstPart = sliceData(this.ctx, data, 0, position);
                  const secondPart = sliceData(this.ctx, data, position, dataLength(this.ctx, data));
    
    
                  const suffix = '';
    
                  const newFirstPart = concatData(this.ctx,
                    concatData(this.ctx, sliceData(this.ctx, firstPart, 0, dataLength(this.ctx, firstPart) - amount), newPart),
                    suffix
                  );
                  const newStr = concatData(this.ctx, newFirstPart, secondPart);
                  block.setContent(newStr, false);

                  this.updateBlock(block.getId());

                  setTimeout(() => {
                    setCaretToBlockSelection(this.uiApi(), this.ctx, [
                      { blockId: block.getId(), position: dataLength(this.ctx, newFirstPart) },
                      { blockId: block.getId(), position: dataLength(this.ctx, newFirstPart) },
                    ]);
                    setTimeout(() => {
                      this.updasdf();
                    }, 0);
                    jQuery(`[data-block-id="${block.getId()}"]`)[0].scrollIntoViewIfNeeded(false);
                  }, 0);
    
                }
    
                const wordLookback = 1;
                const str = dataToString(this.ctx, block.getContent());

                const matches = endingWords(str, this.getPositionInBlock() - 1, wordLookback);


                const autoCompleteOptions = this.props.autoComplete(block.getId());

                if (!this.explicitAutoCompleteMenu && !matches[0] && terminatingChar != '.') {
                  this.showingMenu = false;
                  return [];
                }
                this.showingMenu = true;
    
                const options = autoCompleteOptions.filter(o => {
                  return o.name&&o.name.toLowerCase().startsWith(matches[0].toLowerCase());
                });
    
                this.showCompleteMenu.count = options.length;
    
                const r = options.map(o => {
                  return {
                    key: o.id,
                    label: o.display || o.name,
                    action: (key) => {
                      let newPart = o.part;
                      const length = matches[0].length;
                      replace(block.getContent(), this.getPositionInBlock(), length, [newPart]);
                    },
                    // label: entityDisplayName(o.id),
                    // action: (key) => {
                    //   const name = entityDisplayName(o.id);
                    //   let newPart;
    
                    //   if (key == 'Tab') {
                    //     newPart = name;
                    //   }
                    //   else {
                    //     newPart = [[name, 'entityLink', { id: o.id }]];
    
                    //   }
                    //   replace(block.getContent(), getPositionInBlock(this.ctx), o.match.length, newPart);
                    // },
                  }
                })

                if (!r.length) {
                  this.showingMenu = false;
                }

                return r;
              }
            }}
            onChooseAction={async option => {
    
            }}
          />
        </MenuWrapper>
      );


      this.showCompleteMenu.root = root;
      this.showCompleteMenu.cont = cont;

      console.log('present')

      setTimeout(() => {
        console.log('updat position');


      }, 0);
    
    }
    else {
      // console.log(this.showCompleteMenu);
    }
  }

  presentMenu({ block, left, top, endLength, position, type }: {
    block: Block
    left
    top
    endLength
    position
    type
  }) {
    this.showMenu = {
      selection: getBlockSelection(this.ctx),
      position,
      endLength,
    }

    this.menu = this.menuPresenter()({
      type,
      left,
      top,
      ctx: this.ctx,
      menuPos: this.menuPos,
      value: () => block.getContent(),
      setValue: v => block.setContent(v, false),
      menuIniters: this.props.menuIniters?.({
        ctx: this.ctx,
        value: block.getContent(),
        setValue: v => block.setContent(v, false),
      }),
      actionChosen: () => {
        delete this.menuPos;
        this.ignoreEnter = true;
        
        this.doTick();
        setTimeout(() => {
          // setCaretToBlockSelection(this.ctx, selection);
        }, 1)

        this.closeMenu();
      },
      onChooseAction: async option => {
        if (option) {
          await (option.action as any)(this.menuPos);
          delete this.menuPos;
        }

        const selection = this.showMenu.selection;
        this.ignoreEnter = true;
        
        this.doTick();
        setTimeout(() => {
          // setCaretToBlockSelection(this.ctx, selection);
        }, 1)

        this.closeMenu();
      },
      onSelectAction: option => {
        // this.props?._onSelectAction?.(type, option);
      }
    })
  }

  async closeMenu() {
    if (this.menu) {
      await this.menu.closeMenu();
      delete this.showMenu;  
      delete this.menuPos;
    }
  }

  hideOverlay() {
    this.hideOverays = true;
    jQuery(this.wrapperEl.current).addClass('hideOverlays');;
  }

  enter() {
    const currentBlock = executeEnter(this.ctx, this.blockManager, this.state.activeBlock, this.getPositionInBlock(), {
      altKey: true
    });
    this.forceUpdate();
    setTimeout(() => {
      setCaretToBlockSelection(this.uiApi(), this.ctx, [
        { blockId: currentBlock.getId(), position: 0 },
        { blockId: currentBlock.getId(), position: 0}
      ]);
      jQuery(`[data-block-id="${currentBlock.getId()}"]`)[0].scrollIntoViewIfNeeded(false);
    }, 0);
  }

  timerIds = {};
  save(from) {
    const el = jQuery(window.getSelection().focusNode).closest('[data-block-id]')

    const blockId = el.data('block-id');

    const block = findBlock(this.blockManager, blockId);

    if (!block) {
      console.log('no block found', blockId, el, window.getSelection().focusNode, from);
      return;
    }

    if (!block.hasContent()) return

    const editorEl = el.find('.editor');

    if (editorEl.html() == '<br>') {
      editorEl.html('');
    }
    console.log('save', extractFromEl(this.ctx, editorEl[0]));
    block.setContent(extractFromEl(this.ctx, editorEl[0]), true, this.props.client);
  }

  componentWillUnmount(): void {
    for (const [obj, prop, observer] of this.observing) {
      XObject.removeObserver(obj, prop, observer)
    }

    clearInterval(this.timerId2);
  }

  observing = [];
  resetObserving() {
    for (const [obj, prop, observer] of this.observing) {
      XObject.removeObserver(obj, prop, observer)
    }
    this.observing = [];
    const observe = (blocks: BlocksList) => {
      for (const block of blocks.getArray()) {
        if (block instanceof Block) {
          for (const [obj, prop] of block.getRenderTriggers()) {
            const observer = (mutation) => {
              if (!mutation.pass?.internal && !mutation.pass?.dontSync) {
                block.trigger();
                this.doTick();
              }
            }
            this.observing.push([obj, prop, observer]);
            XObject.observe(obj, prop, observer)
          }
          observe(block.getChildren());
        }
      }
    }

    observe(this.blockManager.getRootBlocks());
  }

  mobileMenuRef = React.createRef<any>();
  formatMenuRef = React.createRef<any>();


  indentOrUnindent(unindent) {
    const el = jQuery(window.getSelection().focusNode.parentElement).closest('[data-block-id]')

    const blockId = el.data('block-id');

    const hasSelection = !window.getSelection().isCollapsed;

    this.saveState('indentation');
    const blockSelection = getBlockSelection(this.ctx);
    this.hideOverlay();

    // e.preventDefault();
    if (hasSelection) {
      const el = document.createElement('div');
      el.innerHTML = getSelectionHtml();
      const blockEls = jQuery(el).find('[data-block-id]');
      const blockIds = blockEls.toArray().map(el => jQuery(el).data('block-id')).filter(id => {
        return unindent ? canUnindentBlock(this.blockManager, id) : canIndentBlock(this.blockManager, id);
      });

      let lowest;
      const indentLevels = {};
      for (const blockId of blockIds) {
        indentLevels[blockId] = getBlockIndentation(this.blockManager, blockId);
        if (lowest === undefined || indentLevels[blockId] < lowest) {
          lowest = indentLevels[blockId];
        }
      }

      if (unindent) {
        blockIds.reverse();
        for (const blockId of blockIds) {
          if (indentLevels[blockId] === lowest) {
            unindentBlock(this.uiApi(), this.blockManager, blockId);
          }
        }
      }
      else {
        for (const blockId of blockIds) {
          if (indentLevels[blockId] === lowest) {
            indentBlock(this.uiApi(), this.blockManager, blockId);
          }
        }
      }
    }
    else {
      if (unindent) {
        unindentBlock(this.uiApi(), this.blockManager, blockId);
        this.doTick();
      }
      else {
        indentBlock(this.uiApi(), this.blockManager, blockId);
      }
    }
    this.doTick();

    setTimeout(() => {
      setCaretToBlockSelection(this.uiApi(), this.ctx, blockSelection);
    }, 1);
  }


  updateArrows() {
    const contEl = ReactDOM.findDOMNode(this);
    const els = jQuery(contEl).find('[data-toggle]');
    for (const el of els) {
      const blockEl = jQuery(contEl).find(`[data-block-id="${el.getAttribute('data-toggle')}"]`);
      if (blockEl[0]) {
        jQuery(el).css({
          left: 6,
          top: blockEl.position().top + 7,
        })
  
      }
    }
  }
  
  renderToggleArrows() {
    const arrows = [];
    const renderArrows = (blocks: Block[]) => {
      for (const block of blocks) {
        if (block.hasChildren()) {
          arrows.push({
            block: block.getId(),
          })
        }

        if (!block.isCollapsed()) {
          renderArrows(block.getChildren().getArray());
        }
      }
    }

    renderArrows(this.blockManager.getRootBlocks().getArray())

    return arrows.map(arrow => {
      const b = this.props.blockManager.findBlock(arrow.block);

      return (
        <div contentEditable="false" className={cx("toggle", { collapsed: b.isCollapsed() })} data-toggle={arrow.block} key={arrow.block}
          onMouseDown={e => {
            const block = (b as any).block;
            // (block as any).block.collapsed = !(block as any).block.collapsed;
            // this.forceUpdate();

            if (e.shiftKey) {
              let allCollapsed = true;
              for (const child of block.children) {
                if (!child.collapsed) {
                  allCollapsed = false;
                  break;
                }
              }
              if (allCollapsed) {
                for (const child of block.children) {
                  child.collapsed = false;
                }  
              }
              else {
                for (const child of block.children) {
                  child.collapsed = true;
                }  

              }

            }
            else {
              block.collapsed = !block.collapsed;
            }
            this.forceUpdate();
            e.stopPropagation();
            e.preventDefault();
          }}
        />
      )
    })
    
  }


  initAutoCompleteMenu() {
    if (!this.props.autoComplete) return;
    if (!this.showCompleteMenu) {
      const el = jQuery(window.getSelection().focusNode.parentElement).closest('[data-block-id]');
      const blockId = el.data('block-id');
      const block = findBlock(this.blockManager, blockId);

      this.showCompleteMenu = {
        block,
      }

      const p = this.getCaretPosition();
      if (p) {
        let left, top, height;
      
        ({ left, top, height } = p);

        this.presentAutoCompleteMenu({ left, top: top + height });
      }
    }

  }

  restorePos

  getPositionInBlock() {
    // if (this.restorePos) {
    //   return this.restorePos[0].position;
    // }
    // else {
      return getPositionInBlock(this.ctx);

    // }
  }

  render(Container?) {
    this.resetObserving();
    return (
      <ThemeProvider theme={{ mode: 'light' }}>
        <Container
          className={cx({
            mobile: isMobile(),
          })}
          key={this._tick}
        >
          <div className="gutter">
            {this.renderToggleArrows()}
          </div>
          <div
            className={cx('wrapper', {
              hideOverlays: this.hideOverays,
              inline: true,
            })}
            suppressContentEditableWarning
            contentEditable={!this.props.readOnly}
            spellCheck={false}
            ref={e => {
              if (e) {
                this.wrapperEl.current = e;
                e.scrollTop = this.currentScroll;
              }
            }}
            onMouseMove={e => {
              if (this.props.readOnly) return;

              this.hideOverays = false;
              jQuery(e.currentTarget).removeClass('hideOverlays');
            }}
            onKeyDown={async e => {
              if (this.props.readOnly) return;
              if (isOnTitle()) return;
              const hasSelection = !window.getSelection().isCollapsed;
              if (!window.getSelection().focusNode) return;

              const el = jQuery(window.getSelection().focusNode.parentElement).closest('[data-block-id]')
              const blockId = el.data('block-id');

              this.position = this.getPositionInBlock();

              const block = findBlock(this.blockManager, blockId);
              if (!block) {
                console.log('no block found', blockId, el, window.getSelection().focusNode);
                return;
              }
              if (!block.hasContent()) return;


              if (e.key == 'z' && e.metaKey) {
                e.preventDefault();
                this.undo();
              }
              else if (this.props.menuIniterKeys?.includes?.(e.key)) {
                if (!this.showMenu) {
                  let left, top, height;
                  const position = this.getPositionInBlock();
                  this.menuPos = position;
                  if (position > 0) {
                    ({ left, top, height } = this.getCaretPosition());
                  }
                  else {
                    left = el.offset().left;
                    top = el.offset().top;
                    height = el.height();
                  }
  
                  const editorEl = el.find('.editor');
                  const data = extractFromEl(this.ctx, editorEl[0])
  
                  this.closeAutocompleteMenu();
  
                  this.presentMenu({
                    left,
                    top: top + height,
                    position,
                    endLength: dataLength(this.ctx, data) - position,
                    block,
                    type: e.key,
                  });
                  this.letNextInsert = true;
                }

              }
              else if (e.key === 'Enter') {
                e.preventDefault();
                if (this.showCompleteMenu?.count) {
                  const option = this.menuRef.current.enter();
                  if (option) {
                    await option.action(e.key);
                  }
                  this.ignoreEnter = true;
                  this.closeAutocompleteMenu();
                }
                else if (this.showMenu) {
                  const option = this.menu.enter();
                  if (option) {
                    this.saveState('action');
                    await option.action(block, this.menuPos);
                    console.log(x(block.getContent()));
                    delete this.menuPos;
                  }

                  const selection = this.showMenu.selection;
                  this.ignoreEnter = true;

                  this.updateBlock(block.getId());
                  
                  // this.doTick();
                  setTimeout(() => {
                    try {
                      selection[0].position++;
                      selection[1].position++;
                      setCaretToBlockSelection(this.uiApi(), this.ctx, selection);
                    }
                    catch (e) {
                      console.error('failed to setCaretToBlockSelection', e);
                    }
                  }, 1)

                  this.closeMenu();
                }
                else if (isOnTitle()) {
                  console.log('enter on title');
                }
                else {
                  console.time('enter');
                  let currentBlock: Block;

                  if (e.metaKey) {
                    const {blockManager}=this.props;
                    const newBlock = blockManager.newBlock();
                    const parent = findBlockParent(blockManager, blockId);
                    let blocks: BlocksList;
                    if (parent) {
                      blocks = parent.getChildren();
                    }
                    else {
                      blocks = blockManager.getRootBlocks();
                    }
              

                    if (e.shiftKey) {
                      blocks.splice(blocks.indexOf(block), 0, newBlock);

                    }
                    else {
                      blocks.splice(blocks.indexOf(block) + 1, 0, newBlock);
  
                    }
                    currentBlock = newBlock;
                  }
                  else {
                    this.saveState('enter');
                    if (hasSelection && isMultiBlockSelection()) {
                      const firstBlock = getSelectedBlockIds()[0];
                      const pos = getBlockSelection(this.ctx).find(b => b.blockId == firstBlock).position;
                      this.deleteSelection();
                      currentBlock = executeEnter(this.ctx, this.blockManager, firstBlock, pos, e);
                    }
                    else {
                      currentBlock = executeEnter(this.ctx, this.blockManager, blockId, this.getPositionInBlock(), e);
                    }
                  }

                  this.forceUpdate();
  
                  setTimeout(() => {
                    setCaretToBlockSelection(this.uiApi(), this.ctx, [
                      { blockId: currentBlock.getId(), position: 0 },
                      { blockId: currentBlock.getId(), position: 0}
                    ]);
                    const el = jQuery(ReactDOM.findDOMNode(this)).find(`[data-block-id="${currentBlock.getId()}"]`)[0];
                    if (el) {
                      el.scrollIntoViewIfNeeded(false);
                    }
                    else {
                      console.log('cant find block', currentBlock.getId())
                    }
                    
                  }, 0);

                  console.timeEnd('enter');
                }
              }
              else if (e.key == 'Tab') {
                e.preventDefault();
                console.log(this.showCompleteMenu, this.showingMenu);
                if (this.showCompleteMenu && this.showingMenu) {
                  const option = this.menuRef.current.enter();
                  if (option) {
                    await option.action(e.key);
                  }
                }
                else {
                  this.indentOrUnindent(e.shiftKey);
                }
              }
              else if (e.key === 'ArrowDown') {
                if (this.showCompleteMenu && this.showingMenu) {
                  e.preventDefault();
                  this.menuRef.current.down();

                }
                else if (this.showMenu) {
                  this.menu.down();
                  e.preventDefault();
                }
                else if (e.altKey) {
                  e.preventDefault();
                  const str = dataToString(this.ctx, block.getContent());
                  const pos = this.getPositionInBlock();
                  
                  const isNumberChar = char => {
                    return char == '-' || char >= '0' && char <= '9';
                  }
                  let beginning, end;
                  if (isNumberChar(str[pos]) || isNumberChar(str[pos - 1])) {
                    beginning = pos;
                    while (isNumberChar(str[beginning - 1])) {
                      -- beginning;
                    }

                    end = beginning;
                    while (isNumberChar(str[end + 1])) {
                      end++;
                    }

                    const curNum = str.slice(beginning, end + 1);
                    const nextNum = parseInt(curNum) - 1;

                    replace(this.ctx, block, beginning, end - beginning + 1, nextNum.toString());
                    // this.saveCaretPos(() => {
                      this.updateBlock(block.getId());
                    // })
                  }
                }
              }
              else if (e.key === 'ArrowUp') {
                if (this.showCompleteMenu && this.showingMenu) {
                  e.preventDefault();
                  this.menuRef.current.up();
                }
                else if (this.showMenu) {
                  this.menu.up();
                  e.preventDefault();
                }
                else if (e.altKey) {
                  e.preventDefault();
                  const str = dataToString(this.ctx, block.getContent());
                  const pos = this.getPositionInBlock();
                  
                  const isNumberChar = char => {
                    return char == '-' || char >= '0' && char <= '9';
                  }
                  let beginning, end;
                  if (isNumberChar(str[pos]) || isNumberChar(str[pos - 1])) {
                    beginning = pos;
                    while (isNumberChar(str[beginning - 1])) {
                      -- beginning;
                    }

                    end = beginning;
                    while (isNumberChar(str[end + 1])) {
                      end++;
                    }

                    const curNum = str.slice(beginning, end + 1);
                    const nextNum = parseInt(curNum) + 1;

                    replace(this.ctx, block, beginning, end - beginning + 1, nextNum.toString());

                    // this.saveCaretPos(() => {
                      this.updateBlock(block.getId());
                    // })
                  }
                }
              }
              else if (e.key == 'ArrowLeft') {
                if (this.getPositionInBlock() == 0) {
                  e.preventDefault();
                  const flatList = this.flatList(block);
                  const index = flatList.findIndex(b => b._id == blockId);

                  const prevBlockId = flatList[index - 1]?._id;

                  const prevBlock = findBlock(this.blockManager, prevBlockId);
                  if (prevBlock) {
                    const length = dataLength(this.ctx, prevBlock.getContent());
                    const pos = {
                      blockId: prevBlock.getId(),
                      position: length,
                    }
                    setCaretToBlockSelection(this.uiApi(), this.ctx, [pos, pos]);
                  }
                }
              }
              else if (e.key == 'ArrowRight') {
                if (this.getPositionInBlock() == dataLength(this.ctx, block.getContent())) {

                  e.preventDefault();
                  const flatList = this.flatList(block);
                  const index = flatList.findIndex(b => b._id == blockId);
                  
                  const nextBlockId = flatList[index + 1]?._id;

                  if (nextBlockId) {
                    const pos = {
                      blockId: nextBlockId,
                      position: 0,
                    }
                    setCaretToBlockSelection(this.uiApi(), this.ctx, [pos, pos]);
                  }
                }
              }
              else if (e.key == 'Backspace') {
                if (hasSelection) {
                  console.log('delete selection');
                  e.preventDefault();
                  this.saveState('deleteSelection');
                  if (this.deleteSelection()) {
                    this.save('delete');
                  }
                  this.doTick();
                }
                else if (this.position == 0) {
                  if (block.handlesBackspaceAtStart()) {
                    console.log('remove type', el, blockId, block);
                    const blockSelection = getBlockSelection(this.ctx);
                    block.handleBackspaceAtStart();
                    this.doTick();
                    e.preventDefault();
                    setTimeout(() => {
                      setCaretToBlockSelection(this.uiApi(), this.ctx, blockSelection);
                    }, 5);
                  }
                  else {
                    console.log('delete blick')
                    this.saveState('backspace');

                    let action: 'unindent' | 'merge' = null;
                    if (getBlockIndentation(this.blockManager, blockId) == 0) {
                      action = 'merge';
                    }
                    else {
                      const parent = findBlockParent(this.blockManager, blockId);
                      const positionInParent = parent?.getChildren()?.indexOf(block);
                      if (positionInParent == 0) {
                        if (parent.getChildren().getLength() > 1) {
                          action = 'merge';
                        }
                        else if (parent.getChildren().getLength() == 1) {
                          action = 'unindent';
                        }
                      }
                      else {
                        const flatList = this.flatList(block);

                        const index = flatList.findIndex(b => b._id == blockId);
                        const flatBlock = flatList[index];
                        const prevFlatBlock = flatList[index - 1];
                        if (prevFlatBlock && prevFlatBlock.indentationLevel == flatBlock.indentationLevel) {
                          action = 'merge';
                        }
                        else {
                          action = 'unindent';
                        }
                      }
                    }

                    if (action == 'unindent') {
                      const blockSelection = getBlockSelection(this.ctx);
                      e.preventDefault();
                      unindentBlock(this.uiApi(), this.blockManager, blockId);
                      this.doTick();
                      setTimeout(() => {
                        setCaretToBlockSelection(this.uiApi(), this.ctx, blockSelection);
                      }, 5);
                    }
                    else if (action == 'merge') {
                      e.preventDefault();

                      const flatList = this.flatList(block);
                      const index = flatList.findIndex(b => b._id == blockId);
                      const prevBlockId = flatList[index - 1]?._id;
                      if (prevBlockId) {
                        const prevBlock: any = findBlock(this.blockManager, prevBlockId);
                        if (prevBlock instanceof Block) {
                          const length = dataLength(this.ctx, prevBlock.getContent());
                          prevBlock.setContent(X(concatData(this.ctx, prevBlock.getContent(), block.getContent())), false);

                          if (findBlockParent(this.blockManager, blockId)?.getId?.() != prevBlock.getId()) {
                            prevBlock.setChildren(block.getChildren());
                          }
                          else if (block.hasChildren()) {
                            prevBlock.setChildren(block.getChildren().concat(prevBlock.getChildren()));
                          }
                          const pos = {
                            blockId: prevBlock.getId(),
                            position: length,
                          }

                          removeBlock(this.blockManager, blockId);

                          this.updateBlock(prevBlockId);
                          this.doTick();
                          


                          const tryThing = ()=> {
                            // setTimeout(() => {
                              try {
                                setCaretToBlockSelection(this.uiApi(), this.ctx, [pos, pos]);
                              }
                              catch (e) {
                                console.error('failed tosetCaretToBlockSelection', e, pos);
                                // tryThing();
                              }
                            // }, 1);
        
                          }
                          setTimeout(() => {
                            tryThing();
                          }, 1)
                        }
                        else if (prevBlock instanceof BlockColumns) {
                          console.log(prevBlock);
                          removeBlock(this.blockManager, blockId);
                          this.doTick();

                          // TODO: merge contents of block with prevBlock
                        }
                        else {
                          throw new Error('unknown block type');
                        }
                      }
                      else {
                        console.log('no prev block');
                        const col = this.blockManager.getBlockColumn(blockId);
                        if (col) {
                          const blockColumns = col[0];
                          if (blockColumns.getColumns().length == 2 && col[1] == 1) {
                            console.log('unwrap columns');
                            const rootBlocks = this.blockManager.getRootBlocks();
                            const index = rootBlocks.indexOf(blockColumns as any);
                            console.log(index);

                            const list = blockColumns.getColumns()[0].getChildren();

                            rootBlocks.splice(index, 1, ...list.getArray());
                            this.doTick();

                            // TODO: merge contents of block with first block of list
                          }
                          else if (blockColumns.getColumns().length > 2) {
                            console.log('merge columns');
                          }
                        }
                      }
                    }
                  }
                }
                else {
                  const pos = this.getPositionInBlock();

                  if (!_.isNil(this.menuPos) && (_.isEqual(pos - 1, this.menuPos) || e.metaKey && pos > this.menuPos)) {

                    this.closeMenu();
                    this.save('input');

                    if ((pos == 1 && dataLength(this.ctx, block.getContent()) == 1) || (pos == dataLength(this.ctx, block.getContent()) && e.metaKey)) {
                      e.preventDefault();
                      const editorEl = el.find('.editor');
                      editorEl.html('');
                      this.save('input');
                    }

                  }
                  else if ((pos == 1 && dataLength(this.ctx, block.getContent()) == 1) || (pos == dataLength(this.ctx, block.getContent()) && e.metaKey)) {
                    e.preventDefault();
                    const editorEl = el.find('.editor');
                    editorEl.html('');
                    this.save('input');
                  }
                  else {


                    this.saveState('delete');
                  }

                }

              }
              else if (e.key == ' ' && e.ctrlKey) {
                this.explicitAutoCompleteMenu = true;
                this.closeMenu();

                if (!this.showCompleteMenu) {
                  e.preventDefault();
                  this.initAutoCompleteMenu();
                }
              }
              else {
                if (e.key.length == 1 && !e.metaKey) {
                  if (hasSelection && isMultiBlockSelection()) {
                    e.preventDefault();
                    this.saveState('deleteSelection');
                    this.deleteSelection(e.key);
                    this.doTick();
                  }
                  else {

                    const closedCont = prev();
                    if (closedCont) {
                      e.preventDefault();
                      const selection = window.getSelection();
                      const range = document.createRange();
                      if (closedCont.nextSibling) {
                        if (closedCont.nextSibling.nodeType == Node.TEXT_NODE) {
                          closedCont.nextSibling.textContent = e.key + closedCont.nextSibling.textContent;
                          range.setStart(closedCont.nextSibling, 1);
                          range.setEnd(closedCont.nextSibling, 1);
                        }
                      }
                      else {
                        const textNode = document.createTextNode(e.key);
                        closedCont.parentNode.insertBefore(textNode, closedCont.nextSibling);

                        range.setStart(textNode, 1);
                        range.setEnd(textNode, 1);
                      }
                      selection.removeAllRanges();
                      selection.addRange(range);

                      this.save('input');
                    }
                    const terminatingRegExp = /[a-zA-Z0-9_]/;

                    if (e.key.match(terminatingRegExp) && !this.showMenu) {
                      this.initAutoCompleteMenu();
                    }

                    if (this.showCompleteMenu) {
                      console.log('asdfadsfasd!!');
                      this.updasdf();
                    }
    

                    // if (!this.showMenu) {
                    //   this.save('input');
                    // }
                    return;
                  }
                }
              }
            }}
            onKeyUp={e => {
              if (this.props.readOnly) return;

              const el = jQuery(window.getSelection().focusNode).closest('[data-block-id]')
              const blockId = el.data('block-id');
              const block = findBlock(this.blockManager, blockId);
              if (e.key == 'Escape') {
                if (this.showMenu) {
                  this.save('input');
                  this.closeMenu();
                }
                else if (this.showCompleteMenu) {
                  this.closeAutocompleteMenu();
                }
              }

              else if (!e.metaKey) {
                if (this.showCompleteMenu) {
                  this.updasdf();
                }
                else if (!this.showMenu) {
                  if (e.key == 'Enter' && this.ignoreEnter) {
                    delete this.ignoreEnter;
                    e.preventDefault();
                  }
                }
                else if (this.letNextInsert) {
                  delete this.letNextInsert;
                }
                else {
                  const editorEl = el.find('.editor');
                  if (editorEl[0]) {
                    const data = extractFromEl(this.ctx, editorEl[0]);
                    this.menu.setFilter(dataToString(this.ctx, sliceData(this.ctx, data, this.showMenu.position + 1, dataLength(this.ctx, data) - this.showMenu.endLength)));  
                  }
                }
              }
            }}
            onScroll={e => {
              this.currentScroll = (e.target as any).scrollTop;
            }}
          >
            {this.props.setTitle && <div data-type="title" className="title">{this.title}</div>}
            <div
              key={this._tick}
              ref={e => {
                if (e){ 
                  if (!this.initial) {
                    this.updateMetaPos();          
                    this.initial = true;
                  }
                }
              }}
            >
              
              {this.blockManager.getRootBlocks().map((b: Block | BlockColumns, i) => {
                if (b instanceof Block) {
                  return this.renderBlock(b);
                }
                /*else if (b instanceof BlockColumns) {
                  const colCount = b.getColumns().length;
                  return (
                    <Columns
                      key={b.getId()}
                      data-block-columns-id={b.getId()}
                    >
                      {b.getColumns().map((col, i) => {
                        const ref = React.createRef<any>();
                        return (
                          <Column
                            key={col.getId()}
                            style={{
                              width: (col.getWidth() * 100) + '%',
                            }}
                            ref={ref}
                            data-column-id={col.getId()}
                          >
                            {col.getChildren().map(b => {
                              return this.renderBlock(b);
                            })}

                            {i < b.getColumns().length - 1 && <ColumnResizer
                            
                            onResizeEnd={pos => {
                              // console.log(ref.current.getBoundingClientRect());
                              const nextCol = b.getColumns()[i + 1];

                              const thisColEl = jQuery(`[data-column-id="${col.getId()}"]`);
                              const nextColEl = jQuery(`[data-column-id="${nextCol.getId()}"]`);

                              
                              const elWidth = nextColEl[0].getBoundingClientRect().right - ref.current.getBoundingClientRect().left;
                              const p = pos.x - ref.current.getBoundingClientRect().left;

                              const pp = p/elWidth;

                              const totalWidth = col.getWidth() + nextCol.getWidth();
                              
                              
                              const firstWidth = pp * totalWidth;
                              const nextWidth = (1 - pp) * totalWidth;

                              col.setWidth(firstWidth);
                              nextCol.setWidth(nextWidth);

                              this.doTick();



                            }}
                            />}
                          </Column>
                        )
                      })}
                    </Columns>
                  )
                }*/
              })}
            </div>
            {isMobile() && (
              <div className="mobileMenu" style={{ display: 'none' }} ref={this.mobileMenuRef} contentEditable={false}>
                <MobileMenu notionDocument={this} />
              </div>
            )}

            {/* {!isMobile() && (
              <div
                  className="formatMenu" style={{ display: 'none' }} ref={this.formatMenuRef} contentEditable={false}
                onMouseDown={e => {
                  e.preventDefault();
                }}   
              >
                Hello
              </div>
            )} */}
            {/* {this.showMenu && (
              <>
                <div
                  className="menu"
                  style={{
                    position: 'absolute',
                    top: this.showMenu.top,
                    left: this.showMenu.left,
                  }}
                >

                </div>
              </>
            )} */}
            {(this.dragging ) && this.areas.map((area, i) => {
              let offset = {left: 0, top: 0 };

              // if (this.props.insidePositionContext) {
              //   offset = jQuery(ReactDOM.findDOMNode(this)).offset();
              // }
              return (
                <DragArea
                  contentEditable={false}
                  key={i}
                  data-index={i}
                  className={cx('dragArea', area.side)}
                  style={{
                    position: 'absolute',
                    top: area.top - offset.top,
                    left: area.left - offset.left,
                    width: area.width,
                    height: area.height,
                    zIndex: 999999,
                  }}
                  // onMouseDown={e => {
                  //   e.preventDefault();
                  //   e.stopPropagation();
                  //   console.log('area', area);
                  //   area.action(this.dragging);
                  // }}
                />
              )
            })}
          </div>
        </Container>
      </ThemeProvider>
    );
  }
}
