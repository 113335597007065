import { db } from './db';
import { indexEdges } from './etc/getEdgesForEntity';

export const theTransientEdges = [];

export function getAllEdges() {
  return [...db.edges, ...theTransientEdges];
}

export function pushEdge(edge) {
  db.edges.push(edge);
  indexEdges();
}
