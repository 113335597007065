export class CellType<TMetaData=any> {
  id: string;
  defaultValue = undefined;
  constructor(public metaData: TMetaData) {
  }
  showOpenButton() {
    return false;
  }
  renderValue(value, args: any = {}): any { }
  renderEditor({ frame, value, setValue, close, state }): any { }

  clickable() {
    return false;
  }

  saveOnClose() {
    return false;
  }

  mobileEditorFullScreen() {
    return false;
  }

  useEditor() {
    return true;
  }
}
