
export enum AttributeType {
  text = '0f7375f1-732b-5591-84e1-1bf2c103c5f9',
  boolean = '456e91cf-d372-5909-bb5b-b0e590e984d3',
  textArray = 'c1790fcc-663a-5a46-9cd1-81a7ad85e90a',
  entity = '95839512-682e-586f-a744-1f6dad7f8354',
  entities = '7c1b2fa7-82a7-529d-9cc0-1c44ba783fd7',
  datetime = '3657ea3f-d79b-5065-8a17-c486a410ead4',
  valuePoint = '4628b101-6851-597f-89c3-e9bbe4ae10b9',
  priority = '66bcc483-0a9e-5d4a-b219-1ab8f1b41c18',
  dueDate = '55c09e2c-9a88-576e-8d8b-5ac26ed2f8a3',
  switch = '91c007d3-2b2f-5131-83d2-7970a977b580',
  duration = 'duration',
  select = '3100c464-56fc-555a-9e4e-488e10cdfeae',
  multiSelect = 'a49385c6-e337-5036-845c-19b78379e852',
  richText = 'fcf990f6-5bfe-505d-8361-010d60ae83e7',
  event = '0bb706de-cf66-54cd-bb46-90c20013d742',
  media = '96fbe378-edb2-5bc9-b65d-f41074e18a27',
  number = 'a2b8192b-910d-52e5-8895-c68ca0450b9d',
  reaction = 'ac3850db-94c3-56b2-90fe-704d5d788576',
  attribute = '38a71f7f-4926-5c70-8ca5-5b84bf5ff235',
  url = 'url',
}
