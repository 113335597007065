import React from 'react';
import styled from 'styled-components';
import { db } from '../db';
import { evaluate, execute, getValuePoint, mapStructure } from '../glue/main';
import { component } from '../component2';
import { X, x, XClone, XObject } from '../XObject';
import { NotionDocumentBad } from '../components/NotionDocumentBad';
import { Runtime } from '../glue/Runtime';
import { Comp } from '../glue/Comp';
import { resolveBlockTypes } from '../glue/structs/$Document';
import { SystemContext, SystemContextProps } from '../etc/SystemContext';
import { PaneType } from '../types/PaneType';
import { NotionTable } from '../components/NotionTable';
import { NotionDocument } from '../components/notionDocument/NotionDocument';
import { MyBlockManager } from '../MyNotionDocument/MyBlockManager';
import { MyNotionDocument } from '../MyNotionDocument/MyNotionDocument';
import { types } from "../MyNotionDocument/types";
import { registerInspectObjectHandler, triggerInspectObject } from '../osHelpers';
import classNames from 'classnames';
import { isMobile } from '../isMobile';
import { DropdownItem, NavStackCont, Section } from '../components/QueryViewEditor';
import { NavTitle, registerRoute, Route } from '../components/registerRoute';
import { DocumentBlockEditor } from '../components/DocumentBlockEditor';
import { ObjectType } from '../types/ObjectRef';
import { doEntityQuery } from '../glue/structs/$EntityQuery';
import { findRefs } from '../scriptComponents';
import { process } from '../MyNotionDocument/RenderData';

registerRoute((route, navigation) => {
  if (route[0] == Route.documentBlockEl) {
    const document = db.notionDocuments.findById(route[1].document);
    const blockManager = new MyBlockManager(() => document.blocks);
    const block = blockManager.findBlock(route[1].block).block;

    return <DocumentBlockEditor
    key={block._id}
      block={block}
      parent={{
        type: ObjectType.document,
        id: document._id,
      }}
    />
    return (
      <>
        <Section>
          <NavTitle><h3>Block</h3></NavTitle>
          <DropdownItem label="Type" options={[]} setValue={null} value={null} />

          {block.id}
        </Section>
      </>
    )
  }
});

registerInspectObjectHandler('b3cf4506-0148-588b-a0ab-a40d5e5207e0', {
  padding: 0,
  render: (props: { document, block, entity }) => {
    return (
      <NavStackCont
        state={props}
        root={[
          Route.documentBlockEl,
          {
            document: props.document,
            block: props.block,
          }
        ]}
      />
    );
  }
});


@component
export class NotionDocumentWindow extends Comp<{ window; insidePositionContext? }> {
  blocks;
  state = X({});
  constructor(props) {
    super(props);
    const document = db.notionDocuments.findById(this.props.window.notionDocument);
    if (!document)
      return;

    const blocks = XObject.get(document, 'blocks', []);
    if (!blocks.length) {
      blocks.push(XObject.obj({
        data: [],
        children: [],
      }));
    }

    this.blocks = XClone(blocks);

    XObject.observe(document, 'blocks', change => {
      console.log('test');
    });

    const observeExternalChanges = () => {
      
      XObject.observe(document.blocks, (change) => {
        // console.log('test');
        this.blocks = XClone(document.blocks);
        this.forceUpdate();
      });
    }

    observeExternalChanges();

    XObject.observe(this.blocks, (change) => {
      document.blocks = XClone(this.blocks);
      observeExternalChanges();
    });
  }

  static contextType = SystemContext;
  context: SystemContextProps;

  title;

  tick = 0;

  static styles = styled.div`

    /* transform: translate(0, 0); */
    /* transform: scale(.5); */
    display: flex;
    flex-direction: column;
    height: 100%;
    ${NotionTable} {
      margin: 0 30px;
    }

    &.mobile {
      ${NotionTable} {
        margin: 0;
      }
    }
  `;

  renderDocBad() {
    const ref = React.createRef<NotionDocumentBad>();
    const document = db.notionDocuments.findById(this.props.window.notionDocument);
    const value = document.config && execute(document.config, new Runtime(this.context, { stateStore: this.state }));

    return (
      <NotionDocumentBad
        onlyShowTitle={document.type != 'de89bc9c-9fb1-579d-8954-53f1519cbb33'}
        inline
        docId={this.props.window.notionDocument}
        ref={ref}
        tick={this.tick}
        blockTypes={value ? resolveBlockTypes(value, {}) : []}
        blocks={this.blocks}
        setBlocks={(blocks) => {
          console.log(x(blocks));
          document.blocks = XClone(blocks);
          this.blocks = XClone(blocks);
          XObject.observe(this.blocks, (change) => {
            document.blocks = XClone(this.blocks);
          });
          this.tick++;
          this.forceUpdate();
          ref?.current?.forceUpdate?.();
        }}
        database={null}
        title={x(document).name}
        setTitle={(title) => {
          document.name = title;
        }}
        entity={null}
      />

    )
  }

  timerId
  setBlocks = (blocks) => {
    const document = db.notionDocuments.findById(this.props.window.notionDocument);

    this.blocks = XClone(blocks);
    XObject.observe(this.blocks, (change) => {
      document.blocks = XClone(this.blocks);
      this.tick++;
      this.forceUpdate();  
    });

    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      document.blocks = XClone(this.blocks);
    }, 500);
  }

  renderDoc() {
    const doc = db.notionDocuments.findById(this.props.window.notionDocument);

    let highlighter;

    const navigate = (config, paneIndex?, pointer?) => {
      // console.log(config, paneIndex, pointer);
      // if (doc.navHandler) {
      //   const vp = getValuePoint(doc.navHandler);
      //   const executedVp = execute(doc.navHandler);
      //   if (evaluate(executedVp, { [vp.parameters[0]._id]: config,
      //   __env: {
      //     __ctx: this.context,
      //   }
      //   })) {
      //     return;
      //   }
      // }
      
      this.context.navigate(config, paneIndex, pointer);

    }

    let entities;

    if (doc.highlighter) {
      const vp = execute(doc.highlighter);
      const mapped = mapStructure(vp);
      entities = doEntityQuery(mapped.query);
      
      highlighter = (el) => {
        const doHighlight = (text: Text) => {
          let node = text;
          const refs = findRefs(node.textContent, entities);
          refs.sort((a, b) => b.reference.length - a.reference.length);

          while (true) {
            let found = false;
            for (const ref of refs) {
              const word = ref.reference;
              const index = node.textContent.replaceAll(' ', ' ').indexOf(word);

              if (index != -1) {
                found = true;
                const n = node.splitText(index);
                node = n.splitText(word.length);
      
                const highlighted = document.createElement('span');
                highlighted.setAttribute('data-presentation', 'true');

                highlighted.style.color = 'rgb(126 126 216)';
                highlighted.style.cursor = 'pointer';
                highlighted.textContent = n.textContent;
                highlighted.onclick = () => {
                  navigate({
                    type: 'entity',
                    id: ref.id,
                  })
                }
                n.replaceWith(highlighted);
                break;
              }
            }
            if (!found) break;
          }

        }
        process(el, doHighlight);
      }
    }

    return (
      <SystemContext.Provider value={{
        ...this.context,
         navigate: navigate,
      }}>
        <MyNotionDocument
          tick={() => doc.tick}
          insidePositionContext={this.props.insidePositionContext}
          baseEntity={null}
          blocks={this.blocks}
          setBlocks={(blocks) => {
            this.setBlocks(blocks);
          }}
          autocompleteList={entities && {
            entities,
          }}
          onBlockSelected={id => {
            triggerInspectObject({
              type: 'b3cf4506-0148-588b-a0ab-a40d5e5207e0',
              args: {
                document: doc._id,
                block: id,
              }
            });
          }}
          docId={this.props.window.notionDocument}
          obj={{
            type: ObjectType.document,
            id: this.props.window.notionDocument,
          }}
          extendEntity={null}
          title={x(doc).name}
          setTitle={(title) => {
            doc.name = title;
          }}
          renderBlockArgs={{
            highlighter,
          }}

        />
      </SystemContext.Provider>
    );
  }


  render(Container?) {
    const document = db.notionDocuments.findById(this.props.window.notionDocument);

    return (
      <Container
        className={classNames({
          mobile: isMobile(),
        })}
      >
        {!document.type && (
          <>
            <div>
              <button
                onClick={() => {
                  document.type = 'de89bc9c-9fb1-579d-8954-53f1519cbb33';
                }}
              >Init Page</button>
              <button
                onClick={() => {
                  document.type = '49746968-8568-5fbc-990d-ea4811d7f018';
                  document.tableData = X({});
                }}
              >Init Table</button>
            </div>
          </>
        )}
        {document.type == 'de89bc9c-9fb1-579d-8954-53f1519cbb33' && (
          this.renderDoc()
        )}
        {document.type == '49746968-8568-5fbc-990d-ea4811d7f018' && (
          <>
            <NotionDocumentBad
              onlyShowTitle
              inline
              docId={this.props.window.notionDocument}
              tick={this.tick}
              blocks={this.blocks}
              setBlocks={(blocks) => {
              }}
              database={null}
              title={x(document).name}
              setTitle={(title) => {
                document.name = title;
              }}
              entity={null}
              blockTypes={[]}
            />
            <NotionTable
              state={this.props.window}
              table={document.tableData}
              onClickRow={id => {
                this.context?.navigate?.({
                  type: PaneType.tableRow,
                  path: [document._id, id ],
                });
              }}
              active={(() => {
                const next = this.context?.next?.();
                if (next?.type == PaneType.tableRow) {
                  return next.path[next.path.length - 1];
                }
              })()}

              onClickAI={() => {
                this.context?.navigate?.({
                  type: PaneType.chatGPT2,
                  table: document._id,
                });
              }}
              editView={id => {
                triggerInspectObject({
                  type: '95d1b469-6eff-5bcc-88f9-a53e4377f1bf',
                  args: {
                    doc: document._id,
                    view: id,
                  },
                });
              }}
            />
          </>
        )}
      </Container>
    );
  }
}
