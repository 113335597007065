import { component, styled } from './component';
import React, { Component } from 'react';
import { ObjectType } from './types/ObjectRef';
import { ObjectDisplay } from './components/ObjectDisplay';
import { XInit, XObject } from './XObject';
import { createEntity } from './etc/createEntity';
import { appState } from './etc/appState';
import { createQuery } from './etc/queryFuncs';
import { objectsInScope } from './components/allObjects';
import { objectName } from './components/objectFuncs';
import { createRootValuePoint } from './glue/main';
import { ScopeView } from './ScopeWindow';


@component
export class ObjectSelector extends Component<{ parent; onSelect; }> {
  static styles = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: white;
    /* border: 1px solid black; */

    border-radius: 4px;
    background: white;
    box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;


    > .left {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 300px;
      overflow: auto;
      border-right: 1px solid rgb(204, 204, 204);
      padding: 4px;
      box-sizing: border-box;
    }

    > .right {
      position: absolute;
      left: 300px;
      right: 0;
      top: 0;
      bottom: 0;
      overflow: auto;
      /* padding: 4px; */
      box-sizing: border-box;
    }
  `;
  state = XInit(class {
    filter = '';
    selected = 0;

  });

  componentDidMount(): void {
    this.options = this.entitySelectOptions('');
    this.forceUpdate();
  }

  up() {
    this.state.selected = (this.state.selected - 1 + this.options.length) % this.options.length;
    appState.focusedObjectInSelectMenu = this.options[this.state.selected].data;
  }

  down() {
    this.state.selected = (this.state.selected + 1) % this.options.length;
    appState.focusedObjectInSelectMenu = this.options[this.state.selected].data;
  }

  enter() {
    this.props.onSelect(this.options[this.state.selected]);
  }

  options = [];
  render() {
    return (
      <>
        <div className="left">
          {this.options.map((i, index) => {
            return (
              <div key={i.key}
                style={{
                  fontWeight: index == this.state.selected ? 'bold' : undefined,
                }}
              >
                {i.label}
              </div>
            );
          })}
        </div>
        <div className="right">
          <ScopeView obj={this.props.parent} />
        </div>
      </>
    );
  }

  setFilter(asdf) {
    this.options = this.entitySelectOptions(asdf);
    this.state.filter = asdf;
    this.forceUpdate();
  }

  entitySelectOptions(filter, action?) {
    const r = []
      // .concat(
      //   getAllEntities().filter(e => {
      //     if (!_.isString(e.name)) return false;
      //     return e.name.toLowerCase().includes(filter.toLowerCase());
      //   }).map(e => ({
      //     key: e._id,
      //     label: <ObjectDisplay obj={{
      //       type: ObjectType.entity,
      //       id: e._id,
      //     }} />,
      //     action: (menuPos) => {
      //       action(menuPos, {
      //         type: ObjectType.entity,
      //         id: e._id,
      //       });
      //     }
      //   }))
      // )
      .concat(objectsInScope(this.props.parent, [
        ObjectType.event,
        ObjectType.document,
        ObjectType.query,
        ObjectType.identifier,
        ObjectType.attribute,
        ObjectType.type,
        ObjectType.valuePoint,
        ObjectType.objectHandle,
        ObjectType.database,
      ]).filter(o => {
        const name = objectName(o);
        if (!name) return;
        return name.toLowerCase().includes(filter.toLowerCase());
      })).map(o => {
        return ({
          data: o,
          key: o.id,
          label: <ObjectDisplay obj={o} showPath="next-line" />,
          action: (menuPos) => {
            action(menuPos, o);
          }
        }) as any;
      })
      /*.concat(
        db.queries.filter(e => {
          if (!e.name) return false;
          if (!_.isString(e.name)) return false;
          return e.name.toLowerCase().includes(filter.toLowerCase());
        }).map(e => ({
          data: {
            type: ObjectType.query,
            id: e._id,
          },
          key: e._id,
          label: <ObjectDisplay obj={{
            type: ObjectType.query,
            id: e._id,
          }} showPath="next-line" />,
          action: (menuPos) => {
            action(menuPos, {
              type: ObjectType.query,
              id: e._id,
            })
          }
        }))
      )
      .concat(
        db.identifiers.filter(e => {
          // if (e.relative) return false;
          if (!_.isString(e.name)) return false;
          return e.name.toLowerCase().includes(filter.toLowerCase());
        }).map(e => ({
          key: e._id,
          data: {
            type: ObjectType.identifier,
            id: e._id,
          },
 
          label: (
            <ObjectDisplay
              obj={{
                type: ObjectType.identifier,
                id: e._id,
              }}
            />
          ),
          action: (menuPos) => {
            action(menuPos, {
              type: ObjectType.identifier,
              id: e._id,
            })
          }
        }))
      )*/
      .concat([
        {
          key: 'CREATE_QUERY',
          label: '@Create query',
          action: (menuPos) => {
            const q = createQuery(null, null, this.props.parent, true);
            action(menuPos, {
              type: ObjectType.query,
              id: q._id,
            });
          }
        },
        {
          key: 'CREATE_GLUE',
          label: '@Create value point',
          action: (menuPos) => {
            // const q = createQuery(null, null, this.props.parent, true);
            // action(menuPos, {
            //   type: ObjectType.query,
            //   id: q._id,
            // })
            const r = createRootValuePoint();
            action(menuPos, {
              type: ObjectType.valuePoint,
              id: r._id,
            });

          }
        },
      ]);
    return r.concat({
      label: `Create entity "${filter}"`,
      action: (menuPos) => {
        const e = XObject.obj({
          name: filter,
        });
        createEntity(e, null);
        action(menuPos, e);

      }
    } as any);
  }
}
