import { AttributeCellType, CheckboxCellType, DurationCellType, EntitiesCellType, EntityCellType, EventCellType, MediaCellType, MultiSelectCellType, NumberCellType, ReactionCellType, SelectCellType, TextCellType, TimeCellType, URLCellType } from "../../components/cells";
import { XObject } from "../../XObject";
import { AttributeType } from "../../components/AttributeType";
import { Cell } from "../../components/Cell";

function cellForAttribute(t, entityId?, entity?) {
  let cellType;
  if (t?.type == AttributeType.switch) {
    // const value = executeSwitch(t.valuePoint, row.entity);
    // if (value?.type?.[1] == $GroupedSelectAttribute.$) {
    //   cellType = new $GroupedSelectAttribute_CellType({
    //     valuePoint: value._id,
    //   });
    // }
  }
  else if (t?.type == AttributeType.text || t?.type == AttributeType.number) {
    cellType = new TextCellType({});
  }
  else if (t?.type == AttributeType.url) {
    cellType = new URLCellType({});
  }
  else if (t?.type == AttributeType.boolean) {
    cellType = new CheckboxCellType({});
  }
  else if (t?.type == AttributeType.duration) {
    cellType = new DurationCellType({});
  }
  else if (t?.type == AttributeType.datetime) {
    cellType = new TimeCellType({});
  }
  else if (t?.type == AttributeType.entity) {
    cellType = new EntityCellType({
      query: t.query,
      baseEntity: entityId,
      options: {
        type: t.optionsType,
        valuePoint: t.valuePoint,
        entity,
      }
    });
  }
  else if (t?.type == AttributeType.entities) {
    cellType = new EntitiesCellType({
      baseEntity: entityId,
      query: t.query,
      resolved: t.resolved,
    });
  }
  else if (t?.type == AttributeType.select) {
    cellType = new SelectCellType({
      addOption: (name) => {
        const option = XObject.obj({
          title: name,
        });
        XObject.push(t, 'options', option);
        return option._id;
      },
      options: XObject.get(t, 'options', []),
    });
  }
  else if (t?.type == AttributeType.select) {
    cellType = new SelectCellType({
      addOption: (name) => {
        const option = XObject.obj({
          title: name,
        });
        XObject.push(t, 'options', option);
        return option._id;
      },
      options: XObject.get(t, 'options', []),
    });
  }
  else if (t?.type == AttributeType.media) {
    cellType = new MediaCellType({});
  }
  else if (t?.type == AttributeType.number) {
    cellType = new NumberCellType({
      ...(t as any),
      baseEntity: entityId,
    });
  }
  else if (t?.type == AttributeType.reaction) {
    cellType = new ReactionCellType({
      icon: t.icon,
    });
  }
  else if (t?.type == AttributeType.multiSelect) {
    cellType = new MultiSelectCellType({
      addOption: (name) => {
        const option = XObject.obj({
          title: name,
        });
        XObject.push(t, 'options', option);
        return option._id;
      },
      options: XObject.get(t, 'options', []),
    });
  }
  else if (t?.type == AttributeType.event) {
    cellType = new EventCellType({});
  }
  else if (t?.type == AttributeType.attribute) {
    cellType = new AttributeCellType({});
  }

  return cellType;
}


export function renderCellForAttribute({attribute, entityId=undefined, entity = undefined, get, set, title }) {
  const cell = cellForAttribute(attribute, entityId, entity);
  if (!cell) return 'invalid type';
  return <Cell
    cell={cell}
    get={get}
    set={set}
    title={title}
  />
}
