import { getObject } from './components/objectFuncs';
import _ from 'lodash';
import { ObjectRef, ObjectRefClass, ObjectType } from './types/ObjectRef';
import { createEntityFromQuery, executeEntityQuery, queryChain } from './etc/queryFuncs';
import { db } from './db';
import { getDataSlot } from './components/getDataSlot';
import { ControlType } from './MyNotionDocument/ControlType';
import { entityDisplayName } from './components/entityDisplayName';
import { getGraphParent, queryGraphBasic } from './etc/queryGraph';
import { GlueView, getValuePoint } from './glue/main';
import { X, XObject, x } from './XObject';
import { registerFormulaAccessorHandler } from './shorthand/registerFormulaAccessorHandler';
import { objectResource } from './components/objectResource';
import { getOccurrenceDescendents } from './components/getOccurrenceDescendents';
import { AttributeType } from './components/AttributeType';
import juration from './juration';
import { isId } from './helpers';
import { collectBlocks, collectEntitiesGood } from './components/collectEntitiesGood';
import { getEntityById } from './etc/createEntity';
import { getEdgesForEntity } from './etc/getEdgesForEntity';
import { findBlock } from './components/NotionDocumentBad';
import { FormulaObjectProxy } from './FormulaObjectProxy';

export function makeDb(collections, prop, prefix='') {
  const collectionName = prop;

  if (collectionName in x(collections)) {
    return collections[collectionName];
  }


  return new FormulaObjectProxy({
    get: (prop) => {
      if (prop == 'push') {
        return val => {
          const key = prefix + collectionName;
          // XObject.get(collections, collectionName, [val]);

          if (!(key in emptyDbCache)) {
            emptyDbCache[key] = X({});
          }
  
          collections[collectionName] = X([val]);
          emptyDbCache[key].test = true;
        }
      }
      else if (prop == 'length') {
        const key = prefix + collectionName;
        if (!(key in emptyDbCache)) {
          emptyDbCache[key] = X({});
        }
        console.log(emptyDbCache[key].test);

        return 0;
      }
      else if (prop == 'map') {
        return () => {
        const key = prefix + collectionName;

        if (!(key in emptyDbCache)) {
          emptyDbCache[key] = X({});
        }


          console.log(emptyDbCache[key].test);
        }
      }
      else {
        return Reflect.get([], prop);
      }
    }
  });
}

export class SelectOptionWrapper {
  constructor(public option) {}
}
registerFormulaAccessorHandler({
  test: o => o instanceof SelectOptionWrapper,
  perform: (o: SelectOptionWrapper, prop) => {
    if (prop == 'Name') {
      return o.option.title;
    }
    if (prop == 'Id') {
      return o.option._id;
    }
  }
});

registerFormulaAccessorHandler({
  test: o => o instanceof ObjectRefClass,
  perform: (o:ObjectRefClass, prop) => {
    return new FormulaObjectWrapper(o).get(prop);
  }
});

registerFormulaAccessorHandler({
  test: o => o instanceof Date,
  perform: (o:Date, prop) => {
    if (prop == 'TimeSince') {
      return () => (new Date().getTime() - o.getTime())/1000;
    }
  }
});


function getResource(obj, id) {
  const resource = objectResource(obj, id, { create: false });
  const handle = db.objectHandles.findById(id);
  if (resource) {
    return {
      GetRowCol: (lookupCol, lookupVal, valueCol) => {
        lookupCol = handle.columns.find(c => c.title == lookupCol)?._id;
        valueCol = handle.columns.find(c => c.title == valueCol)?._id;
        const row = resource.rows.find(r => r.values?.[lookupCol] == lookupVal);
        if (row) {
          return row.values?.[valueCol];
        }
      },
      SetRowCol: () => {

      },
      GetRow: (lookupCol, lookupVal) => {
        lookupCol = handle.columns.find(c => c.title == lookupCol)?._id;
        const row = resource.rows.find(r => r.values?.[lookupCol] == lookupVal);
        if (row) {
          return new FormulaObjectProxy({
            get: (prop) => {
              const col = handle.columns.find(c => c.title == prop);
              return row.values?.[col._id];
            }
          })
        }
      },
      Rows: () => {
        return resource.rows?.map?.(row => {
          return new FormulaObjectProxy({
            get: (prop) => {
              if (prop == 'id') return row._id;
              else if (prop == 'getFormattedAttr') {
                return prop => {
                  const col = handle.columns.find(c => c.title == prop);
                  if (col.type == AttributeType.select) {
                    return col.options.find(o => o._id == row.values?.[prop])?.title;
                  }
                }
              }
              const col = handle.columns.find(c => c.title == prop);
              return row.values?.[col._id];
            }
          });
        }) || [];

      },
      MapRows: func => {
        return resource.rows?.map?.(row => {
          return func(new FormulaObjectProxy({
            get: (prop) => {
              if (prop == 'id') return row._id;
              else if (prop == 'getFormattedAttr') {
                return prop => {
                  const col = handle.columns.find(c => c.title == prop);
                  if (col.type == AttributeType.select) {
                    return col.options.find(o => o._id == row.values?.[prop])?.title;
                  }
                }
              }
              const col = handle.columns.find(c => c.title == prop);
              return row.values?.[col._id];
            }
          }));
        }) || [];
      },
      Length: resource.rows?.length || 0,
    }
  }
}

const emptyDbCache = {};

export class FormulaObjectWrapper {
  constructor(public ref: ObjectRef, public base?) {
  }

  call(env, ...args) {
    if (this.ref.type == ObjectType.valuePoint) {
      const valuePoint = getValuePoint(this.ref.id);
      const a = {};
      for (let i = 0; i < args.length; ++ i) {
        a['%' + i] = args[i];
        if (valuePoint.parameters?.[i]) a[valuePoint.parameters[i]._id] = args[i];
      }
      console.log('valuePoint', valuePoint.name);
      return <GlueView key={this.ref.id} id={this.ref.id} state={{}} args={a} rt={env.__rt} />;
    }
  }

  toPrimitive() {
    if (this.ref.type == ObjectType.identifier) {

      const identifier = db.identifiers.findById(this.ref.id);
      let key;
      if (this.base) {
        key = `${this.base}.${this.ref.id}`;
      }
      else {
        key = this.ref.id;
      }
      const slot = getDataSlot(key);

      if (identifier.controlType == ControlType.checkbox) {
        return slot.get();
      }


    }
    else if (this.ref.type == ObjectType.entity) {
      return this.ref.id;
    }
  }

  toString() {
  }

  get(prop) {
    if (this.ref.type == ObjectType.identifier) {
      const identifier = db.identifiers.findById(this.ref.id);
      let key;
      if (this.base) {
        key = `${this.base}.${this.ref.id}`;
      }
      else {
        key = this.ref.id;
      }
      const slot = getDataSlot(key);

      if (identifier.controlType == ControlType.reaction) {
        if (prop == 'Count') {
          return slot.get()?.length || 0;
        }
      }
      if (identifier.controlType == ControlType.checkbox) {
        if (prop == 'ToString') {
          return () => slot.get() ? 'TRUE' : 'FALSE';
        }
      }
    }
    else if (this.ref.type == ObjectType.query) {
      const query = getObject(this.ref);
      if (prop == 'Length') {
        const r = executeEntityQuery(queryChain(query), null, this.base);
        return r.length;
      }
      else if (prop == 'Add') {
        return (arg={}) => {
          const entity = {
            attributes: arg
          };
          createEntityFromQuery(queryChain(query), entity);
          console.log('[formula] createEntity', entity);

        };
      }
      else if (prop == 'Map') {
        return attr => {
          const r = executeEntityQuery(queryChain(query), null, this.base);
          return r.map(id => {
            const entity = getEntityById(id);
            return entity.attributes?.[attr];
          })
        }
      }
    }
    else if (this.ref.type == ObjectType.day) {
      if (prop == 'GetResource') {
        return id => {
          if (id instanceof FormulaObjectWrapper) {
            id = id.ref.id;
          }
          return getResource({
            type: ObjectType.day,
            id: this.ref.id,
          }, id);
        }
      }
      else if (prop == 'Date') {
        const day = db.days.findById(this.ref.id);
        return day.date;
      }
      else if (prop == 'End') {
        const day = db.days.findById(this.ref.id);
        return day.end;
      }
      else if (prop == 'Start') {
        const day = db.days.findById(this.ref.id);
        return day.start;
      }
      else if (prop == 'SetEnd') {
        return end => {
          const day = db.days.findById(this.ref.id);
          day.end = end;
  
        }        
      }
    }
    else if (this.ref.type == ObjectType.entity) {
      const entity = getEntityById(this.ref.id);
      if (_.isString(prop)) {
        prop = prop.toLowerCase();
      }
      if (prop == 'name') {
        return entityDisplayName(this.ref.id);
      }
      else if (prop == 'getresource') {
        return id => {
          return getResource({
            type: ObjectType.entity,
            id: this.ref.id,
          }, id);
        }
      }
      else if (prop == 'id') {
        return this.ref.id;
      }
      else if (prop == 'parent') {
        const parent = getGraphParent(null, this.ref.id);
        if (parent) {
          return new FormulaObjectWrapper({
            type: ObjectType.entity,
            id: parent,
          }, this.base);
        }
      }
      else if (prop instanceof FormulaObjectWrapper) {
        if (prop.ref.type == ObjectType.attribute) {
          return entity.attributes?.[prop.ref.id];
        }
      }
      else if (prop == 'type') {
        return entity.type;
      }
      else if (prop == 'children') {
        return getEdgesForEntity(entity._id).filter(e => {
          return e.directed && e.entities[0] == entity._id;
        }).map(e => {
          return e.entities[1];
        }).map(id => new FormulaObjectWrapper({ type: ObjectType.entity, id: id }))
      }
      else if (prop == 'descendants') {
        const descendants = queryGraphBasic(entity._id, false, false, false).map(e => e.entity);
        return descendants.map(id => new FormulaObjectWrapper({ type: ObjectType.entity, id: id }));
      }
      else if (prop == 'getformattedattr') {
        return prop => {
          if (!_.isString(prop)) prop = prop.ref.id;
          const attr = db.attributeTypes.findById(prop);
          if (attr.type == AttributeType.duration) {
            return juration.stringify(entity.attributes?.[prop]);
          }
          else if (attr.type == AttributeType.select) {
            return attr.options.find(o => o._id == entity.attributes?.[prop])?.title;
          }
          return entity.attributes?.[prop];
        }
      }
    }
    else if (this.ref.type == ObjectType.event) {
      const event = db.events.findById(this.ref.id);
      if (prop == 'name') return event.name;
      else if (prop == 'id') return event._id;
    }
    else if (this.ref.type == ObjectType.attribute) {
      if (prop == 'Name') {
        const attr = db.attributeTypes.findById(this.ref.id);
        return attr.name;
      }
      if (prop == 'Options') {
        const attr = db.attributeTypes.findById(this.ref.id);
        return x(attr.options).map(o => ({ title: o.title, id: o._id, color: o.color }))
        return [{title: 'Hello', id: 'hello'}, {
          title: 'World', id: 'world'
        }];
      }
    }
    else if (this.ref.type == ObjectType.objectHandle) {
      const handle = db.objectHandles.findById(this.ref.id);
      if (handle.type == ObjectType.table) {
        if (prop == 'Col') {
          return title => {
            const col = handle.columns.find(c => c.title == title);
            return {
              Options: col.options.map?.(o => new SelectOptionWrapper(o)) || [],
            }
          }
        }
      }
    }
    else if (this.ref.type == ObjectType.eventOccurrence) {
      const row = db.eventOccurrences.findById(this.ref.id);
      if (prop == 'GetResource') {
        return id => {
          return getResource({
            type: ObjectType.eventOccurrence,
            id: this.ref.id,
          }, id);
        }
      }
      else if (prop == 'Descendants') {
        return getOccurrenceDescendents(row).map(x => new ObjectRefClass(ObjectType.eventOccurrence, x._id));
      }
      else if (prop == 'EventName') {
        return db.events.findById(row.event).name;
      }
      else if (prop == 'Timestamp') {
        return row.timestamp;
      }
      else if (prop == 'TimeStr') {
        return row.timestamp.format('{HH}:{mm}');
      }
      else if (prop == 'Argument') {
        return new ObjectRefClass(ObjectType.entity, row.arguments);
      }
      else if (prop == 'GetFormattedAttr') {
        return prop => {
          if (!_.isString(prop)) prop = prop.ref.id;
          const attr = db.attributeTypes.findById(prop);
          if (attr.type == AttributeType.duration) {
            return juration.stringify(row.attributes?.[prop]);
          }
          return row.attributes?.[prop];
        }
      }
      else if (prop instanceof FormulaObjectWrapper) {

        if (prop.ref.type == ObjectType.attribute) {
          return row.attributes?.[prop.ref.id];
        }
      }
      else if (isId(prop)) {
        return row.attributes?.[prop];
      }
    }
    else if (this.ref.type == ObjectType.document) {
      if (_.isString(prop)) prop = prop.toLowerCase();

      if (prop == 'referencedentities') {
        return () => {
          const doc = db.notionDocuments.findById(this.ref.id);
          const entities = collectEntitiesGood(doc.content || doc.blocks || []);
          return entities.map(x => new ObjectRefClass(ObjectType.entity, x.entity));
        }
      }
      else if (prop == 'blocks') {
        return (blockId, filter) => {
          const table = db.notionDocuments.findById(this.ref.id);
          return collectBlocks(table.blocks, blockId, filter);
        }
      }
      else if (prop == 'block') {
        return blockId => {
          const table = db.notionDocuments.findById(this.ref.id);
          return findBlock(table.blocks, blockId);
        }
      }

    }
    else if (this.ref.type == ObjectType.database) {
      const database = getObject({ type: ObjectType.database, id: this.ref.id });
      const collections = XObject.get(database, 'collections', {});
      return makeDb(collections, prop, this.ref.id);
      const collectionName = prop;

      if (collectionName in x(collections)) {
        return collections[collectionName];
      }


      return new FormulaObjectProxy({
        get: (prop) => {
          if (prop == 'push') {
            return val => {
              const key = this.ref.id + collectionName;
              // XObject.get(collections, collectionName, [val]);
              collections[collectionName] = X([val]);
              emptyDbCache[key].test = true;
            }
          }
          else if (prop == 'length') {
            const key = this.ref.id + collectionName;
            if (!(key in emptyDbCache)) {
              emptyDbCache[key] = X({});
            }
            console.log(emptyDbCache[key].test);

            return 0;
          }
          else {
            return Reflect.get([], prop);
          }
        }
      });

    }
  }
}



