import React, { Component, useContext } from "react";
import ReactDOM from 'react-dom';
import jQuery from 'jquery';
import _ from 'lodash';
import cx from 'classnames';
import { XInit, XObject, x } from "../XObject";
import { db } from "../db";
import { SystemContext } from "../etc/SystemContext";
import { createEntity, getAllEntities, getEntityById } from "../etc/createEntity";
import { $GroupedSelectAttribute } from "../glue/structs/$GroupedSelectAttribute";
import { CompiledValuePoint, GlueView, ValuePointType, evaluate, execute, getValuePoint, mapStructure } from "../glue/main";
import { PriorityIcon } from "../etc/PriorityIcon";
import { getScopeTree, objectName, typesInScopes } from "./objectFuncs";
import { ObjectType } from "../types/ObjectRef";
import { createEntityFromQuery, executeEntityQuery } from "../etc/queryFuncs";
import { Tag } from "./Tag";
import { SelectEditor } from "./SelectEditor";
import { TextEditor } from "./TextEditor";
import { RenderData } from "../MyNotionDocument/RenderData";
import { types } from "../MyNotionDocument/types";
import { FileUpload, uploadedFileUrl } from "./FileUpload";
import { component, styled } from "../component2";
import { CellType } from "./notionDatabase/CellType";
import { getPathInGraph } from "../etc/queryGraph";
import { entityComputedName, entityDisplayName } from "./entityDisplayName";
import { resumeMode } from "../resumeMode";
import { linkColor } from "./linkColor";
import { entityStyles } from "./entityStyles";
import { styleStyles } from "./doneStyles";
import { appState } from "../etc/appState";
import juration from "../juration";
import { lightColors, mainColors } from "./mainColors";
import { NumberDisplayType } from "../windows/NumberDisplayType";
import { Reaction } from "./Reaction";
import { execFormulaFromData } from '../shorthandEditor/execFormulaFromData';
import Sugar from 'sugar';
import { FormulaObjectWrapper } from "../FormulaObjectWrapper";
import { ObjectPicker } from "./ObjectPicker";
import { ObjectDisplay } from "./ObjectDisplay";
import { getEmbeddings } from "../getEmbeddings";
import * as ml from 'ml-distance';
import { isId } from "../helpers";

export class TextCellType extends CellType {
  id = 'text';
  renderValue(value) {
    if (_.isDate(value)) {
      return 'date object';
    }
    return value;
  }

  renderEditor({ frame, value, setValue, close }) {
    return (
      <TextEditor
        onEnter={value => {
          setValue(value);
          close();
        }}
        autoFocus
        frame={{
          width: frame.width,
          height: frame.height,
        }}
        setValue={value => {
          console.log('whaaat')
          setValue(value);
        }}
        value={value()}
        // close={() => {
        //   close();
        // }}
      />
    );
  }
}

export class NumberCellType extends CellType {
  id = 'text';
  renderValue(value) {
    if (_.isDate(value)) {
      return 'date object';
    }
    return value;
  }

  renderEditor({ frame, value, setValue, close }) {
    return (
      <TextEditor
        onEnter={value => {
          setValue(value);
          close();
        }}
        autoFocus
        frame={{
          width: frame.width,
          height: frame.height,
        }}
        setValue={value => {
          console.log('whaaat')
          setValue(value);
        }}
        value={(value() || '').toString()}
        // close={() => {
        //   close();
        // }}
      />
    );
  }
}


export class TextArrayCellType extends CellType {
  id = 'textArray';

  renderValue(value) {
    return value?.join?.(', ');
  }

  saveOnClose(): boolean {
    return true;
  }

  mapToEditor(value) {
    return value?.join?.(', ');
  }

  mapToValue(value) {
    return value?.split?.(', ') || [];
  }

  renderEditor({ frame, value, setValue, close }) {
    return (
      <TextEditor
        onEnter={value => {
          setValue(value);
          close();
        }}
        autoFocus
        frame={{
          width: frame.width,
          height: frame.height,
        }}
        setValue={value => {
          setValue(value);
        }}
        value={value()}
      />
    );
  }

}

export class DurationCellType extends CellType {
  id = 'text';
  renderValue(value) {
    return juration.stringify(value);
  }

  saveOnClose(): boolean {
    return true;
  }

  mapToEditor(value) {
    return juration.stringify(value);
  }

  mapToValue(value) {
    return juration.parse(value);
  }

  renderEditor({ frame, value, setValue, close }) {
    return (
      <TextEditor
        onEnter={value => {
          setValue(value);
          close();
        }}
        autoFocus
        frame={{
          width: frame.width,
          height: frame.height,
        }}
        setValue={value => {
          setValue(value);
        }}
        value={value()}
      />
    );
  }
}

export class TimeCellType extends CellType {
  id = 'text';
  renderValue(value) {
    return value?.format?.('{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}')
  }

  saveOnClose(): boolean {
    return true;
  }

  mapToEditor(value) {
    return value?.format?.('{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}')
  }

  mapToValue(value) {
    return value && Sugar.Date.create(value);
  }

  renderEditor({ frame, value, setValue, close }) {
    return (
      <TextEditor
        onEnter={value => {
          setValue(value);
          close();
        }}
        autoFocus
        frame={{
          width: frame.width,
          height: frame.height,
        }}
        setValue={value => {
          setValue(value);
        }}
        value={value()}
      />
    );
  }
}


export class URLCellType extends CellType {
  id = 'url';
  renderValue(value) {
    return value && <a style={{ color: linkColor  }} href={value} target="_blank" onClick={e => e.stopPropagation()} onMouseDown={e => e.stopPropagation()}>Open</a>;
  }

  clickable(): boolean {
    return true;
  }

  renderEditor({ frame, value, setValue, close }) {
    return (
      <TextEditor
        rich={false}
        onEnter={value => {
          setValue(value);
          close();
        }}
        autoFocus
        frame={{
          width: frame.width,
          height: frame.height,
        }}
        setValue={value => {
          setValue(value);
        }}
        value={value()}
      />
    );
  }
}

const Wrapper = styled.span`
  ${styleStyles}

`;

export class TitleCellType extends CellType {
  id = 'title';
  showOpenButton(): boolean {
    return true;
  }
  renderValue(value, { row, args }) {
    if (_.isDate(value)) {
      return 'date object';
    }

    return (
      <>
        <RenderData
          placeholder
          className={cx(row && entityStyles(row.id()).map(s => '_' + s))}
          tag={Wrapper}
            ctx={{
            types: types,
          }}
          data={entityComputedName(row.id()) ? entityDisplayName(row.id()) : value}
        />
        {(() => {
          const path = row && getPathInGraph(null, row.id(), args?.baseId);
          return path?.length > 0 && (
            <span className="path">
              {path.map(id => {
                return (
                  <span className="comp" key={id} onClick={() => {
                  }}>{entityDisplayName(id)}</span>
                )
              })}
            </span>
          );
        })()}
      </>
    )
  }

  renderEditor({ frame, value, setValue, close }) {
    return (
      <TextEditor
        onEnter={value => {
          setValue(value);
          close();
        }}
        autoFocus
        frame={{
          width: frame.width,
          height: frame.height,
        }}
        setValue={value => {
          setValue(value);
        }}
        value={value()}
      />
    );
  }
}

export class SelectCellType extends CellType {
  id = 'select';

  mobileEditorFullScreen(): boolean {
    return true;
  }


  renderValue(value) {
    const column = this.metaData;
    if (column.showAll) {
      return column.options.map(option => {
        return <span key={option._id} style={{
          opacity: value == option._id ? 1 : 0.5,
        }}>
          <Tag text={option.title} />
        </span>;
      });
    }
    return value && [value]?.map?.((option) => {
      const o = column.options.find(o => o._id == option);
      return (
        <span key={option}>
          <Tag text={o?.title} color={lightColors.find(c => c.colorType == o?.color)?.color} />
        </span>
      )
    });
  }

  renderEditor({ frame, value, setValue, close }) {
    const col = this.metaData;
    const ref = React.createRef<any>();
    return (
      <SelectEditor
        ref={ref}
        options={col.options}
        createOption={col.addOption}
        editOption={col.editOption}
        close={close}
        frame={{
          width: frame.width,
          height: frame.height,
        }}
        value={value}
        setValue={value => {
          setValue(value);
          ref.current.forceUpdate();
        }}
      />
    );
  }
}

export class MultiSelectCellType extends CellType {
  id = 'multiSelect';


  defaultValue = [];

  wrapper = styled.div`
    margin: -2px 0 0 -2px;
    display: flex;
    flex-wrap: wrap;
    > span {
      margin: 2px;
    }
  `;

  mobileEditorFullScreen(): boolean {
    return true;
  }


  renderValue(value) {
    const Wrapper = this.wrapper;
    const column = this.metaData;
    return <Wrapper>{value?.map?.((option) => (
      <span key={option}>
        <Tag text={column.options.find(o => o._id == option)?.title} />
      </span>
    ))}</Wrapper>;
  }

  renderEditor({ frame, value, setValue, close }) {
    const col = this.metaData;
    return (
      <SelectEditor
        multi
        options={col.options}
        createOption={col.addOption}
        close={close}
        frame={{
          width: frame.width,
          height: frame.height,
        }}
        value={value}
        setValue={value => {
          setValue(value);
        }}
      />
    );
  }
}

export class EntitySelectEditor extends Component<{ query, baseEntity, frame, value, setValue, close }> {
  render() {
    const { query, baseEntity, frame, value, setValue, close } = this.props;
    return (
      <SelectEditor
        createOption={value => {
          const entity = XObject.obj({
            name: value,
          });
          if (query) {
            createEntityFromQuery(query, entity, baseEntity);
          }
          createEntity(entity, baseEntity); // createEntityNull
          return entity._id;
        }}
        optionDisplay={id => getEntityById(id)?.name}
        options={q => {
          let entities;
          if (query) {
            entities = executeEntityQuery(query, null, baseEntity).map(id => getEntityById(id));
          }
          else {
            entities = getAllEntities().filter(e => {
              if (!_.isString(e.name)) return false;
              return e.name.toLowerCase().includes(q.toLowerCase());
            })
          }
      
          return entities.map(e => ({
            _id: e._id,
            title: e.name,
          }))
        }}
        close={close}
        frame={{
          width: frame.width,
          height: frame.height,
        }}
        value={value}
        setValue={value => {
          setValue(value);
        }}
      />
    );

  }
}

export class PriorityCellType extends CellType {
  id = 'priority';


  defaultValue = [];

  mobileEditorFullScreen(): boolean {
    return true;
  }

  renderValue(value) {
    return !_.isNil(value) && <PriorityIcon priority={value} />;
    const context = useContext(SystemContext);
    // const column = this.metaData;
    return value && [value]?.map?.((option) => (
      <span key={option}
        style={{
          marginRight: '4px',
        }}
        onMouseDown={e => {
          console.log('asdfD');
          e.stopPropagation();
          context?.navigate?.({
            type: 'entity',
            id: option,
          });
        }}
      >
        <Tag text={getEntityById(option)?.name} />
      </span>
    ));
  }

  renderEditor({ frame, value, setValue, close }) {
    // const col = this.metaData;
    return <SelectEditor
      options={[
        {
          _id: '0',
          title: 'No Priority',
        },
        {
          _id: '1',
          title: 'Urgent',
        },
        {
          _id: '2',
          title: 'High',
        },
        {
          _id: '3',
          title: 'Medium',
        },
        {
          _id: '4',
          title: 'Low',
        },
      ]}
      close={close}
      frame={{
        width: frame.width,
        height: frame.height,
      }}
      value={value}
      setValue={value => {
        setValue(value === null ? null : parseInt(value));
      }} />;
  }

}

export class EntitiesCellType extends CellType {
  id = 'entities';


  defaultValue = [];

  clickable(): boolean {
    return true;
  }

  mobileEditorFullScreen(): boolean {
    return true;
  }

  useEditor(): boolean {
    return !this.metaData.resolved;
  }


  renderValue(value) {
    const context = useContext(SystemContext);
    

    if (this.metaData.resolved) {
      const entities = executeEntityQuery(this.metaData.query, undefined, this.metaData.baseEntity);
      value = entities;
    }

    return value?.map?.((option) => (
      <span key={option}
        style={{
          marginRight: '4px',
        }}
        onMouseDown={e => {
          e.stopPropagation();
          context?.navigate?.({
            type: 'entity',
            id: option,
          });
        }}
      >
        <Tag text={getEntityById(option)?.name} />
      </span>
    ));
  }

  renderEditor({ frame, value, setValue, close }) {
    return (
      <SelectEditor
        createOption={value => {
          const entity = XObject.obj({
            name: value,
          });
          if (this.metaData.query) {
            createEntityFromQuery(this.metaData.query, entity, this.metaData.baseEntity);
          }
          createEntity(entity, this.metaData.baseEntity); // createEntityNull
          return entity._id;
        }}
        optionDisplay={id => getEntityById(id)?.name}
        multi
        options={query => {
          let entities;
          if (this.metaData.query) {
            entities = executeEntityQuery(this.metaData.query, null, this.metaData.baseEntity).map(id => getEntityById(id));
            entities = entities.filter(e => {
              if (!_.isString(e.name))
                return false;
              return e.name.toLowerCase().includes(query.toLowerCase());
            })
          }
          else {
            entities = getAllEntities().filter(e => {
              if (!_.isString(e.name))
                return false;
              return e.name.toLowerCase().includes(query.toLowerCase());
            })
          }
      
          return entities.map(e => ({
            _id: e._id,
            title: e.name,
          }))
        }}
        close={close}
        frame={{
          width: frame.width,
          height: frame.height,
        }}
        value={value}
        setValue={value => {
          setValue(value);
        }}
      />
    );
  }

}

function executeEntitySwitch(id: string, entity) {
  const vp = getValuePoint(id);
  const executedVp = execute(id);
  const mappedVp = mapStructure(executedVp);

  const passedEntityId = evaluate(mappedVp.entity, {
    [vp.parameters[0]._id]: entity
  });

  console.log(passedEntityId)

  const passedEntity = getEntityById(passedEntityId);

  for (const kase of mappedVp.entries.content) {
    const mappedCase = mapStructure(kase);
    if (mappedCase.match.content == passedEntity.type) {
      return evaluate(mappedCase.content, {
        __env: {
          $: new FormulaObjectWrapper({
            type: ObjectType.entity,
            id: passedEntityId
          })
        }
      });
      break;
    }
  }

}

export function entityOptions(metaData: {
  options: {
    type
    valuePoint
    entity
  }
  query
  baseEntity
}) {
  let entities = [];
  if (metaData.options?.type == 'dd2d06f2-7551-502e-b2ee-e01544ca4817') {
    entities = executeEntitySwitch(metaData.options.valuePoint, metaData.options.entity).map(id => getEntityById(id));
  }
  else {
    if (metaData.query) {
      entities = executeEntityQuery(metaData.query, null, metaData.baseEntity).map(id => getEntityById(id));
    }
    else {
      entities = getAllEntities().filter(e => {
        if (!_.isString(e.name)) return false;
      })
    }
  }

  return entities;

}

export class EntityCellType extends CellType<{
  query?
  sort?
  baseEntity?
  resolved?
  options?
}> {
  id = 'entity';

  defaultValue = [];

  clickable(): boolean {
    return true;
  }

  mobileEditorFullScreen(): boolean {
    return true;
  }

  renderValue(value) {
    const context = useContext(SystemContext);
    return value && [value]?.map?.((option) => (
      <span key={option}
        style={{
          marginRight: '4px',
        }}
        onMouseDown={e => {
          e.stopPropagation();
          context?.navigate?.({
            type: 'entity',
            id: option,
          });
        }}
      >
        <Tag text={getEntityById(option)?.name} />
      </span>
    ));
  }

  renderEditor({ frame, value, setValue, close }) {
    return (
      <SelectEditor
        createOption={value => {
          if (isId(value)) {
            return value;
          }
          else {
            const entity = XObject.obj({
              name: value,
            });
            if (this.metaData.query) {
              createEntityFromQuery(this.metaData.query, entity, this.metaData.baseEntity);
            }
            createEntity(entity, this.metaData.baseEntity); // createEntityNull
            return entity._id;  
          }
        }}
        optionDisplay={id => getEntityById(id)?.name}

        asyncOptions={this.metaData.sort == 'e5315ab1-c719-5cbb-9880-5f4d98a1d6a8' && (async (query) => {
          let entities;
          if (this.metaData.query) {
            entities = executeEntityQuery(this.metaData.query, null, this.metaData.baseEntity).map(id => getEntityById(id));
            entities = entities.filter(e => entityDisplayName(e._id)?.toLowerCase?.().includes?.(query.toLowerCase()))
            const text = [entityDisplayName(this.metaData.baseEntity), ...entities.map(e => entityDisplayName(e._id))];
            const embeddings = await getEmbeddings(text);

            const options = [];

            const baseEntityEmbedding = embeddings[0];
            for (let i = 0; i < entities.length; ++ i) {
              const entityEmbedding = embeddings[i + 1];

              const sim = ml.similarity.cosine(baseEntityEmbedding, entityEmbedding);

              options.push([entities[i], sim]);
            }

            options.sort((a, b) => b[1] - a[1])

            return options.map(o => ({
              _id: o[0]._id,
              title: entityDisplayName(o[0]._id),
            }))

          }

        })}

        options={this.metaData.sort != 'e5315ab1-c719-5cbb-9880-5f4d98a1d6a8' && (query => {
          let entities = [];
          if (this.metaData.options?.type == 'dd2d06f2-7551-502e-b2ee-e01544ca4817') {
            entities = executeEntitySwitch(this.metaData.options.valuePoint, this.metaData.options.entity).map(id => getEntityById(id));
          }
          else {
            if (this.metaData.query) {
              entities = executeEntityQuery(this.metaData.query, null, this.metaData.baseEntity).map(id => getEntityById(id));
              if (this.metaData.sort == 'e5315ab1-c719-5cbb-9880-5f4d98a1d6a8') {
                console.log(entityDisplayName(this.metaData.baseEntity));
              }
              entities = entities.filter(e => {
                if (!_.isString(e.name)) return false;
                return e.name.toLowerCase().includes(query.toLowerCase());
              })
            }
            else {
              entities = getAllEntities().filter(e => {
                if (!_.isString(e.name)) return false;
                return e.name.toLowerCase().includes(query.toLowerCase());
              })
            }
          }

          return entities.map(e => ({
            _id: e._id,
            title: e.name,
          }))

        })}
        close={close}
        frame={{
          width: frame.width,
          height: frame.height,
        }}
        value={value}
        setValue={value => {
          setValue(value);
        }}
      />
    );
  }

}

export class AnyEventCellType extends CellType {
  id = 'entity';

  defaultValue = [];

  clickable(): boolean {
    return true;
  }

  mobileEditorFullScreen(): boolean {
    return true;
  }

  renderValue(value) {
    const context = useContext(SystemContext);
    return value && [value]?.map?.((option) => (
      <span key={option}
        style={{
          marginRight: '4px',
        }}
        onMouseDown={e => {
          // e.stopPropagation();
          // context?.navigate?.({
          //   type: 'entity',
          //   id: option,
          // });
        }}
      >
        <Tag text={db.events.findById(option)?.name} />
      </span>
    ));
  }

  renderEditor({ frame, value, setValue, close }) {
    return <SelectEditor
      createOption={value => {
        const entity = XObject.obj({
          name: value,
        });
        if (this.metaData.query) {
          createEntityFromQuery(this.metaData.query, entity);
        }
        createEntity(entity, this.metaData.baseEntity);
        return entity._id;
      }}
      optionDisplay={id => db.events.findById(id)?.name}
      options={db.events.filter(e => e.parent?.id == appState.currentMode).map(e => ({
        _id: e._id,
        title: e.name,
      }))}

      close={close}
      frame={{
        width: frame.width,
        height: frame.height,
      }}
      value={value}
      setValue={value => {
        setValue(value);
      }} />;
  }

}

export class EventCellType extends CellType {
  id = 'entity';

  defaultValue = [];

  clickable(): boolean {
    return true;
  }

  mobileEditorFullScreen(): boolean {
    return true;
  }

  renderValue(value) {
    const context = useContext(SystemContext);
    return value && [value]?.map?.((option) => (
      <span key={option}
        style={{
          marginRight: '4px',
        }}
        onMouseDown={e => {
          // e.stopPropagation();
          // context?.navigate?.({
          //   type: 'entity',
          //   id: option,
          // });
        }}
      >
        <Tag text={db.events.findById(option)?.name} />
      </span>
    ));
  }

  renderEditor({ frame, value, setValue, close }) {
    return <SelectEditor
      createOption={value => {

      }}
      optionDisplay={id => db.events.findById(id)?.name}
      options={db.events.filter(e => e.parent?.id == appState.currentMode).map(e => ({
        _id: e._id,
        title: e.name,
      }))}

      close={close}
      frame={{
        width: frame.width,
        height: frame.height,
      }}
      value={value}
      setValue={value => {
        setValue(value);
      }} />;
  }

}

export class EventsCellType extends CellType {
  id = 'entity';

  defaultValue = [];

  clickable(): boolean {
    return true;
  }

  mobileEditorFullScreen(): boolean {
    return true;
  }

  renderValue(value) {
    const context = useContext(SystemContext);
    return value?.map?.((option) => (
      <span key={option}
        style={{
          marginRight: '4px',
        }}
        onMouseDown={e => {
          // e.stopPropagation();
          // context?.navigate?.({
          //   type: 'entity',
          //   id: option,
          // });
        }}
      >
        <Tag text={db.events.findById(option)?.name} />
      </span>
    ));
  }

  renderEditor({ frame, value, setValue, close }) {
    return <SelectEditor
      createOption={value => {

      }}
      multi
      optionDisplay={id => db.events.findById(id)?.name}
      options={db.events.filter(e => e.parent?.id == appState.currentMode).map(e => ({
        _id: e._id,
        title: e.name,
      }))}

      close={close}
      frame={{
        width: frame.width,
        height: frame.height,
      }}
      value={value}
      setValue={value => {
        setValue(value);
      }} />;
  }

}


export class $GroupedSelectAttribute_CellType extends CellType {
  id = $GroupedSelectAttribute.$;

  icon = 'text';


  defaultValue = [];

  mobileEditorFullScreen(): boolean {
    return true;
  }

  _options() {
    const valuePoint = execute(this.metaData.valuePoint);
    const evaluated = evaluate(valuePoint);

    const options = [];
    for (const group of evaluated.groups) {
      for (const entry of group.entries) {
        options.push({
          _id: entry._id,
          color: group.color,
          title: `[${group.name}] ${entry.name}`,
        });
      }
    }

    return options;
  }

  renderValue(value) {
    return value && [value]?.map?.((option) => (
      <span key={option}
        data-value-point={this.metaData.valuePoint}
        style={{
          marginRight: '4px',
        }}
        onMouseDown={e => {
        }}
      >
        <Tag text={this._options().find(o => o._id == value)?.title} color={this._options().find(o => o._id == value)?.color} />
      </span>
    ));
  }

  renderEditor({ frame, value, setValue, close }) {

    return <SelectEditor
      // createOption={value => {
      //   const entity = XObject.obj({
      //     name: value,
      //   })
      //   createEntity(entity);
      //   return entity._id;
      // }}
      // optionDisplay={id => getEntityById(id)?.name}
      options={this._options()}
      close={close}
      frame={{
        width: frame.width,
        height: frame.height,
      }}
      value={value}
      setValue={value => {
        setValue(value);
      }} />;
  }

}

export class $GroupedSelectAttribute_Multi_CellType extends CellType {
  id = $GroupedSelectAttribute.$ + '_multi';

  icon = 'text';


  defaultValue = [];

  mobileEditorFullScreen(): boolean {
    return true;
  }

  _options() {
    const valuePoint = execute(this.metaData.valuePoint);
    const evaluated = evaluate(valuePoint);

    const options = [];
    for (const group of evaluated.groups) {
      for (const entry of group.entries) {
        options.push({
          _id: entry._id,
          color: group.color,
          title: `[${group.name}] ${entry.name}`,
        });
      }
    }

    return options;
  }

  renderValue(value) {
    return value?.map?.((option) => (
      <span key={option}
        data-value-point={this.metaData.valuePoint}
        style={{
          marginRight: '4px',
        }}
        onMouseDown={e => {
        }}
      >
        <Tag text={this._options().find(o => o._id == option)?.title} color={this._options().find(o => o._id == option)?.color} />
      </span>
    ));
  }

  renderEditor({ frame, value, setValue, close }) {

    return <SelectEditor
      // createOption={value => {
      //   const entity = XObject.obj({
      //     name: value,
      //   })
      //   createEntity(entity);
      //   return entity._id;
      // }}
      // optionDisplay={id => getEntityById(id)?.name}
      options={this._options()}
      close={close}
      frame={{
        width: frame.width,
        height: frame.height,
      }}
      value={value}
      setValue={value => {
        setValue(value);
      }}
      multi />;
  }

}

export class GlueCellType extends CellType {
  defaultValue = [];


  renderValue() {
    return <GlueView
      args={this.metaData.map}
      id={this.metaData.valuePoint}
      state={{}}
    />
  }

  renderEditor({ frame, value, setValue, close }) {

    return null;
  }

}

export class EntityTypesCellType extends CellType {
  id = 'entityTypes';
  defaultValue = [];

  mobileEditorFullScreen(): boolean {
    return true;
  }

  renderValue(value) {
    const context = useContext(SystemContext);
    return value?.map?.((option) => (
      <span key={option}
        style={{
          marginRight: '4px',
        }}
        onMouseDown={e => {
          console.log('asdfD');
          e.stopPropagation();
          context?.navigate?.({
            type: 'entity',
            id: option,
          });
        }}
      >
        <Tag text={db.entityTypes.findById(option)?.name} />
      </span>
    ));
  }

  renderEditor({ frame, value, setValue, close, state }) {
    let scopes = [];
    for (const s of this.metaData.scopes) {
      const tree = getScopeTree(s);
      console.log(s, tree);
      for (const entry of tree) {
        if (!scopes.find(ss => _.isEqual(x(entry), x(ss)))) {
          scopes.push(entry);
        }
      }
    }

    scopes = scopes.filter(s => s.type != ObjectType.type);

    console.log('scopes', scopes, this.metaData.scopes);

    const types = typesInScopes(this.metaData.scopes);
    console.log(types);



    return (
      <SelectEditor
        optionDisplay={id => {
          const type = db.entityTypes.findById(id);
          // if (type?.scope) {
          // return `${type.name} (${objectName(type.scope)})`;
          // } else {
          return type?.name;
          // }
        }}
        multi
        options={query => types.map(id => db.entityTypes.findById(id)).filter(e => {
          if (!_.isString(e.name))
            return false;
          return e.name.toLowerCase().includes(query.toLowerCase());
        }).slice(0, 10).map(e => {

          return ({
            _id: e._id,
            title: e.scope ? `${e.name} (${objectName(e.scope)})` : e.name,
          });
        })}
        close={close}
        frame={{
          width: frame.width,
          height: frame.height,
        }}
        value={value}
        setValue={value => {
          setValue(value);
        }}

        createOption={value => {
          const newType = XObject.obj({
            name: value,
            scope: scopes.find(s => s.id == state?.scope)
          });
          db.entityTypes.push(newType);
          return newType._id;
        }}

        renderCreate={filter => {
          return <>Create&nbsp;<Tag text={filter} />

            <select
              value={state?.scope || ''}
              onChange={e => {
                state.scope = e.target.value || null;
              }}
            >
              <option />

              {scopes.map(t => {
                return <option key={t.id} value={t.id}>{objectName(t)}</option>;
              })}
            </select>
          </>;
        }} />
    );
  }
}


export class CheckboxCellType extends CellType {
  clickable(): boolean {
    return true;
  }

  useEditor(): boolean {
    return false;
  }

  renderValue(value: any, { setValue }) {
    return <input type="checkbox" checked={value} onClick={() => setValue(!value)} />;
  }

  renderEditor({ frame, value, setValue, close, state }: { frame: any; value: any; setValue: any; close: any; state: any; }) {
    
  }
}

@component
class Media extends Component<{ value, setValue }> {
  static styles = styled.div`
    height: 100%;
    > div {
      height: 100%;
    }
    img {
      height: 30px;
      display: block;
    }
  `;
  render() {
    return (
      <FileUpload
        disabled={resumeMode.enabled}
        onUpload={async (file) => {
          this.props.setValue(file);
        }}
      >
        <img src={uploadedFileUrl(this.props.value)} />
      </FileUpload>

    )
  }
}

export class MediaCellType extends CellType {
  renderEditor({ frame, value, setValue, close, state }: { frame: any; value: any; setValue: any; close: any; state: any; }) {
    
  }

  renderValue(value, { setValue }) {
    return (
      <Media value={value} setValue={setValue} />
    )
  }
}


class Scale extends Component<{ get, set }> {
  static wrapper = styled.div`
    font-family: codaicons;
    color: rgb(228, 167, 9);
    font-size: 20px;

    display: flex;
    .increment {
      &:before {
        content: "\\EA6A";
      }
      cursor: pointer;
    }

  `;

  state = XInit(class {
    hoverIndex = null;
  })

  render() {
    const Wrapper = Scale.wrapper;
    const value = this.props.get() || 0;
    const setValue = v => this.props.set(v);

    const renderIncrement = i => {
      return <span className="increment" style={{
        color: i > value && 'rgb(198, 198, 198)',
      }} onMouseDown={() => setValue(i)} />;
    }

    return (
      <>
                  <Wrapper>
              {/* {value} */}
              
              {renderIncrement(1)}
              {renderIncrement(2)}
              {renderIncrement(3)}
              {renderIncrement(4)}
              {renderIncrement(5)}
            </Wrapper>

      </>
    )
  }
}

@component
class Slider extends Component<{ get, set, min, max, inc, type: NumberDisplayType, color }> {
  static styles = styled.div`
    position: relative;
    cursor: pointer;


    width: 100%;

    &._${NumberDisplayType.slider} {
      height: 20px;
      &:before {
        content: '';
        height: 4px;
        background: #e1e1e1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-radius: 2px;
      }

      > span {
        position: absolute;
        height: 4px;
        background: #1063b1;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;

        &:before {
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #0c3999;
          position: absolute;
          right: -8px;
          top: -4px;
        }
      }
    }

    &._${NumberDisplayType.progress} {
      height: 16px;
      &:before {
        content: '';
        height: 16px;
        background: #e1e1e1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-radius: 4px;
      }

      > span {
        position: absolute;
        height: 16px;
        background: #1063b1;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        /* &:before {
          content: '';
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #0c3999;
          position: absolute;
          right: -8px;
          top: -4px;
        } */
      }
    }

  `;

  dragging
  handler

  update(e) {
    const el = ReactDOM.findDOMNode(this) as Element;
    const bounds = el.getBoundingClientRect();
    const p = Math.max(0, Math.min((e.clientX - bounds.left)/bounds.width, 1));
    this.props.set(p * this.props.max);
  }

  componentDidMount(): void {
    const el = ReactDOM.findDOMNode(this) as Element;

    let timerId;
    this.handler = e => {
      if (this.dragging) {
        // const bounds = el.getBoundingClientRect();
        // const p = Math.max(0, Math.min((e.clientX - bounds.left)/bounds.width, 1));

        clearTimeout(timerId);
        timerId = setTimeout(() => {
          this.update(e);
          // console.log(p);

          // this.props.set(p * this.props.max);
        }, 100);
      }
    }
    jQuery(window).mousemove(this.handler);
  }
  componentWillUnmount(): void {
    jQuery(window).off('mousemove', this.handler);
  }
  render(Container?) {
    const value = this.props.get()
    let color;
    const p = value/this.props.max;

    if (this.props.color == 'e344d5dd-c759-59fb-89ec-3634dc200423') {
      if (p < .3333) {
        color = mainColors.find(c => c.key == 'red').color;
      }
      else if (p < .666) {
        color = mainColors.find(c => c.key == 'yellow').color;
      }
      else {
        color = mainColors.find(c => c.key == 'green').color;
      }
    }

    return (
      <Container
        className={'_' + this.props.type}
        onMouseDown={e => {
          e.preventDefault();
          this.dragging = true;
          this.update(e);
          jQuery(window).one('mouseup', () => {
            this.dragging = false;
          })
        }}
      >
        <span style={{
          width: (p)*100 + '%',
          backgroundColor: color,
        }} />
      </Container>
    )
  }
}


/* export class NumberCellType extends CellType<{
  displayType: NumberDisplayType

  minimum?
  maximum?
  increment?

  useFormula?
  formula?

  baseEntity?
  color?

}> {
  useEditor(): boolean {
    return true;
  }
  renderValue(value, { setValue }) {
    if (this.metaData.displayType) {

      if (this.metaData.useFormula) {
        value = execFormulaFromData(this.metaData.formula, {
          base: this.metaData.baseEntity,
        })
      }

      if (this.metaData.displayType == NumberDisplayType.scale) {
        return (
          <>
            <Scale get={() => value} set={setValue} />
          </>
        )
      }
      else if (this.metaData.displayType == NumberDisplayType.slider || this.metaData.displayType == NumberDisplayType.progress) {
        return (
          <>
            <Slider
              get={() => value}
              set={setValue}
              min={this.metaData.minimum}
              max={this.metaData.maximum}
              inc={this.metaData.increment}
              type={this.metaData.displayType}
              color={this.metaData.color}
            />
          </>
        )
      }
    }
    else {
      return 'asdf';
    }
  }
} */


export class ReactionCellType extends CellType<{
  icon
}> {

  useEditor(): boolean {
    return true;
  }
  renderValue(value, { setValue }) {

    return <Reaction icon={this.metaData.icon} get={() => value} set={setValue} />
  }
}

export class AttributeCellType extends CellType {
  // useEditor(): boolean {
  //   return true;
  // }

  renderValue(value: any, args?: any) {
    return value && <span><ObjectDisplay obj={{ type: ObjectType.attribute, id: value }} showPath /></span>;
  }

  renderEditor({ frame, value, setValue, close, state }: { frame: any; value: any; setValue: any; close: any; state: any; }) {
    return (
      <>
        <ObjectPicker
          type={ObjectType.attribute}
          _onSelect={v => {
            setValue(v?.id);
            close();
          }}
          onEsc={() => {
            close();
          }}
        />
      </>
    )
  }
}