import { X, XObject } from '../XObject';

export function initComponentInstanceDiag(id) {
  if (!componentInstanceDiag[id]) {
    componentInstanceDiag[id] = {
      vars: {},
    };

    XObject.captureAccesses(() => {
      componentInstanceDiagTick[id] = 1;
    },() => 'stop');
  }

  return componentInstanceDiag[id];
}

export function tickComponentInstanceDiag(id) {
  XObject.captureAccesses(() => {
    componentInstanceDiagTick[id] ++;
  },() => 'stop');
  
}

export function getComponentInstanceDiag(id) {
  // eslint-disable-next-line
  componentInstanceDiagTick[id];
  return componentInstanceDiag[id];
}

export function deleteComponentInstanceDiag(id) {
  delete componentInstanceDiagTick[id];
  delete componentInstanceDiag[id];
}

export const componentInstanceDiag = {};
const componentInstanceDiagTick = X({});
export const componentInstanceDiagScopes = {};

export function isComponent(id) {
  return componentInstanceDiag[id];
}