import { ScriptComponent } from '../../scriptComponents/ScriptComponent';
import { RenderRuntime } from '../RenderParams';
import { ValueType } from '../ValueType';
import { evaluate, evaluateWithParams, getValuePoint, ReferenceType, scriptEvaluate } from '../main';
import { Test } from './FormulaValuePoint';
import { StructureRef } from './StructureRef';

export const hooks = (values, map, cache, rt: RenderRuntime) => {
  if (!rt) {
    rt = {
      evaluate: params => evaluateWithParams({...params, rt }),
      scriptEvaluate: scriptEvaluate,
    }
  }

  if (!rt) throw new Error();
  
  return {
    types: {
      scriptComponent: {
        text: id => {
          return `_$12a1ad37-eeea-53a0-953e-7c5bc705befb:${id}$_`;
        },  
      },
      glue: {
        text: (id) => {
          return `_$${id.type}:${id.id}$_`;
        },
      }
    },
    resolveObjectRef: (ref, env={}) => {
      if (ref.type == ReferenceType.StructureParameter) {
        if (ref.id in env) {
          return env[ref.id];
        }
        const key = `${ref.type}:${ref.id}`;

        return cache[key] = evaluateWithParams({ value: values[key], map, rt });
      }
      else if (ref.type == ReferenceType.Parameter) {
        const key = `${ref.type}:${ref.id}`;
        if (key in cache) return cache[key];
        return cache[key] = rt.evaluate({ value: values[key], map });
      }
      else if (ref.type == ReferenceType.Value) {
        const key = `${ref.type}:${ref.id}`;
        const v = getValuePoint(ref.id);
        if (v.parameters?.length) {
          return (...args) => {
            const argsMap = {};
            for (let i = 0; i < args.length && i < v.parameters.length; ++i) {
              argsMap[v.parameters[i]._id] = args[i];
            }
            return scriptEvaluate({ value: values[key], map: { ...map, ...argsMap }, rt });
          };
        }
        else {
          if (key in cache) return cache[key];
          return cache[key] = rt.scriptEvaluate({ value: values[key], map });
        }
      }
      else if (ref.type == ReferenceType.ContextArg) {
        return map[ref.id];
      }
      else if (ref.type == ValueType.Structure) {
        return new StructureRef(ref.id);
      }

      else if (ref.type == '12a1ad37-eeea-53a0-953e-7c5bc705befb') {
        return new ScriptComponent(ref.id);
      }

      return 'e801f1d3-cce2-548f-8253-4d3ed5f035ed';
    },
    accessorHandlers: [
      {
        test: obj => obj instanceof Test,
        perform: () => {
          return 'Hello';
        }
      },
      {
        test: obj => obj instanceof ScriptComponent,
        perform: (obj: ScriptComponent, prop) => {
          return new ScriptComponent(obj.id, obj.childPath.concat(prop));
        }
      }
    ]
  };
};
