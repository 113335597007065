import _ from 'lodash';
import { typeRegistry } from "../typeRegistry.1";
import { ValueType } from '../ValueType';

export const $Property_CreationTemplateParameter = typeRegistry.registerType({
  $: '8a1f7dcb-359e-5f82-9ee0-c8f75576fa10',
  Name: 'f294c3db-6350-5816-b514-aa1cdcf3a2ea',
  Property: '8342938e-83ca-53ed-8c4d-4b5265292a40',
}, ids => ({
  _id: ids.$,
  name: 'Property (Creation Template Parameter)',
  definition: [
    {
      id: ids.Name,
      name: 'Name',
      type: [],
      property: 'name',
    },
    {
      id: ids.Property,
      name: 'Property',
      type: [],
      property: 'property',
    },
  ],
}));

export const $Parent_CreationTemplateParameter = typeRegistry.registerType({
  $: 'b69f4ee4-ef58-505a-b59b-9aaa20ede358',
  Name: '5fae400e-51a4-5f25-8c48-e61615c2c465',
}, ids => ({
  _id: ids.$,
  name: 'Parent (Creation Template Parameter)',
  definition: [
    {
      id: ids.Name,
      name: 'Name',
      type: [],
      property: 'name',
    },
  ],
}));

const $CreationTemplate = typeRegistry.registerType({
  $: 'bbb12220-ad04-527e-92b9-6c058df445dd',
  Name: 'f377b241-c536-5ee4-9ef2-1461ed79db07',
  EntityType: 'fd7e57a7-9b03-5b8b-ab13-7a8cadadba26',
  Properties: '7cd95278-7155-5f74-8882-5ed06d6ad9f5',
  Arguments: '58be7f4d-4fe3-5c3c-8f27-0a6565206834',
}, ids => ({
  _id: ids.$,
  name: 'Creation Template',
  definition: [
    {
      id: ids.Name,
      name: 'Name',
      type: [],
      property: 'name',
    },
    {
      id: ids.EntityType,
      name: 'Entity Type',
      type: [],
      property: 'entityType',
    },
    {
      id: ids.Properties,
      name: 'Properties',
      type: [ ValueType.Array, [  ] ],
      property: 'properties',
    },
    {
      id: ids.Arguments,
      name: 'Arguments',
      type: [ ValueType.Array, [  ] ],
      property: 'arguments',
    },
  ],
}));

export const $CreationTemplates = typeRegistry.registerType({
  $: '0faa3613-54a5-5e67-b62d-48e76653f18b',
  Templates: '3393a818-5237-59b6-b9dd-5727eba2aa99',
}, ids => ({
  _id: ids.$,
  name: 'Creation Templates',
  definition: [
    {
      id: ids.Templates,
      name: 'Templates',
      type: [ ValueType.Array, [ ValueType.Structure, $CreationTemplate.$ ] ],
      property: 'templates',
    }
  ],
}));
