
export enum Type {
  text = '30f09f35-fd82-53d8-be57-cab3c7d7c998',
  name = '629306fe-b47b-5eff-91a7-35bcd3f27415',
  select = '891b29ef-32f7-5e7d-9883-325d081622c0',
  multiSelect = 'e0983ba4-dea7-5b44-bf1e-fa3131722a36',
  code = 'f1cb58c7-825f-5a4a-a3a6-8450dc94a799',
  media = '0fcd006a-2517-52fa-8916-21b6033ab59c',
  url = '64afba0e-7a78-58ef-a3ae-4fce9fc1dbb8',
  duration = 'b970227d-3d4e-519b-a0cd-cafa5d30052f',
  event = '8445adc1-8f42-5f29-b5fa-ed550e98e8eb',
  glue = 'd18ec864-1424-5028-ba5c-e62b06cfd69d',
  checkbox = '5f3516dd-e445-5e9e-b97f-406e53aadd39',
  entity = '376f866f-acb1-50b9-8468-fec87df60f77',
}
