import React, { Component } from "react";
import { component, styled } from "../component";
import ContentEditable from 'react-contenteditable';
import { htmlToText } from 'html-to-text';
import { RichTextEditor } from "./richTextEditor/RichTextEditor";


@component
export class TextEditor extends Component<{ value; rich?, setValue; frame; debounce?; autoFocus?: boolean; onEnter? }> {
  static styles = styled.div`
    border-radius: 4px;
    background: white;
    position: relative;
    max-width: calc(100vw - 24px);
    box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;

    /* width: 100px; */
    /* height: 100px; */
    padding: 6px 9px;

    /* width: 350px; */
    min-height: 35px;
    max-height: 572px;
    box-sizing: border-box;

    > div {
      &:focus {
        outline: none;
      }
    }
  `;

  constructor(props) {
    super(props);
    this.currentValue = props.value;
  }

  componentDidMount() {
  }

  currentValue

  render(Container?) {
    let timerId;
    const rich = this.props.rich ?? true;
    return (
      <Container
        style={{
          minWidth: this.props.frame?.width,
          minHeight: this.props.frame?.height,
        }}
      >
        {!rich && <input type="text"
          autoFocus={this.props.autoFocus}
          defaultValue={this.props.value}
          onKeyDown={e => {
            if (e.key == 'Enter') {
              this.props.onEnter?.(this.currentValue);
            }
          }}
          onChange={e => {
            const value = e.target['value'];
            this.currentValue = value;
            if (this.props.debounce) {
              clearTimeout(timerId);
              timerId = setTimeout(() => {
                this.props.setValue(value);
              }, this.props.debounce);
            }
            else {
              this.props.setValue(value);
            }
          }}
        />}
        {rich && <RichTextEditor
          autoFocus={this.props.autoFocus}
          setValue={value => {
            this.currentValue = value;
            if (this.props.debounce) {
              clearTimeout(timerId);
              timerId = setTimeout(() => {
                this.props.setValue(value);
              }, this.props.debounce);
            }
            else {
              this.props.setValue(value);
            }
          }}
          onEnter={this.props.onEnter && (() => {
            this.props.onEnter?.(this.currentValue);
          })}
          value={() => this.props.value}
        />}
      </Container>
    );
  }
}
