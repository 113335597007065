import { getObject } from "../components/objectFuncs";
import { Type, expandToText } from "../richTextHelpers";
import { db } from "../db";
import { WindowType } from "../etc/WindowType";
import { showContextMenu } from "../helpers";
import { openWindow, registerInspectObjectHandler, triggerInspectObject } from "../osHelpers";
import { ComponentValueWrapper } from "./ComponentValueWrapper";
import { component } from "../@component";
import { Component } from "react";
import { SystemContext, SystemContextProps } from "../etc/SystemContext";
import { getEntityById } from "../etc/createEntity";
import { entityDisplayName } from "../components/entityDisplayName";

@component
class Test extends Component<{ text, id }> {
  static contextType = SystemContext;
  context: SystemContextProps;
  render() {
    return <span onClick={() => {
      // this.context.navigate({
      //   type: 'entity',
      //   id: this.props.id,
      // })
    }}>{this.props.text}</span>
  }
}

@component
class ScriptComp extends Component {
  render() {
    return 'sadf';
  }
}

export const types = {
  entity: {
    type: Type.atomic,
    html: (id) => {
      const entity = getEntityById(id);
      return `<span class="--entity--" contenteditable="false" data-type="entity" data-entity-data="${btoa(JSON.stringify(id))}">${
        // expandToText({ types }, entity ? entity.name : '(deleted)')
        entityDisplayName(entity._id)
        // 'no'
      }</span>`;
    },
    text: (id) => {
      const entity = getEntityById(id);
      return expandToText({ types }, entity ? entity.name : '(deleted)');
    },
    part: el => {
      const data = JSON.parse(atob(el.getAttribute('data-entity-data')));
      return [data, 'entity'];
    }
  },
  code: {
    type: Type.atomic,
    html: (id, comps, args) => {
      const n = Math.random();
      comps[n] = {
        mount: <ComponentValueWrapper component={id.component} args={args} />,
      };
      return `<span class="--code--" contenteditable="false" data-type="code" data-code-data="${btoa(JSON.stringify(id))}"><span data-mount-point="${n}"></span>${!id.component ? `<span style="color: red;">Component not found</span>` : ''}</span>`;
    },
    part: el => {
      const data = JSON.parse(atob(el.getAttribute('data-code-data')));
      return [data, 'code'];
    }
  },

  script: {
    type: Type.atomic,
    html: (id, comps, args) => {
      const n = Math.random();
      comps[n] = {
        mount: <ScriptComp />
      };
      return `<span class="--script--" contenteditable="false" data-type="script" data-script-data="${btoa(JSON.stringify(id))}"><span data-mount-point="${n}"></span>${!id.component ? `<span style="color: red;">Component not found</span>` : ''}</span>`;
    },
    part: el => {
      const data = JSON.parse(atob(el.getAttribute('data-script-data')));
      return [data, 'script'];
    }

  },


  // capture: {
  //   type: Type.text,
  //   html: text => {
  //     return `<span class="--capture--" contenteditable="true" data-type="capture">${text}</span>`;;
  //   },
  //   part: el => {
  //     return [el.textContent, 'capture'];
  //   }
  // },

  entityLink: {
    type: Type.text,
    html: (data, comps, args, part) => {
      const text =  data;
      const { id } = part[2];
      const n = Math.random();
      comps[n] = {
        mount: <Test id={id} text={text} />,
      };
      return `<span data-closed-edges data-entity-id="${id}" data-text="${text}" data-id="${id}" style="color:blue; cursor:pointer" data-type="entityLink" data-entity-data="${btoa(JSON.stringify(part[2]))}"><span data-mount-point="${n}"></span></span>`;
    },
    text: data => {
      return data;
    },
    part: el => {
      const data = JSON.parse(atob(el.getAttribute('data-entity-data')));
      return [el.textContent, 'entityLink', {id:data.id}];
    }
  }
};


