import React, { Component, FunctionComponent } from "react";
import SVG from 'react-svg-raw';
import { img } from "../img";
import { component } from "../component";


export const Svg: FunctionComponent<{ className?: any; src?; name?: string; colors?: any; onClick?: any; onMouseDown?; onContextMenu? }> = props => {
  // return null;
  if (!props.name && !props.src) return null;
  let src = props.src || img(props.name, props.colors);
  if (!src.includes('</svg>')) return <span>Invalid SVG</span>;

  src = src.replace(/class=".*?"/, '')

  return (
    <SVG
      data-script-block-id={props['data-script-block-id']}
      data-script-block-ids={props['data-script-block-ids']}
      data-node-id={props['data-node-id']}
      src={src}
      className={props.className} onClick={props.onClick} onMouseDown={props.onMouseDown} contentEditable={false} onContextMenu={props.onContextMenu}
    />
  );
};


const cache = {};

@component
export class RemoteSvg extends Component<{ className?, url, colors?, onClick? }> {

  data

  constructor(props) {
    super(props);
    if (this.props.url in cache) {
      this.data = cache[this.props.url];
    }

  }

  componentDidCatch(error, errorInfo) {
    // console.log(error, errorInfo);
    console.log(this.data);
    return true;
  }


  async componentDidMount() {
    if (!this.data) {
      if (this.props.url in cache) {
        this.data = cache[this.props.url];
      }
      else {
        const r = await (await fetch(this.props.url)).text();
        cache[this.props.url] = r;
        this.data = r;
          
      }
  
      this.forceUpdate();
  
    }

  }

  render() {
    // return null;
    
    return (
      <>
        {this.data && <Svg
        onClick={this.props.onClick}
        
        data-node-id={this.props['data-node-id']}
        data-script-block-id={this.props['data-script-block-id']}
        data-script-block-ids={this.props['data-script-block-ids']}
        
        className={this.props.className} src={this.data} colors={this.props.colors} />}
      </>
    )
  }
}