

export class FormulaObjectProxy {
  constructor(public args: {
    call?;
    get?;
    set?;
    debugInfo?;

    type?
  }) { }



  debugInfo() {
    return this.args.debugInfo?.();
  }

  call(env, ...args) {
  }

  toPrimitive() {
  }

  toString() {
  }

  get(prop) {
    return this.args.get?.(prop);
  }

  set(prop, value) {
    this.args.set?.(prop, value);
  }
}
