import React, { Component } from 'react';
import _ from 'lodash';
import { db } from '../db';
import { PropertyField } from '../components/PropertyField';
import { component } from '../component2';
import { XObject } from '../XObject';
import { EXPRESSION_QUERY } from '../etc/queryFuncs';
import { ExpressionEntryType, Conjuction, renderEntry } from '../etc/expressionQuery';
import { ObjectType } from '../types/ObjectRef';
import { AttributeType } from '../components/AttributeType';
import { ColorSelector } from '../components/ColorSelector';
import { IconSelector } from '../components/IconSelector';
import { NumberDisplayType } from './NumberDisplayType';
import { FormulaEditor } from '../shorthandEditor/FormulaEditor';
import { CheckboxItem, CodaIconSelectorItem, DropdownItem, Field, ItemFrame, NavItem, Section, TextInput } from '../components/QueryViewEditor';
import { Tag } from '../components/Tag';
import { lightColors } from '../components/mainColors';
import { Svg } from '../components/Svg';
import { styled } from '../component';
import { setAppInspect } from '../etc/appState';
import { InspectState } from '../components/InspectState';
import { createRootValuePoint } from '../glue/main';
import { explicitInspectObj } from '../inspectObj';

@component
class OptionEditor extends Component<{ option, delete }> {
  static styles = styled.div`
    padding: 8px;
    background-color: white;
    border: 1px solid black;
  `;
  render() {
    const o = this.props.option;
    return (
      <>
        <input type="text" defaultValue={this.props.option.title} onChange={e => {
          this.props.option.title = e.target.value;
        }} />

            <div>
                    Color: <ColorSelector
                      get={() => o.color}
                      set={v => o.color = v}
                      theme="primary"
                    />
                  </div>


          <button
            onClick={() => {
              this.props.delete();
            }}
          >Delete</button>

      </>
    )
  }
}

@component
class OptionEntry extends Component<{ option, delete }> {
  static t = {
    wrapper: styled.div`
      display: flex;
      align-items: center;
      ${Tag} {
        margin-left: 4px;
      }

      .grip {
        cursor: grab;
        &, path {
          fill: #ababab;
        }
      }
    `
  };

  render() {
    const { t } = OptionEntry;
    const o = this.props.option;
    return (
      <ItemFrame
        key={o._id}
        left={
          <t.wrapper>
            <Svg name="grip" className="grip" />
            <Tag
              text={o.title}
              color={lightColors.find(c => c.colorType == o.color)?.color}
            />
          </t.wrapper>
        }
        onClick={a => {
          a.present(close => <OptionEditor option={o}  delete={() => {
            close();
            this.props.delete();
          }}/>);
        }}
      />
    );
  }
}

@component
class AddButton extends Component<{ _onClick }> {
  static styles = styled.div`
    display: flex;
    cursor: pointer;
    width: 16px;
    height: 16px;
    align-items: center;
    justify-content: center;
    svg {
      width: 12px;
      height: 12px;
      fill: rgb(182, 182, 182);
    }
  `;
  render() {
    return (
      <>
        <Svg name="plus"
          onClick={() => {
            this.props._onClick();
          }}
        />
      </>
    )
  }
}

@component
export class AttributeEditor extends Component<{ window; }> {
  render() {
    const attr = db.attributeTypes.findById(this.props.window.id);
    return (
      <>
        <Section>
          <DropdownItem
            label="Type"
            options={Object.keys(AttributeType).map(key => ({
              text: _.startCase(key),
              value: AttributeType[key],
            }))}
            value={attr.type}
            setValue={v => attr.type = v}
          />
          {/* <b>{_.invert(AttributeType)[attr.type]}</b> */}
          {/* <ul>
            {attr.reversePropRules?.map?.((rule, i) => {
              const typesScopes = [
                {
                  type: ObjectType.attribute,
                  id: attr._id,
                }
              ];
              return (
                <li key={rule._id}>
                  <button
                    onClick={() => {
                      attr.reversePropRules.splice(i, 1);
                    }}
                  >-</button>
                  <Cell
                    title="Match"
                    cell={new EntityTypesCellType({
                      scopes: typesScopes,
                    })}
                    get={() => {
                      return XObject.get(rule, 'match', []);
                    }}
                    set={value => {
                      rule.match = value;
                    }} />
                  <div>Title: <PropertyField object={rule} property="title" /></div>
                  <div>Type: <select
                    value={rule.viewType || ''}
                    onChange={e => {
                      rule.viewType = e.target.value;
                    }}
                  >
                    <option />
                    <option value="28460994-2d99-5d47-9f30-c29c7fdfd37f">Line</option>
                    <option value="7aeb14b3-f80d-5273-a228-09b815ccff54">Block</option>
                  </select>
                  </div>

                  {rule.viewType == '7aeb14b3-f80d-5273-a228-09b815ccff54' && (
                    <>

                      <div>
                        <input type="checkbox"
                          checked={rule.showEmpty}
                          onChange={e => {
                            rule.showEmpty = e.target.checked;
                          }} /> Show empty
                      </div>
                      {renderEntry(
                        XObject.get(rule, 'filter', [EXPRESSION_QUERY, {
                          _id: 'root',
                          type: ExpressionEntryType.group,
                          conjunction: Conjuction.and,
                          entries: [],
                        }])[1], [
                        ...(rule.match?.map?.(id => ({
                          type: ObjectType.type,
                          id,
                        })) || []),
                        {
                          type: ObjectType.attribute,
                          id: attr._id,
                        }
                      ])}
                    </>
                  )}
                </li>
              );
            })}
          </ul> */}
          {/* <button
            onClick={() => {
              XObject.push(attr, 'reversePropRules', XObject.obj({}));
            }}
          >+ Reverse Prop Rule</button> */}
          {(attr.type == AttributeType.entities || attr.type == AttributeType.entity) && (
            <>
              <CheckboxItem label="Resolved" value={attr.resolved} setValue={v => attr.resolved = v} />
              
              {/* <CheckboxItem label="Auto" value={attr.resolved} setValue={v => attr.resolved = v} /> */}


            </>
          )}

          {attr.type == AttributeType.number && (
            <div>
              <DropdownItem
                label="Display"
                options={[
                  { text: 'Scale', value: NumberDisplayType.scale },
                  { text: 'Slider', value: NumberDisplayType.slider },
                  { text: 'Progress', value: NumberDisplayType.progress },
                ]}
                value={attr.displayType}
                setValue={v => attr.displayType = v}
              />

              {attr.displayType == NumberDisplayType.scale && (
                <>
                  <CodaIconSelectorItem
                    label="Icon"
                    value={attr.icon}
                    setValue={v => attr.icon = v}
                  />
                </>
              )}

              {attr.displayType == NumberDisplayType.slider && (
                <>
                  <Field label="Min">
                    <TextInput value={attr.minimum} setValue={v => attr.minimum = v}/>
                  </Field>
                  <Field label="Max">
                    <TextInput value={attr.maximum} setValue={v => attr.maximum = v}/>
                  </Field>
                  <Field label="Increment">
                    <TextInput value={attr.increment} setValue={v => attr.increment = v}/>
                  </Field>
                </>
              )}

              {attr.displayType == NumberDisplayType.progress && (
                <>
                  <Field label="Min">
                    <TextInput value={attr.minimum} setValue={v => attr.minimum = v}/>
                  </Field>
                  <Field label="Max">
                    <TextInput value={attr.maximum} setValue={v => attr.maximum = v}/>
                  </Field>

                  <DropdownItem
                    label="Color"
                    options={[
                      { text: 'Red, Yellow, Green', value: 'e344d5dd-c759-59fb-89ec-3634dc200423' },
                    ]}
                    value={attr.color}
                    setValue={v => attr.color = v}
                  />
                </>
              )}

              <>
                <CheckboxItem
                  label="Formula"
                  value={attr.useFormula}
                  setValue={v => attr.useFormula = v}
                />
              </>
            </div>
          )}

          {attr.type == AttributeType.reaction && (
            <CodaIconSelectorItem
              label="Icon"
              value={attr.icon}
              setValue={v => attr.icon = v}
            />
          )}
        </Section>

        {(attr.type == AttributeType.select || attr.type == AttributeType.multiSelect) && (
          <Section header="Options" actions={<AddButton
            _onClick={() => {
              attr.options.push(XObject.obj());
            }}
          />}>
            {attr.options.map((o, i) => {
              return <OptionEntry key={o._id} option={o} delete={() => {
                attr.options.splice(i, 1);
              }} />;
            })}
          </Section>
        )}
        {(attr.type == AttributeType.entities || attr.type == AttributeType.entity) && (
          <>
            <Section header="Options">
              <DropdownItem
                label="Type"
                options={[
                  {
                    text: 'Query',
                    value: '728fc253-2834-577c-881f-a02e6e89d87c',
                  },
                  {
                    text: 'Value Point',
                    value: 'dd2d06f2-7551-502e-b2ee-e01544ca4817',
                  },
                  {
                    text: 'Formula',
                    value: '97aee361-cda7-5c88-a2a9-93f03d38b6fb',
                  },
                ]}
                value={attr.optionsType}
                setValue={v => attr.optionsType = v}
              />

              {attr.optionsType == 'dd2d06f2-7551-502e-b2ee-e01544ca4817' && (
                <NavItem 
                  label="Value Point"
                  onClick={() => {
                    if (!attr.valuePoint) {
                      const vp = createRootValuePoint();
                      attr.valuePoint = vp._id;
                    }
                    setAppInspect({
                      mode: InspectState.valuePoint,
                      id: attr.valuePoint,
                    });

                    
                    explicitInspectObj({
                      type: ObjectType.valuePoint,
                      id: attr.valuePoint,
                    })
                  }}
                />
              )}
            </Section>

            <Section header="Query">
              {renderEntry(XObject.get(attr, 'query', [EXPRESSION_QUERY, {
                _id: 'root',
                type: ExpressionEntryType.group,
                conjunction: Conjuction.and,
                entries: [],
              }])[1], [{ type: ObjectType.attribute, id: attr._id }], {
                type: 'entities',
              })}

              <DropdownItem
                label="Sort"
                options={[
                  { text: 'Relevance', value: 'e5315ab1-c719-5cbb-9880-5f4d98a1d6a8' },
                ]}
                value={attr.sort}
                setValue={v => attr.sort = v}
              />

            </Section>
          </>
        )}

        {attr.type == AttributeType.number && attr.useFormula && (
          <Section header="Formula">
            <FormulaEditor
              get={() => attr.formula}
              set={v => attr.formula = v}
            />
          </Section>
        )}

      </>
    );
  }
}
