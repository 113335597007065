import { useContext } from "react";
import { db } from "../../db";
import { styled } from "../../component";
import { ValueType } from "../ValueType";
import { mapStructure } from "../main";
import { structRenderers } from "../structRenderers";
import { typeRegistry } from "../typeRegistry.1";
import { SystemContext } from "../../etc/SystemContext";
import { $EntityDatabase } from "./$EntityDatabase";
import { $EntityQueryView } from "./$EntityQueryView";

export const $Test = typeRegistry.registerType({
  $: 'c1164ffe-f32c-5f7c-8c87-3393a2248648',
  Content: '23c76526-de01-58f8-99fb-8a2a37cdd52b',
}, ids => ({
  _id: ids.$,
  name: 'Test',
  definition: [
    {
      id: ids.Content,
      name: 'Content',
      type: [],
      property: 'content',
    },
  ],
}));

const Wrapper = styled.div`
  border: 1px solid black;
`;

structRenderers[$Test.$] = (value) => {
  const mapped = mapStructure(value);
  return (
    <Wrapper data-value-point={value._id}>
      {mapped.content} adsfasdf
    </Wrapper>
  )
}