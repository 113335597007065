import React, { Component } from 'react';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import jQuery from 'jquery';
import { component } from './component2';
import { x } from './XObject';
import { ScriptEditor } from './MyNotionDocument/ScriptEditor';
import { AnyType, FunctionType, StructType, buildModel, getScope, getType, globalTypes } from "./MyNotionDocument/getBlock";
import { presentMenu } from './components/richTextEditor/MenuPresenter';
import { DevRuntime } from './DevRuntime';
import { FormulaEditor } from './shorthandEditor/FormulaEditor';
import { expandFormula, scriptEmpty } from './shorthand/formula';
import { css, styled } from './component';
import { getComponentReferences } from './getComponentReferences';
import { ScriptWrapper } from './MyNotionDocument/ScriptWrapper';

const objectRefResolver = (objectRef, devRuntime) => {
  const [, id] = objectRef.split(':');
  const comp = devRuntime.devProject.components.find(c => c._id == id);
  if (comp) {
    const model = buildModel(x(comp.blocks || []), devRuntime.__types());

    const lastBlock = model[model.length - 1];
    if (lastBlock) {
      const scope = getScope(lastBlock._id, 0, model, false);
      const type = getType(lastBlock.cst, model, scope, {
        objectRefTypeResolver: objectRef => objectRefResolver(objectRef, devRuntime),
      }, false);
    
      return type;
  
    }
  }


}

@component
export class DevProjectFormulaEditor extends Component<{
  devRuntime: DevRuntime
  importedComponents;
  value
  onValuChanged
  onEnter?
  
}> {
  render() {
    return (
      <FormulaEditor
        additionalTypes={this.props.devRuntime.__types()}
        additionalMenuIniters={{
          $: args => {
            return presentMenu({
              ...args,
              menuIniters: {
                $: this.props.devRuntime.__menu(args, {
                  importedComponents: this.props.importedComponents,
                }),
              }
            });
          }
        }}
        get={() => this.props.value}
        set={v => {
          this.props.onValuChanged(v)
        }}
        onEnter={this.props.onEnter}
      />
    );
  }
}

function getSetupTypes(component, devRuntime: DevRuntime) {
  if (!component) {
    return {}
  }
  if (component.baseComponent) {
    return getSetupTypes(devRuntime.getComponent(component.baseComponent), devRuntime);
  }
  if (!scriptEmpty(component.setup)) {
    const types = {};
    for (const block of component.setup[0].children) {
      const line = expandFormula(block.data, devRuntime._hooks());

      const [name] = line.split(':');
      if (name) {
        types[name] = new AnyType();
      }

    }
    return types;
  }

  return {};
}

export function getGlobalTypes(devRuntime: DevRuntime, { component = undefined }) {
  const types = globalTypes();
  const props = []
  if (component?.propsType) {
    for (const block of component.propsType) {
      const line = expandFormula(block.data);
      const [prop, type] = line.split(':').map(p => p.trim());
      props.push({
        name: prop,
        type: types[type],
      })
    }
  }

  const state = [];
  if (component?.stateType) {
    for (const block of component.stateType) {
      const line = expandFormula(block.data);
      const [prop, type] = line.split(':').map(p => p.trim());
      state.push({
        name: prop,
        type: types[type],
      })
    }
  }

  return {
    ...getSetupTypes(component, devRuntime),
    props: new StructType({
      properties: props,
      name: 'Props',
    }),
    state: new StructType({
      properties: state,
      name: 'State',
    }),
    presentLayer: new FunctionType({
      returnType: new StructType({
        properties: [
          { name: 'remove', type: new FunctionType({}) },
        ],
        name: 'Layer',
      })
    }),
    positionEl: new FunctionType({}),
    emit: new FunctionType({}),
  }
}

@component
export class DevProjectScriptEditor extends Component<{
  devRuntime: DevRuntime
  scriptWrapper: ScriptWrapper
  client?;
  state?;
  importedComponents;
  createComponent?;
  rootId?;
  onClickComponentBadge?;
  contextMenuItems?;
  renderBlockArgs?;
  component?
  highlightId?
  disableAutoComplete?
  onDeleted?
  cmdClick?

  fadeOutNoDebugData?: boolean
}> {

  static styles = styled.div`
    &.fadeOutNoDebugData {
            [data-type="blockData"] {
              opacity: .65;
            }

          }
        


  `;

  componentDidMount(): void {
    const el = ReactDOM.findDOMNode(this);
    jQuery(el).on('mousedown', '[data-prop-path]', e => {
      if (e.metaKey) {
        // console.log(e);
        const blockEl = jQuery(e.target).parents('[data-block-id]');
        const blockId = blockEl.attr('data-block-id');
        const [path, col] = e.target.getAttribute('data-prop-path').split(':');

        // console.log(blockId, path, col);
        this.props.cmdClick?.(blockId, path, col);
      }
    });
  }

  objectRefResolver(objectRef) {
    return objectRefResolver(objectRef, this.props.devRuntime);
  }

  additionalTypes() {
  }

  render(Container?) {
    // const types = globalTypes();
    // const props = []
    // if (this.props.component?.propsType) {
    //   for (const block of this.props.component.propsType) {
    //     const line = expandFormula(block.data);
    //     const [prop, type] = line.split(':').map(p => p.trim());
    //     props.push({
    //       name: prop,
    //       type: types[type],
    //     })
    //   }
    // }

    // const state = [];
    // if (this.props.component?.stateType) {
    //   for (const block of this.props.component.stateType) {
    //     const line = expandFormula(block.data);
    //     const [prop, type] = line.split(':').map(p => p.trim());
    //     state.push({
    //       name: prop,
    //       type: types[type],
    //     })
    //   }
    // }

    const refs = this.props.scriptWrapper ?getComponentReferences(this.props.scriptWrapper.blocks()) : [];

    return (
      <Container className={this.props.fadeOutNoDebugData && this.props.devRuntime.isDebuggingEnabled() && 'fadeOutNoDebugData'}>
        <ScriptEditor
          globals={getGlobalTypes(this.props.devRuntime, { component: this.props.component })}

          onDeleted={this.props.onDeleted}

          highlightBlock={this.props.highlightId}
          rootBlock={this.props.rootId}
          client={this.props.client}
          scriptWrapper={this.props.scriptWrapper}
          debugPane={false}
          state={this.props.state}
          objectRefTypeResolver={objectRef => this.objectRefResolver(objectRef)}
          additionalTypes={this.props.devRuntime.__types()}
          additionalMenuIniters={{
            $: args => {
              return presentMenu({
                ...args,
                menuIniters: {
                  $: this.props.devRuntime.__menu(args, {
                    importedComponents: this.props.importedComponents,
                    component: this.props.component,
                  }),
                },
              });
            }
          }}
          contextMenuItems={this.props.contextMenuItems}
          renderBlockArgs={this.props.renderBlockArgs}
          autoCompleteEntries={!this.props.disableAutoComplete && _.uniq((this.props.importedComponents|| []).filter(Boolean).map(c => c._id).concat(refs)).map(id => {
            const c = this.props.devRuntime.getComponent(id);
            if (c) {
              return {
                id: c._id,
                display: <span style={{
                  'borderBottom': '1px dashed #afafafb0'
  
                }}>{c.name}</span>,
                name: c.name,
                part: [c._id, 'component'], 
              }
            }

          }).filter(Boolean) || []}

          blockMeta={b => {
            return this.props.devRuntime.getBlockDebug(b, this.props.component?._id);
          }}

          disableAutoComplete={this.props.disableAutoComplete}
        />
      </Container>
    );
  }
}
