import React, { Component } from 'react';
import styled from 'styled-components';
import { component } from '../component2';
import { XObject } from '../XObject';
import { appState } from '../etc/appState';
import { FavoritesSidebar } from '../RootSidebarItem';
import { Sidebar } from './Sidebar';
import { allDays } from './allDays';
import juration from '../juration';
import { CurrentDay } from './CurrentDay';
import { db } from '../db';
import { WidgetType } from '../WidgetsWindow';
import { GlueView, getValuePoint } from '../glue/main';

function renderWidget(id) {
  const widget = db.widgets.findById(id);

  if (widget.type == WidgetType.glue) {
    const vp = getValuePoint(widget.valuePoint, false);
    const state = XObject.get(widget, 'state', {});
    return (
      <>
        {vp && <GlueView
          args={{}}
          id={vp._id}
          state={state}
        />}
      </>
    )
  }
}

@component
export class HomeScreen extends Component {
  static styles = styled.div`
    ${CurrentDay} {
      margin-bottom: 4px;
    }

    .toggleDay {
      padding: 0 8px;
      margin-bottom: 8px;
      font-size: 12px;

    }

    .widget {
      overflow: hidden;
      padding: 8px;
      box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 2px 4px;
      border-radius: 3px;
      background: white;
      /* margin-bottom: 8px; */
      margin: 8px;

      /* cursor: pointer;   */

      /* transition: background 100ms; */


      /* &:hover {
        background: #f9f9f8;
      } */
    }
  `;

  timerId;
  componentDidMount(): void {
    this.timerId = setInterval(() => {
      this.forceUpdate();
    }, 1000);
  }

  componentWillUnmount(): void {
    clearTimeout(this.timerId);
  }

  render() {
    const days = allDays();
    const lastDay = days[days.length - 1];
    const mode = db.modes.findById(appState.currentMode);
    let length, timeTillEnd;

    if (lastDay) {
      if (lastDay.start && lastDay.plannedEnd) {
        length = (lastDay.plannedEnd.getTime() - lastDay.start.getTime()) / 1000;
        timeTillEnd = (lastDay.plannedEnd.getTime() - new Date().getTime()) / 1000;
      }
    }

    return (
      <>


        <CurrentDay />
        {mode.widgets?.map?.(w => {
          return (
            <div
              key={w._id}
              className="widget"
            >
              {renderWidget(w.widget)}
            </div>
          )
        })}
        {/* <div className="toggleDay"
          onClick={() => {
            lastDay.end = new Date();
          }}
        >
          {lastDay && (
            lastDay.end ?
              (
                <>
                  Since End: {juration.stringify((new Date().getTime() - lastDay.end.getTime()) / 1000)}

                </>
              )
              :

              (
                <>
                  {juration.stringify((new Date().getTime() - lastDay.start.getTime()) / 1000)}{length && (
                    <>/{juration.stringify(length)} ({juration.stringify(timeTillEnd)})</>
                  )}
                </>
              ))}
          {!lastDay && (
            <>
              Create Day
            </>
          )}
        </div> */}
        <div className="widget">
        <Sidebar
          ignoreChildren
          rootItem={new FavoritesSidebar()}
          state={XObject.get(appState, 'leftSidebar/favorites', {})}
        />
        </div>
      </>
    );
  }
}
