import { component, styled } from '../component';
import React, { Component } from 'react';
import { XInit } from '../XObject';
import { SecondColumn } from '../Query';
import { ThirdColumn } from '../ThirdColumn';
import { FormulaEditor } from './FormulaEditor';


@component
export class FormulaIDE extends Component<{ get; set; object?; scope?; hideFirstColumn?: boolean; }> {
  static styles = styled.div`
    height:100%;
    > .panes {
      display: flex;
      height: 100%;
      overflow: auto;

      > .pane {
        border-right: 1px solid rgb(204, 204, 204);
        width: 500px;
        height: 100%;
        padding: 4px;
        box-sizing: border-box;
        overflow: auto;
        flex: 0 0 auto;

        &:nth-child(3) {
          width: auto;
        }
      }
    }
  `;

  state = XInit(class {
    thirdColumn;
    thirdColumnState = {};
  });

  render() {
    // const objs = [];
    // if (!_.isString(this.props.get()) && this.props.get()) {
    //   for (const entry of this.props.get()) {
    //     if (!_.isString(entry)) {
    //       if (entry[1] == 'query') {
    //         objs.push(new Query(entry[0]));
    //       }
    //       else if (entry[1] == 'valuePoint') {
    //         objs.push(new ValuePoint(entry[0]));
    //       }
    //       else if (entry[1] == 'attribute') {
    //         objs.push(new Attribute(entry[0]));
    //       }
    //     }
    //   }  
    // }
    return (
      <>
        <div className="panes">
          {!this.props.hideFirstColumn && <div className="pane">
            <FormulaEditor get={this.props.get} set={this.props.set} parent={this.props.object} />
            {/* <button
                      onClick={() => {
                        explicitInspectObj(this.props.object);
                      }}
                    >inpsect</button> */}
          </div>}
          <SecondColumn get={this.props.get} _onClick={obj => {
            this.state.thirdColumn = obj;
          }} />
          {this.state.thirdColumn && (
            <ThirdColumn state={this.state.thirdColumn} />
          )}
        </div>
      </>
    );
  }
}
