import { css } from 'styled-components';
import { ObjectType } from '../types/ObjectRef';
import { cssImg } from '../img';
import { typeIcon } from '../components/objectFuncs';


export const formulaStyles = css`
    font-family: Monaco;
    .--entity-- {
      display: inline-block;
      padding: 0 5px;
      background: #eee;
      border-radius: 5px;
      cursor: pointer;

      &:before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        background: ${cssImg('tesseract')};
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        vertical-align: baseline;
        margin-right: 3px;
      }
    }

    .--badge-- {
      /* display: inline-block; */
      /* padding: 0 5px; */
      /* background: #cccccc66; */
      /* border-radius: 5px; */
      cursor: pointer;
      border-bottom: 1px dashed #afafafb0;
      &:hover {
        border-bottom: 1px dashed #afafaf;
      }
    }

    [data-object-type="${ObjectType.event}"] {
      &:before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        background: ${cssImg(typeIcon(ObjectType.event))};
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        vertical-align: baseline;
        margin-right: 3px;
      }
    }

    [data-object-type="${ObjectType.document}"] {
      &:before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        background: ${cssImg(typeIcon(ObjectType.document))};
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        vertical-align: baseline;
        margin-right: 3px;
      }
    }

    .--query-- {
      display: inline-block;
      padding: 0 5px;
      background: #eee;
      border-radius: 5px;
      cursor: pointer;

      &:before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        background: ${cssImg('database')};
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        vertical-align: baseline;
        margin-right: 3px;
      }
    }

    .--identifier-- {
      display: inline-block;
      padding: 0 5px;
      background: #eee;
      border-radius: 5px;
      cursor: pointer;

      &:before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        background: ${cssImg('icons8-shortcut')};
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        vertical-align: baseline;
        margin-right: 3px;
      }
    }

    .--attribute-- {
      display: inline-block;
      padding: 0 5px;
      background: #cccccca8;
      border-radius: 5px;
      cursor: pointer;

      &:before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        background: ${cssImg(typeIcon(ObjectType.attribute))};
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        vertical-align: baseline;
        margin-right: 3px;
      }
    }
`;
