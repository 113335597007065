/*
    --kr-red-medium-rgb: 201,22,22;
    --kr-orange-medium-rgb: 236,101,21;
    --kr-yellow-medium-rgb: 228,167,9;
    --kr-green-medium-rgb: 46,150,75;
    --kr-blue-medium-rgb: 47,117,215;
    --kr-purple-medium-rgb: 133,65,181;
    --kr-pink-medium-rgb: 206,66,166;
    --kr-gray-medium-rgb: 102,102,102;


    --kr-red-gradient-rgb: 255, 146, 146;
    --kr-red-light-rgb: 255, 220, 220;
    --kr-red-chart-rgb: 221, 72, 72;
    --kr-red-medium-rgb: 201, 22, 22;
    --kr-red-dark-rgb: 169, 30, 30;
    --kr-red-chartprimary-rgb: 169, 30, 30;
    --kr-orange-gradient-rgb: 252, 152, 92;
    --kr-orange-light-rgb: 252, 227, 206;
    --kr-orange-chart-rgb: 250, 135, 66;
    --kr-orange-medium-rgb: 236, 101, 21;
    --kr-orange-dark-rgb: 196, 91, 28;
    --kr-orange-chartprimary-rgb: 196, 91, 28;
    --kr-yellow-gradient-rgb: 255, 206, 70;
    --kr-yellow-light-rgb: 253, 243, 216;
    --kr-yellow-chart-rgb: 252, 188, 12;
    --kr-yellow-medium-rgb: 228, 167, 9;
    --kr-yellow-dark-rgb: 139, 108, 29;
    --kr-yellow-chartprimary-rgb: 228, 167, 9;
    --kr-green-gradient-rgb: 142, 203, 114;
    --kr-green-light-rgb: 226, 248, 232;
    --kr-green-chart-rgb: 66, 195, 102;
    --kr-green-medium-rgb: 46, 150, 75;
    --kr-green-dark-rgb: 24, 123, 52;
    --kr-green-chartprimary-rgb: 24, 123, 52;
    --kr-blue-gradient-rgb: 107, 183, 255;
    --kr-blue-light-rgb: 221, 237, 253;
    --kr-blue-chart-rgb: 82, 156, 226;
    --kr-blue-medium-rgb: 47, 117, 215;
    --kr-blue-dark-rgb: 15, 88, 189;
    --kr-blue-chartprimary-rgb: 15, 88, 189;
    --kr-purple-gradient-rgb: 210, 163, 225;
    --kr-purple-light-rgb: 237, 223, 246;
    --kr-purple-chart-rgb: 167, 98, 217;
    --kr-purple-medium-rgb: 133, 65, 181;
    --kr-purple-dark-rgb: 110, 33, 186;
    --kr-purple-chartprimary-rgb: 110, 33, 186;
    --kr-pink-gradient-rgb: 248, 140, 217;
    --kr-pink-light-rgb: 248, 231, 243;
    --kr-pink-chart-rgb: 220, 69, 177;
    --kr-pink-medium-rgb: 206, 66, 166;
    --kr-pink-dark-rgb: 161, 43, 134;
    --kr-pink-chartprimary-rgb: 161, 43, 134;
    --kr-gray-gradient-rgb: 190, 190, 190;
    --kr-gray-light-rgb: 240, 240, 240;
    --kr-gray-chart-rgb: 142, 142, 142;
    --kr-gray-medium-rgb: 102, 102, 102;
    --kr-gray-dark-rgb: 102, 102, 102;
    --kr-gray-chartprimary-rgb: 102, 102, 102;
*/
export const lightColors = [
  {
    name: 'Red',
    key: 'lightRed',
    colorType: 'red',
    color: 'rgb(255, 220, 220)',
  },
  {
    name: 'Orange',
    key: 'lightOrange',
    colorType: 'orange',
    color: 'rgb(252, 227, 206)',
  },
  {
    name: 'Yellow',
    key: 'lightYellow',
    colorType: 'yellow',
    color: 'rgb(253, 243, 216)',
  },
  {
    name: 'Green',
    key: 'lightGreen',
    colorType: 'green',
    color: 'rgb(226, 248, 232)',
  },
  {
    name: 'Blue',
    key: 'lightBlue',
    colorType: 'blue',
    color: 'rgb(221, 237, 253)',
  },
  {
    name: 'Purple',
    key: 'lightPurple',
    colorType: 'purple',
    color: 'rgb(237, 223, 246)',
  },
  {
    name: 'Pink',
    key: 'lightPink',
    colorType: 'pink',
    color: 'rgb(248, 231, 243)',
  },
  {
    name: 'Gray',
    key: 'lightGray',
    colorType: 'gray',
    color: 'rgb(240, 240, 240)',
  },
];
export const mainColors = [
  {
    name: 'Red',
    key: 'red',
    color: 'rgb(201, 22, 22)',
  },
  {
    name: 'Orange',
    key: 'orange',
    color: 'rgb(236, 101, 21)',
  },
  {
    name: 'Yellow',
    key: 'yellow',
    color: 'rgb(228, 167, 9)',
  },
  {
    name: 'Green',
    key: 'green',
    color: 'rgb(46, 150, 75)',
  },
  {
    name: 'Blue',
    key: 'blue',
    color: 'rgb(47, 117, 215)',
  },
  {
    name: 'Purple',
    key: 'purple',
    color: 'rgb(133, 65, 181)',
  },
  {
    name: 'Pink',
    key: 'pink',
    color: 'rgb(206, 66, 166)',
  },
  {
    name: 'Gray',
    key: 'gray',
    color: 'rgb(102, 102, 102)',
  },
];
