import React, { Component } from "react";
import { component } from "../component";
import { db } from "../db";
import { getPathInGraph, resolvePath } from "../etc/queryGraph";
import { styled } from "../component2";
import { getEntityById } from "../etc/createEntity";



@component
export class EntityPath extends Component<{ entity, root?, graph?, separator? }> {
  static styles = styled.div``;
  render(Container?) {
    const path = getPathInGraph(null, this.props.entity, this.props.root);
    return <Container className="path">{path.map(id => getEntityById(id)?.name || '...').join(' / ')}</Container>;
  }
}
