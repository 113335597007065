import React, { FunctionComponent } from "react";
import config from "../config";
import Dropzone from "react-dropzone";
import axios from "axios";
export function uploadedFileUrl(id: string, mime?) {
  // return `http://localhost:3700/images/${id}`;
  return (
    id &&
    (id.startsWith("http")
      ? id
      : `https://jonathan-cook-portfolio.s3.amazonaws.com/images/${id}`)
  );
}
export async function fileUpload(file: File | Blob, name?: string): Promise<string> {
  let formData = new FormData();
  formData.append("image", file);
  if (name) {
    formData.append("name", name);
  } else if (file instanceof File) {
    formData.append("name", file.name);
  }
  const response = await axios.post(`${config.apiServer}images`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
}
export const FileUpload: FunctionComponent<{
  onUpload: (id: string, file: File) => void;
  acceptsClick?: boolean;
  children?;
  disabled?
  Tag?
}> = (props) => {
  const Tag = props.Tag || 'div';
  return (
    <Dropzone
      noClick={!props.acceptsClick}
      onDrop={async (files) => {
        if (props.disabled) return;
        const name = await fileUpload(files[0]);
        props.onUpload(name, files[0]);
      }}>
      {({ getRootProps, getInputProps }) => (
        <Tag {...getRootProps()}>
          <input {...getInputProps()} />
          {props.children}
        </Tag>
      )}
    </Dropzone>
  );
};