import React, { Component } from 'react';
import { db } from '../db';
import { PropertyField } from '../components/PropertyField';
import { component } from '../component2';
import { XObject } from '../XObject';
import { ObjectField } from '../components/ObjectField';
import { setAppInspect } from '../etc/appState';
import { InspectState } from '../components/InspectState';


@component
export class EditModeWindow extends Component<{ window; }> {
  render() {
    const mode = db.modes.findById(this.props.window.id);
    return (
      <>
        {/* <button
          onClick={() => {
            const newSidebar = XObject.obj({
              children: [],
            });
            db.sidebar.push(newSidebar);
            mode.sidebar = newSidebar._id;
          }}
        >Create sidebar</button> */}
        <div>
          <input
            type="checkbox"
            checked={mode.useAsSpace}
            onChange={e => mode.useAsSpace = e.target.checked} /> Use As Space
        </div>
        <button
          onClick={() => {
            db.modes.splice(db.modes.indexOf(mode), 1);
          }}
        >Delete</button>
        <div>
          Creation Templates: <PropertyField object={mode} property="creationTemplates" />
        </div>
        <button
          onClick={() => {
            setAppInspect({
              mode: InspectState.valuePoint,
              id: mode.creationTemplates,
            });

          }}

        >.</button>
        {/* {!mode.useAsSpace && <div>
          Space: <ObjectField
            value={mode.space}
            setValue={value => mode.space = value} />
        </div>} */}

        <div>
          <ul>
            {mode.widgets?.map?.(w => {
              return (
                <li key={w._id}>
                  <div>Widget: <PropertyField object={w} property="widget" /></div>
                </li>
              )
            })}
            <li><button
              onClick={() => {
                XObject.push(mode, 'widgets', XObject.obj());
              }}
            >+</button></li>
          </ul>
        </div>
      </>
    );
  }
}
