import { typeRegistry } from "../typeRegistry.1";

export const $EventCheckboxBinding = typeRegistry.registerType({
  $: '3e951e7e-7ef7-5681-b07b-959b4da2e7bf',
  Event: '0592f623-c6ca-5b28-80e0-6a7004efd9fe',
  Argument: '30f7013f-abb8-5a9b-8813-5aa4a08c8529',
  TimeRange: '542c8fa8-e947-5c00-a070-07796559a31d',
}, ids => ({
  _id: ids.$,
  name: 'Event Checkbox Binding',
  definition: [
    {
      id: ids.Event,
      name: 'Event',
      type: [],
      property: 'event',
    },
    {
      id: ids.Argument,
      name: 'Argument',
      type: [],
      property: 'argument',
    },
    {
      id: ids.TimeRange,
      name: 'Time Range',
      type: [],
      property: 'timeRange',
    },
  ],
}));
