import React, { Component } from 'react';
import { component } from './component2';
import { objectName, setObjectName } from './components/objectFuncs';


@component
export class ObjectRenamer extends Component<{ obj; onRenamed; }> {
  name;
  constructor(props) {
    super(props);
    this.name = objectName(props.obj);
  }

  render() {
    const ref = React.createRef<any>();
    return (
      <>
        <input type="text" defaultValue={this.name} ref={ref}
          onKeyDown={e => {
            if (e.key == 'Enter') {
              setObjectName(this.props.obj, ref.current.value);
              this.props.onRenamed();
            }
          }} />
        <button
          onClick={() => {
            setObjectName(this.props.obj, ref.current.value);
            this.props.onRenamed();
          }}
        >Done</button>
      </>
    );
  }
}
