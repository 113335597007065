import 'react-universal-hooks';
import 'draft-js/dist/Draft.css';
import _ from 'lodash';
import Sugar from 'sugar';
import React, { Component } from 'react';
import classNames from 'classnames';
import axios from 'axios';
import { DndProvider as _DndProvider } from 'react-dnd';
import styled, { ThemeProvider } from 'styled-components';
import { BlockPageCont } from './components/BlockPage';
import { db, dbStatus, initDb, initLocalDb, initLocalStorageDb } from './db';
import { GlueDev, GlueStack, PaneContext, RootValue, indexValuePoints, init, values } from './glue/main';
import { OS } from './os';
import { _inspectState, openWindow, registerInspectObjectHandler, setOsKey } from './osHelpers';
import { component, css } from './component2';
import { X, x, XInit, XObject } from './XObject';
import { WindowType } from './etc/WindowType';
import { themeState } from './etc/themeState';
import { appState, memoryAppState } from './etc/appState';
import { promptData, promptState } from './etc/showPrompt';
import { SystemContext } from './etc/SystemContext';
import { deleteEdge, indexEdges } from './etc/getEdgesForEntity';
import { DebugWrapper } from './components/DebugWrapper';
import { UIWrapper } from './components/UIWrapper';
import { topBarHeight, defaultSidebarWidth, AppRoot, inspectFromNav, initPaneObject, isEmptyPane, MobileRoot } from './components/AppRoot';
import { windowTypes } from './windowTypes';
import { resumeMode } from './resumeMode';
import { PropertyField } from './components/PropertyField';
import { getCodeComponent, pushCode } from './pushCode';
import { componentSystem } from './componentSystem';
import { NotionDocumentWindow } from './windows/NotionDocumentWindow';
import { PaneType } from './types/PaneType';
import { resolvePath } from './components/resolvePath';
import { CodeComponenType, CodeElementType, ComponentIDEEditor, ComponentSystem, EditorState, initEditor, registerComponentRenderer, registerPaneType } from './ideIndex';
import { isCordova, isMobile } from './isMobile';
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import { initBacklinkCache } from './initBacklinkCache';
import { ObjectEditor } from './ObjectEditor';
import './components/presentMobileInspectModal';
import { GoogleLoginButton } from "react-social-login-buttons";

import { initializeApp } from 'firebase/app';
import { defaultWorkspace } from './etc/defaultWorkspace';
import { ValueType } from './glue/ValueType';
import { $EntityTemplateSupplier } from './glue/structs/$EntityTemplate';

import { getAuth, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { Svg } from './components/Svg';
import config from './config';
import { MarketingHome } from './MarketingHome';
import { isElectron } from './etc/isElectron';
import './codaIcons';

import './newFile';
import './newFile.1';
import './MyNotionDocument/ValuePointMount';
import { NavContext, registerRoute, Route as Route2 } from './components/registerRoute';
import { NavStackCont } from './components/QueryViewEditor';
import { addNote } from './components/addNote';
import './glue/structs';

import './setGlobals';
import { setBaseTypes } from './shorthand/formula';
import { types } from './shorthandEditor/types';
import { registerFormulaAccessorHandler, registerFormulaCallHandler, registerFormulaDictKeyEvaluators, registerFormulaObjectRefResolver, registerFormulaPrimiterEvaluators } from './shorthand/registerFormulaAccessorHandler';
import { FormulaObjectWrapper } from './FormulaObjectWrapper';
import { FormulaObjectProxy } from './FormulaObjectProxy';
import { registerContextVar } from './glue/typeRegistering';

import './scriptComponents/index';
import { getAllEdges, pushEdge, theTransientEdges } from './getAllEdges';
import { theTransientEntities } from './etc/createEntity';
import { DevProject, DevProjectRoot, DevProjects } from './DevProject';
import 'react-toastify/dist/ReactToastify.css';
// import { ToastContainer, toast } from 'react-toastify';

setBaseTypes(types);

registerFormulaPrimiterEvaluators({
  test: r => r instanceof FormulaObjectWrapper,
  perform: (r: FormulaObjectWrapper) => r.toPrimitive(),
})


registerFormulaDictKeyEvaluators({
  test: r => r instanceof FormulaObjectWrapper,
  perform: (r: FormulaObjectWrapper) => r.ref.id,
})


registerFormulaCallHandler({
  test: r => r instanceof FormulaObjectWrapper,
  perform: (r: FormulaObjectWrapper, env, args=[]) => {
    return r.call(env, ...args);
  }
})

registerFormulaObjectRefResolver({
  test: () => true,
  perform: (ref, base) => new FormulaObjectWrapper(ref, base),
})

registerFormulaAccessorHandler({
  test: r => r instanceof FormulaObjectWrapper || r instanceof FormulaObjectProxy,
  perform: (r, prop) => r.get(prop),
})

registerContextVar('ba2dd60c-5e65-531e-9824-4fa808e9dd1e', '%Navigation');

document.addEventListener('deviceready', setupOpenwith, false);

declare const cordova: any;

let _inited, _onInit;

function onInit(func) {
  if (_inited) {
    func();
  }
  else {
    _onInit = func;
  }

}

function setupOpenwith() {

  // Increase verbosity if you need more logs
  //cordova.openwith.setVerbosity(cordova.openwith.DEBUG);

  // Initialize the plugin
  cordova.openwith.init(initSuccess, initError);

  function initSuccess()  { console.log('init success!'); }
  function initError(err) { console.log('init failed: ' + err); }

  // Define your file handler
  cordova.openwith.addHandler(myHandler);

    memoryAppState.openWith = '1';


  function myHandler(intent) {
    console.log('intent received');

    console.log('  action: ' + intent.action); // type of action requested by the user
    console.log('  exit: ' + intent.exit); // if true, you should exit the app after processing

    for (var i = 0; i < intent.items.length; ++i) {
      var item = intent.items[i];
      console.log('  type: ', item.type);   // mime type
      console.log('  uri:  ', item.uri);     // uri to the file, probably NOT a web uri

      // some optional additional info
      console.log('  text: ', item.text);   // text to share alongside the item, iOS only
      console.log('  name: ', item.name);   // suggested name of the image, iOS 11+ only
      console.log('  utis: ', item.utis);
      console.log('  path: ', item.path);   // path on the device, generally undefined
    }

    // ...
    // Here, you probably want to do something useful with the data
    // ...
    // An example...

    if (intent.items.length > 0) {

      onInit(() => {
        setTimeout(() => {
          addNote({
            title: '',
            note: [
              XObject.obj({
                data: intent.items[0].data,
                children: [],
              })
            ],
          })

          window.alert('Added');
        }, 5000)

      })
      if (intent.exit) { cordova.openwith.exit(); }

      cordova.openwith.load(intent.items[0], function(data, item) {

        // data is a long base64 string with the content of the file
        // console.log("the item weights " + data.length + " bytes");
        // console.log(item);

        memoryAppState.openWith = JSON.stringify(item);

        // "exit" when done.
        // Note that there is no need to wait for the upload to finish,
        // the app can continue while in background.
        if (intent.exit) { cordova.openwith.exit(); }
      });
    }
    else {
      if (intent.exit) { cordova.openwith.exit(); }
    }
  }
}

Sugar.extend();

function isMobileSignin() {
  return window.document.location.href.indexOf('mobilesignin') !== -1;
}

// const provider = new GoogleAuthProvider();

window['handleOpenURL'] = (url: string)  => {
  // console.log("received url: " + url);
  memoryAppState.fromUrl = url;
  const id = url.slice(url.lastIndexOf('?') + '?user='.length);
  // alert(`${id} ${url}`);
  appState.mobile_firebaseUserId = JSON.parse(window.decodeURIComponent(id));
  window.location.reload();
}

@component
class Button extends Component<{ text, onClick? }> {
  static styles = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 0 auto; */
    /* width: 126px; */
    font-size: 12px;
    height: 36px;
    flex-shrink: 0;
    border-radius: 6px;
    background: rgba(237, 239, 248, 0.80);
    box-shadow: 0px 0px 6px 0px rgba(210, 210, 210, 0.25);
  `;

  render(Container?) {
    return (
      <Container
        onClick={this.props.onClick}
      >
        {this.props.text}
      </Container>
    )
  }
}

@component
class CordovaContinue extends Component {
  render() {
    return (
      <>
        <Svg name="logo" />

        <p>Welcome to ebases</p>

        <Button text="Continue" onClick={() => {
          window.open('https://mobilesignin.ebases.io', '_system');
        }} />
      </>
    )
  }
}

registerInspectObjectHandler('f803b269-1725-57ba-a8c6-dac2fa140e06', {
  render: (args) => {
    let view;
    let columns = [];
    if (args.doc) {
      const page = db.notionDocuments.findById(args.doc);
      view = page.tableData.views.find(x => x._id == args.view);
      columns = page.tableData.columns;
    }
    else if (args.path) {
      const [doc, row] = resolvePath(args.path);
      view = row.tableData.views.find(x => x._id == args.view);
      columns = row.tableData.columns;
    }
    else if (args.table) {
      const table = db.tables.findById(args.table);
      if (table.handle) {
        const handle = db.objectHandles.findById(table.handle);
        view = handle.views.find(x => x._id == args.view);        
      }
      else {
        view = table.views.find(x => x._id == args.view);
      }
      columns = table.columns;
    }
    else if (args.page) {
      const page = db.pages2.findById(args.page);
      const block = page.blocks.find(x => x._id == args.block);
      view = block.views.find(x => x._id == args.view);
      const handle = db.objectHandles.findById(block.handle);
      columns = handle.columns;
    }


    return (
      <div key={args.view}>
        <select
          value={view?.type || ''}
          onChange={e => view.type = e.target.value}
        >
          <option />
          <option value="e81bab60-bdcf-5755-9e85-a1863fbf3a2a">Table</option>
          <option value="c777c657-6257-5cfc-a0f2-7e735bb50d49">Gallery</option>
        </select>

        {/* {view?.type == 'c777c657-6257-5cfc-a0f2-7e735bb50d49' && (
          <>
            <div>
              <select
                value={view.galleryProperty}
                onChange={e => view.galleryProperty = e.target.value}
              >
                <option />
                {columns.map(column => (
                  <option
                    key={column._id}
                    value={column._id}
                  >{column.title}</option>
                ))}
              </select>
            </div>
          </>
        )} */}


      </div>
    )
  }
})


registerInspectObjectHandler('95d1b469-6eff-5bcc-88f9-a53e4377f1bf', {
  render: (args) => {
    let view;
    let columns = [];
    if (args.doc) {
      const page = db.notionDocuments.findById(args.doc);
      view = page.tableData.views.find(x => x._id == args.view);
      columns = page.tableData.columns;
    }
    else if (args.path) {
      const [doc, row] = resolvePath(args.path);
      view = row.tableData.views.find(x => x._id == args.view);
      columns = row.tableData.columns;
    }

    return (
      <div key={args.view}>
        <select
          value={view?.type || ''}
          onChange={e => view.type = e.target.value}
        >
          <option />
          <option value="e81bab60-bdcf-5755-9e85-a1863fbf3a2a">Table</option>
          <option value="c777c657-6257-5cfc-a0f2-7e735bb50d49">Gallery</option>
        </select>

        {view?.type == 'c777c657-6257-5cfc-a0f2-7e735bb50d49' && (
          <>
            <div>
              <select
                value={view.galleryProperty}
                onChange={e => view.galleryProperty = e.target.value}
              >
                <option />
                {columns.map(column => (
                  <option
                    key={column._id}
                    value={column._id}
                  >{column.title}</option>
                ))}
              </select>
            </div>
          </>
        )}


      </div>
    )
  }
})

registerInspectObjectHandler(PaneType.tableRow, {
  render: (object, args) => {
    const [page, row] = resolvePath(object.path);
    return (
      <>
        {!row.tableData && <button
          onClick={() => {
            row.tableData = X({});
          }}
        >Make table</button>}
        

        {row.tableData && (() => {
          const columns = row.tableData.columns;

          return (
            <>
            <button
              onClick={() => {
                delete row.tableData;
              }}
            >Make page</button>
            
            <select
              value={row.tableData.galleryProperty}
              onChange={e => row.tableData.galleryProperty = e.target.value}
            >
              <option />
              {columns?.map?.(column => (
                <option key={column._id} value={column._id}>{column.title}</option>
              ))}
            </select>
    
            </>
          )

        })()}


      </>
    )
  }
})

registerRoute((route, navigation) => {
  if (route[0] == Route2.object) {
    return (
      <>
        <ObjectEditor
          key={route[1].id}
          obj={route[1]}
          onNavObject={obj => {
            navigation.push([Route2.object, obj]);
          }}
        />
      </>
    )
  }
})


registerInspectObjectHandler('e57eb0f2-4a72-5002-b64e-11a7ba64970a', {
  padding: 0,
  render: (props) => {
    return (
      <SystemContext.Provider
        value={{
          navigate: config => {
            appState.panes[0] = initPaneObject(config);
            console.log('asdfadfs');
          }
        }}
      >

        <NavStackCont root={[Route2.object, props.obj]} state={props} />
      </SystemContext.Provider>

    )
  }
});

@component
class ValuePointPane extends Component<{ valuePoint: string }> {
  state = X({
    stack: []
  });
  constructor(props) {
    super(props);
    this.state.stack = [
      XObject.obj({
        id: this.props.valuePoint
      })
    ]
  }
  static styles = styled.div`
    /* background-color: white; */
    ${GlueDev} {
      position: static;

      ${GlueDev.t.panes} {
        position: static;
      }
    }
    display: flex;
    height: 100%;
  `;
  render() {
    return <GlueStack state={this.state} />;
  }
}

registerPaneType('valuePoint', {
  width: 'auto',
  render: props => {
    console.log(props);
    return <ValuePointPane key={props.id} valuePoint={props.id} />
  }
})

registerPaneType('196e909e-5441-5945-adce-8d8658092c82', {
  render: (props) => {

    return (
      <div style={{ background: 'white' }}>
      <SystemContext.Provider value={{
        next: () => {

        },

        navigate: () => {},
      }}>
      <NotionDocumentWindow
        
      window={{
        notionDocument: props.id,
      }}

      />
      </SystemContext.Provider>
      </div>
    )
  }
})

@component
class ValuePointEditor extends Component<{ valuePoint: string, pushStack }> {
  static styles = styled.div`
    /* background-color: white; */
    padding-left: 19px;
  `;
  state = XInit(class {
    active
  })
  render() {
    return (
      <PaneContext.Provider value={{
        active: this.state.active,
        selectValuePoint: id => {
          this.state.active = id;
          this.props.pushStack({
            type: 'valuePoint',
            id,
          })
        },
      }}>
        <RootValue id={this.props.valuePoint} />
      </PaneContext.Provider>
    );
  }
}

registerComponentRenderer(CodeElementType.valuePoint, props => {
  return (
    <>
      <ValuePointEditor valuePoint={props.component._id} pushStack={props.pushStack} />
    </>
  )
});


window['MonacoEnvironment'] = {
  getWorkerUrl: function (moduleId, label) {
    console.log(moduleId, label);
    if (label === 'json') {
      return '/static/js/json.worker.bundle.js';
    }
    if (label === 'css' || label == 'scss') {
      return '/static/js/css.worker.bundle.js';
    }
    if (label === 'html') {
      return '/static/js/html.worker.bundle.js';
    }
    if (label === 'typescript' || label === 'javascript' || label == 'typescriptreact') {
      return '/static/js/ts.worker.bundle.js';
    }
    return '/static/js/editor.worker.bundle.js';
  }
}

@component
export class CodeComponentWindow extends Component<{ window }> {
  editorState: EditorState
  constructor(props) {
    super(props);
    this.editorState = new EditorState(componentSystem);
  }
  static styles = styled.div`
    ${ComponentIDEEditor} {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      /* bottom: 200px; */
      bottom: 0;
    }

    .output {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 200px;
    }

    .run {
      position: absolute;
      z-index: 100;
      top: 0;
      right: 0;
    }
  `;
  render() {
    return (
      <>
        {/* <button
          className="run"
          onClick={async () => {
            console.log(this.props.window.component);
            await pushCode();
            console.log(getCodeComponent(this.props.window.component)());
          }}
        >Run</button> */}

        <ComponentIDEEditor
          componentSystem={componentSystem}
          editorState={this.editorState}
          id={this.props.window.component}
          onClickComponent={(id) => {}}
        />
      </>
    )
  }
}

@component
export class CodeComponentsWindow extends Component<{ window }> {
  render() {
    return (
      <>
        <button
          onClick={() => {
            pushCode();
          }}
        >Deploy</button>
        <ul>
          {db.codeComponents.map(component => {
            return (
              <li
                key={component._id}
              >
                <PropertyField object={component} property="name" /> ({_.invert(CodeComponenType)[component.type]})

                <button
                onClick={() => {
                  openWindow({
                    type: WindowType.CodeComponent,
                    component: component._id,
                  })
                }}
                >View</button>
              </li>
            )
          })}
        </ul>
        <button
          onClick={() => {
            db.codeComponents.push(XObject.obj({
              name: 'New Function',
              type: CodeComponenType.function,
              code:
`function __component__() {

}`
            }));
          }}
        >Function</button>
        <button
          onClick={() => {
            db.codeComponents.push(XObject.obj({
              name: 'New Component',
              type: CodeComponenType.component,
              code:
`class __component__ extends React.Component {
  render() {
    return null;
  } 
}`
            }));
          }}
        >Component</button>
      </>
    )
  }
}
let RootComp;


@component
export class CodeTestWindow extends Component<{ window }> {
  componentSystem: ComponentSystem;
  editoState: EditorState
  constructor(props) {
    super(props);
    this.editoState = new EditorState(this.componentSystem);
  }
  static styles = styled.div`
    ${ComponentIDEEditor} {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 200px;
    }

    .output {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 200px;
    }

    .run {
      position: absolute;
      z-index: 100;
      top: 0;
      right: 0;
    }
  `;
  render() {
    return null;
  }
}

const nav = [
  {title: "ChatGPT Test", nav: {type: WindowType.ChatGPTTest}},
  {title: "Graphs", nav: {type: 'graphs'}},
  {title: "Root", nav: {type: 'root'}},
  {title: "All Graph", nav: {type: WindowType.AllGraph}},
  {title: "Meta States", nav: {type: WindowType.MetaStates}},
  {title: "Linear", nav: {type: WindowType.Linear}},
  {title: "Queues", nav: {type: WindowType.Queues}},
  {title: "Types", nav: {type: WindowType.Types}},
  {title: "Notion", nav: {type: WindowType.Notion}},
  {title: "Org Entities", nav: {type: WindowType.OrgEntities}},
  {title: "Databases", nav: {type: WindowType.Databases}},
  {title: "Documents", nav: {type: WindowType.NotionDocuments}},
  {title: "Glue Dev", nav: {type: WindowType.GlueDev}},
  {title: "Menu Items", nav: {type: WindowType.MenuItems}},
  {title: "System Config", nav: {type: WindowType.SystemConfig}},
  {title: "Users", nav: {type: WindowType.Users}},
  {title: "Inbox", nav: {type: WindowType.Inbox}},
  {title: "Spaces", nav: {type: WindowType.SpacesWindow}},
  {title: "Query Expression Editor", nav: {type: WindowType.QueryExpressionEditor}},

  { title: 'Notion Document Test', nav: { type: WindowType.NotionDocumentTest } },
  { title: 'Code Tetst', nav: { type: WindowType.CodeTest } },
  { title: 'Code Components', nav: { type: WindowType.CodeComponents } },

  { title: 'Data Editor Test Window', nav: { type: WindowType.DataEditorTestWindow } },

  { title: 'Tldraw', nav: { type: WindowType.Whiteboard } },
  { title: 'MindMap', nav: { type: WindowType.MindMap } },

  { title: 'Timeline', nav: { type: WindowType.Formula } },

  { title: 'Widgets', nav: { type: WindowType.Widgets } },
  { title: 'Mobile Home', nav: { type: WindowType.MobileHome } },
  { title: 'Script', nav: { type: WindowType.ScriptWindow } },
  { title: 'Shorthand', nav: { type: WindowType.Shorthand } },
  { title: 'Completions Test', nav: { type: WindowType.CompletionsTest } },
  { title: 'Embedding Test', nav: { type: WindowType.EmbeddingTest } },
  { title: 'WIP', nav: { type: WindowType.Wip } },
]


if (!appState.panes.length) {
  appState.panes.push(XObject.obj<any>());
}

@component
class Prompt extends Component {
  static styles = styled.div`

    .cont {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      background: rgba(0, 0, 0, 0.5);

      z-index: 99999999;

      .prompt {
        background: white;
      }

    }

    
  `;
  render() {
    return (
      <>
        {promptState.current && <div className="cont">
          <div className="prompt">
            {promptState.current.message}
            <input type="text" autoFocus onKeyDown={e => {
              if (e.key === 'Enter') {
                // promptState.current.resolve(e.currentTarget.value);
                promptState.current = null;
                // this.forceUpdate();

                promptData.cb(e.currentTarget.value);
                promptData.cb = null;

              }
            }} />
          </div>
        </div>}
      </>
    )
  }
}

@component
class FullscreenImage extends Component {
  static styles = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;

    z-index: 99999999;

    .img {
      position: absolute;
      top: 16px;
      left: 16px;
      right: 16px;
      bottom: 16px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

    }
  `;
  render(Container?) {
    if (!memoryAppState.image) return null;
    return (
      <Container
        onClick={() => {
          memoryAppState.image = null;
        }}
      >
        {memoryAppState.image && <div className="cont">
          <span className="img" style={{
            backgroundImage: `url(${memoryAppState.image})`,
          }} />
        </div>}
      </Container>
    )
  }
}

@component
class DBStatus extends Component {
  static styles = styled.div`
    color: red;
  `;
  render() {
    return (
      <>
        {!dbStatus.connected && <>Disconnected</>}
        {dbStatus.timedOut && <>Timed Out</>}
      </>
    )
  }
}

let styles;
function updateInspectHighlight() {
  if (styles) {
    styles.parentNode?.removeChild?.(styles);
  }
  if (appState.inspecting?.id) {
    styles = document.createElement('style');
    styles.innerHTML = css`
      /* [data-inspect-id="${appState.inspecting?.id}"] {
        outline: 2px solid #12802d !important;
      } */
    `;
    document.head.appendChild(styles);
  }
}

XObject.observe(appState, 'inspecting', () => {
  updateInspectHighlight();
})
const mobile = '@media (max-width: 768px)'

@component
class Auth extends Component {
  static debounce = false;
  static styles = styled.div`
    max-width: 1300px;
    margin: 0 auto;
    color: rgb(74, 74, 74);

    .logo {
      margin-bottom: 32px;
    }

    ${mobile} {
      max-width: 320px;
    }

    .content {
      max-width: 320px;
      margin: 0 auto;
    }

    .email {
      border-bottom: 1px solid #ccc;
      padding-bottom: 16px;
      margin-bottom: 16px;

      ${Button} {
        margin-top: 16px;
      }
    }

    .field {
      &:not(:first-child) {
        margin-top: 16px;
      }
      .label {
        display: block;
      }
      input {
        width: 100%;
        box-sizing: border-box;

      }
    }
  `;

  state = XInit(class {
    loading = true;
    emailState: 'signin' | 'signup'
    email
    password
    repeatPassword
  });

  render() {
    const finish = () => {
      if (!isMobileSignin()) {
        localStorage.clear();
        document.location.href = '/'  
      }
    }
    const ref = React.createRef<any>();
    const continueEmail = async () => {
      const accountExists = (await axios.get(`${config.apiServer}lookup-user?email=${window.encodeURIComponent(ref.current.value)}`)).data;
      if (accountExists) {
        this.state.emailState = 'signin';
      }
      else {
        this.state.emailState = 'signup';
      }
    }

    return (
      <>
        <div className="logo">
          <Svg name="logo" />
        </div>
        <div className="content">
          <div className="email">
            <div className="field">
              <span className="label">Email</span>
              <input
                type="text"
                name="email"
                ref={ref}
                value={this.state.email || ''}
                onChange={e => {
                  this.state.email = e.currentTarget.value;
                }}
                onKeyDown={e => {
                  if (e.key == 'Enter') {
                    continueEmail();
                  }
                }}
              />
            </div>

            {!this.state.emailState && (
              <>
                <Button text="Continue with email"
                  onClick={async () => {
                    continueEmail();
                  }}
                />
              </>
            )}

            {this.state.emailState == 'signin' && (
              () => {
                const signin = async () => {
                  try {
                    await signInWithEmailAndPassword(getAuth(), this.state.email, this.state.password);
                    finish();
                  }
                  catch (e) {
                    console.error(e);
                    alert('Invalid email or password')
                  }
                }
                return (
                  <>
                <div className="field">
                  <span className="label" >Password</span>
                  <input name="password" autoFocus type="password" value={this.state.password || ''} onChange={e => this.state.password = e.target.value}
                    onKeyDown={async e => {
                      if (e.key == 'Enter') {
                        signin();
                      }
                    }}
                  />
                </div>

                <Button text="Sign in" onClick={async () => {
                  signin();


                }} />
              </>
                )
              }
            )()}

            {this.state.emailState == 'signup' && (
              () => {
                const submit = async () => {
                  if (this.state.password != this.state.repeatPassword) {
                    alert('Passwords do not match');
                    return;
                  }
                  const r = await createUserWithEmailAndPassword(getAuth(), this.state.email, this.state.password);
                  if (r) {
                    finish();
                  }
                }
                const keydown = e => {
                  if (e.key == 'Enter') {
                    submit();
                  }
                }
                return (
                  <>
                <div className="field">
                  <span className="label">Password</span>
                  <input name="password" autoFocus type="password" value={this.state.password || ''} onChange={e => this.state.password = e.target.value} onKeyDown={keydown} />
                </div>
                <div className="field">
                  <span className="label">Repeat Password</span>
                  <input name="repeatPassword" type="password" value={this.state.repeatPassword || ''} onChange={e => this.state.repeatPassword = e.target.value} onKeyDown={keydown} />
                </div>
                <Button text="Sign up" onClick={async () => {
                  submit()
                }} />
              </>
                )
              }
            )()}
          </div>
          <GoogleLoginButton
            text="Continue with Google"
            onClick={async () => {
              const auth = getAuth();
              try {
                await signInWithPopup(auth, new GoogleAuthProvider())
                finish();
              }
              catch (e) {
                console.log(e);
              }
            }}
          />
        </div>
      </>
    );
  }
}

@component
class Website extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MarketingHome />} />
          <Route path="/signin" element={<Auth />} />
          <Route path="/signup" element={<Auth />} />
        </Routes>
      </BrowserRouter>
    )
  }
}


const protoBuilder = true;

const mode: any = 'devProject';
// const mode = 'devProject';

const local = true;


window.onerror = function(message, source, lineno, colno, error) {
  if (message.toString().includes('ResizeObserver')) {
    document.body.className = 'hideErrorOverlay';
  }
  else {
    document.body.className = '';
  }
};


@component
class App extends Component {
  static styles = styled.div`
    /* font-family: "Inter UI", "SF Pro Display", -apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; */
    /* font-family: ui-sans-serif,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,"Apple Color Emoji",Arial,sans-serif,"Segoe UI Emoji","Segoe UI Symbol"; */
    /* -webkit-font-smoothing: auto; */
    /* position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; */
    /* color: #4a4a4a; */
    /* font-size: 12px; */


    /* font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    font-weight: 400; */

    .Toastify__toast-container  {
      z-index: 999999999;
    }

    ${DBStatus} {
      position: fixed;
      bottom: 4px;
      right: 4px;
      z-index: 99999999999;
      font-size: 8px;
    }

    .inspecting {
      outline: 2px solid #12802d;
    }
    ${OS} {
      .mainContent {
      }
    }
    .editable-value {
      position: relative;

      &.empty {
        cursor: text;

        .editable-value__display {
          width: 4px;
          display: inline-block;
        }
      }
      &:hover {
        .editable-value__edit {
          pointer-events: all;
          opacity: 1;
          transform: scale(1);
        }
      }

      &.pre {
        .editable-value__edit  {
          right: 100%;
        }
      }

      .editable-value__edit {
        transform: scale(.9);
        transition: all 100ms linear;
        position: absolute;
        // left: calc(100%);
        opacity: 0;
        pointer-events: none;
        // top: 0;
        bottom: 0;
        margin: auto;
        z-index: 1000;
        margin-left: 0 !important;
        height: 16px;
      }
    }

    > .left {
      width: 500px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border-right: 1px solid #2c2d3c;
      box-sizing: border-box;

      > .select {
        position: absolute;
        z-index: 99999;
      }

    }

    > .right {
      position: absolute;
      top: 0;
      left: 500px;
      right: 0;
      bottom: 0;
    }

    ${BlockPageCont} {
      margin-bottom: 8px;
    }





    .notionDoc {
      position: absolute;
      top: ${topBarHeight}px;
      left: ${defaultSidebarWidth}px;
      right: 0;
      bottom: 0;
    }

    ${AppRoot} {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .glueSideBar {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 50%;
      border-left: 1px solid #2c2d3c;

      .closeGlueSideBar {
        z-index: 999999;
        position: absolute;
        top: 0;
        right: 0;
        --webkit-app-region: no-drag;
      }
    }

    &.showGlue {
      ${AppRoot} {
        right: 50%;
      }
    }


  `;

  componentDidCatch(error, errorInfo) {
    // Log the error or perform any necessary error handling
    console.log(error, errorInfo);
  }

  constructor(props) {
    super(props);
    setOsKey(mode == 'devProject' ? 'devProjectOs' : 'os');

    if (resumeMode.enabled) {
      if (!appState.panes?.length || !appState.panes[0].stack?.length || appState.panes[0].stack?.length == 1 && isEmptyPane(appState.panes[0].stack[0])) {
        appState.panes = X([{"viewType":"stack","pointer":0,"stack":[{"type":"page","id":"646cf264694d7e70c6d262cc","_id":"6484fae87424f6ab581a7636","state":{"notionDocument":"646cf264694d7e70c6d262cc"}}],"_id":"6484fae8ab07d744adccb934"}]);
      }

    }
    // else {
    //   if (isMobile() && (!appState.panes?.length || !appState.panes[0].stack?.length || appState.panes[0].stack?.length == 1 && isEmptyPane(appState.panes[0].stack[0]))) {
    //     memoryAppState.showSidebar = true;
    //   }
    // }

    // appState.leftSidebar = false;

    init();
  }

  state = XInit(class {
    loaded = false;
    state: any = {}
    docState = {}
    drillDownState: any = {}
  });




  async componentDidMount() {
    if (protoBuilder && local) {

      this.state.loaded = true;
      this.forceUpdate();
    }
    else {
      if (resumeMode.enabled) {
        document.title = 'Jonathan Cook';
        const data = (await axios.get('https://jonathan-cook-portfolio.s3.amazonaws.com/data.json')).data;
        initLocalDb(data);
        appState.currentMode = resumeMode.mode;
      }
      else {
        memoryAppState.firebaseUserId = 'Nq0NQbiO8YQCPARONHw8ck77hv22'
        this.init({
          uid: 'Nq0NQbiO8YQCPARONHw8ck77hv22',
        })
        return;
        if (localStorage.getItem('firebaseUserId')) {
          memoryAppState.firebaseUserId = localStorage.getItem('firebaseUserId');
          this.init({
            uid: memoryAppState.firebaseUserId,
          })
  
        }
        else if (isCordova()) {
          memoryAppState.firebaseUserId = appState.mobile_firebaseUserId || false;
          if (memoryAppState.firebaseUserId) {
            this.init({
              uid: memoryAppState.firebaseUserId.uid,
              email: memoryAppState.firebaseUserId.email,
              name: memoryAppState.firebaseUserId.name,
            })
          }
        }
        else if (isElectron()) {
          this.init({
            uid: 'Nq0NQbiO8YQCPARONHw8ck77hv22',
          })
        }
        else {
  
          const initFirebase = () => {
            const firebaseConfig = {
              apiKey: "AIzaSyAzaSeF_Vr0XyIHaXhSMQ83a6PBVB9Hx00",
              authDomain: "ebases-762ff.firebaseapp.com",
              projectId: "ebases-762ff",
              storageBucket: "ebases-762ff.appspot.com",
              messagingSenderId: "996160920232",
              appId: "1:996160920232:web:d53a1ffdb7779ebfbb809d",
              measurementId: "G-2KH6WZX8EY"
            };
            
            initializeApp(firebaseConfig);
      
            getAuth().onAuthStateChanged((user) => {
              if (isMobileSignin()) {
                if (user) {
                  getAuth().signOut();
                  window.location.href = `ebases://?user=${window.encodeURIComponent(JSON.stringify({
                    uid: user.uid,
                    email: user.email,
                    name: user.displayName,
                  }))}`
                }
              }
              else {
                if (!user) {
                  memoryAppState.firebaseUserId = false;
                }
                else if (memoryAppState.firebaseUserId !== user.uid) {
                  memoryAppState.firebaseUserId = user.uid;
                  this.init({
                    uid: user.uid,
                    email: user.email,
                    name: user.displayName,
                  });
                }  
              }
            }); 
      
          }
          initFirebase();  
        }     
      }
    }
  }

  initCollection

  async init(user: {
    uid
    email?
    name?
  }) {
    console.log(user.uid);
    const { initCollection } = await initDb([
      'users',
      'documents',
      // 'entities',
      'edges',
      'entityTypes',
      'stateTypes',
      'attributeTypes',

      'workspaces',
      'dataObjects',
      'orgEntities',
      'databases',
      'notionDocuments',
      'statementTypes',
      'parameters',
      'statements',

      'spaces',

      'sidebar',
      'queries',
      'libraries',
      'elements',
      'pages',
      'modes',

      'codeComponents',

      'canvases',
      'tags',

      'events',
      'parameterizedEvents',
      'eventOccurrences',

      'days',
      'notes',

      'pages2',

      'apps',


      'tables',
      'tableSchemas',
      'tableViews',

      'objectHandles',

      'formulas',

      'identifiers',

      'dataSlots',

      'dashboards',
      'widgets',

      'workspaces',
    ], {
      userInfo: user,
    });

    const userId = db.users[0]._id;
    if (appState.user && appState.user != userId) {
      localStorage.removeItem('state1');
      localStorage.removeItem('os');
      window.location.reload();
      return;
    }

    if (!appState.user) {
      appState.user = userId;
    }

    if (!appState.currentMode) {
      appState.currentMode = db.modes[0]?._id;
    }

    await initCollection('entities', { 'space.id': appState.currentMode });
    this.initCollection = initCollection;

    window['g_initCollection'] = initCollection;

    memoryAppState.user = db.users[0]._id;

    const workspace = defaultWorkspace();

    if (!workspace.entityTemplateSupplier) {
      const entityTemplateSupplier = XObject.obj({
        type: [ ValueType.Structure, $EntityTemplateSupplier.$ ],
      });
      values().push(entityTemplateSupplier);
      workspace.entityTemplateSupplier = entityTemplateSupplier._id;
    }

    // await chatGptManager.init();

    // await initEditor(componentSystem);
    // await initTextMate();

    // await pushCode();

    initBacklinkCache();

    indexValuePoints();

    // XObject.observe(db.edges, () => {
    //   indexEdges();
    // });
    // indexEdges();

    // if (!db.appComponents.length) {
    //   db.appComponents.push(XObject.obj({
    //     name: 'Root',
    //   }));
    // }

    setTimeout(() => {
      updateInspectHighlight();

    }, 1000);

    for (const doc of db.notionDocuments) {

      if (doc.createsEdges) {
        const update = () => {
          const collectEntitiesGood = blocks => {
            const entities: {
              entity
              block
              parent
            }[] = [];
            const _collectEntities = (blocks, parent) => {
              for (const block of blocks) {
                if (block.id) {
                  entities.push({ block: block._id, entity: block.id, parent });
                }
                /*if (block.data?.filter) {
                  const r = block.data.filter(e => e?.[1] == 'entity');
                  for (const e of r) {
                    entities.push({ block: block._id, entity: e[0], parent });
                  }
                  // if (r) {
                  //   entities.push({ block: block._id, entity: r[0] });
                  // }
                }*/
                if (block.children) {
                  _collectEntities(block.children, block.id || parent);
                }
              }
            };
            _collectEntities(blocks, null);
          
            return entities;
          };
          
          const entities = collectEntitiesGood(doc.blocks);
  
          const edges = [];
          for (const entity of entities) {
            const key = JSON.stringify(['fc4a8f4f-aa90-50e2-bcee-e48debc77ab8', doc._id, entity.block ]);
            edges.push({
              entities: [ entity.parent || doc.parent?.id, entity.entity ],
              directed: true,
              sync: ['fc4a8f4f-aa90-50e2-bcee-e48debc77ab8', doc._id, entity.block ],
              key
            });
          }
  
          for (const edgeDef of edges) {
            const existingEdge = getAllEdges().find(e => e.key == edgeDef.key);
            if (!existingEdge) {
              pushEdge(XObject.obj(edgeDef));
            }
            else {
              if (!_.isEqual(x(existingEdge.entities), x(edgeDef.entities))) {
                existingEdge.entities = edgeDef.entities;
              }
            }
          }
  
          const allExistingEdges = getAllEdges().filter(e => e.sync?.[0] == 'fc4a8f4f-aa90-50e2-bcee-e48debc77ab8' && e.sync?.[1] == doc._id);
          for (const existingEdge of allExistingEdges) {
            if (!edges.find(e => e.key == existingEdge.key)) {
              deleteEdge(existingEdge._id);
            }
          }

        }

        update();

        let timerId;
        XObject.observe(doc, 'blocks', () => {
          clearTimeout(timerId);
          timerId = setTimeout(() => {
            update();
          }, 1000);
        });
      }

      if (doc.transientEntities) {
        const transientEntities = [];
        const process = (blocks, parent?) => {
          for (const block of blocks) {
            transientEntities.push({
              _id: block._id,
              name: block.data,
              type: doc.transientEntityType,
            });
            if (parent) {
              theTransientEdges.push({
                _id: block._id,
                entities: [parent._id, block._id],
                directed: true,
              })
            }
            if (block.children?.length) {
              process(block.children, block);
            }
          }
        }
        process(doc.blocks);

        console.log(transientEntities);
        theTransientEntities.push(...transientEntities);
      }
    }

    this.state.loaded = true;

    _inited = true;
    _onInit?.();
    _onInit = null;

    indexEdges();

  }

  signedIn() {
    return protoBuilder || isElectron() || memoryAppState.firebaseUserId || localStorage.getItem('firebaseUserId');
  }

  render(Container?) {

    if (!this.signedIn()) {
      if (isMobileSignin()) return <Auth />;
      if (_.isNil(memoryAppState.firebaseUserId)) {
        return '';
      }
      if (memoryAppState.firebaseUserId === false) {
        if (isCordova()) return <CordovaContinue />;
        else return <Website />
      }  
    }
    if (!this.state.loaded) return 'Loading...';


    // return <CSSDesigner />;
    if (protoBuilder) return (
      <Container>
        <DevProjectRoot />
      </Container>
    );


    return (
      <Container
        className={classNames({
          showInspectBar: appState.rightSidebar,
          showGlue: appState.glue,
        })}
      >
        <DBStatus />
        <BrowserRouter>
          <ThemeProvider theme={{
            mode: themeState.darkMode ? 'dark' : 'light',
          }}>
            <SystemContext.Provider value={{
              // openWindow: () => {

              // }
              navigate: () => {},
              next: () => {},

              
            }}>
              {isMobile() && (
                <Routes>
                  <Route path="*"
                    Component={() => {
                      const args = useParams();
                      return <MobileRoot pathArg={args['*']} />
                    }}
                  />
                </Routes>
              )}
              {!isMobile() && (
                <UIWrapper>
                  <DebugWrapper>
                    <OS
                      showBar
                      windowTypes={windowTypes(this)}
                      menu={nav.map((n, i) => {
                        return <li key={i} onClick={() => {
                          openWindow(n.nav)
                        }}>{n.title}</li>
                      })}
                    >
                      <AppRoot />
                      {appState.glue && (
                        <div className="glueSideBar">
                          <button
                            className="closeGlueSideBar"
                            onClick={() => {
                              delete appState.glue;
                            }}
                          >X</button>
                          <SystemContext.Provider value={{
                            navigate: config => {
                              inspectFromNav(config, appState.panes.length);
                              appState.panes.push(initPaneObject(config));
                            }
                          }}>
                            <GlueDev state={appState.glue} />
                          </SystemContext.Provider>
                        </div>
                      )}
                    </OS>
                  </DebugWrapper>
                </UIWrapper>
              )}
            </SystemContext.Provider>
            <Prompt />
          </ThemeProvider>
        </BrowserRouter>
        <FullscreenImage />
        {/* <ToastContainer /> */}

      </Container>
    );
  }
}

export default App;
