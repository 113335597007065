import { Component } from 'react';
import { component } from './component';
import stringSimilarity from 'string-similarity';
import { XInit } from './XObject';

@component
export class EmbeddingTest extends Component {
  state = XInit(class {
    str1
    str2
  })
  render() {
    return (
      <>
        <input type="text" defaultValue={this.state.str1} onChange={e => this.state.str1 = e.target.value} />
        <input type="text" defaultValue={this.state.str2} onChange={e => this.state.str2 = e.target.value} />
        <button
          onClick={async () => {
            console.log(stringSimilarity.compareTwoStrings(this.state.str1, this.state.str2));
          }}
        >.</button>
      </>
    )
  }
}
