import ReactDOM from "react-dom";
import ReactDOMClient from "react-dom/client";
import cx from 'classnames';
import jQuery from 'jquery';
import { Data, expandToHtml, getExpandedPositionInDataEditor, getPositionInDataEditor, isInDataEditor } from "../richTextHelpers";
import { x } from "../XObject";
import { component } from "../component";
import { Component } from "react";
import { styled } from "../component";
import { resolveOffset } from "../components/notionDocument/resolveOffset";
import _ from 'lodash';

export const process = (el: HTMLElement, highlighter) => {
  let child = el.firstChild;
  while (child) {
    const next = child.nextSibling;
    if (child instanceof Text) {
      highlighter(child);
    }
    else if (child instanceof HTMLElement) {
      if (!child.getAttribute('data-type')) {
        process(child, highlighter);
      }
    }
    child = next;
  }
}

@component
export class RenderData extends Component<{
  placeholder?;
  className?;
  ctx;
  data: Data | (() => Data);
  attrs?;
  tag?;
  args?;
  highlighter?

  noUpdate?

  __reactive?
}> {
  lastContent
  selectionHandler
  prevCaretPos = null;

  // static reactive = false;

  componentDidMount() {
    this.doMount();
    const el = ReactDOM.findDOMNode(this);
    this.timerId = setInterval(() => {
      if (this.lastContent != el.textContent) {
        this.lastContent = el.textContent;
        this.updateHighlight(getExpandedPositionInDataEditor(this.props.ctx, ReactDOM.findDOMNode(this)));
      }
    }, 1);

    this.selectionHandler = (e) => {
      const pos = isInDataEditor(ReactDOM.findDOMNode(this)) ? getPositionInDataEditor(ReactDOM.findDOMNode(this), this.props.ctx) : null;

      if (this.prevCaretPos !== pos) {
        const expandedPos = pos === null ? null : getExpandedPositionInDataEditor(this.props.ctx, ReactDOM.findDOMNode(this));
        this.prevCaretPos = pos;
        this.updateHighlight(expandedPos);
      }
    }
    this.selectionHandler();
    this.prevCaretPos = getPositionInDataEditor(ReactDOM.findDOMNode(this), this.props.ctx);
    document.addEventListener('selectionchange', this.selectionHandler);
  }

  componentWillUnmount(): void {
    document.removeEventListener('selectionchange', this.selectionHandler);
    clearInterval(this.timerId);

    setTimeout(() => {
      for (const root of this.roots) {
        root.unmount();
      }
    }, 0);
  }

  timerId

  mounted = false;
  comps: any = {};

  roots = [];

  doMount() {
    // return;

    if (!this.mounted) {
      if (this.roots.length) {
        throw new Error();
      }
      const el = ReactDOM.findDOMNode(this);

      for (const key in this.comps) {
        const e = jQuery(el).find(`[data-mount-point="${key}"]`);
        if (!e.length) {
        }
        else {
          if (this.comps[key].mount) {
            const root = ReactDOMClient.createRoot(e[0]);
            root.render(this.comps[key].mount);
            this.roots.push(root);
            setTimeout(() => {
              e.removeAttr('data-unmounted');
            }, 0)
          }
          else {
            e.replaceWith(this.comps[key].render());
          }
        }
      }

      const last = el.lastChild as any;
      // console.log(last);
      // if (last?.getAttribute?.('contenteditable') === 'false') {
        // console.log('insert blank');
        if (last) {
          last.after(document.createTextNode(''));

        }
        else if (el.appendChild) {
          // el.appendChild(document.createTextNode(''));
        }
      // }

      this.mounted = true;
    }
  }

  static styles = styled.span`
    &.placeholder {
      &:empty::before {
        content: '―';
      }

      &:empty:focus::before {
        content: "";
      }
    }
  `;

  componentDidUpdate(prevProps: Readonly<{ ctx: any; data: any; }>, prevState: Readonly<{}>, snapshot?: any): void {
    const roots = this.roots;
    setTimeout(() => {
      for (const root of roots) {
        root.unmount();
      }
  
    }, 0);

    this.roots = [];
    this.mounted = false;

    this.doMount();
  }

  highlight = true;

  doHighlight(pos) {
    this.props.highlighter(ReactDOM.findDOMNode(this), pos);
  }

  stripHighlight() {
    const process = (el: HTMLElement) => {
      let child = el.firstChild;
      while (child) {
        const next = child.nextSibling;
        if (child instanceof HTMLElement) {
          if (child.getAttribute('data-presentation')) {
            if ((child.firstChild as any)?.getAttribute?.('data-type')) {
              child.replaceWith(child.firstChild);
            }
            else {
              const textNode = document.createTextNode(child.textContent);
              child.replaceWith(textNode);

            }
          }
          if (!child.getAttribute('data-type')) {
            process(child);
          }
        }

        child = next;
      }
    }

    process(ReactDOM.findDOMNode(this) as any);
    ReactDOM.findDOMNode(this).normalize();
  }
  

  updateHighlight(expandedPos) {
    if (!this.props.highlighter) return;
    const pos = getPositionInDataEditor(ReactDOM.findDOMNode(this), this.props.ctx);
    this.stripHighlight();
    this.doHighlight(expandedPos);
    if (!_.isNil(pos) && pos >= 0) {
      const offset = resolveOffset(this.props.ctx, ReactDOM.findDOMNode(this), pos);
      if (_.isNil(offset[0]) && _.isNil(offset[1])) {
      }
      else {
        window.getSelection().setBaseAndExtent(offset[0], offset[1], offset[0], offset[1]);
      }
    }
  }


  comp

  shouldComponentUpdate(nextProps: Readonly<{ placeholder?: any; className?: any; ctx: any; data: Data | (() => Data); attrs?: any; tag?: any; args?: any; highlighter?: any; }>, nextState: Readonly<{}>, nextContext: any): boolean {
    
    // for (const root of this.roots) {
    //   root.unmount();
    // }

    // this.roots = [];
    // this.mounted = false;




    return true;
  }

  overwrite
  render(Container?) {
    this.mounted = false;
    this.comps = {};

    const data = (_.isFunction(this.props.data) ? this.props.data() : this.props.data);

    const html = expandToHtml(this.props.ctx, (_.isFunction(this.props.data) ? x(this.props.data()) : x(this.props.data)) || [], this.comps, this.props.args, text => {
      return text;
    }, data);


    return (
      <Container
        key={html}
        data-html={html}
        as={this.props.tag}
        className={cx(this.props.className, {
          placeholder: this.props.placeholder
        })}
        {...(this.props.attrs || {})}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  }
}
