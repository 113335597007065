import React, { Component } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import styled from 'styled-components';
import { component } from '../../component2';
import { DraftSelect } from '../../etc/draftHelpers';
import { x, XObject } from '../../XObject';
import { showContextMenu } from '../../helpers';
import { ValuePoint } from '../ValuePoint';
import { Comp } from '../Comp';
import {
  _findValue,
  _iterate,
  _matches,
  addValuePoint,
  CompiledValuePoint,
  execute,
  findValue,
  getScopeForValuePoint,
  getValuePoint,
  PaneContext,
  ReferenceType,
  resolveTypeList,
  RootValue,
  ValuePointProps,
  ValuePointType
} from '../main';
import { Runtime } from "../Runtime";
import { registerType } from "../__typeRegistry";
import { ValueType } from "../ValueType";
import { identColor, indentWidth, propColor } from '../borderColor';
import { openWindow } from '../../osHelpers';
import { WindowType } from '../../etc/WindowType';
import { registerTypeRegister } from '../typeRegistering';


@component
export class ValueRefValuePoint extends Comp<ValuePointProps> {
  static styles = styled.div`
    &.compact {
      display: inline;
      > ul {
        display: inline;
        list-style-type: none;
        margin: 0;
        padding: 0;
        > li {
          display: inline;
          &:not(:last-child) {
            &:after {
              content: ', ';
            }
          }
        }
      }
    }
    &:not(.compact) {
      > ul {
        list-style-type: none;
        padding-left: ${indentWidth};
      }
    }
  `;
  render(Container?) {
    const value = getValuePoint(this.props.state.content.id);
    if (value.parameters?.length) {
      const args = XObject.get(this.props.state.content, 'arguments', []);
      return (
        <Container className={cx({
          compact: this.props.state.presentation?.compact,
        })}>
          <span style={{ color: propColor }}>{value.name}</span>{'('}
          <ul>
            {args.map((arg, i) => {
              const p = value.parameters.find(p => p._id == arg.param);
              return (
                <li key={arg._id}><span
                  onContextMenu={e => {
                    e.preventDefault();
                    showContextMenu(e, [
                      {
                        text: 'Remove',
                        onClick: () => {
                          args.splice(i, 1);
                        },
                      }
                    ]);
                  }}
                ><span
                  style={{
                    color: identColor,
                  }}
                >{p?.templateParam && '*'}{p?.name || '???'}</span></span> := <ValuePoint id={arg.value._id} /></li>
              );
            })}
            {args.length < value.parameters.length && <li>
              <DraftSelect
                id={this.props.elId}
                options={value.parameters.filter(p => !args.find(a => a.param == p._id)).map((param) => ({
                  display: param.name,
                  value: param,
                }))}
                onSelect={(param) => {
                  args.push(XObject.obj({
                    param: param._id,
                    value: addValuePoint(XObject.obj({
                      possibleTypes: resolveTypeList(x(param.type)),
                      parent: this.props.state._id,
                    })),
                  }));
                }} />
            </li>}
          </ul>
          {')'}
        </Container>
      );
    }
    else {
      return <Container className={'compact'}>{value.name}</Container>;
    }
  }
}

export const registerValueType = () => {
  function executeValueReference(value: ValuePointType, rt: Runtime): CompiledValuePoint {
    const memory: { [key: string]: string; } = {};
    if (value.content.arguments) {
      for (const arg of value.content.arguments) {
        memory[arg.param] = arg.value._id;
      }
    }

    // console.log('executeValueRef', value._id, value.content.id, memory);

    const r = execute(value.content.id, _.isEmpty(memory) ? rt : rt.pushMemory(memory));
    if (value.presentation?.debug) {
      console.log('valueRef', x(value), x(getValuePoint(value.content.id)), x(r));

    }
    return r;
    
  }


  @component
  class Sidebar extends Component<{ state }> {
    render() {
      const { state } = this.props;
      return (
        <>

          <RootValue id={state?.content?.id} />

        {/* <button
        onClick={() => {
          openWindow({
            type: WindowType.GlueDev,
            valuePoint: state.content.id,
          })
        }}
      >Edit</button> */}
        </>
      )
    }
  }

  registerType(ValueType.Value, {
    execute: (value, rt) => {
      return executeValueReference(value, rt);
    },
    isBlock: value => {
      if (value.presentation?.compact) return false;
      const v = getValuePoint(value.content.id);
      if (v.parameters?.length) return true;
    },
    scope: (value, parentValue) => {
      let scope = [];

      const arg = parentValue.content.arguments.find(a => a.value?._id == value._id);

      const reffedValue = getValuePoint(parentValue.content.id);

      const param = reffedValue.parameters.find(p => p._id == arg.param);

      if (param.templateParam) {
        const r = findValue(reffedValue._id, {
          type: [ValueType.Param],
          content: param._id,
        }) || findValue(reffedValue._id, {
          type: [ValueType.Param],
          content: { id: param._id },
        });

        if (r) {
          scope = scope.concat(getScopeForValuePoint(r._id));
        }
      }

      if (param.parameters) {
        // console.log('coolio',x(param.parameters), param.parameters.map(p => ({ type: ReferenceType.Parameter, id: p._id })));
        scope = scope.concat(param.parameters.map(p => ({ type: ReferenceType.Parameter, id: p._id })));
      }

      return scope;
    },
    findValue: (value, pattern) => {
      if (value.content.arguments) {
        for (const arg of value.content.arguments) {
          if (_matches(arg.value, pattern)) {
            return arg;
          } else {
            const r = _findValue(arg.value, pattern);
            if (r) return r;
          }
        }
      }
    },
    iterate: (value, parent, func, set) => {
      if (value.content?.arguments?.length) {
        const list = value.content.arguments || [];
        for (let i = 0; i < list.length; i++) {
          const r = _iterate(list[i].value, value, func, value => {
            list[i].value = value;
          });
          if (r !== undefined) return r;
        }
      }
    },
    editorComp: ValueRefValuePoint,
    sideBarComp: Sidebar,
  });
};

registerTypeRegister(registerValueType);