
export function getScopeAttrs(props) {
  const scopeKeys = Object.keys(props).filter(prop => prop.startsWith('data-scope-'));

  const scopeAttrs = {};
  for (const key of scopeKeys) {
    scopeAttrs[key] = props[key];
  }

  return scopeAttrs;

}
