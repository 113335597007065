import jQuery from 'jquery';


export class StyleManager {
  el;
  setStyles(css?) {
    if (this.el) jQuery(this.el).remove();
    this.el = null;

    if (css) {
      const style = document.createElement('style');
      style.innerHTML = css;
      document.head.appendChild(style);
      this.el = style;
    }

  }

  cleanup() {
    jQuery(this.el).remove();
  }
}
