import React, { Component } from "react";
import _ from 'lodash';
import ReactDOM from 'react-dom';
import jQuery from 'jquery';
import md5 from 'md5';
import cx from 'classnames';
import { component, styled } from "../component";
import { concatData, dataLength, expandToText, extractFromEl, getExpandedPositionInDataEditor, sliceData } from "../richTextHelpers";
import { X, XClone, XInit, XObject, x } from "../XObject";
import { MyBlock, MyBlockManager } from "./MyBlockManager";
import { db } from "../db";
import { createEntity, getAllEntities, getEntityById } from "../etc/createEntity";
import { ObjectRef, ObjectType } from "../types/ObjectRef";
import { attributesInScope, typesInScopes } from "../components/objectFuncs";
import { Wrapper, renderBlock } from './renderBlock2';
import { SystemContext, SystemContextProps } from "../etc/SystemContext";
import { Block } from "../components/notionDocument/BlockManager";
import { componentSystem } from "../componentSystem";
import { appState, setAppInspect } from "../etc/appState";
import { InspectState } from "../components/InspectState";
import { showPrompt } from "../etc/showPrompt";
import { resumeMode } from "../resumeMode";
import { ObjectDisplay } from '../components/ObjectDisplay';
import { Tag } from "../components/Tag";
import { BlockType } from "./BlockType";
import { ControlType } from "./ControlType";
import { createRootValuePoint } from "../glue/main";
import { NotionDocument2 } from "../components/notionDocument/NotionDocument2";
import { MenuPresenter } from "../components/richTextEditor/MenuPresenter";
import { flexGrammar, grammar, renderEl } from "../shorthand/formula";
import { expandFormula } from '../shorthand/formula';
import { presentObjectMenu } from '../shorthandEditor/presentObjectMenu';
import { types } from '../shorthandEditor/types';
import { formulaStyles } from '../shorthandEditor/formulaStyles';
import { executeScriptFromData } from "../shorthand/executeScriptFromData";
import { getParts, highlight, identifierCategory, identifierTypes } from "./highlight";
import { unwrapCst } from "./unwrapCst";
import { getCst } from "./getCst";
import { computeStyles } from "../computeStyles";
import { ScriptWrapper } from "./ScriptWrapper";
import { getBlock, getParentBlock, getVarType, getVarName, getScope, getType, FunctionType, globals } from "./getBlock";
import { buildModel } from "./getBlock";
import { scriptBlockDiagInfo } from "../shorthand/scriptBlockDiagInfo";



@component
class ShowEntity extends Component<{ id }> {
  static styles = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    ${Tag} {
      margin-left: auto;
      flex: 0 0 auto;
    }

    ${ObjectDisplay} {
      white-space: nowrap;
      flex: 1 1 auto;
      /* width: 0; */
      overflow: hidden;
    }

  `;  
  render() {
    const entity = getEntityById(this.props.id);
    const type = entity.type && db.entityTypes.findById(entity.type);
    return <><ObjectDisplay obj={{ type: ObjectType.entity, id: this.props.id }} showPath /> {type?.name && <Tag text={type.name} />}</>
  }
} 


let copiedBlock;

@component
export class ScriptEditor extends Component<{
  rootBlock?
  scriptWrapper?: ScriptWrapper
  obj?: ObjectRef;
  title?;
  setTitle?;
  baseEntity?;
  extendEntity?;
  docId?;
  insidePositionContext?
  tick?
  scopes?
  onBlockSelected?(id: string)
  renderBlockArgs?
  run?
  additionalTypes?
  additionalMenuIniters?
  parent?
  debugPane?
  state?
  highlightBlock?

  onDeleted?

  client?

  objectRefTypeResolver?

  contextMenuItems?

  globals?

  onClickEntity?

  autoCompleteEntries?

  disableAutoComplete?

  blockMeta?
}> {
  static styles = styled.div`
    [data-identifier-id="undefined"] {
      border-bottom: 1px dashed red;
    }

    .bracketHighlight {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 1px;
        left: 0;
        right: 1px;
        bottom: 0;
        pointer-events: none;
        border: 1px solid #9f9f9f;

      }
      &.left {
        &:before {
          left: 2px;
          right: -2px;
        }
      }
      &.right {
        &:before {
          left: -1px;
        }
      }

    }

    ${NotionDocument2} {
      padding-bottom: 0;
      ${formulaStyles}
      .editor {
        white-space: nowrap;
      }
      .children {
        border-left: 1px solid #d7d7d7;
        &:hover {
          border-left-color: #b0b0b0;
        }
      }

      ${Wrapper} .children {
        padding-left: 16px;
      }

      ${Wrapper} > .block {
        font-size: 12px;
        min-height: 0;
        > .grip {
          left: -16px;
          top: 6px;
          height: 11px;
        }
        .editor {
          min-height: 18px;
        }
      }
    }

    &.debugPane {
      > .left {
        > ${NotionDocument2} {
      }

      position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 50%;


        overflow: auto;

      }

      > .right {
        box-sizing: border-box;
        padding: 16px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 50%;
        border-left: 1px solid black;

        > .elEditor {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 200px;
          border-top: 1px solid black;

          > .editor {
            position: absolute;
            top: 0;
            left: 0;
            right: 300px;
            bottom: 30px;
            overflow: auto;
            ${ScriptEditor} {
              width: 100%;
            }
          }

          > .elTree {
            height: 30px;
            border-top: 1px solid black;
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            right: 300px;
            .elTreeComp {
              padding: 0 8px;
              cursor: pointer;

              &.active {
                background-color: gray;
              }
            }
          }

          > .styles {
            width: 300px;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            border-left: 1px solid black;
            overflow: auto;

            > .stylesBlock {
              border-bottom: 1px solid black;  
            }


            .stylesBlock {

              .propertyLine {
                padding-left: 16px;
                ${NotionDocument2} {
                  padding-bottom: 0;
                  > .wrapper {
                    padding: 0;
                  }

                  .grip {
                    display: none;
                  }
                }
              }
              .children {
                /* border-top: 1px solid black; */
                /* padding-left: 8px; */
                border-left: 4px solid #e0e0e0;

              }
              .stylesBlock {
              }


              .blockTitle {
                /* font-weight: bold; */
                font-family: Monaco;
                font-size: 12px;
                padding-left: 4px;

              }
            }
          }


        }
      }
    }

    [data-block-id="${props => props.highlightBlock}"] {
      background-color: #efefef;
      border-radius: 2px;
    }
  `;

  args() {
    return {
      objectRefTypeResolver: this.props.objectRefTypeResolver,
      globals: this.props.globals,
    }
  }

  prevHighlight
  changeHighlight_timer
  changeHighlight_el
  changeHighlight(highlight, category) {
    clearTimeout(this.changeHighlight_timer);
    this.changeHighlight_timer = setTimeout(() => {
      if (this.changeHighlight_el) {
        this.changeHighlight_el.parentNode.removeChild(this.changeHighlight_el);
        this.changeHighlight_el = null;
      }

      if (highlight) {
        const css = `
        /*[data-id="${this.id}"] [data-identifier-category="${category}"][data-identifier="${highlight}"] {*/
        [data-id="${this.id}"] [data-identifier-id="${highlight}"] {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            pointer-events: none;
            /* border: 1px solid black; */
            /* background-color: #4f67cd24; */
            background-color: #a87b3424;
            z-index: -1;
          }
        }  
        `;
        var style = document.createElement('style');
        style.innerHTML = css;
        document.head.appendChild(style);
        this.changeHighlight_el = style;
  
  
      }

    }, 10);
  }

  updateTagHighlight_el
  updateTagHighlight(ids) {
    if (this.updateTagHighlight_el) {
      jQuery(this.updateTagHighlight_el).remove();
      this.updateTagHighlight_el = null;
    }

    const css = `
    ${ids.map((id, i) => {

      return `
        [data-id="${this.id}"] [data-identifier-category="tag"][data-id="${id}"] {
          background-color: ${i == 0 ? '#458e4579' : '#e2e2e2'};
        }

        [data-id="${this.id}"] [data-script-block-ids*="${id}"] {
          outline: 2px solid ${i == 0 ? 'green' : '#00000042'};
        }
      `
    }).join('\n')}
    
    `

    const style = document.createElement('style');
    style.innerHTML = css;
    document.head.appendChild(style);
    this.updateTagHighlight_el = style;



  }

  functionParamPopupEl

  scriptWrapper: ScriptWrapper

  _blocks() {
    return this.scriptWrapper.blocks();
  }

  previewFunc

  updateCurrentStyles() {
    console.log(this.inspectingEls[this.inspectingElsIndex])
    this.currentStyles = computeStyles(this.inspectingEls[this.inspectingElsIndex]).source;
    this.forceUpdate();

  }

  renderDebug() {
    const r = executeScriptFromData(this._blocks());

    if (_.isFunction(r)) {
      const el = this.inspectingEls?.[this.inspectingElsIndex]?.getAttribute?.('data-script-block-id');
      return (
        <>
          {renderEl(r(), {
            evaluate() {},
            scriptEvaluate() {},
          })}
          <div className="elEditor">
            <div className="editor">
              {el && <ScriptEditor
                key={el}
                rootBlock={el}
                client={this.props.client + 'inspect'}
                baseEntity={this.props.baseEntity}
                scriptWrapper={this.scriptWrapper}
                extendEntity={this.props.extendEntity}
                // highlightBlock={el}
              />}
            </div>
            <div className="elTree">
              {this.inspectingEls?.map?.((el: Element, i) => {
                const getName = () => {
                  let tag = el.getAttribute('data-identifier') || el.tagName.toLowerCase();
                  if (tag[0] == tag[0].toLowerCase() && el.classList.length) {
                    return `${tag}.${[...el.classList].join('.')}`
                  }
                  else {
                    return tag;
                  }
                }
                return (
                  <span className={cx('elTreeComp', { active: i == this.inspectingElsIndex })}
                    onClick={() => {
                      this.inspectingElsIndex = i;
                      this.updateCurrentStyles();
                    }}
                  >
                    {getName()}
                  </span>
                )
              })}
            </div>

            <div className="styles">
              {(() => {
                if (this.currentStyles) {
                  const grouped = {};

                  const model = this.buildModel();

                  const blockMap = {};

                  const addToBlock = (prop, id) => {
                    initBlock(id);
                    blockMap[id].props.push(prop);
                  }

                  const initBlock = id => {
                    if (!blockMap[id]) {
                      blockMap[id] = {
                        id,
                        props: [],
                        children: [],
                      }

                      if (id != 'root') {
                        const block = getBlock(id, model);
                        if (!block.type) {
                          const parentBlock = getParentBlock(id, model);
                          addBlockToParent(id, parentBlock._id);
                        }
                        else {
                          addBlockToParent(id, 'root');
                        }  
                      }
                    }
                  }

                  const addBlockToParent = (id, parentId) => {
                    initBlock(parentId);
                    blockMap[parentId].children.push(id);
                  }




                  for (const name in this.currentStyles) {
                    addToBlock(name, this.currentStyles[name].stylesId);
                  }

                  console.log(this.currentStyles);

                  console.log(blockMap);

                  const renderBlocks = blocks => {
                    return blocks.map(blockId => {
                      const block = blockMap[blockId];
                      const b = getBlock(block.id, model);
                      return (
                        <div key={block.id} className="stylesBlock">
                          <div className="blockTitle">{b.source}</div>
                          {block.props.map(prop => {
                            const styleId = this.currentStyles[prop].propId;
                            return (
                              <div key={prop} className="propertyLine">
                                <ScriptEditor
                                  rootBlock={styleId}
                                  client={this.props.client + styleId}
                                  baseEntity={this.props.baseEntity}
                                  scriptWrapper={this.scriptWrapper}
                                  extendEntity={this.props.extendEntity}
                                  // highlightBlock={el}
                                />
                              </div>
                            )
                          })}

                          {block.children.length > 0 &&<div className="children">
                          {renderBlocks(block.children)}
                          </div>}
                        </div>
                      )
        
                    })
                  }

                  return renderBlocks(blockMap['root'].children);
                  /* 
                  return Object.keys(grouped).map(stylesId => {
                    const block = getBlock(stylesId, model);
                    console.log(block);
                    return (
                      <div key={stylesId} className="stylesBlock">
                        <div className="blockTitle">{block.source}</div>
                        {grouped[stylesId].map(prop => {
                          return (
                            <div key={prop} className="propertyLine">
                            <span className="name">{prop}</span>: <span className="value">{this.currentStyles[prop].value}</span>
                            </div>
                          )
                        })}
                      </div>
                    )
                  });

                  return Object.keys(this.currentStyles).map(prop => {
                    return (
                      <div key={prop}>
                        {prop}: {this.currentStyles[prop].value}
                      </div>
                    )
                  }) */
                }
              })()}
            </div>

          </div>
        </>
      )
    }

    if (this.state.selectedIdentifier) {
      const model = this.buildModel();
      const [blockId, col] = this.state.selectedIdentifier.split(':');
      const type = getVarType(this.state.selectedIdentifier, model, this.args(), false);
      return (
        <>
          <div>{this.state.selectedIdentifier}</div>
          <div>{getVarName(this.state.selectedIdentifier, model)}</div>
          <div>{type?.humanString?.()}</div>
        </>
      )
    }
    else if (this.state.selectedBlock) {
      const model = this.buildModel();
      const block = getBlock(this.state.selectedBlock, model);
      if (!block) return;
      const scope = getScope(block._id, 0, model, false);
      // console.log(block.cst, getType(block.cst, scope));
      return (
        <>
          {this.state.selectedBlock}

        </>
      );
    }


  }

  internalBlocksObserver
  setBlocksObserver
  blockChangedObserver
  constructor(props) {
    super(props);

    if (this.props.rootBlock) {
      this.scriptWrapper = this.props.scriptWrapper.forBlock(this.props.rootBlock);
    }
    else {
      this.scriptWrapper = this.props.scriptWrapper;
    }

    // if (this.props.readOnly) {
    //   // this.blocks = this.propsBlocks();
    // }
    // else {
      // const blocks = this.propsBlocks();
      // if (!blocks.length) {
      //   blocks.push(XObject.obj({
      //     data: [],
      //     children: [],
      //   }));
      // }
  
      // this.blocks = XClone(blocks);
  
      // const observeExternalChanges = () => {
      //   XObject.observe(this.propsBlocks(), (change) => {
      //     this.blocks = XClone(this.propsBlocks());
      //     this.forceUpdate();
      //   });
      // }
  
      // observeExternalChanges();
  
      // XObject.observe(this.blocks, (change) => {
      //   this.propsSetBlocks(XClone(this.blocks));
      //   observeExternalChanges();
      //   const model = this.buildModel();
      //   const blockTypes = this.pullBlockTypes(model);
      //   if (!_.isEqual(blockTypes, this._blockTypes)) {
      //     console.log('new block types');
      //     this._blockTypes = blockTypes;
      //     this.ref.current.doTick();
      //   }
      // });
      if (this.scriptWrapper) {
        this.scriptWrapper.addEventListener('internalBlocks', this.internalBlocksObserver = () => {
          // console.log('internal blocks')
          // const model = this.buildModel();
          // const blockTypes = this.pullBlockTypes(model);
          // if (!_.isEqual(blockTypes, this._blockTypes)) {
          //   console.log('new block types')
          //   this._blockTypes = blockTypes;
          //   this.ref.current?.updateBlocks?.();
          // }
        });
  
        this.scriptWrapper.addEventListener('setBlocks', this.setBlocksObserver = () => {
          console.log('setBlocks');
          this.tick++;
          this.forceUpdate();
        })
  
        this.scriptWrapper.addEventListener('blockChanged', this.blockChangedObserver = (id, client) => {
          if (client != this.props.client) {
            this.blockComponents[id]?.forceUpdate?.();  
          }
        });

        const model = this.buildModel();
        this._blockTypes = this.pullBlockTypes(model);
    
        if (this.props.state) {
          this.state = this.props.state;
        }
    
      }


    // }



  }
  

  ref = React.createRef<NotionDocument2>()

  _blockTypes = {};
  pullBlockTypes(model) {
    const blockTypes = {};
    const iter = blocks => {
      for (const block of blocks) {
        blockTypes[block._id] = block.type;
        iter(block.children);
      }
    }
    iter(model);
    return blockTypes;
  }


  buildModel() {
    return buildModel(this.props.scriptWrapper.blocks(), this.props.additionalTypes);
  }

  _model

  id = md5(Math.random());

  _ctx() {
    const ctx = {
      types: {
          ...types,
          ...this.props.additionalTypes,
      }
    }
    return ctx;
  }

  static contextType = SystemContext;
  context: SystemContextProps;

  selectionChange

  currentStyles

  mousemoveHandler
  prevEl
  inspectingEls
  inspectingElsIndex

  autoCompleteEntries

  typeCont
  componentDidMount(): void {
    const el = ReactDOM.findDOMNode(this);
    jQuery(el).on('mousedown', '[data-type="component"]', (e) => {
      // const id = JSON.parse(atob(e.target.getAttribute('data-component-data')));
      // console.log(id);
      // this.context?.navigate?.({
      //   type: 'entity',
      //   id
      // })
    });

    jQuery(el).on('click', '[data-type="code"]', (e) => {
      const aaa = jQuery(e.target).closest('[data-code-data]');
      const id = JSON.parse(atob(aaa.attr('data-code-data')));
      setAppInspect({
        mode: InspectState.code,
        component: id.component,
      })
    });

    jQuery(el).on('mouseover', '[data-identifier-id]', e => {
      const id = e.target.getAttribute('data-identifier-id');
      if (id != 'undefined') {
        // const [blockId, col] = id.split(':');
        const model = this.buildModel();
        const type = getVarType(id, model, this.args(), true)?.humanString?.() || 'any!!';

        const pos = e.target.getBoundingClientRect();

        if (this.typeCont) this.typeCont.remove();
        this.typeCont = jQuery(
          `<div><span class="identifier">${e.target.getAttribute('data-identifier')}</span>: <span class="type">${type}</span></div>`
        ).css({
          position: 'absolute',
          left: pos.left,
          zIndex: 999999999999,


          backgroundColor: 'white',
          border: '1px solid #a9a9a9',
          borderRadius: '2px',
          padding: '2px 4px',
          fontFamily: 'Monaco',
          fontSize: '12px',
        })

        this.typeCont.find('.identifier').css({
          color: '#4f67cd',
        })

        this.typeCont.find('.type').css({
          color: 'green',
        })

        this.typeCont.click((e) => {
          jQuery(e.currentTarget).remove()
          delete this.typeCont;
        });

        this.typeCont.appendTo('body');

        this.typeCont.css({
          top: pos.top - this.typeCont.outerHeight(),
        })
      }
    });

    jQuery(el).on('mouseleave', '[data-identifier-id]', e => {
      if (this.typeCont) {
        this.typeCont.remove();
        this.typeCont = null;
      }
    });

    jQuery(el).on('mouseover', '[data-prop-path]', e => {
      const model = this.buildModel();

      const blockEl = jQuery(e.target).parents('[data-block-id]');
      const blockId = blockEl.attr('data-block-id');
      const [path, col] = e.target.getAttribute('data-prop-path').split(':');
      const scope = getScope(blockId, col, model, false, this.props.globals);

      const cst = unwrapCst(flexGrammar.match(path)['_cst'], path);

      const type = getType(cst, model, scope, this.args(), false)?.humanString?.() || 'any!!';


        // const [blockId, col] = id.split(':');
        // const type = ''//getVarType(id, model, this.args(), true)?.humanString?.() || 'any!!';

        const pos = e.target.getBoundingClientRect();

        if (this.typeCont) this.typeCont.remove();
        this.typeCont = jQuery(
          `<div><span class="identifier">${path}</span>: <span class="type">${type}</span></div>`
        ).css({
          position: 'absolute',
          left: pos.left,
          zIndex: 999999999999,


          backgroundColor: 'white',
          border: '1px solid #a9a9a9',
          borderRadius: '2px',
          padding: '2px 4px',
          fontFamily: 'Monaco',
          fontSize: '12px',
        })

        this.typeCont.find('.identifier').css({
          color: '#4f67cd',
        })

        this.typeCont.find('.type').css({
          color: 'green',
        })

        this.typeCont.click((e) => {
          jQuery(e.currentTarget).remove()
          delete this.typeCont;
        });

        this.typeCont.appendTo('body');

        this.typeCont.css({
          top: pos.top - this.typeCont.outerHeight(),
        })

    });

    jQuery(el).on('mouseleave', '[data-prop-path]', e => {
      if (this.typeCont) {
        this.typeCont.remove();
        this.typeCont = null;
      }
    });

    jQuery(el).on('mouseover')

    this.selectionChange = () => {
      const selection = window.getSelection();
      const blockEl = jQuery(selection.anchorNode).parents('[data-type="blockData"]')[0];
      if (!blockEl) return;
      const blockId = blockEl.parentNode?.getAttribute?.('data-block-id')
      if (!blockId) return;
      if (ReactDOM.findDOMNode(this)?.contains?.(blockEl)) {
        const data = extractFromEl(this._ctx(), blockEl);
        const text = expandFormula(data, this._ctx());
        const pos = getExpandedPositionInDataEditor(this._ctx(), blockEl);

        const cst = unwrapCst(flexGrammar.match(text)['_cst'], text);

        const atCaret = getCst(cst, pos - 1);

        const functionCallIndex = atCaret['findLastIndex'](cst => {
          if (cst[0]?.ruleName?.startsWith?.('FunctionCall_')) {
            if (pos >= cst[0].children[1].absoluteOffset) {
              return true;
            }
          }
        });

        if (functionCallIndex >= 0) {
          let paramIndex;
          let functionName;
          if (atCaret[functionCallIndex + 1]?.[1] == 3) {
            paramIndex = atCaret[functionCallIndex + 2]?.[1];
          }
          else if (atCaret[functionCallIndex + 1]?.[1] == 5) {
            paramIndex = atCaret[functionCallIndex][0].children[3].length;
          }
          else {
            paramIndex = 0;
          }


          const pos = window.getSelection().getRangeAt(0).getBoundingClientRect();


          if (atCaret[functionCallIndex][0].children[0].ruleName == 'identifier') {
            functionName = atCaret[functionCallIndex][0].children[0].source;
          }
          else {
            functionName = atCaret[functionCallIndex][0].children[0].children[2].source;
          }
          

          const model = this.buildModel();

          const functionType = getType(atCaret[functionCallIndex][0].children[0], model, getScope(blockId, pos, model, false, this.props.globals), this.args(), false);

          if (functionType instanceof FunctionType) {
            if (!this.functionParamPopupEl) {
              this.functionParamPopupEl = jQuery(
                `<div>
                  Function
                </div>`
              ).css({
                position: 'absolute',
                zIndex: 9999999,
  
  
            
                backgroundColor: 'white',
                border: '1px solid #a9a9a9',
                borderRadius: '2px',
                padding: '2px 4px',
                fontFamily: 'Monaco',
                color: 'gray',
                whiteSpace: 'nowrap',
              })
  
  
              this.functionParamPopupEl.appendTo('body');
            }
  
  
  
            this.functionParamPopupEl.css({
              top: pos.top - this.functionParamPopupEl.outerHeight(),
              left: pos.left,
            })

            if (this.functionParamPopupEl.offset().left + this.functionParamPopupEl.outerWidth() > jQuery('html').width()) {
              this.functionParamPopupEl.css({
                left: jQuery('html').width() - this.functionParamPopupEl.outerWidth(),
              })
  
            }
  
  
            const params = (functionType.params?.map?.((p, i) => {
              if (i == paramIndex) {
                return `<span style="color:black">${p.name}: ${p.type.humanString()}</span>`
              }
              else {
                return `${p.name}: ${p.type.humanString()}`
              }
            }) ||[])?.join(', ')
  
            this.functionParamPopupEl.html(`${functionName}(${params})`)
  
  
          }
          else {
            if (this.functionParamPopupEl) {
              console.log('close')
              this.functionParamPopupEl.remove();
              this.functionParamPopupEl = null;  
            }
  
          }



        }
        else {
          if (this.functionParamPopupEl) {
            console.log('close')
            this.functionParamPopupEl.remove();
            this.functionParamPopupEl = null;  
          }
        }


        const parts = getParts(text, pos, null);
        const part = parts.find(p => pos >= p.range[0] && pos <= p.range[0] + p.range[1]);
        if (identifierTypes.includes(part?.type)) {
          const partText = text.slice(part.range[0], part.range[0] + part.range[1]);
          const model = this.buildModel();
          const scope = getScope(blockId, pos, model, false, this.props.globals);
          if (scope) {
            this.state.selectedIdentifier = scope[partText];
            this.changeHighlight(scope[partText], identifierCategory[part.type]);  
          }
        }
        else {
          this.changeHighlight(null, null);
        }
      }
      else {
        this.changeHighlight(null, null);
      }


    }
    document.addEventListener('selectionchange', this.selectionChange);

		/*let clientX, clientY;
    jQuery(window).mousemove(this.mousemoveHandler = e => {
      function inspectKeys(e) {
        return e.ctrlKey && e.altKey;
      }

      if (inspectKeys(e)) {
        clientX = e.clientX;
        clientY = e.clientY;
  
        const els = document.elementsFromPoint(clientX, clientY).filter(el => el.getAttribute('data-script-block-id'));
        
        this.inspectingEls = _.reverse([...els]);
        this.inspectingElsIndex = els.length - 1;
        const tagIds = els.map(el => el.getAttribute('data-script-block-id'));
        
        if (tagIds.length) {
          // console.log(tagIds);
  
  
          if (this.prevEl != els[0]) {
            this.prevEl = els[0];
  
            this.updateCurrentStyles();
  
            // const computedStyles = window.getComputedStyle(els[0]);
  
            // for (const prop of customProps) {
            //   const value = computedStyles.getPropertyValue(prop);
            //   if (value) {
            //     console.log(prop, value);
            //   }
            // }
          
            // for (const asdf of computedStyles) {
            //   if (asdf.startsWith('--')) {
            //     console.log(asdf);
  
            //   }
            // }
  
            // console.log(els[0], computedStyles, computedStyles.getPropertyValue('--color'));
  
          }
  
        }
        this.updateTagHighlight(tagIds);
      }
      else {
        this.updateTagHighlight([]);
      }
    })*/

    if (this.props.highlightBlock) {
      setTimeout(() => {
        try {
          jQuery(ReactDOM.findDOMNode(this)).find(`[data-block-id="${this.props.highlightBlock}"]`)[0].scrollIntoView();

        }
        catch (e) {

        }
  
      }, 0);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('selectionchange', this.selectionChange);
    this.typeCont?.remove?.();

    // jQuery(window).off('mousemove', this.mousemoveHandler);
    if (this.scriptWrapper) {
      this.scriptWrapper.removeEventListener('internalBlocks', this.internalBlocksObserver);
      this.scriptWrapper.removeEventListener('setBlocks', this.setBlocksObserver);
      this.scriptWrapper.removeEventListener('blockChanged', this.blockChangedObserver);  
    }
  }

  obj() {
    if (this.props.obj) {
      return this.props.obj
    }
    else if (this.props.docId) {
      return {
        type: ObjectType.document,
        id: this.props.docId
      }
    }
  }

  options() {
    const types = this.obj() ? typesInScopes([this.obj()].concat(this.props.scopes || [])) : [];

    const attributes = this.obj() ? attributesInScope(this.obj()) : [];

    const entityOptions = [

      {
        label: 'Insert entity',
        action: (block, menuPos) => {
          const position = menuPos;
          const length = dataLength(this.ctx, block.data);
          const firstPart = sliceData(this.ctx, block.data, 0, position);
          const secondPart = sliceData(this.ctx, block.data, position, length);
          const e = [['6435a0493239f5c3f494c1d6', 'entity']];
          block.data = concatData(this.ctx, concatData(this.ctx, firstPart, e), secondPart);
        }
      },

      {
        label: 'Entity',
        action: block => {
          block.type = 'entity';
        }
      },


      {
        label: 'Attach new entity',
        action: (block: MyBlock) => {
          const entity = XObject.obj({
            name: XClone(block.getContent()),
          });
          this.props.extendEntity?.(entity);
          createEntity(entity, this.props.baseEntity);
          block.block.id = entity._id;
        },
      },
      {
        label: 'Attach existing entity',
        action: async (block: MyBlock) => {
          const id = await showPrompt('Enter entity id');
          if (id) {
            block.block.id = id;
            const entity = getEntityById(id);
            block.setContent(XClone(entity.name));
          }
        }
      },
    ]    .concat(types.map(id => ({
      label: `Create ${db.entityTypes.findById(id).name}`,
      action: (block: MyBlock) => {
        const entity = XObject.obj({
          type: id,
        });
        createEntity(entity, this.props.baseEntity); // createEntityNull
        block.block.id = entity._id;
      }
    }))).concat(attributes.map(id => ({
      label: 'Set attribute ' + db.attributeTypes.findById(id).name,
      action: async (block: MyBlock) => {
        const value = await showPrompt('Enter value');

        if (block.block.attributes) {
          block.block.attributes[id] = value;
        }
        else {
          block.block.attributes = X({
            [id]: value,
          });
        }
      },
    })));

    let options = [
      {
        label: 'Insert code',
        action: (block: Block, menuPos) => {
          const position = menuPos;
          const data = block.getContent();
          const length = dataLength(this.ctx, data);
          const firstPart = sliceData(this.ctx, data, 0, position);
          const secondPart = sliceData(this.ctx, data, position, length);
          const component = componentSystem.createComponent();
          const e = [[{
            id: XObject.id(),
            component: component._id,
          }, 'code']];
          block.setContent(concatData(this.ctx, concatData(this.ctx, firstPart, e), secondPart), false);
          setAppInspect({
            mode: InspectState.code,
            component: component._id,
          })
        },
      },
      {
        label: 'Insert formula',
        action: (block: Block, menuPos) => {
          const position = menuPos;
          const data = block.getContent();
          const length = dataLength(this.ctx, data);
          const firstPart = sliceData(this.ctx, data, 0, position);
          const secondPart = sliceData(this.ctx, data, position, length);
          const formula = XObject.obj({
            parent: this.props.obj,
          })
          db.formulas.push(formula);
          const e = [[{
            id: XObject.id(),
            formula: formula._id,
          }, 'formula']];
          block.setContent(concatData(this.ctx, concatData(this.ctx, firstPart, e), secondPart), false);
        },
      },
      {
        label: 'Insert value point',
        action: (block: Block, menuPos) => {
          const position = menuPos;
          const data = block.getContent();
          const length = dataLength(this.ctx, data);
          const firstPart = sliceData(this.ctx, data, 0, position);
          const secondPart = sliceData(this.ctx, data, position, length);
          
          const vp = createRootValuePoint();


          const e = [[{
            id: XObject.id(),
            valuePoint: vp._id,
          }, 'valuePoint']];
          block.setContent(concatData(this.ctx, concatData(this.ctx, firstPart, e), secondPart), false);
        },
      },
      {
        label: 'Insert button',
        action: (block: Block, menuPos) => {
          const position = menuPos;
          const data = block.getContent();
          const length = dataLength(this.ctx, data);
          const firstPart = sliceData(this.ctx, data, 0, position);
          const secondPart = sliceData(this.ctx, data, position, length);

          const e = [[{
            id: XObject.id(),
            type: ControlType.button,
          }, 'control']];
          block.setContent(concatData(this.ctx, concatData(this.ctx, firstPart, e), secondPart), false);
        },
      },
      {
        label: 'Insert reaction',
        action: (block: Block, menuPos) => {
          const position = menuPos;
          const data = block.getContent();
          const length = dataLength(this.ctx, data);
          const firstPart = sliceData(this.ctx, data, 0, position);
          const secondPart = sliceData(this.ctx, data, position, length);


          const identifier = XObject.obj({
            relative: !!this.props.baseEntity,
          });
          db.identifiers.push(identifier);

          const e = [[{
            id: XObject.id(),
            type: ControlType.reaction,
            binding: identifier._id,
          }, 'control']];
          block.setContent(concatData(this.ctx, concatData(this.ctx, firstPart, e), secondPart), false);
        },
      },
      {
        label: 'Insert checkbox',
        action: (block: Block, menuPos) => {
          const position = menuPos;
          const data = block.getContent();
          const length = dataLength(this.ctx, data);
          const firstPart = sliceData(this.ctx, data, 0, position);
          const secondPart = sliceData(this.ctx, data, position, length);


          const identifier = XObject.obj({
            relative: !!this.props.baseEntity,
          });
          db.identifiers.push(identifier);

          const e = [[{
            id: XObject.id(),
            type: ControlType.checkbox,
            binding: identifier._id,
          }, 'control']];
          block.setContent(concatData(this.ctx, concatData(this.ctx, firstPart, e), secondPart), false);
        },
      },
      {
        label: 'To-do list',
        action: (block: MyBlock) => {
          block.block.type = 'checkItem';
        }
      },
      {
        label: 'Plain block',
        action: block => {
          delete block.type;
        }
      },
      {
        label: 'Heading 1',
        action: (block: MyBlock) => {
          block.block.type = 'heading_1';
        }
      },
      {
        label: 'Code',
        action: (block: MyBlock) => {
          block.block.type = 'code';
        }
      },
      {
        label: 'Query',
        action: (block: MyBlock) => {
          block.block.type = BlockType.query;
        }
      },
      {
        label: 'Media',
        action: block => {
          block.block.type = 'media';
        }
      },



    ];

    if (!resumeMode.enabled) {
      options = options.concat(entityOptions);
    }

    return options.filter(Boolean);
  }
  tick = 0;

  ctx = {
    types: types
  };

  entitySelectOptions(filter, onSelectEntity) {
    const r =  getAllEntities().filter(e => {
      if (!_.isString(e.name)) return false;
      if (e.space?.id != appState.currentMode) return false;
      return expandToText(this.ctx, e.name).toLowerCase().includes(filter.toLowerCase());
    }).map(e => ({
      key: e._id,
      label: <ShowEntity id={e._id} />,
      // filterText: expandToText(this.ctx, e.name),

      action: (block: MyBlock, menuPos) => {
        onSelectEntity(e, block, menuPos);      }
    }))

    return r.concat({
      label: `Create entity "${filter}"`,
      action: (block, menuPos) => {
        const e = XObject.obj({
          name: filter,
        });
        createEntity(e, null);
        onSelectEntity(e, block, menuPos);
      }
    } as any)
  }

  memory = {};

  presentMenu: MenuPresenter = args => {
    /* if (args.type == '@') {
      return presentObjectMenu(args, this.props.parent || { type: ObjectType.global });
    }
    else  */if (this.props.additionalMenuIniters && args.type in this.props.additionalMenuIniters) {
      return this.props.additionalMenuIniters[args.type](args);
    }
  }

  result

  state = XInit(class {
    selectedBlock
    selectedIdentifier
  })


  blockComponents = {}
  render(Container?) {
    if (!this.scriptWrapper) return '';
    const run = () => {
      console.log(this.result = executeScriptFromData(this._blocks()));


    }
    return (
      <Container data-id={this.id} data-test={this.props.highlightBlock} highlightBlock={this.props.highlightBlock} className={this.props.debugPane && 'debugPane'}>
        {this.props.run && <button
          onClick={() => {
            run()
          }}
        >Run</button>}
        <div className="left">
          <NotionDocument2
            onDeleted={this.props.onDeleted}
            ref={this.ref}
            ctxArgs={{
              entity: this.props.baseEntity,
            }}
            key={this.props.tick?.()}
            insidePositionContext={this.props.insidePositionContext}
            title={this.props.title}
            setTitle={this.props.setTitle}
            client={this.props.client}
            renderBlock={(a, b, c) => {
              return renderBlock(a,
                {
                  depth: 0,
                  ...(b||{}),
                  systemCtx: this.context,
                  onMouseDownGrip: (e, block) => {
                    b.onMouseDownGrip(e, block);
                    this.props.onBlockSelected?.(block._id);
                  },

                  renderDataRef: id => {
                    return el => {
                      this.blockComponents[id] = el;
                    }
                  },
                }, 
                {
                  ...(c || {}),
                  childPadding: 17,
                  // meta: () => 'test',
                  meta: this.props.blockMeta && (() => this.props.blockMeta(a.getId())),
                }
              );
            }}
            contextMenuItems={block => {
              return [
                // {
                //   text: 'Debug',
                //   onClick: () => {
                //     // this.blockComponents[block._id]?.forceUpdate?.();

                //     console.log(block._id, x(scriptBlockDiagInfo[block._id]));
                //   },
                // },

                {
                  text: 'Copy',
                  onClick: () => {
                    copiedBlock = x(block);
                    delete copiedBlock._id;
                  },
                },

                copiedBlock && {
                  text: 'Paste',
                  onClick: () => {
                    for (const prop in copiedBlock) {
                      block[prop] = X(copiedBlock[prop]);
                    }
                    this.ref.current.updateBlock(block._id);
                  }
                },

                ...(this.props.contextMenuItems?.(block) || []),
              ]
            }}
            autoComplete={!this.props.disableAutoComplete && (blockId => {
              const selection = window.getSelection();
              const blockEl = jQuery(selection.anchorNode).is('[data-type="blockData"]') ? selection.anchorNode : jQuery(selection.anchorNode).parents('[data-type="blockData"]')[0];
              if (!blockEl) {
                console.log('no blockel', selection)
                return [];
              }

              const pos = getExpandedPositionInDataEditor(this._ctx(), blockEl);

              const model = this.buildModel();

              const block = getBlock(blockId, model);
              if (block) {
                const tree = getCst(block.cst, pos - 1);

                if (tree[tree.length - 2]?.[0]?.ruleName == 'Primary_accessor') {
                  const accessor = tree[tree.length - 2]?.[0];
                  const type = getType(accessor.children[0], model, getScope(blockId, accessor.absoluteOffset, model, false, this.props.globals), this.args(), false);
                  const properties = type?.getProperties?.() || [];
  
                  return properties.map(p => ({
                    id: p.name,
                    name: p.name,
                    part: p.name,
                  }))
                  
                }
  
  
                return Object.keys(getScope(blockId, -1, model, true, this.props.globals)).map(name => ({
                  id: name,
                  name,
                  part: name,
                })).concat(this.props.autoCompleteEntries || []);
              }
              else {
                return [];
              }
            })}
            onBlockSelected={block => {
              if (block) {
                this.state.selectedBlock = block.getId();
                  const formula = expandFormula(x(block.getContent()));
                  const cst = flexGrammar.match(formula)['_cst'];
                  // console.log(cst, unwrapCst(cst), grammar.match(formula)['_cst']);
                // console.log(formula, getEvalInfo(formula));

                // const model = this.buildModel();
                // console.log(model, getScope(block.getId(), 0, model, false));
              }
            }}
            // onBlockSelected={(block: MyBlock) => {
            //   try {
            //     const formula = expandFormula(x(block.getContent()));
            //     console.log(formula, getEvalInfo(formula));
            //   }
            //   catch (e) {
            //     console.log('error with', x(block.getContent()));
            //     console.log(e);
            //   }
              
            //   // if (block?.getId()) {
            //   //   appState.inspecting = {
            //   //     type: 'entity',
            //   //     id: block.getId(),
            //   //   }
            //   // }
            //   // this.props.onBlockSelected?.(block);
            // }}
            renderBlockArgs={{
              onClickEntityDot: id => {
                console.log(id);
                this.context.navigate({
                  type: 'entity',
                  id,
                })
              },
              docId: this.props.docId,
              obj: this.props.obj,
              ...(this.props.renderBlockArgs || {}),
            }}
            highlighter={(el: HTMLDivElement, blockId, pos) => {
              // return;
              const model = this.buildModel();
              const find = (blocks, path=[]) => {
                for (const block of blocks) {
                  if (block._id == blockId) {
                    return path.concat(block);
                  }

                  const r = find(block.children, path.concat(block));
                  if (r) return r;
                }
              }
              const path = find(model);
              if (path) {
                let type;

                for (const el of _.reverse(path.slice(0, -1))) {
                  if (el.blockType) {
                    type = el.blockType;
                    break;
                  }
                  else if (el.type) {
                    type = el.type;
                    break;
                  }
                }
  
                highlight(
                  {
                  
                  ctx: this._ctx(),
                  
                  el: el,
                  
                  type,
                  
                  pos,
                
                  getIdentifierId: (identifier, pos) => {
                    const scope = getScope(blockId, pos, model, false, this.props.globals);
                    if (identifier in scope) {
                      return scope[identifier];
                    }
                    else if (identifier in globals || identifier in (this.props.globals || {})) {
                      return identifier;
                    }
                  },
  
                  getType: (cst) => {
                    // console.log(cst);
                    return getType(cst, model, getScope(blockId, cst.absoluteOffset, model, false, this.props.globals), this.args(), false)
                  },
  
                  getTagId: col => {
                    return blockId;
                    return `${blockId}:${col}`;
                  }
                  
                })
              }
              else {
                console.log('cant find', blockId);
              }
            }}
            types={{
              ...types,
              ...this.props.additionalTypes,
            }}

            blockManager={this.scriptWrapper.blockManager()}
            menuPresenter={this.presentMenu}
            menuIniterKeys={[].concat(Object.keys(this.props.additionalMenuIniters || {}))}
          />
        </div>
        {this.props.debugPane && <div className="right">
          {this.renderDebug()}
        </div>}
        {this.props.run && <button
          onClick={() => {
            run()
          }}
        >Run</button>}
        {/* {this.result && renderEl(this.result, {})} */}
      </Container>
    );
  }
}
