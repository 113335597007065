import { Component } from 'react';
import { component } from '../component2';
import { mainColors, lightColors } from './mainColors';



@component
export class ColorSelector extends Component<{ theme: 'primary' | 'light'; get; set; }> {
  render() {
    let colors: typeof mainColors;
    if (this.props.theme == 'primary') {
      colors = mainColors;
    }
    else {
      colors = lightColors;
    }
    return (
      <>
        <select
          style={{
            color: colors.find(c => c.key == this.props.get())?.color,
          }}
          value={this.props.get()}
          onChange={e => {
            this.props.set(e.target['value']);
          }}
        >
          <option></option>
          {colors.map(c => <option value={c.key}>{c.name}</option>)}
        </select>
      </>
    );
  }
}
