import React from 'react';
import { PropertyField } from '../../components/PropertyField';
import { db } from '../../db';
import { ValueType } from '../ValueType';
import { structRenderers } from '../structRenderers';
import { typeRegistry } from '../typeRegistry.1';
import { doEntityQuery } from "../doEntityQuery";
import { getEntityById } from '../../etc/createEntity';

export const $RelativeEntityQueryStateful = typeRegistry.registerType({
  $: '257e57f2-8de3-543d-b645-b699db627e4c',
}, ids => ({
  _id: ids.$,
  name: '[Relative Entity Query] Stateful',
  definition: [],

}));

const $EntityQuery = typeRegistry.registerType({
  $: 'b12a060a-e8e3-5c3f-9200-0c30d6713bc1',
  Entries: '4f59293e-99a0-507f-8bff-13aad4a80bca',
}, ids => ({
  _id: ids.$,
  name: 'Relative Entity Query',
  definition: [
    {
      id: ids.Entries,
      name: 'Entries',
      type: [ValueType.Array, []],
      property: 'entries',
    }
  ]
}));

structRenderers[$EntityQuery.$] = (value, map, state) => {
  const results = doEntityQuery(value, state.entity);

  return (
    <>
      <PropertyField object={state} property="entity" />

      <ul>
        {results.map(r => (
          <li key={r.entity}>
            {getEntityById(r.entity).name}
          </li>
        ))}


      </ul>
    </>
  );
};
