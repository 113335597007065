import React, { Component } from 'react';
import { component } from '../component2';
import { ObjectDisplay } from './ObjectDisplay';
import { objectName } from "./objectFuncs";
import { XInit } from '../XObject';
import classNames from 'classnames';
import { styled } from '../component';
import { allObjects } from './allObjects';
import { appState } from '../etc/appState';

@component
export class ObjectPicker extends Component<{
  showAllEmpty?;
  _onSelect?;
  type?;
  onEsc?
}> {
  static styles = styled.div`
    .selected {
      font-weight: bold;
    }
  `;
  state = XInit(class {
    filter = '';
    selected = 0;
  });
  render() {
    const objects = allObjects({ includeGlobal: true });

    const getFiltered = () => {
      return (!this.props.showAllEmpty && !this.state.filter) ? [] : objects.filter(obj => {
        const name = objectName(obj);
        if (!name) return false;
        if (this.props.type && obj.type !== this.props.type) {
          return false;
        }
        return name.toLowerCase().includes(this.state.filter.toLowerCase());
      })
    }
    const filtered = getFiltered();

    return (
      <>
        <input type="text" defaultValue={this.state.filter} onChange={e => {
          this.state.filter = e.target.value;
          this.state.selected = 0;
          appState.focusedObjectInSelectMenu = getFiltered()[this.state.selected];

        }}
          onKeyDown={e => {
            // up and down with wrapping
            if (e.key === 'ArrowUp') {
              e.preventDefault();
              this.state.selected = (this.state.selected - 1 + filtered.length) % filtered.length;
              appState.focusedObjectInSelectMenu = filtered[this.state.selected];
            }
            else if (e.key === 'ArrowDown') {
              e.preventDefault();
              this.state.selected = (this.state.selected + 1) % filtered.length;
              appState.focusedObjectInSelectMenu = filtered[this.state.selected];
            }
            else if (e.key === 'Enter') {
              e.preventDefault();
              this.props._onSelect(filtered[this.state.selected], () => {
                this.state.filter = '';
                this.state.selected = 0;
                e.target['value'] = '';
              });
            }
            else if (e.key == 'Escape') {
              this.props.onEsc?.();
            }
          }} />

        <ul>
          {filtered.map((obj, i) => {
            return (
              <li key={obj.id}
                className={classNames({
                  selected: i === this.state.selected,
                })}
                onClick={() => {
                  this.props._onSelect(obj, () => {
                    // this.state.filter = '';
                    // this.state.selected = 0;
                    // e.target['value'] = '';
                  });
                }}
              >
                <ObjectDisplay obj={obj} showPath />
              </li>
            );
          })}
        </ul>
      </>
    );
  }
}
