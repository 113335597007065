import React from 'react';
import { component } from './component2';
import { Component } from 'react';
import { styled } from './component';
import { SketchPicker } from 'react-color';


@component
export class ColorPicker extends Component<{ color; onColorChange; }> {
  static styles = styled.div`
    /* width: 100px; */
    /* height: 100px; */
    /* border: 1px solid black; */

  `;

  static debounce = false;
  color

  constructor(props) {
    super(props);
    this.color = this.props.color;
  }
  render() {
    return (
      <>
        <SketchPicker
          presetColors={[]}
          color={this.props.color}
          onChange={color => {
            this.props.onColorChange(rgbaToHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a));
          }} />
      </>
    );
  }
}
function rgbaToHex(r, g, b, a) {
  const red = r.toString(16).padStart(2, '0');
  const green = g.toString(16).padStart(2, '0');
  const blue = b.toString(16).padStart(2, '0');
  const alpha = Math.round(a * 255).toString(16).padStart(2, '0');
  return `#${red}${green}${blue}${alpha == 'ff' ? '' : alpha}`;
}
