import React, { Component } from "react";
import jQuery from "jquery";
import { component, styled } from "../component";
import { X } from "../XObject";
import ReactDOMClient from "react-dom/client";
import { presentEditComp } from "./notionDatabase/presentEditComp";
import { CellType } from "./notionDatabase/CellType";


@component
export class Cell extends Component<{ readOnly?: boolean; title; cell: CellType; get; set; width?; disableClick?: boolean }> {
  static debounce = false;
  static styles = styled.div`
    flex: 0 0 auto;
    box-sizing: border-box;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 8px;
    padding-bottom: 8px;

    position: relative;
    /* border-right: 1px solid rgba(55, 53, 47, 0.09); */
    min-height: 32px;
    /* line-height: 32px; */
  `;

  state = X({});

  presentEditComp(frame) {
    presentEditComp({
      title: this.props.title,
      cell: this.props.cell,
      frame,
      get: this.props.get,
      set: this.props.set,
      state: this.state,
    })
    return;

    let timerId;
    const cont = jQuery('<div />').css({
      position: 'fixed',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      // top: frame.top + 'px',
      // left: frame.left + 'px',
      zIndex: 2147483001,
    });

    const close = () => {
      cont.remove();
      root.unmount();
    };
    cont.click((e) => {
      e.preventDefault();
      e.stopPropagation();
      close();
    });
    const cont2 = jQuery('<div />').css({
      position: 'absolute',
      left: frame.left + 'px',
      top: frame.top + 'px',
      // width: frame.width + 'px',
    });
    cont.append(cont2);
    cont2.click((e) => {
      e.stopPropagation();
    });

    jQuery('body').append(cont);

    const root = ReactDOMClient.createRoot(cont2[0]);

    const cell = this.props.cell;

    root.render(cell.renderEditor({
      close,
      frame,
      value: () => this.props.get(),
      setValue: value => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
          this.props.set(value);
        }, 300);
      },
      state: this.state,
    }));
  }

  render(Container?) {
    const cell = this.props.cell;
    return (
      <Container
        className="cell"
        style={{ width: this.props.width ? this.props.width + 'px' : undefined }}
        onMouseDown={(e) => {
          if (this.props.readOnly || !cell.useEditor()) return;
          e.preventDefault();
          this.presentEditComp((e.target as any).getBoundingClientRect());
        }}
      >
        {cell.renderValue(this.props.get(), {
          setValue: value => {
            this.props.set(value);
          }
        })}
        {this.props.disableClick && <div
          data-disable-click
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 1,
          }}
         />}
      </Container>
    );

  }
}
