import React from 'react';

export interface SystemContextProps {
  navigate(config, paneIndex?, pointer?)
  next?
  viewType?

  back?
  backToRoot?

  contextArgs?

  initCollection?

  pageFrameBack?

}

export const SystemContext = React.createContext<SystemContextProps>(null);


