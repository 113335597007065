import { db } from '../db';
import { XObject } from '../XObject';
import { BlockPageCont } from '../components/BlockPage';
import { NotionDocumentWrapper } from '../components/NotionDocumentWrapper';
import { PropertyField } from '../components/PropertyField';
import { Svg } from '../components/Svg';
import { styled } from '../component';
import { showContextMenu } from '../helpers';
import { showPrompt } from './showPrompt';
import { useContext } from 'react';
import { SystemContext } from './SystemContext';
import { NotionButton } from '../components/AddButton';
import { $Document } from '../glue/structs/$Document';
import { ObjectType } from '../types/ObjectRef';
import { getEntityById } from './createEntity';

const Wrapper = styled.div`
  .doc {
    position: relative;
    .more {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      svg {
        width: 18px;
        height: 18px;
        fill: #bababa;

      }
    }
  }
`;

export function renderDocuments(n, elId, state, onSelectChild, navigate?) {
  const entity = getEntityById(n);
  const context = useContext(SystemContext);
  return (
    <Wrapper>
      {entity.documents?.map?.((x, i) => {
        if (x.type == 'notion') {
          return (
            <div key={x._id}>
            <BlockPageCont
              noHeader
              id={elId}
              inWindow={false}
              blockPage={x}
              state={XObject.get(state, x._id, {})}
              _onFocused={id => {
                onSelectChild(id);
              }} />

              <button
                onClick={() => {
                  entity.documents.splice(i, 1);
                }}
              >X</button>

            </div>
          );

        }
        else if (x.type == 'f05015fd-bd2a-55e9-992d-017f0ba9ac55') {
          return (
            <div key={x._id} className="doc">
              <span className="more"
                onClick={e => {
                  showContextMenu(e, [
                    {
                      text: 'Open',
                      onClick: () => {
                        context?.navigate?.({
                          type: '146a4264-a507-5fb9-b7d3-e59ee00fb050',
                          entity: n,
                          document: x._id,
                        })
            
                      }
                    },
                    {
                      text: `Edit config (${x.config || ''})`,
                      onClick: async () => {
                        const value = await showPrompt('Config id', x.config);
                        x.config = value;
                      }
                    },
                    {
                      text: 'Delete',
                      onClick: () => {
                        entity.documents.splice(i, 1);

                      }
                    }
                  ], true);
                }}
              >
                <Svg name="dots" />
              </span>

              <NotionDocumentWrapper
                docId={[entity._id, x._id]}
                entity={n}
                inline
                blocks={x.content}
                setBlocks={blocks => {
                  x.content = blocks;
                }}
                configId={x.config}
                name={null}
                configMap={{
                  [$Document.Entity]: n,
                }}
              />
            </div>
          )
        }
      })}

      {db.notionDocuments.filter(d => d.parent?.id == n && d.key != 'f270bbdc-20f3-5a57-b4dd-8c8fb4065d69').map(x => {
        return (
          <div key={x._id} className="doc">
            <span className="more"
              onClick={e => {
                showContextMenu(e, [
                  // {
                  //   text: 'Open',
                  //   onClick: () => {
                  //     context?.navigate?.({
                  //       type: '146a4264-a507-5fb9-b7d3-e59ee00fb050',
                  //       entity: n,
                  //       document: x._id,
                  //     })
          
                  //   }
                  // },
                  {
                    text: 'Debug',
                    onClick: async () => {
                      console.log(x._id);
                    }
                  },
                  {
                    text: 'Delete',
                    onClick: () => {
                      db.notionDocuments.splice(db.notionDocuments.indexOf(x), 1);

                    }
                  }
                ], true);
              }}
            >
              <Svg name="dots" />
            </span>
            <NotionDocumentWrapper
              docId={x._id}
              entity={n}
              inline
              blocks={x.blocks}
              setBlocks={blocks => {
                x.blocks = blocks;
              }}
              configId={x.config}
              name={null}
              configMap={{
                [$Document.Entity]: n,
              }}
            />
          </div>
        );
      })}
      <NotionButton text="Add page" onClick={() => {
        // const doc = XObject.obj({
        //   type: 'f05015fd-bd2a-55e9-992d-017f0ba9ac55',
        //   content: [
        //     XObject.obj({})
        //   ],
        // });
        // XObject.push(entity, 'documents', doc);

        db.notionDocuments.push(XObject.obj({
          parent: {
            type: ObjectType.entity,
            id: n,
          },
          name: 'new doc',
          blocks: [],
        }));
        

      }} />
    </Wrapper>
  );
}
