import { Component } from 'react';
import { component } from './component';
import axios from 'axios';
import { configApiServer, db } from './db';
import { styleStyles } from './components/doneStyles';
import { entityDisplayName } from './components/entityDisplayName';
import { getAllEntities } from './etc/createEntity';

function getSystemText(entries) {
const system =
`Your job: Your job is to scan through text and find references to encyclopedia entries for the purposes of cross referencing articles. The encyclopedia's entries are very narrow in topic, so you only want to find matches to entries that are specific. However, the references you find in the wild might not be *exact* matches to the titles in the encyclopedia, but you want to try to match them anyway.

You use common sense when scanning the text, understanding that common suffixes such as "s" or "ing" don't change the meaning of the word.

Find all references, organized by how precisely they match the entry (not a text based analysis, but a conceptual distance.)

The output should show the entry title, the narrow matching context, the precise matching text, and how close the match was.
JSON output with keys:
  title
  id
  matchingContext
  preciseMatchingText
  matchCloseness:
    exact: exact match
    near-exact: matching a different inflection, i.e., cell and cells, repair and repairing
    partial: missing some words
    conceptual: conceptually related but not the same idea

These are the titles for the encyclopedia entries:
${entries}


END OF INSTRUCTIONS`;
  return system;


}


const test = `
BEGINNING OF TEXT LOOK THROUGH:

The cell is the basic structural and functional unit of all forms of life. Every cell consists of cytoplasm enclosed within a membrane, and contains many macromolecules such as proteins, DNA and RNA, as well as many small molecules of nutrients and metabolites.[1] The term comes from the Latin word cellula meaning 'small room'.[2]

Cells can acquire specified function and carry out various tasks within the cell such as replication, DNA repair, protein synthesis, and motility. Cells are capable of specialization and mobility within the cell.

Most plant and animal cells are only visible under a light microscope, with dimensions between 1 and 100 micrometres.[3] Electron microscopy gives a much higher resolution showing greatly detailed cell structure. Organisms can be classified as unicellular (consisting of a single cell such as bacteria) or multicellular (including plants and animals).[4] Most unicellular organisms are classed as microorganisms.

The study of cells and how they work has led to many other studies in related areas of biology, including: discovery of DNA, cancer systems biology, aging and developmental biology.`

function stripMarkdownCodeBlock(str) {
  // Regular expression to match the pattern
  const regex = /^```json\n([\s\S]*?)\n```$/;

  // Check if the string matches the pattern
  if (regex.test(str)) {
      // Remove the leading and trailing parts
      return str.replace(regex, '$1').trim();
  } else {
      // Return the original string if it doesn't match the pattern
      return str;
  }
}


export async function findRefs(text, ents?) {
  const entities = ents || getAllEntities().filter(e => !e.__deleted && e.type == '654f3e83aa8c8636269b398c');

  const entries = entities.map((e, i) => {
    return `${i}: ${entityDisplayName(e._id).trim().toLowerCase()}`
  }).join('\n');

  
  const r = (await axios.post(`${configApiServer()}completion`, JSON.stringify({
    messages: [
      { role: 'system', content: getSystemText(entries) },
      { role: 'user', content: text },
    ],
    // response_format: { "type": "json_object" },
  }), { headers: { 'Content-Type': 'application/json' }})).data;
  const response: string = r[0].message.content;

  const refs = JSON.parse(stripMarkdownCodeBlock(response.trim()));
  return refs.map(ref => {
    return {
      title: ref.title,
      id: entities[ref.id]._id,
      reference: ref.preciseMatchingText,
      matchCloseness: ref.matchCloseness,
    }
  })
}

@component
export class CompletionsTest extends Component {
  async componentDidMount() {
    console.log(await findRefs(test));
  }
  render() {
    return '';
  }
}
