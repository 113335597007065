import _ from 'lodash';
import React, { Component } from 'react';
import { db } from '../db';
import { PropertyField } from '../components/PropertyField';
import { component } from '../component2';
import { X, XObject, XTouch } from '../XObject';
import { appState } from '../etc/appState';
import { EXPRESSION_QUERY, GLUE_QUERY, queryChain, queryScopeAssertions } from '../etc/queryFuncs';
import { createRootValuePoint } from '../glue/main';
import { ExpressionEntryType, Conjuction, renderEntry } from '../etc/expressionQuery';
import { ObjectType } from '../types/ObjectRef';
import { CheckboxItem, DropdownItem, Section } from '../components/QueryViewEditor';


@component
export class QueryEditorWindow extends Component<{ window; }> {
  render() {
    const query = db.queries.findById(this.props.window.query);
    let scopes = [
      {
        type: ObjectType.query,
        id: query._id,
      }
    ];

    const scopeAssertions = [];
    query.query && queryScopeAssertions(queryChain(query), scopeAssertions);
    scopes.splice(0, 0, ...scopeAssertions);
    scopes = _.uniqBy(scopes, x => x.id);

    XTouch(query.query);

    return (
      <>
        <Section>
          <DropdownItem label="Type" value={query.type} setValue={v => query.type = v} options={[
            {
              text: 'Entities',
              value: 'entities',
            },
            {
              text: 'Event Occurrences',
              value: 'eventOccurrences'
            },
          ]} />
          <CheckboxItem label="Layered" value={query.layered} setValue={v => query.layered = v} />
        </Section>
        <Section header="Query">
        {(() => {
          if (query.layered) {
            return (
              <ul>
                {query.layers?.map?.((layer, i) => {
                  return (
                    <li key={i}>
                      {layer._id}

                      {layer.query[0] == GLUE_QUERY && (
                        <>
                          <span data-value-point={layer.query[1]}

                            onClick={() => {
                              appState.glue = {
                                valuePoints: [layer.query[1]],
                              };
                            }}
                          >{layer.query[1]}</span>

                        </>
                      )}

                      {layer.query[0] == EXPRESSION_QUERY && (
                        <>

                          {renderEntry(layer.query[1], scopes, {})}
                        </>
                      )}



                      <button
                        onClick={() => {
                          query.layers.splice(i, 1);
                        }}
                      >
                        -
                      </button>
                    </li>
                  );
                })}
                <li>
                  <button
                    onClick={() => {
                      const newValuePoint = createRootValuePoint();
                      XObject.push(query, 'layers', XObject.obj({
                        views: [],
                        query: [
                          GLUE_QUERY, newValuePoint._id,
                        ],
                      }));
                    }}
                  >+ glue</button>

                  <button
                    onClick={() => {
                      // const newValuePoint = createRootValuePoint();
                      XObject.push(query, 'layers', XObject.obj({
                        views: [],
                        query: [EXPRESSION_QUERY, {
                          _id: 'root',
                          type: ExpressionEntryType.group,
                          conjunction: Conjuction.and,
                          entries: [],
                        }],
                      }));
                    }}
                  >+ exp</button>
                </li>
              </ul>
            );
          }
        })()}
        {!query.layered && query.query?.[0] == EXPRESSION_QUERY && (
          <>
            {renderEntry(query.query[1], scopes, {
              type: query.type,
            })}
          </>
        )}
        </Section>


        {/* <div>
          Name: <PropertyField object={query} property="name" />
        </div>


        <button onClick={() => {
          query.views = X([]);
        }}>Init views</button>



        <button
          onClick={() => {
            const newValuePoint = createRootValuePoint();
            query.query = [GLUE_QUERY, newValuePoint._id];
          }}
        >Init Glue</button>

        <button
          onClick={() => {
            query.query = [EXPRESSION_QUERY, {
              _id: 'root',
              type: ExpressionEntryType.group,
              conjunction: Conjuction.and,
              entries: [],
            }];
          }}
        >Init Expression</button>

        {query.query?.[0] == GLUE_QUERY && (
          <>
            <span data-value-point={query.query[1]}

              onClick={() => {
                appState.glue = {
                  valuePoints: [query.query[1]],
                };
              }}
            >{query.query[1]}</span>
          </>
        )} */}


      </>
    );
  }
}
