import { Component, Context, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import Color from 'color';
import jQuery from 'jquery';
import { component } from '../component';
import { X, XClone, XInit, XObject, x } from '../XObject';
import _ from 'lodash';
import { styled } from '../component';
import { RemoteSvg, Svg } from './Svg';
import { openWindow, registerInspectObjectHandler, triggerInspectObject } from '../osHelpers';
import { db } from '../db';
import cx from 'classnames';
import { RichTextEditor } from './richTextEditor/RichTextEditor';
import { createQuery } from '../etc/queryFuncs';
import { ObjectType } from '../types/ObjectRef';
import { ViewQuery } from './ViewQuery';
import { explicitInspectObj, inspectObj } from '../inspectObj';
import { SystemContext, SystemContextProps } from '../etc/SystemContext';
import { getObject, getObjectParent } from './objectFuncs';
import { NotionTable2 } from './NotionTable2';
import { MyNotionDocument } from '../MyNotionDocument/MyNotionDocument';
import { MyBlockManager } from '../MyNotionDocument/MyBlockManager';
import { BlockType as BlockType2  } from '../MyNotionDocument/BlockType';
import { registerValueAccessor } from '../getValue';
import { PropertyField } from './PropertyField';
import { types } from '../MyNotionDocument/types';
import { showContextMenu } from '../etc/showContextMenu';
import { WindowType } from '../etc/WindowType';
import { Type } from '../richTextHelpers';
import { ControlType } from '../MyNotionDocument/ControlType';
import { appState } from '../etc/appState';
import { getDataSlot } from './getDataSlot';
import classNames from 'classnames';
import { codaIcons } from '../codaIcons';
import { mainColors } from './mainColors';
import { ColorSelector } from './ColorSelector';
import { IconSelector } from './IconSelector';
import { DocumentBlockEditor } from './DocumentBlockEditor';
import { IdentifierEditor } from './IdentifierEditor';
import { EntityInspect } from './EntityInspect';
import { Route, registerRoute } from './registerRoute';
import { NavStackCont } from './QueryViewEditor';
import { objectResource } from './objectResource';
import { initPaneObject } from './AppRoot';
import { getEntityById } from '../etc/createEntity';

@component
export class Control extends Component<{ id, args }> {
  static debounce = false;
  static styles = styled.div`
    &.button {
      background-color: rgb(255, 255, 255);
      box-shadow: 0 1px 2px 0 rgba(0,0,0,.06);
      /* border: 1px solid rgb(224, 224, 224); */
      border-radius: 6px;
      cursor: pointer;
      padding: 3px 6px;
      transition: background-color .15s ease,border-color .15s ease,color .15s ease,box-shadow .15s ease;
      font-weight: 500;
      font-size: 12px;
      display: inline-flex;
      align-items: center;
      background-color: var(--background-color);

      &:hover {
        box-shadow: 0 2px 10px -1px rgba(0,0,0,.18);
        background-color: var(--highlight-color);
      }

      &.hasColor {
        color: white;

        fill: white;
      }


      &:not(.hasColor) {
        fill: black;
      }

      > span {
        display: inline-flex;
        align-items: center;
      }

      .icon {
        margin-right: 4px;
        width: 16px;
        height: 16px;
        display: inline-block;
        position: relative;
        mask-position: 50% 50%;
        mask-repeat: no-repeat;
        mask-size: contain;
        > svg {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
      }
    }

    &.checkbox {
      background-color: rgb(255, 255, 255);
      box-shadow: 0 1px 2px 0 rgba(0,0,0,.06);
      border: 1px solid rgb(224, 224, 224);
      border-radius: 6px;
      cursor: pointer;
      padding: 3px 6px;
      transition: background-color .15s ease,border-color .15s ease,color .15s ease,box-shadow .15s ease;
      font-weight: 500;
      font-size: 12px;
      display: inline-flex;
      align-items: center;

      &:hover {
        border-color: rgb(198, 198, 198);
        box-shadow: 0 2px 10px -1px rgba(0,0,0,.12);
      }

    }

    &.reaction {
      background-color: rgb(255, 255, 255);
      box-shadow: 0 1px 2px 0 rgba(0,0,0,.06);
      border: 1px solid rgb(224, 224, 224);
      border-radius: 6px;
      cursor: pointer;
      padding: 3px 6px;
      transition: background-color .15s ease,border-color .15s ease,color .15s ease,box-shadow .15s ease;
      font-weight: 500;
      font-size: 12px;
      display: inline-flex;
      align-items: center;
      box-sizing: border-box;

      &.reacted {
        border: 2px solid rgb(15, 95, 175);
      }

      height: 28px;



      .icon {
        display:inline-block;
        width: 16px;
        height: 16px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      &:hover {
        border-color: rgb(198, 198, 198);
        box-shadow: 0 2px 10px -1px rgba(0,0,0,.12);
      }

    }
  `;
  render(Container?) {
    const {id, args} = this.props;
    const path = [
      args.obj,
      args.blockId,
      id.id,

    ]

    const obj = getObject(path[0]);
    const blockManager = new MyBlockManager(() => obj.blocks);
    const block2 = blockManager.findBlock(path[1]);
    const block = block2.block;
    const el = block.data.find(e => e[0]?.id == path[2]);

    if (id.type == ControlType.button) {
      const color = mainColors.find(c => c.key == id.color)?.color;
      return (
        <Container
        
          className={
            classNames('button', {
              hasColor: id.color,
            })
          }

          style={{
            // backgroundColor: mainColors.find(c => c.key == id.color)?.color,
            '--background-color': mainColors.find(c => c.key == id.color)?.color,
            '--highlight-color': color && Color(color).lighten(.2).string(),
          }}
        >
        <span
          onClick={() => {
            window['g_execFormulaFromData'](id.action);
          }}
          onContextMenu={e => {
            e.preventDefault();
            showContextMenu(e, [
              {
                text: 'Edit',
                onClick: () => {
                  triggerInspectObject({
                    type: '9ecf315e-722c-596b-867f-b39e019ae3f9',
                    args: {
                      path: [
                        args.obj,
                        args.blockId,
                        id.id,
                      ]
                    }
                  }, true);
                },
              },
              {
                text: 'Edit action',
                onClick: () => {
                  openWindow({
                    type: WindowType.Formula,
                    path: [
                      '8aaf9057-9113-5ffb-aece-6079bbee567a',
                      args.obj,
                      args.blockId,
                      id.id,
                      'action',
                    ]
                  })
                },
              }
            ])
          }}
        >
          {id.icon && (
            <span
              className="icon"
              style={{
                maskImage: `url("${codaIcons.find(i => i.name == id.icon).url('material')}")`,
                WebkitMaskImage: `url("${codaIcons.find(i => i.name == id.icon).url('material')}")`,
              }}
            >
              <svg width="100%" height="100%"><rect width="100%" height="100%"></rect></svg>
            </span>
          )}
          {el[0].title || 'Button'}</span> 
        </Container>
      )
    }
    else if (id.type == ControlType.reaction) {
      let key;

      if (this.props.args.entity) {
        key = `${this.props.args.entity}.${id.binding}`;
      }
      else {
        key = id.binding;
      }
      const slot = getDataSlot(key);
      const reacted = slot.get()?.includes?.(appState.user);
      return (
        <Container
          data-entity={this.props.args.entity}
          className={
            classNames('reaction', {
              reacted,
            })
          }
          onContextMenu={e => {
            e.preventDefault();
            showContextMenu(e, [
              {
                text: 'Edit',
                onClick: () => {
                  triggerInspectObject({
                    type: '9ecf315e-722c-596b-867f-b39e019ae3f9',
                    args: {
                      path: [
                        args.obj,
                        args.blockId,
                        id.id,
                      ]
                    }
                  }, true);
                },
              },
              // {
              //   text: 'Edit action',
              //   onClick: () => {
              //     openWindow({
              //       type: WindowType.Formula,
              //       path: [
              //         '8aaf9057-9113-5ffb-aece-6079bbee567a',
              //         args.obj,
              //         args.blockId,
              //         id.id,
              //         'action',
              //       ]
              //     })
              //   },
              // }
            ])
          }}

          onClick={() => {
            const users: any[] = XClone(slot.get()) || [];
            const index = users.indexOf(appState.user);
            if (index != -1) {
              users.splice(index, 1);
            }
            else {
              users.push(appState.user);
            }
            slot.set(X(users));
          }}
        >
          <span>{id.icon && <span className="icon"
            style={{
              backgroundImage: `url(${codaIcons.find(i => i.name == id.icon).url('color')})`,
            }}
          />} {slot.get()?.length}</span>
        </Container>
      )
    }
    else if (id.type == ControlType.checkbox) {
      let key;

      if (this.props.args.entity) {
        key = `${this.props.args.entity}.${id.binding}`;
      }
      else {
        key = id.binding;
      }
      const slot = getDataSlot(key);
      return (
        <Container
          data-entity={this.props.args.entity}
          className="checkbox"
          onContextMenu={e => {
            e.preventDefault();
            showContextMenu(e, [

              {
                text: 'Edit',
                onClick: () => {
                  triggerInspectObject({
                    type: '9ecf315e-722c-596b-867f-b39e019ae3f9',
                    args: {
                      path: [
                        args.obj,
                        args.blockId,
                        id.id,
                      ]
                    }
                  }, true);
                },
              },

            ])
          }}

          onClick={() => {
            slot.set(!slot.get());
          }}
        >
          <span>{slot.get() ? 'On' : 'Off'}</span>
        </Container>
      )
    }

  }
}

types['control'] = {
  type: Type.atomic,
  html: (id, comps, args) => {
    const n = Math.random();
    comps[n] = {
      mount: <Control id={id} args={args} />
    };
    return `<span class="--control-- button" contenteditable="false" data-type="control" data-control-data="${btoa(JSON.stringify(id))}"><span data-mount-point="${n}"></span></span>`;
  },
  part: el => {
    const data = JSON.parse(atob(el.getAttribute('data-control-data')));
    return [data, 'control'];
  }
};

// block el
registerInspectObjectHandler('9ecf315e-722c-596b-867f-b39e019ae3f9', {
  render(args) {
    const path = args.path;
    const obj = getObject(path[0]);
    const blockManager = new MyBlockManager(() => obj.blocks);
    const block2 = blockManager.findBlock(path[1]);
    const block = block2?.block;
    const el = block?.data?.find?.(e => e[0]?.id == path[2]);
    if (!el) return;
    console.log('poop', x(el));



    const control = el[0];

    let c;

    if (control.type == ControlType.button) {
      c = (
        <>
          <div>Title: <PropertyField object={control} property="title" /></div>


          <div>
            Color: <ColorSelector theme="primary" get={() => control.color} set={value => control.color = value} />
          </div>

          <div>
            Icon: <IconSelector get={() => control.icon} set={value => control.icon = value} />
          </div>
        </>
      )
    }
    else if (control.type == ControlType.reaction) {
      c = (
        <>

          <div>
            Icon: <IconSelector get={() => control.icon} set={value => control.icon = value} />
          </div>
        </>
      )
    }

    return (
      <>
          
          {control.binding && (
            <>
              <IdentifierEditor id={control.binding} />
            </>
          )}

        {c}
      </>
    )


  }
})

registerValueAccessor('8aaf9057-9113-5ffb-aece-6079bbee567a', {
  get: (path) => {
    const obj = getObject(path[0]);
    const blockManager = new MyBlockManager(() => obj.blocks);
    const block2 = blockManager.findBlock(path[1]);
    const block = block2.block;
    const el = block.data.find(e => e[0]?.id == path[2]);

    return el[0][path[3]];
  },
  set: (path, value) => {
    const obj = getObject(path[0]);
    const blockManager = new MyBlockManager(() => obj.blocks);
    const block2 = blockManager.findBlock(path[1]);
    const block = block2.block;
    const el = block.data.find(e => e[0]?.id == path[2]);
    el[0][path[3]] = value;
  },
})

enum BlockType {
  paragraph = 'c0d87c0d-5829-522d-9157-48c463cb3514',
  query = '289f4085-20de-5d55-883e-142440caa0ae',
  table = '471a7aa7-cc54-5854-894e-7543fdd01121',
  tableView = '9116a2c2-7876-53ed-9f33-b95946698d46',
  callout = '1fb91cda-8341-5ed0-be01-6fd97f361164',
}

enum ParagraphStyle {
  paragraph = '44542657-6557-532b-b87c-5ba18040fae6',
  header1 = '671dd3de-71ed-55e5-8a45-f01faf098786',
}


registerRoute((route, navigation) => {
  if (route[0] == Route.pageBlockEl) {
    const props = route[1];

    const page = db.pages2.findById(props.page);
    if (page.version == '65de94f4-2c38-5760-9f9a-30a89228b558') {
      const blockManager = new MyBlockManager(() => page.blocks);
      const block2 = blockManager.findBlock(props.block);
      if (!block2) return null;
      const block = block2.block;

      return (
        <DocumentBlockEditor key={block._id} block={block} 
          parent={{
            type: ObjectType.page,
            id: page._id,
          }}
        />
      )
    }
    else {
      const block = page.blocks.find(b => b._id === props.block);
      const entity = props.entity;
  
      // const types = [];
      return (
        <div key={props.page}>
          {!block.type && (
            <>
              {Object.values(BlockType).map((type, i) => {
                return (
                  <div key={type}>
                    <button
                      onClick={() => {
                        block.type = type;
                      }}
                    >{_.invert(BlockType)[type]}</button>
                  </div>
                )
              })}
            </>
          )}
          {block.type && (
            <>
              <h2>{_.invert(BlockType)[block.type]}</h2>
              {block.type == BlockType.paragraph && (
                <>
                  <div>Style: <select
                    value={block.style || ParagraphStyle.paragraph}
                    onChange={(e) => {
                      block.style = e.target.value;
                    }}
                  >
                    {Object.values(ParagraphStyle).map((style, i) => {
                      return (
                        <option key={style} value={style}>{_.invert(ParagraphStyle)[style]}</option>
                      )
                    })}
                    </select></div>
                </> 
              )}
  
              {block.type == BlockType.query && (
                <>
                  <button
                    onClick={() => {
                      const query = createQuery(null, null, {
                        type: ObjectType.page,
                        id: page._id,
                      }, true);
                      block.query = query._id;
        
                    }}
                  >Init</button>
  

                  {block.query && (
                   <>
                    <button
                      onClick={() => {
                        inspectObj({
                          type: ObjectType.query,
                          id: block.query,
                        })
                      }}
                    >Edit</button>
                   </> 
                  )}
                </>
              )}
  
              {block.type == BlockType.table && (
                <>
                  <button
                    onClick={() => {
                      const doc = XObject.obj<any>({
                        relative: true,
  
                      });
  
                      if (page.template) {
                        const handle = XObject.obj({
                          type: ObjectType.table,
                          parent: {
                            type: ObjectType.page,
                            id: page._id,
                          }
                        });
                      
                        doc.handle = handle._id;
                        doc.parent = {
                          type: ObjectType.entity,
                          id: page.entity,
                        }
                        doc.key = page.entity + ':' + handle._id;
  
                        db.objectHandles.push(handle);
  
                        block.handle = handle._id;
                      }
                      else {
                        doc.parent = {
                          type: ObjectType.page,
                          id: page._id,
                        }
                        block.table = doc._id;
  
                      }
                      db.tables.push(doc);
      
                    }}
                  >Init</button>
  
                  {/* {block.table && <button
                    onClick={() => {
                      const table = db.tables.findById(block.table);
                      block.handle = table.handle;
                      delete block.table;
                    }}
                  >Fix</button>} */}
  
                  <button
                    onClick={() => {
                      if (page.template) {
                        const key = entity + ':' + block.handle;
                        const table  = db.tables.find(t => t.key === key);
                        explicitInspectObj({
                          type: ObjectType.table,
                          id: table._id,
                        })
                      }
                      else {
                        explicitInspectObj({
                          type: ObjectType.table,
                          id: block.table,
                        })
    
                      }
                    }}
                  >Edit</button>
                </>
              )}
  
              {block.type == BlockType.tableView && (
                <>
                  <div>
                    Handle: <select
                      value={block.handle || ''}
                      onChange={(e) => {
                        block.handle = e.target.value;
                      }}
                    >
                      <option />
                      {db.objectHandles.map((handle, i) => {
                        return (
                          <option key={handle._id} value={handle._id}>{handle.name}</option>
                        )
                      })}
                    </select>
                  </div>
                </>
              )}

            </>
          )}
        </div>
      )
    }
  }
});


// block
registerInspectObjectHandler('7f7ecde6-9553-5ef5-ad25-35ae489a21cb', {
  padding: 0,
  render: (props: { page, block, entity }) => {
    return (
      <SystemContext.Provider
      value={{
        navigate: config => {
          appState.panes[0] = initPaneObject(config);
          console.log('asdfadfs');
        }
      }}
    >

      <NavStackCont
        state={props}
        root={[
          Route.pageBlockEl,
          {
            page: props.page,
            block: props.block,
            entity: props.entity,
          }
        ]}
      />
      </SystemContext.Provider>
    );
  }
});

@component
class Block extends Component<{ page, block, onSelect, active, entity }> {
  static styles = styled.div`
    position: relative;

    > svg {
      position: absolute;
      left: -${14 + 4}px;
      top: 0;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
    }
    min-height: 16px;

    &[data-type="${BlockType.paragraph}"] {
      &[data-paragraph-style="${ParagraphStyle.paragraph}"] {
      }

      &[data-paragraph-style="${ParagraphStyle.header1}"] {
        > svg {
          top: 10px;
        }
        font-size: 24px;
        font-weight: 500;
        margin-top: 20px;
      }
    }

    &.active {
      > svg {
        fill: #386abb;
      }
    }

    > svg {
      opacity: 0;
      padding-right: 4px;
    }

    &:hover, &.active {
      > svg {
        opacity: 1;
      }
    }
  `;

  state = X({});

  render(Container?) {
    let c;
    const { block, page } = this.props;
    const entity = this.props.entity || page.entity;

    if (this.props.block.type === BlockType.paragraph) {
      c = (
        <>
          <RichTextEditor
          key={page.editMode}
          readOnly={!page.editMode}
            value={() => block.data}
            setValue={value => block.data = value}
          />
        </>
      )
    }
    else if (this.props.block.type === BlockType.query) {
      const query = db.queries.findById(block.query);
      c = (
        <>
            <ViewQuery
              key={block._id}
              id={block.query}
              state={this.state}
              entity={entity}
              showToolbar={true}
              touched={e => {
              }}
            />
        </>
      )
    }
    else if (this.props.block.type === BlockType.table) {
      if (page.template) {
        // const key = entity + ':' + block.handle;
        const table = objectResource({ id: entity, type: ObjectType.entity }, block.handle);

        if (table) {
          c = (<NotionTable2
            active={null}
            state={this.state}
            table={table}
            editView={id => {
              triggerInspectObject({
                type: 'f803b269-1725-57ba-a8c6-dac2fa140e06',
                args: {
                  table: table._id,
                  view: id,
                },
              });
    
            }}
          />
    
    
          )
        }

        else {

        }



      }
      else {
        const table = db.tables.findById(block.table);
        c = table && (
          <NotionTable2
          active={null}
          state={this.state}
          table={table}
          editView={id => {
            triggerInspectObject({
              type: 'f803b269-1725-57ba-a8c6-dac2fa140e06',
              args: {
                table: table._id,
                view: id,
              },
            });
  
          }}
        />
  
  
        )
      }

    }
    else if (this.props.block.type === BlockType.tableView) {
      if (page.template) {
        const table = objectResource({ id: entity, type: ObjectType.entity }, block.handle);

        if (table) {
          c = (<NotionTable2
            active={null}
            state={this.state}
            table={table}
            views={XObject.get(block, 'views', [])}
            editView={id => {
              triggerInspectObject({
                type: 'f803b269-1725-57ba-a8c6-dac2fa140e06',
                args: {
                  // table: table._id,
                  page: page._id,
                  block: block._id,
                  view: id,
                },
              });
    
            }}
          />
    
    
          )
        }
      }
      else {
        c = 'Gest';

      }
    }
    return (
      <Container
        data-type={this.props.block.type}
        data-paragraph-style={this.props.block.style}
        className={cx({
          active: this.props.active
        })}>
        {page.editMode && <Svg name="grip"
        
        onClick={() =>{
          this.props.onSelect();
          triggerInspectObject({
            type: '7f7ecde6-9553-5ef5-ad25-35ae489a21cb',
            args: {
              page: this.props.page._id,
              block: this.props.block._id,
              entity: entity,
            }
          })
        }}
        />}

        {c}
      </Container>
    )
  }
}

function pageApp(id: string) {
  let current = {
    type: ObjectType.page,
    id,
  }

  while (current) {
    current = getObjectParent(current);
    if (current?.type == ObjectType.app) {
      return current.id;
    }
  }
}

@component
class Test extends Component {
  static styles = styled.div`color: red;`
  render(Container?) {
    return <>Hello</>
  }
}

@component
export class PageEditor extends Component<{ id; entity? }> {
  static styles = styled.div`
    padding: 0 40px;

    &.fullWidth > .innerCont {
      max-width: none;
    }

    > .innerCont {
      > ${EntityInspect} {
        border-bottom: 1px solid #f3f3f3;
        margin-bottom: 16px;
        /* margin: 0 -182px 16px; */
      }
      ${MyNotionDocument} {
        .wrapper {
          padding: 0;
        }
      }

      > .header {
        margin-bottom: 8px;
        position: relative;
        > .icon {
          position: absolute;
          width: 40px;
          height: 40px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          left: -47px;
          top: 10px;
        }
        > .title {
          display: block;
          font-size: 40px;
          font-weight: 400;
        }

        > .subtitle {
          display: block;
          font-size: 22px;
          color: rgb(142, 142, 142);
        }
      }

      max-width: 800px;
      margin: 0 auto;
      padding: 10px 0;
    }
  `;

  state = XInit(class {
    active
    state: any = {}
  })

  static contextType = SystemContext;
  context: SystemContextProps;

  blocks;

  okay = 0;

  observeExternalChanges = () => {
    const document = db.pages2.findById(this.props.id);
    XObject.observe(document.blocks, (change) => {
      console.log('outside change');
      ++ this.okay;
      ++ this.tick;
      this.blocks = XClone(document.blocks);
      this.forceUpdate();
    });
  }

  constructor(props) {
    super(props);
    const document = db.pages2.findById(this.props.id);

    if (!document)
      return;

    const blocks = XObject.get(document, 'blocks', []);
    if (!blocks.length) {
      blocks.push(XObject.obj({
        data: [],
        children: [],
      }));
    }

    this.blocks = XClone(blocks);

    this.observeExternalChanges();

    XObject.observe(this.blocks, (change) => {
      document.blocks = XClone(this.blocks);
      this.observeExternalChanges();
    });
  }

  timerId
  tick = 0
  setBlocks = (blocks) => {
    const document = db.pages2.findById(this.props.id);



    const update = () => {
      document.blocks = XClone(this.blocks);
      this.observeExternalChanges();
    }

    this.blocks = XClone(blocks);
    XObject.observe(this.blocks, (change) => {
      update();
      this.tick++;
      this.forceUpdate();  
    });

    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      update();
    }, 500);
  }


  margin
  componentDidMount() {
    const page = db.pages2.findById(this.props.id);
    if (!page.fullWidth) {
      const el = ReactDOM.findDOMNode(this);
      const innerCont = jQuery(el).children('.innerCont');
      const margin = (jQuery(el).width() - 800)/2;
      // console.log(jQuery(el).width(), innerCont, margin);
      this.margin = margin;
      this.forceUpdate();
    }

  }

  render(Container?) {
    const page = db.pages2.findById(this.props.id);
    if (!this.margin && !page.fullWidth) return;
    if (page.version == '65de94f4-2c38-5760-9f9a-30a89228b558') { 
      const entity = this.props.entity || page.entity;
      return (
        <Container className={page.fullWidth && 'fullWidth'}>
          <SystemContext.Provider
            value={{
              navigate: config => {
                if (config.type == 'entity' && !config.page) {
                  const appId = pageApp(this.props.id);
                  if (appId) {
                    const entity = getEntityById(config.id);
                    if (entity.type) {
                      const app = db.apps.findById(appId);
                      const handler = app?.navHandlers?.find?.(h => h.type == entity.type);
                      if (handler) {
                        console.log('handler', x(handler));
                        this.context.navigate({
                          type: 'entity',
                          id: config.id,
                          page: handler.page,
                        });
                        return;
                      }
                    }
                  }
                }

                this.context.navigate(config);

              },
              next: this.context.next,
            }}
          >
            <div className="innerCont">
              {this.props.entity && (
                <>
                  <EntityInspect
                    id={this.props.entity}
                    state={this.state.state}
                    onGraphClick={null}
                    onlyTop
                    config={{
                      menu: [
                        // {
                        //   text: 'Create match',
                        //   onClick: () => {
              
                        //   },
                        // }
                      ]
                    }}
                  /> 
                </>
              )}
              <div className="header">
                {page.icon && <span className="icon"
                  style={page.icon && {
                    backgroundImage: `url(${codaIcons.find(i => i.name == page.icon).url('color')})`
                  }}
                ></span>}
                <span className="title">{page.title}</span>
                <span className="subtitle">{page.subtitle}</span>
              </div>
              <MyNotionDocument
                key={this.okay.toString() + entity}
                obj={{
                  type: ObjectType.page,
                  id: this.props.id,
                }}
                tick={() => this.tick}
                baseEntity={entity}
                blocks={this.blocks}
                setBlocks={(blocks) => {
                  this.setBlocks(blocks);
                }}
                extendEntity={null}
                renderBlockArgs={{
                  entity,
                  margin: this.margin,
                }}
                onBlockSelected={block => {
                  triggerInspectObject({
                    type: '7f7ecde6-9553-5ef5-ad25-35ae489a21cb',
                    args: {
                      page: this.props.id,
                      block,
                      entity,
                    }
                  });
                }}
              />
            </div>
          </SystemContext.Provider>
        </Container>
      );
    }

    return 'hello';
    const blocks = XObject.get(page, 'blocks', []);
    return (
      <>
        <SystemContext.Provider
          value={{
            navigate: config => {
              if (config.type == 'entity') {
                const appId = pageApp(this.props.id);
                if (appId) {
                  const entity = getEntityById(config.id);
                  if (entity.type) {
                    const app = db.apps.findById(appId);
                    const handler = app.navHandlers.find(h => h.type == entity.type);
                    if (handler) {
                      console.log('handler', x(handler));
                      this.context.navigate({
                        type: 'entity',
                        id: config.id,
                        page: handler.page,
                      });
                      return;
                    }
                  }
                }
              }

              this.context.navigate(config);

            },
            next: this.context.next,
          }}
        >
          {blocks.map(block => {
            return (
              <Block
                key={block._id}
                entity={this.props.entity}
                active={this.state.active == block._id}
                page={db.pages2.findById(this.props.id)}
                block={block}
                onSelect={() => {
                  this.state.active = block._id;
                }}
              />
            );
          })}
          {page.editMode && <button
            onClick={() => {
              blocks.push(XObject.obj());
            }}
          >+</button>}
        </SystemContext.Provider>
      </>
    )
  }
}
