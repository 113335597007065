import { Component } from "react";
import { component, styled } from "./component";
import { XInit } from "./XObject";
import jQuery from "jquery";
import ReactDOM from "react-dom";
import _ from "lodash";
import classNames from "classnames";
import { ColumnManager, RowManager } from "./components/notionDatabase/ColumnManager";
import { Cell } from "./components/Cell";


const margin = 10;
@component
export class GalleryView extends Component<{
  columnManager: ColumnManager
  rowManager: RowManager

  columns: string[]

  previewColumn?
  renderPreview?

  onClickRow,
  active
}> {
  static styles = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -${margin/2}px;
    margin-right: -${margin/2}px;

    > .card {
      box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 2px 4px;
      border-radius: 3px;
      background: white;
      margin: ${margin/2}px;

      cursor: pointer;  

      transition: background 100ms;


      &:hover {
        background: #f9f9f8;
      }

      &.active {
        background: #eceff9;
      }


      .preview {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: block;


        &:hover {
          position: relative;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            background-color: rgba(0,0,0,0.1);
          }

        }
      }

      .name {
        padding: 8px 10px 10px;
        display: block;
        font-size: 14px;
        line-height: 1.5;
        min-height: 21px;
        font-weight: 500;
      }
    }
  `;

  state = XInit(class {
    cardWidth
  });

  timerId
  componentDidMount() {
    const update = () => {
      const width = jQuery(ReactDOM.findDOMNode(this)).width();
      const cols = 3;
      this.state.cardWidth = (width - margin*cols) / cols;
    }
    this.timerId = setInterval(() => {
      update();
    }, 10);
  }

  componentWillUnmount(): void {
    clearInterval(this.timerId);
  }

  render() {
    if (_.isNil(this.state.cardWidth)) return null;
    return (
      <>
        {this.props.rowManager.rows().map(row => {
          // const nameCol = table.columns.find(c => c.type == Type.name);
          // const previewCol = table.columns.find(c => c._id == this.props.view.galleryProperty);
          const previewCol = false;
          return (
            <div key={row.id()}
              onClick={() => {
                this.props.onClickRow(row.id());
              }}
              className={classNames('card', {
                active: this.props.active == row.id(),
              })}
              style={{ width: this.state.cardWidth }}
            >

              {previewCol && (
                <span className="preview"
                  onClick={e => {
                    e.stopPropagation();
                    // memoryAppState.image = uploadedFileUrl(row.values[previewCol._id]);
                  }}
                  style={{
                    // backgroundImage: `url(${uploadedFileUrl(row.values[previewCol._id])})`,
                    height: this.state.cardWidth/1.5,
                  }}
                />
              )}

              <span className="name">
                {this.props.columns.map(id => {
                  const col = this.props.columnManager.columns().find(c => c.id() == id);
                  if (!col) return;
                  const cell = col.cellObj(row);
                  return (
                    <div key={id}>
                      <Cell
                        readOnly
                        cell={cell}
                        get={() => row.value(id)}
                        set={() => {}}
                        title=""
                      />
                    </div>
                  )
                })}
              </span>

            </div>
          );
        })}
      </>
    )
  }
}
