import { db } from "../../db";
import { mapStructure } from "../main";
import { ObjectRefClass, ObjectType } from '../../types/ObjectRef';

export function executeEventOccurencesByEvent(value) {
  const mapped = mapStructure(value);
  return db.eventOccurrences.filter(x => x.event == mapped.event.content).map(x => {
    return new ObjectRefClass(ObjectType.eventOccurrence, x._id);
  });
}
