import { EvaluateParams, RenderParams } from './RenderParams';
import { CompiledValuePoint, RenderType } from './main';

export const structRenderers: {
  [id: string]: (value: CompiledValuePoint, map?, state?, renderType?: RenderType) => any;
} = {};

export const structEvalutors: {
  [id: string]: (value: CompiledValuePoint, map?) => any;
} = {};

export const structScriptEvaluators: {
  [id: string]: (params: EvaluateParams) => any;
} = {};

export const structParamRenderers: {
  [id: string]: (params: RenderParams) => any;
} = {};


export const structStyledCont = {};