import React, { Component } from 'react';
import * as ml from 'ml-distance';
import _ from 'lodash';
import { db } from '../db';
import { component, styled } from '../component2';
import { X, XClone, XInit, XObject, x } from '../XObject';
import { EntityRow } from './EntityRow';
import { QUERY, createEntityFromQuery, describeQuery, executeEntityQuery, executeEventOccurrencesQuery, queryChain, query_entityType } from '../etc/queryFuncs';
import { entityMetaStates } from '../etc/entityMatch';
import { defaultWorkspace } from '../etc/defaultWorkspace';
import { isId, showContextMenu } from '../helpers';
import { groupById } from '../glue/structs/groupById';
import { attributesForType, entitySchema } from './attributesForType';
import pluralize from 'pluralize';
import { renderAttributeValue } from '../glue/structs/renderAttributeValue';
import { createEntity, getEntityById } from '../etc/createEntity';
import { SystemContext, SystemContextProps } from '../etc/SystemContext';
import { NotionButton } from './AddButton';
import classNames from 'classnames';
import { openWindow, registerInspectObjectHandler, triggerInspectObject } from '../osHelpers';
import { ViewType, supportsGrouping } from '../types/ViewType';
import { EntityDatabase, MyColManager, MyRowManager } from '../glue/structs/$EntityDatabase';
import { ObjectPageFrame } from './ObjectPageFrame';
import { GraphView } from './Graph';
import { entityDisplayName } from './entityDisplayName';
import { getGraphParent, queryGraphBasic } from '../etc/queryGraph';
import { ObjectType } from '../types/ObjectRef';
import { showPrompt } from '../etc/showPrompt';
import { Svg } from './Svg';
import { NavStackCont } from './QueryViewEditor';
import { deleteEdge } from '../etc/getEdgesForEntity';
import { InsertionCont } from './InsertionCont';
import { ViewsList } from './ViewsList';
import { PaneType } from '../types/PaneType';
import { explicitInspectObj } from '../inspectObj';
import { isCordova, isMobile } from '../isMobile';
import { deleteObject } from '../etc/deleteEntity';
import { TimelineView } from '../TimelineView';
import { GalleryView } from '../GalleryView';
import { Board } from '../Board';
import { ChartView } from './ChartView';
import { CalendarView } from './CalendarView';
import { Route } from './registerRoute';
import { EventOccurrence } from './EventsRoot';
import { getAllEdges, pushEdge } from '../getAllEdges';
import { getEmbeddingsReactive } from '../getEmbeddings';
import { Clustering } from './Clustering';

export function executeQueryObj(id, baseId?) {
  let result = [];
  const query = db.queries.findById(id);

  const view = query.views?.find(v => v._id == query?.state?.activeView);

  result = executeEntityQuery(queryChain(query), undefined, baseId);

  if (view?.viewQuery) {
    result = executeEntityQuery(view.viewQuery, result);
  }


  if (view?.filterQuery) {
    result = executeEntityQuery(view.filterQuery, result);
  }

  if (view?.filter?.search) {
    result = result.filter(id => {
      
      if (entityDisplayName(id)?.toLowerCase().includes(view.filter?.search?.toLowerCase())) {
        return true;
      }
    });
  }

  return result;
}

@component
class Search extends Component<{
  value
  setValue
}> {
  static styles = styled.div`
    display: flex;
  `;

  state = XInit(class {
    show
  })

  render() {
    return (
      <>
        <NotionButton 
          img="icons8-search"
          onClick={() => {
            this.state.show = !this.state.show;
            if (!this.state.show) {
              this.props.setValue('');
            }
          }}
        />
        {(this.state.show || this.props.value) && <div>
          <input
        autoFocus
        onBlur={() => {
          if (!this.props.value) {
            this.state.show = false;
          }
        }}
        type="text" defaultValue={this.props.value || ''}
          onChange={e => {
            this.props.setValue(e.target.value);
          }}
        /></div>}
      </>
    )
  }
}

@component
class AISearch extends Component<{
  value
  setValue
}> {
  static styles = styled.div`
    display: flex;
  `;

  state = XInit(class {
    show
  })

  render() {
    return (
      <>
        <NotionButton 
          img="ai"
          onClick={() => {
            this.state.show = !this.state.show;
            if (!this.state.show) {
              this.props.setValue('');
            }
          }}
        />
        {(this.state.show || this.props.value) && <div>
          <input
        autoFocus
        onBlur={() => {
          if (!this.props.value) {
            this.state.show = false;
          }
        }}
        type="text" defaultValue={this.props.value || ''}
          onChange={e => {
            this.props.setValue(e.target.value);
          }}
        /></div>}
      </>
    )
  }
}


function viewIcon(v) {
  if (v.type == ViewType.table) return 'icons8-table';
  if (v.type == ViewType.list) return 'icons8-list';
  if (v.type == ViewType.graph) return 'icons8-tree-structure';
  if (v.type == ViewType.split) return 'icons8-columns';
}

function viewName(view) {
  if (view.name) {
    return view.name
  }
  else if (view.type == ViewType.list) {
    return 'List';
  }
  else if (view.type == ViewType.table) {
    return 'Table';
  }
  else if (view.type == ViewType.graph) {
    return 'Graph';
  }
  else if (view.type == ViewType.split) {
    return 'Split';
  }
  else if (view.type == ViewType.timeline) {
    return 'Timeline';
  }
  else if (view.type == ViewType.gallery) {
    return 'Gallery';
  }
  else {
    return 'View';
  }
}

function renderEntityView(view, state, { entities, grouped }, add, onClick?, baseId?,  uiContext?, getCount?, selected?, localBaseId?, outerMargin?) {
  if (view.type == ViewType.list) {
    if (grouped) {
      return grouped.map(({ key, group, entities }) => {
        return (
          <div key={group} className="group">
            <div className="groupName">{group == 'None' ? 'None' : renderAttributeValue(view.groupBy, key, group)}</div>
            {entities.map(entity => {
              return (
                <EntityRow
                  key={entity}
                  id={entity}
                  path={baseId}
                  _onClick={onClick && (() => {
                    onClick?.(entity)
                  })}
                  uiContext={uiContext}
                />
              )
            })}
          </div>
        );
      })
    }
    else {
      return entities.map(entity => {
        return (
          <EntityRow key={entity} id={entity} path={baseId} _onClick={onClick && (() => {
            onClick?.(entity)
          })}
          
          uiContext={uiContext}
          />
        )
      })
    }
  }
  else if (view.type == ViewType.table) {
    const attributes = view.entityTypes?.[0] ? attributesForType(view.entityTypes[0]) : [];
    return (
      <EntityDatabase
        baseId={baseId}
        active={selected}
        attributes={attributes}
        colStates={XObject.get(view, 'columns', {})}
        states={[]}
        entities={entities.map(x => x)}
        onClickAdd={add && ((props) => {
          console.log(props);
          add(false, props)
        })}
        groupBy={view.groupBy}
      />
    );
  }
  else if (view.type == ViewType.graph) {
    return (
      <GraphView
        _state={state}
        onSelect={onClick}
        selected={selected}
        _onDropExternal={(target, dropped) => {
          console.log('drop', getEntityById(target).name, getEntityById(dropped).name);

          const currentEdge = getAllEdges().find(e => e.entities[1] == dropped && e.directed);
          if (currentEdge) {
            deleteEdge(currentEdge._id);
          }

          pushEdge(XObject.obj({
            entities: [target, dropped],
            directed: true,
          }))
        }}
        _onMove={(id, from, to) => {
          const currentEdge = getAllEdges().find(x => x.entities.includes(from) && x.entities.includes(id));
          if (currentEdge) {
            deleteEdge(currentEdge._id);
          }

          const newEdge = XObject.obj({
            entities: [to, id],
            directed: true,
          });
          pushEdge(newEdge);
        }}
        label={id => {
          if (getCount) {
            return `${entityDisplayName(id)} (${getCount(id)})`;
          }
          else {
            return entityDisplayName(id);
          }
        }}
        data={entities.map(id => {
          return {
            id,
            meta: {
              parent: getGraphParent(null, id),
            }
          }
        })}
        root={null}
      />
    );
  }
  else if (view.type == ViewType.timeline) {
    return (
      <TimelineView
        onClickItem={id => {
          onClick?.(id);
          console.log('click', id, onClick);
        }}
        items={entities.map(id => {
          const entity = getEntityById(id);
          return {
            _id: id,
            start: entity.attributes?.[view.startTimeProperty],
            end: entity.attributes?.[view.endTimeProperty],
            title: entityDisplayName(id),
          }
        })}
        set={(id, start, end) => {
          const entity = getEntityById(id);
          if (!entity.attributes) {
            entity.attributes = X({
              [view.startTimeProperty]: start,
              [view.endTimeProperty]: end,
            })
          }
          else {
            entity.attributes[view.startTimeProperty] = start;
            entity.attributes[view.endTimeProperty] = end;

          }
        }}
      />
    );
  }
  else if (view.type == ViewType.gallery) {
    const attributes = view.entityTypes?.[0] ? attributesForType(view.entityTypes[0]) : [];

    return (
      <>
        <GalleryView
          active={null}
          onClickRow={id => {
            onClick?.(id);
          }}
          columnManager={new MyColManager(attributes, [], XObject.get(view, 'columns', {}))}
          rowManager={new MyRowManager(entities)}
          columns={XObject.get(view, 'columns', {})['@index2']}
        />
      </>
    )
  }
  else if (view.type == ViewType.chart) {
    const attributes = view.entityTypes?.[0] ? attributesForType(view.entityTypes[0]) : [];
    return (
      <>
        <ChartView
          columnManager={new MyColManager(attributes)}
          rowManager={new MyRowManager(entities)}
          view={view}
        />
      </>
    )
  }
  else if (view.type == ViewType.calendar) {
    const attributes = view.entityTypes?.[0] ? attributesForType(view.entityTypes[0]) : [];
    return (
      <>
        <CalendarView
          columnManager={new MyColManager(attributes)}
          rowManager={new MyRowManager(entities)}
          view={view}
          onClickRow={id => {
            onClick(id);
          }}
        />
      </>
    )
  }
  else if (view.type == ViewType.board) {
    const attributes = view.entityTypes?.[0] ? attributesForType(view.entityTypes[0]) : [];
    return (
      <Board
        columnManager={new MyColManager(attributes, [], XObject.get(view, 'columns', {}))}
        rowManager={new MyRowManager(entities, (props) => {
          return add(false, props);
        })}
        view={view}
        onCardClick={id => {
          onClick(id);
        }}
      />
    );
  }
  else if (view.type == ViewType.clustering) {
    return <Clustering 
    view={view}

    active={selected}
    
    connectAll={view.connectAll}
    connectClosest={view.connectClosest}
    
    allThreshold={parseFloat(view.allTreshold) || 1} 
    closestThreshold={parseFloat(view.closestThreshold) || 0} 
      
      entities={entities} onClickEntity={onClick} attribute={view.clusterAttribute} />;
  }
}

function renderEventOccurrencesView(view, state, entries) {
  if (view.type == ViewType.list) {
    return (
      entries.map(e => <EventOccurrence key={e} id={e} />)
    )
  }
  return 'invalid view';
}

interface ViewManager {
  get(): {
    _id
    type
    groupBy
    name
    viewQuery
    filterQuery
  }[]
  addView()
  init()
  editView(id)
  deleteView(id)
}

registerInspectObjectHandler('33118f77-16d5-5d79-b69b-04c172f186c0', {
  padding: 0,
  render: args => {
    return <NavStackCont
      state={args}

      root={[Route.tableView, {
        query: args.query,
        view: args.view,
        space: args.space,
        doc: args.doc,
      }]}
    />
  }
})



@component
export class ViewQueryish extends Component<{
  viewManager: ViewManager,
  entities, state; entity?, showToolbar?, touched?, add? }> {
  static styles = styled.div`
    .toolBar {
      ${NotionButton} {
        height: 28px;
      }
      display: flex;
      margin-bottom: 8px;
      align-items: center;
      .right {
        margin-left: auto;
        display: flex;
        align-items: center;

        select {
          margin-right: 6px;
        }

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    .header {
      display: flex;
      align-items: center;
      .right {
        margin-left: auto;
        display: flex;
        align-items: center;
      }

      .views {
        display: flex;
        .view {
          cursor: pointer;
          padding: 0 4px;
          &.active {
            /* border-bottom: 2px solid black; */
          }
        }
      }
      /* border-bottom: 1px solid #ccc; */
      margin-bottom: 8px;
    }
    .group {
      margin-bottom: 8px;

      .groupName {
        font-size: 14px;
        font-weight: bold;
      }
    }
    .sectionTitle {
      display: flex;
      align-items: center;
      ${NotionButton} {
        margin-left: auto;
      }
    }

  `
  state = XInit(class {
  })
  static contextType = SystemContext;
  context: SystemContextProps;
  render() {
    const showToolbar = this.props.showToolbar ?? true;
    let result = this.props.entities;

    let viewManager = this.props.viewManager;
    const canAddEntity = this.props.add;

    viewManager.init();
    const views = viewManager.get();

    const renderQuery = (mmmstate, onClick, baseId) => {
      if (!mmmstate.activeView && views[0]?._id) {
        mmmstate.activeView = views[0]?._id;
      }
  
      let view = views.find(v => v._id == mmmstate.activeView);
      if (!view && mmmstate.activeView) {
        mmmstate.activeView = views[0]?._id;
        view = views[0];
      }



      if (view?.viewQuery) {
        result = executeEntityQuery(view.viewQuery, result);
      }


      if (view?.filterQuery) {
        result = executeEntityQuery(view.filterQuery, result);
      }

      // if (view.filter)

      // console.log(x(view));
  
      const renderEntitiesSection = ({
        state = null as {
          onlyShowBackground?: boolean,
          includeBackgroundEntities?: boolean,
          // groupBy?: string,
          contentsType
          search
          aiSearch
        },
        // title,
        contents,
        // customCheckboxes = undefined,
        // after = undefined,
        // underEntity = undefined,
        map = undefined,
        baseQuery = undefined,
      }) => {
        let orgContents = contents;
  
        if (map) {
          contents = contents.map(map).filter(Boolean);
        }
        const filterBackgroundEntities = c => {
          const metaStates = entityMetaStates(c);
          const background = metaStates.find(id => {
            const metaState = defaultWorkspace().metaStates.find(m => m._id == id);
            if (metaState.backgroundState) return true;
          });
    
          if (state.onlyShowBackground) {
            if (!background) return false;
          }
          else if (background && !state.includeBackgroundEntities) return false;
    
    
          return true;
        }
        contents = contents.filter(filterBackgroundEntities);
  
        const allCount = contents.length;
  
        const types = {};
        for (const id of contents) {
          const entity = getEntityById(id);
          if (!entity) continue;
          const type = entity.type && db.entityTypes.findById(entity.type);
          if (type) {
            if (!types[type._id]) types[type._id] = 0;
            types[type._id]++;
          }
          else {
            if (!types['untyped']) types['untyped'] = 0;
            types['untyped']++;
          }
        }
    
        const orgSameType = (() => {
          // return true if all entities are of the same type
          let type;
          for (const id of contents) {
            const entity = getEntityById(id);
            if (!entity) continue;
            if (!type) {
              type = entity.type;
            }
            else if (type != entity.type) {
              return false;
            }
          }
          return type;
        })();
  
        if (state.contentsType) {
          contents = contents.filter(e => {
            if (state.contentsType == 'untyped') return !getEntityById(e).type;
    
            const entity = getEntityById(e);
            if (entity) {
              return entity.type === state.contentsType;
            }
            return false;
          });
        }
  
        const singleType = isId(state.contentsType) || orgSameType;
  
        const grouped = view && supportsGrouping(view.type) && view.groupBy && groupById(orgContents, view.groupBy);

  
        const filter = list => {
          return list?.map?.(cc => {
            let c;
            if (map) {
              c = map(cc);
            }
            else {
              c = cc;
            }
  
            if (!contents.includes(c)) return null;
  
            return c;

          }).filter(Boolean);
        }
  
        const add: any = canAddEntity && ((nav=true) => {
          this.props.add(view);
        });
  
        return (
          <>
            {showToolbar && (
              <div className="toolBar">
                <ViewsList
                  views={views?.map?.(v => ({
                    _id: v._id,
                    name: viewName(v),
                    icon: viewIcon(v),
                  }))}
                  active={mmmstate.activeView}
                  onClickView={id => {
                    mmmstate.activeView = id;
                  }}
                  contextMenu={id => {
                    return [
                      {
                        text: 'Edit!',
                        onClick: () => {
                          this.props.viewManager.editView(id);
                        }
                      },
                      {
                        text: 'Delete',
                        onClick: () => {
                          this.props.viewManager.deleteView(id);
                        }
                      },
                    ]
                  }}
                  onAdd={(i, e) => {
                    viewManager.addView();
                  }}
                  onDelete={() => {}}
                />
                <div className="right">  
                  {!orgSameType && (
                    <select value={state.contentsType || ''}
                      onChange={e => {
                        state.contentsType = e.target.value;
                        delete view.groupBy;
                      }}
                    >
                      <option value="">All ({allCount})</option>
                      {Object.keys(types).filter(t => t != 'untyped').map(t => (
                        <option key={t} value={t}>{pluralize(db.entityTypes.findById(t).name)} ({types[t]})</option>
                      ))}
                      {types['untyped'] > 0 && <option value="untyped">Untyped ({types['untyped']})</option>}
                    </select>
                  )}

                  {/* <NotionButton
                    img={false}
                    text="Filter"
                    onClick={() => {
                      this.props.viewManager.editView(view._id);
                    }}
                  /> */}

                  <AISearch
                    value={state.aiSearch}
                    setValue={v => {
                      state.aiSearch = v;
                    }}

                  />

                  <Search
                    value={state.search}
                    setValue={v => {
                      state.search = v;
                    }}
                  />

                  {add && <NotionButton
                    img="icons8-create (2)"
                    onClick={() => {
                      add();

                    }}
                  />}
                </div>
              </div>
            )}
            {view && orgContents && renderEntityView(
              view,
              state, 
              {
                entities: filter(orgContents),
                grouped: grouped && grouped.map(({ key, group, entities }) => {
                  const r = filter(entities);
                  if (!r?.length) return;
                  return {
                    key,
                    group,
                    entities: r,
                  }
                }).filter(Boolean),
              },
              add,
              onClick,
              this.props.entity,
              this.props.touched,
              null,
              null,
              this.context?.next?.()?.id,
            )}
          </>
        );
      }
  
      return renderEntitiesSection({
        contents: result,
        state: view ? XObject.get(view, 'filter', {}) : {},
      });
    }

    return renderQuery(this.props.state, null, this.props.entity);
  }
}

const outerMarginQueries =[
  // ViewType.timeline
]



@component
export class ViewQuery extends Component<{ id; state; entity?, showToolbar?, touched?, outerMargin? }> {
  static styles = styled.div`
    display: flex;
    flex-direction: column;
    .toolBar {
      flex: 0 0 auto;
      ${NotionButton} {
        height: 28px;
      }
      overflow: auto;

      display: flex;
      align-items: center;

      .right {
        margin-left: auto;
        display: flex;
        align-items: center;

        svg {
          width: 16px;
          height: 16px;
        }

        .create {
          svg {
            fill: #88a4ff;
          }
        }

        .add {
          user-select: none;
          transition: background 20ms ease-in 0s;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          border-radius: 3px;
          line-height: 1.1;
          padding-left: 8px;
          padding-right: 8px;
          font-weight: 500;
          background: rgb(35, 131, 226);
          color: white;
          display: block;

          position: relative;
          display: inline-flex;
          flex-shrink: 0;
          border-radius: 3px;
          overflow: hidden;
          height: 28px;
          margin-left: 8px;
          font-size: 14px;
        }
      }
    }

    .queryView {
      position: relative;
      flex: 1 1 auto;
      min-height: 50px;
    }

    .header {
      display: flex;
      align-items: center;
      .right {
        margin-left: auto;
        display: flex;
        align-items: center;
      }

      .views {
        display: flex;
        .view {
          cursor: pointer;
          padding: 0 4px;
          &.active {
            /* border-bottom: 2px solid black; */
          }
        }
      }
      /* border-bottom: 1px solid #ccc; */
      margin-bottom: 8px;
    }
    .group {
      margin-bottom: 8px;

      .groupName {
        font-size: 14px;
        font-weight: bold;
      }
    }
    .sectionTitle {
      display: flex;
      align-items: center;
      ${NotionButton} {
        margin-left: auto;
      }
    }

    .layers {
      display: flex;
      /* position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0; */
      .layer {
        position: relative;
        flex: 1 1 0;
        border-right: 2px solid #ccc;
        overflow: auto;
        display: flex;
        flex-direction: column;
        /* padding: 8px; */

        .queryView {
          padding: 8px;
        }
      }
    }

    &.layered {
      display: flex;
      flex-direction: column;
      .layers {
        flex: 1 1 auto;
        overflow: auto;
      }
    }

    .hasFilter {
      color: rgb(35, 131, 226);
    }

    .aiSearch {
      svg {
        width: 15px;
        height: 15px;
        fill: rgb(167, 130, 195);
      }
    }
  `
  state = XInit(class {
  })
  static contextType = SystemContext;
  context: SystemContextProps;

  sorting

  async updateAiSearch() {

  }

  timerId

  render(Container?) {
    const query = db.queries.findById(this.props.id);
    const showToolbar = this.props.showToolbar ?? true;
    const st = XObject.get(query, 'state', {});

    if (query.type == 'eventOccurrences') {
      const result = executeEventOccurrencesQuery(query.query);
      console.log(result);
      let view = query.views.find(v => v._id == st.activeView);
      if (!view && st.activeView) {
        st.activeView = query.views[0]?._id;
        view = query.views[0];
      }
      return (
        <>
          {showToolbar && (
            <div className="toolBar">
              <ViewsList
                active={st.activeView}
                contextMenu={id => {
                  return [
                    {
                      text: 'Edit',
                      onClick: () => {
                        triggerInspectObject({
                          type: '33118f77-16d5-5d79-b69b-04c172f186c0',
                          args: {
                            query: query._id,
                            view: id,
                          }
                        }, true);
                      }
                    },
                    {
                      text: 'Delete',
                      onClick: () => {
                        query.views.splice(query.views.findIndex(v => v._id == id), 1);
                      }
                    },
                  ]
                }}
                onAdd={(i, e) => {
                  if (!query.views) {
                    XObject.push(query, 'views', XObject.obj({
                      type: ViewType.list,
                    }));

                  }
                  else {
                    query.views.splice(i, 0, XObject.obj({
                      type: ViewType.list,
                    }));
                  }

                }}
                onClickView={id => {
                  st.activeView = id;
                  triggerInspectObject({
                    type: '33118f77-16d5-5d79-b69b-04c172f186c0',
                    args: {
                      query: query._id,
                      view: id,
                    }
                  });

                }}
                onDelete={id => {


                }}
                views={query.views?.map?.(v => ({
                  _id: v._id,
                  icon: viewIcon(v),
                  name: viewName(v),
                }))}
              />
              {/* <div className="right">
                <Search
                  value={state.search}
                  setValue={v => {
                    state.search = v;
                  }}
                />
                {add && view?.type != ViewType.board && <NotionButton
                  className="create"
                  img="icons8-create (2)"
                  onClick={() => {
                    add();
                  }}
                />}
              </div> */}
            </div>
          )}
          <div className="queryView">
            {renderEventOccurrencesView(view, {}, result)}
          </div>
        </>
      )
    }
    else {
      const renderQuery = (query2: {
        layered
        views
        _id
        layers
        parent
        parentType
      }, mmmstate, onClick, baseId, getCount?, selected?, localBaseId?) => {
        let result;
  
        if (query2?.layered) {
          result = [];
  
          const q = {
            query: query2.layers[0].query,
            parentType: query2.parentType,
            parent: query2.parent,
          }
  
          const firstLayer = executeEntityQuery(queryChain(q), undefined, baseId);
          for (const id of firstLayer) {
            const r = executeEntityQuery(query2.layers[1].query, undefined, id);
            if (r.length) {
              result.push(...r);
            }
          }
  
          result = _.uniq(result);
  
        }
        else {
          if (query2) {
            const baseRels = {};
            if (_.isArray(baseId)) {
              result = [];
              for (const id of baseId) {
                result.push(...executeEntityQuery(queryChain(query2), undefined, id));
              }
            }
            else {
              result = executeEntityQuery(queryChain(query2), undefined, baseId, baseRels);
            }
  
    
          }
        }
  
        if (!query2.views) {
          XObject.push(query2, 'views', XObject.obj({
            name: 'Default',
            type: ViewType.list,
          }));
        }
    
    
        if (!mmmstate.activeView && query2.views[0]?._id) {
          mmmstate.activeView = query2.views[0]?._id;
        }
    
        let view = query2.views.find(v => v._id == mmmstate.activeView);
        if (!view && mmmstate.activeView) {
          mmmstate.activeView = query2.views[0]?._id;
          view = query2.views[0];
        }
  
        if (view?.viewQuery) {
          result = executeEntityQuery(view.viewQuery, result);
        }
  
  
        if (view?.filterQuery) {
          result = executeEntityQuery(view.filterQuery, result);
        }


    
        const renderEntitiesSection = ({
          state = null as {
            onlyShowBackground?: boolean,
            includeBackgroundEntities?: boolean,
            // groupBy?: string,
            contentsType
            search
            aiSearch
          },
          // title,
          body = undefined,
          contents = undefined as string[],
          // customCheckboxes = undefined,
          // after = undefined,
          // underEntity = undefined,
          map = undefined,
          baseQuery = undefined,
        }) => {
          contents = _.uniq(contents);
          let orgContents = contents;
    
          if (map) {
            contents = contents.map(map).filter(Boolean) as any;
          }
          const filterBackgroundEntities = c => {
            const metaStates = entityMetaStates(c);
            const background = metaStates.find(id => {
              const metaState = defaultWorkspace().metaStates.find(m => m._id == id);
              if (metaState.backgroundState) return true;
            });
      
            if (state.onlyShowBackground) {
              if (!background) return false;
            }
            else if (background && !state.includeBackgroundEntities) return false;
      
      
            return true;
          }
          contents = contents.filter(filterBackgroundEntities);
    
          const allCount = contents.length;
    
          const types = {};
          for (const id of contents) {
            const entity = getEntityById(id);
            if (!entity) continue;
            const type = entity.type && db.entityTypes.findById(entity.type);
            if (type) {
              if (!types[type._id]) types[type._id] = 0;
              types[type._id]++;
            }
            else {
              if (!types['untyped']) types['untyped'] = 0;
              types['untyped']++;
            }
          }
      
          const orgSameType = (() => {
            // return true if all entities are of the same type
            let type;
            for (const id of contents) {
              const entity = getEntityById(id);
              if (!entity) continue;
              if (!type) {
                type = entity.type;
              }
              else if (type != entity.type) {
                return false;
              }
            }
            return type;
          })();
    
          if (state.contentsType) {
            contents = contents.filter(e => {
              if (state.contentsType == 'untyped') return !getEntityById(e).type;
      
              const entity = getEntityById(e);
              if (entity) {
                return entity.type === state.contentsType;
              }
              return false;
            });
          }
    
          const singleType = isId(state.contentsType) || orgSameType;
    
          const grouped = view && supportsGrouping(view.type) && view.groupBy && groupById(orgContents, view.groupBy);
  
    
          const filter = list => {
            return list?.map?.(cc => {
              let c;
              if (map) {
                c = map(cc);
              }
              else {
                c = cc;
              }
    
              if (!contents.includes(c)) return null;
    
              return c;
  
            }).filter(Boolean);
          }
    
          const createBase = _.isArray(baseId) ? baseId[0] : baseId;
          const add = (createEntityFromQuery(queryChain(query2), {}, createBase) !== false) && (async (nav=true, props={}) => {
            let entity:any = {
              attributes: props,x
            };
            createEntityFromQuery(queryChain(query2), entity, createBase);
            if (state.contentsType && !entity.type) {
              entity.type = state.contentsType;
            }
  
            if (view.viewQuery) {
              createEntityFromQuery(view.viewQuery, entity);
            }
  
  
            if (view.filterQuery) {
              createEntityFromQuery(view.filterQuery, entity);
            }
            
  
            if (view.type == ViewType.graph && (state as any).selected) {
              entity.$edges = [];
              entity.$edges.push({
                from: (state as any).selected || baseId,
                directed: true
              })
            }
  
            if (view.type == ViewType.graph) {
              entity.name = await showPrompt('Name');
            }  
            entity = createEntity(entity, createBase);
  
            if (isMobile()) {
              this.context.navigate({
                type: 'entity',
                id: entity._id,
              })
            }
  
            return entity;
      
          });
    
          return (
            <>
              {showToolbar && (
                <div className="toolBar">
                  <ViewsList
                    active={mmmstate.activeView}
                    contextMenu={id => {
                      return [
                        {
                          text: 'Edit',
                          onClick: () => {
                            triggerInspectObject({
                              type: '33118f77-16d5-5d79-b69b-04c172f186c0',
                              args: {
                                query: query._id,
                                view: id,
                              }
                            }, true);
                          }
                        },
                        {
                          text: 'Delete',
                          onClick: () => {
                            query2.views.splice(query2.views.findIndex(v => v._id == id), 1);
                          }
                        },
                      ]
                    }}
                    onAdd={(i, e) => {
                      if (!query2.views) {
                        XObject.push(query2, 'views', XObject.obj({
                          type: ViewType.list,
                        }));
  
                      }
                      else {
                        query2.views.splice(i, 0, XObject.obj({
                          type: ViewType.list,
                        }));
                      }
  
                    }}
                    onClickView={id => {
                      mmmstate.activeView = id;
                      triggerInspectObject({
                        type: '33118f77-16d5-5d79-b69b-04c172f186c0',
                        args: {
                          query: query._id,
                          view: id,
                        }
                      });
  
                    }}
                    onDelete={id => {
  
  
                    }}
                    views={query2.views?.map?.(v => ({
                      _id: v._id,
                      icon: viewIcon(v),
                      name: viewName(v),
                    }))}
                  />
                  <div className="right">
                    {!orgSameType && (
                      <select value={state.contentsType || ''}
                        onChange={e => {
                          state.contentsType = e.target.value;
                          delete view.groupBy;
                        }}
                      >
                        <option value="">All ({allCount})</option>
                        {Object.keys(types).filter(t => t != 'untyped').map(t => (
                          <option key={t} value={t}>{pluralize(db.entityTypes.findById(t).name)} ({types[t]})</option>
                        ))}
                        {types['untyped'] > 0 && <option value="untyped">Untyped ({types['untyped']})</option>}
                      </select>
                    )}

                    {/* <button
                      onClick={() => {
                        console.log(result.map(id => entityDisplayName(id)).join('\n') )
                      }}
                    >.</button> */}
  

                  <AISearch
                    value={state.aiSearch}
                    setValue={v => {
                      state.aiSearch = v;
                      clearTimeout(this.timerId);
                      this.timerId = setTimeout(() => {
                        this.updateAiSearch();
                      }, 1000);
                    }}

                  />

                    <Search
                      value={state.search}
                      setValue={v => {
                        state.search = v;
                      }}
                    />
                    
                    {add && view?.type != ViewType.board && <NotionButton
                      className="create"
                      img="icons8-create (2)"
                      onClick={() => {
                        add();
                      }}
                    />}
                  </div>
                </div>
              )}
              <div className="queryView"
                style={{
                  ...(
                    view?.type == ViewType.graph ? {
                    minHeight: '500px'
                  } : {}),
  
                  ...(
                    this.props.outerMargin && outerMarginQueries.includes(view?.type) ? {
                      paddingLeft: this.props.outerMargin,
                      marginLeft: -this.props.outerMargin,
                      marginRight: -this.props.outerMargin + 20,
                    } : {}),
              
                  }}
              >
                {body}

                {!body && view && orgContents && renderEntityView(
                  view,
                  state,
                  {
                    entities: filter(orgContents),
                    grouped: grouped && grouped.map(({ key, group, entities }) => {
                      const r = filter(entities);
                      if (!r?.length) return;
                      return {
                        key,
                        group,
                        entities: r,
                      }
                    }).filter(Boolean),
                  },
                  add,
                  onClick,
                  _.isArray(baseId) ? baseId[0] : baseId,
                  {
                    type: ObjectType.query,
                    id: this.props.id,
                  },
                  getCount,
                  selected,
                  localBaseId,
                  this.props.outerMargin,
                )}
              </div>
            </>
          );
        }

  
        if (view?.filter?.search) {
          result = result.filter(id => {
            
            if (entityDisplayName(id)?.toLowerCase().includes(view.filter?.search?.toLowerCase())) {
              return true;
            }
          });
        }

        if (view?.filter?.aiSearch) {
          const text = [view.filter.aiSearch, ...result.map(id => entityDisplayName(id))]
          const embeddings = getEmbeddingsReactive(text);

          if (embeddings) {
            const filterEmbedding = embeddings[0];
            const sims = {};
            for (let i = 0; i < result.length; ++ i) {
              const entityEmbedding = embeddings[i + 1];
              const sim = ml.similarity.cosine(filterEmbedding, entityEmbedding);
              sims[result[i]] = sim;

            }
          
            result.sort((a, b) => sims[b] - sims[a])

            return renderEntitiesSection({
              body: (
                <table border={0} width="100%">
                  {/* <thead>
                    <tr>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead> */}
                  <tbody>
                  {result.map(id => {
                    return (
                      <tr key={id}><td>{sims[id]}</td> <td width="100%"><EntityRow id={id} /></td></tr>
                    ) 
                  })}
                  </tbody>
                </table>
              ),
          state: view ? XObject.get(view, 'filter', {}) : {},

            });
              
  
          }
          else {
            return renderEntitiesSection({
              body: 'Loading...',
          state: view ? XObject.get(view, 'filter', {}) : {},

            });
          }



        }



        return renderEntitiesSection({
          contents: result,
          state: view ? XObject.get(view, 'filter', {}) : {},
        });
      }
  
      
      if (query.layered && query.views?.find?.(v => v._id == st.activeView)?.type == ViewType.split) {
        const view = query.views?.find?.(v => v._id == st.activeView);
        const selected = XObject.get(view, 'layerSelection', {});
        return (
          <Container className={classNames({
            layered: query.layered,
          })}
          >
            <div className="toolBar">
              <ViewsList
                active={st.activeView}
                views={query.views.map(v => ({
                  _id: v._id,
                  name: viewName(v),
                  icon: viewIcon(v),
                }))}
                contextMenu={id => {
                  return [
                    {
                      text: 'Edit',
                      onClick: () => {
                        triggerInspectObject({
                          type: '33118f77-16d5-5d79-b69b-04c172f186c0',
                          args: {
                            query: query._id,
                            view: id,  
                          }
                        }, true);
                      }
                    },
                    {
                      text: 'Delete',
                      onClick: () => {
                        query.views.splice(query.views.findIndex(v => v._id == id), 1);
                      }
                    },
                  ]
                }}
                
                onAdd={() => {
                  XObject.push(query, 'views', XObject.obj({
                    // name: 'New view',
                    type: ViewType.list,
                  }));
  
                }}
  
                onClickView={id => {
                  st.activeView = id;
  
                }}
                onDelete={() => {
  
                }}
              />
  
              {/* <InsertionCont 
                tag="div"
                className="views"
                onInsert={() => {}}
                orientation="horizontal"
              >
                {query.views?.map?.(v => (
                    <span
                      className={classNames('view', { active: st.activeView == v._id})}
                      onClick={() => {
                        
                      }}
                      onContextMenu={e => {
                        e.preventDefault();
                        showContextMenu(e, [
                          {
                            text: 'Edit',
                            onClick: () => {
                              triggerInspectObject({
                                type: '33118f77-16d5-5d79-b69b-04c172f186c0',
                                args: {
                                  query: query._id,
                                  view: v._id,  
                                }
                              }, true);
                            }
                          },
                          {
                            text: 'Delete',
                            onClick: () => {
                              query.views.splice(query.views.indexOf(v), 1);
                            }
                          },
                        ]);
                      }}
                    >
                      <Svg name={viewIcon(v)} /> {viewName(v)}
                    </span>
                  ))}
  
              </InsertionCont> */}
              </div>
              <div className="layers">
                {query.layers.map((layer, i) => {
                  const prevSelected = selected[i - 1];
                  const prevView = query.layers[i - 1]?.views?.find?.(v => v._id == query.layers[i - 1].state.activeView);
                  let graphSelect;
  
                  if (prevView?.type == ViewType.graph) {
                    if (i > 0) {
                      const descendants = queryGraphBasic(prevSelected, true, false, false).map(e => e.entity);
                      graphSelect = descendants;
                    }
                  }
  
                  return (
                    <div key={layer._id} className="layer">
                      {renderQuery(
                        layer,
                        XObject.get(layer, 'state', {}),
                        i < query.layers.length - 1 && (id => {
                          selected[i] = id;
                        }),
                        i == 0 ? this.props.entity : (graphSelect ? graphSelect : prevSelected),
                        i == 0 && query.layers.length > 1 && (id1 => {
                          const q = {
                            query: query.layers[0].query,
                            parentType: query.parentType,
                            parent: query.parent,
                          }
                          const firstLayer = executeEntityQuery(queryChain(q), undefined, id1);
                          let result = [];
                          for (const id of firstLayer) {
                            const r = executeEntityQuery(query.layers[1].query, undefined, id);
                            if (r.length) {
                              result.push(...r);
                            }
                          }
                  
                          result = _.uniq(result);
  
                          const activeViewId = query.layers[1]?.state?.activeView;
                          const activeView = query.layers[1]?.views?.find?.(v => v._id == activeViewId);
  
                          if (activeView?.viewQuery) {
                            result = executeEntityQuery(activeView.viewQuery, result);
                          }
  
  
                          if (activeView?.filterQuery) {
                            result = executeEntityQuery(activeView.filterQuery, result);
                          }
                  
                          return result.length;
                        }),
                        selected[i],
  
                      )}
                    </div>
                  )
                })}
            </div>
          </Container>
        );
      }
      else {
        return (
          <Container>
            {renderQuery(
              query,
              st,
              id => {
                this.context?.navigate?.({
                  type: 'entity',
                  id,
                })
              },
              this.props.entity,
              null,
              this.context?.next?.()?.id,
            )}
          </Container>
        );
      }
    }
  }
}


@component
export class ViewQueryPage extends Component<{ id, state, entity?, pathId }> {
  static contextType = SystemContext;
  context: SystemContextProps;
  static styles() {
    return styled.div`
      .queryName {
        font-size: 26px;
        font-weight: 600;
        display: block;
        line-height: 1.2;
      }
      ${ObjectPageFrame.t.content} {

        /* padding: 16px calc(44px + 16px); */
        padding: 16px calc(0 + 16px);
        display: flex;
        flex-direction: column;
        ${ViewQuery} {
          flex: 1 1 auto;
          position: relative;
        }
      }
      &.layered {
        ${ObjectPageFrame.t.content} {
          display: flex;
          flex-direction: column;
        
          ${ViewQuery} {
            flex: 1 1 auto;
            position: relative;
          }
        }
      }
    `;
  }

  render(Container?) {
    const query = db.queries.findById(this.props.id);
    return (
      <Container
        className={classNames({
          layered: query.layered,
        })}
      >
        <ObjectPageFrame 
          obj={{
            type: ObjectType.query,
            id: this.props.id,
          }}
          pathId={this.props.pathId}
          icon="database"
          path={query.name || describeQuery(query.query)}
          right={(
            <>
              <span className="more"
                onClick={e => {
                  showContextMenu(e, [
                    {
                      text: 'Edit',
                      onClick: () => {
                        explicitInspectObj({
                          type: ObjectType.query,
                          id: this.props.id,
                        })
                      }
                    },
                    {
                      text: 'Delete',
                      onClick: () => {
                        deleteObject(query);
                      }
                    },
                  ], true);
                }}
              >
                <Svg name="dots" />
              </span>

              {isCordova() && <span className="graph"
                onClick={() => {
                  this.context.navigate({
                    type: PaneType.graph,
                  })
                }}
              >
                <Svg name="icons8-mind-map" />
              </span>}
            </>
          )}
        >
          <span className="queryName">{query.name || describeQuery(query.query)}</span>
          <ViewQuery id={this.props.id} state={this.props.state} entity={this.props.entity} />
        </ObjectPageFrame>
      </Container>
    )
  }
}
