import { ValueType } from "../ValueType";
import { typeRegistry } from "../typeRegistry.1";

export const $EntitySwitch = typeRegistry.registerType({
  $: '6e5d58b6-9072-553d-9489-2b324aab6841',
  Entity: '9b58b453-bddd-530d-9dd9-079647d18d45',
  Entries: {
    $: '768654df-e498-541a-956e-bdd538e74fa0',
    $$: 'ab9093a0-46b0-509a-a129-39366eb4b3c7',
    Name: 'b035aaa6-ca3c-5a1b-845f-decbdca6d9a6',
    Content: '7451f229-ddb5-59f0-b708-8ae2378b2cf8',
    Match: 'dc2d6cc1-da40-5cfa-a77c-433609501ad9',
  }
}, ids => ({
  _id: ids.$,
  name: 'Entity Switch',
  definition: [
    {
      id: ids.Entries.$,
      name: 'Entries',
      type: [ValueType.Array, [ValueType.Structure, ids.Entries.$$, [
        {
          id: ids.Entries.Name,
          name: 'Name',
          type: [],
          property: 'name'
        },
        {
          id: ids.Entries.Match,
          name: 'Match',
          type: [],
          property: 'match',
        },
        {
          id: ids.Entries.Content,
          name: 'Content',
          type: [],
          property: 'content',
        }
      ]]],
      property: 'entries'
    },
    {
      id: ids.Entity,
      name: 'Entity',
      type: [],
      property: 'entity',
    },
  ],
}));