import React from 'react';
import { XInit } from "./XObject";
import { component } from './component2';
import { Component } from 'react';
import { isId } from './helpers';
import { styled } from './component';
import Around from './components/Around';
import { ColorEditor } from './ColorEditor.1';


@component
export class ColorDisplay extends Component<{ devRuntime; colorValue; setColorValue; }> {
  state = XInit(class {
    showPicker;
  });
  static styles = styled.span`
    white-space: nowrap;
    .swatch {
      display: inline-block;
      width: 11px;
      height: 11px;
      margin-right: 4px;
      vertical-align: middle;
    }
  `;
  static debounce = false;
  render(Container?) {

    const ref = React.createRef<any>();


    const value = this.props.colorValue();

    let name, color;

    if (isId(value)) {
      const colorObj = this.props.devRuntime.devProject.colors.find(c => c._id == value);
      name = colorObj.name;
      color = colorObj.color;
    }
    else {
      name = value;
      color = value;
    }


    return (
      <Container ref={ref}>
        <span
          onClick={() => {
            this.state.showPicker = !this.state.showPicker;
          }}
        >
          <span className="swatch"
            style={{
              backgroundColor: color
            }} />
          {name}
        </span>
        {this.state.showPicker && <Around
          anchor={() => ref.current}
        >
          <ColorEditor
            devRuntime={this.props.devRuntime}
            setValue={this.props.setColorValue}
            value={this.props.colorValue()} />
        </Around>}
      </Container>
    );
  }
}
