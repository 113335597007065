const resolveListOf = (cst, map?) => {
  const NonemptyListOf = cst.children[0];
  if (!NonemptyListOf) return [];
  const r = [];

  if (NonemptyListOf.children[0]) r.push([NonemptyListOf.childOffsets[0], map ? map(NonemptyListOf.children[0], NonemptyListOf.childOffsets[0]) : NonemptyListOf.children[0]]);

  if (!NonemptyListOf?.children?.[2]?.children?.length) return r;
  for (let i = 0; i < NonemptyListOf.children[2].children.length; ++i) {
    r.push([
      NonemptyListOf.children[2].childOffsets[i],
      map ? map(NonemptyListOf.children[2].children[i], NonemptyListOf.children[2].childOffsets[i]) : NonemptyListOf.children[2].children[i],
    ]);
  }

  return r;
};
function mapUnrwap(cst, text, pos) {
  let matchLengthAdjust = 0;
  if (cst.ruleName == 'AttributeValue_expr') {
    pos += 1;
    matchLengthAdjust = -1;
  }
  return {
    ruleName: cst.ruleName,
    source: text.slice(pos, pos + cst.matchLength + matchLengthAdjust),
    absoluteOffset: pos,
    childOffsets: cst.childOffsets || [],
    matchLength: cst.matchLength + matchLengthAdjust,
    children: cst.children?.map?.((child, i) => unwrapCst(child, text, pos + cst.childOffsets[i])) || [],
    cst,
    terminal: cst.constructor.name == 'TerminalNode',
  };
}
interface CST {
  ruleName: any;
  childOffsets: any;
  matchLength: any;
  children: any;
  cst: any;
  terminal: boolean;
}
export function unwrapCst(cst, text, pos = 0): CST {
  if (!cst) return;
  const terminal = [
    'Expr_for',
    'Expr_elseIf',
    'Expr_if',
    'Expr_else',
    'Or_eq',
  ];

  const singleWrapper = [
    'Or',
    'Expr',
    'And',
    'Eq',
    'Ne',
    'Lt',
    'Lte',
    'Gt',
    'Gte',
    'Additive',
    'Multiplicative',
    'Primary',
    'FunctionCall',
    'Accessor',
    'Array',
    'Dictionary',
    'Closure',

    'DictionaryMod',
    'Element',

    'AttributeValue',

    'Child',

    'DictionaryKey',
  ];

  if (cst.optional) {
    return unwrapCst(cst.children[0], text, pos);
  }
  else if (cst.ruleName == 'ListOf') {
    return resolveListOf(cst, (cst, p) => unwrapCst(cst, text, pos + p)) as any;
  }
  else if (singleWrapper.includes(cst.ruleName)) {
    if (cst.children.length != 1) {
      console.log(cst);
      throw new Error();
    }
    else {
      return unwrapCst(cst.children[0], text, pos);
    }
  }
  else {
    return mapUnrwap(cst, text, pos);
  }
}
