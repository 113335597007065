export const stackSystems = {};
export function registerStackSystem(id, stackSystem: {
  render?
  width?
  height?
  padding?

}) {
  stackSystems[id] = stackSystem;
}
