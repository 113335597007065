import React, { Component } from 'react';
import { component } from './component2';
import { XObject } from './XObject';
// import { DevProjectView } from './DevProjectView';


@component
export class DevProjectWindow extends Component<{ window; }> {
  render() {
    return '';
    // return <DevProjectView id={this.props.window.id} state={XObject.get(this.props.window, 'state', {})} />;
  }
}
