import React from 'react';
import ReactDOM from 'react-dom';
import jQuery from 'jquery';
import _ from 'lodash';
import md5 from 'md5';
import { FormulaObjectProxy } from './FormulaObjectProxy';
import { createScriptWrapper } from "./MyNotionDocument/ScriptWrapper";
import { X, XGuard, XObject, XTouch, ignoreAccesses, x } from "./XObject";
import { assetTypeId, colorTypeId, componentTypeId, nodeTypeId, slotTypeId } from "./componentTypeId";
import { renderDesignComponent } from "./renderDesignComponent";
import { executeScriptFromData } from "./shorthand/executeScriptFromData";
import { Bindingg, Pass, StringWrapper, Thingy, expandFormula, generateCss, generateCss2, renderEl, scriptEmpty, unwrapString } from "./shorthand/formula";
import { CallBlock, LoopBlock, RuntimeContext, RuntimeTrace, RuntimeTraceCont, ValueBlock } from './shorthand/RuntimeTrace';
import { uploadedFileUrl } from './components/FileUpload';
import { RemoteSvg } from './components/Svg';
import { renderBuilderNode } from './BuilderComponentEditor';
import { component } from './component2';
import { toast } from 'react-toastify';
import { Component, ComponentType } from 'react';
import { SignalContext } from './shorthand/SignalContext';
import { DevProjectType, CompType, getDevProjectEditorState, getEditorState } from './DevProject.1';
import { generateDesignerCss } from './CSSDesigner';
import { Type } from './richTextHelpers';
import { substitute } from './shorthandEditor/substitute';
import { OverlayManager } from './OverlayManager';
import { StyleManager } from './StyleManager';
import { isId, showContextMenu } from './helpers';
import { ColorDisplay } from './ColorDisplay';
import { execFormulaFromData } from './shorthandEditor/execFormulaFromData';
import { db } from './db';
import { createEntityFromQuery, executeEntityQuery, queryChain } from './etc/queryFuncs';
import { addAttrToSchema, attributesForType } from './components/attributesForType';
import { createEntity } from './etc/createEntity';
import { getEdgesForEntity } from './etc/getEdgesForEntity';
import { deleteEntity } from './etc/deleteEntity';
import { ComponentEntry } from './ComponentEntry';
import { styled } from './component';
import { findWorkspace } from './findWorkspace';
import { El } from './shorthand/El';
import fuzzy from 'fuzzy';
import { Node, CComponent, StyleNode, Block } from './Node';
import { SyntaxHighlighter } from './SyntaxHighlighter';



function findBlock(blocks, id) {
  for (const block of blocks) {
    if (block._id == id) {
      return block;
    }

    if (block.children) {
      const r = findBlock(block.children, id);
      if (r) return r;
    }
  }
}

class NodeTag {
  constructor(public id) {}
}

class DetachedNodeComp {
  constructor(public id) {}
}

@component
class ComponentBadge extends Component<{ devRuntime: DevRuntime, component, _onClick }> {
  render() {
    return (
      <span
        onMouseDown={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onClickCapture={e => {
          e.preventDefault();
          e.stopPropagation();
          this.props._onClick();
        }}
        onContextMenu={e => {
          e.preventDefault();
          showContextMenu(e, [
            {
              text: 'Open in library',
              onClick: () => {
                const editorState = getDevProjectEditorState(this.props.devRuntime.devProject);
                editorState.inspectTab = 'library';
                const dataLibraryTab = XObject.get(editorState, 'dataLibraryTab', {});
                dataLibraryTab.activePage = this.props.component._id;
              },
            }
          ])
        }}
      >{this.props.component.name}</span>
    )
  }
}

@component
class AssetBadge extends Component<{ asset }> {
  static styles = styled.span`
    svg {
      height: 10px;
      width: 10px;
      margin-right: 3px;
    }
  `;
  render() {
    const asset = this.props.asset;
    return <><RemoteSvg url={uploadedFileUrl(asset.file)} />{asset.name}</>
  }
}


@component
class AssetEntry extends Component<{ asset }> {
  static styles = styled.div`
    display: flex;
    align-items: center;
    svg {
      margin-right: 4px;
    }
  `;
  render() {
    const a = this.props.asset;
    return (
      <>
        <><RemoteSvg url={uploadedFileUrl(a.file)} />{a.name}</>
      </>
    )
  }
}


const DebugWrapper = styled.div`
  font-size: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  svg {
    width: 12px;
    height: 12px;
  }

  img {
    width: 12px;
    height: 12px;
  }

  .comp {
    background-color: #cde2ff;
    font-size: 7px;
    padding: 4px 3px;
    border-radius: 6px;
  }

  .plainObject {
    display: inline-block;
    max-width: 149px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
  }

  .arg {
    display: inline-block;
    &:not(:last-child) {
      &:after {
        content: ',';
        margin-right: 2px;
      }
    }
  }
`;

export class DevRuntime {
  constructor(public devProject: DevProjectType, public navigate, public db?, public extra?) {

  }

  consoleEntries() {
    return this.state.consoleEntrie;
  }

  instanceIdToPath = {};



  resetDebug() {
    console.log('reset')
    this.blockDebug = {};
    this.loggedCalls = []
    this.ticks.blockDebug ++;  
  }

  debuggingEnabled
  // activeInstance

  getActiveInstance() {
    return getDevProjectEditorState(this.devProject).activeInstance;
  }

  getComponentInstance(instanceId) {
    if (instanceId.includes(':')) {
      return this.componentInstancesByPath[instanceId];
    }
    else {
      if (this.componentInstancesById[instanceId]) {
        return this.componentInstancesById[instanceId]
      }
      else {
        const path = this.instanceIdToPath[instanceId];
        if (path) {
          return this.componentInstancesByPath[path];
        }
      }
    }
  }

  selectInstance(instanceId) {
    if (instanceId) {
      if (instanceId.includes(':')) {
        getDevProjectEditorState(this.devProject).activeInstance = instanceId;
        const instance = this.componentInstancesByPath[instanceId];
  
        this.instanceDebugInfo = {};
        if (!this.instanceDebugInfo[instanceId]) {
          this.instanceDebugInfo[instanceId] = {
            csCont: new RuntimeTraceCont,
            enabled: false,
            chosenFunctionCalls: {},
          }
        }
  
        if (!this.instanceDebugInfo[instanceId].enabled) {
          this.instanceDebugInfo[instanceId].enabled = true;
          if (instance) {
            instance.forceUpdate();
            this.ticks.activeInstance++;
          }
        }
  
        this.functionCallDebug = this.instanceDebugInfo[instanceId].chosenFunctionCalls;
  
        setTimeout(() => {
          this.refreshCallStack();
        }, 0)
  
      }
      else if (this.componentInstancesById[instanceId]) {
        this.selectInstance(this.componentInstancesById[instanceId]._pathStr());
      }
    }

  }

  cleanup() {
    this.overlay.cleanup();
    this.overlay2.cleanup();
    this.styleManager.cleanup();
    this.styleManager2.cleanup();

    for (const id in this.designerEls) {
      jQuery(this.designerEls[id]).remove();
    }

    for (const id in this.styleEls) {
      jQuery(this.styleEls[id]).remove();
    }

  }

  getComponent(id) {
    return this.devProject.components.find(c => c._id == id);
  }

  deleteComponent(id) {
    const index = this.devProject.components.findIndex(c => c._id == id);
    if (index != -1) {
      this.devProject.components.splice(index, 1);
    }
  }

  findNode(id) {
    for (const comp of this.devProject.components) {
      if (comp.type == 'builderComponent') {
        const checkNode = (node) => {
          if (node._id == id) {
            return node;
          }
          else {
            if (node.children) for (const child of node.children) {
              const r = checkNode(child);
              if (r) return r;
            }
          }
        }

        const r = checkNode(comp.root);
        if (r) {
          return [
            new Node(r),
            new CComponent(comp),
          ]
        }

        if (comp.providedSlots) {
          for (const pv of comp.providedSlots) {
            for (const rootNode of pv.nodes) {
              const r = checkNode(rootNode);
              if (r) {
                return [
                  new Node(r),
                  new CComponent(comp),
                ]
              }
            }
          }
        }
      }
      else if (comp.type == 'designerComponent') {

      }
      else {

      }
    }
  }

  findDetachedNode(id) {
    for (const comp of this._components()) {
      if (comp.detachedNodes) {
        for (const node of comp.detachedNodes) {
          if (node._id == id) {
            return node;
          }
        }
      }
    }
  }

  findSlot(id) {
    for (const comp of this._components()) {
      if (comp.slots) {
        for (const node of comp.slots) {
          if (node._id == id) {
            return node;
          }
        }
      }
    }
  }

  setComponentParent(id, value) {
    const getParent = (blocks, parent) => {
      for (const block of blocks) {
        if (block.id == id) {
          return parent;
        }
        if (block.children) {
          const r = getParent(block.children, block);
          if (r) return r;
        }
      }
    }

    const getBlock = (blocks, id) => {
      for (const block of blocks) {
        if (block.id == id) {
          return block;
        }
        if (block.children) {
          const r = getBlock(block.children, id);
          if (r) return r;
        }
      }
    }


    const parent = getParent(this.devProject.library.rootBlocks, null);
    if (parent) {
      parent.children.splice(parent.children.findIndex(b => b.id == id), 1);
    }


    const newParent = getBlock(this.devProject.library.rootBlocks, value);
    if (newParent) {
      XObject.push(newParent, 'children', XObject.obj({
        type: 'component',
        id,
      }));
    }
  }

  getComponentParent(id) {
    const iter = (blocks, parent) => {
      for (const block of blocks) {
        if (block.id == id) {
          return parent?.id;
        }
        if (block.children) {
          const r = iter(block.children, block);
          if (r) return r;
        }
      }
    }

    return iter(this.devProject.library.rootBlocks, null);
  }

  selectEvent(id) {
    this.state.activeEvent = id;
    setTimeout(() => {
      this.refreshCallStack();
    }, 0);
  }

  logEvent(args: {
    trace,
    event,
    instanceId,
    blockId,
  }) {

    const event = {
      _id: XObject.id(),
      componentId: this.componentInstancesById[args.instanceId].componentId,
      timestamp: new Date(),
      ...args,
    };

    this.state.eventEntries.push(XGuard(event));
    this.state.activeEvent = event._id;

    setTimeout(() => {
      this.refreshCallStack();
    }, 0);
    
  }

  getComponentPath(id) {
    const ancestors = [];

    const iter = id2 => {
      const comp = this.getComponent(id2);
      if (comp?.parent) {
        if (ancestors.includes(comp.parent)) {
          ancestors.unshift(comp.parent);
          console.log('loop detected', id);
        }
        else {
          ancestors.unshift(comp.parent);
          iter(comp.parent);            
        }
      }
    }

    iter(id);

    return ancestors;

  }

  findStyleNode(id): any[] {
    for (const comp of this.devProject.components) {
      const iterStyleNode = node => {
        if (node._id == id) {
          return node;
        }
        if (node.children) for (const child of node.children) {
          const r = iterStyleNode(child);
          if (r) return r;
        }
      }
      const iterNode = node => {
        if (id == node._id) {
          return [new Node(node), new CComponent(comp)];
        }

        if (node.designer) {
          const r = iterStyleNode(node.designer);
          if (r) {
            return [new StyleNode(r), new Node(node), new CComponent(comp)];
          }  
        }

        if (node.children) {
          for (const child of node.children) {
            const r = iterNode(child);
            if (r) return r;
          }
        }
      }

      if (comp.root) {
        const r = iterNode(comp.root);
        if (r) return r;  
      }

      if (comp.providedSlots) {
        for (const pv of comp.providedSlots) {
          for (const rootNode of pv.nodes) {
            const r = iterNode(rootNode);
            if (r) return r;
          }
        }
      }
    }
  }

  findBlock(id) {
    for (const comp of this.devProject.components) {
      if (comp.type == 'builderComponent') {
        const checkNode = (node) => {
          let r = node.script && findBlock(node.script, id);
          if (r) {
            return [
              new Block(r, 'script'),
              new Node(node),
            ]
          }

          r = node.styles && findBlock(node.styles, id);
          if (r) {
            return [
              new Block(r, 'styles'),
              new Node(node),
            ]
          }



          else {
            if (node.children) for (const child of node.children) {
              const r = checkNode(child);
              if (r) return r;
            }
          }
        }

        const r = checkNode(comp.root);

        if (comp.providedSlots) {
          for (const pv of comp.providedSlots) {
            for (const rootNode of pv.nodes) {
              const r = checkNode(rootNode);
              if (r) {
                return [
                  ...r,
                  new CComponent(comp),
                ]
      
              }
            }
          }
        }
        if (r) {
          return [
            ...r,
            new CComponent(comp),
          ]
        }
      }
      else if (comp.type == 'designerComponent') {

      }
      else {
        const checkBlocks = blocks => {
          for (const block of blocks) {
            if (block._id == id) {
              return block;
            }

            if (block.children) {
              const r = checkBlocks(block.children);
              if (r) return r;
            }
          }
        }

        const r = checkBlocks(comp.blocks);
        if (r) {
          return [
            new Block(r, undefined),
            new CComponent(comp),
          ]
        }
      }
    }
  }

  findWorkspaceFrame(id) {
    const iter = (nodes) => {
      if (nodes) for (const node of nodes) {
        if (node.frames) for (const frame of node.frames) {
          if (frame._id == id) return frame;
        }
        const r = iter(node.children);
        if (r) return r;
      }
    }

    return iter(this.devProject.workspaceStructure)
  }

  findLibraryFrame(id) {
    for (const page of Object.values(this.devProject.library.componentPages)) {
      if (page?.frames) for (const frame of page.frames) {
        if (frame._id == id) return frame;
      }

    }
  }

  withDb(db) {
    return new DevRuntime(this.devProject, this.navigate, db);
  }

  _components() {
    return XObject.get(this.devProject, 'components', []);
  }

  componentCache = {};

  state = X({
    // debuggingEnabled: false,
    consoleEntrie: [],

    eventEntries: [],

    activeEvent: null,
  });

  setDebuggingEnabled(enabled) {
    this.resetDebug();
    getDevProjectEditorState(this.devProject).debuggingEnabled = enabled;
  }

  isDebuggingEnabled() {
    return getDevProjectEditorState(this.devProject).debuggingEnabled;
  }

  componentInstances = {}

  _hooks() {
    return this._execute().hooks;  
  }

  __types() {
    return {
      component: {
        type: Type.atomic,
        html: (data, comps) => {
          const n = Math.random();

          const component = this.getComponent(data);
          comps[n] = {
            mount: <ComponentBadge devRuntime={this} component={component} _onClick={() => {
              // this.props.onClickComponentBadge(component);
              // console.log(data);
              this.extra.navigateToComponent(data);
            }} />,
          };
          // <span data-mount-point="${n}"></span>
          return `<span class="--badge--" contenteditable="false" data-type="component" data-component-data="${btoa(JSON.stringify(data))}"><span data-mount-point="${n}" data-unmounted></span></span>`;

          // return `<span class="--badge--" contenteditable="false" data-type="component" data-component-data="${btoa(JSON.stringify(data))}">${component?.name || '(deleted)'}</span>`;
        },
        text: (id, _, args) => {
          if (args?.humanReadable) {
          const component = this.devProject.components.find(c => c._id == id);
          return component.name;

          }
          else {
            return `_$${componentTypeId}:${id}$_`;

          }
        },
        part: el => {
          const data = JSON.parse(atob(el.getAttribute('data-component-data')));
          return [data, 'component'];
        },
      },
      color: {
        type: Type.atomic,
        html: (data, comps, args, part, xPart) => {
          const n = Math.random();
          comps[n] = {
            mount: <ColorDisplay
              devRuntime={this}
              colorValue={() => xPart[0]}
              setColorValue={value => xPart[0] = value}

            />,
          };
          return `<span class="--badge--" contenteditable="false" data-type="color" data-color-data="${btoa(JSON.stringify(data))}"><span data-mount-point="${n}"></span></span>`;
        },
        text(id) {
          if (isId(id)) {
            return `_$${colorTypeId}:${id}$_`;
          }
          else {
            return id;
          }
        },
        part: el => {
          const data = JSON.parse(atob(el.getAttribute('data-color-data')));
          return [data, 'color'];
        }
      },
      asset: {
        type: Type.atomic,
        html: (data, comps, args, part, xPart) => {
          const n = Math.random();

          const asset = this.devProject.assets.find(a => a._id == data);
          comps[n] = {
            // mount: <><RemoteSvg url={uploadedFileUrl(asset.file)} /> {asset.name}</>,
            mount: <AssetBadge asset={asset} />,
          };
          return `<span class="--badge--" contenteditable="false" data-type="asset" data-asset-data="${btoa(JSON.stringify(data))}"><span data-mount-point="${n}"></span></span>`;
        },
        text(id) {
          if (isId(id)) {
            return `_$${assetTypeId}:${id}$_`;
          }
          else {
            return id;
          }
        },
        part: el => {
          const data = JSON.parse(atob(el.getAttribute('data-asset-data')));
          return [data, 'asset'];
        }
      },
      node: {
        type: Type.atomic,
        html: (data, comps) => {
          const n = Math.random();
          const node = this.findDetachedNode(data);

          // const component = this.devProject.components.find(c => c._id == data);
          // comps[n] = {
          //   mount: <ComponentBadge component={component} _onClick={() => {
          //     this.props.onClickComponentBadge(component);
          //   }} />,
          // };
          // <span data-mount-point="${n}"></span>
          return `<span class="--badge--" contenteditable="false" data-type="node" data-node-data="${btoa(JSON.stringify(data))}">${node.data || '(deleted)'}</span>`;
        },
        text: (id, _, args) => {

          if (args?.humanReadable) {
            const node = this.findDetachedNode(id);
            return node.data;
          }
          else {
            return `_$${nodeTypeId}:${id}$_`;
          }
        },
        part: el => {
          const data = JSON.parse(atob(el.getAttribute('data-node-data')));
          return [data, 'node'];
        },
      },

      slot: {
        type: Type.atomic,
        html: (data, comps) => {
          const n = Math.random();
          const node = this.findSlot(data);

          // const component = this.devProject.components.find(c => c._id == data);
          // comps[n] = {
          //   mount: <ComponentBadge component={component} _onClick={() => {
          //     this.props.onClickComponentBadge(component);
          //   }} />,
          // };
          // <span data-mount-point="${n}"></span>
          return `<span class="--badge--" contenteditable="false" data-type="slot" data-slot-data="${btoa(JSON.stringify(data))}">${node.name || '(deleted)'}</span>`;
        },
        text: (id, _, args) => {

          if (args?.humanReadable) {
            const node = this.findSlot(id);
            return node.data;
          }
          else {
            return `_$${slotTypeId}:${id}$_`;
          }
        },
        part: el => {
          const data = JSON.parse(atob(el.getAttribute('data-slot-data')));
          return [data, 'slot'];
        },
      },
    }
  }

  __menu(args, opts: {
    importedComponents
    component?
  }) {
    return (filter) => {
      let comps = this._components().filter(c => !c.excludeFromLibrary);
      let options = comps.map(c => ({
        label: <ComponentEntry comp={c} devRuntime={this} />,
        filterText: c.name,
        key: c._id,
        action: () => {
          substitute(args, [c._id, 'component']);
        },

        section: 'Components',
      }));

      options = options.concat(this.devProject.assets.filter(a => a.name).map(a => {
        return {
          key: a._id,
          filterText: a.name,
          label: <AssetEntry asset={a} />,
          action: () => {
            substitute(args, [a._id, 'asset']);
          },
          section: 'Icons'
        }
      }));

      options = options.concat({
        label: 'Insert color',
        key: 'color',
        action: () => {
          const color = 'black';
          substitute(args, [color, 'color']);
        },
        section: 'Colors',
        filterText: 'Insert color',
      })

      options = options.concat(this.devProject.colors.map(c => {
        return {
          key: c._id,
          label: <span
          style={{
            display: 'flex',
            gap: '4px',
            alignItems: 'center',
          }}
          ><span style={{
            width: 8,
            height: 8,
            backgroundColor: c.color,
          }}></span> {c.name}</span>,
          filterText: c.name,
          action: () => {
            substitute(args, [c._id, 'color']);
          },
          section: 'Colors',
        }
      }))

      if (opts.component?.slots) {
        options = options.concat(opts.component.slots.map(s => {
          return {
            label: s.name,
            key: s._id,
            filterText: s.name,
            section: 'Slots',
            action: () => {
              substitute(args, [s._id, 'slot']);

            }
          }
        }));
      }

      filter = filter.replace(new RegExp(String.fromCharCode(160), 'g'), ' ')

      // options = options.filter(o => o.filterText?.toLowerCase?.().includes?.(filter.toLowerCase()));
      options = options.filter(o => fuzzy.test(filter, o.filterText || ''));


      if (filter) {
        options = options.concat({
          label: `Create UI component "${filter}"`,
          key: 'create',
          action: () => {
            const comp = this.createBuilderComponent(filter, {
              parent: opts.component?._id,

            });

            const editorState = getDevProjectEditorState(this.devProject);
            if (editorState.mode == 'workspace') {
              const workspace = findWorkspace(editorState.activeWorkspace, this.devProject.workspaceStructure);
              const frame = this.findWorkspaceFrame(workspace.selectedFrame);
              const frameEl = document.querySelector(`[data-canvas-component="${workspace.selectedFrame}"]`)

              const bounds = frameEl.getBoundingClientRect();

              const coords = {
                x: frame.x + bounds.width*workspace.scale + 20,
                y: frame.y,
                
              }

              const frameObj = XObject.obj({
                ...coords,
                component: comp._id,
              });
              comp.workspaceFrame = frameObj._id;

              workspace.frames.push(frameObj);

            }

            substitute(args, [comp._id, 'component']);
          },
          section: 'Create',
        });

        if (opts.component) {
          options = options.concat({
            label: `Create UI component fragment "${filter}"`,
            key: 'create',
            action: () => {
              const comp = this.createBuilderComponent(filter, {
                detachedNode: true,
                baseComponent: opts.component._id,
                parent: opts.component._id,
              });
  
              const editorState = getDevProjectEditorState(this.devProject);
              if (editorState.mode == 'workspace') {
                const workspace = findWorkspace(editorState.activeWorkspace, this.devProject.workspaceStructure);
                const frame = this.findWorkspaceFrame(workspace.selectedFrame);
                const frameEl = document.querySelector(`[data-canvas-component="${workspace.selectedFrame}"]`)
  
                const bounds = frameEl.getBoundingClientRect();
  
                const coords = {
                  x: frame.x + bounds.width*workspace.scale + 20,
                  y: frame.y,
                  
                }
  
                const frameObj = XObject.obj({
                  ...coords,
                  component: comp._id,
                });
                comp.workspaceFrame = frameObj._id;
  
                workspace.frames.push(frameObj);
  
              }
  
              substitute(args, [comp._id, 'component']);
            },
            section: 'Create',
          });
        }

        options = options.concat({
          label: `Create script component "${filter}"`,
          key: 'create',
          action: () => {
            const comp = this.createScriptComponent(filter, {
              parent: opts.component._id,
            });
            substitute(args, [comp._id, 'component']);
          },
          section: 'Create',
        });

        if (opts.component) {
          options = options.concat({
            label: `Create slot "${filter}"`,
            key: 'create',
            action: () => {
              const slot = XObject.obj({ name: filter });
              XObject.push(opts.component, 'slots', slot);
              
              // const comp = this.createScriptComponent(filter, {
              //   parent: opts.component._id,
              // });
              substitute(args, [slot._id, 'slot']);
            },
            section: 'Create',
          });
        }


      }

      return options;
    }
  }

  renderEl(el, path) {
    return renderEl(el, {}, {}, {}, {}, path)
  }


  componentInstancesById = {}
  componentInstancesByPath = {}

  componentInstanceInfoCache: {
    [instanceId: string]: {
      componentId
      instanceNumber
    }
  } = {};

  getComponentInstanceInfo(id) {
    return this.componentInstanceInfoCache[id];
  }

  getComponentInstanceDebugName(id) {
    const info = this.getComponentInstanceInfo(id);
    const comp = this.getComponent(info.componentId);
    return `${comp.name} #${info.instanceNumber}`;
  }

  debugState = X({});

  setFuncCallDebug(blockId, key) {
    this.functionCallDebug[blockId] = key;
  }

  blockDebug = {};
  getBlockDebug(blockId, componentId) {
    // eslint-disable-next-line
    this.ticks.blockDebug;

    const v = this.blockDebug[blockId];


    if (v) {
      const { block, id, cs } = v;
      const stateKey = cs.callStack.concat(blockId).join('');
      const st = () => XObject.get(this.debugState, stateKey, {});

      const toValue = v => {

        if (v instanceof Bindingg) {
          if (v.args.type == 'binding') {
            return toValue(v.args.binding.get())

          }
        }
        else if (v instanceof FormulaObjectProxy) {
          return v.debugInfo() || '...';
        }
        else if (_.isString(v)) {
          if (v.startsWith('https://jonathan-cook-portfolio.s3.amazonaws.com/images/')) {
            if (v.includes('.svg')) {
              return <RemoteSvg key={v} url={v} />;
            }
            else {
              return <img src={v} />
            }
          }
          else {
            return <span style={{color:'red'}}>'{v}'</span>;
          }
        }
        else if (_.isNumber(v)) {
          return <span style={{color:'rgb(185, 185, 80)'}}>{v}</span>
        }
        else if (_.isPlainObject(v)) {
          try {
            return <span className="plainObject" ><SyntaxHighlighter code={JSON.stringify(v)} /></span>;
          }
          catch (e) {
            return '...';
          }
        }
        else if (_.isNil(v)) {
          return 'NIL';
        }
        else if (v instanceof StringWrapper) {
          return toValue(v.str);
        }
        else if (_.isArray(v)) {
          return `Array(${v.length})`
        }
        else if (v instanceof El) {

          let tag = v.identifier;
          const match = tag.match(/_\$(.*?):(.*?)\$_/);
          if (match) {
            tag = <span style={{borderBottom: '1px dashed #afafafb0'}}>{this.getComponent(match[2]).name}</span>;
          }
          return <><span style={{color:'gray'}}>&lt;</span><span style={{color:'purple'}}>{tag}</span><span style={{color:'gray'}}>&gt;</span></>
        }
        else if (_.isFunction(v)) {
          if ((v as any).blockId == blockId) {
            let count = 0;
            const calls = this.getFunctionCalls(blockId);
            count = calls.length;
            const i = calls.findIndex(c => c.id == this.chosenFunctionCall[blockId]);
            const call = calls[i];

            if (count == 0) return;

            return <>{i+1}/{count} {call && <>(<span className="args">{call?.args?.map?.(a => <span className="arg">{toValue(a)}</span>)}</span>) =&gt; {toValue(call?.returnValue)}</>}</>;

          }
          else {
            const r = this.findBlock((v as any).blockId);
            if (r) {
              const comp = r.find(c => c instanceof CComponent)
              if (comp && comp.obj._id != componentId) {
                return <><span className="comp">{comp.obj.name}</span> =&gt;</>
              }
              return '=>';
                            
            }

          }
        }
        else if (_.isBoolean(v)) {
          return <span style={{color:'rgb(68, 107, 185)'}}>{v ? 'true' : 'false'}</span>;
        }
        else if (v instanceof Pass) {
          return;
        }
        else {
          return '...';
        }
  
      }
      if (block instanceof ValueBlock)  {
        const value = block.value;
        if (_.isFunction(value) && (value as any).blockId == blockId) {
          let count = 0;
          const calls = this.getFunctionCalls(blockId);
          count = calls.length;
          const i = calls.findIndex(c => c.id == this.chosenFunctionCall[blockId]);
          const call = calls[i];

          if (!count) return;

          return <DebugWrapper>
            <span
              onClick={() => {
                if (calls.length) {
                  const next = calls[(i + 1) % calls.length];
                  this.setFuncCallDebug((value as any).blockId, next.id);
                  this.refreshCallStack();  
                }
              }}
            >{i+1}/{count}</span> {call && <span
            
                onClick={() => {

                }}
            >(<span className="args">{call?.args?.map?.(a => <span className="arg"
            
            onClick={e => {
              if (e.metaKey) {
                console.log(a?.blockId);
                this.extra?.navigateToBlock?.(a.blockId);

              }
              else {
                this.state.consoleEntrie.push(XObject.obj({
                  type: 'inspect',
                  value: XGuard(a),
                }))

              }

            }}
            >{toValue(a)}</span>)}</span>) =&gt; <span
              onClick={e => {
                if (e.metaKey) {
                this.extra?.navigateToBlock?.(call.returnValue?.blockId);
                console.log(call.returnValue?.blockId);
                }
                else {
                  this.state.consoleEntrie.push(XObject.obj({
                    type: 'inspect',
                    value: XGuard(call?.returnValue),
                  }))
                }




              }}
            >{toValue(call?.returnValue)}</span></span>}


          </DebugWrapper>
        }
        else {
          return <DebugWrapper
            onClick={e => {
              console.log(value, value?._id);

              if (e.metaKey) {
                console.log(value?.blockId);
                this.extra?.navigateToBlock?.(value?.blockId)
              }
              else {
                if (_.isFunction(value) && (value as any).blockId) {

                  // let count = 0;

                  

                  // for (const id in this.blockDebug) {
                  //   const {block} = this.blockDebug[id];
                  //   if (block instanceof CallBlock) {
                  //     if (block.func == blockId) {
                  //       ++ count;
                  //     }
                  //   }
                  // }

                  // for (const call of this.loggedCalls) {
                  //   if (call.func == blockId) {
                  //     ++ count;
                  //   }
                  // }

                  const calls = this.getFunctionCalls((value as any).blockId);
                  const i = calls.findIndex(c => c.id == this.chosenFunctionCall[(value as any).blockId])
                  console.log(i, calls.length);

                  const next = calls[(i + 1) % calls.length];
                  if (next) {
                    this.setFuncCallDebug((value as any).blockId, next.id);
                    this.refreshCallStack();
                  }

                  this.state.consoleEntrie.push(XObject.obj({
                    type: 'inspect',
                    value: XGuard(value),
                  }))


                  // console.log((value as any).blockId, count, this.chosenFunctionCall[blockId], this.loggedCalls )

      
                }
                else {
                  this.state.consoleEntrie.push(XObject.obj({
                    type: 'inspect',
                    value: XGuard(value),
                  }))

                }
              }



            }}
          >{toValue(value)}</DebugWrapper>;
        }  
      }
      else if (block instanceof LoopBlock) {
        const i = st().iteration || 0;
        return <DebugWrapper
        style={{
          cursor: 'pointer'
        }}
        onClick={() => {
          console.log(block, cs);
          st().iteration = (i + 1)%block.iterations.length;
          this.refreshCallStack();
        }}
  
        >{i + 1}/{block.iterations.length} {toValue(block.iterations[i].el)}</DebugWrapper>
      }
      else if (block instanceof CallBlock) {
        const value = block.returnValue;
  
          return <DebugWrapper
            className="call"
            style={block.func && {
              borderBottom: this.chosenFunctionCall[block.func] == blockId && '1px solid #e8e8e8',
              cursor: 'pointer',
            }}
            onClick={() => {
              this.setFuncCallDebug(block.func, blockId);
              this.refreshCallStack();
            }}
          > (<span className="args">{block.args.map(a => <span className="arg"
            onClick={() => {
              this.state.consoleEntrie.push(XObject.obj({
                type: 'inspect',
                value: XGuard(a),
              }))

            }}
          
          >{toValue(a)}</span>)}</span>) =&gt; <span
            onClick={() => {
              this.state.consoleEntrie.push(XObject.obj({
                type: 'inspect',
                value: XGuard(value),
              }))

            }}
          >{toValue(value)}</span></DebugWrapper>;
  
      }
      else if (block) {
        return <DebugWrapper
          onClick={() => {
            console.log(block);
          }}
        >...</DebugWrapper>
      }
    }
  }

  ticks = X({
    blockDebug: 0,
    instances: 0,
    activeInstance: 0,
    debuggingEnabled: 0,
  })

  getComponentInstances(): any[] {
    // eslint-disable-next-line
    this.ticks.instances;
    return Object.values(this.componentInstancesById);
  }

  // cs

  instanceDebugInfo: {
    [key: string]: {
      enabled: boolean;
      csCont: RuntimeTraceCont;
      chosenFunctionCalls;
    }
  } = {}

  functionCallDebug = {};
  chosenFunctionCall = {};

  loggedCalls: {
    func
    returnValue
    id
    args
    cs
  }[] = [];

  getFunctionCalls(blockId) {
    let count = 0;

    const calls: {
      args
      returnValue
      id
      cs

    }[] = [];

    for (const id in this.blockDebug) {
      const {block} = this.blockDebug[id];
      if (block instanceof CallBlock) {
        if (block.func == blockId) {
          ++ count;
          calls.push({
            id: id,
            returnValue: block.returnValue,
            args: block.args,
            cs: block.cs,
          })
        }
      }
    }

    for (const call of this.loggedCalls) {
      if (call.func == blockId) {
        calls.push(call)
      }
    }

    return calls;

  }

  styleManager3 = new StyleManager();

  

  refreshCallStack() {
    this.updateDebugBlocks();
  }

  updateDebugBlocks() {

    const callStack = this.instanceDebugInfo[this.getActiveInstance()]?.csCont?.main;

    this.loggedCalls = [];
    this.chosenFunctionCall = {};
    this.blockDebug = {};

    const blockIds = {};
    const iter = (cs: RuntimeTrace) => {
      for (const id in cs.calls) {
        let i = 0;
        for (const call of cs.calls[id]) {
          this.loggedCalls.push(call);
          ++i;
        }
      }

      for (const id in cs.blocks) {
        blockIds[id] = true;
        const block = cs.blocks[id];
        this.blockDebug[id] = { cs, id, block };
        const stateKey = cs.callStack.concat(id).join('');
        const st = () => XObject.get(this.debugState, stateKey, {});

        if (block instanceof LoopBlock) {
          const i = st().iteration || 0;
          if (block.iterations[i]) {
            iter(block.iterations[i].cs)
          }
        }
        else if (block instanceof CallBlock && block.func) {
          if (this.functionCallDebug[block.func] == id || (!this.functionCallDebug[block.func] && !this.chosenFunctionCall[block.func])) {
            iter(block.cs);
            this.chosenFunctionCall[block.func] = id;
          }
        }
      }

      for (const id in cs.calls) {

        if (cs.calls[id]) {
          let i = 0;

          for (const call of cs.calls[id]) {
            const key = call.id;
            if (this.functionCallDebug[call.func] == key || (!this.functionCallDebug[call.func] && !this.chosenFunctionCall[call.func])) {
              iter(call.cs);
              this.chosenFunctionCall[call.func] = key;
            }
            ++ i;
          }
        }

      }

      for (const child of cs.children) {
        iter(child);
      }
    }

    if (callStack) {
      iter(callStack);      
    }

    /*if (this.instanceDebugInfo[this.getActiveInstance()]?.csCont?.eventLayers) {
      for (const cs of this.instanceDebugInfo[this.getActiveInstance()]?.csCont.eventLayers) {
        iter(cs);
      }
    }*/


    ignoreAccesses(() => {
      console.log('test');
      const event = x(this.state.eventEntries).find(e => x(e)._id == this.state.activeEvent);

      if (event) {
        iter(event.trace);
      }
  
    })


    setTimeout(() => {
      ++ this.ticks.blockDebug;
    }, 0);


    this.styleManager3.setStyles(`

      ${Object.keys(blockIds).map(id => {
        return `
          [data-block-id="${id}"] > .editor {
            opacity: 1 !important;
          }
        `
      }).join('\n')}
    
    `)


  }

  logSignal(signal, block) {
    this.state.consoleEntrie.push(XObject.obj({
      type: 'inspect',
      value: { signal, block }
    }))
    // console.log(signal, block);

    // toast(JSON.stringify(signal), { position: 'bottom-left',
    //   onClick:  () => {
    //     this.extra?.navigateToBlock?.(block);
    //   }
    // });

  }

  debuggingEnabledForInst(inst) {
    return this.instanceDebugInfo[inst._pathStr()]?.enabled;
  }

  _execute() {
    // const thisDb = this.db || XObject.get(this.devProject, 'db', {});
    const assets = XObject.get(this.devProject, 'assets', []);
    const designComponents = XObject.get(this.devProject, 'designComponents', []);
    const resolveObjectRef = ref => {
      if (ref.type == componentTypeId) {
        const comp = x(this._components().find(c => c._id == ref.id));
        if (!comp) return;

        if (comp.type == 'designComponent') {
          throw new Error();
          return (props) => {
            const slots = {};
            for (const [name, value] of props.children.filter(x => _.isArray(x))) {
              slots[name] = value;
            }
            return renderDesignComponent(comp, {
              onClick: props.onClick,
              className: props.className,
              slots: new Proxy({}, {
                has: (__, name) => name in slots,
                get: (__, name) => {
                  return renderEl(slots[name], null)
                }
              })
            })
          }
        }
        /*else if (comp.type == 'builderComponent') {
          let stylesTag;
          if (comp.root.styles?.[0]?.data?.length) {

            stylesTag = exec(x(comp.root).styles || [], {
              __scopeId: comp.root._id,
            });

            if ((stylesTag instanceof Pass) || _.isBoolean(stylesTag)) {
              stylesTag = null;
            }
          }

          const devRuntime = this;
          @component
          class Comp extends Component<any> {
            static debug = true;
            static styles = stylesTag;
            state = X({});
            constructor(props) {
              super(props);

              if (!scriptEmpty(comp.initialState)) {
                this.state = X(devRuntime.executeBlocks(comp.initialState) || {});
              }
            }

            static contextType = SignalContext;

            context: any;

            render(Container?) {
              const props = this.props;

              return renderBuilderNode(devRuntime, devRuntime.devProject, this.state, comp.root, {
                props,
                emit: (signal, ...args) => {
                  const emitBlock = window['g_env'].__blockId;
                  let handlerBlock;


                  if (!props.__signals?.[signal]) {
                  }
                  else {
                    handlerBlock = props.__signals[signal].blockId;
                    props.__signals[signal](...args);
                  }

                  if (this.context?.[signal]) {
                    handlerBlock = this.context[signal].blockId;
                    this.context[signal](...args)
                  }

                  toast(JSON.stringify({ component: comp.name, signal, args }), { position: 'bottom-right',
                    onClick:  () => {
                      devRuntime.extra?.navigateToBlock?.(handlerBlock);
                    }
                  });
                }
              }, {
                'data-component-id': comp._id,
              });

            }
          }

          return Comp;

        }*/
        else {
          if (comp.detachedNode) {
            return new DetachedNodeComp(comp._id);
          }
          return executeComponent(comp);
        }
      }
      else if (ref.type == colorTypeId) {
        return this.devProject.colors.find(c => c._id == ref.id)?.color;
      }
      else if (ref.type == assetTypeId) {
        const asset = assets.find(a => a._id == ref.id);
        return uploadedFileUrl(asset.file);
      }
      else if (ref.type == nodeTypeId) {
        return new NodeTag(ref.id);
      }
      else if (ref.type == slotTypeId) {
        return ref.id;
      }
    }

    const exec = (blocks, scope={}, extra:any={}, callStack?: RuntimeTrace, runtimeContext?: RuntimeContext) => {
      const r = executeScriptFromData(x(blocks) || [], {
        resolveObjectRef,
        types: this.__types(),
        traceStrings: true,
        ...extra,
      }, {
        // db: new FormulaObjectProxy({
        //   get: prop => {
        //     return makeDb(thisDb, prop);
        //   }
        // }),
        db: new FormulaObjectProxy({
          get: prop => {
            const q = db.queries.find(q => q.name == prop);
            const r = executeEntityQuery(queryChain(q), null, null);
            const createEntityMap = (id, prop?) => {
              if (!id) return;
              if (!_.isString(id)) {
                console.log('not string', x(id), prop);
                return;
                throw new Error('not string');
              }
              const entity = db.entities.findById(id);
              if (!entity) {
                console.log(id);
                throw new Error('no entity')
              }

              return new FormulaObjectProxy({
                debugInfo: () => {
                  return `${db.entityTypes.findById(entity.type).name}(${entity.name || ''})`;
                },
                get: prop => {
                  if (prop == '_id') return id;
                  else if (prop == '_typeName') {
                    return db.entityTypes.findById(entity.type).name;
                  }
                  else if (prop == '_children') {
                    return getEdgesForEntity(id).filter(e => {
                      return e.directed && e.entities[0] == id;
                    }).filter(e => {
                      return !db.entities.findById(e.entities[1]).__deleted;
                    }).map(e => {
                      return createEntityMap(e.entities[1]);
                    })
                  }
                  else if (prop == '_delete') {
                    return () => {
                      console.log('adasdfasdfasdf')
                      console.log(x(entity));
                      deleteEntity(id);
                    }
                  }
                  // else if (prop == '_addChild') {
                  //   return entity => {

                  //   }
                  // }
                  else if (prop == 'name') return entity.name;
                  else {
                    const attributes = attributesForType(entity.type);
                    const attr = attributes.find(attrId => {
                      const attrObj = db.attributeTypes.findById(attrId);
                      return attrObj.name == prop;
                    });
                    if (attr) {
                      return entity.attributes[attr];
                    }
                    else {
                      // console.log('invalid', prop);
                    }
                  }
                },
                set: (prop, value) => {
                  console.log(prop, value);
                  if (prop == 'name') {
                    entity.name = value;
                  }
                  else {
                    const attributes = attributesForType(entity.type);
                    const attr = attributes.find(attrId => {
                      const attrObj = db.attributeTypes.findById(attrId);
                      return attrObj.name == prop;
                    });
                    if (attr) {

                      if (!entity.attributes) {
                        entity.attributes = X({
                          [attr]: value,
                        })
                      }
                      else {
                        entity.attributes[attr] = value;
                      }
                    }
                  }

                }
              })
            }
            return new FormulaObjectProxy({
              type: 'array',
              get: prop => {
                if (prop == 'length') {
                  return r.length;
                }
                else if (prop == 'map') {
                  return func => {
                    return r.map((entity, i) => {
                      return func(createEntityMap(entity), i);
                    })
                  }
                }
                else if (prop == 'push') {
                  return (arg:any={}) => {
                    const attrs = {};

                    const entityType:any = {};

                    createEntityFromQuery(queryChain(q), entityType);


                    const attributes = attributesForType(entityType.type);

                    const entity: any = {
                      attributes: attrs,
                    };

                    for (const prop in arg) {
                      if (prop == 'name') {
                        entity.name = arg[prop];
                      }
                      else if (prop == 'edges') {
                        entity.$edges = arg.edges;
                      }
                      else {
                        const attr = attributes.find(attrId => {
                          const attrObj = db.attributeTypes.findById(attrId);
                          return attrObj.name == prop;
                        });
                        if (attr) {
                          attrs[attr] = arg[prop];
                        }
                      }
                    }


                    createEntityFromQuery(queryChain(q), entity);

                    return createEntity(entity, null);
                  };
                }
                else if (prop == 'find') {
                  return func => {
                    return createEntityMap(r.find((entity, i) => {
                      return func(createEntityMap(entity), i);
                    }));
                  }
                }
                else if (prop == 'findById') {
                  return id => {
                    return createEntityMap(id);
                  }
                }
                else if (prop == 'filter') {
                  return func => {
                    return r.filter((entity, i) => {
                      return func(createEntityMap(entity), i);
                    }).map(createEntityMap)
                  }
                }
                else if (_.isNumber(prop) || (_.isString(prop) && prop.match(/^\d+$/))) {
                  return createEntityMap(r[prop], prop);
                }
                else {
                  console.log(prop);
                }
              }
            })
          }
        }),
        assetUrl: name => {
          if (!name) return;
          if (name.startsWith('http')) return name;
          const asset = assets.find(asset => asset.name == name);
          if (asset?.file) {
            return uploadedFileUrl(asset.file);
          }
        },
        renderDesignComponent: (name, slots) => {
          throw new Error();
          const designComp = designComponents.find(d => d.name == name);

          if (designComp) {
            return renderDesignComponent(designComp, {
              slots: new Proxy({}, {
                has: (__, name) => name in slots,
                get: (__, name) => {
                  return renderEl(slots[name], null)
                }
              })
            });
          }
        },
        Svg: (props) => {
          const { url, asset, className } = props;
          return _.isString(url || asset) && (
            <RemoteSvg
              key={url || asset}
              onClick={props.onClick}
              className={className}
              url={url || asset}
              data-node-id={props['data-node-id']}
              data-script-block-id={props['data-script-block-id']}
              data-script-block-ids={props['data-script-block-ids']}
            />
          );
        },
        navigate: {
          push: Object.assign(location => {
            this.navigate.push(unwrapString(location));
          }, { _fromScript: true, _functionName: 'navigate.push' }),
          location: () => {
            return this.navigate.location();
          }
        },
        presentLayer: Object.assign((...args) => {
          console.log('present lyaer')
          return this.extra.presentLayer(args);
        }, { _fromScript: true, _functionName: 'presentLayer' }),
        isEl: arg => {
          return arg instanceof El;
        },
        ...scope,
      }, callStack, runtimeContext || new RuntimeContext({}));

      return r;
    }

    const executeComponent = (comp: CompType) => {
      if (this.componentCache[comp._id]) {
        if (this.componentCache[comp._id].lastUpdated == comp.lastUpdated) {
          return this.componentCache[comp._id].value;
        }
      }

      let r;

      if (comp.type == 'builderComponent') {
        const makeComp = (comp: CompType, embeddedComp?: CompType) => {
          let r;
          let stylesTag;
          if (!scriptEmpty(comp.root.styles)) {
            stylesTag = this.getNodeTag(comp.root);
  
            if ((stylesTag instanceof Pass) || _.isBoolean(stylesTag)) {
              stylesTag = null;
            }
          }
  
          const devRuntime = this;
          @component
          class Comp extends Component<any> {
            // static debug = true;
            static debounce = false;
            static styles = stylesTag;
            static dontAlias = true;
            state = X({});
            embeddedState = embeddedComp && X({});

            instanceId = md5(Math.random());
            instanceNumber

            componentId = embeddedComp?._id || comp._id;

            setup

            callStack

            _path() {
              return this.props['data-pb-path'].concat(`component:${this.componentId}`);

            }

            _pathStr() {
              return this._path().join('/');
            }


            constructor(props) {
              super(props);
  
              if (!scriptEmpty(comp.initialState)) {
                this.state = X(devRuntime.executeBlocks(comp.initialState) || {});
              }

              XObject.observe(this.state, Object.assign(mutation => {
                if (mutation.pass instanceof Thingy && mutation.pass.trace) {
                  mutation.pass.trace.captureMutation(mutation.pass.block, mutation.pass.runtimeContext, mutation, 'ui', {
                    instanceId: this.instanceId,
                    componentId: this.componentId,
                  });
                }
          
              }, { immediate: true }));

              if (embeddedComp) {
                if (!scriptEmpty(embeddedComp.initialState)) {
                  this.embeddedState = X(devRuntime.executeBlocks(embeddedComp.initialState) || {});
                }

                XObject.observe(this.embeddedState, Object.assign(mutation => {
                  console.log('ui mutation')
                  if (mutation.pass instanceof Thingy && mutation.pass.trace) {
                    mutation.pass.trace.captureMutation(mutation.pass.block, mutation.pass.runtimeContext, mutation, 'ui', {
                      instanceId: this.instanceId,
                      componentId: this.componentId,
                    });
                  }
            
                }, { immediate: true }));
              }



  
              if (!devRuntime.componentInstances[comp._id]) {
                devRuntime.componentInstances[comp._id] = [];
              }
  
              devRuntime.componentInstances[comp._id].push(this);



              devRuntime.componentInstancesById[this.instanceId] = this;

              devRuntime.instanceIdToPath[this.instanceId] = this._pathStr();

              if (devRuntime.componentInstancesByPath[this._pathStr()]) {
                console.log('already exists', this._path())
              }
              devRuntime.componentInstancesByPath[this._pathStr()] = this;

              this.instanceNumber = devRuntime.componentInstances[comp._id].length;


              devRuntime.componentInstanceInfoCache[this.instanceId] = {
                componentId: this.componentId,
                instanceNumber: this.instanceNumber,
              }


              XObject.captureAccesses(() => {
                devRuntime.ticks.instances++;
              }, () => 'stop');


              if (embeddedComp) {
                if (!devRuntime.componentInstances[embeddedComp._id]) {
                  devRuntime.componentInstances[embeddedComp._id] = [];
                }
    
                devRuntime.componentInstances[embeddedComp._id].push(this);
  
              }

              this.emit = Object.assign(((signal, ...args) => {
                signal = unwrapString(signal);

    
  
                const props = this.props;
                const emitBlock = window['g_env'].__blockId;
                let handlerBlock, handler;
    
                if (!props.__signals?.[signal]) {
                }
                else {
                  handler = props.__signals[signal];
                }
    
                if (this.context?.[signal]) {
                  handler = this.context[signal];
                }

                handlerBlock = handler.blockId;

                const index = args.findIndex(x => x instanceof RuntimeTrace);
                if (index != -1) {
                  args[index].handler = handler;
                  args[index] = args[index].push();
                  args[index].instanceId = handler.instanceId;
                }
                
                handler(...args);
    
                try {
                  // toast(JSON.stringify({ component: comp.name, signal, args }), { position: 'bottom-left',
                  //   onClick:  () => {
                  //     devRuntime.extra?.navigateToBlock?.(handlerBlock);
                  //   }
                  // });
  
                  devRuntime.logSignal({ component: comp.name, signal, args }, handlerBlock);
                }
                catch (e) {
                  // toast(JSON.stringify({ component: comp.name, signal }), { position: 'bottom-left',
                  //   onClick:  () => {
                  //     devRuntime.extra?.navigateToBlock?.(handlerBlock);
                  //   }
                  // });
                  devRuntime.logSignal({ component: comp.name, signal }, handlerBlock);
                }


                return handlerBlock;
    
              }), { _fromScript:true, _functionName:'emit', instanceId: this.instanceId })

              // console.log(this.emit._fromScript);
              
            }
  
  
            static contextType = SignalContext;
  
            context: any;
  
            componentWillUnmount(): void {
              const index = devRuntime.componentInstances[comp._id].indexOf(this);
              devRuntime.componentInstances[comp._id].splice(index, 1);

              delete devRuntime.componentInstancesById[this.instanceId];

              delete devRuntime.componentInstancesByPath[this._pathStr()];

              if (embeddedComp) {
                const index = devRuntime.componentInstances[embeddedComp._id].indexOf(this);
                devRuntime.componentInstances[embeddedComp._id].splice(index, 1);
  
              }

              XObject.captureAccesses(() => {
                devRuntime.ticks.instances++;
              }, () => 'stop');
            }
  
  
            emit
  
            render(Container?) {
              // if (devRuntime.debuggingEnabledForInst(this)) {
              //   devRuntime.blockDebug = {};
              //   devRuntime.loggedCalls = [];
              // }

              // console.log(comp.name, embeddedComp?.name, x(embeddedComp));
              const _comp = devRuntime._components().find(c => c._id == comp._id);
              const _embeddedComp = embeddedComp && devRuntime.getComponent(embeddedComp._id);
              // embeddedComp = embeddedComp && devRuntime._components().find(c => c._id == embeddedComp._id);
              const props = this.props;
  
              let setup = {}

              let embeddedSetup
  
              const baseComponent = comp.baseComponent && devRuntime.getComponent(comp.baseComponent);
              if (baseComponent) {
                if (!scriptEmpty(baseComponent.setup)) {
                  setup = devRuntime.executeBlocks(baseComponent.setup, {
                    props
                  }) || {};
                }  
  
              }
              else {
                if (!scriptEmpty(_comp.setup)) {
                  setup = devRuntime.executeBlocks(_comp.setup, {
                    props
                  }) || {};
                }  
              }

              if (embeddedComp) {
                if (!scriptEmpty(_embeddedComp.setup)) {
                  embeddedSetup = devRuntime.executeBlocks(_embeddedComp.setup, {
                    props
                  }) || {};
                }
              }

              this.setup = setup;

              const callStack = devRuntime.debuggingEnabledForInst(this) && new RuntimeTrace({
                init: `${this.instanceId} (component ${embeddedComp?._id || comp._id})`,
                args: {
                  instanceId: this.instanceId,
                },
                instanceId: this.instanceId,
              })

              const runtimeContext = new RuntimeContext({
                instanceId: this.instanceId,
                componentId: this.componentId,
              })
  
              const renderRootNode = (node, overrides: {
                compId?
                setup?
                state?
                additionalRootClasses?
              }={}) => {
                const elPos = el => {
                  const offset = jQuery('[data-offset-cont]').offset();
                  const rect = el.getBoundingClientRect();
                  return {
                    x: rect.x - offset.left,
                    y: rect.y - offset.top,
                    left: rect.left - offset.left,
                    top: rect.top - offset.top,
  
                    bottom: rect.bottom - offset.top,
                    right: rect.right - offset.left,
  
                    width: rect.width,
                    height: rect.height,
                  }

                }


                const componentIdAttrs = {};
                const ci = overrides.compId === false ? undefined : (overrides.compId || comp?._id);

                if (embeddedComp) {
                  if (overrides.compId !== false) {
                    componentIdAttrs[`data-component-${embeddedComp._id}`] = true;
                    componentIdAttrs[`data-component-${comp._id}`] = true;
  
                  }
                }
                else {
                  componentIdAttrs[`data-component-${ci}`] = true;
  
                }
  
                return renderBuilderNode(
                  devRuntime,
                  _comp,
                  overrides.state || this.state,
                  x(node),
                  {
                    ...(overrides.setup || setup),
                    // setup,
                    this: this,
                    props,

                    __instanceId: this.instanceId,
                    compPosition: comp => {
                      return elPos(ReactDOM.findDOMNode(comp));
                    },
                    findDOMNode: comp => {
                      return ReactDOM.findDOMNode(comp);
                    },
    
                    positionEl(...a) {
                      const args: any = {};
                      for (const x of a) {
                        args[x[0]] = x[1];
                      }
                      const anchor = args.anchor instanceof Element ? args.anchor : ReactDOM.findDOMNode(args.anchor);
                      const el = args.el instanceof Element ? args.el : ReactDOM.findDOMNode(args.el);
    
    
                      const anchorBounds = elPos(anchor);
                      const elBounds = elPos(el);
    
                      const xSpacing = args.spacingX || 0;
                      const ySpacing = args.spacingY || 0;
    
                      let x, y;
    
                      if (args.alignX == 0) {
                        x = anchorBounds.left + anchorBounds.width/2 - elBounds.width/2;
                      }
                      else if (args.alignX == 1) {
                        x = anchorBounds.left + xSpacing;
                      }
                      else if (args.alignX == 2) {
                        x = anchorBounds.right + xSpacing;
                      }
                      else if (args.alignX == -1) {
                        x = anchorBounds.right - elBounds.width - xSpacing;
                      }
                      else if (args.alignX == -2) {
                        x = anchorBounds.left - elBounds.width - xSpacing;
                      }
    
                      if (args.alignY == 0) {
                        y = anchorBounds.top + anchorBounds.height/2 - elBounds.height/2;
                      }
                      else if (args.alignY == 1) {
                        y = anchorBounds.top + ySpacing;
                      }
                      else if (args.alignY == 2) {
                        y = anchorBounds.bottom + ySpacing;
                      }
                      else if (args.alignY == -1) {
                        y = anchorBounds.bottom - elBounds.height - ySpacing;
                      }
                      else if (args.alignY == -2) {
                        y = anchorBounds.top - elBounds.height - ySpacing;
                      }
    
                      el.style.position = 'absolute';
                      el.style.top = y + 'px';
                      el.style.left = x + 'px';
    
                    },

                    slot: (ref) => {
                      if (_embeddedComp) {
                        let slot
                        if (isId(ref)) {
                          slot = comp.slots.find(s => s._id == ref);
                        }
                        else {
                          slot = comp.slots.find(s => s.name == ref);
                        }
                        if (slot) {
                          const pv = _embeddedComp.providedSlots?.find?.(pv => pv.slot == slot._id);
                          if (pv) {
                            return pv.nodes.map(n => renderRootNode(n, {
                              compId: false,
                              state: this.embeddedState,
                              setup: embeddedSetup,
                              additionalRootClasses: ['slotRoot']
                            }));
                          }
                          else {
                            // return defaultContent;
                          }
                        }
                      }
                    },
                    
                    hasSlot: ref => {
                      if (_embeddedComp) {
                        let slot

                        if (isId(ref)) {
                          slot = comp.slots.find(s => s._id == ref);
                        }
                        else {
                          slot = comp.slots.find(s => s.name == ref);
                        }

                        if (!slot) {
                          console.log('invalid slot', ref);
                        }
                        const pv = _embeddedComp.providedSlots?.find?.(pv => pv.slot == slot._id);
                        return !!pv;
                      }
                    },
                    windowFrame() {
                      return {
                        width: jQuery('#protobuilder-output-area .renderedOutput').width(),
                        height: jQuery('#protobuilder-output-area .renderedOutput').height(),
                      }
                    },
                    emit: Object.assign((signal, ...args) => {
                      signal = unwrapString(signal);
                      const emitBlock = window['g_env'].__blockId;
                      let handlerBlock, handler;
      
                      if (!props.__signals?.[signal]) {
                      }
                      else {
                        handler = props.__signals[signal];
                      }

                      if (this.context?.[signal]) {
                        handler = this.context[signal];
                      }
      
                      if (handler) {

                        handlerBlock = handler.blockId;
  
                        const index = args.findIndex(x => x instanceof RuntimeTrace);
                        if (index != -1) {
                          args[index].handler = handler;
                          args[index] = args[index].push();
                          args[index].instanceId = handler.instanceId;
                        }
                        
        
  
                        handler(...args);
                      }

      
                      try {
                        devRuntime.logSignal({ component: comp.name, signal, args }, handlerBlock);
                        // toast(JSON.stringify({ component: comp.name, signal, args }), { position: 'bottom-left',
                        //   onClick:  () => {
                        //     devRuntime.extra?.navigateToBlock?.(handlerBlock);
                        //   }
                        // });
                      }
                      catch (e) {
                        devRuntime.logSignal({ component: comp.name, signal }, handlerBlock);
                        // toast(JSON.stringify({ component: comp.name, signal }), { position: 'bottom-left',
                        //   onClick:  () => {
                        //     devRuntime.extra?.navigateToBlock?.(handlerBlock);
                        //   }
                        // });
                      }

                      // const trace = args.find(a => a instanceof RuntimeTrace);

                      // if (trace) {
                      //   trace.captureSignal({
                      //     signal, handler
                      //   })
                      // }
      

                      // return handlerBlock;
      
                    }, {_fromScript:true, _functionName:'emit', instanceId: this.instanceId })
                  },
                  {
                    ...(
                      componentIdAttrs
                    ),

                    'data-instance-id': this.instanceId,
                    
                    'data-component-id': ci,
                    'data-key': props['data-key'],
                    'data-script-block-ids': props['data-script-block-ids'],
                    'classes': overrides.additionalRootClasses,
                  },
    
                  // hooks
                  {
                    tagHandlers: [
    
                      {
                        test: tag => tag instanceof DetachedNodeComp,
                        render: el => {
                          const comp = devRuntime.getComponent(el.tag.id);
                          return renderRootNode(comp.root, { compId: comp._id });
                        }
                      },
                      {
                        test: tag => tag instanceof NodeTag,
                        render: el => {
                          const node = devRuntime.findDetachedNode(el.tag.id);
                          return renderRootNode(node);
                        }
                      },
                    ]
                  },
                  0,
                  {
                    // captureBlockValue: (block, value, callStack) => {
                    //   if (this.instanceId == devRuntime.activeInstance) {
                    //     console.log(block, value, callStack);
                    //   }
                    // },
                    // callStack: new CallStack(embeddedComp?._id || comp._id),
                  },
                  callStack,
                  runtimeContext,
                  this._path(),
                );
              }
  
              const r = renderRootNode(this.props.__node || _comp.root, {
                compId: embeddedComp?._id
              });

              // console.log(callStack);


              if (devRuntime.isDebuggingEnabled() && devRuntime.debuggingEnabledForInst(this)) {
                this.callStack = callStack;
                devRuntime.instanceDebugInfo[this._pathStr()].csCont.main = callStack;

                if (devRuntime.getActiveInstance() == this._pathStr()) {
                  devRuntime.refreshCallStack();
                }
              }
              return r;
            }
          }


          const toString = () => {
            return `[data-component-${comp._id}]`;
            // return `[data-component-id="${comp._id}"]`;
          }

          Comp.toString = toString;
          Comp[Symbol.toPrimitive] = toString;
          Comp.valueOf = toString;
          // comp.styledComponentId = styledComponent.styledComponentId;
        

  
          r = Comp;
          return r;
        }

        if (comp.template) {
          const templateComp = this.getComponent(comp.template);
          r = makeComp(templateComp, comp);
        }
        else {
          r = makeComp(comp);
        }
      }
      else {
        r = exec(comp.blocks, {
          __lastUpdated: comp.lastUpdated,
          __scopeId: comp._id,
        });
      }

      this.componentCache[comp._id] = { value: r, lastUpdated: comp.lastUpdated };
      return r;
    }

    return {
      exec,
      executeComponent,
      hooks: {
        resolveObjectRef,
        types: this.__types(),
      }
    }
  }



  executeBlocks(blocks, scope={}, extra?, callStack?: RuntimeTrace, runtimeContext?: RuntimeContext) {
    const { exec } = this._execute();
    return exec(blocks, scope, extra, callStack, runtimeContext);
  }


  executeComponent(comp: CompType) {
    const { executeComponent } = this._execute();
    return executeComponent(comp);
  }

  createGraph() {
    const components = this._components();
    const referencedBy = {};
    const references = {};

    for (const comp of components) {
      const refs = {};
      const collectRefs = blocks => {
        for (const block of blocks) {
          if (_.isArray(x(block.data))) {
            for (const el of block.data) {
              if (_.isArray(x(el))) {
                if (el[1] == 'component') {
                  refs[el[0]] = true;
                  if (!referencedBy[el[0]]) referencedBy[el[0]] = {};
                  referencedBy[el[0]][comp._id] = true;
                }
              }
            }
          }
  
          collectRefs(block.children);
        }
      }
  
      if (comp.type == 'builderComponent') {
        const iterNodes = node => {
          collectRefs(node?.script || []);
          if (node.children) {
            for (const child of node.children) iterNodes(child);
          }
        }
        iterNodes(comp.root);
      }
      else {
        collectRefs(comp?.blocks || []);
      }
      references[comp._id] = Object.keys(refs);
    }
    
    return { references, referencedBy };
  }



  _invalidateCache(id, parents, graph) {
    if (parents.includes(id)) return;
    // console.log('invalidate cache', id);
    delete this.componentCache[id];

    const { referencedBy } = graph;

    if (referencedBy[id]) {
      for (const id2 of Object.keys(referencedBy[id])) {
        this._invalidateCache(id2, parents.concat(id), graph);
      }
    }
  }
  invalidateCache(id, parents=[]) {
    // console.log('invalidate cache', id);
    const graph = this.createGraph();
    this._invalidateCache(id, [], graph)
  }


  updateStyles(ids: string[]) {
    const components = [];
    for (const id of ids) {
      for (const comp of this._components()) {
        if (comp.type == 'builderComponent') {
          const iterNode = node => {
            if (node.attachedStyles?.includes?.(id)) {
              components.push(comp._id);
            }
            if (node.children) {
              for (const child of node.children) {
                iterNode(child);
              }
            }
          }
          iterNode(comp.root);
        }
      }
    }
    this.updateComponents(components);
  }

  updateComponents(ids: string[]) {
    const graph = this.createGraph();
    for (const id of ids) {
      const comp = this.getComponent(id);
      if (comp.baseComponent) {
        this.updateComponents([comp.baseComponent])
      }
      if (comp.type != 'builderComponent') {
        if (graph.referencedBy[id]) {
          for (const id2 of Object.keys(graph.referencedBy[id])) {
            if (this.componentInstances[id2]) {
              for (const comp of this.componentInstances[id2]) {
                comp.forceUpdate();
              }
            }
          }
        }
      }

      if (this.componentInstances[id]) {
        for (const comp of this.componentInstances[id]) {
          comp.forceUpdate();
        }
      }
    }
  }

  scriptWrappers = {}
  getScriptWrapper(component) {
    const scripWrappers = this.scriptWrappers;
    if (scripWrappers[component._id]) {
      return scripWrappers[component._id];
    }
    else {
      const compChanged = () => {
        component.lastUpdated = Date.now();
        this.changed.components[component._id] = true;

      }
      return scripWrappers[component._id] = createScriptWrapper({
        blocks: () => {
          return XObject.get(component, 'blocks', []);
        },
        setBlocks: b => {
          this.invalidateCache(component._id);
          component.blocks = b
          compChanged();
        },
        onChange: (id) => {
          this.invalidateCache(component._id);
          this.changedBlock(id);  
          compChanged();

        }
      })
    }
  }

  nodeScriptWrappers = {};
  getNodeScriptWrapper(component, node, prop) {
    const key = component._id + node._id + prop;

    if (key in this.nodeScriptWrappers) {
      return this.nodeScriptWrappers[key];
    }

    let timerId;
    const compChanged = () => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        this.invalidateCache(component._id);
      }, 500);
      this.changed.components[component._id] = true;
      component.lastUpdated = Date.now();
    }

    return this.nodeScriptWrappers[key] = createScriptWrapper({
      blocks: () => XObject.get(node, prop, []),
      setBlocks: b => {
        node[prop] = b;
        compChanged();
      },
      onChange: (id) => {
        this.changedBlock(id);
        compChanged();
      },

    });

  }

  styleEls = {};

  getNodeTag(node) {
    const block = node.styles[0];
    const line = expandFormula(block.data, this._hooks());
    if (line[0] == '~') {
      const tag = line.slice(1);
      if (tag[0].toLowerCase() == tag[0]) {
        return tag;
      }
      else {
        return this.executeBlocks([
          {
            _id: block._id,
            data: tag,
            children: [],
          }
        ])
      }
    }

    // return this.executeBlocks([
    //   {
    //     _id: block._id,
    //     data: block.data,
    //     children: [],
    //   }
    // ])
  }

  getStyleClass(styles, scope, stylesKey) {
    let css = generateCss({
      baseSelector: 'BASE_SELECTOR',
      hooks: this._hooks(),
      rootBlock: styles[0],
      scope,
      scopeId: undefined,
    })

    const key = md5(css);
    css = css.replace(/BASE_SELECTOR/g, `._${key}`);
    if (!this.styleEls[stylesKey]) {
      const el = document.createElement('style');
      el.setAttribute('data-node-styles', stylesKey);
      el.setAttribute('data-key', key);
      el.innerHTML = css;
      document.head.appendChild(el);
      this.styleEls[stylesKey] = el;
    }
    else {
      this.styleEls[stylesKey].innerHTML = css;
    }
    return '_' + key;
  }

  getNodeStylesClass(component, node, scope={}) {
    if (node.styles?.[0]) {
      let css = generateCss({
        baseSelector: 'BASE_SELECTOR',
        hooks: this._hooks(),
        rootBlock: node.styles[0],
        scope,
        scopeId: undefined,
      })
  
      const key = md5(css);
      css = css.replace(/BASE_SELECTOR/g, `._${key}`);
      if (!this.styleEls[key]) {
        const el = document.createElement('style');
        el.setAttribute('data-node-styles', node._id);
        el.setAttribute('data-key', key);
        el.innerHTML = css;
        document.head.appendChild(el);
        this.styleEls[key] = el;
      }
      return '_' + key;
    }
  }

  designerEls = {}
  getNodeDesignerClass(node, debug?) {
    if (!this.designerEls[node._id]) {
      const el = document.createElement('style');
      el.setAttribute('data-node-asdf', node._id);
      el.innerHTML = generateDesignerCss(this, node);
      document.head.prepend(el);
      this.designerEls[node._id] = el;
    }
    else {
      const css = generateDesignerCss(this, node);
      if (css != this.designerEls[node._id].innerHTML) {
        this.designerEls[node._id].innerHTML = css;
      }
    }
    return null;
  }

  getNodeStyles(component, node, scope) {
    // let css = generateCss({
    //   baseSelector: 'BASE_SELECTOR',
    //   hooks: this._execute().hooks,
    //   rootBlock: node.styles[0],
    //   scope,
    //   scopeId: undefined,
    // })

    // const key = md5(css);
    // css = css.replace(/BASE_SELECTOR/g, `._${key}`);
    // if (!this.styleEls[key]) {
    //   const el = document.createElement('style');
    //   el.setAttribute('data-node-styles', node._id);
    //   el.setAttribute('data-key', key);
    //   el.innerHTML = css;
    //   document.head.appendChild(el);
    //   this.styleEls[key] = el;
    // }


    return this.executeBlocks(node.styles || [], {
      __scopeId: node._id,
      ...scope,
    })
  }

  getStylesObjClass(style) {
    let css = generateCss2({
      baseSelector: 'BASE_SELECTOR',
      hooks: this._hooks(),
      blocks: style.stylesheet?.[0]?.children || [],
      rootId: `style#${style._id}`,
      scope: {},
      scopeId: undefined,
    })

    const key = md5(css);
    css = css.replace(/BASE_SELECTOR/g, `._${key}`);
    if (!this.styleEls[key]) {
      const el = document.createElement('style');
      // el.setAttribute('data-node-styles', node._id);
      // el.setAttribute('data-key', key);
      el.innerHTML = css;
      document.head.appendChild(el);
      this.styleEls[key] = el;
    }
    return '_' + key;
  }



  overlay = new OverlayManager(500);
  overlay2 = new OverlayManager(500);

  changed = {
    blocks: {},
    nodes: {},
    components: {},
    styles: {},
  }


  styleManager2 = new StyleManager();
  changedBlock(id) {
    this.changed.blocks[id] = true;

    this.overlay.setOverlays([id].map(id => {
      return {
        selector: `[data-script-block-ids*="${id}"]`,
        styles: {
          border: '2px solid #93d39d',
          boxSizing: 'border-box',
        }
      }
    }), 4)


    this.styleManager2.setStyles(`
    
    ${Object.keys(this.changed.blocks).map(id => {

      return `
      [data-block-id="${id}"] {
        background-color: #def0e1
      }
      `;
    }).join('\n')}

    `);
  }
  styleManager = new StyleManager();
  changedNode(id) {
    console.log('changed node', id);
    this.changed.nodes[id] = true;

    this.overlay2.setOverlays([id].map(id => {
      return {
        selector: `[data-node-id="${id}"]`,
        styles: {
          border: '2px solid #93d39d',
          boxSizing: 'border-box',
        }
      }
    }), 4);


    // styleManager.setStyles(`
    
    // ${Object.keys(changed.nodes).map(id => {

    //   return `[data-node-id="${id}"] {
    //     outline: 1px solid #0000001f !important;
    //   }`;
    // }).join('\n')}

    // `);
  }

  resetChanges() {
    this.changed.nodes = {};
    this.changed.blocks = {};
    this.changed.components = {};
    this.changed.styles = {};
    this.overlay.setOverlays([]);
    this.overlay2.setOverlays([]);
    this.styleManager.setStyles();
    this.styleManager2.setStyles();
  }


  buildSelector(node, styleNodes) {
    const c = [].concat(styleNodes.map(p => p.selector ? execFormulaFromData(p.selector, {}, undefined, this._hooks(), 'TextBlock') : `[data-node-id="${node._id}"]`));
    let output = '';
  
    for (let i = 0; i < c.length; ++ i) {
      const ii = c[i];
      if (!ii) continue;
      if (i == 0) output += ii;
      else {
        if (ii[0] == '&') {
          output += ii.slice(1);
        }
        else {
          output += ` ${ii}`
        }
      }
    }
  
    return output;
  }

  createBuilderComponent(name='Untitled', extra={}):any {
    const obj = XObject.obj({
      type: 'builderComponent',
      name,
      root: XObject.obj({
        type: 'el',
        data: '<node>'
      }),
      ...extra,
    });

    this.devProject.components.push(obj);
    return obj;
  }

  createScriptComponent(name='Untitled', extra={}):any {
    const obj = XObject.obj({
      name,
      ...extra,
    });

    this.devProject.components.push(obj);
    return obj;
  }
  
}
