import { useContext } from "react";
import _ from 'lodash';
import { db } from "../../db";
import { styled } from "../../component";
import { ValueType } from "../ValueType";
import { evaluate, getRootValuePoint, mapStructure } from "../main";
import { structRenderers } from "../structRenderers";
import { typeRegistry } from "../typeRegistry.1";
import { SystemContext } from "../../etc/SystemContext";
import { $EntityDatabase } from "./$EntityDatabase";
import { $EntityQueryView } from "./$EntityQueryView";
import { x } from "../../XObject";
import { execFormulaFromData } from '../../shorthandEditor/execFormulaFromData';

export const $Button = typeRegistry.registerType({
  $: '20dd584f-2a0a-5340-8856-8548ee2863c7',
  Text: '5ca955d0-1e38-5570-b6af-e26bb658f031',
  Action: '998e25ef-1e74-5dfd-a5b1-a8ddd033a718',
  Color: '',
}, ids => ({
  _id: ids.$,
  name: 'Button',
  definition: [
    {
      id: ids.Text,
      name: 'Text',
      type: [],
      property: 'text',
    },
    {
      id: ids.Action,
      name: 'Action',
      type: [],
      property: 'action',
    },
    {
      id: ids.Color,
      name: 'Color',
      type: [],
      property: 'color',
    },
  ],
}));


structRenderers[$Button.$] = (value, map) => {
  const mapped = mapStructure(value);
  // const context = useContext(SystemContext);
  let r = evaluate(mapped.text, map);
  if (!_.isString(r)) r = 'no title';


  return (
   <button
    style={{
      color: mapped.color && evaluate(mapped.color, map),
    }}
    onClick={() => {

      if (mapped.action?.type?.[0] == ValueType.Formula) {
        // console.log(mapped.color?.content);
        // // console.log('asdf', x());
        // const root = getRootValuePoint(value._id);
        // const env = {};
        // for (const p of root.parameters) {
        //   env[p.name] = map[p._id];
        // }

        console.log(map);

        evaluate(mapped.action, map);

        // const r = execFormulaFromData(mapped.action.content.formula, env);
        // // console.log(r);
        
      }
      if (mapped.action?.type?.[0] == ValueType.Param) {
        console.log('pooop', map, value.rt, map[mapped.action.content.id]);


      }


    }}
   >{r}</button>
  )
}