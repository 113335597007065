import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import { component } from './component2';
import { styled } from './component';
import { baseCoreStyles, baseStyles } from './baseStyles';
import { DevRuntime } from './DevRuntime';
import { X, XInit, XObject, x } from './XObject';
import { NotionDocument2 } from './components/notionDocument/NotionDocument2';
import { MyBlockManager2 } from './MyNotionDocument/MyBlockManager2';
import { renderBlock3 } from './MyNotionDocument/renderBlock3';
import { isId, showContextMenu } from './helpers';
import { PropertyField } from './components/PropertyField';
import { ScriptWrapper, createScriptWrapper } from './MyNotionDocument/ScriptWrapper';
import { scriptEmpty } from './shorthand/formula';
import { renderWrapperBuilderNode } from './BuilderComponentEditor';
import { generateAppTree } from './generateAppTree';
import { getDevProjectEditorState } from './DevProject.1';
import { Tag } from './components/Tag';
import { Tree } from './Tree';
import { sectionStyles } from './sectionStyles';
import { RemoteSvg, Svg } from './components/Svg';
import { FileUpload, uploadedFileUrl } from './components/FileUpload';
import { Entry } from './DevProjectWindow';

@component
class ScaledFrame extends Component<{ children, frame, className, scale }> {
  componentDidMount(): void {
    const bound = this.ref.current.getBoundingClientRect();
    const el = ReactDOM.findDOMNode(this) as any;
    el.style.width = bound.width + 'px'
    el.style.height = bound.height + 'px'


    const elBound = el.getBoundingClientRect();
    this.ref.current.style.marginTop = -(bound.top - elBound.top) + 'px';
    this.ref.current.style.marginLeft = -(bound.left - elBound.left) + 'px';

  }
  ref = React.createRef<any>();

  componentDidUpdate(prevProps: Readonly<{ children: any; frame: any; className: any; scale: any; }>, prevState: Readonly<{}>, snapshot?: any): void {
    const el = ReactDOM.findDOMNode(this) as any;
    el.style.width = '';
    el.style.height = '';
    this.ref.current.style.marginTop = '';
    this.ref.current.style.marginLeft = '';


    const bound = this.ref.current.getBoundingClientRect();

    el.style.width = bound.width + 'px'
    el.style.height = bound.height + 'px'


    const elBound = el.getBoundingClientRect();
    this.ref.current.style.marginTop = -(bound.top - elBound.top) + 'px';
    this.ref.current.style.marginLeft = -(bound.left - elBound.left) + 'px';

  }
  render() {
    return (
      <div>
        <div
          ref={this.ref}
          className={this.props.className}
          style={{
            position: 'absolute',
            transform: `scale(${this.props.scale})`,
          }}
          data-library-frame={this.props.frame._id}
        >
          {this.props.children}
        </div>
        
      </div>
    )
  }
}

@component
class Frame extends Component<{ devRuntime: DevRuntime, componentId, frame, selectMode, parent }> {
  editorStylesScriptWrapper: ScriptWrapper
  defaultPropsScriptWrapper: ScriptWrapper
  static debounce = false;

  static styles = styled.div`
    > h3 {
      ${baseCoreStyles}
      margin-bottom: 8px;
    }
    > .frame {
      border: 1px solid #d1d1d1;
      padding: 20px;
      border-radius: 10px;

      > .inner {
        background-color: #fafafa;
        padding: 20px;
        overflow: auto;
        position: relative;
        > .overlay {
          position: absolute;
          z-index: 99999999;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
  `;

  constructor(props) {
    super(props);
    const { frame } = this.props;
    this.editorStylesScriptWrapper = createScriptWrapper({
      blocks: () => XObject.get(frame, 'editorStyles', []),
      setBlocks: b => frame.editorStyles = b,
    });
    this.defaultPropsScriptWrapper = createScriptWrapper({
      blocks: () => XObject.get(frame, 'componentProps', []),
      setBlocks: b => frame.componentProps = b,
    });

  }
  render() {
    const { frame } = this.props;
    const comp = this.props.devRuntime.getComponent(this.props.componentId);
    const Comp = comp && this.props.devRuntime.executeComponent(x(comp));

    let defaultProps = {}
    if (!scriptEmpty(frame?.componentProps)) {
      defaultProps = this.props.devRuntime.executeBlocks(frame?.componentProps);
    }



    let wrapperComp;

    if (this.props.devRuntime.devProject.wrapperComponent) {
      wrapperComp = this.props.devRuntime.getComponent(this.props.devRuntime.devProject.wrapperComponent);
    }

    const stylesClass = !scriptEmpty(this.props.frame.editorStyles) && this.props.devRuntime.getStyleClass(this.props.frame.editorStyles, {}, frame._id);

    const editorState = getDevProjectEditorState(this.props.devRuntime.devProject);

    return (
      <>
        <h3
          onClick={() => {
            editorState.additionalInspectorTabs = [
              { type: 'libraryFrame', id: this.props.frame._id }
            ]
          }}
        ><PropertyField object={frame} property="name" dblClickEdit /></h3>
        <div className={cx('frame')}>
          <div className="inner">
            <ScaledFrame scale={frame.scale || 1} frame={frame} className={stylesClass}>
              {wrapperComp && renderWrapperBuilderNode(this.props.devRuntime, x(wrapperComp), x(wrapperComp).root, 
                <Comp {...defaultProps} data-pb-path={[]} />
              )}
              {!wrapperComp && <Comp {...defaultProps} data-pb-path={[]} />}
            </ScaledFrame>
            {this.props.selectMode && <div className="overlay" data-select-overlay />}
          </div>
        </div>
      </>
    )
  }
}

@component
class ComponentPage extends Component<{ devRuntime: DevRuntime, componentId, page, selectMode, parent }> {
  static styles = styled.div`
    ${Frame} {
      margin-bottom: 16px;
    }
    > .type {
      font-size: 13px;
      ${baseCoreStyles}
      color: #c0c0c0;
    }
    > h2 {
      margin-top: 0;
      ${baseCoreStyles}
    }
  `;

  componentDidMount(): void {
    const rootEl = ReactDOM.findDOMNode(this) as Element;

    const update = () => {
      const els = rootEl.querySelectorAll('[data-library-frame]');
      for (const el of els) {
        generateAppTree(this.props.devRuntime, el.firstChild, [`frame:${el.getAttribute('data-library-frame')}`]);

      }
    }

    update();
  }


  state = X({});
  render() {
    const { page } = this.props;
    const frames = XObject.get(page, 'frames', []);
    const component = this.props.devRuntime.getComponent(this.props.componentId);
    if (component.type == 'builderComponent') {
      const type = this.props.devRuntime.devProject.builderComponentTypes.find(t => t._id == component.builderComponentType);
      return (
        <>
          {type && <span className="type">{type.name}</span>}
          <h2
            onContextMenu={e => {
              e.preventDefault();
              showContextMenu(e, [
                {
                  text: 'Add frame',
                  onClick: () => {
                    frames.push(XObject.obj({
                      name: 'Default'
                    }));
                  }
                }
              ])
            }}
          >
            <PropertyField object={component} property="name" dblClickEdit />
  
          </h2>
  
          <div>
            {frames.map(f => {
              return (
                <div key={f._id}>
                  <Frame componentId={this.props.componentId} frame={f} devRuntime={this.props.devRuntime} selectMode={this.props.selectMode} parent={this.props.parent} />
                </div>
              )
            })}
          </div>
        </>
      )
    }
    else {
      return (
        <Entry
          active={null}
          comp={component}
          components={[]}
          devProject={this.props.devRuntime.devProject}
          devRuntime={this.props.devRuntime}
          i={0}
          nodeDesignerChanged={() => {}}
          st={this.state}
        />
      )
    }

  }
}

@component
class ColorsPage extends Component<{ devRuntime: DevRuntime }> {
  static styles = styled.div`
    ${baseStyles}
    h2 {
      margin-top: 0;
    }

    .colors {
      display: flex;
      flex-wrap: wrap;
      .color {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 70px;
        /* height: 50px; */
        .swatch {
          display: block;
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }
    }
  `
  render() {
    return (
      <>
        {/* <h2>Colors</h2> */}
        <div className="colors">
          {this.props.devRuntime.devProject.colors.map(c => {
            return (
              <div key={c._id} className="color">
                <span className="swatch"
                  style={{
                    backgroundColor: c.color
                  }}
                />
                <span className="name">{c.name}</span>
              </div> 
            )
          })}
        </div>
      </>
    )
  }
}

@component
class IconsPage extends Component<{ devRuntime: DevRuntime }> {
  static styles = styled.div`
    ${baseStyles}
    h2 {
      margin-top: 0;
    }

    .icons {
      display: flex;
      flex-wrap: wrap;
      gap: 18px;
      .icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 70px;
        /* height: 50px; */
        .image {
          display: block;
          width: 20px;
          height: 20px;
          /* border-radius: 50%; */
          display: flex;
          justify-content: center;
        }

        .name {
          text-align: center;
        }
      }
    }
  `
  render(Container?) {
    return (
      <>
        {/* <h2>Icons</h2> */}
        <FileUpload Tag={Container}
        onUpload={id => {
          this.props.devRuntime.devProject.assets.push(XObject.obj({
            name: 'Untitled',
            file: id,
          }))
        }}
      >
        <div className="icons">
          {this.props.devRuntime.devProject.assets.map((c,i) => {
            return (
              <div key={c._id} className="icon"
                onContextMenu={e => {
                  e.preventDefault();
                  showContextMenu(e, [
                    {
                      text: 'Delete',
                      onClick: () => {
                        this.props.devRuntime.devProject.assets.splice(i, 1);
                      },
                    }
                  ])
                }}
              >
                <span className="image">
                  <RemoteSvg url={uploadedFileUrl(c.file)} />
                </span>
                <PropertyField dblClickEdit className="name" object={c} property="name" />
              </div> 
            )
          })}
        </div>
        </FileUpload>
      </>
    )
  }
}

@component
class StylePage extends Component<{ devRuntime: DevRuntime, style }> {
  static styles = styled.div`
    ${baseStyles}

    > h2 {
      margin-top: 0;
    }

    > .frame {
      border: 1px solid #d1d1d1;
      padding: 20px;
      border-radius: 10px;

      > .inner {
        background-color: #fafafa;
        padding: 20px;
        overflow: auto;
        position: relative;
        .asdf {
          width: 30px;
          height: 30px;
        }
        /* > .overlay {
          position: absolute;
          z-index: 99999999;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        } */
      }
    }
  `;
  render() {
    const className = this.props.devRuntime.getStylesObjClass(this.props.style)
    return (<>


      <h2>{this.props.style.name}</h2>

      <div className="frame">
        <div className="inner">
          <div className={cx('asdf', className )} />
        </div>
      </div>
    </>)
  }
}


@component
class ComponentEntry extends Component<{ comp, devRuntime }> {
  static styles = styled.div`
    display: flex;
    align-items: center;

    .type {
      display: flex;
      align-items: center;
      margin-left: 4px;
    }
    .thing {
      margin-left: auto;
      font-size: 8px;
      color: gray;
    }
  `;
  render(Container?) {
    const devProject = this.props.devRuntime.devProject;
    const comp = this.props.comp;
    const type = devProject.builderComponentTypes.find(t => t._id == comp.builderComponentType);
    let thing;

    if (devProject.rootComponent == comp._id) {
      thing = 'Entry';
    }
    if (devProject.wrapperComponent == comp._id) {
      thing = 'Global Styles';
    }
    return (
      <Container>
       <>{comp.name} {comp.type == 'builderComponent' ? (type && <span className="type">{type.name}</span>) : <span className="type">Script</span>} <span className="thing">{thing}</span></>
      </Container>
    )
  }
}

@component
export class Library extends Component<{ devRuntime: DevRuntime, selectMode, parent, state: {
  activeBlock
  activePage
} }> {
  static styles = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    > .left {
      ${baseStyles}
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 300px;
      border-right: 1px solid #efefef;
      overflow: auto;

      .header {
        &:not(:hover) {
          .add {
            display: none;
          }
        }
      }

      .add {
        cursor: pointer;
        margin-left: auto;
        display: flex;
        align-items: center;
        svg {
          width: 10px;
          height: 10px;
        }
      }

      > div {
        ${sectionStyles}

      }

      .row {
        padding-top: 4px;
        padding-bottom: 4px;
      }

      .type {
        color: #c0c0c0;
        font-size: 10px;
      }
    }

    > .right {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 300px;
      right: 0;
      overflow: auto;
      padding: 18px 30px;
    }
  `;

  memory = {}

  ref =  React.createRef<NotionDocument2>();

  state = XInit(class {
    asdfasdf = {}

    editing

    stylesState ={}
  })

  __render() {
    const { devRuntime } = this.props;
    const { devProject } = devRuntime;
    const library = XObject.get(devProject, 'library', {});

    return (
      <>
        <button
          onClick={() => {
            console.log(x(library));
          }}
        >Log</button>
        <button
          onClick={() => {
            const pages = {};
            const iter = blocks => {
              for (const block of blocks) {
                if (block.id) {
                  pages[block.id] = library._pages[block._id];
                }
                if (block.children) iter(block.children);
              }
            }
            iter(library.rootBlocks);
            console.log(pages);
            library.componentPages = pages;
          }}
        >New pages</button>
        {/* <button
          onClick={() => {
            library._pages = library.pages;
          }}
        >Backup pages</button> */}
      </>
    )
  }

  render() {
    const { devRuntime } = this.props;
    const { devProject } = devRuntime;
    const library: {
      rootBlocks
      componentPages
      // activePage
      // activeBlock
      excluded
    } = XObject.get(devProject, 'library', {});
    XObject.get(library, 'componentPages', {});
    const pages = XObject.get(library, 'pages', {});

    const state = this.props.state;

    let activePageId = isId(state.activePage) && devRuntime.getComponent(state.activePage) && state.activePage;




    const components = {};

    const componentsByParent: any = {};

    for (const comp of this.props.devRuntime._components()) {
      if (comp.excludeFromLibrary) continue;
      components[comp._id] = true;
      if (!componentsByParent[comp.parent]) {
        componentsByParent[comp.parent] = [];
      }
      componentsByParent[comp.parent].push(comp._id);
    }

    // const collect = (blocks, parent) => {
    //   for (const block of blocks) {
    //     if (block.id) {
    //       components[block.id] = true;

    //       // if (!componentsByParent[parent?.id]) {
    //       //   componentsByParent[parent?.id] = [];
    //       // }
    //       // componentsByParent[parent?.id].push(block.id);
    //     }
    //     if (block.children) collect(block.children, block);
    //   }
    // }

    // collect(library.rootBlocks, null);

    const activePage = activePageId && XObject.get(library.componentPages, activePageId, X({}));

    const groupedByType = {};

    for (const id of componentsByParent.undefined) {
      const comp = devRuntime.getComponent(id);
      if (comp.type == 'builderComponent') {
        if (!groupedByType[comp.builderComponentType]) {
          groupedByType[comp.builderComponentType] = [];
        }
        groupedByType[comp.builderComponentType].push(id);
  
      }
    }



    const activeStyle = isId(state.activePage) && devProject.styles.find(s => s._id == state.activePage);

    


    return (
      <>
        <div className="left">
          <div>
            <span className="sectionHeader">Design Tokens</span>
            <div className="section">
              <Tree
                nodes={[
                  {
                    key: 'colors',
                    text: 'Colors',
                    children: [],
                    elId: 'colors',
                  }
                ]}
                onClickNode={id => {
                  state.activePage = id;
                }}
                state={{}}
                active={state.activePage}
              />
            </div>
          </div>
          <div>
            <span className="sectionHeader">Assets</span>
            <Tree
              nodes={[
                {
                  key: 'icons',
                  text: 'Icons',
                  elId: 'icons',
                  children: [],
                }
              ]}
              state={{}}
              onClickNode={id => {
                state.activePage = id;
              }}
              active={state.activePage}
            />
            {this.props.devRuntime.devProject.styles.length > 0 && (
              <>
                <span className="sectionHeader">Styles</span>
                <Tree
                  nodes={[
                    ...this.props.devRuntime.devProject.styles.map((s, i) => {
                      return {
                        key: s._id,
                        text: s.name,
                        elId: s._id,
                        children: [],
                        contextMenu: [
                          {
                            text: 'Delete',
                            onClick: () => {
                              this.props.devRuntime.devProject.styles.splice(i, 1);
                            }
                          }
                        ]
                      }
                    })

                    // {
                    //   key: 'button',
                    //   text: 'Button 1',
                    //   children: [],
                    // },
                    // {
                    //   key: 'tasdf',
                    //   text: 'Typography',
                    //   children: [
                    //     {
                    //       key: 'colors',
                    //       text: 'T1',
                    //       children: [],
                    //     },
                    //     {
                    //       key: 'button',
                    //       text: 'T2',
                    //       children: [],
                    //     },
                    //   ]
                    // },
                    // {
                    //   key: 'asdfasdfa',
                    //   text: 'Surfaces',
                    //   children: []
                    // }

                  ]}
                  state={{}}

                  onClickNode={id => {
                    state.activePage = id;
                  }}
                  active={state.activePage}
                />
              </>
            )}
          </div>
          <div>
            <span className="sectionHeader">Components</span>
            {Object.keys(groupedByType).map(typeId => {
              const type = devProject.builderComponentTypes.find(t => t._id == typeId);
              const components = groupedByType[typeId];

              const collectItems = (entries, level=0) => {
                const items = [];
                
                for (const entry of entries) {
                  const comp = devRuntime.getComponent(entry);
                  const type = devProject.builderComponentTypes.find(t => comp.builderComponentType == t._id);

                  items.push({
                    key: entry,
                    elId: entry,
                    // text: <>{comp.name} {comp.type == 'builderComponent' ? (type && <span className="type">{type.name}</span>) : <span className="type">Script</span>}</>,
                    text: <ComponentEntry comp={comp} devRuntime={this.props.devRuntime} />,
                    children: componentsByParent[entry] && collectItems(componentsByParent[entry], level + 1),
                    contextMenu: [
                      {
                        text: 'Delete',
                        onClick: () => {
                          this.props.devRuntime.deleteComponent(comp._id);
                        },
                      },
                      {
                        text: 'Exclude from library',
                        onClick: () => {
                          comp.excludeFromLibrary = true;
                        },
                      },
                      {
                        text: 'Make app entry',
                        onClick: () => {
                          this.props.devRuntime.devProject.rootComponent = comp._id;
                        },
                      },
                      {
                        text: 'Make global styles',
                        onClick: () => {
                          this.props.devRuntime.devProject.wrapperComponent = comp._id;
                        }
                      }
                    ],
                  })
                }

                return items;
              }

              return (
                <div key={typeId} className="section">
                  <span className="header">{type?.name || 'Component'} <span
                  className="add"
                    onClick={() => {
                      this.props.devRuntime.createBuilderComponent('Untitled', {
                        builderComponentType: type?._id,
                      })
                    }}
                  ><Svg name="plus" /></span></span>
                  <div className="content">
                  <Tree
                    nodes={collectItems(components)}
                    state={XObject.get(this.state.asdfasdf, typeId, {})}
                    onClickNode={id => {
                      state.activePage = id;
                      this.props.parent.navigateToComponent(id)
                    }}
                    active={state.activePage}
                  />
                  </div>
                </div>
              )
            })}
          </div>
          <div>
            <span className="sectionHeader">Scripts</span>
            <Tree
              onClickNode={id => {
                console.log(id);
                state.activePage = id;
              }}
              active={state.activePage}
              nodes={this.props.devRuntime._components().filter(c => !c.type && !c.excludeFromLibrary).map(c => {
                return (
                  {
                    key: c._id,
                    elId: c._id,
                    text: c.name,
                    children: [],
                    contextMenu: [
                      {
                        text: 'Debug',
                        onClick: () => {
                          console.log(x(c));
                        }
                      },
                      {
                        text: 'Delete',
                        onClick: () => {
                          this.props.devRuntime.deleteComponent(c._id);
                        },
                      },
                      {
                        text: 'Exclude from library',
                        onClick: () => {
                          c.excludeFromLibrary = true;
                        }
                      }
                    ],
                  }
                );
              })}
              state={{}}
            />
          </div>
        </div>
        <div className="right" id="protobuilder-output-area">
          {state.activePage == 'colors' && (
            <>
              <ColorsPage devRuntime={this.props.devRuntime} />
            </>
          )}
          {state.activePage == 'icons' && (
            <>
              <IconsPage devRuntime={this.props.devRuntime} />
            </>
          )}
          {activePage && (
            <ComponentPage
              key={activePageId}
              devRuntime={this.props.devRuntime}
              componentId={activePageId}
              page={activePage}
              selectMode={this.props.selectMode}
              parent={this.props.parent}
            />
          )}

          {activeStyle && (
            <>
              <StylePage style={activeStyle} devRuntime={this.props.devRuntime} />
            </>
          )}
        </div>
      </>
    );
  }
}
