import cx from 'classnames';
import { Component } from 'react';
import { component } from './component2';
import { styled } from './component';
import { X, XClone, XInit, XObject, x } from './XObject';
import { PropValuePair, Type, render, renderText } from './Type';
import { Labeled } from './Labeled';
import { HStack } from './Type';
import { DevRuntime } from './DevRuntime';
import { execFormula, expandFormula } from './shorthand/formula';
import { execFormulaFromData } from './shorthandEditor/execFormulaFromData';


@component
class Section extends Component<{ header: string, children? }> {
  static styles = styled.div`
    > .header {
      color: gray;
      font-weight: bold;
    }
    > .content {
      > * {
        margin-bottom: 4px;
      }
    }
  `;
  render() {
    return (
      <>
        <span className="header">{this.props.header}</span>
        <div className="content">
          {this.props.children}
        </div>
      </>
    )
  }
}

@component
export class CSSDesignerCont extends Component {
  static styles = styled.div`
    width: 400px;
  `

  render() {
    return '';
    // return <CSSDesigner state={null} />;
  }
}


const props = {
  'display': Type.Display,
  'position': Type.Position,
  'border': Type.Border,
  'border-radius': Type.BorderRadius,
  'overflow': Type.Overflow,

  'top': Type.Top,
  'right': Type.Right,
  'bottom': Type.Bottom,
  'left': Type.Left,
  'z-index': Type.ZIndex,

  'margin': Type.Margin,
  'padding': Type.Padding,
  'box-sizing': Type.BoxSizing,
  'opacity': Type.Opacity,

  'width': Type.Width,
  'height': Type.Height,

  'font-family': Type.FontFamily,
  'font-weight': Type.FontWeight,
  'font-style': Type.FontStyle,
  'font-size': Type.FontSize,
  'color': Type.Color,

  'text-align': Type.TextAlign,

  'justify-content': Type.JustifyContent,
  'align-items': Type.AlignItems,
  'align-content': Type.AlignContent,
  'flex-direction': Type.FlexDirection,
  'gap': Type.Gap,

  'cursor': Type.Cursor,

  'background-color': Type.BackgroundColor,

  'box-shadow': Type.BoxShadow,

  'white-space': Type.WhiteSpace,
}

export function generateCssDesignerLines(devRuntime, properties) {
  const lines: string[] = [];
  for (const prop in x(properties)) {
    const v = renderText(devRuntime, { type: props[prop], value: x(properties)[prop] });
    if (v instanceof PropValuePair) {
      lines.push(`${v.prop}: ${v.value}`);
    }
    else if (v !== '') {
      lines.push(`${prop}: ${v}`);
    }
  }

  return lines;
}

export function generateCssDesignerLines2(devRuntime, properties) {
  const lines: [string, string][] = [];
  for (const prop in properties) {
    const v = renderText(devRuntime, { type: props[prop], value: properties[prop] });
    if (v instanceof PropValuePair) {
      lines.push([v.prop, v.value]);
    }
    else if (v !== '') {
      lines.push([prop, v]);
    }
  }

  return lines;
}




export function generateDesignerCss(devRuntime: DevRuntime, node) {
  const output = [];

  const iter = (styleNode, parents=[]) => {
    const lines = generateCssDesignerLines2(devRuntime, styleNode.properties || {});

    output.push(
      `${devRuntime.buildSelector(node, parents.concat(styleNode))} {
        --id: styleNode#${styleNode._id || node._id};
        ${lines.map(l => 
          `
          ${l[0]}: ${l[1]};
          --id-${l[0]}: styleNode#${styleNode._id || node._id}/${l[0]};
          `).join('\n')};
      }`
    );

    if (styleNode.children) for (const child of styleNode.children) {
      iter(child, parents.concat(styleNode))
    }

  }

  if (node.designer) iter(node.designer);

  return output.join('\n');
}

@component
class PropWrapper extends Component<{ prop, state, children }> {
  static styles = styled.div`
    &.active {
      background-color: #f0f0f0;
      outline: 2px solid #f0f0f0;
    }
  `;
  render(Container?) {
    return (
      <Container
        onClick={() => {
          this.props.state.prop = this.props.prop;
        }}
        className={cx({
          active: this.props.prop == this.props.state.prop,
        })}
      >
        {this.props.children}
      </Container>
    );
  }
}

@component
export class CSSDesigner extends Component<{ properties, devRuntime, showAll, state }> {
  static debounce = false;
  static styles = styled.div`
    /* width: 400px; */
    /* border-right: 1px solid black; */

    > * {
      margin-bottom: 8px;
      padding-bottom: 8px;
    }

    > ${Section} {
      border-bottom: 1px solid #e3e3e3;
    }

    .placeholder {
      color: gray;
    }
  `;

  // state = XInit(class {
  //   properties:any = {}
  //   showAll = true
  // })

  state: {
    properties
    showAll
  }

  // constructor(props) {
  //   super(props);
  //   this.state = XClone(this.props.state);
  // }


  render() {
    // const state = this.state;
    // const state = this.props.state || this.state;
    const properties = this.props.properties;


    const rr = (prop) => {
      return render(this.props.devRuntime, {
        type: props[prop],
        value: x(properties[prop]),
        setValue: v => {
          properties[prop] = X(v)
        },
      })
    }


    const position = renderText(this.props.devRuntime, { type: Type.Position, value: properties['position'] });
    const display = renderText(this.props.devRuntime, { type: Type.Position, value: properties['display'] });
    const isPositioned = position && position != 'static';



    type Section  = 
      | 'boxModel.dimensions'
      | 'boxModel.border'
      | 'boxModel.padding'
      | 'boxModel.margin'
      | 'boxModel.border-radius'
      | 'boxModel.box-sizing-bottom'
      | 'boxModel.background-color'
      | 'boxModel.box-shadow'
      // | 'boxModel.overflow'

      | 'typography'
      | 'typography.fontFamily'
      | 'typography.weightStyle'
      | 'typography.sizeColor'
      | 'typography.textOverflow'
      | 'typography.textAlign'
      | 'typography.whiteSpace'

      | 'position'
      | 'position.topLeft'
      | 'position.bottomRight'
      | 'position.zIndex'

      | 'flexLayout'
      | 'flexLayout.direction'
      | 'flexLayout.line2'
      | 'flexLayout.gap'
      
      | 'userInterface'
      | 'userInterface.cursor'


    const hasValue = prop => {

    }
    const shouldShow = (section: Section) => {
      // return true;
      if (this.props.showAll) return true;
      
      if (section == 'boxModel.dimensions') {
        return properties.width || properties.height;
      }
      else if (section == 'boxModel.border') {
        return properties.border;
      }
      else if (section == 'boxModel.border-radius') {
        return properties['border-radius'];
      }
      else if (section == 'boxModel.box-sizing-bottom') {
        return properties.overflow || properties['box-sizing'] || properties['opacity']
      }
      else if (section == 'boxModel.padding') {
        return properties.padding;
      }
      else if (section == 'boxModel.margin') {
        return properties.margin;
      }
      else if (section == 'boxModel.background-color') {
        return properties['background-color'];
      }
      else if (section == 'boxModel.box-shadow') {
        return properties['box-shadow'];
      }

      else if (section == 'typography') {
        return (
          shouldShow('typography.fontFamily') ||
          shouldShow('typography.sizeColor') ||
          shouldShow('typography.textOverflow') ||
          shouldShow('typography.weightStyle') ||
          shouldShow('typography.textAlign') ||
          shouldShow('typography.whiteSpace')
        )
      }
      else if (section == 'typography.fontFamily') {
        return properties['font-family'];
      }
      else if (section == 'typography.sizeColor') {
        return properties['font-size'] || properties['color'];
      }
      else if (section == 'typography.weightStyle') {
        return properties['font-weight'] || properties['font-style'];
      }
      else if (section == 'typography.textAlign') {
        return properties['text-align'];
      }
      else if (section == 'typography.whiteSpace') {
        return properties['white-space'];
      }

      else if (section == 'position') {
        return (
          shouldShow('position.topLeft') ||
          shouldShow('position.bottomRight') ||
          shouldShow('position.zIndex')
        )
      }
      else if (section == 'position.topLeft') {
        return properties['top'] || properties['left'];
      }
      else if (section == 'position.bottomRight') {
        return properties['bottom'] || properties['right'];
      }
      else if (section == 'position.zIndex') {
        return properties['z-index'];
      }

      else if (section == 'flexLayout') {
        return (
          shouldShow('flexLayout.direction') ||
          shouldShow('flexLayout.line2') ||
          shouldShow('flexLayout.gap')
        )
      }
      else if (section == 'flexLayout.direction') {
        return properties['flex-direction'];
      }
      else if (section == 'flexLayout.line2') {
        return properties['justify-content'] || properties['align-items'] || properties['align-content'];
      }
      else if (section == 'flexLayout.gap') {
        return properties['gap'];
      }

      else if (section == 'userInterface') {
        return (
          shouldShow('userInterface.cursor')
        )
      }
      else if (section == 'userInterface.cursor') {
        return properties.cursor;
      }

      else {
        return false;
      }
    }
    
    return (
      <>
        {/* <button
          onClick={() => {

          }}
        >Css</button>

        <input type="checkbox" checked={state.showAll} onChange={e => {
          state.showAll = !state.showAll;
        }} /> */}


        <Section header="">
          <HStack>
            {rr('display')}
            {rr('position')}
            <select>
              <option className="placeholder"></option>
              <option>Flex</option>
              <option>Grid</option>
            </select>
          </HStack>



          {shouldShow('boxModel.dimensions') && <HStack>
            <Labeled
              onClear={() => {
                delete properties.width;
              }}
              label="Width"
              input={rr('width')}
            />
            <Labeled
              label="Height"
              input={rr('height')}
            />
          </HStack>}

          {shouldShow('boxModel.padding') && <Labeled
            label="Padding"
            input={rr('padding')}
          />}
          {shouldShow('boxModel.margin') && <Labeled
            label="Margin"
            input={rr('margin')}
          />}

          {shouldShow('boxModel.border') && <Labeled
            onClear={() => delete properties.border}
            label="Border"
            input={rr('border')}
          />}
          {shouldShow('boxModel.border-radius') && <Labeled
            label="Border Radius"
            input={rr('border-radius')}
          />}

          {shouldShow('boxModel.box-sizing-bottom') && <HStack>
            <PropWrapper prop="box-sizing" state={this.props.state}>
            <Labeled
              label="Box Sizing"
              input={rr('box-sizing')}
            />
            </PropWrapper>
            <PropWrapper prop="overflow" state={this.props.state}>
              <Labeled
                label="Overflow"
                input={rr('overflow')}
              />
            </PropWrapper>
            <PropWrapper prop="opacity" state={this.props.state}>
            <Labeled
              onClear={() => delete properties.opacity}
              label="Opacity"
              input={rr('opacity')}
            />
            </PropWrapper>
          </HStack>}

          {shouldShow('boxModel.background-color') && <Labeled
            label="Background Color"
            input={rr('background-color')}
          />}


          {shouldShow('boxModel.box-shadow') && <Labeled
            label="Box Shadow"
            input={rr('box-shadow')}
            onClear={() => delete properties['box-shadow']}

          />}
        </Section>
        
        
        {shouldShow('typography') && <Section header="Typography">
          {shouldShow('typography.fontFamily') && (
            <PropWrapper prop="font-family" state={this.props.state}>
              <Labeled
                onClear={() => delete properties['font-family']}
                label="Family"
                input={rr('font-family')}
              />
            </PropWrapper>
          )}
          {shouldShow('typography.weightStyle') && <HStack>
            <PropWrapper prop="font-weight" state={this.props.state}>
            <Labeled
              label="Weight"
              input={rr('font-weight')}
            />
            </PropWrapper>
            <PropWrapper prop="font-style" state={this.props.state}>
            <Labeled
              label="Style"
              input={rr('font-style')}
            />
            </PropWrapper>
          </HStack>}
          {shouldShow('typography.sizeColor') && <HStack>
          <PropWrapper prop="font-size" state={this.props.state}>
            <Labeled
            onClear={() => delete properties['font-size']}
              label="Size"
              input={rr('font-size')}
            />
            </PropWrapper>

            <PropWrapper prop="color" state={this.props.state}>
            <Labeled
              onClear={() => delete properties['color']}
              label="Color"
              input={rr('color')}
            />
            </PropWrapper>
          </HStack>}

          {(shouldShow('typography.textAlign')|| shouldShow('typography.whiteSpace')) && <HStack>
          <Labeled
              onClear={() => delete properties['text-align']}
              label="Text Align"
              input={rr('text-align')}
            />
        <Labeled
              onClear={() => delete properties['white-space']}
              label="White Space"
              input={rr('white-space')}
            />
          </HStack>}

          {/* {shouldShow('typography.textOverflow') && <Labeled
              label="Text Overflow"
              input={
                <select>
                  <option>visible</option>
                </select>
              }
            />} */}
        </Section>}

        {isPositioned && shouldShow('position') && <Section header="Position">
          {shouldShow('position.topLeft') && <HStack>
            <Labeled
              label="Top"
              input={rr('top')}
            />
            <Labeled
              label="Left"
              input={rr('left')}
            />
          </HStack>}
          {shouldShow('position.bottomRight') && <HStack>
            <Labeled
              label="Bottom"
              input={rr('bottom')}
            />
            <Labeled
              label="Right"
              input={rr('right')}
            />
          </HStack>}
          {shouldShow('position.zIndex') && <Labeled
            label="Z Index"
            input={rr('z-index')}
          />}
        </Section>}

        {(display == 'flex' || display == 'inline-flex') && shouldShow('flexLayout') && <Section header="Flex Layout">
          {shouldShow('flexLayout.direction') && <Labeled
            label="Direction"
            input={rr('flex-direction')}
          />}
          {shouldShow('flexLayout.line2') && <HStack>
            <Labeled
              label="Justify Content"
              input={rr('justify-content')}
            />
            <Labeled
              label="Align Items"
              input={rr('align-items')}
            />
            <Labeled
              label="Align Content"
              input={rr('align-content')}
            />
          </HStack>}

          {shouldShow('flexLayout.gap') && <Labeled
            label="Gap"
            input={rr('gap')}
          />}
        </Section>}

        {/* <Section header="Grid Layout">
        </Section>
         */}
        {/* <Section header="Appearance">

         <h4>Backgrounds</h4>

         <h4>Box Shadows</h4>

         <h4>Text Shadows</h4>

         <h4>Filters</h4>

         <h4>Transforms</h4>
        </Section> */}
        {shouldShow('userInterface') && <Section header="User Interface">
          {shouldShow('userInterface.cursor') && <Labeled
            label="Cursor"
            input={rr('cursor')}
          />}

          {/* <Labeled
            label="Outline"
            input={(
              <>
                <HStack>
                  <Labeled
                    label="Width"
                    input={
                      <UnitInput />
                    }
                  />
                  <Labeled
                    label="Type"
                    input={
                      <select>
                        <option>solid</option>
                        <option>dotted</option>
                        <option>dashed</option>
                      </select>
                    }
                  />
                  <Labeled
                    label="Color"
                    input={
                      <ColorPicker />
                    }
                  />
                </HStack>
              </>
            )}
          /> */}

        </Section>}
      </>
    )
  }
}
