import { db } from '../db';
import { XObject } from '../XObject';
import { appState } from '../etc/appState';

export function addNote(args) {
  db.notes.push(XObject.obj({
    mode: appState.currentMode,
    user: appState.user,
    ...args,
  }));

}
