import { NotionDocumentWrapper } from "../../components/NotionDocumentWrapper";
import { XObject, x } from "../../XObject";
import { db } from "../../db";
import { evaluate, mapStructure } from "../main";
import { structRenderers } from "../structRenderers";
import { typeRegistry } from "../typeRegistry.1";
import { $Document } from "./$Document";
import { getEntityById } from "../../etc/createEntity";

export const $EntityDocumentEditor = typeRegistry.registerType({
  $: '3a71c6e7-e67e-51f1-9337-395663944057',
  Entity: '2cf6abee-92be-5f67-b9f2-032bef719236',
  DocumentKey: 'e68a79df-c1be-5712-a75d-8e76e0fb89a6',
  Config: 'aa8eec88-d92c-513b-a913-32c1d0397e6e',
}, ids => ({
  _id: ids.$,
  name: 'Entity Document Editor',
  definition: [
    {
      id: ids.Entity,
      name: 'Entity',
      type: [],
      property: 'entity',
    },
    {
      id: ids.DocumentKey,
      name: 'Document Key',
      type: [],
      property: 'documentKey',
    },
    {
      id: ids.Config,
      name: 'Config',
      type: [],
      property: 'config',
    },
  ]
}));

function getEntityDoc(entityId: string, key: string, config: string) {
  const entity = getEntityById(entityId);
  if (!entity) {
    return null;
  }

  const doc = entity.documents?.find?.(d => d.key == key);
  if (doc) {
    return doc;
  }

  const newDoc = XObject.obj({
    type: 'f05015fd-bd2a-55e9-992d-017f0ba9ac55',
    key,
    config,
    content: [
      XObject.obj({}),
    ],
  })

  // entity.documents.push(newDoc);
  XObject.push(entity, 'documents', newDoc);
  return newDoc;
  
}

structRenderers[$EntityDocumentEditor.$] = (value, map, state) => {
  const mapped = mapStructure(value);
  const entity = evaluate(mapped.entity, map);
  const key = evaluate(mapped.documentKey, map);
  const config = evaluate(mapped.config, map);
  // console.log('entity', entity, key, config?.content);

  // return null;
  if (entity && key) {
    const doc = getEntityDoc(entity, key, config?.content?.id);
    return <NotionDocumentWrapper
    docId={[entity, doc._id]}
      entity={entity}
      inline
      blocks={doc.content}
      setBlocks={blocks => {
        doc.content = blocks;
      }}
      name={null}
      configId={config?.content?.id}
      configMap={{
        [$Document.Entity]: entity,
      }}
      // setName={() => {

      // }}
    />
  }
  
}