export enum WindowType {
  AllGraph = 'f5b21c68-d1af-57d8-a5d8-440fa0f6a204',
  MetaStates = 'aee81282-3372-5bd0-bbb5-7ff85b6096a0',
  Linear = 'c78a8f42-2141-5587-9c25-e0a10fd029d1',
  Queues = 'b5a3e327-222c-5265-8a38-b27198535d46',
  Queue = '284d5db3-0e83-5e7c-a575-8ce35d3e6038',
  EntityQueues = 'ce7073e4-6cba-56c5-b8e3-efc70bee3a7d',
  Types = '2e40c66d-e9eb-5445-a485-6d30e5d7220b',
  Notion = '3ca15639-1a3a-5c1b-9fa2-0b5e78350b8a',
  OrgEntities = '16968fd1-1f41-527b-9d19-1d1972bc5a78',
  Databases = '796f9d3e-38ad-51db-9ad5-a56381299ab7',
  Database = '1bd0fc6f-d7e8-533b-ad43-f97f3ae11622',
  Document = 'c33e10e6-6b99-5393-9e35-174940d55c31',
  NotionDocuments = '207068fb-f945-5b87-a5cb-3c8c1f26d3f9',
  NotionDocument = '371b72a3-6c17-5e86-b54f-15abde2d9513',
  Glue = 'glue',
  GlueDev = '1d08e3bc-8639-55f0-b56c-28a3a16f6a8b',
  MenuItems = '93d32b42-6ccf-5732-8e51-29cd27553374',
  SystemConfig = '9abc654b-49cf-533c-83fc-15aef5611b6d',
  Users = '2d51905b-6d1b-5096-8b1d-b4421b496556',
  EntityConfig = '0a566efb-0bb7-5e99-a480-f59dc9768c51',
  Inbox = 'ad6c535f-9478-58f6-91f8-d741a8eff14b',
  SpaceWindow = '0648fa32-5a3c-5a65-9397-2936606e019b',
  SpacesWindow = '9de0e7ba-3c7b-54ba-acc3-203ba3c136b7',
  Query = '29e8dbec-d690-5845-9e4f-af81e04af321',
  QueryView = 'ac1d9f9c-0343-5120-a7bc-b8df218e8439',
  QueryExpressionEditor = '3d6e8586-b78f-588a-8fe0-166e7f81cb7e',
  Space = 'fd068295-cb05-5835-ac00-fff31ef16d80',
  EditDocument = '075a89c3-6481-5d06-8ea6-60538025da18',
  TypeWindow = '996ce405-1298-528d-a6fe-81632e35cbb4',

  Attribute = 'f7e672c1-6f71-5003-9569-8e59dbd7abde',

  AppLibrary = 'afd7920b-7408-5076-91c0-5d963859a548',

  EditMode = '12b714cd-9018-54ec-b77b-15aae63ba938',
  ChatGPTTest = 'd4c2513e-3693-5db1-98f1-00b161be50ef',

  NotionDocumentTest = '828691c4-1527-5f0f-a473-b264f5715af8',

  CodeTest = '7be0b12a-366e-584f-95c4-89000337c8ce',

  CodeComponents = '777a5898-7f08-5323-96f4-18072061c00d',

  CodeComponent = '42074689-aed3-500a-a965-74b33d4b2f75',

  NotionDocument_CodeEntityEditor = 'e0f145cf-bda3-53fd-b6af-3958aa4f2475',

  DataEditorTestWindow = '41b37d7d-afd4-5f6d-bd1e-63a3d9583fba',

  Whiteboard = 'a371fd46-d87c-53ac-aaed-7437a877d6e8',


  MindMap = '7079fa0d-2add-5622-ad28-bf7b1e804ec9',

  Formula = 'da50852a-858b-550a-9b23-98dcfe2ab1e4',

  ScopeWindow = '71c19a0d-c7d4-5e35-b55b-b796980d069e',

  PinBoard = '1ce0a93a-c3a1-591b-ba25-1f8dc389f368',

  Widgets = '4eca57e7-1924-59f7-966e-a5a4c8f37d58',
  Widget = '0b54320d-9bb1-5482-847e-bf57fae287f6',

  MobileHome = '73c172f1-cdbe-5f93-b136-08ec45a2fc52',

  ScriptWindow = 'b0bedafb-e01e-562f-9f4a-9423ed33e6ae',

  Shorthand = '4773cccd-14e8-51f1-8d24-897d8df41eca',

  CompletionsTest = '32ab3f33-ec04-548e-bdf7-5f5a13ef7bc7',

  EmbeddingTest = 'dc96c701-a9cc-5451-b903-e012310759bd',

  Wip = '2e01cff2-358a-56fe-95c5-c2228f4fb53a',

  DevProject = '586fb726-63fc-51e9-b695-f5e093b0fda7',
}
