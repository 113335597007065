import { useContext } from "react";
import { SystemContext } from "../etc/SystemContext";

export function WithContextArgs(props: {
  args?,
  children?,
}) {
  const ctx = useContext(SystemContext);
  return (
    <SystemContext.Provider value={{
      ...ctx,
      contextArgs: {
        ...(ctx.contextArgs || {}),
        ...props.args,
      },
    }}>
      {props.children}
    </SystemContext.Provider>
  )
}
