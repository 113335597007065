export enum PaneType {
  app = 'f2f87067-9e5b-5b73-804e-0fc968f30563',
  appLibrary = 'f7533e5b-5aab-5b1d-9c63-2e774a40c834',
  canvas = 'fc1eab27-5f91-55be-b3b5-d346f3180c09',
  chatGPT = '65501bfd-a13d-585d-be98-bb717a355aff',
  chatGPT2 = '82cdac9c-2b94-51a1-a1bd-73eeadcf1e0c',
  day = '8b2aff86-9552-5700-819b-1ae3b713bb24',
  days = '98d64951-6b14-561f-abf8-658931067943',
  event = 'e6e98071-48dc-5b49-b7f1-e8c6139dd3f7',
  events = 'bf8aa645-44c9-5e80-8c16-15e6a5f0429d',
  eventOccurrence = 'f2fe999f-30cc-54b4-822f-ac32627aaef7',
  graph = '42c0fbba-d5c2-5e97-b5b8-c912f98d3f94',
  library = '3b8b552a-9fc7-5617-8432-750ec3b1addf',
  page = '1b5bb200-a1a4-517d-a911-ac495ac12f9b',
  pageEntities = '250d2880-357f-598d-ae7f-ca3675566e60',
  parameterizedEvent = '3fdb2a27-3a1b-548f-a647-525af3c844cb',
  richTextEditor = '91c37f96-909e-5ca5-b33e-7b7cb341f3cb',
  table = '8a12c612-5a96-596a-83bb-f6d4350a225d',
  tableRow = 'b08366f5-7af3-5926-9de7-a38b59d4d32f',
  uiInspect = '1c026ea6-5be6-5ea0-aac8-64f1e6a3074a',
  notes = '6fa05c66-7f51-5f34-a9dc-672018371d63',
  note = 'c37293e7-c4c4-5b00-9a0f-c705dc996a6a',
  workspace = 'a38340ae-3e3a-58bb-9421-5d3a6a9e78fe',

  valuePoint = '70828d77-a85b-5a1e-aa7e-0a80d182bb9a',

  cards = '5b51f991-330e-5850-b136-9e54d9739a00',

  devProject = '74ff8c21-8450-57b3-8e71-bb461136943b',
  
}
