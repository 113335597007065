import { FormulaObjectWrapper } from '../FormulaObjectWrapper';
import { Data } from '../richTextHelpers';
import { Hooks, execFormula } from '../shorthand/formula';
import { expandFormula } from '../shorthand/formula';

export function execFormulaFromData(input: Data, env = {}, resolve = false, hooks: Hooks = {}, startRule?) {
  const f = expandFormula(input, hooks);
  const r = execFormula(f, env, hooks, startRule);
  if (resolve) {
    if (r instanceof FormulaObjectWrapper) {
      return r.toPrimitive();
    }
  }
  return r;
}
