import { useContext } from "react";
import _ from 'lodash';
import { db } from "../../db";
import { styled } from "../../component";
import { ValueType } from "../ValueType";
import { evaluate, getRootValuePoint, mapStructure, render } from "../main";
import { structRenderers } from "../structRenderers";
import { typeRegistry } from "../typeRegistry.1";
import { SystemContext } from "../../etc/SystemContext";
import { $EntityDatabase } from "./$EntityDatabase";
import { $EntityQueryView } from "./$EntityQueryView";
import { x } from "../../XObject";
import { execFormulaFromData } from '../../shorthandEditor/execFormulaFromData';

export const $Container = typeRegistry.registerType({
  $: '2ac33a55-5ced-5ed4-bb16-c531d3619410',
  Content: '348714ed-0d25-56f6-afb3-cf316d23c6c1',
  Spacing: 'c10fd5ad-3502-5a2b-b0c6-6e1c64b914d6',
}, ids => ({
  _id: ids.$,
  name: 'Container',
  definition: [
    {
      id: ids.Content,
      name: 'Content',
      type: [],
      property: 'content',
    },
    {
      id: ids.Spacing,
      name: 'Spacing',
      type: [],
      property: 'spacing',
    },
  ],
}));


const Wrapper = styled.div`
  > * {
    margin: ${props => props.spacing};
  }
`;

structRenderers[$Container.$] = (value, map) => {
  const mapped = mapStructure(value);
  

  return (
    <Wrapper spacing={mapped.spacing?.content} data-value-point={value._id}>
      {render(mapped.content, map)}
    </Wrapper>
  )
}