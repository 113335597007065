import React, { Component } from 'react';
import styled from 'styled-components';
import {component} from '../../component2';
import {_Editor} from '../../etc/draftHelpers';
import {Comp} from '../Comp';
import {_iterate, _matches, addValuePoint, CompiledValuePoint, evaluate, execute, getParameter, getRootValuePoint, getScopeForValuePoint, getValuePoint, ReferenceType, ValuePointProps} from '../main';
import {registerChangeAction, trackChange, UndoActions} from '../changes';
import {ValueType} from '../ValueType';
import {registerType} from "../__typeRegistry";
import { execFormulaFromData } from '../../shorthandEditor/execFormulaFromData';
import { FormulaEditor } from '../../shorthandEditor/FormulaEditor';
import { x, XObject } from '../../XObject';
import { ValuePoint } from '../ValuePoint';
import { ObjectType } from '../../types/ObjectRef';
import { SecondColumn } from '../../Query';
import { FormulaObjectWrapper } from '../../FormulaObjectWrapper';
import { db } from '../../db';
import { registerTypeRegister } from '../typeRegistering';
import { additionalTypes, additionalMenuIniters } from './additionalTypes';
import { pullValues } from './pullValues';
import { hooks } from './hooks';

const Set = 'a49a0f8a-d68d-5cff-a074-e64ae3e2e56b'

registerChangeAction(Set, {
  name: 'Set Formula',
});

export class Test {

}
function isValidReactChild(element) {
  if (Array.isArray(element)) {
    return element.every(isValidReactChild);
  }
  return React.isValidElement(element) || typeof element === 'string' || typeof element === 'number';
}

@component
export class FormulaValuePoint extends Comp<ValuePointProps> {
  static styles = styled.span`
    /* color: #ca8f76; */
  `;
  timerId
  render() {
    const state = XObject.get(this.props.state, 'content', {});
    const root = getRootValuePoint(this.props.state._id);
    const scope = getScopeForValuePoint(this.props.state._id);

    return (
      <>
        {state.entity && (
          <ValuePoint
            id={state.entity._id}
            path={this.props.path && this.props.path.concat('entity')}  
          />
        )}
        <FormulaEditor
          get={() => state.formula}
          set={value => state.formula = value}
          parent={{
            type: ObjectType.valuePoint,
            id: root._id,
          }}
          vars={[
            {
              key: ''
            }
          ]}
          additionalTypes={additionalTypes()}
          additionalMenuIniters={additionalMenuIniters(scope)}
        />
      </>
    );
  }
}

@component
class SideBar extends Component<{ state, useStackSystem, next }> {
  render() {
    return (
      <>
        <SecondColumn
          get={() => this.props.state.content?.formula}
          _onClick={obj => {
            this.props.useStackSystem('677c3c69-1f7a-5b4b-8c9c-158ff288e809', obj)
          }}
          next={this.props.next}
        />
      </>
    )
  }
}

export const registerFormulaType = () => {
  registerType(ValueType.Formula, {
    execute: (value, rt) => {
      const values = {};
      if (value.content?.formula) {
        pullValues(value.content?.formula, rt, values);
      }
      return {
        _id: value._id,
        type: value.type,
        content: {
          formula: value.content?.formula,
          entity: value.content?.entity && execute(value.content.entity._id, rt.appendPath(['entity'])),
          values,
        },
        isState: false,
        presentation: value.presentation,
        rt,
      }
    },
    evaluateWithParams: ({value, map,rt}) => {
      const env = {
        ...(map.__env || {}),
      };
      for (const ref of getScopeForValuePoint(value._id)) {
        if (ref.type == ReferenceType.Parameter) {  
          const p = getParameter(ref.id);
          if (p._id in map) {
            env[p.name] = map[p._id];
          }
          else if (p._id in value.rt.paramStore) {
            const r = execute(value.rt.paramStore[p._id], value.rt);
            if (r.type?.[0] == ValueType.EntityAttribute) {
              env[p.name] = new FormulaObjectWrapper({ type: ObjectType.attribute, id: r.content }, null);
            }
            else if (r.type?.[0] == ValueType.Param) {
              env[p.name] = map[r.content.id];
            }
            else {
              env[p.name] = evaluate(r, value.rt);
            }
          }
        }
        else if (ref.type == ReferenceType.Value) {
          const vp = getValuePoint(ref.id);
          if (vp.name?.[0] == '%') {
            const executed = execute(ref.id, value.rt);
            const evaluated = evaluate(executed, map);
            // console.log('[poop]', evaluated);
            env['$' + vp.name.slice(1)] = evaluated;
          }
        }
      }


      if (value.presentation?.debug) {
        console.log('paramStore', value.rt, value.rt.paramStore);
        console.log('poop', value.content.values);
      }

      return execFormulaFromData(value.content?.formula, {
        base: value.content?.entity && evaluate(value.content.entity, map),
        __rt: value.rt,
        ...env,
      }, true, hooks(value.content.values, map, {}, rt));
    },
    isBlock: () => true,
    render: (value: CompiledValuePoint, map, state, renderType) => {
      const r = evaluate(value, map);
      if (isValidReactChild(r)) {
        return <>{r}</>;
      }
      else {
        return 'invalid output';
      }
    },
    findValue: (value, pattern) => {
      if (value.content?.entity) {
        if (_matches(value.content?.entity, pattern)) {
          return value.content?.entity;
        }
      }
    },
    iterate: (value, parent, func, set) => {
      if (value.content?.entity) {
        const r = _iterate(value.content.entity, value, func, v => {
          value.content.entity = v;
        });
        if (r !== undefined) return r;
      }
    },
    editorComp: FormulaValuePoint,
    sideBarComp: SideBar,
  });
};

registerTypeRegister(registerFormulaType);