import _ from 'lodash';
import { component, styled } from './component';
import { Component } from 'react';
import { ObjectDisplay } from './components/ObjectDisplay';


const secondColumnRegistry = [];
export function registerSecondColumnThing(func) {
  secondColumnRegistry.push(func);
}

@component
export class SecondColumn extends Component<{ get; _onClick; next? }> {
  static styles = styled.div`

    .section {
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      margin-bottom: 4px;
      padding: 8px;

    }

  `;
  render(Container?) {
    const sections = [];
    if (!_.isString(this.props.get()) && this.props.get()) {
      for (const entry of this.props.get()) {
        if (!_.isString(entry)) {
          for (const func of secondColumnRegistry) {
            const r = func(entry, this.props._onClick, this.props.next);
            if (r) {
              sections.push(<div className="section">{r}</div>);
              break;
            }
          }
          // if (entry[1] == 'query') {
          //   objs.push(new Query(entry[0]));
          // }
          // else if (entry[1] == 'valuePoint') {
          //   objs.push(new ValuePoint(entry[0]));
          // }
          // else if (entry[1] == 'attribute') {
          //   objs.push(new Attribute(entry[0]));
          // }
        }
      }
    }

    return (
      <Container className="pane">
        {sections}
        {/* {objs.map(obj => {
          for (const func of secondColumnRegistry) {
            const r = func()
          }

          let c;

          if (obj instanceof Query) {
            c = (
              <>
                <ObjectEditor3 obj={{ type: ObjectType.query, id: obj.id }} />
                <ObjectEditor2 obj={{ type: ObjectType.query, id: obj.id }} />
                <button
                  onClick={() => {
                    this.props._onClick({
                      type: ColumnType.viewQuery,
                      id: obj.id,
                    });
                    // this.state.thirdColumn = {
                    //   type: ColumnType.viewQuery,
                    //   id: obj.id,
                    // }
                  }}
                >View</button>
              </>
            );
          }
          else if (obj instanceof ValuePoint) {
            c = (
              <>
                <ObjectEditor3 obj={{ type: ObjectType.valuePoint, id: obj.id }} />
                <ObjectEditor2 obj={{ type: ObjectType.valuePoint, id: obj.id }} />
                <PaneContext.Provider value={{
                  selectValuePoint: value => {
                    this.props._onClick({
                      type: ColumnType.valuePoint,
                      stack: [
                        XObject.obj({
                          type: 'valuePoint',
                          id: value,
                        })
                      ],
                    });
                    // this.state.thirdColumn = X({
                    //   type: ColumnType.valuePoint,
                    //   stack: [
                    //     XObject.obj({
                    //       type: 'valuePoint',
                    //       id: value,
                    //     })
                    //   ],
                    // })
                  }
                }}>

                  <RootValue id={obj.id} />
                </PaneContext.Provider>
              </>
            );
          }
          else if (obj instanceof Attribute) {
            c = (
              <>
                <ObjectEditor3 obj={{ type: ObjectType.attribute, id: obj.id }} />
                <ObjectEditor2 obj={{ type: ObjectType.attribute, id: obj.id }} />
              </>
            );
          }

          return (
            <div className="section">
              {c}
            </div>
          );
        })} */}
      </Container>
    );
  }
}
