import { db } from '../db';
import { getEntityById } from '../etc/createEntity';

export function entityStyles(id: string) {
  const entity = getEntityById(id);
  if (!entity) return [];
  const styles = {};

  if (entity.tags) {
    for (const id in entity.tags) {
      const tag = db.tags.findById(id);
      if (tag.style) {
        styles[tag.style] = true;
      }
    }
  }

  return Object.keys(styles);
}
