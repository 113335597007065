import React, { Component } from 'react';
import styled from 'styled-components';
import { db } from '../db';
import { component } from '../component2';
import { ViewType } from '../types/ViewType';
import { Conjuction, ExpressionEntryType, renderEntry } from '../etc/expressionQuery';
import { XInit, XObject, XTouch, x } from '../XObject';
import { EXPRESSION_QUERY, queryChain, queryScopeAssertions } from '../etc/queryFuncs';
import { ObjectType } from '../types/ObjectRef';
import _ from 'lodash';
import { SortableCont, SortableEl, showContextMenu, showElement } from '../helpers';
import { arrayMove } from 'react-sortable-hoc';
import { Cell } from './Cell';
import { EntitySelectEditor, EntityTypesCellType, EventsCellType } from './cells';
import { attributesForType } from './attributesForType';
import { isMobile } from '../isMobile';
import { Svg } from './Svg';
import { entityTypeName } from '../etc/entityTypeName';
import { registerRoute, BackButton, renderRoute, Route, NavContext, NavTitle } from './registerRoute';
import Around from './Around';
import { SelectEditor } from './SelectEditor';
import { codaIcons } from '../codaIcons';
import { entityDisplayName } from './entityDisplayName';
import { SystemContext, SystemContextProps } from '../etc/SystemContext';

const colTitle = id => {
  if (id == 'title') return 'Title';
  const attr = db.attributeTypes.findById(id);
  return attr?.name || id;
}

@component
class Columns extends Component<{ view: {
  entityTypes
  columns: {
    '@index2': string[]
  }
} }> {
  render() {
    const {  view } = this.props;
    const attributes = view.entityTypes?.[0] ? attributesForType(view.entityTypes[0]) : [];

    const allColumns = ['title'].concat(x(attributes));
    const columns = XObject.get(view, 'columns', {});
    const index = XObject.get(columns, '@index2', []);
    return (
      <>
        <b>Included</b>
        <SortableCont
          tag="ul"
          distance={isMobile() ? undefined : 20}
          pressDelay={isMobile() ? 200 : undefined}

          onSortEnd={({oldIndex, newIndex}) => {
            columns['@index2'] = arrayMove(x(index), oldIndex, newIndex);
          }}
        >
          {view.columns?.['@index2']?.map?.((id,i) => {
            return (
              <SortableEl
                key={id}
                index={i}

              >
              <li key={id}>
                <span
                  onClick={e => {
                    e.preventDefault();
                    showContextMenu(e, [
                      {
                        text: 'Exclude',
                        onClick: () => {
                          columns['@index2'] = index.filter(i => i != id);
                        }
                      }
                    ]);
                  }}
                >{colTitle(id)}</span>
              </li>
              </SortableEl>
            );
          })}
        </SortableCont>
        <b>Excluded</b>
        <ul>
          {allColumns.map(id => {
            if (index?.includes(id)) return null;
            return (
              <li key={id}>
                <span
                  onClick={e => {
                    e.preventDefault();
                    showContextMenu(e, [
                      {
                        text: 'Include',
                        onClick: () => {
                          index.push(id);
                        }
                      }
                    ]);
                  }}
                >{colTitle(id)}</span>
              </li>
            )
          })}
        </ul>
      </>
    )

  }
}

@component
class ColumnSelector extends Component<{ get, set, type }> {
  render() {
    const attributes = this.props.type ? attributesForType(this.props.type) : [];
    return (
      <>
        <select
          value={this.props.get() || ''}
          onChange={e => {
            this.props.set(e.target.value);
          }}
        >
          <option value="">None</option>
          <option value="title">Title</option>
          {attributes.map(a => {
            const attr = db.attributeTypes.findById(a);
            if (!attr) return null;
            return (
              <option key={a} value={a}>{attr.name}</option>
            )
          })}
        </select>
      </>
    )
  }
}

@component
export class CodaIconSelectorItem extends Component<{ label, value, setValue }> {
  render() {
    return (
      <DropdownItem
        label={this.props.label}
        options={codaIcons.map(c => ({
          text: c.name,
          value: c.name,
        }))}
        value={this.props.value}
        setValue={this.props.setValue}
      />
    )
  }
}


@component
export class Field extends Component<{ label, children? }> {
  static styles = styled.div`
    padding: 0 4px;
  `;
  static t = {
    label: styled.span`
      color: gray;
      font-size: 10px;
    `,

    inputCont: styled.div`

    `,
  };

  render() {
    const { t } = Field;
    return (
      <>
        <t.label>{this.props.label}</t.label>
        <t.inputCont>{this.props.children}</t.inputCont>
      </>
    )
  }
}

@component
export class TextInput extends Component<{ value, setValue }> {
  static styles = styled.input`
    width: 100%;
    box-sizing: border-box;
  `;
  render(Container?) {
    return <Container type="text" defaultValue={this.props.value} onChange={e => this.props.setValue(e.target.value)} />;
  }
}

@component
export class DropdownItem extends Component<{ label, value, setValue, options: {
  value
  text
}[] }> {
  static styles = styled.div`
    display: flex;
    height: 24px;
    align-items: center;
    border-radius: 3px;
    padding: 0 4px;
    cursor: pointer;
    &:hover {
      background-color: #ebebeb;
    }
    
    svg {
      transform: rotate(-90deg);
      margin-left: 6px;
      path {
        fill: #b6b6b6;
      }
    }
  `;

  static t = {
    label: styled.span``,
    value: styled.span`
      margin-left: auto;
      color: #9a9a9a;
    `,
  }

  state = XInit(class {
    showOptions
  })

  render(Container?) {
    const { t } = DropdownItem;
    const option = this.props.options.find(o => o.value == this.props.value);
    const ref = React.createRef();
    return (
      <>
        <Container
          ref={ref}
          onClick={e => {
            e.preventDefault();
            this.state.showOptions = !this.state.showOptions;
          }}>
          <t.label>{this.props.label}</t.label>
          <t.value>{option?.text}</t.value>
          <Svg name="chevron" />
        </Container>
        {this.state.showOptions && (
          <Around anchor={() => ref.current}>
            <SelectEditor
              close={() => this.state.showOptions = false}
              options={this.props.options.map(o => ({
                _id: o.value,
                title: o.text,
              }))}
              value={this.props.value}
              setValue={this.props.setValue}
              frame={{
                width: 300,
                height: 300,
              }}
            />
          </Around>
        )}
      </>
    )
  }
}

@component
export class EntityDropdownItem extends Component<{ label, value, setValue }> {
  static styles = styled.div`
    display: flex;
    height: 24px;
    align-items: center;
    border-radius: 3px;
    padding: 0 4px;
    cursor: pointer;
    &:hover {
      background-color: #ebebeb;
    }
    
    svg {
      transform: rotate(-90deg);
      margin-left: 6px;
      path {
        fill: #b6b6b6;
      }
    }
  `;

  static t = {
    label: styled.span``,
    value: styled.span`
      margin-left: auto;
      color: #9a9a9a;
    `,
  }

  state = XInit(class {
    showOptions
  })

  render(Container?) {
    const { t } = EntityDropdownItem;
    const ref = React.createRef();
    return (
      <>
        <Container
          ref={ref}
          onClick={e => {
            e.preventDefault();
            this.state.showOptions = !this.state.showOptions;
          }}>
          <t.label>{this.props.label}</t.label>
          <t.value>{this.props.value && entityDisplayName(this.props.value)}</t.value>
          <Svg name="chevron" />
        </Container>
        {this.state.showOptions && (
          <Around anchor={() => ref.current}>
            <EntitySelectEditor
              baseEntity={null}
              query={null}
              close={() => this.state.showOptions = false}
              frame={{
                width: 300,
                height: 300,
              }}
              value={this.props.value}
              setValue={this.props.setValue}
            />
            {/* <SelectEditor
              close={() => this.state.showOptions = false}
              options={this.props.options.map(o => ({
                _id: o.value,
                title: o.text,
              }))}
              value={this.props.value}
              setValue={this.props.setValue}
              frame={{
                width: 300,
                height: 300,
              }}
            /> */}
          </Around>
        )}
      </>
    )
  }
}


@component
export class ItemFrame extends Component<{ left, right?, onClick? }> {
  static styles = styled.div`
    display: flex;
    height: 24px;
    align-items: center;
    border-radius: 3px;
    padding: 0 4px;
    cursor: pointer;
    &:hover {
      background-color: #ebebeb;
    }
    
    svg.chevron {
      transform: rotate(-90deg);
      margin-left: 6px;
      path {
        fill: #b6b6b6;
      }
    }
  `;

  static t = {
    label: styled.span``,
    right: styled.span`
      margin-left: auto;
    `,
  }

  state = XInit(class {
  });

  render(Container?) {
    const { t } = ItemFrame;
    const ref = React.createRef();
    return (
      <>
        <Container
          ref={ref}
          onClick={e => {
            e.preventDefault();
            this.props.onClick?.({
              present: (el) => {
                const close = showElement(e, el(() => {
                  close();
                }));
              }
            });
          }}
        >
          {this.props.left}
          <t.right>{this.props.right}</t.right>
          <Svg className="chevron" name="chevron" />
        </Container>
      </>
    )
  }
}



@component
export class NavItem extends Component<{ label, right?, onClick? }> {
  static styles = styled.div`
    display: flex;
    height: 24px;
    align-items: center;
    border-radius: 3px;
    padding: 0 4px;
    cursor: pointer;
    &:hover {
      background-color: #ebebeb;
    }
    
    svg {
      transform: rotate(-90deg);
      margin-left: 6px;
      path {
        fill: #b6b6b6;
      }
    }

  `;

  static t = {
    label: styled.span``,
    value: styled.span`
      margin-left: auto;
      color: #9a9a9a;
    `,
  }

  render(Container?) {
    const { t } = DropdownItem;
    return (
      <Container onClick={e => {
        this.props.onClick();
      }}>
        <t.label>{this.props.label}</t.label>
        <t.value>{this.props.right}</t.value>
        <Svg name="chevron" />
      </Container>
    )
  }
}

@component
export class CheckboxItem extends Component<{ label, value, setValue }> {
  static styles = styled.div`
    display: flex;
    height: 24px;
    align-items: center;
    border-radius: 3px;
    padding: 0 4px;
    cursor: pointer;
    &:hover {
      background-color: #ebebeb;
    }
    

  `;

  static t = {
    label: styled.span``,
    value: styled.span`
      margin-left: auto;
    `,
  }

  render(Container?) {
    const { t } = CheckboxItem;
    return (
      <Container onClick={e => {
        this.props.setValue(!this.props.value);
      }}>
        <t.label>{this.props.label}</t.label>
        <t.value><input type="checkbox" checked={this.props.value} /></t.value>
      </Container>
    )
  }
}

@component
class PropertyDropdownItem extends Component<{
  label,
  value,
  setValue,
  attributes?,
  entityType?
  obj?
}> {
  render() {
    const { attributes, entityType, obj } = this.props;

    let a = []
    if (attributes) a = attributes;
    else if (entityType) {
      a = attributesForType(entityType).map(id => db.attributeTypes.findById(id)).filter(Boolean);
    }
    else if (obj) {
      if (obj.type == ObjectType.type) {
        a = attributesForType(entityType).map(id => db.attributeTypes.findById(id)).filter(Boolean);
      }
      else if (obj.type == ObjectType.event) {
        const event = db.events.findById(obj.id);
        a =  [
          {_id: 'argument', name: 'Argument'}
        ].concat((event.attributes || []).map(id => db.attributeTypes.findById(id)).filter(Boolean));
      }
    }
    return (
      <DropdownItem
        label={this.props.label}
        options={[{
          value: null,
          text: 'None',
        }].concat(a.filter(Boolean).map(a => ({
          value: a._id,
          text: a.name,
        })))}
        value={this.props.value}
        setValue={this.props.setValue}            
      />
    );
  }
}

@component
export class Section extends Component<{ header?, children?, actions? }> {
  static styles = styled.div`
    padding: 6px 16px;
  `;
  static t = {
    header: styled.span`
      font-weight: bold;
      font-size: 10px;
      color: #cecece;

      display: flex;
      margin-bottom: 4px;

      .actions {
        margin-left: auto;
      }
    `,
    content: styled.div``,
  }
  render() {
    const { t } = Section;
    return (
      <>
        {this.props.header && <t.header>{this.props.header} {this.props.actions && <div className="actions">{this.props.actions}</div>}</t.header>}
        <t.content>{this.props.children}</t.content>
      </>
    )
  }
}


// view properties
registerRoute((route, navigation) => {
  if (route?.[0] == Route.viewProperties) {
    const [view] = getView(route[1]);
    return (
      <>
        <Section>
          <NavTitle><h3>Properties</h3></NavTitle>
          <Field label="Columns">
            <Columns view={view} />
          </Field>
        </Section>
      </>
    )

  }
})

// table view
registerRoute((route, navigation) => {
  if (route[0] == Route.tableView) {
    const [view, scopes, typesInScope, objectType] = getView(route[1]);
    const attributes = (view.entityTypes?.[0] ? attributesForType(view.entityTypes[0]) : []).map(id => db.attributeTypes.findById(id))

    const obj = {
      type: objectType == ObjectType.entity ? ObjectType.type : ObjectType.event,
      id: view.entityTypes?.[0] || view.events?.[0]
    }

    const switches = {
      properties: false,
      groupBy: false,
    }

    if (view.type == ViewType.table) {
      switches.properties = true;
      switches.groupBy = true;
    }
    if (view.type == ViewType.board) {
      switches.properties = true;
    }
    if (view.type == ViewType.list) {
      switches.groupBy = true;
    }
    if (view.type == ViewType.gallery) {
      switches.properties = true;
    }
    


    return (
      <>
        <Section>
          <NavTitle><h3>Table view</h3></NavTitle>
          <Field label="Name">
            <TextInput value={view.name} setValue={value => view.name = value} />
          </Field>
          <DropdownItem label="Layout" value={view.type} setValue={value => view.type = value} options={[
            { value: ViewType.list, text: 'List' },
            { value: ViewType.table, text: 'Table' },
            { value: ViewType.split, text: 'Split' },
            { value: ViewType.list, text: 'List' },
            { value: ViewType.graph, text: 'Graph' },
            { value: ViewType.timeline, text: 'Timeline' },
            { value: ViewType.gallery, text: 'Gallery' },
            { value: ViewType.board, text: 'Board' },
            { value: ViewType.chart, text: 'Chart' },
            { value: ViewType.calendar, text: 'Calendar' },
            { value: ViewType.clustering, text: 'Clustering' },
          ]} />
          {objectType == ObjectType.entity && <NavItem
            label="Entity Type"
            onClick={() => {
              navigation.push([Route.viewEntityType, route[1]])
            }}
            right={view.entityTypes?.[0] && entityTypeName(view.entityTypes[0])}
          />}
          {objectType == ObjectType.eventOccurrence && <NavItem
            label="Event"
            onClick={() => {
              navigation.push([Route.viewEvent, route[1]])
            }}
            right={view.events?.[0] && db.events.findById(view.events[0]).name}
          />}
        </Section>
        {/* <Section header="Filter">
          {renderEntry(
            XObject.get(view, 'viewQuery', [EXPRESSION_QUERY, {
            _id: 'root',
            type: ExpressionEntryType.group,
            conjunction: Conjuction.and,
            entries: [],
          }])[1], scopes)}
        </Section> */}
        {/* <Section>
          {renderEntry(
            XObject.get(view, 'filterQuery', [EXPRESSION_QUERY, {
            _id: 'root',
            type: ExpressionEntryType.group,
            conjunction: Conjuction.and,
            entries: [],
          }])[1], scopes)}
        </Section> */}
        <Section>
          {switches.properties && <NavItem
            label="Properties"
            onClick={() => {
              navigation.push([Route.viewProperties, route[1]])
            }}            
            right={<>{view?.columns?.['@index2']?.length || 0} shown</>}
          />}

          <NavItem label="Filter" onClick={() => {
            navigation.push([Route.viewFilter, route[1]])
          }} />

          {switches.groupBy && <PropertyDropdownItem label="Group By" value={view.groupBy} setValue={v => view.groupBy = v} attributes={attributes} />}


          {/* {view.type == ViewType.list && (() => {
            const attributes = view.entityTypes?.[0] ? attributesForType(view.entityTypes[0]) : [];
            return (
              <>
                <select
                  value={view.groupBy || ''}
                  onChange={e => {
                    view.groupBy = e.target.value;
                  }}
                >
                  <option value="">No Grouping</option>
                  {attributes.map(a => {
                    const attr = db.attributeTypes.findById(a);
                    if (!attr) return null;
                    return (
                      <option key={a} value={a}>{attr.name}</option>
                    )
                  })}
                </select>
              </>
            )
          })()} */}

          {view.type == ViewType.timeline && (() => {
            // const attributes = view.entityTypes?.[0] ? attributesForType(view.entityTypes[0]) : [];
            return (
              <>
                <PropertyDropdownItem
                  label="Start Time Property"
                  attributes={attributes}
                  value={view.startTimeProperty}
                  setValue={v => view.startTimeProperty = v}
                />

                <PropertyDropdownItem
                  label="End Time Property"
                  attributes={attributes}
                  value={view.endTimeProperty}
                  setValue={v => view.endTimeProperty = v}
                />



                {/* <div>
                  End Time Property: <select
                    value={view.endTimeProperty || ''}
                    onChange={e => {
                      view.endTimeProperty = e.target.value;
                    }}
                  >
                    <option value="">None</option>
                    {attributes.map(a => {
                      const attr = db.attributeTypes.findById(a);
                      if (!attr) return null;
                      return (
                        <option key={a} value={a}>{attr.name}</option>
                      )
                    })}
                  </select>
                  </div> */}
              </>
            )
          })()}

          {/* {view.type == ViewType.gallery && (() => {
            return (
              <>
                <Columns
                  view={view}
                />
              </>
            )
          })()} */}

          {view.type == ViewType.board && (() => {
            // const attributes = view.entityTypes?.[0] ? attributesForType(view.entityTypes[0]) : [];

            return (
              <>
                <PropertyDropdownItem label="Grouping Property" value={view.groupingProperty} setValue={v => view.groupingProperty = v} attributes={attributes} />

                {/* <div>
                  Grouping Property: <select
                    value={view.groupingProperty || ''}
                    onChange={e => {
                      view.groupingProperty = e.target.value;
                    }}
                  >
                    <option value="">None</option>
                    {attributes.map(a => {
                      const attr = db.attributeTypes.findById(a);
                      if (!attr) return null;
                      return (
                        <option key={a} value={a}>{attr.name}</option>
                      )
                    })}
                  </select>
                </div> */}

              {/* <Columns
                view={view}
              /> */}
              </>
            )
          })()}

          {view.type == ViewType.chart && (() => {
            // const attributes = view.entityTypes?.[0] ? attributesForType(view.entityTypes[0]) : [];

            return (
              <>

                <DropdownItem
                  label="Chart Type"
                  options={[
                    { text: 'None', value: null },
                    { text: 'Bar Chart', value: 'bar' },
                    { text: 'Pie Chart', value: 'pie' },
                  ]}
                  value={view.chartType}
                  setValue={v => view.chartType = v}
                />

                {view.chartType == 'bar' && (
                  <>
                    <PropertyDropdownItem
                      label="Horizontal Axis"
                      obj={obj}
                      value={view.horizontalAxis}
                      setValue={v => view.horizontalAxis = v}

                    />

                    <PropertyDropdownItem
                      label="Segment By"
                      obj={obj}
                      value={view.segmentBy}
                      setValue={v => view.segmentBy = v}
                    />

                  </>
                )}

                {view.chartType == 'pie' && (
                  <>
                    <PropertyDropdownItem
                      label="Grouping Property"
                      obj={obj}
                      value={view.groupingProperty}
                      setValue={v => view.groupingProperty = v}

                    />
                    {/* <div>
                      Grouping Property: <ColumnSelector get={() => view.groupingProperty} set={v => view.groupingProperty = v} type={view.entityTypes?.[0]} />
                    </div>   */}
                  </>
                )}






              {/* 
                <Columns
                  view={view}
                /> */}
              </>
            )
          })()}

          {view.type == ViewType.calendar && (() => {
            const attributes = (view.entityTypes?.[0] ? attributesForType(view.entityTypes[0]) : []).map(id => db.attributeTypes.findById(id))

            return (
              <>
                <div>
                  Start Col: <select
                    value={view.startAttr || ''}
                    onChange={e => {
                      view.startAttr = e.target.value;
                    }}
                  >
                    <option />
                    {attributes.map(a => {
                      return <option value={a._id}>{a.name}</option>
                    })}
                  </select>
                </div>
                <div>
                  End Col: <select
                    value={view.endAttr || ''}
                    onChange={e => {
                      view.endAttr = e.target.value;
                    }}
                  >
                    <option />
                    {attributes.map(a => {
                      return <option value={a._id}>{a.name}</option>
                    })}
                  </select>
                </div>


              </>
            )
          })()}

          {view.type == ViewType.clustering && (() => {
            return (
              <>
                <PropertyDropdownItem
                  label="Attribute"
                  attributes={attributes}
                  value={view.clusterAttribute}
                  setValue={v => view.clusterAttribute = v}
                />

          <DropdownItem label="View Type" value={view.clusterViewType} setValue={value => view.clusterViewType = value} options={[
            { value: '69434cd0-94ea-5cc6-8563-a93b23cf7eea', text: 'Clusters' },
            { value: '1ba52e45-2e21-5346-8dc5-9662ee43794c', text: 'Graph' },
          ]} />


{/* {view.clusterViewType == '1ba52e45-2e21-5346-8dc5-9662ee43794c' && ( */}
<>
<CheckboxItem label="Connect All" value={view.connectAll} setValue={value => view.connectAll = value} />


                <Field label="All Threshold">
                  <TextInput value={view.allTreshold} setValue={value => view.allTreshold = value} />
                </Field>


                <CheckboxItem label="Connect Closest" value={view.connectClosest} setValue={value => view.connectClosest = value} />


<Field label="Closest Threshold">
  <TextInput value={view.closestThreshold} setValue={value => view.closestThreshold = value} />
</Field>
</>

{/* )} */}


{view.clusterViewType == '69434cd0-94ea-5cc6-8563-a93b23cf7eea' && (
<>


</>
)}
              </>
            )
          })()}

        </Section>
      </>
    );
  }
})

// view filter
registerRoute((route, navigation) => {
  if (route[0] == Route.viewFilter) {
    const [view, scopes] = getView(route[1]);
    return (
          <>
            <Section>
              <NavTitle><h3>Filter</h3></NavTitle>

              {renderEntry(
              XObject.get(view, 'viewQuery', [EXPRESSION_QUERY, {
              _id: 'root',
              type: ExpressionEntryType.group,
              conjunction: Conjuction.and,
              entries: [],
            }])[1], scopes, {})}


            </Section>
          </>
        )

  }
})

// view entity type
registerRoute((route, navigation) => {
  if (route[0] == Route.viewEntityType) {
    const [view, scopes, typesScopes] = getView(route[1]);

        return (
          <Section>
          <NavTitle><h3>Entity Type</h3></NavTitle>

          <Field label="Entity Type">
          <Cell
            title={'Entity Types'}
            cell={new EntityTypesCellType({
              scopes: typesScopes,
            })}
            get={() => {
              return XObject.get(view, 'entityTypes', []);
            }}
            set={value => {
              view.entityTypes = value;
            }}
          />
        </Field>
        </Section>

        )

  }
})

// view event
registerRoute((route, navigation) => {
  if (route[0] == Route.viewEvent) {
    const [view, scopes, typesScopes] = getView(route[1]);

        return (
          <Section>
          <NavTitle><h3>Event</h3></NavTitle>

          <Field label="Event">
          <Cell
            title={'Events'}
            cell={new EventsCellType({
              scopes: typesScopes,
            })}
            get={() => {
              return XObject.get(view, 'events', []);
            }}
            set={value => {
              view.events = value;
            }}
          />
        </Field>
        </Section>

        )

  }
})

function getView(a: {
  query
  view
  space
  doc
}) {
  let view, scopes: any[], typesScopes, objectType;
  if (a.query) {
    const query = db.queries.findById(a.query);
    view = query.views?.find?.(v => v._id == a.view);
    if (!view) {
      if (query.layers) for (const layer of query.layers) {
        view = layer.views.find(v => v._id == a.view);
        if (view) break;
      }
    }

    scopes = [{type: ObjectType.query, id: query._id}];
    query.query && queryScopeAssertions(queryChain(query), scopes);
    scopes.splice(0, 0, ...scopes);
    scopes = _.uniqBy(scopes, x => x.id);

    XTouch(query.query);

    typesScopes = [ {type: ObjectType.query, id: query._id} ];

    if (query.type == 'eventOccurrences') {
      objectType = ObjectType.eventOccurrence;
    }
    else {
      objectType = ObjectType.entity;
    }
  }
  else if (a.space) {
    const space = db.spaces.findById(a.space);
    view = space.views?.find?.(v => v._id == a.view);

    scopes = [{type: ObjectType.space, id: space._id}];
    typesScopes = [ {type: ObjectType.space, id: space._id} ];
  }
  else if (a.doc) {
    const doc = db.notionDocuments.findById(a.doc);
    console.log(x(doc), a.view);
    view = doc.entitySpace?.views?.find?.(v => v._id == a.view);
    scopes = [{type: ObjectType.document, id: doc._id}];
    typesScopes = [ {type: ObjectType.document, id: doc._id} ];
  }


  return [view, scopes, typesScopes, objectType];
}

@component
export class NavStackCont extends Component<{ state; root }> {
  static contextType = SystemContext;
  context: SystemContextProps;

  static styles = styled.div`
    h3 {
      margin:0;
    }
    ${Section}:not(:last-child) {
      border-bottom: 1px solid #e6e6e6;
    }

    ${DropdownItem}, ${Field}, ${NavItem}, ${ItemFrame} {
      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  `;

  render() {
    const navStack = XObject.get(this.props.state, 'navStack', []);

    const navigation = {
      push: route => {
        navStack.push(route);
      },
      pop: () => {
        navStack.pop();
      },
      hasBack: () => navStack.length,
      navigate: this.context.navigate,
    }

    const navItem = navStack[navStack.length - 1];

    return renderRoute(navItem || this.props.root, navigation);
  }
}
