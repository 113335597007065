import React, { Component } from "react";
import { component, styled } from "../component";
import { db } from "../db";
import { AttributeType } from "../components/AttributeType";
import { showPrompt } from "../etc/showPrompt";
import { color } from "../components/notionDocument/color";
import { getEntityById } from "../etc/createEntity";


@component
export class Badge extends Component<{ text; _onClick?; dataValuePoint?; }> {
  static styles = styled.div`
    user-select: none;
    font-size: 10px;
    color: ${color('block.badge.text')};
    background-color: ${color('block.badge.bg')};
    border-radius: 5px;
    padding: 0 4px;
    transition: opacity 0.2s;
    cursor: pointer;
  `;
  render(Container?) {
    return (
      <Container
        data-value-point={this.props.dataValuePoint}
        onClick={this.props._onClick}
      >
        {this.props.text}
      </Container>
    );
  }
}

@component
export class RecordBadges extends Component<{ recordId; db; }> {
  static styles = styled.div`
    display: flex;
    ${Badge} {
      margin-right: 4px;
    }
  `;
  render() {
    const { recordId, db } = this.props;
    const record = db.getRecord(recordId);

    return (
      <>
        {this.props.db.database.columns.filter(c => c.showInDoc).map(c => {
          const value = record.data[c._id];
          let d;

          if (c.type == 'select') {
            d = c.options.find(o => o._id == value)?.title;
          }
          else if (c.type == 'text') {
            d = value;
          }
          if (!d)
            return;
          return <Badge key={c._id} text={d} _onClick={() => {
            if (c.type == 'text') {
              const value = prompt("Enter new value");
              if (value) {
                const record = db.getRecord(recordId);
                record.data[c._id] = value;
              }
            }
          }} />;
        })}
      </>
    );

    // return <Badge  text={"asdf"} />
  }
}


@component
export class EntityBadges extends Component<{ block; entity; blockType; }> {
  static styles = styled.div`
    display: flex;
    > * {
      margin-right: 4px;
    }

    align-items: center;

    .type {
      font-size: 10px;
      color: ${color('block.metaLine.text')};
      margin-left: 2px;
    }
  `;
  render() {
    if (!this.props.entity)
      return null;
    const type = this.props.blockType?.name || db.entityTypes.findById(this.props.entity.type)?.name;
    return (
      <>
        {this.props.blockType?.elements?.map?.((el, i) => {
          if (el.type == 'attribute') {
            const d = el.binding.get(this.props.entity._id);
            let display;

            if (el.binding.type == AttributeType.entity) {
              display = d && getEntityById(d).name;
            }
            else {
              display = d;
            }

            return d && (
              <Badge
                key={i}
                text={display}
                dataValuePoint={el._id}
                _onClick={async () => {
                  const value = await showPrompt("Enter new value");
                  if (value) {
                    el.binding.set(this.props.entity._id, value);
                  }
                }} />
            );
          }
          else if (el.type == 'badge') {
            const d = el.binding.get(this.props.block);
            return d && <Badge key={i} text={d} dataValuePoint={el._id} />;
          }
        })}

        {type && <span className="type">{type}</span>}
      </>
    );
  }
}
