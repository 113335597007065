export enum ObjectType {
  app = 'e3576f02-bbbc-55d4-884c-103866d86b84',
  attribute = '6a5561f3-f8eb-5f21-85dd-5f60ff5888d7',
  canvas = 'dca1af8d-3352-5072-9d13-58ec245d5086',
  codeComponent = 'bc1d82d2-15af-5c08-9bc5-1481a9303681',
  day = '2e93c075-67d8-5846-9d18-12d3f736ea9b',
  document = '3233c65b-5419-5435-af64-41c8f4ff0e52',
  element = '5bb91d65-c64f-5842-b8a8-ce961aa38d4e',
  entity = 'b3b94670-26f1-5fec-96e5-9f6137e97eed',
  event = '6a7e7644-a720-583b-9a1a-d1683cc4b8a3',
  eventOccurrence = 'ae72acf9-d0a8-565c-a45b-80a406d67b7e',
  formula = 'e45bf854-4f6e-58d3-b440-514069a9abec',
  global = '12ad8c65-5aa2-5e2b-9a8b-4435a1f98b82',
  identifier = 'defef5d1-7bed-503c-8123-9a7fb2e9d719',
  library = '29e57a7d-b613-5119-a147-91f4f3883fbc',
  mode = 'fc55965c-e0ab-5778-bfa7-e81046667aa5',
  note = 'eb803737-03a8-520b-9c01-a7ee11e7f6f4',
  objectHandle = '9eb31ad6-8ffc-5307-915a-6d44c820a234',
  page = '5e59196b-9d55-5149-b420-dd048e4b5d99',
  parameterizedEvent = '3b49cf57-3ab7-5735-934b-c99b390449ae',
  query = 'faecfe12-ca1a-5bc8-9118-40cc9527d4ee',
  space = 'b136901e-de50-560c-967c-6ba1b2ae1b71',
  table = '6a7c8ab8-aa4b-5e59-8203-7d4a2d9ffbaf',
  tableSchema = '538e3c62-7091-540f-9600-d67d07114153',
  tag = 'e057de16-c9bc-571c-8336-45d799e45819',
  tagGroup = '8cb05f61-01dc-5e94-abef-b788a65f6a40',
  type = '721907d2-7cf8-50ea-a851-ed8bf8381ea0',
  valuePoint = 'ccf27752-c086-53d1-8dc9-6339bc50a57e',
  widget = '54968569-5312-59ef-841b-a9f393a104ac',
  dashboard = '9677478f-a0d1-5c62-b200-db20a7d1eee7',
  workspace = '5f049e35-c9e1-5564-a8be-c5efe9cc486d',
  database = '36823022-8796-553a-93ec-34df66e02704',

  card = 'f46e5cf7-b06f-55f7-aa28-04cad08f4346',
  cardCategory = 'a0a3667f-94e6-5c4f-9a61-b0c173c20efc',

  devProject = '7d88857c-bb0b-55f6-9f32-0e3aeb9a3490',
}

export interface ObjectRef {
  id: string;
  type: ObjectType;
}

export class ObjectRefClass {
  constructor(public type: ObjectType, public id: string) {}
  static fromObjectRef(ref: ObjectRef) {
    return new ObjectRefClass(ref.type, ref.id)
  }
}