import { db } from '../db';
import { XObject } from '../XObject';
import { appState } from '../etc/appState';


export function allDays() {
  return db.days.filter(d => d.user == appState.user);
}

export function getToday() {
  const days = allDays();
  return days[days.length - 1];
}

export function createDay(args) {
  const day = XObject.obj({
    user: appState.user,
    ...args,
  });
  db.days.push(day);

  return day;
}
