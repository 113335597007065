import { ViewQuery } from './components/ViewQuery';
import { GlueStack, PaneContext } from './glue/main';
import { ColumnType } from './ColumnType';
import { registerThirdColumnThing } from './ThirdColumn';
import { styled } from './component';

registerThirdColumnThing(ColumnType.viewQuery, obj => {
  // return 'hello';
  return (
    <ViewQuery
      id={obj.id}
      state={obj} />
  );
});

const Wrapper = styled.div`
  display: flex;
  height: 100%;

  ${GlueStack.t.pane} {
    overflow: auto;
  }
`;
registerThirdColumnThing(ColumnType.valuePoint, obj => {
  return (
    <PaneContext.Provider value={{
      selectValuePoint: value => {
      }
    }}>
      <Wrapper>
      <GlueStack

        state={obj} />
        </Wrapper>
    </PaneContext.Provider>
  );
});
