import ReactDOMClient from "react-dom/client";
import jQuery from "jquery";
import { isMobile } from "../../isMobile";
import { closeMobileModal, presentMobileModal } from "../presentMobileModal";
import _ from "lodash";
export function presentEditComp({ frame, title = undefined, cell, get, set, state }) {
  console.log('presentEditComp', title);
  if (isMobile() && cell.mobileEditorFullScreen()) {
    frame = {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    };

    const d = cell.renderEditor({
      state,
      close: () => {
        closeMobileModal();
      },
      frame,
      value: get,
      setValue: value => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
          set(value);
        }, 0);
      }
    });

    presentMobileModal((
      <>
        {d} 
      </>
    ), title);
  }
  else {
    const cont2 = jQuery('<div />').css({
      position: 'absolute',
      left: frame.left + 'px',
      top: frame.top + 'px',
    });
  
    const cont = jQuery('<div />').css({
      position: 'fixed',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 2147483001,
    });
  
    const close = () => {
      _close?.();
      cont.remove();
      root.unmount();
    };
    cont.click((e) => {
      e.preventDefault();
      e.stopPropagation();
      close();
    });
    cont.append(cont2);
    cont2.click((e) => {
      e.stopPropagation();
    });
  
    jQuery('body').append(cont);
    const root = ReactDOMClient.createRoot(cont2[0]);

    let _get, _set, _close;

    if (cell.saveOnClose()) {
      let value;
      _get = () => value;
      _set = v => {
        value = v;
      }

      value = cell.mapToEditor(get());

      _close = () => {
        set(cell.mapToValue(value));
      }
    }
    else {
      _get = get;
      _set = value => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
          set(value);
        }, 0);
      }
    }

    const d = cell.renderEditor({
      state: null,
      close,
      frame,
      value: _get,
      setValue: _set,
    });

    root.render(d);
  }

  
  let timerId;
  // root.render();
}
