import { typeRegistry } from "../typeRegistry.1";
import { NotionDatabase } from "../../components/notionDatabase/NotionDatabase";
import { getIcon } from "../../components/notionDatabase/icons";
import { Column, ColumnManager, RowManager, Row } from "../../components/notionDatabase/ColumnManager";
import { $GroupedSelectAttribute_CellType, AttributeCellType, CheckboxCellType, DurationCellType, EntitiesCellType, EntityCellType, EventCellType, MediaCellType, MultiSelectCellType, NumberCellType, ReactionCellType, SelectCellType, TextCellType, TimeCellType, TitleCellType } from "../../components/cells";
import { db } from "../../db";
import { component, styled } from "../../component";
import { Component, useContext } from "react";
import { structRenderers } from "../structRenderers";
import { evaluate, mapStructure } from "../main";
import { doEntityQuery } from "./$EntityQuery";
import { ValueType } from "../ValueType";
import { X, XObject, x } from "../../XObject";
import { SystemContext } from "../../etc/SystemContext";
import { executeCreate } from "./executeCreate";
import { EntityName } from "../../components/EntityDisplay";
import { AttributeType } from "../../components/AttributeType";
import { executeSwitch } from "../../components/executeSwitch";
import { $GroupedSelectAttribute } from "./$GroupedSelectAttribute";
import { deleteEntity } from "../../etc/deleteEntity";
import { Svg } from "../../components/Svg";
import { attributeTypeDefs } from "../../components/attributeTypes";
import { FormulaObjectWrapper } from "../../FormulaObjectWrapper";
import { ObjectType } from "../../types/ObjectRef";
import { getEntityById } from "../../etc/createEntity";


export const $EntityDatabase = typeRegistry.registerType({
  $: '55343c7e-2026-5169-820f-f9dfcb78256e',
  Query: '6ee187e5-421d-535c-958a-920c6ccee711',
  Attributes: '6df2b61b-d384-55b2-9ca6-a8fa5cc16aee',
  States: 'e70cb8ca-c24b-59e1-b6e5-d37dfbcf7ff4',
  Name: 'e73a6a7b-de5c-5030-a0c2-a6b3f87cf0be',
  Create: '3ab9b7a2-cfc3-5728-a584-fe39b00c0dc6',
}, ids => ({
  _id: ids.$,
  name: 'Entity Database',
  definition: [
    {
      id: ids.Name,
      name: 'Name',
      type: [],
      property: 'name',
    },
    {
      id: ids.Query,
      name: 'Query',
      type: [],
      property: 'query',
    },
    {
      id: ids.Attributes,
      name: 'Attributes',
      type: [],
      property: 'attributes',
    },
    {
      id: ids.States,
      name: 'States',
      type: [],
      property: 'states',
    },
    {
      id: ids.Create,
      name: 'Create',
      type: [],
      property: 'create',
    }
  ]
}));

structRenderers[$EntityDatabase.$] = (value, map) => {
  const mapped = mapStructure(value);
  const entities = doEntityQuery(mapped.query, map);
  const attributes = mapped.attributes ? evaluate(mapped.attributes, map) : [];
  const states = mapped.states ? evaluate(mapped.states, map) : [];

  return <EntityDatabase
  createDataValuePoint={mapped.create?._id}
  entities={entities.map(e => e)} attributes={attributes} states={states} dataValuePoint={value._id} 
    onClickAdd={mapped.create && (() => {
      executeCreate(mapped.create, map);
    })}
  />;
}



export class MyColThing extends Column {
  constructor(public _type, private state?) {
    super();
  }

  __type() {
    if (db.attributeTypes.findById(this._type)) return 'attribute';
    if (db.stateTypes.findById(this._type)) return 'state';
  }

  id() {
    return this._type._id || this._type;
  }
  title() {
    if (this._type == 'title') {
      return 'Name';
    }
    else {
      if (this.__type() == 'state') {
        return db.stateTypes.findById(this._type).values.map(v => v.name).join(', ');
      }
      else {
        return db.attributeTypes.findById(this._type)?.name;
      }
    }
  }

  width() {
    return this.state?.width || 200;
  }
  setWidth(value: any): void {
    this.state.width = value;
  }

  getIcon() {
    const ttt = db.attributeTypes.findById(this._type);
    if (!ttt) return;
    const tt = attributeTypeDefs.find(t => t.value == ttt.type);
    if (tt?.icon) {
      return (
        <Svg name={tt.icon} />
      )
  
    }
    return null;
    return super.getIcon();
    return getIcon('text');
  }
  metaData() {
    if (this.__type() == 'state') {
      const state = db.stateTypes.findById(this._type);
      return {
        options: state.values.map(v => ({
          _id: v._id,
          title: v.name,
        }))
      }
    }
  }

  cellObj(row: MyRowThing) {
    if (this._type == 'title') {
      return new TitleCellType(this.metaData());
    }
    const t = db.attributeTypes.findById(this._type);

    let cellType;
    if (t?.type == AttributeType.switch) {
      const value = executeSwitch(t.valuePoint, row.entity);
      if (value?.type?.[1] == $GroupedSelectAttribute.$) {
        cellType = new $GroupedSelectAttribute_CellType({
          valuePoint: value._id,
        });
      }
    }
    else if (t?.type == AttributeType.text) {
      cellType = new TextCellType({

      });
    }
    else if (t?.type == AttributeType.boolean) {
      cellType = new CheckboxCellType({

      });
    }
    else if (t?.type == AttributeType.duration) {
      cellType = new DurationCellType({

      });
    }
    else if (t?.type == AttributeType.datetime) {
      cellType = new TimeCellType({

      });
    }
    else if (t?.type == AttributeType.entity) {
      cellType = new EntityCellType({
        query: t.query,
        sort: t.sort,
        baseEntity: row.id(),
        options: {
          type: t.optionsType,
          valuePoint: t.valuePoint,
          entity: new FormulaObjectWrapper({
            type: ObjectType.entity,
            id: row.id(),
          })
        }
      });
    }
    else if (t?.type == AttributeType.entities) {
      cellType = new EntitiesCellType({
        baseEntity: row.id(),
        query: t.query,
        resolved: t.resolved,
      });
    }
    else if (t?.type == AttributeType.select) {
      cellType = new SelectCellType({
        addOption: (name) => {
          const option = XObject.obj({
            title: name,
          })
          XObject.push(t, 'options', option);
          return option._id;
        },
        options: XObject.get(t, 'options', []),
      });
    }
    else if (t?.type == AttributeType.select) {
      cellType = new SelectCellType({
        addOption: (name) => {
          const option = XObject.obj({
            title: name,
          })
          XObject.push(t, 'options', option);
          return option._id;
        },
        options: XObject.get(t, 'options', []),
      });
    }
    else if (t?.type == AttributeType.media) {
      cellType = new MediaCellType({
      });
    }
    else if (t?.type == AttributeType.number) {
      cellType = new NumberCellType({
        ...(t as any),
        baseEntity: row.id(),
      });
    }
    else if (t?.type == AttributeType.reaction) {
      cellType = new ReactionCellType({
        icon: t.icon,
      });
    }
    else if (t?.type == AttributeType.multiSelect) {
      cellType = new MultiSelectCellType({
        addOption: (name) => {
          const option = XObject.obj({
            title: name,
          })
          XObject.push(t, 'options', option);
          return option._id;
        },
        options: XObject.get(t, 'options', []),
      });
    }
    else if (t?.type == AttributeType.event) {
      cellType = new EventCellType({});
    }
    else if (t?.type == AttributeType.attribute) {
      cellType = new AttributeCellType({});
    }

    if (!cellType) {
      cellType = new TextCellType(this.metaData());
    }

    return cellType;
  }

  type() {

    if (this._type == 'title') {
      return 'title';
    }
    else {
      if (this.__type() == 'state') {
        return 'select';
      }
      else {
        // if (type.type == AttributeType.
        return 'text';
      }
    }
  }
}
export class MyRowThing extends Row {
  constructor(public entity: string) {
    super();
  }
  id() {
    return this.entity;
  }

  __type(colId) {
    if (db.attributeTypes.findById(colId)) return 'attribute';
    if (db.stateTypes.findById(colId)) return 'state';
  }


  setValue(colId: any, value: any) {
    const entity = getEntityById(this.entity);
    if (entity) {
      if (colId == 'title') {
        entity.name = value;
      }
      else {
        if (this.__type(colId) == 'state') {
          const state = db.stateTypes.findById(colId);
          if (state) {
            if (entity.states) {
              entity.states[colId] = value;
            }
            else {
              entity.states = X({
                [colId]: value,
              });
            }
          }
        }
        else {
          const attribute = db.attributeTypes.findById(colId);
          if (attribute) {
            if (entity.attributes) {
              entity.attributes[colId] = value;
  
            }
            else {
              entity.attributes = X({
                [colId]: value,
              });
            }
          }
  
        }
      }
    }
  }

  value(colId: any, defaultValue?: any, context?) {
    const entity = getEntityById(this.entity);
    if (!entity)
      return null;

    if (colId == 'title') {
      return entity?.name;
    }
    else {
      if (this.__type(colId) == 'state') {
        return entity.states?.[colId];

        const state = db.stateTypes.findById(colId);
        if (state) {
        }
      }
      else {
        const attribute = db.attributeTypes.findById(colId);
        let defaultValue;
        // if (attribute.type == AttributeType.entities || attribute.type == AttributeType.multiSelect) {
        //   defaultValue = [];
        // }
        // return XObject.get(XObject.get(entity, 'attributes', {}), colId, defaultValue);
        return entity?.attributes?.[colId];
      }
      // const element = this.blockType.elements.find(e => e._id == colId);
      // if (element) {
        // return element.binding.get(this.entity);
      // }
    }
  }
}
export class MyRowManager extends RowManager {
  constructor(public entities: string[], public add?) {
    super();
  }
  rows(): Row[] {
    return this.entities.map(e => new MyRowThing(e));
  }

  addRow(props) {
    const entity = this.add?.(props);
    return new MyRowThing(entity._id);
  }

  deleteRow(id) {
    deleteEntity(id);
  }

  canAddDeleteRows: boolean = false;
}

export class MyColManager extends ColumnManager {
  constructor(private attributes: string[], private states = [], private colStates?) {
    super();
  }

  private index() {
    return XObject.get(this.colStates, '@index2', ['title'].concat(x(this.attributes)));
  }

  displayValue(col: any, value: any): void {
    if (col == 'title') return value;
    const attr = db.attributeTypes.findById(col);
    if (attr.type == AttributeType.select) {
      return attr.options.find(o => o._id == value).title;
    }
    return value;
  }

  columns(): Column[] {
    
    if (!this.attributes?.map) return [];

    if (this.colStates) {
      return this.index().map(a => new MyColThing(a, XObject.get(this.colStates, a, {})));
    }
    else {
      return [new MyColThing('title')].concat(this.attributes.map(a => new MyColThing(a)));
    }

    // return [
    //   new MyColThing('title', XObject.get(this.colStates, 'title', {})),
    // ].concat(this.attributes.map(a => new MyColThing(a, XObject.get(this.colStates, a, {}))));
      // .concat(this.states.map(a => new MyColThing(a)));
  }

  move(oldIndex: any, newIndex: any): void {
    const index = x(this.index());
    const item = index[oldIndex];
    index.splice(oldIndex, 1);
    index.splice(newIndex, 0, item);
    this.colStates['@index2'] = index;
  }
}


@component
export class EntityDatabase extends Component<
  {
    entities: string[],
    attributes: any[]
    states
    dataValuePoint?
    createDataValuePoint?
    onClickAdd?: Function
    active?: string
    colStates?
    baseId?: string
    groupBy?: string
  }> {
  static styles = styled.div`
    .activeBlock {
      font-weight: bold;
    }
    overflow: auto;
  `;

  render() {
    const entities = this.props.entities;
    const context = useContext(SystemContext);
    return (
      <>
        <NotionDatabase
          activeRowId={this.props.active}
          groupBy={this.props.groupBy}
          createDataValuePoint={this.props.createDataValuePoint}
          onClickAdd={this.props.onClickAdd}
          dataValuePoint={this.props.dataValuePoint}
          columnManager={new MyColManager(this.props.attributes, this.props.states, this.props.colStates)}
          rowManager={new MyRowManager(entities)}
          cellArgs={{
            baseId: this.props.baseId,
          }}
          onClickRow={id => {
            context?.navigate?.({
              type: 'entity',
              id,
            })
          }}
        />
      </>
    );
  }
}
