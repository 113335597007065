import React, { Component, useContext } from 'react';
import styled from 'styled-components';
import { db } from './db';
import { component } from './component2';
import { XInit, x } from './XObject';
import { appState } from './etc/appState';
import { SystemContext, SystemContextProps } from './etc/SystemContext';
import { pushToPane } from './components/AppRoot';
import { ObjectDisplay } from './components/ObjectDisplay';
import { ObjectType } from './types/ObjectRef';
import { ObjectPicker } from './components/ObjectPicker';
import { createNewObject, getObjectParent, objectName, setObjectName, setObjectParent } from './components/objectFuncs';
import { viewObject } from './viewObject';
import { showContextMenu } from './helpers';
import { Sidebar } from './components/Sidebar';
import { objectsHierachy } from './components/allObjects';
import { _inspectState, openWindow } from './osHelpers';
import { ObjectSidebarItem } from './rightSidebar';
import { SidebarAddButton } from './components/SidebarAddButton';
import copyToClipboard from 'copy-to-clipboard';
import { ObjectEditor2 } from './ObjectEditor2';
import { ObjectRenamer } from './ObjectRenamer';
import { WindowType } from './etc/WindowType';
import { NavContext, NavTitle } from './components/registerRoute';




@component
class ObjectInspect extends Component<{ object, onNavObject }> {
  state = XInit(class {

    sidebarState = {};
    movingPicked
  })

  static contextType = SystemContext;
  context: SystemContextProps;

  static styles = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    ${Sidebar} {
      position: absolute;
      top: 0;
      bottom: 47px;
      left: 0;
      right: 0;
      overflow: auto;
    }
  `;
  
  render() {
    const { object } = this.props;
    const hierachy = objectsHierachy([ ObjectType.entity, ObjectType.query, ObjectType.space ]);
    return (
      <>
        <Sidebar
          rootItem={new ObjectSidebarItem(object, hierachy, obj => {
            this.props.onNavObject(obj);
          }, this.context)}
          state={this.state.sidebarState}
        />
        <SidebarAddButton
          onClick={e => {
            showContextMenu(e, [
              {
                text: 'Type',
                onClick: () => {
                  createNewObject(ObjectType.type, object);
                }
              },
              {
                text: 'Attribute',
                onClick: () => {
                  createNewObject(ObjectType.attribute, object);
                },
              },
              {
                text: 'Tag',
                onClick: () => {
                  createNewObject(ObjectType.tag, object);
                },
              },
              {
                text: 'Table Schema',
                onClick: () => {
                  createNewObject(ObjectType.tableSchema, object);
                },
              },
              {
                text: 'Database',
                onClick: () => {
                  createNewObject(ObjectType.database, object);
                },
              },
              {
                text: 'Page',
                onClick: () => {
                  createNewObject(ObjectType.page, object);
                },
              },
              {
                text: 'Identifier',
                onClick: () => {
                  createNewObject(ObjectType.identifier, object);
                },
              },
              {
                text: 'Value Point',
                onClick: () => {
                  createNewObject(ObjectType.valuePoint, object);
                },
              },
            ], 'bottom')
          }}
        />
      </>
    )
  }
}





@component
export class ObjectEditor extends Component<{
  obj
  onNavObject
 }> {
  state = XInit(class {
    mode;
  });

  static contextType = SystemContext;
  context: SystemContextProps;

  static styles = styled.div`
    color: #464646;
    font-family: ui-sans-serif,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,"Apple Color Emoji",Arial,sans-serif,"Segoe UI Emoji","Segoe UI Symbol";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;

    .top {
      padding: 8px;
      > .title {
        flex: 0 0 auto;
        font-weight: 500;
        display: flex;
        align-items: center;
        overflow: hidden;
        .back {
          transform: rotate(90deg);
          flex: 0 0 auto;
          width: 12px;
          height: 12px;
          margin-right: 6px;
          cursor: pointer;
          fill: #7d7d7d;

        }
        > ${ObjectDisplay} {
          display: flex;
          white-space: nowrap;
          color: #7d7d7d;
          svg {
            fill: #7d7d7d;
          }
        }
      }

    }

    .section {
      /* background-color: white; */
      display: flex;
      flex-direction: column;
      .sectionTitle {
        text-transform: uppercase;
        font-size: 10px;
        flex: 0 0 auto;
        padding: 4px;
        font-weight: 500;
        color: #c1c1c1;
      }
      flex: 1 1 0;

      .sectionContent {
        position: relative;
        flex: 1 1 0;
        overflow: auto;
        /* padding: 4px 8px; */
      }

      &:not(:last-child) {
        border-bottom: 1px solid #e9e9e7;
      }
    }
  `;

  render() {
    const { obj } = this.props;
    const render = obj => {
      const contextMenu = [
        {
          text: 'Rename',
          onClick: () => {
            this.state.mode = 'renaming';
          },
        },
        {
          text: 'Move',
          onClick: () => {
            this.state.mode = 'moving';
          },
        },
        {
          text: 'View',
          onClick: () => {
            viewObject(obj, this.context);
          },
        },
        {
          text: 'Copy ID',
          onClick: () => {
            copyToClipboard(obj.id);
          }
        },
        {
          text: 'View scope',
          onClick: () => {
            openWindow({
              type: WindowType.ScopeWindow,
              obj,
            })
          }
        },
        {
          text: 'View in scope',
          onClick: () => {
            openWindow({
              type: WindowType.ScopeWindow,
              obj: getObjectParent(obj),
            })
          }
        },
      ];

      return (
        <SystemContext.Provider value={{
          next: () => { },
          navigate: config => {
            console.log('navigate');
            pushToPane(appState.panes[0], config, false);
          },
        }}>
          <div className="top">
            <div className="title">
              <NavTitle>
                <ObjectDisplay
                  _onClick={e => {
                    showContextMenu(e, contextMenu);
                  }}
                  onContextMenu={e => {
                    e.preventDefault();
                    showContextMenu(e, contextMenu);
                  }}
                  obj={obj}
                  showPath
                />
              </NavTitle>
            </div>
            {this.state.mode == 'moving' && (
              <ObjectPicker
                _onSelect={(ref, clear) => {
                  if (ref.type == ObjectType.global) {
                    setObjectParent(obj, null);
                  }
                  else {
                    setObjectParent(obj, ref);
                  }
                  delete this.state.mode;
                }}
              />
            )}
            {this.state.mode == 'renaming' && (
              <ObjectRenamer obj={obj} onRenamed={() => {
                delete this.state.mode;
              }} />
            )}
          </div>
          <div className="section">
            <div className="sectionContent">
              <ObjectEditor2
                obj={obj}
                objNav={obj => {
                  this.props.onNavObject(obj);
                  // stack.push(obj);
                }}
              />
            </div>
          </div>
          <div className="section objects">
            <span className="sectionTitle">Objects</span>
            <div className="sectionContent">
              <ObjectInspect object={obj} onNavObject={this.props.onNavObject} />
            </div>
          </div>
        </SystemContext.Provider>
      );
    };

    return render(obj);
  }
}
