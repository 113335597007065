import { component, styled } from '../component';
import React, { Component } from 'react';
import { ObjectType } from '../types/ObjectRef';
import { expandToText } from '../richTextHelpers';
import { db } from '../db';
import { ObjectDisplay } from '../components/ObjectDisplay';
import { XInit, XObject } from '../XObject';
import { createEntity } from '../etc/createEntity';
import { RichTextEditorCustom } from '../components/richTextEditor/RichTextEditorCustom';
import { MenuPresenter } from '../components/richTextEditor/MenuPresenter';
import { getValue, setValue } from '../getValue';
import { appState } from '../etc/appState';
import { createQuery } from '../etc/queryFuncs';
import { objectsInScope } from '../components/allObjects';
import { objectName } from '../components/objectFuncs';
import { createRootValuePoint } from '../glue/main';
import { formulaStyles } from './formulaStyles';
import { grammar } from '../shorthand/formula';
import { Type2, Property, getNode, getContext, getStr, getPrimaryType } from './getCurrentAccessor';
import { presentObjectMenu } from './presentObjectMenu';
import { types } from './types';
import { highlight } from '../MyNotionDocument/highlight';

@component
export class FormulaEditor extends Component<{ get?; set?; window?: { id; path; }; parent?; scope?; vars?; additionalTypes?; additionalMenuIniters?; onEnter? }> {
  static styles = styled.div`
    ${formulaStyles}
  `;

  componentDidMount(): void {
    // console.log(toAstVisitor("ta.es(1, 3)"));
  }

  entitySelectOptions(filter, action?) {
    const r = []
      .concat(objectsInScope(this.props.parent, [ObjectType.document, ObjectType.query, ObjectType.identifier, ObjectType.attribute, ObjectType.type, ObjectType.valuePoint]).filter(o => {
        const name = objectName(o);
        if (!name) return;
        return name.toLowerCase().includes(filter.toLowerCase());
      })).map(o => {
        return ({
          data: o,
          key: o.id,
          label: <ObjectDisplay obj={o} showPath="next-line" />,
          action: (menuPos) => {
            action(menuPos, o);
          }
        }) as any;
      })
      /*.concat(
        db.queries.filter(e => {
          if (!e.name) return false;
          if (!_.isString(e.name)) return false;
          return e.name.toLowerCase().includes(filter.toLowerCase());
        }).map(e => ({
          data: {
            type: ObjectType.query,
            id: e._id,
          },
          key: e._id,
          label: <ObjectDisplay obj={{
            type: ObjectType.query,
            id: e._id,
          }} showPath="next-line" />,
          action: (menuPos) => {
            action(menuPos, {
              type: ObjectType.query,
              id: e._id,
            })
          }
        }))
      )
      .concat(
        db.identifiers.filter(e => {
          // if (e.relative) return false;
          if (!_.isString(e.name)) return false;
          return e.name.toLowerCase().includes(filter.toLowerCase());
        }).map(e => ({
          key: e._id,
          data: {
            type: ObjectType.identifier,
            id: e._id,
          },
 
          label: (
            <ObjectDisplay
              obj={{
                type: ObjectType.identifier,
                id: e._id,
              }}
            />
          ),
          action: (menuPos) => {
            action(menuPos, {
              type: ObjectType.identifier,
              id: e._id,
            })
          }
        }))
      )*/
      .concat([
        {
          key: 'CREATE_QUERY',
          label: '@Create query',
          action: (menuPos) => {
            const q = createQuery(null, null, this.props.parent, true);
            action(menuPos, {
              type: ObjectType.query,
              id: q._id,
            });
          }
        },
        {
          key: 'CREATE_GLUE',
          label: '@Create value point',
          action: (menuPos) => {
            // const q = createQuery(null, null, this.props.parent, true);
            // action(menuPos, {
            //   type: ObjectType.query,
            //   id: q._id,
            // })
            const r = createRootValuePoint();
            action(menuPos, {
              type: ObjectType.valuePoint,
              id: r._id,
            });

          }
        },
      ]);
    return r.concat({
      label: `Create entity "${filter}"`,
      action: (menuPos) => {
        const e = XObject.obj({
          name: filter,
        });
        createEntity(e, null);
        action(menuPos, e);

      }
    } as any);
  }

  objectItems(filter) {
    return this.entitySelectOptions(filter /* , (menuPos, e) => {
          const data = value();
          const position = menuPos;
          const length = dataLength(ctx, data);
          const firstPart = sliceData(ctx, data, 0, position);
          const secondPart = sliceData(ctx, data, position, length);
          if (e.type == ObjectType.entity) {
            setValue(concatData(ctx, concatData(ctx, firstPart, [[e.id, 'entity']]), secondPart));
          }
          else if (e.type == ObjectType.query) {
            setValue(concatData(ctx, concatData(ctx, firstPart, [[e.id, 'query']]), secondPart));
          }
          else if (e.type == ObjectType.identifier) {
            setValue(concatData(ctx, concatData(ctx, firstPart, [[e.id, 'identifier']]), secondPart));
          }
          else if (e.type == ObjectType.attribute) {
            setValue(concatData(ctx, concatData(ctx, firstPart, [[e.id, 'attribute']]), secondPart));
          }
          else if (e.type == ObjectType.valuePoint) {
            setValue(concatData(ctx, concatData(ctx, firstPart, [[e.id, 'valuePoint']]), secondPart));
          }
        } */
    );
  }

  presentMenu: MenuPresenter = args => {
    if (args.type == '@') {
      return presentObjectMenu(args, this.props.parent);
    }
    else if (this.props.additionalMenuIniters && args.type in this.props.additionalMenuIniters) {
      return this.props.additionalMenuIniters[args.type](args);
    }
  };

  state = XInit(class {
    position;
    currentType;
    test;
  });

  currentType: Type2;
  currentFunction;
  editing;

  showList;

  showProperty: Property;

  p;
  update() {
    const p = this.p;
    let forumla: string;
    try {
      forumla = this.formula().trimEnd();

    }
    catch (e) {
      forumla = 'error';
    }

    const match = grammar.match(forumla);
    this.state.position = Math.min(p, forumla.length);
    this.currentType = null;
    this.currentFunction = null;
    this.showProperty = null;

    if (match['_cst']) {
      const nodeTree = getNode(match['_cst'], this.state.position, true);

      // console.log('nodeTree', nodeTree, getStr(nodeTree, forumla));
      const contextTree = getContext(nodeTree);
      if (contextTree) {
        this.state.test = getStr(contextTree, forumla);

        const contextNode = contextTree[contextTree.length - 1];
        if (contextNode.ruleName == 'FunctionCall') {
          const primaryTree = contextTree.concat(contextNode.children[0]);
          const type = getPrimaryType(forumla, primaryTree);
          // console.log('ctx: functionCall', contextNode, contextTree, type);
          this.currentFunction = type;
        }
        else if (contextNode.ruleName == 'Primary_accessor') {
          // console.log('ctx: Primary_accessor', contextNode, contextTree);
          const accessorNodeTree = contextTree;
          if (accessorNodeTree) {
            const accessorNode = accessorNodeTree[accessorNodeTree.length - 1];
            if (accessorNode.children) {
              const primaryTree = accessorNodeTree.concat(accessorNode.children[0]);
              const a = getStr(primaryTree, forumla);
              const b = getStr(accessorNodeTree.concat(accessorNode.children[2]), forumla);
              // console.log(primaryTree, getStr(accessorNodeTree, forumla), a, b, primaryTree[primaryTree.length - 1].toString());
              this.currentType = getPrimaryType(forumla, primaryTree);

              if (this.currentType) {
                if (this.editing) {
                  const properties = this.currentType.properties().filter(p => p.name.toLowerCase().startsWith(b.toLowerCase()));
                  if (properties.length == 1 && properties[0].name.toLowerCase() == b.toLowerCase()) {
                    this.showProperty = properties[0];
                  }
                }
                else {
                  const property = this.currentType.properties().find(p => p.name.toLowerCase() == b.toLowerCase());
                  this.showProperty = property;
                }
              }

            }
            else {
              console.log('no children');
            }
          }
        }
        else {
          console.log('none');
        }
      }

    }
    else {
      // console.log(match);
      // console.log('no cst');
      this.state.test = '';
    }

    this.forceUpdate();
  }

  _formula() {
    if (this.props.get) return this.props.get();
    else if (this.props.window.path) {
      return getValue(this.props.window.path);
    }
    else {
      return db.formulas.findById(this.props.window.id).formula;

    }
  }

  _setFormula(value) {
    if (this.props.set) {
      return this.props.set(value);
    }
    else if (this.props.window.path) {
      setValue(this.props.window.path, value);
    }
    else {
      db.formulas.findById(this.props.window.id).formula = value;
    }
  }

  formula() {
    return expandToText({ types }, this._formula()).replace(/[^\u0000-\u007E]/g, " ");
  }

  render() {
    return (
      <>
        <RichTextEditorCustom
          highlighter={(el, pos) => {
            highlight({
              
              ctx: {
                types: {
                  ...types,
                  ...(this.props.additionalTypes || {}),
                }  
              },
              el: el,
              type: null,
              pos: pos,
              getIdentifierId: () => {},
              getType: () => {}
            });
          }}
          onEnter={this.props.onEnter}
          menuPresenter={this.presentMenu}
          onChangeLocation={p => {
            this.p = p;
            this.update();
          }}
          ctx={{
            types: {
              ...types,
              ...(this.props.additionalTypes || {}),
            }
          }}
          menuIniterKeys={['@'].concat(Object.keys(this.props.additionalMenuIniters || {}))}
          setValue={v => {
            this._setFormula(v);
            this.editing = true;
            this.update();
          }}
          value={() => this._formula()}
          _onSelectAction={(type, option) => {
            appState.focusedObjectInSelectMenu = option?.data;
          }}
        />


        {/* {this.currentType && <div>
                  <b>{this.currentType.name()}</b>
                  {!this.showProperty && <ul>
                    {this.currentType.properties().map(p => {
                      return (
                        <li key={p.name}>{p.name}: {p.type.name()}</li>
                      )
                    })}
                  </ul>}
                </div>}
        
                {this.showProperty && (
                  <>
                    {this.showProperty.name}: {this.showProperty.type.name()}
                  </>
                )}
        
        
                {this.currentFunction && (
                  <div>
                    <b>{this.currentFunction.funcName}() -&gt; {this.currentFunction.returnType?.name?.()}</b>
                    <ul>
                      {this.currentFunction.params?.map?.(p => {
                        return (
                          <li key={p.name}>{p.name}: {p.type.name()}</li>
                        )
                      })}
                    </ul>
                  </div>
                )} */}

        {/* <div>{formula} ({formula.length})</div> */}

        {/*<div>{JSON.stringify(formula)}</div>
        
                <div>
                  {test(formula)}
                </div> */}

        {/* <div>
                  {this.state.position} ({this.state.test})
                </div> */}
      </>
    );
  }
}
