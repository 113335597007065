import React from 'react';
import _ from 'lodash';
import { component } from '../../component2';
import { DraftSelect } from '../../etc/draftHelpers';
import { x, XObject } from '../../XObject';
import { showContextMenu } from '../../helpers';
import { ValuePoint } from '../ValuePoint';
import { Comp } from '../Comp';
import {
  _iterate,
  addValuePoint,
  CompiledValuePoint,
  execute,
  getParameter,
  getStructureParameter,
  resolveTypeList,
  ValuePointProps,
  ValuePointType
} from '../main';
import { Runtime } from "../Runtime";
import { registerType } from "../__typeRegistry";
import { ValueType } from "../ValueType";
import { registerTypeRegister } from '../typeRegistering';


@component
export class ParameterValuePoint extends Comp<ValuePointProps> {
  render() {
    const id = this.props.state.content.id || this.props.state.content;
    const p = getParameter(id) || getStructureParameter(id);
    if (p) {
      return <>{p.templateParam && '*' || ''}{p.name}{p.parameters?.length > 0 && (() => {
        const value = this.props.state.content;
        if (_.isString(value))
          return null;
        const args = XObject.get(value, 'arguments', []);

        return (<ul>
          {args.map((arg, i) => {
            const pp = p.parameters.find(p => p._id == arg.param);
            return (
              <li key={arg._id}><span
                onContextMenu={e => {
                  e.preventDefault();
                  showContextMenu(e, [
                    {
                      text: 'Remove',
                      onClick: () => {
                        args.splice(i, 1);
                      },
                    }
                  ]);
                }}
              >{pp?.templateParam && '*'}{pp?.name || '???'}</span> := <ValuePoint id={arg.value._id} /></li>
            );
          })}
          {args.length < p.parameters.length && <li>
            <DraftSelect
              id={this.props.elId}
              options={p.parameters.filter(p => !args.find(a => a.param == p._id)).map((param) => ({
                display: param.name,
                value: param,
              }))}
              onSelect={(param) => {
                args.push(XObject.obj({
                  param: param._id,
                  value: addValuePoint(XObject.obj({
                    possibleTypes: resolveTypeList(x(param.type)),
                    parent: this.props.state._id,
                  })),
                }));
              }} />
          </li>}
        </ul>);
      })()}</>;
    }
    else {
      return <span style={{ color: 'red' }}>Parameter not found</span>;
    }
  }
}

export function resolveParam(id, args, rt: Runtime) {
  if (id in rt.paramStore) {
    const memory: { [key: string]: string; } = {};
    if (args) {
      for (const arg of args) {
        memory[arg.param] = arg.value._id;
      }
    }

    return execute(rt.paramStore[id], rt.pushMemory(memory));
  }
}

export function executeParam(value: ValuePointType, rt: Runtime): CompiledValuePoint {
  // TODO: come up with a better way to distinguish between value params and struct params
  const id = value.content.id || value.content;
  // console.log('executeParam', value._id, rt.paramStore);

  if (id in rt.paramStore) {
    return resolveParam(id, value.content.arguments, rt);
    // const memory: { [key: string]: string; } = {};
    // if (value.content.arguments) {
    //   for (const arg of value.content.arguments) {
    //     memory[arg.param] = arg.value._id;
    //   }
    // }

    // return execute(rt.paramStore[id], rt.pushMemory(memory));
  } else {
    return {
      _id: value._id,
      type: value.type,
      content: value.content,
      rt,
      isState: false,
      presentation: value.presentation,
    };
  }
}

export const registerParamType = () => {


  registerType(ValueType.Param, {
    execute: (value, rt) => {
      return executeParam(value, rt);
    },
    evaluate: (value, map) => {
      return map[value.content.id || value.content];
    },
    render: (value, map, state, renderType) => {
      if (value.presentation?.debug) {
        console.log('sdafasdf', map);
      }
      if (_.isString(value.content?.id) && value.content?.id in map) {
        return map[value.content?.id];
      }
    },
    isBlock: value => {
      if (value.presentation?.compact) return false;
      const v = getParameter(value.content.id || value.content);
      if (v?.parameters?.length) return true;

    },
    iterate: (value, parent, func, set) => {
      if (value.content?.arguments?.length) {
        const list = value.content.arguments || [];
        for (let i = 0; i < list.length; i++) {
          const r = _iterate(list[i].value, value, func, value => {
            list[i].value = value;
          });
          if (r !== undefined) return r;
        }
        // return _iterate(value.content.arguments.map(a => a.value) || [], value, func);

      }
    },
    editorComp: ParameterValuePoint,
  });
};

registerTypeRegister(registerParamType);