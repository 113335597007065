import React, { Component } from 'react';
import styled from 'styled-components';
import { db } from '../db';
import { component } from '../component2';
import { XInit, XObject } from '../XObject';
import { typesInScope } from "./objectFuncs";
import classNames from 'classnames';
import { appState } from '../etc/appState';
import { createEntity, getAllEntities, getEntityById } from '../etc/createEntity';
import { EntityRow } from './EntityRow';
import { cssImg } from '../img';
import { ObjectType } from '../types/ObjectRef';
import { ChatGPTConversation, chatGptManager } from '../etc/chatGptManager';
import { attributesForType } from './attributesForType';
import { executeEntityQuery, queryChain } from '../etc/queryFuncs';
import { NotionTable } from './NotionTable';


@component
export class ChagGPTPane2 extends Component<{ window }> {
  state = XInit(class {
    messages = [
      // XObject.obj({
      //   content: 'Hello',
      //   type: 'sent',
      // }),
      // XObject.obj({
      //   content: 'Hi',
      //   type: 'received',
      // }),
    ];

    results = [];
  });
  conversation: ChatGPTConversation;
  constructor(props) {
    super(props);

    this.conversation = chatGptManager.createConversation();
  }
  static styles = styled.div`
    .message {
      padding-left: 50px;
      position: relative;
      min-height: 30px;
      padding-top: 20px;
      padding-bottom: 20px;

      &.received {
        background: #eee;
      }
      &:before {
        content: '';
        position: absolute;
        left: 10px;
        top: 20px;

        width: 30px;
        height: 30px;
        background-image: ${cssImg('chatGpt')};
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      &.sent {
        &:before {
          background-image: ${cssImg('icons8-human')};
        }
      }
    }

    .queryResults {
      padding: 8px;
    }
  `;

  performQueryAction(action) {
    const message = [];

      
    message.push(
      `
YOUR INSTRUCTIONS:
The user is asking you to find something. Look through the GIVEN OBJECTS and return the IDs matching the question. The user is not asking for a literal word match, but a general relation of the topic.
            
What your output needs to look like (needs to be valid JSON):
{
  "actionType": "query",
  "response": [
    {
      "_id": "ID OF OBJECT",
      "reasonForIncluding": "<REASON FOR INCLUDING -- please do elaborate a bit>"
    }
  ]
}


`);

message.push('USER MESSAGE:');
message.push(action);

  
  
      message.push('GIVEN OBJECTS:');

      const doc = db.notionDocuments.findById(this.props.window.table);
      for (const row of doc.tableData.rows) {
        message.push(`${row._id} { ${Object.values(row.values).join(', ')} }`);
      }

  
      return message.join('\n');
    }


  async doQuery(action) {
    console.log(this.performQueryAction(action));
    const r2 = await this.conversation.sendMessage(this.performQueryAction(action));
    console.log(r2);
    const results = JSON.parse(r2);


    let newEntity;

    console.log(results);

    if (results.actionType == 'query') {
      this.state.messages.push(XObject.obj({
        type: 'received',
        contentType: 'queryResults',

        content: results.response,
      }));
    }
    else {
      this.state.messages.push(XObject.obj({
        type: 'received',

        content: r2,
        newEntity,
      }));
    }

  }


  render() {
    return (
      <>
        {/* <pre>{this.extractTypesMessage()}</pre> */}

        {this.state.messages.map(message => {

          if (message.contentType == 'queryResults') {
            return (
              <div key={message._id}>

                <NotionTable
                  state={this.props.window}
                  table={db.notionDocuments.findById(this.props.window.table).tableData}
                  active={null}
                  hideToolbar
                  ids={message.content.map(result => result._id)}
                />



                {/* <div className="queryResults">
                  {message.content.map(result => {



                    return (
                      <div key={result._id}>
                        <p>"{result.reasonForIncluding}"</p>
                      </div>
                    );

                  })}

                </div> */}

              </div>
            );
          }
          else {
            return (
              <div key={message._id}
                className={classNames('message', message.type, {
                  // type: ,
                })}
              >
                {message.content}

                {message.newEntity && (
                  <EntityRow id={message.newEntity} path />
                )}
              </div>
            );

          }
        })}
        <textarea
          onKeyDown={async (e) => {
            if (e.key == 'Enter') {
              e.preventDefault();
              const message = e.target['value'];
              e.target['value'] = '';

              this.state.messages.push(XObject.obj({
                type: 'sent',
                content: message,
              }));

              // if (this.props.window.query) {
                this.doQuery(message);
              // }


              // const response = await this.conversation.sendMessage(message);
              // this.state.messages.push(XObject.obj({
              //   type: 'received',
              //   content: response,
              // }))
            }
          }}
        ></textarea>

      </>
    );
  }
}



@component
export class ChagGPTPane extends Component<{ window }> {
  state = XInit(class {
    messages = [
      // XObject.obj({
      //   content: 'Hello',
      //   type: 'sent',
      // }),
      // XObject.obj({
      //   content: 'Hi',
      //   type: 'received',
      // }),
    ];

    results = [];
  });
  conversation: ChatGPTConversation;
  constructor(props) {
    super(props);

    this.conversation = chatGptManager.createConversation();
  }
  static styles = styled.div`
    .message {
      padding-left: 50px;
      position: relative;
      min-height: 30px;
      padding-top: 20px;
      padding-bottom: 20px;

      &.received {
        background: #eee;
      }
      &:before {
        content: '';
        position: absolute;
        left: 10px;
        top: 20px;

        width: 30px;
        height: 30px;
        background-image: ${cssImg('chatGpt')};
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      &.sent {
        &:before {
          background-image: ${cssImg('icons8-human')};
        }
      }
    }

    .queryResults {
      padding: 8px;
    }
  `;

  extractTypesMessage(action) {
    const message = [];

    const types = typesInScope({
      type: ObjectType.mode,
      id: appState.currentMode,
    });

    message.push('OBJECT TYPES:');
    message.push(

      types.map(id => db.entityTypes.findById(id).name).join(', ')
    );
    for (const id of types) {
      const type = db.entityTypes.findById(id);
      const attributes = attributesForType(id);
      message.push(
        `
${type.name} {
${attributes.map(attribute => {
          const attr = db.attributeTypes.findById(attribute);
          return `  ${attr.name}`;
        }).join('\n')}
}
`
      );
    }

    message.push('INSTRUCTIONS:');
    message.push(
      `
    I am going to present a user message to you.
    The user is going to ask you to find something, or create something.
    Please try to figure out which object types are related to the request. 
    You might need to do a bit of inferrence. If the action refers to lets say a movie by name, 
    include the movie type. Same for states, people, etc.
    The user might ask you to do an action related to a category of object. Include the category types as well.
    You response should be in valid JSON and look like as follows (only use ASCII characters):
    [
      "Type1",
      "Type2",
      ...
    ]

    USER MESSAGE:
    ${action}
`
    );

    /*
 
    message.push('INSTRUCTIONS:');
 
    message.push('If the user is asking you to find something, look through the GIVEN OBJECTS and return the IDs matching the question. The user is not asking for a literal word match, but a general relation of the topic.');
 
    message.push(`
    Use this as the format for returning results:
    [
      {
        objectID: '1',
        reasonForIncluding: 'The note is about cars, and the user is asking about travel'
      }
    ]
 
    `)*/
    return message.join('\n');

  }



  performQueryAction(action) {
    const message = [];

      
    message.push(
      `
YOUR INSTRUCTIONS:
The user is asking you to find something. Look through the GIVEN OBJECTS and return the IDs matching the question. The user is not asking for a literal word match, but a general relation of the topic.
            
What your output needs to look like (needs to be valid JSON):
{
  "actionType": "query",
  "response": [
    {
      "_id": "ID OF OBJECT",
      "reasonForIncluding": "<REASON FOR INCLUDING -- please do elaborate a bit>"
    }
  ]
}


`);

message.push('USER MESSAGE:');
message.push(action);

    const query2 = db.queries.findById(this.props.window.query);
    const objects = executeEntityQuery(queryChain(query2)).slice(0, 40).map(id => getEntityById(id));
  
  
      console.log(objects);
      message.push('GIVEN OBJECTS:');
      for (const object of objects) {
        const type = db.entityTypes.findById(object.type);
  
        if (object.attributes) { }
        message.push(
          `
  ${type.name} {
    _id: ${object._id}
    name: ${object.name}
    ${Object.keys(object.attributes || {}).map(id => {
            const attr = db.attributeTypes.findById(id);
            if (!attr)
              return '';
            return `${attr.name}: ${object.attributes[id]}`;
          }).join('\n')}
  }
  `
  
        );
      }

  
  

  
  
  
  
  
      return message.join('\n');
    }
  


  performActionMessage(messageTypes, action) {
    const objects = [];
    for (const type of messageTypes) {
      for (const entity of getAllEntities().filter(e => e.type == type._id)) {
        objects.push(entity);
      }
    }

    const message = [];


    console.log(objects);
    message.push('GIVEN OBJECTS:');
    for (const object of objects) {
      const type = db.entityTypes.findById(object.type);

      if (object.attributes) { }
      message.push(
        `
${type.name} {
  _id: ${object._id}
  name: ${object.name}
  ${Object.keys(object.attributes || {}).map(id => {
          const attr = db.attributeTypes.findById(id);
          if (!attr)
            return '';
          return `${attr.name}: ${object.attributes[id]}`;
        }).join('\n')}
}
`

      );
    }

    message.push(
      `
The Query Action
If the user is asking you to find something, look through the GIVEN OBJECTS and return the IDs matching the question. The user is not asking for a literal word match, but a general relation of the topic.
            
What your output needs to look like (needs to be valid JSON):
{
  "actionType": "query",
  "response": [
    {
      "_id": "ID OF OBJECT",
      "reasonForIncluding": "<REASON FOR INCLUDING -- please do elaborate a bit>"
    }
  ]
}

The Create Action
If the user is asking you to create something,
please do your best to create on object based on
the types give under OBJECT TYPES. Don't make up types.

A create action can take the form of an explicit request, i.e.,
"create a new movie called 'The Matrix'" or "note for the matrix: watch this"
However, it can also be indirect: "ask peter to finish the task" would be
a create action for Question object (if the Question object exists)

If the user just created an object, they might refer to it in another request.
Try to find it in the give objects based on the creation action.

If the new object is related to another object, but there doesn't
seem to be an attribute for it, please set the new objects
parent attribute to the ID of the related object.

The name of the new object is likely not the action text, so you'll have to try to derive
it from what they said.
Never make up attributes. If you don't know what to set, leave it out.



What your output needs to look like (don't include anything else, the response needs to be valid JSON. Don't make up attributes.)
{
  "actionType": "create",
  "response": [
    {
      "type": "<TYPE OF OBJECT>",
      "attributes": {
        "Name": "<NAME OF OBJECT BASED ON REQUEST>",
        "Attribute": "<Attribute Value>"
      },
      parent: "<ID OF RELATED OBJECT>"
    }
  ]
}


`);


    message.push('USER MESSAGE:');
    message.push(action);





    return message.join('\n');
  }

  async doQuery(action) {
    console.log(this.performQueryAction(action));
    const r2 = await this.conversation.sendMessage(this.performQueryAction(action));
    console.log(r2);
    const results = JSON.parse(r2);



    let newEntity;

    console.log(results);

    if (results.actionType == 'create') {
      for (const r of results.response) {
        const type = db.entityTypes.find(t => t.name == r.type);
        const name = r.attributes?.Name || r.attributes?.name;
        if (type && name) {
          const parent = r.parent;
          const entity: any = {
            type: type._id,
            name,
          };

          if (parent) {
            entity.$edges = [
              {
                from: parent,
                directed: true,
              }
            ];
          }
          console.log(entity);
          newEntity = createEntity(entity, null)?._id;
        }
      }
    }

    if (results.actionType == 'query') {
      this.state.messages.push(XObject.obj({
        type: 'received',
        contentType: 'queryResults',

        content: results.response,
      }));
    }
    else {
      this.state.messages.push(XObject.obj({
        type: 'received',

        content: r2,
        newEntity,
      }));
    }

  }

  async doAction(action) {
    console.log(this.extractTypesMessage(action));
    const r = await this.conversation.sendMessage(this.extractTypesMessage(action));
    console.log(r);
    this.state.messages.push(XObject.obj({
      type: 'received',
      content: r,
    }));

    const types = typesInScope({
      type: ObjectType.mode,
      id: appState.currentMode,
    }).map(id => db.entityTypes.findById(id));


    // const objects = [];
    const messageTypes = JSON.parse(r).map(typeName => types.find(type => type.name == typeName)).filter(t => t);

    console.log(this.performActionMessage(messageTypes, action));

    const r2 = await this.conversation.sendMessage(this.performActionMessage(messageTypes, action));
    console.log(r2);
    const results = JSON.parse(r2);



    let newEntity;

    console.log(results);

    if (results.actionType == 'create') {
      for (const r of results.response) {
        const type = db.entityTypes.find(t => t.name == r.type);
        const name = r.attributes?.Name || r.attributes?.name;
        if (type && name) {
          const parent = r.parent;
          const entity: any = {
            type: type._id,
            name,
          };

          if (parent) {
            entity.$edges = [
              {
                from: parent,
                directed: true,
              }
            ];
          }
          console.log(entity);
          newEntity = createEntity(entity, null)?._id;
        }
      }
    }

    if (results.actionType == 'query') {
      this.state.messages.push(XObject.obj({
        type: 'received',
        contentType: 'queryResults',

        content: results.response,
      }));
    }
    else {
      this.state.messages.push(XObject.obj({
        type: 'received',

        content: r2,
        newEntity,
      }));
    }



    // this.state.results = results;
  }

  render() {
    return (
      <>
        {/* <pre>{this.extractTypesMessage()}</pre> */}

        {this.state.messages.map(message => {

          if (message.contentType == 'queryResults') {
            return (
              <div key={message._id}>
                <div className="queryResults">
                  {message.content.map(result => {

                    return (
                      <div key={result._id}>
                        <EntityRow id={result._id} path />
                        <p>"{result.reasonForIncluding}"</p>
                      </div>
                    );

                  })}

                </div>

              </div>
            );
          }
          else {
            return (
              <div key={message._id}
                className={classNames('message', message.type, {
                  // type: ,
                })}
              >
                {message.content}

                {message.newEntity && (
                  <EntityRow id={message.newEntity} path />
                )}
              </div>
            );

          }
        })}
        <textarea
          onKeyDown={async (e) => {
            if (e.key == 'Enter') {
              e.preventDefault();
              const message = e.target['value'];
              e.target['value'] = '';

              this.state.messages.push(XObject.obj({
                type: 'sent',
                content: message,
              }));

              if (this.props.window.query) {
                this.doQuery(message);
              }
              else {
                this.doAction(message);

              }


              // const response = await this.conversation.sendMessage(message);
              // this.state.messages.push(XObject.obj({
              //   type: 'received',
              //   content: response,
              // }))
            }
          }}
        ></textarea>

      </>
    );
  }
}
