import React from 'react';
import ReactDOM from 'react-dom';
import jQuery from 'jquery';
import classNames from "classnames";
import _ from 'lodash';
import { EntityViewType } from "../components/EntityRow";
import { db } from "../db";
import { GlueView, evaluate, execute, getValuePoint, mapStructure } from "../glue/main";
import { $Badge, $DocumentCheckbox, $For } from "../glue/structs/$Document";
import { resolveEntityBinding } from "../glue/structs/resolveBinding";
import { Wrapper } from "./Wrapper";
import { ObjectRefClass, ObjectType } from "../types/ObjectRef";
import { Svg } from "../components/Svg";
import { Code } from "./Code";
import { Badge, EntityBadges, RecordBadges } from "./Badge";
import { EntityPath } from "../components/EntityPath";
import { MyBlock } from "./MyBlockManager";
import { CheckBox } from "./CheckBox";
import { RenderData } from "./RenderData";
import { component } from "../component";
import { Component } from "react";
import { styled } from "../component2";
import { color } from "../components/notionDocument/color";
import { X, XInit, XObject, x } from "../XObject";
import { getCodeComponent } from "../pushCode";
import { FileUpload, uploadedFileUrl } from "../components/FileUpload";
import { isMobile } from '../isMobile';
import { BlockType } from './BlockType';
import { fullBlocks } from './fullBlocks';
import { ViewQuery } from '../components/ViewQuery';
import { codaIcons } from '../codaIcons';
import { showContextMenu } from '../etc/showContextMenu';
import { lightColors } from '../components/mainColors';
import { NotionDocumentWrapper } from '../components/NotionDocumentWrapper';
import { BlockColumn } from '../components/notionDocument/BlockManager';
import { doEventOccurrencesQuery } from '../glue/structs/$EventOccurrencesQuery';
import { getEntityById } from '../etc/createEntity';
import { Pass } from '../shorthand/formula';
import { scriptBlockDiagInfo } from '../shorthand/scriptBlockDiagInfo';

@component
export class AttributeBadges extends Component<{ block }> {
  static styles = styled.div`
    display: inline-flex;
    > * {
      margin-right: 4px;
    }

    align-items: center;

    .type {
      font-size: 10px;
      color: ${color('block.metaLine.text')};
      margin-left: 2px;
    }
  `;
  render() {
    const { block } = this.props;
    if (!block.attributes) return null;

    return (
      <>
        {Object.keys(x(block.attributes))?.map?.(attr => {
            const value = block.attributes[attr];
            return (
              <Badge
                key={attr}
                text={`${db.attributeTypes.findById(attr).name}: ${value}`}
              />
            );
        })}
      </>
    );
  }
}

@component
export class ScriptBadges extends Component<{ block, args }> {
  // static delayedUpdate = 500;
  static styles = styled.div`
    display: inline-flex;
    > * {
      margin-right: 4px;
    }

    align-items: center;

  `;
  render() {
    const { block } = this.props;

    return this.props.args?.render?.(block);


  }
}

@component
export class CodeComponentBadges extends Component<{ block, page }> {
  static styles = styled.div`
    display: inline-flex;
    > * {
      margin-right: 4px;
    }
    align-items: center;
  `;
  render() {
    const { block } = this.props;
    return (
      <>
        {this.props.page?.blockCodeComponents?.map?.(ud => {
          const comp = getCodeComponent(ud);
          let value;
          try {
            value = comp?.({ blockId: block._id, pageId: this.props.page._id });
          }
          catch (e) {
            value = 'error';
          }
          if (_.isNil(value)) return null;
            return (
              <Badge
                key={ud}
                text={value}
              />
            );
        })}
      </>
    );
  }
}

@component
export class ValuePointBadges extends Component<{ block, page }> {
  static styles = styled.div`
    display: inline-flex;
    > * {
      margin-right: 4px;
    }
    align-items: center;
  `;
  render() {
    const { block } = this.props;

    return (
      <>
        {this.props.page?.blockValuePoints?.map?.(ud => {
          const compiled = execute(ud);
          const valuePoint = getValuePoint(ud);

          const args = {
            [valuePoint.parameters?.[0]?._id]: this.props.page._id,
            [valuePoint.parameters?.[1]?._id]: this.props.block._id,
          }

          const value = evaluate(compiled, args);



          if (_.isNil(value) || value instanceof Pass || (!_.isString(value) && !_.isNumber(value))) return null;
            return (
              <Badge
                key={ud}
                text={value}
              />
            );
        })}
      </>
    );
  }
}

@component
class MediaBlock extends Component<{ block }> {
  static styles = styled.div`
    .media {
      display: block;
      height: 300px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  `;
  render() {
    const { block } = this.props;
    return (
      <FileUpload
        onUpload={file => {
          block.media = file;
        }}
      >
        {block.media && (
          <span className="media"
            style={{
              backgroundImage: `url(${uploadedFileUrl(block.media)})`,
            }}
          />
        )}
        {!block.media && 'No media'}
      </FileUpload>
    )
  }
}

@component
class ReactiveWrapper extends Component<{ func }> {
  el
  componentDidMount(): void {
    this.el = ReactDOM.findDOMNode(this);
  }
  render() {
    return this.props.func(this.el);
  }
}


@component
class HorizontalLine extends Component {
  static styles = styled.div`
    height: 20px;
    display: flex;
    align-items: center;
    > span {
      height: 1px;
      background-color: rgb(224, 224, 224);
      width: 100%;
    }
  `;

  render() {
    return <span />;
  }
}

@component
class DocumentBlock extends Component<{ block, entity }> {
  render() {
    const { block, entity } = this.props;
    let doc;
    let key;

    if (block.identifier) {
      const identifier = db.identifiers.findById(block.identifier);
      if (identifier.relative) {
        key = `${entity}:${identifier._id}`
      }
      else {
        key = `${identifier._id}`

      }

      doc = db.notionDocuments.find(d => d.key == key);
    }
    else if (block.document) {
      doc = db.notionDocuments.findById(block.document);
    }
    return (
      <>
        <div contentEditable={false} className="innerDocument">
          {doc && <NotionDocumentWrapper
          docId={doc._id}
          entity={null}
          inline
          blocks={doc.blocks}
          setBlocks={blocks => {
            doc.blocks = blocks;
          }}
          configId={null}
          name={null}
          configMap={{
            // [$Document.Entity]: n,
          }}
        />}

        {!doc && <button
          onClick={() => {
            const doc: any = XObject.obj({
              name: '',
              blocks: [],
              type: 'de89bc9c-9fb1-579d-8954-53f1519cbb33',
            });

            if (block.identifier) {
              doc.key = key;
              db.notionDocuments.push(doc);
            }
            else {
              db.notionDocuments.push(doc);
              block.document = doc._id;
            }
          }}
        >{block.placeholder}</button>}
        </div>
      </>
    )
  }
}

@component
class GlueBlock extends Component<{ block, entity }> {
  state = X({});
  render() {
    const { block } = this.props;
    const vp = getValuePoint(this.props.block.valuePoint, false);
    const state = XObject.get(block, 'state', {});
    return (
      <>
        {vp && <GlueView
          debug
          args={vp.parameters?.[0] && {
            [vp.parameters[0]._id]: this.props.entity,
          }}
          id={block.valuePoint}
          state={state}
        />}
      </>
    )
  }
}

const Spaced = styled.div`
  display: flex;
  > * {
    margin-right: 4px;
  }
`;

class BlockComponent extends Component<{
  depth,
  args,
  block2: MyBlock
  systemCtx,
  draggingId,
  activeBlock,
  onMouseDownGrip,
  onContextMenu,
  beforeChange,
  changed,
  renderDataRef,
  ctx,
  noChildren,
  onClickAddBlock,
}> {

  render() {
    console.log('render', this.props.block2.getId());

    const {
      depth,
      args,
      block2,
      systemCtx,
      draggingId,
      activeBlock,
      onMouseDownGrip,
      onContextMenu,
      beforeChange,
      changed,
      renderDataRef,
      ctx,
      noChildren,
      onClickAddBlock,
    } = this.props;

    const onClickEntityDoc = args.onClickEntityDot;
    const blockTypes = [];
    const block: { collapsed, _id, textColor, identifier, textAlign, valuePoint, color, children, type, checked, id, record, dataBinding, attributes, media, query, icon, document } = block2.block;
    const blockType = blockTypes.find(b => b._id == block.type);
    let checkbox = blockType?.elements?.find?.(e => e.type == 'checkbox');
  
    let meta = [];

    if (args.meta) {
      meta.push(<ReactiveWrapper 
        
        func={() => {
          return args.meta(block)
        }}
        />);
    }
  
  
    if (block.id || block.type == BlockType.template) {
      let valuePointId;
  
      if (block.type == BlockType.template) {
        valuePointId = block.valuePoint;
      }
      else {
        const entity = getEntityById(block.id);
        if (entity?.type) {
          const type = db.entityTypes.findById(entity.type);
          const blockView = type.views?.find?.(v => v.viewType == EntityViewType.block);
          if (blockView) {
            if (blockView.valuePoint) {
              valuePointId = blockView.valuePoint;
            }
          }
        }
      }
  
      if (valuePointId) {
        try {
          const valuePoint = getValuePoint(valuePointId);
          const mapped = mapStructure(execute(valuePointId));
          const checkboxTempl = mapped.elements?.content?.find?.(e => e.type?.[1] == $DocumentCheckbox.$);
  
          let map = {};
  
          if (valuePoint.parameters?.[0]) {
            map[valuePoint.parameters[0]._id] = block.id;
          }
  
          if (args.obj?.type == ObjectType.document) {
            const doc = db.notionDocuments.findById(args.obj.id);
            if (doc.contextVariables) {
              for (const cv of doc.contextVariables) {
                map[cv.identifier.id] = new ObjectRefClass(cv.value.type, cv.value.id);
              }
            }
          }
  
          if (checkboxTempl) {
            const mappedA = mapStructure(checkboxTempl);
            checkbox = {
              binding: {
                get: (entityId) => {
                  return resolveEntityBinding(entityId, mappedA.binding, map).get();
                },
                set:  (entityId, value) => {
                  return resolveEntityBinding(entityId, mappedA.binding, map).set(value);
                }
              }
            }
          }
          const badges = [];
  
          meta.push(<ReactiveWrapper func={() => {
            const badges = [];
            for (const el of mapped.elements?.content) {
              if (el.type[1] == $Badge.$) {
    
              }
              else if (el.type[1] == $For.$) {
                const mapped = mapStructure(el);
                if (mapped.block.type[1] == $Badge.$) {
                  const blockMapped = mapStructure(mapped.block);
                  const series = doEventOccurrencesQuery(mapped.series, map);
                  for (const obj of series) {
                    const r = evaluate(blockMapped.content, {
                      [$For.I]: obj,
                      __env: {
                        $i: obj
                      }
                    });
                    badges.push(<Badge key={obj.id} text={r} _onClick={() => {
                        evaluate(blockMapped.onClick, {
                          [$For.I]: obj,
                          __env: {
                            $i: obj,
                            __ctx: systemCtx,
                          }  
                        });
                      }}
                    />);
                  }
                }
              }
            }
  
            if (badges) {
              return <Spaced>{badges}</Spaced>
            }
  
  
          }} />);
  
          /*for (const el of mapped.elements?.content) {
            if (el.type[1] == $Badge.$) {
  
            }
            else if (el.type[1] == $For.$) {
              const mapped = mapStructure(el);
              if (mapped.block.type[1] == $Badge.$) {
                const blockMapped = mapStructure(mapped.block);
                const series = doEventOccurrencesQuery(mapped.series, map);
                for (const obj of series) {
                  badges.push(<Asdf key={obj.id} func={() => {
                    const r = evaluate(blockMapped.content, {
                      [$For.I]: obj,
                      __env: {
                        $i: obj
                      }
                    });
                    return <Badge key={obj.id} text={r} _onClick={() => {
                      evaluate(blockMapped.onClick, {
                        [$For.I]: obj,
                        __env: {
                          $i: obj,
                          __ctx: systemCtx,
                        }  
                      });
                    }} />
                  }} />)
                }
              }
            }
          }
  
          if (badges.length) {
            meta.push(<Spaced>{badges}</Spaced>)
          }*/
        }
  
        catch (e) {
          console.log('asdf', e);
        }
      }
    }
  
  
    if (block.id) {
      meta.push(<EntityBadges key="entity" block={block} entity={getEntityById(block.id)} blockType={blockType} />)
    }
  
    if (block.attributes) {
      meta.push(<AttributeBadges key="attributes" block={block} />)
    }
  
    meta.push(<ScriptBadges key="script" block={block} args={args.scriptBadgesArgs} />)
  
    meta.push(<CodeComponentBadges key="CodeComponentBadges" block={block} page={db.notionDocuments.findById(args.docId)} />)
    meta.push(<ValuePointBadges key="ValuePointBadges" block={block} page={db.notionDocuments.findById(args.docId)} />)
  
    const isChecked = () => {
      return checkbox ? checkbox.binding.get(block.id) : block.type == 'checkItem' && block.checked;
    }
    const checked = isChecked();
    const ref = React.createRef<any>();
    const blockRef = React.createRef<any>();
  
    return (
      <Wrapper
        key={block._id}
        ref={ref}
        data-value-point={blockType?._id}
        data-block-type={blockType?._id}
        data-entity={block.id}
        data-record={block.record}
        data-type="blockCont"
        data-depth={depth}
        className={classNames(block.type, {
          dragging: block._id == draggingId,
          hasCheckbox: checkbox || block.type == 'checkItem',
          checked: checked,
          mobile: isMobile(),
          callout: block.type == BlockType.callout,
          collapsed: block2.isCollapsed(),
        })}
        style={{
          backgroundColor: block.type == BlockType.callout && block.color && lightColors.find(c => c.key == block.color)?.color,
        }}
      >
        <ReactiveWrapper func={() => {
          if (isChecked()) {
            jQuery(ref.current).addClass('checked');
          }
          else {
            jQuery(ref.current).removeClass('checked');
          }
          if (block.type == BlockType.callout) {
            jQuery(ref.current).addClass('callout');
          }
          else {
            jQuery(ref.current).removeClass('callout');
          }
        }} />

        <div 
          data-ui-binding={block.id && JSON.stringify({
            viewType: EntityViewType.block,
            entity: block.id,
            context: {
              type: ObjectType.document,
            }
          })}
          data-block-id={block._id}
          data-input-id={block._id}
          data-inspect-id={block.id}
          ref={blockRef}
          className={classNames('block', {
            activeBlock: block._id == activeBlock,
            mobile: isMobile(),
            hasIcon: block.icon,
          })}
          style={{
            color: block.textColor,
          }}
        >
          {/* <ReactiveWrapper func={el => {
            // if (block.icon) {
            //   jQuery(blockRef.current).addClass('hasIcon');
  
            // }
            // else {
            //   jQuery(blockRef.current).removeClass('hasIcon');
            // }
  
          }} /> */}
  
          {/* <Svg name="plus" className="addBlock"
            onClick={e => {
              onClickAddBlock(e, block);
            }}
          /> */}
  
          {block.type == BlockType.callout && (
            <>
            <ReactiveWrapper
              func={() => {
                return (
                  <span 
                  onContextMenu={e => {
                    e.preventDefault();
                    showContextMenu(e, [
                      {
                        text: 'Clear icon',
                        onClick: () => {
                          delete block.icon;
                        }
                      }
                    ])
                  }}
                  className="icon"
                  contentEditable="false"
                  style={block.icon && {
                    backgroundImage: `url(${codaIcons.find(i => i.name == block.icon).url('color')})`
                  }}
                  onMouseDown={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    console.log('asdf', block[XObject._idSymbol]);
                    // setTimeout(() => {
                    //   block.icon = 'info';//codaIcons[0].name;
                    // }, 4000);
                  }}
                />
                )
              }}
  
              />
  
            </>
          )}
  
          {!isMobile() && (
            <>
              <Svg name="grip" className="grip"
                onMouseDown={e => {
                  onMouseDownGrip(e, block);
                }}
                onContextMenu={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  onContextMenu(e, block);
                }}
              />
  
            </>
          )}
          {block.type == 'code' && (
            <>
              <div contentEditable={false} className="codeBlock">
                <Code block={block} />
              </div>
            </>
          )}
          {block.type == 'media' && (
            <>
              <div contentEditable={false} className="mediaBlock">
                <MediaBlock block={block} />
              </div>
            </>
          )}
          {block.type == BlockType.query && (
            <>
              <div contentEditable={false}
                style={{
                  // marginLeft: -args.margin,
                  // marginRight: -args.margin + 20,
                  // paddingLeft: args.margin,
                }}
              >
                <ViewQuery
                  key={block._id}
                  id={block.query}
                  state={XObject.get(block, 'state', {})}
                  entity={args.entity}
                  showToolbar={true}
                  touched={e => {
                  }}
                  outerMargin={args.margin}
                />
              </div>
            </>
          )}
          {block.type == BlockType.horizontalLine && (
            <>
              <div contentEditable={false}>
                <HorizontalLine />
              </div>
            </>
          )}
          {block.type == BlockType.document && (() => {
            return <DocumentBlock block={block} entity={args.entity} />;
          })()}
  
          {block.type == BlockType.glue && (() => {
            return (
              <div contentEditable={false}>
                <GlueBlock block={block} entity={args.entity} />
              </div>
            )
          })()}
  
          {!fullBlocks.find(block.type) && (
            <>
              {block.id && (
                <span 
                  draggable
                  onDragStart={(e) => {
                    e.dataTransfer.setData('text/plain', block.id);
                  }}
                  className="entityDot"  contentEditable={false}
                
                  onClick={() => {
                    onClickEntityDoc(block.id);
                  }}
                />
              )}
              {block.dataBinding && (
                <span 
                  className="entityDot dataBindingDot" draggable contentEditable={false}
                
                  onClick={() => {
                    onClickEntityDoc(block.id);
                  }}
                />
              )}

              {block2.hasChildren() && <div contentEditable={false} className="toggle"
              style={{
                left: -19 - (args.childPadding || 25)*depth,
              }}
                onMouseDown={(e) => {
                  if (e.shiftKey) {
                    let allCollapsed = true;
                    for (const child of block.children) {
                      if (!child.collapsed) {
                        allCollapsed = false;
                        break;
                      }
                    }
                    if (allCollapsed) {
                      for (const child of block.children) {
                        child.collapsed = false;
                      }  
                    }
                    else {
                      for (const child of block.children) {
                        child.collapsed = true;
                      }  

                    }

                  }
                  else {
                    block.collapsed = !block.collapsed;
                  }
                  this.forceUpdate();
                  e.stopPropagation();
                  e.preventDefault();
                }}
               />}

              {(checkbox || block.type == 'checkItem') && <CheckBox dataValuePoint={checkbox?._id} checkbox={checkbox} block={block} _onBeforeChange={beforeChange} _onChange={changed} />}
  
              <RenderData
                __reactive={false}
                ref={renderDataRef?.(block._id)}
                tag="div"
                attrs={{
                  className: 'editor',
                  'data-type': "blockData",
                  style: {
                    textAlign: block.textAlign,
                  }
                }}
                ctx={ctx}
                // data={block2['contentGetter']}
                // data={() => block2.getContent()}
                data={() => block2.getContent()}
                args={{
                  blockId: block._id,
                  pageId: args.docId,
                  obj: args.obj,
                }}
                highlighter={args.highlighter && ((el, pos) => {
                  args.highlighter(el, block._id, pos);
                })}
              />
              {meta.length > 0 && (
                <div className="meta" style={{display:'none'}} contentEditable={false}>
                  {meta}
                </div>
              )}
              {block.id && (
                <div className="metaLine" contentEditable={false}>
                  <EntityPath entity={block.id} />
                </div>
              )}
            </>
          )}
        </div>

        {!block2.isCollapsed() && !noChildren && (
          <div className="children" data-type="blockChildren">
            {block2.hasChildren() && block2.getChildren().map(b => renderBlock(b, {
              onMouseDownGrip,
              draggingId,
              changed,
              activeBlock,
              beforeChange,
              onClickAddBlock,
              onContextMenu,
              ctx,
              systemCtx,
              renderDataRef,
              depth: depth + 1,
            }, args))}
          </div>
        )}
      </Wrapper>
    );
  }

  shouldComponentUpdate(nextProps: Readonly<{ depth: any; args: any; block2: MyBlock; systemCtx: any; draggingId: any; activeBlock: any; onMouseDownGrip: any; onContextMenu: any; beforeChange: any; changed: any; renderDataRef: any; ctx: any; noChildren: any; onClickAddBlock: any; }>, nextState: Readonly<{}>, nextContext: any): boolean {
    return false;
  }
}

export function renderBlock(
  block2: MyBlock,
  {
    depth,
    onMouseDownGrip,
    draggingId,
    beforeChange,
    changed,
    activeBlock,
    noChildren = false,
    onClickAddBlock,
    onContextMenu,
    ctx,
    systemCtx,
    renderDataRef = undefined,
  },
  args: {
    docId
    obj
    onClickEntityDot,
    onClickGrip?
    entity?
    margin?
    highlighter?
    scriptBadgesArgs?
    meta?
    childPadding?
  }
) {
  return <BlockComponent
    key={block2.getId()}
    depth={depth}
    activeBlock={activeBlock}
    args={args}
    beforeChange={beforeChange}
    block2={block2}
    changed={changed}
    ctx={ctx}
    draggingId={draggingId}
    noChildren={noChildren}
    onClickAddBlock={onClickAddBlock}
    onContextMenu={onContextMenu}
    onMouseDownGrip={onMouseDownGrip}
    renderDataRef={renderDataRef}
    systemCtx={systemCtx}
  />

}
