import React from 'react';
import { Component } from 'react';
import cx from 'classnames';
import { component, styled } from './component2';
import { XInit, XObject, XTouch } from './XObject';
import { showContextMenu } from './helpers';
import { genDocumentation } from '../ide/genDocumentation';
import { PropertyField } from './components/PropertyField';
import { ScriptWrapper, createScriptWrapper } from './MyNotionDocument/ScriptWrapper';
import { ScriptEditor } from './MyNotionDocument/ScriptEditor';
import { renderDesignComponent } from './renderDesignComponent';


@component
export class DesignComponentEditor extends Component<{ obj: {
  styles
  root
}, state }> {
  static styles = styled.div`
    /* position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; */

    >.top {
    display: flex;

    >.left {
      width: 50%;
    }

    >.right {
      width: 50%;
      max-height: 300px;
      overflow: auto;
    }
  }

    .canvas {
      background-color: white;
      .frame {
        /* outline: 1px solid black; */
        margin: 16px;
        position: relative;
        &:before {
          content: '';
          z-index: -1;
          position: absolute;
          top: -3px;
          bottom: -3px;
          left: -3px;
          right: -3px;
          border: 1px solid #e1e1e1
        }
      }

      .__active {
        outline: 1px solid blue;
      }
    }


    .tree {

      border-right: 1px solid black;

      .el {
        &.active {
          > .name {
            font-weight: bold;
          }
        }
      }
    }

    .inspector {

      border-left: 1px solid black;
    }




  `;

  scriptWrapper: ScriptWrapper

  constructor(props) {
    super(props);
    this.scriptWrapper = createScriptWrapper({
      blocks: () => XObject.get(this.props.obj, 'styles', []),
      setBlocks: b => this.props.obj.styles = b,
    })
  }

  state = XInit(class {
    editor = {}
  });

  render() {
    const { state } = this.props;
    const frameDimensions = {
      width: 300,
      height: 300,
    }
    const root = XObject.get(this.props.obj, 'root', XObject.obj());

    const renderElSidebar = (el, parent) => {
      if (el.type == 'text') {
        return (
          <div className={cx('el', 'text', { active: state.activeEl == el._id })} key={el._id}
          onClick={() => {
            state.activeEl = el._id;
          }}
          onContextMenu={e => {
            e.preventDefault();
            showContextMenu(e, [
              parent && { text: 'Delete', onClick: () => {
                const index = parent.children.indexOf(el);
                if (index != -1) {
                  parent.children.splice(index, 1);
                }
              } },

            ])
          }}
          >
            <span className="name">text</span>
          </div>
        )
      }
      if (el.type == 'slot') {
        return (
          <div className={cx('el', 'slot', { active: state.activeEl == el._id })} key={el._id}


          >
            <span className="name"
                      onContextMenu={e => {
                        e.preventDefault();
                        showContextMenu(e, [
                          parent && { text: 'Delete', onClick: () => {
                            const index = parent.children.indexOf(el);
                            if (index != -1) {
                              parent.children.splice(index, 1);
                            }
                          } },
                          { text: 'Add child', 
                          onClick: () => {
                            XObject.push(el, 'children', XObject.obj({
                              children: [],
                            }))
                          } },
                          {
                            text: 'Add text node',
                            onClick: () => {
                              XObject.push(el, 'children', XObject.obj({
                                type: 'text',
                              }))
                            },
                          },
                          {
                            text: 'Add svg node',
                            onClick: () => {
                              XObject.push(el, 'children', XObject.obj({
                                type: 'svg',
                              }))
                            },
                          },
                        ])
                      }}
            onClick={() => {
              state.activeEl = el._id;
            }}
            >slot.{el.name}</span>

            {el.children?.length > 0 && (
            <ul>
              {el.children.map(child => (
                <li key={child._id}>
                  {renderElSidebar(child, el)}
                </li>
              ))}
            </ul>
          )}
          </div>
        )
      }
      if (el.type == 'svg') {
        return (
          <div className={cx('el', 'svg', { active: state.activeEl == el._id })} key={el._id}
          onClick={() => {
            state.activeEl = el._id;
          }}
          onContextMenu={e => {
            e.preventDefault();
            showContextMenu(e, [
              parent && { text: 'Delete', onClick: () => {
                const index = parent.children.indexOf(el);
                if (index != -1) {
                  parent.children.splice(index, 1);
                }
              } },


            ])
          }}
          >
            <span className="name">svg</span>
          </div>
        )
      }
      return (
        <div className={cx('el', { active: state.activeEl == el._id })} key={el._id}>
          <span className={cx('name')}
            onClick={() => {
              state.activeEl = el._id;
            }}
            onMouseOver={() => state.hoverEl = el._id}
            onContextMenu={e => {
              e.preventDefault();
              showContextMenu(e, [
                { text: 'Add child', 
                onClick: () => {
                  XObject.push(el, 'children', XObject.obj({
                    children: [],
                  }))
                } },
                { text: 'Add after', 
                onClick: () => {
                  const index = parent.children.indexOf(el);
                  if (index != -1) {
                    parent.children.splice(index + 1, 0, XObject.obj({ children: [] }));
                  }
                } },
                { text: 'Add before', 
                onClick: () => {
                  const index = parent.children.indexOf(el);
                  if (index != -1) {
                    parent.children.splice(index, 0, XObject.obj({ children: [] }));
                  }

                } },
                {
                  text: 'Add text node',
                  onClick: () => {
                    XObject.push(el, 'children', XObject.obj({
                      type: 'text',
                    }))
                  },
                },
                {
                  text: 'Add slot',
                  onClick: () => {
                    XObject.push(el, 'children', XObject.obj({
                      type: 'slot',
                    }))
                  },
                },
                {
                  text: 'Add svg node',
                  onClick: () => {
                    XObject.push(el, 'children', XObject.obj({
                      type: 'svg',
                    }))
                  },
                },
                parent && { text: 'Delete', onClick: () => {
                  const index = parent.children.indexOf(el);
                  if (index != -1) {
                    parent.children.splice(index, 1);
                  }
                } },
  
              ])
            }}
          >div{el.class && `.${el.class}`}</span>
          {el.children?.length > 0 && (
            <ul>
              {el.children.map(child => (
                <li key={child._id}>
                  {renderElSidebar(child, el)}
                </li>
              ))}
            </ul>
          )}
        </div>
      );
    }

    const getNode = (id, obj=root) => {
      if (id == obj._id) return obj;
      else if (obj.children) {
        for (const child of obj.children) {
          const r =  getNode(id, child);
          if (r) return r;
        }
      }
    }

    XTouch(this.props.obj);
    return (
      <>


    <div className="top">
      <div className="left">
      <div className="canvas">
          <div className="frame"
            style={{
              width: frameDimensions.width,
              height: frameDimensions.height,
            }}
          >
            {renderDesignComponent(this.props.obj, {
              classes: el => {
                return { __active: el._id == state.hoverEl && state.showOutline }
              }
            })}
          </div>




        </div>
      </div>
      <div className="right">
      <div className="tree"
          onMouseOver={() => {
            state.showOutline = true;
          }}
          onMouseOut={() => {
            state.showOutline = false;
          }}
        >
          <ul>
            <li>{renderElSidebar(root, null)}</li>
          </ul>
          
        </div>

        <div className="inspector" key={state.activeEl}>
          {(() => {
            if (state.activeEl) {
              const node = getNode(state.activeEl);
              if (!node) return;

              if (node.type == 'text') {
                return (
                  <div>
                    <PropertyField object={node} property="content" />
                  </div>
                )  
              }
              else if (node.type == 'svg') {
                return (
                  <div>
                    <PropertyField object={node} property="url" />
                  </div>
                )
              }
              else if (node.type == 'slot') {
                return (
                  <div>
                    <PropertyField object={node} property="name" />
                  </div>
                )
              }
              else {
                const properties = XObject.get(node, 'properties', {});

                const propertyDefs: any[] = [
                  [
                    { name: 'display' },
                    { name: 'width' },
                    { name: 'height' },
                    { name: 'padding' },
                    { name: 'margin' },

                    { name: 'background-color' },
                    { name: 'background-image', type: 'image' },
                    { name: 'background-size' },
                    { name: 'background-position' },
                    { name: 'background-repeat' },
                    { name: 'border-radius' },
                    { name: 'color' },
                  ],
                  [
                    { if: { display: 'flex' } },
                    { name: 'justify-content' },
                    { name: 'align-items' },
                  ]
                ]

                return (
                  <>
                  Class: <PropertyField object={node} property="class" />
                  <div>
                  {propertyDefs.map(x => {
                  let props;
                  if (x[0].if) props = x.slice(1);
                  else props = x;
                  return (
                    <div key={JSON.stringify(x)}>
                      {props.map(prop => {
                        return (
                          <div key={prop.name}>{prop.name}: <input type="text" defaultValue={properties[prop.name]} onChange={e => properties[prop.name] = e.target.value} /></div>
                        )
                      })}
                    </div>
                  )
                })}
                </div>
                  </>
                )
              }

            }
            else {
              return '';
            }


          })()}
        </div>
      </div>

      </div>

      <ScriptEditor
            scriptWrapper={this.scriptWrapper}
            state={this.state.editor}
          />

      </>
    )
  }
}
