import React from 'react';
import ReactDOMClient from "react-dom/client";
import jQuery from 'jquery';
import { Menu } from '../notionDocument/Menu';

// const ctx = {
//   types: types,
// }
export interface MenuType {
  closeMenu();
  up();
  down();
  setFilter(filter);
  enter();
}

export type MenuPresenter = (args: {
  type;
  onChooseAction;
  left;
  top;
  menuIniters;
  onSelectAction?;
  ctx;
  menuPos;
  value;
  setValue;
  actionChosen;
}) => MenuType;

export const presentMenu: MenuPresenter = args => {
  const cont = jQuery('<div />').css({
    position: 'absolute',
    left: args.left,
    top: args.top,
    zIndex: 9999999,
  });
  jQuery('body').append(cont);
  const root = ReactDOMClient.createRoot(cont[0]);
  let instance: Menu;

  setTimeout(() => {
    if (cont.offset().top + cont.outerHeight() > jQuery(window).height()) {
      cont.css({
        top: '',
        bottom: jQuery(window).height() - args.top + 20,
      })
    }
  }, 10);


  root.render(
    <Menu
      ref={m => {
        if (m) {
          instance = m;
        }
      }}
      type={args.type}
      menuIniters={args.menuIniters}
      onChooseAction={args.onChooseAction}
      onSelectAction={args.onSelectAction}
    />
  );



  return {
    closeMenu() {
      return new Promise<void>(resolve => {
        instance.close(() => {
          root.unmount();
          cont.remove();
          resolve();
        });
      });
    },
    enter() {
      return instance.enter();
    },
    down() {
      instance.down();
    },
    up() {
      instance.up();
    },
    setFilter(filter) {
      instance.setFilter(filter);
    },
  };
};
