import { XObject } from './XObject';

export function getComponentReferences(blocks) {
  const componentReferences = [];
  const iterBlocks = (blocks) => {
    for (const block of blocks) {
      if (XObject.isArray(block.data)) {
        for (const el of block.data) {
          if (el[1] == 'component') {
            componentReferences.push(el[0]);
          }
        }
      }
      if (block.children) {
        iterBlocks(block.children);
      }
    }
  };

  iterBlocks(blocks);
  return componentReferences;
}
