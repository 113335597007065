import { styled } from "../../component";
import { mapStructure } from "../main";
import { structRenderers } from "../structRenderers";
import { typeRegistry } from "../typeRegistry.1";


export const $Link = typeRegistry.registerType({
  $: 'b3816a95-2bbe-5ab6-9691-396da58bcd6a',
  Content: '89ba1f78-037b-5150-b15f-0c1ca3ea0f4d',
}, ids => ({
  _id: ids.$,
  name: 'Link',
  definition: [
    {
      id: ids.Content,
      name: 'Content',
      type: [],
      property: 'content',
    }
  ],
}));


const Wrapper = styled.div`
  border: 1px solid black;
`;

structRenderers[$Link.$] = (value) => {
  return (
    <Wrapper data-value-point={value._id}>
      
    </Wrapper>
  )
}