import { css } from "./component";


export const baseCoreStyles = css`
  color: #4a4a4a;
  font-family: "Inter UI", "SF Pro Display", -apple-system, "system-ui", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: auto;
`;

export const baseStyles = css`
  font-size: 12px;
  line-height: 1.5;

  ${baseCoreStyles}

  input[type="text"], select {
    border: 1px solid #dbdbdb;
  }
`;
