import React, { Component } from "react";
import _ from 'lodash';
import { component, styled } from "../../component";
import { XInit } from "../../XObject";
import classNames from "classnames";
import { baseStyles } from "../../baseStyles";

const MenuWrapper = styled.div`
  ${baseStyles}
  width: 324px;
  border-radius: 4px;
  background: white;
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
  overflow: auto;
  padding: 6px 0;
  transition: opacity .1s linear, transform .1s linear;

  transform-origin: top left;
  z-index: 999999999;

  max-height: 400px;

  &.hidden {
    opacity: 0;
    transform: scale(.9);
  }

  &.visible {
    opacity: 1;
    transform: scale(1);
    animation-name: bounce;
    animation-duration: 0.3s;
  }

  &.closing {
    opacity: 0;
    transform: scale(.9);
  }


  @keyframes bounce {
    20% {
      transform: scale(1.01);
    }
    
    50% {
      transform: scale(0.99);
    }
    
    100% {
      transform: scale(1);
    }
  }

  .section {
    + .section {
      border-top: 1px solid #eeeeee;
      padding-top: 8px;
      margin-top: 8px;
    }
    .title {
      color: #b4b4b4;
      padding: 0 16px;
      display: block;
      font-size: 10px;
      margin-bottom: 4px;
      font-weight: 500;
    }
  }

  .item {
    margin: 0 4px;
    user-select: none;
    transition: background 20ms ease-in 0s;
    cursor: pointer;
    border-radius: 3px;
    &.active {
      background: #f3f3f3;
    }
    min-height: 28px;
    display: flex;
    align-items: center;
    padding: 0 14px;
  }
`;

type MenuOption = {
  key: string;
  label: string;
  filterText?: string;
  action: Function;
  section?: string;
};

@component
export class Menu extends Component<{ onMount?, onUpdate?, type; menuIniters; onChooseAction; onSelectAction?; hideEmpty? }> {
  state = XInit(class {
    index = 0;
    filter = '';
    hoverIndex;
    state = 'hidden';
  });
  down() {
    const options = this.filtererOptions();
    this.state.index = (this.state.index + 1) % options.length;
    this.props.onSelectAction?.(options[this.state.index]);
  }
  up() {
    const options = this.filtererOptions();
    this.state.index = (this.state.index - 1 + options.length) % options.length;
    this.props.onSelectAction?.(options[this.state.index]);
  }
  filtererOptions(): MenuOption[] {
    return this.preFiltered() ? this.options() : this.options().filter(o => (o.filterText || o.label).toLowerCase().includes(this.state.filter.toLowerCase()));
  }
  setFilter(filter) {
    if (filter != this.state.filter) {
      this.state.filter = filter;
      this.state.index = 0;
      const options = this.filtererOptions();
      this.props.onSelectAction?.(options[this.state.index]);
    }
  }
  enter() {
    const option = this.filtererOptions()[this.state.index];
    return option;
  }
  close(cb) {
    this.state.state = 'closing';
    setTimeout(() => {
      cb();
    }, 100);
  }

  preFiltered() {
    return _.isFunction(this.props.menuIniters[this.props.type]);
  }

  options(): MenuOption[] {
    if (this.preFiltered()) {
      return this.props.menuIniters[this.props.type](this.state.filter);
    }
    else {
      return this.props.menuIniters[this.props.type];
    }
  }

  constructor(props) {
    super(props);
  }

  componentDidMount(): void {
    setTimeout(() => {
      this.state.state = 'visible';
    }, 50);

    this.props.onMount?.();
  }

  componentDidUpdate(prevProps: Readonly<{ onMount?: any; onUpdate?: any; type: any; menuIniters: any; onChooseAction: any; onSelectAction?: any; hideEmpty?: any; }>, prevState: Readonly<{}>, snapshot?: any): void {
    this.props.onUpdate?.();
  }

  render() {
    const filteredOptions = this.filtererOptions();
    const index = this.state.index;
    if (this.props.hideEmpty && !filteredOptions.length) return null;
    const hasSections = this.options().find(o => o.section);
    if (hasSections) {
      const grouped = {};
      for (const op of filteredOptions) {
        const section = op.section || '';
        if (!grouped[section]) {
          grouped[section] = [];
        }
        grouped[section].push(op);
      }

      let i =0;
      return (
        <MenuWrapper className={this.state.state}>
          {Object.keys(grouped).map(title => {
            return (
              <div className="section" key={title}>
                <span className="title">{title}</span>
                <div className="options">
                {grouped[title].map((o) => {
                  const ii = i;
                  ++ i;
                  return (
                    <div
                      key={o.key}
                      className={classNames("item", { active: ii == index })}
                      onMouseEnter={() => this.state.index = ii}
                      onMouseLeave={() => this.state.index = null}
                      onClick={() => {
                        this.close(() => {
                          this.props.onChooseAction(o);
                        });
                      }}
                    >
                      {o.label}
                    </div>
                  );

                })}

                </div>
              </div>
            )
          })}

        </MenuWrapper>
      );
    }
    else {
      return (
        <MenuWrapper className={this.state.state}>
          {filteredOptions.map((o, i) => (
            <div
              key={o.key}
              className={classNames("item", { active: i == index })}
              onMouseEnter={() => this.state.index = i}
              onMouseLeave={() => this.state.index = null}
              onClick={() => {
                this.close(() => {
                  this.props.onChooseAction(o);
                });
              }}
            >
              {o.label}
            </div>
          ))}
        </MenuWrapper>
      );
    }

  }
}
