import React, { Component } from 'react';
import { component } from './component2';
import { XTouch, x } from './XObject';
import { componentInstanceDiagScopes, getComponentInstanceDiag } from './shorthand/componentInstanceDiag';


@component
export class ComponentDiag extends Component<{ id; }> {
  render() {
    const diag = getComponentInstanceDiag(this.props.id);
    if (diag) {

      if (diag.comp?.state) {
        const state = diag.comp?.state;
        XTouch(state);
        return (
          <div className="stylesBlock">
            <div className="componentName">State</div>
            <ul>
              {Object.keys(x(state)).map(name => {
                return (
                  <li key={name}>
                    {name}: {JSON.stringify(x(state[name]))}
                  </li>
                );
              })}
            </ul>
          </div>
        );

      }
      if (diag.vars) {
        return (
          <ul>
            {Object.keys(x(diag.vars)).map(name => {
              const scope = componentInstanceDiagScopes[this.props.id + name];
              XTouch(scope[name]);
              return (
                <li key={name}>
                  {name}: {JSON.stringify(x(scope[name]))}
                </li>
              );
            })}
          </ul>
        );
      }
    }
  }
}
