import { Component } from 'react';
import { component } from '../component2';
import { X, XClone } from '../XObject';
import { styled } from '../component';
import { appState } from '../etc/appState';
import classNames from 'classnames';
import { codaIcons } from '../codaIcons';


@component
export class Reaction extends Component<{
  icon;
  get;
  set;
}> {
  static styles = styled.div`



    /* &.reaction { */
      background-color: rgb(255, 255, 255);
      box-shadow: 0 1px 2px 0 rgba(0,0,0,.06);
      border: 1px solid rgb(224, 224, 224);
      border-radius: 6px;
      cursor: pointer;
      padding: 3px 6px;
      transition: background-color .15s ease,border-color .15s ease,color .15s ease,box-shadow .15s ease;
      font-weight: 500;
      font-size: 12px;
      display: inline-flex;
      align-items: center;
      box-sizing: border-box;

      &.reacted {
        border: 2px solid rgb(15, 95, 175);
      }

      height: 28px;



      .icon {
        display:inline-block;
        width: 16px;
        height: 16px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 4px;
      }

      &:hover {
        border-color: rgb(198, 198, 198);
        box-shadow: 0 2px 10px -1px rgba(0,0,0,.12);
      }

    /* } */
  `;

  render(Container?) {
    const { icon, get, set } = this.props;
    const reacted = get()?.includes?.(appState.user);
    return (
      <Container

        className={classNames({
          reacted
        })}



        onClick={() => {
          const users: any[] = XClone(get()) || [];
          const index = users.indexOf(appState.user);
          if (index != -1) {
            users.splice(index, 1);
          }
          else {
            users.push(appState.user);
          }
          set(X(users));
        }}
      >
        {icon && <span className="icon"
          style={{
            backgroundImage: `url(${codaIcons.find(i => i.name == icon).url('color')})`,
          }} />} {get()?.length}
      </Container>
    );
  }
}
