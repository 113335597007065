import React, { Component } from 'react';
import { component } from './component2';
import { styled } from './component';
import { ColorPicker } from './ColorPicker';


@component
export class ColorEditor extends Component<{ color; }> {
  static styles = styled.div`
    padding: 8px;
    border: 1px solid #d2d2d2;
    border-radius: 3px;

    background-color: white;
    .name {
      margin-bottom: 8px;
      width: 100%;
      box-sizing: border-box;
    }

    .sketch-picker {
      padding: 0 !important;
      box-shadow: none !important;
      border: none !important;
    }

  `;
  static debounce = true;

  render() {
    return (
      <>
        <input className="name" type="text" value={this.props.color.name} onChange={e => {
          this.props.color.name = e.target.value;
        }} />
        <ColorPicker
          color={this.props.color.color}
          onColorChange={color => this.props.color.color = color}
        />
      </>
    );
  }
}
