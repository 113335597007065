import _ from 'lodash';
import { execFormulaFromData } from '../../shorthandEditor/execFormulaFromData';
import { ValueType } from "../ValueType";
import { evaluate, mapStructure } from "../main";
import { structRenderers } from "../structRenderers";
import { typeRegistry } from "../typeRegistry.1";
import { executeEventOccurencesByEvent } from './executeEventOccurencesByEvent';
import { $EntityQuery, doEntityQuery } from './$EntityQuery';
import { ObjectRefClass, ObjectType } from '../../types/ObjectRef';
import { NotionDatabase } from '../../components/notionDatabase/NotionDatabase';
import { Column, ColumnManager, Row, RowManager } from '../../components/notionDatabase/ColumnManager';
import { CellType } from '../../components/notionDatabase/CellType';




export const $EventOccurrencesByEvent = typeRegistry.registerType({
  $: '31d83b80-7966-521b-8a38-1cbd366e4c1b',
  Event: '99e07323-899b-5efc-ae9b-88f291009a77',
}, ids => ({
  _id: ids.$,
  name: 'Event Occurrences By Event',
  definition: [
    {
      id: ids.Event,
      name: 'Event',
      type: [],
      property: 'event',
    }
  ],
}));

const $Column = typeRegistry.registerType({
  $: 'dc1f5591-7cc9-599f-a28f-1463710e8eb7',
  Title: 'c8169672-ef0b-5358-ac69-627309a615f3',
  CellGetter: 'e78d73ee-b073-5acd-8f29-8aed2020d507',
  CellSetter: '22738f7d-c197-56cc-8b9e-f763265db480',
}, ids => ({
  _id: ids.$,
  name: '[Table] Column',
  definition: [
    {
      id: ids.Title,
      name: 'Title',
      type: [],
      property: 'title',
    },
    {
      id: ids.CellGetter,
      name: 'Cell Getter',
      type: [],
      property: 'cellGetter',
    },
    {
      id: ids.CellSetter,
      name: 'Cell Setter',
      type: [],
      property: 'cellSetter',
    },
  ],
}));

const $ColumnRepeater = typeRegistry.registerType({
  $: 'b1493410-3b30-5a28-92b7-4fe19030ae3f',
  Input: 'b1d026fb-988d-5401-a8f2-273482a6444d',
  ColumnTitle: '4e3599e8-3212-5a6b-884d-c4ae95543c0c',
  CellGetter: '3ab7d3e1-d9d1-5aad-8268-04f520190309',
  CellSetter: '403efbb4-7c83-5789-83fe-77dbaaecf85b',
}, ids => ({
  _id: ids.$,
  name: '[Table] Column Repeater',
  scope: [
    {
      id: '3a953e79-8727-531d-b525-b5cb07e71a53',
      name: '%Col',
    },
  ],
  definition: [
    {
      id: ids.Input,
      name: 'Input',
      type: [],
      property: 'input',
    },
    {
      id: ids.ColumnTitle,
      name: 'Column Title',
      type: [],
      property: 'columnTitle',
    },
    {
      id: ids.CellGetter,
      name: 'Cell Getter',
      type: [],
      property: 'cellGetter',
    },
    {
      id: ids.CellSetter,
      name: 'Cell Setter',
      type: [],
      property: 'cellSetter',
    },
  ],
}));

export const $Table = typeRegistry.registerType({
  $: '8d2e0a2f-4657-593c-a325-9aebba200e82',
  Columns: 'f76d34de-f03f-5aeb-99f3-90a45b4c18db',
  Rows: 'ef093d53-65ac-55fd-9a20-ec8d0268546b',
}, ids => ({
  _id: ids.$,
  name: 'Table',
  definition: [
    {
      id: ids.Columns,
      name: 'Columns',
      type: [ValueType.Array, []],
      property: 'columns',
      scope: [
        {
          id: 'ca19c404-61f3-5325-acaf-232404aa7ed1',
          name: '%Row',
        },
        // {
        //   id: '3a953e79-8727-531d-b525-b5cb07e71a53',
        //   name: '%Col',
        // },
      ],
    },
    {
      id: ids.Rows,
      name: 'Rows',
      type: [],
      property: 'rows',
    },
  ],
}));

export class MyColManager extends ColumnManager {
  constructor(private columnsDefs) {
    super();
  }

  canAddDeleteColumns = false;
  addColumn(): void {
    
  }

  columns(): Column[] {
    return this.columnsDefs.map((colDef, i) => new MyCol(colDef, i));
  }

  deleteColumn(id: any): void {
    
  }

  displayValue(col: any, value: any) {
    
  }

  move(oldIndex: any, newIndex: any): void {
    
  }
}

export class MyRowManager extends RowManager {
  constructor(private rowData, private colData) {
    super();
  }

  addRow(props?: any): Row {
    return null;
  }

  canAddDeleteRows: boolean = false;

  deleteRow(id: any) {
    
  }

  rows(): Row[] {
    return this.rowData.map((d, i) => new MyRow(d, this.colData, i));
  }
}

class MyRow extends Row {
  constructor(private rowData, private colData, private index) {
    super();
  }
  id() {
    return this.index;
  }
  setValue(colId: any, value: any) {
    
  }
  value(colId: any, defaultValue?: any, context?: any) {
    return this.colData[colId].getter(this.rowData);
  }
}

class StaticCellType extends CellType {
  constructor(options) {
    super(options);
  }
  clickable(): boolean {
    return true;
  }

  renderValue(value: any, args?: any) {
    return value;
  }

  showOpenButton(): boolean {
    return this.metaData.showOpenButton;
  }
}

class MyCol extends Column {
  constructor(private colDef, private index) {
    super();
  }
  cellObj(row: any): CellType<any> {
    return new StaticCellType({
      showOpenButton: this.colDef.showOpenButton,
    });
  }
  getIcon(): void {
    
  }
  id() {
    return this.index;
  }
  metaData() {
    
  }
  setTitle(value: any): void {
    
  }
  setWidth(value: any): void {
    
  }
  title() {
    return this.colDef.title;
  }
  type() {
    return this.colDef.type;
  }
  width() {
    return this.colDef.width || 100;
  }
}

structRenderers[$Table.$] = (value) => {
  const columns: {
    key
    title
    getter
    setter
  }[] = [];
  const mapped = mapStructure(value);

  // console.log(mapped.columns.content);

  for (const colDef of mapped.columns.content) {
    const colDefMapped = mapStructure(colDef);
    if (colDef.type?.[1] == $Column.$) {
      columns.push({
        key: colDef._id,
        title: evaluate(colDefMapped.title),
        getter: row => {
          return evaluate(colDefMapped.cellGetter, {
            'ca19c404-61f3-5325-acaf-232404aa7ed1': row,
            __env: {
              $row: row,
            }
          });
        },
        setter: () => {},
      });
    }
    else if (colDef.type?.[1] == $ColumnRepeater.$) {
      let value;

      if (colDefMapped.input.type[0] == ValueType.Formula) {
        value = execFormulaFromData(colDefMapped.input.content.formula);  
      }
      else if (colDefMapped.input.type[1] == $EntityQuery.$) {
        value = doEntityQuery(colDefMapped.input).map(x => new ObjectRefClass(ObjectType.entity, x));
      }
      if (colDefMapped.input.type[0] == ValueType.Array) {
        value = evaluate(colDefMapped.input);
      }
      
      let i = 0;
      for (const v of value) {
        columns.push({
          key: colDef._id + i,
          title: evaluate(colDefMapped.columnTitle, {
            '3a953e79-8727-531d-b525-b5cb07e71a53': v,
            __env: {
              $col: v,
            }
          }),
          getter: (row) => {
            // return <button
            //   onClick={() => {
            //     console.log(row, v, evaluate(colDefMapped.cellGetter, {
            //       'ca19c404-61f3-5325-acaf-232404aa7ed1': row,
            //       '3a953e79-8727-531d-b525-b5cb07e71a53': v,
            //       __env: {
            //         $col: v,
            //         $row: row,
            //       }
            //     }));
            //   }}
            // >.</button>
            return evaluate(colDefMapped.cellGetter, {
              'ca19c404-61f3-5325-acaf-232404aa7ed1': row,
              '3a953e79-8727-531d-b525-b5cb07e71a53': v,
              __env: {
                $col: v,
                $row: row,
              }
            });
          },
          setter: () => {},
        });
        ++ i;
      }
    }
  }

  let rows;
  if (mapped.rows.type[1] == $EventOccurrencesByEvent.$) {
    rows = executeEventOccurencesByEvent(mapped.rows);
  }
  else if (mapped.rows.type[0] == ValueType.Formula || mapped.rows.type[0] == ValueType.Array) {
    rows = evaluate(mapped.rows);
  }


  if (0) {
    return (
      <>
        <table border={1}>
          <thead>
            {columns.map(c => {
              return (
                <th key={c.key}>{c.title}</th>
              );
            })}
          </thead>
          <tbody>
            {rows.map(r => {
              const cells = [];
              let i = 0;
              for (const col of columns) {
                cells.push(col.getter(r))
                ++ i;
              }
              return (
                <tr key={r._id}>
                  {cells.map((c, i) => {
                    if (_.isDate(c)) {
                      c = c.toString();
                    }
                    return (
                      <td key={i}>
                        {c}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </>
    )
  }
  else {
    return (
      <NotionDatabase
        columnManager={new MyColManager(columns)}
        rowManager={new MyRowManager(rows, columns)}
      />
    )
  }
}
