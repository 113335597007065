import React, { Component } from 'react';
import { db } from './db';
import { component } from './component2';
import { XObject } from './XObject';
import { Cell } from './components/Cell';
import { EventCellType } from './components/cells';
import { PropertyField } from './components/PropertyField';
import { appState } from './etc/appState';
import { Conjuction, ExpressionEntryType, renderEntry } from './etc/expressionQuery';
import { EXPRESSION_QUERY } from './etc/queryFuncs';
import { ObjectType } from './types/ObjectRef';

@component
export class EventEditor extends Component<{ id; }> {
  render() {
    const event = db.events.findById(this.props.id);
    const events = db.events.filter(event => event.parent.id == appState.currentMode);
    return (
      <>
        Toggl Mapping: <PropertyField object={event} property="togglMapping" />

        <Cell
          cell={new EventCellType({})}
          get={() => event.kind}
          set={v => event.kind = v}
          title="Kind" />

        <h3>Children Types</h3>
        <ul>
          {event.childTypes?.map?.(id => {
            const event = db.events.findById(id);
            return <li key={id}>{event.name}</li>;
          })}
        </ul>
        <select
          onChange={e => {
            // console.log(e.target.value);
            XObject.push(event, 'childTypes', e.target.value);
            e.target.value = '';
          }}
        >
          <option />
          {events.map(event => <option key={event._id} value={event._id}>{event.name}</option>)}
        </select>

        <PropertyField
          object={event}
          property="entityLabel"
        />

        <div>
        {renderEntry(XObject.get(event, 'entityQuery', [EXPRESSION_QUERY, {
                _id: 'root',
                type: ExpressionEntryType.group,
                conjunction: Conjuction.and,
                entries: [],
              }])[1], [{ type: ObjectType.event, id: event._id }], {})}
        </div>
      </>
    );
  }
}
