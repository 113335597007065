import React, { Component } from 'react';
import { db } from './db';
import { component } from './component2';
import { XObject } from './XObject';
import { ObjectDisplay } from './components/ObjectDisplay';
import { ObjectType } from './types/ObjectRef';
import { ObjectPicker } from './components/ObjectPicker';
import { createQuery } from './etc/queryFuncs';

@component
export class CanvasEditor extends Component<{ id; }> {
  render() {
    const canvas = db.canvases.findById(this.props.id);
    return (
      <>
        <div className="tools">
          {canvas.objects?.map?.(obj => {
            return (
              <div key={obj._id}>
                <ObjectDisplay obj={obj.ref} showPath />
              </div>
            );
          })}

          <select
            onChange={e => {
              let ref;
              if (e.target.value == 'page') {
                const obj = XObject.obj();
                db.notionDocuments.push(obj);
                ref = {
                  type: ObjectType.document,
                  id: obj._id,
                };
              }
              else if (e.target.value == 'query') {
                const q = createQuery(null);
                ref = {
                  type: ObjectType.query,
                  id: q._id,
                };
              }

              XObject.push(canvas, 'objects', XObject.obj({
                ref,
                frame: {
                  x: 0,
                  y: 0,
                  width: 100,
                  height: 100,
                }
              }));


              e.target.value = '';
            }}
          >
            <option />
            <option value="page">Page</option>
            <option value="query">Query</option>
          </select>

          <ObjectPicker
            _onSelect={obj => {
              XObject.push(canvas, 'objects', XObject.obj({
                ref: obj,
                frame: {
                  x: 0,
                  y: 0,
                  width: 100,
                  height: 100,
                }
              }));
            }} />
        </div>
      </>
    );
  }
}
