import _ from "lodash";
import { MutationType, X, XObject, x } from "./XObject";
import { collectEntitiesGood } from "./components/collectEntitiesGood";
import { db } from "./db";
import { ObjectRef, ObjectType } from "./types/ObjectRef";
import { getAllEntities } from "./etc/createEntity";

const cacheByObj: {
  [id: string]: {
    entities: string[]; 
  }
} = {}

export const cacheByEntity: {
  [id: string]: {
    [objId: string]: {
      obj: ObjectRef
      references?: string[]
    }
  }
} = X({});



function updateDocCache(doc) {
  if (cacheByObj[doc._id]) {
    for (const entity of cacheByObj[doc._id].entities) {
      if (cacheByEntity[entity]) {
        delete cacheByEntity[entity][doc._id];
      }
    }
  }

  const entities = collectEntitiesGood(doc.content || doc.blocks);

  cacheByObj[doc._id] = {
    entities: _.uniq(entities.map(e => e.entity)),
  };

  for (const { entity, block } of entities) {
    if (!cacheByEntity[entity]) {
      cacheByEntity[entity] = X({});
    }
    cacheByEntity[entity][doc._id] = X({
      obj: { type: ObjectType.document, id: doc._id },
      references: _.uniq(entities.filter(e => e.entity === entity).map(e => e.block)),
    });
  }  
}

function updateEntityNameCache(entity) {
  if (cacheByObj[entity._id]) {
    for (const e of cacheByObj[entity._id].entities) {
      if (cacheByEntity[e]) {
        delete cacheByEntity[e][entity._id];
      }
    }
  }

  if (_.isArray(x(entity.name))) {
    const r: string[] = _.uniq(entity.name.filter(e => e?.[1] == 'entity').map(e => e[0]));

    cacheByObj[entity._id] = {
      entities: r,
    };

    for (const e of r) {
      if (!cacheByEntity[e]) {
        cacheByEntity[e] = X({});
      }
      cacheByEntity[e][entity._id] = X({
        obj: { type: ObjectType.entity, id: entity._id },
      });
    }
  }
}

export function initBacklinkCache(switches={
  documents: true,
  entityNames: true,
}) {
  for (const doc of db.notionDocuments) {
    updateDocCache(doc);
  }
  const timers = {};
  XObject.observe(db.notionDocuments, (change) => {
    clearTimeout(timers[change.el._id]);
    timers[change.el._id] = setTimeout(() => {
      updateDocCache(change.el);
    }, 1000);
  });


  function observeEntity(entity) {
    XObject.observe(entity, 'name', (change) => {
      clearTimeout(timers[entity._id]);
      timers[entity._id] = setTimeout(() => {
        updateEntityNameCache(entity);
      }, 1000);
    });

  }
  for (const entity of getAllEntities()) {
    updateEntityNameCache(entity);
    observeEntity(entity);
  }

  XObject.observe(getAllEntities(), null, (change) => {
    if (change.type === MutationType.insert) {
      observeEntity(change.el);
    }
  });
}
