import { Component } from 'react';
import { component, styled } from './component';
import { x } from './XObject';
import { getObjectParent, objectName } from './components/objectFuncs';
import { ObjectDisplay } from './components/ObjectDisplay';
import { ObjectType } from './types/ObjectRef';
import { makeKey, objectsHierachy } from './components/allObjects';
import { appState } from './etc/appState';
import { explicitInspectObj } from './inspectObj';

@component
class Entry extends Component<{ obj, selected }> {
  render() {
    const { obj } = this.props;
    return (
      <div key={obj.id}
        className={this.props.selected && 'selected'}
      style={{
        fontWeight: appState.focusedObjectInSelectMenu?.id == obj.id ? 'bold' : undefined,
        color: this.props.selected ? 'blue' : undefined,
      }}
        onClick={() => {
          explicitInspectObj(obj);
        }}
      >
        <ObjectDisplay obj={obj} />
      </div>
    )
  }
}

@component
export class ScopeView extends Component<{ obj }> {
  static styles = styled.div`
    /* position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; */
    ${ObjectDisplay} {
      white-space: nowrap;
    }
    .objects {
      display: flex;
      overflow: auto;
      height: 100%;
      .object {
        border-right: 1px solid rgb(204, 204, 204);
        width: 200px;
        flex: 0 0 auto;
        overflow: auto;
        padding: 8px;
        box-sizing: border-box;

        > .header {
          border-bottom: 1px solid rgb(204, 204, 204);
        }
      }
    }
  `;

  constructor(props) {
    super(props);
    this.allObjects = objectsHierachy();
  }

  allObjects

  render() {
    const allObjects = this.allObjects;

    const tree = [];

    let current = this.props.obj;

    while (current) {
      tree.unshift(current);
      current = getObjectParent(current);
    }

    tree.unshift({
      type: ObjectType.global,
    });
    
    return (
      <>
        <div className="objects">
          {tree.map((t, i) => {
            const children = allObjects?.[makeKey(t)] || [];
            return (
              <div className="object">
                <div className="header">
                  <ObjectDisplay obj={t} />
                </div>

                <div>
                  {children.map((c) => {
                    if (!objectName(c)) return;
                    return (
                      <div key={c.id}>
                        <Entry obj={c} selected={tree[i + 1]?.id == c.id} />
                      </div>
                    )
                  })}
                </div>
                
              </div>
            )
          })}
        </div>
      </>
    )
  }
}

// import { FormulaTest } from './FormulaTest';
@component
export class ScopeWindow extends Component<{ window: {
  obj
} }> {
  static styles = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `;
  
  render() {
    return <ScopeView obj={this.props.window.obj} />;
  }
}
