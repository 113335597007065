// const host = 'jonathans-macbook-pro.local';
const host = 'localhost';
export default {
  apiServer: `http://${host}:3700/`,
  wsServer: `ws://${host}:3701/`,
};


// const host = 'stratosphere.ebases.io';
// export default {
//   apiServer: `https://${host}:3710/`,
//   wsServer: `wss://${host}:3711/`,
// };

