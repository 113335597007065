import { OverlayManager } from './OverlayManager';

const overlayManager = new OverlayManager();

export function highlightEls(ids) {

  overlayManager.setOverlays(ids.map(id => {
    return {
      selector: `[data-node-id="${id}"], [data-component-id="${id}"]`,
      styles: {
        outline: '2px solid blue',
      },
    }
  }))

}
