import { db, initLocalStorageDb2 } from './db';
import { indexValuePoints, values } from './glue/main';
import { XObject } from './XObject';
import { memoryAppState } from './etc/appState';
import { indexEdges } from './etc/getEdgesForEntity';
import { initBacklinkCache } from './initBacklinkCache';
import { defaultWorkspace } from './etc/defaultWorkspace';
import { ValueType } from './glue/ValueType';
import { $EntityTemplateSupplier } from './glue/structs/$EntityTemplate';
import { addDevProject, getDevProject } from './DevProject.1';



export async function initDevProjectApp(_db) {
  XObject.get(_db, 'dataObjects', []);
  XObject.get(_db, 'notionDocuments', []);
  XObject.get(_db, 'entityTypes', []);
  XObject.get(_db, 'attributeTypes', []);
  XObject.get(_db, 'queries', []);
  XObject.get(_db, 'modes', []);
  XObject.get(_db, 'entities', []);
  XObject.get(_db, 'edges', []);
  XObject.get(_db, 'tags', []);
  XObject.get(_db, 'pages', []);
  XObject.get(_db, 'stateTypes', []);
  XObject.get(_db, 'workspaces', [XObject.obj()]);
  XObject.get(_db, 'users', [XObject.obj()]);
  initLocalStorageDb2(_db);

  memoryAppState.user = db.users[0]._id;

  const workspace = defaultWorkspace();

  if (!workspace.entityTemplateSupplier) {
    const entityTemplateSupplier = XObject.obj({
      type: [ValueType.Structure, $EntityTemplateSupplier.$],
    });
    values().push(entityTemplateSupplier);
    workspace.entityTemplateSupplier = entityTemplateSupplier._id;
  }


  // initBacklinkCache();
  indexValuePoints();
  indexEdges();

}
