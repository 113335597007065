import React, { Component } from 'react';
import styled from 'styled-components';
import { db } from '../db';
import { PropertyField } from '../components/PropertyField';
import { component } from '../component2';
import { XObject } from '../XObject';
import { defaultWorkspace } from '../etc/defaultWorkspace';
import { Root } from '../components/Root';
import { EntityDisplay } from '../components/EntityDisplay';
import { resolvePath } from '../etc/queryGraph';
import { appState } from '../etc/appState';
import { getEntityById } from '../etc/createEntity';


@component
export class EntityQueuesWindow extends Component<{ window; hideInspectPane?; }> {
  static t = {
    wrapper: styled.div`
      display: flex;
    `
  };
  static styles = styled.div`
    .path {
      font-size: 9px;
      color: gray;
    }
  `;

  render() {
    const { t } = EntityQueuesWindow;
    const workspace = defaultWorkspace();
    // const queue = workspace.queues?.find?.((x) => x._id === this.props.window.queue);
    const queues = workspace.queues?.filter?.((x) => x.entity == this.props.window.entity);
    // sort queues by name
    queues.sort((a, b) => {
      return (a.name || '').localeCompare(b.name || '');
    });
    return (
      <>
        <Root
          hideInspectPane={this.props.hideInspectPane}
          root={{
            children: () => {
              const queue = queues?.find?.((x) => x._id === this.props.window.selectedQueue);
              return queue?.list || [];
            },
            render: (args) => {
              return <t.wrapper>{queues.map(queue => {

                return (
                  <div key={queue._id}>
                    <h3>
                      <PropertyField object={queue} property="name" />
                    </h3>
                    <ul>
                      {queue.list.map(id => {
                        const path = (() => {
                          const g = resolvePath('63e969838f513756f28239f7', queue.entity, id);
                          return g.slice(1);

                        })();


                        return (
                          <li key={id}
                            data-inspect-id={id}

                            data-value={id}
                            onClick={() => {
                              this.props.window.selectedQueue = queue._id;
                              appState.inspecting = {
                                type: 'entity',
                                id,
                              };
                              // args.onInspect(id);
                            }}>
                            <EntityDisplay id={id} root={queue.entity} /> <button
                              onClick={() => {
                                this.props.window.selectedQueue = queue._id;
                                args.onSelectChild(id);
                              }}
                            >&gt;</button>

                            <div className="path">{path && path.map(id => getEntityById(id).name).join(' / ')}</div>


                            {/* {getEntityById(id)?.name} <span
                                                      style={{
                                                        fontSize: '9px',
                                                        color: 'gray',
                                                      }}
                                                    >{renderAttributes(getEntityById(id))}</span>
                                                     */}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}</t.wrapper>;

            },
            width: '800px',
          }}
          drillDownState={XObject.get(this.props.window, 'drillDownState', {})}
          graph={{
            graph: '63e969838f513756f28239f7',
            root: this.props.window.entity,
          }} />
      </>
    );
  }
}
