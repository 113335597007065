import { Component } from 'react';
import { component } from './component2';
import { styled } from './component';
import { XInit } from './XObject';
import { HStack } from './Type';
import { showContextMenu } from './helpers';


@component
export class Labeled extends Component<{ label: string; input; onClear?; isActive? }> {
  static styles = styled.div`
    .label {
      color: gray;


    }
    ${Labeled} .label {
      font-size: 80%;
    }
  `;
  render(Container?) {
    return (
      <Container
        style={{
          backgroundColor: this.props.isActive && '#e3e3e3',
        }}
      >
        <div className="label"
          onContextMenu={e => {
            e.preventDefault();
            showContextMenu(e, [
              {
                text: 'Clear',
                onClick: () => {
                  this.props.onClear?.();
                }
              }
            ])
          }}
        >{this.props.label}</div>
        <div className="input">{this.props.input}</div>
      </Container>
    );
  }
}

@component
export class UnitInput extends Component<{}> {
  state = XInit(class {
    unit = 'auto';
  });
  static styles = styled.div`
    input {
      width: 60px;
    }
    select {
      min-width: 39px;
    }
  `;
  render() {
    return (
      <HStack>
        {this.state.unit != 'auto' && <input type="text" />}
        {this.state.unit != 'px' && <select
          value={this.state.unit}
          onChange={e => this.state.unit = e.target.value}
        >
          <option>unset</option>
          <option>auto</option>
          <option>px</option>
          <option>%</option>
        </select>}
      </HStack>
    );
  }
}

@component
export class ColorPicker extends Component {
  static styles = styled.div`
    width: 18px;
    aspect-ratio: 1;
    border: 1px solid gray;
    background-color: black;
  `;
  render() {
    return (
      ''
    );
  }
}
