import { typeRegistry } from "../typeRegistry.1";

export const $Link = typeRegistry.registerType({
  $: '46479293-822c-5128-9437-16684e53ff42',
  Query: 'e62ee663-bea9-53f7-afbe-a2fd072bf3ec',
  OtherNames: '53e440f5-c902-57e5-8009-4499bb094ebb',
}, ids => ({
  _id: ids.$,
  name: 'Highlighter',
  definition: [
    {
      id: ids.Query,
      name: 'Query',
      type: [],
      property: 'query',
    },
    {
      id: ids.OtherNames,
      name: 'Other Names',
      type: [],
      property: 'otherNames',
    },
    // {
    //   id: ids.Query,
    //   name: 'Query',
    //   type: [],
    //   property: 'query',
    // },
  ],
}));
