import { Component } from 'react';
import { component } from '../component2';
import { db } from '../db';
import { PropertyField } from './PropertyField';
import { ControlType } from '../MyNotionDocument/ControlType';
import { explicitInspectObj } from '../inspectObj';
import { ObjectType } from '../types/ObjectRef';
import { ObjectDisplay } from './ObjectDisplay';


@component
export class IdentifierEditor extends Component<{ id; }> {
  render() {
    const identifier = db.identifiers.findById(this.props.id);
    return (
      <>
        <div>


          Identifier Control Type: <select value={identifier.controlType || ''}

            onChange={e => {
              identifier.controlType = e.target['value'];
            }}
          >
            <option />
            <option value={ControlType.reaction}>Reaction</option>
            <option value={ControlType.checkbox}>Checkbox</option>
          </select>
        </div>

        <div>
          Identifier: <PropertyField object={identifier} property="name" />
        </div>


        <ObjectDisplay
            obj={{
              type: ObjectType.identifier,
              id: identifier._id,
            }}
            showPath
          />
        <button
          onClick={() => {
            explicitInspectObj({
              type: ObjectType.identifier,
              id: identifier._id,
            })
          }}
        >Inspect identifier</button>

        <div>
          Relative: <input type="checkbox" checked={identifier.relative} onChange={e => {
            identifier.relative = e.target.checked;
          }} />
        </div>
      </>
    );
  }
}
