import React from 'react';
import { ObjectRefClass, ObjectType } from './types/ObjectRef';
import { db } from './db';
import { x } from './XObject';
import { FormulaObjectWrapper } from './FormulaObjectWrapper';
import { getToday } from './components/allDays';
import { PaneType } from './types/PaneType';
import { isId } from './helpers';
import { Hooks, execFormula, setGlobals, unwrapString } from './shorthand/formula';
import { getScopeAttrs } from './shorthand/getScopeAttrs';
import juration from './juration';
import { AttributeType } from './components/AttributeType';
import _ from 'lodash';
import { findRefs } from './CompletionsTest';
import { getAllEntities } from './etc/createEntity';
import { execute, getValuePoint, render } from './glue/main';
import { structStyledCont } from './glue/structRenderers';
import { queryGraphBasic } from './etc/queryGraph';
import { El } from './shorthand/El';
import { Component, useContext } from 'react';
import { RouterContext } from './RouterContext';
import _pathMatch from 'path-match';
import { component, css } from './component';
// import { styled } from './component2';
import ContentEditable from 'react-contenteditable';
import { uploadedFileUrl } from './components/FileUpload';
import styled from 'styled-components';

const pathMatch = _pathMatch({
  sensitive: false,
  strict: false,
  end: false,
})



@component
class _ContentEditable extends Component<any> {
  static styles = styled(ContentEditable)`

    &:focus {
      outline: none;
    }
    ${p => {
      return p.placeholder && css`
          &:empty {
            &:before {
              content: '${p.placeholder}';
              color: gray;
            }
          }
      `;
    }}


  `;
  render(Container?) {
    const props = this.props;
    let get, set;

    if (props.__bindings?.value) {
      get = _.isFunction(props.__bindings.value.get) && props.__bindings.value.get || (() => '');
      set = _.isFunction(props.__bindings.value.set) && props.__bindings.value.set || (() => {});
    }
    else {
      get = () => _.isString(props.value) ? props.value  : '';
      set = value => {
        props.onChange?.(value);
      }
    }
  
    return (
      <Container
        onKeyDown={e => {
          if (e.key == 'Enter') {
            props.enter?.(e);
          }
        }}
        data-node-id={props['data-node-id']}
        {...getScopeAttrs(props)}
        placeholder={unwrapString(props.placeholder)}
        className={unwrapString(props.className)}
        html={_.isString(get()) ? get() : ''}
        onChange={e => {
          set(e.target.value);
        }}
      />
    );

  }
}
const Test = _ContentEditable;


/*function _ContentEditable(props) {
  let get, set;

  if (props.__bindings?.value) {
    get = _.isFunction(props.__bindings.value.get) && props.__bindings.value.get || (() => '');
    set = _.isFunction(props.__bindings.value.set) && props.__bindings.value.set || (() => {});
  }
  else {
    get = () => _.isString(props.value) ? props.value  : '';
    set = value => {
      props.onChange?.(value);
    }
  }

  return <ContentEditable
    onKeyDown={e => {
      if (e.key == 'Enter') {
        props.enter?.(e);
      }
    }}
    // data-highlight-id={props['data-highlight-id']}
    data-node-id={props['data-node-id']}
  {...getScopeAttrs(props)} placeholder={props.placeholder} className={props.className} html={_.isString(get()) ? get() : ''} onChange={e => {
    set(e.target.value);
  }} />;
}*/


@component
class Link extends Component<any> {
  static styles = styled.span`
    &:hover {
      text-decoration: underline;
    }
    color: blue;
    cursor: pointer;
    &.active {
      font-weight: bold;
    }
  `
  render(Container?) {
    // return <button
    //   onClick={() => {
    //     console.log(this.props);
    //   }}
    // >.</button>

    const routerContext = useContext(RouterContext);
    return <Container
      className={routerContext?.path == this.props.to && 'active'}
      onClick={() => {
        routerContext.push(this.props.to);
      }}
    >{this.props.children?.[0]}</Container>
  }
}

const Asfd = () => {

}

setGlobals(({env}) => {
  return {
    fileUrl: id => {
      return uploadedFileUrl(id);
    },
    createRef: () => React.createRef(),
    Link,
    TimeSpent(args) {
      const descendants = queryGraphBasic(args.argument, true, false, false).map(e => e.entity);
      const eventOccurrences = [];
      const eo = db.eventOccurrences.filter(eo => {
        return descendants.includes(eo.arguments) && eo.event == args.event// && eo.start.getTime() >= args.start.getTime() && eo.start.getTime() <= args.end.getTime();
      });
      eventOccurrences.push(...eo);

      let totalTime = 0;

      for (const eo of eventOccurrences) {
        totalTime += (eo.stop || new Date()).getTime() - eo.start.getTime();
      }

      return totalTime/1000;

    },
    StyledCont(id) {
      return structStyledCont[id];
    },
    Render(id, args) {
      const params = {};
      const vp = getValuePoint(id);
      for (const param of vp.parameters) {
        params[param._id] = args[param.name];
      }
      // return <button
      //   onClick={() => console.log(params)}
      // >.</button>
      return render(execute(id), params);
    },
    FormatSeconds(input) {
      return juration.stringify(input);
    },
    ParseSeconds(input) {
      return juration.parse(input);
    },
    Format(input, format) {
      if (_.isNil(input)) return '';
      if (isId(format)) {
        const attr = db.attributeTypes.findById(format);
        if (attr.type == AttributeType.duration) {
          return juration.stringify(input);
        }
      }

      return input;
    },
    EntitiesConcat(...args) {
      return [].concat(...x(args));
    },
    GetResourceDef: (resource) => {
      return new FormulaObjectWrapper({
        type: ObjectType.objectHandle,
        id: resource,
      });
    },
    ContentEditable: Test,
    Navigate(obj) {
      console.log(obj, env);

      let config;
      if (obj.type == ObjectType.eventOccurrence) {
        config = {
          type: PaneType.eventOccurrence,
          id: obj.id,
        };
      }

      env.__ctx.navigate(config);
    },
    Days(start, end) {
      return [...db.days.map(d => new ObjectRefClass(ObjectType.day, d._id))].reverse();
    },
    TotalTime: (args: FormulaObjectWrapper[]) => {
      let time = 0;
      for (const el of args) {
        const eo = db.eventOccurrences.findById(el.ref.id);
        time += eo.stop.getTime() - eo.start.getTime();
      }

      return time / 1000;
    },
    EventOccurrences: (args) => {
      const filters = [];

      for (const prop in args) {
        if (prop == 'Event') {
          if (isId(args.Event)) {
            filters.push(eo => eo.event == args.Event);
          }
          else if (args.Event instanceof ObjectRefClass) {
            filters.push(eo => eo.event == args.Event.id);
          }
          else if (args.Event instanceof FormulaObjectWrapper) {
            filters.push(eo => eo.event == args.Event.ref.id);
          }

          else {
            const event = db.events.find(e => e.name == args.Event);
            filters.push(eo => eo.event == event._id);
          }
        }
        else if (prop == 'Argument') {
          filters.push(eo => eo.arguments == args.Argument.id);
        }
        else if (prop == 'Time') {
          const time = args.Time;
          if (time instanceof ObjectRefClass) {
            if (time.type == ObjectType.day) {
              const day = db.days.findById(time.id);
              filters.push(eo => {
                if (eo.timestamp) {
                  if (eo.timestamp < day.start) return false;
                  if (day.end && eo.timestamp > day.end) return false;
                }
                else if (eo.start) {
                  if (eo.start < day.start) return false;
                  if (day.end && eo.start > day.end) return false;
                }
                return true;
              });
            }
          }
        }
      }

      const eos = db.eventOccurrences.filter(eo => {
        for (const filter of filters) {
          if (!filter(eo)) return false;
        }
        return true;

      });

      return eos.map(eo => new FormulaObjectWrapper({ type: ObjectType.eventOccurrence, id: eo._id }));
    },
    Entities: args => {
      return getAllEntities().filter(e => !e.__deleted && e.type == args[0].type.ref.id).map(e => new ObjectRefClass(ObjectType.entity, e._id));
      return args;
    },
    Today() {
      return new ObjectRefClass(ObjectType.day, getToday()._id);
    },
    Entity: id => {
      return new ObjectRefClass(ObjectType.entity, id);
    },
    Event: id => {
      return new ObjectRefClass(ObjectType.event, id);
    },
    FindRefs: findRefs,
    Document: id => {
      return new ObjectRefClass(ObjectType.document, id);
    },

    pathMatch(pattern, url, end=false) {
      return _pathMatch({
        sensitive: false,
        strict: false,
        end: end,
      })(pattern)(url);
    },

    router: (routes) => {
      return () => {
        const routerContext = useContext(RouterContext);
        const url = routerContext.path;
        for (const route of routes) {
          const routeMatch = _pathMatch({
            sensitive: false,
            strict: false,
            end: route.strict,
          })(unwrapString(route.route));
          const params = routeMatch(url);
          if (params) {
            return new El(route.component, [
              ['params', params]
            ]);
          }
        }
        return '';
      }
    }
  }
})
