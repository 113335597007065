import Sugar from 'sugar';
import cx from 'classnames';
import React, { Component } from 'react';
import { component } from './component2';
import { styled } from './component';
import Xarrow from 'react-xarrows';
import jQuery from 'jquery';
import { XInit } from './XObject';

@component
export class TimelineViewWindow extends Component<{ window }> {
  render() {
    return (
      <>
        {/* <TimelineView /> */}
      </>
    )
  }
}

@component
class TimelineItem extends Component<{ item, style, _ref, _onMouseDown, }> {
  static styles = styled.div`
    border: 1px solid #000;
    box-sizing: border-box;

    cursor: ew-resize;

    height: 100%;

    display: flex;
    align-items: center;
    padding: 0 8px;
    white-space: nowrap;
    /* overflow: hidden; */

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 10px;
      cursor: col-resize;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 10px;
      cursor: col-resize;
    }

    box-shadow:  1px 2px 0 rgba(0,0,0,.06);
    border-radius: 6px;
    border: 1px solid rgb(174, 174, 174);
  `;

  render(Container?) {
    return (
      <Container
        style={this.props.style}
        data-id={this.props.item._id}
        ref={this.props._ref}
        onMouseDown={this.props._onMouseDown}
      >
        {this.props.item.title}
      </Container>
    )
  }
}


@component
export class TimelineView extends Component<{
  onClickItem
  items: {
    _id: string;
    readonly start: Date;
    readonly end: Date;
    title: string;
    prerequisites?: string[];

  }[]
  set(id, startTime, endTime)
}> {
  static debounce = false;
  static styles = styled.div`
    display: flex;
    cursor: default;

    .items {
      width: 200px;
      padding-top: 32px;
      border-right: 1px solid #e0e0e0;
      flex: 0 0 auto;


      .item {
        height: 32px;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        padding: 0 8px;


        &.hovered {
          background: #eee;
        }
        &:not(.placed) {
          color: gray;
        }
      }
    }
    .timeline {
      overflow: auto;
    }
    .wrapper {
      /* height: 300px; */
      position: relative;
      ${TimelineItem} {
        position: absolute;
      }
      .timelineHeader {
        height: 32px;
        background-color: #fafafa;


        .timelineHeaderCol {
          height: 32px;
          position: absolute;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          padding: 0 8px;
        }
      }

      .timelineMarkings {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        .timelineMark {
          position: absolute;
          height: 100%;
          top: 0;
          width: 1px;
          background: #f5f5f5;
        }
      }

      .timelineRow {
        height: 32px;
        position: relative;
        margin-bottom: 4px;

        &.hovered {
          background: #eee;
        }
      }
    }
  `;

  manipulationState


  dragging: {
    id, start, end
  }


  /*data: {
    _id: string;
    start: Date;
    end: Date;
    title: string;
    prerequisites?: string[];
    dragging?
  }[] = [
    {
      _id: '1',
      start: Sugar.Date.create('two weeks ago'),
      end: (Sugar.Date.create('two weeks ago') as any).addDays(4),
      title: 'Hello',
    },
    {
      _id: '2',
      start: Sugar.Date.create('one week ago'),
      end: Sugar.Date.create('2 days ago'),
      title: 'world',
      prerequisites: ['1'],
    },
    {
      _id: '3',
      start: Sugar.Date.create('3 weeks ago'),
      end: Sugar.Date.create('2 weeks ago'),
      title: '!',
    },
    {
      _id: '4',
      start: Sugar.Date.create('in 3 weeks'),
      end: Sugar.Date.create('in 4 weeks'),
      title: '!',

    }
  ];*/

  state = XInit(class {
    hover
  })

  render() {
    const data = this.props.items;

    let beginTime, endTime;
    for (const item of data) {
      if (!beginTime || item.start < beginTime) {
        beginTime = item.start;
      }
      if (!endTime || item.end > endTime) {
        endTime = item.end;
      }
    }


    const totalWidth = 1000;

    const markings = [];

    const spacing = 200;

    const totalTime = endTime.getTime() - beginTime.getTime();

    // add proportionate margins
    beginTime = new Date(beginTime.getTime() - totalTime * 0.1);
    endTime = new Date(endTime.getTime() + totalTime * 0.1);

    for (let i = 0; i < totalWidth; i += spacing) {
      const date = new Date(beginTime.getTime() + (i / totalWidth) * (endTime.getTime() - beginTime.getTime()));
      markings.push({
        date,
        x: i,
      });
    }

    const refs = {};

    const translateXToDate = (x) => {
      const date = new Date(beginTime.getTime() + (x / totalWidth) * (endTime.getTime() - beginTime.getTime()));
      return date;
    }

    const translateDateToX = (date) => {
      const x = ((date.getTime() - beginTime.getTime()) / (endTime.getTime() - beginTime.getTime())) * totalWidth;
      return x;
    }

    return (
      <>
        <div className="items">
          {data.map(item => {
            const placed = item.start && item.end;
            return (
              <div className={cx("item", {
                hovered: this.state.hover === item._id,
                placed,
              })}
              
                onClick={() => {
                  console.log('click', item._id);
                  this.props.onClickItem(item._id);
                }}
              key={item._id}>
                {item.title}
              </div>
            )
          })}
        </div>
        <div className="timeline">
        <div
          className="wrapper"
          style={{
            width: totalWidth + 'px',
          }}
        >
          <div className="timelineHeader">
            {markings.map(mark => {
              return (
                <div
                  key={mark.x}
                  className="timelineHeaderCol"
                  style={{
                    left: mark.x + 'px',
                  }}
                >
                  {mark.date.toLocaleDateString()}
                </div>
              )
            })}
          </div>
          <div className="timelineMarkings">
            {markings.map(mark => {
              return (
                <div
                  key={mark.x}
                  className="timelineMark"
                  style={{
                    left: mark.x + 'px',
                  }}
                />
              )
            })}
          </div>
          {data.map(item => {
            refs[item._id] = React.createRef();
            const start = this.dragging?.id == item._id ? this.dragging.start : item.start;
            const end = this.dragging?.id == item._id ? this.dragging.end : item.end;
            

            return (
              <div
                key={item._id}
                className={cx("timelineRow", {
                  hovered: this.state.hover === item._id,
                })}
                onMouseOver={() => {
                  this.state.hover = item._id;
                }}
                onMouseOut={() => {
                  this.state.hover = null;
                }}
                onMouseDown={e => {
                  if (!start && !end) {
                    const relativeX = e.clientX - e.currentTarget.getBoundingClientRect().left;
                    console.log(relativeX);

                    const date = translateXToDate(relativeX);
                    console.log(date);
                    this.props.set(item._id, date, date);
                  }
                }}
              >
                {start && end && <TimelineItem
                  _ref={refs[item._id]}
                  _onMouseDown={e => {
                    const relativeX = e.clientX - refs[item._id].current.getBoundingClientRect().left;
                    console.log(relativeX);

                    e.preventDefault();

                    let handleDrag;

                    if (relativeX < 10) {
                      handleDrag = e => {
                        const dx = e.clientX - a.start.x;
                        const currentPos = translateDateToX(a.start.startTime);
                        const newPos = currentPos + dx;
                        const date = translateXToDate(newPos);
                        // const duration = item.end.getTime() - item.start.getTime();
                        this.dragging = {
                          id: item._id,
                          start: date,
                          end: item.end,
                        };
                        this.forceUpdate();
                      }
                    }
                    else if (relativeX > refs[item._id].current.getBoundingClientRect().width - 10) {
                      handleDrag = e => {
                        const dx = e.clientX - a.start.x;
                        const currentPos = translateDateToX(a.start.endTime);
                        const newPos = currentPos + dx;
                        const date = translateXToDate(newPos);
                        // const duration = item.end.getTime() - item.start.getTime();
                        this.dragging = {
                          id: item._id,
                          start: item.start,
                          end: date,
                        };
                        this.forceUpdate();
                      }
                    }
                    else {
                      handleDrag = e => {
                        const dx = e.clientX - a.start.x;
                        const currentPos = translateDateToX(a.start.startTime);
                        const newPos = currentPos + dx;
                        const date = translateXToDate(newPos);
                        const duration = item.end.getTime() - item.start.getTime();
                        this.dragging = {
                          id: item._id,
                          start: date,
                          end: new Date(date.getTime() + duration),
                        };
                        this.forceUpdate();
                      }
                    }


                    const a = this.manipulationState = {
                      item: item._id,
                      start: {
                        x: e.clientX,
                        startTime: item.start,
                        endTime: item.end,
                      }
                    }

                    jQuery(window).on('mousemove', handleDrag);
                    jQuery(window).one('mouseup', e => {
                      jQuery(window).off('mousemove', handleDrag);
                      this.manipulationState = null;
                      if (this.dragging?.id == item._id) {
                        this.props.set(item._id, this.dragging.start, this.dragging.end);
                        this.dragging = null;
                          
                      }
                      this.forceUpdate();
                    });
                  }}
                  style={{
                    left: ((start.getTime() - beginTime.getTime()) / (endTime.getTime() - beginTime.getTime())) * totalWidth + 'px',
                    width: ((end.getTime() - start.getTime()) / (endTime.getTime() - beginTime.getTime())) * totalWidth + 'px',
                  }}
                  item={item}
                />}

                {item.prerequisites?.length > 0 && (
                  item.prerequisites.map(prerequisite => {
                    return (<Xarrow
                      key={prerequisite}
                      start={refs[prerequisite]}
                      end={refs[item._id]}
                      startAnchor={'right'}
                      endAnchor={'left'}
                      showHead={false}
                    />
                    )
                  })
                )}
              </div>
            )
          })}
        </div>
        </div>
      </>
    )
  }
}
