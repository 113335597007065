import { Component } from 'react';
import { component, styled } from './component';
import Masonry from "react-responsive-masonry";
import { db } from './db';
import { entityDisplayName } from './components/entityDisplayName';
import { ObjectDisplay } from './components/ObjectDisplay';
import { Tag } from './components/Tag';
import { entityTypeName } from './etc/entityTypeName';
import { getAllEntities } from './etc/createEntity';

const Card = styled.div`
  overflow: hidden;
  padding: 8px;
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 2px 4px;
  border-radius: 3px;
  background: white;

  cursor: pointer;  

  transition: background 100ms;


  &:hover {
    background: #f9f9f8;
  }

`;

@component
export class PinBoard extends Component {
  static styles = styled.div`
    padding: 8px;
  `;
  render() {
    return (
      <>
        <Masonry className="masonry" columnsCount={3} gutter="8px">
          {getAllEntities().filter(e => e.pinned).map(e => {
            return <Card key={e._id}>
              {entityDisplayName(e._id)}

              <Tag text={entityTypeName(e.type)} />
              <ObjectDisplay
                obj={e.space}
                showPath />
            </Card>;
          })}
        </Masonry>
      </>
    );
  }
}
