import React, { Component } from 'react';
import styled from 'styled-components';
import { component } from '../component2';
import { Svg } from './Svg';


@component
export class BackButton extends Component<{ onClick; }> {
  static styles = styled.div`
    display: inline-flex;
    cursor: pointer;
    svg {
      transform: rotate(90deg);
    }
  `;
  render(Container?) {
    return <Container onClick={this.props.onClick}><Svg name="chevron" /></Container>
  }
}
const routes = [];
export function registerRoute(func) {
  routes.push(func);
}
export function renderRoute(route, navigation) {
  for (const func of routes) {
    const r = func(route, navigation);
    if (r) {
      return (
        <NavContext.Provider value={navigation}>{r}</NavContext.Provider>
      );
    }
  }
}

export const NavContext = React.createContext(null);

export enum Route {
  viewProperties = 'b35e264a-c7d7-5730-9c74-babf2afc67ce',
  tableView = 'f1f0fc2f-bf3e-5139-ad61-c8a0e067a46e',
  viewFilter = '10d8be7f-91c7-5b26-a779-d3c93e31a51e',
  viewEntityType = '5ecaa4f1-2a11-57b3-9f54-2012329d173c',
  viewEvent = 'adb2e1dc-1463-585f-a9f1-790d9e4f5d05',
  pageBlockEl = 'e8546d98-02a4-5993-8629-ba3ea62d3103',
  documentBlockEl = '380c854e-849e-5cd5-9634-22f1b3556e03',
  object = 'f431ce17-940a-54be-9ff6-76bdae38420d',
}

@component
export class NavTitle extends Component<{ children }> {
  static contextType = NavContext;
  context: any;
  static styles = styled.div`
    display: flex;
    align-items: center;
    ${BackButton} {
      margin-right: 4px;
    }
  `
  render() {
    if (this.context?.hasBack?.()) {
      return <><BackButton onClick={() => this.context.pop()} /> {this.props.children}</>
    }
    return this.props.children;
  }
}
