import * as ohm from 'ohm-js';
import cx from 'classnames';
import _ from 'lodash';
import { styled } from '../component';
import React, { Component } from 'react';
import { X, XClone, XGuard, XObject, x } from '../XObject';
import { callHandlers, formulaAccessorHandlers, objRefHandlers, primitiveHandlers } from './registerFormulaAccessorHandler';
import { grammarDef } from './grammarDef';
import { grammarDefFlex } from "./grammarDefFlex";
import { Data, expandToText } from '../richTextHelpers';
import { CompiledValuePointRef, StructureRef } from '../glue/valuePoints/StructureRef';
import { CompiledValuePoint, GlueViewCompiled, structuresMap } from '../glue/main';
import { ValueType } from '../glue/ValueType';
import { Runtime } from '../glue/Runtime';
import classNames from 'classnames';
import Sugar from 'sugar';

import { RenderRuntime } from '../glue/RenderParams';
import { ScriptComponent } from '../scriptComponents/ScriptComponent';
import { El } from './El';
import { scriptBlockDiagInfo } from './scriptBlockDiagInfo';
import md5 from 'md5';
import { componentInstanceDiagScopes, deleteComponentInstanceDiag, initComponentInstanceDiag, tickComponentInstanceDiag } from './componentInstanceDiag';
import { component } from '../@component';
import { selectorGrammarDef } from './cssGrammarDef';
import { SignalContext } from './SignalContext';
import { FormulaObjectProxy } from '../FormulaObjectProxy';
import { RuntimeContext, RuntimeTrace } from './RuntimeTrace';
import { Block } from '../Node';

export class Thingy {
  constructor(public block, public runtimeContext, public trace) {

  }

  consumed(args, pass) {
    // console.log(this, args, pass);
    return false;
  }


}

export interface Hooks {
  resolveObjectRef?
  accessorHandlers?: {
    test
    perform
  }[]
  types?
  traceStrings?

  captureBlockValue?
}

function map(arg) {
  if (_.isArray(arg)) {
    return {
      _id: null,
      type: [ValueType.Array],
      content: arg.map(map),
    }
  }
  else if (arg instanceof ModdedDict && arg.mod instanceof StructureRef) {
    return createValuePoint(arg.mod.id, arg.dict);
  }
  else {
    return arg;
  }
}

function createValuePoint(structId, props): CompiledValuePoint {
  const content = [];
  const struct = structuresMap[structId];

  for (const name in props) {
    const prop = struct.definition.find(p => p.property == name);
    content.push({
      _id: null,
      prop: prop.id,
      value: map(props[name]),
    })
  }

  return {
    _id: null,
    type: [ValueType.Structure, structId],
    isState: false,
    content,
    presentation: false,
    rt: new Runtime({}),
    isTerminal: false,
  }
}


@component
class Wrap extends Component<any> {
  static debounce = false;
  instanceId
  constructor(props) {
    super(props);
    this.instanceId = md5(Date.now());

  }
  componentDidMount() {
    if (this.props['data-component-block']) {
      if (!scriptBlockDiagInfo[this.props['data-component-block']]) {
        scriptBlockDiagInfo[this.props['data-component-block']] = X({count:0});
      }
      scriptBlockDiagInfo[this.props['data-component-block']].count = scriptBlockDiagInfo[this.props['data-component-block']].count+ 1;
    }
  }
  componentWillUnmount(): void {
    if (scriptBlockDiagInfo[this.props['data-component-block']]) {
      scriptBlockDiagInfo[this.props['data-component-block']].count = scriptBlockDiagInfo[this.props['data-component-block']].count - 1;
    }
  }
  
  render() {
    const props = this.props;

    // console.log(props._func.injectScope);
    const additional = {};
    if (props['data-has-scope']) {
      additional['data-has-scope'] = props['data-has-scope'];
    }
    if (props['data-no-scope']) {
      additional['data-no-scope'] = props['data-no-scope'];
    }

    const scopeKeys = Object.keys(props).filter(prop => prop.startsWith('data-scope-'));
    for (const key of scopeKeys) {
      additional[key] = props[key];
    }


    const r = renderEl(props._func.injectScope ? props._func.injectScope({
      // declareHook: name => console.log(name),
    }, props) : props._func(props), props._rt, {
      'data-script-block-id2': props['data-script-block-id'],
      'data-script-block-ids': props['data-script-block-ids'],
      'data-component-block': props['data-component-block'],
      'data-component-instance': this.instanceId,
      ...additional
    }, undefined, undefined, props['data-pb-path']);

    if (r instanceof Pass) {
      return 'pass';
    }
    else {
      return r;
    }
  }
}

export function transformAttrs(attrs: any[], additionalClasses?) {
  const attributes:any = {};

  try {
    if (attrs) for (const attr of attrs) {
      if (attr instanceof Bindingg) {
        if (attr.args.type == 'signal') {
          if (!attributes.__signals) {
            attributes.__signals = {};
          }
          attributes.__signals[attr.args.name] = attr.args.receiver;  
        }
        else if (attr.args.type == 'deepSignal') {
          if (!attributes.__deepSignals) {
            attributes.__deepSignals = {};
          }
          attributes.__deepSignals[attr.args.name] = attr.args.receiver;  
        }
        else if (attr.args.type == 'binding') {
          if (!attributes.__bindings) {
            attributes.__bindings = {};
          }
          attributes.__bindings[attr.args.name] = attr.args.binding;
        }
      }
      else {
        let [name, value] = attr;
        if (name == 'class') name = 'className';

        if (name == 'className') {
          value = classNames(unwrapStringsDeep(value), unwrapStringsDeep(additionalClasses));
        }
        if (name == 'on click' || name == 'click') {
          name = 'onClick';
        }
        if (name == 'change') {
          name = 'onChange';
        }
        if (name == 'keydown') {
          name = 'onKeyDown';
        }
        if (name == 'html') {
          name = 'dangerouslySetInnerHTML';
          value = {__html:value};
        }
        
        attributes[name] = value;
      }

    }
  }
  catch (e) {
    console.log(e);
  }


  if (additionalClasses) {
    if (attributes.className) {
      attributes.className = cx(unwrapStringsDeep(attributes.className), unwrapStringsDeep(additionalClasses));
    }
    else {
      attributes.className = cx(unwrapStringsDeep(additionalClasses));
    }
  }
  return attributes;
}

export function _hasChildren(tag) {
  if (tag == 'img' || tag?.target == 'img' || tag == 'br' || tag == 'hr' || tag == 'input' || tag?.target == 'input' || tag == 'link') {
    return false;
  }
  return true;

}

export interface RenderElHooks {
  tagHandlers?: {
    test
    render
  }[]
}
export function renderEl(
  el,
  rt: RenderRuntime={},
  extraAttrs={},
  extra?: {additionaClasses?, childrenOverride? },
  hooks:RenderElHooks={},
  path?
) {
  if (React.isValidElement(el)) return el;
  if (el instanceof El) {

    if (hooks?.tagHandlers) {
      for (const handler of hooks.tagHandlers) {
        if (handler.test(el.tag)) {
          return handler.render(el);
        }
      }
    }

    if (el.tag instanceof ScriptComponent) {
      return el.tag.render(el, rt);
    }
    else {
      const { childrenOverride, additionaClasses }=extra || {};

      const attributes = { ...transformAttrs(el.attributes, additionaClasses), ...extraAttrs };

      if ('key' in attributes) {
        attributes.key = unwrapString(attributes.key);
      }

      const wrapFunction = attr => {
        if (_.isFunction(attributes[attr]) && receivesRuntimeTrace(attributes[attr]) && !attributes[attr].wrapped) {
          const onClick = attributes[attr];
          attributes[attr] = function(...args) {
            // return onClick.apply(this, args);
            if (!onClick.instanceId) {
              throw new Error();
            }

            const cs = new RuntimeTrace({
              init: 'event', 
              args: { instanceId: onClick.instanceId },
              instanceId: onClick.instanceId,
            })
            const cb = cs.logFunctionEntry(attributes[attr].blockId, attributes[attr].blockId, args);

            rt.devRuntime.logEvent({
              event: attr,
              trace: cs,
              blockId: onClick.blockId,
              instanceId: onClick.instanceId,
            })

            const r = onClick.call(this, ...args, cb.cs, el.runtimeContext);
            cb.returnValue = r;


            return r;
          }
          attributes[attr]._fromScript = onClick._fromScript;
          attributes[attr].paramCount = onClick.paramCount;
          attributes[attr].blockId = onClick.blockId;
          attributes[attr].instanceId = onClick.instanceId;
          attributes[attr].wrapped = true;
        }
      }

      wrapFunction('onClick');
      wrapFunction('onChange');
      wrapFunction('enter');


      attributes['data-script-block-id'] = el.blockId;

      if (extraAttrs['data-script-block-ids']) {
        attributes['data-script-block-ids'] = extraAttrs['data-script-block-ids'].concat(el.blockId)
      }
      else {
        attributes['data-script-block-ids'] = [el.blockId];
      }

 
      attributes['data-identifier'] = el.identifier;

      if (!attributes['data-key']) {
        attributes['data-key'] = attributes.key;
      }

      if (el.scopeId) {
        attributes[`data-scope-${el.scopeId}`] = true;
        attributes['data-has-scope'] = true;
      }
      else if (!attributes['data-has-scope']) {
        attributes['data-no-scope'] = true;
      }

  
      let hasChildren = true;

      if (!_hasChildren(el.tag)) {
        hasChildren = false;
      }

      if (attributes.key) {
        path = path.concat(`block:${el.blockId}:${attributes.key}`)
      }
      else {
        path = path.concat(`block:${el.blockId}`)
      }

      attributes['data-pb-path'] = path;


  
      const children = hasChildren ? el.children.map(c => renderEl(c, rt, undefined, undefined, hooks, path)) : undefined;
  
      if (el.tag instanceof StructureRef) {
        return <GlueViewCompiled args={{}} compiledValuePoint={createValuePoint(el.tag.id, attributes)} state={{}}  renderRuntime={rt} />;
      }
  
      
  
      let tag = el.tag || 'span';

      if (tag == 'option') {
        if (attributes.value) {
          attributes.value = unwrapString(attributes.value);
        }
      }

      if (tag?.asdfasdf) {
        attributes._rt = rt;
      }
      else if (_.isFunction(tag) && tag?.prototype?.render) {
        attributes._rt = rt;
      }

      else if (_.isFunction(tag)) {
        attributes._func = tag;
        attributes._rt = rt;
        attributes['data-component-block'] = tag['blockId'];
        attributes.key = tag['blockId'];
        tag = Wrap;
      }
      else if (tag instanceof Pass) {
        return <span>Pass</span>
      }

      else if (!_.isString(tag) && !tag.styledComponentId) {
        return <span
          onClick={() => {
            console.log(tag, hooks);
          }}
        >Error</span>;
      }
  
      const r = React.createElement(tag || 'span', attributes, hasChildren ?(children || []).concat(childrenOverride || []) :undefined);
      
      if (attributes.__deepSignals) {
        return (
          <SignalContext.Provider value={attributes.__deepSignals}>
            {r}
          </SignalContext.Provider>
        )
      }
      else {
        return r;
      }
    }
  }
  else if (el instanceof ModdedDict) {
    if (el.mod instanceof StructureRef) {
      return <GlueViewCompiled args={{}} compiledValuePoint={map(el)} state={{}} renderRuntime={rt} />;
    }
  }
  else if (el instanceof CompiledValuePointRef) {
    return <GlueViewCompiled args={el.map} compiledValuePoint={el.value} state={{}} renderRuntime={rt} />;
  }
  else if (_.isArray(el)) {
    return el.map(x => renderEl(x, rt, undefined, undefined, hooks, path));
  }
  else if (_.isString(el) || _.isNumber(el)) {
    return el;
  }
  else if (el instanceof StringWrapper) {
    return <>
      {/* <HtmlComment text={el.blockId} /> */}

      <script type="text" data-text-block-id={el.blockId} data-instance-id={el.instanceId} />

      {el.str}
    </>
  }
  else if (el instanceof Pass) {
    return undefined;
  }
  else if (!_.isNil(el)) {
    return <button
      onClick={() => {
        console.log(el);
      }}
    >..</button>
  }
}


enum GrammarType {
  Expr = 'Expr',
  Not = 'Not',
  Negate = 'Negate',
  Or = 'Or',
  And = 'And',
  Eq = 'Eq',
  Ne = 'Ne',
  Lt = 'Lt',
  Lte = 'Lte',
  Gt = 'Gt',
  Gte = 'Gte',
  Additive = 'Additive',
  Multiplicative = 'Multiplicative',
  Primary = 'Primary',

  identifier = 'identifier',
  integer = 'integer',
  string = 'string',
}

export const grammar = ohm.grammar(grammarDef);
export const flexGrammar = ohm.grammar(grammarDefFlex);

export const selectorGrammar = ohm.grammar(selectorGrammarDef);

let _globals;
export function setGlobals(gloabls) {
  _globals = gloabls;
}


let _types = {};
export function setBaseTypes(types) {
  _types = types;
}

function evalPrimitive(node) {
  const r = node.eval();
  for (const handler of primitiveHandlers) {
    if (handler.test(r)) return handler.perform(r);
  }

  return r;
}

export const transform = (line, scopeId) => {
  const match = selectorGrammar.match(line);
  let deep;

  const selectorSemantics = selectorGrammar.createSemantics().addOperation<any>('eval', {
    NonemptyListOf(a, b, c) {
      return [ a.eval() ].concat(c.eval());
    },
    selectorComp(tag, modifiers) {
      return tag.sourceString + modifiers.sourceString;
    },
    _iter(...children) {
      return children.map(c => c.eval());
    },    
    _terminal() {
      return this.sourceString;
    },

    Selector(els) {
      const r = els.eval();
      return r.map(el => {
        if (el == '>>>') {
          deep = true;
          return;
        }
        else if (el == '>') return el
        else if (scopeId && !deep && el[0] != '&') {
          return `${el}[data-scope-${scopeId}]`;
        }
        else {
          return el;
        }
      }).filter(Boolean).join(' ')
    },
    SelectorList(els) {
      return els.eval().join(', ');
    }
  });

  let l;
  let asdf
  if (match.succeeded()) {
    asdf = selectorSemantics(match).eval();
    l = asdf;

  }
  else {
    l = line;
  }

  return l;
}

export const generateCss2 = ({blocks, rootId, baseSelector, scopeId, hooks, scope}) => {
  const output = [];
  const _generateCss = (blocks, baseProps, parents) => {
    const properties = [];

    output.push({
      selector: parents.join(' '),
      properties,
    });  

    for (let i = 0; i < blocks.length; ++ i) {
      const block = blocks[i];
      const formula = expandFormula(block.data, hooks);
      const line = execFormula(formula, scope, hooks, 'TextBlock');

      if (line[0] == '#') continue;

      if (line.startsWith('if ') || line.startsWith('else if ')) {
        let expr;
        if (line.startsWith('if ')) expr = line.slice(3);
        else expr = line.slice(8);

        if (execFormula(expr, scope, hooks)) {
          _generateCss(block.children || [], [`--control: ${line}`, ...baseProps], parents);
          while (i < blocks.length - 1) {
            ++i;
            const line2 = expandFormula(blocks[i].data, hooks);
            if (!(line2.startsWith('else if ') || line2 == 'else')) {
              --i;
              break;
            }
          }  
        }
      }
      else if (line == 'else') {
        _generateCss(block.children || [], [`--control: ${line}`, ...baseProps], parents);
      }
      else {
        if (block.children?.length) {
          const nextProperties = [
            `--id: block#${block._id}`
          ]
  
          const selector = transform(line, scopeId);
  
          if (selector[0] == '&') {
            _generateCss(block.children, nextProperties, parents.slice(0, -1).concat(parents[parents.length - 1] + selector.slice(1)));
          }
          else {
            _generateCss(block.children, nextProperties, parents.concat(selector));
          }
        }
        else {
          const [name] = line.split(':');
          properties.push(`--id-${name}: block#${block._id}`);
          properties.push(line);
        }
      }
    }

    if (properties.length) {
      properties.splice(0, 0, ...baseProps);
    }
  }

  _generateCss(blocks, [
    `--id: ${rootId}`,
  ], [baseSelector]);

  return output.filter(o => o.properties.length).map(rule => {
    return `${rule.selector} {\n${rule.properties.map(p => `\t${p};`).join('\n')}\n}`
  }).join('\n');
}

export const generateCss = ({rootBlock, baseSelector, scopeId, hooks, scope}) => {
  const output = [];
  const _generateCss = (blocks, baseProps, parents) => {
    const properties = [];

    output.push({
      selector: parents.join(' '),
      properties,
    });  

    for (let i = 0; i < blocks.length; ++ i) {
      const block = blocks[i];
      const formula = expandFormula(block.data, hooks);
      const line = execFormula(formula, scope, hooks, 'TextBlock');

      if (line[0] == '#') continue;

      if (line.startsWith && (line.startsWith('if ') || line.startsWith('else if '))) {
        let expr;
        if (line.startsWith('if ')) expr = line.slice(3);
        else expr = line.slice(8);

        if (execFormula(expr, scope, hooks)) {
          _generateCss(block.children || [], [`--control: ${line}`, ...baseProps], parents);
          while (i < blocks.length - 1) {
            ++i;
            const line2 = expandFormula(blocks[i].data, hooks);
            if (!(line2.startsWith('else if ') || line2 == 'else')) {
              --i;
              break;
            }
          }  
        }
      }
      else if (line == 'else') {
        _generateCss(block.children || [], [`--control: ${line}`, ...baseProps], parents);
      }
      else {
        if (block.children?.length) {
          const nextProperties = [
            `--id: block#${block._id}`
          ]
  
          const selector = transform(line, scopeId);
  
          if (selector[0] == '&') {
            _generateCss(block.children, nextProperties, parents.slice(0, -1).concat(parents[parents.length - 1] + selector.slice(1)));
          }
          else {
            _generateCss(block.children, nextProperties, parents.concat(selector));
          }
        }
        else {
          const [name] = line.split(':');
          properties.push(`--id-${name}: block#${block._id}`);
          properties.push(line);
        }
      }
    }

    if (properties.length) {
      properties.splice(0, 0, ...baseProps);
    }
  }

  _generateCss(rootBlock.children, [
    `--id: block#${rootBlock._id}`,
  ], [baseSelector]);

  return output.filter(o => o.properties.length).map(rule => {
    return `${rule.selector} {\n${rule.properties.map(p => `\t${p};`).join('\n')}\n}`
  }).join('\n');
}

function compileStyles(blocks: ScriptBlock[], hooks: Hooks, scope, scopeId?, indent=0) {
  const lines = [];

  for (const block of blocks) {
    const formula = expandFormula(block.data, hooks);
    const line = execFormula(formula, scope, hooks, 'TextBlock');

    if (block.children?.length) {
      const match = selectorGrammar.match(line);
      let deep;

      const selectorSemantics = selectorGrammar.createSemantics().addOperation<any>('eval', {
        NonemptyListOf(a, b, c) {
          return [ a.eval() ].concat(c.eval());
        },
        selectorComp(tag, modifiers) {
          return tag.sourceString + modifiers.sourceString;
        },
        _iter(...children) {
          return children.map(c => c.eval());
        },    
        _terminal() {
          return this.sourceString;
        },
    
        Selector(els) {
          const r = els.eval();
          return r.map(el => {
            if (el == '>>>') {
              deep = true;
              return;
            }
            else if (el == '>') return el
            else if (scopeId && !deep && el[0] != '&') {
              return `${el}[data-scope-${scopeId}]`;
            }
            else {
              return el;
            }
          }).filter(Boolean).join(' ')
        },
        SelectorList(els) {
          return els.eval().join(', ');
        }
      });

      let l;
      let asdf
      if (match.succeeded()) {
        asdf = selectorSemantics(match).eval();
        l = asdf;

      }
      else {
        l = line;
      }

      if (l[0] == '#') continue;

      lines.push(
        `${l} {
          --id: block#${block._id};
          --asdf: ${asdf};
          ${compileStyles(block.children, hooks, scope, !deep && scopeId, indent+1)}
        }`
      )
    }
    else {
      const [name] = line.split(':');
      lines.push(line + ';');
      lines.push(`--id-${name}: block#${block._id};`);
    }
  }

  return lines.join('\n');
}

const typeSemantics = grammar.createSemantics().addOperation('eval', {
  identifier(a) {
    return 'identifier';
  },
  oldRhinos(a) {
    return 'identifier';
  },
  ObjectRef(a, b, c) {
    return 'ObjectRef';
  },
  string(a, b, c) {
    return 'string';
  },
  Expr_else(a) {
    return 'Else';
  },
  Expr_if(a, b) {
    return 'If';
  },
  Assign(a, b, c) {
    return 'Assign';
  },
  Expr_or(a) {
    return 'OR';
  }
});


const evalSemantics = grammar.createSemantics().addOperation<any>('eval', {
  identifier(a) {
    return ['Expr', a.sourceString];
  },
  oldRhinos(a) {
    return ['Expr', a.sourceString];
  },
  ObjectRef(a, b, c) {
    return ['Expr'];
  },
  ObjectRefId(a, b, c) {
    return ['Expr'];
  },
  string(a, b, c) {
    return ['Expr'];
  },
  And_asfd(a, b, c) {
    return ['Expr'];
  },
  integer(a, b) {
    return ['Expr'];
  },
  Expr_else(a) {
    return ['Else'];
  },
  Expr_if(a, b) {
    return ['If', b.sourceString];
  },
  Expr_or(a) {
    return ['OR'];
  },
  Negate(a, b) {
    return ['Expr'];
  },
  Expr_elseIf(a, b) {
    return ['Else If', b.sourceString];
  },
  _iter(...children) {
    return children.map(c => c.eval());
  },
  NonemptyListOf(a, b, c) {
    return [ a.eval() ].concat(c.eval());
  },
  EmptyListOf() {
    return [];
  },
  Not(a, b) {
    return ['Expr'];
  },
  Assign(a, b, c) {
    return ['Assign', a.sourceString, c.sourceString];
  },
  InterpolatedString(a, b, c, d) {
    return ['Expr'];
  },
  PlusEquals(a, b, c) {
    return ['PlusEquals', a.sourceString, c.sourceString];
  },
  Additive_subtract(a, b, c) {
    return ['Expr'];
  },
  MinusEquals(a, b, c) {
    return ['MinusEquals', a.sourceString, c.sourceString];
  },
  DeclareConst(identifier, _op, expr) {
    return ['DeclareConst', identifier.sourceString, expr.sourceString];
  },
  DeclareVar(_var, identifier, _op, expr) {
    return ['DeclareVar', identifier.sourceString, expr.sourceString];
  },
  Expr_comment(_op, comment) {
    return ['Comment', comment.sourceString];
  },
  FunctionCall_withArgs(a, b, c, d, e, f) {
    return ['Expr'];
  },
  FunctionCall_noArgs(a, b, c, d) {
    return ['Expr'];
  },
  Closure_noParams(arrow, _c, expr) {
    if (expr.sourceString) {
      return ['Expr'];
    }
    else {
      return ['Closure', arrow.sourceString, []];
    }
  },
  Boolean(a) {
    return ['Expr'];
  },
  Closure_singleIdentifier(arg, _a, arrow, _c, expr) {
    if (expr.sourceString) {
      return ['Expr'];
    }
    else {
      return ['Closure', arrow.sourceString, [arg.sourceString]];
    }
  },
  Closure_identifierList(a, b, identifiers, c, d, e, f, arrow, h, expr) {
    if (expr.sourceString) {
      return ['Expr'];
    }
    else {
      return ['Closure', arrow.sourceString, identifiers.eval().map(x => x[1])];
    }
  },
  Additive_add(a, b, c) {
    return ['Expr'];
  },
  Dictionary_emptyDict(x, a,b,c) {
    return ['Expr'];
  },
  Dictionary_dict(x, a, b, c, d, e) {
    return ['Expr'];
  },

  Primary_tertiary(a, b, c, d, e, f, g, h, i) {
    return ['Expr'];
  },
  Array_emptyArray(a, b, c, d) {
    return ['Expr'];
  },
  Null(a) {
    return ['Expr'];
  },
  Binding(a, b, c, d) {
    return ['Binding', a.sourceString, b.sourceString, c.sourceString, d.sourceString];
  },
  Labeled(a, b, c) {
    return ['Labeled', a.sourceString, c.sourceString];
  },
  Array_ary(x, a, b, c, d, e) {
    return ['Expr'];
  },
  PipedLine(a, b) {
    return ['PipedLine', b.sourceString];
  },
  Element_onlyOpening(a, b, c, d, e) {
    return ['Expr'];
  },
  Element_asdf(a, b, c) {
    return ['Expr'];
  },
  Element_withChildren(a, b, c, d, e, f, g, h) {
    return ['Expr'];
  },
  StyledComponent(a, identifier, b, modifier) {
    return ['StyledComponent', identifier.sourceString, modifier.eval()?.[0]];
  },
  StyledComponentModifier_param(identifier, a, primary, b) {
    return [identifier.sourceString, primary.sourceString];
  },
  StyledComponentModifier_noParam(identifier) {
    return [identifier.sourceString];
  },
  Primary_accessor(a, b, c) {
    return ['Expr'];
  },
  Primary_bracketAccessor(a, b, c, d) {
    return ['Expr'];
  },
  Expr_for(a, b, c, d) {
    return ['For', b.sourceString, c.sourceString, d.sourceString];
  },
  Lt_one(a, b, c) {
    return ['Expr'];
  },

  Eq_add(a, b, c) {
    return ['Expr'];
  },
  Or_eq(a, b, c) {
    return ['Expr'];
  },
});


export function scriptEmpty(blocks) {
  if (!blocks) return true;
  if (!blocks.length) return true;
  return !blocks?.[0]?.data?.length;
}


export interface ScriptBlock {
  _id?
  children: ScriptBlock[]
  data: string | [string, any[]][]
}

export class Pass {}

function createScope(scope={}, chain=[]) {
  return new Proxy({}, {
    get: (__, prop: string) => {
      if (prop == '$$isScope') return true;
      if (prop == '$$createChild') {
        return init => {
          return createScope(init, chain.concat(scope));
        }
      }
      else {
        if (prop in scope) {
          // console.log(`getting ${prop} in current scope`)
          return scope[prop];
        }
        for (let i = chain.length - 1; i >= 0; --i) {
          if (prop in chain[i]) {
          // console.log(`getting ${prop} in ${i}`)

            return chain[i][prop];
          }
        }

        // console.log(`no ${prop}`)
        // throw new Error(`no var ${prop}`)

      }
    },
    getOwnPropertyDescriptor(__, prop) {
      if (prop in scope) return Reflect.getOwnPropertyDescriptor(scope, prop);
      for (let i = chain.length - 1; i >= 0; --i) {
        if (prop in chain[i]) {
          return Reflect.getOwnPropertyDescriptor(chain[i], prop);
        }
      }

    },
    ownKeys() {
      const keys = {};
      for (const key in scope) keys[key] = true;
      for (const s of chain) {
        for (const key in s) {
          keys[key] = true;
        }
      }
      return Object.keys(keys);
    },
    set: (__, prop: string, val) => {
      let set = false;
      if (prop in scope) {
        scope[prop] = val;
        // console.log(`setting ${prop} in current scope`)
        set = true;
      }
      for (let i = chain.length - 1; i >= 0; --i) {
        if (prop in chain[i]) {
          chain[i][prop] = val;
        // console.log(`setting ${prop} in ${i}`)

          set = true;
          break;
        }
      }

      if (!set) {
        // console.log(`setting ${prop} in current scope`)

        scope[prop] = val;
      }

      return true;

    },
  })
}

function childScope(scope, init={}) {
  return scope.$$createChild(init);
}

function isScope(scope) {
  return scope.$$isScope;
}

const styledComponentCache = {};
function isIterable(input) {  
  if (input === null || input === undefined) {
    return false
  }

  return typeof input[Symbol.iterator] === 'function'
}



function functionId(func) {
  return func.blockId || func._functionName;
}

export function executeScript(script: ScriptBlock[], hooks: Hooks={}, parentScope?, callStack?: RuntimeTrace, runtimeContext?: RuntimeContext) {

  if (!runtimeContext) {
    throw new Error();
  }
  const createStyledComponent = (tag, block, modifier) => {
    let scopeId;

    if (tag == 'Svg') {
      console.log(_globals({}));
    }

    if (tag[0] == tag[0].toUpperCase()) {
      if (Object.keys(scope).includes(tag)) {
        tag = scope[tag];
      }
      else if (tag in _globals({})) {
        tag = _globals({})[tag];
      }
      else {
        console.log('nooooo', tag, _globals({}), Object.keys(scope));
        tag = 'div';
      }

      if (!tag) {
        console.log('poopy');

        tag = 'div';
      }
    }

    if (modifier?.[0] == 'scoped') {
      if (modifier[1]) {
        scopeId = execFormula(modifier[1], scope, hooks);
      }
      else {
        scopeId = scope.__scopeId;
      }
    }

    const styles = `
    --tag: ${tag.toString()};
    --id: block#${block._id};
    ${compileStyles(block.children, hooks, scope, scopeId)}
    `



    if (styledComponentCache[styles]) return styledComponentCache[styles];
    return styledComponentCache[styles] = styled(tag)`
    --id: block#${block._id};
      ${compileStyles(block.children, hooks, scope, scopeId)}
      `
  }

  const scope = isScope(parentScope) ? parentScope : createScope(parentScope);

  let lastVal = new Pass();
  const vals = [];
  for (let i = 0; i < script.length; ++ i) {
    const block = script[i];

    const formula = expandFormula(x(block.data), hooks);

    const doRoot = formula => {
      let lastVal;

      if (!formula) return new Pass;
  
      try {
        const evalInfo = getEvalInfo(formula);

        const createClosureFunc = (valueEvalInfo) => {
          function func(this: any, injectScope={}, args) {
            const argScope = {};
            const callStack = args.find(i => i instanceof RuntimeTrace)
            const runContext = args.find(i => i instanceof RuntimeContext) || runtimeContext;
            for (let i = 0; i < valueEvalInfo[2].length; ++ i) {
              argScope[valueEvalInfo[2][i]] = args[i];
            }

            return executeScript(block.children || [], hooks, childScope(scope, {...argScope, ...injectScope, this: this }), callStack, runContext);
          }
          
          function func2(this: any, ...args) {
            return func.call(this, {}, args);
          }

          func2.injectScope = (scope, ...args) => {
            return func(scope, args);
          }
          func2.injectScopeThis = (th, scope, ...args) => {
            return func.call(th, scope, args);
          }
          func2.paramCount = valueEvalInfo[2].length;
          func2.blockId = block._id;
          func2.instanceId = parentScope.__instanceId;
          func2._fromScript = true;

          return func2;
        }
  
        const evaluateExprBlock = str => {
          if (block.children?.length) {
            const value = execFormula(str, childScope(scope, { __blockId: block._id, __instanceId: parentScope.__instanceId }), hooks, undefined, callStack, runtimeContext);
            if (_.isFunction(value)) {
              const args = [];
              for (const argBlock of block.children) {
                args.push(executeScript([argBlock], hooks, scope, callStack, runtimeContext));
              }

              window['__blockId'] = block._id;

              if (!receivesRuntimeTrace(value)) {
                const cb = callStack?.logFunctionCall?.(block._id, undefined, functionId(value), args);

                const r = XObject.withPass(new Thingy(block._id, runtimeContext, callStack), () => {
                  return value(...args.map(unwrapStringsDeep));
                });

                if (cb) {
                  cb.returnValue = r;
                }
                return r;
              }
              else {
                const cb = callStack?.logFunctionCall?.(block._id, (value as any).instanceId, functionId(value), args);
                const r = cb ? value(...args, cb.cs, runtimeContext) : value(...args, runtimeContext);
                if (cb) cb.returnValue = r;
                return r;
              }
            }
            else if (_.isPlainObject(value)) {
              for (const argBlock of block.children) {
                const entry = executeScript([argBlock], hooks, scope, callStack, runtimeContext);
                if (_.isArray(entry)) {
                  value[unwrapString(entry[0])] = entry[1];
                }
              }
              return value;
            }
            else if (_.isArray(value)) {
              for (const argBlock of block.children) {
                const entry = executeScript([argBlock], hooks, scope, callStack, runtimeContext);
                if (!(entry instanceof Pass)) {
                  value.push(entry);
                }
              }
              return value;
            }
            else if (value instanceof ModdedDict) {
              for (const argBlock of block.children) {
                const entry = executeScript([argBlock], hooks, scope, callStack, runtimeContext);
                if (_.isArray(entry)) {
                  value.dict[entry[0]] = entry[1];
                }
              }
              return value;
            }
            else if (value instanceof El) {
              for (const argBlock of block.children) {
                const entry = executeScript([argBlock], hooks, scope, callStack, runtimeContext);
                if (entry instanceof Meta) {
                  value.attributes.push(entry.value);
                }
                else if (!_.isNil(entry)) {
                  value.children.push(entry);
                }
              }
              return value;
            }
            else {
              return value;
            }
          }
          else {
            return execFormula(str, childScope(scope, { __blockId: block._id, __instanceId: parentScope.__instanceId }), hooks, undefined, callStack, runtimeContext);
          }
        }
  
        const evaluateExprStr = (str) => {
          const valueEvalInfo = getEvalInfo(str, true);
          if (valueEvalInfo[0] == 'Expr') {
            return evaluateExprBlock(str);
          }
          else if (valueEvalInfo[0] == 'Closure') {
            return createClosureFunc(valueEvalInfo);
          }
          else if (valueEvalInfo[0] == 'StyledComponent') {
            return createStyledComponent(valueEvalInfo[1], block, valueEvalInfo[2]);
          }
          else {
            console.log(str, valueEvalInfo);
          }
        }
  
        if (evalInfo[0] == 'DeclareVar') {
          if (scope.declareHook) {
            scope.declareHook(evalInfo[1]);
          }
          scope[evalInfo[1]] = evaluateExprStr(evalInfo[2]);
        }
        else if (evalInfo[0] == 'DeclareConst') {
          const value = evaluateExprStr(evalInfo[2]);
          if (scope.declareHook) {
            scope.declareHook(evalInfo[1], value, scope);
          }
          scope[evalInfo[1]] = value;
          lastVal = value;
        }
        else if (evalInfo[0] == 'PlusEquals') {
          scope[evalInfo[1]] += evaluateExprStr(evalInfo[2]);
        }
        else if (evalInfo[0] == 'MinusEquals') {
          scope[evalInfo[1]] -= evaluateExprStr(evalInfo[2]);
        }
        else if (evalInfo[0] == 'Assign') {
          const assign = (obj, prop, value) => {
            if (obj instanceof FormulaObjectProxy) {
              XObject.withPass(new Thingy(block._id, runtimeContext, callStack), () => {
                obj.set(prop, value);
              })
            }
            else if (XObject.isObject(obj) || XObject.isArray(obj)) {
              XObject.withPass(new Thingy(block._id, runtimeContext, callStack), () => {
                obj[prop] = unwrapStringsDeep(value);
              });
            }
            else {
              obj[prop] = value;
            }
          }

          const r = evaluateExprStr(evalInfo[2]);
          callStack?.capture?.(block._id, r);

          if (evalInfo[1].indexOf('[') != -1) {
            const match = evalInfo[1].match(/^(.*?)\[(.*?)\]$/);
            if (match) {
              assign(scope[match[1]], scope[match[2]], r);
            }
          }
          else {
            const parts = evalInfo[1].split('.');
            if (parts.length == 1) {
              const value = r;
              scope[evalInfo[1]] = value;
              if (scope.declareHook) {
                scope.declareHook(evalInfo[1], value, scope);
              }
    
            }
            else if (parts.length == 2) {
              assign(scope[parts[0]], parts[1], r);
            }
            else if (parts.length == 3) {
              assign(scope[parts[0]][parts[1]], parts[2], r);
            }
          }

        }
        else if (evalInfo[0] == 'Binding') {
          if (evalInfo[2] == '::>') {
            lastVal = new Bindingg({
              type: 'deepSignal',
              name: evalInfo[1],
              receiver: evaluateExprStr(evalInfo[4]),
            });
          }
          else if (evalInfo[2] == ':>') {
            lastVal = new Bindingg({
              type: 'signal',
              name: evalInfo[1],
              receiver: evaluateExprStr(evalInfo[4]),
            });
          }
          else if (evalInfo[2] == '<:>') {
            if (evalInfo[3] == '&') {
              lastVal = new Pass();

            }
            else {
              lastVal = new Bindingg({
                type: 'binding',
                name: evalInfo[1],
                binding: evaluateExprStr(evalInfo[4]),
              })
            }

          }
        }
        else if (evalInfo[0] == 'Comment') {
          lastVal = new Pass();
        }
        else if (evalInfo[0] == 'Closure') {
          lastVal = createClosureFunc(evalInfo);
        }
        else if (evalInfo[0] == 'Expr') {
          lastVal = evaluateExprBlock(formula);
          if (_.isString(lastVal)) {
            lastVal = new StringWrapper(lastVal, block._id, runtimeContext?.context?.instanceId)
          }
        }
        else if (evalInfo[0] == 'Labeled') {
          const r = evaluateExprStr(evalInfo[2]);
          callStack?.capture?.(block._id, r);
          lastVal = [ evalInfo[1], r ];
        }
        else if (evalInfo[0] == 'PipedLine') {
          lastVal = new Meta(doRoot(evalInfo[1]));
        }
        else if (evalInfo[0] == 'If' || evalInfo[0] == 'Else If') {
          const r = execFormula(evalInfo[1], scope, hooks, undefined, callStack, runtimeContext);
          callStack?.capture?.(block._id, r);
          if (r) {
            lastVal = executeScript(block.children, hooks, childScope(scope), callStack, runtimeContext);
            while (i < script.length) {
              ++i;
              const evalInfo = getEvalInfo(expandFormula(x(script[i]?.data)), true);
              if (evalInfo[0] != 'Else If' && evalInfo[0] != 'Else') {
                --i;
                break;
              }
            }
          }
        }
        else if (evalInfo[0] == 'For') {
          if (evalInfo[2] == 'in') {
            const obj = execFormula(evalInfo[3], scope, hooks, undefined, callStack, runtimeContext);
            let i = 0;
            for (const key in obj) {
              executeScript(block.children, hooks, childScope(scope, {
                [evalInfo[1]]: key,
              }), callStack?.pushForLoop?.(block._id, i, key), runtimeContext);
              ++i;
            }

          }
          else {
            const array = execFormula(evalInfo[3], scope, hooks, undefined, callStack, runtimeContext);
            if (isIterable(array)) {
              let i = 0;
              for (const el of array) {
                executeScript(block.children, hooks, childScope(scope, {
                  [evalInfo[1]]: el,
                }), callStack?.pushForLoop?.(block._id, i, el), runtimeContext);
                ++ i;
              }  
            }
          }
        }
        else if (evalInfo[0] == 'Else') {
          callStack?.capture?.(block._id, new Pass());
          lastVal = executeScript(block.children, hooks, childScope(scope), callStack, runtimeContext);
        }
        else if (evalInfo[0] == 'StyledComponent') {
          lastVal = createStyledComponent(evalInfo[1], block, evalInfo[2]);
        }

        else {
          console.log('[executeScript] not handled', formula, evalInfo);
        }
      }
      catch (e) {
        console.log('[executeScript] failed', formula, e);
      }

      callStack?.capture?.(block._id, lastVal);

      // if (hooks.captureBlockValue) {
      //   hooks.captureBlockValue(block._id, lastVal, callStack);
      // }

      return lastVal;
    }

    const r = doRoot(formula);
    vals.push(r);
    if (!(r instanceof Pass)) {
      lastVal = r;
    }
  }

  return lastVal;
  return vals[vals.length - 1];
}

export function getType(str) {
  return typeSemantics(grammar.match(str)).eval();
}

export class Bindingg {
  constructor(public args) {}
}

class Meta {
  constructor(public value) {}
}

export function getEvalInfo(str, catchError=false) {
  if (catchError) {
    try {
      return evalSemantics(grammar.match(str)).eval();
    }
    catch (e) {
      return ['Error', e];
    }
  }
  else {
    return evalSemantics(grammar.match(str)).eval();
  }
}

class ModdedDict {
  constructor(public mod, public dict) {

  }
}

function evalDictionaryMod(mod, dict, runtimeTrace, runtimeContext) {
  if (mod.sourceString == 'X') {
    const obj = X(unwrapStringsDeep(dict));
    XObject.observe(obj, Object.assign(mutation => {
      if (mutation.pass instanceof Thingy && mutation.pass.trace) {
        console.log('globalState', mutation);
        mutation.pass.trace.captureMutation(mutation.pass.block, mutation.pass.runtimeContext, mutation, 'globalState');
      }

    }, { immediate: true }));

    return obj;
  }
  if (mod.sourceString == 'XObj') {
    return XObject.obj(unwrapStringsDeep(dict));
  }

  const type = getType(mod.sourceString);

  if (type == 'ObjectRef') {
    return new ModdedDict(mod.eval(), dict);
  }
  else if (type == 'identifier') {
    return new ModdedDict(mod.sourceString, dict);
  }
  else {
    return new ModdedDict(undefined, dict);
  }
  console.log('poooadsfadsf', type);
  return dict;
  if (mod == 'X') {
    return X(dict);
  }
  else {
    console.log('asdfasdfasfd', mod);
    throw new Error();
  }
}

const componentCache = {}


export function unwrapString(str) {
  if (str instanceof StringWrapper) {
    return str.str;
  }
  else {
    return str;
  }
}

export function unwrapStringsDeep(value) {
  if (_.isArray(x(value))) {
    return value.map(unwrapStringsDeep);
  }
  else if (_.isPlainObject(x(value))) {
    const newObj = {};
    for (const prop in value) {
      newObj[prop] = unwrapStringsDeep(value[prop]);
    }
    return newObj;
  }
  else {
    return unwrapString(value);
  }
}

export class StringWrapper {
  constructor(public str, public blockId, public instanceId) {}
}

function receivesRuntimeTrace(func) {
  return func._fromScript;
}

const cache = {};
export function execFormula(input: string, env, hooks:Hooks={}, startRule?, callStack?: RuntimeTrace, runtimeContext?: RuntimeContext) {
  const doIdent = a => {
    const identifier = a.sourceString;
  
    const scope = {
      ...env,
      ...(_globals?.({ env }) || {}),
      unwrapX(args) {
        return x(args);
      },
      XGuard: XGuard,
      JsonStringify(args) {
        return JSON.stringify(args);
      },
      Concat: (...args) => {
        let str = '';
        for (const a of args) {
          // if (a instanceof FormulaObjectWrapper) {
          //   str += a.get('ToString')?.();
          // }
          // else {
            str += a;
          // }
        }
        return str;
      },

      Average: values => {
        let total = 0;
        for (const value of values) {
          total += value;
        }
        return total/values.length;
      },
      Debug: (...values) => {
        console.log('[formula]', ...values);
      },
      Sum: (args) => {
        return _.sum(args.map(x => window.parseFloat(x) || 0));
      },
      Max: (...args) => {
        if (_.isArray(args[0])) {
          return _.max(args[0].map(x => window.parseFloat(x) || 0));
        }
        else {
          return _.max(args.map(x => window.parseFloat(x) || 0));
        }
      },
      Min: (...args) => {
        if (_.isArray[0]) {
          return _.min(args[0].map(x => window.parseFloat(x) || 0));
        }
        else {
          return _.min(args.map(x => window.parseFloat(x) || 0));

        }
      },
      Now() {
        return new Date();
      }, 


      log(...args) {
        console.log('[FORMULA][log]', ...args);
      },

      component(...args) {
        const styles = args.find(a => a?.[0] == 'styles')?.[1];
        const render = args.find(a => a?.[0] == 'render')?.[1];
        const didMount = args.find(a => a?.[0] == 'didMount')?.[1];
        const blockId = window['__blockId'];
        const key = blockId + env.__lastUpdated;
        if (componentCache[key]) return componentCache[key];

        const props = comp => {


          return {
            'data-component-block': blockId,
            'data-component-instance': comp.instanceId,
            // ...scopeAttrs,

            ...(env.__scopeId ? {[`data-scope-${env.__scopeId}`]: true} : {}),
          }
        }

        const callRender = ({ comp, container = undefined }) => {
          if (render.injectScopeThis) {
            return render.injectScopeThis(comp, {
              declareHook: (name, value, scope) => {
                const inst = initComponentInstanceDiag(comp.instanceId);
                inst.vars[name] = XClone(value);
                componentInstanceDiagScopes[comp.instanceId + name] = scope;
                tickComponentInstanceDiag(comp.instanceId);
              }
            }, container);
          }
          else {
            return render.call(comp, container);
          }
        }

        let val;
        if (render.paramCount) {
          @component
          class Comp extends Component<any> {
            static asdfasdf = true;
            static styles = styles;
            static debounce = false;
            hello = 'asdfasdf';
            state = X({});
            blockId
            instanceId
            
            constructor(props) {
              super(props);
              this.instanceId = md5(Math.random());
              this.blockId = blockId;
              initComponentInstanceDiag(this.instanceId).comp = this;

            }
            componentDidMount() {
              didMount?.call?.(this);
              if (blockId) {
                if (!scriptBlockDiagInfo[blockId]) {
                  scriptBlockDiagInfo[blockId] = X({
                    count: 0
                  });
                }
                scriptBlockDiagInfo[blockId].count = scriptBlockDiagInfo[blockId].count  + 1 
              }
            }
            componentWillUnmount(): void {
              if (this.blockId) {
                scriptBlockDiagInfo[this.blockId].count = scriptBlockDiagInfo[this.blockId].count  - 1;
              }
              deleteComponentInstanceDiag(this.instanceId);
            }
            render(Container?) {
              if (render) {
                const scopeKeys = Object.keys(this.props).filter(prop => prop.startsWith('data-scope-'));

                const scopeAttrs = {};
                for (const key of scopeKeys) {
                  scopeAttrs[key] = this.props[key];
                }
      
                return renderEl(callRender({ comp: this, container: Container }), this.props._rt, props(this)) || '';
              }
              return <button
                onClick={() => {
                  console.log(args);
                }}
              >.</button>
            }
          }
  
          val = Comp;
        }
        else {
          @component
          class Comp extends Component<any> {
            static asdfasdf = true;
            static styles = styles;
            static debounce = false;
            hello = 'asdfasdf';
            state = X({});
            blockId
            instanceId
            constructor(props) {
              super(props);
              this.instanceId = md5(Math.random());
              this.blockId = blockId;

              initComponentInstanceDiag(this.instanceId).comp = this;

            }
            componentDidMount() {
              didMount?.call?.(this);
              
              if (blockId) {
                if (!scriptBlockDiagInfo[blockId]) {
                  scriptBlockDiagInfo[blockId] = X({
                    count: 0
                  });
                }


                scriptBlockDiagInfo[blockId].count = scriptBlockDiagInfo[blockId].count  + 1
                
              }
            }
            componentWillUnmount(): void {
              if (this.blockId) {
                scriptBlockDiagInfo[this.blockId].count = scriptBlockDiagInfo[this.blockId].count  - 1;
              }

              deleteComponentInstanceDiag(this.instanceId);
            }
            render(Container?) {
              if (render) {
                if (!Container) Container = 'div';
                const scopeKeys = Object.keys(this.props).filter(prop => prop.startsWith('data-scope-'));

                const scopeAttrs = {};
                for (const key of scopeKeys) {
                  scopeAttrs[key] = this.props[key];
                }

                if (env.__scopeId) {
                  scopeAttrs[`data-scope-${env.__scopeId}`] = true;
                }
      
                return (
                  <Container
                    onClick={this.props.onClick}
                    className={this.props.className}
                    data-component-block={blockId}
                    data-component-instance={this.instanceId}
                    data-script-block-id={this.props['data-script-block-id']}
                    data-script-block-ids={this.props['data-script-block-ids']}
                    {...scopeAttrs}
                  >
                {renderEl(callRender({ comp: this }), this.props._rt, props(this)) || ''}
                  </Container>
                )

              }
              return <button
                onClick={() => {
                  console.log(args);
                }}
              >.</button>
            }
          }
  
          val = Comp;
        }

        componentCache[key] = val;

        return val;

      },

      daysSince(date) {
        return Sugar.Date.create(date)['daysSince']()
      },

      objectKeys(obj) {
        return Object.keys(obj);
      },

      parseInt(value) {
        return parseInt(value);
      },
      toString(value) {
        if (_.isNil(value)) {
          return 'nil';
        }
        else {
          return value.toString();
        }
      }
    }

    for (let i = scopes.length - 1; i >= 0; -- i) {
      if (identifier in scopes[i]) {
        return scopes[i][identifier];
      }
    }

    return scope[identifier];
  }
  const scopes = [];
  const semantics = grammar.createSemantics().addOperation('eval', {
    Not(a, b) {
      return !evalPrimitive(b);
    },
    Gt_one(a, b, c) {
      return a.eval() > c.eval();
    },
    [GrammarType.Expr](a) {
      return a.eval();
    },
    [GrammarType.Additive + '_add'](a, _, b) {
      return unwrapString(a.eval()) + unwrapString(b.eval());
    },
    Boolean(a) {
      return a.sourceString.toLowerCase() == 'true';
    },
    Null(a) {
      return null;
    },
    [GrammarType.Additive + '_subtract'](a, _, b) {
      return a.eval() - b.eval();
    },
    Assign(a, b, c) {
      return c.eval();
    },
    PlusEquals(a, b, c) {
      return c.eval();
    },
    Closure_identifierList(a, b, c, d, e, f, g, h, i, j) {
      const closure = (...args) => {
        const scope = {};
        for (let i = 0; i < args.length && i < c.children.length; ++ i) {
          scope[c.children[i].sourceString] = args[i];
        }
        scopes.push(scope);
        const [r] = j.eval();
        scopes.pop();
        return r;
      }
      closure._fromScript = true;
      closure.paramCount = c.children.length;
      closure.blockId = env.__blockId;
      closure.instanceId = env.__instanceId;

      return closure;
    },
    Closure_noParams(c, d, expr) {
      const closure = () => {
        return expr.eval()[0];
      }
      closure._fromScript = true;
      closure.blockId = env.__blockId;
      closure.instanceId = env.__instanceId;

      return closure;
    },
    Closure_singleIdentifier(ident, b, c, d, expr) {
      const closure = (...args) => {
        const scope = {};
        scope[ident.sourceString] = args[0];
        scopes.push(scope);
        const [r] = expr.eval();
        scopes.pop();
        return r;
      }
      closure._fromScript = true;
      closure.blockId = env.__blockId;
      closure.instanceId = env.__instanceId;

      closure.paramCount = 1;

      return closure;
    },
    DictionaryMod(a) {
      // console.log('nice', a.sourceString);
      return a;
    },
    Dictionary_dict(x, a, b, entries, e, f) {
      const dict = {};
      for (let [key, value] of entries.eval()) {
        for (const handler of primitiveHandlers) {
          if (handler.test(key)) {
            key = handler.perform(key);
          }
        }
        
        dict[key] = value;
      }

      return x.eval().length ? evalDictionaryMod(x.eval()[0], dict, callStack, runtimeContext): dict;
    },
    TextBlock(b, c) {
      return b.eval().join('') + c.eval().map(i => _.isArray(i) ? i.join('') : i).join('');
    },
    TextBlockSegment_objectRef(a, b, c) {
      const [type, id] = b.sourceString.split(':');

      if (hooks.resolveObjectRef) {
        const resolvedObj = hooks.resolveObjectRef?.({ type, id }, env);
        if (resolvedObj != 'e801f1d3-cce2-548f-8253-4d3ed5f035ed') return resolvedObj;  
      }
      for (const handler of objRefHandlers) {
        if (handler.test({ type, id })) {
          return handler.perform({ type, id }, env.base);
        }
      }
      console.log('failed', type, id)
    },
    TextBlockSegment_interpolation(a, b, c) {
      return b.eval();
    },
    TextBlockSegment_text(a) {
      return a.eval();
    },
    Dictionary_emptyDict(x, a, b, c) {
      return x.eval().length ? evalDictionaryMod(x.eval()[0], {}, callStack, runtimeContext):  {};
    },

    Array_ary(x, a, b, c, d, e) {
      const array = c.eval();
      return x.eval().length ? X(array) : array;
    },

    Array_emptyArray(a, b, c, d) {
      return [];
    },
    Expr_for(a, b, c, d) {
      console.log(a.sourceString, d.eval())
    },
    Child_expr(a, expr, b) {
      return expr.eval();
    },
    DictionaryEntry(a, b, c) {
      // console.log('DictionaryEntry', {
      //   a: a.eval(),
      //   b: b.eval(),
      //   c: c.eval(),
      // })

      if (getType(a.sourceString) == 'identifier') {
        return [a.sourceString, c.eval()];

      }
      else {
        return [a.eval(), c.eval()];

      }
    },
    Ne_one(a, b, c) {
      return a.eval() != c.eval();
    },
    Gte_one(a, b, c) {
      return a.eval() >= c.eval();
    },
    [GrammarType.Or + '_eq'](a, _, b) {
      return unwrapString(evalPrimitive(a)) || unwrapString(evalPrimitive(b));
    },
    [GrammarType.Eq + '_add'](a, _, b) {
      return unwrapString(a.eval()) == unwrapString(b.eval());
    },
    integer: (a, b) => {
      return parseInt(a.children.length ? '-' + b.sourceString : b.sourceString);
    },
    Primary_parens(_lp, exp, _rp) {
      return exp.eval();
    },
    Negate(a, b) {
      return -b.eval();
    },
    FunctionCall_withArgs(expr, b, c, args, e, f) {
      const func = expr.eval();
      // console.log('FunctionCall_withArgs', func);

      for (const handler of callHandlers) {
        if (handler.test(func)) {
          window['g_env'] = env;
          window['g_callStack'] = env;
          return XObject.withPass(new Thingy(env.__blockId, runtimeContext, callStack), () => {

            return handler.perform(func, env, args.eval());
          });
        }
      }

      if (_.isFunction(func)) {
        window['g_env'] = env;

        if (!receivesRuntimeTrace(func)) {
          return XObject.withPass(new Thingy(env.__blockId, runtimeContext, callStack), () => {
            return func(...args.eval().map(unwrapString));
          });
        }
        else {
          const a = args.eval();
          const cb = callStack?.logFunctionCall?.(env.__blockId, (func as any).instanceId, functionId(func), a);
          const r = func(...a, cb?.cs, runtimeContext);
          if (cb) {
            cb.returnValue = r;
          }
          return r;
        }
      }
      else {
        // console.log('not a func', func, expr.sourceString);
      }
    },
    FunctionCall_noArgs(expr, a, b, c) {
      const func = expr.eval();
      for (const handler of callHandlers) {
        if (handler.test(func)) {
          window['g_env'] = env;
          return XObject.withPass(new Thingy(env.__blockId, runtimeContext, callStack), () => {

            return handler.perform(func, env);
          });
        }
      }

      if (_.isFunction(func)) {
        window['g_env'] = env;
        if (!receivesRuntimeTrace(func)) {
          return XObject.withPass(new Thingy(env.__blockId, runtimeContext, callStack), () => {
            return func();
          });
        }
        else {
          const cb = callStack?.logFunctionCall?.(env.__blockId, (func as any).instanceId, functionId(func), []);

          const r = func(cb?.cs, runtimeContext);
          if (cb) {
            cb.returnValue = r;
          }
          return r;
        }
      }
      else {
        // console.log('not a func', func, expr.sourceString);
      }
    },
    And_asfd(a, b, c) {
      return a.eval() && c.eval();
    },
    ObjectRef(a, b, c) {
      const [type, id] = b.sourceString.split(':');

      if (hooks.resolveObjectRef) {
        const resolvedObj = hooks.resolveObjectRef?.({ type, id }, env);
        if (resolvedObj != 'e801f1d3-cce2-548f-8253-4d3ed5f035ed') return resolvedObj;  
      }
      for (const handler of objRefHandlers) {
        if (handler.test({ type, id })) {
          return handler.perform({ type, id }, env.base);
        }
      }
      console.log('failed', type, id)
    },
    ObjectRefId(a, b, c) {
      const [type, id] = b.sourceString.split(':');
      return id;

      if (hooks.resolveObjectRef) {
        const resolvedObj = hooks.resolveObjectRef?.({ type, id }, env);
        if (resolvedObj != 'e801f1d3-cce2-548f-8253-4d3ed5f035ed') return resolvedObj;  
      }
      for (const handler of objRefHandlers) {
        if (handler.test({ type, id })) {
          return handler.perform({ type, id }, env.base);
        }
      }
      // return new FormulaObjectWrapper({
      //   type: type as any,
      //   id,
      // }, env.base)

    },
    identifier(a) {
      return doIdent(a);
    },
    oldRhinos(a) {
      return doIdent(a)
    },
    _iter(...children) {
      return children.map(c => c.eval());
    },
    NonemptyListOf(a, b, c) {
      return [ a.eval() ].concat(c.eval());
    },
    string(a, b, c) {
      if (hooks.traceStrings) {
        return new StringWrapper(b.sourceString, env.__blockId, env.__instanceId);
      }
      else {
        return b.sourceString;

      }
      const str = b.sourceString.slice();
      // (str as any).blockId = env.__blockId;
      return str;
    },
    _terminal() {
      return this.sourceString;
    },

    Primary_accessor(a, __, b) {
      const obj = a.eval();
      let prop = b.sourceString;


      if (_.isNil(obj)) {
        // console.log('accessing nil', a.sourceString, b.sourceString);
      }
      else if (obj instanceof FormulaObjectProxy && obj.args.type == 'array' && ['filter','map'].includes(prop)) {
        if (prop == 'filter') {
          return func => {
            if (callStack) {
              callStack.calls[env.__blockId] = [];
            }
            const newArray = [];
            for (let i = 0; i < obj.get('length'); ++ i) {
              let call;
              if (callStack) {
                call = callStack.logFunctionEntry(env.__blockId, func.blockId, [obj.get(i), i], i);
              }
              const rv = func.blockId && call ? func(obj.get(i), i, call.cs) : func(obj.get(i), i);
              if (call) {
                call.returnValue = rv;
              }
              if (rv) {
                newArray.push(obj.get(i))
              }
            }
            return newArray;
          }
        }
        else if (prop == 'map') {
          return func => {
            if (callStack) {
              callStack.calls[env.__blockId] = [];
            }
            const newArray = [];
            for (let i = 0; i < obj.get('length'); ++ i) {
              let call;
              if (callStack) {
                call = callStack.logFunctionEntry(env.__blockId, func.blockId, [obj.get(i), i], i);
              }
              const rv = func.blockId && call ? func(obj.get(i), i, call.cs) : func(obj.get(i), i);
              if (call) {
                call.returnValue = rv;
              }

              newArray.push(rv)
            }
            return newArray;
          }
        }


      }
      else if (_.isArray(obj) || XObject.isArray(obj)) {
        prop = prop.toLowerCase();
        if (prop == 'map') {
          return func => {
            if (callStack) {
              callStack.calls[env.__blockId] = [];
            }
            const newArray = [];
            for (let i = 0; i < obj.length; ++ i) {
              let call;
              if (callStack) {
                call = callStack.logFunctionEntry(env.__blockId, func.blockId, [obj[i], i], i);
              }
              const rv = func.blockId && call ? func(obj[i], i, call.cs) : func(obj[i], i);
              if (call) {
                call.returnValue = rv;
              }
              newArray.push(rv)
            }
            return newArray;
          }
        }
        else if (prop == 'push') {
          return func => obj.push(func);
        }
        else if (prop == 'includes') {
          return el => obj.includes(el);
        }
        else if (prop == 'filter') {
          return func => {
            if (callStack) {
              callStack.calls[env.__blockId] = [];
            }
            const newArray = [];
            for (let i = 0; i < obj.length; ++ i) {
              let call;
              if (callStack) {
                call = callStack.logFunctionEntry(env.__blockId, func.blockId, [obj[i], i], i);
              }
              const rv = func.blockId && call ? func(obj[i], i, call.cs) : func(obj[i], i);
              if (call) {
                call.returnValue = rv;
              }
              if (rv) {
                newArray.push(obj[i])
              }
            }
            return newArray;
          }

        }
        else if (prop == 'find') {
          return func => {

            for (let i = 0; i < obj.length; ++ i) {
              let call;
              if (callStack) {
                call = callStack.logFunctionEntry(env.__blockId, func.blockId, [obj[i], i], i);
              }
              const rv = func.blockId && call ? func(obj[i], i, call.cs) : func(obj[i], i);
              if (call) {
                call.returnValue = rv;
              }
              if (rv) {
                return obj[i];
              }
            }
          }
        }
        else if (prop == 'findindex') {
          return func => {

            for (let i = 0; i < obj.length; ++ i) {
              let call;
              if (callStack) {
                call = callStack.logFunctionEntry(env.__blockId, func.blockId, [obj[i], i], i);
              }
              const rv = func.blockId && call ? func(obj[i], i, call.cs) : func(obj[i], i);
              if (call) {
                call.returnValue = rv;
              }
              if (rv) {
                return i;
              }
            }
          }

          // return func => obj.findIndex(func);
        }
        else if (prop == 'sort') {
          return func => obj.sort(func);
        }
        else if (prop == 'length') {
          return obj.length;
        }
      }
      else if (_.isPlainObject(obj)) {
        return obj[prop];
      }
      else if (_.isString(obj)) {
        if (prop == 'Length') {
          return obj.length;
        }
      }
      else if (_.isDate(obj)) {
        if (prop == 'format') {
          return format => (obj as any).format(unwrapString(format))
        }
      }
      else {
        for (const handler of (hooks.accessorHandlers || []).concat(formulaAccessorHandlers)) {
          if (handler.test(obj)) {
            const propType = getType(prop);
            if (propType == 'identifier') {
              return handler.perform(obj, prop);
            }
            else {
              return handler.perform(obj, b.eval()[0]);
            }
          }
        }
      }

      const propType = getType(prop);
      let p;
      if (propType == 'identifier') {
        p = prop;
      }
      else {
        p = b.eval()[0];
        return obj[b.eval()[0]];
      }

      if (_.isNil(obj)) {
        // console.log('accessing nil!!', b)
      }
      else {
        const r = obj[p];
        if (_.isFunction(r)) {
          const f = r.bind(obj);
          f['_fromScript'] = r['_fromScript'];
          f['_functionName'] = r['_functionName'];
          f['instanceId'] = r['instanceId'];
          return f;
        }
        else {
          return r;
        }  
      }
    },
    Primary_bracketAccessor(a, __, b, ___) {
      const obj = a.eval();
      const prop = unwrapString(b.eval());

      // if (obj instanceof FormulaObjectWrapper || obj instanceof FormulaObjectProxy) {
      //   return obj.get(prop);
      // }
      if (_.isNil(obj)) {
        // console.log('asdf accessing nil', a.sourceString, prop);
        return null;
      }
      else if (_.isPlainObject(obj) ||  _.isArray(obj)) {
        return obj[prop];
      }
      else {
        for (const handler of (hooks.accessorHandlers || []).concat(formulaAccessorHandlers)) {
          if (handler.test(obj)) {
            return handler.perform(obj, prop);
          }
        }
  
      }
    },
    Primary_tertiary(condition, _s1, _q, _s2, first, _s3, _c, _s4, second) {
      return condition.eval() ? first.eval() : second.eval();
    },
    InterpolatedString(a, b, c, d) {
      
      const str = b.eval().join('') + c.eval().map(i => _.isArray(i) ? i.map(unwrapString).join('') : unwrapString(i)).join('');

      if (hooks.traceStrings) {
        return new StringWrapper(str, env.__blockId, env.__instanceId);

      }
      else {
        return str;
      }
    },
    InterpolatedSegment_interpolation(aa, a, b, c) {
      return unwrapString(b.eval());

    },
    InterpolatedSegment_text(a) {
      return unwrapString(a.eval());
    },
    Element_onlyOpening(a, tagName, attributes, b, children) {

      let tag;
      if (tagName.sourceString.startsWith('_$')) {
        tag = tagName.eval();
      }
      else if (tagName.sourceString[0] == tagName.sourceString[0].toUpperCase()) {
        tag = doIdent(tagName);
      }
      else {
        tag = tagName.sourceString;
      }

      return new El(tag, attributes.eval(), children.eval(), env.__blockId, env.__instanceId, tagName.sourceString, env.__scopeId, callStack, runtimeContext);
    },
    Element_withChildren(a, tagName, attributes, aa, children, b, c, d) {
      let tag;
      if (tagName.sourceString.startsWith('_$')) {
        tag = tagName.eval();
      }
      else if (tagName.sourceString[0] == tagName.sourceString[0].toUpperCase()) {
        tag = doIdent(tagName);
      }
      else {
        tag = tagName.sourceString
      }

      return new El(tag, attributes.eval(), children.eval(), env.__blockId, env.__instanceId, tagName.sourceString, env.__scopeId, callStack, runtimeContext);
    },
    Element_asdf(a, children, c) {
      return children.eval();
    },
    Attribute_pair(name, a, value) {
      return [name.sourceString, value.eval()[0]];
    },
    Text(a) {
      if (hooks.traceStrings) {
        return new StringWrapper(a.sourceString, env.__blockId, env.__instanceId);
      }
      else {
        return a.sourceString;

      }
    },
    AttributeValue_expr(a, expr, b) {
      return expr.eval();
    },
    Lt_one(a, b, c) {
      return a.eval() < c.eval();
    },
    Lte_one(a, b, c) {
      return a.eval() <= c.eval();
    },
  })
  
  try {
    const match = (input in cache) ? cache[input] : (cache[input] = grammar.match(input, startRule));
    return semantics(match).eval();
  }
  catch (e) {
    console.log(e);
    return 'error!';
  }
}
export function expandFormula(input: Data, hooks: Hooks = {}) {
  return expandToText({
    types: {
      ..._types,
      ...(hooks.types || {}),
    }
  }, input)['replace'](/[^\u0000-\u007E]/g, " ");
}
