import { EntityDisplay } from "../../components/EntityDisplay";
import { db } from "../../db";
import { getAllEntities, getEntityById } from "../../etc/createEntity";
import { queryGraph } from "../../etc/queryGraph";
import { ObjectRefClass, ObjectType } from "../../types/ObjectRef";
import { ValueType } from "../ValueType";
import { CompiledValuePoint, evaluate, mapStructure } from "../main";
import { structEvalutors, structRenderers } from "../structRenderers";
import { typeRegistry } from "../typeRegistry.1";

const $GraphEntry = typeRegistry.registerType({
  $: '0e929bed-f4f4-5d71-8f23-b289443a9400',
  Entity: 'b0ef5c56-3da6-5ec3-8dbb-d7b1e6652d00',
  Graph: '35a444d8-06d2-59c9-9c2d-eff1b7289441',
  ExcludeRoot: '0daf5551-9174-53e3-93dd-bdbb03948ff7',
}, ids => ({
  _id: ids.$,
  name: '[Entity Query] Graph Entry',
  definition: [
    {
      id: ids.Entity,
      name: 'Entity',
      type: [],
      property: 'entity',
    },
    {
      id: ids.Graph,
      name: 'Graph',
      type: [],
      property: 'graph',
    },
    {
      id: ids.ExcludeRoot,
      name: 'Exclude Root',
      type: [],
      property: 'excludeRoot',
    }
  ]
}));

export const $Stateful = typeRegistry.registerType({
  $: '211c11fa-d7bc-58c2-8711-fb2905a2b2c9',
}, ids => ({
  _id: ids.$,
  name: '[Entity Query] Stateful',
  definition: [],

}));

export const $Attribute = typeRegistry.registerType({
  $: '36009a66-1847-5d3e-af85-5d6fb97293fb',
  Attribute: 'fa700ab6-3817-558a-911c-48378e55a747',
  Value: '881d254c-354d-50b2-8770-91c0bb90eb14',
  Not: '93e3cded-5bc7-5cab-9fa7-4e5b22a2c766',
}, ids => ({
  _id: ids.$,
  name: '[Entity Query] Attribute',
  definition: [
    {
      id: ids.Attribute,
      name: 'Attribute',
      type: [],
      property: 'attribute',
    },
    {
      id: ids.Value,
      name: 'Value',
      type: [],
      property: 'value',
    },
    {
      id: ids.Not,
      name: 'Not',
      type: [],
      property: 'not',
    },
  ]
}));

const $Space = typeRegistry.registerType({
  $: '0b68a032-94d0-5b99-9af2-8fd1d666459c',
  Space: '9dbf2b51-ef52-58ac-8659-f4e4385fbaec',
}, ids => ({
  _id: ids.$,
  name: '[Entity Query] Space',
  definition: [
    {
      id: ids.Space,
      name: 'Space',
      type: [],
      property: 'space',
    },
  ]
}));


const $State = typeRegistry.registerType({
  $: '4343430e-6b2e-59ae-ae82-cf0e2a45efcf',
  State: '6a04157e-b669-5d46-910c-2d3bccb05558',
  Not: '562dcf01-16ac-5034-8a2c-c0763ad29aeb',
}, ids => ({
  _id: ids.$,
  name: '[Entity Query] State',
  definition: [
    {
      id: ids.State,
      name: 'State',
      type: [],
      property: 'state',
    },
    {
      id: ids.Not,
      name: 'Not',
      type: [],
      property: 'not',
    }
  ]
}));


export const $EntityQuery = typeRegistry.registerType({
  $: 'ffd3d97c-3042-5960-bd30-35e4fabe2ee7',
  Entries: '48724105-4b77-503d-b8a0-04589e99304e',
  ChainedEntity: '8f10b0ee-de6a-5260-bcb4-46ae984f9ece',
}, ids => ({
  _id: ids.$,
  name: 'Entity Query',
  definition: [
    {
      scope: [
        {
          id: ids.ChainedEntity,
          name: 'Chained Entity',
        }
      ],
      id: ids.Entries,
      name: 'Entries',
      type: [ValueType.Array, []],
      property: 'entries',
    }
  ],
}));

const getStateType = stateValue => {
  return db.stateTypes.find(s => s.values.find(v => v._id == stateValue));
}

export function doEntityQuery(value: CompiledValuePoint, map={}) {
  const mapped = mapStructure(value);
  let results = [];
  let i = 0;
  for (const entry of mapped.entries.content || []) {
    const evaluated = evaluate(entry, map);
    if (entry.type[0] == ValueType.EntityType) {
      if (i == 0) {
        results = getAllEntities().filter(e => !e.__deleted && e.type == entry.content).map(e => e._id);
      }
      else {
        results = results.filter(e => {
          const entity = getEntityById(e);
          return entity.type == entry.content;
        });
      }
    }
    else if (entry.type[1] == $GraphEntry.$) {
      results = queryGraph(evaluated.graph, evaluated.entity, !evaluated.excludeRoot).map(r => r.entity);
    }
    else if (entry.type[1] == $Attribute.$) {
      const mapped = mapStructure(entry);
      const attribute = evaluate(mapped.attribute, map);
      const value = evaluate(mapped.value, map);
      const not = mapped.not && evaluate(mapped.not, map);
      const test = e => {
        if (not) {
          return e.attributes?.[attribute] != value
        }
        else {
          return e.attributes?.[attribute] == value
        }
      }
      if (i == 0) {
        results = getAllEntities().filter(test).map(e => e._id);
      }
      else {
        results = results.filter(e => {
          const entity = getEntityById(e);
          return test(entity);
        });
      }
    }
    else if (entry.type[1] == $State.$) {
      const mapped = mapStructure(entry);
      const state = evaluate(mapped.state, map);
      const stateType = getStateType(state);
      if (stateType) {
        const not = evaluate(mapped.not, map);
        const test = e => {
          if (not) {
            return e.states?.[stateType._id] != state
          }
          else {
            return e.states?.[stateType._id] == state
          }
        }
        if (i == 0) {
          results = getAllEntities().filter(test).map(e => e._id);
        }
        else {
          results = results.filter(e => {
            const entity = getEntityById(e);
            return test(entity);
          });
        }  
      }
      else {
        // throw new Error(`State ${state} not found`);
      }
    }
    else if (entry.type[1] == $Space.$) {
      const mapped = mapStructure(entry);
      const space = evaluate(mapped.space, map);

      if (i == 0) {
        results = getAllEntities().filter(e => e.space == space).map(e => e._id);
      }
      else {
        results = results.filter(e => {
          const entity = getEntityById(e);
          return entity.space == space;
        });
      }
    }

      

    ++ i;
  }
  return results;
}

export function doCreateEntityFromQuery(entity, value: CompiledValuePoint, map={}) {
  console.log(map);
  const mapped = mapStructure(value);
  for (const entry of mapped.entries.content || []) {
    const evaluated = evaluate(entry, map);
    if (entry.type[0] == ValueType.EntityType) {
      entity.type = entry.content;
    }
    else if (entry.type[1] == $GraphEntry.$) {
      if (!entity.$edges) {
        entity.$edges = [];
      }
      entity.$edges.push({
        from: evaluated.entity,
        directed: true,
      });
      entity.$from = evaluated.entity;
    }
    else if (entry.type[1] == $Attribute.$) {
      const mapped = mapStructure(entry);
      const attribute = evaluate(mapped.attribute, map);
      const value = evaluate(mapped.value, map);
      if (!entity.attributes)
        entity.attributes = {};
      entity.attributes[attribute] = value;
    }
    else if (entry.type[1] == $State.$) {
    }
    else if (entry.type[1] == $Space.$) {
      const mapped = mapStructure(entry);
      const space = evaluate(mapped.space, map);
      entity.space = space;
    }
  }

  return true;
}




structRenderers[$EntityQuery.$] = (value, map, state) => {
  const results = doEntityQuery(value);
  return (
    <>
      <ul>
        {results.map(r => (
          <li key={r}>
            <EntityDisplay id={r} />
            {/* {getEntityById(r).name} */}
          </li>
        ))}


      </ul>

    </>
  );
};

structEvalutors[$EntityQuery.$] = (value, map) => {
  const results = doEntityQuery(value, map);
  return results.map(r => new ObjectRefClass(ObjectType.entity, r))
}