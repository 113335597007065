import { db } from './db';
import { x, XObject } from './XObject';
import { appState } from './etc/appState';
import { entityQueries } from './components/EntityInspect';
import { entityDisplayName } from './components/entityDisplayName';
import { createQuery, describeQuery } from './etc/queryFuncs';
import { cssImg } from './img';
import { getSidebar } from './etc/getSidebar';
import { SidebarItemType } from './types/SidebarItemType';
import { ObjectType } from './types/ObjectRef';
import { collectEntities } from "./etc/collectEntities";
import { PaneType } from './types/PaneType';
import { iconColor } from "./color";
import { notionMode, resumeMode } from './resumeMode';
import { explicitInspectObj, inspectObj } from './inspectObj';
import { SidebarItem } from './components/Sidebar';
import { entityTypeName } from './etc/entityTypeName';
import { deleteObject } from './etc/deleteEntity';
import { showContextMenu } from './helpers';
import { createEntity, getEntityById } from './etc/createEntity';
import { allDays } from './components/allDays';
import { openWindow } from './osHelpers';
import { WindowType } from './etc/WindowType';

class SpaceSidebarItem extends SidebarItem {
  space;
  constructor(data) {
    super(data);
    this.space = db.spaces.findById(data.space);
  }
  getId() {
    return this.data._id;
  }
  getTitle() {
    return this.space?.name;
  }

  getNav() {
    return {
      type: 'spaceEntities',
      space: this.space?._id,
    };
  }

  getIcon() {
    return cssImg('box', {
      '#000000': iconColor,
    });
  }

  getChildren() {
    return this.space?.queries?.map?.(q => {
      const query = db.queries.findById(q);
      if (!query)
        return null;
      return new QuerySidebarItem({
        id: query._id,
      });
    })?.filter?.(Boolean) || [];
  }

  contextMenu() {
    return [
      {
        text: 'Add query',
        onClick: () => {
          const query = XObject.obj({
            name: 'New query',
            parentType: ObjectType.space,
            parent: this.space._id,
            views: [],
          });
          db.queries.push(query);
          XObject.push(this.space, 'queries', query._id);
        },
      },
      // {
      //   text: 'Edit',
      //   onClick: () => {
      //     openWindow({
      //       type: WindowType.SpaceWindow,
      //       space: this.space._id,
      //     });
      //   }
      // }
    ];
  }
}

class DaySidebarItem extends SidebarItem {
  day
  constructor(data) {
    super(data);
    this.day = db.days.findById(data.id);
  }

  getTitle(): string {
    return this.day.date;
  }

  getNav() {
    return {
      type: PaneType.day,
      id: this.day._id,
    };
  }

  getIcon() {
    return cssImg('icons8-day', {
      '#000000': iconColor,
    });
  }
}

class QuerySidebarItem extends SidebarItem {
  query;
  constructor(data: {
    id
    entity?
  }) {
    super(data);
    this.query = db.queries.findById(data.id);
  }
  getChildren(): SidebarItem[] {
    const children = db.queries.filter(q => q.parent == this.data.id);
    return children.map(x => new QuerySidebarItem({
      id: x._id,
    }));
  }

  isDeleted(): boolean {
    return this.query?.__deleted || !this.query;
  }

  getIcon() {
    return cssImg('database', {
      '#000000': iconColor,
    });
  }

  getId() {
    return this.data.id;
  }

  getTitle(): string {
    if (!this.query)
      return '---';
    return this.query.name || describeQuery(this.query.query);
  }

  getNav() {
    return {
      type: 'query',
      id: this.data.id,
      entity: this.data.entity,
    };
  }

  contextMenu() {
    const query = this.query;
    return [
      {
        text: 'Edit',
        onClick: () => {
          explicitInspectObj({
            type: ObjectType.query,
            id: query._id,
          });
        }
      },
      {
        text: 'Delete',
        onClick: () => {
          deleteObject(query);
          // db.queries.splice(db.queries.indexOf(query), 1);
          // if (this.data.entity) {
          //   const entity = getEntityById(this.data.entity);
          //   entity.queries.splice(entity.queries.findIndex(x => x.query == query._id), 1);
          // }
        }
      },
      {
        text: 'Add subquery',
        onClick: () => {
          db.queries.push(XObject.obj({
            name: 'New query',
            views: [],
            parent: query._id,
          }));
        }
      },
      // {
      //   text: 'Add to sidebar',
      //   onClick: () => {
      //     getSidebar().children.push(XObject.obj({
      //       type: SidebarItemType.query,
      //       id: query._id,
      //     }));
      //   }
      // }
    ];
  }
}
class DocumentSidebarItem extends SidebarItem {
  page;
  constructor(data) {
    super(data);
    if (this.data.entity) {
      const entity = getEntityById(data.entity);
      this.page = entity.documents.find(doc => doc._id == data.id);

    }
    else {
      this.page = db.notionDocuments.findById(data.id);
    }
  }
  getId(): string {
    return this.data.id;
  }
  getTitle(): string {
    return this.page?.name || 'Untitled';
  }

  getIcon() {
    return cssImg('icons8-page', {
      '#000000': iconColor,
    });
  }

  isDeleted(): boolean {
    return this.page.__deleted;
  }

  getChildren() {
    if (!this.page)
      return [];
    const entities = collectEntities(this.page.content || this.page.blocks || []);

    if (entities.length) {
      return [
        new PageEntitiesSidebarItem({
          ...this.data,
        }),
      ];
    }
    else {
      return [];
    }
  }

  contextMenu() {
    return [
      {
        text: 'Delete',
        onClick: () => {
          deleteObject(this.page);
        }
      }
      // {
      //   text: 'Edit',
      //   onClick: () => {
      //     openWindow({
      //       type: WindowType.EditDocument,
      //       document: this.page._id,
      //     });
      //   }
      // }
    ];
  }

  getNav() {
    if (!this.page)
      return {};
    if (this.data.entity) {
      return {
        type: '146a4264-a507-5fb9-b7d3-e59ee00fb050',
        entity: this.data.entity,
        document: this.data.id,
      };
    }
    else {
      return {
        type: 'page',
        id: this.page._id,
      };
    }
  }
}
class PageEntitiesSidebarItem extends SidebarItem {
  constructor(data) {
    super(data);
  }
  getIcon() {
    return cssImg('tesseract', {
      '#000000': iconColor,
    });
  }

  getTitle() {
    return 'Entities';
  }

  getNav() {
    return {
      type: PaneType.pageEntities,
      ...this.data,
    };
  }
  getChildren() {
    if (this.data.entity) {
      const entity = getEntityById(this.data.entity);
      const doc = entity.documents.find(doc => doc._id == this.data.id);
      return (doc.queries?.map?.(q => {
        const query = db.queries.findById(q);
        if (!query)
          return null;
        return new QuerySidebarItem({
          id: query._id,
        });
      }) || []).filter(Boolean);
    }
    else {
      const doc = db.notionDocuments.findById(this.data.id);
      return (doc.queries?.map?.(q => {
        const query = db.queries.findById(q);
        if (!query)
          return null;
        return new QuerySidebarItem({
          id: query._id,
        });
      }) || []).filter(Boolean);
    }
  }
  contextMenu() {
    const entity = getEntityById(this.data.entity);
    const doc = entity.documents.find(doc => doc._id == this.data.id);
    return [
      {
        text: 'Add query',
        onClick: () => {
          const query = XObject.obj({
            name: 'New query',
            parentType: ObjectType.document,
            parent: [this.data.entity, this.data.id],
            views: [],
          });
          db.queries.push(query);
          XObject.push(doc, 'queries', query._id);
        }
      }
    ];
  }
}
class EntitySidebarItem extends SidebarItem {
  constructor(data: {
    id
  }) {
    super(data);
    this.entity = getEntityById(data.id);
  }
  entity;
  getChildren(): SidebarItem[] {
    if (!this.entity)
      return [];
    const children = [];
    if (this.entity.documents) {
      for (const doc of this.entity.documents) {
        children.push(new DocumentSidebarItem({
          entity: this.entity._id,
          id: doc._id,
        }));
      }
    }

    const pages = db.notionDocuments.filter(doc => doc.parent?.id == this.entity._id);
    for (const doc of pages) {
      children.push(new DocumentSidebarItem({
        id: doc._id,
      }));
    }

    const queries = entityQueries(this.entity._id);

    for (const query of queries) {
      children.push(new QuerySidebarItem({
        id: query,
        entity: this.entity._id,
      }));
    }

    return children;
  }

  getIcon() {
    return cssImg('tesseract', {
      '#000000': iconColor,
    });
  }

  getId() {
    return this.entity?._id;
  }

  getTitle() {
    return this.entity && entityDisplayName(this.entity._id) || 'Untitled';
  }

  contextMenu() {
    return [
      {
        text: 'Add page',
        onClick: () => {
          db.notionDocuments.push(XObject.obj({
            parent: {
              type: ObjectType.entity,
              id: this.entity._id,
            },
            name: 'new doc',
            blocks: [],
          }));

        }
      },
      {
        text: 'Add query',
        onClick: () => {
          const query = createQuery(null, ['58c22695-b585-5f44-93f9-1fd1e135f2e5', this.entity._id], null, true);
          XObject.push(this.entity, 'queries', XObject.obj({
            query: query._id,
          }));

        }
      },
      {
        text: 'Delete',
        onClick: () => {
          deleteObject(this.entity);
        }
      }
    ];
  }

  onClick() {
    appState.inspecting = {
      type: 'entity',
      id: this.entity._id,
    };
  }

  getNav() {

    return {
      type: 'entity',
      id: this.entity?._id,
    };

  }

  getBadge() {
    return this.entity.type && entityTypeName(this.entity.type);
  }

  isDeleted() {
    return !this.entity || this.entity.__deleted;
  }
}


class NoteSidebarItem extends SidebarItem {
  constructor(data: {
    id
  }) {
    super(data);
    this.entity = db.notes.findById(data.id);
  }
  entity;


  getIcon() {
    return cssImg('icons8-note', {
      '#000000': iconColor,
    });
  }

  getId() {
    return this.entity?._id;
  }

  getTitle() {
    return 'note';
  }


  // onClick() {
  //   appState.inspecting = {
  //     type: 'entity',
  //     id: this.entity._id,
  //   };
  // }

  getNav() {

    return {
      type: PaneType.note,
      id: this.entity?._id,
    };

  }

  // getBadge() {
  //   return this.entity.type && entityTypeName(this.entity.type);
  // }

  isDeleted() {
    return !this.entity || this.entity.__deleted;
  }
}
class ErrorSidebarItem extends SidebarItem {
  constructor(data, i) {
    super(data);
  }
  getIcon() {
    // return cssImg('error', {
    //   '#000000': iconColor,
    // })
  }

  getTitle() {
    return 'Error';
  }

  getNav() {
    return {
      type: 'error',
    };
  }

  getChildren() {
    return [];
  }
}

class LibrarySidebarItem extends SidebarItem {
  library;
  constructor(data) {
    super(data);
    this.library = db.libraries.findById(data.library);
  }
  getId() {
    return this.library._id;
  }
  getIcon() {
    return cssImg('bundle', {
      '#000000': iconColor,
    });
  }
  getTitle() {
    return this.library.name;
  }

  getNav() {
    return {
      type: PaneType.library,
      id: this.library._id,
    };
  }
}
class ChatGPTSidebarItem extends SidebarItem {
  getId() {
    return 'chatGPT';
  }

  getIcon() {
    return cssImg('ai', {
      '#000000': 'rgb(167, 130, 195)',
    });
  }
  getTitle() {
    return 'Chat';
  }

  getNav() {
    return {
      type: PaneType.chatGPT
    };

  }
}
class AppLibrarySidebarItem extends SidebarItem {
  constructor(data) {
    super(data);
  }
  getId() {
    return 'appLibrary';
  }
  getIcon() {
    return cssImg('bundle', {
      '#000000': iconColor,
    });
  }
  getTitle() {
    return 'App Library';
  }

  getNav() {
    return {
      type: PaneType.appLibrary
    };
  }
}

class AddSidebarItem extends SidebarItem {
  constructor(data) {
    super(data);
    this.clickAction = 'handleClick';
  }
  getTitle(): string {
    return 'Add';
  }
  getIcon() {
    return cssImg('icons8-create (2)', {
      '#000000': 'rgb(136, 164, 255)',
    });
  }
  color() {
    return 'rgb(136, 164, 255)';
  }
  onClick(e): void {
    showContextMenu(e, [
      {
        text: 'Add document',
        onClick: () => {
          const doc = XObject.obj({
            name: '',
            blocks: [],
            type: 'de89bc9c-9fb1-579d-8954-53f1519cbb33',
          });
          db.notionDocuments.push(doc);

          getSidebar().children.push(XObject.obj({
            type: SidebarItemType.document,
            id: doc._id,
          }));

        }
      },
      // !notionMode.enabled && {
      //   text: 'Add space',
      //   onClick: () => {
      //     const space = XObject.obj({
      //       name: 'new space',
      //     });
      //     db.spaces.push(space);
      //     getSidebar().children.push(XObject.obj({
      //       type: SidebarItemType.space,
      //       space: space._id,
      //     }));
      //   }
      // },
      // !notionMode.enabled && {
      //   text: 'Add library',
      //   onClick: () => {
      //     const library = XObject.obj({
      //       name: 'new library',
      //     });
      //     db.libraries.push(library);
      //     getSidebar().children.push(XObject.obj({
      //       type: SidebarItemType.library,
      //       library: library._id,
      //     }));
      //   }
      // },
      !notionMode.enabled && {
        text: 'Add entity',
        onClick: () => {
          const entity = createEntity({
            name: '',
          }, null);
          getSidebar().children.push(XObject.obj({
            type: SidebarItemType.entity,
            id: entity._id,
          }));

        }
      },

      !notionMode.enabled && {
        text: 'Add query',
        onClick: () => {
          const q = createQuery(null, null, appState.currentMode && {
            type: ObjectType.mode,
            id: appState.currentMode
          });
          getSidebar().children.push(XObject.obj({
            type: SidebarItemType.query,
            id: q._id,
          }));
        }
      },

      {
        text: 'Add page',
        onClick: () => {
          const page = XObject.obj({
            version: '65de94f4-2c38-5760-9f9a-30a89228b558',
          });
          db.pages2.push(page);
          getSidebar().children.push(XObject.obj({
            type: SidebarItemType.page,
            id: page._id,
          }));
        }
      },
      {
        text: 'Add table',
        onClick: () => {
          const table = XObject.obj();
          db.tables.push(table);
          getSidebar().children.push(XObject.obj({
            type: SidebarItemType.table,
            id: table._id,
          }));
        }
      },
      
      {
        text: 'Add app',
        onClick: () => {
          const app = XObject.obj<any>();
          db.apps.push(app);
          getSidebar().children.push(XObject.obj({
            type: SidebarItemType.app,
            id: app._id,
          }));
        }
      },

      {
        text: 'Add workspace',
        onClick: () => {
          const workspace = XObject.obj<any>({
            type: ObjectType.workspace,
          });
          db.dataObjects.push(workspace);
          getSidebar().children.push(XObject.obj({
            type: SidebarItemType.workspace,
            id: workspace._id,
          }));
        }
      },


      {
        text: 'Add dev project',
        onClick: () => {
          const workspace = XObject.obj<any>({
            type: ObjectType.devProject,
          });
          db.dataObjects.push(workspace);
          getSidebar().children.push(XObject.obj({
            type: SidebarItemType.devProject,
            id: workspace._id,
          }));
        }
      },
      {
        text: 'Add canvas',
        onClick: () => {
          const canvas = XObject.obj({
            name: 'New canvas',
            version: 'ab599af3-6f75-54c2-bbe9-aa25055959e1',
          });
          db.canvases.push(canvas);
          getSidebar().children.push(XObject.obj({
            type: SidebarItemType.canvas,
            id: canvas._id,
          }));
        }
      },


    ], 'bottom');
  }
}

class CanvasSidebarItem extends SidebarItem {
  constructor(data) {
    super(data);
    this.canvas = db.canvases.findById(data.id);
  }
  canvas;

  getIcon() {
    return cssImg('icons8-canvas', {
      '#000000': iconColor,
    });

  }
  getTitle() {
    return this.canvas.name;
  }
  onClick() {
    // getSidebar().children.push(XObject.obj({
    //   type: SidebarItemType.canvas,
    // }));
  }

  getNav() {
    return {
      type: PaneType.canvas,
      id: this.canvas._id,
    }
  }
}

class PageSidebarItem extends SidebarItem {
  constructor(data) {
    super(data);
    this.page = db.pages2.findById(data.id);
  }
  page;

  getIcon() {
    return cssImg('icons8-web-page', {
      '#000000': iconColor,
    });

  }
  getTitle() {
    return this.page.name;
  }
  onClick() {
    // getSidebar().children.push(XObject.obj({
    //   type: SidebarItemType.canvas,
    // }));
  }

  getChildren() {
    const childrenPages = db.pages2.filter(p => p.parent?.id == this.page._id);

    return childrenPages.map(p => new PageSidebarItem({
      id: p._id,
    }))
  }
  contextMenu() {
    return [
      {
        text: 'Add page',
        onClick: () => {
          const page = XObject.obj({
            version: '65de94f4-2c38-5760-9f9a-30a89228b558',
            parent: {
              type: ObjectType.page,
              id: this.page._id,
            }
          });

          db.pages2.push(page);
        },
      }
    ]
  }

  getNav() {
    return {
      type: PaneType.page,
      id: this.page._id,
    }
  }
}

class WorkspaceSidebarItem extends SidebarItem {
  constructor(data) {
    super(data);
    this.workspace = db.dataObjects.findById(data.id);
  }
  workspace;

  getIcon() {
    return cssImg('icons8-app-icon', {
      '#000000': iconColor,
    });

  }
  getTitle() {
    return this.workspace.name;
  }
  onClick() {
    // getSidebar().children.push(XObject.obj({
    //   type: SidebarItemType.canvas,
    // }));
  }

  getChildren() {
    return [];
    // const childrenPages = db.pages2.filter(p => p.parent?.id == this.page._id);

    // return childrenPages.map(p => new PageSidebarItem({
    //   id: p._id,
    // }))
  }
  contextMenu() {
    return [
      // {
      //   text: 'Add page',
      //   onClick: () => {
      //     const page = XObject.obj({
      //       version: '65de94f4-2c38-5760-9f9a-30a89228b558',
      //       parent: {
      //         type: ObjectType.page,
      //         id: this.page._id,
      //       }
      //     });

      //     db.pages2.push(page);
      //   },
      // }
    ]
  }

  getNav() {
    return {
      type: PaneType.workspace,
      id: this.workspace._id,
    }
  }
}

class DevProjectSidebarItem extends SidebarItem {
  constructor(data) {
    super(data);
    this.workspace = db.dataObjects.findById(data.id);
  }
  workspace;

  getIcon() {
    return cssImg('icons8-app-icon', {
      '#000000': iconColor,
    });

  }
  getTitle() {
    return this.workspace.name;
  }
  onClick() {
    // getSidebar().children.push(XObject.obj({
    //   type: SidebarItemType.canvas,
    // }));
  }

  getChildren() {
    return [];
    // const childrenPages = db.pages2.filter(p => p.parent?.id == this.page._id);

    // return childrenPages.map(p => new PageSidebarItem({
    //   id: p._id,
    // }))
  }
  contextMenu() {
    return [
      {
        text: 'Open window',
        onClick: () => {
          openWindow({
            type: WindowType.DevProject,
            id: this.data.id,
          })
        },  
      },
      // {
      //   text: 'Add page',
      //   onClick: () => {
      //     const page = XObject.obj({
      //       version: '65de94f4-2c38-5760-9f9a-30a89228b558',
      //       parent: {
      //         type: ObjectType.page,
      //         id: this.page._id,
      //       }
      //     });

      //     db.pages2.push(page);
      //   },
      // }
    ]
  }

  getNav() {
    return {
      type: PaneType.devProject,
      id: this.workspace._id,
    }
  }
}

class AppSidebarItem extends SidebarItem {
  app
  constructor(data) {
    super(data);
    this.app = db.apps.findById(data.id);
  }

  getIcon() {
    return cssImg('icons8-app-icon', {
      '#000000': iconColor,
    });
  }

  getTitle() {
    return this.app.name;
  }

  getNav() {
    return {
      type: PaneType.app,
      id: this.app._id,
    }
  }
}

class TableSidebarItem extends SidebarItem {
  table
  constructor(data) {
    super(data);
    this.table = db.tables.findById(data.id);
  }

  getIcon() {
    return cssImg('icons8-table', {
      '#000000': iconColor,
    });
  }

  getTitle() {
    return this.table.name;
  }

  getNav() {
    return {
      type: PaneType.table,
      id: this.table._id,
    }
  }
}

export class RootSidebarItem extends SidebarItem {
  constructor() {
    super(null);
    this.sidebar = getSidebar();
  }
  sidebar;

  ignoreChildren(): boolean {
    return true;
  }

  fixedItems() {
    return [];
    if (resumeMode.enabled) {
      return [];
    }
    else {
      return [new ChatGPTSidebarItem(null)];
    }
  }

  moveChild(oldIndex: any, newIndex: any): void {
    oldIndex -= this.fixedItems().length;
    newIndex -= this.fixedItems().length;
    const children = x(this.sidebar.children);
    const child = children[oldIndex];
    children.splice(oldIndex, 1);
    children.splice(newIndex, 0, child);
    this.sidebar.children = children;
  }

  getChildren(): SidebarItem[] {
    return this.fixedItems().concat(this.sidebar.children.map((entry, i) => {
      if (!entry) {
        return new ErrorSidebarItem(entry, i);
      }
      if (entry.type == SidebarItemType.query) {
        return new QuerySidebarItem(entry);
      }
      else if (entry.type == SidebarItemType.entity) {
        return new EntitySidebarItem(entry);
      }
      else if (entry.type == SidebarItemType.space) {
        return new SpaceSidebarItem(entry);
      }
      else if (entry.type == SidebarItemType.document) {
        return new DocumentSidebarItem(entry);
      }
      else if (entry.type == SidebarItemType.pageEntities) {
        return new PageEntitiesSidebarItem(entry);
      }
      else if (entry.type == SidebarItemType.library) {
        return new LibrarySidebarItem(entry);
      }
      else if (entry.type == SidebarItemType.canvas) {
        return new CanvasSidebarItem(entry);
      }
      else if (entry.type == SidebarItemType.page) {
        return new PageSidebarItem(entry);
      }
      else if (entry.type == SidebarItemType.app) {
        return new AppSidebarItem(entry);
      }
      else if (entry.type == SidebarItemType.table) {
        return new TableSidebarItem(entry);
      }
      else if (entry.type == SidebarItemType.workspace) {
        return new WorkspaceSidebarItem(entry);
      }
      else if (entry.type == SidebarItemType.devProject) {
        return new DevProjectSidebarItem(entry);
      }

      else if (entry.type == SidebarItemType.error) {
      }

    })).concat(new AddSidebarItem({}));
  }

  deleteItem(i: any): void {
    this.sidebar.children.splice(i - this.fixedItems().length, 1);
  }
}

export class FavoritesSidebar extends SidebarItem {
  constructor() {
    super(null);
  }

  fixedItems() {
    return [];
    if (resumeMode.enabled) {
      return [];
    }
    else {
      return [new ChatGPTSidebarItem(null)];
    }
  }


  moveChild(oldIndex: any, newIndex: any): void {
    // oldIndex -= this.fixedItems().length;
    // newIndex -= this.fixedItems().length;
    // const children = x(this.sidebar.children);
    // const child = children[oldIndex];
    // children.splice(oldIndex, 1);
    // children.splice(newIndex, 0, child);
    // this.sidebar.children = children;
  }
  getChildren(): SidebarItem[] {
    const mode = db.modes.findById(appState.currentMode);

    const days = allDays();
    const day = days[days.length - 1];

    return (day ? [
/*       new DaySidebarItem({
        id: day._id,
      }) */
    ] : []).concat(mode.favorites?.map?.((entry, i) => {
      if (!entry) {
        return new ErrorSidebarItem(entry, i);
      }
      else if (entry.type == ObjectType.query) {
        return new QuerySidebarItem({
          id: entry.id,
        });
      }
      else if (entry.type == ObjectType.page) {
        return new PageSidebarItem({
          id: entry.id,
        });
      }
      else if (entry.type == ObjectType.document) {
        return new DocumentSidebarItem({
          id: entry.id,
        });
      }
      else if (entry.type == ObjectType.event) {
        return null;
      }
      else if (entry.type == ObjectType.entity) {
        return new EntitySidebarItem({
          id: entry.id,
        });
      }
      else if (entry.type == ObjectType.note) {
        return new NoteSidebarItem({
          id: entry.id,
        });
      }
    })?.filter?.(Boolean) || []);
  }

  deleteItem(i: any): void {
    const mode = db.modes.findById(appState.currentMode);
    mode.favorites.splice(i, 1);
  }
}