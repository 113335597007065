import { mapStructure } from "../main";
import { structRenderers } from "../structRenderers";
import { typeRegistry } from "../typeRegistry.1";

export const $DateRangePicker = typeRegistry.registerType({
  $: '7c6fce89-ab68-50dc-982b-d29952717be1',
  Start: '64c6009d-3240-5a4c-b8de-7a24d0155376',
  End: 'd5941781-c8b0-5d6b-b086-5cf9beae0610',
}, ids => ({
  _id: ids.$,
  name: 'Date Range Picker',
  definition: [
    {
      id: ids.Start,
      name: 'Start',
      type: [],
      property: 'start',
    },
    {
      id: ids.End,
      name: 'End',
      type: [],
      property: 'end',
    },

  ],
}));

structRenderers[$DateRangePicker.$] = (value) => {
  const mapped = mapStructure(value);
  
  return (
    <div data-value-point={value._id}>
      <input type="date" defaultValue={value.rt.getState(mapped.start._id)} onChange={e => {
        value.rt.setState(mapped.start._id, e.target.value);
      }} /> - <input type="date" defaultValue={value.rt.getState(mapped.end._id)} onChange={e => {
        value.rt.setState(mapped.end._id, e.target.value);
      }} />  
    </div>
  )
}