import _ from 'lodash';
import React from 'react';
import { BlockPageCont } from './components/BlockPage';
import { db } from './db';
import { GlueDev } from './glue/main';
import { GlueWindow } from "./windows/GlueWindow";
import { getComponentCache } from './osHelpers';
import { XObject } from './XObject';
import { defaultWorkspace } from './etc/defaultWorkspace';
import { Root } from './components/Root';
import { AllGraph } from './components/AllGraph';
import { LinearWindow } from './components/LinearWindow';
import { QueueWindow } from './components/QueueWindow';
import { WindowType } from './etc/WindowType';
import { NavStackCont } from './components/QueryViewEditor';
import { AppLibrary } from './components/AppLibrary';
import { EditModeWindow } from './windows/EditModeWindow';
import { TestWindow } from './windows/TestWindow';
import { GraphsWindow } from './windows/GraphsWindow';
import { GraphWindow } from './windows/GraphWindow';
import { QueuesWindow } from './windows/QueuesWindow';
import { MetaStatesWindow } from './windows/MetaStatesWindow';
import { EntityQueuesWindow } from './windows/EntityQueuesWindow';
import { NotionWindow } from './windows/NotionWindow';
import { TypesWindow } from './TypesWindow';
import { AttributeEditor } from './windows/AttributeEditor';
import { DatabaseWindow } from './windows/DatabaseWindow';
import { InboxWindow } from './windows/InboxWindow';
import { UsersWindow } from './windows/UsersWindow';
import { EntityConfigWindow } from './windows/EntityConfigWindow';
import { SpaceWindow } from './windows/SpaceWindow';
import { EditDocumentWindow } from './windows/EditDocumentWindow';
import { QueryExpressionEditorWindow } from './windows/QueryExpressionEditorWindow';
import { QueryEditorWindow } from './windows/QueryEditorWindow';
import { ChatGPTTestWindow } from './windows/ChatGPTTestWindow';
import { TypeWindow } from './windows/TypeWindow';
import { SpacesWindow } from './windows/SpacesWindow';
import { MenuItemsWindow } from './windows/MenuItemsWindow';
import { SystemConfigWindow } from './windows/SystemConfigWindow';
import { DatabasesWindow } from './windows/DatabasesWindow';
import { OrgEntitiesWindow } from './windows/OrgEntitiesWindow';
import { DocumentWindow } from './windows/DocumentWindow';
import { NotionDocumentsWindow } from './windows/NotionDocumentsWindow';
import { NotionDocumentWindow } from './windows/NotionDocumentWindow';
import { NotionDocumentTestWindow } from './windows/NotionDocumentTestWindow';
import { CodeComponentWindow, CodeComponentsWindow, CodeTestWindow } from './App';
import { NotionDocument_CodePartEditorWindow } from './NotionDocument_CodePartEditorWindow';
import { DataEditorTestWindow } from './DataEditorTestWindow';
import '@tldraw/tldraw/editor.css'
import '@tldraw/tldraw/ui.css'
import { Whiteboard } from './Whiteboard';
import { MindMap } from './MindMap';
import { FormulaEditor } from './shorthandEditor/FormulaEditor';
import { ScopeWindow } from './ScopeWindow';
import { PinBoardWindow } from './PinBoardWindow';
import { WidgetWindow, WidgetsWindow } from './WidgetsWindow';
import { HomeScreen } from './components/HomeScreen';
import { ScriptWindow } from './ScriptWindow';
import { ShorthandWindow } from './ShorthandWindow';
import { CompletionsTest } from './CompletionsTest';
import { EmbeddingTest } from './EmbeddingTest';
import { WIP } from './WIP';
import { getEntityById } from './etc/createEntity';
import { DevProjectWindow } from './DevProjectWindow.1';

export const windowTypes = comp => ({
  test: {
    title: () => 'Test',
    component: win => { return getComponentCache(win, () => { return <TestWindow window={win} />; }); }
  },
  graphs: {
    title: () => 'Graphs',
    component: win => { return getComponentCache(win, () => { return <GraphsWindow window={win} />; }); }
  },
  graph: {
    title: (win) => {
      const workspace = defaultWorkspace();
      const graph = workspace.graphs.find(x => x._id == win.graph);
      return graph?.name || 'Graph';
    },
    component: win => { return getComponentCache(win, () => { return <GraphWindow window={win} />; }); }
  },
  [WindowType.Glue]: {
    title: (win) => GlueWindow.title(win, comp.context),
    component: win => { return getComponentCache(win, () => { return <GlueWindow win={win} />; }); }
  },
  root: {
    title: () => 'Root',
    component: win => {
      return getComponentCache(win, () => {
        return <Root root={{
          children: () => {
            let doc;

            if (!db.documents.length) {
              doc = XObject.obj({
                title: 'Untitled',
                content: [],
              });
              db.documents.push(doc);
            }
            else {
              doc = db.documents[0];
            }

            let entities = [];
            const collectEntities = (blocks, level = 0) => {
              for (const block of blocks) {
                let nextLevel = level;
                if (block.type == 'entity') {
                  ++nextLevel;
                  entities.push(block.content);
                }

                if (block.children) {
                  collectEntities(block.children, nextLevel);
                }
              }
            };
            collectEntities(doc.content);
            return _.uniq(entities);
          },
          render: args => {
            let doc;

            if (!db.documents.length) {
              doc = XObject.obj({
                title: 'Untitled',
                content: [],
              });
              db.documents.push(doc);
            }
            else {
              doc = db.documents[0];
            }

            return (
              <BlockPageCont
                noHeader
                id={doc._id}
                inWindow={false}
                blockPage={doc}
                state={comp.state.state}
                _onFocused={id => {
                  args.onSelectChild(id);
                }} />
            );

          }
        }} />;
      });
    }
  },
  [WindowType.AllGraph]: {
    title: () => 'All Graph',
    component: win => { return getComponentCache(win, () => { return <AllGraph active={"63e965c940ebe1e626df619b"} />; }); }
  },
  [WindowType.MetaStates]: {
    title: () => 'Meta States',
    component: win => { return getComponentCache(win, () => { return <MetaStatesWindow window={win} />; }); },
  },
  [WindowType.Linear]: {
    title: () => 'Linear',
    component: win => { return getComponentCache(win, () => { return <LinearWindow window={win} />; }); },
  },
  [WindowType.Queues]: {
    title: () => 'Queues',
    component: win => { return getComponentCache(win, () => { return <QueuesWindow window={win} />; }); },
  },
  [WindowType.Queue]: {
    title: (win) => {
      const queue = defaultWorkspace().queues.find(x => x._id == win.queue);
      return getEntityById(queue?.entity)?.name + ' ' + (queue.name || '(unnamed)') + ' queue';
    },
    component: win => { return getComponentCache(win, () => { return <QueueWindow window={win} />; }); },
  },
  [WindowType.EntityQueues]: {
    title: (win) => {
      const entity = getEntityById(win.entity);
      return entity?.name + ' queues';
    },
    component: win => { return getComponentCache(win, () => { return <EntityQueuesWindow window={win} />; }); },
  },
  [WindowType.Types]: {
    title: () => 'Types',
    component: win => { return getComponentCache(win, () => { return <TypesWindow window={win} />; }); },
  },
  [WindowType.Notion]: {
    title: () => 'Notion',
    component: win => { return getComponentCache(win, () => { return <NotionWindow window={win} />; }); },
  },
  [WindowType.OrgEntities]: {
    title: () => 'Org Entities',
    component: win => { return getComponentCache(win, () => { return <OrgEntitiesWindow window={win} />; }); },
  },
  [WindowType.Databases]: {
    title: () => 'Databases',
    component: win => { return getComponentCache(win, () => { return <DatabasesWindow window={win} />; }); },
  },
  [WindowType.Database]: {
    title: (win) => {
      const database = db.databases.findById(win.database);
      return database?.name || 'Database';
    },
    component: win => { return getComponentCache(win, () => { return <DatabaseWindow window={win} />; }); },
  },
  [WindowType.Document]: {
    title: (win) => {
      return 'Doc';
    },
    component: win => { return getComponentCache(win, () => { return <DocumentWindow window={win} />; }); },
  },
  [WindowType.NotionDocuments]: {
    title: (win) => {
      return 'Notion Documents';
    },
    component: win => { return getComponentCache(win, () => { return <NotionDocumentsWindow window={win} />; }); },
  },
  [WindowType.NotionDocument]: {
    title: (win) => {
      return 'Notion Document';
    },
    component: win => { return getComponentCache(win, () => { return <NotionDocumentWindow window={win} />; }); },
  },
  [WindowType.GlueDev]: {
    title: (win) => 'Glue Dev',
    component: win => { return getComponentCache(win, () => { return <GlueDev state={win} />; }); },
  },
  [WindowType.MenuItems]: {
    title: (win) => 'Menu Items',
    component: win => { return getComponentCache(win, () => { return <MenuItemsWindow window={win} />; }); },
  },
  [WindowType.SystemConfig]: {
    title: (win) => 'System Config',
    component: win => { return getComponentCache(win, () => { return <SystemConfigWindow window={win} />; }); },
  },
  [WindowType.Users]: {
    title: (win) => 'Users',
    component: win => { return getComponentCache(win, () => { return <UsersWindow window={win} />; }); },
  },
  [WindowType.EntityConfig]: {
    title: (win) => {
      const entity = getEntityById(win.id);
      return entity?.name + ' config';
    },
    component: win => { return getComponentCache(win, () => { return <EntityConfigWindow window={win} />; }); },
  },
  [WindowType.Inbox]: {
    title: (win) => 'Inbox',
    component: win => { return getComponentCache(win, () => { return <InboxWindow window={win} />; }); },
  },
  [WindowType.SpaceWindow]: {
    title: (win) => 'Space',
    component: win => { return getComponentCache(win, () => { return <SpaceWindow window={win} />; }); },
  },
  [WindowType.SpacesWindow]: {
    title: (win) => 'Spaces',
    component: win => { return getComponentCache(win, () => { return <SpacesWindow window={win} />; }); },
  },
  // [WindowType.QueryView]: {
  //   title: (win) => 'Query View',
  //   component: win => { return getComponentCache(win, () => { return <QueryViewEditor window={win} />; }); },
  // },
  [WindowType.Query]: {
    title: (win) => 'Query',
    component: win => { return getComponentCache(win, () => { return <QueryEditorWindow window={win} />; }); },
  },
  [WindowType.QueryExpressionEditor]: {
    title: (win) => 'Query Expression Editor',
    component: win => { return getComponentCache(win, () => { return <QueryExpressionEditorWindow window={win} />; }); },
  },
  [WindowType.EditDocument]: {
    title: (win) => 'Edit Document',
    component: win => { return getComponentCache(win, () => { return <EditDocumentWindow window={win} />; }); },
  },
  [WindowType.TypeWindow]: {
    title: (win) => 'Type',
    component: win => { return getComponentCache(win, () => { return <TypeWindow window={win} />; }); },
  },
  [WindowType.Attribute]: {
    title: (win) => 'Attribute',
    component: win => { return getComponentCache(win, () => { return <AttributeEditor window={win} />; }); },
  },
  [WindowType.AppLibrary]: {
    title: () => 'App Library',
    component: win => { return getComponentCache(win, () => { return <AppLibrary window={win} />; }); },
  },
  [WindowType.EditMode]: {
    title: () => 'Edit Mode',
    component: win => { return getComponentCache(win, () => { return <EditModeWindow window={win} />; }); },
  },
  [WindowType.ChatGPTTest]: {
    title: () => 'ChatGPT Test',
    component: win => { return getComponentCache(win, () => { return <ChatGPTTestWindow window={win} />; }); },
  },
  [WindowType.NotionDocumentTest]: {
    title: () => 'Notion Document Test',
    component: win => { return getComponentCache(win, () => { return <NotionDocumentTestWindow window={win} />; }); },
  },
  [WindowType.CodeTest]: {
    title: () => 'Code Test',
    component: win => { return getComponentCache(win, () => { return <CodeTestWindow window={win} />; }); },
  },
  [WindowType.CodeComponents]: {
    title: () => 'Code Components',
    component: win => { return getComponentCache(win, () => { return <CodeComponentsWindow window={win} />; }); },
  },
  [WindowType.CodeComponent]: {
    title: () => 'Code Component',
    component: win => { return getComponentCache(win, () => { return <CodeComponentWindow window={win} />; }); },
  },
  [WindowType.NotionDocument_CodeEntityEditor]: {
    title: () => 'Code Entity Editor',
    component: win => { return getComponentCache(win, () => { return <NotionDocument_CodePartEditorWindow window={win} />; }); },
  },
  [WindowType.DataEditorTestWindow]: {
    title: () => 'Data Editor Test',
    component: win => { return getComponentCache(win, () => { return <DataEditorTestWindow />; }); },
  },
  [WindowType.Whiteboard]: {
    title: () => 'Whiteboard',
    component: win => { return getComponentCache(win, () => { return <Whiteboard window={win} />; }); },
  },
  [WindowType.MindMap]: {
    title: () => 'Mind Map',
    component: win => { return getComponentCache(win, () => { return <MindMap window={win} />; }); },
  },
  [WindowType.Formula]: {
    title: () => 'Formula',
    component: win => { return getComponentCache(win, () => { return <FormulaEditor window={win} /> }); },
  },
  [WindowType.ScopeWindow]: {
    title: () => 'Scope',
    component: win => { return getComponentCache(win, () => { return <ScopeWindow window={win} /> }); },
  },
  [WindowType.PinBoard]: {
    title: () => 'Pin Board',
    component: win => { return getComponentCache(win, () => { return <PinBoardWindow window={win} /> }); },
  },
  [WindowType.Widgets]: {
    title: () => 'Widgets',
    component: win => { return getComponentCache(win, () => { return <WidgetsWindow window={win} /> }); },
  },
  [WindowType.Widget]: {
    title: () => 'Widget',
    component: win => { return getComponentCache(win, () => { return <WidgetWindow window={win} /> }); },
  },
  [WindowType.MobileHome]: {
    title: () => 'Home',
    component: win => { return getComponentCache(win, () => { return <HomeScreen /> }); },
  },
  [WindowType.ScriptWindow]: {
    title: () => 'Script',
    component: win => { return getComponentCache(win, () => { return <ScriptWindow window={win} /> }); },
  },
  [WindowType.Shorthand]: {
    title: () => 'Shorthand',
    component: win => { return getComponentCache(win, () => { return <ShorthandWindow window={win} /> }); },
  },
  [WindowType.CompletionsTest]: {
    title: () => 'Completions Test',
    component: win => { return getComponentCache(win, () => { return <CompletionsTest /> }); },
  },
  [WindowType.EmbeddingTest]: {
    title: () => 'Embedding test',
    component: win => { return getComponentCache(win, () => { return <EmbeddingTest /> }); },
  },
  [WindowType.Wip]: {
    title: () => 'Embedding test',
    component: win => { return getComponentCache(win, () => { return <WIP /> }); },
  },

  [WindowType.DevProject]: {
    title: () => 'Dev Project',
    component: win => { return getComponentCache(win, () => { return <DevProjectWindow window={win} /> }); },
  },
});
