import _, { pull } from 'lodash';
import ReactDOMClient from "react-dom/client";
import jQuery from 'jquery';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { db } from '../db';
import { GlueDev, GlueView, getValuePoint } from '../glue/main';
import { _inspectHandlers, _inspectState, currentSpace, openWindow, triggerInspectObject } from '../osHelpers';
import { component } from '../component2';
import { X, x, XInit, XObject } from '../XObject';
import { LinearWindow } from './LinearWindow';
import { getValueParams, showContextMenu } from '../helpers';
import { ObjectDisplay } from './ObjectDisplay';
import { WindowType } from '../etc/WindowType';
import { Svg } from './Svg';
import classNames from 'classnames';
import { appState, memoryAppState } from '../etc/appState';
import { SystemContext, SystemContextProps } from '../etc/SystemContext';
import { isElectron } from '../etc/isElectron';
import { EntityView } from './EntityView';
import { createEntity, getAllEntities, getEntityById } from '../etc/createEntity';
import { NotionButton } from './AddButton';
import { ViewQueryPage, ViewQueryish } from './ViewQuery';
import { getSidebar } from '../etc/getSidebar';
import { ViewType } from '../types/ViewType';
import { ObjectType } from '../types/ObjectRef';
import { ObjectPageFrame } from './ObjectPageFrame';
import { collectEntities } from "../etc/collectEntities";
import { AppLibrary } from './AppLibrary';
import { PaneType } from '../types/PaneType';
import { RichTextEditorOld } from './RichTextEditorOld';
import { GraphView__ } from './GraphView__';
import { ChagGPTPane, ChagGPTPane2 } from './ChatGPTPane';
import { QueuesWindow } from '../windows/QueuesWindow';
import { EntityQueuesWindow } from '../windows/EntityQueuesWindow';
import { Inbox } from './Inbox';
import { NotionDocumentWindow } from '../windows/NotionDocumentWindow';
import { UIInspectPane } from './UIInspectPane';
import { SpaceEntities } from './SpaceEntities';
import { TableRowView } from './TableRowView';
import { RootSidebarItem } from '../RootSidebarItem';
import { Sidebar } from './Sidebar';
import { color, iconColor } from '../color';
import { ImageEnum } from '../ImageEnum';
import { notionMode, resumeMode } from '../resumeMode';
import { CanvasView } from './CanvasView';
import { explicitInspectObj, inspectObj } from '../inspectObj';
import { entityDisplayName, entityDisplayView } from './entityDisplayName';
import { resolvePath } from './resolvePath';
import { Type } from './Type';
import Hammer from 'hammerjs';
import Sugar from 'sugar';
import { useNavigate } from  'react-router-dom';
import { isCordova, isDevMode, isMobile, useMobileNavSystem } from '../isMobile';
import { EntityRow } from './EntityRow';
import { presentMobileModal, closeMobileModal, setMobileComp } from './presentMobileModal';
import {getAuth } from 'firebase/auth';
import { deleteObject } from '../etc/deleteEntity';
import { objectName, typesInScope } from './objectFuncs';
import { EventsRoot, EventView, ParameterizedEventView, OccurrenceBar } from './EventsRoot';
import { getCurrentOccurrence } from './getCurrentOccurrence';
import { DaysRoot, DayPage } from './DaysRoot';
import { WithContextAction } from './WithContextAction';
import { PageEditor } from './PageEditor';
import { NotionTable2 } from './NotionTable2';
import { CreateRoot } from './CreateRoot';
import { TrackScreen } from './TrackScreen';
import { EventOccurrencePage } from './EventOccurrencePage';
import { RenderData } from '../MyNotionDocument/RenderData';
import { types } from '../MyNotionDocument/types';
import { RichTextEditor } from './richTextEditor/RichTextEditor';
import { NotionDocumentWrapper, isEmptyBlocks } from './NotionDocumentWrapper';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { addNote } from './addNote';
import { PinBoard } from '../PinBoard';
import { HomeScreen } from './HomeScreen';
import { htmlToBlocks } from '../WIP';
import { CardsPane } from './CardsPane';

@component
class NotesRoot extends Component {
  static contextType = SystemContext;
  context: SystemContextProps;

  static styles = styled.div`
    .masonry {
      .card {
        overflow: hidden;
        padding: 8px;
        box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 2px 4px;
        border-radius: 3px;
        background: white;

        cursor: pointer;  

        transition: background 100ms;


        &:hover {
          background: #f9f9f8;
        }

      }
    }
  `;

  render() {
    const notes = db.notes.filter?.(n => !n.__deleted && n.mode == appState.currentMode && n.user == appState.user) || [];

    notes.reverse();
    return (
      <>
        <ObjectPageFrame

          path="Notes"
        >
          
          {/* <ul>
            {db.notes.filter?.(n => n.mode == appState.currentMode && n.user == appState.user)?.map?.(note => {
              return (
                <li key={note._id}
                  onClick={() => {
                    this.context.navigate({
                      type: PaneType.note,
                      id: note._id,
                    })
                  }}
                >
                  <b><RenderData ctx={{ types: types }} data={note.title || ''} /></b>


                </li>
              )
            })}
          </ul> */}

          <button
            onClick={() => {
              addNote({
                note: [],
                title: '',
            
              })

            }}
          >+</button>

          <Masonry className="masonry" columnsCount={3} gutter="8px">
            {notes?.map?.(note => {
              return (
                <div key={note._id}
                className="card"
                  onClick={() => {
                    this.context.navigate({
                      type: PaneType.note,
                      id: note._id,
                    })
                  }}
                >
                  <b><RenderData ctx={{ types: types }} data={note.title || ''} /></b>
                  {!isEmptyBlocks(note.note) && <NotionDocumentWrapper
            
            docId={null}
            entity={null}
            inline
            blocks={() => note.note}
            setBlocks={blocks => {}}
            configId={null}
            name={null}
            readOnly
            configMap={{
              // [$Document.Entity]: n,
            }}

          />}
                </div>
              )
            })}
          </Masonry>


        </ObjectPageFrame>
      </>
    );
  }
}

@component
class NoteRoot extends Component<{ id }> {
  static styles = styled.div`
    .title {
      display: block;
      font-size: 18px;
    }
  `;

  render() {
    const note = db.notes.findById(this.props.id);
    return (
      <>
        <ObjectPageFrame
          path="Note"
          obj={{
            type: ObjectType.note,
            id: note._id,
          }}
          right={
            <>
            
            <span className="more"
                onClick={e => {
                  showContextMenu(e, [
                    {
                      text: 'Edit',
                      onClick: () => {
                        explicitInspectObj({
                          type: ObjectType.note,
                          id: this.props.id,
                        });
                      }
                    },
                    {
                      text: 'Delete',
                      onClick: () => {
                        deleteObject(note);
                      },
                    },

                  ], true);
                }}
              >
                <Svg name="dots" />
              </span></>
          }
        >
          <span className="title">
            <RichTextEditor
              value={() => note.title}
              setValue={v => note.title = v}
            />
          </span>


          <NotionDocumentWrapper
            
            scopes={[
              {
                type: ObjectType.mode,
                id: appState.currentMode,
              }
            ]}
            docId={null}
            entity={null}
            inline
            blocks={note.note || []}
            setBlocks={blocks => {
              note.note = blocks;
            }}
            configId={null}
            name={null}
            configMap={{
              // [$Document.Entity]: n,
            }}

          />

        </ObjectPageFrame>
      </>
    )
  }
}

@component
class ModeSelector extends Component {
  static styles = styled.div`

  `;
  render(Container?) {

    return (
      <Container
        onClick={e => {
          showContextMenu(e, db.modes.map(m => ({
            text: m.name,
            onClick: () => {
              appState.currentMode = m._id;
            }
          })))
        }}
      >
        {db.modes.findById(appState.currentMode).name}
      </Container>
    )
  }
}

@component
class Search extends Component {
  static debounce = false;
  static styles = styled.div`
    background: rgba(15, 15, 15, 0.6);

    > div {
      position: absolute;
      margin: auto;
      box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 5px 10px, rgba(15, 15, 15, 0.2) 0px 15px 40px;
      border-radius: 6px;
      max-width: 600px;
      top: 11%;
      bottom: 19%;
      left: 0;
      right: 0;
      background-color: white;
      display: flex;
      flex-direction: column;

      > .header {
        height: 48px;
        display: flex;
        align-items: center;
        padding: 0px 16px;
        box-shadow: rgba(55, 53, 47, 0.09) 0px 1px 0px;
        flex: 0 0 auto;

        .close {
          width: 18px;
          height: 18px;
        }

        svg {
          fill: #c1c1c1;
        }

        input {
          width: 100%;
          border: none;
          padding: 0;
          margin-left: 8px;
          &::placeholder {
            color: #c1c1c1;
          }
          &:focus {
            outline: none;
          }
        }
      }

      > .body {
        flex: 1;
        overflow: auto;
        padding: 6px 9px;
        .header {
          font-weight: 600;
          color: rgba(55, 53, 47, 0.65);
          font-size: 12px;
        }

        .section {
          margin-bottom: 16px;
        }
      }

    }

    &.mobile {
      > div {
        width: 100%;
        height: 100%;
        border-radius: 0;
        position: static;
      }
    }
  `;

  state = XInit(class {
    query
    focused = 0
  })

  timerId 

  static contextType = SystemContext;
  context: SystemContextProps;

  ref = React.createRef<any>();

  componentDidMount(): void {
    this.ref?.current?.focus?.();
    this.timerId = setTimeout(() => {
      this.ref.current?.focus?.();
    }, 2000);
  }

  render(Container?) {
    // if (!memoryAppState.search) return null;
    let entities = getAllEntities().filter(entity => {
      return entity.space == appState.currentMode || entity.space?.id == appState.currentMode;
    });

    // sort by meta.timestamp descending is a Date object
    entities.sort((a, b) => {
      if (!b.meta?.creation?.timestamp) return -1;
      if (!a.meta?.creation?.timestamp) return 1;

      return b.meta.creation.timestamp.getTime() - a.meta.creation.timestamp.getTime();
    });


    let mode = 'all';
    let c;
    if (this.state.query) {
      mode = 'search';
    }

    let results;
    
    const onClick = (entity) => {
      return async () => {
        await closeSearch();
        this.context.navigate({
          type: 'entity',
          id: entity._id,
        });
        // memoryAppState.search = false;
        // closeMobileModal();

      }
    }


    if (mode == 'all') {
      entities = entities.slice(0, 100).filter(e => e.meta?.creation?.timestamp);
      // group by today, yesterday, and older
      const today = Sugar.Date.create('beginning of day');
      const yesterday = today['clone']();
      yesterday.setDate(yesterday.getDate() - 1);

      const todayEntities = entities.filter(entity => {
        return entity.meta.creation.timestamp && entity.meta.creation.timestamp.getTime() > today.getTime();
      });

      const yesterdayEntities = entities.filter(entity => {
        return entity.meta.creation.timestamp && entity.meta.creation.timestamp.getTime() > yesterday.getTime() && entity.meta.creation.timestamp.getTime() < today.getTime();
      });

      // past week
      const pastWeek = today['clone']();
      pastWeek.setDate(pastWeek.getDate() - 7);

      const pastWeekEntities = entities.filter(entity => {
        return entity.meta.creation.timestamp && entity.meta.creation.timestamp.getTime() > pastWeek.getTime() && entity.meta.creation.timestamp.getTime() < yesterday.getTime();
      });

      // past 30 days
      const past30Days = today['clone']();
      past30Days.setDate(past30Days.getDate() - 30);

      const past30DaysEntities = entities.filter(entity => {
        return entity.meta.creation.timestamp && entity.meta.creation.timestamp.getTime() > past30Days.getTime() && entity.meta.creation.timestamp.getTime() < pastWeek.getTime();
      });

      // older
      const olderEntities = entities.filter(entity => {
        return entity.meta.creation.timestamp && entity.meta.creation.timestamp.getTime() < past30Days.getTime();
      });


      c = (
        <>
                    {todayEntities.length > 0 && (
                <div className="section">
                  <div className="header">Today</div>
                  {todayEntities.map(entity => {
                    return (
                      <div key={entity._id}>
                        <EntityRow id={entity._id} path _onClick={onClick(entity)} />
                      </div>
                    );
                  })}
                </div>
              )}

              {yesterdayEntities.length > 0 && (
                <div className="section">
                  <div className="header">Yesterday</div>
                  {yesterdayEntities.map(entity => {
                    return (
                      <div key={entity._id}>
                        <EntityRow dontHighlight id={entity._id} path _onClick={onClick(entity)} />
                      </div>
                    );
                  })}
                </div>
              )}

              {pastWeekEntities.length > 0 && (
                <div className="section">
                  <div className="header">Past Week</div>
                  {pastWeekEntities.map(entity => {
                    return (
                      <div key={entity._id}>
                        <EntityRow dontHighlight id={entity._id} path farRight={(
                          <span>{entity.meta.creation.timestamp.format('{Month} {day}')}</span>
                        )} _onClick={onClick(entity)} />
                      </div>
                    );
                  })}
                </div>
              )}

              {past30DaysEntities.length > 0 && (
                <div className="section">
                  <div className="header">Past 30 Days</div>
                  {past30DaysEntities.map(entity => {
                    return (
                      <div key={entity._id}>
                        <EntityRow  dontHighlight id={entity._id} path farRight={(
                          <span>{entity.meta.creation.timestamp.format('{Month} {day}')}</span>
                        )}  _onClick={onClick(entity)} />
                      </div>
                    );
                  })}
                </div>
              )}

              {olderEntities.length > 0 && (
                <div className="section">
                  <div className="header">Older</div>
                  {olderEntities.map(entity => {
                    return (
                      <div key={entity._id}>
                        <EntityRow  dontHighlight id={entity._id} path farRight={(
                          <span>{entity.meta.creation.timestamp.format('{Month} {day}')}</span>
                        )}  _onClick={onClick(entity)}/>
                      </div>
                    );
                  })}
                </div>
              )}
              {/* {entities.map(entity => {
                return (
                  <div key={entity._id}>
                    <EntityRow  dontHighlight id={entity._id} path />
                  </div>
                );
              })} */}
        </>
      )

      // const olderEntities = entities.filter(entity => {
      //   return entity.meta.creation.timestamp && entity.meta.creation.timestamp.getTime() < yesterday.getTime();
      // });

    }
    else if (mode == 'search') {
      const searchResults = entities.filter(e => {
        return entityDisplayName(e._id).toLowerCase().includes(this.state.query.toLowerCase());
      })

      results = searchResults;

      c = (
        <>
          {searchResults.map((entity, i) => {
            return (
              <div key={entity._id}
                style={{
                  backgroundColor: this.state.focused == i ? '#eee' : 'transparent'
                }}
              >
                <EntityRow  dontHighlight id={entity._id} path _onClick={onClick(entity)}
                  farRight={(
                    <span>{entity.meta?.creation?.timestamp?.format?.('{Month} {day}')}</span>
                  )}
                />
              </div>
            );
          })}
        </>
      )
    }


    return (
      <Container
        className={classNames({
          mobile: isMobile(),

        })}
        onClick={e => {
          if (e.target == e.currentTarget) {
            // memoryAppState.search = false;
            // closeMobileModal();
            closeSearch();
          }
        }}
      >
        <div>
          <div className="header">
            <Svg name="magnifyingGlass" />
            <input 
              ref={this.ref}
            autoFocus type="text" placeholder="Search" defaultValue={this.state.query} onChange={e => {
              clearTimeout(this.timerId);
              this.timerId = setTimeout(() => {
                this.state.query = e.target.value;
                this.state.focused = 0;
              }, 500);
            }}
            
            onKeyDown={e => {
              if (e.key == 'Escape') {
                // memoryAppState.search = false;
                // closeMobileModal();
                closeSearch();
              }
              if (e.key == 'ArrowDown') {
                e.preventDefault();
                this.state.focused = (this.state.focused + 1) % results.length;
              }
              else if (e.key == 'ArrowUp') {
                e.preventDefault();
                this.state.focused = (this.state.focused - 1 + results.length) % results.length;
              }
              else if (e.key == 'Enter') {
                e.preventDefault();
                const entity = results[this.state.focused];
                if (entity) {
                  this.context.navigate({
                    type: 'entity',
                    id: entity._id,
                  });

                  // memoryAppState.search = false;
                  // closeMobileModal();
                  closeSearch();
                }
              }
            }}
            />

            {isMobile() && <Svg name="icons8-close" className="close"
                          onClick={() => {
                            // memoryAppState.search = false;
                            // closeMobileModal();
                            closeSearch();
                          }}
            
            />}
            {/* <button
              onClick={() => {
                memoryAppState.search = false;
              }}
            >X</button> */}
          </div>

          <div className="body">
            {c}
          </div>
        </div>
      </Container>
    )
  }
}

@component
class SearchCont extends Component {
  static styles = styled.div`
    ${Search} {
      z-index: 9999999;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

    }
  `;
  render(Container?) {
    return memoryAppState.search && <Container><Search /></Container>;
  }
}

function openSearch() {
  if (isMobile()) {
    console.log('presenting search');
    presentMobileModal(<Search />);
  }
  else {
    memoryAppState.search = true;
  }
}

async function closeSearch() {
  if (isMobile()) {
    await closeMobileModal();
  }
  else {
    memoryAppState.search = false;
  }
}


@component
class DocumentPane extends Component<{ pane, state }> {
  static contextType = SystemContext;
  context: SystemContextProps;
  render() {
    const { pane } = this.props;
    const notionDoc = db.notionDocuments.findById(this.props.pane.id);
        return (
      <ObjectPageFrame
          obj={{
            type: ObjectType.document,
            id: notionDoc._id,
          }}
        icon="icons8-page"
        pathId={pane._id}
        path={notionDoc.name}
        right={(

          <>
              <span className="more"
                onClick={e => {
                  showContextMenu(e, [
                    {
                      text: 'Edit',
                      onClick: () => {
                        explicitInspectObj({
                          type: ObjectType.document,
                          id: notionDoc._id,
                        });
                      }
                    },
                    {
                      text: 'Delete',
                      onClick: () => {
                        deleteObject(notionDoc);
                      }
                    },
                    {
                      text: 'Paste HTML',
                      onClick: async () => {
                        const items = await navigator.clipboard.read();
                        const children = htmlToBlocks(await (await items[0].getType('text/html')).text());
                        notionDoc.blocks = X(children);
                      },
                    }
                  ], true);
                }}
              >
                <Svg name="dots" />
              </span>
            </>
          )}
      >
        <NotionDocumentWindow
          key={notionDoc._id}
          window={this.props.state}
        />
      </ObjectPageFrame>
    );
  }
}

const rightWidth = '500px';

const sidebarStyles = css`
  background-color: #ffffff;
  /* position: absolute; */
  /* top: 0; */
  /* right: 0; */
  /* bottom: 0; */
  box-sizing: border-box;
  /* border-left: 1px solid ${color('separator')}; */

  > .top {
    background-color: #ffffff;
    -webkit-app-region: drag;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 37px;
    box-sizing: border-box;

    display: flex;
    align-items: center;

    .title {
      margin-left: 10px;
      color: ${color('bgTextColor')};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      font-size: 12px;
      font-weight: 500;
    }
    .toggleSidebar {
      flex: 0 0 auto;
      margin-left: auto;
      /* margin-right:   8px; */
    }

    .toggleAppInspect {
      margin-right: 11px;
    }
  }

  > .content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

  }
`;


export function pushToPane(pane, config, inspect=true) {
  if (inspect) inspectFromNav(config, appState.panes.indexOf(pane));
  pane.stack = X([...x(pane.stack.slice(0, pane.pointer + 1)), XObject.obj(config)]);
  pane.pointer = pane.stack.length - 1;
}

@component
class RightTopPanel extends Component {
  render() {
    return (
      <>
        <span
          className={classNames('toggleGraphView svg-button', { active: appState.rightSidebar })}
          onClick={() => {
            appState.rightSidebar = !appState.rightSidebar;
          }}
        >
          <Svg name="icons8-mind-map" />
        </span>
        <span className={classNames('toggleSidebar svg-button', { visible: currentSpace().sideBar })} onClick={() => {
          currentSpace().sideBar = !currentSpace().sideBar;
        }}>
          <Svg name={currentSpace().sideBar ? ImageEnum.sidebarRightFilled : ImageEnum.sidebarRight} />
        </span>
      </>
    );
  }
}

@component
class ModeSelect extends Component {
  static styles = styled.div`
    height: 39px;
    position: relative;
    padding: 2px 10px;
    box-sizing: border-box;

    border-radius: 18px;

    &:not(:hover) {
      > svg {
        display: none;
      }
    }

    > svg {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 20px;
      margin: auto;
      width: 12px;
      height: 12px;
      fill: rgba(55, 53, 47, 0.45);
    }

    display: flex;
    align-items: center;

    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }

    cursor: pointer;
    .icon {
      border-radius: 0.25em;
      width: 20px;
      height: 20px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      svg, svg path {
        fill: #7081b9;
      }
    }

    .userName {
      color: #7081b9;
      font-weight: 500;
    }

  `;

  static contextType = SystemContext;
  context: SystemContextProps;

  render(Container?) {
    return <Container
      onContextMenu={e => {
        e.preventDefault();
        showContextMenu(e, [
          {
            text: 'Edit',
            onClick: () => {
              inspectObj({
                type: ObjectType.mode,
                id: appState.currentMode,
              });
  
            },
          }
        ]);
      }}
      onClick={e => {
        if (resumeMode.enabled) return;
        showContextMenu(e, [{
          text: 'Pin Board',
          onClick: () => {
            if (isMobile()) {
              presentMobileModal(<PinBoard />, 'Pin Board');
            }
            else {
              openWindow({
                type: WindowType.PinBoard,
              })  
            }
          },
        }].concat(db.modes.map(mode => ({
          text: mode.name,
          onClick: async () => {
            await window['g_initCollection']('entities', { 'space.id': mode._id });
            appState.currentMode = mode._id;

            inspectObj({
              type: ObjectType.mode,
              id: mode._id,
            })
          },
        }))).concat([
          {
            text: '(Edit mode)',
            onClick: () => {
              explicitInspectObj({
                type: ObjectType.mode,
                id: appState.currentMode,
              });
            }
          },
          {
            text: '(New mode)',
            onClick: () => {
              const newSidebar = XObject.obj({
                children: [],
              });
              db.sidebar.push(newSidebar);
  
              const newMode = XObject.obj({
                name: 'Untitled',
                useAsSpace: true,
                sidebar: newSidebar._id,
              });
              db.modes.push(newMode);
              appState.currentMode = newMode._id;
              inspectObj({
                type: ObjectType.mode,
                id: newMode._id,
              })

            }
          },
          // {
          //   text: '(Open library)',
          //   onClick: () => {
          //     openWindow({
          //       type: WindowType.AppLibrary,
          //     });
  
          //   }
          // },

          {
            text: '(Sign out)',
            onClick: () => {
              if (!isCordova()) {
                getAuth().signOut();
              }
              
              localStorage.clear();
              window.location.href = '/';

            }
          }
        ]))
      }}
    >
      <span className="icon">
          <Svg name="icons8-mode" />
      {/* {db.modes.findById(appState.currentMode)?.name?.[0]?.toUpperCase?.()} */}
      </span>
      <span className="userName">{db.modes.findById(appState.currentMode)?.name}</span>
      <Svg name="expand" />
    </Container>;
  }
}


export function inspectFromNav(config, pane) {
  console.log('inspectFromNav', config, pane);
  appState.lastActivePane = pane;
  if (config?.type == 'entity') {
    appState.inspecting = {
      type: 'entity',
      id: config.id,
    };
  }

  if (config.type == 'query') {
    inspectObj({
      type: ObjectType.query,
      id: config.id,
    })
  }
  else if (config.type == 'page') {
    inspectObj({
      type: ObjectType.document,
      id: config.id,
    })
  }
  else if (config.type == PaneType.canvas) {
    inspectObj({
      type: ObjectType.canvas,
      id: config.id,
    })
  }
  else if (config.type == PaneType.page) {
    inspectObj({
      type: ObjectType.page,
      id: config.id,
    })
  }
  else if (config.type == 'entity') {
    // inspectObj({
    //   type: ObjectType.entity,
    //   id: config.id,
    // })
  }
  else if (config.type == PaneType.tableRow) {
    appState.inspecting = config;

    triggerInspectObject({
      type: PaneType.tableRow,
      args: config,
    })
  }
  else if (config.type == PaneType.app) {
    inspectObj({
      type: ObjectType.app,
      id: config.id,
    })
  }
  else if (config.type == PaneType.table) {
    inspectObj({
      type: ObjectType.table,
      id: config.id,
    })
  }
  else if (config.type == PaneType.workspace) {
    inspectObj({
      type: ObjectType.workspace,
      id: config.id,
    })
  }

  else if (config.type == PaneType.devProject) {
    inspectObj({
      type: ObjectType.devProject,
      id: config.id,
    })
  }

}

function viewWidth(pane) {
  if (pane.type == PaneType.uiInspect) {
    return 'auto';
  }
}

export function isEmptyPane(pane) {
  return !pane || Object.keys(x(pane)).length <= 1;
}

@component
class EditObjectPane extends Component<{ obj, pathId }> {
  state = XInit(class {

    props
  })

  constructor(props) {
    super(props);
    this.state.props = X({
      obj: props.obj,
      stack: [],
    })
  }

  render() {
    return (
      <ObjectPageFrame
        obj={null}
        icon={null}
        path={null}
        pathId={this.props.pathId}
      >
      {/* <ObjectEditor
        props={this.state.props}
      /> */}
      </ObjectPageFrame>
    );
  }
}

@component
class TableRoot extends Component<{ id }> {
  state = X({});
  render() {
    const table = db.tables.findById(this.props.id);
    return (
      <>
        <ObjectPageFrame
          icon="icons8-table"
          obj={{
            type: ObjectType.table,
            id: this.props.id,
          }}
          path={table.name}
          pathId={null}
        >
          <NotionTable2
            active={null}
            state={this.state}
            table={table}
            editView={id => {
              triggerInspectObject({
                type: 'f803b269-1725-57ba-a8c6-dac2fa140e06',
                args: {
                  table: table._id,
                  view: id,
                },
              });

            }}
          />
        </ObjectPageFrame>
      </>
    )
  }
}

@component
class WorkspacePane extends Component<{ id }> {
  render() {
    const workspace = db.dataObjects.findById(this.props.id);
    return (
      <>
        {this.props.id}
      </>
    )
  }
}

@component
class ValuePointEditor extends Component<{ id, state }> {
  constructor(props) {
    super(props);
    this.props.state.valuePoint = this.props.id;
  }
  render() {
    return (
      <>
        <GlueDev state={this.props.state} />
      </>
    )
  }
}


function renderView(pane, state, navigate) {
  if (isEmptyPane(pane)) {
    return 'empty';
  }

  const id = pane.id;
  if (pane.type == PaneType.graph) {
    // return 'hell'
    return (
      <ObjectPageFrame
        icon={null}
        obj={null}
        path={'Graph'}
        pathId={null}
      >
        <GraphView__ />
      </ObjectPageFrame>
    )
  }
  if (pane.type == PaneType.days) {
    return <DaysRoot />;
  }
  if (pane.type == PaneType.day) {
    return <DayPage id={pane.id} />;
  }
  if (pane.type == PaneType.events) {
    return (
      <ObjectPageFrame
        icon={'icons8-time'}
        obj={null}
        path={'Events'}
        pathId={null}
      >
        <EventsRoot />
      </ObjectPageFrame>

    )
  }
  if (pane.type == PaneType.event) {
    const event = db.events.findById(pane.id);
    return (
      <ObjectPageFrame
        icon={'icons8-time'}
        obj={{
          type: ObjectType.event,
          id: event._id,
        }}
        path={event.name}
        pathId={null}
        right={(
          <>
              <span className="more"
                onClick={e => {
                  showContextMenu(e, [
                    {
                      text: 'Edit',
                      onClick: () => {
                        explicitInspectObj({
                          type: ObjectType.event,
                          id: pane.id,
                        })
                      }
                    },
                  ], true);
                }}
              >
                <Svg name="dots" />
              </span>
          </>
        )}
      >
        <EventView id={pane.id} />
      </ObjectPageFrame>
    );
  }
  if (pane.type == PaneType.eventOccurrence) {
    return (
      <EventOccurrencePage id={pane.id} />
    )
  }
  if (pane.type == PaneType.parameterizedEvent) {
    // const parameterizedEvent = db.parameterizedEvents.findById(pane.id);
    return (
      <ObjectPageFrame
        icon={'icons8-time'}
        obj={null}
        path={objectName({
          type: ObjectType.parameterizedEvent,
          id: pane.id,
        })}
        pathId={null}
      >
        <ParameterizedEventView id={pane.id} />
      </ObjectPageFrame>
    );
  }
  if (pane.type == PaneType.table) {
    return <TableRoot id={pane.id} />
  }
  if (pane.type == PaneType.tableRow) {
    return <TableRowView window={pane} />;
  }
  if (pane.type == PaneType.chatGPT) {
    return <ChagGPTPane window={pane} />;
  }
  if (pane.type == PaneType.chatGPT2) {
    return <ChagGPTPane2 window={pane} />;
  }
  if (pane.type == PaneType.richTextEditor) {
    if (pane.entity) {
      const entity = getEntityById(pane.entity);
      return (
        <RichTextEditorOld
          value={entity?.attributes?.[pane.attribute]}
          setValue={value => {
            if (!entity.attributes) {
              entity.attributes = {
                [pane.attribute]: value,
              };
            }
            else {
              entity.attributes[pane.attribute] = value;
            }
          }} />
      );
    }
  }
  if (pane.type == PaneType.uiInspect) {
    return <UIInspectPane args={pane.args} />
  }
  if (pane.type == PaneType.page) {
    const page = db.pages2.findById(pane.id);
    return (
      <ObjectPageFrame
        icon={'icons8-web-page'}
        path={page.name}
        obj={{
          type: ObjectType.page,
          id: pane.id,
        }}
        pathId={null}
        noPadding
      >
        <PageEditor id={pane.id} />


      </ObjectPageFrame>

    )
  }
  if (pane.type == 'entity') {
    return <EntityView id={pane.id} state={XObject.get(state, 'viewState', {})} pathId={pane._id} page={pane.page} />;
  }
  if (pane.type == 'query') {
    return <ViewQueryPage id={pane.id} state={state} entity={pane.entity} pathId={pane._id} />;
  }
  if (pane.type == 'spaceEntities') {
    return <SpaceEntities space={pane.space} state={state} pathId={pane._id} />;
  }
  if (pane.type == WindowType.EntityQueues) {
    return <EntityQueuesWindow window={pane} hideInspectPane />;
  }
  if (pane.type == PaneType.appLibrary) {
    return <AppLibrary window={pane} />;
  }
  if (pane.type == PaneType.cards) {
    return <CardsPane />;
  }
  if (pane.type == 'glue') {
    const args = x(pane.map || pane.args) || {};
    if (pane.valueArgs) {
      const valuePoint = getValuePoint(pane.id);
      if (valuePoint.parameters) {
        for (let i = 0; i < valuePoint.parameters.length && i < pane.valueArgs.length; ++ i) {
          args[valuePoint.parameters[i]._id] = pane.valueArgs[i];
        }
      }
    }

    return (
      <GlueView
        key={pane._id}
        args={args}
        id={pane.id}
        state={state}
      />
    );
  }
  else if (pane.type == PaneType.canvas) {
    return <CanvasView pane={pane} />
  }
  else if (pane.type == 'inbox') {
    return <Inbox />;
  }
  else if (id == '6effb8ff-9c63-5bca-b51a-a629d1e5b2b6') {
    return <QueuesWindow window={{}} />;
  }
  else if (id == '83078246-7ae7-593a-8fb1-75b411b8507e') {
    return <GlueDev state={state} />;
  }
  if (id == 'linear') {
    return <LinearWindow window={state} />;
  }
  else if (pane.type == PaneType.pageEntities) {
    let doc;
    if (pane.entity) {
      const entity = getEntityById(pane.entity);
      doc = entity.documents.find(doc => doc._id == pane.id);

    }
    else {
      doc = db.notionDocuments.findById(pane.document || pane.id);
    }


    const entities = collectEntities(doc.content || doc.blocks || []);

    const entityQuery = XObject.get(doc, 'entitySpace', {
      views: [],
    });


    return <ObjectPageFrame
      obj={null}
      pathId={pane._id}
      icon="tesseract"
      path={<>
        <span>{'Entities'}</span>
      </>}
      right={null}
    >
      <ViewQueryish
        entities={entities}
        state={state}
        showToolbar
        viewManager={{
          get() {
            return entityQuery.views || [];
          },
          addView() {
            XObject.push(entityQuery, 'views', XObject.obj({
              type: ViewType.list,
            }));
          },
          init() {
          },
          editView(id) {
            openWindow({
              type: WindowType.QueryView,
              doc: doc._id,
              view: id,
            });

          },
          deleteView() {
          }
        }} />
    </ObjectPageFrame>;

  }
  else if (pane.type == PaneType.notes) {
    return <NotesRoot />;
  }
  else if (pane.type == PaneType.note) {
    return <NoteRoot id={pane.id} />;
  }
  else if (pane.type == PaneType.valuePoint) {
    return <ValuePointEditor id={pane.valuePoint} state={XObject.get(pane, 'state', {})} />;
  }
  else if (pane.type == PaneType.workspace) {
    return (
      <>
        <WorkspacePane id={pane.id} />
      </>
    ) 
  }
  else if (pane.type == PaneType.devProject) {
    return 'test';
  }

  const notionDoc = db.notionDocuments.findById(id);
  if (notionDoc) {
    state.notionDocument = id;
    return <DocumentPane pane={pane} state={state} />
    return (
      <ObjectPageFrame
        obj={null}
        icon="icons8-page"
        pathId={pane._id}
        path={notionDoc.name}
        right={(

          <>
              <span className="more"
                onClick={e => {
                  showContextMenu(e, [
                    {
                      text: 'Edit',
                      onClick: () => {
                        // navigate({
                        //   type: PaneType.inspectObj,
                        //   obj: { type: ObjectType.page, id: id },
                        // })
                      }
                    },
                  ], true);
                }}
              >
                <Svg name="dots" />
              </span>
            </>
          )}
      >
        <NotionDocumentWindow
          key={id}
          window={state}
        />
      </ObjectPageFrame>
    );
  }

  return (
    <>
      {isDevMode() && <button onClick={() => {
        console.log(x(pane));
      }}>Debug</button>}
    </>

  );
}

function viewTitle(pane) {
  if (isEmptyPane(pane)) return '';

  if (pane.type == 'inbox') {
    return 'Inbox';
  }
  if (pane.type == '146a4264-a507-5fb9-b7d3-e59ee00fb050') {
    return <Svg name="icons8-page" />;
  }
  if (pane.type == 'entity') {
    return <ObjectDisplay obj={{
      id: pane.id,
      type: ObjectType.entity,
    }} />;
  }
  else if (pane.type == PaneType.canvas) {
    return (
      <ObjectDisplay
        obj={{
          id: pane.id,
          type: ObjectType.canvas,
        }} />
    );
  }
  else if (pane.type == PaneType.page) {
    return (
      <ObjectDisplay
        obj={{
          id: pane.id,
          type: ObjectType.page,
        }} />
    )
      }
  else if (pane.type == PaneType.tableRow) {
    console.log(x(pane));
    const [doc, row] = resolvePath(pane.path);
    
    const cols = doc.tableData.columns;

    const titleCol = cols.find(col => col.type == Type.name);

    return row.values?.[titleCol._id] || '';


    // return <ObjectDisplay obj={{
    //   id: pane.id,
    //   type: ObjectType.page,
    // }} />;
  }
  else if (pane.type == 'query') {
    return <ObjectDisplay obj={{
      id: pane.id,
      type: ObjectType.query,
    }} />;
  }
  const id = pane.id;
  if (pane.type == WindowType.EntityQueues) {
    const entity = getEntityById(pane.entity);
    return entity.name + ' queues';
  }
  if (id == 'linear') {
    return 'Linear';
  }
  if (pane.type == 'spaceEntities') {
    return <ObjectDisplay
      obj={{
        id: pane.space,
        type: ObjectType.space,
      }} />;
  }

  const notionDoc = db.notionDocuments.findById(pane.id);
  if (notionDoc) {
    return <ObjectDisplay
      obj={{
        type: ObjectType.document,
        id: notionDoc._id,
      }} />;
  }



  return '';

}

@component
class Title extends Component<{ pane; }> {
  static styles = styled.span`
  display: inline-flex;
  align-items: center;
    svg {
      fill: #3131319c;
    }
  `;
  render() {
    if (!this.props.pane) return '';
    return viewTitle(this.props.pane) || '';
  }
}

@component
class LeftSidebar extends Component {
  state = X({});
  context: SystemContextProps;
  static contextType = SystemContext;

  static styles = styled.div`
    user-select: none;
    background-color: #ffffff;
    ${sidebarStyles}

    ${ModeSelect} {
      margin: 0 8px;
      margin-bottom: 8px;
    }

    > .top {
      padding-left: ${isElectron() ? '68px' : '8px'};
      
      border-bottom: none;
    }

    
    > .content {
      background-color: #ffffff;
      position: absolute;
      top: ${isElectron() ? '37px' : '0'};
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      > .top {
        flex: 0 0 auto;

        > .items {
          > .item {
            svg {
              width: 13px;
              height: 13px;
              fill: ${iconColor};
              margin-right: 4px;
            }

            cursor: pointer;
            font-size: 13px;
            margin: 0 8px;
            margin-bottom: 2px;
            padding: 2px 5px;
            border-radius: 2px;
            display: flex;
            align-items: center;
            color: #808080;
            font-weight: 500;

            &:hover {
              background: rgba(0, 0, 0, 0.04);
            }
          }
        }
      }

      > .middle {
        padding: 16px 0;
        overflow: auto;
        flex: 1 1 auto;
        > .section {
          margin-bottom: 30px;
          > .header {
            font-size: 12px;
            line-height: 1;
            color: rgba(55, 53, 47, 0.5);
            font-weight: 600;

            /* margin-left: 31px; */
            margin-left: 14px;
          }
        }
      }

      > .bottom {
        flex: 0 0 auto;
        .add {
          height: 47px;
          display: flex;
          align-items: center;
          padding: 0 12px;
          color: ${color('bgTextColor')};
          font-weight: 600;
          svg {
            margin-right: 12px;
            fill: ${color('bgTextColor')};
          }
          cursor: pointer;

          &:hover {
            background: rgba(255, 255, 255, 0.055);
          }
        }
      }



    }


    .veryTop {
      display: flex;
      /* align-items: center; */
      ${ModeSelect} {
        flex: 1 1 auto;
      }

      :not(:hover) {
        .toggleLeftSidebar {
          display: none;
        }
      }

      .toggleLeftSidebar {
        margin-top: 7px;
        margin-right: 7px;
      }
    }


    z-index: 9999;
    .toggleLeftSidebar.svg-button {
      transform: rotate(0);
      margin-left: auto;

      svg path {
        fill: rgba(55,53,47,0.45);
      }



    }
  `;

  render(Container?) {
    const hasFavorites = !!db.modes.findById(appState.currentMode)?.favorites?.length || db.days.filter(d => d.user == appState.user).length > 0;
    const user = db.users.findById(appState.user);
    return (
      <>
        {appState.leftSidebar && (
          <Container>
            {(
              <>
                <div className="top">

                  <span className="title">
                  </span>
                </div>
              </>
            )}

            <div className="content">
              <div className="top">
                <div className="veryTop">
                  <ModeSelect />
                  <span
                    className={classNames('toggleLeftSidebar svg-button', { visible: appState.leftSidebar })}
                    onClick={() => {
                      appState.leftSidebar = !appState.leftSidebar;
                    }}
                  >
                    <Svg name="doubleChevronLeft" />
                  </span>
                  {/* <div>
                    <button
                      onClick={() => {
                        // appState.lef
                      }}
                    >X</button>
                  </div> */}
                </div>

                <div className="items">
                  <div className="item"
                    onClick={() => {
                      openSearch();
                    }}
                  >
                    <Svg name="magnifyingGlass" /> Search
                  </div>
                  <div className="item"
                    onClick={() => {

                    }}
                  >
                    <Svg name="tesseract" /> Entities
                  </div>
                  {user.features?.notes && <div className="item"
                    onClick={() => {
                      this.context.navigate({
                        type: PaneType.notes,
                      }); 
                    }}
                  >
                    <Svg name="icons8-note" /> Notes
                  </div>}
                  <div className="item"
                    onClick={() => {
                      this.context.navigate({
                        type: PaneType.events
                      }); 
                    }}
                  >
                    <Svg name="icons8-time" /> Events
                  </div>
                  <div className="item"
                    onClick={() => {
                      this.context.navigate({
                        type: PaneType.cards
                      }); 
                    }}
                  >
                    <Svg name="icons8-note" /> Cards
                  </div>
                  {user.features?.days && <div className="item"
                    onClick={() => {
                      this.context.navigate({
                        type: PaneType.days,
                      });
                    }}
                  >
                    <Svg name="icons8-day" /> Days
                  </div>}
                </div>
              </div>
              <div className="middle">
                {/* {hasFavorites && (
                  <div className="section">
                    <span className="header">Favorites</span>
                    <Sidebar
                      ignoreChildren
                      rootItem={new FavoritesSidebar()}
                      state={XObject.get(appState, 'leftSidebar/favorites', {})}
                    />
                  </div>
                )} */}
                {getSidebar() && (
                  <div className="section">
                    {hasFavorites && <span className="header">Private</span>}
                    <Sidebar rootItem={new RootSidebarItem()} state={XObject.get(getSidebar(), 'state', {})} />
                  </div>
                )}
              </div>
              <div className="bottom">
                {/* <div className="add"
                  onClick={e => {
                    if (notionMode.enabled) {
                      const doc = XObject.obj({
                        name: 'new page',
                        blocks: [],
                        showInitView: true,
                      });
                      db.notionDocuments.push(doc);

                      getSidebar().children.push(XObject.obj({
                        type: SidebarItemType.page,
                        id: doc._id,
                      }));
                    }
                    else {

                    }
                  }}
                >
                  <Svg name="plus" /> New
                </div> */}
              </div>
            </div>
          </Container>
        )}
      </>
    );
  }
}

@component
class Create extends Component {
  static styles = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50%;
    svg {
      width: 32px;
      height: 32px;
    }
  `;
  static contextType = SystemContext;
  context: SystemContextProps;

  render() {
    const types = typesInScope({ type: ObjectType.mode, id: appState.currentMode });
    return (
      <>
      <WithContextAction
        contextPosition="bottomRight"
        menu={db.modes.map(m => ({
          text: m.name,
          active: appState.currentMode == m._id,
          onClick: () => {
            appState.currentMode = m._id;

            if (isMobile()) {
              this.context.backToRoot();
            }
          }
        }))}
      >
        <Svg name="icons8-create (2)"
        colors={{
          '#000000': 'rgb(136, 164, 255)',
        }}
          onClick={e => {
            showContextMenu(e, types.map(t => ({
              text: <ObjectDisplay showPath obj={{
                type: ObjectType.type,
                id: t,
              }} />,
              onClick: () => {
                const entity = createEntity({
                  type: t,
                }, t);
                this.context.navigate({
                  type: 'entity',
                  id: entity._id,
                });
              }
            })), 'bottomRight');
          }}
        />
        </WithContextAction>
      </>
    )
  }
}

@component
class RightSidebar extends Component {
  state = X({});
  static styles = styled.div`
    ${sidebarStyles}
    right: 0;

    background-color: white;
      width: ${rightWidth};
      > .top {
        background-color: #fbfbfb;
        z-index: 1;
      }

      > .content {
        top: 37px;
      }
  `;

  render(Container?) {
    return (
      <>
        {appState.rightSidebar && <Container className="rightSidebar">
          <div className="top">
            <span className="title">
              {(() => {

                if (appState.inspecting?.type == 'entity') {
                  const entity = getEntityById(appState.inspecting.id);
                  return entityDisplayView(entity._id);
                }
              })()}

              
            </span>

            {/* <RightTopPanel /> */}



          </div>
          <div className="content">
              Hello
            <GraphView__ />

            {(() => {

              if (appState.inspecting?.type == 'entity') {
                // return renderInspect(appState.inspecting.id, this.inspectState);
                return (
                  <>

                    {/* <EntityInspect id={appState.inspecting.id} /> */}

                    {/* <EntityView id={appState.inspecting.id} state={this.state} /> */}

                  </>
                );
              }
              else {
                return <div>Nothing to inspect</div>;
              }
            })()}
          </div>
        </Container>}</>
    );
  }
}
const topHeight = 37 * 2;
export function initPaneObject(config) {
  return XObject.obj({
    viewType: appState.lastViewState || 'stack',
    pointer: 0,
    stack: [XObject.obj(config)],
  });
}

@component
class ClientView extends Component<{ pane, i }> {
  static contextType = SystemContext;
  context: SystemContextProps;
  scrollToNew(id) {

    const timerId = setInterval(() => {
      const el = jQuery(`[data-stack-id="${id}"]`);
      if (el.length) {
        setTimeout(() => {
          el[0].scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
          });
  
        }, 200);
        clearInterval(timerId);
      }
    }, 100);
  }
  render() {
    const { pane, i } = this.props;
    
    return (
      <div className={classNames("client", pane.viewType)} key={pane._id}>
      {(() => {
        if (pane.viewType == 'browser') {
          const current = pane.stack?.[pane.pointer];
          if (!current)
            return null;
          return (
            <div className="clientComp" key={current._id}>
              <SystemContext.Provider
                value={{
                  navigate: (config) => {
                    inspectFromNav(config, i);
                    const newItem = XObject.obj(config);
                    pane.stack = X([...x(pane.stack.slice(0, pane.pointer + 1)), newItem]);
                    pane.pointer = pane.stack.length - 1;
                  },
                  next: () => {
                    return pane.stack[pane.pointer + 1];
                  },
                  viewType: pane.viewType,
                }}
              >

                {renderView(current, XObject.get(current, 'state', {}), (config) => {
                  inspectFromNav(config, i);
                  pane.stack = X([...x(pane.stack.slice(0, pane.pointer + 1)), XObject.obj(config)]);
                  pane.pointer = pane.stack.length - 1;
                })}
              </SystemContext.Provider>
            </div>
          );
        }
        else if (pane.viewType == 'stack') {
          return pane?.stack?.map?.((item, j) => {
            return (
              <div 
                className="clientComp"
                key={item._id}
                data-stack-id={item._id}
                style={{
                  width: viewWidth(item),
                }}
              >
                <SystemContext.Provider
                  value={{
                    navigate: (config) => {
                      inspectFromNav(config, i);
                      const newItem = XObject.obj(config);
                      pane.stack = X([...x(pane.stack.slice(0, j + 1)), newItem]);
                      pane.pointer = pane.stack.length - 1;
                      const el = ReactDOM.findDOMNode(this) as Element;

                      this.scrollToNew(newItem._id);

                    },
                    next: () => {
                      return pane.stack[j + 1];
                    },
                    viewType: pane.viewType,
                  }}
                >

                  {renderView(item, XObject.get(item, 'state', {}), (config) => {
                    inspectFromNav(config, i);
                    // use j instead of pane.pointer
                    pane.stack = X([...x(pane.stack.slice(0, j + 1)), XObject.obj(config)]);
                    pane.pointer = pane.stack.length - 1;
                  })}
                </SystemContext.Provider>
              </div>
            );
          });
        }
      })()}

    </div>
    )

  }
}

const speed = 200;
const delay = 10;

@component
class ModalFrame extends Component<{ title, children? }> {
  static styles = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    > .header {
      height: 30px;
      font-weight: 500;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #f0f0f0;
      box-sizing: border-box;
      > .close {
        position: absolute;
        right: 8px;
        top: 0;
        width: 24px;
        height: 24px;
        bottom: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 16px;
          height: 16px;
          fill: #bababa;
        }
      }
    }
    > .content {
      position: absolute;
      top: 30px;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
    }
  `;
  render() {
    return (
      <>
        <div className="header">
          {this.props.title}
          <span
            className="close"
            onClick={() => {
              closeMobileModal();
            }}
          ><Svg name="icons8-close" /></span>
        </div>
        <div className="content">
          {this.props.children}
        </div>
      </>
    )
  }
}

@component
class ActionSheet extends Component<{ options, close }> {
  static styles = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0,0,0,0.3);
    overflow: auto;
    user-select: none;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    /* padding: 8px; */
    box-sizing: border-box;


    .options {
      position: absolute;
      left: 24px;
      right: 24px;
      bottom: 8px;
      border-radius: 8px;
      max-height: calc(100% - ${8+8}px);
      overflow: auto;
      box-shadow: 0 0 8px rgba(0,0,0,0.3);

      > .option {
        height: 48px;
        line-height: 48px;
        text-align: center;
        background: #fff;
        border-top: 1px solid #f0f0f0;
        box-sizing: border-box;
        cursor: pointer;

        &.active {
          font-weight: bold;
        }
        
        &:last-child {
          border-bottom: 1px solid #f0f0f0;
        }
      }
    }
  `;
  render(Container?) {
    return (
      <Container
        onMouseDown={e => {
          if (e.target == e.currentTarget) {
            console.log('close!!!!');
            this.props.close();
          }
        }}
      >
        <div className="options">
          {this.props.options.map((item, i) => {
            return (
              <div
                className={classNames('option', {
                  active: item?.active,
                })}
                key={i}
                onClick={() => {
                  item.onClick();
                  this.props.close();
                  
                }}
              >{item?.text}</div>
            )
          })}
        </div>
      </Container>
    )
  }
}


export function MobileRoot({ pathArg }) {
  const navigate = useNavigate();
  return <_MobileRoot pathArg={pathArg} navigate={navigate} />
}

const Wrapper = styled.div`

  &.occurrenceBar {
  > .screen {
    bottom: 45px;
  }
  }

  &:not(.occurrenceBar) {
    > .screen {
      bottom: 0;
    }
  }

  > .occurrenceBar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 45px;
    border-top: 1px solid #ddd;
    z-index: 99999999;
    ${OccurrenceBar} {
      height: 45px;
    }
  }

  ${Create} {
    position: fixed;
    bottom: 8px;
    right: 8px;
    z-index: 99999999;
  }
`;

@component
class _MobileRoot extends Component<{ pathArg, navigate }> {
  static styles = styled.div`
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    > .content {
      position: relative;
      flex: 1 1 0;
      ${OccurrenceBar} {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 45px;
        border-top: 1px solid #ddd;
      }

      ${LeftSidebar} > .content {
        padding-top: 8px;
      }

      > .view {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: white;
        border-left: 1px solid #ddd;
        box-shadow: 0 0px 5px rgb(220 220 220);
        transition: left 0.1s ease-in-out;
      }

      > .inspect {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: white;
      }

      &.showSidebar {
        > .view {
          left: calc(100% - 80px);
        }
      }

      &.occurrenceBar {
        > .view, > ${LeftSidebar} > .content {
          bottom: 45px;

        }
      }
    }

    > .tabs {
      flex: 0 0 auto;
      display: flex;
      border-top: 1px solid #ebebeb;
      padding: 0 8px;
      align-items: center;
      .tab {
        /* border: 1px solid black; */
        font-size: 16px;
        cursor: pointer;
        padding: 4px;
      }

      ${ModeSelect} {
        margin-left: auto;
      }
    }
  `;

  modal: [any, any][] = []

  actionSheet

  closeActionSheet() {
    this.actionSheet[1].animate({
      opacity: 0,
    }, speed);
    setTimeout(() => {
      this.actionSheet[0].unmount();
      this.actionSheet[1].remove();  
    }, speed);
  }
  presentActionSheet(options) {
    const e = this.createNavStackLayer(<ActionSheet options={options} close={() => {
      this.closeActionSheet();
    }} />, {
      zIndex: 99999999999999,
      top: 0,
      left: 0,
    });
    this.actionSheet = e;

    e[1].css({
      opacity: 0,
    });

    setTimeout(() => {
      e[1].animate({
        opacity: 1,
      }, speed);
    }, delay);



    // e[1].css({
    // });

    // setTimeout(() => {
    //   e[1].animate({
    //     top: 0,
    //   }, speed);
    // }, delay);
  }

  presentModal(el, title?) {
    const e = this.createNavStackLayer(title ? <ModalFrame title={title}>{el}</ModalFrame> : el, {
      top: '100%',
      overflow: 'auto',
      backgroundColor: 'white',
    });

    this.modal.push(e);

    e[1].css({
      zIndex: 9999999 + this.modal.length,
    });

    setTimeout(() => {
      e[1].animate({
        top: 0,
      }, speed);
    }, delay);
  }

  closeModal() {
    return new Promise<void>(resolve => {
      const a = this.modal.pop();
      if (a) {
        a[1].animate({
          top: '100%',
        }, speed, () => {
          a[1].remove();
          a[0].unmount();
          resolve();
        });
      }
      else {
        resolve();
      }
    });
  }

  state = XInit(class {
    view
    token
  });

  current

  renderStack(i) {
    if (i == 0) {
      if (appState.mobileTab == 'add') {
        return <CreateRoot />;
      }
      if (appState.mobileTab == 'browse') {
        return <LeftSidebar />;
      }
      if (appState.mobileTab == 'track') {
        return <TrackScreen />;
      }
    }
    else {
      return (
        renderView(appState.mobileStack[i - 1], this.state, () => {})
      );
    }
  }

  back(_next?, root?) {
    if (!appState.mobileStack.length) return;
    if (root) {
      appState.mobileStack = X([]);
    }
    else {
      appState.mobileStack.pop();
    }
    const current = this.current;
    const next = _next || this.createNavStackLayer(this.renderStack(appState.mobileStack.length), {
      backgroundColor: 'white',
    });
    
    if (!_next) {
      next[1].css({
        left: '-20%',
        zIndex: -1,
      });
    }

    this.current = next;

    setTimeout(() => {
      next[1].animate({
        left: 0,
      }, speed);
      current[1].animate({
        left: '100%',
      }, speed, () => {
        next[1].css({
          zIndex: 0,
        });
        current[1].remove();
        current[0].unmount();
      });
    }, delay);
  }

  wrapContext(el) {
    return (
      <SystemContext.Provider
        value={{
          next: () => {},
          navigate: (config) => {

            if (useMobileNavSystem()) {
              const pane = XObject.obj(config);
              appState.mobileStack = X([...x(appState.mobileStack || []), pane]);

              const current = this.current;
              const el = this.createNavStackLayer(this.renderStack(appState.mobileStack.length), {
                backgroundColor: 'white',
              });

              this.current = el;
              

              el[1].css({
                left: '100%',
              })

              setTimeout(() => {
                el[1].animate({
                  left: 0,
                }, speed);
                
                current[1].animate({
                  left: '-20%',
                }, speed, () => {
                  current[1].remove();
                  current[0].unmount();
                });
              }, delay);

            }
            else {
              this.state.view = XObject.obj(config);
              memoryAppState.showSidebar = false;
              this.props.navigate('/' + btoa(JSON.stringify(x(config))));

            }


          },
          back: () => {
            this.back();
          },
          backToRoot: () => {
            this.back(null, true);
          }
        }}
      >
        {el}
      </SystemContext.Provider>
    );
  }

  createNavStackLayer(aaa, rootStyles): [any,any] {
    const el = ReactDOM.findDOMNode(this) as Element;
    const rootEl = jQuery(`<div class="screen" />`).css({
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      bottom: 0,
      // background: 'white',
      ...rootStyles,
    })
    rootEl.appendTo(el);
    const root = ReactDOMClient.createRoot(rootEl[0]);


    root.render(
      this.wrapContext(aaa)
      // <SystemContext.Provider
      //   value={{
      //     next: () => {},
      //     navigate: (config) => {

      //       if (useMobileNavSystem()) {
      //         const pane = XObject.obj(config);
      //         appState.mobileStack = X([...x(appState.mobileStack || []), pane]);
  
      //         const current = this.current;
      //         const el = this.createNavStackLayer(this.renderStack(appState.mobileStack.length), {
      //           backgroundColor: 'white',
      //         });
  
      //         this.current = el;
              
  
      //         el[1].css({
      //           left: '100%',
      //         })
  
      //         setTimeout(() => {
      //           el[1].animate({
      //             left: 0,
      //           }, speed);
                
      //           current[1].animate({
      //             left: '-20%',
      //           }, speed, () => {
      //             current[1].remove();
      //             current[0].unmount();
      //           });
      //         }, delay);
  
      //       }
      //       else {
      //         this.state.view = XObject.obj(config);
      //         memoryAppState.showSidebar = false;
      //         this.props.navigate('/' + btoa(JSON.stringify(x(config))));
  
      //       }


      //     },
      //     back: () => {
      //       this.back();
      //     }
      //   }}
      // >
      //   {aaa}
      // </SystemContext.Provider>
    );

    return [root, rootEl];
  }

  componentDidMount(): void {
    setMobileComp(this);
    let occurrenceBarRoot;
    let prevHasOccurrenceBar = false;
    setInterval(() => {
      const hasOccurrenceBar = !!getCurrentOccurrence();

      if (prevHasOccurrenceBar != hasOccurrenceBar) {
        prevHasOccurrenceBar = hasOccurrenceBar;

        const el = ReactDOM.findDOMNode(this) as Element;
        if (hasOccurrenceBar) {
          jQuery(el).addClass('occurrenceBar');
          const rootEl = jQuery(`<div class="occurrenceBar" />`);
          rootEl.appendTo(el);
          const root = ReactDOMClient.createRoot(rootEl[0]);
          root.render(
            <SystemContext.Provider
              value={{
                navigate: (config) => {
                  presentMobileModal(renderView(config, {}, null), 'Asdf');
                },
              }}
            >
            <OccurrenceBar />
            </SystemContext.Provider>
          );

          occurrenceBarRoot = [root, rootEl]
        }
        else {
          jQuery(el).removeClass('occurrenceBar');
          occurrenceBarRoot[0].unmount();
          occurrenceBarRoot[1].remove();
          occurrenceBarRoot = null;
        }
        
      }
    }, 1000);
    /*if (useMobileNavSystem()) {
      this.current = this.createNavStackLayer(this.renderStack(appState.mobileStack.length), {
        backgroundColor: 'white',
      });
      let initPullBack;
      let pullingBack;
      let next;
      const updatePullBack = e => {
        const left = e.touches[0].clientX;
        const percentage =  left / jQuery(window).width();
        this.current[1].css({
          left: e.touches[0].clientX,
        });

        const start = -jQuery(window).width() * 0.2;
        const end = 0;
        next[1].css({
          left: start + (end - start) * percentage,
        });

      }
      const width = 15;
      document.addEventListener('touchstart', e => {
        const touch = e.touches[0];
        if (touch.clientX < width) {
          initPullBack = touch;
          e.preventDefault();
        }
      });

      document.addEventListener('touchend', e => {
        console.log(initPullBack, pullingBack, next);
        if (!initPullBack) return;
        if (pullingBack) {
          if (e.changedTouches[0].clientX > jQuery(window).width() * 0.2) {
            const n = next;
            initPullBack = null;
            pullingBack = false;
            next = null;
            this.back(n);
          }
          else {
            const n = next;
            next = null;
            initPullBack = null;
            pullingBack = false;
  
            this.current[1].animate({
              left: 0,
            }, speed, () => {
              n[0].unmount();
              n[1].remove();
  
            });
  
          }
        }
        else {
          initPullBack = null;
        }

      });

      document.addEventListener('touchmove', e => {
        if (pullingBack) {
          e.preventDefault();
          updatePullBack(e);
        }
        else if (initPullBack) {
          e.preventDefault();

          const touch = e.touches[0];
          const delta = touch.clientX - initPullBack.clientX;
          if (touch.clientX > width && delta > 10) {
            
            if (Math.abs(touch.clientY - initPullBack.clientY) < 15) {
              next = this.createNavStackLayer(this.renderStack(appState.mobileStack.length - 1), {
                backgroundColor: 'white',
              });
              next[1].css({
                zIndex: -1,
              });
              pullingBack = true;
              updatePullBack(e);
            }
          }
        }
      });


      let occurrenceBarRoot;
      let prevHasOccurrenceBar = false;
      setInterval(() => {
        const hasOccurrenceBar = !!getCurrentOccurrence();

        if (prevHasOccurrenceBar != hasOccurrenceBar) {
          prevHasOccurrenceBar = hasOccurrenceBar;

          const el = ReactDOM.findDOMNode(this) as Element;
          if (hasOccurrenceBar) {
            jQuery(el).addClass('occurrenceBar');
            const rootEl = jQuery(`<div class="occurrenceBar" />`);
            rootEl.appendTo(el);
            const root = ReactDOMClient.createRoot(rootEl[0]);
            root.render(<OccurrenceBar />);

            occurrenceBarRoot = [root, rootEl]
          }
          else {
            jQuery(el).removeClass('occurrenceBar');
            occurrenceBarRoot[0].unmount();
            occurrenceBarRoot[1].remove();
            occurrenceBarRoot = null;
          }
          
        }
      }, 1000);
    }
    else {
      // const hammer = new Hammer(ReactDOM.findDOMNode(this) as Element);

      // hammer.on('panmove', (e) => {
      // });

      // hammer.on('panend', (e) => {
      //   if (e.deltaX > 100) {
      //     appState.mobileStack?.pop?.();
      //   }

      // });
    }*/
  }

  shouldComponentUpdate(nextProps: Readonly<{ pathArg: any; }>, nextState: Readonly<{}>, nextContext: any): boolean {
    if (useMobileNavSystem()) {
      return false;
    }
    return true;
  }

  render() {
    let c;
    if (appState.mobileTab == 'home') {
      const addState = XObject.get(appState, 'homeTab', {});
      c = (
        <NavStack
          key="home"
          renderStack={(i) => {
            if (i == 0) {
              return (
                <HomeScreen />
              );
            }
            else {
              return (
                renderView(XObject.get(addState, 'stack', [])[i - 1], this.state, () => {})
              );
            }
          }}
          setStack={stack => addState.stack = stack}
          stack={() => XObject.get(addState, 'stack', [])}
        />
      );
    }
    if (appState.mobileTab == 'browse') {
      c = (
        <NavStack
          key="browse"
          renderStack={(i) => {
            if (i == 0) {
                return <LeftSidebar />;
            }
            else {
              return (
                renderView(appState.mobileStack[i - 1], this.state, () => {})
              );
            }
          }}
          setStack={stack => appState.mobileStack = stack}
          stack={() => appState.mobileStack}
        />
      )
    }
    if (appState.mobileTab == 'add') {
      const addState = XObject.get(appState, 'addTab', {});
      c = (
        <NavStack
        key="add"
            renderStack={(i) => {
              if (i == 0) {
                  return <CreateRoot />;
              }
              else {
                return (
                  renderView(XObject.get(addState, 'stack', [])[i - 1], this.state, () => {})
                );
              }
            }}
            setStack={stack => addState.stack = stack}
            stack={() => XObject.get(addState, 'stack', [])}
          />
      )
    }
    if (appState.mobileTab == 'track') {
      const addState = XObject.get(appState, 'trackTab', {});
      c = (
        <NavStack
          key="track"
          renderStack={(i) => {
            if (i == 0) {
              return <TrackScreen />;
            }
            else {
              return (
                renderView(XObject.get(addState, 'stack', [])[i - 1], this.state, () => {})
              );
            }
          }}
          setStack={stack => addState.stack = stack}
          stack={() => XObject.get(addState, 'stack', [])}
        />
      )
    }
    if (appState.mobileTab == 'notes') {
      const addState = XObject.get(appState, 'notesTab', {});
      c = (
        <NavStack
          key="notes"
          renderStack={(i) => {
            if (i == 0) {
              return <NotesRoot />;
            }
            else {
              return (
                renderView(XObject.get(addState, 'stack', [])[i - 1], this.state, () => {})
              );
            }
          }}
          setStack={stack => addState.stack = stack}
          stack={() => XObject.get(addState, 'stack', [])}
        />
      )
    }

    return (
      <>
        <div className="content">
          {c}
        </div>
        <div className="tabs">
        <div
            className="tab"
            style={{
              fontWeight: appState.mobileTab == 'home' ? 'bold' : undefined,
            }}
            onClick={() => {
              appState.mobileTab = 'home';
            }}
          >Home</div>
          <div className="tab"
                      style={{
                        fontWeight: appState.mobileTab == 'add' ? 'bold' : undefined,
                      }}
          
          onClick={() => {
            appState.mobileTab = 'add';
          }}

          >Add</div>
          <div className="tab"
                      style={{
                        fontWeight: appState.mobileTab == 'track' ? 'bold' : undefined,
                      }}
          
          onClick={() => {
            appState.mobileTab = 'track';
          }}

          >Track</div>
          <div className="tab"
                      style={{
                        fontWeight: appState.mobileTab == 'notes' ? 'bold' : undefined,
                      }}
          
          onClick={() => {
            appState.mobileTab = 'notes';
          }}

          >Notes</div>
          <div
            className="tab"
            style={{
              fontWeight: appState.mobileTab == 'browse' ? 'bold' : undefined,
            }}
            onClick={() => {
              appState.mobileTab = 'browse';
            }}
          >Browse</div>

          <ModeSelect />
        </div>
      </>
    );
    /*if (useMobileNavSystem()) {
      return (
        <Wrapper>
          {this.wrapContext(<Create />)}
        </Wrapper>
      );
    }

    let config;

    const fix = (str) => {
      if (_.isString(str)) {
        if (str.endsWith('/')) {
          str = str.slice(0, -1);
        }
      }

      return str;

    }

    let pathArg = fix(this.props.pathArg) || 'root';

    if (pathArg) {
      if (this.state.token != pathArg) {
        if (pathArg == 'root') {
          // config = {"type":"page","id":"646cf264694d7e70c6d262cc"}
        }
        else {
          try {
            config = JSON.parse(atob(pathArg));
          }
          catch (e) {
            // console.error(e);
            pathArg = 'root';
          }
        }

        this.state.token = pathArg;        
        this.state.view = config ? XObject.obj(config) : null;
      }
    }
    else {
      
    }

    return (
      <Container
        className={classNames({
          showSidebar: memoryAppState.showSidebar,
          occurrenceBar: getCurrentOccurrence(),
        })}
      >
        <SystemContext.Provider
          value={{
            next: () => {},
            navigate: (config) => {
              this.state.view = XObject.obj(config);
              memoryAppState.showSidebar = false;
              this.props.navigate('/' + btoa(JSON.stringify(x(config))));
            },
            back: () => {
              window['history'].back();
            },
          }}
        >
          <LeftSidebar />
          <div className="view" key={pathArg}>
            {pathArg == 'root' && (
              <>
                <LeftSidebar />
              </>
            )}
            {pathArg != 'root' && this.state.view && renderView(this.state.view, this.state, () => {

            })}
          </div>
          <OccurrenceBar />
        </SystemContext.Provider>
      </Container>
    );*/
  }
}

@component
class NavStack extends Component<{
  stack: () => any
  setStack: (v) => any
  renderStack,
}> {
  static styles = styled.div`
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  `;

  state = XInit(class {
    view
    token
  });

  current

  renderStack(i) {
    return this.props.renderStack(i);
    // if (i == 0) {
    //   return <CreateRoot />;
    //   // return <LeftSidebar />;
    // }
    // else {
    //   return (
    //     renderView(appState.mobileStack[i - 1], this.state, () => {})
    //   );
    // }
  }

  back(_next?, root?) {
    if (!this.props.stack().length) return;
    if (root) {
      this.props.setStack(X([]));
    }
    else {
      this.props.stack().pop();
    }
    const current = this.current;
    const next = _next || this.createNavStackLayer(this.renderStack(this.props.stack().length), {
      backgroundColor: 'white',
    });
    
    if (!_next) {
      next[1].css({
        left: '-20%',
        zIndex: -1,
      });
    }

    this.current = next;

    setTimeout(() => {
      next[1].animate({
        left: 0,
      }, speed);
      current[1].animate({
        left: '100%',
      }, speed, () => {
        next[1].css({
          zIndex: 0,
        });
        current[1].remove();
        current[0].unmount();
      });
    }, delay);
  }

  wrapContext(el) {
    return (
      <SystemContext.Provider
        value={{
          next: () => {},
          navigate: (config) => {
            const pane = XObject.obj(config);
            this.props.setStack(X([...x(this.props.stack() || []), pane]));


            const current = this.current;
            const el = this.createNavStackLayer(this.renderStack(this.props.stack().length), {
              backgroundColor: 'white',
            });

            this.current = el;
            

            el[1].css({
              left: '100%',
            })

            setTimeout(() => {
              el[1].animate({
                left: 0,
              }, speed);
              
              current[1].animate({
                left: '-20%',
              }, speed, () => {
                current[1].remove();
                current[0].unmount();
              });
            }, delay);
          },
          back: () => {
            this.back();
          },
          backToRoot: () => {
            this.back(null, true);
          }
        }}
      >
        {el}
      </SystemContext.Provider>
    );
  }

  createNavStackLayer(aaa, rootStyles): [any,any] {
    const el = ReactDOM.findDOMNode(this) as Element;
    const rootEl = jQuery(`<div class="screen" />`).css({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      bottom: 0,
      // background: 'white',
      ...rootStyles,
    })
    rootEl.appendTo(el);
    const root = ReactDOMClient.createRoot(rootEl[0]);


    root.render(
      this.wrapContext(aaa)
      // <SystemContext.Provider
      //   value={{
      //     next: () => {},
      //     navigate: (config) => {

      //       if (useMobileNavSystem()) {
      //         const pane = XObject.obj(config);
      //         appState.mobileStack = X([...x(appState.mobileStack || []), pane]);
  
      //         const current = this.current;
      //         const el = this.createNavStackLayer(this.renderStack(appState.mobileStack.length), {
      //           backgroundColor: 'white',
      //         });
  
      //         this.current = el;
              
  
      //         el[1].css({
      //           left: '100%',
      //         })
  
      //         setTimeout(() => {
      //           el[1].animate({
      //             left: 0,
      //           }, speed);
                
      //           current[1].animate({
      //             left: '-20%',
      //           }, speed, () => {
      //             current[1].remove();
      //             current[0].unmount();
      //           });
      //         }, delay);
  
      //       }
      //       else {
      //         this.state.view = XObject.obj(config);
      //         memoryAppState.showSidebar = false;
      //         this.props.navigate('/' + btoa(JSON.stringify(x(config))));
  
      //       }


      //     },
      //     back: () => {
      //       this.back();
      //     }
      //   }}
      // >
      //   {aaa}
      // </SystemContext.Provider>
    );

    return [root, rootEl];
  }

  componentDidMount(): void {
    this.current = this.createNavStackLayer(this.renderStack(this.props.stack().length), {
      backgroundColor: 'white',
    });
    let initPullBack;
    let pullingBack;
    let next;
    const updatePullBack = e => {
      const left = e.touches[0].clientX;
      const percentage =  left / jQuery(window).width();
      this.current[1].css({
        left: e.touches[0].clientX,
      });

      const start = -jQuery(window).width() * 0.2;
      const end = 0;
      next[1].css({
        left: start + (end - start) * percentage,
      });

    }
    const width = 15;
    document.addEventListener('touchstart', e => {
      const touch = e.touches[0];
      if (touch.clientX < width) {
        initPullBack = touch;
        e.preventDefault();
      }
    });

    document.addEventListener('touchend', e => {
      console.log(initPullBack, pullingBack, next);
      if (!initPullBack) return;
      if (pullingBack) {
        if (e.changedTouches[0].clientX > jQuery(window).width() * 0.2) {
          const n = next;
          initPullBack = null;
          pullingBack = false;
          next = null;
          this.back(n);
        }
        else {
          const n = next;
          next = null;
          initPullBack = null;
          pullingBack = false;

          this.current[1].animate({
            left: 0,
          }, speed, () => {
            n[0].unmount();
            n[1].remove();

          });

        }
      }
      else {
        initPullBack = null;
      }

    });

    document.addEventListener('touchmove', e => {
      if (pullingBack) {
        e.preventDefault();
        updatePullBack(e);
      }
      else if (initPullBack) {
        e.preventDefault();

        const touch = e.touches[0];
        const delta = touch.clientX - initPullBack.clientX;
        if (touch.clientX > width && delta > 10) {
          
          if (Math.abs(touch.clientY - initPullBack.clientY) < 15) {
            next = this.createNavStackLayer(this.renderStack(this.props.stack().length - 1), {
              backgroundColor: 'white',
            });
            next[1].css({
              zIndex: -1,
            });
            pullingBack = true;
            updatePullBack(e);
          }
        }
      }
    });
  }

  componentWillUnmount(): void {
    this.current[1].remove();
    this.current[0].unmount();
  }

  shouldComponentUpdate(): boolean {
    return false;
  }

  render() {
      return (
        <>

        </>
      );
  }
}


@component
class MobileWebRoot extends Component {
  render() {
    return (
      <>
      </>
    )
  }
}


const bottomPaneHeight = '50%';

@component
export class AppRoot extends Component {
  static styles = styled.div`
    &:not(.electron) {
      ${LeftSidebar} > .content {
        padding-top: 8px;
      }
    }

    ${LeftSidebar}, ${RightSidebar} {
      position: absolute;
      top: 0;
      bottom: 0;
    }

    ${LeftSidebar} {
      border-right: 1px solid ${color('separator')};
      box-shadow: 0 3px 2px rgb(0 0 0 / 10%);
    }
  
    .toggleSidebar {
      &.visible {
        svg path {
          fill: #797979;
        }
      }

      &:not(.visible) {
        rect, line {
          stroke: #797979;
        }
      }
    }

    .toggleLeftSidebar.svg-button {
      /* border-left: 2px solid ${color('separator')}; */
      transform: rotate(180deg);
      cursor: pointer;
      svg path {
          fill: #797979;;
        }


      /* margin-left: auto; */

      &.visible {
      }

      &:not(.visible) {
        rect, line {
          stroke: #797979;
        }
      }
    }

    .svg-button {
      -webkit-app-region: no-drag;

      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: rgba(255, 255, 255, 0.055);
        border-radius: 3px;
      }
      svg {
        width: 16px;
        height: 16px;
        & {
          fill: #797979;
        }
      }
    }

    .toggleGraphView {
      &:not(.active) {
        svg {
          fill: #c1c1c1;
        }
      }
    }

    > .panes {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
      z-index: 1;
      > div {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        overflow: auto;

        &:not(:last-child) {
          border-right: 1px solid ${color('separator')};
        }

        > .top {
          box-shadow: 0px 0px 4px rgb(0 0 0 / 10%);
          position: relative;
          background-color: #fbfbfb;
          height: ${topHeight}px;
          color: ${color('bgTextColor')};
          border-bottom: 1px solid ${color('separator')};
          box-sizing: border-box;


          > .topTop {
            height: 37px;
            -webkit-app-region: drag;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            box-sizing: border-box;
            /* border-bottom: 2px solid red; */
            display: flex;
            align-items: center;
            padding: 0 10px;
            font-size: 12px;
            font-weight: 500;

            > .close {
              margin-left: auto;
            }
            
            /* font-weight: bold; */
            > span {
              -webkit-app-region: no-drag;
            }

            .title {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
              align-items: center;
              svg {
                fill: #3131319c;
                width: 12px;
                height: 12px;
              }
            }

            .add, .toggleSidebar {

            }


            .right {
              position: absolute;
              right: 8px;

              top: 0;
              bottom: 0;
              margin: auto;

              display: flex;
              align-items: center;

            }

            .add {
              flex: 0 0 auto;
              width: 24px;  
              display: flex;
              align-items: center;
              justify-content: center;

              &.last {
                /* border-left: 1px solid ${color('separator')}; */

              }

              &.first {

                /* border-right: 1px solid ${color('separator')}; */
                margin-right: 8px;

              }



              -webkit-app-region: no-drag;
              /* margin-right: 16px; */
              width: 24px;
              height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              &:hover {
                background: rgba(255, 255, 255, 0.055);
                border-radius: 3px;
              }
              svg {
                width: 16px;
                height: 16px;
                fill: #797979;
              }
            }
          }

          > .topBottom {
            position: absolute;
            top: 37px;
            height: 37px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            right: 0;
            box-sizing: border-box;
            overflow: hidden;

            .nav {
              flex: 0 0 auto;
              white-space: nowrap;
              ${NotionButton} {
                &.disabled {
                  opacity: 0.5;
                  /* cursor: default; */
                  pointer-events: none;
                }
                height: 24px;
              }
              svg {
                /* fill: rgba(255,255,255,0.81); */
                fill: #797979;
              }
              .prev {
                svg {
                  transform: rotate(90deg);
                }
              }

              .next {
                svg {
                  transform: rotate(-90deg);
                }
              }

              margin-right: 8px;
            }



            .stack {
              display: flex;
              flex: 0 1 auto;
              overflow: hidden;
              margin-right: 8px;
              .comp {
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                flex: 0 1 auto;
                max-width: 260px;
                .title {
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .active {
                  font-weight: 700;
                }

                &:not(:first-child) {
                  &:before {
                    content: ' › ';
                    margin: 0 4px;
                  }
                }
              }
            }

            .viewType {
              margin-left: auto;
              flex: 0 0 auto;
              width: 24px;
              height: 24px;

              svg {
                width: 18px;
                height: 18px;
              }

              &.stack-view {
                svg {
                  fill: #242424;
                }
              }
              /* width: 100%; */
            }


          }
        }

        &:first-child {
          > .top {
            padding-left: ${isElectron() ? '60px' : '8px'};
            > .topTop/* , > .topBottom */ {
              left: ${isElectron() ? '60px' : '0'};
            }

            > .topBottom {
              left: 0;
            }
          }
        }

        &:not(:first-child) {
          > .top {
            > .topBottom {
              left: 0;
            }
          }
        }

        > .client {
          overflow: auto;
          position: absolute;
          top: ${topHeight + 2}px;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;

          &.stack {
            > .clientComp {
              position: relative;
              flex: 0 0 auto;
              width: 600px;
              overflow: auto;
              &:not(:last-child) {
                border-right: 1px solid ${color('separator')};
              }
            }
          }

          &.browser {
            width: 100%;
            .clientComp {
              width: 100%;
            }
          }

        }

        &:last-child {
          > .client {
            &.stack {
              > .clientComp {
                &:last-child {
                  border-right: 1px solid ${color('separator')};
                }
              }
            }
          }
        }
      }
    }

    &.rightSidebar {
      &:last-child {
          > .panes  > div > .client {
            &.stack {
              > .clientComp {
                &:last-child {
                  /* border-right: none; */
                }
              }
            }
          }
        }

    }

    &.leftSidebar {
      > .panes {
        > div {
          &:first-child {
            > .top {
              padding-left: 0;
              > .topTop, > .topBottom {
                left: 0;
              }
            }
          }
        }
      }
    }

    ${LeftSidebar} {
      left: 0;
      right: auto;
      width: 265px;
    }

    &.rightSidebar {
      > .panes {
        right: ${rightWidth};
      }
    }

    &.leftSidebar {
      > .panes {
        left: 265px;
      }
    }

    .toggleAppInspect {
      -webkit-app-region: no-drag;
    }

    &.bottomPane {
      > .panes {
        bottom: ${bottomPaneHeight};
      }
      ${RightSidebar}, ${LeftSidebar} {
        bottom: ${bottomPaneHeight};
      }
      
      .bottomPane {
        border-top: 2px solid ${color('separator')};
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: ${bottomPaneHeight};
        overflow: auto;
        z-index: 9999;
        background-color: white;
      }
    }

    ${OccurrenceBar} {
      position: fixed;
      bottom: 0;
      right: 0;
      border: 1px solid black;
      z-index: 9999999;
    }
    ${Create} {
      position: fixed;
      bottom: 6px;
      right: 6px;
      z-index: 9999999;
    }
  `;

  inspectState = X({});

  viewState = X({});

  render(Container?) {
    // return <TrackRoot />;
    if (!appState.panes.length) {
      appState.panes.push(initPaneObject({}));
    }
    return (
      <Container
        className={classNames({
          rightSidebar: appState.rightSidebar,
          leftSidebar: appState.leftSidebar,
          bottomPane: appState._appInspect,
          electron: isElectron(),
        })}
      >
        <SystemContext.Provider value={{
          navigate: config => {
            inspectFromNav(config, 0);
            appState.panes[0] = initPaneObject(config);
          },
          next: () => {
            const pane = appState.panes?.[0];
            return pane.stack?.[0];
          },
        }}>
          <LeftSidebar />
          <SearchCont />
        </SystemContext.Provider>
        <div className="panes">
          {appState.panes.map((pane, i) => {
            const current = pane.stack?.[pane.pointer];
            return (
              <div key={pane._id} data-pane-id={pane._id}>
                <div className="top">
                  <div className="topTop">
                    {i == 0 && (
                      <>
                        {!appState.leftSidebar && <>
                          <span
                            className={classNames('toggleLeftSidebar svg-button', { visible: appState.leftSidebar })}
                            onClick={() => {
                              appState.leftSidebar = !appState.leftSidebar;
                            }}>
                            {/* <Svg name={appState.leftSidebar ? ImageEnum.sidebarRightFilled : ImageEnum.sidebarRight} /> */}
                            <Svg name={appState.leftSidebar ? 'doubleChevronLeft' : 'menu'} />
                          </span></>}
                        <span className="add first svg-button"
                          onClick={e => {
                            appState.panes = X([initPaneObject({}), ...x(appState.panes)]);
                            // showContextMenu(e, showViewMenu(id => {
                            //   appState.panes = X([initPaneObject({ id }), ...x(appState.panes)]);
                            // }));
                          }}
                        ><Svg name="plus" /></span>
                      </>
                    )}

                    <span
                      className="title"
                      onContextMenu={e => {
                        e.preventDefault();
                        showContextMenu(e, [
                          {
                            text: 'Close',
                            onClick: () => {
                              appState.panes.splice(i, 1);
                              if (appState.panes.length == 0) {
                                appState.panes.push(XObject.obj());
                              }
                            }
                          }
                        ]);
                      }}
                    >
                      <Title pane={current} />
                    </span>


                    {i < appState.panes.length - 1 && appState.panes.length > 1&& <span className="svg-button close"
                      onClick={() => {
                        appState.panes.splice(i, 1);
                        if (appState.panes.length == 0) {
                          appState.panes.push(XObject.obj());
                        }

                      }}
                    >
                      <Svg name="icons8-close" />
                    </span>}
                    {!notionMode.enabled && appState.panes.length - 1 == i && (
                      <div className="right">
                        {appState.panes.length > 1&& <span className="svg-button close"
                      onClick={() => {
                        appState.panes.splice(i, 1);
                        if (appState.panes.length == 0) {
                          appState.panes.push(XObject.obj());
                        }

                      }}
                    >
                      <Svg name="icons8-close" />
                    </span>}
                        <span
                          className="add last svg-button"
                          onClick={e => {
                            appState.panes.push(initPaneObject({}));
                          }}
                        >
                          <Svg name="plus" />
                        </span>
                        <RightTopPanel />
                      </div>
                    )}
                  </div>
                  <div className="topBottom">
                    <span className="nav">
                      <NotionButton
                        dark
                        className={classNames("prev", {
                          disabled: pane.pointer == 0
                        })}
                        img="chevron"
                        onClick={() => {
                          pane.pointer = Math.max(0, pane.pointer - 1);
                          inspectFromNav(pane.stack[pane.pointer], i);
                        }}
                      />
                      <NotionButton
                        dark
                        className={classNames("next", {
                          disabled: pane.pointer == pane.stack?.length - 1
                        })}
                        img="chevron"
                        onClick={() => {
                          pane.pointer = Math.min(pane.stack.length - 1, pane.pointer + 1);
                        }}
                      />
                    </span>
                    <span className="stack">
                      {pane?.stack?.map?.((item, j) => {
                        return (
                          <span
                            key={j}
                            className={classNames('comp')}
                            onClick={() => {
                              pane.pointer = j;
                              inspectFromNav(item, i);
                            }}
                          >
                            <span className={classNames('title', { active: j == pane.pointer })}><Title pane={item} /></span>
                          </span>
                        );
                      })}
                    </span>

                    <NotionButton 
                      className={classNames("viewType", pane.viewType + '-view')}
                      img="icons8-view-column"
                      onClick={() => {
                        if (pane.viewType == 'stack') {
                          pane.viewType = 'browser';
                        }
                        else {
                          pane.viewType = 'stack';
                        }
                        appState.lastViewState = pane.viewType;
                      }}
                    />
                  </div>
                </div>
                <ClientView i={i} pane={pane} />
              </div>
            );
          })}
        </div>
        <SystemContext.Provider value={{
          navigate: (config, paneIndex, pointer) => {
            inspectFromNav(config, paneIndex || 0);
            if (!_.isNil(paneIndex) && !_.isNil(pointer)) {
              appState.panes[paneIndex].stack = X([...x(appState.panes[paneIndex].stack.slice(0, pointer + 1)), XObject.obj(config)]);
            }
            else {
              appState.panes[0] = initPaneObject(config);
            }
          }
        }}>
          <RightSidebar />
          {appState._appInspect && (
            <div className="bottomPane">
              <UIInspectPane
                args={appState._appInspect}
                onClickClose={() => {
                  appState._appInspect = null;
                }}
              />
            </div>
          )}

          <Create />
        </SystemContext.Provider>

        <OccurrenceBar />

        
      </Container>
    );
  }
}

export const topBarHeight = 35;
export const defaultSidebarWidth = 300;
