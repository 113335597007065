import React, { Component, useContext } from 'react';
import ReactDOM from 'react-dom';
import jQuery from 'jquery';
import { component, styled } from "../component2";
import * as go from 'gojs';
import { ReactDiagram } from 'gojs-react';
import { x } from '../XObject';
import { showContextMenu } from '../etc/showContextMenu';
import copyToClipboard from 'copy-to-clipboard';
import { useDrop } from 'react-dnd';
import { openWindow } from '../osHelpers';
import { WindowType } from '../etc/WindowType';
import { SystemContext } from '../etc/SystemContext';
// import { useDrop } from 'react-dnd';
// @component
// export class _StructureWindow extends Comp<{ win }> {
//   render() {
//     return '';
//   }
// }

const highlightColor = '#95badc';

const onHighlight = (diagram, part) => {  // may be null
  // console.log(part);
  const myDiagram = diagram;
  const oldskips = myDiagram.skipsUndoManager;
  myDiagram.skipsUndoManager = true;
  myDiagram.startTransaction("highlight");
  if (part) {
    myDiagram.highlight(part);
  } else {
    myDiagram.clearHighlighteds();
  }
  myDiagram.commitTransaction("highlight");
  myDiagram.skipsUndoManager = oldskips;
}

@component
export class GraphView extends Component<{
  root: string,
  label?
  data: {
    id: string
    name?: string
    meta: {
      // path: string[]
      parent
      contextMenu?
    }
  }[],
  _state,
  type?: 'groups' | 'graph'
  onSelect?
  _onDropExternal?
  selected?

  _onMove?(id, from, to)
}> {
  static debug = true;
  static styles = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;

    .diagram-component {
      height: 100%;
    }

    .top {
      top: 0;
      left: 0;
      right: 0;
      position: absolute;
      bottom: 25%;
    }

    .bottom- {
      top: 75%;
      left: 0;
      right: 0;
      position: absolute;
      bottom: 0;

      border-top: 1px solid #f0f0f0;
      overflow: auto;

    }
  `;

  diagram
  componentDidMount() {
    const el = ReactDOM.findDOMNode(this);
    const doThing = (e) => {
      // console.log(e);
      const myDiagram = this.diagram;
      // console.log(this.diagram);
      const event = e.originalEvent;
      const can = event.target;

      const pixelratio = myDiagram.computePixelRatio();

      // if the target is not the canvas, we may have trouble, so just quit:
      if (!(can instanceof HTMLCanvasElement)) return;

      const bbox = can.getBoundingClientRect();
      let bbw = bbox.width;
      if (bbw === 0) bbw = 0.001;
      let bbh = bbox.height;
      if (bbh === 0) bbh = 0.001;
      const mx = event.clientX - bbox.left * ((can.width / pixelratio) / bbw);
      const my = event.clientY - bbox.top * ((can.height / pixelratio) / bbh);
      const point = myDiagram.transformViewToDoc(new go.Point(mx, my));
      const part = myDiagram.findPartAt(point, true);

      onHighlight(this.diagram, part);
    }

    jQuery(el).find('.diagram-component canvas').on('dragover', (e) => {
      e.preventDefault();
      e.stopPropagation();
      doThing(e);
    });

    jQuery(el).find('.diagram-component canvas').on('dragleave', (e) => {
      onHighlight(this.diagram, null);
    });

    jQuery(el).find('.diagram-component canvas').on('drop', (e) => {
      console.log(e);
      onHighlight(this.diagram, null);
    });

    setTimeout(() => {
      // console.log(this.props.selected, , this.diagram.nodes);
      const node = this.diagram.findNodeForKey(this.props.selected);
      if (node) {
        this.diagram.select(node);
        this.diagram.commandHandler.scrollToPart(node);
      }

    }, 1000)
  }

  diagramRef = React.createRef<ReactDiagram>();
  render() {
    const context = useContext(SystemContext);

    const onDrop = e => {
      const droppedEntity = e.dataTransfer.getData('text/plain');
      console.log(droppedEntity)
      if (!droppedEntity) return;

      // console.log(e, e.dataTransfer.items);
      // return;
      onHighlight(this.diagram, null);

      const myDiagram = this.diagram;
      // console.log(this.diagram);
      // const event = e.originalEvent;
      const event = e
      const can = (ReactDOM.findDOMNode(this) as any).querySelector('.diagram-component canvas');

      const pixelratio = myDiagram.computePixelRatio();

      // if the target is not the canvas, we may have trouble, so just quit:
      if (!(can instanceof HTMLCanvasElement)) return;

      const bbox = can.getBoundingClientRect();
      let bbw = bbox.width;
      if (bbw === 0) bbw = 0.001;
      let bbh = bbox.height;
      if (bbh === 0) bbh = 0.001;
      const mx = event.clientX - bbox.left * ((can.width / pixelratio) / bbw);
      const my = event.clientY - bbox.top * ((can.height / pixelratio) / bbh);
      const point = myDiagram.transformViewToDoc(new go.Point(mx, my));
      const part = myDiagram.findPartAt(point, true);

        // console.log(part?.data, );


      if (part?.data) {
        this.props._onDropExternal(part?.data.entity, droppedEntity);
        // const r = await entityMatch(this.context, part?.data?.entity, this.props.matches.map(m => ({
        //   match: m.match,
        //   value: m,
        //   _id: m._id,
        // })));
        // if (r?.[0]?.onDrop) {
        //   const rr = await this.context.api.procedure[r[0].onDrop](part?.data?.entity, (item as any).entity);
        // }
      }
    }

    const [, drop] = useDrop({
      accept: 'object',
      drop: async (item, monitor) => {
        onHighlight(this.diagram, null);

        const myDiagram = this.diagram;
        // console.log(this.diagram);
        // const event = e.originalEvent;
        const event = (window as any)._g_dropEvent;
        const can = (ReactDOM.findDOMNode(this) as any).querySelector('.diagram-component canvas');
  
        const pixelratio = myDiagram.computePixelRatio();
  
        // if the target is not the canvas, we may have trouble, so just quit:
        if (!(can instanceof HTMLCanvasElement)) return;
  
        const bbox = can.getBoundingClientRect();
        let bbw = bbox.width;
        if (bbw === 0) bbw = 0.001;
        let bbh = bbox.height;
        if (bbh === 0) bbh = 0.001;
        const mx = event.clientX - bbox.left * ((can.width / pixelratio) / bbw);
        const my = event.clientY - bbox.top * ((can.height / pixelratio) / bbh);
        const point = myDiagram.transformViewToDoc(new go.Point(mx, my));
        const part = myDiagram.findPartAt(point, true);
  
        if (part?.data) {
          // console.log(part?.data, item);
          this.props._onDropExternal(part?.data.entity, (item as any).entity);
          // const r = await entityMatch(this.context, part?.data?.entity, this.props.matches.map(m => ({
          //   match: m.match,
          //   value: m,
          //   _id: m._id,
          // })));
          // if (r?.[0]?.onDrop) {
          //   const rr = await this.context.api.procedure[r[0].onDrop](part?.data?.entity, (item as any).entity);
          // }
        }
      }
    });

    const initGraphDiagram = () => {

      const $ = go.GraphObject.make;

      const myDiagram = $(go.Diagram,
        {
          initialAutoScale: go.Diagram.UniformToFill,
          // define the layout for the diagram
          layout: $(go.TreeLayout, { nodeSpacing: 5, layerSpacing: 30 }),
          model: $(go.GraphLinksModel,
            {
              linkKeyProperty: 'key',

              // positive keys for nodes
              makeUniqueKeyFunction: (m: go.Model, data: any) => {
                let k = data.key || 1;
                while (m.findNodeDataForKey(k))
                  k++;
                data.key = k;
                return k;
              },
              // negative keys for links
              makeUniqueLinkKeyFunction: (m: go.GraphLinksModel, data: any) => {
                let k = data.key || -1;
                while (m.findLinkDataForKey(k))
                  k--;
                data.key = k;
                return k;
              }
            })
        });

      myDiagram.nodeTemplate =
        $(go.Node, "Horizontal",
          {
            selectionChanged: (a) => {
              // console.log(a.data.key, a.isSelected);

              if (this.props._state) {
                if (a.isSelected) {
                  this.props._state.selected = a.data.key;
                  this.props.onSelect?.(a.data.key);
                }
                else {
                  this.props._state.selected = null;
                }
              }

            },

            mouseDragEnter: (e, node: any, prev) => {
              const shape = node.findObject("BODY");
              if (shape) {
                shape._prevFill = shape.fill; // remember the original brush
                shape.fill = "darkred";

              }
            },
            mouseDragLeave: (e, node: any, next) => {
              const shape = node.findObject("BODY");
              if (shape && shape._prevFill) {
                shape.fill = shape._prevFill; // restore the original brush
              }
            },

            doubleClick: (e, node: any) => {
              // console.log(node.data.entity);
              // this.navigate({ type: 'entity', props: { entity: node.data.entity } }, undefined, { present: 'sidePeek' });
            },

            mouseDrop: (e, dropTargetNode: any) => {
              const diagram = dropTargetNode.diagram;
              const selnode = diagram.selection; // assume just one Node in selection


              const selectedNodes = [];
              for (const node of diagram.selection.toArray()) {
                // selectedNodes.push(node);
                // moveEntity(this.context.api, node.entity, )
                const currentParent = node.findTreeParentLink()?.fromNode?.data?.entity;

                const nextParent = dropTargetNode.data.entity;

                const id = node.data.entity;

                console.log(id, currentParent, nextParent)

                this.props._onMove?.(id, currentParent, nextParent);
                // moveEntity(this.context.api, id, currentParent, nextParent);
              }

              console.log('mouseDrop', dropTargetNode.data, selectedNodes);

            },
            contextMenu: $(go.HTMLInfo, {
              show: async (obj, diag, tool) => {
                const pos = { x: diag.lastInput.viewPoint.x + jQuery(diag.div).offset().left, y: diag.lastInput.viewPoint.y + jQuery(diag.div).offset().top };

                showContextMenu({ clientX: pos.x, clientY: pos.y }, [
                  {
                    text: 'Copy ID',
                    onClick: () => {
                      copyToClipboard(obj.data.entity);
                    }
                  },
                  {
                    text: 'View',
                    onClick: () => {
                      context?.navigate?.({
                        type: 'entity',
                        id: obj.data.entity
                      })

                      // openWindow({
                      //   type: WindowType.Notion,
                      //   entity: obj.data.entity,
                      // })
                    },
                  },
                  {
                    text: 'Debug',
                    onClick: () => {
                      console.log(children[obj.data.entity], this.props.data.find(e => e.id == obj.data.entity));
                    }
                  },
                  {
                    text: 'Show',
                    onClick: () => {
                      // this.navigate({ type: 'entity', props: { entity: obj.data.entity } }, undefined, { present: 'sidePeek' });
                    }
                  },
                  ...(this.props.data.find(e => e.id == obj.data.entity)?.meta?.contextMenu || []),
                ]);
              },
              hide: () => {
              }
            }),
          },
          $(go.Panel, "Auto",
            $(go.Shape, { fill: "#1F4963", stroke: null, name: 'BODY' }),
            $(go.TextBlock,
              {
                font: "bold 13px Helvetica, bold Arial, sans-serif",
                stroke: "white", margin: 3
              },
              new go.Binding("text", "text"))
          ),
          $("TreeExpanderButton")
        );

      
      myDiagram.linkTemplate =
        $(go.Link,
          { selectable: false },
          $(go.Shape)); // the link shape


        this.diagram = myDiagram;


      return myDiagram;

    };

    const initGroupDiagram = () => {

      const $ = go.GraphObject.make;
      const myVisualTree =
        $(go.Diagram,
          {
            // isReadOnly: true,  // do not allow users to modify or select in this view
            // allowSelect: false,
            layout: $(go.TreeLayout, { nodeSpacing: 5 }),  // automatically laid out as a tree

            model: $(go.GraphLinksModel,
              {
                nodeKeyProperty: "key",
                nodeGroupKeyProperty: "parentKey",
                // always return true so that all node data is represented by groups
                nodeIsGroupProperty: data => true,
      
              }
            )
          }
        );

      myVisualTree.groupTemplate =
        $(go.Group, "Auto",
          {
            computesBoundsAfterDrag: true,
            layout: $(go.GridLayout,
              {
                wrappingColumn: 2,
                alignment: go.GridLayout.Position,
                cellSize: new go.Size(1, 1)
              }),

              selectionChanged: (a) => {
                // console.log(a.data.key, a.isSelected);
  
                if (this.props._state) {
                  if (a.isSelected) {
                    this.props._state.selected = a.data.key;
                  }
                  else {
                    this.props._state.selected = null;
                  }
                }
  
              },

            mouseDragEnter: (e, node: any, prev) => {
              const shape = node.findObject("BODY");
              if (shape) {
                shape._prevFill = shape.fill; // remember the original brush
                shape.fill = highlightColor;

              }

            },
            mouseDragLeave: (e, node: any, next) => {
              const shape = node.findObject("BODY");
              if (shape && shape._prevFill) {
                shape.fill = shape._prevFill; // restore the original brush
              }

            },

            contextMenu: $(go.HTMLInfo, {
              show: async (obj, diag, tool) => {
                const pos = { x: diag.lastInput.viewPoint.x + jQuery(diag.div).offset().left, y: diag.lastInput.viewPoint.y + jQuery(diag.div).offset().top };

                showContextMenu({ clientX: pos.x, clientY: pos.y }, [
                  {
                    text: 'Debug',
                    onClick: () => {
                      console.log(children[obj.data.entity], this.props.data.find(e => e.id == obj.data.entity));
                    }
                  },
                  {
                    text: 'Show',
                    onClick: () => {
                      // this.navigate({ type: 'entity', props: { entity: obj.data.entity } }, undefined, { present: 'sidePeek' });
                    }
                  },

                  ...(obj.data.meta.contextMenu || []),

                ]);
              },
              hide: () => {
              }
            }),

            mouseDrop: (e, dropTargetNode: any) => {
              const diagram = dropTargetNode.diagram;
              const selnode = diagram.selection; // assume just one Node in selection


              const selectedNodes = [];
              for (const node of diagram.selection.toArray()) {
                // selectedNodes.push(node);
                // moveEntity(this.context.api, node.entity, )
                // console.log(node.data);
                const currentParent = node.data.parentKey;

                const nextParent = dropTargetNode.data.entity;

                const id = node.data.entity;

                // moveEntity(this.context.api, id, currentParent, nextParent);
              }

              console.log('mouseDrop', dropTargetNode.data, selectedNodes);

            },

            isShadowed: true,
            shadowBlur: 4,
            shadowColor: 'rgba(209, 209, 209, .7)',
            shadowOffset: new go.Point(0, 0),
          },
          
          $(go.Shape,'RoundedRectangle',
            {
              // fill: 'rgba(99,99,99,0.05)',
              strokeWidth: 1,
              // stroke: 'rgba(0, 0, 0, 0.17)',
              name: 'BODY',
              parameter1: 2,
            },

            new go.Binding('fill', 'background'),
            new go.Binding('stroke', 'borderColor'),

            new go.Binding("fill", "", (obj, shape) => {
              const h = obj.isHighlighted;
              if (h) return highlightColor;
              return obj.data.background;
            }).ofObject(),
    
          ),
          $(go.Panel, "Spot",
            $(go.Placeholder,
              { padding: new go.Margin(15, 5, 5, 5) }),

            $(go.Panel, "Horizontal",
              { alignment: go.Spot.Top, alignmentFocus: go.Spot.Top, margin: 3 },
              $('SubGraphExpanderButton',
                {
                  width: 10,
                  height: 10,
                  margin: new go.Margin(0, 4, 0, 0),
                },
                new go.Binding('visible', '', (v, ...args) => !!v.data.childrenCount).ofObject()),

              $(go.TextBlock,
                {
                  font: "bold 11px Helvetica Neue, Helvetica",
                  // stroke: "#212121",
                },
                new go.Binding('stroke', 'titleColor'),
                
                new go.Binding("text", "text"))),

            // $(go.TextBlock,
            //   {
            //     font: "bold 13px Helvetica, bold Arial, sans-serif",
            //     stroke: "black",
            //     margin: 3,
            //     alignment: go.Spot.Top, alignmentFocus: go.Spot.Top
            //   },
            //   // bind the text to the Diagram/Layer/Part/GraphObject converted to a string
            //   new go.Binding("text", "text"))
  
                
          )
        );

        this.diagram = myVisualTree;
    
        return myVisualTree;
    }

    const links = [];
    const nodes = [];

    const children = {};

    if (this.props.root) {
      children[this.props.root] = {};

    }

    for (const entity of this.props.data) {
      if (!children[entity.id]) {
        children[entity.id] = {};
      }

      if (entity.meta.parent) {
        if (!children[entity.meta.parent]) {
          children[entity.meta.parent] = {};
        }
        children[entity.meta.parent][entity.id] = true;
      }

      /*if (entity.meta.path.length == 0) {
        children[this.props.root][entity.id] = true;
      }

      for (let i = 0; i < entity.meta.path.length; ++i) {
        const id = entity.meta.path[i];
        if (!children[id]) {
          children[id] = {};
        }
        if (i == 0) {
          children[this.props.root][id] = true;
        }
        if (i == entity.meta.path.length - 1) {
          children[id][entity.id] = true;
        }
        else {
          children[id][entity.meta.path[i + 1]] = true;
        }
      }*/
    }
    

    const entityLabel = (id, match) => {
      if (this.props.label) return this.props.label(id);
      return this.props.data.find(e => e.id == id)?.name || id;
    }

    const color = (id, match) => {
      return null;
    }

    const borderColor = (id, match) => {
      return null;

    }

    const textColor = (id, match) => {
      return null;

    }

    for (const id in children) {
      const c = Object.keys(children[id]);

      let m;
      

      nodes.push({ key: id, entity: id, text: entityLabel(id, m), childrenCount: c.length,
        background: color(id, m) || 'rgba(99,99,99,0.05)',
        borderColor: borderColor(id, m) || 'rgba(0, 0, 0, 0.17)',
        titleColor: textColor(id, m) || '#212121',
      });

      for (const childId of c) {
        links.push({
          key: `${id}-${childId}`,
          from: id,
          to: childId,
        });
      }
    }

    for (const link of links) {
      const node = nodes.find(n => n.key == link.to);
      if (node) {
        node.parentKey = link.from;
      }
    }

    return (
      <div className="top"
        ref={drop}
        onDragOver={e => {
          e.preventDefault();
          console.log(e);
        }}
        onDrop={e => {
          e.preventDefault();
          console.log(e);
          onDrop(e);
        }}
      >
        <ReactDiagram
          ref={this.diagramRef}
          key={this.props.type}
          initDiagram={this.props.type == 'groups' ? initGroupDiagram : initGraphDiagram}
          divClassName='diagram-component'
          nodeDataArray={nodes}
          linkDataArray={(this.props.type == 'graph' || !this.props.type) ? links : undefined}
        />
      </div>
    );
  }
}
