import { execute, getValuePoint, ReferenceType } from '../main';
import { ValueType } from '../ValueType';
import { resolveParam } from './ParameterValuePoint';

export function pullValues(formula: any[], rt, values) {
  // for (const el of formula || []) {
  if (formula) for (let i = 0; i < formula.length; ++ i) {
    const el = formula[i];
    if (el[1] == 'glue') {
      if (el[0].type == ReferenceType.Value) {
        const key = `${el[0].type}:${el[0].id}`;
        if (key in values) continue;
        values[key] = execute(el[0].id, rt);
      }
      else if (el[0].type == ReferenceType.Parameter) {
        const key = `${el[0].type}:${el[0].id}`;
        if (key in values) continue;
        // values[key] = execute(el[0].id, rt);

        const resolved = resolveParam(el[0].id, null, rt);

        if (resolved) {
          values[key] = resolved;
        }
        else {
          values[key] = {
            type: [ValueType.Param],
            content: { id: el[0].id },
            rt,
            isState: false,
          };
        }

      }
      else if (el[0].type == ReferenceType.StructureParameter) {
        const key = `${el[0].type}:${el[0].id}`;
        if (key in values) continue;
        values[key] = {
          type: [ValueType.Param],
          content: { id: el[0].id },
          rt,
          isState: false,
        };
      }
    }
  }
}
