import jQuery from 'jquery';
import _, { over } from 'lodash';

export class OverlayManager {
  constructor(public duration?, public zIndex?) {
  }
  overlay;

  timerId
  arg
  margin

  clearTimerId

  boundsMap = new Map();

  frame() {
    const frameEl = jQuery('#protobuilder-output-area');
    if (!frameEl.length) return;
    const offset = frameEl.offset();
    return {
      top: offset.top,
      left: offset.left,
      width: frameEl.outerWidth(),
      height: frameEl.outerHeight(),
    }
  }

  setOverlays(arg: {
    selector;
    styles;
    label?
  }[], margin = 0) {
    this.arg = arg;
    this.margin = margin;
    clearTimeout(this.timerId);

    if (!this.overlay) {
      this.overlay = jQuery('<div />').css({
        position: 'fixed',
        ...this.frame(),
        zIndex: 214748300 + (this.zIndex || 0),
        pointerEvents: 'none',
        overflow: 'hidden',
      }).appendTo('body');
    }

    if (arg.length == 0) {
      this.overlay.html('');
      this.boundsMap = new Map();
      return;
    }

    this.boundsMap = new Map();


    const update = () => {
      const frame = this.frame();
      if (!frame) {
        // console.log('no frame');
        return;
      }
      this.overlay.css(frame);
      if (!arg.length) {
        this.overlay.html('');
      }
      else {
        const newEls = [];
        let reset;
        const allEls = [];

        for (const overlayElInfo of arg) {
          const els = jQuery(overlayElInfo.selector);
          if (overlayElInfo.styles?.outline) {
            // overlayElInfo.styles.border = overlayElInfo.styles?.outline;
            // delete overlayElInfo.styles.outline; 
            overlayElInfo.styles.borderRadius = '2px';
            overlayElInfo.styles.boxSizing = 'border-box';
          }



          for (const el of els) {
            allEls.push(el);
            const r = el.getBoundingClientRect();
            const bounds = {top:r.top - frame.top, left: r.left - frame.left, width: r.width, height: r.height};
            const lastBounds = this.boundsMap.get(el);
            if (_.isEqual(bounds, lastBounds)) {
              continue;
            }
  
            this.boundsMap.set(el, bounds);

            const overlayEl = jQuery('<div />').css({
              position: 'absolute',
              top: bounds.top - margin,
              left: bounds.left - margin,
              width: bounds.width + margin * 2,
              height: bounds.height + margin * 2,
              // borderRadius: el.style.borderRadius,
    
              ...overlayElInfo.styles,
            }).attr('selector', overlayElInfo.selector);

            if (overlayElInfo.label) {
              overlayEl.append(jQuery(`<span>${overlayElInfo.label.content.replace(/</g, '&lt;').replace(/>/g, '&gt;')}</span>`).css({
                position: 'absolute',
                bottom: '100%',
                left: 0,
                backgroundColor: overlayElInfo.label.color,
                borderTopLeftRadius: '3px',
                borderTopRightRadius: '3px',
                whiteSpace: 'nowrap',
                color: 'white',
                padding: '2px 2px',
                fontSize: '8px',
                fontFamily: '"Inter UI","SF Pro Display",-apple-system,"system-ui","Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif',
              }));
            }
  
            newEls.push(overlayEl);
          }

        }

        for (const el of this.boundsMap.keys()) {
          if (!allEls.includes(el)) {
            reset = true;
            this.boundsMap.delete(el);
          }
        }


  
        if (newEls.length || reset) {
          this.overlay.html('');
          // this.boundsMap = new Map();
          for (const el of newEls) this.overlay.append(el);
  
        }
      }



    }

    update();

    if (arg.length) {
      this.timerId = setInterval(() => {
        update();
      }, 50);  
    }

    if (this.duration) {
      clearTimeout(this.clearTimerId);
      this.clearTimerId = setTimeout(() => {
        jQuery(this.overlay).fadeOut(() => {
          this.cleanup();
        })
      }, this.duration);
    }
  }

  cleanup() {
    
    clearInterval(this.timerId);
    this.overlay?.remove?.();
    this.overlay = null;
  }
}
