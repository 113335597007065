import React, { Component } from 'react';
import { component } from '../component2';
import { chatGptManager } from '../etc/chatGptManager';
import { attributesForType } from '../components/attributesForType';
import { appState } from '../etc/appState';
import { configApiServer, db } from '../db';
import { typesInScope } from '../components/objectFuncs';
import { ObjectType } from '../types/ObjectRef';
import copyToClipboard from 'copy-to-clipboard';
import { attributeTypeDefs } from '../components/attributeTypes';
import { AttributeType } from '../components/AttributeType';
import juration from '../juration';
import { entityOptions } from '../components/cells';
import { FormulaObjectWrapper } from '../FormulaObjectWrapper';
import { FormulaObjectProxy } from '../FormulaObjectProxy';
import stringSimilarity from 'string-similarity';
import { entityDisplayName } from '../components/entityDisplayName';
import _ from 'lodash';
import axios from 'axios';
import { cmp } from 'semver';

function generateSchema(typeId) {
  const type = db.entityTypes.findById(typeId);
  const attributes = attributesForType(typeId);




return `
${type.name} {
${attributes.map(id => {
  const attribute = db.attributeTypes.findById(id);
  if (attribute) {
    const type = attributeTypeDefs.find(t => t.value == attribute.type);
    return `${attribute.name}: ${type.display}`;
  }
}).filter(Boolean).join('\n')}
}
`
}

function generatePrompt(types, request) {

  const schemas = [];

  for (const type of types) {

    schemas.push(generateSchema(type));
  }

return `
Entity Schemas:
${schemas.join('\n')}

Attribute Type Rules:
  Duration: format as "XhXmXs"
  Entity: string pulled from prompt

Actions: CREATE

Entity JSON Schema:
{
  entityType: string // type of entity
  entityName: string // name or content of the entity
  attributes: {
    "Attribute Name": "Value" // other attributes intelligenty extract from prompt
  }
}

Task:
Convert the human language request into a JSON command.
If the command is a CREATE action, do you best to pull the attributes from the prompt.

Example requests:
CREATE Audio Note
  {attribute.Speaker} said {entityName} at {attribute.Time}

CREATE Podcast Episode
  New {attribute.Podcast} episode
  New {attribute.Podcast} episode called {entityName}
  New {attribute.Podcast} episode called {entityName} with {attribute.Guests}

CREATE Podcast
  New podcast called {entityName}
  New podcast called {entityName} hosted by {attribute.Hosts}

CREATE Video:
  new video at {attributes.URL} called {entityName}

CREATE Thought:
  new thought {entityName}

These are just example requests -- there may be subtle varitions. Do your best to find the correct match.


LASTLY: requests may be coming in through voice to text, so they may be awkwardly structured


ONLY respond in JSON:
{
  action
  data
}
`
}

export function executeResponse(response, parent) {
  const types = typesInScope({ type: ObjectType.mode, id: appState.currentMode });
  const type = types.find(t => db.entityTypes.findById(t)?.name == response.data.entityType);
  const typeAttributes = attributesForType(type);

  const attributes = {};

  const edges = [];

  if (parent) {
    edges.push({
      from: parent,
      directed: true,
    })
  }

  for (const attrName in response.data.attributes) {
    const attrId = typeAttributes.find(id => db.attributeTypes.findById(id)?.name == attrName);
    const attribute = db.attributeTypes.findById(attrId);
    let value = response.data.attributes[attrName];

    const getOptions = () => {
      return entityOptions({
        query: attribute.query,
        baseEntity: null,
        options: {
          entity: new FormulaObjectProxy({
            get: prop => {
              if (prop?.toLowerCase?.() == 'parent') {
                return new FormulaObjectWrapper({
                  type: ObjectType.entity,
                  id: parent,
                })
              }
            }
          }),
          type: attribute.optionsType,
          valuePoint: attribute.valuePoint,
        }
      });
    }

    const getBestMatch = (entities, str) => {
      if (!entities.length) return null;
      const comps = [];
      for (const option of entities) {
        const name = entityDisplayName(option._id);
        comps.push([option, stringSimilarity.compareTwoStrings(name, str), name, str]);
      }

      comps.sort((a, b) => b[1] - a[1])

      return comps[0][0]._id;

    }

    if (attribute.type == AttributeType.duration) {
      value = juration.parse(value);
    }
    else if (attribute.type == AttributeType.entity) {
      const entities = getOptions();
      value = getBestMatch(entities, value);
      // value = entityDisplayName(value);
    }
    else if (attribute.type == AttributeType.entities) {
      if (!_.isArray(value)) {
        value = [value];
      }
      const entities = getOptions();
      value = value.map(name => getBestMatch(entities, name)).filter(Boolean);
    }
    
    attributes[attrId] = value;
  }

  const entity = {
    type,
    name: response.data.entityName,
    attributes,
    $edges: edges,
  }

  return entity;
}

export async function processPrompt(request) {
  const types = typesInScope({ type: ObjectType.mode, id: appState.currentMode });
  const prompt = generatePrompt(types, '');

  const r = (await axios.post(`${configApiServer()}completion`, JSON.stringify({
    messages: [
      { role: 'system', content: prompt },
      { role: 'user', content: request },
    ],
    model: 'gpt-4',
  }), { headers: { 'Content-Type': 'application/json' }})).data;
  const response: string = r[0].message.content;

  return JSON.parse(response);

}

@component
export class ChatGPTTestWindow extends Component<{ window; }> {
  render() {
    return (
      <>
        <button
          onClick={async () => {
            // const types = typesInScope({ type: ObjectType.mode, id: appState.currentMode });

            // copyToClipboard(generatePrompt(types, 'New Modern Wisdom podcast episode called this is an episode'));

            // copyToClipboard(generatePrompt(types, 'Jordan Peterson said do things that are hard at twelve minute four seconds'));

            // const response = {
            //   "action": "CREATE",
            //   "data": {
            //     "entityType": "Audio Note",
            //     "entityName": "something about the suffering of Palestinians",
            //     "attributes": {
            //       "Time": "1h4m2s",
            //       "Speaker": "Nigel"
            //     }
            //   }
            // }

            // const response = {
            //   "action": "CREATE",
            //   "data": {
            //     "entityType": "Podcast Episode",
            //     "entityName": "Test",
            //     "attributes": {
            //       "Podcast": "Modern Wisdom",
            //       "Guests": ["Jordan Peterson"],
            //     }
            //   }
            // }

            
            // executeResponse(response, '646c2503bb399b3687bcc57a');
            const r = await processPrompt('Create new, making sense podcast Called Gaza and world order');
            console.log(r, executeResponse(r,'654899dee2751ad4a82ec338'))

          }}
        >
          Test
        </button>
      </>
    );
  }
}
