import styled from 'styled-components';
import { db } from '../db';
import { objectName } from "./objectFuncs";
import { XInit, XObject } from '../XObject';
import { Tag } from "./Tag";
import { SelectEditor } from "./SelectEditor";
import React, { Component } from 'react';
import { component } from '../component';
import { Svg } from './Svg';
import classNames from 'classnames';
import { attributeTypeDefs } from './attributeTypes';


@component
export class AttributeAdder extends Component<{ attrs: string[]; scope; onAdd; close; }> {
  state = XInit(class {
    addToSchema;
    focused = 0;
  });

  static styles = styled.div`
    ${SelectEditor} {
      .option {
        svg {
          width: 14px;
          height: 14px;
          margin-right: 4px;
        }
      }
    }

    &:not(.addToSchema) {
      ${SelectEditor} {
        .option {
          color: blue;
        }
      }
    }
  `;

  render(Container?) {
    const { attrs } = this.props;
    const addToSchema = this.state.addToSchema ?? this.state.focused == 0;

    return (
      <Container
        className={classNames({
          addToSchema: addToSchema,
        })}
      >
        <SelectEditor
          onChangeFocus={i => this.state.focused = i}
          createAtTop
          close={this.props.close}
          frame={null}
          createOption={name => {
            const newAttr = XObject.obj({
              name,
              scope: this.props.scope,
            });
            db.attributeTypes.push(newAttr);
            return newAttr._id;
          }}
          options={attrs.map(id => db.attributeTypes.findById(id)).filter(Boolean).map(t => ({
            _id: t._id,
            title: t.scope ? `${t.name} (${objectName(t.scope)})` : t.name,
          }))}
          setValue={async (value, i) => {
            const addToSchema = this.state.addToSchema ?? i == 0;
            console.log(value, i, this.state.addToSchema, addToSchema);

            this.props.onAdd(value, addToSchema);
          }}
          value={null}

          renderOption={option => {
            const attr = db.attributeTypes.findById(option._id);
            const tt = attributeTypeDefs.find(t => t.value == attr.type);

            return (
              <>
                {tt?.icon ? <Svg name={tt.icon} /> : <Svg name={'icons8-empty-set'} />} {option.title}
              </>
            );
          }}
          renderCreate={filter => {
            return <>Create&nbsp;<Tag text={filter} />

              {/* <select
                              value={this.state.newPropertyScope || ''}
                              onChange={e => {
                                this.state.newPropertyScope = e.target.value || null;
                              }}
                            >
                              <option />
                              {entity.type && <option
                                value={entity.type}
                              >{objectName({
                                id: entity.type,
                                type: ObjectType.type,
                              })} (type)</option>}
                              {tree.map(t => {
                                return <option key={t.id} value={t.id}>{objectName(t)}</option>
                              })}
                            </select> */}
            </>;
          }}
          right={i => (
            <>
              <input type="checkbox" checked={this.state.addToSchema ?? i == 0} onChange={() => {
                this.state.addToSchema = !(this.state.addToSchema ?? i == 0);
              }} />
            </>
          )} />
      </Container>
    );
  }
}
