import { ObjectType } from './types/ObjectRef';
import { XObject, x } from './XObject';
import { ObjectEditor2 } from './ObjectEditor2';
import { ObjectEditor3 } from './ObjectEditor3';
import { PaneContext, RootValue } from './glue/main';
import { registerSecondColumnThing } from './Query';
import { ColumnType } from './ColumnType';

registerSecondColumnThing((entry, onClick) => {
  if (entry[1] == 'query') {
    return (
      <>

        <b><ObjectEditor3 obj={{ type: ObjectType.query, id: entry[0] }}
        
          onView={() => {
            onClick({
              type: ColumnType.viewQuery,
              id: entry[0],
            });

          }}
        /></b>
        <ObjectEditor2 obj={{ type: ObjectType.query, id: entry[0] }} />

      </>
    );
  }
});
registerSecondColumnThing((entry, onClick, next) => {
  if (entry[1] == 'valuePoint') {
    return (
      <>
        <b><ObjectEditor3 obj={{ type: ObjectType.valuePoint, id: entry[0] }} /></b>
        <ObjectEditor2 obj={{ type: ObjectType.valuePoint, id: entry[0] }} />
        <PaneContext.Provider value={{
          active: next?.stackSystemArgs?.stack?.[0]?.id,
          selectValuePoint: value => {
            console.log('poop', x(next));
            onClick({
              type: ColumnType.valuePoint,
              stack: [
                XObject.obj({
                  type: 'valuePoint',
                  id: value,
                })
              ],
            });
          }
        }}>

          <RootValue id={entry[0]} />
        </PaneContext.Provider>
      </>
    );
  }
});
registerSecondColumnThing((entry, onClick) => {
  if (entry[1] == 'attribute') {
    return (
      <>
        <b><ObjectEditor3 obj={{ type: ObjectType.attribute, id: entry[0] }} /></b>
        <ObjectEditor2 obj={{ type: ObjectType.attribute, id: entry[0] }} />
      </>
    );
  }
});
