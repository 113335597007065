import { Component, Context } from 'react';
import { db } from '../db';
import { component } from '../component2';
import cx from 'classnames';
import { XInit, XObject, x } from '../XObject';
import { DraftSelect } from '../etc/draftHelpers';
import { entityTypeName } from '../etc/entityTypeName';
import { styled } from '../component';
import { showContextMenu } from '../helpers';
import { ObjectRef, ObjectType } from '../types/ObjectRef';
import { openWindow } from '../osHelpers';
import { WindowType } from '../etc/WindowType';
import { objectName, getScopeTree, typesInScope } from './objectFuncs';
import { SystemContext, SystemContextProps } from '../etc/SystemContext';
import { PaneType } from '../types/PaneType';
import { explicitInspectObj } from '../inspectObj';
import { appState } from '../etc/appState';
import { WithContextAction } from './WithContextAction';

@component
export class TypeEditor extends Component<{ value; onChange; inline?; showClear?, right?, scopeObj?: ObjectRef }> {
  static styles = styled.span`
    &.noType {
      min-width: 29px !important;
    }
  `;
  
  state = XInit(class {
    scope
  });

  static contextType = SystemContext;
  context: SystemContextProps;

  render(Container?) {
    const types = typesInScope(this.props.scopeObj).map(id => db.entityTypes.findById(id)).map(t => ({
      display: t.scope ? `${entityTypeName(t._id)} (${objectName(t.scope)})` : t.name,
      value: t._id,
    }));

    const tree = getScopeTree(this.props.scopeObj);

    // sort by name
    types.sort((a, b) => {
      if (a.display < b.display) return -1;
      if (a.display > b.display) return 1;
      return 0;
    });
    return (
      <WithContextAction
        mobileInvoke="longPress"
        menu={[
          {
            text: 'Clear type',
            onClick: () => {
              this.props.onChange(null);
            }
          }
        ]}
      >
      <Container
        className={cx({
          noType: !this.props.value,
        })}
        // onContextMenu={e => {
        //   e.preventDefault();
        //   showContextMenu(e, );
        // }}
      >
        {this.props.value ? <><span
          style={{cursor: 'pointer'}}
          onClick={() => {
            explicitInspectObj({
              type: ObjectType.type,
              id: this.props.value,
            })
          }}
        >{entityTypeName(this.props.value)}</span> {this.props.showClear && <button
          onClick={() => {
            this.props.onChange(null);
          }}
        >X</button>}</> : <DraftSelect
          placeholder="Type"
          right={this.props.right}
          inline={this.props.inline}
          id="type"
          options={types}
          stateOnFocus={false}
          keepMenuOpen
          onCreate={value => {
            // let selectedScope;
            // if (this.state.scope) {
            //   selectedScope = tree.find(ref => ref.id == this.state.scope);
            // }
            const t = XObject.obj({
              name: value,
              scope: {
                type: ObjectType.mode,
                id: appState.currentMode,
              }
            });
            db.entityTypes.push(t);
            return t._id;
          }}

          onSelect={value => {
            this.props.onChange(value);
          }}

          // renderCreate={filter => {
          //   return (
          //     <>Create type "{filter}" <select
          //       value={this.state.scope || ''}

          //       onChange={e => {
          //         this.state.scope = e.target.value || null;
          //       }}
          //     >
          //       {tree.map(ref => {
          //         // const obj = getObject(ref);
          //         return <option key={ref.id} value={ref.id}>{objectName(ref)}</option>
          //       })
          //       }
          //       </select></>
          //   );
          // }}
        />}
      </Container>
      </WithContextAction>
    );
  }
}
