import {component} from '../../component2';
import {Comp} from '../Comp';
import {ValuePointProps} from '../main';
import {registerType} from "../__typeRegistry";
import {ValueType} from "../ValueType";
import { XObject } from '../../XObject';
import { defaultWorkspace } from '../../etc/defaultWorkspace';
import { db } from '../../db';
import { registerChangeAction, trackChange, UndoActions } from '../changes';
import { registerTypeRegister } from '../typeRegistering';

const SetValue = '46cfcb5d-a2aa-5535-8482-4c9db3c34669';
registerChangeAction(SetValue, {
  name: 'Set Value',
});

@component
export class EntityStateValuePoint extends Comp<ValuePointProps> {
  render() {
    return (
      <>
        <select
          value={this.props.state.content || ''}
          onChange={(e) => {
            trackChange(SetValue, {}, [
              [ UndoActions.modifiedValuePoint, this.props.state._id ],
            ]);
            this.props.state.content = e.target.value || null;
          }}
        >
          <option value=""></option>
          {db.stateTypes.map(stateType => (
            <optgroup key={stateType._id} label={stateType.values.map(v => v.name).join(', ')}>
              {stateType.values.map(value => (
                <option key={value._id} value={value._id}>
                  {value.name}
                </option>
              ))}
            </optgroup>
          ))}

        </select>
      </>
    )
  }
}

export const registerEntityStateType = () => {
    registerType(ValueType.EntityState, {
        editorComp: EntityStateValuePoint,
        evaluate: (state) => {
          return state.content;
        }
    });
};

registerTypeRegister(registerEntityStateType)