export const typeRegisterFuncs = [];
export function registerTypeRegister(func) {
  typeRegisterFuncs.push(func);
}


export const contextVars = {};
export function registerContextVar(id, label) {
  console.log('registerContextVar', id, label)
  contextVars[id] = {
    label
  }
}