import { iconColor } from "./color";
import { SidebarItem } from "./components/Sidebar";
import { makeKey } from "./components/allObjects";
import { objectIcon, objectName } from "./components/objectFuncs";
import { cssImg } from "./img";
import { viewObject } from "./viewObject";

export class ObjectSidebarItem extends SidebarItem {
  constructor(public object, public hierarchy, public _onClick, public ctx) {
    super(null);
    this.clickAction = 'handleClick';
  }

  getId(): string {
    return makeKey(this.object);
  }


  getIcon() {
    return cssImg(objectIcon(this.object), {
      '#000000': iconColor,
  });
  } 

  getChildren(): SidebarItem[] {
    // console.log('ObjectSidebarItem', this.hierarchy[makeKey(this.object)]);
    const chidlren = this.hierarchy[makeKey(this.object)]?.map?.((obj) => new ObjectSidebarItem(obj, this.hierarchy, this._onClick, this.ctx)) ?? [];
    return chidlren;
  }

  getTitle(): string {
    return objectName(this.object);
  }

  contextMenu() {
    return [
      {
        text: 'View',
        onClick: () => {
          viewObject(this.object, this.ctx);
        }
      }
    ]
  }
  onClick(): void {
    this._onClick(this.object);
  }
}