import cx from 'classnames'
import copy from 'copy-to-clipboard';
import * as htmlToImage from 'html-to-image';
import jQuery from 'jquery';
import _, { extendWith } from 'lodash';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import serialize from 'serialize-javascript';
import { BuilderComponentEditor, renderWrapperBuilderNode, } from './BuilderComponentEditor';
import { ColorEditor } from './ColorEditor';
import { DevProjectType, addDevProject, createDevProject, getActiveEditorState, getComponentEditorState, getDevProject, getDevProjectEditorState, getDevProjects, setActiveEditorState } from './DevProject.1';
import { DevProjectScriptEditor, getGlobalTypes } from './DevProjectScriptEditor';
import { DevProjectEditor, DevProjectOutput, Entry } from './DevProjectWindow';
import { DevRuntime } from './DevRuntime';
import { Block, CComponent, Node, StyleNode, StyleNodeProperty } from './Node';
import { Graph } from './Graph';
import { Related } from './ImportMenu';
import { Library } from './Library';
import { MyBlockManager } from './MyNotionDocument/MyBlockManager';
import { ScriptEditor } from './MyNotionDocument/ScriptEditor';
import { ScriptWrapper, createScriptWrapper } from './MyNotionDocument/ScriptWrapper';
import { renderBlock } from './MyNotionDocument/renderBlock';
import { ObjectEditor } from './ObjectEditor';
import { OverlayManager } from './OverlayManager';
import { StyleManager } from './StyleManager';
import { Tree } from './Tree';
import { X, XGuard, XInit, XObject, XTouch, x } from './XObject';
import { baseStyles } from "./baseStyles";
import { styled } from './component';
import { component } from './component2';
import Around from './components/Around';
import { EntityView } from './components/EntityView';
import { FileUpload, fileUpload, uploadedFileUrl } from './components/FileUpload';
import { PropertyField } from './components/PropertyField';
import { RemoteSvg, Svg } from './components/Svg';
import { ViewQueryPage } from './components/ViewQuery';
import { NotionDocument2 } from './components/notionDocument/NotionDocument2';
import { computeStyles } from './computeStyles';
import { db } from './db';
import { SystemContext } from './etc/SystemContext';
import { createEntityFromQuery, createQuery, queryChain } from './etc/queryFuncs';
import { findNodeInComponent } from './findNodeInComponent';
import { generateAppTree } from './generateAppTree';
import { showContextMenu } from './helpers';
import { highlightEls } from './highlightOutputStylesManager';
import { initDevProjectApp } from './initDevProjectApp';
import { explicitInspectObj } from './inspectObj';
import { _inspectHandlers, _inspectState, currentSpace, openWindow } from './osHelpers';
import { expandToText } from './richTextHelpers';
import { sectionStyles } from './sectionStyles';
import { Thingy, execFormula, flexGrammar, generateCss, scriptEmpty, unwrapString } from './shorthand/formula';
import { ObjectType } from './types/ObjectRef';
import { Layer } from './Layer';
import { Selector } from './components/Selector';
import { findWorkspace } from './findWorkspace';
import { ComponentEntry } from './ComponentEntry';
import { Tag } from './components/Tag';
import { AnyType, ArrayType, StringType, StructType, additionalGlobalTypes, buildModel, getScope, getType, globals } from './MyNotionDocument/getBlock';
import { attributesForType } from './components/attributesForType';
import { ObjectInspector } from 'react-inspector';
import { unwrapCst } from './MyNotionDocument/unwrapCst';
import { CallBlock, LoopBlock, RuntimeTrace } from './shorthand/RuntimeTrace';
import { SyntaxHighlighter } from './SyntaxHighlighter';


const topBarHeight = 40;


@component
class LibraryFrame extends Component<{ frame, devRuntime }> {
  editorStylesScriptWrapper: ScriptWrapper
  defaultPropsScriptWrapper: ScriptWrapper
  static styles = styled.div`

  `;
  constructor(props) {
    super(props);
    const { frame } = this.props;
    this.editorStylesScriptWrapper = createScriptWrapper({
      blocks: () => XObject.get(frame, 'editorStyles', []),
      setBlocks: b => frame.editorStyles = b,
    });
    this.defaultPropsScriptWrapper = createScriptWrapper({
      blocks: () => XObject.get(frame, 'componentProps', []),
      setBlocks: b => frame.componentProps = b,
    });
  }
  render() {
    const { frame } = this.props;

    return (
      <>
              <input type="number" defaultValue={(frame.scale || 1) * 100} onChange={e => {
          frame.scale = parseInt(e.target.value)/100;
        }} />


          <div className="section">
            <span className="header">Canvas Styles</span>
            <div className="content">
              <ScriptEditor
                scriptWrapper={this.editorStylesScriptWrapper}
              />
            </div>
          </div>

          <div className="section">
          <span className="header">Props</span>
          <div className="content">
            <DevProjectScriptEditor scriptWrapper={this.defaultPropsScriptWrapper} devRuntime={this.props.devRuntime} importedComponents={[]} />
          </div>
        </div>
      </>
    )
  }
}

const tabTypes = {
  workspaceFrame: {
    title: 'Frame',
    render: () => {
      
    }
  },
  libraryFrame: {
    title: 'Frame',
    render: (data, devRuntime: DevRuntime) => {
      const frame = devRuntime.findLibraryFrame(data.id);
      return frame && <LibraryFrame key={data.id} frame={frame} devRuntime={devRuntime} />;
    }
  }
}

enum WindowTypes {
  editor = 'd0db72d5-57d2-57bc-8354-8decd6747ead',
  stylesTest = '9ccd9c7c-25b6-50be-8f95-dfba127e202f',

  db = '85d19805-8693-5897-b6ac-9de6fe098eec',
  query = '29d16a4f-4471-506f-8ccc-03f4552149ee',

  entity = 'd19a2f3a-b26e-581f-9d8a-b72761390691',

}

@component
class EditorWindow extends Component<{ devRuntime, window }> {
  state = X({});
  render() {
    const id = '65a069ca19f33f971b2166d7';

    return <DevProjectEditor hideSidebar={false} id={id} devRuntime={this.props.devRuntime} state={this.props.window} />;
  }
}

@component
class StylesTestWindow extends Component<{ window }> {
  scriptWrapper
  constructor(props) {
    super(props);
    this.scriptWrapper = createScriptWrapper({
      blocks: () => XObject.get(this.props.window, 'blocks', []),
      setBlocks: blocks => this.props.window.blocks = blocks,
    })
  }
  render() {



    return (
      <>
        <ScriptEditor
          scriptWrapper={this.scriptWrapper}
        />

        <button
          onClick={() => {
            // , , 'scopeId';
            const css = generateCss({
              rootBlock: x(this.props.window.blocks[0]),
              baseSelector: '.class',
              scopeId: 'scopeId',
              hooks: {},
              scope: {},
            });
            console.log(css);
          }}
        >Test</button>
      </>
    )
  }
}

@component
class DBWindow extends Component<{ window }> {
  render() {
    return (
      <>
        <h2>Types</h2>
        <ul>
          {db.entityTypes.map(t => {
            return (
              <li key={t._id}
                onClick={() => {
                  explicitInspectObj({
                    type: ObjectType.type,
                    id: t._id,
                  })
                }}
              >{t.name}</li>
            )
          })}
        </ul>
        <button
          onClick={() => {
            db.entityTypes.push(XObject.obj({
              name: 'Untitled',
            }))
          }}
        >+</button>

        <h2>Tables</h2>
        <ul>
          {db.queries.map(q => {
            return (
              <li key={q._id}

              >
                
                {q.name}
                
                <button
                onClick={() => {
                  explicitInspectObj({
                    type: ObjectType.query,
                    id: q._id,
                  })

                }}
                
                >Edit</button>
                <button
                  onClick={() => {
                    openWindow({
                      type: WindowTypes.query,
                      id: q._id,
                    })
                  }}
                
                >View</button>
                </li>
            )
          })}
        </ul>
        <button
          onClick={() => {
            createQuery(null, null, null);
          }}
        >+</button>
      </>
    )
  }
}

@component
class QueryWindow extends Component<{ window }> {
  render() {
    return (
      <>
        <SystemContext.Provider
          value={{
            navigate: config => {
              if (config.type == 'entity') {
                
                openWindow({
                  type: WindowTypes.entity,
                  id: config.id,
                })
              }
            }
          }}
        >
          <ViewQueryPage
            id={this.props.window.id}
            pathId={''}
            state={this.props.window}
          />
        </SystemContext.Provider>
      </>
    )
  }
}

@component
class EntityWindow extends Component<{ window }> {
  render() {
    return (
      <>
        <SystemContext.Provider
          value={{
            navigate: config => {
              if (config.type == 'entity') {
                
                openWindow({
                  type: WindowTypes.entity,
                  id: config.id,
                })
              }
            }
          }}
        >
        <EntityView
          id={this.props.window.id}
          page={null}
          pathId={null}
          state={this.props.window}

        />
        </SystemContext.Provider>
      </>
    )
  }
}

class BlockBinding {
  constructor(public id, public el) {}
}
class NodeBinding {
  constructor(public id, public el){}
}

@component
class BottomBar extends Component<{ devProject, activeComponent, onClickComp }> {
  state = XInit(class {
    inspecting
  })

  static styles = styled.div`
    display: flex;
    .comp {
      padding: 0 4px;
      display: flex;
      align-items: center;
      border-right: 1px solid #c9c9c9;

      &.active {
        background-color: lightgray;
      }
    }
  `;
  render() {
    return (
      <>
        {this.state.inspecting?.map?.(id => {
          return (
            <span
              key={id}
              data-input-id={id}
              className={cx("comp", {
                active: this.props.activeComponent == id
              })}
              onMouseOver={() => {
                highlightEls([id]);
              }}
              onMouseOut={() => {
                highlightEls([]);
              }}
              onClick={() => {
                this.props.onClickComp(id);
              }}
            >
              {this.props.devProject.components.find(c => c._id == id).name}
            </span>
          )
        })}
      </>
    )
  }
}

@component
export class DevProjects extends Component {

  state = XInit(class {
    selected
  })

  static styles = styled.div`
    ${baseStyles}
    display: flex;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* > .leftBar {
      width: 264px;
      flex: 0 0 auto;
      height: 100%;
      border-right: 1px solid #e1e1e1;

    } */
    > .body {
      flex: 1 1 auto;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      > .top {
        height: 40px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e1e1e1;
        padding: 0 32px;
        .title {
          font-size: 14px;
        }

        .buttons {
          margin-left: auto;
          .add {
            background-color: #0d99ff;
            border-radius: 6px;
            padding: 0 12px;
            display: flex;
            align-items: center;
            height: 32px;
            color: white;
          }
        }
      }
    }
    .devProjects {
      padding: 16px 32px 32px;
      display: grid;
      grid-template-columns: repeat(auto-fill,minmax(272px,1fr));
      grid-gap: 32px 32px;


      .devProject {
        height: 31px;
        padding-top: 60%;
        position: relative;
        &.selected {
          .screenshot {
            &:before {
              border-radius: 7px;
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              border: 2px solid #0b99ff;

            }

          }
        }
        .bottom {
          margin: 6px 0;
        }
        .name {
          display: block;
          font-weight: bold;
        }
        .edited {
          display: block;
          color: gray;
        }
        .screenshot {
          border-radius: 7px;
        border: 1px solid #e7e7e7;
        box-sizing: border-box;

          /* height: 60%; */
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 31px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          background-clip: border-box;
        }
      }
    }

    .by {
      position: absolute;
      bottom: 5px;
      left: 0;
      right: 0;
      text-align: center;
      color: gray;

    }
  `

  componentDidMount(): void {
    document.title = 'Proto Builder';
  }

  render() {
    const nav = useNavigate();
    const devProjects = getDevProjects().filter(dp => !dp.deletedAt);
    return (
      <>
        <div className="leftBar">
          
        </div>

        <div className="body">
          <div className="top">
            <span className="title">Projects</span>

            <div className="buttons">
              <span className="add"
                onClick={() => {
                  createDevProject();
                }}
              >+ Project</span>

            </div>
          </div>
          <div className="devProjects">
            {devProjects.map(dp => {
              return (
                <div key={dp._id} className={cx("devProject", {
                  selected: this.state.selected == dp._id
                })}
                onClick={() => {
                  this.state.selected = dp._id;
                }}
                  onDoubleClick={() => {
                    nav(`/projects/${dp._id}`);
                  }}

                  onContextMenu={e => {
                    e.preventDefault();
                    showContextMenu(e, [
                      {
                        text: 'Debug',
                        onClick: () => {
                          console.log(x(dp));
                        },
                      },
                      {
                        text: 'Delete',
                        onClick: () => {
                          dp.deletedAt = new Date();
                        },
                      }
                    ])
                  }}
                
                >
                  <span style={{
                    backgroundImage: `url(${uploadedFileUrl(dp.screenshot)})`,
                  }} className="screenshot" />
                  <div className="bottom">
                  <span className="name">{dp.name}</span>
                  {/* <span className="edited">Edited 3 days ago</span> */}

                  </div>

                </div>
              )
            })}
          </div>
          <div className="by">
            &copy; 2024 Jonathan Cook
          </div>
        </div>
      </>
    )
  }
}

@component
class AppTree extends Component<{
  parent: DevProject
  devRuntime: DevRuntime
  active
  onClickNode
  onNodeMouseOver
  onNodeMouseOut
}> {

  state = X({});
  static styles = styled.div`

  `
  render() {
    const tree = x(this.props.parent.state.tree);
    return (
      <>
        {tree && (
          <Tree
            className="appTree"
            active={this.props.active}
            defaultOpen
            nodes={[tree]}
            state={this.state}
            scroll
            onClickNode={(__, node) => {
              this.props.parent.navigateToEl2(node.node.el);
              this.props.onClickNode(node);
            }}
            onNodeMouseOut={this.props.onNodeMouseOut}
            onNodeMouseOver={this.props.onNodeMouseOver}
          />
        )}
      </>
    )
  }
}

@component
class ColorEntry extends Component<{ color }> {
  state = XInit(class {
    showEditor = false
  });

  static styles = styled.div`
      .color {
        cursor: pointer;
      height: 32px;
      display: flex;
      align-items: center;
      padding: 0 8px;
      &:hover {
        background-color: #f5f5f5;
      }
      .swatch {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 4px;
      }
    }
  `;

  render(Container?) {
    const { color } = this.props;
    const ref = React.createRef<any>();
    return (
      <Container>

        {this.state.showEditor && <Around
          anchor={() => ref.current}
        >

            <ColorEditor color={this.props.color} />
          </Around>}
          <div key={color._id} className="color"
            ref={ref}

            onClick={() => {
              this.state.showEditor = !this.state.showEditor;
            }}
          
          >



          <span className="swatch"

            style={{
              backgroundColor: color.color
            }}
            /> <PropertyField object={color} property="name" dblClickEdit />
          {/* <div>

          Name: <PropertyField object={color} property="name" />
          </div>
          <div
            style={{
              color: color.color
            }}
          >
          Color: <PropertyField object={color} property="color" />

          </div> */}
        </div>

      </Container>
    )
  }
}

@component
class Colors extends Component<{ devProject }> {
  static styles = styled.div`

  `;
  render() {
    const colors = XObject.get(this.props.devProject, 'colors', []);

    return (
      <>
                          <div>
                      {colors.map(color => {

                        return <ColorEntry color={color} ref={color._id} />
                      })}
                    </div>
      </>

    )
  }
}

@component
class Assets extends Component<{ devProject: DevProjectType }> {
  static styles = styled.div`
    .asset {
      display: flex;
      align-items: center;

      padding: 0 8px;


      height: 32px;

      &:hover {
        background-color: #f5f5f5;
      }


      .image {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 4px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
      svg {

      }
    }
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
  `;
  render(Container?) {
    const { devProject } = this.props;

    const assets = [...devProject.assets];
    assets.sort((a, b) => {
      return a.name < b.name ? -1 : 1;
    });

    return (
      <FileUpload Tag={Container}
        onUpload={id => {
          devProject.assets.push(XObject.obj({
            name: 'untitled',
            file: id,
          }))
        }}
      >
        {assets.map(asset => {
          // if (asset.name == 'hash' || asset.name == 'untitled') return;
            return (
              <div key={asset._id} className="asset">
                <span className="image"><RemoteSvg url={uploadedFileUrl(asset.file)} /></span>
                <PropertyField object={asset} property="name" dblClickEdit />
              </div>
            )
        })}
      </FileUpload>
    )
  }
}

@component
class ElementStyles extends Component<{ devRuntime: DevRuntime, state, devProjectComp }> {
  overlay = new OverlayManager();
  static styles = styled.div``;

  render() {
    const { devRuntime } = this.props;
    const devProject = this.props.devRuntime.devProject;
    const editorState = getDevProjectEditorState(devProject);

    const el = document.querySelector(`[data-path="${editorState.activePath}"]`);

    let propId;

    if (getActiveEditorState(devProject)?.nodeState?.styleNodeState?.prop) {
      propId = `styleNode#${getActiveEditorState(devProject)?.nodeState?.styleNodeState.id || getActiveEditorState(devProject)?.nodeState.id}/${getActiveEditorState(devProject)?.nodeState?.styleNodeState.prop}`
    }
    else if (getActiveEditorState(devProject)?.nodeState?.block) {
      propId = `block#${getActiveEditorState(devProject)?.nodeState?.block}`
    }



    const groupedStyles = {};

    const styles = el ? computeStyles(el) : null;


    if (styles) {
      for (const prop in styles.source) {
        if (!groupedStyles[styles.source[prop].stylesId]) groupedStyles[styles.source[prop].stylesId] = [];
        groupedStyles[styles.source[prop].stylesId].push(prop);

      }
    }

    return (
      <>


          {Object.keys(groupedStyles).map(group => {
            let header;

            let found;

            const [type, id] = group.split('#');
            if (type == 'styleNode') {
              found = this.props.devRuntime.findStyleNode(id);
              // const parts = [];


              
            }
            else if (type == 'block') {
              header = id;
              found = this.props.devRuntime.findBlock(id);

            }

            else if (type == 'style') {
              found = [];
              header = ['style']
            }


            const comp = found.find(el => el instanceof CComponent)
            const node = found.find(el => el instanceof Node);

            header = _.reverse([...found]).map(el => {
              if (el instanceof CComponent) {
                return el.obj.name;
              }
              else if (el instanceof Node) {
                if (el.obj._id == comp.obj.root._id) return;
                return el.obj.class ? `.${el.obj.class}` : 'node';
              }
              else if (el instanceof StyleNode) {
                return expandToText({
                  types: this.props.devRuntime.__types(),
                }, el.obj.selector, {}, {
                  humanReadable: true,
                });
              }
              else if (el instanceof Block) {
                return expandToText({
                  types: this.props.devRuntime.__types(),
                }, el.obj.data);
              }
              else {
                return el;
              }
            }).filter(Boolean).join(' ');


            return (
              <div key={group} className="stylesGroup">
                <span
                  className="stylesGroupHeader"
                  onMouseOver={() => {
                    console.log(group);
                    if (group.startsWith('styleNode#')) {
                      const styleNodeId = group.slice('styleNode#'.length);
                      const found = devRuntime.findStyleNode(styleNodeId);
                      console.log(found);

                      const findNode = (id, start, path=[]) => {
                        if (start._id == id) {
                          return path.concat(start);
                        }
                        else {
                          if (start.children) {
                            for (const child of start.children) {
                              const r = findNode(id, child, path.concat(start));
                              if (r) return r;
                            }
                          }
                        }
                      }

                      const node = found.find(el => el instanceof Node);
                      const path = findNode(styleNodeId == node.obj._id ? null : styleNodeId, node.obj.designer);

                      const selector = this.props.devRuntime.buildSelector(node.obj, path);
                      console.log(selector);

                      this.overlay.setOverlays([
                        {
                          selector: selector,
                          styles: {
                            outline: '2px solid #0b99ff',
                          }
                        }
                      ], -2)
                    }
                  }}
                  onMouseOut={() => {
                    this.overlay.setOverlays([]);
                  }}
                >{header}</span>
                {groupedStyles[group].map(key => {
                  return (
                    <div key={key}
                      className={cx("property", {
                        active: styles.source[key].propId == propId,
                      })}
                      data-style-prop-id={styles.source[key].propId}
                      onClick={() => {
                        const propId = styles.source[key].propId;
                        if (propId.startsWith('styleNode#')) {
                          const [styleNodePart, prop] = propId.split('/');
                          const styleNodeId = styleNodePart.slice('styleNode#'.length);
                          const found = devRuntime.findStyleNode(styleNodeId);
                          if (found) {

                            this.props.devProjectComp.navigateSidebarTo([new StyleNodeProperty(prop)].concat(found));

                          }
                          else {
                            console.log('not found', propId);

                          }
                        }
                        if (propId.startsWith('block#')) {
                          const id = propId.slice('block#'.length);
                          const found = devRuntime.findBlock(id);
                          console.log(id, found);

                          if (found) {
                            this.props.devProjectComp.navigateSidebarTo(found, true);

                          }
                          else {
                            console.log('not found', propId);
                          }
                        }
                      }}
                    >
                      <span className="name">{key}</span>: <span className="value">{styles.source[key].value}</span>
                    </div>
                  )
                })}
              </div>
            )
          })}

      </>
    )
  }
}


@component
class TabBar extends Component<{ tabs: {
  key
  title
  contextMenu?
}[], active, onClickTab }> {
  static styles = styled.div`
    flex: 0 0 auto;
    position: sticky;
    top: 0;
    z-index: 9999;
    display: flex;
    .tab {
      padding: 2px 4px;
      cursor: pointer;
      &.active {
        /* font-weight: bold; */
        background-color: #cdcdcd33;
      }
    }
  `;
  render() {
    return (
      <>
        {this.props.tabs.map(t => {
          return (
            <span className={cx('tab', {
              active: this.props.active == t.key
            })}

            onContextMenu={e => {
              e.preventDefault();
              showContextMenu(e, t.contextMenu);
            }}

            key={t.key}
            
            onClick={() => {
              this.props.onClickTab(t.key);
            }}
            >{t.title}</span>
          )
        })}
      </>
    )
  }
}

@component
class DBTab extends Component<{ state }> {
  static styles = styled.div`
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    > .queries {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 200px;
      border-right: 1px solid #f3f3f3;
      box-sizing: border-box;

      .header {
        background-color: #e7e7e7;
        padding: 0 4px;
        font-weight: 600;

        display: flex;

        align-items: center;

        .add {
          margin-left: auto;
          cursor: pointer;
          display: flex;

          align-items: center;
          justify-content: center;

          svg {
            width: 11px;
            height: 11px;
          }
        }
      }

      .item {
        padding: 0 8px;

        &:hover {
          background-color: #f3f3f3;
        }

        &.active {
          background-color: #e3e3e3;
        }

        cursor: pointer;
      }
    }

    > .query {
      position: absolute;
      left: 200px;
      top: 0;
      bottom: 0;
      width: 400px;
      border-right: 1px solid #f3f3f3;
      box-sizing: border-box;
    }

    > .client {
      position: absolute;
      left: 600px;
      top: 0;
      bottom: 0;
      right: 0;
    }

    > .editPane {
      box-shadow: 0 0 3px #9898985c;
      z-index: 9999;
      background-color: white;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 310px;
      border-left: 1px solid #f3f3f3;

      > .close {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;

        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;

        svg {
          width: 12px;
          height: 12px;
          fill: #1a1a1a;
        }
      }


      ${ObjectEditor} {
        .section.objects {
          display: none;
        }
      }
    }
  `;

  state = XInit(class {
    // selectQuery
    // queryState = {}
    // clientNavStack = []
  })

  render() {
    // const selectedQuery = db.queries.findById(this.state.selectQuery);


    const queryState = XObject.get(this.props.state, 'queryState', {});
    const clientNavStack = XObject.get(this.props.state, 'clientNavStack', []);

    const topClient = clientNavStack[clientNavStack.length - 1];

    return (
      <>
        <div className="queries">
          <div className="header">
            Tables


            <div className="add"
              onClick={() => {
                createQuery(null, null, null);
              }}
            >
              <Svg name="plus" />
            </div>
          </div>
          <div className="items">
            {db.queries.filter(q => !q.__deleted).map(q => {
              return (
                <div key={q._id}
                className={cx("item", { active: this.props.state.selectQuery == q._id })}
                  onClick={() => {
                    this.props.state.selectQuery = q._id;
                    this.props.state.clientNavStack = [];
                  }}
                >{q.name || 'untitled'}</div>
              )
            })}
          </div>
        </div>
        <div className="query">
          {this.props.state.selectQuery && <SystemContext.Provider
            value={{
              navigate: config => {
                if (config.type == 'entity') {
                  
                  this.props.state.clientNavStack = [
                    XObject.obj({
                      type: 'entity',
                      id: config.id,
                    })
                  ]
                }
              },
              next: () => {
                return clientNavStack[0]
              }
            }}
          >
            <ViewQueryPage
              id={this.props.state.selectQuery}
              pathId={''}
              state={queryState}
            />
          </SystemContext.Provider>}
        </div>
        <div className="client" key={topClient?._id}>
          {topClient && (
            <>
              {topClient.type == 'entity' && (
                <>
                  <SystemContext.Provider
                    value={{
                      pageFrameBack: clientNavStack.length > 1,
                      navigate: config => {
                        if (config.type == 'entity') {
                          
                          clientNavStack.push(XObject.obj({
                            type: 'entity',
                            id: config.id,
                          }))
                        }
                      },
                      back: () => {
                        clientNavStack.pop();
                      }
                    }}
                  >
                  <EntityView
                    
                    id={topClient.id}
                    page={null}
                    pathId={null}
                    state={XObject.get(topClient, 'state', {})}

                  />
                  </SystemContext.Provider>
                </>

              )}
            </>
          )}
        </div>


        {currentSpace().sideBar && <div className="editPane">

          <div
          className="close"
          onClick={() => {
            currentSpace().sideBar = false;
          }}

          >
            <Svg name="icons8-close" />

          </div>

          <SystemContext.Provider value={{
            navigate: () => {},
          }}>
            {_inspectHandlers[_inspectState()?.focused?.type]?.render?.(_inspectState()?.focused?.args)}
        </SystemContext.Provider>
        </div>}
      </>
    )
  }
}
const iter = (node, activePath: string, path=[]) => {
  if (node.key == activePath) {
    return path.concat(node);
  }
  else {
    if (node.children) for (const child of node.children) {
      const r = iter(child, activePath, path.concat(node));
      if (r) return r;
    }
  }
}

@component
class ComponentInstance extends Component<{ devRuntime: DevRuntime, instance }> {
  static styles = styled.div`
    ${sectionStyles}
  `;
  render() {
    const instance = this.props.devRuntime.componentInstancesById[this.props.instance];
    if (instance) {
      const component = this.props.devRuntime.getComponent(instance.componentId);

      const props = {};

      for (const prop in instance.props) {
        if (
          prop.startsWith('data-') ||
          prop == 'children' ||
          prop == '_rt' ||
          prop == '__deepSignals' ||
          prop == '__signals'
        ) continue;

        props[prop] = instance.props[prop];
      }

      XTouch(instance.state);

      return (
        <>
          <span className="sectionHeader">{component.name}</span>

          <div className="section">
            <span className="header">Props</span>
            <div className="content">
              <ObjectInspector
                key={JSON.stringify(x(props))}
                expandLevel={2}
                data={x(props)}
              />
            </div>
          </div>

          <div className="section">
            <span className="header">State</span>
            <div className="content">
              <ObjectInspector
                expandLevel={1}
                data={x(instance.state)}
              />
            </div>
          </div>

          <div className="section">
            <span className="header">Setup</span>
            <div className="content">
              <ObjectInspector
                expandLevel={1}
                data={x(instance.setup)}
              />
            </div>
          </div>
        </>
      )
  
    }
  }
}

@component
class KeepBottomScroll extends Component<{ children? }> {
  static styles = styled.div`
    overflow: auto;
  `;

  timerId
  componentDidMount(): void {
    const cont = ReactDOM.findDOMNode(this) as HTMLElement;


    let lastScrollHeight, lastScrollTop, lastOffsetHeight;


    cont.addEventListener('scroll', e => {
      const bottomScroll = cont.scrollHeight - cont.offsetHeight;
      
      if (bottomScroll - cont.scrollTop < 10) {
        scrollToBottom = true;
      }
      else {
        scrollToBottom = false;
      }
    });


    let scrollToBottom = true;

    const update = () => {
      const bottomScroll = cont.scrollHeight - cont.offsetHeight;


      if (lastScrollHeight != cont.scrollHeight || lastOffsetHeight != cont.offsetHeight) {
        if (scrollToBottom) {
          lastScrollHeight = cont.scrollHeight;
          lastOffsetHeight = cont.offsetHeight;
  
          cont.scrollTop = bottomScroll;  

          lastScrollTop = cont.scrollTop;

        }
      }
      else {
        if (lastScrollTop != cont.scrollTop) {
          // scrollToBottom = false;
        }
      }
    }
    update();
    this.timerId = setInterval(() => {
      update();
    }, 10);


  }

  componentWillUnmount(): void {
    clearInterval(this.timerId);
  }

  render(Container?) {
    return (
      <Container>
        {this.props.children}
      </Container>
    )
  }
}

@component
class Console extends Component<{ devRuntime: DevRuntime }> {
  static styles = styled.div`
    ${KeepBottomScroll} {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  
    .entry {
      /* border-bottom: 1px solid #efefef; */
      padding: 8px;

      position: relative;

      &:not(:last-child) {
        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          right: 8px;
          height: 1px;
          background: #efefef;
          left: 8px;
        }
      }


    }
  `;
  render() {
    return (
      <>
        <KeepBottomScroll>
          {this.props.devRuntime.state.consoleEntrie.map(e => {
            let data;
            try {
              data = JSON.parse(JSON.stringify(x(e.value)));
            }
            catch (e) {
              data = 'error';
            }
            return (
              <div className="entry" key={e._id}>
                <ObjectInspector
                  data={data}
                />
              </div>
            )
          })}
        </KeepBottomScroll>
      </>
    )
  }
}

@component
class Events extends Component<{ devRuntime: DevRuntime, parent: DevProject }> {
  static styles = styled.div`
    ${KeepBottomScroll} {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .code {
      font-family: Monaco;
      &.string {
        color: red;
      }

      &.maxWidth {
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .instance {
      font-size: 8px;
      color: gray;
      cursor: pointer;
    }

    font-size: 10px;

    .instance.origin {
              margin-left: auto;
              color: #7e9cce;
              text-decoration: underline;
              white-space: nowrap;


            }

  
    .entry {
      padding: 8px;

      position: relative;

      &.active {
        > .mainEvent > .eventName {
          text-decoration: underline;
        }
      }

      > .mainEvent {
        display: flex;

        align-items: center;

        gap: 4px;

        ${Tag} {
          cursor: pointer;
        }
        font-weight: 500;

        .eventName {
          cursor: pointer;
        }

        input[type="radio"] {
          margin: 0;
          margin-right: 4px;
        }

        /* padding-left: 30px;
        > input {
          position: absolute;
          top: 7px;
          left: 4px;
        } */

        .instance.origin {
          margin-left: auto;
          color: #7e9cce;
          text-decoration: underline;
        }

        .timestamp {
          margin-left: auto;
          font-size: 10px;
          color: gray;
        }
      }

      > .otherEvents {
        .event {
          padding: 4px 0;
          &:first-child {
            margin-top: 4px;

          }

          .main {
            display: flex;
            align-items: center;

            .eventTitle {
              display: flex;
              align-items: center;
              gap: 4px;

              overflow: hidden;
              margin-right: 4px;
              white-space: nowrap;
            }


          }

          /* &:not(:last-child) { */
            border-top: 1px solid #efefef;

          /* } */
        }
      }

      &:not(:last-child) {
        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          right: 8px;
          height: 1px;
          background: #e5e5e5;
          left: 8px;
        }
      }
    }
  `;
  render() {
    return (
      <>
        <KeepBottomScroll>
          {this.props.devRuntime.state.eventEntries.map(e => {
            let data;
            try {
              data = x(e);
            }
            catch (e) {
              // console.log(e);
              data = 'error';
            }

            const component = this.props.devRuntime.getComponent(data.componentId);


            const collectEvents = (trace: RuntimeTrace, events) => {
              for (const id in trace.blocks) {
                const block = trace.blocks[id];
                if (block instanceof CallBlock) {
                  if (block.func == 'emit') {
                    events.push({
                      type: 'emit',
                      blockId: id,
                      instanceId: trace.instanceId,
                      block,
                      order: block.order,
                    })
                  }
                  else if (block.func == 'navigate.push') {
                    events.push({
                      type: 'navigate.push',
                      blockId: id,
                      instanceId: trace.instanceId,
                      block,
                      order: block.order,
                    })

                  }
                  else if (block.func == 'presentLayer') {
                    events.push({
                      type: 'presentLayer',
                      blockId: id,
                      instanceId: trace.instanceId,
                      block,
                      order: block.order,
                    })

                  }
                  else if (block.func == 'layer.remove') {
                    events.push({
                      type: 'layer.remove',
                      blockId: id,
                      instanceId: trace.instanceId,
                      block,
                      order: block.order,
                    })

                  }
                  collectEvents(block.cs, events);
                }
                else if (block instanceof LoopBlock) {
                  for (const iter of block.iterations) {
                    collectEvents(iter.cs, events);
                  }
                }
              }

              for (const id in trace.calls) {
                const calls = trace.calls[id];
                for (const call of calls) {
                  collectEvents(call.cs, events);
                }
              }

              for (const id in trace.mutations) {
                const mutations = trace.mutations[id];
                events.push({
                  type: 'mutation',
                  blockId: id,
                  instanceId: mutations.runtimeContext.context.instanceId,
                  runtimeContext: mutations.runtimeContext,
                  mutation: mutations.mutation,
                  order: mutations.order,
                  dataCategory: mutations.type,
                  extra: mutations.extra,
                })
              }

              for (const child of trace.children) {
                collectEvents(child, events);
              }
            }

            const events = [];
            collectEvents(data.trace, events);

            events.sort((a, b) => a.order - b.order);

            const instanceTag = (id, {onClick = undefined, className=undefined}={}) => {
              const instanceInfo = this.props.devRuntime.getComponentInstanceInfo(id);
              const comp = this.props.devRuntime.getComponent(instanceInfo.componentId);
              return (
                <span className={cx('instance', className)}
                  onClick={onClick}
                >
                  {`${comp.name} #${instanceInfo.instanceNumber}`}
                </span>
              )

              // const inst = this.props.devRuntime.getComponentInstance(id);
              // if (inst) {
              //   const comp = this.props.devRuntime.getComponent(inst.componentId);
              //   return (
              //     <span className="instance"
              //       onClick={onClick}
              //     >
              //       {`${comp.name} #${inst.instanceNumber}`}
              //     </span>
              //   )
              //   // return (
              //   //   <Tag className="instance" text={`${comp.name} #${inst.instanceNumber}`} _onClick={onClick} />
              //   // )
  
              // }
            }

            return (
              <div className={cx("entry", { active: data._id == this.props.devRuntime.state.activeEvent })} key={data._id}>
                <div className="mainEvent">
                  {/* <input
                    type="radio"
                    checked={data._id == this.props.devRuntime.state.activeEvent}
                    onChange={() => {
                      this.props.devRuntime.selectEvent(data._id)
                    }}
                  /> */}
                  <span className="eventName"
                    onClick={() => {
                      this.props.devRuntime.selectEvent(data._id)

                    }}
                  >{data.event}</span>

                  {instanceTag(data.instanceId, {
                    className: 'origin',
                    onClick: () => {
                      this.props.devRuntime.selectEvent(data._id)
                      this.props.parent.navigateToInstanceBlock(data.instanceId, data.blockId);
                    }
                  })}
                  {/* <Tag
                    text={component.name}                  
                    _onClick={() => {
                      this.props.devRuntime.selectEvent(data._id)
                      this.props.parent.navigateToInstanceBlock(data.instanceId, data.blockId);
                    }}
                  /> */}
                  {/* <span className="timestamp">{data.timestamp['format']('{HH}:{mm}:{ss}')}</span> */}
                </div>
                <div className="otherEvents">
                  <div>
                    {events.map((e, i) => {
                      if (e.type == 'emit') {
                        const found = this.props.devRuntime.findBlock(e.blockId);
                        // const comp = found.find(f => f instanceof CComponent);

                        const handler = e.block.cs.handler;

                        // const instance = handler && this.props.devRuntime.getComponentInstance(handler.instanceId);
                        // const comp2 = instance && this.props.devRuntime.getComponent(instance.componentId);

                        return (
                          <div key={i} className="event ui">
                            <div className="main">
                              <div className="eventTitle">
                                Emitted <span className="code string">'{unwrapString(e.block.args[0])}'</span>


                                {handler && instanceTag(handler.instanceId, {
                                  onClick: () => {
                                    this.props.devRuntime.selectEvent(data._id)
                                    this.props.parent.navigateToInstanceBlock(handler.instanceId, handler.blockId);
                                  }
                                })}
                                {/* <Tag text={this.props.devRuntime.getComponentInstanceDebugName(handler.instanceId)}
                                  _onClick={() => {
                                    this.props.parent.navigateToInstanceBlock(handler.instanceId, handler.blockId);
                                  }}
                                /> */}

                              </div>
                              {instanceTag(e.instanceId, {
                                className: 'origin',
                                onClick: () => {
                                  this.props.devRuntime.selectEvent(data._id)
                                  this.props.parent.navigateToInstanceBlock(e.instanceId, e.blockId);
                                }
                              })}
                            </div>

                          </div>
                        )
                      }
                      else if (e.type == 'mutation') {
                        if (e.dataCategory == 'db') {
                          if (e.mutation.type == 'set') {
                            const id = e.mutation.path[0].slice(1);
                            const entity = this.props.devRuntime.devProject.db.entities.find(o => o._id == id);
                            const type = this.props.devRuntime.devProject.db.entityTypes.find(o => o._id == entity.type);

                            if (e.mutation.path[1] == '__deleted') {
                              return (
                                <div key={i} className="event mutation">
                                  <div className="main">
                                    <div className="eventTitle">
                                      Deleted <span className="code string maxWidth">'{entity.name}'</span> <Tag text={type.name} /> 
                                    </div>
    
                                    {instanceTag(e.instanceId, {
                                      className: 'origin',
                                      onClick: () => {
                                        this.props.devRuntime.selectEvent(data._id)
                                        this.props.parent.navigateToInstanceBlock(e.instanceId, e.blockId);
                                      },
    
                                    })}
                                  </div>
                                </div>
                              )
                            }
                            else {
                              const attributeId = e.mutation.path[2];
    
                              const attr = this.props.devRuntime.devProject.db.attributeTypes.find(o => o._id == attributeId);
      
                              return (
                                <div key={i} className="event mutation">
                                  <div className="main">
                                    <div className="eventTitle">
                                      <SyntaxHighlighter className="code" code={`record.${attr.name} = ${JSON.stringify(e.mutation.value)}`} /> <Tag text={type.name} />
                                      {/* <span className="code">record.{attr.name} = {JSON.stringify(e.mutation.value)}</span> <Tag text={type.name} />  */}
                                    </div>
    
                                    {instanceTag(e.instanceId, {
                                      className: 'origin',
                                      onClick: () => {
                                        this.props.devRuntime.selectEvent(data._id)
                                        this.props.parent.navigateToInstanceBlock(e.instanceId, e.blockId);
                                      },
    
                                    })}
                                  </div>
                                </div>
                              )
                            }
    

                          }
                          else if (e.mutation.type == 'insert') {

                            const entity = e.mutation.el;
                            const type = this.props.devRuntime.devProject.db.entityTypes.find(o => o._id == entity.type);

    
                            return (
                              <div key={i} className="event mutation">
                                <div className="main">
                                  <div className="eventTitle">
                                    Inserted <span className="code string maxWidth">'{entity.name}'</span> <Tag text={type.name} /> 
                                  </div>
  
                                  {instanceTag(e.instanceId, {
                                    className: 'origin',
                                    onClick: () => {
                                      this.props.devRuntime.selectEvent(data._id)
                                      this.props.parent.navigateToInstanceBlock(e.instanceId, e.blockId);
                                    },
  
                                  })}
                                </div>
                              </div>
                            )
                          }
                          else {
                            return (
                              <div key={i} className="event mutation">
                                <button
                                  onClick={() => {
                                    this.props.parent.navigateToInstanceBlock(e.runtimeContext.context.instanceId, e.blockId);
  
                                  }}
                                >.</button>
                              </div>
                            )
                          }
                        }
                        else if (e.dataCategory == 'ui') {
                          return (
                            <div key={i} className="event mutation">
                              <div className="main">
                                <div className="eventTitle">
                                  <SyntaxHighlighter className="code" code={`state.${e.mutation.path.join('.')} = ${JSON.stringify(e.mutation.value)}`} />
                                  {e.extra.instanceId != e.instanceId && instanceTag(e.extra.instanceId)}
                                </div>
                                
                                {instanceTag(e.extra.instanceId, {
                                  className: 'origin',
                                  onClick: () => {
                                    this.props.devRuntime.selectEvent(data._id)
                                    this.props.parent.navigateToInstanceBlock(e.extra.instanceId, e.blockId);
                                  },
                                })}
                              </div>
                            </div>
                          );
                        }
                        else if (e.dataCategory == 'globalState') {
                          return (
                            <div key={i} className="event mutation">
                              <div className="main">
                                <div className="eventTitle">
                                  <SyntaxHighlighter className="code" code={`obj.${e.mutation.path.join('.')} = ${JSON.stringify(e.mutation.value)}`} />
                                </div>
                                
                                {instanceTag(e.instanceId, {
                                  className: 'origin',
                                  onClick: () => {
                                    this.props.devRuntime.selectEvent(data._id)
                                    this.props.parent.navigateToInstanceBlock(e.instanceId, e.blockId);
                                  },
                                })}
                              </div>
                            </div>
                          );
                        }
                      }
                      else if (e.type == 'navigate.push') {
                        return (
                          <div key={i} className="event">
                            <div className="main">
                              <div className="eventTitle">
                              Navigated to <span className="code string">'{unwrapString(e.block.args[0])}'</span>
                              </div>

                              {instanceTag(e.instanceId, {
                                className: 'origin',
                                onClick: () => {
                                  this.props.devRuntime.selectEvent(data._id)
                                  this.props.parent.navigateToInstanceBlock(e.instanceId, e.blockId);
                                }
                              })}
                            </div>
                          </div>
                        )
                      }
                      else if (e.type == 'presentLayer') {
                        return (
                          <div key={i} className="event">
                            <div className="main">
                              Presented layer
                              {instanceTag(e.instanceId, {
                                className: 'origin',
                                onClick: () => {
                                  this.props.devRuntime.selectEvent(data._id)
                                  this.props.parent.navigateToInstanceBlock(e.instanceId, e.blockId);
                                }
                              })}
                            </div>
                          </div>
                        )

                      }
                      else if (e.type == 'layer.remove') {
                        return (
                          <div key={i} className="event">
                            <div className="main">
                              Layer removed
                              {instanceTag(e.instanceId, {
                                className: 'origin',
                                onClick: () => {
                                  this.props.devRuntime.selectEvent(data._id)
                                  this.props.parent.navigateToInstanceBlock(e.instanceId, e.blockId);
                                }
                              })}
                            </div>
                          </div>
                        )
                      }

                      return (
                        <div key={i} className="event">
                          <button
                            onClick={() => {
                              console.log(e);
                              this.props.devRuntime.selectEvent(data._id)
                              this.props.parent.navigateToInstanceBlock(e.instanceId, e.blockId);

                            }}
                          >.</button>
                        </div>
                      )
  
                    })}
                  </div>
                </div>
              </div>
            )
          })}
        </KeepBottomScroll>
      </>
    )
  }
}

@component
class BottomPane extends Component<{
  devRuntime: DevRuntime
  devProjectComp: DevProject
  state
  additionalTabs
}> {
  static styles = styled.div`
    ${baseStyles}

    position: relative;
    z-index: 0;
    ${TabBar} {
      border-bottom: 1px solid #e3e3e3;
      background: #f3f3f3;

      .active {
        background: #e3e3e3
      }
    }
    
    > .content {
      position: absolute;
      top: 23px;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &.element {
      > .content {
        box-sizing: border-box;
        display: flex;
        padding-bottom: 26px;
        > .left {
          width: 300px;
          overflow: auto;
          border-right: 1px solid #efefef;
          flex: 0 0 auto;

        }

        > .script {
          flex: 1 1 auto;
          overflow: auto;
        }

        > .right {
          width: 300px;
          flex: 0 0 auto;
          border-left: 1px solid #efefef;


          display: flex;
          flex-direction: column;

          > .content {
            flex: 1 1 auto;
            overflow: auto;
            position: relative;
            ${ElementStyles} {
              font-family: Monaco;
              padding: 8px;

              .stylesGroup {
                margin-bottom: 8px;
              }

              .property {
                margin-left: 16px;
                cursor: pointer;



                &:hover {
                  background-color: #f3f3f3;
                }

                &.active {
                  background-color: #e3e3e3;
                }
              }

              .name {
                color: green;
              }

              .value {
                color: orange;
              }
            }
          }





        }


        > .pathBar {
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          height: 26px;
          border-top: 1px solid #f4f4f4;
          box-sizing: border-box;

          display: flex;
          overflow: auto;

          &::-webkit-scrollbar {
            display: none;
          }


          .comp {
            padding: 0 8px;
            display: flex;
            align-items: center;
            cursor: pointer;
            flex: 0 0 auto;

            &.active {
              background-color: #f4f4f4;
            }

            &:hover {
              background-color: #f4f4f4;

            }
          }

          
        }
      }
    }
  `;

  timerId
  stylesRef = React.createRef<ElementStyles>();
  updateStyles() {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      this.stylesRef.current?.forceUpdate?.();
    }, 1000);
  }

  render(Container?) {
    const devRuntime = this.props.devRuntime;
    const devProject = this.props.devRuntime.devProject;

    const editorState = getDevProjectEditorState(devProject);

    
    let componentId, nodeId, blockId;
    const parts = editorState.activeBlockPath?.split?.('/') || [];
    if (parts.length == 3) {
      [componentId, nodeId, blockId] = parts;
    }
    else if (parts.length == 2) {
      [componentId, blockId] = parts;

    }
    let block, node, component;

    if (componentId) {
      component = devRuntime.getComponent(componentId);
    }

    if (nodeId) {
      const found = devRuntime.findNode(nodeId);
      if (found) {
        node = found.find(el => el instanceof Node)?.obj;
  
      }
    }


    const path = x(this.props.devProjectComp.state.path);

    let importedComponents = []
    if (component) {
      importedComponents = this.props.devRuntime._components()
      .filter(c => c.parent == component._id)
      .concat(component?.imported?.map?.(id => this.props.devRuntime._components().find(c => c._id == id)) || [])

    }

    if (editorState.mode == 'workspace') {
      const workspace = findWorkspace(editorState.activeWorkspace, this.props.devRuntime.devProject.workspaceStructure);
      if (workspace) {
        let asfd = workspace.frames.map(f => {
          return this.props.devRuntime._components().find(c => c._id == f.component)
        });
  
  
        importedComponents = importedComponents.concat(asfd);
  
        importedComponents = _.uniq(importedComponents);

      }
      
    }


    const elementTabState = XObject.get(this.props.state, 'elementTab', {});

    const tree = x(this.props.devProjectComp.state.tree);


    const findComponent = (node, instanceId?) => {
      if (node) {
        if (node.key == editorState.activePath) {
          return node.node.instanceId || instanceId;
        }
        for (const child of node.children) {
          const r = findComponent(child, node.node.instanceId || instanceId);
          if (r) return r;
        }
  
      }
    }
    
    const instanceId = findComponent(tree);


    




    return (
      <Container className={this.props.state.inspectTab || 'element'}>
        <TabBar
          active={this.props.state.inspectTab || 'element'}
          onClickTab={t => {
            this.props.state.inspectTab = t;
          }}
          tabs={[
            { title: 'Element', key: 'element' },
            { title: 'Data', key: 'data' },
            { title: 'Library', key: 'library' },
            ...this.props.additionalTabs.map(t => {
              return {
                title: tabTypes[t.type].title,
                key: t.type,
              }
            }),
          ]}
        />
        <div className="content">
          {(!this.props.state.inspectTab || this.props.state.inspectTab == 'element') && (
            <>
              <div className="left">
                <AppTree
                  onClickNode={node => {
                    this.props.devProjectComp.navigateToEl2(node.node.el);
                    // this.props.devProjectComp.state.path = XGuard(iter(x(this.props.devProjectComp.state.tree), editorState.activePath));

                    this.props.devProjectComp.setPath(editorState.activePath);

                    const instanceId = findComponent(tree);
                    this.props.devRuntime.selectInstance(instanceId);

                  }}
                  active={editorState.activePath}
                  parent={this.props.devProjectComp}
                  devRuntime={this.props.devRuntime}
                  onNodeMouseOut={node => {
                    this.props.devProjectComp.setOverlay(null);
                  }}
                  onNodeMouseOver={node => {
                    this.props.devProjectComp.setOverlay(node.key, true);
                  }}
                />
              </div>
              <div className="script">
                {(node || component) && (
                  <div data-node-script>
                    <DevProjectScriptEditor
                      fadeOutNoDebugData
                      key={blockId}
                      devRuntime={devRuntime}
                      importedComponents={importedComponents}
                      scriptWrapper={node ? this.props.devRuntime.getNodeScriptWrapper(component, node, 'script') : this.props.devRuntime.getScriptWrapper(component)}
                      highlightId={blockId}
                      client="inspect"
                      component={component}
                      cmdClick={(blockId, path, col) => {
                        const scriptWrapper = node ? this.props.devRuntime.getNodeScriptWrapper(component, node, 'script') : this.props.devRuntime.getScriptWrapper(component);
                        console.log(blockId, path, col);
                        const parts = path.split('.');
                        const model = buildModel(scriptWrapper.blocks(), this.props.devRuntime.__types());
                        const scope = getScope(blockId, col, model, false, getGlobalTypes(this.props.devRuntime, { component }));


                        const cst = unwrapCst(flexGrammar.match(parts[0])['_cst'], parts[0]);

                        const type = getType(cst, model, scope, {
                          globals: getGlobalTypes(this.props.devRuntime, { component }),
                        }, false)?.humanString?.() || 'any!!';

                        
                        if (type == 'Props') {
                          const instance = this.props.devRuntime.componentInstancesById[instanceId];
                          const r = execFormula(path, {
                            props: instance.props,
                          });


                          if (r?.blockId) {
                            this.props.devProjectComp.navigateToInstanceBlock(r.instanceId, r.blockId);
                            // const f = this.props.devRuntime.findBlock(r.blockId);
                            // const node = f.find(c => c instanceof Node);
                            // const el = jQuery(`[data-node-id="${node.obj._id}"][data-instance-id="${r.instanceId}"]`)[0];
                            // if (el) {
                            //   this.props.devProjectComp.navigateToEl2(el, r.blockId);
                            //   // this.props.devRuntime.selectInstance(r.instanceId);
  
                            // }
                            // console.log(r.instanceId, this.props.devRuntime.componentInstancesById[r.instanceId]);
                            // this.props.devProjectComp.navigateToBlock(r.blockId, true);

                          }
                          else {
                            console.log('no block', instance, r);
                          }
                          
                        }

                         
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="right">
                <TabBar
                  tabs={[
                    {
                      key: 'styles',
                      title: 'Styles',
                    },
                    {
                      key: 'component',
                      title: 'Component',
                    },
                    {
                      key: 'console',
                      title: 'Console',
                    },
                    {
                      key: 'events',
                      title: 'Events',
                      contextMenu: [
                        {
                          text: 'Clear',
                          onClick: () => {
                            this.props.devRuntime.state.eventEntries = X([])
                          }
                        }
                      ]
                    },
                  ]}
                  active={elementTabState.tab || 'styles'}
                  onClickTab={tab => {
                    elementTabState.tab = tab;
                  }}
                />
                <div className="content">
                  {elementTabState.tab == 'styles' && (
                    <ElementStyles
                      ref={this.stylesRef}
                      devProjectComp={this.props.devProjectComp}
                      devRuntime={this.props.devRuntime}
                      state={this.props.state}
                    />
                  )}

                  {elementTabState.tab == 'component' && (
                    <>
                      <ComponentInstance devRuntime={this.props.devRuntime} instance={instanceId} />
                    </>
                    

                  )}

                  {elementTabState.tab == 'console' && (
                    <>
                      <Console devRuntime={this.props.devRuntime} />
                    </>
                  )}


                  {elementTabState.tab == 'events' && (
                    <>
                      <Events devRuntime={this.props.devRuntime} parent={this.props.devProjectComp} />
                    </>
                  )}


                </div>
              </div>
              <div className="pathBar" data-pb-path-bar>
                {path?.map?.((node, i) => {
                  return (
                    <div
                      key={node.key}
                      style={node.styles}
                      className={cx("comp", {
                        active: node.key == editorState.activePath
                      })}
                      onClick={() => {
                        const next = path[i + 1];
                        let blockId;
                        const blockIds = next?.node?.blockIds;
                        if (blockIds) {
                          blockId = blockIds.split(',')[0]
                        }
                        this.props.devProjectComp.navigateToEl2(node.node.el, blockId);
                        const instanceId = findComponent(tree);
                        this.props.devRuntime.selectInstance(instanceId);
                      }}
                      onMouseOut={node => {
                        this.props.devProjectComp.setOverlay(null);
                      }}
                      onMouseOver={() => {
                        this.props.devProjectComp.setOverlay(node.key, true);
                      }}
                    >
                      {node.text}
                    </div>
                  )
                })}
              </div>
            </>
          )}
          {this.props.state.inspectTab == 'data' && <DBTab
            state={XObject.get(this.props.state, 'dataInspectTab', {})}
          />}
          {this.props.state.inspectTab == 'library' && <Library
            devRuntime={this.props.devRuntime}
            parent={this.props.devProjectComp}
            selectMode={true}
            state={XObject.get(this.props.state, 'dataLibraryTab', {})}
          />}
          {tabTypes[this.props.state.inspectTab] && this.props.additionalTabs.find(t => t.type == this.props.state.inspectTab) && (
            <>
              {tabTypes[this.props.state.inspectTab].render(
                this.props.additionalTabs.find(t => t.type == this.props.state.inspectTab),
                this.props.devRuntime
              )}
            </>
          )}
        </div>
      </Container>
    )
  }
}


@component
class CanvasFrame extends Component<{
  devRuntime: DevRuntime,
  frame,
  wrapperComp,
  canvasEditorFrames,
  _onMouseDown,
  i,
  isActive
  selectMode
  onUpdate
}> {

  // editorStylesWrapper

  constructor(props) {
    super(props);
    // if (!scriptEmpty(this.props.frame.editorStyles)) {
    //   // this.editorStylesWrapper = executeScriptFromData(this.props.frame.editorStyles);
    // }
  }

  static styles = styled.div`
    z-index: 0;
    > .name {
      position: absolute;
      bottom: calc(100% + 2px);
      left: 0;
      cursor: default;
      white-space: nowrap;
      ${baseStyles}
      color: #919191;
      font-weight: 500;
      &.workspaceFrame {
        .compName {
          text-decoration: underline;
          
        }
      }
    }
    &.selected {
      > .name {
        color: #0b99ff;
      }
    }

    > .overlay {
      position: absolute;
      z-index: 99999999;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    /* > .asdfasdfasdf.selectMode {
      pointer-events: none;
    } */


  `;

  tick = 0

  render(Container?) {
    // return <div />
    const { frame, wrapperComp, canvasEditorFrames, i } = this.props;

    // const Wrapper = this.editorStylesWrapper || 'div';

    const stylesClass = !scriptEmpty(this.props.frame.editorStyles) && this.props.devRuntime.getStyleClass(this.props.frame.editorStyles, {}, frame._id);


    const comp:any = frame.component && this.props.devRuntime.getComponent(frame.component);
    const Comp = comp && this.props.devRuntime.executeComponent(x(comp));

    const node = frame.node && findNodeInComponent(frame.node, comp);//comp.detachedNodes.find(n => n._id == frame.node);

    let defaultProps = {}
    if (!scriptEmpty(frame?.componentProps)) {
      defaultProps = this.props.devRuntime.executeBlocks(frame?.componentProps);
    }


    let frameName;

    if (comp) {
      if (node) {
        frameName = `${comp.name} / ${node.data || 'node'}`
      }
      else {
        const type = this.props.devRuntime.devProject.builderComponentTypes.find(t => t._id == comp.builderComponentType)
        frameName = <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
        }}
        ><span className="compName">{comp.name}</span> {type && <Tag text={type.name} />}</div>;
        
      }
    }
    else {
      frameName = 'Frame'
    }
    return (
      <Container
        key={frame._id + this.tick}
        className={cx(stylesClass, { selected: this.props.isActive })}
        data-canvas-component={frame._id}
        style={{
          position: 'absolute',
          left: x(frame).x,
          top: x(frame).y,
          // left: canvasComponents[id].x,
          // top: canvasComponents[id].y,
        }}
      >
        <div className={cx('name', {
          workspaceFrame: frame._id == comp?.workspaceFrame,
        })}
          onContextMenuCapture={e => {
            e.preventDefault();
            e.stopPropagation();
            showContextMenu(e, [
              {
                text: 'Delete',
                onClick: () => {
                  canvasEditorFrames.splice(i, 1);
                },
              },
              comp && {
                text: 'Make component frame',
                onClick: () => {
                  comp.workspaceFrame = frame._id;
                },
              },
              {
                text: 'Update',
                onClick: () => {
                  delete this.props.devRuntime.componentCache[comp._id];
                  this.props.onUpdate();
                },
              }
            ])
          }}
          onMouseDown={e => {
            this.props._onMouseDown(e);
          }}
        
        >{frameName}</div>

        <div className={cx('content', 'asdfasdfasdf', { selectMode: this.props.selectMode })}>
          {comp && (
            <>
              {wrapperComp && renderWrapperBuilderNode(this.props.devRuntime, x(wrapperComp), x(wrapperComp).root, 
                <Comp __node={node} {...defaultProps} data-pb-path={[]} />
              )}
              {!wrapperComp && <Comp __node={node} {...defaultProps} data-pb-path={[]} />}
            </>
          )}
        </div>

        {comp && this.props.selectMode && <div className="overlay" data-select-overlay/>}

        {!comp && (
          <>
            <PropertyField
              object={frame}
              property="component"
              type="selector"

              entries={this.props.devRuntime.devProject.components.map(c => ({
                _id: c._id,
                display: c.name,
                key: c._id,
              }))}

            />
          </>
        )}

        {}
      </Container>
    )

  }
}

@component
class CanvasEditor extends Component<{
  devRuntime: DevRuntime,
  state,
  selectedFrame,
  onSelectFrame,
  selectMode
 }> {
  static debounce = false;
  static styles = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    > .toolbar {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 30px;
      border-bottom: 1px solid black;
      box-sizing: border-box;

      ${baseStyles}
    }
      .react-transform-wrapper {
        position: absolute;
        user-select: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        width: auto;
        height: auto;

        background: #e5e5e5;
      }

      /* [data-canvas-component] { 
        > .name {
          position: absolute;
          bottom: 100%;
          left: 0;
          cursor: default;
          white-space: nowrap;
          ${baseStyles}
        }

        > .content {
        }
      } */
  `;


  dragging
  draggingOffset

  cleanUpFuncs = []


  translateCoords(x, y) {
    const el = ReactDOM.findDOMNode(this);

    const asdfads = jQuery(el).offset();
    const editorState = this.props.state;

    return [(x - editorState.x - asdfads.left)/editorState.scale,
    (y - editorState.y - asdfads.top)/editorState.scale];

  }

  frameRefs = {}

  componentDidMount() {
    const editorState = this.props.state;
    editorState.scale = 1;

    const el = ReactDOM.findDOMNode(this);

    const asdfads = jQuery(el).offset();

    const canvasEditorFrames = XObject.get(this.props.state, 'frames', []);



    jQuery(window).mousemove(e => {
      if (this.dragging) {
        const frame = canvasEditorFrames.find(f => f._id == this.dragging);
        if (frame) {
          frame.x = (e.clientX - editorState.x - asdfads.left)/editorState.scale - this.draggingOffset.x;
          frame.y = (e.clientY - editorState.y - asdfads.top)/editorState.scale - this.draggingOffset.y;

          const el:any = ReactDOM.findDOMNode(this.frameRefs[frame._id].current);
  
          el.style.left = frame.x + 'px';
          el.style.top = frame.y + 'px';
        }
      }
    });

    jQuery(window).mouseup(() => {
      delete this.dragging;
    })

    const rootEl = ReactDOM.findDOMNode(this) as Element;

    const update = () => {
      const componentEls = rootEl.querySelectorAll('[data-canvas-component]');
      for (const componentEl of componentEls) {
        if (componentEl.firstChild?.nextSibling?.firstChild) {
          generateAppTree(this.props.devRuntime, componentEl.firstChild.nextSibling.firstChild, [`frame:${componentEl.getAttribute('data-canvas-component')}`]);
        }
      }  
    }

    update();

    const obs = new MutationObserver(() => {
      update();
    });
    obs.observe(document.querySelector('.renderedOutput'), { childList: true, subtree: true }); 

    this.cleanUpFuncs.push(() => {
      obs.disconnect();      
    });

  }

  componentWillUnmount(): void {
    for (const func of this.cleanUpFuncs) {
      func();
    }
  }

  frameTicks = {}

  render(Container?) {
    // return <div />
    const devProject = this.props.devRuntime.devProject;
    // const canvasComponents = XObject.get(devProject, 'canvasComponents', {});
    const editorState = this.props.state;
    const disabled = !this.props.selectMode;

    const canvasEditorFrames = XObject.get(editorState, 'frames', []);

    const canvasEditorState: {
      selectedFrame
    } = editorState;

    let wrapperComp;

    if (devProject.wrapperComponent) {
      wrapperComp = this.props.devRuntime.getComponent(devProject.wrapperComponent);
    }

    const selectedFrame = canvasEditorFrames.find(f => canvasEditorState.selectedFrame == f._id);

    const components = devProject.components;


    return (
      <Container
        data-offset-cont
        onContextMenu={e => {
          e.preventDefault();
          showContextMenu(e, [
            {
              text: 'Add frame',
              onClick: () => {
                const [x, y]=this.translateCoords(e.clientX, e.clientY);
                canvasEditorFrames.push(XObject.obj({
                  x,
                  y,
                  width: 100,
                  height: 100,
                }));
              },
            },

            {
              text: 'Add component',
              onClick: () => {
                const [ x, y ] = this.translateCoords(e.clientX, e.clientY);
                const comp = this.props.devRuntime.createBuilderComponent(prompt('Component name') || 'Untitled');
                const frame = XObject.obj({
                  x,
                  y,
                  width: 100,
                  height: 100,
                  component: comp._id,
                });
                canvasEditorFrames.push(frame);
                comp.workspaceFrame = frame._id;
              }
            },

            {
              text: 'Reset viewport',
              onClick: () => {
                editorState.x = 0;
                editorState.y = 0;
                editorState.scale = 1;
                this.forceUpdate();
              }
            }
          ])
        }}
      >
        <TransformWrapper
          minScale={0.01}
          limitToBounds={false}

          initialScale={x(editorState).scale}
          initialPositionX={x(editorState).x}
          initialPositionY={x(editorState).y}
          disabled={disabled}

          panning={{
            disabled: disabled,
          }}
          wheel={{
            step: 0.9,
            smoothStep: 0.01,
            wheelDisabled: true,
          }}

          pinch={{ step: 100 }}

          doubleClick={{
            disabled: true
          }}
          onTransformed={(el, state) => {
            editorState.scale = state.scale;
            editorState.x = state.positionX;
            editorState.y = state.positionY;
          }}
        >
          <TransformComponent>
            {canvasEditorFrames.map((frame, i) => {
              if (!this.frameRefs[frame._id]) {
                this.frameRefs[frame._id] = React.createRef();
              }
              return (
                <CanvasFrame
                  ref={this.frameRefs[frame._id]}
                  onUpdate={() => {
                    if (!this.frameTicks[frame._id]) {
                      this.frameTicks[frame._id] = 0;
                    }
                    this.frameTicks[frame._id]++;
                    this.forceUpdate();
                    // this.props.devRuntime.componentCache[]
                  }}
                  selectMode={this.props.selectMode}
                  key={frame._id+this.frameTicks[frame._id]}
                  canvasEditorFrames={canvasEditorFrames}
                  devRuntime={this.props.devRuntime}
                  frame={frame}
                  i={i}
                  wrapperComp={wrapperComp}
                  isActive={this.props.state.selectedFrame == frame._id}
                  _onMouseDown={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.dragging = frame._id;
                    this.props.onSelectFrame(frame._id);
                    const [x, y] = this.translateCoords(e.clientX, e.clientY);
                    this.draggingOffset = {
                      x: x - frame.x,
                      y: y - frame.y,
                    }
                  }}
                />
              )
            })}
          </TransformComponent>
        </TransformWrapper>
      </Container>
    )
  }
}

@component
class StylesList extends Component<{ devRuntime: DevRuntime, activeStyle, onSelectStyle }> {
  static styles = styled.div`
    .active {
      font-weight: bold;
    }
  `;
  render() {
    const { devRuntime } = this.props;
    const { devProject } = devRuntime;
    const styles = XObject.get(devProject, 'styles', []);
    return (
      <>
        <ul>
          {styles.map(style => {
            return (
              <li key={style._id}
                className={cx({
                  active: this.props.activeStyle == style._id
                })}
                onClick={() => {
                  this.props.onSelectStyle(style._id);
                }}
              >
                <PropertyField object={style} property="name" dblClickEdit />
              </li>
            )
          })}
        </ul>
      </>
    )
  }
}

@component
class StyleEditor extends Component<{ devRuntime: DevRuntime, style }> {

  scriptWrapper

  state = XInit(class {
    state = {}
  })
  
  constructor(props) {
    super(props);
    const changed = () => {
      this.props.devRuntime.changed.styles[this.props.style._id] = true;
    }
    this.scriptWrapper = createScriptWrapper({
      blocks: () => XObject.get(this.props.style, 'stylesheet', []),
      setBlocks: b => {
        this.props.style.stylesheet = b;
        changed();
      },
      onChange: (id) => {
        this.props.devRuntime.changedBlock(id);
        changed();
      },
    });
  }

  render() {
    return (
      <>
        <DevProjectScriptEditor
          devRuntime={this.props.devRuntime}
          scriptWrapper={this.scriptWrapper}
          state={this.state.state}
          importedComponents={[]}
        />
      </>
    )
  }
}

@component
class ComponentSidebar extends Component {
  render() {
    return (
      <>
      
      </>
    )
  }
}

@component
class Workspace extends Component<{ devRuntime: DevRuntime, parent: DevProject, selectMode }> {
  static styles = styled.div`
    ${baseStyles}

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    > .left {
      position: absolute;
      top: 0;
      bottom: 0;
      /* border-right: 1px solid black; */
      width: 300px;
      overflow: auto;

      ${sectionStyles}

      .section .header {
        .right {
          margin-left: auto;



        }
      }

      .add {
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: #f3f3f3;
        }
        cursor: pointer;
        svg {
          width: 10px;
        }

      }
    }

    > .right {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 300px;
    }

    #layers {

    }
  `;
  static debounce = true;
  state = XInit(class {
    editingPages
    treeState = {}
  })
  render() {
    const { devRuntime } = this.props;
    const devProject = devRuntime.devProject;
    const editorState = getDevProjectEditorState(devProject);


    const activeWorkspace = findWorkspace(editorState.activeWorkspace, devProject.workspaceStructure)


    const makeTree = pages => {
      if (!pages) return [];
      return pages.map((page, i) => {
        return {
          key: page._id,
          elId: page._id,
          text: page.data.toString(),
          children: makeTree(page.children),
          contextMenu: [
            {
              text: 'Add page',
              onClick: () => {
                XObject.push(page, 'children', XObject.obj({ data: 'Untitled'}))
              },
            },
            {
              text: 'Delete',
              onClick: () => {
                pages.splice(i, 1);
              }
            },
            {
              text: 'Rename',
              onClick: () => {
                page.data = prompt('Rename page');
              },
            }
          ]
        }
      })
    }

    return (
      <>
        <div className="left">
          <div className="section">
            <span className="header"
            
              onContextMenu={e => {
                e.preventDefault();
                showContextMenu(e, [
                  {
                    text: 'Edit',
                    onClick: () => {
                      this.state.editingPages = true;
                    },
                  }
                ])
              }}
            >Pages

            <div className="right">
            <span
            className="add"
            onClick={() => {
              devProject.workspaceStructure.push(XObject.obj({
                data: 'Untitled',
              }))
            }}
          ><Svg name="plus" /></span>

            </div>
            
              {/* {this.state.editingPages && <button
                onClick={() => {
                  this.state.editingPages = false;
                }}
              >Done</button>} */}
            </span>
            <div className="content">
              {this.state.editingPages && <NotionDocument2
                blockManager={new MyBlockManager(
                  () => XObject.get(devProject, 'workspaceStructure', [XObject.obj()]),
                  blks => devProject.workspaceStructure = blks,
                  {
                    plainText: true,
                  }
                )}
                renderBlock={(a, b, c) => {
                  return renderBlock(
                    a,
                    {
                      depth: 0,
                      ...(b || {}),
                    },
                    {
                      ...c,
                      meta: block => (
                        <button
                          onClick={() => {
                            editorState.activeWorkspace = block._id;
                          }}
                        >{editorState.activeWorkspace == block._id && '.'}</button>
                      )
                    }
                  );
                }}
                types={{}}
              />}

              {!this.state.editingPages && (
                <>
                  <Tree
                    nodes={makeTree(devProject.workspaceStructure)}
                    state={this.state.treeState}
                    onClickNode={id => {
                      editorState.activeWorkspace = id;
                    }}
                    defaultOpen
                    active={editorState.activeWorkspace}
                  />
                </>
              )}
            </div>
          </div>

        </div>
        <div className="right" id="protobuilder-output-area">
          {activeWorkspace && (
            <>
                      <CanvasEditor
            selectMode={this.props.selectMode}
            devRuntime={devRuntime}
            onSelectFrame={id => {
              activeWorkspace.selectedFrame = id;
              const frame = activeWorkspace.frames.find(f => f._id == id);
              if (frame.component) {
                this.props.parent.navigateToComponent(frame.component, undefined, id);
              }
            }}
            selectedFrame={() => {
              return activeWorkspace.selectedFrame;
            }}
            state={activeWorkspace}
          />
            </>
          )}

          <div id="layers" />

        </div>
      </>
    )
  }
}

@component
class LibraryThing extends Component {
  /*render() {
    return (
      <>
                <div className="bottom">
            <div className="bottomTop">
              <TabBar
                active={editorState.tab}
                onClickTab={t => editorState.tab = t}
                tabs={[
                  { key: 'components', title: 'Components' },
                  { key: 'assets', title: 'Assets' },
                  { key: 'colors', title: 'Colors' },
                  { key: 'styles', title: 'Styles' },
                ]}
              />
              <span
                className="add"
                onClick={e => {
                  if (editorState.tab == 'components') {
                    showContextMenu(e, [
                      {
                        text: 'Script Component',
                        onClick: () => {
                          devProject.components.push(XObject.obj({
                            name: 'Untitled',
                          }));
                        },
                      },
                      {
                        text: 'Builder Component',
                        onClick: () => {
                          devProject.components.push(XObject.obj({
                            type: 'builderComponent',
                            name: 'Untitled',
                            root: XObject.obj({
                              type: 'el',
                            }),
                          }));
                        },
                      }
                    ]);
                  }
                  else if (editorState.tab == 'colors') {
                    colors.push(XObject.obj({

                    }))
                  }
                  else if (editorState.tab == 'assets') {

                  }
                  else if (editorState.tab == 'styles') {
                    styles.push(XObject.obj({
                      name: 'Untitled',
                    }));
                  }
                }}
              >
                <Svg name="plus" />
              </span>
            </div>
            <div className="bottomContent">
            {editorState.tab == 'assets' && <Assets devProject={this.devProject} />}
            {editorState.tab == 'components' && (
              <ComponentsTree
                root={devProject.rootComponent}
                wrapper={devProject.wrapperComponent}
                setRoot={id => devProject.rootComponent = id}
                setWrapper={id => devProject.wrapperComponent = id}
                devProject={devProject}
                onNavigate={id => {
                  const comp = this.devRuntime.getComponent(id);
                  if (comp.type == 'el') {
                    this.setComponentState({ id, nodeState: { id: comp.root._id, styleNodeState: {} } });
                  }
                  else {
                    this.setComponentState({ id });
                  }
                }}
                state={editorState}
              />
            )}
            {editorState.tab == 'colors' && <Colors devProject={this.devProject} />}
            {editorState.tab == 'styles' && <StylesList
              devRuntime={this.devRuntime}
              activeStyle={editorState.styleState?.id}
              onSelectStyle={id => {
                this.setStyleState({ id })
              }}
            />}
            </div>
          </div>
      </>
    )
  }*/
}

@component
class Right extends Component<{ devRuntime: DevRuntime, parent: DevProject }> {
  static styles = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    ${baseStyles}

    > .tabs {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 19px;
      display: flex;
      border-bottom: 1px solid #e3e3e3;
      background-color: #f3f3f3;


      .tabsScroll {
        overflow: auto;
        display: flex;
        &::-webkit-scrollbar {
          display: none;
        }

      }
      .tab {
        white-space: nowrap;
        display: flex;
        align-items: center;
        padding: 0 4px;
        &:hover {
          background-color: #f3f3f3;
        }

        cursor: pointer;
        &.active {
          background-color: #e3e3e3;
        }
      }

      .add {
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: #f3f3f3;
        }
        cursor: pointer;
        svg {
          width: 10px;
        }
      }
    }

    > .content {
      position: absolute;
      top: 20px;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: auto;
      padding-bottom: 30px;
    }
  `;
  state = X({});
  render(Container?) {
    const { devRuntime, parent } = this.props;
    const { devProject } = devRuntime;
    const editorState = getDevProjectEditorState(devProject);
    const activeComponent = devProject.components.find(c => c._id == getActiveEditorState(devProject)?.id);
    const activeTab = editorState.editorTabs.find(t => t._id == editorState.currentEditorTab);
    return (
      <Container id="rightPane">
        <div className="tabs">
          <div className="tabsScroll">
          {editorState.editorTabs.map((tab, i) => {
            const comp = tab.state?.id && this.props.devRuntime.getComponent(tab.state.id);

            let text;

            if (comp) {
              text = comp.name;
            }
            else if (tab.type == 'style') {
              text = devProject.styles.find(s => s._id == tab.id)?.name;
            }
            else {
              text = 'Tab';
            }
            return (
              <div className={cx("tab", { active: editorState.currentEditorTab == tab._id })} key={tab._id}
              onContextMenu={e => {
                e.preventDefault();
                showContextMenu(e, [
                  {
                    text: 'Close',
                    onClick: () => {
                      editorState.editorTabs.splice(i, 1);
                    }
                  },
                  {
                    text: 'Close All',
                    onClick: () => {
                      editorState.editorTabs = X([]);
                    }
                  },
                  {
                    text: 'Debug',
                    onClick: () => {
                      console.log(x(tab));
                    }
                  }
                ])
              }}
                onClick={() => {
                  editorState.currentEditorTab = tab._id;
                }}
              >
                {text}
              </div>
            )
          })}
          </div>
          <span
            className="add"
            onClick={() => {
              editorState.editorTabs.push(XObject.obj())
            }}
          ><Svg name="plus" /></span>
        </div>
        <div
          className="content"
          key={activeComponent?._id}
          ref={el => {
            if (el) {
              el.scrollTop = activeTab.scrollTop;
            }
          }}
          onScroll={e => {
            activeTab.scrollTop = (e.target as any).scrollTop;
          }}
        >
          {activeTab.type == 'style' && (
            <StyleEditor devRuntime={this.props.devRuntime} style={devProject.styles.find(s => s._id == activeTab.id)} />
          )}
          {!activeTab.type && (
            <>
              {!activeComponent && (
                <>
                  <Selector
                    entries={this.props.devRuntime._components().map(c => {
                      const type = this.props.devRuntime.devProject.builderComponentTypes.find(t => c.builderComponentType == t._id);
                      return {
                        key: c._id,
                        display: <ComponentEntry comp={c} devRuntime={this.props.devRuntime} />,
                        filter: c.name,
                      }
                    })}
                    onSelected={id => {
                      activeTab.state = {
                        id,
                        nodeState: {},
                      }
                    }}
                  />
                </>
              )}
              {activeComponent && (
                <>
                  {activeComponent.type == 'builderComponent' && (
                    <BuilderComponentEditor
                      nodeDesignerChanged={() => {
                        parent.inspectRef.current?.updateStyles?.();
                        parent.hideSelect();
                      }}
                      devRuntime={devRuntime}
                      builderComponent={activeComponent}
                      devProject={devProject}
                      selectedNode={getActiveEditorState(devProject).nodeState?.id}
                      state={getActiveEditorState(devProject).nodeState}
                      frame={getActiveEditorState(devProject).frame}
                      parent={this.props.parent}
                    />
                  )}
                  {!activeComponent.type && (
                    <Entry
                      nodeDesignerChanged={() => {
                        throw new Error();
                      }}
                      active={null}
                      comp={activeComponent}
                      components={activeComponent.imported?.map?.(id => this.props.devRuntime.getComponent(id))}
                      devProject={devProject}
                      devRuntime={devRuntime}
                      i={0}
                      st={this.state}
                    />
                  )}
                </> 
              )}
            </>
          )}
        </div>
      </Container>
    )
  }
}


const rightWidth = 400;

@component
class DropdownSelector extends Component<{ options: {text, value}[], value, onValueChange }> {
  static styles = styled.div`
    padding: 0 8px;
    display: flex;
    align-items: center;
    height: 100%;
    cursor: default;


    &:hover {
      background-color: #111111;
    }
    &.active {
      background-color: #111111;

    }
    svg {
      width: 10px;
      path {
        fill: white;

      }
      margin-left: 6px;
    }
  `;
  render(Container?) {
    const selected = this.props.options.find(o => o.value == this.props.value);
    return (
      <Container
        onClick={async e => {
          const el = e.currentTarget;
          el.classList.add('active');
          await showContextMenu(e, this.props.options.map(o => ({
            text: o.text,
            onClick: () => {
              this.props.onValueChange(o.value);
            },
            active: o.value == this.props.value,
          })), {
            alignY: 2,
            alignX: -1,
          })

          el.classList.remove('active');

        }}
      >
        {selected?.text}
        <Svg name="chevron" />
      </Container>
    )
  }
}

@component
class InstanceSelector extends Component<{ devRuntime: DevRuntime }> {
  static styles = styled.div`
    display: flex;
    /* gap: 4px; */
    height: 100%;
    align-items: center;

    .dropdown {

    }

    .switch {
      height: 20px;
      width: 24px;
      display: flex;
      border-radius: 17px;
      align-items: center;
      padding: 0 3px;
      background-color: #4f4f4f;
      cursor: pointer;
      position: relative;
      transition: background-color 100ms linear;


      .circle {
        width: 15px;
        height: 15px;
        /* aspect-ratio: 1/1; */
        border-radius: 50%;
        background-color: white;
        position: absolute;
        left: 3px;
        top: 0;
        bottom: 0;
        margin: auto;
        transition: left 100ms linear;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 12px;
          height: 12px;
        }
      }

      &.on {
        background-color: #0b99ff;
        .circle {
          left: 12px;
        }
      }
    }
  `;
  render() {

    return (
      <>
        <DropdownSelector
          options={[
            {
              text: 'Not Attached',
              value: null,
            }
          ].concat(this.props.devRuntime.getComponentInstances().map(inst => {
            const comp = this.props.devRuntime.getComponent(inst.componentId);

            return {
              text: `${comp.name} #${inst.instanceNumber}`,
              value: inst._pathStr(),
            }
          }))}

          value={(this.props.devRuntime.ticks.activeInstance, this.props.devRuntime.getActiveInstance())}
          onValueChange={v => {
            this.props.devRuntime.selectInstance(v);
          }}
        />

          <div
            className={cx('tool', { active: this.props.devRuntime.isDebuggingEnabled() })}
            title="Select mode"
            onClick={() => {
              this.props.devRuntime.resetDebug();
              this.props.devRuntime.setDebuggingEnabled(!this.props.devRuntime.isDebuggingEnabled());

              if (this.props.devRuntime.isDebuggingEnabled()) {
                this.props.devRuntime.refreshCallStack();
              }

              

            }}
          >
            <Svg name="icons8-inspect-code" />
          </div>
      </>
    )
  }
}

@component
export class DevProject extends Component<{ id }> {
  state = XInit(class {
    activePath
    tree

    path

    layers = X([

    ])

  })

  devRuntime: DevRuntime

  devProject: DevProjectType

  // navStack = [];
  // navPointer = 0;

  navState = X({
    navPointer: 0,
    navStack: [],
  })

  observer
  constructor(props) {
    super(props);
    const id = this.props.id;
    this.devProject = getDevProject(id);

    XObject.get(this.devProject, 'builderComponentTypes', []);
    XObject.get(this.devProject, 'workspaceStructure', []);

    const editorState = getDevProjectEditorState(this.devProject);


    


    initDevProjectApp(this.devProject.db);

    XObject.observe(this.devProject.db.entities, this.observer = Object.assign(mutation => {
      if (mutation.pass instanceof Thingy && mutation.pass.trace) {
        mutation.pass.trace.captureMutation(mutation.pass.block, mutation.pass.runtimeContext, mutation, 'db');
      }
    }, { immediate: true }));


    this.navState.navStack = [this.devProject.editorState.url];
    this.navState.navPointer = 0;


    this.devRuntime = new DevRuntime(this.devProject, {
      push: location => {
        this.devProject.editorState.url = location;
        this.navState.navStack = this.navState.navStack.slice(0, this.navState.navPointer + 1).concat(location);
        this.navState.navPointer = this.navState.navStack.length - 1;
      },

      location: () => this.devProject.editorState.url,
    }, undefined, {
      navigateToBlock: id => this.navigateToBlock(id),
      navigateToComponent: id => {
        
        const editorState = getDevProjectEditorState(this.devProject);

        const comp = this.devRuntime.getComponent(id);
        getComponentEditorState(comp).selectedNode = null;

        const tab = editorState.editorTabs.find(t => t.state?.id == id);
        if (tab) {
          editorState.currentEditorTab = tab._id;
        }
        else {
          const newTab = XObject.obj({
            state: {
              id,
            }
          })

          console.log(x(newTab));
  
          editorState.editorTabs.push(newTab);
          editorState.currentEditorTab = newTab._id;
        }


      },
      presentLayer: args => {
        const layer = {
          _id: XObject.id(),
        };
        for (const x of args) {
          layer[x[0]] = x[1];
        }

        const layersCont = document.querySelector('#layers');

        const newEl = document.createElement('div');
        layersCont.appendChild(newEl);

        ReactDOM.render(<Layer key={layer._id} devRuntime={this.devRuntime} layer={layer} args={args} />, newEl);


        const update = () => {
          generateAppTree(this.devRuntime, newEl.firstChild.firstChild.firstChild, [`layer:${layer._id}`], true);
        }
        update();
        const obs = new MutationObserver(() => {
          update();
        });
        obs.observe(newEl.firstChild.firstChild.firstChild, { childList: true, subtree: true }); 


        return {
          remove: Object.assign(() => {
            obs.disconnect();
            ReactDOM.unmountComponentAtNode(newEl);
            layersCont.removeChild(newEl);
          }, { _fromScript: true, _functionName: 'layer.remove' }),
        }
      },
    });

    if (_.isNil(editorState.debuggingEnabled)) {
      this.devRuntime.setDebuggingEnabled(true);
    }
  }

  screenshotInterval

  static styles = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    > .topBar {
      ${baseStyles}
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: ${topBarHeight}px;
      border-bottom: 1px solid #c9c9c9;
      box-sizing: border-box;
      background-color: #2c2c2c;

      display: flex;
      align-items: center;
      padding: 0 8px;
      .logo {
        display: flex;
        margin-right: 8px;
        svg {
          width: 26px;
          height: 26px;
          rect {
            fill: white;
          }
          path {
            fill: #2c2c2c;
          }
          rect {
            fill: #b9b9b9;
          }
          path {
            fill: #2c2c2c;
          }
        }
      }

      input[type="text"].name {
        position: absolute;
        width: 100px;
        text-align: center;
        left: 0;
        right: 0;
        margin: auto;
        background-color: transparent;
        color: white;
        border: none;
      }

      .right {
        margin-left: auto;
        display: flex;
        color: white;
        height: 100%;

        align-items: center;


        gap: 12px;
        .debugger {
          display: flex;
          align-items: center;
          height: 100%;
        }






      }

      .tool {
        padding: 0 8px;
        display: flex;
        align-items: center;
        height: 100%;
        &:hover {
          background-color: #111111;
        }
        &.active {
          background-color: #0b99ff;
        }

        svg {
          width: 17px;
          height: 17px;
        }
        svg path {
          fill: white;
        }
      }
    }


    > .left {
      ${baseStyles}
      position: absolute;
      top: ${topBarHeight}px;
      bottom: 0;
      left: 0;
      width: 200px;
      border-right: 1px solid #c9c9c9;
      box-sizing: border-box;

      z-index: 9999;
      background: white;

      > .top {
        > .componentPanel {
          > .componentHeader {
            position: sticky;
            top: 0;
            display: flex;
            height: 24px;
            display: flex;
            font-weight: 600;
            align-items: center;
            background-color: #f4f4f4;
            padding: 0 4px;
            ${Related} {
              margin-left: auto;
            }
          }

          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 100%;
          overflow: auto;
        }
        position: absolute;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        overflow: auto;
        box-sizing: border-box;
      }
    }

    &:not(.designer) {
      > .right {
        position: absolute;
        top: ${topBarHeight}px;
        bottom: 0;
        left: 200px;
        right: 0;
      }
    }

    &.designer {
      > .middle {
        position: absolute;
        top: ${topBarHeight}px;
        bottom: 0;
        left: calc(200px + 350px);
        right: 0;

        > .outputArea {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 300px;
          > ${DevProjectOutput} {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
          }

          > .overlay {
            position: absolute;
            left: 0;
            top: 34px;
            right: 0;
            bottom: 0;
            z-index: 99999;
          }

          &.canvasEditorMode {
            > .overlay {
              top: 30px;
            }
          }

        }

        ${BottomPane} {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 300px;
          /* box-shadow: inset 0 0 3px #00000036; */
          border-top: 1px solid #cfcfcf;
        }

      
        > .bottomBar {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 24px;
          box-sizing: border-box;
          border-top: 1px solid #c9c9c9;
          background-color: #ededed;

          display: flex;
          box-shadow: inset 0 0 3px #00000036;


        }
      }

      > .right {
        ${baseStyles}
        position: absolute;
        top: ${topBarHeight}px;
        left: 200px;
        bottom: 0;
        width: 350px;
        /* border-right: 2px solid #c9c9c9; */
        box-sizing: border-box;
        overflow: auto;
        z-index: 99;

        box-shadow: 0 0 5px #00000078;
      }

      [data-node-script] [data-identifier-category="tag"] {
        opacity: .5;
      }




    }

    > .left {
      right: 350px;
      left: auto;
      box-shadow: 0 0 5px #00000078;
    }

    &.designer > .right {
      right: 0;
      left: auto;
      z-index: 999999;
      box-shadow: none;
      background-color: white;

    }

    &.designer > .middle {
      left: 0;
      right: ${rightWidth}px;
    }

    > .rightBar {
      position: absolute;
      top: 40px;
      right: 0;
      bottom: 0;
      width: ${rightWidth}px;
      z-index: 9999;
      border-left: 1px solid #cfcfcf;
      /* box-shadow: 0 0 5px #00000033; */
    }
  `;

  bottomBarRef = React.createRef<BottomBar>();

  navigateToBlock(id, bottom?) {
    let found = this.devRuntime.findBlock(id);
    found ||= [];

    console.log('navigate to block', found)


    const comp = found.find(el => el instanceof CComponent);
    const node = found.find(el => el instanceof Node);
    const block = found.find(el => el instanceof Block);
    const editorState = getDevProjectEditorState(this.devProject);

    if (bottom) {
      if (comp && block && node) {
        editorState.activeBlockPath = `${comp.obj._id}/${node.obj._id}/${block.obj._id}`
      }
      else if (comp && block) {
        editorState.activeBlockPath = `${comp.obj._id}/${block.obj._id}`
      }
    }
    else {
      let componentState: any = {};


      if (comp) {
        editorState.activeComponent = comp.obj._id;
  
        // const frame = jQuery(el).parents('[data-canvas-component]');
  
  
        componentState = {
          id: comp.obj._id,
          // frame: frame.attr('data-canvas-component'),
          nodeState: comp.obj.root && {
            id: comp.obj.root._id,
            styleNodeState: {},
          }
        }
  
      }
  
      if (node) {
        getComponentEditorState(comp.obj).selectedNode = node.obj._id;
        componentState.nodeState = {
          id: node.obj._id,
          styleNodeState: {},
        }
      }
  
      this.setComponentState(componentState);
  
      setTimeout(() => {
        const blockEl = jQuery(`#rightPane [data-block-id="${block.obj._id}"]`)[0];
  
        blockEl?.scrollIntoViewIfNeeded?.();
  
        jQuery(blockEl).css({
          backgroundColor: '#0b99ff24',
        })
  
        setTimeout(() => {
          jQuery(blockEl).css({
            backgroundColor: 'transparent',
            transition: 'background-color 1000ms linear',
          }, 400);
        }, 1000);
      }, 500);
  
    }
  }


  navigateToComponent(id, rootNode?, frame?) {
    const editorState = getDevProjectEditorState(this.devProject);
    const comp = this.devProject.components.find(c => c._id == id);

    let componentState: any = {
      id: comp._id,
      rootNode,
      frame,
      nodeState: {
        styleNodeState: {},
      }
    };

    this.setComponentState(componentState);
  }

  setComponentState(cs) {
    const editorState = getDevProjectEditorState(this.devProject);
    setActiveEditorState(this.devProject, cs);
    editorState.styleState = null;
  }

  setStyleState(state) {
    const editorState = getDevProjectEditorState(this.devProject);
    editorState.styleState = state;
    setActiveEditorState(this.devProject, null);
  }


  navigateToNode(id) {
    let found = this.devRuntime.findNode(id);
    found ||= [];

    const comp = found.find(el => el instanceof CComponent);
    const node = found.find(el => el instanceof Node);
    const editorState = getDevProjectEditorState(this.devProject);

    if (comp) {
      editorState.activeComponent = comp.obj._id;
    }

    if (node) {
      getComponentEditorState(comp.obj).selectedNode = node.obj._id;

      this.overlayManager_selectedNode.setOverlays([
        {
          selector: `[data-node-id="${id}"]`,
          styles: {
            outline: '1px solid #4bd4825f',
          },
        }
      ], -1);
    }

    // this.overlayManager_selectedBlock.setOverlays([
    //   {
    //     selector: `[data-node-id="${node.obj._id}"]`,
    //     styles: {
    //       outline: '1px solid #22a0fe61',
    //     },
    //   }
    // ], -1);
  }


  navigateSidebarTo(found, moveToBlock?) {
    const editorState = getDevProjectEditorState(this.devProject);
    const comp = found.find(el => el instanceof CComponent);
    const node = found.find(el => el instanceof Node);
    const block = found.find(el => el instanceof Block);
    const styleNode = found.find(el => el instanceof StyleNode);
    const styleNodeProp = found.find(el => el instanceof StyleNodeProperty);

    if (styleNode) {
      window['g_selectedStyleNode'] = styleNode.obj._id;
    }

    // if (comp && node && block) {
    //   editorState.activeBlockPath = `${comp.obj._id}/${node.obj._id}/${block.obj._id}`;
    // }
    // else {
    //   console.log('oops');
    // }

    let componentState:any = {};

    if (comp) {
      editorState.activeComponent = comp.obj._id;

      componentState = {
        id: comp.obj._id,
        nodeState: {
          id: this.devRuntime.getComponent(comp.obj._id).root._id,
          styleNodeState: {},
        }
      }
    }

    if (node) {
      getComponentEditorState(comp.obj).selectedNode = node.obj._id;
      componentState.nodeState = {
        id: node.obj._id,
        styleNodeState: {},
      }

      this.overlayManager_selectedNode.setOverlays([
        {
          selector: `[data-node-id="${node.obj._id}"]`,
          styles: {
            outline: '1px solid #4bd4825f',
          },
        }
      ], -1);
    }

    if (styleNode) {
      componentState.nodeState.styleNodeState = {
        id: styleNode.obj._id,
      }
    }

    if (styleNodeProp) {
      componentState.nodeState.styleNodeState.prop = styleNodeProp.property;
    }

    if (block) {
      componentState.nodeState.block = block.obj._id;
    }

    this.setComponentState(componentState);

    if (block && moveToBlock) {
      setTimeout(() => {
        if (node.obj.script[node.obj.script.length - 1]?._id == block.obj._id) {
          return;
        }

        const blockEl = jQuery(ReactDOM.findDOMNode(this.builderComponentEditorRef.current)).find(`[data-block-id="${block.obj._id}"]`)[0];

        blockEl?.scrollIntoViewIfNeeded?.();

        jQuery(blockEl).css({
          backgroundColor: '#0b99ff87',
        })

        setTimeout(() => {
          jQuery(blockEl).css({
            backgroundColor: 'transparent',
            transition: 'background-color 1000ms linear',
          }, 400);
        }, 1000);
      }, 500);
    }
  }

  navigateToEl(el, blockId?, updatePath=false) {


    const editorState = getDevProjectEditorState(this.devProject);

    let binding;
    let found;
    if (el.getAttribute('data-script-block-id')) {
      binding = new BlockBinding(el.getAttribute('data-script-block-id'), el);
      found = this.devRuntime.findBlock(binding.id);
    }
    else if (el.getAttribute('data-node-id')) {
      binding = new NodeBinding(el.getAttribute('data-node-id'), el);
      found = this.devRuntime.findNode(binding.id);
    }
    else {
      console.log('hwat');
    }

    found ||= [];

    const comp = found.find(el => el instanceof CComponent);
    const node = found.find(el => el instanceof Node);
    const block = found.find(el => el instanceof Block);

    const _blockId = blockId || block?.obj?._id;


    if (comp && node && _blockId) {
      editorState.activeBlockPath = `${comp.obj._id}/${node.obj._id}/${_blockId}`;
    }
    else if (comp && node) {
      editorState.activeBlockPath = `${comp.obj._id}/${node.obj._id}/null`;
    }
    else {
      console.log('oops', found);
    }

    let componentState: any = {};

    if (comp) {
      editorState.activeComponent = comp.obj._id;

      const frame = jQuery(el).parents('[data-canvas-component]');


      componentState = {
        id: comp.obj._id,
        frame: frame.attr('data-canvas-component'),
        nodeState: comp.obj?.root && {
          id: comp.obj.root._id,
          styleNodeState: {},
        }
      }

    }

    if (node) {
      getComponentEditorState(comp.obj).selectedNode = node.obj._id;
      componentState.nodeState = {
        id: node.obj._id,
        styleNodeState: {},
      }
    }

    this.setComponentState(componentState);

    /*if (block) {
      setTimeout(() => {
        if (node.obj.script[node.obj.script.length - 1]?._id == block.obj._id) {
          return;
        }
        

        const blockEl = jQuery(`[data-block-id="${block.obj._id}"]`)[0];
        console.log(blockEl);


        blockEl?.scrollIntoViewIfNeeded?.();

        jQuery(blockEl).css({
          backgroundColor: '#0b99ff87',
        })

        setTimeout(() => {
          jQuery(blockEl).css({
            backgroundColor: 'transparent',
            transition: 'background-color 1000ms linear',
          }, 400);
        }, 1000);

      }, 500);
    }*/

    if (updatePath) {
      this.setPath(el.getAttribute('data-path'));
    }
  }

  navigateToInstanceBlock(instanceId, blockId) {
    const found = this.devRuntime.findBlock(blockId);
    const node = found.find(c => c instanceof Node);
    if (node) {
      const el = jQuery(`[data-node-id="${node.obj._id}"][data-instance-id="${instanceId}"]`)[0];
      if (el) {
        this.asdfasdf(el);
        this.navigateToEl2(el, blockId, true);
      }
      else {
        const path = this.devRuntime.instanceIdToPath[instanceId];
        const inst = this.devRuntime.componentInstancesByPath[path];
        const el = inst && jQuery(`[data-node-id="${node.obj._id}"][data-instance-id="${inst.instanceId}"]`)[0];
  
        if (el) {
        this.asdfasdf(el);
  
          this.navigateToEl2(el, blockId, true);
  
        }
        else {
          const comp: CComponent = found.find(el => el instanceof CComponent);
          const node: Node = found.find(el => el instanceof Node);
          const block: Block = found.find(el => el instanceof Block);
    
          const editorState = getDevProjectEditorState(this.devProject);
    
          if (comp && block && node) {
            editorState.activeBlockPath = `${comp.obj._id}/${node.obj._id}/${block.obj._id}`
          }
          else if (comp && block) {
            editorState.activeBlockPath = `${comp.obj._id}/${block.obj._id}`
          }
    
          this.state.activePath = null;
          editorState.activePath = null;
          this.setPath();
    
        }
      }
    }
    else {
      this.navigateToBlock(blockId);
      this.devRuntime.selectInstance(instanceId)
    }


  }

  styleManager = new StyleManager();
  styleManager2 = new StyleManager();
  styleManager3 = new StyleManager();
  styleManager4 = new StyleManager();

  overlayManager = new OverlayManager();
  overlayManager2 = new OverlayManager();
  overlayManager3 = new OverlayManager();

  overlayManager4 = new OverlayManager();
  overlayManager_selectedBlock = new OverlayManager(undefined, 0);
  overlayManager_selectedEl = new OverlayManager(undefined, 1);

  overlayManager_selectedNode = new OverlayManager(undefined, 2);


  overlayManager5 = new OverlayManager();


  styleManager5 = new StyleManager();

  styleManager_blockHighlight = new StyleManager();

  setOverlay(path?, onSelecting=false) {
    if (path && (onSelecting || path != this.selecting)) {
      const el = jQuery(`[data-path="${path}"]`)[0];
      // console.log(el[0]);

      let binding;
      let found;
      if (el.getAttribute('data-script-block-id')) {
        binding = new BlockBinding(el.getAttribute('data-script-block-id'), el);
        found = this.devRuntime.findBlock(binding.id);
      }
      else if (el.getAttribute('data-node-id')) {
        binding = new NodeBinding(el.getAttribute('data-node-id'), el);
        found = this.devRuntime.findNode(binding.id);
      }
      else {
        console.log('hwat');
      }
  
      found ||= [];
  
      const comp = found.find(el => el instanceof CComponent);
      const node = found.find(el => el instanceof Node);
      const block = found.find(el => el instanceof Block);
  
  
      let label;
  
      if (comp && node && block) {
        label = `${comp.obj.name}/${expandToText({
          types: {}
        } ,node.obj.data)}`
        // editorState.activeBlockPath = `${comp.obj._id}/${node.obj._id}/${_blockId}`;
      }
      else if (comp && node) {
        label = `${comp.obj.name}/${expandToText({
          types: {}
        } ,node.obj.data)}`;
        // editorState.activeBlockPath = `${comp.obj._id}/${node.obj._id}/null`;
      }

      // console.log(x(node.obj));


      this.overlayManager4.setOverlays([
        {
          selector: `[data-path="${path}"]`,
          styles: {
            outline: '2px solid #0b99ff',
          },
          label: label && {
            content: label,
            color: '#0b99ff',
          }
        }
      ], -2)
    }
    else {
      this.overlayManager4.setOverlays([]);
    }
  }


  editorState() {
    const editorState = getDevProjectEditorState(this.devProject);
    return editorState;
  }
  selecting


  cleanupFuncs = []

  navigateToEl2(el, blockId?, updatePath=false) {
    const editorState = getDevProjectEditorState(this.devProject);

    if (el.getAttribute('data-text-block-id')) {
      const path = el.getAttribute('data-path');
      this.state.activePath = path;
      editorState.activePath = path;

      let found = this.devRuntime.findBlock(el.getAttribute('data-text-block-id'));
      const comp: CComponent = found.find(el => el instanceof CComponent);
      const node: Node = found.find(el => el instanceof Node);
      const block: Block = found.find(el => el instanceof Block);

      if (comp && block && node) {
        editorState.activeBlockPath = `${comp.obj._id}/${node.obj._id}/${block.obj._id}`
      }
      else if (comp && block) {
        editorState.activeBlockPath = `${comp.obj._id}/${block.obj._id}`
      }
      return;
    }


    const path = el.getAttribute('data-path');
    const node = jQuery(el).parents('[data-node-id]').attr('node-data-id');
    const block = el.getAttribute('data-script-block-id');

    this.selecting = path;

    this.state.activePath = path;
    editorState.activePath = path;

    this.overlayManager4.setOverlays([]);

    if (!this.editorState().hideSelectOverlay) {
      this.overlayManager_selectedNode.setOverlays([
        {
          selector: `[data-node-id="${node}"]`,
          styles: {
            outline: '1px solid #4bd4825f',
          },
        }
      ], -1);
      this.overlayManager_selectedEl.setOverlays([
        {
          selector: `[data-path="${path}"]`,
          styles: {
            outline: '1.5px solid #22a0fe',
          },
        }
      ], -1.5);
    }
    this.navigateToEl(el, blockId, updatePath);
  }

  setPath(path?: string) {
    if (!path) {
      this.state.path = [];
    }
    else {
      this.state.path = XGuard(iter(x(this.state.tree), path));
      setTimeout(() => {
        if (jQuery('[data-pb-path-bar]')[0]) {
          jQuery('[data-pb-path-bar]')[0].scrollLeft = 100000;
  
        }
      }, 200)
  
    }
  }

  asdfasdf(el) {
    const editorState = getDevProjectEditorState(this.devProject);

    if (editorState.activeCanvas || editorState.mode == 'workspace') {

      const frame = jQuery(el).parents('[data-canvas-component]');

      const workspace = findWorkspace(editorState.activeWorkspace, this.devProject.workspaceStructure);
      workspace.selectedFrame = frame.attr('data-canvas-component');

      this.state.tree = XGuard(generateAppTree(this.devRuntime, frame[0].firstChild.nextSibling.firstChild, [
        `frame:${frame.attr('data-canvas-component')}`
      ], false));

    }
    else if (editorState.mode == 'library') {
      const frame = jQuery(el).parents('[data-library-frame]');
      this.state.tree = XGuard(generateAppTree(this.devRuntime, frame[0].firstChild, [
        `frame:${frame.attr('data-library-frame')}`
      ], false));



    }
    else if (editorState.mode == 'output') {
      const layer = jQuery(el).parents('[data-layer]')[0];
      if (layer) {
        this.state.tree = XGuard(generateAppTree(this.devRuntime, layer.firstChild, [
          `layer:${layer.getAttribute('data-layer')}`
        ], false));
      }
      else {
        const rootEl = document.querySelector('.renderedOutput')?.firstChild;
        const tree = generateAppTree(this.devRuntime, rootEl);
        this.state.tree = XGuard(tree);
      }
    }

  }
  
  componentDidMount() {
    window['g_goToBlock'] = blockId => {
      this.navigateToBlock(blockId);
    }

    document.title = `${this.devProject.name} - Proto Builder`;
    const editorState = getDevProjectEditorState(this.devProject);

    const attachEvent = (el, event, handler) => {
      jQuery(el)[event](handler);
      this.cleanupFuncs.push(() => {
        jQuery(el).unbind(event, handler);
      })
    }


    const inspectKeys = (e) => {
      return !editorState.selectMode && e.metaKey && e.altKey;
    }

    const interactKeys = (e) => {
      return editorState.selectMode && e.metaKey && e.altKey;
    }

    attachEvent(window, 'mousedown', e => {
      
      if (inspectKeys(e)) {
        e.stopPropagation();
        e.preventDefault();
        const els = document.elementsFromPoint(e.clientX, e.clientY).filter(el => {
          return el.getAttribute('data-script-block-id') || el.getAttribute('data-node-id');
        });

        const el = els[0];
        
        if (el) {
          let binding;
          let found;
          if (el.getAttribute('data-script-block-id')) {
            binding = new BlockBinding(el.getAttribute('data-script-block-id'), el);

            found = this.devRuntime.findBlock(binding.id);
          }
          else if (el.getAttribute('data-node-id')) {
            binding = new NodeBinding(el.getAttribute('data-node-id'), el);

            found = this.devRuntime.findNode(binding.id);
          }

          found ||= [];

          const comp = found.find(el => el instanceof CComponent);
          const node = found.find(el => el instanceof Node);
          const block = found.find(el => el instanceof Block);

          
          if (comp) {
            editorState.activeComponent = comp.obj._id;
          }

          if (node) {
            getComponentEditorState(comp.obj).selectedNode = node.obj._id;
          }
        }
      }

      if (editorState.selectMode && !interactKeys(e) && !_.isNil(_x) && !_.isNil(y)) {
        const allEls = document.elementsFromPoint(_x, y);

        if (allEls.find(el => el.getAttribute?.('data-context-menu') || el.classList.contains('--around') || el.getAttribute?.('data-os-window'))) {
          return;
        }

        const els = allEls.filter(el => {
          return el.getAttribute('data-path');
        });

        if (els.length) {
          const path = els[0].getAttribute('data-path');
          const node = els.find(el => el.getAttribute('data-node-id'))?.getAttribute('data-node-id')

          const block = els[0].getAttribute('data-script-block-id');

          this.selecting = path;

          this.state.activePath = path;
          editorState.activePath = path;

          this.overlayManager4.setOverlays([]);

          if (!this.editorState().hideSelectOverlay) {
            this.overlayManager_selectedNode.setOverlays([
              {
                selector: `[data-node-id="${node}"]`,
                styles: {
                  outline: '1px solid #4bd4825f',
                },
              }
            ], -1);
            this.overlayManager_selectedEl.setOverlays([
              {
                selector: `[data-path="${path}"]`,
                styles: {
                  outline: '1.5px solid #22a0fe',
                },
              }
            ], -1.5);
          }
          this.navigateToEl(els[0]);

          let additionalTabs = [];

          if (editorState.activeCanvas || editorState.mode == 'workspace') {
            const el = els[0];

            const frame = jQuery(el).parents('[data-canvas-component]');

            const workspace = findWorkspace(editorState.activeWorkspace, this.devProject.workspaceStructure);
            workspace.selectedFrame = frame.attr('data-canvas-component');

            this.state.tree = XGuard(generateAppTree(this.devRuntime, frame[0].firstChild.nextSibling.firstChild, [
              `frame:${frame.attr('data-canvas-component')}`
            ], false));

            this.setPath(path);

            additionalTabs = [
              { type: 'workspaceFrame', id: frame.attr('data-canvas-component') }
            ]
          }
          else if (editorState.mode == 'library') {
            const el = els[0];

            const frame = jQuery(el).parents('[data-library-frame]');
            this.state.tree = XGuard(generateAppTree(this.devRuntime, frame[0].firstChild, [
              `frame:${frame.attr('data-library-frame')}`
            ], false));


            this.setPath(path);


            additionalTabs = [
              { type: 'libraryFrame', id: frame.attr('data-library-frame') }
            ]

          }
          else if (editorState.mode == 'output') {
            const el = els[0];
            const layer = jQuery(el).parents('[data-layer]')[0];
            if (layer) {
              this.state.tree = XGuard(generateAppTree(this.devRuntime, layer.firstChild, [
                `layer:${layer.getAttribute('data-layer')}`
              ], false));
            }
            else {
              const rootEl = document.querySelector('.renderedOutput')?.firstChild;
              const tree = generateAppTree(this.devRuntime, rootEl);
              this.state.tree = XGuard(tree);
            }
          }


          this.setPath(path);

          editorState.additionalInspectorTabs = additionalTabs;


          const findComponent = (node, instanceId?) => {
            if (node.key == editorState.activePath) {
              return node.node.instanceId || instanceId;
            }
            for (const child of node.children) {
              const r = findComponent(child, node.node.instanceId || instanceId);
              if (r) return r;
            }
          }
          
          const instanceId = findComponent(x(this.state.tree));

          this.devRuntime.selectInstance(instanceId);
          
        }
      }
    });

    attachEvent(window, 'dblclick', e => {
      if (editorState.selectMode && !_.isNil(_x) && !_.isNil(y)) {
        const allEls = document.elementsFromPoint(_x, y);

        if (allEls.find(el => el.getAttribute?.('data-context-menu') || el.classList.contains('--around') || el.getAttribute?.('data-os-window'))) {
          return;
        }

        const els = allEls.filter(el => {
          return el.getAttribute('data-path');
        });

        const findComponent = (node, instanceId?) => {
          if (node.key == editorState.activePath) {
            return node.node.instanceId || instanceId;
          }
          for (const child of node.children) {
            const r = findComponent(child, node.node.instanceId || instanceId);
            if (r) return r;
          }
        }
        

        if (els.length) {
          const textNode = jQuery(els[0]).children('[data-text-block-id]')[0]
          if (textNode) {
            this.navigateToEl2(textNode);

            if (textNode.getAttribute('data-instance-id')) {
              this.devRuntime.selectInstance(textNode.getAttribute('data-instance-id'));
            }
            this.setPath(textNode.getAttribute('data-path'));

          }
          // else {
          //   const el = els.find(el => el.getAttribute('data-script-block-id'));
          //   if (el) {
          //     this.navigateToBlock(el.getAttribute('data-script-block-id'))
          //   }
          // }
        }
      }
    })


    let _x, y;

    /*const doThing = e => {
      const inspecting = inspectKeys(e);

      const els = document.elementsFromPoint(_x, y).filter(el => {
        return el.getAttribute('data-script-block-id') || el.getAttribute('data-node-id');
      });


      const el = els[0];
      if (el) {
        if (this.bottomBarRef.current && inspecting) {
          const components = jQuery(el).parents('[data-component-id]').toArray().map(el => el.getAttribute('data-component-id'));
          if (el.getAttribute('data-component-id')) {
            components.unshift(el.getAttribute('data-component-id'));
          }
          components.reverse();
          this.bottomBarRef.current.state.inspecting = components;
        }
        let binding;
        let found;
        if (el.getAttribute('data-script-block-id')) {
          binding = new BlockBinding(el.getAttribute('data-script-block-id'), el);

          found = this.devRuntime.findBlock(binding.id);
        }
        else if (el.getAttribute('data-node-id')) {
          binding = new NodeBinding(el.getAttribute('data-node-id'), el);

          found = this.devRuntime.findNode(binding.id);
        }

        found ||= [];

        if (inspecting) {
          this.overlayManager.setOverlays(found.map(f => {
            return {
              selector: `[data-node-id="${f.obj._id}"], [data-script-block-id="${f.obj._id}"]`,
              styles: {
                outline: '2px solid green',
              }
            }
          }))
  
        }


        // this.overlayManager2.setOverlays(found.map(f => {
        //   return {
        //     selector: `[data-input-id="${f.obj._id}"]`,
        //     styles: {
        //       outline: '2px solid #48cd23ae',
        //     }
        //   }
        // }))

        this.styleManager.setStyles(`



          ${found.map(f => `
            

            [data-input-id="${f.obj._id}"]:before {
              position: absolute;
              content: '';
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              border: 1px solid #48cd23ae;
            }
          `).join('\n')}

        `)
      }
      else {
        this.styleManager.setStyles();
        this.overlayManager.setOverlays([]);
        this.overlayManager2.setOverlays([]);
      }
    }*/


    attachEvent('[data-editor-area]', 'mousemove', e => {
      this.showSelect();

      {
        const els = document.elementsFromPoint(e.clientX, e.clientY).filter(el => {
          return el.getAttribute('data-path');
        });
  
  
        const path = els[0]?.getAttribute?.('data-path');
        if (editorState.selectMode && !interactKeys(e)) {
  
          this.setOverlay(path);
        }
  
        const nodeId = els[0]?.getAttribute?.('data-node-id');
  
  
        this.styleManager4.setStyles(path && `
          .appTree [data-key="${path}"] {
            background-color: #f3f3f3 !important;
          }
  
          .nodeTree [data-key="${nodeId}"] {
            background-color: #f3f3f3 !important;
          }
        `)
  
      }

      {
        const els = document.elementsFromPoint(e.clientX, e.clientY).filter(el => {
          return el.getAttribute('data-script-block-ids');
        });

        const el = els[0];

        let allIds = [];

        for (const el of els) {
          const ids = el.getAttribute('data-script-block-ids').split(',');
          allIds = allIds.concat(ids);
        }

          this.styleManager_blockHighlight.setStyles(
            allIds.map(id => `
              [data-block-id="${id}"] > .editor {
                background-color: #f3f3f3 !important;
              }

            `).join('\n')
          )
 

      }


      
    })

    jQuery(ReactDOM.findDOMNode(this)).on('mouseenter', '[data-block-id]', e => {
      this.overlayManager5.setOverlays([]);

      this.overlayManager5.setOverlays([
        {
          selector: `[data-script-block-ids*="${e.target.getAttribute('data-block-id')}"]`,
          styles: {
            outline: '1px solid #0b99ffa5',
          },
        }
      ], -1)

    });

    jQuery(ReactDOM.findDOMNode(this)).on('mouseleave', '[data-block-id]', e => {

      this.overlayManager5.setOverlays([]);

    });

    // attachEvent(window, 'mouseover', e => {
    //   {
    //     const els = document.elementsFromPoint(e.clientX, e.clientY).filter(el => el.getAttribute('data-block-id'))

    //     const el = els[0];
    //     console.log(el?.getAttribute?.('data-block-id'))

    //     if (el) {
    //       this.overlayManager5.setOverlays([]);

    //       this.overlayManager5.setOverlays([
    //         {
    //           selector: `[data-script-block-ids*="${el.getAttribute('data-block-id')}"]`,
    //           styles: {
    //             outline: '2px solid #0b99ff',
    //           },
    //         }
    //       ], -2)
    //     }
    //     else {
    //       this.overlayManager5.setOverlays([]);
    //     }


    //   }
    // })

    attachEvent('[data-editor-area]', 'mouseout', () => {
      this.setOverlay();
    })

    attachEvent(window, 'mousemove', e => {
      _x = e.clientX;
      y = e.clientY;
    });

    attachEvent(window, 'keydown', e => {
      if (e.metaKey) {
        this.styleManager5.setStyles(`
          [data-prop-path] {
            cursor: pointer;
          }

          [data-prop-path]:hover {
            text-decoration: underline;
            color: blue !important;
          }
        
        `)
      }
      if (e.metaKey && e.key == 's') {
        e.preventDefault();
        console.log('save')
        this.devRuntime.resetDebug();
        this.devRuntime.updateComponents(Object.keys(this.devRuntime.changed.components));
        this.devRuntime.updateStyles(Object.keys(this.devRuntime.changed.styles));
        this.devRuntime.resetChanges();


        if (this.devRuntime.getActiveInstance()) {
          const instance = this.devRuntime.componentInstancesByPath[this.devRuntime.getActiveInstance()];
          console.log(instance, this.devRuntime.getActiveInstance());
          if (instance) {
            instance.forceUpdate();
            
          }
          else {
            console.log('no instance');
          }
      
        }


      }

      if (inspectKeys(e)) {
        // doThing(e);
      }

      if (interactKeys(e)) {
        this.overlayManager4.setOverlays([]);

        this.styleManager3.setStyles(`
          [data-select-overlay] {
            pointer-events: none;
          }
        `)
      }
    })

    attachEvent(window, 'keyup', e => {

      if (!e.metaKey) {
        this.styleManager5.setStyles(``);
      }

      if (!inspectKeys(e)) {
        this.styleManager.setStyles();
        this.overlayManager.setOverlays([]);
        this.overlayManager2.setOverlays([]);
      }

      if (!interactKeys(e)) {
        this.styleManager3.setStyles()
      }
    })



    
    

    // this.screenshotInterval = setInterval(async () => {
    //   const data = await htmlToImage.toBlob(document.querySelector('.renderedOutput') as any);
    //   const r = await fileUpload(data, 'screenshot');
    //   this.devProject.screenshot = r;

    // }, 5 * 60 * 1000);

    // this.screenshot();


    const updateDbType = () => {
      globals.db = new StructType({
        properties: db.queries.filter(q => !q.__deleted).map(q => {
          const entity:any = {};
          createEntityFromQuery(queryChain(q), entity);
          const attrs = attributesForType(entity.type);
          const type = db.entityTypes.findById(entity.type);
          return {
            name: q.name,
            type: new ArrayType({
              elType: new StructType({
                properties: 
                [
                  {
                    name: '_id',
                    type: new StringType(),
                  },
                  {
                    name: 'name',
                    type: new StringType(),
                  },
                ...attrs.map(id => {
                  const attr = db.attributeTypes.findById(id);
                  return {
                    name: attr.name,
                    type: new AnyType(),
                  }
                }),

                ],
                name: type?.name,
              })
            }),
          }
        }),
        name: 'Db',
      })
    }
    updateDbType();
    additionalGlobalTypes.push(() => {
      const types = {};
      for (const type of db.entityTypes) {
        const attrs = attributesForType(type._id);
        types[type.name] = new StructType({
          properties: 
          [
            {
              name: '_id',
              type: new StringType(),
            },
            {
              name: 'name',
              type: new StringType(),
            },
          ...attrs.map(id => {
            const attr = db.attributeTypes.findById(id);
            return {
              name: attr.name,
              type: new AnyType(),
            }
          }),

          ],
          name: type.name,
        })

      }

      return types;
    })
    
  }

  componentWillUnmount(): void {
    XObject.removeObserver(this.devProject.db.entities, this.observer);
    this.devRuntime.cleanup();
    for (const func of this.cleanupFuncs) func();   


    this.styleManager.cleanup();
    this.styleManager2.cleanup();
    this.styleManager3.cleanup();
    this.styleManager4.cleanup();

    this.overlayManager.cleanup();
    this.overlayManager2.cleanup();
    this.overlayManager3.cleanup();
    this.overlayManager4.cleanup();
    this.overlayManager_selectedEl.cleanup();
    this.overlayManager_selectedNode.cleanup();
    

    clearInterval(this.screenshotInterval);

    XObject.removeObserver()
  }

  select(path?) {
    console.log(path);
      this.selecting = path;

      this.state.activePath = path;
  
      if (path) {
        (document.querySelector(`[data-path="${path}"]`) as any)?.scrollIntoViewIfNeeded?.();
  
        this.overlayManager4.setOverlays([]);
    
        if (!this.editorState().hideSelectOverlay) {
          this.overlayManager_selectedEl.setOverlays([
            {
              selector: `[data-path="${path}"]`,
              styles: {
                outline: '1.5px solid #22a0fe',
              },
            }
          ], -1.5);
        }

      }
      else {
        this.overlayManager_selectedEl.setOverlays([]);
      }



  }



  asdf = React.createRef<AppTree>();
  tick = 0

  showSelectTimerId

  hideSelect() {
    clearTimeout(this.showSelectTimerId);
    this.overlayManager_selectedEl.overlay?.hide?.();
    this.overlayManager_selectedNode.overlay?.hide?.();
    this.showSelectTimerId = setTimeout(() => {
      this.overlayManager_selectedEl.overlay?.show?.();
      this.overlayManager_selectedNode.overlay?.show?.();
    }, 2000);
  }

  showSelect() {
    clearTimeout(this.showSelectTimerId);
    this.overlayManager_selectedEl.overlay?.show?.();
  }

  async screenshot() {
    const data = await htmlToImage.toBlob(document.querySelector('.renderedOutput') as any);
    const r = await fileUpload(data, 'screenshot');
    this.devProject.screenshot = r;
  }

  inspectRef = React.createRef<BottomPane>();

  builderComponentEditorRef = React.createRef<BuilderComponentEditor>();



  render(Container?) {
    const id = this.props.id;

    const devProject = this.devProject;
    // const colors = XObject.get(this.devProject, 'colors', []);
    // const styles = XObject.get(this.devProject, 'styles', []);
    // const assets = XObject.get(this.devProject, 'assets', []);

    const editorState = getDevProjectEditorState(devProject);
    // const asdf = XObject.get(this.state, 'asdf', {});

    const navigate = useNavigate();

    const previewMode = editorState.previewMode && devProject.rootComponent;

    // const canvasEditorState = XObject.get(devProject, 'canvasEditorState', {});
    
    // const canvases = XObject.get(devProject, 'canvases', []);
    // const activeCanvas = canvases.find(c => c._id == editorState.activeCanvas);

    return (
      <Container className={cx({designer: previewMode})}>
        <div className="topBar">
          <span className="logo"
            onContextMenu={e => {
              e.preventDefault();
              showContextMenu(e, [
                {
                  text: 'Copy project',
                  onClick: () => {
                    copy(serialize(x(this.devProject)));
                  },
                },
                {
                  text: 'Screenshot',
                  onClick: () => {
                    this.screenshot();
                  }
                },
                {
                  text: 'Copy ID',
                  onClick: () => {
                    copy(this.devProject._id);
                  }
                }
              ])
            }}
            onClick={() => {
              navigate('/')
            }}
          >
            <Svg name="logoWhite" />
          </span>
          <div
            className={cx('tool', { active: editorState.selectMode })}
            title="Select mode"
            onClick={() => {
              editorState.selectMode = true;
            }}
          >
            <Svg name="icons8-select-cursor" />
          </div>
          <div
            className={cx('tool', { active: !editorState.selectMode })}
            title="Interact mode"
            onClick={() => {
              editorState.selectMode = false;
            }}
          >
            <Svg name="icons8-click" />
          </div>
          <input
            className="name"
            type="text"
            defaultValue={this.devProject.name}
            onChange={e => {
              this.devProject.name = e.target.value;
            }}
          />
          <div className="right">
            {/* <span className="dropdown">
              Label

              <Svg name="chevron" />
            </span> */}

            <div className="debugger">
              <InstanceSelector devRuntime={this.devRuntime} />
            </div>

            <DropdownSelector
              options={[
                { value: 'output', text: 'App' },
                { value: 'workspace', text: 'Workspace' },
                { value: 'library', text: 'Library' },
                { value: 'grap', text: 'Graph' },
              ]}
              value={editorState.mode || 'output'}
              onValueChange={v => {
                editorState.mode = v;
              }}
            />

            {/* <select
              onChange={e => {
                editorState.mode = e.target.value;
              }}
              value={editorState.mode || 'output'}
              >
              <option value="output">App</option>
              <option value="workspace">Workspace</option>
              <option value="library">Library</option>
              <option value="graph">Graph</option>
            </select> */}
          </div>

        </div>


        <div className="middle">
          <div
            className={cx("outputArea", {
              canvasEditorMode: editorState.activeCanvas,
            })}
            data-editor-area
          >
            {editorState.mode == 'output' && (
              <>
                {!editorState.activeCanvas && devProject.rootComponent && (
                  <DevProjectOutput
                    parent={this}
                    key={this.tick}
                    rootId={devProject.rootComponent}
                    devRuntime={this.devRuntime}
                    id={id}
                    state={this.state}
                    layers={this.state.layers}
                    navState={this.navState}
                    // hasNext={this.navState.navPointer < this.navState.navStack.length - 1}
                    // hasPrev={this.navState.navPointer > 0}
                    navNext={() => {
                      if (this.navState.navPointer < this.navState.navStack.length - 1) {
                        this.navState.navPointer++;
                        this.devProject.editorState.url = this.navState.navStack[this.navState.navPointer];
                      }

                    }}
                    navPrev={() => {
                      console.log(this.navState.navPointer, this.navState.navStack)
                      if (this.navState.navPointer > 0) {
                        this.navState.navPointer--;
                        this.devProject.editorState.url = this.navState.navStack[this.navState.navPointer];
                      }
                    }}
                  />
                )}
              </>
            )}
            {editorState.mode == 'library' && (
              <Library devRuntime={this.devRuntime} selectMode={editorState.selectMode} parent={this} 
              state={XObject.get(devProject, 'library', {})}
              />
            )}
            {editorState.mode == 'workspace' && (
              <div className="renderedOutput">
                <Workspace
                  devRuntime={this.devRuntime}
                  parent={this}
                  selectMode={editorState.selectMode}
                />
              </div>
            )}
            {editorState.mode == 'output' && editorState.selectMode && <div data-select-overlay className="overlay" />}
            {editorState.mode == 'graph' && <Graph devRuntime={this.devRuntime} />}
          </div>
          <BottomPane
            ref={this.inspectRef}
            devProjectComp={this}
            devRuntime={this.devRuntime}
            state={editorState}
            additionalTabs={editorState.additionalInspectorTabs || []}
          />
        </div>

        <div className="rightBar">
          <Right
            devRuntime={this.devRuntime}
            parent={this}
          />
        </div>

        {/* <div className="right" key={activeComponent?._id}>
          {activeStyle && (() => {
            return (
              <StyleEditor style={activeStyle} devRuntime={this.devRuntime} />
            )
          })()}
          {activeComponent && (() => {
            if (activeComponent.type == 'builderComponent') {
              return (

              )
            }
            else {
              return (
                <Entry
                  nodeDesignerChanged={() => {
                    throw new Error();
                  }}
                  active={null}
                  comp={activeComponent}
                  components={devProject.components}
                  devProject={devProject}
                  devRuntime={this.devRuntime}
                  i={0}
                  st={asdf}
                />
              )
            }
          })()}
        </div> */}
      </Container>
    );
  }
}

@component
export class DevProjectRoot extends Component {
  loading = true;

  async componentDidMount() {
    if (!getDevProject('65a069ca19f33f971b2166d7')) {
      const r = await fetch('https://jonathan-cook-portfolio.s3.amazonaws.com/protobuilder/todoist.json');
      const text = await r.text();
      const obj = eval(`(${text})`);
      addDevProject(obj);
    }
    if (!getDevProject('65d64e44ff875072d08cbd88')) {
      const r = await fetch('https://jonathan-cook-portfolio.s3.amazonaws.com/protobuilder/linear.json');
      const text = await r.text();
      const obj = eval(`(${text})`);
      addDevProject(obj);
    }    

    this.loading = false;
    this.forceUpdate();
  }

  constructor(props) {
    super(props);

  }

  render() {
    if (this.loading) return;
    const nav: { nav; title; }[] = [
      {
        nav: {
          type: WindowTypes.editor
        },
        title: 'Editor',
      },
      {
        nav: {
          type: WindowTypes.stylesTest
        },
        title: 'Styles Test',
      },
      {
        nav: {
          type: WindowTypes.db
        },
        title: 'DB',
      },
    ];

    return (
      <>
        {/* <SystemContext.Provider value={{
              // openWindow: () => {

              // }
              navigate: () => {},
              next: () => {},

              
            }}>
              <OS
                  showBar
                  windowTypes={{
                    // [WindowTypes.editor]: {
                    //   title: () => 'Editor',
                    //   component: win => { return getComponentCache(win, () => { return <EditorWindow devRuntime={this.devRuntime} window={win} /> }); },
                    // },
                    // [WindowTypes.stylesTest]: {
                    //   title: () => 'Editor',
                    //   component: win => { return getComponentCache(win, () => { return <StylesTestWindow window={win} /> }); },
                    // },
                    [WindowTypes.db]: {
                      title: () => 'DB',
                      component: win => { return getComponentCache(win, () => { return <DBWindow window={win} /> }); },
                    },
                    [WindowTypes.query]: {
                      title: () => 'Query',
                      component: win => { return getComponentCache(win, () => { return <QueryWindow window={win} /> }); },
                    },
                    [WindowTypes.entity]: {
                      title: () => 'Entity',
                      component: win => { return getComponentCache(win, () => { return <EntityWindow window={win} /> }); },
                    },
                  }}
                  menu={nav.map((n, i) => {
                    return <li key={i} onClick={() => {
                      openWindow(n.nav);
                    }}>{n.title}</li>;
                  })}
                >
        */}
        <BrowserRouter>
          <Routes>
            <Route path="/projects/:id"
              Component={() => {
                const args = useParams();
                return <DevProject key={args.id} id={args.id} />
              }}
            />
            <Route path="/" 
              Component={() => <DevProjects />}
            />
          </Routes>
          <ToastContainer />
        </BrowserRouter>
        {/* </OS>
        </SystemContext.Provider> */}
      </>
    );
  }
}
