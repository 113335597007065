import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import jQuery from 'jquery';
import cx from 'classnames';
import { component, styled } from './component2';
import { showContextMenu } from './helpers';
import { highlightEls } from './highlightOutputStylesManager';
import { x } from './XObject';

@component
export class Tree extends Component<{
  scroll?: boolean;
  defaultOpen?;
  nodes: TreeCompNode[];
  active?;
  state;
  onClickNode?;
  className?;

  onNodeMouseOver?
  onNodeMouseOut?
  left?
}> {
  static styles = styled.div`
    &:not(:hover) {
      .line:not(.highlight) {
        display: none;
      }
    }

    .line {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: #eeeeee;

        &.highlight {
          background-color: #acacac;
        }
      }

    .component {
      cursor: pointer;
      &:hover {
        background-color: #f3f3f3;
      }
      position: relative;

      &.active {
        background-color: #e3e3e3;
        .bullet {
          background-color: #cfcfcf;

        }
      }



      &.showingChildren {
          > .inner > .toggle {
            transform: rotate(45deg);
            margin-top: -2px;
          }
        }
    }

    .checkbox {
      position: absolute;
      left: 0;
    }

    .inner {
      position: relative;
      padding-left: 20px;


      .bullet {
        width: 4px;
        height: 4px;
        position: absolute;
            left: 7px;
            top: 9px;

            border-radius: 50%;
            background-color: #e2e2e2;
      }
      .toggle {
        transform: rotate(-45deg);
        pointer-events: auto;

        width: 6px;
        height: 6px;
        cursor: pointer;
        border-right: 1px solid #c4c4c4;
        border-bottom: 1px solid #c4c4c4;

        &:hover {
          border-color: black;
        }

        position: absolute;
        left: 6px;
        top: 6px;
      }

      /* padding-left: 40px; */
      /* margin-left: 20px; */
      /* .bullet {
        left: 27px;
      }
      .toggle {
        left: 26px;
      }
      .checkbox {
        position: absolute;
        left: 0px;
      } */
    }
  `;

  componentDidUpdate(prevProps: Readonly<{ scroll?: boolean; defaultOpen?: any; nodes: TreeCompNode[]; active?: any; state: any; onClickNode: any; }>, prevState: Readonly<{}>, snapshot?: any): void {
    if (this.props.scroll) {
      // console.log(this.props.active);
      jQuery(ReactDOM.findDOMNode(this)).find(`[data-key="${this.props.active}"]`)[0]?.scrollIntoViewIfNeeded?.();
    }
  }

  render(Container?) {

    const left = this.props.left || 10;

    const { active } = this.props;
    const sidebarState = this.props.state;

    const items: [TreeCompNode, any][] = [];

    const isOpen = id => {
      // eslint-disable-next-line
      sidebarState[id];
      let open;
      if (this.props.defaultOpen) {
        if (id in x(sidebarState)) {
          open =sidebarState[id];
        }
        else {
          open = true;
        }
      }
      else {
        open =sidebarState[id];
      }

      return open;
    }

    const genItems = (comps: TreeCompNode[], level, lineStyles = {}) => {
      for (const component of comps) {
        const children = component.children || [];
        const childActive = children.find(c => active == c.key);
        items.push([component, { level, lineStyles, hasChildren: !!children.length || !!component.content }]);


        if (isOpen(component.key)) {
          genItems(children, level + 1, {
            ...lineStyles,
            ...(childActive ? { [level]: 'highlight' } : {}),
          });
        }
      }
    };
    genItems(this.props.nodes, 0);

    return (
      <Container className={this.props.className}
        data-active={this.props.active}
      >
        {items.map(([node, { level, lineStyles, hasChildren }]) => {
          return (
            <div key={node.key}>
              <div
                className={cx('component', node.className, {
                  active: active == node.key,
                  showingChildren: isOpen(node.key)
                })}
                data-key={node.key}
                style={{
                  paddingLeft: level * left,
                  ...(node.styles || {}),
                }}
                onMouseOver={() => {
                  this.props.onNodeMouseOver?.(node);
                }}
                onMouseOut={() => {
                  this.props.onNodeMouseOut?.(node);
                }}
                data-input-id={node.elId}
                onClick={() => {
                  this.props.onClickNode(node.elId, node);
                }}
                onContextMenu={e => {
                  e.preventDefault();
                  if (node.contextMenu) {
                    showContextMenu(e, node.contextMenu);
                  }
                }}
              >
                {[...Array(level)].map((__, i) => {
                  return <span className={cx('line', lineStyles[i])}
                    style={{
                      left: i * left + 9,
                    }} />;
                })}

                <div className="inner">
                  {hasChildren && <span className="toggle"
                    onClick={e => {
                      e.stopPropagation();
                      sidebarState[node.key] = !isOpen(node.key);
                    }} />}

                  {!hasChildren && <span className="bullet" />}
                  {node.text}
                </div>
              </div>

              {isOpen(node.key) && node.content && (
                <>
                  <div
                    data-test
                    style={{
                      position: 'relative',
                      paddingLeft: level * left + 22,
                    }}
                  >
                  {[...Array(level + 1)].map((__, i) => {
                    return <span className={cx('line', lineStyles[i])}
                      style={{
                        left: i * left + 9,
                      }} />;
                  })}
                    {node.content()}
                  </div>
                </>
              )}
            </div>
          );
        })}
      </Container>
    );
  }
}
export interface TreeCompNode {
  key;
  elId?
  text;
  contextMenu?;
  children: TreeCompNode[];
  styles?: {}
  className?
  content?
}

