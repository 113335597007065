const source = `home page	https://cdn.coda.io/icons/png/color/home-page-128.png
gmail	https://cdn.coda.io/icons/png/color/gmail-128.png
assignment turned in	https://cdn.coda.io/icons/png/color/assignment-turned-in-128.png
bear	https://cdn.coda.io/icons/png/color/bear-128.png
snow	https://cdn.coda.io/icons/png/color/snow-128.png
to do	https://cdn.coda.io/icons/png/color/to-do-128.png
checklist	https://cdn.coda.io/icons/png/color/checklist-128.png
forest	https://cdn.coda.io/icons/png/color/forest-128.png
note	https://cdn.coda.io/icons/png/color/note-128.png
database	https://cdn.coda.io/icons/png/color/database-128.png
ladder	https://cdn.coda.io/icons/png/color/ladder-128.png
boy	https://cdn.coda.io/icons/png/color/boy-128.png
girl	https://cdn.coda.io/icons/png/color/girl-128.png
meeting	https://cdn.coda.io/icons/png/color/meeting-128.png
cruise ship	https://cdn.coda.io/icons/png/color/cruise-ship-128.png
chicken	https://cdn.coda.io/icons/png/color/chicken-128.png
environment	https://cdn.coda.io/icons/png/color/environment-128.png
computer	https://cdn.coda.io/icons/png/color/computer-128.png
apple	https://cdn.coda.io/icons/png/color/apple-128.png
pear	https://cdn.coda.io/icons/png/color/pear-128.png
christmas tree	https://cdn.coda.io/icons/png/color/christmas-tree-128.png
fly	https://cdn.coda.io/icons/png/color/fly-128.png
pumpkin	https://cdn.coda.io/icons/png/color/pumpkin-128.png
table	https://cdn.coda.io/icons/png/color/table-128.png
chair	https://cdn.coda.io/icons/png/color/chair-128.png
building	https://cdn.coda.io/icons/png/color/building-128.png
light	https://cdn.coda.io/icons/png/color/light-128.png
lamp	https://cdn.coda.io/icons/png/color/lamp-128.png
water bottle	https://cdn.coda.io/icons/png/color/water-bottle-128.png
dog	https://cdn.coda.io/icons/png/color/dog-128.png
cat	https://cdn.coda.io/icons/png/color/cat-128.png
washing machine	https://cdn.coda.io/icons/png/color/washing-machine-128.png
phone	https://cdn.coda.io/icons/png/color/phone-128.png
iphone	https://cdn.coda.io/icons/png/color/iphone-128.png
blind	https://cdn.coda.io/icons/png/color/blind-128.png
car	https://cdn.coda.io/icons/png/color/car-128.png
paper	https://cdn.coda.io/icons/png/color/paper-128.png
pencil	https://cdn.coda.io/icons/png/color/pencil-128.png
country	https://cdn.coda.io/icons/png/color/country-128.png
world	https://cdn.coda.io/icons/png/color/world-128.png
globe	https://cdn.coda.io/icons/png/color/globe-128.png
flag	https://cdn.coda.io/icons/png/color/flag-128.png
gun	https://cdn.coda.io/icons/png/color/gun-128.png
square	https://cdn.coda.io/icons/png/color/square-128.png
microsoft	https://cdn.coda.io/icons/png/color/microsoft-128.png
school	https://cdn.coda.io/icons/png/color/school-128.png
teacher	https://cdn.coda.io/icons/png/color/teacher-128.png
bored	https://cdn.coda.io/icons/png/color/bored-128.png
money	https://cdn.coda.io/icons/png/color/money-128.png
currency	https://cdn.coda.io/icons/png/color/currency-128.png
europe	https://cdn.coda.io/icons/png/color/europe-128.png
euro	https://cdn.coda.io/icons/png/color/euro-128.png
print	https://cdn.coda.io/icons/png/color/print-128.png
template	https://cdn.coda.io/icons/png/color/template-128.png
monitor	https://cdn.coda.io/icons/png/color/monitor-128.png
mouse	https://cdn.coda.io/icons/png/color/mouse-128.png
keyboard	https://cdn.coda.io/icons/png/color/keyboard-128.png
clock	https://cdn.coda.io/icons/png/color/clock-128.png
watch	https://cdn.coda.io/icons/png/color/watch-128.png
motorcycle	https://cdn.coda.io/icons/png/color/motorcycle-128.png
toothbrush	https://cdn.coda.io/icons/png/color/toothbrush-128.png
toothpaste	https://cdn.coda.io/icons/png/color/toothpaste-128.png
tooth	https://cdn.coda.io/icons/png/color/tooth-128.png
cup	https://cdn.coda.io/icons/png/color/cup-128.png
jacket	https://cdn.coda.io/icons/png/color/jacket-128.png
jeans	https://cdn.coda.io/icons/png/color/jeans-128.png
virus	https://cdn.coda.io/icons/png/color/virus-128.png
math	https://cdn.coda.io/icons/png/color/math-128.png
calculator	https://cdn.coda.io/icons/png/color/calculator-128.png
key	https://cdn.coda.io/icons/png/color/key-128.png
tent	https://cdn.coda.io/icons/png/color/tent-128.png
campfire	https://cdn.coda.io/icons/png/color/campfire-128.png
like	https://cdn.coda.io/icons/png/color/like-128.png
happy	https://cdn.coda.io/icons/png/color/happy-128.png
sad	https://cdn.coda.io/icons/png/color/sad-128.png
sword	https://cdn.coda.io/icons/png/color/sword-128.png
book	https://cdn.coda.io/icons/png/color/book-128.png
ruler	https://cdn.coda.io/icons/png/color/ruler-128.png
king	https://cdn.coda.io/icons/png/color/king-128.png
queen	https://cdn.coda.io/icons/png/color/queen-128.png
learning	https://cdn.coda.io/icons/png/color/learning-128.png
pawn	https://cdn.coda.io/icons/png/color/pawn-128.png
knight	https://cdn.coda.io/icons/png/color/knight-128.png
moon	https://cdn.coda.io/icons/png/color/moon-128.png
star	https://cdn.coda.io/icons/png/color/star-128.png
sun	https://cdn.coda.io/icons/png/color/sun-128.png
gas	https://cdn.coda.io/icons/png/color/gas-128.png
cloud	https://cdn.coda.io/icons/png/color/cloud-128.png
water	https://cdn.coda.io/icons/png/color/water-128.png
scale	https://cdn.coda.io/icons/png/color/scale-128.png
cards	https://cdn.coda.io/icons/png/color/cards-128.png
truck	https://cdn.coda.io/icons/png/color/truck-128.png
tow truck	https://cdn.coda.io/icons/png/color/tow-truck-128.png
sail boat	https://cdn.coda.io/icons/png/color/sail-boat-128.png
yacht	https://cdn.coda.io/icons/png/color/yacht-128.png
baseball	https://cdn.coda.io/icons/png/color/baseball-128.png
field	https://cdn.coda.io/icons/png/color/field-128.png
coach	https://cdn.coda.io/icons/png/color/coach-128.png
whistle	https://cdn.coda.io/icons/png/color/whistle-128.png
farm	https://cdn.coda.io/icons/png/color/farm-128.png
gold medal	https://cdn.coda.io/icons/png/color/gold-medal-128.png
medal	https://cdn.coda.io/icons/png/color/medal-128.png
metal	https://cdn.coda.io/icons/png/color/metal-128.png
iron	https://cdn.coda.io/icons/png/color/iron-128.png
basketball	https://cdn.coda.io/icons/png/color/basketball-128.png
tennis	https://cdn.coda.io/icons/png/color/tennis-128.png
football	https://cdn.coda.io/icons/png/color/football-128.png
helmet	https://cdn.coda.io/icons/png/color/helmet-128.png
american football	https://cdn.coda.io/icons/png/color/american-football-128.png
bicycle	https://cdn.coda.io/icons/png/color/bicycle-128.png
rock	https://cdn.coda.io/icons/png/color/rock-128.png
cheese	https://cdn.coda.io/icons/png/color/cheese-128.png
lettuce	https://cdn.coda.io/icons/png/color/lettuce-128.png
onion	https://cdn.coda.io/icons/png/color/onion-128.png
tomato	https://cdn.coda.io/icons/png/color/tomato-128.png
cow	https://cdn.coda.io/icons/png/color/cow-128.png
cucumber	https://cdn.coda.io/icons/png/color/cucumber-128.png
peach	https://cdn.coda.io/icons/png/color/peach-128.png
plum	https://cdn.coda.io/icons/png/color/plum-128.png
hamburger	https://cdn.coda.io/icons/png/color/hamburger-128.png
bed	https://cdn.coda.io/icons/png/color/bed-128.png
electricity	https://cdn.coda.io/icons/png/color/electricity-128.png
windmill	https://cdn.coda.io/icons/png/color/windmill-128.png
pig	https://cdn.coda.io/icons/png/color/pig-128.png
temperature	https://cdn.coda.io/icons/png/color/temperature-128.png
Thermometer	https://cdn.coda.io/icons/png/color/thermometer-128.png
diploma	https://cdn.coda.io/icons/png/color/diploma-128.png
playground	https://cdn.coda.io/icons/png/color/playground-128.png
stroller	https://cdn.coda.io/icons/png/color/stroller-128.png
baby	https://cdn.coda.io/icons/png/color/baby-128.png
roller coaster	https://cdn.coda.io/icons/png/color/roller-coaster-128.png
pregnant	https://cdn.coda.io/icons/png/color/pregnant-128.png
family	https://cdn.coda.io/icons/png/color/family-128.png
old man	https://cdn.coda.io/icons/png/color/old-man-128.png
old woman	https://cdn.coda.io/icons/png/color/old-woman-128.png
police car	https://cdn.coda.io/icons/png/color/police-car-128.png
prison	https://cdn.coda.io/icons/png/color/prison-128.png
Dictionary	https://cdn.coda.io/icons/png/color/dictionary-128.png
smart	https://cdn.coda.io/icons/png/color/smart-128.png
hide	https://cdn.coda.io/icons/png/color/hide-128.png
calendar	https://cdn.coda.io/icons/png/color/calendar-128.png
date	https://cdn.coda.io/icons/png/color/date-128.png
wednesday	https://cdn.coda.io/icons/png/color/wednesday-128.png
tuesday	https://cdn.coda.io/icons/png/color/tuesday-128.png
thursday	https://cdn.coda.io/icons/png/color/thursday-128.png
friday	https://cdn.coda.io/icons/png/color/friday-128.png
saturday	https://cdn.coda.io/icons/png/color/saturday-128.png
sunday	https://cdn.coda.io/icons/png/color/sunday-128.png
thanksgiving	https://cdn.coda.io/icons/png/color/thanksgiving-128.png
knife	https://cdn.coda.io/icons/png/color/knife-128.png
fork	https://cdn.coda.io/icons/png/color/fork-128.png
spoon	https://cdn.coda.io/icons/png/color/spoon-128.png
party hat	https://cdn.coda.io/icons/png/color/party-hat-128.png
birthday	https://cdn.coda.io/icons/png/color/birthday-128.png
birthday cake	https://cdn.coda.io/icons/png/color/birthday-cake-128.png
business	https://cdn.coda.io/icons/png/color/business-128.png
presentation	https://cdn.coda.io/icons/png/color/presentation-128.png
google drive	https://cdn.coda.io/icons/png/color/google-drive-128.png
google slides	https://cdn.coda.io/icons/png/color/google-slides-128.png
image	https://cdn.coda.io/icons/png/color/image-128.png
camera	https://cdn.coda.io/icons/png/color/camera-128.png
groom	https://cdn.coda.io/icons/png/color/groom-128.png
bride	https://cdn.coda.io/icons/png/color/bride-128.png
digger	https://cdn.coda.io/icons/png/color/digger-128.png
nail	https://cdn.coda.io/icons/png/color/nail-128.png
hammer	https://cdn.coda.io/icons/png/color/hammer-128.png
trash	https://cdn.coda.io/icons/png/color/trash-128.png
road	https://cdn.coda.io/icons/png/color/road-128.png
grass	https://cdn.coda.io/icons/png/color/grass-128.png
flower	https://cdn.coda.io/icons/png/color/flower-128.png
bee	https://cdn.coda.io/icons/png/color/bee-128.png
honey	https://cdn.coda.io/icons/png/color/honey-128.png
surfing	https://cdn.coda.io/icons/png/color/surfing-128.png
shark	https://cdn.coda.io/icons/png/color/shark-128.png
dolphin	https://cdn.coda.io/icons/png/color/dolphin-128.png
beach	https://cdn.coda.io/icons/png/color/beach-128.png
starfish	https://cdn.coda.io/icons/png/color/starfish-128.png
cross	https://cdn.coda.io/icons/png/color/cross-128.png
plus	https://cdn.coda.io/icons/png/color/plus-128.png
minus	https://cdn.coda.io/icons/png/color/minus-128.png
umbrella	https://cdn.coda.io/icons/png/color/umbrella-128.png
hand	https://cdn.coda.io/icons/png/color/hand-128.png
fish	https://cdn.coda.io/icons/png/color/fish-128.png
fishing	https://cdn.coda.io/icons/png/color/fishing-128.png
lake	https://cdn.coda.io/icons/png/color/lake-128.png
poster	https://cdn.coda.io/icons/png/color/poster-128.png
tank	https://cdn.coda.io/icons/png/color/tank-128.png
pie	https://cdn.coda.io/icons/png/color/pie-128.png
pizza	https://cdn.coda.io/icons/png/color/pizza-128.png
package	https://cdn.coda.io/icons/png/color/package-128.png
amazon	https://cdn.coda.io/icons/png/color/amazon-128.png
linkedin	https://cdn.coda.io/icons/png/color/linkedin-128.png
ebay	https://cdn.coda.io/icons/png/color/ebay-128.png
imac	https://cdn.coda.io/icons/png/color/imac-128.png
mustache	https://cdn.coda.io/icons/png/color/mustache-128.png
cookies	https://cdn.coda.io/icons/png/color/cookies-128.png
chocolate bar	https://cdn.coda.io/icons/png/color/chocolate-bar-128.png
sell	https://cdn.coda.io/icons/png/color/sell-128.png
company	https://cdn.coda.io/icons/png/color/company-128.png
stocks	https://cdn.coda.io/icons/png/color/stocks-128.png
recycling	https://cdn.coda.io/icons/png/color/recycling-128.png
twitter	https://cdn.coda.io/icons/png/color/twitter-128.png
facebook	https://cdn.coda.io/icons/png/color/facebook-128.png
instagram	https://cdn.coda.io/icons/png/color/instagram-128.png
twitch	https://cdn.coda.io/icons/png/color/twitch-128.png
controller	https://cdn.coda.io/icons/png/color/controller-128.png
xbox	https://cdn.coda.io/icons/png/color/xbox-128.png
up	https://cdn.coda.io/icons/png/color/up-128.png
down	https://cdn.coda.io/icons/png/color/down-128.png
left	https://cdn.coda.io/icons/png/color/left-128.png
right	https://cdn.coda.io/icons/png/color/right-128.png
storm	https://cdn.coda.io/icons/png/color/storm-128.png
beer	https://cdn.coda.io/icons/png/color/beer-128.png
chip	https://cdn.coda.io/icons/png/color/chip-128.png
potato	https://cdn.coda.io/icons/png/color/potato-128.png
python	https://cdn.coda.io/icons/png/color/python-128.png
coffee	https://cdn.coda.io/icons/png/color/coffee-128.png
eggs	https://cdn.coda.io/icons/png/color/eggs-128.png
french fries	https://cdn.coda.io/icons/png/color/french-fries-128.png
delete	https://cdn.coda.io/icons/png/color/delete-128.png
hexagon	https://cdn.coda.io/icons/png/color/hexagon-128.png
glue	https://cdn.coda.io/icons/png/color/glue-128.png
edit	https://cdn.coda.io/icons/png/color/edit-128.png
synchronize	https://cdn.coda.io/icons/png/color/synchronize-128.png
share	https://cdn.coda.io/icons/png/color/share-128.png
search	https://cdn.coda.io/icons/png/color/search-128.png
document	https://cdn.coda.io/icons/png/color/document-128.png
scroll	https://cdn.coda.io/icons/png/color/scroll-128.png
image file	https://cdn.coda.io/icons/png/color/image-file-128.png
folder invoices	https://cdn.coda.io/icons/png/color/folder-invoices-128.png
invoice	https://cdn.coda.io/icons/png/color/invoice-128.png
puzzle	https://cdn.coda.io/icons/png/color/puzzle-128.png
settings	https://cdn.coda.io/icons/png/color/settings-128.png
lock	https://cdn.coda.io/icons/png/color/lock-128.png
box	https://cdn.coda.io/icons/png/color/box-128.png
link	https://cdn.coda.io/icons/png/color/link-128.png
external link	https://cdn.coda.io/icons/png/color/external-link-128.png
new	https://cdn.coda.io/icons/png/color/new-128.png
news	https://cdn.coda.io/icons/png/color/news-128.png
menu	https://cdn.coda.io/icons/png/color/menu-128.png
info	https://cdn.coda.io/icons/png/color/info-128.png
user male	https://cdn.coda.io/icons/png/color/user-male-128.png
user female	https://cdn.coda.io/icons/png/color/user-female-128.png
toolbox	https://cdn.coda.io/icons/png/color/toolbox-128.png
big ben	https://cdn.coda.io/icons/png/color/big-ben-128.png
olympic torch	https://cdn.coda.io/icons/png/color/olympic-torch-128.png
crown	https://cdn.coda.io/icons/png/color/crown-128.png
crown	https://cdn.coda.io/icons/png/color/crown-128.png
ask question	https://cdn.coda.io/icons/png/color/ask-question-128.png
chat	https://cdn.coda.io/icons/png/color/chat-128.png
comments	https://cdn.coda.io/icons/png/color/comments-128.png
candy	https://cdn.coda.io/icons/png/color/candy-128.png
refuse	https://cdn.coda.io/icons/png/color/refuse-128.png
quote	https://cdn.coda.io/icons/png/color/quote-128.png
speaker	https://cdn.coda.io/icons/png/color/speaker-128.png
volume	https://cdn.coda.io/icons/png/color/volume-128.png
type	https://cdn.coda.io/icons/png/color/type-128.png
video	https://cdn.coda.io/icons/png/color/video-128.png
video message	https://cdn.coda.io/icons/png/color/video-message-128.png
movie	https://cdn.coda.io/icons/png/color/movie-128.png
youtube	https://cdn.coda.io/icons/png/color/youtube-128.png
pin	https://cdn.coda.io/icons/png/color/pin-128.png
sent	https://cdn.coda.io/icons/png/color/sent-128.png
email	https://cdn.coda.io/icons/png/color/email-128.png
astronaut	https://cdn.coda.io/icons/png/color/astronaut-128.png
download	https://cdn.coda.io/icons/png/color/download-128.png
alien	https://cdn.coda.io/icons/png/color/alien-128.png
word	https://cdn.coda.io/icons/png/color/word-128.png
powerpoint	https://cdn.coda.io/icons/png/color/powerpoint-128.png
clothes	https://cdn.coda.io/icons/png/color/clothes-128.png
snowboarding	https://cdn.coda.io/icons/png/color/snowboarding-128.png
read	https://cdn.coda.io/icons/png/color/read-128.png
reading	https://cdn.coda.io/icons/png/color/reading-128.png
puppy	https://cdn.coda.io/icons/png/color/puppy-128.png
music	https://cdn.coda.io/icons/png/color/music-128.png
piano	https://cdn.coda.io/icons/png/color/piano-128.png
baby	https://cdn.coda.io/icons/png/color/baby-128.png
bat	https://cdn.coda.io/icons/png/color/bat-128.png
superman	https://cdn.coda.io/icons/png/color/superman-128.png
kiss	https://cdn.coda.io/icons/png/color/kiss-128.png
panda	https://cdn.coda.io/icons/png/color/panda-128.png
erase	https://cdn.coda.io/icons/png/color/erase-128.png
check	https://cdn.coda.io/icons/png/color/check-128.png
card security	https://cdn.coda.io/icons/png/color/card-security-128.png
shopping cart	https://cdn.coda.io/icons/png/color/shopping-cart-128.png
buy	https://cdn.coda.io/icons/png/color/buy-128.png
buy upgrade	https://cdn.coda.io/icons/png/color/buy-upgrade-128.png
budget	https://cdn.coda.io/icons/png/color/budget-128.png
collect	https://cdn.coda.io/icons/png/color/collect-128.png
face id	https://cdn.coda.io/icons/png/color/face-id-128.png
touch id	https://cdn.coda.io/icons/png/color/touch-id-128.png
shopping basket	https://cdn.coda.io/icons/png/color/shopping-basket-128.png
shopaholic	https://cdn.coda.io/icons/png/color/shopaholic-128.png
return	https://cdn.coda.io/icons/png/color/return-128.png
return purchase	https://cdn.coda.io/icons/png/color/return-purchase-128.png
march	https://cdn.coda.io/icons/png/color/march-128.png
clarinet	https://cdn.coda.io/icons/png/color/clarinet-128.png
flute	https://cdn.coda.io/icons/png/color/flute-128.png
guitar	https://cdn.coda.io/icons/png/color/guitar-128.png
drum set	https://cdn.coda.io/icons/png/color/drum-set-128.png
communication	https://cdn.coda.io/icons/png/color/communication-128.png
project	https://cdn.coda.io/icons/png/color/project-128.png
scan stock	https://cdn.coda.io/icons/png/color/scan-stock-128.png
scanner	https://cdn.coda.io/icons/png/color/scanner-128.png
pdf	https://cdn.coda.io/icons/png/color/pdf-128.png
doc	https://cdn.coda.io/icons/png/color/doc-128.png
coda	https://cdn.coda.io/icons/png/color/coda-128.png
january	https://cdn.coda.io/icons/png/color/january-128.png
february	https://cdn.coda.io/icons/png/color/february-128.png
april	https://cdn.coda.io/icons/png/color/april-128.png
may	https://cdn.coda.io/icons/png/color/may-128.png
june	https://cdn.coda.io/icons/png/color/june-128.png
july	https://cdn.coda.io/icons/png/color/july-128.png
august	https://cdn.coda.io/icons/png/color/august-128.png
september	https://cdn.coda.io/icons/png/color/september-128.png
october	https://cdn.coda.io/icons/png/color/october-128.png
november	https://cdn.coda.io/icons/png/color/november-128.png
december	https://cdn.coda.io/icons/png/color/december-128.png
present	https://cdn.coda.io/icons/png/color/present-128.png
gift	https://cdn.coda.io/icons/png/color/gift-128.png
future	https://cdn.coda.io/icons/png/color/future-128.png
chrome	https://cdn.coda.io/icons/png/color/chrome-128.png
safari	https://cdn.coda.io/icons/png/color/safari-128.png
rope	https://cdn.coda.io/icons/png/color/rope-128.png
tire	https://cdn.coda.io/icons/png/color/tire-128.png
jeep	https://cdn.coda.io/icons/png/color/jeep-128.png
qr code	https://cdn.coda.io/icons/png/color/qr-code-128.png
code	https://cdn.coda.io/icons/png/color/code-128.png
adr	https://cdn.coda.io/icons/png/color/adr-128.png
mind map	https://cdn.coda.io/icons/png/color/mind-map-128.png
delivery	https://cdn.coda.io/icons/png/color/delivery-128.png
door	https://cdn.coda.io/icons/png/color/door-128.png
parachute	https://cdn.coda.io/icons/png/color/parachute-128.png
fragile	https://cdn.coda.io/icons/png/color/fragile-128.png
shipped	https://cdn.coda.io/icons/png/color/shipped-128.png
package	https://cdn.coda.io/icons/png/color/package-128.png
label	https://cdn.coda.io/icons/png/color/label-128.png
wood	https://cdn.coda.io/icons/png/color/wood-128.png
atm	https://cdn.coda.io/icons/png/color/atm-128.png
visa	https://cdn.coda.io/icons/png/color/visa-128.png
paypal	https://cdn.coda.io/icons/png/color/paypal-128.png
mastercard	https://cdn.coda.io/icons/png/color/mastercard-128.png
stripe	https://cdn.coda.io/icons/png/color/stripe-128.png
bitcoin	https://cdn.coda.io/icons/png/color/bitcoin-128.png
discover	https://cdn.coda.io/icons/png/color/discover-128.png
gg	https://cdn.coda.io/icons/png/color/gg-128.png
average	https://cdn.coda.io/icons/png/color/average-128.png
average 2	https://cdn.coda.io/icons/png/color/average-2-128.png
cheap	https://cdn.coda.io/icons/png/color/cheap-128.png
cheap 2	https://cdn.coda.io/icons/png/color/cheap-2-128.png
expensive	https://cdn.coda.io/icons/png/color/expensive-128.png
low price	https://cdn.coda.io/icons/png/color/low-price-128.png
low price euro	https://cdn.coda.io/icons/png/color/low-price-euro-128.png
low price pound	https://cdn.coda.io/icons/png/color/low-price-pound-128.png
price comparison	https://cdn.coda.io/icons/png/color/price-comparison-128.png
additional	https://cdn.coda.io/icons/png/color/additional-128.png
art prices	https://cdn.coda.io/icons/png/color/art-prices-128.png
commodity	https://cdn.coda.io/icons/png/color/commodity-128.png
jewelry	https://cdn.coda.io/icons/png/color/jewelry-128.png
kitchenwares	https://cdn.coda.io/icons/png/color/kitchenwares-128.png
new product	https://cdn.coda.io/icons/png/color/new-product-128.png
not applicable	https://cdn.coda.io/icons/png/color/not-applicable-128.png
plush	https://cdn.coda.io/icons/png/color/plush-128.png
good quality	https://cdn.coda.io/icons/png/color/good-quality-128.png
poor quality	https://cdn.coda.io/icons/png/color/poor-quality-128.png
souvenirs	https://cdn.coda.io/icons/png/color/souvenirs-128.png
best seller	https://cdn.coda.io/icons/png/color/best-seller-128.png
discount	https://cdn.coda.io/icons/png/color/discount-128.png
new	https://cdn.coda.io/icons/png/color/new-128.png
today	https://cdn.coda.io/icons/png/color/today-128.png
yard sale	https://cdn.coda.io/icons/png/color/yard-sale-128.png
garage	https://cdn.coda.io/icons/png/color/garage-128.png
sale	https://cdn.coda.io/icons/png/color/sale-128.png
pay date	https://cdn.coda.io/icons/png/color/pay-date-128.png
gift card	https://cdn.coda.io/icons/png/color/gift-card-128.png
loyalty card	https://cdn.coda.io/icons/png/color/loyalty-card-128.png
membership card	https://cdn.coda.io/icons/png/color/membership-card-128.png
travel card	https://cdn.coda.io/icons/png/color/travel-card-128.png
address	https://cdn.coda.io/icons/png/color/address-128.png
track order	https://cdn.coda.io/icons/png/color/track-order-128.png
airplane landing	https://cdn.coda.io/icons/png/color/airplane-landing-128.png
around the globe	https://cdn.coda.io/icons/png/color/around-the-globe-128.png
box important	https://cdn.coda.io/icons/png/color/box-important-128.png
commercial	https://cdn.coda.io/icons/png/color/commercial-128.png
counter	https://cdn.coda.io/icons/png/color/counter-128.png
scooter	https://cdn.coda.io/icons/png/color/scooter-128.png
guarantee	https://cdn.coda.io/icons/png/color/guarantee-128.png
headset	https://cdn.coda.io/icons/png/color/headset-128.png
warehouse	https://cdn.coda.io/icons/png/color/warehouse-128.png
warranty	https://cdn.coda.io/icons/png/color/warranty-128.png
bonds	https://cdn.coda.io/icons/png/color/bonds-128.png
collectibles	https://cdn.coda.io/icons/png/color/collectibles-128.png
equity security	https://cdn.coda.io/icons/png/color/equity-security-128.png
accuracy	https://cdn.coda.io/icons/png/color/accuracy-128.png
address book	https://cdn.coda.io/icons/png/color/address-book-128.png
answers	https://cdn.coda.io/icons/png/color/answers-128.png
resume	https://cdn.coda.io/icons/png/color/resume-128.png
attract customers	https://cdn.coda.io/icons/png/color/attract-customers-128.png
client management	https://cdn.coda.io/icons/png/color/client-management-128.png
collaboration	https://cdn.coda.io/icons/png/color/collaboration-128.png
complaint	https://cdn.coda.io/icons/png/color/complaint-128.png
conference	https://cdn.coda.io/icons/png/color/conference-128.png
conflict	https://cdn.coda.io/icons/png/color/conflict-128.png
contract	https://cdn.coda.io/icons/png/color/contract-128.png
cover up	https://cdn.coda.io/icons/png/color/cover-up-128.png
broom	https://cdn.coda.io/icons/png/color/broom-128.png
customer insight	https://cdn.coda.io/icons/png/color/customer-insight-128.png
idea	https://cdn.coda.io/icons/png/color/idea-128.png
idea bank	https://cdn.coda.io/icons/png/color/idea-bank-128.png
inspection	https://cdn.coda.io/icons/png/color/inspection-128.png
internet	https://cdn.coda.io/icons/png/color/internet-128.png
inter	https://cdn.coda.io/icons/png/color/inter-128.png
invite	https://cdn.coda.io/icons/png/color/invite-128.png
invitation	https://cdn.coda.io/icons/png/color/invitation-128.png
privacy	https://cdn.coda.io/icons/png/color/privacy-128.png
privacy policy	https://cdn.coda.io/icons/png/color/privacy-policy-128.png
teamwork	https://cdn.coda.io/icons/png/color/teamwork-128.png
trust	https://cdn.coda.io/icons/png/color/trust-128.png
zoom	https://cdn.coda.io/icons/png/color/zoom-128.png
slack	https://cdn.coda.io/icons/png/color/slack-128.png
manager	https://cdn.coda.io/icons/png/color/manager-128.png
supplier	https://cdn.coda.io/icons/png/color/supplier-128.png
vip	https://cdn.coda.io/icons/png/color/vip-128.png
technical support	https://cdn.coda.io/icons/png/color/technical-support-128.png
reminder	https://cdn.coda.io/icons/png/color/reminder-128.png
collect	https://cdn.coda.io/icons/png/color/collect-128.png
approval	https://cdn.coda.io/icons/png/color/approval-128.png
high priority	https://cdn.coda.io/icons/png/color/high-priority-128.png
low priority	https://cdn.coda.io/icons/png/color/low-priority-128.png
journal	https://cdn.coda.io/icons/png/color/journal-128.png
group of projects	https://cdn.coda.io/icons/png/color/group-of-projects-128.png
expired	https://cdn.coda.io/icons/png/color/expired-128.png
goal	https://cdn.coda.io/icons/png/color/goal-128.png
leave	https://cdn.coda.io/icons/png/color/leave-128.png
sprint iteration	https://cdn.coda.io/icons/png/color/sprint-iteration-128.png
medium priority	https://cdn.coda.io/icons/png/color/medium-priority-128.png
task completed	https://cdn.coda.io/icons/png/color/task-completed-128.png
tasks	https://cdn.coda.io/icons/png/color/tasks-128.png
barbershop	https://cdn.coda.io/icons/png/color/barbershop-128.png
blog	https://cdn.coda.io/icons/png/color/blog-128.png
department	https://cdn.coda.io/icons/png/color/department-128.png
growing money	https://cdn.coda.io/icons/png/color/growing-money-128.png
travel agency	https://cdn.coda.io/icons/png/color/travel-agency-128.png
area chart	https://cdn.coda.io/icons/png/color/area-chart-128.png
bar chart	https://cdn.coda.io/icons/png/color/bar-chart-128.png
pie chart	https://cdn.coda.io/icons/png/color/pie-chart-128.png
close position	https://cdn.coda.io/icons/png/color/close-position-128.png
combo chart	https://cdn.coda.io/icons/png/color/combo-chart-128.png
doughnut chart	https://cdn.coda.io/icons/png/color/doughnut-chart-128.png
futures	https://cdn.coda.io/icons/png/color/futures-128.png
gantt chart	https://cdn.coda.io/icons/png/color/gantt-chart-128.png
options	https://cdn.coda.io/icons/png/color/options-128.png
timeline	https://cdn.coda.io/icons/png/color/timeline-128.png
vertical timeline	https://cdn.coda.io/icons/png/color/vertical-timeline-128.png
workflow	https://cdn.coda.io/icons/png/color/workflow-128.png
bubble	https://cdn.coda.io/icons/png/color/bubble-128.png
hockey	https://cdn.coda.io/icons/png/color/hockey-128.png
copy	https://cdn.coda.io/icons/png/color/copy-128.png
creative commons	https://cdn.coda.io/icons/png/color/creative-commons-128.png
copyright	https://cdn.coda.io/icons/png/color/copyright-128.png
trademark	https://cdn.coda.io/icons/png/color/trademark-128.png
cent	https://cdn.coda.io/icons/png/color/cent-128.png
approve	https://cdn.coda.io/icons/png/color/approve-128.png
bad decision	https://cdn.coda.io/icons/png/color/bad-decision-128.png
decision	https://cdn.coda.io/icons/png/color/decision-128.png
disapprove	https://cdn.coda.io/icons/png/color/disapprove-128.png
agreement	https://cdn.coda.io/icons/png/color/agreement-128.png
brief	https://cdn.coda.io/icons/png/color/brief-128.png
change	https://cdn.coda.io/icons/png/color/change-128.png
hand with pen	https://cdn.coda.io/icons/png/color/hand-with-pen-128.png
rubber stamp	https://cdn.coda.io/icons/png/color/rubber-stamp-128.png
terms and conditions	https://cdn.coda.io/icons/png/color/terms-and-conditions-128.png
work instructions	https://cdn.coda.io/icons/png/color/work-instructions-128.png
internship	https://cdn.coda.io/icons/png/color/internship-128.png
school	https://cdn.coda.io/icons/png/color/school-128.png
teacher	https://cdn.coda.io/icons/png/color/teacher-128.png
students	https://cdn.coda.io/icons/png/color/students-128.png
blockchain	https://cdn.coda.io/icons/png/color/blockchain-128.png
bounced check	https://cdn.coda.io/icons/png/color/bounced-check-128.png
cashbook	https://cdn.coda.io/icons/png/color/cashbook-128.png
coins	https://cdn.coda.io/icons/png/color/coins-128.png
credit control	https://cdn.coda.io/icons/png/color/credit-control-128.png
debt	https://cdn.coda.io/icons/png/color/debt-128.png
deposit	https://cdn.coda.io/icons/png/color/deposit-128.png
exchange	https://cdn.coda.io/icons/png/color/exchange-128.png
fund accounting	https://cdn.coda.io/icons/png/color/fund-accounting-128.png
money transfer	https://cdn.coda.io/icons/png/color/money-transfer-128.png
initiate money transfer	https://cdn.coda.io/icons/png/color/initiate-money-transfer-128.png
paycheque	https://cdn.coda.io/icons/png/color/paycheque-128.png
stripe	https://cdn.coda.io/icons/png/color/stripe-128.png
tax	https://cdn.coda.io/icons/png/color/tax-128.png
transaction list	https://cdn.coda.io/icons/png/color/transaction-list-128.png
withdrawal	https://cdn.coda.io/icons/png/color/withdrawal-128.png
badge	https://cdn.coda.io/icons/png/color/badge-128.png
permanent job	https://cdn.coda.io/icons/png/color/permanent-job-128.png
time card	https://cdn.coda.io/icons/png/color/time-card-128.png
europe news	https://cdn.coda.io/icons/png/color/europe-news-128.png
news	https://cdn.coda.io/icons/png/color/news-128.png
radio	https://cdn.coda.io/icons/png/color/radio-128.png
tasklist	https://cdn.coda.io/icons/png/color/tasklist-128.png
disclaimer	https://cdn.coda.io/icons/png/color/disclaimer-128.png
innovation	https://cdn.coda.io/icons/png/color/innovation-128.png
ratings	https://cdn.coda.io/icons/png/color/ratings-128.png
prototype	https://cdn.coda.io/icons/png/color/prototype-128.png
proactivity	https://cdn.coda.io/icons/png/color/proactivity-128.png
video projector	https://cdn.coda.io/icons/png/color/video-projector-128.png
vision	https://cdn.coda.io/icons/png/color/vision-128.png
remove ads	https://cdn.coda.io/icons/png/color/remove-ads-128.png
service	https://cdn.coda.io/icons/png/color/service-128.png
accounting	https://cdn.coda.io/icons/png/color/accounting-128.png
satisfaction	https://cdn.coda.io/icons/png/color/satisfaction-128.png
star of david	https://cdn.coda.io/icons/png/color/star-of-david-128.png
star wars	https://cdn.coda.io/icons/png/color/star-wars-128.png
ticket	https://cdn.coda.io/icons/png/color/ticket-128.png
satellite	https://cdn.coda.io/icons/png/color/satellite-128.png
rainbow	https://cdn.coda.io/icons/png/color/rainbow-128.png
dinosaur	https://cdn.coda.io/icons/png/color/dinosaur-128.png
netflix	https://cdn.coda.io/icons/png/color/netflix-128.png
hp	https://cdn.coda.io/icons/png/color/hp-128.png
acupuncture	https://cdn.coda.io/icons/png/color/acupuncture-128.png
bread	https://cdn.coda.io/icons/png/color/bread-128.png
bug	https://cdn.coda.io/icons/png/color/bug-128.png
butterfly	https://cdn.coda.io/icons/png/color/butterfly-128.png
spider	https://cdn.coda.io/icons/png/color/spider-128.png
bull	https://cdn.coda.io/icons/png/color/bull-128.png
trotting horse	https://cdn.coda.io/icons/png/color/trotting-horse-128.png
horse	https://cdn.coda.io/icons/png/color/horse-128.png
dog house	https://cdn.coda.io/icons/png/color/dog-house-128.png
aquarium	https://cdn.coda.io/icons/png/color/aquarium-128.png
cat butt	https://cdn.coda.io/icons/png/color/cat-butt-128.png
cat footprint	https://cdn.coda.io/icons/png/color/cat-footprint-128.png
dog house	https://cdn.coda.io/icons/png/color/dog-house-128.png
dog park	https://cdn.coda.io/icons/png/color/dog-park-128.png
dog paw print	https://cdn.coda.io/icons/png/color/dog-paw-print-128.png
pixel cat	https://cdn.coda.io/icons/png/color/pixel-cat-128.png
crab	https://cdn.coda.io/icons/png/color/crab-128.png
orca	https://cdn.coda.io/icons/png/color/orca-128.png
prawn	https://cdn.coda.io/icons/png/color/prawn-128.png
pixel cat	https://cdn.coda.io/icons/png/color/pixel-cat-128.png
bear footprint	https://cdn.coda.io/icons/png/color/bear-footprint-128.png
kiss panda	https://cdn.coda.io/icons/png/color/kiss-panda-128.png
skeleton	https://cdn.coda.io/icons/png/color/skeleton-128.png
skull	https://cdn.coda.io/icons/png/color/skull-128.png
grasshopper	https://cdn.coda.io/icons/png/color/grasshopper-128.png
caterpillar	https://cdn.coda.io/icons/png/color/caterpillar-128.png
dragonfly	https://cdn.coda.io/icons/png/color/dragonfly-128.png
insect	https://cdn.coda.io/icons/png/color/insect-128.png
ladybird	https://cdn.coda.io/icons/png/color/ladybird-128.png
wasp	https://cdn.coda.io/icons/png/color/wasp-128.png
mosquito	https://cdn.coda.io/icons/png/color/mosquito-128.png
chicken ladder	https://cdn.coda.io/icons/png/color/chicken-ladder-128.png
sheep	https://cdn.coda.io/icons/png/color/sheep-128.png
sheep on bike	https://cdn.coda.io/icons/png/color/sheep-on-bike-128.png
dog bone	https://cdn.coda.io/icons/png/color/dog-bone-128.png
corgi	https://cdn.coda.io/icons/png/color/corgi-128.png
turtle	https://cdn.coda.io/icons/png/color/turtle-128.png
dog leash	https://cdn.coda.io/icons/png/color/dog-leash-128.png
dog bowl	https://cdn.coda.io/icons/png/color/dog-bowl-128.png
clown fish	https://cdn.coda.io/icons/png/color/clown-fish-128.png
coral	https://cdn.coda.io/icons/png/color/coral-128.png
prawn	https://cdn.coda.io/icons/png/color/prawn-128.png
tail of whale	https://cdn.coda.io/icons/png/color/tail-of-whale-128.png
tentacles	https://cdn.coda.io/icons/png/color/tentacles-128.png
whale	https://cdn.coda.io/icons/png/color/whale-128.png
fish	https://cdn.coda.io/icons/png/color/fish-128.png
alligator	https://cdn.coda.io/icons/png/color/alligator-128.png
beaver	https://cdn.coda.io/icons/png/color/beaver-128.png
black jaguar	https://cdn.coda.io/icons/png/color/black-jaguar-128.png
deer	https://cdn.coda.io/icons/png/color/deer-128.png
giraffe	https://cdn.coda.io/icons/png/color/giraffe-128.png
llama	https://cdn.coda.io/icons/png/color/llama-128.png
rabbit	https://cdn.coda.io/icons/png/color/rabbit-128.png
wolf	https://cdn.coda.io/icons/png/color/wolf-128.png
slug	https://cdn.coda.io/icons/png/color/slug-128.png
slug eating	https://cdn.coda.io/icons/png/color/slug-eating-128.png
Gorilla	https://cdn.coda.io/icons/png/color/gorilla-128.png
Big drop	https://cdn.coda.io/icons/png/color/big-drop-128.png
carousel	https://cdn.coda.io/icons/png/color/carousel-128.png
circus cannon	https://cdn.coda.io/icons/png/color/circus-cannon-128.png
circus tent	https://cdn.coda.io/icons/png/color/circus-tent-128.png
claw machine	https://cdn.coda.io/icons/png/color/claw-machine-128.png
cotton candy	https://cdn.coda.io/icons/png/color/cotton-candy-128.png
elephant circus	https://cdn.coda.io/icons/png/color/elephant-circus-128.png
fog machine	https://cdn.coda.io/icons/png/color/fog-machine-128.png
fountain	https://cdn.coda.io/icons/png/color/fountain-128.png
heart balloon	https://cdn.coda.io/icons/png/color/heart-balloon-128.png
roller coaster	https://cdn.coda.io/icons/png/color/roller-coaster-128.png
seal circus	https://cdn.coda.io/icons/png/color/seal-circus-128.png
seesaw	https://cdn.coda.io/icons/png/color/seesaw-128.png
skeeball	https://cdn.coda.io/icons/png/color/skeeball-128.png
swinging boat	https://cdn.coda.io/icons/png/color/swinging-boat-128.png
water park	https://cdn.coda.io/icons/png/color/water-park-128.png
bank	https://cdn.coda.io/icons/png/color/bank-128.png
bank building	https://cdn.coda.io/icons/png/color/bank-building-128.png
elevator	https://cdn.coda.io/icons/png/color/elevator-128.png
palace	https://cdn.coda.io/icons/png/color/palace-128.png
parking	https://cdn.coda.io/icons/png/color/parking-128.png
city	https://cdn.coda.io/icons/png/color/city-128.png
restaurant	https://cdn.coda.io/icons/png/color/restaurant-128.png
bus	https://cdn.coda.io/icons/png/color/bus-128.png
train	https://cdn.coda.io/icons/png/color/train-128.png
city railway station	https://cdn.coda.io/icons/png/color/city-railway-station-128.png
wharf	https://cdn.coda.io/icons/png/color/wharf-128.png
segway	https://cdn.coda.io/icons/png/color/segway-128.png
kick scooter	https://cdn.coda.io/icons/png/color/kick-scooter-128.png
cosh weapon	https://cdn.coda.io/icons/png/color/cosh-weapon-128.png
detain	https://cdn.coda.io/icons/png/color/detain-128.png
fat cop	https://cdn.coda.io/icons/png/color/fat-cop-128.png
bar	https://cdn.coda.io/icons/png/color/bar-128.png
meal	https://cdn.coda.io/icons/png/color/meal-128.png
food bar	https://cdn.coda.io/icons/png/color/food-bar-128.png
bar building	https://cdn.coda.io/icons/png/color/bar-building-128.png
obelisk	https://cdn.coda.io/icons/png/color/obelisk-128.png
statue	https://cdn.coda.io/icons/png/color/statue-128.png
archeology	https://cdn.coda.io/icons/png/color/archeology-128.png
parliament	https://cdn.coda.io/icons/png/color/parliament-128.png
embassy	https://cdn.coda.io/icons/png/color/embassy-128.png
elections	https://cdn.coda.io/icons/png/color/elections-128.png
chapel	https://cdn.coda.io/icons/png/color/chapel-128.png
church	https://cdn.coda.io/icons/png/color/church-128.png
city church	https://cdn.coda.io/icons/png/color/city-church-128.png
kaaba	https://cdn.coda.io/icons/png/color/kaaba-128.png
monastery	https://cdn.coda.io/icons/png/color/monastery-128.png
mosque	https://cdn.coda.io/icons/png/color/mosque-128.png
pagoda	https://cdn.coda.io/icons/png/color/pagoda-128.png
synagogue	https://cdn.coda.io/icons/png/color/synagogue-128.png
no diving	https://cdn.coda.io/icons/png/color/no-diving-128.png
no camera	https://cdn.coda.io/icons/png/color/no-camera-128.png
no cash	https://cdn.coda.io/icons/png/color/no-cash-128.png
no credit cards	https://cdn.coda.io/icons/png/color/no-credit-cards-128.png
no running	https://cdn.coda.io/icons/png/color/no-running-128.png
no selfie	https://cdn.coda.io/icons/png/color/no-selfie-128.png
no shoes	https://cdn.coda.io/icons/png/color/no-shoes-128.png
no yelling	https://cdn.coda.io/icons/png/color/no-yelling-128.png
no smoking	https://cdn.coda.io/icons/png/color/no-smoking-128.png
smoking	https://cdn.coda.io/icons/png/color/smoking-128.png
watch your step	https://cdn.coda.io/icons/png/color/watch-your-step-128.png
slippery floor	https://cdn.coda.io/icons/png/color/slippery-floor-128.png
taxi	https://cdn.coda.io/icons/png/color/taxi-128.png
airport	https://cdn.coda.io/icons/png/color/airport-128.png
animal shelter	https://cdn.coda.io/icons/png/color/animal-shelter-128.png
child safe zone	https://cdn.coda.io/icons/png/color/child-safe-zone-128.png
fire hazard	https://cdn.coda.io/icons/png/color/fire-hazard-128.png
fire hose	https://cdn.coda.io/icons/png/color/fire-hose-128.png
garbage truck	https://cdn.coda.io/icons/png/color/garbage-truck-128.png
hospital	https://cdn.coda.io/icons/png/color/hospital-128.png
flammable	https://cdn.coda.io/icons/png/color/flammable-128.png
fire sprinkler	https://cdn.coda.io/icons/png/color/fire-sprinkler-128.png
fire hydrant	https://cdn.coda.io/icons/png/color/fire-hydrant-128.png
factory	https://cdn.coda.io/icons/png/color/factory-128.png
market square	https://cdn.coda.io/icons/png/color/market-square-128.png
metal detector	https://cdn.coda.io/icons/png/color/metal-detector-128.png
pavilion	https://cdn.coda.io/icons/png/color/pavilion-128.png
parking ticket	https://cdn.coda.io/icons/png/color/parking-ticket-128.png
police station	https://cdn.coda.io/icons/png/color/police-station-128.png
post office	https://cdn.coda.io/icons/png/color/post-office-128.png
rope bridge	https://cdn.coda.io/icons/png/color/rope-bridge-128.png
prison	https://cdn.coda.io/icons/png/color/prison-128.png
stadium	https://cdn.coda.io/icons/png/color/stadium-128.png
swimming pool	https://cdn.coda.io/icons/png/color/swimming-pool-128.png
warehouse	https://cdn.coda.io/icons/png/color/warehouse-128.png
water tower	https://cdn.coda.io/icons/png/color/water-tower-128.png
co2	https://cdn.coda.io/icons/png/color/co2-128.png
poster	https://cdn.coda.io/icons/png/color/poster-128.png
billboard	https://cdn.coda.io/icons/png/color/billboard-128.png
foundation	https://cdn.coda.io/icons/png/color/foundation-128.png
hospital	https://cdn.coda.io/icons/png/color/hospital-128.png
doctor male	https://cdn.coda.io/icons/png/color/doctor-male-128.png
doctor female	https://cdn.coda.io/icons/png/color/doctor-female-128.png
nurse male	https://cdn.coda.io/icons/png/color/nurse-male-128.png
nurse female	https://cdn.coda.io/icons/png/color/nurse-female-128.png
fireman male	https://cdn.coda.io/icons/png/color/fireman-male-128.png
fireman female	https://cdn.coda.io/icons/png/color/fireman-female-128.png
cut	https://cdn.coda.io/icons/png/color/cut-128.png
scissors	https://cdn.coda.io/icons/png/color/scissors-128.png
law	https://cdn.coda.io/icons/png/color/law-128.png
sun glasses	https://cdn.coda.io/icons/png/color/sun-glasses-128.png
glasses	https://cdn.coda.io/icons/png/color/glasses-128.png
genie	https://cdn.coda.io/icons/png/color/genie-128.png
monster face	https://cdn.coda.io/icons/png/color/monster-face-128.png
mummy	https://cdn.coda.io/icons/png/color/mummy-128.png
scary tree	https://cdn.coda.io/icons/png/color/scary-tree-128.png
unicorn	https://cdn.coda.io/icons/png/color/unicorn-128.png
magic lamp	https://cdn.coda.io/icons/png/color/magic-lamp-128.png
comedy	https://cdn.coda.io/icons/png/color/comedy-128.png
crime	https://cdn.coda.io/icons/png/color/crime-128.png
drama	https://cdn.coda.io/icons/png/color/drama-128.png
documentary	https://cdn.coda.io/icons/png/color/documentary-128.png
fantasy	https://cdn.coda.io/icons/png/color/fantasy-128.png
horror	https://cdn.coda.io/icons/png/color/horror-128.png
musical	https://cdn.coda.io/icons/png/color/musical-128.png
short	https://cdn.coda.io/icons/png/color/short-128.png
thriller	https://cdn.coda.io/icons/png/color/thriller-128.png
western	https://cdn.coda.io/icons/png/color/western-128.png
detective	https://cdn.coda.io/icons/png/color/detective-128.png
full moon	https://cdn.coda.io/icons/png/color/full-moon-128.png
new moon	https://cdn.coda.io/icons/png/color/new-moon-128.png
iron man	https://cdn.coda.io/icons/png/color/iron-man-128.png
kicking	https://cdn.coda.io/icons/png/color/kicking-128.png
punching	https://cdn.coda.io/icons/png/color/punching-128.png
scream	https://cdn.coda.io/icons/png/color/scream-128.png
binoculars	https://cdn.coda.io/icons/png/color/binoculars-128.png
3d glasses	https://cdn.coda.io/icons/png/color/3d-glasses-128.png
treasure chest	https://cdn.coda.io/icons/png/color/treasure-chest-128.png
diamond	https://cdn.coda.io/icons/png/color/diamond-128.png
sphere	https://cdn.coda.io/icons/png/color/sphere-128.png
top view	https://cdn.coda.io/icons/png/color/top-view-128.png
lock	https://cdn.coda.io/icons/png/color/lock-128.png
align justify	https://cdn.coda.io/icons/png/color/align-justify-128.png
align left	https://cdn.coda.io/icons/png/color/align-left-128.png
align center	https://cdn.coda.io/icons/png/color/align-center-128.png
bring forward	https://cdn.coda.io/icons/png/color/bring-forward-128.png
bring to front	https://cdn.coda.io/icons/png/color/bring-to-front-128.png
front sorting	https://cdn.coda.io/icons/png/color/front-sorting-128.png
rearrange	https://cdn.coda.io/icons/png/color/rearrange-128.png
send backward	https://cdn.coda.io/icons/png/color/send-backward-128.png
send to back	https://cdn.coda.io/icons/png/color/send-to-back-128.png
border horizontal	https://cdn.coda.io/icons/png/color/border-horizontal-128.png
document header	https://cdn.coda.io/icons/png/color/document-header-128.png
fold	https://cdn.coda.io/icons/png/color/fold-128.png
header	https://cdn.coda.io/icons/png/color/header-128.png
merge	https://cdn.coda.io/icons/png/color/merge-128.png
paste	https://cdn.coda.io/icons/png/color/paste-128.png
sign up	https://cdn.coda.io/icons/png/color/sign-up-128.png
undo	https://cdn.coda.io/icons/png/color/undo-128.png
unfold	https://cdn.coda.io/icons/png/color/unfold-128.png
hand drawn star	https://cdn.coda.io/icons/png/color/hand-drawn-star-128.png
hexagon	https://cdn.coda.io/icons/png/color/hexagon-128.png
polygon	https://cdn.coda.io/icons/png/color/polygon-128.png
polyline	https://cdn.coda.io/icons/png/color/polyline-128.png
rounded rectangle	https://cdn.coda.io/icons/png/color/rounded-rectangle-128.png
rhombus	https://cdn.coda.io/icons/png/color/rhombus-128.png
metamorphose	https://cdn.coda.io/icons/png/color/metamorphose-128.png
starburst shape	https://cdn.coda.io/icons/png/color/starburst-shape-128.png
blur	https://cdn.coda.io/icons/png/color/blur-128.png
color dropper	https://cdn.coda.io/icons/png/color/color-dropper-128.png
chisel tip marker	https://cdn.coda.io/icons/png/color/chisel-tip-marker-128.png
ball point pen	https://cdn.coda.io/icons/png/color/ball-point-pen-128.png
crop	https://cdn.coda.io/icons/png/color/crop-128.png
dashed line	https://cdn.coda.io/icons/png/color/dashed-line-128.png
fill color	https://cdn.coda.io/icons/png/color/fill-color-128.png
flip horizontal	https://cdn.coda.io/icons/png/color/flip-horizontal-128.png
flip vertical	https://cdn.coda.io/icons/png/color/flip-vertical-128.png
full image	https://cdn.coda.io/icons/png/color/full-image-128.png
invert selection	https://cdn.coda.io/icons/png/color/invert-selection-128.png
edit image	https://cdn.coda.io/icons/png/color/edit-image-128.png
line	https://cdn.coda.io/icons/png/color/line-128.png
line width	https://cdn.coda.io/icons/png/color/line-width-128.png
pen	https://cdn.coda.io/icons/png/color/pen-128.png
resize	https://cdn.coda.io/icons/png/color/resize-128.png
zoom in	https://cdn.coda.io/icons/png/color/zoom-in-128.png
zoom out	https://cdn.coda.io/icons/png/color/zoom-out-128.png
360 view	https://cdn.coda.io/icons/png/color/360-view-128.png
categorize	https://cdn.coda.io/icons/png/color/categorize-128.png
four squares	https://cdn.coda.io/icons/png/color/four-squares-128.png
bulleted list	https://cdn.coda.io/icons/png/color/bulleted-list-128.png
list	https://cdn.coda.io/icons/png/color/list-128.png
medium icons	https://cdn.coda.io/icons/png/color/medium-icons-128.png
small icons	https://cdn.coda.io/icons/png/color/small-icons-128.png
content	https://cdn.coda.io/icons/png/color/content-128.png
details	https://cdn.coda.io/icons/png/color/details-128.png
xlarge icons	https://cdn.coda.io/icons/png/color/xlarge-icons-128.png
collapse	https://cdn.coda.io/icons/png/color/collapse-128.png
compress	https://cdn.coda.io/icons/png/color/compress-128.png
expand	https://cdn.coda.io/icons/png/color/expand-128.png
resize	https://cdn.coda.io/icons/png/color/resize-128.png
drag list up	https://cdn.coda.io/icons/png/color/drag-list-up-128.png
drag reorder	https://cdn.coda.io/icons/png/color/drag-reorder-128.png
header 1	https://cdn.coda.io/icons/png/color/header-1-128.png
header 2	https://cdn.coda.io/icons/png/color/header-2-128.png
header 3	https://cdn.coda.io/icons/png/color/header-3-128.png
indent	https://cdn.coda.io/icons/png/color/indent-128.png
insert white space	https://cdn.coda.io/icons/png/color/insert-white-space-128.png
select all	https://cdn.coda.io/icons/png/color/select-all-128.png
text	https://cdn.coda.io/icons/png/color/text-128.png
term	https://cdn.coda.io/icons/png/color/term-128.png
underline	https://cdn.coda.io/icons/png/color/underline-128.png
uppercase	https://cdn.coda.io/icons/png/color/uppercase-128.png
type	https://cdn.coda.io/icons/png/color/type-128.png
paper waste	https://cdn.coda.io/icons/png/color/paper-waste-128.png
clipboard	https://cdn.coda.io/icons/png/color/clipboard-128.png
drafting compass	https://cdn.coda.io/icons/png/color/drafting-compass-128.png
glue	https://cdn.coda.io/icons/png/color/glue-128.png
hole punch	https://cdn.coda.io/icons/png/color/hole-punch-128.png
paper clamp	https://cdn.coda.io/icons/png/color/paper-clamp-128.png
stanley knife	https://cdn.coda.io/icons/png/color/stanley-knife-128.png
stapler	https://cdn.coda.io/icons/png/color/stapler-128.png
stationery	https://cdn.coda.io/icons/png/color/stationery-128.png
pencil sharpener	https://cdn.coda.io/icons/png/color/pencil-sharpener-128.png
quill with ink	https://cdn.coda.io/icons/png/color/quill-with-ink-128.png
feed paper	https://cdn.coda.io/icons/png/color/feed-paper-128.png
stack of paper	https://cdn.coda.io/icons/png/color/stack-of-paper-128.png
journal	https://cdn.coda.io/icons/png/color/journal-128.png
printer error	https://cdn.coda.io/icons/png/color/printer-error-128.png
printer out of paper	https://cdn.coda.io/icons/png/color/printer-out-of-paper-128.png
send to printer	https://cdn.coda.io/icons/png/color/send-to-printer-128.png
cloud print	https://cdn.coda.io/icons/png/color/cloud-print-128.png
two pages	https://cdn.coda.io/icons/png/color/two-pages-128.png
typewriter with paper	https://cdn.coda.io/icons/png/color/typewriter-with-paper-128.png
automotive	https://cdn.coda.io/icons/png/color/automotive-128.png
dam	https://cdn.coda.io/icons/png/color/dam-128.png
deployment	https://cdn.coda.io/icons/png/color/deployment-128.png
overhead crane	https://cdn.coda.io/icons/png/color/overhead-crane-128.png
capacitor symbol	https://cdn.coda.io/icons/png/color/capacitor-symbol-128.png
electricity	https://cdn.coda.io/icons/png/color/electricity-128.png
ground symbol	https://cdn.coda.io/icons/png/color/ground-symbol-128.png
capacitor	https://cdn.coda.io/icons/png/color/capacitor-128.png
carbon brush	https://cdn.coda.io/icons/png/color/carbon-brush-128.png
circuit	https://cdn.coda.io/icons/png/color/circuit-128.png
display	https://cdn.coda.io/icons/png/color/display-128.png
electronics	https://cdn.coda.io/icons/png/color/electronics-128.png
integrated circuit	https://cdn.coda.io/icons/png/color/integrated-circuit-128.png
lcd	https://cdn.coda.io/icons/png/color/lcd-128.png
led diode	https://cdn.coda.io/icons/png/color/led-diode-128.png
transistor	https://cdn.coda.io/icons/png/color/transistor-128.png
resistor	https://cdn.coda.io/icons/png/color/resistor-128.png
processor	https://cdn.coda.io/icons/png/color/processor-128.png
relay	https://cdn.coda.io/icons/png/color/relay-128.png
oil rig	https://cdn.coda.io/icons/png/color/oil-rig-128.png
bulk gas tanker	https://cdn.coda.io/icons/png/color/bulk-gas-tanker-128.png
gas bottle	https://cdn.coda.io/icons/png/color/gas-bottle-128.png
gas industry	https://cdn.coda.io/icons/png/color/gas-industry-128.png
gas rig	https://cdn.coda.io/icons/png/color/gas-rig-128.png
oil industry	https://cdn.coda.io/icons/png/color/oil-industry-128.png
biomass	https://cdn.coda.io/icons/png/color/biomass-128.png
biotech	https://cdn.coda.io/icons/png/color/biotech-128.png
eco fuel	https://cdn.coda.io/icons/png/color/eco-fuel-128.png
reuse	https://cdn.coda.io/icons/png/color/reuse-128.png
solar panel	https://cdn.coda.io/icons/png/color/solar-panel-128.png
wind turbine	https://cdn.coda.io/icons/png/color/wind-turbine-128.png
water wheel	https://cdn.coda.io/icons/png/color/water-wheel-128.png
hydroelectric	https://cdn.coda.io/icons/png/color/hydroelectric-128.png
geothermal	https://cdn.coda.io/icons/png/color/geothermal-128.png
bot	https://cdn.coda.io/icons/png/color/bot-128.png
robot	https://cdn.coda.io/icons/png/color/robot-128.png
automatic	https://cdn.coda.io/icons/png/color/automatic-128.png
broken robot	https://cdn.coda.io/icons/png/color/broken-robot-128.png
engineering	https://cdn.coda.io/icons/png/color/engineering-128.png
flicker free	https://cdn.coda.io/icons/png/color/flicker-free-128.png
flickr	https://cdn.coda.io/icons/png/color/flickr-128.png
hologram	https://cdn.coda.io/icons/png/color/hologram-128.png
prism	https://cdn.coda.io/icons/png/color/prism-128.png
laser beam	https://cdn.coda.io/icons/png/color/laser-beam-128.png
manual	https://cdn.coda.io/icons/png/color/manual-128.png
microscope	https://cdn.coda.io/icons/png/color/microscope-128.png
motion detector	https://cdn.coda.io/icons/png/color/motion-detector-128.png
radar	https://cdn.coda.io/icons/png/color/radar-128.png
sensor	https://cdn.coda.io/icons/png/color/sensor-128.png
wearable technology	https://cdn.coda.io/icons/png/color/wearable-technology-128.png
fork lift	https://cdn.coda.io/icons/png/color/fork-lift-128.png
scoop lighting	https://cdn.coda.io/icons/png/color/scoop-lighting-128.png
searchlight	https://cdn.coda.io/icons/png/color/searchlight-128.png
spotlight	https://cdn.coda.io/icons/png/color/spotlight-128.png
fresnel lantern	https://cdn.coda.io/icons/png/color/fresnel-lantern-128.png
cloth	https://cdn.coda.io/icons/png/color/cloth-128.png
coal	https://cdn.coda.io/icons/png/color/coal-128.png
gold bars	https://cdn.coda.io/icons/png/color/gold-bars-128.png
rfid sensor	https://cdn.coda.io/icons/png/color/rfid-sensor-128.png
rfid signal	https://cdn.coda.io/icons/png/color/rfid-signal-128.png
rfid tag	https://cdn.coda.io/icons/png/color/rfid-tag-128.png
radio tower	https://cdn.coda.io/icons/png/color/radio-tower-128.png
radio	https://cdn.coda.io/icons/png/color/radio-128.png
industrial scales	https://cdn.coda.io/icons/png/color/industrial-scales-128.png
scale	https://cdn.coda.io/icons/png/color/scale-128.png
jog reverse	https://cdn.coda.io/icons/png/color/jog-reverse-128.png
do not mix	https://cdn.coda.io/icons/png/color/do-not-mix-128.png
do not insert	https://cdn.coda.io/icons/png/color/do-not-insert-128.png
jog forward	https://cdn.coda.io/icons/png/color/jog-forward-128.png
plug 3	https://cdn.coda.io/icons/png/color/plug-3-128.png
plug 4	https://cdn.coda.io/icons/png/color/plug-4-128.png
blueprint	https://cdn.coda.io/icons/png/color/blueprint-128.png
chamfer	https://cdn.coda.io/icons/png/color/chamfer-128.png
gears	https://cdn.coda.io/icons/png/color/gears-128.png
gyroscope	https://cdn.coda.io/icons/png/color/gyroscope-128.png
land surveying	https://cdn.coda.io/icons/png/color/land-surveying-128.png
metal	https://cdn.coda.io/icons/png/color/metal-128.png
pallet	https://cdn.coda.io/icons/png/color/pallet-128.png
rack	https://cdn.coda.io/icons/png/color/rack-128.png
soldering iron	https://cdn.coda.io/icons/png/color/soldering-iron-128.png
threading metal	https://cdn.coda.io/icons/png/color/threading-metal-128.png
spring in motion	https://cdn.coda.io/icons/png/color/spring-in-motion-128.png
poison	https://cdn.coda.io/icons/png/color/poison-128.png
worker female	https://cdn.coda.io/icons/png/color/worker-female-128.png
worker male	https://cdn.coda.io/icons/png/color/worker-male-128.png
elderly person	https://cdn.coda.io/icons/png/color/elderly-person-128.png
head profile	https://cdn.coda.io/icons/png/color/head-profile-128.png
pointing at self	https://cdn.coda.io/icons/png/color/pointing-at-self-128.png
chef hat	https://cdn.coda.io/icons/png/color/chef-hat-128.png
geisha	https://cdn.coda.io/icons/png/color/geisha-128.png
butting in	https://cdn.coda.io/icons/png/color/butting-in-128.png
racism	https://cdn.coda.io/icons/png/color/racism-128.png
shit hits fan	https://cdn.coda.io/icons/png/color/shit-hits-fan-128.png
suppression	https://cdn.coda.io/icons/png/color/suppression-128.png
thirst	https://cdn.coda.io/icons/png/color/thirst-128.png
trust	https://cdn.coda.io/icons/png/color/trust-128.png
family	https://cdn.coda.io/icons/png/color/family-128.png
refugee	https://cdn.coda.io/icons/png/color/refugee-128.png
standing man	https://cdn.coda.io/icons/png/color/standing-man-128.png
population return	https://cdn.coda.io/icons/png/color/population-return-128.png
crowd	https://cdn.coda.io/icons/png/color/crowd-128.png
map	https://cdn.coda.io/icons/png/color/map-128.png
map editing	https://cdn.coda.io/icons/png/color/map-editing-128.png
quest	https://cdn.coda.io/icons/png/color/quest-128.png
treasure map	https://cdn.coda.io/icons/png/color/treasure-map-128.png
north	https://cdn.coda.io/icons/png/color/north-128.png
south	https://cdn.coda.io/icons/png/color/south-128.png
east	https://cdn.coda.io/icons/png/color/east-128.png
west	https://cdn.coda.io/icons/png/color/west-128.png
north direction	https://cdn.coda.io/icons/png/color/north-direction-128.png
south direction	https://cdn.coda.io/icons/png/color/south-direction-128.png
west direction	https://cdn.coda.io/icons/png/color/west-direction-128.png
east direction	https://cdn.coda.io/icons/png/color/east-direction-128.png
germany map	https://cdn.coda.io/icons/png/color/germany-map-128.png
place marker	https://cdn.coda.io/icons/png/color/place-marker-128.png
visit	https://cdn.coda.io/icons/png/color/visit-128.png
marker	https://cdn.coda.io/icons/png/color/marker-128.png
america	https://cdn.coda.io/icons/png/color/america-128.png
map pin	https://cdn.coda.io/icons/png/color/map-pin-128.png
orientation	https://cdn.coda.io/icons/png/color/orientation-128.png
near me	https://cdn.coda.io/icons/png/color/near-me-128.png
country	https://cdn.coda.io/icons/png/color/country-128.png
azimuth	https://cdn.coda.io/icons/png/color/azimuth-128.png
compass	https://cdn.coda.io/icons/png/color/compass-128.png
compass east	https://cdn.coda.io/icons/png/color/compass-east-128.png
compass north	https://cdn.coda.io/icons/png/color/compass-north-128.png
compass west	https://cdn.coda.io/icons/png/color/compass-west-128.png
compass east	https://cdn.coda.io/icons/png/color/compass-east-128.png
easy to find	https://cdn.coda.io/icons/png/color/easy-to-find-128.png
geocaching	https://cdn.coda.io/icons/png/color/geocaching-128.png
hard to find	https://cdn.coda.io/icons/png/color/hard-to-find-128.png
street view	https://cdn.coda.io/icons/png/color/street-view-128.png
sun elevation	https://cdn.coda.io/icons/png/color/sun-elevation-128.png
layers	https://cdn.coda.io/icons/png/color/layers-128.png
lighthouse	https://cdn.coda.io/icons/png/color/lighthouse-128.png
satellites	https://cdn.coda.io/icons/png/color/satellites-128.png
south south east	https://cdn.coda.io/icons/png/color/south-south-east-128.png
south south west	https://cdn.coda.io/icons/png/color/south-south-west-128.png
west north west	https://cdn.coda.io/icons/png/color/west-north-west-128.png
west south west	https://cdn.coda.io/icons/png/color/west-south-west-128.png
north north east	https://cdn.coda.io/icons/png/color/north-north-east-128.png
north north west	https://cdn.coda.io/icons/png/color/north-north-west-128.png
north east	https://cdn.coda.io/icons/png/color/north-east-128.png
east north east	https://cdn.coda.io/icons/png/color/east-north-east-128.png
alaska	https://cdn.coda.io/icons/png/color/alaska-128.png
arkansas	https://cdn.coda.io/icons/png/color/arkansas-128.png
colorado	https://cdn.coda.io/icons/png/color/colorado-128.png
arizona	https://cdn.coda.io/icons/png/color/arizona-128.png
california	https://cdn.coda.io/icons/png/color/california-128.png
connecticut	https://cdn.coda.io/icons/png/color/connecticut-128.png
delaware	https://cdn.coda.io/icons/png/color/delaware-128.png
District of Columbia	https://cdn.coda.io/icons/png/color/district-of-columbia-128.png
Florida	https://cdn.coda.io/icons/png/color/florida-128.png
Georgia	https://cdn.coda.io/icons/png/color/georgia-128.png
Hawaii	https://cdn.coda.io/icons/png/color/hawaii-128.png
Idaho	https://cdn.coda.io/icons/png/color/idaho-128.png
Illinois	https://cdn.coda.io/icons/png/color/illinois-128.png
Indiana	https://cdn.coda.io/icons/png/color/indiana-128.png
Iowa	https://cdn.coda.io/icons/png/color/iowa-128.png
Kansas	https://cdn.coda.io/icons/png/color/kansas-128.png
Kentucky	https://cdn.coda.io/icons/png/color/kentucky-128.png
Louisiana	https://cdn.coda.io/icons/png/color/louisiana-128.png
Maine	https://cdn.coda.io/icons/png/color/maine-128.png
Montana	https://cdn.coda.io/icons/png/color/montana-128.png
Nebraska	https://cdn.coda.io/icons/png/color/nebraska-128.png
Nevada	https://cdn.coda.io/icons/png/color/nevada-128.png
New Hampshire	https://cdn.coda.io/icons/png/color/new-hampshire-128.png
New Jersey	https://cdn.coda.io/icons/png/color/new-jersey-128.png
New Mexico	https://cdn.coda.io/icons/png/color/new-mexico-128.png
New York	https://cdn.coda.io/icons/png/color/new-york-128.png
North Carolina	https://cdn.coda.io/icons/png/color/north-carolina-128.png
North Dakota	https://cdn.coda.io/icons/png/color/north-dakota-128.png
Ohio	https://cdn.coda.io/icons/png/color/ohio-128.png
Oklahoma	https://cdn.coda.io/icons/png/color/oklahoma-128.png
Oregon	https://cdn.coda.io/icons/png/color/oregon-128.png
Maryland	https://cdn.coda.io/icons/png/color/maryland-128.png
Massachusetts	https://cdn.coda.io/icons/png/color/massachusetts-128.png
Michigan	https://cdn.coda.io/icons/png/color/michigan-128.png
Minnesota	https://cdn.coda.io/icons/png/color/minnesota-128.png
Mississippi	https://cdn.coda.io/icons/png/color/mississippi-128.png
Missouri	https://cdn.coda.io/icons/png/color/missouri-128.png
Pennsylvania	https://cdn.coda.io/icons/png/color/pennsylvania-128.png
Rhode Island	https://cdn.coda.io/icons/png/color/rhode-island-128.png
South Carolina	https://cdn.coda.io/icons/png/color/south-carolina-128.png
South Dakota	https://cdn.coda.io/icons/png/color/south-dakota-128.png
Tennessee	https://cdn.coda.io/icons/png/color/tennessee-128.png
Texas	https://cdn.coda.io/icons/png/color/texas-128.png
Utah	https://cdn.coda.io/icons/png/color/utah-128.png
Vermont	https://cdn.coda.io/icons/png/color/vermont-128.png
Virginia	https://cdn.coda.io/icons/png/color/virginia-128.png
Washington	https://cdn.coda.io/icons/png/color/washington-128.png
West Virginia	https://cdn.coda.io/icons/png/color/west-virginia-128.png
Wisconsin	https://cdn.coda.io/icons/png/color/wisconsin-128.png
Wyoming	https://cdn.coda.io/icons/png/color/wyoming-128.png
around the globe	https://cdn.coda.io/icons/png/color/around-the-globe-128.png
wikimapia	https://cdn.coda.io/icons/png/color/wikimapia-128.png
rain gauge	https://cdn.coda.io/icons/png/color/rain-gauge-128.png
high tide	https://cdn.coda.io/icons/png/color/high-tide-128.png
low tide	https://cdn.coda.io/icons/png/color/low-tide-128.png
avalanche	https://cdn.coda.io/icons/png/color/avalanche-128.png
drought	https://cdn.coda.io/icons/png/color/drought-128.png
earthquakes	https://cdn.coda.io/icons/png/color/earthquakes-128.png
fires	https://cdn.coda.io/icons/png/color/fires-128.png
floods	https://cdn.coda.io/icons/png/color/floods-128.png
hurricane	https://cdn.coda.io/icons/png/color/hurricane-128.png
tornado	https://cdn.coda.io/icons/png/color/tornado-128.png
tsunami	https://cdn.coda.io/icons/png/color/tsunami-128.png
wildfire	https://cdn.coda.io/icons/png/color/wildfire-128.png
hail	https://cdn.coda.io/icons/png/color/hail-128.png
snow storm	https://cdn.coda.io/icons/png/color/snow-storm-128.png
sleet	https://cdn.coda.io/icons/png/color/sleet-128.png
autumn	https://cdn.coda.io/icons/png/color/autumn-128.png
summer	https://cdn.coda.io/icons/png/color/summer-128.png
spring	https://cdn.coda.io/icons/png/color/spring-128.png
winter	https://cdn.coda.io/icons/png/color/winter-128.png
dry	https://cdn.coda.io/icons/png/color/dry-128.png
dust	https://cdn.coda.io/icons/png/color/dust-128.png
icy	https://cdn.coda.io/icons/png/color/icy-128.png
sunrise	https://cdn.coda.io/icons/png/color/sunrise-128.png
sunset	https://cdn.coda.io/icons/png/color/sunset-128.png
wet	https://cdn.coda.io/icons/png/color/wet-128.png
lightning bolt	https://cdn.coda.io/icons/png/color/lightning-bolt-128.png
windchill	https://cdn.coda.io/icons/png/color/windchill-128.png
virus free	https://cdn.coda.io/icons/png/color/virus-free-128.png
bandage	https://cdn.coda.io/icons/png/color/bandage-128.png
bug	https://cdn.coda.io/icons/png/color/bug-128.png
code	https://cdn.coda.io/icons/png/color/code-128.png
inspect code	https://cdn.coda.io/icons/png/color/inspect-code-128.png
code fork	https://cdn.coda.io/icons/png/color/code-fork-128.png
outline	https://cdn.coda.io/icons/png/color/outline-128.png
registry editor	https://cdn.coda.io/icons/png/color/registry-editor-128.png
variable	https://cdn.coda.io/icons/png/color/variable-128.png
xml	https://cdn.coda.io/icons/png/color/xml-128.png
xml transformer	https://cdn.coda.io/icons/png/color/xml-transformer-128.png
command line	https://cdn.coda.io/icons/png/color/command-line-128.png
console	https://cdn.coda.io/icons/png/color/console-128.png
run command	https://cdn.coda.io/icons/png/color/run-command-128.png
commit git	https://cdn.coda.io/icons/png/color/commit-git-128.png
compare git	https://cdn.coda.io/icons/png/color/compare-git-128.png
merge git	https://cdn.coda.io/icons/png/color/merge-git-128.png
pull request	https://cdn.coda.io/icons/png/color/pull-request-128.png
pin	https://cdn.coda.io/icons/png/color/pin-128.png
unpin	https://cdn.coda.io/icons/png/color/unpin-128.png
unpin 2	https://cdn.coda.io/icons/png/color/unpin-2-128.png
full version	https://cdn.coda.io/icons/png/color/full-version-128.png
program	https://cdn.coda.io/icons/png/color/program-128.png
add property	https://cdn.coda.io/icons/png/color/add-property-128.png
edit property	https://cdn.coda.io/icons/png/color/edit-property-128.png
important property	https://cdn.coda.io/icons/png/color/important-property-128.png
play property	https://cdn.coda.io/icons/png/color/play-property-128.png
property script	https://cdn.coda.io/icons/png/color/property-script-128.png
urgent property	https://cdn.coda.io/icons/png/color/urgent-property-128.png
api	https://cdn.coda.io/icons/png/color/api-128.png
markdown	https://cdn.coda.io/icons/png/color/markdown-128.png
swiftui	https://cdn.coda.io/icons/png/color/swiftui-128.png
system information	https://cdn.coda.io/icons/png/color/system-information-128.png
system report	https://cdn.coda.io/icons/png/color/system-report-128.png
system task	https://cdn.coda.io/icons/png/color/system-task-128.png
preview pane	https://cdn.coda.io/icons/png/color/preview-pane-128.png
programming flag	https://cdn.coda.io/icons/png/color/programming-flag-128.png
active directory	https://cdn.coda.io/icons/png/color/active-directory-128.png
virtual machine	https://cdn.coda.io/icons/png/color/virtual-machine-128.png
software installer	https://cdn.coda.io/icons/png/color/software-installer-128.png
help	https://cdn.coda.io/icons/png/color/help-128.png
1	https://cdn.coda.io/icons/png/color/1-128.png
2	https://cdn.coda.io/icons/png/color/2-128.png
3	https://cdn.coda.io/icons/png/color/3-128.png
4	https://cdn.coda.io/icons/png/color/4-128.png
5	https://cdn.coda.io/icons/png/color/5-128.png
6	https://cdn.coda.io/icons/png/color/6-128.png
7	https://cdn.coda.io/icons/png/color/7-128.png
8	https://cdn.coda.io/icons/png/color/8-128.png
9	https://cdn.coda.io/icons/png/color/9-128.png
1 circle	https://cdn.coda.io/icons/png/color/1-circle-128.png
2 circle	https://cdn.coda.io/icons/png/color/2-circle-128.png
3 circle	https://cdn.coda.io/icons/png/color/3-circle-128.png
4 circle	https://cdn.coda.io/icons/png/color/4-circle-128.png
5 circle	https://cdn.coda.io/icons/png/color/5-circle-128.png
6 circle	https://cdn.coda.io/icons/png/color/6-circle-128.png
7 circle	https://cdn.coda.io/icons/png/color/7-circle-128.png
8 circle	https://cdn.coda.io/icons/png/color/8-circle-128.png
9 circle	https://cdn.coda.io/icons/png/color/9-circle-128.png
twitter squared	https://cdn.coda.io/icons/png/color/twitter-squared-128.png
tiktok	https://cdn.coda.io/icons/png/color/tiktok-128.png
line	https://cdn.coda.io/icons/png/color/line-128.png
renren	https://cdn.coda.io/icons/png/color/renren-128.png
tencent weibo	https://cdn.coda.io/icons/png/color/tencent-weibo-128.png
weibo	https://cdn.coda.io/icons/png/color/weibo-128.png
medium	https://cdn.coda.io/icons/png/color/medium-128.png
tumblr	https://cdn.coda.io/icons/png/color/tumblr-128.png
wordpress	https://cdn.coda.io/icons/png/color/wordpress-128.png
xing	https://cdn.coda.io/icons/png/color/xing-128.png
foursquare	https://cdn.coda.io/icons/png/color/foursquare-128.png
yelp	https://cdn.coda.io/icons/png/color/yelp-128.png
behance	https://cdn.coda.io/icons/png/color/behance-128.png
angry face meme	https://cdn.coda.io/icons/png/color/angry-face-meme-128.png
pinterest	https://cdn.coda.io/icons/png/color/pinterest-128.png
facebook messenger	https://cdn.coda.io/icons/png/color/facebook-messenger-128.png
whatsapp	https://cdn.coda.io/icons/png/color/whatsapp-128.png
bebo	https://cdn.coda.io/icons/png/color/bebo-128.png
google plus	https://cdn.coda.io/icons/png/color/google-plus-128.png
delicious	https://cdn.coda.io/icons/png/color/delicious-128.png
digg	https://cdn.coda.io/icons/png/color/digg-128.png
stumbleupon	https://cdn.coda.io/icons/png/color/stumbleupon-128.png
hearts	https://cdn.coda.io/icons/png/color/hearts-128.png
facebook like	https://cdn.coda.io/icons/png/color/facebook-like-128.png
retweet	https://cdn.coda.io/icons/png/color/retweet-128.png
share	https://cdn.coda.io/icons/png/color/share-128.png
reddit	https://cdn.coda.io/icons/png/color/reddit-128.png
feedly	https://cdn.coda.io/icons/png/color/feedly-128.png
forrst	https://cdn.coda.io/icons/png/color/forrst-128.png
music	https://cdn.coda.io/icons/png/color/music-128.png
accordion	https://cdn.coda.io/icons/png/color/accordion-128.png
piano	https://cdn.coda.io/icons/png/color/piano-128.png
pipe organ	https://cdn.coda.io/icons/png/color/pipe-organ-128.png
00s music	https://cdn.coda.io/icons/png/color/00s-music-128.png
60s music	https://cdn.coda.io/icons/png/color/60s-music-128.png
70s music	https://cdn.coda.io/icons/png/color/70s-music-128.png
80s music	https://cdn.coda.io/icons/png/color/80s-music-128.png
90s music	https://cdn.coda.io/icons/png/color/90s-music-128.png
amazon echo	https://cdn.coda.io/icons/png/color/amazon-echo-128.png
boombox	https://cdn.coda.io/icons/png/color/boombox-128.png
guitar amp	https://cdn.coda.io/icons/png/color/guitar-amp-128.png
radio 2	https://cdn.coda.io/icons/png/color/radio-2-128.png
bluetooth	https://cdn.coda.io/icons/png/color/bluetooth-128.png
country music	https://cdn.coda.io/icons/png/color/country-music-128.png
classic music	https://cdn.coda.io/icons/png/color/classic-music-128.png
rock music	https://cdn.coda.io/icons/png/color/rock-music-128.png
bass clef	https://cdn.coda.io/icons/png/color/bass-clef-128.png
treble clef	https://cdn.coda.io/icons/png/color/treble-clef-128.png
half rest	https://cdn.coda.io/icons/png/color/half-rest-128.png
music notation	https://cdn.coda.io/icons/png/color/music-notation-128.png
musical	https://cdn.coda.io/icons/png/color/musical-128.png
whole rest	https://cdn.coda.io/icons/png/color/whole-rest-128.png
quarter rest	https://cdn.coda.io/icons/png/color/quarter-rest-128.png
bass drum	https://cdn.coda.io/icons/png/color/bass-drum-128.png
bell lyre	https://cdn.coda.io/icons/png/color/bell-lyre-128.png
cymbals	https://cdn.coda.io/icons/png/color/cymbals-128.png
drum set	https://cdn.coda.io/icons/png/color/drum-set-128.png
timpani	https://cdn.coda.io/icons/png/color/timpani-128.png
applause	https://cdn.coda.io/icons/png/color/applause-128.png
autograph	https://cdn.coda.io/icons/png/color/autograph-128.png
choir	https://cdn.coda.io/icons/png/color/choir-128.png
dj	https://cdn.coda.io/icons/png/color/dj-128.png
violinist	https://cdn.coda.io/icons/png/color/violinist-128.png
music conductor	https://cdn.coda.io/icons/png/color/music-conductor-128.png
add song	https://cdn.coda.io/icons/png/color/add-song-128.png
music heart	https://cdn.coda.io/icons/png/color/music-heart-128.png
playlist	https://cdn.coda.io/icons/png/color/playlist-128.png
smart playlist	https://cdn.coda.io/icons/png/color/smart-playlist-128.png
record	https://cdn.coda.io/icons/png/color/record-128.png
audio wave	https://cdn.coda.io/icons/png/color/audio-wave-128.png
headphones	https://cdn.coda.io/icons/png/color/headphones-128.png
loudspeaker	https://cdn.coda.io/icons/png/color/loudspeaker-128.png
micro	https://cdn.coda.io/icons/png/color/micro-128.png
sonometer	https://cdn.coda.io/icons/png/color/sonometer-128.png
sound surround	https://cdn.coda.io/icons/png/color/sound-surround-128.png
square wave	https://cdn.coda.io/icons/png/color/square-wave-128.png
tape drive	https://cdn.coda.io/icons/png/color/tape-drive-128.png
cello	https://cdn.coda.io/icons/png/color/cello-128.png
harp	https://cdn.coda.io/icons/png/color/harp-128.png
bugle	https://cdn.coda.io/icons/png/color/bugle-128.png
clarinet	https://cdn.coda.io/icons/png/color/clarinet-128.png
cornet	https://cdn.coda.io/icons/png/color/cornet-128.png
flute	https://cdn.coda.io/icons/png/color/flute-128.png
french horn	https://cdn.coda.io/icons/png/color/french-horn-128.png
tuba	https://cdn.coda.io/icons/png/color/tuba-128.png
trumpet	https://cdn.coda.io/icons/png/color/trumpet-128.png
harmonica	https://cdn.coda.io/icons/png/color/harmonica-128.png
volcano	https://cdn.coda.io/icons/png/color/volcano-128.png
contact card	https://cdn.coda.io/icons/png/color/contact-card-128.png
s	https://cdn.coda.io/icons/png/color/s-128.png
date from	https://cdn.coda.io/icons/png/color/date-from-128.png
date span	https://cdn.coda.io/icons/png/color/date-span-128.png
date to	https://cdn.coda.io/icons/png/color/date-to-128.png
calendar 1	https://cdn.coda.io/icons/png/color/calendar-1-128.png
calendar 10	https://cdn.coda.io/icons/png/color/calendar-10-128.png
calendar 11	https://cdn.coda.io/icons/png/color/calendar-11-128.png
calendar 2	https://cdn.coda.io/icons/png/color/calendar-2-128.png
calendar 3	https://cdn.coda.io/icons/png/color/calendar-3-128.png
calendar 4	https://cdn.coda.io/icons/png/color/calendar-4-128.png
calendar 5	https://cdn.coda.io/icons/png/color/calendar-5-128.png
calendar 6	https://cdn.coda.io/icons/png/color/calendar-6-128.png
calendar 7	https://cdn.coda.io/icons/png/color/calendar-7-128.png
calendar 8	https://cdn.coda.io/icons/png/color/calendar-8-128.png
calendar 9	https://cdn.coda.io/icons/png/color/calendar-9-128.png
calendar 12	https://cdn.coda.io/icons/png/color/calendar-12-128.png
calendar 13	https://cdn.coda.io/icons/png/color/calendar-13-128.png
calendar 14	https://cdn.coda.io/icons/png/color/calendar-14-128.png
calendar 15	https://cdn.coda.io/icons/png/color/calendar-15-128.png
calendar 16	https://cdn.coda.io/icons/png/color/calendar-16-128.png
calendar 17	https://cdn.coda.io/icons/png/color/calendar-17-128.png
calendar 18	https://cdn.coda.io/icons/png/color/calendar-18-128.png
calendar 19	https://cdn.coda.io/icons/png/color/calendar-19-128.png
calendar 20	https://cdn.coda.io/icons/png/color/calendar-20-128.png
calendar 21	https://cdn.coda.io/icons/png/color/calendar-21-128.png
calendar 22	https://cdn.coda.io/icons/png/color/calendar-22-128.png
calendar 23	https://cdn.coda.io/icons/png/color/calendar-23-128.png
calendar 24	https://cdn.coda.io/icons/png/color/calendar-24-128.png
calendar 25	https://cdn.coda.io/icons/png/color/calendar-25-128.png
calendar 26	https://cdn.coda.io/icons/png/color/calendar-26-128.png
calendar 27	https://cdn.coda.io/icons/png/color/calendar-27-128.png
calendar 28	https://cdn.coda.io/icons/png/color/calendar-28-128.png
calendar 29	https://cdn.coda.io/icons/png/color/calendar-29-128.png
calendar 30	https://cdn.coda.io/icons/png/color/calendar-30-128.png
alarm	https://cdn.coda.io/icons/png/color/alarm-128.png
alarm clock	https://cdn.coda.io/icons/png/color/alarm-clock-128.png
stopwatch	https://cdn.coda.io/icons/png/color/stopwatch-128.png
timer	https://cdn.coda.io/icons/png/color/timer-128.png
day view	https://cdn.coda.io/icons/png/color/day-view-128.png
week view	https://cdn.coda.io/icons/png/color/week-view-128.png
calendar delete	https://cdn.coda.io/icons/png/color/calendar-delete-128.png
calendar minus	https://cdn.coda.io/icons/png/color/calendar-minus-128.png
calendar plus	https://cdn.coda.io/icons/png/color/calendar-plus-128.png
event accepted	https://cdn.coda.io/icons/png/color/event-accepted-128.png
pay date	https://cdn.coda.io/icons/png/color/pay-date-128.png
leave	https://cdn.coda.io/icons/png/color/leave-128.png
planner	https://cdn.coda.io/icons/png/color/planner-128.png
hourglass	https://cdn.coda.io/icons/png/color/hourglass-128.png
google sheets	https://cdn.coda.io/icons/png/color/google-sheets-128.png
google docs	https://cdn.coda.io/icons/png/color/google-docs-128.png
google maps	https://cdn.coda.io/icons/png/color/google-maps-128.png
google play	https://cdn.coda.io/icons/png/color/google-play-128.png
play	https://cdn.coda.io/icons/png/color/play-128.png
deviation	https://cdn.coda.io/icons/png/color/deviation-128.png
variation	https://cdn.coda.io/icons/png/color/variation-128.png
add	https://cdn.coda.io/icons/png/color/add-128.png
button	https://cdn.coda.io/icons/png/color/button-128.png
dashboard layout	https://cdn.coda.io/icons/png/color/dashboard-layout-128.png
delete	https://cdn.coda.io/icons/png/color/delete-128.png
download	https://cdn.coda.io/icons/png/color/download-128.png
email sign	https://cdn.coda.io/icons/png/color/email-sign-128.png
enter	https://cdn.coda.io/icons/png/color/enter-128.png
error	https://cdn.coda.io/icons/png/color/error-128.png
form	https://cdn.coda.io/icons/png/color/form-128.png
four squares	https://cdn.coda.io/icons/png/color/four-squares-128.png
drag and drop	https://cdn.coda.io/icons/png/color/drag-and-drop-128.png
gear	https://cdn.coda.io/icons/png/color/gear-128.png
change theme	https://cdn.coda.io/icons/png/color/change-theme-128.png
clear symbol	https://cdn.coda.io/icons/png/color/clear-symbol-128.png
level up	https://cdn.coda.io/icons/png/color/level-up-128.png
loading bar	https://cdn.coda.io/icons/png/color/loading-bar-128.png
numbered list	https://cdn.coda.io/icons/png/color/numbered-list-128.png
keypad	https://cdn.coda.io/icons/png/color/keypad-128.png
hint	https://cdn.coda.io/icons/png/color/hint-128.png
find and replace	https://cdn.coda.io/icons/png/color/find-and-replace-128.png
replace	https://cdn.coda.io/icons/png/color/replace-128.png
save	https://cdn.coda.io/icons/png/color/save-128.png
save all	https://cdn.coda.io/icons/png/color/save-all-128.png
save close	https://cdn.coda.io/icons/png/color/save-close-128.png
save as	https://cdn.coda.io/icons/png/color/save-as-128.png
screensharing	https://cdn.coda.io/icons/png/color/screensharing-128.png
upload	https://cdn.coda.io/icons/png/color/upload-128.png
storage	https://cdn.coda.io/icons/png/color/storage-128.png
workflow	https://cdn.coda.io/icons/png/color/workflow-128.png
tree structure	https://cdn.coda.io/icons/png/color/tree-structure-128.png
double tick	https://cdn.coda.io/icons/png/color/double-tick-128.png
info	https://cdn.coda.io/icons/png/color/info-128.png
info popup	https://cdn.coda.io/icons/png/color/info-popup-128.png
lifebuoy	https://cdn.coda.io/icons/png/color/lifebuoy-128.png
maintenance	https://cdn.coda.io/icons/png/color/maintenance-128.png
online support	https://cdn.coda.io/icons/png/color/online-support-128.png
terms and conditions	https://cdn.coda.io/icons/png/color/terms-and-conditions-128.png
user manual	https://cdn.coda.io/icons/png/color/user-manual-128.png
view details	https://cdn.coda.io/icons/png/color/view-details-128.png
support	https://cdn.coda.io/icons/png/color/support-128.png
work	https://cdn.coda.io/icons/png/color/work-128.png
faq	https://cdn.coda.io/icons/png/color/faq-128.png
password	https://cdn.coda.io/icons/png/color/password-128.png
circled menu	https://cdn.coda.io/icons/png/color/circled-menu-128.png
menu	https://cdn.coda.io/icons/png/color/menu-128.png
firework	https://cdn.coda.io/icons/png/color/firework-128.png
explosion	https://cdn.coda.io/icons/png/color/explosion-128.png
	https://cdn.coda.io/icons/png/color/-128.png
plumber	https://cdn.coda.io/icons/png/color/plumber-128.png
psychology	https://cdn.coda.io/icons/png/color/psychology-128.png
therapy	https://cdn.coda.io/icons/png/color/therapy-128.png
under computer	https://cdn.coda.io/icons/png/color/under-computer-128.png
camera automation	https://cdn.coda.io/icons/png/color/camera-automation-128.png
data encryption	https://cdn.coda.io/icons/png/color/data-encryption-128.png
ios app icon shape	https://cdn.coda.io/icons/png/color/ios-app-icon-shape-128.png
feedback	https://cdn.coda.io/icons/png/color/feedback-128.png
astronaut helmet	https://cdn.coda.io/icons/png/color/astronaut-helmet-128.png
total sales 1	https://cdn.coda.io/icons/png/color/total-sales-1-128.png
add white space	https://cdn.coda.io/icons/png/color/add-white-space-128.png
java coffee bean logo	https://cdn.coda.io/icons/png/color/java-coffee-bean-logo-128.png
floor plan	https://cdn.coda.io/icons/png/color/floor-plan-128.png
toggle indeterminate	https://cdn.coda.io/icons/png/color/toggle-indeterminate-128.png
people working together	https://cdn.coda.io/icons/png/color/people-working-together-128.png
thinking bubble	https://cdn.coda.io/icons/png/color/thinking-bubble-128.png
aperture	https://cdn.coda.io/icons/png/color/aperture-128.png
overtime	https://cdn.coda.io/icons/png/color/overtime-128.png
front gate open	https://cdn.coda.io/icons/png/color/front-gate-open-128.png
mobile payment	https://cdn.coda.io/icons/png/color/mobile-payment-128.png
air pilot hat	https://cdn.coda.io/icons/png/color/air-pilot-hat-128.png
so so	https://cdn.coda.io/icons/png/color/so-so-128.png
open document	https://cdn.coda.io/icons/png/color/open-document-128.png
handle with care	https://cdn.coda.io/icons/png/color/handle-with-care-128.png
trombone	https://cdn.coda.io/icons/png/color/trombone-128.png
lost and found	https://cdn.coda.io/icons/png/color/lost-and-found-128.png
t shirt	https://cdn.coda.io/icons/png/color/t-shirt-128.png
keep away from children	https://cdn.coda.io/icons/png/color/keep-away-from-children-128.png
bank card missing	https://cdn.coda.io/icons/png/color/bank-card-missing-128.png
group task	https://cdn.coda.io/icons/png/color/group-task-128.png
show password	https://cdn.coda.io/icons/png/color/show-password-128.png
approve and update	https://cdn.coda.io/icons/png/color/approve-and-update-128.png
add list	https://cdn.coda.io/icons/png/color/add-list-128.png
last 12 hours	https://cdn.coda.io/icons/png/color/last-12-hours-128.png
coniferous tree	https://cdn.coda.io/icons/png/color/coniferous-tree-128.png
student male	https://cdn.coda.io/icons/png/color/student-male-128.png
class	https://cdn.coda.io/icons/png/color/class-128.png
business report	https://cdn.coda.io/icons/png/color/business-report-128.png
gender neutral user	https://cdn.coda.io/icons/png/color/gender-neutral-user-128.png
under pressure male	https://cdn.coda.io/icons/png/color/under-pressure-male-128.png
statue of liberty	https://cdn.coda.io/icons/png/color/statue-of-liberty-128.png
anmeld	https://cdn.coda.io/icons/png/color/anmeld-128.png
ios photos	https://cdn.coda.io/icons/png/color/ios-photos-128.png
data sheet	https://cdn.coda.io/icons/png/color/data-sheet-128.png
ecosia	https://cdn.coda.io/icons/png/color/ecosia-128.png
generic sorting	https://cdn.coda.io/icons/png/color/generic-sorting-128.png
parse from clipboard	https://cdn.coda.io/icons/png/color/parse-from-clipboard-128.png
1 circle c	https://cdn.coda.io/icons/png/color/1-circle-c-128.png
2 circle c	https://cdn.coda.io/icons/png/color/2-circle-c-128.png
saliva test	https://cdn.coda.io/icons/png/color/saliva-test-128.png
activity feed 2	https://cdn.coda.io/icons/png/color/activity-feed-2-128.png
visual game boy	https://cdn.coda.io/icons/png/color/visual-game-boy-128.png
exam	https://cdn.coda.io/icons/png/color/exam-128.png
crystal	https://cdn.coda.io/icons/png/color/crystal-128.png
in inventory	https://cdn.coda.io/icons/png/color/in-inventory-128.png
todo list	https://cdn.coda.io/icons/png/color/todo-list-128.png
dragon fruit	https://cdn.coda.io/icons/png/color/dragon-fruit-128.png
first quarter	https://cdn.coda.io/icons/png/color/first-quarter-128.png
report card	https://cdn.coda.io/icons/png/color/report-card-128.png
checked radio button	https://cdn.coda.io/icons/png/color/checked-radio-button-128.png
courses	https://cdn.coda.io/icons/png/color/courses-128.png
connection sync	https://cdn.coda.io/icons/png/color/connection-sync-128.png
meeting room	https://cdn.coda.io/icons/png/color/meeting-room-128.png
flow chart	https://cdn.coda.io/icons/png/color/flow-chart-128.png
3 circle c	https://cdn.coda.io/icons/png/color/3-circle-c-128.png
4 circle c	https://cdn.coda.io/icons/png/color/4-circle-c-128.png
5 circle c	https://cdn.coda.io/icons/png/color/5-circle-c-128.png
6 circle c	https://cdn.coda.io/icons/png/color/6-circle-c-128.png
7 circle c	https://cdn.coda.io/icons/png/color/7-circle-c-128.png
8 circle c	https://cdn.coda.io/icons/png/color/8-circle-c-128.png
9 circle c	https://cdn.coda.io/icons/png/color/9-circle-c-128.png
stop sign	https://cdn.coda.io/icons/png/color/stop-sign-128.png
hard to find	https://cdn.coda.io/icons/png/color/hard-to-find-128.png
dog tag	https://cdn.coda.io/icons/png/color/dog-tag-128.png
this way up	https://cdn.coda.io/icons/png/color/this-way-up-128.png
activity grid 2	https://cdn.coda.io/icons/png/color/activity-grid-2-128.png
math book	https://cdn.coda.io/icons/png/color/math-book-128.png
heart with arrow	https://cdn.coda.io/icons/png/color/heart-with-arrow-128.png
traffic light	https://cdn.coda.io/icons/png/color/traffic-light-128.png
google classroom	https://cdn.coda.io/icons/png/color/google-classroom-128.png
checked truck	https://cdn.coda.io/icons/png/color/checked-truck-128.png
workers male	https://cdn.coda.io/icons/png/color/workers-male-128.png
sandbox	https://cdn.coda.io/icons/png/color/sandbox-128.png
hub	https://cdn.coda.io/icons/png/color/hub-128.png
video call	https://cdn.coda.io/icons/png/color/video-call-128.png
defensive wood wall	https://cdn.coda.io/icons/png/color/defensive-wood-wall-128.png
leaving queue	https://cdn.coda.io/icons/png/color/leaving-queue-128.png
--camel	https://cdn.coda.io/icons/png/color/--camel-128.png
bird	https://cdn.coda.io/icons/png/color/bird-128.png
army star	https://cdn.coda.io/icons/png/color/army-star-128.png
sliding door closet	https://cdn.coda.io/icons/png/color/sliding-door-closet-128.png
rgb lamp	https://cdn.coda.io/icons/png/color/rgb-lamp-128.png
charity	https://cdn.coda.io/icons/png/color/charity-128.png
add folder	https://cdn.coda.io/icons/png/color/add-folder-128.png
trophy	https://cdn.coda.io/icons/png/color/trophy-128.png
medal2	https://cdn.coda.io/icons/png/color/medal2-128.png
medal second place	https://cdn.coda.io/icons/png/color/medal-second-place-128.png
submit resume	https://cdn.coda.io/icons/png/color/submit-resume-128.png
ok	https://cdn.coda.io/icons/png/color/ok-128.png
cancel	https://cdn.coda.io/icons/png/color/cancel-128.png
cash in hand	https://cdn.coda.io/icons/png/color/cash-in-hand-128.png
archive folder	https://cdn.coda.io/icons/png/color/archive-folder-128.png
mylivn	https://cdn.coda.io/icons/png/color/mylivn-128.png
civil twilight	https://cdn.coda.io/icons/png/color/civil-twilight-128.png
google ads	https://cdn.coda.io/icons/png/color/google-ads-128.png
hand right
pancake	https://cdn.coda.io/icons/png/color/pancake-128.png`;




export const codaIcons: {
	name,
	url(type: 'color' | 'material')
}[] = source.split('\n').map(line => {
	const name = line.split('\t')[0];
	// const url = `https://cdn.coda.io/icons/svg/color/${name.replaceAll(' ', '-')}.svg`;
	// const url = `https://cdn.coda.io/icons/svg/material/${name.replaceAll(' ', '-')}.svg`;

	return {
		name,
		url(type) {
			return `https://cdn.coda.io/icons/svg/${type}/${name.replace(/ /g, '-')}.svg`
		}
	}
})
