import { RenderData } from '../MyNotionDocument/RenderData';
import { types } from "../MyNotionDocument/types";
import { entitySchema } from './attributesForType';
import { expandToText } from '../richTextHelpers';
import { getEntityById } from '../etc/createEntity';
import { db } from '../db';
import { evaluate, execute, getValuePoint } from '../glue/main';
import { Pass } from '../shorthand/formula';
import _ from 'lodash';

export function entityDisplayName(id: string): string {
  const entity = getEntityById(id);
  if (!entity) return '(deleted)';
  const schema = entitySchema(id);
  const entityType = entity.type && db.entityTypes.findById(entity.type);

  let nameValue;
  if (schema?.titleAttribute) {
    nameValue = entity.attributes?.[schema.titleAttribute.content]?.format?.('{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}');
  }
  else if (entityType?.nameFunction) {
    const r = callEntityNameFunc(id);
    if (_.isString(r)) return r;
  }
  else {
    nameValue = expandToText({ types }, entity.name);
  }

  return nameValue;
}

function callEntityNameFunc(id) {
  // return 'adsf';
  const entity = getEntityById(id);
  const entityType = entity.type && db.entityTypes.findById(entity.type);
  
  const vp = getValuePoint(entityType.nameFunction);
  const compiled = execute(entityType.nameFunction);
  const r = evaluate(compiled, vp.parameters?.[0] && { [vp.parameters[0]._id]: id });
  if (r instanceof Pass) {
    return '';
  }
  return r;
}


export function entityComputedName(id: string) {
  const entity = getEntityById(id);
  if (!entity) return false;
  const schema = entitySchema(id);
  const entityType = entity.type && db.entityTypes.findById(entity.type);

  return schema?.titleAttribute || entityType?.nameFunction;
}

export function entityDisplayView(id) {
  const entity = getEntityById(id);
  if (!entity) return '(deleted)';
  const schema = entitySchema(id);
  const entityType = entity.type && db.entityTypes.findById(entity.type);


  let nameValue;
  if (schema?.titleAttribute) {
    nameValue = entity.attributes?.[schema.titleAttribute.content]?.format?.('{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}');
  }
  else if (entityType?.nameFunction) {
    const r = callEntityNameFunc(id);
    if (_.isString(r)) return r;
  }

  else {
    nameValue = <RenderData ctx={{
      types: types,
    }}
    data={entity.name}
    />
  }

  return nameValue;

}