import { collectEntities } from "../../etc/collectEntities";
import _ from 'lodash';
import { db } from "../../db";
import { RenderType, evaluate, mapStructure } from "../main";
import { structRenderers } from "../structRenderers";
import { typeRegistry } from "../typeRegistry.1";
import { x } from "../../XObject";
import { getEntityById } from "../../etc/createEntity";

export const $EntityDocumentEntities = typeRegistry.registerType({
  $: '06e9a667-95b1-5ec4-b942-e98a5d1e7a57',
  Entity: '6f819434-4abd-508c-a682-88ac2391fb54',
}, ids => ({
  _id: ids.$,
  name: 'Entity Document Entities',
  definition: [
    {
      id: ids.Entity,
      name: 'Entity',
      type: [],
      property: 'entity',
    }
  ]
}));

structRenderers[$EntityDocumentEntities.$] = (value, map, state, renderType) => {
  const mapped = mapStructure(value);
  const entityId = evaluate(mapped.entity, map);

  const entity = getEntityById(entityId);
  const documents = entity?.documents || [];
  let entities = [];
  for (const doc of documents) {
    entities.push(...collectEntities(doc.content || []));
  }


  for (const doc of db.notionDocuments) {
    if (doc.parent?.id == entityId) {
      entities.push(...collectEntities(doc.blocks || []));
    }
  }

  entities = _.uniq(entities);
  if (renderType == RenderType.entities) {
    return entities;
  }
  

  return (
    <>
      {entityId}
      <div>
        {entities.map(e => <div>{e}</div>)}
      </div>
    </>
  )
}