import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { component, css, styled } from '../component';
import jQuery from 'jquery';
import { XInit, XTouch, x } from '../XObject';
import { timeStamp } from 'console';
import { appState, memoryAppState } from '../etc/appState';

const _window: any = window;

function inspectKeys(e) {
	return false;
	return e.ctrlKey && e.altKey;
}

function getValuePoints(el) {
	return el.getAttribute('data-value-point') ? [el.getAttribute('data-value-point')] : el.getAttribute('data-value-points').split(' ');
	return JSON.parse(el.getAttribute('data-value-point') || el.getAttribute('data-value-points'));
}
function elementDepth(el){
	var depth = 0
	while(null!==el.parentElement){
		el = el.parentElement
		depth++
	}
	return depth
}

@component
export class DebugWrapper extends React.Component<{ children? }> {
	static styles = styled.div`
		${props => css`
			${props.hoverValuePoints.map(id => `[data-value-point="${id}"], [data-value-points~="${id}"] { outline: 2px solid #f005; }`).join('')}
		`}
	`;
  state = XInit(class {
		inspectOverlay
	})

	els = [];



	componentDidMount() {
		let el = ReactDOM.findDOMNode(this);
		if (!el) return;
		let doc = el.ownerDocument;
		let win = doc.defaultView;
		let clientX, clientY;
		const update = () => {
			// console.log(clientX, clientY);
			let els = []
			// if (_inspectState().inspectType == 'ui') {
				els = doc.elementsFromPoint(clientX, clientY).filter(el => el.getAttribute('data-value-point') || el.getAttribute('data-value-points'));

				// sort els by depth
				els = els.sort((a, b) => elementDepth(b) - elementDepth(a))

				appState.glueInspectStack = els.map(el => getValuePoints(el)).reduce((a, b) => a.concat(b), []).filter(Boolean)
			// }
			// else if (_inspectState().inspectType == 'data') {
			// 	els = doc.elementsFromPoint(clientX, clientY).filter(el => el.getAttribute('data-reactive'));
			// 	appState.glueInspectStack = _.uniq(els.map(el => el.getAttribute('data-reactive')));
			// }

			this.els = els;
			this.forceUpdate();
		};


		win.addEventListener('keydown', e => {
			if (inspectKeys(e)) {
				this.state.inspectOverlay = true;
				update();
			}
		});
		win.addEventListener('keyup', e => {
			if (!inspectKeys(e)) {
				this.state.inspectOverlay = false;
				this.els = [];
			}
		});
		win.addEventListener('mousedown', e => {
			if (this.state.inspectOverlay) {
				// if (_inspectState().inspectType == 'ui') {
					const el = this.els[0];
					if (el) {
						console.log(x(appState.glueInspectStack));
						// const obj = getValuePoints(el);
						// inspectObjectAction(obj);
						this.state.inspectOverlay = false;
						// console.log(obj);
						appState.glue = {
							valuePoints: appState.glueInspectStack,
						}
					}
	
				// }

			}
		});
		/*win.addEventListener('mousedown', e => {
			if (inspectKeys(e)) {
				e.preventDefault();
				e.stopImmediatePropagation();
				e.stopPropagation();
				let [ el ] = doc.elementsFromPoint(e.clientX, e.clientY).filter(el => el.getAttribute('data-path') && el.getAttribute('data-instance'));
				if (el) {
          let path = el.getAttribute('data-path').split('-');
          jQuery(el).trigger('navigatePath', [ path ]);
					// let context = path[0];
          // // currentOs().config.debugContext = context;
          

					// let currentWindow = currentOs().windows.find(win => win.type == 'ide' && win.context == context);
					// if (currentWindow) {
					// 	console.log('NEW PATH');
					// 	currentWindow.initialPath = path.slice(2);
					// }
					// else {
					// 	openWindow({
					// 		type: 'ide',
					// 		context,
					// 		activeComponent: path[1],
					// 		initialPath: path.slice(2),
					// 	}, [1000, 700]);
					// }
				}
			}
		}, true);*/
		win.addEventListener('contextmenu', e => {
			if (inspectKeys(e)) {
				e.preventDefault();
			}
		});
		jQuery(win).mousemove(e => {
			clientX = e.clientX;
			clientY = e.clientY;
			if (inspectKeys(e)) {
				if (e.shiftKey) {

				}
				else {
					update();

				}
				// if (el.hasAttribute('data-path')) {
				// 	window.g_debugState.hoverPath = el.getAttribute('data-path').split('-');
				// }	
				// else {
				// 	window.g_debugState.hoverPath = null;
				// }	
			}
		});
	}
	render(Container?) {
		XTouch(memoryAppState.hoveringValuePoints);

		const hovering = Object.keys(x(memoryAppState.hoveringValuePoints));

		return (
			<Container hoverValuePoints={hovering}>
				{/* <DebugSheet activePath={_window.g_debugState.activePath} hoverPaths={_window.g_debugState.hoverPaths} /> */}
				{this.state.inspectOverlay && <div className="inspectOverlay">
					{this.els.map((el, i) => {

						const bounding = el.getBoundingClientRect();
						const color = i == 0 ? 'green': 'blue';

						let c;

						// if (_inspectState().inspectType == 'data') {
							// c = el.getAttribute('data-reactive');
						// }
						// else if (_inspectState().inspectType == 'ui') {
							c = el.getAttribute('data-value-point') || el.getAttribute('data-value-points');
						// }

						return <div key={i} style={{ zIndex: this.els.length - i, position: 'absolute', left: bounding.left, top: bounding.top, width: bounding.width, height: bounding.height, outline: `2px solid ${color}` }}>
							{/* <span style={{ background: 'white', border: '1px solid black' }}>{c}</span> */}

						</div>
					})}
					</div>} 
				{this.props.children}
			</Container>
		);
	}
}
