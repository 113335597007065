import { Component } from "react";
import { component, styled } from "../component";
import { ObjectEditor } from "../ObjectEditor";
import { XInit } from "../XObject";
import { Svg } from "./Svg";
import { _inspectHandlers, _inspectState } from "../osHelpers";
import { closeMobileModal, presentMobileModal } from "./presentMobileModal";

window['g_presentMobileInspectModal'] = presentMobileInspectModal;

export function presentMobileInspectModal(config) {
  presentMobileModal(<MobileInspect config={config} />);
}

@component
class MobileInspect extends Component<{ config; }> {
  static styles = styled.div`
    /* position: fixed; */
    /* top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99999999; */
    /* background-color: white; */

    .close {
      position: absolute;
      top: 8px;
      right: 8px;
      z-index: 999999999;
      svg {
        width: 18px;
        height: 18px;
        fill: #c1c1c1;
      }
    }

    .content {
      padding: 8px;
    }
  `;

  state = XInit(class {
    stack = [];
  });

  render(Container?) {
    const { config } = this.props;
    let c;
    if (config.type == 'f885051f-2117-5276-9564-004aa60085d3') {
      c = (
        <div className="content">
          {_inspectHandlers[_inspectState()?.focused?.type]?.render?.(_inspectState()?.focused?.args)}
        </div>
      );
    }
    else {
      c = (
        <ObjectEditor
          obj={config.root}
          onNavObject={() => {
            throw new Error();
          }}
          />
      );
    }

    return (
      <Container>
        <div className="inspect">
          <span className="close"
            onClick={() => {
              closeMobileModal();
            }}
          >
            <Svg name="icons8-close" />
          </span>
          {c}
        </div>

      </Container>
    );
  }
}
