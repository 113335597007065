import { Component } from 'react';
import { db } from '../db';
import { GraphView } from './Graph';
import { component } from '../component2';
import { getGraphMatch, queryGraph } from '../etc/queryGraph';
import { XObject, x } from '../XObject';
import { defaultWorkspace } from '../etc/defaultWorkspace';
import { entityTypeName } from '../etc/entityTypeName';
import { deleteEdge, deleteEdgeByIndex } from '../etc/getEdgesForEntity';
import { createEntity, getAllEntities, getEntityById } from '../etc/createEntity';
import { getAllEdges, pushEdge } from '../getAllEdges';


@component
export class EntityGraphView extends Component<{ graph; rootEntity; state; onSelect?; label? }> {
  render() {
    const nodes = queryGraph(this.props.graph, this.props.rootEntity);
    const graph = defaultWorkspace().graphs.find(x => x._id == this.props.graph);

    return (
      <>
        <GraphView
          label={this.props.label}
          onSelect={this.props.onSelect}
          _state={this.props.state}
          _onDropExternal={(target, dropped) => {
            console.log('drop', getEntityById(target).name, getEntityById(dropped).name);

            const currentEdge = getAllEdges().find(e => e.entities[1] == dropped && e.directed);
            if (currentEdge) {
              deleteEdge(currentEdge._id);
            }

            pushEdge(XObject.obj({
              entities: [target, dropped],
              directed: true,
            }))
          }}
          _onMove={(id, from, to) => {
            const currentEdge = getAllEdges().find(x => x.entities.includes(from) && x.entities.includes(id));
            if (currentEdge) {
              deleteEdge(currentEdge._id);
            }

            const newEdge = XObject.obj({
              entities: [to, id],
              directed: true,
            });
            pushEdge(newEdge);


            // console.log('move', id, from, to);
          }}
          data={nodes.map(node => {
            const entity = getEntityById(node.entity);
            const match = getGraphMatch(graph, entity);

            return {
              id: node.entity,
              name: (() => {
                const name = entity.name;
  
                const parts = [];
  
                if (name) {
                  parts.push(name);
                }

  
                return parts.join(' ');
  
              })(),
              meta: {
                parent: node.parent,
                contextMenu: [
                  ...((match?.rels?.map?.(rel => {
                    if (!rel.entityMatch.type) return;
                    return {
                      text: `Add ${entityTypeName(rel.entityMatch.type)}`,
                      onClick: () => {
                        const newEntity = XObject.obj({
                          type: rel.entityMatch.type,
                          name: 'New ' + entityTypeName(rel.entityMatch.type),
                        });
                        createEntity(newEntity, node.entity);
                        pushEdge(XObject.obj({
                          entities: [node.entity, newEntity._id],
                          directed: true,
                        }));
                      },
                    }
                  }) || []).filter(Boolean)),
                  {
                    text: 'Delete',
                    onClick: () => {
                      if (window.confirm('Are you sure?')) {
                        throw new Error();
                        // const index = getAllEntities().indexOf(entity);
                        // getAllEntities().splice(index, 1);
  
                        // delete edges
                        while (true) {
                          const index = getAllEdges().findIndex(x => x.entities.includes(entity._id));
                          if (index == -1) {
                            break;
                          }
                          deleteEdgeByIndex(index);
                        }  
                      }
                    }
                  },
                  {
                    text: 'Debug',
                    onClick: () => {
                      console.log(x(match));
                    }
                  },
                  
                ]
              }
            };
          })}
          root={this.props.rootEntity}
        />
      </>
    );
  }
}
