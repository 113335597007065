import { db } from '../db';
import { XObject } from '../XObject';
import { appState } from '../etc/appState';

export function userEntity() {
  return db.users.findById(appState.user).entity;
}
export function startEvent({
  event = undefined, description = undefined, arguments: args = undefined,
}) {
  db.eventOccurrences.push(XObject.obj({
    user: userEntity(),
    mode: appState.currentMode,
    event,
    description,
    start: new Date(),
    arguments: args,
  }));

}

export function createEventOccurrence(args) {
  db.eventOccurrences.push(XObject.obj({
    user: userEntity(),
    mode: appState.currentMode,
    ...args,
  }));
}