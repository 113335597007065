import React, { Component } from 'react';
import { component } from '../component2';
import { ObjectDisplay } from './ObjectDisplay';
import { showContextMenu } from '../etc/showContextMenu';
import { ObjectPicker } from './ObjectPicker';


@component
export class ObjectField extends Component<{ value; setValue; }> {
  render() {
    return (
      <>
        {this.props.value ? <span
          onContextMenu={e => {
            e.preventDefault();
            showContextMenu(e, [
              {
                text: 'Clear',
                onClick: () => {
                  this.props.setValue(null);
                }
              }
            ]);
          }}
        ><ObjectDisplay obj={this.props.value} showPath /></span> :
          <ObjectPicker _onSelect={(obj, clear) => {
            this.props.setValue(obj);
            clear();
          }} />}
      </>
    );
  }
}
