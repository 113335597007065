import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import _ from 'lodash';
import { component, styled } from './component2';
import { DevRuntime } from './DevRuntime';
import { RuntimeTrace } from './shorthand/RuntimeTrace';


@component
export class Layer extends Component<{ devRuntime: DevRuntime; layer; args }> {

  runtimeTrace() {
    return this.props.args.find(a => a instanceof RuntimeTrace);
  }

  componentDidMount() {
    const rt = this.runtimeTrace();
    const el = ReactDOM.findDOMNode(this)?.firstChild?.firstChild;
    if (el) {
      this.props.layer.mounted?.(el, rt);
    }
    else {
      console.log(ReactDOM.findDOMNode(this))
    }
  }
  componentWillUnmount() {
    this.props.layer.removed?.(this);
  }
  static styles = styled.div`
    pointer-events: none;
  
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;


    > .inner {
      position: absolute;
      top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    &.captureClicks {
      pointer-events: auto;
    }
    &:not(.captureClicks) {
      pointer-events: none;
      * {
        pointer-events: auto;
      }
    }
    }


  `;

  wrapper;
  render(Container?) {
    const l = this.props.layer;

    const Inner = l.styles || 'div';

    return (
      <Container


      >
        <Inner
          data-layer={l._id}
          onMouseDown={e => {
            if (e.target == e.currentTarget) {
              if (_.isFunction(this.props.layer.captureClicks)) {
                this.props.layer.captureClicks?.(this);
              }
            }
          }}

          className={cx(this.props.layer.captureClicks && 'captureClicks', 'inner')}>
          {this.props.devRuntime.renderEl(l.content(this.runtimeTrace()), [])}
        </Inner>
      </Container>
    );
  }
}
