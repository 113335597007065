import { Component } from "react";
import { component } from "../../@component";
import { styled } from "../../component";
import { mapStructure, render, renderParams, renderWithParams } from "../main";
import { structParamRenderers, structRenderers } from "../structRenderers";
import { typeRegistry } from "../typeRegistry.1";
import { XInit } from "../../XObject";


export const $App = typeRegistry.registerType({
  $: '664bf1db-5ee8-5a23-a90b-95926d60591b',
  Content: '7d0b8703-e8c8-5e1c-b49d-bab9b49f447c',
}, ids => ({
  _id: ids.$,
  name: 'App',
  definition: [
    {
      id: ids.Content,
      name: 'Content',
      type: [],
      property: 'content',
    }
  ],
}));


const Wrapper = styled.div`
  /* border: 1px solid black; */
`;

@component
class App2 extends Component<{ value, rt }> {
  state = XInit(class {
    location = '/';
  });

  static debounce = false;

  render() {
    const { value } = this.props;
    const mappedValue = mapStructure(value);
    const that = this;
    return (
      <Wrapper data-value-point={value._id}>
        {renderWithParams({
          ...renderParams(mappedValue.content, {
          'ba2dd60c-5e65-531e-9824-4fa808e9dd1e': {
            get location() {
              return that.state.location;
            },
            navigate: path => {
              that.state.location = path;
            }
          }
        }),

        rt: this.props.rt,

      }
        
        )}
      </Wrapper>
    )
  
  }
}

structParamRenderers[$App.$] = ({ value, rt }) => {
  if (!rt) throw new Error();
  return <App2 value={value} rt={rt} />
}