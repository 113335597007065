import { x, X, XClone, XObject } from "../XObject";
import { dataToString, expandToText } from "../richTextHelpers";
import { BlocksList, BlockManager, Block, BlockColumns, BlockColumn } from "../components/notionDocument/BlockManager";
import { db } from "../db";
import { createEntity, getEntityById } from "../etc/createEntity";
import { use } from "cytoscape";
import { fullBlocks } from "./fullBlocks";
import { BlockType } from "./BlockType";

const columnsId = 'faaf9a3e-e02b-5696-9b54-8f30dc836f53';
const columnId = 'ecf8c459-b246-5535-a7b6-cad18282e7e5';

interface BlockData {
  _id: string;
  type: string;
  data: any;
  children: BlockData[];
  id: string;

  columns: {
    _id: string;
    type: string;
    children: BlockData[];
  }[]

}

class MyBlocksList extends BlocksList {
  constructor(public blocks: any[], public set, public blockManager: MyBlockManager) {
    super();
  }
  concat(blocks: MyBlocksList): BlocksList {
    return new MyBlocksList([...x(this.blocks), ...x(blocks.blocks)], this.set, this.blockManager);
  }
  indexOf(block: Block): number {
    return this.blocks.findIndex(x => x._id === block.getId());
  }
  indexOfId(id: string): number {
    return this.blocks.findIndex(x => x._id === id);
  }
  push(...blocks: MyBlock[]): void {
    this.blocks.push(...blocks.map(x => x.block));
  }
  setContents(blocks: BlocksList): void {
    if (!this.set) {
      throw new Error(`set not set`);
    }
  }
  splice(index: number, count: number, ...blocks: MyBlock[]): void {
    this.blocks.splice(index, count, ...blocks.map(x => x.block));
  }

  private wrap(block: BlockData): any {
    return this.blockManager.wrap(block);
  }

  get(index: number): Block {
    return this.wrap(this.blocks[index]);
  }

  _set(index: number, block: MyBlock) {
    this.blocks[index] = block.block;
  }


  getLength(): number {
    return this.blocks.length;
  }

  map<T>(fn: (block: MyBlock, i) => T): T[] {
    return this.blocks.map((x, i) => fn(this.wrap(x), i));
  }

  getArray(): Block[] {
    return this.blocks?.map?.(x => this.wrap(x)) || [];
  }

  createColumns(atBlock: MyBlock, newBlock: MyBlock, extendSide: 'left' | 'right') {
    const index = this.indexOf(atBlock);

    const columns = XObject.obj({
      type: columnsId,
      columns: [
        XObject.obj({
          type: columnId,
          width: .5,
          children: [
            atBlock.block,
          ]
        }),
        XObject.obj({
          type: columnId,
          width: .5,
          children: [
            newBlock.block,
          ]
        }),
      ]
    });

    this.blocks[index] = columns;
  }
}

export class MyBlockManager extends BlockManager {
  constructor(
    public blocks: () => any[],
    public setBlocks?,

    public args?: {
      ctx?,
      extendEntity?,
      baseEntity?,
      memory?,
      onSetContent?
      defaultData?,
      plainText?
      dontInherit?
    }

  ) {
    super();
    this.memory = args?.memory;
  }
  _tick = 0

  memory = {tick:0}

  tick() {
    // for (const key in this.memory) {
    //   delete this.memory[key];
    // }
    // this.memory.tick = ++this._tick;
  }

  public wrap(block: BlockData): any {
    if (block.type == columnsId) {
      return new MyBlockColumns(block, this);
    }
    else if (block.type == columnId) {
      return new MyBlockColumn(block, this);
    }
    else {
      return new MyBlock(block, this);
    }
  }

  _findBlock(blocks: BlockData[], id: string): MyBlock {
    for (const block of blocks) {
      if (block._id === id) {
        return this.wrap(block);
      }
      if (block.type == columnsId) {
        for (const col of block.columns) {
          const p = this._findBlock(col.children, id);
          if (p) {
            return p;
          }
        }
      }
      else if (block.children) {
        const p = this._findBlock(block.children, id);
        if (p) {
          return p;
        }
      }
    }
    return null;
  }

  findBlock(id: string, errorOnNotFound?): MyBlock {
    const r =  this._findBlock(this.blocks(), id);
    if (!r && errorOnNotFound) {
      throw new Error(`Block ${id} not found`);
    }
    return r;
  }

  _findBlockParent(blocks: BlockData[], id: string, parent = null) {
    for (const block of blocks) {
      if (block._id === id) {
        return parent && this.wrap(parent);
      }
      if (block.type == columnsId) {
        for (const col of block.columns) {
          const p = this._findBlockParent(col.children, id, col);
          if (p) {
            return p;
          }
        }
      }
      else if (block.children) {
        const p = this._findBlockParent(block.children, id, block);
        if (p) {
          return p;
        }
      }
    }
    return null;
  }

  _findBlockColumn(blocks: BlockData[], id: string, parent = null, rootOnly=false): [any, any] {
    for (const block of blocks) {
      if (block._id === id) {
        return parent;
      }
      if (block.type == columnsId) {
        if (rootOnly) {
          for (const col of block.columns) {
            const p = col.children.find(x => x._id === id);
            if (p) {
              return [block, col];
            }
          }
        }
        else {
          for (const col of block.columns) {
            const p = this._findBlockColumn(col.children, id, [block, col], rootOnly);
            if (p) {
              return p;
            }
          }
  
        }
      }
      else if (block.children) {
        const p = this._findBlockColumn(block.children, id, parent, rootOnly);
        if (p) {
          return p;
        }
      }
    }
    return null;
  }

  getBlockColumn(id: string, rootOnly=false): [BlockColumns, number] {
    const col = this._findBlockColumn(this.blocks(), id, null, rootOnly);
    if (col) {
      return [this.wrap(col[0]), col[0].columns.indexOf(col[1])];
    }
  }

  findBlockParent(id: string) {
    return this._findBlockParent(this.blocks(), id);
  }

  getRootBlocks() {
    return new MyBlocksList(this.blocks(), this.setBlocks, this);
  }
  newBlock(id?): MyBlock {
    return new MyBlock(XObject.obj({ _id: id, children: [], ...(this.args?.defaultData?.() || {}) }), this);
  }

  setRootBlocks(blocks: MyBlocksList): void {
    this.setBlocks(blocks.blocks);
  }

  newBlocksList(): BlocksList {
    return new MyBlocksList([], null, this);
  }
}

export class MyBlock extends Block {
  blockManager: MyBlockManager;
  constructor(public block, blockManager: BlockManager) {
    super();
    this.blockManager = blockManager as MyBlockManager;
    if (!this.block) {
      throw new Error(`block is null`);
    }

    this.contentGetter = () => this.getContent();
  }

  isCollapsed(): boolean {
    return this.block.collapsed;
  }

  getId() {
    return this.block._id;
  }

  getChildren() {
    return new MyBlocksList(XObject.get(this.block, 'children', []), children => {
      this.block.children = XClone(children) || X([]);
    }, this.blockManager);
  }

  hasContent(): boolean {
    return !fullBlocks.find(this.block.type);
  }

  hasChildren(): boolean {
    return !!this.block.children?.length;
  }

  contentGetter

  getContent() {
    if (this.block.id) {
      const mem = this.getMemory();
      const entity = getEntityById(this.block.id);
      if (!('content' in mem)) {
        mem.content = XClone(entity.name);
      }
      return mem.content;
    }
    else {
      return this.block.data;
    }
  }

  getRenderTriggers() {
    if (this.block.id) {
      const entity = getEntityById(this.block.id);
      return [[entity, 'name']];
    }
    return [];
  }

  trigger() {
    delete this.blockManager.memory[this.getId()];
  }

  getMemory() {
    const id = this.getId();
    if (!this.blockManager.memory[id]) {
      this.blockManager.memory[id] = {};
    }
    return this.blockManager.memory[id];
  }

  setContent(content, userInput=true, client?) {
    const block = this.block;
    if (block.id) {
      const entity = getEntityById(block.id);
      this.getMemory().content = XClone(content);
      if (entity) {
        if (userInput) {
          clearTimeout(this.getMemory().timerId);
          this.getMemory().timerId = setTimeout(() => {
            XObject.withPass({ internal: 1 }, () => {
              entity.name = XClone(content);
            });
          }, 200);
        }
        else {
          XObject.withPass({ internal: 2 }, () => {
            entity.name = XClone(content);
          });
        }
      }
    }
    else {
      block.data = this.blockManager.args?.plainText ? expandToText({types:{}}, content) : XClone(content);
    }

    this.blockManager.args?.onSetContent?.(block._id, client);
    // TODO: block syncing
    // if (block.id) {
    //   const entity = getEntityById(block.id);
    //   if (entity) {
    //     XObject.withPass({ internal: block._id }, () => {
    //       entity.name = block.data || '';
    //     });
    //   }
    // }
    /*else if (block.record) {
      const db = new DB(this.props.database);
      const titleCol = db.titleCol();
      const record = db.getRecord(block.record);
      if (record) {
        XObject.withPass({ internal: block._id }, () => {
          record.data[titleCol._id] = X(x(block.data))[0] || '';
        });
      }
    }
    else if (block.dataBinding && block.attrBinding) {
      const e = getEntityById(this.props.entity);
      if (e) {
        let value;

        if (block.pullEntities) {
          value = collectEntitiesGood([block]).map(e => e.entity);
        }
        else {
          value = x(block.data).find(d => d[1] == 'capture')?.[0];
        }

        if (e.attributes) {
          e.attributes[block.attrBinding] = value;
        }
        else {
          e.attributes = X({
            [block.attrBinding]: value
          });
        }
      }
    }

    let parent = findBlockParent(this.blockManager, blockId);
    while (parent) {
      if (parent.pullEntities) {
        const e = getEntityById(this.props.entity);

        const value = collectEntitiesGood([parent]).map(e => e.entity);
        if (e.attributes) {
          e.attributes[parent.attrBinding] = value;
        }
        else {
          e.attributes = X({
            [parent.attrBinding]: value
          });
        }
      }

      parent = findBlockParent(this.blockManager, parent.getId());
    }*/
  }

  setChildren(children: MyBlocksList) {
    this.block.children = XClone(children.blocks);
  }

  syncing(): boolean {
    return this.block.id;
  }

  // serializeData() {
  //   return {
  //     type: this.block.type,
  //   }
  // }

  serialize() {
    return {
      _id: this.block._id,
      type: this.block.type,
      children: this.block.children,
      data: this.block.data,
    }
  }

  deserialize(data: any) {
    this.block.type = data.type;
    this.block.data = data.data;
    this.block.children = data.children;
  }

  // deserializeData(data: any) {
  //   // this.block.type = data.type;
  //   throw new Error(`not implemented`);
  // }

  createBlock(): Block {
    const b =  this.blockManager.newBlock();
    b.block.type = this.block.type;

    const newBlock = b.block;

    if (!this.blockManager.args.dontInherit) {
      if (this.block.type || this.block.id) {

        if (this.block.type == BlockType.template) {
          newBlock.valuePoint = this.block.valuePoint;
        }
  
        /*if (block.type) {
          if (!block.type.startsWith('heading')) {
            newBlock.type = block.type;
          }
          const blockType = blockTypes.find(b => b._id == block.type);
          if (block.id) {
            const entity = XObject.obj({
              type: blockType.entityType,
            });
            if (blockType.parent) {
              pushEdge(XObject.obj({
                entities: [blockType.parent, entity._id],
                directed: true,
              }))
            }
            if (blockType.stateful) {
              entity.stateful = true;
            }
            newBlock.id = entity._id;
            extendEntity?.(entity);
            createEntity(entity, baseEntity); // createEntityNull
          }
        }
        else {*/
  
  
        if (this.block.id) {
          const currentEntity = getEntityById(this.block.id);
          const e = {
            type: currentEntity.type,
          }
          this.blockManager.args.extendEntity?.(e);
          const entity = createEntity(e, this.blockManager.args.baseEntity);
          newBlock.id = entity._id;
        }
      }
  
    }


    return b;


  }

  handlesBackspaceAtStart(): boolean {
    if (this.block.type) {
      return true;
    }
    return false;
  }

  handleBackspaceAtStart(): void {
    this.block.type = null;
  }

  getRootBlockList(): BlocksList {
    const col = this.blockManager._findBlockColumn(this.blockManager.blocks(), this.getId(), null);
    if (col) {
      return new MyBlockColumn(col[1], this.blockManager).getChildren();
    }
    return this.blockManager.getRootBlocks();
  }
}

export class MyBlockColumns extends BlockColumns {
  constructor(public data, public blockManager: MyBlockManager) {
    super();
  }
  getColumns(): BlockColumn[] {
    return this.data.columns.map(c => new MyBlockColumn(c, this.blockManager));
  }
  hasChildren(): boolean {
    return false;
  }
  getId(): string {
    return this.data._id;
  }

  serialize() {
    return {
      _id: this.data._id,
      columns: this.data.columns,
    }
  }

  deserialize(data: any) {
    this.data.columns = data.columns;
  }

  removeColumn(i: number): void {
    this.data.columns.splice(i, 1);
    for (const col of this.data.columns) {
      col.width = 1/this.data.columns.length;
    }
  }


  insertColumn(i: number, block: MyBlock) {
    this.data.columns.splice(i, 0, XObject.obj({
      type: columnId,
      children: [
        block.block,
      ]
    }));
    for (const col of this.data.columns) {
      col.width = 1/this.data.columns.length;
    }

  }
}

export class MyBlockColumn extends BlockColumn {
  constructor(public data, public blockManager: MyBlockManager) {
    super();
  }
  getId(): string {
    return this.data._id;
  }

  getChildren(): MyBlocksList {
    return new MyBlocksList(this.data.children, (children) => {
      this.data.children = XClone(children) || X([]);
    }, this.blockManager);
  }

  hasChildren(): boolean {
    return this.data.children.length > 0;
  }

  getWidth() {
    return this.data.width;
  }

  setWidth(value) {
    this.data.width = value;
  }
}