import { Calendar } from "../../Calendar";
import { entityDisplayName } from "../../components/entityDisplayName";
import { db } from "../../db";
import { execFormulaFromData } from '../../shorthandEditor/execFormulaFromData';
import { ValueType } from "../ValueType";
import { mapStructure } from "../main";
import { structRenderers } from "../structRenderers";
import { typeRegistry } from "../typeRegistry.1";
import { doEventOccurrencesQuery } from "./$EventOccurrencesQuery";
import { executeEventOccurencesByEvent } from "./executeEventOccurencesByEvent";


const $EventOccurrencesCalendar = typeRegistry.registerType({
  $: '70cdc557-0cc0-5904-b993-81eab5ca32f4',
  DisplayType: {
    $: '698e025a-0402-5481-bf23-1c5bf20664ce',
    Calendar: '6f134276-7343-557a-bbd9-36c1cad72f73',
    List: '321006f1-eceb-57db-8856-3cf6a8b2302d',
  },
  EventOccurrences: '965d557b-a69f-5c2e-86b4-10264d45411d',
  GroupBy: 'e398908d-b76c-505b-ae34-97fd47e4de2a',
  Aggregate: '38da032f-9dbe-5b27-a05b-be4978de1693',
}, ids => ({
  _id: ids.$,
  name: 'Event Occurrences Breakdown',
  definition: [
    {
      id: ids.DisplayType.$,
      name: 'Display Type',
      type: [ ValueType.Enum, '5f75a2ab-4386-571a-929e-9a0c86be13c7', [
        [ 'Calendar', ids.DisplayType.Calendar ],
        [ 'List', ids.DisplayType.List ],
      ] ],

      property: 'displayType',
    },
    {
      id: ids.EventOccurrences,
      name: 'Event Occurrences',
      type: [],
      property: 'eventOccurrences',
    },
    {
      id: ids.GroupBy,
      name: 'Group By',
      type: [],
      property: 'groupBy',
    },
    {
      id: ids.Aggregate,
      name: 'Aggregate',
      type: [],
      property: 'aggregate',
    },
  ],
}));

structRenderers[$EventOccurrencesCalendar.$] = (value, map) => {
  const mapped = mapStructure(value);
  const eventOccurrences = doEventOccurrencesQuery(mapped.eventOccurrences, map);

  if (mapped.displayType.content == $EventOccurrencesCalendar.DisplayType.Calendar) {
    return (
      <div
        style={{
          overflow: 'auto'
        }}
      >
      <Calendar
        renderDay={day => {
          const endOfDay = day.clone().endOfDay();
          const eventOccurrencesOnDay = eventOccurrences.filter(x => {
            const eo = db.eventOccurrences.findById(x.id);
            if (eo.timestamp >= day && eo.timestamp <= endOfDay) {
              return true;
            }
          });

          const grouped = {};
          for (const x of eventOccurrencesOnDay) {
            const eo = db.eventOccurrences.findById(x.id);
            const key = eo.arguments;
            if (!grouped[key]) grouped[key] = [];

            grouped[key].push(x);
          }

          const keys = Object.keys(grouped);
          keys.sort((a, b) => {
            const aValue = entityDisplayName(a), bValue = entityDisplayName(b);
            return aValue < bValue ? -1 : 1;
          })

          return keys.map(id => {
            const r = execFormulaFromData(mapped.aggregate.content.formula, {
              $eventOccurrences: grouped[id],
            })
            return (
              <div key={id}>
                {entityDisplayName(id)}: {r}
              </div>
            )
          });
        }}
      />
      </div>
    )
  }
  else if (mapped.displayType.content == $EventOccurrencesCalendar.DisplayType.List) {
  
    const eventOccurrencesOnDay = eventOccurrences;

    const grouped = {};
    for (const x of eventOccurrencesOnDay) {
      const eo = db.eventOccurrences.findById(x.id);
      const key = eo.arguments;
      if (!grouped[key]) grouped[key] = [];

      grouped[key].push(x);
    }

    const keys = Object.keys(grouped);
    keys.sort((a, b) => {
      const aValue = entityDisplayName(a), bValue = entityDisplayName(b);
      return aValue < bValue ? -1 : 1;
    })

    console.log('asdfasdfaasdf', eventOccurrences);

    const total = execFormulaFromData(mapped.aggregate.content.formula, {
      $eventOccurrences: eventOccurrences,
    })



    return (
      <>
        Total: {total}
        {keys.map(id => {
      const r = execFormulaFromData(mapped.aggregate.content.formula, {
        $eventOccurrences: grouped[id],
      })
      return (
        <div key={id}>
          {entityDisplayName(id)}: {r}
        </div>
      )
    })}
      </>
    )
  }
}

/*
Sum($eventOccurences.Map((x) => x.Amount))
*/