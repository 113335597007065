import { entityDisplayName } from "../../components/entityDisplayName";
import { evaluate, mapStructure } from "../main";
import { structRenderers } from "../structRenderers";
import { typeRegistry } from "../typeRegistry.1";
import { doEntityQuery } from "./$EntityQuery";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export const $BarChart = typeRegistry.registerType({
  $: 'd7a851ca-24ae-5e79-8c12-6a84399b9154',
  HorizontaAxis: 'f1b923a4-4a4f-5dfa-b9f3-a7de6800a2fb',
  VerticalAxis: 'e3251f99-72d3-5fda-9315-6ce7f25df0f8',
}, ids => ({
  _id: ids.$,
  name: 'Bar Chart',
  definition: [
    {
      id: ids.HorizontaAxis,
      name: 'Horizontal Axis',
      type: [],
      property: 'horizontalAxis',
    },
    {
      id: ids.VerticalAxis,
      name: 'Vertical Axis',
      type: [],
      property: 'verticalAxis',
    },

  ],
}));

structRenderers[$BarChart.$] = (value, map, state, renderType) => {
  const mapped = mapStructure(value);
  const horizontalAxis = doEntityQuery(mapped.horizontalAxis);
  
  const options = () => {


    const series = [];
    const categories = [];
    const data = [];

    for (const id of horizontalAxis) {

      const r = evaluate(mapped.verticalAxis, {
        __env: {
          $x: id,
        }
      })
      // execFormulaFromData(mapped.verticalAxis.content.formula, {
      // })
      data.push(r);
  
      categories.push(entityDisplayName(id));
    }
    series.push({
      type: 'column',
      data: data,
    })


    const options: Highcharts.Options = {
      title: {
        text: null,
      },
      // xAxis: {
      //   categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      // },
      // series: [{
      //   name: 'Installation',
      //   data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4]
      // }],
      xAxis: {
        categories: categories,
      },
      yAxis: {
        tickInterval: 1,
      },
      series,
      
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
          },
  
        },

      }

      // plotOptions: {
      //   line: {
      //     dataLabels: {
      //       enabled: true
      //     },
      //     enableMouseTracking: false
      //   }
      // },

    };

    return options;
  }
  return (
    <div data-value-point={value._id}>
    <HighchartsReact
      highcharts={Highcharts}
      options={options()}
    />
    </div>
  );
}