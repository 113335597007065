import { Type } from "../../richTextHelpers";
import { DataCtx } from "../../richTextHelpers";



export function resolveOffset(ctx: DataCtx, el, position): [Node, number] {
  const { types } = ctx;

  let pos = 0;
  for (let i = 0; i < el.childNodes.length; ++i) {
    const node = el.childNodes[i];
    let length;
    if (types[node?.getAttribute?.('data-type')]?.type == Type.atomic) {
      length = 1;
      if (position == pos) {
        return [el, i];
      }
    }
    else if (node.firstChild?.getAttribute?.('data-type') && types[node.firstChild?.getAttribute?.('data-type')]?.type == Type.atomic) {
      length = 1;
      if (position == pos) {
        return [el, i];
      }
    }
    else if (node.nodeType === Node.TEXT_NODE) {
      length = node.length;
      if (position >= pos && position <= pos + length) {
        return [node, position - pos];
      }
    }
    else if (node?.getAttribute?.('data-presentation')) {
      length = node.textContent.length;
      if (position >= pos && position < pos + length) {
        return [node.firstChild, position - pos];
      }

    }


    pos += length;

  }

  return [el, el.childNodes.length];
}
