import { db } from '../db';

export function getOccurrenceDescendents(ocurrence) {
  const occurrences = [];
  const children = db.eventOccurrences.filter(eo => eo.parent == ocurrence._id);
  for (const child of children) {
    occurrences.push(child);
    occurrences.push(...getOccurrenceDescendents(child));
  }

  return occurrences;
}
