import jQuery from 'jquery';
import { XObject, X, x } from './XObject';
import _ from 'lodash';
import { isMobile } from './isMobile';

export const _inspectState = () => {
	return XObject.get(currentSpace(), 'inspectState', {}) as {
		focused
		inspectType
	}
}

export interface IInspectObject {
	type
	args
}



export function triggerInspectObject(obj: IInspectObject, openSidebar=false) {

	if (isMobile()) {
		if (openSidebar) {
			_inspectState().focused = obj;
			window['g_presentMobileInspectModal']({
				type: 'f885051f-2117-5276-9564-004aa60085d3',
				obj,
			});
		}

	}
	else {
		_inspectState().focused = obj;
		if (openSidebar) {
			currentSpace().sideBar = true;
		}
	
	}
}


export const _inspectHandlers = {};
interface IInspectObjectHandler {
	padding?
	render
}
export function registerInspectObjectHandler(type, handler: IInspectObjectHandler) {
	_inspectHandlers[type] = handler;
}

let osKey = 'os.space';

let componentCache = {};
export function getComponentCache(win, component, namespace='') {
	let key = `${win._id}-${win.type}-${namespace}`;
	if (!componentCache[key]) {
		componentCache[key] = component;
	}
	return componentCache[key];
}

export function setOsKey(key) {
	osKey = key;

	_state.os = X({});
	_state.space = X(localStorage.getItem(osKey) ? JSON.parse(localStorage.getItem(osKey)) : {
		sideBar: false,
	});

	_lastMovePos = XObject.get(_state.space, 'lastMovePos', {});
	_lastSize = XObject.get(_state.space, 'lastSize', {});

	let timerId;
	XObject.observe(_state.space, mutation => {
		clearTimeout(timerId);
		timerId = setTimeout(() => {
			// console.log('saving os state');
			localStorage.setItem(osKey, JSON.stringify(x(_state.space)));
		}, 100);
	});

}

let _state: any = X({});



export function currentOs(): any {
	return _state.os;
}

export function currentSpace(): any {
	return _state.space;
}

export function setWindowTransitionMode(mode) {
	_state.mode = mode;
}

export function getWindowTransitionMode() {
	return _state.mode;
}


export enum WindowArea {
	windows = 'windows',
	taskBar = 'taskBar',
}

export function windowAreaList(state: WindowArea): string[] {
	return XObject.get(XObject.get(currentSpace(), 'windowAreas', {}), state, []);
}

type Window = any;

export function windowRegistry(): { [ id: string ]: Window } {
	return XObject.get(currentSpace(), 'windows', {}) || {};
}


export function openWindow(config, size?, e?) {
	const windows = windowRegistry(), windowArea = windowAreaList(WindowArea.windows), taskBarArea = windowAreaList(WindowArea.taskBar);

	let width = 700;
	let height = 500;
	if (_lastSize[config.type]) {
		width = _lastSize[config.type].width;
		height = _lastSize[config.type].height;
	}
	else if (size) {
		[ width, height ] = size;
	}
	let x = jQuery(window).width()/2 - width/2;
	let y = Math.max(0, jQuery('.os-page > .area').height()/2 - height/2);

	if (_lastMovePos[config.type]) {
		x = _lastMovePos[config.type].x;
		y = _lastMovePos[config.type].y;
	}
	else {
		if (windowArea.length) {
			const topWindow = windows[windowArea[windowArea.length - 1]];
			if (topWindow) {
				x = topWindow.pos.x + 20;
				y = topWindow.pos.y + 20;
			}
		}	
	}

	x = Math.max(0, Math.min(x, jQuery(window).width() - width - 5));
	y = Math.max(0, Math.min(y, jQuery(window).height() - height - 5));

	const win = XObject.obj(Object.assign({
		pos: { x, y },
		size: { width, height },
		debugMode: true,
	}, config));
	windows[win._id] = win;

	windowArea.push(win._id);
	taskBarArea.push(win._id);
}

export function closeWindow(win) {
	{
		const i = windowAreaList(WindowArea.taskBar).indexOf(win._id);
		if (i != -1) {
			windowAreaList(WindowArea.taskBar).splice(i, 1);
		}	
	}
	{
		const i = windowAreaList(WindowArea.windows).indexOf(win._id);
		if (i != -1) {
			windowAreaList(WindowArea.windows).splice(i, 1);
		}	
	}

	delete windowRegistry()[win._id];
}


export function formatDate(date) {
	if (!date) return '';
	if (currentOs().config.dateTimeFormat) {
		return date.format(currentOs().config.dateTimeFormat);
	}
	else {
		if (!date.format) {
			console.log(date);
			return '';
		}
		return date.format('{yyyy}-{MM}-{dd} {HH}:{mm}');
	}
}

export function componentConfig(component) {
	let data = XObject.get(currentOs(), 'config', {});
	let components = XObject.get(data, 'components', {});

	return XObject.get(components, _.isString(component) ? component : component._id, {});
}

let _lastMovePos = {};
export function setLastMovePos(win, pos) {
	_lastMovePos[win.type] = pos;
}


let _lastSize = {};
export function setLastSize(win, size) {
	_lastSize[win.type] = size;
}
