import { Component } from 'react';
import _ from 'lodash';
import { component } from './component2';


@component
export class Select extends Component<{ placeholder?; options; value; onChangeValue; }> {
  render() {
    return (
      <select
        value={this.props.options.find(o => _.isEqual(o.value, this.props.value))?.display || ''}
        onChange={e => {
          this.props.onChangeValue(this.props.options.find(o => o.display == e.target.value)?.value);
        }}
      >
        <option style={{ color: 'gray'}} value=''>{this.props.placeholder}</option>
        {this.props.options.map(o => (
          <option key={o.display} value={o.display}>{o.display}</option>
        ))}
      </select>
    );
  }
}
