import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import jQuery from 'jquery';
import fuzzy from 'fuzzy';
import Sugar from 'sugar';
import classNames from 'classnames';
import _, { union, filter, bind, at } from 'lodash';
import { component, styled } from '../component2';
import { w } from '../w';
import { NotionDataEditor as BlockDataEditor } from './NotionDataEditor';
import { XInit, XObject, x } from '../XObject';
import { _BlockManager, _BlockComp, BlockEditor } from './BlockEditor';
import { _Editor, editorStateForKey, state, FocusAnchor, isId } from '../etc/draftHelpers';
import { getDefaultKeyBinding } from 'draft-js';
import { ImbuedTextInput } from './ImbuedTextInput';
import { collections, db } from '../db';
import Around from './Around';
import juration from '../juration';
import { PropertyField } from "./PropertyField"
import { entityTypes } from '../etc/entities';
import { entityMetaStates } from '../etc/entityMatch';
import { defaultWorkspace } from '../etc/defaultWorkspace';
import { renderQueuePositions } from '../glue/getQueue';
import { renderAttributes } from "../etc/renderAttributes";
import { createEntity, getEntityById } from '../etc/createEntity';

interface TBlockType {
  _id
  name

  checkboxBinding?
  attributes?
  actions?
  badges?


}

const blockTypes: TBlockType[] = [
  ...entityTypes.map(t => ({
    _id: t._id,
    name: t.name,
  }))
  // {
  //   _id: '1',
  //   name: 'Task',
  // },
  // {
  //   _id: '2',
  //   name: 'App Component',
  // },
  // {
  //   _id: '3',
  //   name: 'Requirement',
  // },
  // {
  //   _id: '4',
  //   name: 'Specification',
  // },
  // {
  //   _id: '5',
  //   name: 'Feature',
  // },
  // {
  //   _id: '6',
  //   name: 'Bug',
  // },
  // {
  //   _id: '7',
  //   name: 'Note',
  // },
  // {
  //   _id: '8',
  //   name: 'Page',
  // },
];


const Svg = {
  x: <svg viewBox="0 0 8 8" style={{ width: '8px', height: '8px', display: 'block',  backfaceVisibility: 'hidden', opacity: 0.5 }}><polygon points="8 1.01818182 6.98181818 0 4 2.98181818 1.01818182 0 0 1.01818182 2.98181818 4 0 6.98181818 1.01818182 8 4 5.01818182 6.98181818 8 8 6.98181818 5.01818182 4"></polygon></svg>,
  dots: <svg viewBox="0 0 13 3" style={{ width: '14px', height: '14px', display: 'block', fill: 'rgba(55, 53, 47, 0.4)', flexShrink: 0, backfaceVisibility: 'hidden' }}><g> <path d="M3,1.5A1.5,1.5,0,1,1,1.5,0,1.5,1.5,0,0,1,3,1.5Z"></path> <path d="M8,1.5A1.5,1.5,0,1,1,6.5,0,1.5,1.5,0,0,1,8,1.5Z"></path> <path d="M13,1.5A1.5,1.5,0,1,1,11.5,0,1.5,1.5,0,0,1,13,1.5Z"></path> </g></svg>,
  grip: <svg viewBox="0 0 10 10" style={{ width: '12px', height: '12px', display: 'block', fill: 'rgba(55, 53, 47, 0.4)', flexShrink: 0, backfaceVisibility: 'hidden' }}><path d="M3,2 C2.44771525,2 2,1.55228475 2,1 C2,0.44771525 2.44771525,0 3,0 C3.55228475,0 4,0.44771525 4,1 C4,1.55228475 3.55228475,2 3,2 Z M3,6 C2.44771525,6 2,5.55228475 2,5 C2,4.44771525 2.44771525,4 3,4 C3.55228475,4 4,4.44771525 4,5 C4,5.55228475 3.55228475,6 3,6 Z M3,10 C2.44771525,10 2,9.55228475 2,9 C2,8.44771525 2.44771525,8 3,8 C3.55228475,8 4,8.44771525 4,9 C4,9.55228475 3.55228475,10 3,10 Z M7,2 C6.44771525,2 6,1.55228475 6,1 C6,0.44771525 6.44771525,0 7,0 C7.55228475,0 8,0.44771525 8,1 C8,1.55228475 7.55228475,2 7,2 Z M7,6 C6.44771525,6 6,5.55228475 6,5 C6,4.44771525 6.44771525,4 7,4 C7.55228475,4 8,4.44771525 8,5 C8,5.55228475 7.55228475,6 7,6 Z M7,10 C6.44771525,10 6,9.55228475 6,9 C6,8.44771525 6.44771525,8 7,8 C7.55228475,8 8,8.44771525 8,9 C8,9.55228475 7.55228475,10 7,10 Z"></path></svg>,
  plus: <svg viewBox="0 0 16 16" className="plus" style={{ width: '12px', height: '100%', display: 'block', fill: 'rgba(55, 53, 47, 0.4)', flexShrink: 0, backfaceVisibility: 'hidden' }}><path d="M7.977 14.963c.407 0 .747-.324.747-.723V8.72h5.362c.399 0 .74-.34.74-.747a.746.746 0 00-.74-.738H8.724V1.706c0-.398-.34-.722-.747-.722a.732.732 0 00-.739.722v5.529h-5.37a.746.746 0 00-.74.738c0 .407.341.747.74.747h5.37v5.52c0 .399.332.723.739.723z"></path></svg>,
  open: <svg viewBox="0 0 14 14" style={{ width: '14px', height: '14px', display: 'block', fill: 'rgba(55, 53, 47, 0.4)', flexShrink: 0, backfaceVisibility: 'hidden' }}><polygon points="9.13029 3.66667 3.66667 9.13029 3.66667 7 2 7 2 12 7 12 7 10.3333 4.82065 10.3333 10.3333 4.82065 10.3333 7 12 7 12 2 7 2 7 3.66667"></polygon></svg>,

  downArrow: <svg viewBox="0 0 30 30" style={{ width: '10px', height: '100%', display: 'block', fill: 'inherit', flexShrink: 0, backfaceVisibility: 'hidden' }}><polygon points="15,17.4 4.8,7 2,9.8 15,23 28,9.8 25.2,7 "></polygon></svg>,
}


@component
class Chip extends Component<{ text, onX?, color?, size?, onClick? }> {
  static styles = styled.span`
    margin: 0px 6px 6px 0px;
    padding-left: 6px;
    border-radius: 3px;
    background-color: rgb(211, 229, 239);
    font-size: 14px;
    height: 18px;

    display: inline-flex;

    align-items: center;

    white-space: nowrap;

    &:not(.hasX) {
      padding-right: 6px;
    }

		&.hasOnClick {
			cursor: pointer;
		}
  `;

  static t = {
    xCont: styled.div`
      width: 18px;
      height: 18px;
      margin: 0 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    `,

  }


  render(Container?) {
    const { t } = Chip;
    
    return <Container style={{ backgroundColor: this.props.color, zoom: this.props.size }} className={classNames(this.props.onX && 'hasX', this.props.onClick && 'hasOnClick')} onClick={this.props.onClick}>{this.props.text}{this.props.onX && <t.xCont onClick={this.props.onX}>{Svg.x}</t.xCont>}</Container>
  }
}




function contentToString(content) {
  content = x(content);
  if (_.isString(content)) {
    return content;
  }
  else if (_.isArray(content)) {
    return content.join('');
  }
}

async function turnInto(block, blockType:TBlockType) {
  const entityType = entityTypes.find(i => i._id == blockType._id);
  if (entityType) {
    block.type = 'entity';

    const entity = XObject.obj({
      type: entityType._id,
    });

    createEntity(entity, null); // createEntityNull

    block.content = entity._id;
  }
}

export const notionPageLibraryId = '64579d48-e055-55e6-b69f-81954d34a1b1';

function notionPageLibrary() {
  let library = db.dataObjects.find(i => i.type == notionPageLibraryId);
  if (!library) {
    library = XObject.obj({
      type: notionPageLibraryId,
    })
    db.dataObjects.push(library);
  }

  return library;
}

type BlockType = any;


@component
export class EntityEditor extends Component<{
  editorId,
  id,
  onEnter?,
  tag?,
  handleKey?,
  // view,
  block,
  blockManager,
  focusBlock?,
  blockType: TBlockType,
  _onFocus?,
  _onBlur?,
  focused?
  root?
}> {
  constructor(props) {
    super(props);
  }

  text
  _text

  openPos

  state = XInit(class {
    focused
    hovered
  });

  static t = {
    block: styled.div`
      &:not(.stateful) {
        opacity: .6;
      }

      &.closed {
        opacity: .6;
        text-decoration: line-through;
      }

      &.highlight {
        /* text-decoration: underline; */
        background-color: #f9ff86;
      }

    `,
    open: styled.span`

      margin-right: 4px;
      font-size: 10px;
      height: 12px;

      &:not(.hovered) {
        opacity: .5;
      }

      &.focused {
        font-weight: bold;
        opacity: 1;
      }
    `,
    features: styled.div`
      position: absolute;
      top: 0;
      z-index: 1;
      bottom: 0;
      margin: auto;

      .metaStates {
        margin-left: 4px;
        margin-right: 4px;
        font-size: 10px;
        color: #0c62c3;
      }

      .documentCounts {
        margin-right: 4px;
        font-size: 10px;

      }

      .queuePositions {
        font-size: 10px;
        margin-right: 4px;
      }

      /* opacity: .5; */

      /* &.hovered, &.focused {
        opacity: 1;
      } */
    `,
  };

  static styles() {
    const { t } = this;
    return styled.div`
      position: relative;

      .hasCheckbox {
        position: relative;
        padding-left: 21px;
        input[type="checkbox"] {
          position: absolute;
          left: 0;
          top: 3px;
        }
      }

    `
  }

  editable() {
    return true;
    return this.props.block.data.editable || this.props.block.data.blockType;
  }

  loaded


  updatePos() {
    const el = ReactDOM.findDOMNode(this);
    const textEl = jQuery(el).find('[data-text]');
    const width = (parseInt(textEl.width()) || 0);
    if (width != this.openPos) {
      this.openPos = width;      
      this.forceUpdate();
    }
  }

  getText() {
    return this.text;
  }

  tick = 0

  componentDidUpdate() {
    this.updatePos();
  }

  componentDidMount(): void {
    const entity  = getEntityById(this.props.id);
    if (entity) {
      this.text = entity.name || '';
      this.loaded = true;
      this.forceUpdate();  
    }
  }

  updateTimer
  editor

  render() {
    const { t } = EntityEditor;
    const Tag = this.props.tag || 'div';
    const { blockType } = this.props;

    const metaStates = entityMetaStates(this.props.id);
    const closed = !!metaStates.find(id => defaultWorkspace().metaStates.find(i => i._id == id).closedState);
    const highlight = !!metaStates.find(id => defaultWorkspace().metaStates.find(i => i._id == id).highlightState);


    if (this.editable()) {
      if (!this.loaded) return;

      const entity  = getEntityById(this.props.id);

  
      const update = () => {
        if (this.text != this._text) {
          this._text = this.text;
          entity.name = this.text;
        }
      }
      const hasCheckbox = false;// !!this.props.blockType.checkboxBinding;
      return (
        <t.block
          onMouseEnter={() => {
            this.state.hovered = true;
          }}
          onMouseLeave={() => {
            this.state.hovered = false;
          }}

          className={classNames({
            hovered: this.state.hovered,
            stateful: entity.stateful,
            closed,
            highlight,
          })}
        >
          <Tag className={hasCheckbox && 'hasCheckbox'} data-entity={this.props.id}>
            {hasCheckbox && (() => {
            return <input type="checkbox" checked={false} onClick={() => {}} />
            })()}

            <ImbuedTextInput
              key={this.tick}
              triggerCharacters={['/']}
              selectorEl={(character, filter, ref) => {
                if (character == '/') {
                  const suggestions = [];
                  if (blockType.attributes) {
                    for (const attribute of blockType.attributes) {

                    }
                  }

                  if (blockType.actions) {
                    for (const action of blockType.actions) {
                      suggestions.push({
                        category: 'Actions',
                        key: action._id,
                        label: action.label,
                        view: ({ close }) => {
                          return (
                            <>
                              {/* <_Form
                                _ctx={ctx}
                                ctx={ctx.ctx}
                                form={action.action}
                                onEsc={close}
                                onSubmit={r => {
                                  console.log(r);
                                  close();
                                }}
                              /> */}
                            </>
                          )
                        }
                      });

                    }
                  }

                  return (
                    <Selector
                      ref={ref}
                      filter={filter}
                      block={this.props.block}
                      blockManager={this.props.blockManager}
                      focusBlock={this.props.focusBlock}
                      suggestions={suggestions}
                    />
                  );
                }
              }}
              id={this.props.editorId}
              keyBindingFn={e => this.props.handleKey(e, this.props.editorId)}
              defaultValue={[this.text]}
              onChange={(value) => {
                this.text = value[0]
                const el = ReactDOM.findDOMNode(this);
                const textEl = jQuery(el).find('[data-text]');
                this.openPos = textEl.width();
                this.forceUpdate();
  
                clearTimeout(this.updateTimer);
                this.updateTimer = setTimeout(() => {
                  update()
                }, 1000);
              }}
              onEnter={this.props.onEnter}
              _onFocus={() => {
                this.state.focused = true;
                this.props._onFocus?.();
              }}
              _onBlur={() => {
                this.state.focused = false;
                update()
                this.props._onBlur?.();
              }}
            />

            <t.features className={classNames({ hovered: this.state.hovered, focused: this.props.focused })} style={{ left: (hasCheckbox ? 21 : 0) + this.openPos + 8 + 'px' }}>
            <t.open className={classNames({ hovered: this.state.hovered, focused: this.props.focused })}>{
                this.props.blockType?.name
              }</t.open>
              {blockType?.badges?.map?.(b => {
                if (b.hide) return null;
                return <Chip size={b.size} color={b.color} key={b._id} text={'name'} onClick={b.action && (e => {
                  
                })} />
              })}

              {blockType?.attributes?.map?.(a => {
                if (a.dataBinding && a.access != 'ce072ed9-b534-5637-98e8-f95f70d10b3c') {
                  const dataBinding:any = {}
                  if (dataBinding.type == 'entity') {
                    return <Chip size={a.size} color={a.color} key={a._id}  text={'asdf'} />
                  }
                  else if (dataBinding.type == 'time') {
                    return <Chip size={a.size} color={a.color} key={a._id}  text={'asdf'} />
                  }
                  else if (dataBinding.type == 'duration') {
                    return <Chip size={a.size} color={a.color} key={a._id}  text={'asdf'} />
                  }

                }
              })}

                {this.props.root && <span className="queuePositions">{renderQueuePositions(this.props.root, entity._id)}</span>}
              


              {entity?.documents?.length > 0 && <span className="documentCounts">({entity?.documents?.length})</span>}


<span
          style={{
            fontSize: '9px',
            color: '#d58428',
          }}
        >{renderAttributes(entity)}</span>

<span className="metaStates">{entityMetaStates(this.props.id).map(id => defaultWorkspace().metaStates.find(i => i._id == id).name).join(', ')}</span>

            </t.features>

          </Tag>
        </t.block>
      );
  
    }
    else {
      return (
        <Tag>
          <ImbuedTextInput
            id={this.props.editorId}
            entityDisplay={async e => {
              if (e.type == 'entity') {
                return 'poop';
              }
            }}
            defaultValue={[ { type: 'entity', content: this.props.id } ]}
            triggerCharacters={['/']}
            selectorEl={(character, filter, ref) => {
              if (character == '/') {
                return (
                  <Selector filter={filter} ref={ref} block={this.props.block} blockManager={this.props.blockManager} focusBlock={this.props.focusBlock} />
                );
              }
            }}
            keyBindingFn={e => this.props.handleKey(e, this.props.editorId)}
            onEnter={this.props.onEnter}
            _onFocus={() => {
              this.state.focused = true;
              this.props._onFocus?.();
            }}
            _onBlur={() => {
              this.props._onBlur?.();
            }}
          />
        </Tag>
      );
    }
  }
}

@component
class Selector extends Component<{ filter, block, blockManager, focusBlock, suggestions?: {
  category
  key
  label
  onSelect?
}[] }> {
  static styles = styled.div`
    background-color: white;
    box-shadow: rgb(15 15 15 / 5%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 3px 6px, rgb(15 15 15 / 20%) 0px 9px 24px;
    border-radius: 3px;
    width: 200px;
    color: black;
    /* padding: 8px; */
  `;

  static t = {
    header: styled.span`
      color: rgba(55, 53, 47, 0.6);
      font-size: 11px;
      font-weight: 500;
      line-height: 120%;
      user-select: none;
      text-transform: uppercase;
      padding: 0 14px;
      margin-bottom: 4px;
      display: block;
    `,
    category: styled.div`
      padding-top: 6px;
      padding-bottom: 6px;
      &:not(:first-child) {
        box-shadow: rgb(55 53 47 / 9%) 0px -1px 0px;
        margin-top: 6px;
      }
    `,

    entry: styled.div`
      user-select: none;
      transition: background 20ms ease-in 0s;
      cursor: pointer;

      &.active {
        background: #f3f3f3;
      }

      padding: 5px 14px;
      box-sizing: border-box;
    `
  }

  suggestions = []

  state = XInit(class {
    selected = 0
  })


  view

  updateSuggestions() {
    const { block } = this.props;

    
    const all = (blockTypes.map(blockType => ({
      key: blockType._id,
      category: 'Create',
      label: blockType.name,
      onSelect: () => {

        (async () => {
          if (contentToString(block.content)) {
            const newBlock = XObject.obj({ content: [], children: [] });
            await turnInto(newBlock, blockType);
            this.props.focusBlock(newBlock._id);
            this.props.blockManager.addBlock(block, newBlock, true);  
          }
          else {
            turnInto(block.data, blockType);
          }
        })();

      }
    })) as any).concat(blockTypes.map(blockType => ({
      key: blockType._id,
      category: 'Turn Into',
      label: blockType.name,
      onSelect: async () => {
        turnInto(block.data, blockType);
      }
    }))).concat({
      category: 'Turn Into',
      key: 'checkItem',
      label: 'Check Item',
      onSelect: () => {
        block.data.type = 'checkItem';
      }
    }).concat({
      category: 'Create',
      key: 'checkItem',
      label: 'Check Item',
      onSelect: () => {
        if (contentToString(block.content)) {
          const newBlock = XObject.obj({ content: [], children: [], type: 'checkItem' });
          this.props.focusBlock(newBlock._id);
          this.props.blockManager.addBlock(block, newBlock, true);
        }
        else {
          block.data.type = 'checkItem';
        }
      }
    }).concat({
      category: 'Turn Into',
      key: 'h1',
      label: 'Heading 1',
      onSelect: () => {
        block.data.type = 'h1';
      }
    }).concat({
      category: 'Create',
      key: 'h1',
      label: 'Heading 1',
      onSelect: () => {
        if (contentToString(block.content)) {
          const newBlock = XObject.obj({ content: [], children: [], type: 'h1' });
          this.props.focusBlock(newBlock._id);
          this.props.blockManager.addBlock(block, newBlock, true);
        }
        else {
          block.data.type = 'h1';
        }
      }
    }).concat({
      category: 'Turn Into',
      key: 'h2',
      label: 'Heading 2',
      onSelect: () => {
        block.data.type = 'h2';
      }
    }).concat({
      category: 'Create',
      key: 'h2',
      label: 'Heading 2',
      onSelect: () => {
        if (contentToString(block.content)) {
          const newBlock = XObject.obj({ content: [], children: [], type: 'h2' });
          this.props.focusBlock(newBlock._id);
          this.props.blockManager.addBlock(block, newBlock, true);
        }
        else {
          block.data.type = 'h2';
        }
      }
    })
    .concat({
      category: 'Actions',
      key: 'attachEntity',
      label: 'Attach Entity',
      view: ({ close }) => {
        return (
          <>
            {/* <EntitySelector onSelected={value => {
              console.log('asdfa', x(value), value)
              block.data.type = 'entity';
              block.data.content = value;
              close();
            }} /> */}
          </>
        );
      },
    })
    
    .concat(this.props.suggestions as any || []);

    this.suggestions = all.filter(entry => fuzzy.test(this.props.filter, entry.label));

    this.forceUpdate();
  }

  onDownArrow(e) {
    console.log(e);
    this.state.selected ++;
  }
  onUpArrow(e) {
    this.state.selected --;
    console.log(e);
  }
  onEnter(e, clear) {
    const entry = this.getEntry(this.state.selected);
    
    if (entry.onSelect) {
      entry.onSelect();
      clear();
    }
    else if (entry.view) {
      this.view = () => entry.view({ close: clear });
      this.forceUpdate();
    }
    
  }

  componentDidMount() {
    this.updateSuggestions();
  }

  componentDidUpdate(prevProps) {
    if (this.props.filter != prevProps.filter) {
      this.updateSuggestions();
    }
  }

  getEntry(ii) {
    const grouped = {};

    for (const entry of this.suggestions) {
      if (!grouped[entry.category]) grouped[entry.category] = [];
      grouped[entry.category].push({ ...entry  });
    }

    let i = 0;
    for (const cat in grouped) {
      for (const entry of grouped[cat]) {
        if ( i++ == ii) return entry;
      }
    }
  }

  render() {
    const { t } = Selector;

    if (this.view) {
      return this.view();
    }
    const grouped = {};

    for (const entry of this.suggestions) {
      if (!grouped[entry.category]) grouped[entry.category] = [];
      grouped[entry.category].push({ ...entry  });
    }

    let i = 0;
    for (const cat in grouped) {
      for (const entry of grouped[cat]) {
        entry.i = i++;
      }
    }

    return (
      Object.keys(grouped).map(cat => (
        <t.category key={cat}>
          <t.header>{cat}</t.header>
          {grouped[cat].map(entry => (
            <t.entry className={this.state.selected == entry.i && 'active'} key={entry.key}>{entry.label}</t.entry>
          ))}
        </t.category>
      ))
    );


    // return library.blockTypes.map(blockType => (
    //   <li key={blockType._id}>
    //     <span className="link" onClick={async () => {
    //       // const r = await this.context.api.alter([ { $entity: [ '$', { creationString: contentToString(this.clicked.block.data.content) } ] } ]);
    //     }}>{blockType.name}</span>
    //   </li>
    // ))
  }
}

type TBlock = {
  _id
  content: any
  children: TBlock[]
}

@component
export class BlockPageCont extends Component<{ id
  _onFocused?
  blockPage: {
  title
  entity
  content: TBlock[]
}, rootBlock?: string, inWindow, noHeader?, state?: { layout } }> {
  static styles() {
    const { t } = this;
    return styled.div`
      background: white;
      ${_BlockComp.t.handle} {
        margin-top: 6px;
      }

      &.inWindow ${t.main} {

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: auto;

      }

      ${t.main}.board, ${t.main}.graph {
        display: flex;
        flex-direction: column;
        ${t.content} {
          flex: 1 1 0;
          position: relative;
        }

        ${BlockEditor} {
          height: 100%;
        }
      }
    `
  }
  focusedEditor
  state = XInit(class {
    data = [
      XObject.obj({
        content: [],
        children: []
      })
    ]
    focused

    state = {}

    attachingEntity 
    asdf = {}
  })

  static t = {
    block: styled.div`
      /* height: 30px; */
      font-size: 16px;
      line-height: 1.5;
      padding: 3px 2px;


      &.hasCheckbox {
        position: relative;
        padding-left: 21px;
        input[type="checkbox"] {
          position: absolute;
          left: 0;
          top: 5px;
        }

        &.checked {
          color: gray;

          text-decoration: line-through;
        }
      }


    `,
    menu: styled.div`
      background-color: white;
      border: 1px solid black;
      > button {
        display: block;
      }
    `,
    entity: styled.div`
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 270px;
      z-index: 999;
      background: white;
      overflow: auto;
    `,

    entities: styled.div`
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 400px;
      overflow: auto;
      border-right: 1px solid black;
    `,

    main: styled.div`
      padding: 8px 24px;
      &.hasRightPanel {
        margin-right: 270px;
      }
    `,

    content: styled.div`

    `,

    header: (() => {
      const t = {
        actions: styled.div`
          display: flex;
          transition: opacity 100ms ease 0s;

          span {
            user-select: none;
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            flex-shrink: 0;
            white-space: nowrap;
            height: 28px;
            border-radius: 3px;
            font-size: 14px;
            line-height: 1.2;
            min-width: 0px;
            padding-left: 6px;
            padding-right: 8px;
            color: rgba(55, 53, 47, 0.4);
            pointer-events: auto;
    
            &:hover {
              background: #f3f3f3;
            }
          }
        `,
        title: styled.span`
          font-size: 35px;
          font-weight: 600;
          display: block;

          .public-DraftEditorPlaceholder-root {
            color: #e1e1e0;
          }
        `,
        entity: styled.span`
          color: rgba(55,53,47,0.4);
          display: block;
          font-size: 12px;
        `,
      };
      return w('', styled.div`
        margin-bottom: 16px;
        color: #4a4a4a;
        &:not(:hover) {
          ${t.actions} {
            opacity: 0;
          }
        }
      `, t)
    })(),


  }


  _loading = {};

  classifications = {}

  clicked

  data() {
    const pageData: TBlock[] = XObject.get(this.props.blockPage, 'content', [
      XObject.obj({
        content: [],
        children: []
      })
    ]);

    if (this.props.rootBlock) {
      const findBlock = (blocks: TBlock[]): [TBlock] => {
        for (const block of blocks) {
          if (block._id == this.props.rootBlock) return [ block ];
          const b = findBlock(block.children);
          if (b) return b;
        }
      }

      return findBlock(pageData) || [];
    }
    else {
      return pageData;
    }
  }

  render(Container?) {
    const { t } = BlockPageCont;


    const handleKey = ({ e, _id, keyBindingFn, blockManager, block }) => {
      if (keyBindingFn) {
        const r = keyBindingFn(e);
        if (r == 'handled') return 'handled';
      }
      if (e.key == 'Tab') {
        if (e.shiftKey) {
          blockManager.unindent(block);
        }
        else {
          blockManager.indent(block);
        }
        return 'handled';
      }
      else if (e.key == 'ArrowUp') {
        const next = blockManager.prevBlock(block);
        if (next) {
          this.focusedEditor = next.id;
          // blockManager.components[next.id].editor.focus();  
        }
        return 'handled';
      }
      else if (e.key == 'ArrowDown') {
        const next = blockManager.nextBlock(block);
        if (next) {
          this.focusedEditor = next.id;
          // blockManager.components[next.id].editor.focus();

        }
        return 'handled';
      }
      else if (e.key == 'Backspace') {
        const editorState = editorStateForKey(_id);
        if (!editorState) {
          console.log(_id);
        }
        else if (editorState.isSelectionAtStartOfContent()) {
          if (block.data.type == 'checkItem') {
            delete block.data.type;
          }
          else if (block.data.type == 'entity') {
            console.log('test');
            blockManager.unindent(block, true);
          }
          else {
            const nextBlock = blockManager.unindentOrDelete(block, true);
            if (nextBlock) {
              state.focusAnchor = FocusAnchor.end;
              state.focused = this.props.id + nextBlock.data._id;
            }
  
            // if (block.parent) {
            //   blockManager.unindent(block);
            // }
            // else {
            //   const nextBlock = blockManager.removeBlock(block, true);
  
            //   if (nextBlock) {
            //     state.focusAnchor = FocusAnchor.end;
            //     state.focused = this.props.id + nextBlock.data._id;
            //   }
            // }
            this.forceUpdate();
  
          }
          return 'handled';

        }
      }
      return getDefaultKeyBinding(e);
    }

    const onEnter = (block, blockManager, e) => {
      let newBlock;
      newBlock = XObject.obj({ content: [], children: [] });
      state.focused = this.props.id + newBlock._id;
      const nb = blockManager.addBlock(block, newBlock, true);

      if (!e.altKey) {
        if (block.data.type == 'checkItem') nb.data.type = 'checkItem';
        else {
          const blockType = blockTypes.find(t => t._id == block.data.blockType);
          if (blockType) {
            // turnInto(this.context.api, nb.data, blockType);
          }
        }  
      }
    }

    const renderDraftEditor = (block, blockManager: _BlockManager, { id, keyBindingFn }: { id, keyBindingFn?  }) => {
      // const _id = this.props.id + id;
      return (
        <ImbuedTextInput
          id={id}
          defaultValue={block.content}          
          triggerCharacters={[ '@', '&', '/' ]}
          selectorEl={(character, filter, ref) => {
						if (character == '/') {
              return (
                <Selector filter={filter} ref={ref} block={block} blockManager={blockManager}   focusBlock={id => {
                  state.focused = this.props.id + id;
                }} />
              );
						}
          }}
					options={(character, filter) => {
						if (character == '@') {
              if (isId(filter)) {
                return [
                  {
                    key: filter,
                    name: 'Entity',
                    type: 'Entity',
                    value: {
                      type: 'entity',
                      content: filter,
                    }
                  }
                ]
              }
              else {
                // this.entityFiltering.state.filter = filter;
                return [];
  
              }
						}
						else if (character == '&') {							
							try {
								return [
									{
										key: '',
										type: 'Time',
										name: `${filter} (${(Sugar.Date.create(filter) as any).format('{yyyy}-{MM}-{dd} {HH}:{mm}')})`,
										value: {
											content: Sugar.Date.create(filter),
											type: 'time',
										}
									}
								];	
							}
							catch (e) {
								return [
								];	
							}
						}
					}}
					entityDisplay={async e => {
						if (e.type == 'entity') {
							return 'Entity Name';
						}
						else if (e.type == 'time') {
							return e.content.format('{yyyy}-{MM}-{dd} {HH}:{mm}');
						}
						else if (e.type == 'text') {
							return e.content;
						}
					}}

          onChange={value => {
            if (block.data.type != 'entity') {
              block.content = value
            }
          }}
          keyBindingFn={(e) => {
            return handleKey({ e, _id: id, keyBindingFn, block, blockManager })
          }}
          onEnter={(content, clear, e) => {
            if (!e.shiftKey) {
              onEnter(block, blockManager, e);
              this.forceUpdate();
              return 'handled';
            }
          }}
        />
      );
    }

    const data = this.data();



    // let entities = [];

    // const blockType = blockTypes[0];


    // const collectEntities = (blocks, level=0) => {
    //   for (const block of blocks) {
    //     let nextLevel = level;
    //     if (block.type == 'entity' && block.blockType == blockType._id) {
    //       ++ nextLevel;
    //       entities.push({ id: block.content, level });
    //     }

    //     if (block.children) {
    //       collectEntities(block.children, nextLevel);
    //     }
    //   }
    // }

    // collectEntities(data);



    const ref = React.createRef<BlockDataEditor>();

    const layout = this.props.state?.layout || 'notion';

    return (
      <Container className={this.props.inWindow && 'inWindow'}>
        
        <t.main className={classNames(layout, { hasRightPanel: this.state.focused && 0 })}>
          {!this.props.noHeader && <t.header>
            <t.header.actions>
              {!_.isNil(this.props.blockPage.title) && <span onClick={() => {
                delete this.props.blockPage.title;
              }}>Remove title</span>}
              {_.isNil(this.props.blockPage.title) && <span onClick={() => {
                this.props.blockPage.title = '';
              }}>Add title</span>}

              {_.isNil(this.props.blockPage.entity) && <span onClick={() => {
                this.props.blockPage.entity = '';
              }}>Attach entity</span>}
              {!_.isNil(this.props.blockPage.entity) && <span onClick={() => {
                delete this.props.blockPage.entity;
              }}>Remove entity</span>}
            <PropertyField object={this.props.state} property="layout" type="dropdown" options={[ 'notion', 'board', 'graph' ]} />

            </t.header.actions>

            {!_.isNil(this.props.blockPage.title) && (
              <t.header.title><_Editor
                placeholder="Untitled"
                defaultContent={this.props.blockPage.title}
                onContentChanged={(__, value) => {
                  this.props.blockPage.title = value;
                }}
              /></t.header.title>
            )}



          </t.header>}

          <t.content>
            {!data.length && <button onClick={() => {
              data.push(XObject.obj({
                content: [],
                children: [],
              }))
            }}>init</button>}
          <BlockDataEditor
            ref={ref}
            setContent={(block, content) => {
              block.content = content;
            }}
            newBlock={content => content}
            setFocusedBlock={id => this.focusedEditor = id}
            data={data}
            blockSelected={block => {
              if (block?.data?.type == 'entity') {
                this.state.focused = block.data.content;
                this.props._onFocused?.(block.data.content);
              }
            }}
            dataInterface={{
              id: (data) => {
                return this.props.id + data._id;
              },
              children(data) {
                return data.children;
              },
              getContent(data) {
                return data.content;
              },
              setContent(data, content) {
                data.content = content;
              },
              collapsed(data) {
                return data.collapsed;
              },
              setCollapsed(data, value) {
                data.collapsed = value;
              },
            }}





            renderBlock={(block, blockManager: _BlockManager) => {
              const editorId = this.props.id + block.data._id;
              if (block.data.type == 'entity') {
                // if (!block.data.blockType) {
                //   return (
                //     <t.block>
                //       >
                //     </t.block>
                //   )
                // }
                const entityId = block.data.content;
                const classifications = [];

                let Tag: any = 'div';
                for (const id of classifications) {
                  // const match = config.matches.find(match => match._id == id);
                  // Tag = match.styles ? compileSnippet(match.styles) : 'div';
                  // if (Tag instanceof NoValue) Tag = 'div';
                }

                const entity = getEntityById(entityId);

                return (
                  <t.block data-value={block.data.content}>
                    <EntityEditor
                      key={block.data.blockType}
                      focused={this.state.focused == entityId}
                      editorId={editorId}
                      block={block}
                      blockManager={blockManager}
                      handleKey={(e, _id) => {
                        return handleKey({ e, _id, block, blockManager, keyBindingFn: null });
                      }}
                      focusBlock={id => {
                        state.focused = this.props.id + id;
                      }}
                      tag={Tag}
                      id={entityId}
                      blockType={entity?.type && db.entityTypes.findById(entity?.type)}
                      // view={block.data.blockType && blockTypes.find(i => i._id == block.data.blockType)?.view}
                      onEnter={(value, __, e) => {
                        onEnter(block, blockManager, e);
                        this.forceUpdate();
                        return 'handled';  
                      }}
                      _onFocus={() => {
                        this.state.focused = entityId;
                        this.props._onFocused?.(entityId);
                      }}
                      _onBlur={() => {
                      }}
                    />
                  </t.block>
                );
              }
              else {
                const styles = (() => {
                  if (block.data.type == 'h1') {
                    return { fontSize: '1.875em', fontWeight: '600', marginTop: '0.9em', marginBottom: '4px' };
                  }
                  else if (block.data.type == 'h2') {
                    return { fontSize: '1.5em', fontWeight: '600', marginTop: '1.4em', marginBottom: '1px' };
                  }

                })();
                return <t.block className={classNames({ 'hasCheckbox':  block.data.type == 'checkItem', checked: block.data.checked })} style={styles}>{block.data.type == 'checkItem' && <input type="checkbox" checked={block.data.checked} onClick={() => block.data.checked = !block.data.checked} />}{renderDraftEditor(block, blockManager, { id: editorId })}</t.block>;
              }
            }}
            _onClick={(block, e) => {
              if (this.clicked?.block?.id == block.id) {
                this.clicked = null;
              }
              else {
                this.clicked = { target: e, block }
              }
              this.forceUpdate();
            }}
            state={XObject.get(this.props.blockPage, 'boardState', {})}
            layout={layout}
          />
          </t.content>
        </t.main>

        {/* {this.state.focused && <t.entity>
          <EntitySource rightColOnly id={this.state.focused} state={this.state.state} />
        </t.entity>} */}
        {this.clicked && (
          <Around
            anchor={() => this.clicked?.target}
            position="below"
          >
            <t.menu>
              <button onClick={() => {
                this.clicked.block.data.collapsed = !this.clicked.block.data.collapsed;
                this.clicked = null;
                this.forceUpdate();
              }}>Toggle</button>
              <button onClick={() => {
                ref.current.blockManager.removeBlock(this.clicked.block);
                this.clicked = null;
                this.forceUpdate();
              }}>Delete block</button>
              {this.clicked.block?.data?.type == 'entity' && (
                <button onClick={() => {
                  // this.context.api.alter({ $entity: { [this.clicked.block.data.content]: '$deleteAll' } });
                  // ref.current.blockManager.removeBlock(this.clicked.block);
                  // this.clicked = null;
                  // this.forceUpdate();
                }}>Delete block and entity</button>
              )}
              {/* <div><EntitySelector onSelected={value => {

                this.clicked.block.data.type = 'entity';
                this.clicked.block.data.content = value;
                this.clicked = null;
                this.forceUpdate();
              }} /></div>
              <ul>
                {this.clicked.block.data.type != 'entity' && <li><span className="link" onClick={async () => {
                  const r = await this.context.api.alter([ { $entity: [ '$', { creationString: contentToString(this.clicked.block.data.content) } ] } ]);
                  this.clicked.block.data.content = r.entities.$;
                  this.clicked.block.data.type = 'entity';
                }}>Create entity</span></li>}
                {library.blockTypes.map(blockType => (
                  <li key={blockType._id}>
                    <span className="link" onClick={async () => {
                      await turnInto(this.context.api, this.clicked.block.data, blockType);
                      this.clicked = null;
                      this.forceUpdate();
                    }}>{blockType.name}</span>
                  </li>
                ))}
                {this.clicked.block.data.type == 'entity' && (
                  <li><EntityRef entity={this.clicked.block.data.content} /></li>
                )}
              </ul> */}
              {/* <Inspector data={x(this.clicked.block.data)} /> */}
            </t.menu>
          </Around>
        )}
      </Container>
    );
  }
}
