import { db } from '../db';
import { getAllEdges } from '../getAllEdges';
import { appState } from './appState';
import { getEntityById } from './createEntity';
import { deleteEdgeByIndex, indexEdges } from './getEdgesForEntity';

export function deleteObject(obj) {
  obj.__deleted = {
    timestamp: new Date(),
    user: appState.user,
  };
}

export function deleteEntity(id: string, real?: boolean) {
  if (!real) {
    const entity = getEntityById(id);
    deleteObject(entity);
    indexEdges();
  }
  else {
    while (true) {
      const index = getAllEdges().findIndex(e => e.entities.includes(id));
      if (index === -1) {
        break;
      }
      deleteEdgeByIndex(index);
    }
  
    const index = db.entities.findIndex(e => e._id === id);
    if (index !== -1) {
      db.entities.splice(index, 1);
    }
  }
}
