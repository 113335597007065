import _ from 'lodash';
import { db } from '../db';
import { ObjectRef, ObjectType } from '../types/ObjectRef';
import { entityDisplayName, entityDisplayView } from './entityDisplayName';
import { createRootValuePoint, getStructureById, getValuePoint } from '../glue/main';
import { XObject, x } from '../XObject';
import { getAllEntities, getEntityById } from '../etc/createEntity';


export function getObject(ref: ObjectRef) {
  if (ref.type == ObjectType.type) {
    return db.entityTypes.findById(ref.id);
  }
  else if (ref.type == ObjectType.event) {
    return db.events.findById(ref.id);
  }
  else if (ref.type == ObjectType.parameterizedEvent) {
    return db.parameterizedEvents.findById(ref.id);
  }
  else if (ref.type == ObjectType.attribute) {
    return db.attributeTypes.findById(ref.id);
  }
  else if (ref.type == ObjectType.query) {
    return db.queries.findById(ref.id);
  }
  else if (ref.type == ObjectType.entity) {
    return getEntityById(ref.id);
  }
  else if (ref.type == ObjectType.document) {
    // console.log(ref)
    return db.notionDocuments.findById(ref.id);
    throw new Error('Not implemented');
  }
  else if (ref.type == ObjectType.space) {
    return db.spaces.findById(ref.id);
  }
  else if (ref.type == ObjectType.library) {
    return db.libraries.findById(ref.id);
  }
  else if (ref.type == ObjectType.global) {
    return null;
  }
  else if (ref.type == ObjectType.element) {
    return db.elements.findById(ref.id);
  }
  else if (ref.type == ObjectType.mode) {
    return db.modes.findById(ref.id);
  }
  else if (ref.type == ObjectType.codeComponent) {
    return db.codeComponents.findById(ref.id);
  }
  else if (ref.type == ObjectType.valuePoint) {
    return getValuePoint(ref.id);
  }
  else if (ref.type == ObjectType.canvas) {
    return db.canvases.findById(ref.id);
  }
  else if (ref.type == ObjectType.tag) {
    return db.tags.findById(ref.id);
  }
  else if (ref.type == ObjectType.day) {
    return db.days.findById(ref.id);
  }
  else if (ref.type == ObjectType.page) {
    return db.pages2.findById(ref.id);
  }
  else if (ref.type == ObjectType.app) {
    return db.apps.findById(ref.id);
  }
  else if (ref.type == ObjectType.table) {
    return db.tables.findById(ref.id);
  }
  else if (ref.type == ObjectType.tableSchema) {
    return db.tableSchemas.findById(ref.id);
  }
  else if (ref.type == ObjectType.objectHandle) {
    return db.objectHandles.findById(ref.id);
  }
  else if (ref.type == ObjectType.formula) {
    return db.formulas.findById(ref.id);
  }
  else if (ref.type == ObjectType.identifier) {
    return db.identifiers.findById(ref.id);
  }
  else if (ref.type == ObjectType.eventOccurrence) {
    return db.eventOccurrences.findById(ref.id);
  }
  else if (ref.type == ObjectType.database) {
    return db.dataObjects.findById(ref.id);
  }
  else if (ref.type == ObjectType.note) {
    return db.notes.findById(ref.id);
  }
  else if (ref.type == ObjectType.workspace) {
    return db.dataObjects.findById(ref.id);
  }
  else if (ref.type == ObjectType.devProject) {
    return db.dataObjects.findById(ref.id);
  }
  else {
    console.log(x(ref));
    throw new Error('Invalid type');
  }
}

function _setObjectParent(type, obj, parent) {
  if (type == ObjectType.entity) {
    obj.space = parent;
  }
  else if (type == ObjectType.type) {
    obj.scope = parent;
  }
  else if (type == ObjectType.attribute) {
    obj.scope = parent;
  }
  else if (type == ObjectType.space) {
    obj.scope = parent;
  }
  else if (type == ObjectType.document) {
    obj.parent = parent;
  }
  else if (type == ObjectType.codeComponent) {
    obj.parent = parent;
  }
  else if (type == ObjectType.tag) {
    obj.parent = parent;
  }
  else if (type == ObjectType.event) {
    obj.parent = parent;
  }
  else if (type == ObjectType.page) {
    obj.parent = parent;
  }
  else if (type == ObjectType.app) {
    obj.parent = parent;
  }
  else if (type == ObjectType.table) {
    obj.parent = parent;
  }
  else if (type == ObjectType.tableSchema) {
    obj.parent = parent;
  }
  else if (type == ObjectType.objectHandle) {
    obj.parent = parent;
  }
  else if (type == ObjectType.formula) {
    obj.parent = parent;
  }
  else if (type == ObjectType.identifier) {
    obj.parent = parent;
  }
  else if (type == ObjectType.query) {
    obj.parent = parent;
  }
  else if (type == ObjectType.valuePoint) {
    obj.parent = parent;
  }
  else if (type == ObjectType.workspace) {
    obj.parent = parent;
  }
  else if (type == ObjectType.database) {
    obj.parent = parent;
  }
  else if (type == ObjectType.devProject) {
    obj.parent = parent;
  }
  else {
    throw new Error('Not implemented');
  }
}
export function setObjectParent(ref: ObjectRef, parent?: ObjectRef) {
  const obj = getObject(ref);
  _setObjectParent(ref.type, obj, parent);
}

export function getObjectParent(ref: ObjectRef) {
  const obj = getObject(ref);
  if (!obj) {
    return null;
  }
  if (ref.type == ObjectType.type) {
    return obj.scope;
  }
  else if (ref.type == ObjectType.attribute) {
    return obj.scope;
  }
  else if (ref.type == ObjectType.element) {
    return obj.scope;
  }
  else if (ref.type == ObjectType.query) {
    if (obj.parentType) {
      return {
        id: obj.parent,
        type: obj.parentType,
      };
    }
    else if (_.isString(obj.parent)) {
      if (getEntityById(obj.parent)) {
        return {
          id: obj.parent,
          type: ObjectType.entity,
        };
      }
      else {
        return {
          id: obj.parent,
          type: ObjectType.query,
        };

      }
    }
    else if (obj.parent) {
      return obj.parent;
    }
    else if (obj.relative) {
      const entity = getAllEntities().find(e => e.queries?.find?.(q => q.query == obj._id));
      if (!entity)
        return null;
      return {
        id: entity._id,
        type: ObjectType.entity,
      };
    }
  }
  else if (ref.type == ObjectType.entity) {
    if (_.isString(obj.space)) {
      return {
        id: obj.space,
        type: ObjectType.space,
      };
    }
    else {
      return obj.space;
    }
  }
  else if (ref.type == ObjectType.document) {
    return obj.parent;
  }
  else if (ref.type == ObjectType.space) {
    return obj.scope;
  }
  else if (ref.type == ObjectType.codeComponent) {
    return obj.parent;
  }
  else if (ref.type == ObjectType.tag) {
    return obj.parent;
  }
  else if (ref.type == ObjectType.event) {
    return obj.parent;
  }
  else if (ref.type == ObjectType.parameterizedEvent) {
    return { id: obj.event, type: ObjectType.event };
  }
  else if (ref.type == ObjectType.page) {
    return obj.parent;
  }
  else if (ref.type == ObjectType.app) {
    return obj.parent;
  }
  else if (ref.type == ObjectType.table) {
    return obj.parent;
  }
  else if (ref.type == ObjectType.tableSchema) {
    return obj.parent;
  }
  else if (ref.type == ObjectType.objectHandle) {
    return obj.parent;
  }
  else if (ref.type == ObjectType.formula) {
    return obj.parent;
  }
  else if (ref.type == ObjectType.identifier) {
    return obj.parent;
  }
  else if (ref.type == ObjectType.valuePoint) {
    if (_.isString(obj.parent)) {
      return { type: ObjectType.valuePoint, id: obj.parent };
    }
    else {
      return obj.parent;
    }
  }
  else if (ref.type == ObjectType.workspace) {
    return obj.parent;
  }
  else if (ref.type == ObjectType.database) {
    return obj.parent;
  }
  else if (ref.type == ObjectType.devProject) {
    return obj.parent;
  }
}

export function objectName(ref: ObjectRef, plainText=true) {
  if (ref.type == ObjectType.global) {
    return 'Global';
  }

  const obj = getObject(ref);
  if (!obj) return '(deleted)';

  if (ref.type == ObjectType.type) {
    return obj.name;
  }
  else if (ref.type == ObjectType.attribute) {
    return obj.name;
  }
  else if (ref.type == ObjectType.element) {
    return obj.name;
  }
  else if (ref.type == ObjectType.query) {
    return obj.name;
  }
  else if (ref.type == ObjectType.entity) {
    if (plainText) {
      return entityDisplayName(obj._id);
    }
    else {
      return entityDisplayView(obj._id);
    }
  }
  else if (ref.type == ObjectType.document) {
    return obj.name;
  }
  else if (ref.type == ObjectType.tag) {
    return obj.name;
  }
  else if (ref.type == ObjectType.space) {
    return obj.name;
  }
  else if (ref.type == ObjectType.library) {
    return obj.name;
  }
  else if (ref.type == ObjectType.mode) {
    return obj.name;
  }
  else if (ref.type == ObjectType.codeComponent) {
    return obj.name;
  }
  else if (ref.type == ObjectType.event) {
    return obj.name;
  }
  else if (ref.type == ObjectType.database) {
    return obj.name;
  }

  else if (ref.type == ObjectType.parameterizedEvent) {
    const eventName = objectName({ type: ObjectType.event, id: obj.event });
    const entityName = obj.arguments && objectName({ type: ObjectType.entity, id: obj.arguments });
    if (entityName) return `${eventName} ${entityName}`;
    else return eventName;
  }
  else if (ref.type == ObjectType.valuePoint) {
    if (obj.name) return obj.name;
    const structure = obj.type?.[1] && getStructureById(obj.type?.[1]);
    return structure?.name || 'value point ' + obj._id;

    // return 'value point ' + obj._id;
  }
  else if (ref.type == ObjectType.day) {
    return obj.date;
  }
  else if (ref.type == ObjectType.canvas) {
    return obj.name;
  }
  else if (ref.type == ObjectType.page) {
    return obj.name;
  }
  else if (ref.type == ObjectType.app) {
    return obj.name;
  }
  else if (ref.type == ObjectType.table) {
    return obj.name;
  }
  else if (ref.type == ObjectType.tableSchema) {
    return obj.name;
  }
  else if (ref.type == ObjectType.objectHandle) {
    return obj.name;
  }
  else if (ref.type == ObjectType.formula) {
    return obj.name;
  }
  else if (ref.type == ObjectType.workspace) {
    return obj.name;
  }
  else if (ref.type == ObjectType.devProject) {
    return obj.name;
  }
  else if (ref.type == ObjectType.identifier) {
    if (obj.relative) return `.${obj.name}`;
    return obj.name;
  }
}

export function setObjectName(ref: ObjectRef, name) {
  const obj = getObject(ref);
  if (ref.type == ObjectType.type ||
      ref.type == ObjectType.canvas ||
      ref.type == ObjectType.attribute ||
      ref.type == ObjectType.document || 
      ref.type == ObjectType.query ||
      ref.type == ObjectType.mode ||
      ref.type == ObjectType.tag || 
      ref.type == ObjectType.event ||
      ref.type == ObjectType.page ||
      ref.type == ObjectType.app ||
      ref.type == ObjectType.table ||
      ref.type == ObjectType.tableSchema ||
      ref.type == ObjectType.objectHandle ||
      ref.type == ObjectType.formula ||
      ref.type == ObjectType.workspace ||
      ref.type == ObjectType.identifier ||
      ref.type == ObjectType.database ||
      ref.type == ObjectType.devProject
    ) {
    obj.name = name;
  }
  else {
    throw new Error('Not implemented');
  }
}

export function getScopeTree(scope: ObjectRef) {
  if (scope.type == ObjectType.global) return [scope];
  const tree = [scope];
  while (true) {
    const parent = getObjectParent(scope);
    if (!parent) {
      break;
    }
    tree.push(parent);
    scope = parent;
  }

  tree.push({
    id: null,
    type: ObjectType.global,
  });

  return tree;
}
function getFromLibrary(id, type) {
  // const library = db.libraries.findById(id);
  if (type == ObjectType.type) {
    return db.entityTypes.filter(t => t.scope?.id == id).map(t => t._id);
  }
  else if (type == ObjectType.attribute) {
    return db.attributeTypes.filter(t => t.scope?.id == id).map(t => t._id);
  }
  else if (type == ObjectType.tag) {
    return db.tags.filter(t => t.parent?.id == id).map(t => t._id);
  }
}

export function typesInScope(scope: ObjectRef): string[] {
  if (!scope)
    return db.entityTypes.filter(t => !t.scope).map(t => t._id);
  let types = [];
  const tree = getScopeTree(scope);

  for (const ref of tree) {
    if (ref.type == ObjectType.global) {
      types = types.concat(db.entityTypes.filter(t => !t.scope || t.scope.type == ObjectType.global).map(t => t._id));
    }
    else if (ref.type == ObjectType.space) {
      const space = db.spaces.findById(ref.id);
      if (space.imports) {
        for (const entry of space.imports) {
          if (entry.type == ObjectType.type) {
            types.push(entry.id);
          }
          else if (entry.type == ObjectType.library) {
            types = types.concat(getFromLibrary(entry.id, ObjectType.type));
          }
        }
      }
      types = types.concat(db.entityTypes.filter(t => t.scope?.id == ref.id).map(t => t._id));
    }
    else if (ref.type == ObjectType.document) {
      const space = db.notionDocuments.findById(ref.id);
      if (space.imports) {
        for (const entry of space.imports) {
          if (entry.type == ObjectType.type) {
            types.push(entry.id);
          }
          else if (entry.type == ObjectType.library) {
            types = types.concat(getFromLibrary(entry.id, ObjectType.type));
          }
        }
      }
      types = types.concat(db.entityTypes.filter(t => t.scope?.id == ref.id).map(t => t._id));
    }

    else {
      types = types.concat(db.entityTypes.filter(t => t.scope?.id == ref.id).map(t => t._id));
    }
  }


  return _.uniq(types);
}

export function tagsInScope(scope: ObjectRef) {
  if (!scope)
    return db.tags.filter(t => !t.parent).map(t => t._id);
  let types = [];
  const tree = getScopeTree(scope);
  for (const ref of tree) {
    if (ref.type == ObjectType.global) {
      types = types.concat(db.tags.filter(t => !t.parent).map(t => t._id));
    }
    else if (ref.type == ObjectType.space) {
      const space = db.spaces.findById(ref.id);
      if (space.imports) {
        for (const entry of space.imports) {
          if (entry.type == ObjectType.tag) {
            types.push(entry.id);
          }
          else if (entry.type == ObjectType.library) {
            types = types.concat(getFromLibrary(entry.id, ObjectType.tag));
          }
        }
      }
      types = types.concat(db.tags.filter(t => t.parent?.id == ref.id).map(t => t._id));
    }
    else {
      types = types.concat(db.tags.filter(t => t.parent?.id == ref.id).map(t => t._id));
    }
  }
  return types;
}

export function attributesInScope(scope: ObjectRef) {
  if (!scope)
    return db.attributeTypes.filter(t => !t.scope).map(t => t._id);
  let types = [];
  const tree = getScopeTree(scope);
  for (const ref of tree) {
    if (ref.type == ObjectType.global) {
      types = types.concat(db.attributeTypes.filter(t => !t.scope).map(t => t._id));
    }
    else if (ref.type == ObjectType.space) {
      const space = db.spaces.findById(ref.id);
      if (space.imports) {
        for (const entry of space.imports) {
          if (entry.type == ObjectType.attribute) {
            types.push(entry.id);
          }
          else if (entry.type == ObjectType.library) {
            types = types.concat(getFromLibrary(entry.id, ObjectType.attribute));
          }
        }
      }
      types = types.concat(db.attributeTypes.filter(t => t.scope?.id == ref.id).map(t => t._id));
    }
    else {
      types = types.concat(db.attributeTypes.filter(t => t.scope?.id == ref.id).map(t => t._id));
    }
  }
  return types;
}

export function typesInScopes(scopes: ObjectRef[]) {
  let types = [];
  for (const scope of scopes) {
    types = types.concat(typesInScope(scope));
  }
  return _.uniq(types);
}

export function attributesInScopes(scopes: ObjectRef[]) {
  let types = [];
  for (const scope of scopes) {
    types = types.concat(attributesInScope(scope));
  }
  return _.uniq(types);
}

export function objectIcon(obj) {
  return typeIcon(obj.type);
}

export function typeIcon(type) {
  if (type == ObjectType.space) {
    return 'box';
  }
  else if (type == ObjectType.entity) {
    return 'tesseract';
  }
  else if (type == ObjectType.type) {
    return 'icons8-type';
  }
  else if (type == ObjectType.attribute) {
    return 'icons8-detail (1)';
  }
  else if (type == ObjectType.library) {
    return 'bundle';
  }
  else if (type == ObjectType.query) {
    return 'database';
  }
  else if (type == ObjectType.document) {
    return 'icons8-page';
  }
  else if (type == ObjectType.mode) {
    return 'icons8-mode';
  }
  else if (type == ObjectType.canvas) {
    return 'icons8-canvas';
  }
  else if (type == ObjectType.codeComponent) {
    return 'icons8-code';
  }
  else if (type == ObjectType.tag) {
    return 'icons8-price-tag';
  }
  else if (type == ObjectType.event) {
    return 'icons8-time';
  }
  else if (type == ObjectType.parameterizedEvent) {
    return 'icons8-time';
  }
  else if (type == ObjectType.page) {
    return 'icons8-web-page';
  }
  else if (type == ObjectType.app) {
    return 'icons8-app-icon';
  }
  else if (type == ObjectType.workspace) {
    return 'icons8-app-icon';
  }
  else if (type == ObjectType.devProject) {
    return 'icons8-app-icon';
  }

  else if (type == ObjectType.table) {
    return 'icons8-table';
  }
  else if (type == ObjectType.tableSchema) {
    return 'icons8-schema';
  }
  else if (type == ObjectType.objectHandle) {
    return 'icons8-shortcut';
  }
  else if (type == ObjectType.identifier) {
    return 'icons8-shortcut';
  }
  else if (type == ObjectType.formula) {
    return 'icons8-formula';
  }
}


export function createNewObject(type, parent?) {
  if (type == ObjectType.valuePoint) {
    createRootValuePoint(undefined, parent);
  }
  else {
    let id;
    let obj;
    if (type == ObjectType.type) {
      obj = XObject.obj();
      id = obj._id;
    }
    else if (type == ObjectType.attribute) {
      obj = XObject.obj();
      id = obj._id;
    }
    else if (type == ObjectType.database) {
      obj = XObject.obj({
        type: ObjectType.database,
      });
      id = obj._id;
    }
    else if (type == ObjectType.identifier) {
      obj = XObject.obj();
      id = obj._id;
    }
    else if (type == ObjectType.page) {
      obj = XObject.obj({
        version: '65de94f4-2c38-5760-9f9a-30a89228b558',
      });
      id = obj._id;
    }
    else {
      obj = XObject.obj();
      id = obj._id;
    }
  
    if (parent) {
      _setObjectParent(type, obj, parent);
    }
  
    if (type == ObjectType.type) {
      db.entityTypes.push(obj);
    }
    else if (type == ObjectType.attribute) {
      db.attributeTypes.push(obj);
    }
    else if (type == ObjectType.tag) {
      db.tags.push(obj);
    }
    else if (type == ObjectType.tableSchema) {
      db.tableSchemas.push(obj);
    }
    else if (type == ObjectType.page) {
      db.pages2.push(obj);
    }
    else if (type == ObjectType.identifier) {
      db.identifiers.push(obj);
    }
    else if (type == ObjectType.database) {
      db.dataObjects.push(obj);
    }
    else {
      throw new Error('Unknown type');
    }
  }
}