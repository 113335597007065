import React, { Component } from 'react';
import { db } from './db';
import { component } from './component2';
import { X, XObject } from './XObject';
import { QueryEditorWindow } from './windows/QueryEditorWindow';
import { ObjectDisplay } from './components/ObjectDisplay';
import { ObjectType } from './types/ObjectRef';
import { EditDocumentWindow } from './windows/EditDocumentWindow';
import { AttributeEditor } from './windows/AttributeEditor';
import { ObjectPicker } from './components/ObjectPicker';
import { EditModeWindow } from './windows/EditModeWindow';
import { CanvasEditor } from './CanvasEditor';
import { TypeEditor } from './components/TypeEditor';
import { EntityCellType } from './components/cells';
import { IconSelector } from './components/IconSelector';
import { CheckboxItem, CodaIconSelectorItem, EntityDropdownItem, Field, NavItem, Section, TextInput } from './components/QueryViewEditor';
import { EventEditor } from './EventEditor';
import { setAppInspect } from './etc/appState';
import { UIInspectPane } from './components/UIInspectPane';
import { InspectState } from './components/InspectState';
import { createRootValuePoint } from './glue/main';
import { PropertyField } from './components/PropertyField';
import { DevProjectInspector } from './DevProjectInspector';


@component
class PageInspector extends Component<{ page }> {
  render() {
    const { page } = this.props;
    return (
      <>
        <Section>
          <CheckboxItem label="Edit Mode" value={page.editMode} setValue={v => page.editMode = v} />
          <CheckboxItem label="Full Width" value={page.fullWidth} setValue={v => page.fullWidth = v} />

        </Section>


        <Section>
        <Field label="Title">
          <TextInput value={page.title} setValue={v => page.title = v} />
        </Field>
        <Field label="Subtitle">
          <TextInput value={page.subtitle} setValue={v => page.subtitle = v} />
        </Field>

        <CodaIconSelectorItem label="Icon" value={page.icon} setValue={v => page.icon = v} />
        </Section>


        <Section>
        <CheckboxItem label="Template" value={page.template} setValue={v => page.template = v} />
        {/* {page.template && <div>
          Entity: <Cell
            cell={new EntityCellType({})}
            get={() => page.entity}
            set={v => page.entity = v}
            title="Entity"
          />
        </div>} */}

        {page.template && <EntityDropdownItem label="Entity" value={page.entity} setValue={v => page.entity = v} />}
        </Section>


        {/* <div>
          Icon: <IconSelector
            get={() => page.icon}
            set={v => page.icon = v}
          />
        </div> */}

      </>
    )
  }
}

@component
export class ObjectEditor2 extends Component<{ obj; objNav?; }> {
  render() {
    const { obj } = this.props;

    return (
      <>
        {obj.type == ObjectType.event && (
          <>
            <EventEditor id={obj.id} />
          </>
        )}
        {obj.type == ObjectType.query && (
          <QueryEditorWindow window={{
            query: obj.id,
          }} />
        )}
        {obj.type == ObjectType.document && (
          <EditDocumentWindow
            window={{
              document: obj.id,
            }} />
        )}
        {obj.type == ObjectType.attribute && (
          <AttributeEditor window={{ id: obj.id }} />
        )}
        {obj.type == ObjectType.mode && (
          <>
            <EditModeWindow
              window={{
                id: obj.id,
              }} />
          </>
        )}
        {obj.type == ObjectType.canvas && (
          <>
            <CanvasEditor id={obj.id} />
          </>
        )}
        {obj.type == ObjectType.codeComponent && (
          <>
            {(() => {
              const comp = db.codeComponents.findById(obj.id);
              if (comp) {
                return (
                  <>
                    <ul>
                      {comp?.scope?.map?.((obj, i) => (
                        <li key={obj._id}>

                          <ObjectDisplay obj={obj} showPath /> <button
                            onClick={() => {
                              comp.scope.splice(i, 1);
                            }}
                          >X</button>
                        </li>
                      ))}
                    </ul>

                    <ObjectPicker
                      _onSelect={(obj, clear) => {
                        XObject.push(comp, 'scope', XObject.obj(obj));
                        clear();
                      }} />
                  </>
                );
              }
            })()}
          </>
        )}
        {obj.type == ObjectType.tag && (
          (() => {
            const tag = db.tags.findById(obj.id);
            return (
              <>
                <>
                  <select
                    value={tag.style || ''}
                    onChange={e => {
                      tag.style = e.target.value;
                    }}
                  >
                    <option />
                    <option value="5c4ad6d5-b808-55f2-b0e2-8583876b97bd">Done</option>
                    <option value="73e24e6e-96e5-577d-bbae-5c9838d41439">Highlight</option>
                    <option value="e712b7ba-7765-5f99-b3f9-76c423ce22e2">Background</option>
                  </select>
                </>
              </>
            );
          })()
        )}
        {obj.type == ObjectType.page && (
          (() => {
            const page = db.pages2.findById(obj.id);
            return <PageInspector page={page} />;
          })()
        )}
        {obj.type == ObjectType.devProject && (
          (() => {
            const page = db.dataObjects.findById(obj.id);
            return <DevProjectInspector devProject={page} />;
          })()
        )}
        {obj.type == ObjectType.app && (() => {
          const app = db.apps.findById(obj.id);
          return (
            <>
              <ul>
                {app.navHandlers?.map?.((handler, i) => {
                  return (
                    <li key={handler._id}>
                      <div>
                        Type: <TypeEditor
                          scopeObj={{
                            type: ObjectType.app,
                            id: app._id,
                          }}
                          onChange={v => {
                            handler.type = v;
                          }}

                          value={handler.type} />
                      </div>

                      <div>
                        Page: <select
                          value={handler.page || ''}
                          onChange={e => {
                            handler.page = e.target.value;
                          }}
                        >
                          <option />
                          {db.pages2.map(page => (
                            <option value={page._id}>{page.name}</option>
                          ))}
                        </select>
                      </div>
                    </li>
                  );
                })}
              </ul>
              <button
                onClick={() => {
                  XObject.push(app, 'navHandlers', XObject.obj());
                }}
              >+</button>
            </>
          );
        })()}
        {obj.type == ObjectType.table && (() => {
          const table = db.tables.findById(obj.id);

          return (
            <>
              {table.key}
              <div>
                Schema: <select
                  value={table.schema || ''}
                  onChange={e => {
                    table.schema = e.target.value;
                  }}
                >
                  <option />
                  {db.tableSchemas.map(schema => (
                    <option value={schema._id}>{schema.name}</option>
                  ))}
                </select>
              </div>

              {table.handle && <div>
                Handle: <ObjectDisplay _onClick={() => {
                  this.props.objNav({
                    type: ObjectType.objectHandle,
                    id: table.handle,
                  });
                }} obj={{
                  type: ObjectType.objectHandle,
                  id: table.handle,
                }} />
              </div>}
            </>
          );
        })()}
        {obj.type == ObjectType.valuePoint && (() => {
          return (
            <>
              <button
                onClick={() => {
                  setAppInspect({
                    mode: InspectState.valuePoint,
                    id: obj.id,
                  })
                }}
              >Edit</button>
              
            </>
          )
        })()}

        {obj.type == ObjectType.type && (() => {
          const type = db.entityTypes.findById(obj.id);
          const pages = XObject.get(type, 'pages', []);
          return (
            <>
              <div>
                <b>Constructor</b>
                {!type.const && (
                  <NavItem
                    label="Init"
                    onClick={() => {
                      const vp = createRootValuePoint();
                      type.const = vp._id;
                      setAppInspect({
                        mode: InspectState.valuePoint,
                        id: type.const,
                      });
                    }}
                  />
                )}

                {type.const && (
                  <NavItem
                    label="Edit"
                    onClick={() => {
                      setAppInspect({
                        mode: InspectState.valuePoint,
                        id: type.const,
                      });
                    }}
                  />
                )}
              </div>
              <div>
                <b>Name Function</b>
                {!type.nameFunction && (
                  <NavItem
                    label="Init"
                    onClick={() => {
                      const vp = createRootValuePoint();
                      type.nameFunction = vp._id;
                      setAppInspect({
                        mode: InspectState.valuePoint,
                        id: type.nameFunction,
                      });
                    }}
                  />
                )}

                {type.nameFunction && (
                  <NavItem
                    label="Edit"
                    onClick={() => {
                      setAppInspect({
                        mode: InspectState.valuePoint,
                        id: type.nameFunction,
                      });
                    }}
                  />
                )}
              </div>

            <b>Pages</b>
            <ul>
              {pages.map(p => {
                return (
                  <li key={p._id}>
                    <PropertyField object={p} property="title" />

                    {!p.valuePoint && (
              <NavItem
                label="Init"
                onClick={() => {
                  const vp = createRootValuePoint();
                  p.valuePoint = vp._id;
                  setAppInspect({
                    mode: InspectState.valuePoint,
                    id: p.valuePoint,
                  });
                }}
              />
            )}

            {p.valuePoint && (
              <NavItem
                label="Edit"
                onClick={() => {
                  setAppInspect({
                    mode: InspectState.valuePoint,
                    id: p.valuePoint,
                  });
                }}
              />
            )}
                  </li>
                )
              })}
            </ul>
            <button
              onClick={() => {
                pages.push(XObject.obj({

                }))
              }}
            >+</button>
            </>
          )
        })()}
      </>
    );
  }
}
