import _ from 'lodash';
import { db } from "../../db";
import { DraftSelect, state } from "../../etc/draftHelpers";
import { showContextMenu } from "../../helpers";
import { component, styled } from "../../component";
import { X, x, XInit, XObject } from "../../XObject";
import { Comp } from "../Comp";
import { execute, getParameter, getStructureParameter, getValuePoint, isValuePointId, matchType, ReferenceType, ValuePointProps } from "../main";
import { ValueType } from "../ValueType";
import { registerType } from "../__typeRegistry";
import { registerTypeRegister } from '../typeRegistering';


@component
export class ValueInput extends Comp<{ id?; obj; params?: Param[]; onDeleteStart?; param?: {
  options?
  _id
}; }> {
  state = XInit(class {
    type;
  });

  constructor(props) {
    super(props);

    // if (_focused == this.props.id) {
    //   state.focused = `${this.props.id}.type`;
    // }
  }


  static styles = styled.span`
    display: inline-flex;
    .DraftEditor-root {
      min-width: 10px;
    }

  `;

  render() {
    if (this.props.param?.options) {
      if (this.props.obj.value) {
        return <span className="link" onClick={() => {
          this.props.obj.value = null;
        }}>{this.props.param.options.find(option => option.value == this.props.obj.value).display}</span>;
      }
      else {
        return <DraftSelect id={this.props.id} options={this.props.param.options} onSelect={value => {
          this.props.obj.value = value;
        }} />;
      }
    }
    else if (this.props.obj.type) {
      const c = (() => {
        switch (this.props.obj.type) {
          case 'resolvedTime':
            return <input type="text" onChange={e => this.props.obj.content = e.target.value} defaultValue={this.props.obj.content} />;
          case 'text':
          case 'number':
            return <input type="text" onChange={e => this.props.obj.content = e.target.value} defaultValue={this.props.obj.content} />;
          case 'state':
          case 'arg':
            return <>
              <input type="text" placeholder="Arg" onChange={e => this.props.obj.content = e.target.value} defaultValue={this.props.obj.content} />
              <input type="text" placeholder="Type" onChange={e => this.props.obj.contentType = e.target.value} defaultValue={this.props.obj.contentType} />
            </>;
          case 'entity':
            return <input type="text" placeholder="Arg" onChange={e => this.props.obj.content = e.target.value} defaultValue={this.props.obj.content} />
            // if (!this.props.obj.content) {
            //   return <EntityDraftSelector onSelect={value => this.props.obj.content = value} />;
            // }
            // else {
            //   return <EntityRef entity={this.props.obj.content} />;
            // }
          case 'statement':
            return <StatementInput id={this.props.id} obj={XObject.get(this.props.obj, 'content', {})} params={(this.props.params || []).concat('%')} />;
          // case 'routine': {
          //   return <RoutineInput id={this.props.id} obj={XObject.get(this.props.obj, 'content', {})} params={this.props.params} />;
          // }
          // case 'entityQuery':
          //   return <QueryInput id={this.props.id} obj={XObject.get(this.props.obj, 'content', [])} params={this.props.params} />;
          // case 'setParameter':
          //   return <StructureParamRef editorId={this.props.id} obj={XObject.get(this.props.obj, 'content', {})} params={this.props.params} />;
          default:
            const param = this.props.params?.find?.(p => _.isString(p) ? p == this.props.obj.type : p.value == this.props.obj.type);
            if (param)
              return _.isString(param) ? param : param.display;
            return '(error)';

          
        }
      })();


      return <>{c} <button onClick={() => {
        for (const key in x(this.props.obj)) {
          delete this.props.obj[key];
        }
      }}>X</button></>;

    }
    else {
      return (
        <>
          <DraftSelect
            onDeleteStart={this.props.onDeleteStart}
            id={`${this.props.id}.type`}
            options={[
              { display: 'Entity', value: 'entity' },
              { display: 'Time (Resolved)', value: 'resolvedTime' },
              { display: 'Time (Absolute)', value: 'time' },
              { display: 'Text', value: 'text' },
              { display: 'Number', value: 'number' },
              { display: 'Duration', value: 'duration' },
              { display: 'Routine', value: 'routine' },
              { display: 'State', value: 'state' },
              { display: 'Arg', value: 'arg' },
              { display: 'Statement', value: 'statement' },
              { display: 'Entity Query', value: 'entityQuery' },
              { display: 'Set Parameter', value: 'setParameter' },

              ...(this.props.params?.map?.(p => {
                if (_.isString(p))
                  return { value: p, display: p };
                else
                  return p;
              }) || [])
              // { display: '@', value: '@' },
            ]}
            onSelect={value => {
              // const param = this.props.params?.find?.(p => p.value == this.props.obj.type);
              this.props.obj.type = value;
            }} />
        </>
      );
    }
  }
}


export type Param = {
  value,
  display
} | string;

@component
class StatementInput extends Comp<{ id, obj, params?: Param[] }> {
  static styles = styled.span`
    display: inline-flex;
    .DraftEditor-root {
      min-width: 10px;
    }
    .public-DraftEditorPlaceholder-inner {
      white-space: nowrap !important;
    }
  `
  render() {
    if (!this.props.obj.type) {
      return <DraftSelect
      id={this.props.id}
      options={db.statementTypes.map(s => ({ display: s.handle || '', value: s._id }))}
      onSelect={value => {
        state.focused = this.props.id + '1';
        this.props.obj.type = value;
      }}
      onCreate={value => {
        console.log(value);
        const statementType = XObject.obj({ handle: value, parameters: [] })
        db.statementTypes.push(statementType);
        return statementType._id;
      }}
    />
    }
    else {
      const statementType = db.statementTypes.findById(this.props.obj.type);
      return (
        <>
          <span className="link" onContextMenu={e => {
            e.preventDefault();
            showContextMenu(e, [
              { text: 'Remove', onClick: () => {
                delete this.props.obj.type;
                delete this.props.obj.params;
              }}
            ])
          }} onClick={e => {
            // this.navigate({ type: 'statementType', statementType: statementType._id }, e);
          }}>{statementType.handle}</span>({this.props.obj.params && Object.keys(this.props.obj.params).map((param, i) => {
            const last = i == Object.keys(this.props.obj.params).length - 1;
            return <span key={param}><span onClick={() => {
              delete this.props.obj.params[param];
            }}>{db.parameters.findById(param)?.handle || '(deleted)'}</span>=
            {/* <PredicateInput params={this.props.params} id={`${this.props.id}.${param}`} obj={this.props.obj.params[param]} /> */}
            <ValueInput id={`${this.props.id}.${param}`} obj={this.props.obj.params[param]} params={this.props.params} onDeleteStart={() => {
              state.focused = this.props.id + '1';
              delete this.props.obj.params[param];
            }} />
            {!last && ','}</span>
          })}
          
          <DraftSelect
            id={this.props.id + '1'}
            onDeleteStart={() => {
              if (_.isEmpty(x(this.props.obj.params))) {
                state.focused = this.props.id;
                delete this.props.obj.type;
                delete this.props.obj.params;
              }
            }}
            options={db.parameters.filter(param => {
              return !this.props.obj.params?.[param._id];
            }).map(param => {
              return {
                value: param._id,
                display: statementType.parameters.find(p => p.type == param._id) ? `*${param.handle}` : param.handle,
              }
            })}
            onSelect={value => {
              state.focused = `${this.props.id}.${value}.type`;
              XObject.get(this.props.obj, 'params', {})[value] = X({});
            }}
            onCreate={value => {
              const param = XObject.obj({ handle: value });
              db.parameters.push(param);
              return param._id;
            }}
          />
          )
        </>
      )

    }
  }
}

@component
class StatementValuePoint extends Comp<ValuePointProps> {
  render() {
    // return '';
    const { scope } = this.props;
    const options = [];

    options.splice(options.length, 0, ...scope.filter(s => s.type == ReferenceType.Parameter && matchType(getParameter(s.id).type, [])).map((s) => ({
      display: getParameter(s.id).name,
      value: s.id,
    })));

    options.splice(options.length, 0, ...scope.filter(s => s.type == ReferenceType.StructureParameter).map((s) => ({
      display: getStructureParameter(s.id).name,
      value: s.id,
    })));

    options.splice(options.length, 0, ...scope.filter(s => s.type == ReferenceType.Value && matchType(getValuePoint(s.id).type, [])).map((s) => ({
      display: getValuePoint(s.id).name,
      value: s.id,
    })));

    return (
      <StatementInput 
        id={this.props.elId}
        obj={XObject.get(this.props.state, 'content', {})}
        params={['%', '@'].concat(options)}
      />
    );
  }
}

export const registerStatementType = () => {
  registerType(ValueType.Statement, {
    editorComp: StatementValuePoint,
    isBlock: () => false,
    execute: (value, rt) => {
      const params = {};
      for (const paramId in value.content.params) {
        const param = value.content.params[paramId];
        if (isValuePointId(param.type)) {
          params[paramId] = execute(param.type, rt);
        }
        else if (param.type in rt.paramStore) {
          params[paramId] = rt.paramStore[param.type];
          if (isValuePointId(params[paramId])) {
            params[paramId] = execute(params[paramId], rt);
            if (params[paramId].type?.[0] == ValueType.Entity) {
              params[paramId] = { type: 'entity', content: params[paramId].content}
            }
          }
        }
        else {
          params[paramId] = param;
        }
      }
      const content = {
        type: value.content.type,
        params,
        test: true,
      }
      return {
        _id: value._id,
        type: value.type,
        content,
        isState: value.isState,
        rt,
        presentation: value.presentation,

      }
    },
    evaluate: (value, rt) => {
      return value.content;
    }
  });
}

registerTypeRegister(registerStatementType);