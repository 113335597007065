import React, { Component } from 'react';
import { db } from '../db';
import { component } from '../component2';
import { XObject } from '../XObject';
import { NotionDatabase } from '../components/notionDatabase/NotionDatabase';
import { Column, ColumnManager, RowManager, Row } from "../components/notionDatabase/ColumnManager";
import { cellTypeMap } from '../components/cellTypeMap';

class ColumnThing extends Column {
  constructor(public column) {
    super();
  }

  width() {
    return this.column.width;
  }

  setWidth(value: any): void {
    this.column.width = value;
  }

  type() {
    return this.column.type;
  }

  id() {
    return this.column._id;
  }

  metaData() {
    return this.column;
  }

  title() {
    return this.column.title;
  }

  cellObj(row: any) {
    return new cellTypeMap[this.type()](this.metaData(), row);

  }

  setTitle(value: any): void {
    this.column.title = value;
  }
}
class ColManager extends ColumnManager {
  constructor(public database) {
    super();
  }

  canAddDeleteColumns = false;

  columns() {
    return this.database.columns.map(col => {
      return new ColumnThing(col);
    });
  }
}
class MyRowThing extends Row {
  constructor(public row) {
    super();
  }
  id() {
    return this.row._id;
  }

  value(colId: any, defaultValue?: any) {
    if (defaultValue !== undefined) {
      return XObject.get(this.row.data, colId, defaultValue);
    }
    else {
      return this.row.data[colId];
    }
  }

  setValue(colId: any, value: any): void {
    this.row.data[colId] = value;
  }
}
class MyRowManager extends RowManager {
  constructor(public database) {
    super();
  }
  rows(): Row[] {
    return this.database.rows.map(row => {
      return new MyRowThing(row);
    });
  }

  addRow() {
    throw new Error('unimplemented');
    return null;
  }

  deleteRow(id: any) {
  }

  canAddDeleteRows: boolean = false;
}

@component
export class DatabaseWindow extends Component<{ window; }> {
  render() {
    const database = db.databases.findById(this.props.window.database);
    return (
      <>
        <NotionDatabase
          columnManager={new ColManager(database)}
          rowManager={new MyRowManager(database)} />
      </>
    );
  }
}
