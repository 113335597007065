export function findNodeInComponent(id, builderComponent) {
  const findNode = (id, start = builderComponent.root) => {
    if (id == start._id) return start;
    if (start.children) {
      for (const child of start.children) {
        const r = findNode(id, child);
        if (r) return r;
      }
    }
  };

  let selectedNode = findNode(id);
  if (!selectedNode && builderComponent.detachedNodes) {
    for (const node of builderComponent.detachedNodes) {
      if (node._id == id) return node;
      selectedNode = findNode(id, node);
      if (selectedNode) break;
    }
  }

  if (builderComponent.providedSlots) {
    for (const pv of builderComponent.providedSlots) {
      for (const rootNode of pv.nodes) {
        const r = findNode(id, rootNode);
        if (r) return r;
      }
    }
  }

  return selectedNode;
}
