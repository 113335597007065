import { Component } from 'react';
import { component, styled } from './component';

import { XInit } from './XObject';
import React, { useRef, useEffect } from 'react';
import {  keymap } from '@codemirror/view';
import { defaultKeymap, indentWithTab } from '@codemirror/commands';
import { EditorState } from '@codemirror/state';
import {EditorView, basicSetup } from "codemirror"
import { ScriptBlock, renderEl } from './shorthand/formula';
import { executeScriptFromData } from './shorthand/executeScriptFromData';

interface Line {
  content?
  children?: Line[]
  parent?
  indentation?
}
function textToNestedStructure(text) {
  const lines = text.split('\n');
  const root: Line = { content: null, children: [] };
  let currentParent = root;
  let prevIndentation = -1;

  for (let line of lines) {
    const indentation = line.search(/\S/);  // Find the first non-space character.
    line = line.trim();
    if (!line) continue;  // Skip empty lines.

    const newItem: any = { content: line, children: [] };

    if (indentation > prevIndentation) {
      // It's a child of the previous line.
      currentParent.children.push(newItem);
    } else {
      // It's a sibling (or a sibling of an ancestor). Move up the parent chain.
      while (indentation <= prevIndentation) {
        currentParent = currentParent.parent;
        prevIndentation = currentParent.indentation;
      }
      currentParent.children.push(newItem);
    }

    // Set up for the next iteration.
    newItem.parent = currentParent;
    newItem.indentation = indentation;
    currentParent = newItem;
    prevIndentation = indentation;
  }

  return root.children;
}

function lineStructureToScriptBlocks(lines: Line[]): ScriptBlock[] {
  return lines.map(line => {
    return {
      data: line.content,
      children: lineStructureToScriptBlocks(line.children),
    }
  })
}



export const Editor = ({ defaultValue, onChange }) => {
  const editor = useRef();

  useEffect(() => {
    let sync_val;
    const startState = EditorState.create({
      doc: defaultValue,
      extensions: [
        basicSetup,
        keymap.of([...defaultKeymap, indentWithTab]),
          EditorView.updateListener.of(function(e) {
            onChange(e.state.doc.toString());
              
          })

      ],
    });

    const view = new EditorView({
      state: startState,
      parent: editor.current,
  
    });



    return () => {
      view.destroy();
    };
  }, []);

  return <div ref={editor}></div>;
};

@component
export class ShorthandWindow extends Component<{ window; }> {
  static debounce = false;
  static styles = styled.div`
    .left {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 50%;
    }

    .right {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      bottom: 0;
    }
  `;

  state = XInit(class {
    value = '';
  })


  timerId
  save(value) {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      this.props.window.code = value;
    }, 500);
  }

  constructor(props) {
    super(props);
    this.state.value = this.props.window.code;
  }

  
  render() {
    const v = this.state.value;
    const r = executeScriptFromData(lineStructureToScriptBlocks(textToNestedStructure(v || '')));



    return (
      <>
      <div className="left">
      <Editor
      defaultValue={v}
      onChange={value => {
        this.state.value = value;
        this.save(value);
      }} />
      <button
        onClick={() => {
          executeScriptFromData(lineStructureToScriptBlocks(textToNestedStructure(v || '')));
        }}
      >Run</button>


</div>

<div className="right">
  FIX ME
      {/* {renderEl(r)} */}

</div>

      </>
    );
  }
}
