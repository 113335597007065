import { component, styled } from './component';
import { Component } from 'react';
import { ColumnType } from './ColumnType';
import { registerStackSystem } from './glue/stackSystems';
import cx from 'classnames';
import { x } from './XObject';

registerStackSystem('677c3c69-1f7a-5b4b-8c9c-158ff288e809', {
  padding: 0,
  width: 'auto',
  height: '100%',
  render: args => {
    return <ThirdColumn state={args} />
  }
})


const thirdColumnRegistry = {};

export function registerThirdColumnThing(id, asdf) {
  thirdColumnRegistry[id] = asdf;
}

@component
export class ThirdColumn extends Component<{ state; }> {
  static styles = styled.div`
    height: 100%;
  `;
  render(Container?) {
    return (
      <>
        <Container className={cx('pane', this.props.state.type)}>

          {(() => {
            return thirdColumnRegistry[this.props.state.type](this.props.state);
          })()}

        </Container>

      </>
    );
  }
}
