import { Component } from 'react';
import { component } from './component';
import { ScriptEditor } from './MyNotionDocument/ScriptEditor';
import { XObject } from './XObject';
import { ScriptWrapper, createScriptWrapper } from './MyNotionDocument/ScriptWrapper';

@component
export class ScriptWindow extends Component<{ window }> { 
  scriptWrapper: ScriptWrapper
  constructor(props) {
    super(props);
    this.scriptWrapper = createScriptWrapper({
      baseEntity: null,
      extendEntity: null,
      blocks: () => {
        return XObject.get(this.props.window, 'blocks', []);
      },
      setBlocks: b => {
        this.props.window.blocks = b
      }
    })
  }
  render() {
    console.log('renderWindow');
    return (
      <>
        <ScriptEditor
          client={this.props.window._id}
          scriptWrapper={this.scriptWrapper}
          debugPane={true}
          state={XObject.get(this.props.window, 'state', {})}
        />
      </>
    );
  }
}
