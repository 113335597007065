import React from 'react';
import {component} from '../../component2';
import {db} from '../../db';
import {Comp} from '../Comp';
import {ValuePointProps} from '../main';
import {registerType} from "../__typeRegistry";
import {ValueType} from "../ValueType";
import { registerTypeRegister } from '../typeRegistering';

@component
export class EntityElement extends Comp<ValuePointProps> {
  render() {
    return (
      <>
        <select
          value={this.props.state.content || ''}
          onChange={(e) => {
            this.props.state.content = e.target.value || null;
          }}
        >
          <option value="">Any</option>
          {db.elements.map((x, i) => <option key={i} value={x._id}>{x.name}</option>)}
        </select>
      </>
    );
  }
}

export const registerEntityElementType = () => {
  registerType(ValueType.EntityElement, {
    evaluate: (value, map) => {
      return value.content;
    },
    editorComp: EntityElement,
  });
};


registerTypeRegister(registerEntityElementType)