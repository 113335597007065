import { concatData, dataLength, sliceData } from '../richTextHelpers';


export function substitute(args, d) {
  const ctx = args.ctx;
  const setValue = args.setValue;
  // const e = obj.data;
  const data = args.value();
  const position = args.menuPos;
  const length = dataLength(ctx, data);
  const firstPart = sliceData(ctx, data, 0, position);
  const secondPart = sliceData(ctx, data, position, length);
  setValue(concatData(ctx, concatData(ctx, firstPart, [d]), secondPart));

}
