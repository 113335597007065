import React, { Component } from "react";
import { component, styled } from "../component";
import { X, XInit, XObject, x } from "../XObject";
import { SortHandle, SortableCont, SortableEl, showContextMenu } from "../helpers";
import { isMobile } from "../isMobile";
import classNames from "classnames";

class BlockManager {
  constructor(
    private insertionBlocks: {
      get(): {
        _id
        after
      }[]
      set(v)
    },
    private page: {

      blocks?: {
        _id
      }[]
    },
    private blockMatcher
  ) {

  }

  addPersonalBlock(index?) {
    this.insertBlock({
      block: XObject.obj(),
      personal: true,
    }, index ?? this.blocks().length);
  }

  resetAfter() {
    const typeBlocks = this.matchedBlocks();
    const entityPageBlocks = this.insertionBlocks.get();
    for (let i = 0; i < entityPageBlocks.length; i++) {
      const personalBlock = entityPageBlocks[i];
      if (i == 0) {
        personalBlock.after = typeBlocks[typeBlocks.length - 1]._id;
      }
      else {
        personalBlock.after = entityPageBlocks[i - 1]._id;
      }
    }
  }

  matchedBlocks():{
    _id
  }[] {
    return this.page.blocks?.filter?.(b => {
      return this.blockMatcher(b);
    }) || [];
  }

  blocks(): {
    personal
    block
  }[] {
    const entityPageBlocks = this.insertionBlocks.get();

    const typeBlocks = this.matchedBlocks();

    const blocks = [];

    for (const block of typeBlocks) {
      blocks.push(block);
    }

    const toAdd = [...entityPageBlocks]
    loop: while (toAdd.length > 0) {
      for (let i = 0; i < toAdd.length; i++) {
        const block = toAdd[i];
        if (block.after) {
          const after = blocks.find(b => b._id == block.after);
          if (after) {
            const index = blocks.indexOf(after);
            blocks.splice(index + 1, 0, block);
            toAdd.splice(i, 1);  
            continue loop;
          }

        }
        else {
          blocks.unshift(block);
          toAdd.splice(i, 1);
          continue loop;
        }
      }
    }


    const b = blocks.map(b => ({
      personal: !typeBlocks.find(tb => tb._id == b._id),
      block: b,
    }))

    return b;
  }

  insertBlock(block: {personal, block }, index) {
    const blocks = this.blocks();
    const afterChanges = {};
    const newPageBlocks = x(this.page.blocks);

    const entityPageBlocks = x(this.insertionBlocks.get());


    if (block.personal) {
      entityPageBlocks.push(block.block);
    }
    else {
      // newPageBlocks.push(block.block);
    }


    if (block.personal) {
      afterChanges[block.block._id] = blocks[index - 1]?.block?._id;

    }
    else {
      let lastBlock;
      for (let i = index - 1; i >= 0; i--) {
        if (!blocks[i].personal) {
          lastBlock = blocks[i];
          break;
        }
      }

      if (lastBlock) {
        const lastBlockIndex = newPageBlocks.findIndex(b => b._id == lastBlock.block._id);
        newPageBlocks.splice(lastBlockIndex + 1, 0, block.block);
      }
      else {
        newPageBlocks.unshift(block.block);
      }
    }

    const currentBlock = blocks[index];

    if (currentBlock) {
      if (currentBlock.personal) {
        afterChanges[currentBlock.block._id] = block.block._id;
      }
    }


    this.page.blocks = X(newPageBlocks);

    for (const id in afterChanges) {
      const block = entityPageBlocks.find(b => b._id == id);
      if (block) {
        block.after = afterChanges[id];
      }
    }

    this.insertionBlocks.set(X(entityPageBlocks));

  }

  delete(index) {
    const blocks = this.blocks();
    const afterChanges = {};
    const block = blocks[index];
    const newPageBlocks = x(this.page.blocks);

    const nextBlock = blocks[index + 1];
    if (nextBlock?.personal) {
      afterChanges[nextBlock.block._id] = blocks[index - 1]?.block?._id;
    }

    if (block.personal) {
      this.insertionBlocks.get().splice(this.insertionBlocks.get().findIndex(b => b._id == block.block._id), 1);
    }
    else {
      newPageBlocks.splice(newPageBlocks.findIndex(b => b._id == block.block._id), 1);
    }
    
    blocks.splice(index, 1);

    this.page.blocks = X(newPageBlocks);

    const entityPageBlocks = x(this.insertionBlocks.get());
    for (const id in afterChanges) {
      const block = entityPageBlocks.find(b => b._id == id);
      if (block) {
        block.after = afterChanges[id];
      }
    }

    this.insertionBlocks.set(X(entityPageBlocks));


  }

  moveBlock(fromIndex, toIndex) {
    if (fromIndex == toIndex) return;
    const blocks = this.blocks();

    const blockIds = {};

    const afterChanges = {};
    const newPageBlocks = x(this.page.blocks);
    for (const block of newPageBlocks) {
      blockIds[block._id] = true;
    }

    const removeBlock = index => {
      const block = blocks[index];
      const nextBlock = blocks[index + 1];
      if (nextBlock?.personal) {
        afterChanges[nextBlock.block._id] = blocks[index - 1]?.block?._id;
      }

      if (block.personal) {
        afterChanges[block.block._id] = false;
      }
      else {
        newPageBlocks.splice(newPageBlocks.findIndex(b => b._id == block.block._id), 1);
      }
      
      blocks.splice(index, 1);
    }
    const insertBlock = (index, block: { personal, block }) => {
      if (block.personal) {
        console.log(afterChanges[block.block._id] = blocks[index - 1]?.block?._id);

      }
      else {
        let lastBlock;
        for (let i = index - 1; i >= 0; i--) {
          if (!blocks[i].personal) {
            lastBlock = blocks[i];
            break;
          }
        }

        if (lastBlock) {
          const lastBlockIndex = newPageBlocks.findIndex(b => b._id == lastBlock.block._id);
          console.log(lastBlockIndex)
          newPageBlocks.splice(lastBlockIndex + 1, 0, block.block);
          console.log(1);
        }
        else {
          newPageBlocks.unshift(block.block);
          console.log(2);
        }
      }

      const currentBlock = blocks[index];

      if (currentBlock) {
        if (currentBlock.personal) {
          afterChanges[currentBlock.block._id] = block.block._id;
        }
      }

    }

    const block = blocks[fromIndex];
    removeBlock(fromIndex);

    insertBlock(toIndex, block);

    for (const id in blockIds) {
      if (!newPageBlocks.find(b => b._id == id)) {
        console.log('missing a block', id, x(block));
        return
      }
    }

    this.page.blocks = X(newPageBlocks);

    const entityPageBlocks = x(this.insertionBlocks.get());
    for (const id in afterChanges) {
      const block = entityPageBlocks.find(b => b._id == id);
      if (block) {
        block.after = afterChanges[id];
      }
    }
    this.insertionBlocks.set(X(entityPageBlocks));
  }
}

@component
export class Blocks extends Component<{
  page: {
    blocks?: {
      _id
    }[]
  }
  contextMenu
  insertionBlocks: {
    get(): {
      _id
      after
    }[]
    set(v)
  },
  renderBlock,
  blockMatcher,
  }> {
  state = XInit(class {
    reordering = false
  });

  static styles = styled.div`
    .line {
      height: 4px;
      margin: 8px 0;
      cursor: pointer;
      position: relative;

      &:hover:before {
        background: #ccc;
      }


      &:before {
        content: '';
        position: absolute;
        background: #f7f7f7;
        top: 0;
        /* width: 70%; */
        right: 0;
        left: 0;
        /* left: 100px; */
        /* right: 100px; */
        height: 2px;
        /* width: 100%; */
        margin: auto;
        bottom: 0;
      }
    }

    /* .actions {
      position: absolute;
      top: 0;
      right: 0;
      height: 28px;
      svg {
        width: 15px;
        height: 15px;
      }
      z-index: 999999;
    } */

    .block__ {
      /* padding-top: 4px; */


      .actionBar {
        content: '';
        position: absolute;
        left: -11px;
        top: 0;
        bottom: 0;
        /* height: 30px; */
        margin: auto;
        width: 3px;
        background: #eee;

        cursor: pointer;

        &:hover {
          background: #ccc;
        }
      }

      position: relative;

      &.personal {
        /* .actions {
          svg {
            fill: #5684ee;;
          }
        } */

        .actionBar {
          /* background: #5684ee4d; */
          background: #569eee4d;

          &:hover {
            background: #5684ee;
          }
        }
      }
    }
  `;

  static t = {
    init: styled.div`
      span {
        padding: 3px 2px;
        margin-top: 16px;
        margin-bottom: 4px;
        letter-spacing: 0px;
        font-size: 13px;
        color: rgba(55, 53, 47, 0.5);
        display: flex;
        align-items: center;
        font-weight: 500;
      }
    
      button {
        display: block;
        width: 100%;

        appearance: none;
        border: none;
        background: transparent;

        height: 32px;

        &:hover {
          background: #f3f3f3;
          color: #4a4a4a;
        }

        border-radius: 3px;
        font: inherit;

        text-align: left;
        cursor: pointer;

        color: inherit;

        color: #969696;
      }
    `,
  }

  render() {
    const { t } = Blocks;

    const renderBlock: (block)=>[any, any] = (block => {
      return this.props.renderBlock(block);
    }) as any;

    const blockManager = new BlockManager(this.props.insertionBlocks, this.props.page, this.props.blockMatcher);
    const blocks = blockManager.blocks();

    return (
      <>
        <SortableCont
          className="blocks"
          distance={isMobile() ? undefined : this.state.reordering ? 10 : 10}
          pressDelay={!isMobile() ? undefined : 100}
          style={this.state.reordering ? {
            'user-select': 'none',
          } : {}}
          onSortEnd={({ oldIndex, newIndex }) => {
            blockManager.moveBlock(oldIndex, newIndex);
          }}
          useDragHandle
        >
          {blocks.map?.(({ block, personal }, i) => {
            const id = block._id;
            if (!block) {
              return '';
            }
            const [ c, contextMenu ] = renderBlock(block)
            return (
              <SortableEl index={i} key={block._id}>
                <div key={id} data-id={id}>
                  <div className="line" onClick={() => blockManager.addPersonalBlock(i)} />
                  <div className={classNames('block__', { personal })}>
                    <SortHandle>
                      <div
                        className="actionBar"
                        onClick={e => {
                          showContextMenu(e, [
                            {
                              text: 'Delete',
                              onClick: () => {
                                blockManager.delete(i);
                              }
                            },

                            ...(this.props.contextMenu?.({
                              block,
                              i,
                              blockManager,
                            }) || []),

                            ...((contextMenu || []))
                          ].filter(Boolean));
                        }}
                      />
                    </SortHandle>
                    <div
                      style={this.state.reordering ? {
                        maxHeight: '100px',
                        overflow: 'hidden',
                      } :{}}
                    >
                      {c}
                    </div>
                  </div>
                </div>
              </SortableEl>
            );
          })}
        </SortableCont>
        <div className="line"
          onClick={() => {
            blockManager.addPersonalBlock();
          }}
        />
      </>
    );

  }
}
