import React, { Component } from 'react';
import { PropertyField } from '../../components/PropertyField';
import { db } from '../../db';
import { ValueType } from '../ValueType';
import { structRenderers } from '../structRenderers';
import { typeRegistry } from '../typeRegistry.1';
import { doEntityQuery } from "../doEntityQuery";
import { component, styled } from '../../component';
import { evaluate, mapStructure, render } from '../main';
import { SystemContext, SystemContextProps } from '../../etc/SystemContext';
import { codaIcons } from '../../codaIcons';
import { x } from '../../XObject';

const $Navigate = typeRegistry.registerType({
  $: 'd3a37ca9-a2f4-5ab5-b7c1-74df3c6d5863',
  Page: '23862a82-ffcf-5c71-8f07-9205a011b93d',
  Entity: 'd94b3ae5-3953-51be-906e-00a094e87dfc',
}, ids => ({
  _id: ids.$,
  name: 'Navigate',
  definition: [
    {
      id: ids.Page,
      name: 'Page',
      type: [ValueType.String],
      property: 'page',
    },
    {
      id: ids.Entity,
      name: 'Entity',
      type: [ValueType.String],
      property: 'entity',
    },
  ],
}));


const $BasecampNavCard = typeRegistry.registerType({
  $: '70a096e9-ea45-5052-9afe-0b3b620bfb2b',
}, ids => ({
  _id: ids.$,
  name: 'Basecamp Nav Card',
  definition: [
  ]
}));

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */

  align-items: flex-start;
  margin: 0 -8px;
  .card {
    cursor: pointer;
    flex: 0 0 auto;
    width: 189px;
    /* border: 1px solid black; */
    border-radius: 8px;
    margin: 8px;
    /* text-align: center; */
    padding: 20px;


    border: 1px solid rgb(224, 224, 224);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

    &:hover {
      box-shadow: 0 2px 10px -1px rgba(0,0,0,.12);
      border-color: rgb(198, 198, 198);
    }

    transition: all 200ms linear;

    position: relative;
    padding-left: 45px;

    > .icon {
          position: absolute;
          width: 26px;
          height: 26px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          left: 12px;
          top: 20px;
        }


    .title {
      display: block;
      font-size: 18px;
      font-weight: 600;
      /* border-bottom: 1px solid black; */
    }

    .description {
      color: rgb(142, 142, 142);
      display: block;
    }
  }
`;

structRenderers[$BasecampNavCard.$] = (value, map, state) => {
  return (
    <Wrapper>
      Hello
    </Wrapper>
  );
};

const $BasecampNav = typeRegistry.registerType({
  $: 'a8d0d41c-9ff0-5b87-b6a5-8ba5554f956b',
  Cards: {
    $: '4c6d4157-de2b-5b97-8e5a-e18d9ae00478',
    $$: '57b8c3e2-ea50-5d47-94ee-4b30e996ee66',
    Title: '79d9b39b-b4d0-5951-a07a-0d032993836d',
    Icon: '4b683192-1bd2-5ebe-97d5-a3651002fece',
    Description: '244e82b8-f3ea-5401-8903-b474c4cc28a3',
    Action: 'e0d63fd0-eb0f-5459-925e-0ba7797b9fb7',
  }
}, ids => ({
  _id: ids.$,
  name: 'Basecamp Nav',
  definition: [
    {
      id: ids.Cards.$,
      name: 'Cards',
      type: [ValueType.Array, [ValueType.Structure, ids.Cards.$$, [
        {
          id: ids.Cards.Title,
          name: 'Title',
          type: [ValueType.String],
          property: 'title',
        },
        {
          id: ids.Cards.Description,
          name: 'Description',
          type: [ValueType.String],
          property: 'description',
        },
        {
          id: ids.Cards.Action,
          name: 'Action',
          type: [],
          property: 'action',
        },
        {
          id: ids.Cards.Icon,
          name: 'Icon',
          type: [],
          property: 'icon',
        },
      ]]],
      property: 'cards',
    }
  ]
}));


@component
class BascampNav extends Component<{ value, map }> {
  static contextType = SystemContext;
  context: SystemContextProps;
  render() {
    const { value, map } = this.props;
    const mapped = mapStructure(value);
    return (
      <Wrapper data-value-point={value._id}>
        {mapped.cards?.content?.map?.(card => {
          const mapped = mapStructure(card);
          return (
            <div className="card" key={card._id}
            data-value-point={card._id}
              onClick={() => {
                const action = mapStructure(mapped.action);
                // console.log('poop', action);


                // const pageValue = evaluate(action.page, this.props.map);
                let page;
                if (action.page?.type?.[0] == ValueType.String) {
                  page = action.page.content;
                }
                if (action.page?.type?.[0] == ValueType.ObjectRef) {
                  page = action.page.content.ref.id;
                }

                console.log('asdfasdfa', page);

                // console.log(x(action.page))

                if (!page) {
                  throw new Error('invalid page')
                }

                this.context.navigate({
                  type: 'entity',
                  id: evaluate(action.entity, this.props.map),
                  page,
                })
  
              }}
            >
                          {mapped.icon?.content && <span className="icon"
                            style={{
                              backgroundImage: `url(${codaIcons.find(i => i.name == mapped.icon?.content).url('color')})`
                            }}
            
            ></span>}
              <span className="title">{mapped.title && render(mapped.title, map)}</span>
              <span className="description">{mapped.description && render(mapped.description, map)}</span>
            </div>
          )
        })}
      </Wrapper>
    );  
  }
}


structRenderers[$BasecampNav.$] = (value, map, state) => {
  return <BascampNav map={map} value={value} />;
  const mapped = mapStructure(value);
  return (
    <Wrapper data-value-point={value._id}>
      {mapped.cards?.content?.map?.(card => {
        const mapped = mapStructure(card);
        return (
          <div className="card" key={card._id}
          data-value-point={card._id}
            onClick={() => {
              const action = mapStructure(mapped.action);
              console.log('poop', action);

            }}
          >
            <span className="title">{mapped.title && render(mapped.title, map)}</span>
            <span className="description">{mapped.description && render(mapped.description, map)}</span>
          </div>
        )
      })}
    </Wrapper>
  );
};
