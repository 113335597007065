import React, { Component } from 'react';
import { db } from '../db';
import { component } from '../component2';
import { showContextMenu } from '../helpers';
import { Svg } from './Svg';
import { SystemContext, SystemContextProps } from '../etc/SystemContext';
import { ObjectPageFrame } from './ObjectPageFrame';
import { PaneType } from '../types/PaneType';
import { EventsList, EventOccurrence } from './EventsRoot';
import { AttributeField } from './AttributeField';
import { AttributeType } from './AttributeType';
import { Cell } from './Cell';
import { DurationCellType, EntityCellType, EventCellType, TimeCellType } from './cells';
import { X, XInit, XObject, x } from '../XObject';
import { InsertionCont } from './InsertionCont';
import { AttributeAdder } from './AttributeAdder';
import { attributesInScope } from './objectFuncs';
import { ObjectRefClass, ObjectType } from '../types/ObjectRef';
import { renderCellForAttribute } from '../glue/structs/cellForAttribute';
import { memoryAppState, setAppInspect } from '../etc/appState';
import { Blocks } from './BlockSystem';
import { objectResource } from './objectResource';
import { NotionTable2 } from './NotionTable2';
import { triggerInspectObject } from '../osHelpers';
import { explicitInspectObj } from '../inspectObj';
import { GlueView, createRootValuePoint } from '../glue/main';
import { InspectState } from './InspectState';
import { getOccurrenceDescendents } from './getOccurrenceDescendents';

function getSystemPage(systemBlocks) {
  let systemPage = db.pages.find(p => p.key == '2e09aa55-ae9b-5b11-ad9c-a7f531460144');
  if (!systemPage) {
    systemPage = XObject.obj({
      key: '2e09aa55-ae9b-5b11-ad9c-a7f531460144',
      blocks: systemBlocks.map(b => XObject.obj(b)),
    });
    db.pages.push(systemPage);
  }
  return systemPage;
}

function renderNestedOccurrences(occurrences) {
  return (
    <>
      {occurrences.map(o => (
        <div key={o._id}>
          <EventOccurrence id={o._id} />
        </div>
      ))}
    </>
  )
}

enum BlockType {
  table = 'ac1e9fb9-3980-581c-a679-ed534ff85430',
  glue = '2d514502-8ac9-59e5-a713-e58a2b980c78',
}

function getAll(occurrences) {
  const all = [];
  for (const occurrence of occurrences) {
    all.push(occurrence);
    all.push(...getOccurrenceDescendents(occurrence));
  }

  return all;
}

@component
export class EventOccurrencePage extends Component<{ id; }> {
  context: SystemContextProps;
  static contextType = SystemContext;
  state = XInit(class {
    addingAttribute
  })
  render() {
    const occurrence = db.eventOccurrences.findById(this.props.id);
    const event = db.events.findById(occurrence.event);

    const childEvents = event.childTypes?.map?.(id => db.events.findById(id)) || [];

    const childOccurrences = db.eventOccurrences.filter(eo => eo.parent == occurrence._id);

    let attrs = [];

    const systemPage = getSystemPage([]);


    attrs = attributesInScope({
      type: ObjectType.event,
      id: event._id,
    })

    const attributes = XObject.get(occurrence, 'attributes', {});

    const duration = occurrence.start && !occurrence.timestamp && (occurrence.stop ? occurrence.stop.getTime() - occurrence.start.getTime() : Date.now() - occurrence.start.getTime())/1000;

    return (
      <>
        <ObjectPageFrame
          icon={null}
          obj={null}
          path={event.name}
          pathId={null}
          right={(
            <>
              <span className="more"
                onClick={e => {
                  showContextMenu(e, [
                    {
                      text: 'Event',
                      onClick: () => {
                        this.context.navigate({
                          type: PaneType.event,
                          id: occurrence.event,
                        });
                      },
                    },
                    {
                      text: 'Copy',
                      onClick: () => {
                        memoryAppState.copiedEventOccurrence = occurrence._id;
                      },
                    },
                    {
                      text: 'Paste Parent',
                      onClick: () => {
                        occurrence.parent = memoryAppState.copiedEventOccurrence;
                      },
                    },
                    !occurrence.start && {
                      text: 'Turn into timer',
                      onClick: () => {
                        occurrence.start = occurrence.timestamp;
                        delete occurrence.timestamp;
                      },
                    },
                    {
                      text: 'Delete',
                      onClick: () => {
                        const index = db.eventOccurrences.indexOf(occurrence);
                        db.eventOccurrences.splice(index, 1);
                      },
                    },
                  ], true);
                }}
              >
                <Svg name="dots" />
              </span>
            </>
          )}
        >
          {/* <EventOccurrence id={this.props.id} /> */}
          {occurrence.parent && <EventOccurrence id={occurrence.parent} />}
          <div>
            <AttributeField
              title={'Event'}
              type={AttributeType.event}
              field={<Cell
                cell={new EventCellType({
                })}
                get={() => occurrence.event}
                set={v => occurrence.event = v}
                title="Event" />
              }
            />

            {occurrence.timestamp && <AttributeField
              title={'When'}
              type={AttributeType.datetime}
              field={<Cell
                cell={new TimeCellType({})}
                get={() => occurrence.timestamp}
                set={v => { if (v) occurrence.timestamp = v } }
                title="Entity"
              />}
            />}
            {!occurrence.timestamp &&
              <>
              <AttributeField
                title={'Start'}
                type={AttributeType.datetime}
                field={<Cell
                  cell={new TimeCellType({})}
                  get={() => occurrence.start}
                  set={v => { if (v) occurrence.start = v } }
                  title="Stop"
                />}
              />
              <AttributeField
                title={'Stop'}
                type={AttributeType.datetime}
                field={<Cell
                  cell={new TimeCellType({})}
                  get={() => occurrence.stop}
                  set={v => occurrence.stop = v }
                  title="Stop"
                />}
              />
              <AttributeField
                title={'Duration'}
                type={AttributeType.duration}
                field={<Cell
                  cell={new DurationCellType({})}
                  get={() => duration}
                  set={v => { } }
                  title="Stop"
                />}
              />
              </>
            }
            <AttributeField
              title={event.entityLabel || 'Entity'}
              type={AttributeType.entity}
              field={<Cell
                cell={new EntityCellType({
                  query: event.entityQuery,
                })}
                get={() => occurrence.arguments}
                set={v => occurrence.arguments = v}
                title="Entity" />
              }
            />
          </div>
          <InsertionCont
            onInsert={() => this.state.addingAttribute = true}
            orientation="vertical"
            tag="div"
            mobileAdd
          >
            {event.attributes?.map?.(a => {
              const attrType = db.attributeTypes.findById(a);
              return (
                <AttributeField
                  title={attrType.name}
                  field={renderCellForAttribute({
                    attribute: attrType,
                    get: () => attributes[a],
                    set: v => attributes[a] = v,
                    title: attrType.name,
                  })}
                  type={attrType.type}
                />
              );
            })}
          </InsertionCont>
          {this.state.addingAttribute && (
            <AttributeAdder
              attrs={attrs}
              close={() => {}}
              onAdd={a => {
                this.state.addingAttribute = false;
                XObject.push(event, 'attributes', a);
              }}
              scope={{
                type: ObjectType.event,
                id: event._id,
              }}
            />
          )}
          <Blocks
            blockMatcher={b => {
              if (b.match) {
                if (b.match[0] == 'f6c2c157-201b-5063-a786-ebb4fd3ca504') return true;
                if (b.match[0] == '2344e714-fe21-5df6-8423-ae3adf33083f' && b.match[1] == occurrence.event) {
                  return true;
                }
              }
              return false;
            }}
            contextMenu={({ block, blockManager, i }) => {
              if (!block.match) {
                return [
                  {
                    text: 'Type match',
                    onClick: () => {
                      const b = x(block);
                      blockManager.delete(i);
                      setTimeout(() => {
                        b.match = ['2344e714-fe21-5df6-8423-ae3adf33083f', occurrence.event];
                        blockManager.insertBlock({
                          block: b,
                          personal: false,
                        }, i);
                      }, 1000);
                    },
                  },
                  {
                    text: 'Global',
                    onClick: () => {
                      const b = x(block);
                      blockManager.delete(i);
                      setTimeout(() => {
                        b.match = ['f6c2c157-201b-5063-a786-ebb4fd3ca504'];
                        blockManager.insertBlock({
                          block: b,
                          personal: false,
                        }, i);
                      }, 1000);
                    },

                  }
                ]
              }
              return [];
            }}
            insertionBlocks={{
              get: () => {
                return XObject.get(occurrence, 'blocks', []);
              },
              set: (v) => {
                occurrence.blocks = X(v);
              },
            }}
            page={systemPage}
            renderBlock={block => {
              if (!block.type) {
                return (
                  [<Blocks.t.init>
                    <span>Block type:</span>
                    <button
                      onClick={() => {
                        const handle = XObject.obj({
                          type: ObjectType.table,
                          parent: {
                            type: ObjectType.eventOccurrence,
                            id: occurrence._id,
                          }
                        });
                        db.objectHandles.push(handle);

                        block.handle = handle._id;
                        block.type = BlockType.table;
                      }}
                    >Table</button>

                    <button
                      onClick={() => {
                        const vp = createRootValuePoint();
                        block.valuePoint = vp._id;
                        block.type = BlockType.glue;
                      }}
                    >Glue</button>
                  </Blocks.t.init>]
                )
              }

              if (block.type == BlockType.table) {
                const table = objectResource({ id: occurrence._id, type: ObjectType.eventOccurrence }, block.handle);

                if (table) {
                  return [
                    <NotionTable2
                      active={null}
                      state={this.state}
                      table={table}
                      editView={id => {
                        triggerInspectObject({
                          type: 'f803b269-1725-57ba-a8c6-dac2fa140e06',
                          args: {
                            table: table._id,
                            view: id,
                          },
                        });
                      }}
                    />, [
                      {
                        text: 'Edit object handle',
                        onClick: () => {
                          explicitInspectObj({
                            type: ObjectType.objectHandle,
                            id: block.handle,
                          })
                        },
                      }
                    ]
                  ]
                }
                return (
                  [<>
                    Test
                  </>]
                )
              }

              if (block.type == BlockType.glue) {
                return [
                  <GlueView
                    id={block.valuePoint}
                    args={{
                      __env: {
                        $this: new ObjectRefClass(ObjectType.eventOccurrence, occurrence._id),
                      }
                    }}
                    state={{}}
                  />,
                  [
                    {
                      text: 'Edit value point',
                      onClick: () => {
                        setAppInspect({
                          mode: InspectState.valuePoint,
                          id: block.valuePoint,
                        });
        
                      },
                    }
                  ]
                ]
              }

              return [];
            }}
          />
          <EventsList
            events={childEvents}
            extraProps={{
              parent: occurrence._id,
            }}
          />
          <div>
            {renderNestedOccurrences(getAll(childOccurrences))}
          </div>
        </ObjectPageFrame>
      </>
    );
  }
}
