import { XObject } from "./XObject";
import { db } from "./db";
import { _getStorageState } from "./etc/getStorageState";
import { ObjectType } from "./types/ObjectRef";

export interface DevProjectType {
  components?: CompType[];
  assets?: {
    _id
    name
    file
  }[]
  styles: any[]
  colors?: any[]
  deletedAt?
  db?
  builderComponentTypes: any[]
  editorState?: {

    activeInstance?
    url

    debuggingEnabled

    inspectTab

    mode

    // what's the diff?
    activeBlockPath
    activePath

    activeCanvas

    additionalInspectorTabs
    
    // right sidebar
    tab
    activeComponent
    componentEditorState: {
      selectedNode
      selectedStyleNode
      selectedStyleProp
    }



    editorTabs
    currentEditorTab


    componentState: {
      id
      rootNode
      frame
      nodeState?: {
        id
        block?
        styleNodeState?: {
          id?
          prop?
        }
      }
    }

    styleState: {
      id
    }

    // top bar
    hideSelectOverlay
    selectMode
    previewMode


    activeWorkspace
  }
  name?
  screenshot?
  rootComponent?
  wrapperComponent?

  workspaceStructure?

  library: {
    rootBlocks: any[]
    pages
    componentPages: {
      [key: string]: any
    }
  }

  _id?
}

export function getDevProjectEditorState(devProject: DevProjectType): DevProjectType['editorState']  {
  return XObject.get(devProject, 'editorState', {});
}

export function getEditorState(devProject, id) {
  const editorState = getDevProjectEditorState(devProject);
  const tabs = XObject.get(editorState, 'editorTabs', []);
  const tab = tabs.find(t => t._id == id);
  return XObject.get(tab, 'state', {});
}

export function setEditorState(devProject, id, state) {
  const editorState = getDevProjectEditorState(devProject);
  const tabs = XObject.get(editorState, 'editorTabs', []);
  const tab = tabs.find(t => t._id == id);
  tab.state = state;
}


export function getActiveEditorState(devProject) {
  const editorState = getDevProjectEditorState(devProject);
  if (!editorState.currentEditorTab) {
    const newTab = XObject.obj();
    const tabs = XObject.get(editorState, 'editorTabs', []);
    tabs.push(newTab);
    editorState.currentEditorTab = newTab._id;
  }
  else {
    const tabs = XObject.get(editorState, 'editorTabs', []);
    const tab = tabs.find(t => t._id == editorState.currentEditorTab);
    if (!tab) {
      const tabs = XObject.get(editorState, 'editorTabs', []);

      if (tabs.length) {
        editorState.currentEditorTab = tabs[tabs.length - 1]._id;
      }
      else {
        const newTab = XObject.obj();
        tabs.push(newTab);
        editorState.currentEditorTab = newTab._id;
      }

  
    }
  }


  return getEditorState(devProject, editorState.currentEditorTab);
}

export function setActiveEditorState(devProject, state) {
  const editorState = getDevProjectEditorState(devProject);
  if (!editorState.currentEditorTab) {
    const newTab = XObject.obj();
    const tabs = XObject.get(editorState, 'editorTabs', []);
    tabs.push(newTab);
    editorState.currentEditorTab = newTab._id;
  }
  setEditorState(devProject, editorState.currentEditorTab, state);
}

// export function getActiveEditorState(devProject) {
//   const editorState = getDevProjectEditorState(devProject);
//   return editorState.componentState;
// }

// export function setActiveEditorState(devProject, state) {
//   const editorState = getDevProjectEditorState(devProject);
  
//   editorState.componentState = state;
// }



export function getComponentEditorState(component: CompType): CompType['editorState'] {
  return XObject.get(component, 'editorState', {});
}

export interface CompType {
  _id?;
  lastUpdated?;
  type?;
  name?;

  slots?;
  baseComponent?;

  template?;
  
  imported?
  // builder
  root?;
  initialState?;
  setup?;

  // script
  blocks?;

  parent?;

  excludeFromLibrary?

  providedSlots?: {
    slot
    nodes
  }[]


  builderComponentType?

  detachedNodes?

  editorState?: {
    selectedNode

    scale
    x
    y
  }
}


const storage = _getStorageState('devProjects', 1, []);

export function init() {

}

export function getDevProject(id): DevProjectType {
  return getDevProjects().find(x => x._id == id)
  // return db.dataObjects.findById(id);
}

export function getDevProjects(): DevProjectType[] {
  return storage;
  return db.dataObjects.filter(obj => obj.type == ObjectType.devProject);
}

export function addDevProject(obj) {
  storage.push(obj);
}

export function createDevProject() {
  const comp = XObject.obj({
    type: 'builderComponent',
    name: 'main',
    root: XObject.obj({
      type: 'el',
    }),
  });
  
  addDevProject(XObject.obj({
    type: ObjectType.devProject,
    components: [comp],
    rootComponent: comp._id,
    assets: [],
    name: 'Untitled',
    editorState: {
      activeComponent: comp._id,
      tab: 'components',
      previewMode: true,
      url: '/'
    },
    db: {},

  }))
}