import { X, x, XInit, XObject } from '../XObject';

export function getStorageState<T>(key, version, defaultValue: (new () => T)): T {
  const stored = localStorage.getItem(key);
  let obj;
  if (stored) {
    const [currentVersion, o] = JSON.parse(stored);
    obj = X(o);
  }
  else {
    obj = XInit(defaultValue);
  }

  XObject.observe(obj, () => {
    localStorage.setItem(key, JSON.stringify([version, x(obj)]));
  });

  return obj;
}

export function _getStorageState<T>(key, version, defaultValue: T): T {
  const stored = localStorage.getItem(key);
  let obj;
  if (stored) {
    const [currentVersion, o] = JSON.parse(stored);
    obj = X(o);
  }
  else {
    obj = X(defaultValue);
  }

  let timerId;
  XObject.observe(obj, () => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      localStorage.setItem(key, JSON.stringify([version, x(obj)]));
    }, 100);
  });

  return obj;
}
