import { all } from 'axios';
import { db } from '../db';
import { ObjectType } from '../types/ObjectRef';
import { getObjectParent, getScopeTree, objectName } from './objectFuncs';
import { values } from '../glue/main';

export function allObjects(switches: {
  exclude?: any[]
  includeGlobal?: boolean;
} = {}) {
  const objects = [{
    type: ObjectType.global,
    id: undefined,
  }]
    .concat(db.attributeTypes?.map?.((attr) => ({
      type: ObjectType.attribute,
      id: attr._id,
    })) || [])
    .concat(db.entityTypes?.map?.((type) => ({
      type: ObjectType.type,
      id: type._id,
    })) || [])
    .concat(db.spaces?.map?.((space) => ({
      type: ObjectType.space,
      id: space._id,
    })) || [])
    .concat(db.libraries?.map?.((library) => ({
      type: ObjectType.library,
      id: library._id,
    })) || [])
    .concat(db.queries?.map?.((query) => ({
      type: ObjectType.query,
      id: query._id,
    })) || [])
    .concat(db.modes?.map?.((mode) => ({
      type: ObjectType.mode,
      id: mode._id,
    })) || [])
    .concat(db.canvases?.map?.((mode) => ({
      type: ObjectType.canvas,
      id: mode._id,
    })) || [])

    .concat(db.codeComponents?.map?.((comp) => ({
      type: ObjectType.codeComponent,
      id: comp._id,
    })) || [])
    .concat(db.tags?.map?.((comp) => ({
      type: ObjectType.tag,
      id: comp._id,
    })) || [])

    .concat(db.notionDocuments?.filter?.(p => p.name && !p.relative).map((comp) => ({
      type: ObjectType.document,
      id: comp._id,
    })) || [])
    .concat(db.apps?.filter?.(p => p.name).map((comp) => ({
      type: ObjectType.app,
      id: comp._id,
    })) || [])
    .concat(db.tableSchemas?.map?.((comp) => ({
      type: ObjectType.tableSchema,
      id: comp._id,
    })) || [])
    .concat(db.objectHandles?.map?.((comp) => ({
      type: ObjectType.objectHandle,
      id: comp._id,
    })) || [])
    .concat(db.pages2?.map?.((comp) => ({
      type: ObjectType.page,
      id: comp._id,
    })) || [])
    .concat(db.identifiers?.map?.((comp) => ({
      type: ObjectType.identifier,
      id: comp._id,
    })) || [])
    .concat(db.days?.map?.((comp) => ({
      type: ObjectType.day,
      id: comp._id,
    })) || [])
    .concat(db.events?.map?.((comp) => ({
      type: ObjectType.event,
      id: comp._id,
    })) || [])
    .concat(db.dataObjects?.filter?.(o => o.type == ObjectType.database)?.map?.((comp) => ({
      type: ObjectType.database,
      id: comp._id,
    })) || [])
    .concat(values().map((comp) => ({
      type: ObjectType.valuePoint,
      id: comp._id,
    })))
    

  return objects.filter(obj => {
    if (switches.exclude?.includes?.(obj.type)) {
      return false;
    }
    if (obj.type == ObjectType.global && !switches.includeGlobal) {
      return false;
    }
    return true;
  })
}

export function makeKey(obj) {
  return obj.type + ':' + obj.id;
}

export function objectsHierachy(exclude=[]) {
  const objects = {};

  function fromKey(key) {
    const [type, id] = key.split(':');
    return {
      type,
      id,
    };
  }

  function addToParent(obj) {
    const parent = getObjectParent(obj);
    if (parent) {
      if (!exclude.includes?.(parent.type)) {
        const key = makeKey(parent);
        if (!objects[key]) {
          objects[key] = {};
        }
        objects[key][makeKey(obj)] = true;
  
        addToParent(parent);
  
      }
    }
    else {
      const key = makeKey({
        type: ObjectType.global,
      });
      if (!objects[key]) {
        objects[key] = {}
      }
      objects[key][makeKey(obj)] = true;
    }
  }

  for (const object of allObjects({ exclude })) {
    addToParent(object);
  }

  const newObjects = {};
  for (const key in objects) {
    newObjects[key] = Object.keys(objects[key]).map(fromKey);
    // sort by type
    newObjects[key].sort((a, b) => {
      if (a.type < b.type) {
        return -1;
      }
      else if (a.type > b.type) {
        return 1;
      }
      else {
        return (objectName(a) || '').localeCompare(objectName(b));
      }
    });
  }

  return newObjects;
}

export function childObjects(parent) {
  const objects = allObjects();
  return objects.filter(ref => getObjectParent(ref)?.id == parent.id);
}

export function objectsInScope(obj, types?) {
  const tree = getScopeTree(obj);
  const objects = [];
  const hierarchy = objectsHierachy();

  for (const obj of tree) {
    const children = hierarchy[makeKey(obj)] || [];
    for (const child of children) {
      objects.push(child);
    }
  }

  if (types) {
    return objects.filter(o => types.includes(o.type))
  }
  else {
    return objects;
  }
}