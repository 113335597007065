import { db } from '../db';
import { getEntityById } from '../etc/createEntity';
import { CompiledValuePoint, execute, mapStructure } from '../glue/main';

export function executeSwitch(id: string, entityId: string): CompiledValuePoint {
  const entity = getEntityById(entityId);
  if (!entity) throw new Error(`Entity ${entityId} not found`);
  const value = execute(id);
  const mapped = mapStructure(value);
  for (const entry of mapped.entries.content) {
    const mappedEntry = mapStructure(entry);
    if (mappedEntry.match.content == entity.type) {
      return mappedEntry.content;
    }
  }
}
