import { db } from "../../db";
import { getEntityById } from "../../etc/createEntity";
import { ValueType } from "../ValueType";
import { CompiledValuePoint, RenderType, evaluate, mapStructure, render } from "../main";
import { structRenderers } from "../structRenderers";
import { typeRegistry } from "../typeRegistry.1";
import { getEntityZIndex } from "./renderAttributeValue";

const $NegativeZIndex = typeRegistry.registerType({
  $: '4ed5c26f-724e-536d-9581-c7a502769826',
}, ids => ({
  _id: ids.$,
  name: 'Negative Z Index',
  definition: []
}));

export const $AllEntitiesMatch = typeRegistry.registerType({
  $: '966abf58-5137-5a09-8430-2b4c53606648',
  Entities: '2145db41-3e3f-5c8c-8d49-02c4634912a5',
  Match: 'ed72cad4-964c-5994-9f10-7b1d172942b1',
}, ids => ({
  _id: ids.$,
  name: 'All Entities Match',
  definition: [
    {
      id: ids.Entities,
      name: 'Entities',
      type: [],
      property: 'entities',
    },
    {
      id: ids.Match,
      name: 'Match',
      type: [],
      property: 'match',
    }
  ]
}));

export function executeAllEntitiesMatch(value: CompiledValuePoint, map) {
  return false;
  const mapped = mapStructure(value);

  const entities = render(mapped.entities, map, RenderType.entities, {});

  let test;

  if (mapped.match.type[0] == ValueType.EntityState) {
    const match = evaluate(mapped.match, map);
    const s = db.stateTypes.find(st => st.values.find(v => v._id == match));

    test = entity => {
      const stateValue = entity.states?.[s._id];
      if (stateValue != match) {
        return false;
      }

      return true;
  
    }

  }
  else if (mapped.match.type?.[1] == $NegativeZIndex.$) {
    test = entity => {
      const zIndex = getEntityZIndex(entity._id);
      if (zIndex >= 0) {
        return false;
      }
      return true;
    }
  }

  let pass = true;
  for (const entityId of entities) {


    const entity = getEntityById(entityId);

    if (!test(entity)) {
      pass = false;
      break;
    }
  }

  return pass;
}

structRenderers[$AllEntitiesMatch.$] = (value, map, state) => {
  const pass = executeAllEntitiesMatch(value, map);
  return (
    <>
    {pass ? 'pass' : 'fail'}
    </>
  )

}