import styled from 'styled-components';
import ReactDOM from 'react-dom';
import jQuery from 'jquery';
import _ from 'lodash';
import { db } from '../db';
import { defaultWorkspace } from '../etc/defaultWorkspace';
import { DraftSelect } from '../etc/draftHelpers';
import { SortHandle, SortableCont, SortableEl, isId, showContextMenu } from '../helpers';
import { PropertyField } from './PropertyField';
import { TypeEditor } from './TypeEditor';
import { ObjectDisplay, ObjectRow } from './ObjectDisplay';
import { attributesInScope, getObject, tagsInScope } from "./objectFuncs";
import { X, XInit, XObject, XTouch, x } from '../XObject';
import { Cell } from "./Cell";
import { Tag } from "./Tag";
import { $GroupedSelectAttribute_CellType, DurationCellType, EntitiesCellType, EntityCellType, EventCellType, MediaCellType, MultiSelectCellType, PriorityCellType, NumberCellType, SelectCellType, TextCellType, ReactionCellType, TimeCellType, AttributeCellType, TextArrayCellType, CheckboxCellType } from "./cells";
import { css } from '../component2';
import React, { Component, Context, ContextType } from 'react';
import { component } from '../component';
import { SystemContext, SystemContextProps } from '../etc/SystemContext';
import { EntityDisplay, EntityName, MetaStatesWrapper, metaStatesClasses } from './EntityDisplay';
import { findBlock } from './NotionDocumentBad';
import { NotionBlockWrapper, NotionDocumentWrapper } from './NotionDocumentWrapper';
import { getEdgesForEntity } from '../etc/getEdgesForEntity';
import { deleteEdge } from '../etc/getEdgesForEntity';
import { addAttrToSchema, entitySchema, removeAttrFromSchema } from './attributesForType';
import { $AllEntitiesMatch, executeAllEntitiesMatch } from '../glue/structs/$AllEntitiesMatch';
import { $EntityTemplate, REMOVE_ATTRIBUTE_SIGNAL } from '../glue/structs/$EntityTemplate';
import { CompiledValuePoint, GlueView, RenderType, createRootValuePoint, evaluate, execute, getValuePoint, mapStructure, render } from '../glue/main';
import { sendSignal } from "../glue/signalsRegistry";
import { entityMetaStates } from '../etc/entityMatch';
import { NotionButton } from './AddButton';
import { EntityPath } from './EntityPath';
import { EntityRow } from './EntityRow';
import { getGraphParent, queryGraphBasic } from '../etc/queryGraph';
import pluralize from 'pluralize';
import { createEntity, getAllEntities, getEntityById } from '../etc/createEntity';
import { UserBadge } from './UserBadge';
import { appState, memoryAppState } from '../etc/appState';
import { $GroupedSelectAttribute } from '../glue/structs/$GroupedSelectAttribute';
import { AttributeType } from './AttributeType';
import { ValueType } from '../glue/ValueType';
import { getAttributeKey, renderAttributeValue } from '../glue/structs/renderAttributeValue';
import { executeSwitch } from './executeSwitch';
import { groupById } from '../glue/structs/groupById';
import { EXPRESSION_QUERY, QUERY, createQuery, describeQuery, executeEntityQuery, queryChain, query_entityChildren, query_entityDescendants, query_entityReferences, query_entityType } from "../etc/queryFuncs";
import { openWindow, triggerInspectObject } from '../osHelpers';
import { WindowType } from '../etc/WindowType';
import { ViewQuery, ViewQueryish } from './ViewQuery';
import { Svg } from './Svg';
import classNames from 'classnames';
import { ObjectRef, ObjectType } from '../types/ObjectRef';
import { $EntityElement } from '../glue/structs/$EntityElement';
import { $Document } from '../glue/structs/$Document';
import { showPrompt } from '../etc/showPrompt';
import { getReferences } from './getReferences';
import { PaneType } from '../types/PaneType';
import { RichTextEditorOld } from './RichTextEditorOld';
import { entityComputedName, entityDisplayName, entityDisplayView } from './entityDisplayName';
import { ViewType } from '../types/ViewType';
import { getCodeComponent } from '../pushCode';
import { explicitInspectObj, inspectObj } from '../inspectObj';
import { EntityNameEditor } from './EntityNameEditor';
import { attributeTypeDefs } from './attributeTypes';
import { InsertionCont } from './InsertionCont';
import { CodeComponenType } from '../ideIndex';
import { isMobile } from '../isMobile';
import { cacheByEntity } from '../initBacklinkCache';
import { WithContextAction } from './WithContextAction';
import { NotionTable2 } from './NotionTable2';
import { FormulaObjectWrapper as ObjectRef_ } from '../FormulaObjectWrapper';
import { AttributeSelector } from './AttributeSelector';
import { AttributeAdder } from './AttributeAdder';
import sanitizeHtml from 'sanitize-html';
import md5 from 'md5';
import { getAllEdges, pushEdge } from '../getAllEdges';
import { relatedEntities } from './relatedEntities';
import { objectResource } from './objectResource';
import { componentClass } from '../../ide/TSEditor';
import { EventOccurrence } from './EventsRoot';
import { createEventOccurrence } from './startEvent';

@component
class Backlinks extends Component<{ id }> {
  static contextType = SystemContext;
  context: SystemContextProps;
  render() {
    const backlinks = getResolvedBacklinks(this.props.id)
    return (
      <>
        {backlinks.map(backlink => {
            return (
              <ObjectRow active={this.context.next?.()?.id == backlink.id} key={backlink.id} obj={backlink} _onClick={() => {
                if (backlink.type == ObjectType.entity) {
                  this.context.navigate({
                    type: 'entity',
                    id: backlink.id,
                  })
                }
                else if (backlink.type == ObjectType.document) {
                  this.context.navigate({
                    type: 'page',
                    id: backlink.id,
                  })
                }
              }} />
            )
        })}
      </>
    )
  }

}

interface PassedData {
  references: {
    sourceEntity: string
    attribute: string;
  }[];
  backlinks: string[];
}

enum BlockType2 {
  page = 'e65f19a5-5151-5da5-ad08-a9f50193230c',
  attributes = 'd364cbd9-7486-5889-8032-827bf66daca7',
  schemaAttributes = 'b754124a-0ec3-5898-b48d-093b5cd0f82e',
  query = '8eb3a96d-7625-568e-8974-6c7cdb732d0e',
  contentsQuery = '4826a002-8d81-56a0-b72c-de66294f369b',
  richTextAttribute = '3db1484a-cecf-5ab6-bd43-a25b8b278212',

  systemProperties = 'f1e6b625-2eae-57ac-a454-4d5bff393c2c',
  systemEntities = 'e21bd85f-ca4c-537c-9455-d0c943dec3be',
  systemBacklinks = 'f8771db1-2476-588a-a782-11ce103342fa',
  systemReferences = 'ac2991c2-b981-578a-97ba-67cfda487a58',

  attributeReferences = 'd255aad0-c8a7-59d9-af68-04702dc633f3',

  table = '942dc8fe-3370-502f-8be9-2b8d614b6dc4',
  table2 = 'cd806415-3597-5db0-a6ff-6de350cdf262',

  related = '59ad7c7b-ae5c-5c57-aab8-597ef3933776',
  timeTracking = '8617ad30-9874-51c3-96e6-77ed0f8dae47',
}

function getReverseLineProps(passedData: PassedData) {
  const reverseLineProps = {};
  const refs = passedData.references
  for (const ref of refs) {
    const attr = db.attributeTypes.findById(ref.attribute);
    const rule = attr?.reversePropRules?.find?.(r => r.viewType == '28460994-2d99-5d47-9f30-c29c7fdfd37f');
    if (rule) {
      if (!reverseLineProps[ref.attribute]) reverseLineProps[ref.attribute] = {
        entities: [],
        rule,
      }
      reverseLineProps[ref.attribute].entities.push(ref.sourceEntity);
    }
  }

  return reverseLineProps;
}


const renderEntitiesSection = ({
  defaultHidden,
  id,
  state,
  title,
  contents,
  customCheckboxes = undefined,
  after = undefined,
  underEntity = undefined,
  map = undefined,
  baseQuery = undefined,
  n,
  el = undefined,
}) => {
  let orgContents = contents;
  const entity = getEntityById(n);
  if (map) {
    contents = contents.map(map).filter(Boolean);
  }
  const filterBackgroundEntities = c => {
    const metaStates = entityMetaStates(c);
    const background = metaStates.find(id => {
      const metaState = defaultWorkspace().metaStates.find(m => m._id == id);
      if (metaState.backgroundState) return true;
    });

    if (state.onlyShowBackground) {
      if (!background) return false;
    }
    else if (background && !state.includeBackgroundEntities) return false;


    return true;
  }
  contents = contents.filter(filterBackgroundEntities);

  const allCount = contents.length;

  const types = {};
  for (const id of contents) {
    const entity = getEntityById(id);
    if (!entity) continue;
    const type = entity.type && db.entityTypes.findById(entity.type);
    if (type) {
      if (!types[type._id]) types[type._id] = 0;
      types[type._id]++;
    }
    else {
      if (!types['untyped']) types['untyped'] = 0;
      types['untyped']++;
    }
  }

  const orgSameType = (() => {
    // return true if all entities are of the same type
    let type;
    for (const id of contents) {
      const entity = getEntityById(id);
      if (!entity) continue;
      if (!type) {
        type = entity.type;
      }
      else if (type != entity.type) {
        return false;
      }
    }
    return type;
  })();

  if (state.contentsType) {
    contents = contents.filter(e => {
      if (state.contentsType == 'untyped') return !getEntityById(e).type;

      const entity = getEntityById(e);
      if (entity) {
        return entity.type === state.contentsType;
      }
      return false;
    });
  }

  const singleType = isId(state.contentsType) || orgSameType;

  const grouped = state.groupBy && groupById(orgContents, state.groupBy);


  const render = list => {
    return list?.map?.(cc => {
      let c;
      if (map) {
        c = map(cc);
      }
      else {
        c = cc;
      }

      if (!contents.includes(c)) return null;
      

      
      const entity = getEntityById(c);
      

      // const entity = getEntityById(c);
      // const schema = entitySchema(c, {});
      // let elements = renderEntityElements(c);
      if (!entity) return (
        <div className="entityRow" key={c}
        onContextMenu={e => {
          e.preventDefault();
          showContextMenu(e, [
            {
              text: 'Delete edge',
              onClick: () => {
                const edge = getAllEdges().find(e => e.entities.includes(n) && e.entities.includes(c));
                if (edge) deleteEdge(edge._id);
              }
            }
          ]);
        }}

        >
          <span className="name">
            {c}
          </span>
        </div>
      )
      
      return (
        <div  key={c}>
        <EntityRow id={c} parent={n} path={n} el={el?.(cc)} />
        {underEntity?.(cc)}
        </div>
      );
    }).filter(Boolean);
  }

  const hidden = state.hidden ?? defaultHidden;

  return (
    <>
      <div className={classNames('section contents', {
        hidden: hidden,
      })}>
        <span
          className="sectionTitle"
          onContextMenu={e => {
            e.preventDefault();
            showContextMenu(e, [
              {
                text: 'Create query',
                onClick: () => {
                  createQuery([
                    QUERY,
                    baseQuery.concat([
                      state.contentsType && query_entityType(state.contentsType),
                    ]),
                  ], ['58c22695-b585-5f44-93f9-1fd1e135f2e5', n]);
                }
              },
              {
                text: 'Create relative query',
                onClick: () => {
                  const q = createQuery([
                    QUERY,
                    baseQuery.concat([
                      state.contentsType && query_entityType(state.contentsType),
                    ]),
                  ], ['58c22695-b585-5f44-93f9-1fd1e135f2e5', n], null, true);

                  XObject.push(entity, 'queries', XObject.obj({
                    query: q._id,
                  }));
                }
              },
            ]);
          }}
        >
          {hidden && <Svg name="chevron" className="hiddenIndicator" />}
          <span
            className="sectionTitleTitle"
            onClick={() => {
              console.log(id);
              state.hidden = !hidden;
            }}
          >{title}</span>
          
          {/* {orgSameType && (
            <>
              <select>
                <option>{pluralize(db.entityTypes.findById(orgSameType).name)} ({allCount})</option>
              </select>
            </>
          )} */}

          {/* {!orgSameType && (
            <>
                          <select value={state.contentsType || ''}
            onChange={e => {
              state.contentsType = e.target.value;
              delete state.groupBy;
            }}
          >
            <option value="">All ({allCount})</option>
            {Object.keys(types).filter(t => t != 'untyped').map(t => (
              <option key={t} value={t}>{pluralize(db.entityTypes.findById(t).name)} ({types[t]})</option>
            ))}
            {types['untyped'] > 0 && <option value="untyped">Untyped ({types['untyped']})</option>}
          </select>
            </>
          )} */}

          {/* {singleType && (() => {
            const schema = entitySchema(contents[0]);
            if (!schema) return null;
            const attributes = schema.attributes && evaluate(schema.attributes);

            return (
              <>
                <select
                  value={state.groupBy || ''}
                  onChange={e => {
                    state.groupBy = e.target.value;
                  }}
                >
                  <option value="">No Grouping</option>
                  {attributes?.map?.(a => {
                    const attr = db.attributeTypes.findById(a);
                    if (!attr) return null;
                    return (
                      <option key={a} value={a}>{attr.name}</option>
                    )
                  })}
                </select>
              </>
            )
          })()} */}
          


          {/* <div className="checkboxes">
            {customCheckboxes?.()}
            <input title="Include Background Entities" type="checkbox" checked={state.includeBackgroundEntities} onChange={e => {
              state.includeBackgroundEntities = e.target.checked;
            }} />
            <input title="Only Show Background Entities" type="checkbox" checked={state.onlyShowBackground} onChange={e => {
              state.onlyShowBackground = e.target.checked;
            }} />

          </div> */}

        </span>

        
        {!hidden && (
          <>
                      {grouped && (
          <>
            {grouped.map(({ key, group, entities }) => {
              const r = render(entities);
              if (!r?.length) return;
              return (
                <div key={group} className="group">
                  <div className="groupName">{group == 'None' ? 'None' : renderAttributeValue(state.groupBy, key, group)}</div>
                  {r}
                </div>
              )
            })}
          </>
        )}
        {!grouped && render(orgContents)}
        {after?.()}
          </>
        )}


      </div>
    </>
  );
}

@component
class HTMLPreview extends Component<{ html: string }> {
  static styles = styled.div`
    h1 {
      display: inline;
      font-size: inherit;
      margin: 0;
      padding: 0;
      margin-right: 4px;
    }
    ul, li, p {
      margin: 0;
      padding: 0;
      display: inline;
      margin-right: 4px;
    }
  `;
  render() {
    return '...';
  //   return <Truncate
  //   lines={1}
  //   dangerouslySetInnerHTML={{
  //    __html: this.props.html
  //   }}
  // />
  }
}

const hoverStyles = css`
  border-radius: 3px;
  /* padding: 0px 6px; */
  /* height: 34px; */
  /* display: flex; */
  /* align-items: center; */
  color: #969696;
  &:hover {
    background: rgba(57, 57, 57, 0.08);
  }
`

function getEntityBacklinks(entityId: string) {
  const sources: {
    obj: ObjectRef
    ref?: string
  }[] = [];

  const c = cacheByEntity[entityId];

  if (c) {
    for (const id in c) {
      if (c[id].obj.type == ObjectType.document) {
        for (const ref of c[id].references) {
          sources.push({
            obj: c[id].obj,
            ref,
          });
        }  
      }
      else if (c[id].obj.type == ObjectType.entity) {
        sources.push({
          obj: c[id].obj,
        });
      }
    }
  }

  return sources;
}

function getResolvedBacklinks(entityId: string): ObjectRef[] {
  const backlinks = getEntityBacklinks(entityId);

  const map = {};
  for (const backlink of backlinks) {
    let ref;
    const obj = getObject(backlink.obj);
    let key
    if (backlink.obj.type == ObjectType.document) {
      if (obj.parent?.type == ObjectType.entity) {
        ref = obj.parent;
      }
      else {
        ref = backlink.obj;
      }
    }
    else {
      ref = backlink.obj;
      
    }

    key = `${ref.type}:${ref.id}`;
    map[key] = true;
  }

  return Object.keys(map).map(k => {
    const [type, id] = k.split(':');
    return {
      type,
      id,
    } as any;
  });
}

function getEntityEntityBacklinks(entityId: string) {
  const backlinks = getEntityBacklinks(entityId);
  const entities = {};
  for (const backlink of backlinks) {
    const obj = getObject(backlink.obj);
    if (backlink.obj.type == ObjectType.document) {
      if (obj.parent?.type == ObjectType.entity) {
        entities[obj.parent.id] = true;
      }
    }
    else if (backlink.obj.type == ObjectType.entity) {
      entities[backlink.obj.id] = true;
    }
  }

  return Object.keys(entities);
}

function _renderElement(element: CompiledValuePoint, map, id) {
  if (!element.type) return;
  const entity = getEntityById(id);
  let c;
  let styles;
  if (element.type[1] == $AllEntitiesMatch.$) {
    const pass = executeAllEntitiesMatch(element, {
      ...map,
    });
    c = pass ? '✓' : '✗';
  }
  else if (element.type[1] == $EntityElement.$) {
    const mapped = mapStructure(element);
    c = _renderElement(mapped.content, map, id);
  }
  else if (element.type[0] == ValueType.EntityElement) {
    c = 'poop2';
  }
  else if (element.type[0] == ValueType.EntityAttribute) {
    styles = {
      color: 'gray',
      fontSize: '.8em',
      fontWeight: 500,
    }
    c = renderAttributeValue(element.content, getAttributeKey(element.content, id), entity.attributes?.[element.content]);
  }
  else if (element.type[0] == ValueType.CodeComponent) {
    c = 'test!';
  }

  return (
    <span data-value-point={element._id} key={element._id} style={styles}>{c}</span>
  )
}

export function _renderEntityElements(id, _elements) {
  const entity = getEntityById(id);

  let elements;
    const map = {
      [$EntityTemplate.Entity]: id,
    }
    elements = _elements.map(e => {
      const mapped = mapStructure(e);
      const element = mapped.content;
      if (!element?.type) return;
      let c;
      let styles;
      if (element.type[1] == $AllEntitiesMatch.$) {
        const pass = executeAllEntitiesMatch(element, {
          ...map,
        });
        c = pass ? '✓' : '✗';
      }
      else if (element.type[1] == $EntityElement.$) {
        c = 'poop';
      }
      else if (element.type[0] == ValueType.EntityElement) {
        c = 'poop2';

        const el = db.elements.findById(element.content);
        // if (el.type == ElementConfigType.glue) {
          c = _renderElement(execute(el.valuePoint), map, id);
        // }

      }
      else if (element.type[0] == ValueType.EntityAttribute) {
        styles = {
          color: 'gray',
          fontSize: '.8em',
          fontWeight: 500,
        }
        c = renderAttributeValue(element.content, getAttributeKey(element.content, id), entity.attributes?.[element.content]);
      }
      else if (element.type[0] == ValueType.CodeComponent) {
        if (element.content) {
          if (getCodeComponent(element.content)) {
            const comp = db.codeComponents.findById(element.content);
            if (comp.type == CodeComponenType.function) {
              c = getCodeComponent(element.content)(entity);
            }
            else if (comp.type == CodeComponenType.component) {
              const Comp = getCodeComponent(element.content);
              c = <Comp entity={entity} />;
            }
            else {
              c = 'test!';
            }  

          }
          else {
            c = 'no component';
          }
        }
        else {
          c = 'no value';
        }
      }
      else if (element.type[0] == ValueType.String) {
        c = element.content;
      }


      return (
        <span data-value-point={e._id} key={element._id} style={styles}>{c}</span>
      )
    });


  return elements;
}

export function renderEntityElements(id) {
  if (!id) return [];
  // return [];
  const entity = getEntityById(id);
  const schema = entitySchema(id, {});
  let elements;
  if (schema?.elements) {
    const map = {
      [$EntityTemplate.Entity]: id,
    }
    elements = schema.elements.content.map(e => {
      const mapped = mapStructure(e);
      const element = mapped.content;
      let c;
      let styles;
      if (element.type[1] == $AllEntitiesMatch.$) {
        const pass = executeAllEntitiesMatch(element, {
          ...map,
        });
        c = pass ? '✓' : '✗';
      }
      else if (element.type[0] == ValueType.EntityAttribute) {
        styles = {
          color: 'gray',
          fontSize: '.8em',
          fontWeight: 500,
        }
        c = renderAttributeValue(element.content, getAttributeKey(element.content, id), entity.attributes?.[element.content]);
      }
      else if (element.type[0] == ValueType.CodeComponent) {
        c = 'test';
      }

      return (
        <span data-value-point={e._id} key={element._id} style={styles}>{c}</span>
      )
    });
  }

  return elements;
}

export function renderEntityRowElememnts(c) {
  // return [];
  const entity = getEntityById(c);
  const schema = entitySchema(c, {});
  let rowElements;
  if (schema?.rowElements) {
    const map = {
      [$EntityTemplate.Entity]: c,
    }
    rowElements = schema.rowElements.content.map(e => {
      const mapped = mapStructure(e);
      const element = mapped.content;
      let c;
      if (element.type[1] == $AllEntitiesMatch.$) {
        const pass = executeAllEntitiesMatch(element, {
          ...map,
        });
        c = pass ? '✓' : '✗';
      }
      else if (element.type[0] == ValueType.EntityAttribute) {
        return renderAttributeValue(element.content, getAttributeKey(element.content, c), entity.attributes?.[element.content]);
      }
      else if (element.type[0] == ValueType.CodeComponent) {
        c = 'test';
      }


      return (
        <span data-value-point={e._id} key={element._id}>{c}</span>
      )
    });
  }

  return rowElements;
}

function capturedReferences(id: string, passedData: PassedData) {
  const captured: { sourceEntity, attribute }[] = [];
  for (const ref of passedData.references) {
    const attr = db.attributeTypes.findById(ref.attribute);
    if (attr?.reversePropRules?.length) {
      captured.push(ref);
    }
  }
  return captured; 
}

function excludeReferences(source: {sourceEntity, attribute}[], compare: {sourceEntity, attribute}[]) {
  return source.filter(s => !compare.find(c => c.sourceEntity == s.sourceEntity && c.attribute == s.attribute));
}

class BlockManager {
  constructor(private entity, private page, private passedData: PassedData) {

  }

  _entityPageBlocks() {
    return XObject.get(XObject.get(this.entity, 'pageBlocks', {
      [this.page._id]: [],
    }), this.page._id, []);;
  }

  addPersonalBlock(index?) {
    this.insertBlock({
      block: XObject.obj(),
      personal: true,
    }, index ?? this.blocks().length);
  }

  resetAfter() {
    const typeBlocks = this.matchedBlocks();
    const entityPageBlocks = this._entityPageBlocks();
    for (let i = 0; i < entityPageBlocks.length; i++) {
      const personalBlock = entityPageBlocks[i];
      if (i == 0) {
        personalBlock.after = typeBlocks[typeBlocks.length - 1]._id;
      }
      else {
        personalBlock.after = entityPageBlocks[i - 1]._id;
      }
    }
  }

  matchedBlocks(baseRels={}) {
    return this.page.blocks?.filter?.(b => {
      if (b.match) {
        return b.match[1] == this.entity.type;
      }
      else if (b.type == BlockType2.systemProperties) {
        return true;
      }
      else if (b.type == BlockType2.systemReferences) {
        const captured = capturedReferences(this.entity._id, this.passedData);
        let refs = excludeReferences(this.passedData.references, captured);
                refs = refs.filter(r => {
          if (baseRels[r.sourceEntity]?.[1] == r.attribute) {
            return false;
          }
          return true;
        });

        return refs.length > 0;
      }
      else if (b.type == BlockType2.systemBacklinks) {
        return this.passedData.backlinks.length > 0;
      }
      else if (b.type == BlockType2.systemEntities) {

        
        let r = getEdgesForEntity(this.entity._id).filter(e => {
          return e.directed && e.entities[0] == this.entity._id;
        }).map(e => e.entities[1]).filter(id => {
          const entity = getEntityById(id);
          if (!entity || entity.__deleted) return false;
          return true;
        });

                      r = r.filter(id => {
                if (baseRels[id]?.[0] == 'children' || baseRels[id]?.[0] == 'descendants') {
                  return false;
                }
                return true;
              })

        

        return r.length > 0;
      }
      else if (b.type == BlockType2.attributeReferences) {
        const attr = db.attributeTypes.findById(b.attribute);
        const rule = attr.reversePropRules.find(r => r.viewType == '7aeb14b3-f80d-5273-a228-09b815ccff54');
        if (rule) {
          let refs = this.passedData.references.filter(r => r.attribute == b.attribute).map(r => r.sourceEntity)
          if (rule.filter) {
            refs = executeEntityQuery(rule.filter, refs);
          }

          return refs.length > 0;
        }
      }
    }) || [];
  }

  blocks(baseRels={}): {
    personal
    block
  }[] {
    const entityPageBlocks = this._entityPageBlocks();

    const typeBlocks = this.matchedBlocks(baseRels);

    const blocks = [];

    for (const block of typeBlocks) {
      blocks.push(block);
    }

    const toAdd = [...entityPageBlocks]
    loop: while (toAdd.length > 0) {
      for (let i = 0; i < toAdd.length; i++) {
        const block = toAdd[i];
        if (block.after) {
          const after = blocks.find(b => b._id == block.after);
          if (after) {
            const index = blocks.indexOf(after);
            blocks.splice(index + 1, 0, block);
            toAdd.splice(i, 1);  
            continue loop;
          }

        }
        else {
          blocks.unshift(block);
          toAdd.splice(i, 1);
          continue loop;
        }
      }
    }


    const b = blocks.map(b => ({
      personal: !typeBlocks.find(tb => tb._id == b._id),
      block: b,
    }))

    return b;
  }

  insertBlock(block: {personal, block }, index) {

    const blocks = this.blocks();
    const afterChanges = {};
    const newPageBlocks = x(this.page.blocks);

    const entityPageBlocks = x(this._entityPageBlocks());


    if (block.personal) {
      entityPageBlocks.push(block.block);
    }
    else {
      // newPageBlocks.push(block.block);
    }


    if (block.personal) {
      afterChanges[block.block._id] = blocks[index - 1]?.block?._id;

    }
    else {
      let lastBlock;
      for (let i = index - 1; i >= 0; i--) {
        if (!blocks[i].personal) {
          lastBlock = blocks[i];
          break;
        }
      }

      if (lastBlock) {
        const lastBlockIndex = newPageBlocks.findIndex(b => b._id == lastBlock.block._id);
        newPageBlocks.splice(lastBlockIndex + 1, 0, block.block);
      }
      else {
        newPageBlocks.unshift(block.block);
      }
    }

    const currentBlock = blocks[index];

    if (currentBlock) {
      if (currentBlock.personal) {
        afterChanges[currentBlock.block._id] = block.block._id;
      }
    }


    this.page.blocks = X(newPageBlocks);

    for (const id in afterChanges) {
      const block = entityPageBlocks.find(b => b._id == id);
      if (block) {
        block.after = afterChanges[id];
      }
    }

    this.entity.pageBlocks[this.page._id] = X(entityPageBlocks);

  }

  delete(index) {
    const blocks = this.blocks();
    const afterChanges = {};
    const block = blocks[index];
    const newPageBlocks = x(this.page.blocks);

    const nextBlock = blocks[index + 1];
    if (nextBlock?.personal) {
      afterChanges[nextBlock.block._id] = blocks[index - 1]?.block?._id;
    }

    if (block.personal) {
      this._entityPageBlocks().splice(this._entityPageBlocks().findIndex(b => b._id == block.block._id), 1);
    }
    else {
      newPageBlocks.splice(newPageBlocks.findIndex(b => b._id == block.block._id), 1);
    }
    
    blocks.splice(index, 1);

    this.page.blocks = X(newPageBlocks);

    const entityPageBlocks = x(this._entityPageBlocks());
    for (const id in afterChanges) {
      const block = entityPageBlocks.find(b => b._id == id);
      if (block) {
        block.after = afterChanges[id];
      }
    }

    this.entity.pageBlocks[this.page._id] = X(entityPageBlocks);


  }

  moveBlock(fromIndex, toIndex) {
    if (fromIndex == toIndex) return;
    const blocks = this.blocks();

    const blockIds = {};

    const afterChanges = {};
    const newPageBlocks = x(this.page.blocks);
    for (const block of newPageBlocks) {
      blockIds[block._id] = true;
    }

    const removeBlock = index => {
      const block = blocks[index];
      const nextBlock = blocks[index + 1];
      if (nextBlock?.personal) {
        afterChanges[nextBlock.block._id] = blocks[index - 1]?.block?._id;
      }

      if (block.personal) {
        afterChanges[block.block._id] = false;
      }
      else {
        newPageBlocks.splice(newPageBlocks.findIndex(b => b._id == block.block._id), 1);
      }
      
      blocks.splice(index, 1);
    }
    const insertBlock = (index, block: { personal, block }) => {
      if (block.personal) {
        console.log(afterChanges[block.block._id] = blocks[index - 1]?.block?._id);

      }
      else {
        let lastBlock;
        for (let i = index - 1; i >= 0; i--) {
          if (!blocks[i].personal) {
            lastBlock = blocks[i];
            break;
          }
        }

        if (lastBlock) {
          const lastBlockIndex = newPageBlocks.findIndex(b => b._id == lastBlock.block._id);
          console.log(lastBlockIndex)
          newPageBlocks.splice(lastBlockIndex + 1, 0, block.block);
          console.log(1);
        }
        else {
          newPageBlocks.unshift(block.block);
          console.log(2);
        }
      }

      const currentBlock = blocks[index];

      if (currentBlock) {
        if (currentBlock.personal) {
          afterChanges[currentBlock.block._id] = block.block._id;
        }
      }

    }

    const block = blocks[fromIndex];
    removeBlock(fromIndex);

    insertBlock(toIndex, block);

    for (const id in blockIds) {
      if (!newPageBlocks.find(b => b._id == id)) {
        console.log('missing a block', id, x(block));
        return
      }
    }

    this.page.blocks = X(newPageBlocks);

    const entityPageBlocks = x(this._entityPageBlocks());
    for (const id in afterChanges) {
      const block = entityPageBlocks.find(b => b._id == id);
      if (block) {
        block.after = afterChanges[id];
      }
    }

    this.entity.pageBlocks[this.page._id] = X(entityPageBlocks);
  }
}

const renderAttribute = (entity, attrId, context) => {
  const t = db.attributeTypes.findById(attrId);
  if (!t) return null;

  const n = entity._id;

  let cellType;
  let defaultValue;
  let valuePoint;
  if (t.type === AttributeType.text) {
    cellType = new TextCellType({});
  }
  else if (t.type === AttributeType.textArray) {
    cellType = new TextArrayCellType({});
  }
  else if (t.type == AttributeType.entities) {
    cellType = new EntitiesCellType({
      baseEntity: n,
      query: t.query,
      resolved: t.resolved,
    });
    defaultValue = [];
  }
  else if (t.type == AttributeType.entity) {
    cellType = new EntityCellType({
      baseEntity: n,
      query: t.query,
      sort: t.sort,
      resolved: t.resolved,
      options: {
        type: t.optionsType,
        valuePoint: t.valuePoint,
        entity: new ObjectRef_({
          type: ObjectType.entity,
          id: n,
        })
      },
    });
  }
  else if (t.type == AttributeType.event) {
    cellType = new EventCellType({});
  }
  else if (t.type == AttributeType.valuePoint) {
    if (t.valuePoint) {
      const value = execute(t.valuePoint);
      if (value.type?.[1] == $GroupedSelectAttribute.$) {
        cellType = new $GroupedSelectAttribute_CellType({
          valuePoint: t.valuePoint
        });
      }
    }
    else {
      return (
        <>
          <PropertyField object={t} property="valuePoint" />
          <button
            onClick={() => {
              const valuePoint = createRootValuePoint();
              t.valuePoint = valuePoint._id;
            }}
          >New</button>
        </>
      );
    }
  }
  else if (t.type == AttributeType.switch) {
    if (t.valuePoint) {
      const value = executeSwitch(t.valuePoint, n);
      valuePoint = t.valuePoint;
      if (value?.type?.[1] == $GroupedSelectAttribute.$) {
        cellType = new $GroupedSelectAttribute_CellType({
          valuePoint: value._id,
        });
      }
      else {
        return <span data-value-point={t.valuePoint}>No entry</span>
      }
    }
    else {
      return (
        <span data-value-point={t.valuePoint}>
          <PropertyField object={t} property="valuePoint" />
          <button
            onClick={() => {
              const valuePoint = createRootValuePoint();
              t.valuePoint = valuePoint._id;
            }}
          >New</button>
        </span>
      );
    }
  }
  else if (t.type == AttributeType.priority) {
    cellType = new PriorityCellType({});
  }
  else if (t.type == AttributeType.select) {
    cellType = new SelectCellType({
      addOption: (name) => {
        const option = XObject.obj({
          title: name,
        })
        XObject.push(t, 'options', option);
        return option._id;
      },
      options: XObject.get(t, 'options', []),
    });
  }
  else if (t.type == AttributeType.multiSelect) {
    cellType = new MultiSelectCellType({
      addOption: (name) => {
        const option = XObject.obj({
          title: name,
        })
        XObject.push(t, 'options', option);
        return option._id;
      },
      options: XObject.get(t, 'options', []),
    });
    defaultValue = [];
  }
  else if (t.type == AttributeType.media) {
    cellType = new MediaCellType({
    });
  }
  else if (t.type == AttributeType.richText) {
    const active = context.next?.()?.attribute == t._id;

    return (
      <div
        style={{
          cursor: 'pointer',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',

          background: active ? 'rgba(0, 0, 0, 0.1)' : undefined,


        }}
        onClick={() => {
          context.navigate({
            type: PaneType.richTextEditor,
            entity: n,
            attribute: t._id,
          })

        }}

        onContextMenu={e => {
          e.preventDefault();
          showContextMenu(e, [
            { text: 'Paste', onClick: async () => {
              const items = await navigator.clipboard.read();
              console.log(await (await items[0].getType('text/html')).text());
              // console.log(text);

              XObject.get(entity, 'attributes', {})[t._id] = sanitizeHtml(await (await items[0].getType('text/html')).text());

            }}
          ])
        }}
      >
       
        {entity?.attributes?.[t._id] && <HTMLPreview html={entity.attributes[t._id]} />}


      </div>
    )
  }
  else if (t.type == AttributeType.duration) {
    cellType = new DurationCellType({});
  }
  else if (t.type == AttributeType.boolean) {
    cellType = new CheckboxCellType({});
  }
  else if (t.type == AttributeType.datetime) {
    cellType = new TimeCellType({});
  }
  else if (t.type == AttributeType.number) {
    cellType = new NumberCellType({
      ...(t as any),
      baseEntity: n,
    });
  }
  else if (t.type == AttributeType.reaction) {
    cellType = new ReactionCellType({
      icon: t.icon,
    });
  }
  else if (t.type == AttributeType.attribute) {
    cellType = new AttributeCellType({});
  }

  if (cellType) {
    return (
      <span data-value-point={valuePoint}>
        <Cell
          title={t.name}
          cell={cellType}
          get={() => {
            // console.log('get', x(XObject.get(XObject.get(entity, 'attributes', {}), t._id, defaultValue)));
            return XObject.get(XObject.get(entity, 'attributes', {}), t._id, defaultValue);
          }}
          set={value => {
            XObject.get(entity, 'attributes', {})[t._id] = value;
          }}
        />
      </span>
    );
  }

  if (t.type == AttributeType.entity) {
    return (
      <PropertyField object={XObject.get(entity, 'attributes', {})} property={t._id} display={value => {
        return value && <EntityName id={value} />;
      }} />
    )
  }
  if (t.type == AttributeType.datetime || t.type == AttributeType.dueDate) {
    const styles = {};
    if (t.type == AttributeType.dueDate) {
      if (XObject.get(entity, 'attributes', {})[t._id]) {
        const dueDate = new Date(XObject.get(entity, 'attributes', {})[t._id]);
        if (dueDate < new Date()) {
          styles['color'] = 'red';
        }
      }
    }
      
    return (
      <span style={styles}>
      <PropertyField type="time" object={XObject.get(entity, 'attributes', {})} property={t._id} />
      </span>
    )
  }

  return (
    <>
      <PropertyField type={t.type} object={XObject.get(entity, 'attributes', {})} property={t._id} />
    </>
  );

}


@component
class RelatedEntities extends Component<{ entityId }> {
  static contextType = SystemContext;
  context: SystemContextProps;
  render() {

    const sims = relatedEntities(this.props.entityId);
    return (
      <>
        <table border={1}>
          {sims.map(x => {
            return (
              <tr key={x[0]._id}>
                <td>{x[1]}</td>
                <td><span
                  className="link"
                  onClick={() => {
                    this.context.navigate({
                      type: 'entity',
                      id: x[0]._id,
                    })
                  }}
                >{entityDisplayName(x[0]._id)}</span></td>
              </tr>
            )
          })}
        </table>
      </>
    )
    return (
      <>
        asdf
      </>
    )
  }
}

@component
class TimeTracking extends Component<{ entity }> {
  static styles = styled.div`
    .toolbar {
      display: flex;
      .toggle {
        margin-left: auto;
      }
    }
  `;
  render() {
    const descendants = queryGraphBasic(this.props.entity._id, true, false, false).map(e => e.entity);
    const eos = db.eventOccurrences.filter(eo => descendants.includes(eo.arguments));
    const events = db.events.filter(event => event.parent.id == appState.currentMode);
    const current = db.eventOccurrences.find(eo => eo.start && !eo.stop);
    return (
      <>
        <div className="toolbar">
          {current ? <button  className="toggle"
            onClick={() => {
              current.stop = new Date();
            }}
          >Stop</button> : <select className="toggle"
            onChange={e => {
              createEventOccurrence({
                event: e.target.value,
                start: new Date(),
                arguments: this.props.entity._id,
              });

              e.target.value = '';
            }}
          >
            <option value="">Start</option>
            {events.map(event => <option key={event._id} value={event._id}>{event.name}</option>)}
          </select>}
        </div>
        {eos.map(eo => {
          return (
            <div key={eo._id}>
              <EventOccurrence id={eo._id} />
            </div>
          )
        })}
      </>
    )
  }
}

@component
class EntityBlocks extends Component<{ page, entity, renderAttributes, passedData: PassedData }> {
  state = XInit(class {
    blockState = {}
    reordering = false
    addingProperty = false
  })

  static styles = styled.div`
    /* .section {
      margin-bottom: 0 !important;
      padding-top: 0 !important;
    } */
    .line {
      height: 4px;
      margin: 8px 0;
      cursor: pointer;
      position: relative;

      &:hover:before {
        background: #ccc;
      }


      &:before {
        content: '';
        position: absolute;
        background: #f7f7f7;
        top: 0;
        /* width: 70%; */
        right: 0;
        left: 0;
        /* left: 100px; */
        /* right: 100px; */
        height: 2px;
        /* width: 100%; */
        margin: auto;
        bottom: 0;
      }
    }

    /* .actions {
      position: absolute;
      top: 0;
      right: 0;
      height: 28px;
      svg {
        width: 15px;
        height: 15px;
      }
      z-index: 999999;
    } */

    .block__ {
      /* padding-top: 4px; */


      .actionBar {
        content: '';
        position: absolute;
        left: -11px;
        top: 0;
        bottom: 0;
        /* height: 30px; */
        margin: auto;
        width: 3px;
        background: #eee;

        cursor: pointer;

        &:hover {
          background: #ccc;
        }
      }

      position: relative;

      &.personal {
        /* .actions {
          svg {
            fill: #5684ee;;
          }
        } */

        .actionBar {
          /* background: #5684ee4d; */
          background: #569eee4d;

          &:hover {
            background: #5684ee;
          }
        }
      }
    }

    ${ViewQuery} {
      flex: 1 1 auto;
    }
  `;

  static t = {
    init: styled.div`
      span {
        padding: 3px 2px;
        margin-top: 16px;
        margin-bottom: 4px;
        letter-spacing: 0px;
        font-size: 13px;
        color: rgba(55, 53, 47, 0.5);
        display: flex;
        align-items: center;
        font-weight: 500;
      }
    
      button {
        display: block;
        width: 100%;

        appearance: none;
        border: none;
        background: transparent;

        height: 32px;

        &:hover {
          background: #f3f3f3;
          color: #4a4a4a;
        }

        border-radius: 3px;
        font: inherit;

        text-align: left;
        cursor: pointer;

        color: inherit;

        color: #969696;
      }
    `,
  }

  render() {
    const { t } = EntityBlocks;

    const page = this.props.page;
    const entity = this.props.entity;
    const _renderAttributes = this.props.renderAttributes;
    const n = entity._id;

    const renderBlock: (block)=>[any, any] = (block => {
      if (!block.type) {
        return [(
          <t.init>
            <span>Block type:</span>
            <button
              onClick={() => {
                const doc = XObject.obj({
                  relative: true,
                  key: '0a2b8091-6355-5df8-9abb-ae59667d9206',
                  parent: {
                    type: ObjectType.entity,
                    id: n,
                  },
                  blocks: [],
                });
                db.notionDocuments.push(doc);

                block.type = BlockType2.page;
                block.page = doc._id;

              }}
            >Page</button>
            <button
              onClick={() => {
                const doc = XObject.obj({
                  relative: true,
                  key: '0a2b8091-6355-5df8-9abb-ae59667d9206',
                  parent: {
                    type: ObjectType.entity,
                    id: n,
                  },
                });
                db.tables.push(doc);

                block.type = BlockType2.table;
                block.table = doc._id;

              }}
            >Table</button>

            <button
              onClick={() => {
                const handle = XObject.obj({
                  type: ObjectType.table,
                  parent: {
                    type: ObjectType.entity,
                    id: n,
                  }
                });
                db.objectHandles.push(handle);

                block.handle = handle._id;
                block.type = BlockType2.table2;
              }}
            >Table 2</button>

            <button
            
              onClick={() => {
                block.attributes = X([]);
                block.type = BlockType2.attributes;
              }}
            >Attributes</button>

<button
            
            onClick={() => {
              block.type = BlockType2.timeTracking;
            }}
          >Time Tracking</button>

          <button
            onClick={() => {
              block.type = BlockType2.related;
            }}
          >Related</button>

            <button
              onClick={() => {
                block.type = BlockType2.schemaAttributes;
              }}
            >Schema Attributes</button>

            <button
            
            onClick={() => {
              const query = createQuery(null, ['58c22695-b585-5f44-93f9-1fd1e135f2e5', n], {
                type: ObjectType.entity,
                id: n,
              }, true);
              block.query = query._id;


              block.type = BlockType2.query;
            }}
          >Query</button>

            <button
            
            onClick={() => {
              const query = createQuery(null, ['58c22695-b585-5f44-93f9-1fd1e135f2e5', n], {
                type: ObjectType.entity,
                id: n,
              }, true);
              block.query = query._id;


              block.type = BlockType2.contentsQuery;
            }}
          >Contents Query</button>



          <button
            onClick={() => {
              block.type = BlockType2.richTextAttribute;
            }}
          >Rich Text Property</button>
          </t.init>
        )];
      }

      const st = XObject.get(this.state.blockState, block._id, {});

      if (block.type == BlockType2.page) {
        const doc = db.notionDocuments.findById(block.page);
        if (!doc) return ['!!'];
        return [(
          <>
            <NotionDocumentWrapper
              docId={doc._id}
              entity={n}
              inline
              blocks={doc.blocks}
              setBlocks={blocks => {
                doc.blocks = blocks;
              }}
              configId={null}
              name={null}
              configMap={{
                [$Document.Entity]: n,
              }}
            />
          </>
        ), [{
          text: 'Edit',
          onClick: () => {
            openWindow({
              type: WindowType.NotionDocument,
              notionDocument: doc._id,
            })
          }
        }]]
      }
      else if (block.type == BlockType2.table) {
        const table = db.tables.findById(block.table);
        return [(
          <>
                    <NotionTable2
            active={null}
            state={this.state}
            table={table}
            editView={id => {
              triggerInspectObject({
                type: 'f803b269-1725-57ba-a8c6-dac2fa140e06',
                args: {
                  table: table._id,
                  view: id,
                },
              });

            }}
          />

          </>
        ), [{
          text: 'Edit',
          onClick: () => {
            explicitInspectObj({
              type: ObjectType.table,
              id: block.table,
            })
          }
        }]]
      }
      else if (block.type == BlockType2.attributes) {
        const schema = entitySchema(n);
        const attributeTypes = db.attributeTypes.filter(a => {
          if (schema?.attributes) {
            const attributes = evaluate(schema.attributes);
            if (attributes?.includes?.(a._id)) {
              return true;
            }
            else {
              return false;
            }
    
          }
          return false;
    
        });

        return [(
          <>
            {_renderAttributes(
              block.attributes?.map?.(id => db.attributeTypes.findById(id)) || [],
              attr => {
                XObject.push(block, 'attributes', attr);
              },
              attr => {
                block.attributes.splice(block.attributes.indexOf(attr._id), 1);
              },
              st,
              t => {
                return !attributeTypes.find(a => a._id == t._id);
              }
            )}
          </>
        )]
      }
      else if (block.type == BlockType2.schemaAttributes || block.type == BlockType2.systemProperties) {
        const schema = entitySchema(n);
        const schemaAttributes = (() => {
          if (schema?.attributes) {
            return evaluate(schema.attributes);
          }
          else {
            return [];
          }
        })();

        let theseAttributeTypes = db.attributeTypes.filter(a => {

          if (schemaAttributes.includes(a._id)) {
            return true;
          }
          return false;
    
        })
    
        if (entity.hasAttributes) {
          for (const id of entity.hasAttributes) {
            if (!theseAttributeTypes.find(a => a._id === id)) {
              theseAttributeTypes.push(db.attributeTypes.findById(id));
            }
          }
        }
    
        if (entity.attributes) {
          for (const id in entity.attributes) {
            if (!theseAttributeTypes.find(a => a?._id === id)) {
              theseAttributeTypes.push(db.attributeTypes.findById(id));
            }
          }
        }
    
        theseAttributeTypes = _.uniqBy(theseAttributeTypes, '_id');

        let attrs = [];

        if (entity.type) {
          attrs = attributesInScope({
            type: ObjectType.type,
            id: entity.type,
          })
        }
    
        attrs = attrs.concat(attributesInScope({
          id: n,
          type: ObjectType.entity,
        }));
    
        attrs = _.uniq(attrs);


        return [(
          <>
            {_renderAttributes(
              theseAttributeTypes,
              async value => {
                this.state.addingProperty = true;
              },
              t => {
                removeAttrFromSchema(n, t._id);
                // if (schema?.match) sendSignal(schema.match, REMOVE_ATTRIBUTE_SIGNAL, t._id);
                if (entity.hasAttributes) {
                  entity.hasAttributes.splice(entity.hasAttributes.indexOf(t._id), 1);
                }

              },
              st,
              t => {
                return !schemaAttributes.includes(t._id);
              },
              'Add property to schema',
              true,
            )}

            {this.state.addingProperty && (
                <>
                  <AttributeAdder
                    close={() => {
                      delete this.state.addingProperty;
                    }}
                    attrs={attrs}
                    scope={entity.type && {
                      type: ObjectType.type,
                      id: entity.type,
                    }}
                    onAdd={async (value, addToSchema) => {
                      if (addToSchema) {
                        addAttrToSchema(n, entity.type, value);
                      }
                      else {
                        XObject.push(entity, 'hasAttributes', value);
                      }
                      delete this.state.addingProperty;
                    }}
                  />

                </>
              )}
          </>
        )]
      }
      else if (block.type == BlockType2.related) {
        return [(
          <>
            <RelatedEntities entityId={n}  />
          </>
        )]
      }
      else if (block.type == BlockType2.query || block.type == BlockType2.contentsQuery) {
        return [(
          <div
          >
            <ViewQuery
              key={block._id}
              id={block.query}
              state={st}
              entity={n}
              showToolbar={true}
              touched={e => {
              }}
            />
          </div>
        ), [
          {
            text: 'Edit',
            onClick: () => {
              explicitInspectObj({
                type: ObjectType.query,
                id: block.query,
              })

            }
          }
        ]]
      }
      else if (block.type == BlockType2.richTextAttribute) {
        if (!block.attribute) {
          return (
            [<>
              <AttributeSelector
                scopes={[
                  {
                    type: ObjectType.entity,
                    id: n,
                  }
                ]}
                onSelect={attr => {
                  block.attribute = attr;
                }}

              />
            </>]
          )
        }
        else {
          const attr = db.attributeTypes.findById(block.attribute);
          return [
            <>
              <h2>{attr.name}</h2>
              <RichTextEditorOld
          value={entity?.attributes?.[block.attribute]}
          setValue={value => {
            if (!entity.attributes) {
              entity.attributes = {
                [block.attribute]: value,
              }
            }
            else {
              entity.attributes[block.attribute] = value;
            }
          }}

              />
            </>
          ]
        }
      }
      else if (block.type == BlockType2.systemReferences) {
        const captured = capturedReferences(n, this.props.passedData);

        let refs = excludeReferences(this.props.passedData.references, captured); 

        refs = refs.filter(r => {
          if (baseRels[r.sourceEntity]?.[1] == r.attribute) {
            return false;
          }
          return true;
        });

        return [
          <div className="df0e3751-93ea-5acd-adef-afef57ef9b85">
            {renderEntitiesSection({
              defaultHidden: false,
              id: 'references',
              state: XObject.get(st, 'referencesFilters', {}),
              contents: refs,
              map: ref => ref.sourceEntity,
              title: <><Svg name="tesseract" /> Referenced by</>,
              baseQuery: [
                query_entityReferences(n),
              ],
              n: entity._id,
              el: c => db.attributeTypes.findById(c.attribute)?.name,
            })}
          </div>
        ]
      }
      else if (block.type == BlockType2.systemBacklinks) {
        return (
          [
            <Backlinks id={n} />
            // renderEntitiesSection({
            //   defaultHidden: false,
            //   id: 'backlinks',
            //   state: XObject.get(st, 'backlinksFilters', {}),
            //   contents: this.props.passedData.backlinks,
            //   title: <><Svg name="tesseract" /> Backlinks</>,
            //   // map: b => b.sourceEntity,
            //   underEntity: c => {
            //     return;
            //     const entity = getEntityById(c.sourceEntity);
            //     const doc = entity.documents.find(d => d._id == c.document);
            //     const block = findBlock(doc.content, c.block);
            //     return (
            //       <div className="blockRef">
            //         <NotionBlockWrapper
            //           block={block}
            //           configId={doc.config}
            //           noChildren
            //         />
            //       </div>
            //     );
            //   },
            //   n: entity._id,
            // })
          ]
        )
      }
      else if (block.type == BlockType2.systemEntities) {
        return [
          <div className="df0e3751-93ea-5acd-adef-afef57ef9b85">
          {renderEntitiesSection({
            defaultHidden: false,
            id: 'contents',
            state: XObject.get(st, 'contentsFilters', {
              includeDescendants: true,
            }),
            contents: (() => {
              let r;
              if (st.includeDescendants) {
                r = queryGraphBasic(entity._id, false, false, !st.contentsFilters?.includeBackgroundEntities).map(e => e.entity);
              }
              else {
                r = getEdgesForEntity(entity._id).filter(e => {
                  return e.directed && e.entities[0] == entity._id;
                }).map(e => {
                  return e.entities[1];
                }).filter(id => {
                  const entity = getEntityById(id);
                  if (!entity || entity.__deleted) return false;
                  return true;
                });
              }

              r = r.filter(id => {
                if (baseRels[id]?.[0] == 'children' || baseRels[id]?.[0] == 'descendants') {
                  return false;
                }
                return true;
              })

              return r;

            })(),
            title: <><Svg name="tesseract" /> Entities</>,
            customCheckboxes: () => {
              return                 <input title="Include Descendants" type="checkbox" checked={st.includeDescendants} onChange={e => {
                st.includeDescendants = e.target.checked;
              }} />

            },
            after: () => {
              // return             <NotionButton text="Add entity" onClick={() => {
              //   add();
              // }} />
  
            },
            baseQuery: [
              st.includeDescendants ?
                query_entityDescendants(n) :
                query_entityChildren(n),
            ],
            n: entity._id,
          })}
          </div>
        ]
      }
      else if (block.type == BlockType2.attributeReferences) {
        
        const attr = db.attributeTypes.findById(block.attribute);
        const rule = attr.reversePropRules.find(r => r.viewType == '7aeb14b3-f80d-5273-a228-09b815ccff54');
        if (rule) {
          let refs = this.props.passedData.references.filter(r => r.attribute == block.attribute).map(r => r.sourceEntity);

          if (rule.filter) {
            refs = executeEntityQuery(rule.filter, refs);
          }
          return [
            <>
              <b>{rule.title}</b>
              {/* <div>
                {refs.map(id => (
                  <EntityRow key={id} id={id} />
                ))}
              </div> */}

              <ViewQueryish
                entities={refs}
                state={st}
                add={() => {
                  const entity = {
                    type: rule.match?.[0],
                    attributes: {
                      [attr._id]: attr.type == AttributeType.entity ? n : [n]
                    }
                  }

                  createEntity(entity, n);
                }}
                viewManager={{
                  addView: () => {
                    XObject.push(rule, 'views', XObject.obj({
                      type: ViewType.list,
                    }))
      
                  },
                  deleteView: () => {
                  },
                  editView: () => {
                  },
                  get: () => {
                    return rule.views || [];
                  },
                  init: () => {

                  },
                }}
              />
            </>
          ]
        }
      }
      else if (block.type == BlockType2.table2) {
        const table = objectResource({ id: n, type: ObjectType.entity }, block.handle);

        if (table) {
          return [
            <NotionTable2
              active={null}
              state={this.state}
              table={table}
              editView={id => {
                triggerInspectObject({
                  type: 'f803b269-1725-57ba-a8c6-dac2fa140e06',
                  args: {
                    table: table._id,
                    view: id,
                  },
                });
              }}
            />, [
              {
                text: 'Edit object handle',
                onClick: () => {
                  explicitInspectObj({
                    type: ObjectType.objectHandle,
                    id: block.handle,
                  })
                },
              }
            ]
          ]
        }
        return (
          [<>
            Test
          </>]
        )
      }
      else if (block.type == BlockType2.timeTracking) {
        return [
          <div>
            <TimeTracking entity={this.props.entity} />
          </div>
        ]
      }

      return [(
        <>
          ???
        </>
      )];
    }) as any;

    const blockManager = new BlockManager(entity, page, this.props.passedData);

    let blocks = blockManager.blocks();
    const baseRels = {};

    for (const block of blocks) {
      if (block.block.type == BlockType2.query || block.block.type == BlockType2.contentsQuery) {
        const query = db.queries.findById(block.block.query);
        executeEntityQuery(queryChain(query), undefined, n, baseRels);
      }
    }

    blocks = blockManager.blocks(baseRels);

    return (
      <>
        <SortableCont
          className="blocks"
          distance={isMobile() ? undefined : this.state.reordering ? 10 : 10}
          pressDelay={!isMobile() ? undefined : 100}
          style={this.state.reordering ? {
            'user-select': 'none',
          } : {}}
          onSortEnd={({ oldIndex, newIndex }) => {
            blockManager.moveBlock(oldIndex, newIndex);
          }}
          useDragHandle
        >
          {blocks.map?.(({ block, personal }, i) => {
            const id = block._id;
            if (!block) {
              return '';
            }
            const [c, contextMenu ] = renderBlock(block)
            return (
              <SortableEl index={i} key={block._id}>
                <div key={id} data-id={id}>
                  <div className="line" onClick={() => blockManager.addPersonalBlock(i)} />
                  <div className={classNames('block__', { personal })}>
                    <SortHandle>
                      <div
                        className="actionBar"
                        onClick={e => {
                          showContextMenu(e, [
                            {
                              text: 'Delete',
                              onClick: () => {
                                blockManager.delete(i);
                              }
                            },
                            page.match && !block.match && entity.type && {
                              text: 'Type match', 
                              onClick: () => {
                                const b = x(block);
                                blockManager.delete(i);

                                setTimeout(() => {
                                  b.match = ['2344e714-fe21-5df6-8423-ae3adf33083f', entity.type];
                                  blockManager.insertBlock({
                                    block: b,
                                    personal: false,
                                  }, i);

                                }, 1000);



                                // entityPageBlocks.splice(entityPageBlocks.indexOf(block), 1);
                                // XObject.push(page, 'blocks', block);
        
                              },
                            },

                            ...((contextMenu || []))
                          ].filter(Boolean));
                        }}
                      />
                    </SortHandle>
                    <div
                      style={this.state.reordering ? {
                        maxHeight: '100px',
                        overflow: 'hidden',
                      } :{}}
                    >
                      {c}
                    </div>
                  </div>
                </div>
              </SortableEl>
            );
          })}
        </SortableCont>
        <div className="line"
          onClick={() => {
            blockManager.addPersonalBlock();
          }}
        />
      </>
    );

  }
}

@component
class EntityActivity extends Component<{ id }> {
  static contextType = SystemContext;
  context!: ContextType<typeof SystemContext>;
  render() {
    const children = getEdgesForEntity(this.props.id).filter(e => {
      return e.directed && e.entities[0] == this.props.id;
    }).map(e => {
      return e.entities[1];
    }).filter(e => {
      const entity = getEntityById(e);
      if (!entity?.meta?.creation) {
        return false;
      }
      return true;
    });

    children.sort((a, b) => {
      const aEntity = getEntityById(a);
      const bEntity = getEntityById(b);
      return aEntity.meta.creation.timestamp.getTime() - bEntity.meta.creation.timestamp.getTime();
    });

    const entity = getEntityById(this.props.id);


    return (
      <>
        {entity.meta?.creation && <div>

          <b>{db.users.findById(entity.meta.creation.user).name}</b> created this entity at {entity.meta.creation.timestamp.toLocaleString()}
        </div>}
        {children.map(e => {
          const entity = getEntityById(e);
          const user = db.users.findById(entity.meta.creation.user);
          return (
            <div key={e}>
              <b>{user.name}</b> added <b
                style={{
                  background: this.context.next?.()?.id == e ? '#f3f3f3' : 'transparent',
                }}
                onClick={() => {
                  this.context.navigate({
                    type: 'entity',
                    id: e,
                  })
                }}
              >{entity.name}</b> at {entity.meta.creation.timestamp.toLocaleString()}
            </div>
          )
        })}
      </>
    );
  }
}

export function entityQueries(id) {
  const entity = getEntityById(id);
  const schema = entitySchema(id);
  const length = entity.queries?.length;
  return (entity.queries || []).concat(schema?.queries ? evaluate(schema.queries) : []).map(q => q.query).filter(q => db.queries.findById(q))
}

function getSystemPage(systemBlocks) {
  let systemPage = db.pages.find(p => p.key == 'system');
  if (!systemPage) {
    systemPage = XObject.obj({
      key: 'system',
      match: ['6a1705b5-b274-5578-800d-192f43d77fcb'],
      blocks: systemBlocks.map(b => XObject.obj(b)),
    });
    db.pages.push(systemPage);
  }
  return systemPage;
}

@component
export class EntityInspect extends Component<{
  id,
  state: {
    includeDescendants: boolean,
    contentsFilters
    // view
    statesExpanded
  }
  onGraphClick
  config
  onlyTop?
}> {
  static styles = styled.div`
    .nameAndType {
      position: relative;
      margin-bottom: 8px;
      .timestamp {
        font-size: 10px;
        color: rgba(55, 53, 47, 0.5);
      }

      .entityName {
        flex: 1 1 auto;
      }
      ${TypeEditor} {
        flex: 0 0 auto;
        min-width: 20px;
      }

      > .space {
        display: flex;
        align-items: center;
      }

      .right {
      overflow: hidden;

        display: flex;
        flex-direction: column;
        .tags {
          margin-top: 4px;
          margin-left: auto;
          ${Tag} {
            margin-left: 4px;
          }
        }

                position: absolute;
        top: 0;
        right: 0;
        transition: opacity 0.2s;

        .typeAndOther {
                ${Tag} {
        margin-right: 7px;
      }
        box-shadow: 0 0 3px 0px rgba(0,0,0,0.16);
        background-color: white;
        border-bottom-left-radius: 4px;  
        padding-bottom: 2px;
        padding-left: 4px; 
        > * {
          &:not(:last-child) {
            margin-right: 4px;
          }
        }


        margin-left: auto;

        display: flex;
        align-items: center;
        > * {
          flex: 0 0 auto;
        }

        > .space {
          flex: 1 1 0;
          select {
            flex: 1 1 0;
            width: 100%;
          }

        }

        }
        

      }

      ${ObjectDisplay} {
        white-space: nowrap;
      }
    }

    &.nameOver:not(.nameFocused) {
      .nameAndType .right {
        opacity: .5;
      }
    }

    &.nameFocused {

      .nameAndType .right {
        pointer-events: none;
      }

      &.nameOver {
        .nameAndType .right {
          opacity: 0;
        }
      }
      &:not(.nameOver) {
        .nameAndType .right {
          opacity: .5;
        }
      }

    }

    > .top {
      > .parent {
        font-size: 12px;
        color: rgb(150, 150, 150);
        svg {
          fill: rgb(150, 150, 150);
          width: 14px;
          height: 14px;
        }
      }

      > .toolBar {
        ${NotionButton} {
          height: 28px;
        }

        display: flex;
        /* border-bottom: 1px solid rgba(55, 53, 47, 0.1); */
        /* padding-bottom: 4px; */
        margin-bottom: 8px;
        align-items: center;
        .views {
          display: flex;
          align-items: center;
          ${NotionButton} {
            margin-left: 4px;
          }
          .view {
            cursor: pointer;
            display: flex;
            align-items: center;
            height: 34px;
            padding: 0 8px;
            color: #bcbcbc;
            font-size: 14px;
            font-weight: 600;
            box-sizing: border-box;
            border-bottom: 2px solid transparent;

            &.personal {
              svg {
                fill: #5684eea1;
              }
            }
            svg {
              width: 15px;
              height: 15px;
              margin-right: 3px;
              fill: #bcbcbc;
            }
            &.noText {
              svg {
                margin-right: 0;
              }
            }
            /* border-bottom: 2px solid #bcbcbc; */
            &.active {
              /* border-bottom: 2px solid #4a4a4a; */
              color: inherit;
              svg {
                fill: #4a4a4a;
              }

              &.personal {
                svg {
                  fill: #5684ee;
                }
                /* border-bottom: 2px solid #5684ee; */
              }

            }
          }
        }
        .right {
          margin-left: auto;
          display: flex;
          align-items: center;

          .create {
            svg {
              fill: #88a4ff;
            }
          }

          svg {
            width: 16px;
            height: 16px;
          }

          .actions {
            svg {
              width: 12px;
              height: 12px;
            }
          }
        }
      }

      &.fullscreen {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        /* > * {
          flex: 1 1 auto;
        } */
      }

      > .view {
        &.fullscreen {
          flex: 1 1 auto;
          position: relative;
        }
      }


    /* } */


    .addButton {
      ${hoverStyles}
      padding-left: 6px;
      padding-right: 6px;
      height: 34px;
      display: inline-flex;
      align-items: center;
      svg {
        fill: #bababa;
        margin-right: 9px;
      }
    }

    .entityName {
      font-size: 26px;
      font-weight: 600;
      display: block;
      cursor: text;

      > * {
        &:empty::before {
          content: 'Untitled';
          color: #373737; 
        }
        &:empty:focus::before {
          content: "";
        }
        
      }

      > *:focus {
        outline: none;
      }

      [contenteditable] {
        outline: none;
      }
    }

    .df0e3751-93ea-5acd-adef-afef57ef9b85 {
      .section.contents {
      /* max-height: 380px; */
      overflow: auto;
      .sectionTitle {
        display: flex;
        align-items: center;
        select {
          margin-left: 4px;
        }
        .checkboxes {
          margin-left: auto;
        }
      }
    }
      .section {

        

        .sectionTitle {
          color: rgba(55, 53, 47, 0.5);
          font-size: 14px;
          display: block;
          font-weight: 600;
          display: flex;
          align-items: center;
          /* border-bottom: 1px solid #ededec; */
          margin-bottom: 8px;
          padding-bottom: 4px;

          .hiddenIndicator {
            transform: rotate(-90deg);
          }


          > .sectionTitleTitle {
            display: flex;
            align-items: center;
            cursor: pointer;
          }


          svg {
            width: 14px;
            height: 14px;
            margin-right: 4px;

            fill: #37352f57;
          }


          &.hasToolbar {
            padding-bottom: 0;
          }
        }
        
        margin-bottom: 16px;
        padding-bottom: 8px;

        &.hidden {
          .sectionTitle {
            border-bottom: none;

          }
        }
      }

    }


    .attributes {
      .attribute {
        &.personal {
          .name {
            color: #5684ee;
            svg {
              fill: #5684ee;
            }
          }
        }
        &:not(:last-child) {
          margin-bottom: 4px;
        }
        display: flex;
        height: 29px;

        .name {
          flex: 0 0 auto;
          svg {
            width: 14px;
            height: 14px;
            margin-right: 4px;
            fill: #969696;
          }
          font-size: 12px;
          width: 160px;
          border-radius: 3px;
          padding: 0px 3px;
          display: flex;
          align-items: center;
          color: #969696;
          &:hover {
            background: #f3f3f3;
          }
        }

        .value {
          color: #4a4a4a;
          flex: 1 1 auto;
          > * {
            width: 100%;
          }

          border-radius: 3px;
          padding: 0px 3px;
          display: flex;
          align-items: center;
          &:hover {
            background: #f3f3f3;
          }
        }
      }
    }

    .entityRow {
      width: 100%;
      box-sizing: border-box;
      border-radius: 3px;
      height: 32px;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 0px 6px;
      &.active {
        background: #f3f3f3;
      }
      .name {
        margin-right: auto;
        display: flex;
        align-items: center;
        ${EntityPath} {
          margin-left: 4px;
          color: rgba(55, 53, 47, 0.5);
        }
      }

      ${EntityPath} {
        font-size: 8px;
      }
    }


    .blockRef {
      margin-left: 10px;
      padding-left: 8px;
      border-left: 2px solid #dbdbdb;
    }


    .group {
      margin-bottom: 8px;

      .groupName {
        font-size: 14px;
        font-weight: bold;
      }
    }

    .pageView {
      .top {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 200px;
      }
      .bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 200px;
        border-top: 1px solid #ddd;
      }
    }
  }
  `;

  static contextType = SystemContext;
  context: SystemContextProps;

  state = XInit(class {
    addingProperty /* = {
      addToRule: true
    } */

    newPropertyScope

    docState:any = {}
    blockState ={}

    // nameFocused = false
  })

  constructor(props) {
    super(props);
    if (this.props.state.includeDescendants === undefined) {
      this.props.state.includeDescendants = true;
    }
  }

  render(Container?) {
    const { id: n, state, onGraphClick, config } = this.props;
    const navigate = this.context.navigate;
    const entity = getEntityById(n);
    if (!entity) return 'Entity not found'

    const schema = entitySchema(n);

    XTouch(entity.tags);

    const tags = Object.keys(x(entity.tags) || {});

    const systemBlocks: any[] = [
      {
        type: BlockType2.systemProperties,
        key: BlockType2.systemProperties,
      },
      {
        type: BlockType2.systemEntities,
        key: BlockType2.systemEntities,
      },
      {
        type: BlockType2.systemReferences,
        key: BlockType2.systemReferences,
      },
      {
        type: BlockType2.systemBacklinks,
        key: BlockType2.systemBacklinks,
      },
    ]

    for (const attr of db.attributeTypes) {
      if (attr.reversePropRules?.find?.(r => r.viewType == '7aeb14b3-f80d-5273-a228-09b815ccff54')) {
        systemBlocks.push({
          type: BlockType2.attributeReferences,
          key: BlockType2.attributeReferences + attr._id,
          attribute: attr._id,
        });
      }
    }
  
    /*const stateTypes = db.stateTypes.filter(s => {
      if (config?.states) {
        return config?.states?.includes?.(s._id);        
      }
      else if (schema?.states) {
        const states = evaluate(schema.states);
        if (states?.includes?.(s._id)) {
          return true;
        }
        else {
          return false;
        }
      }
    });*/

    const systemPage = getSystemPage(systemBlocks);

    // for (const sysBlock of systemBlocks) {
    //   if (!systemPage.blocks.find(b => b.key === sysBlock.key)) {
    //     console.log('adding system block', sysBlock.key);
    //     systemPage.blocks.push(XObject.obj(sysBlock));
    //   }
    // }

    const schemaAttributes = (() => {
      if (schema?.attributes) {
        return evaluate(schema.attributes);
      }
      else {
        return [];
      }
    })();

    let theseAttributeTypes = db.attributeTypes.filter(a => {
      if (config?.attributes) {
        return config?.attributes?.includes?.(a._id);
      }
      else if (schemaAttributes.includes(a._id)) {
        return true;
      }
      return false;

    })

    if (entity.hasAttributes) {
      for (const id of entity.hasAttributes) {
        if (!theseAttributeTypes.find(a => a._id === id)) {
          theseAttributeTypes.push(db.attributeTypes.findById(id));
        }
      }
    }

    if (entity.attributes) {
      for (const id in entity.attributes) {
        if (!theseAttributeTypes.find(a => a?._id === id)) {
          theseAttributeTypes.push(db.attributeTypes.findById(id));
        }
      }
    }

    theseAttributeTypes = _.uniqBy(theseAttributeTypes, '_id');

    let queries = (entity.queries || []).concat(schema?.queries ? evaluate(schema.queries) : []);

    // const defaultContentHidden = !!queries.find(q => q.inline);

    // unique queries by .query
    queries = queries.filter((q, i) => {
      const index = queries.findIndex(q2 => q2.query === q.query);
      return index === i;
    });

  
    const user = db.users.findById(memoryAppState.user);
    const subscriptions = XObject.get(user, 'subscriptions', {});

    let allCount;
    let contents = (() => {
      let c;
      if (state.includeDescendants) {
        c = queryGraphBasic(this.props.id, false, false, !state.contentsFilters?.includeBackgroundEntities).map(e => e.entity);
        // const descendants = getAllEntities().find(e => e.path?.includes?.(n));
        // return descendants;
      }
      else {
        c = getEdgesForEntity(this.props.id).filter(e => {
          return e.directed && e.entities[0] == this.props.id;
        }).map(e => {
          return e.entities[1];
        }).filter(id => {
          const entity = getEntityById(id);
          if (entity.__deleted) return false;
        });
      }

      c = _.uniq(c);


      allCount = c.length;


      return c;
    })();

    const _renderAttribute = attrId => {
      return renderAttribute(entity, attrId, this.context);
    }


    const backlinks = getEntityEntityBacklinks(n);
    const references = getReferences(n);  

    const passedData: PassedData = {
      backlinks,
      references,
    }

    const renderEntitiesSection = ({
      defaultHidden,
      id,
      state,
      title,
      contents,
      customCheckboxes = undefined,
      after = undefined,
      underEntity = undefined,
      map = undefined,
      baseQuery = undefined,
      el = undefined
    }) => {
      let orgContents = contents;

      if (map) {
        contents = contents.map(map).filter(Boolean);
      }
      const filterBackgroundEntities = c => {
        const metaStates = entityMetaStates(c);
        const background = metaStates.find(id => {
          const metaState = defaultWorkspace().metaStates.find(m => m._id == id);
          if (metaState.backgroundState) return true;
        });
  
        if (state.onlyShowBackground) {
          if (!background) return false;
        }
        else if (background && !state.includeBackgroundEntities) return false;
  
  
        return true;
      }
      contents = contents.filter(filterBackgroundEntities);

      const allCount = contents.length;

      const types = {};
      for (const id of contents) {
        const entity = getEntityById(id);
        if (!entity) continue;
        const type = entity.type && db.entityTypes.findById(entity.type);
        if (type) {
          if (!types[type._id]) types[type._id] = 0;
          types[type._id]++;
        }
        else {
          if (!types['untyped']) types['untyped'] = 0;
          types['untyped']++;
        }
      }
  
      const orgSameType = (() => {
        // return true if all entities are of the same type
        let type;
        for (const id of contents) {
          const entity = getEntityById(id);
          if (!entity) continue;
          if (!type) {
            type = entity.type;
          }
          else if (type != entity.type) {
            return false;
          }
        }
        return type;
      })();

      if (state.contentsType) {
        contents = contents.filter(e => {
          if (state.contentsType == 'untyped') return !getEntityById(e).type;
  
          const entity = getEntityById(e);
          if (entity) {
            return entity.type === state.contentsType;
          }
          return false;
        });
      }

      const singleType = isId(state.contentsType) || orgSameType;

      const grouped = state.groupBy && groupById(orgContents, state.groupBy);


      const render = list => {
        return list?.map?.(cc => {
          let c;
          if (map) {
            c = map(cc);
          }
          else {
            c = cc;
          }

          if (!contents.includes(c)) return null;
          

          
          const entity = getEntityById(c);
          

          // const entity = getEntityById(c);
          // const schema = entitySchema(c, {});
          // let elements = renderEntityElements(c);
          if (!entity) return (
            <div className="entityRow" key={c}
            onContextMenu={e => {
              e.preventDefault();
              showContextMenu(e, [
                {
                  text: 'Delete edge',
                  onClick: () => {
                    const edge = getAllEdges().find(e => e.entities.includes(n) && e.entities.includes(c));
                    if (edge) deleteEdge(edge._id);
                  }
                }
              ]);
            }}

            >
              <span className="name">
                {c}
              </span>
            </div>
          )
          
          return (
            <div key={c}>
              <EntityRow id={c} parent={n} path={this.props.id} el={el?.(cc)} />
              {underEntity?.(cc)}
            </div>
          );
        }).filter(Boolean);
      }

      const hidden = state.hidden ?? defaultHidden;

      return (
        <>
          <div className={classNames('section contents', {
            hidden: hidden,
          })}>
            <span
              className="sectionTitle"
              onContextMenu={e => {
                e.preventDefault();
                showContextMenu(e, [
                  {
                    text: 'Create query',
                    onClick: () => {
                      createQuery([
                        QUERY,
                        baseQuery.concat([
                          state.contentsType && query_entityType(state.contentsType),
                        ]),
                      ], ['58c22695-b585-5f44-93f9-1fd1e135f2e5', n]);
                    }
                  },
                  {
                    text: 'Create relative query',
                    onClick: () => {
                      const q = createQuery([
                        QUERY,
                        baseQuery.concat([
                          state.contentsType && query_entityType(state.contentsType),
                        ]),
                      ], ['58c22695-b585-5f44-93f9-1fd1e135f2e5', n], null, true);

                      XObject.push(entity, 'queries', XObject.obj({
                        query: q._id,
                      }));
                    }
                  },
                ]);
              }}
            >
              {hidden && <Svg name="chevron" className="hiddenIndicator" />}
              <span
                className="sectionTitleTitle"
                onClick={() => {
                  console.log(id);
                  state.hidden = !hidden;
                }}
              >{title}</span>
              
              {orgSameType && (
                <>
                  <select>
                    <option>{pluralize(db.entityTypes.findById(orgSameType).name)} ({allCount})</option>
                  </select>
                </>
              )}

              {!orgSameType && (
                <>
                              <select value={state.contentsType || ''}
                onChange={e => {
                  state.contentsType = e.target.value;
                  delete state.groupBy;
                }}
              >
                <option value="">All ({allCount})</option>
                {Object.keys(types).filter(t => t != 'untyped').map(t => (
                  <option key={t} value={t}>{pluralize(db.entityTypes.findById(t).name)} ({types[t]})</option>
                ))}
                {types['untyped'] > 0 && <option value="untyped">Untyped ({types['untyped']})</option>}
              </select>
                </>
              )}

              {singleType && (() => {
                const schema = entitySchema(contents[0]);
                if (!schema) return null;
                const attributes = schema.attributes && evaluate(schema.attributes);

                return (
                  <>
                    <select
                      value={state.groupBy || ''}
                      onChange={e => {
                        state.groupBy = e.target.value;
                      }}
                    >
                      <option value="">No Grouping</option>
                      {attributes?.map?.(a => {
                        const attr = db.attributeTypes.findById(a);
                        if (!attr) return null;
                        return (
                          <option key={a} value={a}>{attr.name}</option>
                        )
                      })}
                    </select>
                  </>
                )
              })()}
              


              <div className="checkboxes">
                {customCheckboxes?.()}
                <input title="Include Background Entities" type="checkbox" checked={state.includeBackgroundEntities} onChange={e => {
                  state.includeBackgroundEntities = e.target.checked;
                }} />
                <input title="Only Show Background Entities" type="checkbox" checked={state.onlyShowBackground} onChange={e => {
                  state.onlyShowBackground = e.target.checked;
                }} />
              </div>
            </span>            
            {!hidden && (
              <>
                {grouped && (
                  <>
                    {grouped.map(({ key, group, entities }) => {
                      const r = render(entities);
                      if (!r?.length) return;
                      return (
                        <div key={group} className="group">
                          <div className="groupName">{group == 'None' ? 'None' : renderAttributeValue(state.groupBy, key, group)}</div>
                          {r}
                        </div>
                      )
                    })}
                  </>
                )}
                {!grouped && render(orgContents)}
                {after?.()}
              </>
            )}
          </div>
        </>
      );
    }

    const add = () => {
      const newEntity = XObject.obj({
        name: '',
        type: undefined,
      });
      if (state.contentsFilters?.contentsType && state.contentsFilters?.contentsType != 'untyped') newEntity.type = state.contentsFilters?.contentsType;
      createEntity(newEntity, n);
      pushEdge(XObject.obj({
        directed: true,
        entities: [n, newEntity._id],
      }));
      navigate?.({
        type: 'entity',
        id: newEntity._id,
      });

    }

    /*const tree = getScopeTree({
      type: ObjectType.entity,
      id: n,
    })*/

    let attrs = [];

    if (entity.type) {
      attrs = attributesInScope({
        type: ObjectType.type,
        id: entity.type,
      })
    }

    attrs = attrs.concat(attributesInScope({
      id: n,
      type: ObjectType.entity,
    }));

    attrs = _.uniq(attrs);

    

    const matchedPages = db.pages.filter(p => p.match?.[0] == '6a1705b5-b274-5578-800d-192f43d77fcb' || entity.type && p.match?.[1] == entity.type);

    if (!entity.pageIndex) {
      entity.pageIndex = X(matchedPages.map(p => p._id));
    }
    else {
      for (const page of matchedPages) {
        if (!entity.pageIndex.includes(page._id)) {
          entity.pageIndex.push(page._id);
        }
      }
    }

    const type = entity.type && db.entityTypes.findById(entity.type);

    const key = md5((type?.pages || []).map(p => p._id).join(''));

    const entityInspectTabStates = XObject.get(appState, 'entityInspectTabStates', {});


    const getCurrentView = () => entityInspectTabStates[key];
    const setCurrentView = (view) => entityInspectTabStates[key] =view;


    if (!getCurrentView()) {
      setCurrentView(systemPage._id);
    }


    const activeView = getCurrentView() && schema?.views?.content?.find?.(v => v._id == getCurrentView());

    const activePage = getCurrentView() && entity.pages?.find?.(p => p._id == getCurrentView()) || db.pages.findById(getCurrentView());
    


    const activeTypePage = type?.pages?.find?.(p => p._id == getCurrentView());



    // const reverseLineProps = getReverseLineProps(passedData);

    const fullscreen = activeView && mapStructure(activeView)?.fullscreen?.content || getCurrentView() == 'c9c20aa3-b226-5d27-ad0d-d5f04535ca96';

    const renderAttributes = (theseAttributeTypes: {
      _id
      type?
      icon?
      name?
    }[], onAdd, remove, state: {
      addingProperty: boolean;
      newPropertyScope
    }, isPersonal?, addLabel?, reverse?) => {
      return (
        <div className="attributes section">
          <InsertionCont
            onInsert={i => onAdd(i)}
            tag="div"
            orientation="vertical"
          >
            {theseAttributeTypes.filter(Boolean).map(t => {
              if (t._id == schema?.titleAttribute?.content) return null;
              const tt = attributeTypeDefs.find(tt => tt.value == t.type);
              return (
                <div key={t._id} className={classNames("attribute", {
                  personal: isPersonal?.(t),
                })}>
                  <WithContextAction
                    mobileInvoke="press"
                    menu={[
                      {
                        text: 'Clear type',
                        onClick: () => {
                          delete t.type;
                        }
                      },
                      {
                        text: 'Remove',
                        onClick: () => {
                          remove(t);
                        }
                      },
                      {
                        text: 'Delete value',
                        onClick: () => {
                          delete entity.attributes[t._id];
                        }
                      },
                      {
                        text: 'Edit',
                        onClick: () => {
                          explicitInspectObj({
                            type: ObjectType.attribute,
                            id: t._id,
                          });
                        }
                      },
                      {
                        text: 'Copy ID',
                        onClick: () => {
                          navigator.clipboard.writeText(t._id);
                        }
                      },
                      entity.type && {
                        text: 'Add to type',
                        onClick: () => {
                          addAttrToSchema(n, entity.type, t._id);
                        }
                      },
                      {
                        text: 'Debug',
                        onClick: () => {
                          console.log(x(entity.attributes[t._id]))
                        },
                      }
                    ]}
                  >
                    <span className="name">
                      {tt?.icon && <Svg name={tt.icon} />}
                      {t.name}
                    </span>
                  </WithContextAction>
                  <div className="value">
                    {!t.type && (
                      <>
                        <DraftSelect
                          inline
                          id='asdfasdfsdf'
                          options={attributeTypeDefs}
                          onSelect={value => {
                            t.type = value;
                          }}
                        />
                      </>
                    )}
                    {t.type && (() => {
                      return _renderAttribute(t._id);
                    })()}
                  </div>
                </div>
              );
            })}
          </InsertionCont>
          {reverse && (() => {
            const reverseLineProps = getReverseLineProps(passedData);

            return (
              Object.keys(reverseLineProps).map(id => {
                const rule = reverseLineProps[id].rule;
                const entities = reverseLineProps[id].entities;

                return (
                  <div key={id} className="attribute"
                  >
                    <span className="name"
                      onContextMenu={e => {
                        showContextMenu(e, [
                          {
                            text: 'Edit',
                            onClick: () => {
                              inspectObj({
                                type: ObjectType.attribute,
                                id: id,
                              });    
                            }
                          }
                        ])
                      }}
                    >{rule.title}</span>
                    <div className="value"><div>{entities.map(id => <Tag
                      key={id}  
                      text={entityDisplayName(id)}
                      _onClick={() => {
                        this.context?.navigate?.({
                          type: 'entity',
                          id,
                        })
                      }}
                    />)}</div></div>
                  </div>
                )
              })
            )

          })()}
          {isMobile() && (
            <NotionButton text={addLabel || "Add a property"} onClick={e => {
              onAdd();
            }} />
          )}
        </div>
      )
    }

    let nameChangeTimer;
    const parent = getGraphParent(null, this.props.id);


    return (
      <Container data-value-point={config?.valuePoint}>
        <div className={classNames("top", {
          fullscreen,
        })}>
          <div className="nameAndType">
            <span className="entityName">
              {schema?.titleAttribute && _renderAttribute(schema.titleAttribute.content)}
              {!schema?.titleAttribute && (
                <MetaStatesWrapper className={metaStatesClasses(n)}>
                  {entityComputedName(entity._id) ? entityDisplayView(entity._id) : <EntityNameEditor id={entity._id}
                    _onFocus={() => {
                      jQuery(ReactDOM.findDOMNode(this)).addClass('nameFocused');
                    }}
                    _onBlur={() => {
                      jQuery(ReactDOM.findDOMNode(this)).removeClass('nameFocused');
                    }}
                    _onMouseOver={() => {
                      jQuery(ReactDOM.findDOMNode(this)).addClass('nameOver');
                    }}
                    _onMouseOut={() => {
                      jQuery(ReactDOM.findDOMNode(this)).removeClass('nameOver');
                    }}
                  />}
                </MetaStatesWrapper>
              )}
            </span>
            <span className="right">
              <div className="typeAndOther">
                {schema?.showTimestamp && entity.meta?.creation?.timestamp && (
                  <span className="timestamp">{entity.meta.creation.timestamp.format('{Month} {day}')}</span>
                )}
                {entity.type ? (
                  <Tag
                    text={
                      <TypeEditor
                        scopeObj={{
                          id: n,
                          type: ObjectType.entity,
                        }}
                        right
                        value={entity.type}
                        onChange={id => {
                          entity.type = id;
                        }}
                      />
                    }
                  />
                ) : (
                  <TypeEditor
                    right
                    value={entity.type}
                    onChange={id => {
                      entity.type = id;
                    }}
                    scopeObj={{
                      id: n,
                      type: ObjectType.entity,
                    }}
                  />
                )}
                {schema?.showCreator && entity.meta?.creation?.user && <UserBadge user={entity.meta?.creation?.user} />}
                {(_.isString(entity.space)) ?
                  <ObjectDisplay
                    obj={{
                      type: ObjectType.space,
                      id: entity.space,
                    }}
                    showPath
                  /> : 
                  entity.space && <ObjectDisplay obj={entity.space} showPath />
                }
              </div>
              <div className="tags">
                {tags.map(id => {

                  return <Tag key={id} text={db.tags.findById(id).name}
                  
                  _onClick={() => {
                    delete entity.tags[id];
                  }}/>
                })}
                {/*  */}
              </div>
            </span>
          </div>
          <div
            className="parent"
            onClick={() => {
              this.context?.navigate?.({
                type: 'entity',
                id: parent,
              })
            }}
          >
            {parent && (
              <>
                <ObjectDisplay obj={{
                  type: ObjectType.entity,
                  id: parent,
                }} />
              </>
            )}
          </div>

          {!this.props.onlyTop && (
            <>
                      <div className="toolBar">
            <InsertionCont
            disable
              className="views"
              onInsert={() => {
                const page = XObject.obj({});
                XObject.push(entity, 'pages', page);
                XObject.push(entity, 'pageIndex', page._id);
              }}
              orientation="horizontal"
              tag="div"
            >
              {entity?.pageIndex?.map?.((id, i) => {
                const page = entity.pages?.find?.(p => p._id === id) || db.pages.findById(id);
                if (!page) return;
                const personal = entity.pages?.find?.(p => p._id === id);

                return (
                  <div key={id} className={classNames("view entity noText page", {
                    active: getCurrentView() == id,
                    personal,
                  })}

                  onContextMenu={e => {
                    if (page.key == 'system') return;
                    e.preventDefault();
                    showContextMenu(e, [
                      {
                        text: 'Rename',
                        onClick: async () => {
                          const name = await showPrompt('Rename page', page.name);
                          if (!_.isNil(name)) {
                            page.name = name;
                          }
                        }
                      },
                      !page.match && {
                        text: 'Global match',
                        onClick: () => {
                          page.match = ['6a1705b5-b274-5578-800d-192f43d77fcb'];
                          entity.pages.splice(entity.pages.findIndex(p => p._id == page._id), 1);
                          db.pages.push(page);
            
                        }
                      },
                      !page.match && {
                        text: 'Type match',
                        onClick: () => {
                          page.match = ['2344e714-fe21-5df6-8423-ae3adf33083f', entity.type];
                          entity.pages.splice(entity.pages.findIndex(p => p._id == page._id), 1);
                          db.pages.push(page);
            
                        }
                      },
                      {
                        text: 'Delete',
                        onClick: () => {
                          if (personal) {
                            entity.pages.splice(entity.pages.findIndex(p => p._id == page._id), 1);
                            entity.pageIndex.splice(i, 1);
                          }
                          else {
                            db.pages.splice(db.pages.indexOf(page), 1);
                            entity.pageIndex.splice(i, 1);
                          }
                        }
                      }
                    ]);
                  }}
                  onClick={() => {
                    setCurrentView(id);
                  }}
                  >{page.name ? page.name : <Svg name="icons8-page" />}</div>
    
                );
                
              })}
              <div className={classNames("view entity noText", {
                active: getCurrentView() == 'df0e3751-93ea-5acd-adef-afef57ef9b85'
              })}
              onClick={() => {
                setCurrentView('df0e3751-93ea-5acd-adef-afef57ef9b85');
              }}
              ><Svg name="tesseract" /></div>
              {schema?.views?.content?.map?.(v => {
                const mapped = mapStructure(v);
                return (
                  <div key={v._id} className={classNames("view entity", {
                    active: getCurrentView() == v._id,
                  })} onClick={() => {
                    setCurrentView(v._id);
                  }}>{mapped?.name?.content}</div>
                )
              })}
              {type?.pages?.map?.(p => {
                return (
                  <div key={p._id} className={classNames("view entity", {
                    active: getCurrentView() == p._id,
                  })} onClick={() => {
                    setCurrentView(p._id);
                  }}>{p.title}</div>
                )
              })}
            </InsertionCont>
            <div className="right">
              
              <NotionButton
                className="create"
                img="icons8-create (2)"
                onClick={() => {
                  add();
                }}
              />
              {/* <span className="add" onClick={() => {
                add();
              }}>Add</span> */}
            </div>
          </div>

          {getCurrentView() == 'df0e3751-93ea-5acd-adef-afef57ef9b85' && (
            <div className="df0e3751-93ea-5acd-adef-afef57ef9b85">
              {
                renderAttributes(
                  theseAttributeTypes,
                  i => {
                    this.state.addingProperty = true;
                  },
                  (t) => {
                    if (schema?.match) sendSignal(schema.match, REMOVE_ATTRIBUTE_SIGNAL, t._id);
                    if (entity.hasAttributes) {
                      entity.hasAttributes.splice(entity.hasAttributes.indexOf(t._id), 1);
                    }
                  },
                  {
                    addingProperty: false,
                    newPropertyScope: null,
                  },
                  (t) => !schemaAttributes.includes(t._id),
                  null,
                  false,
                )
              }
              {this.state.addingProperty && (
                <>
                  <AttributeAdder
                    close={() => {
                      delete this.state.addingProperty;
                    }}
                    attrs={attrs}
                    scope={entity.type && {
                      type: ObjectType.type,
                      id: entity.type,
                    }}
                    onAdd={async (value, addToSchema) => {
                      if (addToSchema) {
                        addAttrToSchema(n, entity.type, value);
                      }
                      else {
                        XObject.push(entity, 'hasAttributes', value);
                      }
                      delete this.state.addingProperty;
                    }}
                  />

                </>
              )}

              {renderEntitiesSection({
                defaultHidden: false,//defaultContentHidden,
                id: 'contents',
                state: XObject.get(state, 'contentsFilters', {
                  includeDescendants: true,
                }),
                contents: (() => {

                  return contents//.filter(e => !touched[e]);
                })(),
                title: <><Svg name="tesseract" /> Entities</>,
                customCheckboxes: () => {
                  return                 <input title="Include Descendants" type="checkbox" checked={state.includeDescendants} onChange={e => {
                    state.includeDescendants = e.target.checked;
                  }} />

                },
                after: () => {
                  // return             <NotionButton text="Add entity" onClick={() => {
                  //   add();
                  // }} />
      
                },
                baseQuery: [
                  state.includeDescendants ?
                    query_entityDescendants(n) :
                    query_entityChildren(n),
                ]
              })}

              {references.length > 0 && renderEntitiesSection({
                defaultHidden: false,
                id: 'references',
                state: XObject.get(state, 'referencesFilters', {}),
                contents: references,
                map: r => r.sourceEntity,
                title: <><Svg name="tesseract" /> Referenced by</>,
                baseQuery: [
                  query_entityReferences(n),
                ],
                el: c => db.attributeTypes.findById(c.attribute).name,
              })}

              {backlinks.length > 0 && renderEntitiesSection({
                defaultHidden: false,
                id: 'backlinks',
                state: XObject.get(state, 'backlinksFilters', {}),
                contents: backlinks,
                title: <><Svg name="tesseract" /> Backlinks</>,
                // map: b => b.sourceEntity,
                underEntity: c => {
                  return;
                  const entity = getEntityById(c.sourceEntity);
                  const doc = entity.documents.find(d => d._id == c.document);
                  const block = findBlock(doc.content, c.block);
                  return (
                    <div className="blockRef">
                      <NotionBlockWrapper
                        block={block}
                        configId={doc.config}
                        noChildren
                      />
                    </div>
                  );
                }
              })}

              <div className="section">
                <div className="sectionTitle">Activity <input type="checkbox" checked={!!subscriptions[n]} onChange={e => {
                  if (e.target.checked) {
                    subscriptions[n] = new Date();
                  }
                  else {
                    delete subscriptions[n];
                  }
                }} /></div>
                <EntityActivity id={n} />
              </div>
            </div>
          )}

          {activePage && <EntityBlocks page={activePage} entity={entity} renderAttributes={renderAttributes} passedData={passedData} />}

          {activeView && (() => {
            const mapped = mapStructure(activeView);
            // console.log(mapped);

            return (
              <div className={classNames('view', {
                fullscreen: mapped.fullscreen?.content,
              })}>
                {render(mapped.content, {
                  [$EntityTemplate.Entity]: n
                }, RenderType.full, XObject.get(state, activeView._id, {}))}
              </div>
            );
            
          })()}

          {activeTypePage && (() => {
            const vp = getValuePoint(activeTypePage.valuePoint);
            const compiledVp = execute(vp._id);
            return <GlueView
              id={vp._id}
              args={{
                [vp.parameters[0]._id]: n,
              }}
              state={XObject.get(state, activeTypePage._id, {})}
            />
          })()}
        
            </>
          )}


        </div>
      </Container>
    );
  }
}
