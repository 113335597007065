import React, { Component } from "react";
import ReactDOMClient from "react-dom/client";
import jQuery from "jquery";
import cx from "classnames";
import { component, styled } from "../../component";
import { showContextMenu, SortableCont, SortableEl, SortHandle } from "../../helpers";
import { ColumnManager, RowManager, Column, Row } from "./ColumnManager";
import { plusImage } from "./icons";
import { isMobile } from "../../isMobile";
import { linkColor } from "../linkColor";
import { Svg } from "../Svg";
import { presentEditComp } from "./presentEditComp";

@component
export class NotionDatabase extends Component<{
  columnManager: ColumnManager
  rowManager: RowManager
  activeRowId?
  onClickRow?
  dataValuePoint?
  createDataValuePoint?
  onClickAdd?
  onClickAddCol?
  onRightClickCol?
  onEditCol?
  cellArgs?
  groupBy?
}> {
  static styles = styled.div` 
    overflow: auto;
    width: 100%;
    box-sizing: border-box;
    position: relative;

    .tableHeader {
      position: sticky;
      top: 0;
      display: flex;
      border-bottom: 1px solid #efefef;
      color: #969696;
      font-size: 10px;

      .cell {
        svg {
          width: 14px;
          height: 14px;
          fill: #969696;
        }
        flex: 0 0 auto;
        .cont {
          /* height: 32px; */
          /* line-height: 32px; */
          display: flex;
          align-items: center;
          padding-top: 8px;
          padding-bottom: 3px;
        }

        position: relative;
        box-sizing: border-box;
        padding-left: 8px;
        padding-right: 8px;
        /* border-right: 1px solid rgba(55, 53, 47, 0.09); */
        white-space: nowrap;
        overflow: hidden;

        &:hover {
          /* background: #f3f3f3; */
        }
        svg {
          margin-right: 3px;
        }

        .resizeHandle {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 0px;
          opacity: 0;
          transition: opacity 200ms ease-out 0s;
          &:hover {
            opacity: 1;
          }
          div {
            width: 3px;
            margin-left: -3px;
            margin-top: -1px;
            height: 34px;
            transition: background 200ms ease-out 0s;
            background: #eaeaeacc;
            cursor: col-resize;
          }
        }
      }

      .addCol {
        width: 32px;
        /* height: 32px; */
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .row {
      position: relative;
      display: flex;
      border-bottom: 1px solid #efefef;

      &.active {
        background: #eceff9;
      }

      &:not(:hover) {
        .actions {
          opacity: 0;
        }
      }

      .actions {
        position: absolute;
        right: 100%;
        top: 8px;
        margin-right: 8px;
      }

      .cell {
        .path {
          display: block;
          font-size: 9px;
          color: gray;
          line-height: 13px;
          .comp {
            &:not(:last-child) {
              &:after {
                content: ' / ';
              }
            }
          }
        }
        flex: 0 0 auto;
        box-sizing: border-box;
        padding: 5px;
        min-height: 24px;
        position: relative;


        &.clickToOpen {
          /* text-decoration: underline; */
          cursor: pointer;
          /* color: ${linkColor}; */
        }


        .open {
          position: absolute;
          right: 4px;
          top: 0;
          bottom: 0;
          margin: auto;
          user-select: none;
          
          transition: background 20ms ease-in 0s;
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          border-radius: 4px;
          height: 19px;
          padding: 0px 6px;
          background: #f2f2f2;

          font-size: 8px;
          font-weight: 500;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          color: #bcbcbc;
          margin-left: 4px;

          background: #f2f2f2;

        }
      }

      &:hover {
        background-color: #f5f5f5;
      }
    }

    &:not(.mobile) {
      .cell {

        &:not(:hover) {
          .open {
            opacity: 0;
          }
        }

      }
    }

    .addRow {
      box-sizing: border-box;
      svg {
            fill: #bababa;
          }

      svg {
        margin-right: 6px;
        width: 16px;
        height: 16px;
      }
      display: flex;
      height: 24px;
      align-items: center;
      color: #bababa;
      padding: 0 4px;
      cursor: pointer;

      font-size: 12px;
      &:hover {
        /* background: #f3f3f3; */
      }
    }

    .grouped {
      border-bottom: 1px solid rgb(239, 239, 239);
      display: flex;
      .groupValue {
        flex: 0 0 auto;
      }

      .row:last-child {
        border-bottom: none;
      }
    }
  `;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    jQuery(window).mousemove((e) => {
      if (this.resizingColumn) {
        const column = jQuery('[data-column-id="' + this.resizingColumn + '"]')[0];
        const { x, width } = column.getBoundingClientRect();
        const newWidth = e.clientX - x;
        if (newWidth > 0) {
          this.props.columnManager.columns().find(c => c.id() == this.resizingColumn).setWidth(newWidth);
        }
      }
    });

    jQuery(window).mouseup((e) => {
      if (this.resizingColumn) {
        this.resizingColumn = null;
        jQuery('html').removeClass('cursor-col-resize');
      }
    });
  }
  resizingColumn
  editComp

  presentEditComp(frame, col: Column, row: Row) {
    const cell = col.cellObj(row);
    presentEditComp({
      title: col.title(),
      frame,
      cell,
      get: () => {
        return row.value(col.id(), cell.defaultValue);
      },
      set: value => {
        row.setValue(col.id(), value);
      },
      state: null,
    })
  }
  
  render(Container?) {
    const columns = this.props.columnManager.columns()
    const rows = this.props.rowManager.rows();
    let totalWidth = 0;
    for (const column of columns) {
      totalWidth += column.width();
    }

    if (this.props.columnManager.canAddDeleteColumns) {
      totalWidth += 32;
    }

    const renderRows = (rows, columns, add) => {
      let totalWidth = 0;
      for (const column of columns) {
        totalWidth += column.width();
      }
  
      return (
        <>
          {rows.map((row) => (
            <div
              style={{
                width: totalWidth + 'px'
              }}
              className={cx('row', {
                active: this.props.activeRowId === row.id()
              })}
              key={row.id()}
              onContextMenu={e => {
                e.preventDefault();
                showContextMenu(e, [
                  {
                    text: 'Delete',
                    onClick: () => {
                      this.props.rowManager.deleteRow(row.id());
                    }
                  }
                ]);
              }}
            >
              {/* <div className="actions">
                <Svg name="grip"
                />
              </div> */}
              {columns.map((column) => {
                const cell = column.cellObj(row);
                const d = cell?.renderValue?.(row.value(column.id(), undefined, 'display'), {
                  args: this.props.cellArgs,
                  row,
                  setValue: value => {
                    console.log(value);
                    row.setValue(column.id(), value);
                  }
                });
                const openRef = React.createRef<HTMLDivElement>();
                return (
                  <div
                    key={column.id()}
                    className={cx('cell', {
                      clickToOpen: isMobile() && cell?.showOpenButton?.(),
                    })}
                    style={{ width: column.width() + 'px' }}
                    onContextMenu={e => {
                      e.preventDefault();
                    }}
                    onMouseDown={(e) => {
                      if (isMobile() && cell?.showOpenButton?.()) {
                        e.preventDefault();
                        this.props.onClickRow?.(row.id());
                        return;
                      }
                      if (e.button == 2) return;
                      if (e.target == openRef?.current || cell.clickable() && e.target != e.currentTarget) {
                        return;
                      }
                      e.preventDefault();
                      if (!cell.useEditor()) return;
                      this.presentEditComp((e.currentTarget as any).getBoundingClientRect(), column, row);
                    }}
                  >
                    {d}
                    {!isMobile() && cell?.showOpenButton?.() && (
                      <span
                        ref={openRef}
                        className="open"
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          this.props.onClickRow?.(row.id());
                        }}
                      >
                        Open
                      </span>
                    )}
                  </div>
                );
              })}
            </div>
          ))}
        {this.props.onClickAdd && (
          <div
            style={{
              width: totalWidth + 'px'
            }}
            className="addRow"
            data-value-point={this.props.createDataValuePoint}
            onClick={() => {
              add();
            }}
          >
            <Svg name="icons8-create (2)" /> Add new row
          </div>
        )}
        </>
      )
    }

    const renderBody = () => {
      if (this.props.groupBy) {
        const groups = {};
        for (const row of rows) {
          const value = row.value(this.props.groupBy);
          if (value) {
            if (!groups[value]) groups[value] = [];

            groups[value].push(row);
          }
          else {
            if (!groups['NULL']) groups['NULL'] = [];

            groups['NULL'].push(row);

          }
        }

        // for (const value of) {
        //   const rows = groups[value];

        // }

        return Object.keys(groups).map(value => {
          
          return (
            <div key={value} className="grouped">
              <div className="groupValue"
               style={{ width: columns[0].width() + 'px' }}
              >
                {value !== 'NULL' && columns[0].cellObj(groups[value][0]).renderValue(value, {
                  args: this.props.cellArgs,
                })}
              </div>
              <div>
                {renderRows(groups[value], columns.slice(1), () => {
                  this.props.onClickAdd?.({
                    [this.props.groupBy]: value
                  });
                })}
              </div>
            </div>
          )
        });
      }
      else {
        return renderRows(rows, columns, () => {
          this.props.onClickAdd?.();
        });
      }
    }

    return (
      <Container
        className={cx({ mobile: isMobile() })}
        data-value-point={this.props.dataValuePoint}
      >
        <SortableCont
          style={{
            width: totalWidth + 'px'
          }}
          className="tableHeader"
          axis="x"
          distance={isMobile() ? undefined : 10}
          pressDelay={isMobile() ? 200 : 0}
          useDragHandle
          onSortEnd={(sortEnd) => {
            this.props.columnManager.move(sortEnd.oldIndex, sortEnd.newIndex);
          }}
        >
          {columns.map((column, i) => (
            <SortableEl key={column.id()} index={i}>
              <div
                className="cell"
                onContextMenu={e => {
                  e.preventDefault();
                  showContextMenu(e, [
                    {
                      text: 'Delete',
                      onClick: () => {
                        this.props.columnManager.deleteColumn(column.id());
                      }
                    },
                    {
                      text: 'Edit',
                      onClick: () => {
                        this.props.onEditCol(column);
                      }
                    },
                    {
                      text: 'Rename',
                      onClick: () => {
                        const name= prompt('Enter new name', column.title());
                        if (name) {
                          column.setTitle(name);
                        }
                      }
                    },
                  ]);
                }}
                data-column-id={column.id()} style={{ width: column.width() + 'px' }}
              >
                <SortHandle>
                  <div className="cont">
                    {column.getIcon()} {column.title()}
                  </div>
                </SortHandle>

                <div className="resizeHandle"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.resizingColumn = column.id();
                    jQuery('html').addClass('cursor-col-resize');
                  }}
                  ><div />
                </div>
                
              </div>
            </SortableEl>
          ))}
          {this.props.onClickAddCol && <div className="addCol"
            onClick={e => {
              this.props.onClickAddCol?.(e);
            }}
          >
            {plusImage}
          </div>}
        </SortableCont>
        {renderBody()}

      </Container>
    )
  }
}
