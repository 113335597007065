import { Component } from "react";
import { component, styled } from "./component";
import { XInit } from "./XObject";
import Sugar from 'sugar';
import classNames from "classnames";

@component
export class Calendar extends Component<{
  renderDay?
}> {
  static styles = styled.div`
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */


    > .header {
      .month-year {
        margin: 10px 0;
        font-size: 1.5em;
      }

      .right {
        margin-left: auto;
      }
    }

    .body {
      height: 952px;
    }

    &.month {
      .body {
        /* display: flex; */
        /* flex-direction: column; */
        /* align-items: center; */

      }
      .week {
        display: flex;
        justify-content: space-around;
        width: ${(100 + 2) * 7}px;
      }

      .header.week {
        .day {
          height: 30px;
          padding-top: 0;
        }
      }

      .day {
        width: 100px;
        height: 150px;
        padding-top: 29px;
        box-sizing: border-box;
        margin: 1px;
        border: 1px solid #ddd;
        position: relative;
        flex: 0 0 auto;

        &.today {
          .number {
            font-weight: bold;
          }
        }
        .number {
          position: absolute;
          top: 5px;
          right: 5px;
        }

        .row {
          cursor: pointer;
          position: relative;
          z-index: 1;
          border: 1px solid black;
          box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
          border-radius: 5px;
          white-space: nowrap;
          overflow: hidden;
          background-color: white;
          padding: 0 8px;
          box-sizing: border-box;

          &.left, &.middle {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 0;
          }
          &.right, &.middle {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 0;
          }
        }
      }

      .day.adjacent-month {
        color: #aaa;
      }
    }



  `;

  state = XInit(class {
    year = new Date().getFullYear();
    month = new Date().getMonth();
  });

  render(Container?) {
    const width = 100 + 1;
    const { year, month } = this.state;

    const render = () => {
      const daysInPreviousMonth = new Date(year, month, 0).getDate();
      const daysInCurrentMonth = new Date(year, month + 1, 0).getDate();
  
      const firstDayOfCurrentMonth = new Date(year, month, 1).getDay();
      const lastDayOfCurrentMonth = new Date(year, month + 1, 0).getDay();
  
      let leadingDays = [];
      for (let i = 0; i < firstDayOfCurrentMonth; i++) {
        leadingDays.push(<div key={`pl${i}`} className="day adjacent-month"><span className="number">{daysInPreviousMonth - firstDayOfCurrentMonth + i + 1}</span></div>);
      }
  
      let trailingDays = [];
      for (let i = 1; i < 7 - lastDayOfCurrentMonth; i++) {
        trailingDays.push(<div key={`nl${i}`} className="day adjacent-month"><span className="number">{i}</span></div>);
      }
  

      
      const daysInCurrentMonthArray = [];
      for (let d = 1; d <= daysInCurrentMonth; d++) {
        const date = Sugar.Date.create(`${year}-${month + 1}-${d}`).beginningOfDay();



        daysInCurrentMonthArray.push(
          <div key={`c${d}`} className={classNames("day", {
            today: date.isToday(),
          })}>
            <span className="number">{d}</span>
            <div>
              {this.props.renderDay?.(date)}
            </div>
          </div>
        );
      }
  
      const totalSlots = [...leadingDays, ...daysInCurrentMonthArray, ...trailingDays];
      const rows = [];
      let cells = [];
  
      totalSlots.forEach((row, i) => {
        if ((i % 7) !== 0 || i === 0) {
          cells.push(row);
        } else {
          rows.push(<div key={`w${rows.length}`} className="week">{cells}</div>);
          cells = [];
          cells.push(row);
        }
        if (i === totalSlots.length - 1) {
          rows.push(<div key={`w${rows.length}`} className="week">{cells}</div>);
        }
      });
  
      return (
        <>
          <div className="header week">
            <div className="day">S</div>
            <div className="day">M</div>
            <div className="day">T</div>
            <div className="day">W</div>
            <div className="day">T</div>
            <div className="day">F</div>
            <div className="day">S</div>
          </div>
          {rows}   
        </>
      );
    }

    return (
      <Container className="month">
        <div className="header">
          <div className="month-year">
            {new Date(year, month).toLocaleString('default', { month: 'long' })} {year}
          </div>
        </div>
        <div className="body">
          {render()}
        </div>
      </Container>
    );
  }
}
