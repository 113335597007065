import { getParameter, getStructureById, getStructureParameter, getValuePoint, ReferenceType, structuresMap } from '../main';
import { substitute } from '../../shorthandEditor/substitute';
import { presentMenu } from '../../components/richTextEditor/MenuPresenter';
import { Type } from '../../richTextHelpers';
import { contextVars } from '../typeRegistering';
import { typeRegistry } from '../typeRegistry.1';
import { ValueType } from '../ValueType';
import { scriptComponents } from '../../scriptComponents/registerScriptComponent';

export const additionalTypes = () => {
  return {
    scriptComponent: {
      type: Type.atomic,
      html: data => {
        const sc = scriptComponents[data];
        return `<span class="--badge--" contenteditable="false" data-type="scriptComponent" data-scriptComponent-data="${btoa(JSON.stringify(data))}">${sc.name}</span>`;
      },
      text: id => {
        return `_$12a1ad37-eeea-53a0-953e-7c5bc705befb:${id}$_`;
      },
      part: el => {
        const data = JSON.parse(atob(el.getAttribute('data-scriptComponent-data')));
        return [data, 'scriptComponent'];
      },
    },
    glue: {
      type: Type.atomic,
      html: (data) => {
        let display;
        if (data.type == ReferenceType.Value) {
          try {
            display = getValuePoint(data.id).name;
          }
          catch (e) {
            display = 'ERROR';
          }
        }
        else if (data.type == ReferenceType.Parameter) {
          display = getParameter(data.id).name;
        }
        else if (data.type == ReferenceType.StructureParameter) {
          display = getStructureParameter(data.id)?.name || 'nooo';
        }
        else if (data.type == ReferenceType.ContextArg) {
          display = contextVars[data.id].label;
        }
        else if (data.type == ValueType.Structure) {
          display = getStructureById(data.id).name || 'asdfasdf';
        }
        else {
          display = 'nooo';
        }
        return `<span class="--badge--" contenteditable="false" data-type="glue" data-glue-data="${btoa(JSON.stringify(data))}">${display}</span>`;
      },
      text: (id) => {
        return `_$${id.type}:${id.id}$_`;
      },
      part: el => {
        const data = JSON.parse(atob(el.getAttribute('data-glue-data')));
        return [data, 'glue'];
      }
    },
  };
};
export const additionalMenuIniters = (scope, structs=[]) => {

  const structParams = [];

  for (const structId of structs) {
    const struct = structuresMap[structId];
    if (struct.scope) for (const prop of struct.scope) {
      structParams.push({
        id: prop.id,
        label: `${struct.name}/${prop.name}`,
      })
    }

    for (const prop of struct.definition) {
      if (prop.scope) for (const p of prop.scope) {
        structParams.push({
          id: p.id,
          label: `${struct.name}/${p.name}`,
        })
      }
    }

  }

  return {
    '$': args => {
      return presentMenu({
        ...args,
        menuIniters: {
          '$': [
            ...structParams.map(p => ({
              label: p.label,
              key: p.id,
              action: () => {
                substitute(args, [{type:ReferenceType.StructureParameter, id: p.id}, 'glue']);

              },
            })),
            ...typeRegistry.types.map((struct) => ({
              label: struct.name,
              key: `${ValueType.Structure}:${struct._id}`,
              action: () => {
                substitute(args, [{type:ValueType.Structure, id: struct._id}, 'glue']);
              },
            })),
            ...scope.filter(s => s.type == ReferenceType.Parameter).map((s) => ({
              label: getParameter(s.id).name,
              key: `${s.type}:${s.id}`,
              action: () => {
                substitute(args, [s, 'glue']);
              },
            })),

            ...Object.keys(contextVars).map((s) => ({
              label: contextVars[s].label,
              key: `${ReferenceType.ContextArg}:${s}`,
              action: () => {
                substitute(args, [{ type: ReferenceType.ContextArg, id: s }, 'glue']);
              },
            })),

            ...scope.filter(s => s.type == ReferenceType.StructureParameter).map((s) => ({
              label: getStructureParameter(s.id).name,
              key: `${s.type}:${s.id}`,
              // value: { type: [ValueType.Param], content: { id: s.id } }
              action: () => {
                substitute(args, [s, 'glue']);
              },
            })),

            ...scope.filter(s => s.type == ReferenceType.Value).map((s) => {
              const display = getValuePoint(s.id).name;
              return display && ({
                label: display,
                key: `${s.type}:${s.id}`,
                action: () => {
                  substitute(args, [s, 'glue']);
                },
              });
            }).filter(Boolean),


            ...Object.entries(scriptComponents).map(([ id, sc ]) => {
              return ({
                label: sc.name,
                key: `12a1ad37-eeea-53a0-953e-7c5bc705befb:${id}`,
                action: () => {
                  substitute(args, [id, 'scriptComponent']);
                },
              })
            }),
            // {
            //   key: 'hello',
            //   label: 'Hello!',
            //   action: () => {
            //     console.log('asdfasdf');
            //   }
            // }
          ]
        },
      });
    }
  };
};
