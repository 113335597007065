import React, { Component } from 'react';
import { component, styled } from './component2';
import { XInit, XObject } from './XObject';
import { Selector } from './components/Selector';
import { showContextMenu } from './helpers';
import Around from "./components/Around";


@component
export class ImportMenu extends Component<{ devProject; comp; }> {
  static styles = styled.div`
      background-color: white;
  border: 1px solid black;
  width: 140px;
  .header {
    font-size: 10px;
    color: gray;
  }
  `;
  render() {
    const components = this.props.devProject.components;
    const comp = this.props.comp;
    return (
      <>
        <>
          <div>
            <span className="header">Children</span>
            {components.filter(c => c.parent == comp._id).map(c => {
              return (
                <div key={c._id}>{c.name}</div>
              );
            })}
            <button
              onClick={e => {
                showContextMenu(e, [
                  {
                    text: 'Script Component',
                    onClick: () => {
                      components.push(XObject.obj({
                        name: 'Untitled',
                        parent: comp._id,
                      }));
                    },
                  },
                  {
                    text: 'Builder Component',
                    onClick: () => {
                      components.push(XObject.obj({
                        type: 'builderComponent',
                        name: 'Untitled',
                        parent: comp._id,
                        root: XObject.obj({
                          type: 'el',
                        }),
                      }));
                    },
                  }
                ]);
              }}
            >+</button>
          </div>

          <div>
            <span className="header">Imported</span>
            {comp.imported?.map?.(id => {
              const comp = components.find(c => c._id == id);
              return (
                <div key={comp?._id}>{comp?.name || 'deleted'}</div>
              );
            })}
            <Selector
              entries={components.map(c => ({
                key: c._id,
                display: c.name,
              }))}
              onSelected={id => {
                if (id) {
                  XObject.push(comp, 'imported', id);
                }
              }} />
          </div>


        </>
      </>
    );
  }
}
@component
export class Related extends Component<{ devProject; comp; }> {
  state = XInit(class {
    showMenu;
  });
  ref = React.createRef<any>();

  static styles = styled.div`
          position: relative;
        .relatedCount {
          cursor: pointer;
        }

        .menu {
          position: absolute;
          z-index: 99999;
          top: 100%;
          right: 0;

        }
  `;
  render() {
    const components = this.props.devProject.components;
    const comp = this.props.comp;
    return (
      <>
        <span
          ref={this.ref}
          onClick={() => {
            this.state.showMenu = !this.state.showMenu;
          } }
          className="relatedCount"

        >{(comp.imported?.length || 0) + components.filter(c => c.parent == comp._id).length}</span>
        {this.state.showMenu && <Around anchor={() => this.ref.current}>
          <ImportMenu devProject={this.props.devProject} comp={comp} />
        </Around>}
      </>
    );
  }
}

