import React, { Component } from 'react';
import _ from 'lodash';
import { component } from '../component2';
import { defaultWorkspace } from '../etc/defaultWorkspace';
import { appState } from '../etc/appState';
import { db } from '../db';
import { evaluate, execute, getValuePoint, mapStructure } from '../glue/main';
import { X, XClone, XInit, XObject, x } from '../XObject';
import { createEntity, getAllEntities } from '../etc/createEntity';
import { ObjectRefClass, ObjectType } from '../types/ObjectRef';
import { $Parent_CreationTemplateParameter, $Property_CreationTemplateParameter } from '../glue/structs/$CreationTemplates';
import { renderCellForAttribute } from '../glue/structs/cellForAttribute';
import { Cell } from './Cell';
import { EntityCellType } from './cells';
import { AttributeType } from './AttributeType';
import { styled } from '../component';
import { EntityRow } from './EntityRow';
import { WithContextAction } from './WithContextAction';
import { AttributeField } from './AttributeField';
import { RichTextEditor } from './richTextEditor/RichTextEditor';
import { FormulaObjectWrapper } from '../FormulaObjectWrapper';
import { FormulaObjectProxy } from '../FormulaObjectProxy';
import { executeResponse, processPrompt } from '../windows/ChatGPTTestWindow';

@component
export class CreateRoot extends Component {
  state = XInit(class {
    // template = '64e94fbf9278c3f83ee7851f';
    // properties = {}
    // arguments = {}
    description
  });

  tick = 0

  static styles = styled.div`


    display: flex;
    flex-direction: column;
    height: 100%;

    .top {
      flex: 1 1 0;
      overflow: auto;
      display: flex;
      flex-direction: column;
      .entities {
        margin-top: auto;
        padding: 4px;
      }
    }

    .bottom {
      flex: 0 0 auto;
      /* border: 1px solid black; */
      box-shadow: rgb(0 0 0 / 15%) 0px 0px 5px 0px;
      padding: 8px;
      /* background-color: gray; */

      .template {
        display: flex;
        margin-bottom: 4px;
        align-items: center;

        .property {
          flex: 1 1 auto;
        }
      }
    }
  `;

  componentDidMount(): void {
    this.topRef.current.scrollTop = this.topRef.current.scrollHeight;
  }

  topRef = React.createRef<any>();

  render() {
    const mode = db.modes.findById(appState.currentMode);
    const value = mode.creationTemplates && mapStructure(execute(mode.creationTemplates));
    const templates = value?.templates?.content || [];

    const entities = getAllEntities().filter(entity => {
      return (entity.space == appState.currentMode || entity.space?.id == appState.currentMode) && entity.creationTemplate && !entity.__deleted
    });

    const addTabState = XObject.get(appState, 'addTab');
    const templateState: {
      template
      properties
      arguments
      parent
    } = XObject.get(addTabState, 'template', {});

    XObject.get(templateState, 'properties', {});
    XObject.get(templateState, 'arguments', {});

    const template = templateState.template && templateState.template != 'assistant' && mapStructure(templates.find(t => t._id == templateState.template));


    const getParent = () => {
      const match = p => {

      }

      if (templateState?.parent) return templateState.parent;

      if (template.properties?.content) {
        for (const p of template.properties.content) {
          if (p.type?.[1] == $Parent_CreationTemplateParameter.$) {
            return templateState.properties[p._id];
          }
        }
      }

      if (template.arguments?.content) {
        for (const p of template.arguments.content) {
          if (p.type?.[1] == $Parent_CreationTemplateParameter.$) {
            return templateState.arguments[p._id];
          }
        }
      }
    }

    const renderProperty = (p, state, showName=true) => {
      const mapped = mapStructure(p);
      let c;
      let type;
      if (p.type?.[1] == $Property_CreationTemplateParameter.$) {
        const property = db.attributeTypes.findById(mapped.property.content);
        type = property.type;
        c = renderCellForAttribute({
          attribute: property,
          get: () => state[p._id],
          set: value => state[p._id] = value,
          title: mapped.name.content,
          entity: new FormulaObjectProxy({
            get: prop => {
              if (prop?.toLowerCase?.() == 'parent') {
                return new FormulaObjectWrapper({
                  type: ObjectType.entity,
                  id: getParent(),
                })
              }
            }
          }),
        })
      }
      if (p.type?.[1] == $Parent_CreationTemplateParameter.$) {
        type = AttributeType.entity;
        c = (
          <Cell
            cell={new EntityCellType({})}
            get={() => state[p._id]}
            set={value => state[p._id] = value}
            title={mapped.name.content}
          />
        );
      }

      if (showName) {
        return <AttributeField
            title={mapped.name.content}
            field={c}
            type={type}

        />
        return (
          <div key={p._id}>
            {mapped.name.content}: {c}
          </div>
        )
  
      }
      return c;
    }

    return (
      <>
        <div ref={this.topRef} className="top">
          <div className="entities">
            {entities.map(e => {
              return (
                <div key={e._id}>
                  <WithContextAction
                    mobileInvoke="longPress"
                    menu={() => {
                      const tasdf = [];

                      function match(p) {
                        const mapped = mapStructure(p);
                        if (p.type?.[1] == $Property_CreationTemplateParameter.$) {
                          const attr = db.attributeTypes.findById(mapped.property.content);
                          if (attr.type == AttributeType.entity) {
                            return true;
                          }
                        }
                        if (p.type?.[1] == $Parent_CreationTemplateParameter.$) {
                          return true;
                        }

                        return false;
                      }

                      for (const a of templates) {
                        const template = mapStructure(a);
                        if (template.properties?.content) {
                          for (const p of template.properties?.content) {
                            if (match(p)) {
                              tasdf.push([
                                a._id,
                                p,
                                'properties',
                              ]);
                            }
                          }
                        }
                        if (template.arguments?.content) {
                          for (const p of template.arguments?.content) {
                            if (match(p)) {
                              tasdf.push([
                                a._id,
                                p,
                                'arguments',
                              ]);
                            }
                          }
                        }  
                      }

                      return [
                        {
                          text: 'Parent',
                          onClick: () => {
                            templateState.parent = e._id;
                          },
                        },
                        
                        ...tasdf.map(([ a, p, property ]) => {
                        const template = mapStructure(templates.find(t => t._id == a));
                        const prop = mapStructure(p);

                        return {
                          text: `${template.name.content} - ${prop.name.content}`,
                          onClick: () => {
                            templateState.template = a;
                            templateState[property] = X({
                              [p._id]: e._id,
                            })
                          },
                        }
                      })];
                    }}
                  >
                  <EntityRow
                    id={e._id}
                  />

                  </WithContextAction>

                  {/* <button
                    onClick={(event) => {
                      const tasdf = [];

                      function match(p) {
                        const mapped = mapStructure(p);
                        if (p.type?.[1] == $Property_CreationTemplateParameter.$) {
                          const attr = db.attributeTypes.findById(mapped.property.content);
                          if (attr.type == AttributeType.entity) {
                            return true;
                          }
                        }
                        if (p.type?.[1] == $Parent_CreationTemplateParameter.$) {
                          return true;
                        }

                        return false;
                      }

                      for (const a of templates) {
                        const template = mapStructure(a);
                        if (template.properties?.content) {
                          for (const p of template.properties?.content) {
                            if (match(p)) {
                              tasdf.push([
                                a._id,
                                p,
                                'properties',
                              ]);
                            }
                          }
                        }
                        if (template.arguments?.content) {
                          for (const p of template.arguments?.content) {
                            if (match(p)) {
                              tasdf.push([
                                a._id,
                                p,
                                'arguments',
                              ]);
                            }
                          }
                        }  
                      }

                      showContextMenu(event, tasdf.map(([ a, p, property ]) => {
                        const template = mapStructure(templates.find(t => t._id == a));
                        const prop = mapStructure(p);

                        return {
                          text: `${template.name.content} - ${prop.name.content}`,
                          onClick: () => {
                            this.state.template = a;
                            this.state[property] = X({
                              [p._id]: e._id,
                            })
                          },
                        }
                      }));
                    }}
                  >.</button> */}
                </div>
              )
            })}
          </div>
        </div>

        <div className="bottom">
          <div className="template">
            {templates.length > 0 && <select
              value={templateState.template || ''}
              onChange={e => {
                templateState.template = e.target.value;
                templateState.arguments = X({});
                templateState.properties = X({});
                templateState.parent = null;
              }}
            >
              <option />
              <option value="assistant">Assistant</option>
              {templates.map(t => {
                const mapped = mapStructure(t);
                return (
                  <option key={t._id} value={t._id}>{mapped.name.content}</option>
                )
              })}
            </select>}
            {templateState?.parent && (
               <Cell
                cell={new EntityCellType({})}
                get={() => templateState.parent}
                set={value => {}}
                readOnly
                title={''}
              />
            )}
            {template?.properties && (
              <>
                  {/* <ul> */}
                  {template.properties.content.map(p => {
                    return (
                      <div key={p._id} className="property">
                        {renderProperty(p, templateState.properties, false)}
                      </div>
                    )
                  })}
                {/* </ul> */}
              </>
            )}

          </div>

          <RichTextEditor
            key={this.tick}
            value={() => this.state.description}
            setValue={value => this.state.description = value}
            autoFocus
            onEnter={async () => {
              const template = templateState.template && templateState.template != 'assistant' && templates.find(t => t._id == templateState.template) && mapStructure(templates.find(t => t._id == templateState.template));
              let entityType;
              let attributes = {};
              let edges = [];
              let name;

              if (template) {
                name = this.state.description;
                entityType = evaluate(template.entityType);
                const process = (p, state) => {
                  const mapped = mapStructure(p);

                  if (p.type?.[1] == $Property_CreationTemplateParameter.$) {
                    attributes[mapped.property.content] = state[p._id];
                  }
                  else if (p.type?.[1] == $Parent_CreationTemplateParameter.$) {
                    edges.push({
                      from: state[p._id],
                      directed: true,
                    });
                  }
                }

                if (template.properties?.content) {
                  for (const p of template.properties?.content) {
                    process(p, templateState.properties);
                  }
                }

                if (template.arguments?.content) {
                  for (const p of template.arguments?.content) {
                    process(p, templateState.arguments);
                  }
                }

              }
              else if (templateState.template == 'assistant') {
                const request = await processPrompt(this.state.description);
                const response = executeResponse(request, getParent());

                entityType = response.type;
                name = response.name;
                attributes = response.attributes;
                edges = response.$edges;
              }

              const type = entityType&& db.entityTypes.findById(entityType);
              if (type?.const) {
                const vp = getValuePoint(type.const);
                const compiled = execute(type.const);
                evaluate(compiled, { [vp.parameters[0]._id]: new FormulaObjectProxy({
                  set: (prop, value) => {

                  },
                  get: prop => {
                    if (_.isString(prop)) prop = prop.toLowerCase();
                    
                    if (prop == 'parent' && getParent()) {
                      return new ObjectRefClass(ObjectType.entity, getParent());
                    }
                    else if (prop == 'setattr') {
                      return (attr, value) => {
                        attributes[attr.ref.id] = XClone(value);
                      }
                    }
                    else if (prop instanceof FormulaObjectWrapper) {
                      if (prop.ref.type == ObjectType.attribute) {
                        return attributes[prop.ref.id];
                      }
                    }

                  }
                }) });
              }

              createEntity({
                type: entityType,
                name,
                attributes,
                $edges: edges,
                creationTemplate: {
                  template: template?._id,
                  properties: templateState.properties,
                  arguments: templateState.arguments,
                },
                space: {
                  type: ObjectType.mode,
                  id: mode._id,
                }
              }, null);
              this.tick++;
              this.state.description = [];

              templateState.arguments = X({});
            }}

            
          />
{/* 
          <input type="text"
            onKeyDown={e => {
              if (e.key == 'Enter') {
                const template = this.state.template && mapStructure(templates.find(t => t._id == this.state.template));
                let entityType;
                const attributes = {};
                const edges = [];

                if (template) {
                  entityType = evaluate(template.entityType);
                  const process = (p, state) => {
                    const mapped = mapStructure(p);
  
                    if (p.type?.[1] == $Property_CreationTemplateParameter.$) {
                      attributes[mapped.property.content] = state[p._id];
                    }
                    else if (p.type?.[1] == $Parent_CreationTemplateParameter.$) {
                      edges.push({
                        from: state[p._id],
                        directed: true,
                      });
                    }
                  }
  
                  if (template.properties?.content) {
                    for (const p of template.properties?.content) {
                      process(p, this.state.properties);
                    }
                  }
  
                  if (template.arguments?.content) {
                    for (const p of template.arguments?.content) {
                      process(p, this.state.arguments);
                    }
                  }
  
                }

                createEntity({
                  type: entityType,
                  name: e.target['value'],
                  attributes,
                  $edges: edges,
                  creationTemplate: {
                    template: template?._id,
                    properties: this.state.properties,
                    arguments: this.state.arguments,
                  },
                  space: {
                    type: ObjectType.mode,
                    id: mode._id,
                  }
                }, null);
                e.target['value'] = '';

                this.state.arguments = X({});
              }
            }}
          /> */}
          {template?.arguments && (
            <>
              {/* <h3>Arguments</h3> */}
              <div>
              {template.arguments.content.map(p => {
                  return (
                    <div key={p._id}>
                      {renderProperty(p, templateState.arguments)}
                    </div>
                  )
                })}
                </div>

            </>
          )}
        </div>
      </>
    );
  }
}
