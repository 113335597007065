export enum ViewType {
  list = '6fb314db-5246-5898-97ea-33553acbcdbc',
  table = '2632631b-ef9b-5f3c-98b3-ff7bedcc0e5d',
  split = '72cc541e-953a-5beb-a325-511202b35983',
  graph = '0baf01e2-2e78-510e-8639-b990bc392385',
  timeline = '3ac9a3b0-178a-5e74-844d-dd68585845d1',
  gallery = '6b477a88-d05e-5fca-a969-d35417f88982',
  board = '88d04dc2-45c2-52e0-9d12-33bd2498b73e',
  chart = '3d3cba9e-cddf-501e-bd63-49398e4cf777',
  calendar = 'e8153a85-6903-53dc-b531-b29ba999df79',

  clustering = 'e05996b7-64a4-532c-a889-dcd3a4f8206c',
}

export function supportsGrouping(viewType: ViewType) {
  return viewType === ViewType.list;
}
