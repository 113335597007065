import { Component } from 'react';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import jQuery from 'jquery';
import { component } from './component2';
import { XClone, XInit, XObject } from './XObject';
import { styled } from './component';
import { ColumnManager, RowManager } from './components/notionDatabase/ColumnManager';
import { db } from './db';
import { Cell } from './components/Cell';
import { lightColors } from './components/mainColors';
import { Svg } from './components/Svg';
import classNames from 'classnames';

function onDrag(e, func, mouseup) {
  let dragged;
  const mousemove = ee => {
    const dx = e.clientX - ee.clientX;
    const dy = e.clientY - ee.clientY;

    if (dx * dy >= 10*10) {
      dragged = true;
      func();
      jQuery(window).off('mousemove', mousemove);
    }
  }

  jQuery(window).mousemove(mousemove);

  jQuery(window).one('mouseup', () => {
    jQuery(window).off('mousemove', mousemove);
    mouseup();
  })
}


function createDropZone(a: {
  comp
  key
  handler
}) {
  if (!a.comp.dropHandlers) {
    a.comp.dropHandlers = {};
  }

  a.comp.dropHandlers[a.key] = a.handler;

  return {
    attrs: () => {
      return {
        'data-drop': JSON.stringify({
          type: 'dropZone',
          zoneKey: a.key,
        })
      };
    }
  }
}

function createVerticalOrdering(aa: {
  comp
  key
  handler
}) {

  if (!aa.comp.dropHandlers) {
    aa.comp.dropHandlers = {};
  }

  aa.comp.dropHandlers[aa.key] = aa.handler;

  return {
    contProps: (a) => {
      return {
        'data-drop': JSON.stringify({
          type: 'verticalOrdering',
          listKey: aa.key,
          data: a,
        })
      };
    },
    itemProps: (a) => {
      return {
        'data-drop-tag': JSON.stringify(a),
      };
    }
  }
}

@component
export class Board extends Component<{
  columnManager: ColumnManager
  rowManager: RowManager
  view: {
    groupingProperty
    entityTypes
    columns: {
      '@index2': string[]
    }
  }
  onCardClick
}> {
  static debounce = false;
  static styles = styled.div`
    overflow: auto;
    .boards {
      display: flex;
      align-items: flex-start;
      min-height: 275px;
      .board {
        width: 257px;
        flex: 0 0 auto;
        border-radius: 4px;
        background-color: #fafafa;
        margin-right: 8px;
        padding: 8px;
        border: 1px solid #fafafa;
        transition: border 200ms linear, background-color 200ms linear;

        &.collapsed {
          margin-right: -225px;
          > .header {
            margin-bottom: 0;
            .collapse {
              transform: rotate(-90deg); 
              margin-left: 6px;
            }
          }
          > .cards {
            display: none;
          }



          transform: rotate(90deg); 
          transform-origin: left bottom 0;
          margin-top: -42px;
        }

        &:hover {
          border: 1px solid #e0e0e0;
        }

        > .header {
          height: 24px;
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          > .title {
            border: 1px solid rgba(0, 0, 0, 0.08);
            background-color: #f0f0f0;
            border-radius: 11px;
            height: 22px;
            padding: 0 8px;
            display: inline-flex;
            align-items: center;
            font-size: 14px;
            font-weight: bold;
          }

          > .count {
            margin-left: 4px;
            color: rgb(142, 142, 142);
          }

          > .add {
            width: 16px;
            height: 16px;

            svg {
              width: 16px;
              height: 16px;
              fill: rgb(136, 164, 255);
            }
            margin-left: auto;
          }

          > .collapse {
            margin-right: 8px;
            cursor: pointer;
          }
        }

        .cards {
          position: relative;
          .card {
            cursor: pointer;
            border: 1px solid rgb(224, 224, 224);
            border-radius: 4px;
            padding: 8px;
            background-color: white;
            transition: all 200ms linear;
            &:hover {
              background-color: #fafafa;
              border-color: #c6c6c6;
            }


            &:not(:first-child) {
              margin-top: 8px;
            }
          }

          .add {
            margin-top: 8px;
            color: rgb(136, 164, 255);
            display: flex;
            align-items: center;
            cursor: pointer;
            svg {
              width: 16px;
              height: 16px;
              fill: rgb(136, 164, 255);
              margin-right: 8px;
            }
          }
        }
      }
    }
  `;
  state = XInit(class {
    boards = [
      XObject.obj({
        title: 'hello',
        cards: [
          XObject.obj({
            content: 'World',
          }),
          XObject.obj({
            content: 'Weee',
          }),
          XObject.obj({
            content: 'Weee1',
          }),
          XObject.obj({
            content: 'Weee2',
          }),
          XObject.obj({
            content: 'Weee3',
          }),
        ]
      }),
      XObject.obj({
        title: 'hello2',
        cards: [
          XObject.obj({
            content: 'World',
          })
        ]
      }),
      XObject.obj({
        title: 'hello2',
        cards: [

        ]
      }),
    ]
  })

  cleanupFuncs = [];

  dragging

  startDragging(data) {
    this.dragging = {
      data
    }
  }

  componentDidMount(): void {
		const el = ReactDOM.findDOMNode(this);
    const doc = el.ownerDocument;
    const win = doc.defaultView;


    let dropLocationData: {
      key,
      el,
      data,
      zoneKey
    }

    const setDropLocation = ({x, y, width, height, key, parent, data, zoneKey}) => {
      if (!dropLocationData || dropLocationData.key != key) {
        if (dropLocationData) {
          jQuery(dropLocationData.el).remove();
        }

        const el = jQuery('<div />').css({
          left: x,
          top: y,
          width: width,
          height: height,
          backgroundColor: 'red',
          position: 'absolute',
        }).appendTo(parent);

        dropLocationData = {
          key,
          el,
          data,
          zoneKey,
        }
      }
    }

    let h1;
    jQuery(win).mousemove(h1 = e => {
      if (!this.dragging) return;
      const els = doc.elementsFromPoint(e.clientX, e.clientY);
      const el = els.find(el => el.getAttribute('data-drop'));

      if (el) {
        const data = JSON.parse(el.getAttribute('data-drop'));
        if (data) {
          if (data.type == 'verticalOrdering') {
            const children = jQuery(el).find('[data-drop-tag]');
            const rect = el.getBoundingClientRect();

            const relY = e.clientY - rect.top;

            let i = 0;
            for (const child of children) {
              const top = jQuery(child).offset().top - rect.top;
              const height = jQuery(child).outerHeight();
              if (i == 0 && relY < height/2) {
                setDropLocation({
                  parent: el,
                  height: 2,
                  width: rect.width,
                  x: 0,
                  y: -4,
                  key: i,
                  zoneKey: data.listKey,
                  data: {
                    listKey: data.listKey,
                    drop: data.data,
                    index: i,
                  },
                })
              }
              else if (i == children.length - 1 && relY > rect.height - height/2) {
                setDropLocation({
                  parent: el,
                  height: 2,
                  width: rect.width,
                  x: 0,
                  y: top + height + 2,
                  key: i + 1,
                  zoneKey: data.listKey,
                  data: {
                    listKey: data.listKey,
                    drop: data.data,
                    index: i + 1,
                  },

                })
              }
              else {
                const next = children[i + 1];
                if (next) {
                  const t = top + height/2;
                  const nextHeight = jQuery(next).outerHeight();
                  const nextTop = jQuery(next).offset().top - rect.top;

                  const b = nextTop + nextHeight/2;
                  if (relY >= t && relY <= b) {
                    setDropLocation({
                      parent: el,
                      height: 2,
                      width: rect.width,
                      x: 0,
                      y: top + height + 2,
                      key: i + 1,
                      zoneKey: data.listKey,
                      data: {
                        listKey: data.listKey,
                        drop: data.data,
                        index: i + 1,
                      },
    
                    })
                  }
                }

              }
              ++ i;
            }
          }
          else if (data.type == 'dropZone') {
            if (dropLocationData?.el) {
              jQuery(dropLocationData.el).remove();
            }
            dropLocationData = {
              data: null,
              key: data.zoneKey,
              zoneKey: data.zoneKey,
              el: null,
            }
          }
        }
        // const rect = el.getBoundingClientRect();
        // const relX = e.clientX - rect.left;
        // const relY = e.clientY - rect.top;

        // console.log(relX, relY);
      }
      else if (dropLocationData) {
        jQuery(dropLocationData.el).remove();
        dropLocationData = null;
      }
    })
    this.cleanupFuncs.push(() => {
      jQuery(win).unbind('mousemove', h1);
    });

    let h2;
    jQuery(win).mouseup(h2 = e => {
      if (this.dragging) {
        if (dropLocationData) {
          console.log(dropLocationData.data, this.dragging.data);

          this['dropHandlers'][dropLocationData.zoneKey](dropLocationData.data, this.dragging.data)

          if (dropLocationData.el) {
            jQuery(dropLocationData.el).remove();

          }
          dropLocationData = null;
        }
        delete this.dragging;
      }

    });
    this.cleanupFuncs.push(() => {
      jQuery(win).unbind('mouseup', h2);
    })
  }

  componentWillUnmount(): void {
    for (const func of this.cleanupFuncs) {
      func();
    }
  }


  cancelClick
  render() {
    class Board {
      key
      _id
      title
      color
      constructor(public opts) {
        this.key = opts.key;
        this._id = opts.key;
        this.title = opts.title;
        this.color = opts.color;
      }

      addCards(id) {
        this.cards.push(id);
      }

      cards: string[] = []
    }

    const boards: Board[] = [];
    const groupingAttr = db.attributeTypes.findById(this.props.view.groupingProperty);
    const boardStates = XObject.get(this.props.view, 'boardStates', {});


    for (const option of groupingAttr.options) {
      boards.push(new Board({
        key: option._id,
        title: option.title,
        color: lightColors.find(c => c.colorType == option.color)?.color
      }))
    }

    const noValues = [];

    for (const row of this.props.rowManager.rows()) {
      const value = row.value(this.props.view.groupingProperty);
      if (!value) {
        noValues.push(row);
      }
      else {
        const board = boards.find(b => b.key == value);
        if (board) {
          board.addCards(row.id());
        }
      }
    }

    if (noValues.length) {
      const board = new Board({
        key: undefined,
        title: '',
      })

      for (const row of noValues) {
        board.addCards(row.id());
      }

      boards.push(board);
    }


    return (
      <>
      <div className="boards">
        {boards.map(board => {
          const verticalOrdering = createVerticalOrdering({
            comp: this,
            key: board._id,
            handler: (dropLocation, item) => {
              if (item.board == board._id) {
                // console.log('same board');
                // console.log('from', item.index, 'to', dropLocation.index)

                // const from = item.index;
                // let to = dropLocation.index;
                // if (from == to) return;

                // if (from < to) {
                //   to --;
                // }

                // const card = board.cards[from];
                // const cards = XClone(board.cards);
                // cards.splice(from, 1);
                // cards.splice(to, 0, card);

                // board.cards = cards;
              }
              else {
                console.log('different board');
                console.log(dropLocation, item);
                const fromBoardId = item.board;
                const toBoardId = dropLocation.drop.board;

                const row = this.props.rowManager.rows().find(r => r.id() == item.card);

                row.setValue(this.props.view.groupingProperty, toBoardId);
                


                // const fromBoard = this.state.boards.find(b => b._id == fromBoardId);
                // const toBoard = this.state.boards.find(b => b._id == toBoardId);

                // const card = fromBoard.cards[item.index];
                // fromBoard.cards.splice(item.index, 1);
                // toBoard.cards.splice(dropLocation.index, 0, card);

              }
            }
          });
          const dropZone = createDropZone({
            comp: this,
            key: board._id + 'container',
            handler: (dropLocation, item) => {
              const row = this.props.rowManager.rows().find(r => r.id() == item.card);
              row.setValue(this.props.view.groupingProperty, board._id);

              // console.log(dropLocation, item);
              // const fromBoardId = item.board;
              // const fromBoard = this.state.boards.find(b => b._id == fromBoardId);
              // const card = fromBoard.cards[item.index];
              // fromBoard.cards.splice(item.index, 1);

              // board.cards.push(card);

            }
          });

          return (
            <div
              key={board._id}
              className={
                classNames('board', {
                  collapsed: boardStates?.[board.key]?.collapsed,
                })
              }
              {...dropZone.attrs()}
            >
              <div className="header">
                <span className="collapse"
                  onClick={() => {
                    const boardState = XObject.get(boardStates, board.key, {});
                    boardState.collapsed = !boardState.collapsed;
                  }}
                ><Svg name="chevron" /></span>
                {board.title && (
                  <span
                    className="title"
                    style={{
                      backgroundColor: board.color,
                    }}
                  >{board.title}</span>
                )}
                <span className="count">{board.cards.length}</span>

                <span className="add"
                  onClick={() => {
                    const row = this.props.rowManager.addRow({
                      [this.props.view.groupingProperty]: board.key,
                    });
                    // row.setValue(this.props.view.groupingProperty, board.key);
                  }}
                >
                  <Svg name="icons8-create (2)" />
                </span>
              </div>
              <div
                className="cards"
                {...verticalOrdering.contProps({
                  board: board._id,
                })}
              >
                {board.cards.map((id, i) => {
                  const row = this.props.rowManager.rows().find(r => r.id() == id);
                  return (
                    <div
                      key={row.id()}
                      className="card"
                      {...verticalOrdering.itemProps({
                        index: i,
                        key: id,
                        data: {
                          card: id,
                        }
                      })}
                      onMouseDown={e => {
                        e.preventDefault();
                        onDrag(e,
                          (() => {
                            this.cancelClick = true;
                            this.startDragging({
                              board: board._id,
                              card: id,
                              index: i,
                            })
                          }),
                        
                          (() => {
                            this.cancelClick = false;
                          })
                        );
                      }}
                      onMouseUp={e => {
                        if (!this.cancelClick) {
                          this.props.onCardClick(id);
                        }
                        else {
                          this.cancelClick = false;
                        }
                      }}
                      onClick={() => {
                      }}
                    >
                      {this.props.view.columns['@index2'].map(id => {
                        const col = this.props.columnManager.columns().find(c => c.id() == id);
                        if (!col) return;
                        const cell = col.cellObj(row);

                        if (_.isNil(row.value(id))) return;
      
                        return (
                          <div key={id}>
                            <Cell
                              readOnly
                              cell={cell}
                              get={() => row.value(id)}
                              set={() => {}}
                              title=""
                            />
                          </div>
                        )
      
                      })}
                    </div>
                  );
                })}

                <div className="add"
                  onClick={() => {
                    const row = this.props.rowManager.addRow({
                      [this.props.view.groupingProperty]: board.key,
                    });
                    // row.setValue(this.props.view.groupingProperty, board.key);

                  }}
                >
                  <Svg name="icons8-create (2)" /> New card
                </div>
              </div>
            </div>
          )
        })}
        </div>
      </>
    )
  }
}
