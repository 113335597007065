import _ from 'lodash';
import { db } from '../db';
import { attributesInScopes, getScopeTree, objectName } from "./objectFuncs";
import { XInit, XObject } from '../XObject';
import { Tag } from "./Tag";
import { SelectEditor } from "./SelectEditor";
import React, { Component } from 'react';
import { component } from '../component';


@component
export class AttributeSelector extends Component<{ scopes; onSelect; }> {
  state = XInit(class {
    newPropertyScope;
  });
  render() {
    const state = this.state;
    let attrs = [];

    const tree = getScopeTree(this.props.scopes[0]);

    attrs = attrs.concat(attributesInScopes(this.props.scopes));

    attrs = _.uniq(attrs);

    return (
      <>
        <SelectEditor
          close={() => { }}
          frame={null}
          createOption={name => {
            let scope;
            if (state.newPropertyScope) {
              // if (state.newPropertyScope == entity.type) {
              //   scope = {
              //     type:ObjectType.type,
              //     id:entity.type,
              //   }
              // }
              // else {
              scope = tree.find(t => t.id == state.newPropertyScope);
              // }
            }
            console.log(scope);
            const newAttr = XObject.obj({
              name,
              scope,
            });
            db.attributeTypes.push(newAttr);
            return newAttr._id;
          }}
          options={attrs.map(id => db.attributeTypes.findById(id)).map(t => ({
            _id: t._id,
            title: t.scope ? `${t.name} (${objectName(t.scope)})` : t.name,
          }))}
          setValue={async (value) => {
            // onAdd(value);
            // delete state.addingProperty;
            this.props.onSelect(value);
          }}
          value={null}

          renderCreate={filter => {
            return <>Create&nbsp;<Tag text={filter} />

              <select
                value={state.newPropertyScope || ''}
                onChange={e => {
                  state.newPropertyScope = e.target.value || null;
                }}
              >
                <option />
                {/* {entity.type && <option
                              value={entity.type}
                            >{objectName({
                              id: entity.type,
                              type: ObjectType.type,
                            })} (type)</option>} */}
                {tree.map(t => {
                  return <option key={t.id} value={t.id}>{objectName(t)}</option>;
                })}
              </select>
            </>;
          }} />
      </>
    );
  }
}
