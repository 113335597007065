//// <reference path="../../node_modules/sugar/sugar-extended.d.ts" />


import React, { Component } from 'react';
import { component, styled } from '../component2';
import { XInit } from '../XObject';
import { ColumnManager, Row, RowManager } from './notionDatabase/ColumnManager';
import Sugar from 'sugar';
import { entityDisplayName } from './entityDisplayName';
import classNames from 'classnames';
import juration from '../juration';


declare global {
  interface Date {
    isBetween
    clone(): Date
    beginningOfDay(): Date
    endOfDay(): Date;
    isBefore(date: Date): boolean;
    isAfter(date: Date): boolean;
    addDays(numner): Date;
    isToday(): boolean
  }
}

@component
class WeekView extends Component<{
  columnManager: ColumnManager
  rowManager: RowManager
  view
  onClickRow

}> {
  static styles = styled.div`
    /* padding-top: 30px; */
    .days {
      display: flex;
    }

    .header {
      background-color: white;
      z-index: 9999999;
      border-bottom: 1px solid #e0e0e0;
      position: sticky;
      top: 0;
      height: 30px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      padding-left: 66px;
      .days {
        display: flex;
        width: 100%;
        .day {
          width: 100%;
          box-sizing: border-box;
          border-left: 1px solid #e0e0e0;
          flex: 1 1 auto;
        }
      }
    }

    .now {
      height: 1px;
      background-color: red;
      width: 100%;
      z-index: 999999;
    }
    .content {
      display: flex;
      width: 100%;

      > .timeline {
        width: 66px;
        flex: 0 0 auto;
        .hour {
          height: 60px;
        }
      }
      .days {
        display: flex;
        width: 100%;
        .day {
          width: 100%;
          flex: 1 1 auto;
          position: relative;
          border-left: 1px solid #e0e0e0;
          box-sizing: border-box;
          
          .hour {
            box-sizing: border-box;
            height: 60px;
            position: relative;
            z-index: 1;
            border-bottom: 1px solid #e0e0e0;
            box-sizing: border-box;
          }

          .occurrence {
            background-color: #f0f0f0;
            height: 20px;
            width: 100%;
            z-index: 4;
            padding: 0 4px;

            box-sizing: border-box;

            overflow: hidden;

            > *:first-child {
              margin-top: 4px;
            }
            > *:last-child {
              margin-bottom: 4px;
            }
          }
        }
      }
    }

  `;

  // timerId
  // componentDidMount(): void {
  //   this.timerId = setInterval(() => {
  //     this.forceUpdate();
  //   }, 1000);
  // }
  // componentWillUnmount(): void {
  //   clearInterval(this.timerId);
  // }

  render() {
    const { startAttr, endAttr } = this.props.view;

    let beginningOfWeek = new Date();
    beginningOfWeek.addDays(-(new Date().getDay() - 1));
    const days = [
      {
        name: 'Mon',
      },
      {
        name: 'Tue',
      },
      {
        name: 'Wed',
      },
      {
        name: 'Thu',
      },
      {
        name: 'Fri',
      },
      {
        name: 'Sat',
      },
      {
        name: 'Sun',
      },
    ]
    let rowsDisplayed = {};
    return (
      <>
        <div className="header">
          <div className="days">
            {days.map(day => (
              <span className="day">{day.name}</span>
            ))}
          </div>
        </div>
        <div className="content">
          <div className="timeline">
            {[...Array(24)].map((_, i) => (
              <div className="hour">
                {i}
              </div>
            ))}
          </div>
          <div className="days">
            {days.map((day, i) => {
              const date = beginningOfWeek.clone().addDays(i).beginningOfDay();
              const rows = rowsInDay(this.props.rowManager.rows(), startAttr, endAttr, date)//.filter(r => !rowsDisplayed[r.id()]);

              // for (const row of rows) {
              //   rowsDisplayed[row.id()] = true;
              // }
    
              const totalHeight = 60 * 24;
              const isToday = date.isToday();
              return (
                <div className="day" key={i}>

                  {rows.map(occurrence => {
                    const start: Date = new Date(Math.max(date.getTime(), occurrence.value(startAttr).getTime()));
                    const end: Date = occurrence.value(endAttr) || start.clone().addDays(.1);
                    const duration = (end || new Date()).getTime() - start.getTime();

                    const top = (start.getTime() - date.getTime()) / (1000 * 60 * 60) * 60;
                    const maxHeight = totalHeight - top;

                    return (
                      <div className="occurrence" style={{
                        position: 'absolute',
                        top,
                        height: Math.min(maxHeight, Math.max(0, (duration) / (1000 * 60 * 60) * 60)),

                      }}>
                        <div>Hello</div>
                        <div>{juration.stringify(duration/1000)}</div>
                      </div>
                    )
                  })}

                  {[...Array(24)].map((_, i) => (
                    <div className="hour">
                      <span className="hour"></span>
                    </div>
                  ))}
                  {isToday && (
                    <>
                      <div className="now" style={{
                        position: 'absolute',
                        top: (new Date().getTime() - date.getTime()) / (1000 * 60 * 60) * 60,
                      }} />
                    </>
                  )}

                </div>
              )
            })}
          </div>
        </div>
        {/* <div className="timeline">
          {[...Array(24)].map((_, i) => (
            <div className="hour">
              <span className="hour">{i}</span>
            </div>
          ))}
        </div> */}
        {/* <div className="days">
          {days.map(day => (
            <div className="day">
              <span className="day">{day.name}</span>
            </div>
          ))}
        </div> */}
      </>
    );
  }
}





function overlaps(rangeA: [ Date, Date ], rangeB: [ Date, Date ]) {
  if (
    rangeA[0].isBetween(...rangeB) || 
    rangeA[1].isBetween(...rangeB) || 
    rangeB[0].isBetween(...rangeA) || 
    rangeB[1].isBetween(...rangeA)) {
      return true;
  }
  else {
    return false;
  }
}

function rowsInDay(rows: Row[], startCol: string, endCol: string, date: Date) {
  const end = date.clone().endOfDay();
  return rows.filter(r => {
    const startValue = r.value(startCol);
    const endValue = endCol && r.value(endCol);
    if (startValue) {
      if (endValue) {
        if (overlaps([ date, end ], [ startValue, endValue ])) {
          return true;
        }
      }
      else if (startValue.isBetween(date, end)) {
        return true;
      }
    }
  });
}

@component
export class CalendarView extends Component<{
  columnManager: ColumnManager
  rowManager: RowManager
  view
  onClickRow
}> {
  static styles = styled.div`
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */


    > .header {
      .month-year {
        margin: 10px 0;
        font-size: 1.5em;
      }

      .right {
        margin-left: auto;
      }
    }

    .body {
      height: 952px;

      ${WeekView} {
        width: 100%;
      }
    }

    &.month {
      .body {
        display: flex;
        flex-direction: column;
        align-items: center;

      }
      .week {
        display: flex;
        justify-content: space-around;
      }

      .header.week {
        .day {
          height: 30px;
          padding-top: 0;
        }
      }

      .day {
        width: 100px;
        height: 150px;
        padding-top: 29px;
        box-sizing: border-box;
        margin: 1px;
        border: 1px solid #ddd;
        position: relative;
        .number {
          position: absolute;
          top: 5px;
          right: 5px;
        }

        .row {
          cursor: pointer;
          position: relative;
          z-index: 1;
          border: 1px solid black;
          box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
          border-radius: 5px;
          white-space: nowrap;
          overflow: hidden;
          background-color: white;
          padding: 0 8px;
          box-sizing: border-box;

          &.left, &.middle {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 0;
          }
          &.right, &.middle {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 0;
          }
        }
      }

      .day.adjacent-month {
        color: #aaa;
      }
    }



  `;

  state = XInit(class {
    year = new Date().getFullYear();
    month = new Date().getMonth();
  });

  render(Container?) {
    const width = 100 + 1;
    const { year, month } = this.state;
    const display = this.props.view.display || 'month';


    const render = () => {

      if (display == 'month') {
        const daysInPreviousMonth = new Date(year, month, 0).getDate();
        const daysInCurrentMonth = new Date(year, month + 1, 0).getDate();
    
        const firstDayOfCurrentMonth = new Date(year, month, 1).getDay();
        const lastDayOfCurrentMonth = new Date(year, month + 1, 0).getDay();
    
        let leadingDays = [];
        for (let i = 0; i < firstDayOfCurrentMonth; i++) {
          leadingDays.push(<div key={`pl${i}`} className="day adjacent-month"><span className="number">{daysInPreviousMonth - firstDayOfCurrentMonth + i + 1}</span></div>);
        }
    
        let trailingDays = [];
        for (let i = 1; i < 7 - lastDayOfCurrentMonth; i++) {
          trailingDays.push(<div key={`nl${i}`} className="day adjacent-month"><span className="number">{i}</span></div>);
        }
    
        const { startAttr, endAttr } = this.props.view;
    
        let rowsDisplayed = {};
        
        const daysInCurrentMonthArray = [];
        for (let d = 1; d <= daysInCurrentMonth; d++) {
          const date = Sugar.Date.create(`${year}-${month + 1}-${d}`).beginningOfDay();
          if (date.getDay() == 0) {
            rowsDisplayed = {};
          }
          // const end = date.clone().endOfDay();
          const rows = rowsInDay(this.props.rowManager.rows(), startAttr, endAttr, date).filter(r => !rowsDisplayed[r.id()]);

          for (const row of rows) {
            rowsDisplayed[row.id()] = true;
          }

          daysInCurrentMonthArray.push(
            <div key={`c${d}`} className="day">
              <span className="number">{d}</span>
              <div>
                {rows.map(row => {
                  const startValue: Date = row.value(startAttr);
                  const endValue: Date = endAttr && row.value(endAttr)
                  let length;
                  if (endValue) {
                    length = Math.min(Sugar.Date.range(date, endValue).days() + 1, (6 - date.getDay()) + 1);
                  }
                  else {
                    length = 1;
                  }
      
                  const endInRow = date.clone().addDays(length - 1).endOfDay();
      
      
      
                  let part;
      
                  if (endValue) {
                    if (startValue.clone().beginningOfDay().isBefore(date) && endValue.clone().endOfDay().isAfter(endInRow)) {
                      part = 'middle';
                    }
                    else if (startValue.clone().beginningOfDay().isBefore(date)) {
                      part = 'right'
                    }
                    else if (endValue.clone().endOfDay().isAfter(endInRow)) {
                      part = 'left';
                    }
        
                  }
      
      
      
      
                  return (
                    <div
                      key={row.id()}
                      className={classNames("row", part, {
                        
                      })}
                      onClick={() => {
                        this.props.onClickRow(row.id());
                      }}
                      style={{
                        width: length > 1 && width * length,
                      }}
                    >
                      {entityDisplayName(row.id())} {length}
                    </div>
                  )
                })}
              </div>
            </div>
          );
        }
    
        const totalSlots = [...leadingDays, ...daysInCurrentMonthArray, ...trailingDays];
        const rows = [];
        let cells = [];
    
        totalSlots.forEach((row, i) => {
          if ((i % 7) !== 0 || i === 0) {
            cells.push(row);
          } else {
            rows.push(<div key={`w${rows.length}`} className="week">{cells}</div>);
            cells = [];
            cells.push(row);
          }
          if (i === totalSlots.length - 1) {
            rows.push(<div key={`w${rows.length}`} className="week">{cells}</div>);
          }
        });
    
        return (
          <>
            <div className="header week">
              <div className="day">S</div>
              <div className="day">M</div>
              <div className="day">T</div>
              <div className="day">W</div>
              <div className="day">T</div>
              <div className="day">F</div>
              <div className="day">S</div>
            </div>
            {rows}   
          </>
        )
      }
      else if (display == 'week') {
        return (
          <WeekView
            columnManager={this.props.columnManager}
            rowManager={this.props.rowManager}
            onClickRow={this.props.onClickRow}
            view={this.props.view}
          />
        )
      }

    }


    return (
      <Container className={display}>
        <div className="header">
          <div className="month-year">
            {new Date(year, month).toLocaleString('default', { month: 'long' })} {year}
          </div>

          <div className="right">
            <select
              value={this.props.view.display || 'month'}
              onChange={e => {
                this.props.view.display = e.target.value;
              }}
            >
              <option value="month">Month</option>
              <option value="week">Week</option>
              <option value="day">Day</option>

            </select>
          </div>

        </div>

        <div className="body">
          {render()}
        </div>

      </Container>
    );
  }
}
