import _ from 'lodash';

export const getCst = (root, targetPos) => {
  const tree = [[root, 0]];
  let current = root;
  const terminal = [
    'identifier',
    'oldRhinos',
  ];
  while (current) {
    if (terminal.includes(current.ruleName)) {
      return tree;
    }
    let foundChild;
    loop: for (let i = 0; i < current.children.length; ++i) {
      const child = current.children[i];
      if (_.isArray(child)) {
        for (let j = 0; j < child.length; ++j) {
          const start = child[j][1].absoluteOffset, end = child[j][1].absoluteOffset + child[j][1].matchLength;
          if (targetPos >= start && targetPos < end) {
            current = child[j][1];
            foundChild = true;
            tree.push([{absoluteOffset: child[0][0].absoluteOffset, children:child}, i]);
            tree.push([child[j][1], j]);
            break loop;
          }
        }
      }
      else {
        if (!child || !child.matchLength) continue;
        const start = child.absoluteOffset, end = child.absoluteOffset + child.matchLength;
        if (targetPos >= start && targetPos < end) {
          current = child;
          foundChild = true;
          tree.push([child, i]);
          break;
        }
      }
    }

    if (!foundChild) {
      return tree;
    }
  }

  return tree;
};
