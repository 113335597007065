import { BlockType } from './BlockType';

export const fullBlocks = [
  'code',
  'media',
  BlockType.query,
  BlockType.horizontalLine,
  BlockType.document,
  BlockType.glue,
];
