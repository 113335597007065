
export const grammarDef = `
  Coda {
    Expr = 
      | "#" any* -- comment
      | "OR" -- or
      | "AND" --and
      | "+" -- plus
      | "for" identifier ("in"|"of") Expr -- for
      | "else if" Expr -- elseIf
      | "if" Expr -- if
      | "else" -- else
      | Assign
      | PlusEquals
      | MinusEquals
      | DeclareConst
      | DeclareVar
      | PipedLine
      | Labeled
      | StyledComponent
      | Or


    TextBlock =
      | #" "* (#" "+ | TextBlockSegment)*

    TextBlockSegment =
      | "_$" content "$_" -- objectRef
      | "{" Expr "}"  --interpolation
      | ~"\{" any  --text

    StyledComponent =
      | "~" identifier (":" StyledComponentModifier)?

    StyledComponentModifier = 
      | identifier "(" Primary ")" --param
      | identifier --noParam

    Element =
      | "<" TagName Attributes ">" (#" "+ | Child)* "</" TagName? ">"  -- withChildren
      | "<" TagName Attributes "/>"                            -- selfClosing
      | "<" TagName Attributes ">" (#" "+ | Child)* -- onlyOpening
      | "<>" (#" "+ | Child)* "</>"? -- asdf
      
    TagName = #alnum+ | ObjectRef
    Attributes = Attribute*
    Attribute = #letter+ ("=" AttributeValue)?      -- pair
    AttributeValue =
      | string -- string
      | "{" Expr "}" -- expr
      | Text --noSyntaxString
    // // CodeInsertion = "{" code "}"
    // // code = ~"}" any*  // anything that is not a closing brace
    Child = 
      | Element --element
      | Text -- text
      | "{" Expr "}" -- expr

    Text = (#alnum | "/" | "!".."(" | "*".."." | ":"..";" | "?".."@" | "[".."\`")+


    PipedLine = "|" Expr

    Labeled = (alnum | " ")+ ":" Expr
      
    InterpolatedString =
      | "\`" #" "* (#" "+ | InterpolatedSegment)* "\`"

    InterpolatedSegment =
      | "$"? "{" Expr "}"  --interpolation
      | ~"\${" ~"{" ~"\`" any  --text
      
    Not =
      | "!" Primary
      
    Negate =
      | "-" Primary

    DeclareConst =
      | identifier ":=" Expr

    Assign =
      | Primary "=" Expr

    DeclareVar =
      | "var" identifier "=" Expr

    PlusEquals =
      | Primary "+=" Expr

    MinusEquals =
      | Primary "-=" Expr
    
    Or =
      | Or ("OR" | "or" | "||") And -- eq
      | And
      
    And =
      | And ("AND" | "and" | "&&") Eq --asfd
      | Eq

    Binding =
      | Primary ("<:>" | "<:" | "::>" | ":>") "&"? Primary
      
    Eq =
      | Eq "==" Ne -- add
      | Ne
      
    Ne =
      | Ne "!=" Lt -- one
      | Lt
      
    Lt =
      | Lt "<" Lte --one
      | Lte
      
    Lte =
      | Lt "<=" Gt --one
      | Gt

    Gt =
      | Lt ">" Gte --one
      | Gte
      
    Gte =
      | Lt ">=" Additive --one
      | Additive
    

    Additive
      =
      | Additive "+" Multiplicative --add
      | Additive "-" Multiplicative --subtract
      | Multiplicative

    Multiplicative
      =
      | Multiplicative "*" Primary --multiply
      | Multiplicative "/" Primary --divide
      | Primary


    Boolean =
      | "TRUE"
      | "FALSE"
      | "true"
      | "false"

    Null = "NULL" | "null"

    ClosureArrow = "=>" | "->"
    Closure = 
      | ClosureArrow spaces Expr? --noParams
      | identifier spaces ClosureArrow spaces Expr? --singleIdentifier
      | "("? spaces ListOf<identifier, ","> ","? spaces ")"? spaces ClosureArrow spaces Expr? --identifierList

    DictionaryEntry = DictionaryKey ":" Expr
    DictionaryKey = oldRhinos | string | ObjectRef --ref

    DictionaryMod =
      | ObjectRef
      | oldRhinos
      | "[" Primary "]" -- eval

    Dictionary = 
      | DictionaryMod? "{" spaces "}" -- emptyDict
      | DictionaryMod? "{" spaces ListOf<DictionaryEntry, ","> spaces "}" --dict

    Array =
      | "X"? "[" spaces "]" --emptyArray
      | "X"? "[" spaces ListOf<Expr, ","> spaces "]"? --ary

    Primary
      =
      | Binding
      | Null
      | Element
      | InterpolatedString
      | Primary spaces "?" spaces Primary spaces ":" spaces Primary -- tertiary
      | Closure
      | Dictionary
      | Array
      | Not
      | Negate
      | Boolean
      | integer
      | string
      | FunctionCall
      | "(" Expr ")" --parens
      | Primary "." Accessor? -- accessor
      | Primary "[" Expr "]" -- bracketAccessor
      | ObjectRef
      | ObjectRefId
      | identifier
      

    FunctionCall =
      | Primary "(" spaces ")"  -- noArgs
      | Primary "(" spaces ListOf<Expr, ","> spaces ")"?  -- withArgs
  
    ExprPadding = 
      spaces Expr spaces

    Accessor = oldRhinos | ObjectRef
    

    identLetter = alnum | "$" | "_"
    identifier = ~("_" "$") identLetter+

    oldRhinos = ~("_" "$") identLetter+

    integer = "-"? digit+
    string = 
      | "\\"" (~"\\"" any)* "\\""?
      | "'" (~"'" any)* "'"?

    ObjectRef
      = "_$" content "$_"

    ObjectRefId
      = "&_$" content "$_"
      
    content
      = (~"$_" any)*

    space := "\\x00".."\\x20" | "\\n"

  }
`;


