import React from 'react';
import ReactDOM from 'react-dom';
import classNames from "classnames";
import _ from 'lodash';
import { Svg } from "../components/Svg";
import { MyBlock } from "./MyBlockManager";
import { CheckBox } from "./CheckBox";
import { RenderData } from "./RenderData";
import { component } from "../component";
import { Component } from "react";
import { styled } from "../component2";
import { color } from "../components/notionDocument/color";
import { isMobile } from '../isMobile';
import { fullBlocks } from './fullBlocks';


export const Wrapper = styled.div`

  .meta {
    position: absolute;
    user-select: none;
    display: flex;
  }


  &.dragging {
    opacity: 0.2;
  }

  /* > .block {

      > .toggle {
        position: absolute;
        left: -17px;
        top: 11px;
        width: 6px;
        height: 6px;
        transform: rotate(45deg);
        cursor: pointer;
        border-right: 1px solid #c4c4c4;
        border-bottom: 1px solid #c4c4c4;
        &:hover {
          border-color: #b0b0b0;
        }
      }
    } */


  > .block {
    position: relative;
    font-size: 14px;
    line-height: 1.5;
    min-height: 22px;

    .editor {
      min-height: 21px;
      &:empty::before {
          /* content: 'Task'; */
          color: #acacac; 
      }

      &:empty:focus::before {
          content: "";
      }

    }

    > .grip {
      position: absolute;
      top: 2px;
      left: -21px;
      opacity: 0;
      transition: opacity 0.2s;
      cursor: grab;
      path {
        fill: ${color('block.actionIcon')};
      }
      height: 21px;

      &:hover {
        border-radius: 3px;
        /* fill: #bababa; */
        background: ${color('block.actionIcon.bg')};
      }
    }


    .addBlock {
      position: absolute;
      top: 3px;
      left: -41px;
      opacity: 0;
      transition: opacity 0.2s;
      cursor: pointer;
      path, & {
        fill: ${color('block.actionIcon')};
      }
      height: 24px;

      &:hover {
        border-radius: 3px;
        /* fill: #bababa; */
        background: ${color('block.actionIcon.bg')};
      }
    }


    padding: 3px 0;

    &.activeBlock {
      &.mobile {
        .entityDot, .dataBindingDot {
          width: 11px;
          height: 11px;
          margin-top: -4px;
          margin-left: -3px;
        }
      }

      .entityDot, .dataBindingDot {
        opacity: 1;
        width: 8px;
        height: 8px;
        margin-top: -2px;
        margin-left: -2px;

      }
    }


    &:not(.mobile) {

      &:hover {
        > .grip/* , .addBlock */ {
          opacity: 1;
        }
      }

      &:not(:hover) {
        /* .meta {
          opacity: .5;
        } */

        .entityDot {
          /* opacity: .5; */
        }
      }

      :hover {
        .entityDot {
          left: -30px;
        }
      }


    }


    .editor:focus {
      outline: none;
    }
  }


  /* &.collapsed {
    > .block {
      > .toggle {
        transform: rotate(315deg);
        margin-left: -2px;
        border-color: #595959;

      }
    }
  } */

  .children {
    /* padding-left: 18px; */
    padding-left: 24px;
  }
`;

@component
class ReactiveWrapper extends Component<{ func }> {
  el
  componentDidMount(): void {
    this.el = ReactDOM.findDOMNode(this);
  }
  render() {
    return this.props.func(this.el);
  }
}

class BlockComponent extends Component<{
  depth,
  args,
  block2: MyBlock
  systemCtx,
  draggingId,
  activeBlock,
  onMouseDownGrip,
  onContextMenu,
  beforeChange,
  changed,
  renderDataRef,
  ctx,
  noChildren,
  onClickAddBlock,
}> {
  render() {
    const {
      args,
      block2,
      activeBlock,
      onMouseDownGrip,
      onContextMenu,
      renderDataRef,
      ctx,
    } = this.props;

    const block: { collapsed, _id, textColor, identifier, textAlign, valuePoint, color, children, type, checked, id, record, dataBinding, attributes, media, query, icon, document } = block2.block;
  
    let meta = [];

    if (args.meta) {
      meta.push(<ReactiveWrapper 
        func={() => {
          return args.meta(block)
        }}
        />);
    }
  

    return (
      // <Wrapper
      //   key={block._id}
      //   data-type="blockCont"
      //   data-depth={depth}
      //   className={classNames(block.type, {
      //     dragging: block._id == draggingId,
      //     mobile: isMobile(),
      //     collapsed: block2.isCollapsed(),
      //   })}
      // >
        <div 
          data-block-id={block._id}
          data-input-id={block._id}
          data-inspect-id={block.id}
          className={classNames('block', {
            activeBlock: block._id == activeBlock,
            mobile: isMobile(),
          })}
        >
          {!isMobile() && (
            <>
              <Svg name="grip" className="grip"
                onMouseDown={e => {
                  onMouseDownGrip(e, block);
                }}
                onContextMenu={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  onContextMenu(e, block);
                }}
              />
  
            </>
          )}
          {(
            <>

              {/* {block2.hasChildren() && <div contentEditable={false} className="toggle"
                style={{
                  left: -19 - (args.childPadding || 25)*depth,
                }}
                onMouseDown={(e) => {
                  if (e.shiftKey) {
                    let allCollapsed = true;
                    for (const child of block.children) {
                      if (!child.collapsed) {
                        allCollapsed = false;
                        break;
                      }
                    }
                    if (allCollapsed) {
                      for (const child of block.children) {
                        child.collapsed = false;
                      }  
                    }
                    else {
                      for (const child of block.children) {
                        child.collapsed = true;
                      }  

                    }

                  }
                  else {
                    block.collapsed = !block.collapsed;
                  }
                  this.forceUpdate();
                  e.stopPropagation();
                  e.preventDefault();
                }}
               />} */}

  
              
              
              <RenderData
                __reactive={false}
                ref={renderDataRef?.(block._id)}
                tag="div"
                attrs={{
                  className: 'editor',
                  'data-type': "blockData",
                  style: {
                    textAlign: block.textAlign,
                  }
                }}
                ctx={ctx}
                data={() => block2.getContent()}
                args={{
                  blockId: block._id,
                  pageId: args.docId,
                  obj: args.obj,
                }}
                highlighter={args.highlighter && ((el, pos) => {
                  args.highlighter(el, block._id, pos);
                })}
              />

              {meta.length > 0 && (
                <div className="meta" style={{visibility:'hidden'}} contentEditable={false}>
                  {meta}
                </div>
              )}
            </>
          )}
        </div>

        // {!block2.isCollapsed() && !noChildren && (
        //   <div className="children" data-type="blockChildren">
        //     {block2.hasChildren() && block2.getChildren().map(b => renderBlock(b, {
        //       onMouseDownGrip,
        //       draggingId,
        //       changed,
        //       activeBlock,
        //       beforeChange,
        //       onClickAddBlock,
        //       onContextMenu,
        //       ctx,
        //       systemCtx,
        //       renderDataRef,
        //       depth: depth + 1,
        //     }, args))}
        //   </div>
        // )}
      // </Wrapper>
    );
  }

  shouldComponentUpdate(nextProps: Readonly<{ depth: any; args: any; block2: MyBlock; systemCtx: any; draggingId: any; activeBlock: any; onMouseDownGrip: any; onContextMenu: any; beforeChange: any; changed: any; renderDataRef: any; ctx: any; noChildren: any; onClickAddBlock: any; }>, nextState: Readonly<{}>, nextContext: any): boolean {
    return false;
  }
}

export function renderBlock(
  block2: MyBlock,
  {
    depth,
    onMouseDownGrip,
    draggingId,
    beforeChange,
    changed,
    activeBlock,
    noChildren = false,
    onClickAddBlock,
    onContextMenu,
    ctx,
    systemCtx,
    renderDataRef = undefined,
  },
  args: {
    docId
    obj
    onClickEntityDot,
    onClickGrip?
    entity?
    margin?
    highlighter?
    scriptBadgesArgs?
    meta?
    childPadding?
  }
) {
  return <BlockComponent
    key={block2.getId()}
    depth={depth}
    activeBlock={activeBlock}
    args={args}
    beforeChange={beforeChange}
    block2={block2}
    changed={changed}
    ctx={ctx}
    draggingId={draggingId}
    noChildren={noChildren}
    onClickAddBlock={onClickAddBlock}
    onContextMenu={onContextMenu}
    onMouseDownGrip={onMouseDownGrip}
    renderDataRef={renderDataRef}
    systemCtx={systemCtx}
  />

}
