import React from 'react';
import _ from 'lodash';
import { component } from '../../component2';
import { DraftSelect } from '../../etc/draftHelpers';
import { x, XObject } from '../../XObject';
import { showContextMenu } from '../../helpers';
import { ValuePoint } from '../ValuePoint';
import { Comp } from '../Comp';
import {
  _iterate,
  addValuePoint,
  CompiledValuePoint,
  execute,
  getParameter,
  getStructureParameter,
  resolveTypeList,
  ValuePointProps,
  ValuePointType
} from '../main';
import { Runtime } from "../Runtime";
import { registerType } from "../__typeRegistry";
import { ValueType } from "../ValueType";
import { contextVars, registerTypeRegister } from '../typeRegistering';


@component
export class ContextArgValuePoint extends Comp<ValuePointProps> {
  render() {
    const id = this.props.state.content.id;
    const p = contextVars[id];
    if (p) {
      return <>{p.label}</>;
    }
    else {
      return <span style={{ color: 'red' }}>Parameter not found</span>;
    }
  }
}

export function executeParam(value: ValuePointType, rt: Runtime): CompiledValuePoint {
  return {
    _id: value._id,
    type: value.type,
    content: value.content,
    rt,
    isState: false,
    presentation: value.presentation,
  };
}

const registerContextArgType = () => {
  registerType(ValueType.ContextArg, {
    execute: (value, rt) => {
      return executeParam(value, rt);
    },
    evaluate: (value, map) => {
      return map[value.content.id];
    },
    render: (value, map, state, renderType) => {
      if (_.isString(value.content?.id) && value.content?.id in map) {
        return map[value.content?.id];
      }
    },
    isBlock: value => {
      return false;
    },
    iterate: (value, parent, func, set) => {

    },
    editorComp: ContextArgValuePoint,
  });
};

registerTypeRegister(registerContextArgType);