import React from 'react';
import styled from 'styled-components';
import {component} from '../../component2';
import {_Editor} from '../../etc/draftHelpers';
import {Comp} from '../Comp';
import {ValuePointProps} from '../main';
import {registerChangeAction, trackChange, UndoActions} from '../changes';
import {ValueType} from '../ValueType';
import {registerType} from "../__typeRegistry";
import { ObjectPicker } from '../../components/ObjectPicker';
import { XObject } from '../../XObject';
import { ObjectDisplay } from '../../components/ObjectDisplay';
import { registerTypeRegister } from '../typeRegistering';

const Set = 'bef83c95-185d-5deb-b0b8-786fd94c6f76'

registerChangeAction(Set, {
  name: 'Set String',
});

@component
export class ObjectRefValuePoint extends Comp<ValuePointProps> {
  static styles = styled.span`

  `;
  timerId
  render() {
    const state = XObject.get(this.props.state, 'content', {});

    return (
      <>
        {!state.ref && <ObjectPicker
          _onSelect={value => {
            state.ref = value;
          }}
        />}

        {state.ref && <ObjectDisplay showPath obj={state.ref} />}
        
      </>
    );
  }
}

export const registerObjectRefType = () => {
  registerType(ValueType.ObjectRef, {
    evaluate: (value, map) => {
      // return value.content;
    },
    render: (value, map, state, renderType) => {
      // return <span key={value._id} data-value-point={value._id}>{value.content}</span>;
    },
    editorComp: ObjectRefValuePoint,
  });
};

registerTypeRegister(registerObjectRefType)