import { ValueType } from "../ValueType";
import { mapStructure, render, renderParams, renderWithParams } from "../main";
import { structParamRenderers, structRenderers, structScriptEvaluators } from "../structRenderers";
import { typeRegistry } from "../typeRegistry.1";
import { CompiledValuePointRef } from "../valuePoints/StructureRef";

export const $RouterSwitch = typeRegistry.registerType({
  $: '6dbcc467-d6a8-5943-a7bd-dc6cf9db9627',
  Routes: {
    $: 'f9db5937-39f3-5087-a482-e59fa0de8b33',
    $$: '2846365f-79d0-5256-ae73-88528ccc7477',
    Path: '03a8ceaf-af6e-564c-81da-d8e87f845ff7',
    Content: '466e265e-82fb-57ba-8193-b0a97c171dbd',
  }
}, ids => ({
  _id: ids.$,
  name: 'Router Switch',
  definition: [
    {
      id: ids.Routes.$,
      name: 'Routes',
      type: [ValueType.Array, [ValueType.Structure, ids.Routes.$$, [
        {
          id: ids.Routes.Path,
          name: 'Path',
          type: [],
          property: 'path',
        },
        {
          id: ids.Routes.Content,
          name: 'Content',
          type: [],
          property: 'content',
        },
      ]]],
      property: 'routes',
    }
  ],
}));

structScriptEvaluators[$RouterSwitch.$] = ({value, map}) => {
  return new CompiledValuePointRef(value, map);
}

structParamRenderers[$RouterSwitch.$] = ({value, map,rt}) => {
  const location = map['ba2dd60c-5e65-531e-9824-4fa808e9dd1e'].location;
  const route = mapStructure(value).routes.content.find(r => {
    if (mapStructure(r).path.content == location) {
      return true;
    }
  })
  return (
    <>
      {renderWithParams({
        ...renderParams(mapStructure(route).content, map),
        rt,
      })}
    </>
  )
}