import { El } from "../shorthand/El";
import { renderEl } from "../shorthand/formula";
import { scriptComponents } from "./registerScriptComponent";

export class ScriptComponent {
  constructor(public id, public childPath=[]) {}
  render(el: El, rt) {
    const sc = scriptComponents[this.id];
    return sc.render(el, rt);
    // return renderEl(newEl, rt);
    
    return <button onClick={() => {
      console.log(sc, this, el);
    }}>.</button>
  }
}
