import { db } from '../db';
import { XObject } from '../XObject';
import classNames from 'classnames';
import { EntityHandle } from '../components/EntityHandle';
import { PriorityIcon } from './PriorityIcon';
import { displayDateString } from '../components/LinearWindow';
import { linearData, update } from './linearData';
import { Component } from 'react';
import { component, styled } from '../component2';
import Sugar from 'sugar';
import { showContextMenu } from '../helpers';
import { entityContextMenuItems } from '../components/EntityDisplay';
import { renderQueuePositions } from '../glue/getQueue';
import { createEntity, getAllEntities, getEntityById } from './createEntity';
import { getAllEdges } from '../getAllEdges';

@component
class LinearRow extends Component<{ row, data, _onClick, active?, rootEntity?, graph?, entity? }> {
  static styles = styled.div`
    display: flex;
    height: 44px;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 0 8px;

    &:hover {
      background: #f4f4f4;
    }

    &.active {
      background: #eee;
    }
    .priority, .key {
      display: flex;
      align-items: center;
      align-content: center;
      * > {
      }
    }

    .priority, .key, .created, .entity, .parent, .state {
      margin-right: 9px;
    }

    .state {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      flex: 0 0 auto;
    }

    .sep {
      margin-left: auto;
    }

    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }


    .key {
      width: 58px;
      white-space: nowrap;
    }
    .created {
      white-space: nowrap;
    }

    .parentIssue {
      &:before {
        content: "›";
        margin-right: 2px;
      }
      margin-left: 4px;
      font-size: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .parent {
      white-space: nowrap;
    }
    .parent, .entity {
      font-size: 8px;
      border: 1px solid #ccc;
      border-radius: 8px;
      padding: 0px 4px;
    }

    .queue {
      white-space: nowrap;
      font-size: 8px;
    }

    .assignee {
      display: flex;
      align-items: center;
      img {
        width: 18px;
        height: 18px;
        border-radius: 50%;
      }
    }

    &.completed {
      .title {
        text-decoration: line-through;
        color: gray;
      }
    }
  `;

  render(Container?) {
    const { data, row } = this.props;
    const team = data.teams.find(p => p.id == row.team);
    const assignee = data.users.find(p => p.id == row.assignee);

    const entity = getAllEntities().find(e => e.linearId == row.id);

    const parentIssue = row.parent && data.issues.find(p => p.id == row.parent);

    const parentId = entity && getAllEdges().find(e => e.entities[1] == entity._id && e.directed)?.entities?.[0];
    const state = data.states.find(p => p.id == row.state);

    return (
      <Container
        className={classNames(state.type, { active: this.props.active })}
        onClick={e => {
          e.stopPropagation();
          this.props._onClick();
        }}
        onContextMenu={e => {
          e.stopPropagation();
          e.preventDefault();
          showContextMenu(e, entityContextMenuItems({
            id: entity._id,
            rootId: this.props.rootEntity,
            graphId: this.props.graph,
          }));
        }}
      >
        <span className="priority"><PriorityIcon priority={row.priority} /></span>
        <a className="key" target="_blank" href={row.url}>{team.key}-{row.number}</a>
        <span className="state" title={state?.name} style={{ backgroundColor: state?.color }} />
        <span className="title"
        
        
        data-value={entity?._id} onClick={e => {
          if (this.props._onClick) {
            e.stopPropagation();
            this.props._onClick();
          }
        }}>{row.title}</span>
        {parentIssue && <span className="parentIssue">
          {parentIssue.title}
        </span>}
        <span className="sep" />
        {entity && this.props.rootEntity && <div className="queue">{renderQueuePositions(this.props.rootEntity, entity._id)}</div>}

        <span className="entity">
        {entity ?
                <EntityHandle id={entity._id}>#</EntityHandle>
              : <button onClick={() => {
                  const entity = XObject.obj({
                    linearId: row.id,
                    name: row.title,
                    stateful: true,
                  });
                  createEntity(entity, this.props.rootEntity);
                }}>Create</button>}

        </span>
        {parentId && <span className="parent">
        {parentId && getEntityById(parentId)?.name}
        </span>}
        <span className="created">{(Sugar.Date.create(row.createdAt) as any).format('{Mon} {d}')}</span>
        <span className="assignee">{assignee && <img src={assignee.avatarUrl} />}</span>
      </Container>
    )
  }
}

export function renderLinearIssuesLinear({
  issues,
  groupByState = false,
  data,
  active,
  onClick,
  renderPath = undefined,
  disableCols = undefined,
  sort = undefined,
  rootEntity = undefined,
  graph = undefined,
}) {
  if (!disableCols) disableCols = {};
  function render(issues) {
    return (
      <div>
        {issues.map(x => {
          return <LinearRow key={x.id} row={x} data={data}
          _onClick={() => {
            onClick?.(x.id);
          }} active={active == x.id}
          
          rootEntity={rootEntity}
          graph={graph}
          />
        })}
        
      </div>
    )
  }

  if (sort) {
    issues.sort((a, b) => {
      if (sort == 'createdAt') {
        return -(new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
      }
      else if (sort == 'priority') {
        // sort from highest to lowest priority, but put zero priority at end
        // then sort by created at (newest first)
        if (a.priority == b.priority) {
          return -(new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
        }
        else if (a.priority == 0) {
          return 1;
        }
        else if (b.priority == 0) {
          return -1;
        }
        else {
          return a.priority - b.priority;
        }


        
      }

    });
  }

  // group issues by state id
  const states = {};
  for (const issue of issues) {
    if (!states[issue.state]) states[issue.state] = [];
    states[issue.state].push(issue);
  }


  // state ids sorted by position
  const stateIds = Object.keys(states).sort((a, b) => {
    const stateA = data.states.find(s => s.id == a);
    const stateB = data.states.find(s => s.id == b);
    return stateA.position - stateB.position;
  });

  // render issues by state, with a header for each state
  // <h3>State Name</h3>
  // <table>...</table>
  let c;

  if (groupByState) {
    const result = [];
    for (const stateId of stateIds) {
      if (!states[stateId]) continue;
      const state = data.states.find(s => s.id == stateId);
      if (!state) continue;
      result.push(<h3 style={{ color: state.color }}>{state.name}</h3>);
      result.push(render(states[stateId]));
    }
    c = result;
  }
  else {
    c = render(issues);
  }

  return (
    <>
      <button onClick={async () => {
        await update();
        alert('done');
      }}>Update</button>
      {c}
    </>
  )
    

  // return render(issues);
}


export function renderLinearIssuesTable(issues, data, active, onClick, renderPath?, disableCols: any={}) {

  function render(issues) {
    return <table border={1}>
      <thead>
        <tr>
          <th />
          <th></th>
          <th>Title</th>
          {!disableCols.state && <th>State</th>}
          <th>Date</th>
          <th>Updated</th>
          {!disableCols.entity && <th>Entity</th>}
          {!disableCols.parent && <th>Parent</th>}
        </tr>
      </thead>
      <tbody>
        {issues.map(x => {
          const entity = getAllEntities().find(e => e.linearId == x.id);
          const state = data.states.find(s => s.id == x.state);

          const parentId = entity && getAllEdges().find(e => e.entities[1] == entity._id && e.directed)?.entities?.[0];
          return (
            <tr key={x.id}>
              <td><a target="_blank" href={x.url}>$</a></td>
              <td><PriorityIcon priority={x.priority} /></td>
              <td
               
                style={{ cursor: 'pointer', minWidth: '200px' }}
                className={x.id == active ? 'active' : ''}
                onClick={() => {
                  onClick(x.id);
                }}
              >
                <div data-value={entity?._id}>
                {x.title}
                </div>
                {entity && renderPath && renderPath(entity._id)}
                
              </td>
              {!disableCols.state && <td>{state ? state.name : ''}</td>}
              <td style={{ whiteSpace: 'nowrap' }}>{displayDateString(x.createdAt)}</td>
              <td style={{ whiteSpace: 'nowrap' }}>{displayDateString(x.updatedAt)}</td>
              {!disableCols.entity && <td>{entity ?
                <EntityHandle id={entity._id}>#</EntityHandle>
                : <button onClick={() => {
                  const entity = XObject.obj({
                    linearId: x.id,
                    name: x.title,
                  });
                  createEntity(entity, null); // createEntityNull
                }}>Create</button>}</td>}
                {!disableCols.parent && <td>
                {parentId && getEntityById(parentId)?.name}
              </td>}
            </tr>
          );
        })}
      </tbody>
    </table>
  }

  // group issues by state id
  const states = {};
  for (const issue of issues) {
    if (!states[issue.state]) states[issue.state] = [];
    states[issue.state].push(issue);
  }


  // state ids sorted by position
  const stateIds = Object.keys(states).sort((a, b) => {
    const stateA = data.states.find(s => s.id == a);
    const stateB = data.states.find(s => s.id == b);
    return stateA.position - stateB.position;
  });

  // render issues by state, with a header for each state
  // <h3>State Name</h3>
  // <table>...</table>
  const result = [];
  for (const stateId of stateIds) {
    if (!states[stateId]) continue;
    const state = data.states.find(s => s.id == stateId);
    if (!state) continue;
    result.push(<h3>{state.name}</h3>);
    result.push(render(states[stateId]));
  }

  return (
    <>
      <button onClick={async () => {
        await update();
        alert('done');
      }}>Update</button>
      {result}
    </>
  )
    

  // return render(issues);
}
