
export const selectorGrammarDef = `

Css {
  SelectorList = ListOf<Selector, ",">

  identLetter = alnum | "-"
  identifier = identLetter+

  tag = identifier | "*"

  modifier =
    | "." identifier -- class
    | "#" identifier --id
    | "[" identifier "]" --attrNoValue
    | "[" identifier "=" attrValue "]" --attrValue
    | ":" identifier "(" param ")" -- thing2
    | ":" identifier -- thing

  string = 
    | "\\"" (~"\\"" any)* "\\""?
    | "'" (~"'" any)* "'"?
    
  attrValue = 
  	| string
    | identifier
    
  param = 
  	| alnum+
    | selectorComp
    
  selectorComp = (tag | "&")? modifier*
  
  selectorEl = ">>>" | ">" | selectorComp
  
  
  Selector =
    | ListOf<selectorEl, #" ">
}

`