import { AttributeType } from '../components/AttributeType';
import _ from 'lodash';
import Sugar from 'sugar';
import { unarchivedEntities } from "../components/unarchivedEntities";
import { getReferences } from "../components/getReferences";
import { db } from '../db';
import { getEdgesForEntity } from './getEdgesForEntity';
import { queryGraphBasic } from './queryGraph';
import { CHAINED_ENTITY, Conjuction, DateOperator, ExpressionEntryType, FORMULA, SetValueOperator, SetValuesOperator, StructuredSetValueOperator, TypeOperator } from './expressionQuery';
import { execute } from '../glue/main';
import { $EntityQuery, doCreateEntityFromQuery, doEntityQuery } from '../glue/structs/$EntityQuery';
import { XObject, x } from '../XObject';
import { ObjectType } from '../types/ObjectRef';
import { entitiesInSpace } from './entitiesInSpace';
import { collectEntities } from "./collectEntities";
import { ViewType } from '../types/ViewType';
import { execFormulaFromData } from '../shorthandEditor/execFormulaFromData';
import { FormulaObjectWrapper } from '../FormulaObjectWrapper';
import { appState } from './appState';
import { getAllEntities, getEntityById } from './createEntity';

export const QUERY = '07edd6c1-5106-53a5-960d-e64dde3df51b';
const CHAINED_QUERY = '51fee6cb-026b-55f5-be5a-24cf46f1945d';

export const GLUE_QUERY = '67ff7117-c2c6-5542-aa25-cf7e88a5861b';
export const EXPRESSION_QUERY = 'a68e16af-528a-5808-9166-1cf5479b4850';
export const SPACE_QUERY = '42e80ef3-965f-5e21-a327-040b140aafcb';
const PAGE_ENTITIES_QUERY = 'a56c95ca-1ef2-5fa7-bb03-5f1fe5f8b1a3';

export function queryChain(query) {
  const objs = [];
  while (true) {
    objs.unshift(query.query);
    if (!query.parent) break;
    if (query.parentType == ObjectType.space) {
      objs.unshift([SPACE_QUERY, query.parent]);
      break;
    }
    else if (query.parentType == ObjectType.page) {
      objs.unshift([PAGE_ENTITIES_QUERY, query.parent]);
      break;
    }
    else if (query?.parent?.type == ObjectType.formula || query?.parent?.type == ObjectType.entity || query?.parent?.type == ObjectType.mode || query?.parent?.type == ObjectType.page) {
      break;
    }
    else {
      query = db.queries.findById(query.parent);
    }
  }

  return [CHAINED_QUERY, objs];
}

export function createQuery(query, context?, parent?, relative?) {
  const q = XObject.obj({
    context,
    query: query || [EXPRESSION_QUERY, {
      _id: 'root',
      type: ExpressionEntryType.group,
      conjunction: Conjuction.and,
      entries: [],
    }],
    parent,
    views: [
      XObject.obj({
        type: ViewType.list,
      })
    ],
    relative,
  });
  db.queries.push(q);
  return q;
}

export function query_spaceEntities(id) {
  return ['3d652e9a-e63f-5a70-978e-ebf3e0905f99', id];
}

export function query_entityReferences(id) {
  return [ '07a162d6-cf2c-5d47-a46d-1af86899db18', id ]
}

export function query_entityType(id) {
  return ['577e61ee-a33d-5bcb-a2f5-bef1763a32ee', id];
}

export function query_entityDescendants(id) {
  return ['b19572c2-2f49-5dde-85dd-3fa5c3e02340', id];
}

export function query_entityChildren(id) {
  return ['a3e9c4df-92db-5aa2-92a9-ff578f8c143a', id];
}

function resolveEntity(entry, baseId) {
  let asdf;
  if (entry.entity == CHAINED_ENTITY) {
    asdf = baseId;
  }
  else if (entry.entity == FORMULA) {
    asdf = execFormulaFromData(entry.entityFormula, {
      this: new FormulaObjectWrapper({
        type: ObjectType.entity,
        id: baseId,
      }, baseId),
      base: baseId,
    }, true);
  }

  if (asdf) {
    asdf = getEntityById(asdf);
  }

  return asdf;
}


export function executeEventOccurrencesQuery(query, result?): string[] {
  if (query[0] == EXPRESSION_QUERY) {
    const testFunc = entry => {
      if (entry.type == ExpressionEntryType.group) {
        if (entry.conjunction == Conjuction.and) {
          const testFuncs = {};
          for (const e of entry.entries) {
            testFuncs[e._id] = testFunc(e);
          }
          return entity => {
            for (const e of entry.entries) {
              if (!testFuncs[e._id](entity)) return false;
            }
            return true;
          }
        }
        else if (entry.conjunction == Conjuction.or) {
          return entity => {
            for (const e of entry.entries) {
              if (testFunc(e)(entity)) return true;
            }
            return false;
          }
        }
      }
      else if (entry.type == ExpressionEntryType.eventOccurrence_event) {
        if (entry.operator.type == TypeOperator.is) {
          return entity => {
            return entry.operator.typeValues?.includes?.(entity?.event);
          }
        }
        else if (entry.operator.type == TypeOperator.isNot) {
          return entity => {
            return !entry.operator.typeValues.includes(entity?.event);
          }
        }
      }
    }
    const t = testFunc(query[1]);
    if (result) {

    }
    else {
      result = db.eventOccurrences.filter(eo => {
        return eo.mode == appState.currentMode && t(eo);
      }).map(eo => eo._id);
    }
  }

  return result || [];
}

export function executeEntityQuery(query, result?, baseId?, baseRels={}): string[] {
  if (!query) return [];
  if (query[0] == QUERY) {
    const execEntry = entry => {
      if (!entry) return;
      if (entry[0] == '07a162d6-cf2c-5d47-a46d-1af86899db18') {
        result = getReferences(entry[1]).map(r => r.sourceEntity);
      }
      else if (entry[0] == '577e61ee-a33d-5bcb-a2f5-bef1763a32ee') {
        if (!result) {
          result = unarchivedEntities(baseId).filter(e => e.type == entry[1]).map(e => e._id);
        }
        else {
          result = result.filter(e => getEntityById(e)?.type == entry[1])
        }
      }
      else if (entry[0] == '3d652e9a-e63f-5a70-978e-ebf3e0905f99') {
        if (!result) {
          result = getAllEntities().filter(e => e.space == entry[1]).map(e => e._id);
        }
        else {
          result = result.filter(e => getEntityById(e).space == entry[1]);
        }
      }
      else if (entry[0] == 'b19572c2-2f49-5dde-85dd-3fa5c3e02340') {
        if (!result) {
          result = queryGraphBasic(entry[1], false, false, false).map(e => e.entity);
        }
        else {
          throw new Error('unsupported');
        }
      }
      else if (entry[0] == 'a3e9c4df-92db-5aa2-92a9-ff578f8c143a') {
        result = getEdgesForEntity(entry[1]).filter(e => {
          return e.directed && e.entities[0] == entry[1];
        }).map(e => {
          return e.entities[1];
        });
      }
      else {
        throw new Error('Unknown query entry: ' + entry[0]);
      }
    }

    for (const entry of query[1]) {
      execEntry(entry);
    }
  }
  else if (query[0] == CHAINED_QUERY) {
    for (const q of query[1]) {
      result = executeEntityQuery(q, result, baseId, baseRels);
    }
  }
  else if (query[0] == SPACE_QUERY) {
    result = entitiesInSpace(db.spaces.findById(query[1])).map(e => e._id);
  }
  else if (query[0] == PAGE_ENTITIES_QUERY) {
    if (query[1].length == 2) {
      const entity = getEntityById(query[1][0]);
      const doc = entity.documents.find(doc => doc._id == query[1][1]);
      result = collectEntities(doc.content || []);
    }
  }
  /*else if (query[0] == GLUE_QUERY) {
    if (result) {
      throw new Error('GLUE_QUERY does not support chaining');
    }
    const value = execute(query[1]);
    if (!value.type) return [];
    return doEntityQuery(value, {
      [$EntityQuery.ChainedEntity]:baseId
    });
  }*/
  else if (query[0] == EXPRESSION_QUERY) {
    const keys = [];
    queryCacheKeys(query, keys, baseId);

    const testFunc = entry => {
      if (entry.type == ExpressionEntryType.group) {
        if (entry.conjunction == Conjuction.and) {
          const testFuncs = {};
          for (const e of entry.entries) {
            testFuncs[e._id] = testFunc(e);
          }
          return entity => {
            for (const e of entry.entries) {
              if (!testFuncs[e._id](entity)) return false;
            }
            return true;
          }
        }
        else if (entry.conjunction == Conjuction.or) {
          return entity => {
            for (const e of entry.entries) {
              if (testFunc(e)(entity)) return true;
            }
            return false;
          }
        }
      }
      else if (entry.type == ExpressionEntryType.type) {
        if (entry.operator.type == TypeOperator.is) {
          return entity => {
            return entry.operator.typeValues?.includes?.(entity?.type);
          }
        }
        else if (entry.operator.type == TypeOperator.isNot) {
          return entity => {
            return !entry.operator.typeValues.includes(entity?.type);
          }
        }
      }
      else if (entry.type == ExpressionEntryType.space) {
        if (entry.operator?.type == SetValueOperator.Is) {
          return entity => {
            return entry.operator.values?.includes?.(entity.space);
          }
        }
        else if (entry.operator?.type == SetValueOperator.IsNot) {
          return entity => {
            return !entry.operator.values.includes(entity.space);
          }
        }
      }
      else if (entry.type == ExpressionEntryType.tags) {
        if (entry.operator?.type == SetValuesOperator.Contains) {
          return entity => {
            return entry.operator.values?.some?.(v => Object.keys(x(entity.tags) || {}).includes(v));
          }
        }
        // else if (entry.operator?.type == SetValueOperator.IsNot) {
        //   return entity => {
        //     return !entry.operator.values.includes(entity.space);
        //   }
        // }
      }
      else if (entry.type == ExpressionEntryType.children) {
        const asdf = resolveEntity(entry, baseId);
        if (!asdf) return () =>{};
        const children = getEdgesForEntity(asdf._id).filter(e => {
          return e.directed && e.entities[0] == asdf._id;
        }).map(e => {
          return e.entities[1];
        });

        const map = {};
        for (const child of children) {
          map[child] = true;
        }
        return entity => {
          const r = map[entity._id];
          if (r) {
            baseRels[entity._id] = ['children'];
          }
          return r;
        }
      }
      else if (entry.type == ExpressionEntryType.attributeValue) {
        const asdf = resolveEntity(entry, baseId);
        return e => {
          return asdf?.attributes?.[entry.attribute]?.includes?.(e._id);
        }
      }
      else if (entry.type == ExpressionEntryType.descendants) {
        const descendants = queryGraphBasic(baseId, false, false, false).map(e => e.entity);
        const map = {};
        for (const child of descendants) {
          map[child] = true;
        }
        return entity => {
          if (entry.includeSelf) {
            if (entity._id == baseId) return true;
          }
          const r = map[entity._id];

          if (r) {
            baseRels[entity._id] = ['descendants'];
          }

          return r;
        }
      }
      else if (entry.type == ExpressionEntryType.attribute) {
        const attr = db.attributeTypes.findById(entry.attribute);
        if (attr) {
          if (attr.type == AttributeType.entity || attr.type == AttributeType.select || attr.type == AttributeType.switch) {
            if (entry.operator.type == SetValueOperator.Is || entry.operator.type == StructuredSetValueOperator.Is) {
              return entity => {
                const r = entry.operator.values.map(v => v == CHAINED_ENTITY ? baseId : v).includes(entity.attributes?.[entry.attribute]);
                if (r && entity.attributes[entry.attribute] == baseId) {
                  baseRels[entity._id] = ['attr', entry.attribute];
                }
                return r;
              }
            }
            else if (entry.operator.type == SetValueOperator.IsNot || entry.operator.type == StructuredSetValueOperator.IsNot) {
              return entity => {
                return !entry.operator.values?.map?.(v => v == CHAINED_ENTITY ? baseId : v).includes(entity.attributes?.[entry.attribute]);
              }
            }
          }
          else if (attr.type == AttributeType.entities) {
            if (entry.operator.type == SetValuesOperator.Contains) {
              return entity => {
                const r = entry.operator.values.map(v => v == CHAINED_ENTITY ? baseId : v).every(v => entity.attributes?.[entry.attribute]?.includes?.(v));
                if (r && entry.operator.values.includes(CHAINED_ENTITY) && entity.attributes[entry.attribute].includes(baseId)) {
                  baseRels[entity._id] = ['attr', entry.attribute];
                }
                return r;
              }
            }
            else if (entry.operator.type == SetValuesOperator.DoesNotContain) {
              return entity => {
                return entry.operator.values.map(v => v == CHAINED_ENTITY ? baseId : v).every(v => !entity.attributes?.[entry.attribute]?.includes?.(v));
              }
            }
          }
          else if (attr.type == AttributeType.datetime) {
            if (entry.operator.type == DateOperator.IsOnOrAfter) {
              return entity => {
                const value = entity.attributes?.[entry.attribute]
                const date = Sugar.Date.create(entry.operator.dateStr);
                return value >= date;
              }
            }
            if (entry.operator.type == DateOperator.IsOnOrBefore) {
              return entity => {
                const value = entity.attributes?.[entry.attribute]
                const date = Sugar.Date.create(entry.operator.dateStr);
                return value <= date;
              }
            }

          }
          else if (attr.type == AttributeType.richText) {
            return entity => {
              const value = entity.attributes?.[entry.attribute]
              return !value;
            }
          }
          else {
            if (entry.key) {
            
            }
          }
        }
      }
      else if (entry.type == ExpressionEntryType.formula) {
        return entity => {
          const r = execFormulaFromData(entry.formula, {
            this: new FormulaObjectWrapper({
              type: ObjectType.entity,
              id: entity._id,
            }, baseId),
            base: baseId,
          }, true);
          return r;
          if (_.isObjectLike(r)) {
            return false;
          }

          return r;
      
        }
      }
      else if (entry.type == ExpressionEntryType.query) {
        const q = db.queries.findById(entry.id);
        const r = executeEntityQuery(q.query, null, baseId, baseRels);
        const map = {};
        for (const id of r) {
          map[id] = true;
        }
        return entity => {
          return map[entity._id];
        }
      }
      return () => true;
    }

    const t = testFunc(query[1]);
    if (result) {
      result = result.filter(e => t(getEntityById(e)))
    }
    else {
      if (!query?.[1]?.entries?.length) return [];
      result = unarchivedEntities(baseId).filter(t).map(e => e._id);
    }
  }

  return result || [];
}

export function createEntityFromQuery(query, entity, baseId?) {
  if (!query) return false;
  if (query[0] == PAGE_ENTITIES_QUERY) {
    return false;
  }
  if (query[0] == QUERY) {
    const execEntry = entry => {
      if (!entry) return;
      if (entry[0] == '07a162d6-cf2c-5d47-a46d-1af86899db18') {
        return false;
      }
      else if (entry[0] == '577e61ee-a33d-5bcb-a2f5-bef1763a32ee') {
        entity.type = entry[1];
      }
      else if (entry[0] == '3d652e9a-e63f-5a70-978e-ebf3e0905f99') {
        entity.space = entry[1];
      }
      else if (entry[0] == 'b19572c2-2f49-5dde-85dd-3fa5c3e02340' || entry[0] == 'a3e9c4df-92db-5aa2-92a9-ff578f8c143a') {
        if (!entity.$edges) {
          entity.$edges = [];
        }
        entity.$edges.push({
          from: entry[1],
          directed: true,
        });
        entity.$from = entry[1];
        // return false;
      }
      // else if (entry[0] == 'a3e9c4df-92db-5aa2-92a9-ff578f8c143a') {
      //   return false;
      // }
      else {
        throw new Error('Unknown query entry: ' + entry[0]);
      }
    }

    for (const entry of query[1]) {
      if (execEntry(entry) === false) {
        return false;
      }
    }
  }
  else if (query[0] == SPACE_QUERY) {
    entity.space = query[1];
  }
  else if (query[0] == CHAINED_QUERY) {
    for (const q of query[1]) {
      if (createEntityFromQuery(q, entity, baseId) === false) {
        return false;
      }
    }
  }
  else if (query[0] == GLUE_QUERY) {
    // return false;
    const value = execute(query[1]);
    if (!value.type) return false;
    if (doCreateEntityFromQuery(entity, value, {
      [$EntityQuery.ChainedEntity]:baseId
    }) === false) {
      return false;
    }

  }
  else if (query[0] == EXPRESSION_QUERY) {
    const execEntry = entry => {
      if (!entry) return;
      if (entry.type == ExpressionEntryType.group) {
        if (entry.conjunction == Conjuction.and) {
          for (const e of entry.entries) {
            if (execEntry(e) === false) {
              return false;
            }
          }
        }
      }
      else if (entry.type == ExpressionEntryType.type) {
        if (entry.operator.type == TypeOperator.is) {
          entity.type = entry.operator.typeValues[0];
        }
      }
      else if (entry.type == ExpressionEntryType.space) {
        if (entry.operator.type == SetValueOperator.Is) {
          entity.space = entry.operator.values[0];
        }
      }
      else if (entry.type == ExpressionEntryType.descendants) {
        if (!entity.$edges) {
          entity.$edges = [];
        }
        entity.$edges.push({
          from: baseId,
          directed: true,
        });
        entity.$from = baseId;

      }
      else if (entry.type == ExpressionEntryType.children) {
        if (!entity.$edges) {
          entity.$edges = [];
        }

        const parent = resolveEntity(entry, baseId);

        if (parent) entity.$edges.push({
          from: parent?._id,
          directed: true,
        });
        entity.$from = baseId;
      }
      else if (entry.type == ExpressionEntryType.attribute) {
        const attr = db.attributeTypes.findById(entry.attribute);
        if (!entity.attributes) {
          entity.attributes = {};
        }
        if (attr.type == AttributeType.entity || attr.type == AttributeType.select || attr.type == AttributeType.switch) {
          if (entry.operator.type == SetValueOperator.Is || entry.operator.type == StructuredSetValueOperator.Is) {
            entity.attributes[entry.attribute] = entry.operator.values.map(v => v == CHAINED_ENTITY ? baseId : v)[0];
          }
          else if (entry.operator.type == SetValueOperator.IsNot || entry.operator.type == StructuredSetValueOperator.IsNot) {
            // entity.attributes[entry.attribute] = entry.operator.values.map(v => v == CHAINED_ENTITY ? baseId : v)[0];
          }
        }
        else if (attr.type == AttributeType.entities) {
          if (entry.operator.type == SetValuesOperator.Contains) {
            entity.attributes[entry.attribute] = entry.operator.values.map(v => v == CHAINED_ENTITY ? baseId : v);
          }
        }
        else {
          if (entry.key) {
          
          }
  
        }
      }
      else if (entry.type == ExpressionEntryType.formula) {
        return false;
      }

    }

    execEntry(query[1]);
  }
}

export function queryScopeAssertions(query, scopes, baseId?) {
  if (!query) return;
  if (query[0] == PAGE_ENTITIES_QUERY) {

  }

  /*if (query[0] == QUERY) {
    const execEntry = entry => {
      if (!entry) return;
      if (entry[0] == '07a162d6-cf2c-5d47-a46d-1af86899db18') {
        return false;
      }
      else if (entry[0] == '577e61ee-a33d-5bcb-a2f5-bef1763a32ee') {
        entity.type = entry[1];
      }
      else if (entry[0] == '3d652e9a-e63f-5a70-978e-ebf3e0905f99') {
        entity.space = entry[1];
      }
      else if (entry[0] == 'b19572c2-2f49-5dde-85dd-3fa5c3e02340' || entry[0] == 'a3e9c4df-92db-5aa2-92a9-ff578f8c143a') {
        if (!entity.$edges) {
          entity.$edges = [];
        }
        entity.$edges.push({
          from: entry[1],
          directed: true,
        });
        entity.$from = entry[1];
        // return false;
      }
      // else if (entry[0] == 'a3e9c4df-92db-5aa2-92a9-ff578f8c143a') {
      //   return false;
      // }
      else {
        throw new Error('Unknown query entry: ' + entry[0]);
      }
    }

    for (const entry of query[1]) {
      if (execEntry(entry) === false) {
        return false;
      }
    }
  }*/
  if (query[0] == SPACE_QUERY) {
    scopes.push({
      type: ObjectType.space,
      id: query[1],
    });
  }
  if (query[0] == CHAINED_QUERY) {
    for (const q of query[1]) {
      queryScopeAssertions(q, scopes, baseId)
    }
  }
  /*if (query[0] == GLUE_QUERY) {
    const value = execute(query[1]);
    if (!value.type) return false;
    if (doCreateEntityFromQuery(entity, value, {
      [$EntityQuery.ChainedEntity]:baseId
    }) === false) {
      return false;
    }
  }*/
  if (query[0] == EXPRESSION_QUERY) {
    const execEntry = entry => {
      if (!entry) return;
      if (entry.type == ExpressionEntryType.group) {
        if (entry.conjunction == Conjuction.and) {
          for (const e of entry.entries) {
            execEntry(e);
          }
        }
      }
      else if (entry.type == ExpressionEntryType.type) {
        if (entry.operator.type == TypeOperator.is) {
          if (entry.operator.typeValues?.[0]) scopes.push({
            type: ObjectType.type,
            id: entry.operator.typeValues[0],
          });
        }
      }
      else if (entry.type == ExpressionEntryType.space) {
        if (entry.operator?.type == SetValueOperator.Is) {
          if (entry.operator.values?.[0]) scopes.push({
            type: ObjectType.space,
            id: entry.operator.values[0],
          });
        }
      }
      else if (entry.type == ExpressionEntryType.descendants) {
      }
      else if (entry.type == ExpressionEntryType.attribute) {

      }

    }

    execEntry(query[1]);
  }
}

export function queryCacheKeys(query, keys, baseId?) {
  if (!query) return;
  if (query[0] == PAGE_ENTITIES_QUERY) {

  }
  
  /*if (query[0] == QUERY) {
    const execEntry = entry => {
      if (!entry) return;
      if (entry[0] == '07a162d6-cf2c-5d47-a46d-1af86899db18') {
        return false;
      }
      else if (entry[0] == '577e61ee-a33d-5bcb-a2f5-bef1763a32ee') {
        entity.type = entry[1];
      }
      else if (entry[0] == '3d652e9a-e63f-5a70-978e-ebf3e0905f99') {
        entity.space = entry[1];
      }
      else if (entry[0] == 'b19572c2-2f49-5dde-85dd-3fa5c3e02340' || entry[0] == 'a3e9c4df-92db-5aa2-92a9-ff578f8c143a') {
        if (!entity.$edges) {
          entity.$edges = [];
        }
        entity.$edges.push({
          from: entry[1],
          directed: true,
        });
        entity.$from = entry[1];
        // return false;
      }
      // else if (entry[0] == 'a3e9c4df-92db-5aa2-92a9-ff578f8c143a') {
      //   return false;
      // }
      else {
        throw new Error('Unknown query entry: ' + entry[0]);
      }
    }

    for (const entry of query[1]) {
      if (execEntry(entry) === false) {
        return false;
      }
    }
  }*/
  if (query[0] == SPACE_QUERY) {
    // keys.push({
    //   type: ObjectType.space,
    //   id: query[1],
    // });
  }
  if (query[0] == CHAINED_QUERY) {
    for (const q of query[1]) {
      queryScopeAssertions(q, keys, baseId)
    }
  }
  /*if (query[0] == GLUE_QUERY) {
    const value = execute(query[1]);
    if (!value.type) return false;
    if (doCreateEntityFromQuery(entity, value, {
      [$EntityQuery.ChainedEntity]:baseId
    }) === false) {
      return false;
    }
  }*/
  if (query[0] == EXPRESSION_QUERY) {
    const execEntry = entry => {
      if (!entry) return;
      if (entry.type == ExpressionEntryType.group) {
        if (entry.conjunction == Conjuction.and) {
          for (const e of entry.entries) {
            execEntry(e);
          }
        }
      }
      else if (entry.type == ExpressionEntryType.type) {
        if (entry.operator.type == TypeOperator.is) {
          if (entry.operator.typeValues?.[0]) keys.push(`type.${entry.operator.typeValues[0]}`);
        }
      }
      // else if (entry.type == ExpressionEntryType.space) {
      //   if (entry.operator?.type == SetValueOperator.Is) {
      //     if (entry.operator.values?.[0]) keys.push({
      //       type: ObjectType.space,
      //       id: entry.operator.values[0],
      //     });
      //   }
      // }
      // else if (entry.type == ExpressionEntryType.descendants) {
      // }
      // else if (entry.type == ExpressionEntryType.attribute) {

      // }

    }

    execEntry(query[1]);
  }
}


export function describeQuery(query) {
  if (!query) return false;
  if (query[0] == QUERY) {
    return query[1].map(entry => {
      if (!entry) return;
      if (entry[0] == '07a162d6-cf2c-5d47-a46d-1af86899db18') {
        return `References to ${getEntityById(entry[1]).name}`;
      }
      else if (entry[0] == '577e61ee-a33d-5bcb-a2f5-bef1763a32ee') {
        return `Type is ${db.entityTypes.findById(entry[1]).name}`;
      }
      else if (entry[0] == '3d652e9a-e63f-5a70-978e-ebf3e0905f99') {
        return `Space is ${db.spaces.findById(entry[1]).name}`;
      }
      else if (entry[0] == 'b19572c2-2f49-5dde-85dd-3fa5c3e02340') {
        return `Descendants of ${getEntityById(entry[1]).name}`;
      }
      else if (entry[0] == 'a3e9c4df-92db-5aa2-92a9-ff578f8c143a') {
        return `Children of ${getEntityById(entry[1]).name}`;
      }
      else {
        throw new Error('Unknown query entry: ' + entry[0]);
      }
    }).filter(Boolean).join(' and ');
  }
  else if (query[0] == CHAINED_QUERY) {
    return query[1].map(q => describeQuery(q)).filter(Boolean).join(' and ');
  }
}