import React from 'react';
import { component } from '../../component2';
import { XObject } from '../../XObject';
import { showContextMenu } from '../../helpers';
import { ValuePoint } from '../ValuePoint';
import { Comp } from '../Comp';
import {
  _findValue,
  _iterate,
  _matches,
  addValuePoint,
  CompiledValuePoint,
  evaluate,
  execute,
  isBlock,
  ValuePointProps,
  ValuePointType
} from '../main';
import { Runtime } from "../Runtime";
import { ValueType } from "../ValueType";
import { registerType } from "../__typeRegistry";
import { registerTypeRegister } from '../typeRegistering';


@component
export class IfChainValuePoint extends Comp<ValuePointProps> {
  render() {
    const values = XObject.get(this.props.state, 'content', []);
    const onContextMenu = (i, e) => {
      e.preventDefault();
      showContextMenu(e, [
        {
          text: 'Remove',
          onClick: () => values.splice(i, 1),
        }
      ]);
    };
    return (
      <>
        <ul>
          {values.map((v, i) => (
            <li key={v._id} className={v.content && isBlock(v.content) && 'block'}>
              {v.condition ? <span onContextMenu={e => onContextMenu(i, e)}>{i == 0 ? 'if' : 'else if'} <ValuePoint id={v.condition._id} />:</span> : <span onContextMenu={e => onContextMenu(i, e)}>else:</span>} <ValuePoint id={v.content._id} />
            </li>
          ))}

          <li>
            <button onClick={() => {
              values.push(XObject.obj({
                condition: addValuePoint(XObject.obj({
                  parent: this.props.state._id,
                })),
                content: addValuePoint(XObject.obj({
                  parent: this.props.state._id,
                })),
              }));
            }}>Add If</button>
            <button onClick={() => {
              values.push(XObject.obj({
                content: addValuePoint(XObject.obj({
                  parent: this.props.state._id,
                })),
              }));
            }}>Add Else</button>
          </li>
        </ul>
      </>
    );
  }
}

export const registerIfChainType = () => {
  function executeIfChain(value: ValuePointType, rt: Runtime): CompiledValuePoint {
    for (const branch of value.content) {
      if (branch.condition) {
        const condition = execute(branch.condition._id, rt);
        if (condition && evaluate(condition)) {
          return execute(branch.content._id, rt);
        }
      } else {
        return execute(branch.content._id, rt);
      }
    }

    return {
      _id: value._id,
      type: [ValueType.Void],
      content: null,
      rt,
      isState: false,
      presentation: value.presentation,
    };
  }

  registerType(ValueType.IfChain, {
    execute: (value, rt) => {
      return executeIfChain(value, rt);
    },
    isBlock: value => true,
    findValue: (value, pattern) => {
      for (const item of value.content) {
        if (_matches(item.content, pattern)) {
          return item.content;
        } else {
          const r = _findValue(item.content, pattern);
          if (r) return r;
        }
      }
    },
    iterate: (value, parent, func, set) => {
      const list = value.content || [];
      for (let i = 0; i < list.length; i++) {
        let r;
        r = _iterate(list[i].condition, value, func, value => {
          list[i].condition = value;
        });
        if (r !== undefined) return r;
        r = _iterate(list[i].content, value, func, value => {
          list[i].content = value;
        });
        if (r !== undefined) return r;
      }

      // return _iterate(value.content.reduce((acc, curr) => {
      //   if (curr.condition) {
      //     acc = acc.concat(curr.condition);
      //   }
      //   if (curr.content) {
      //     acc = acc.concat(curr.content);
      //   }
      //   return acc;
      // }, []), value, func);

    },
    editorComp: IfChainValuePoint,
  });
};

registerTypeRegister(registerIfChainType)