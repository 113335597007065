import { Component, Context } from 'react';
import _ from 'lodash';
import { component } from '../component2';
import { XObject, x } from '../XObject';
import { createQuery } from '../etc/queryFuncs';
import { ObjectType } from '../types/ObjectRef';
import { inspectObj } from '../inspectObj';
import { BlockType as BlockType2 } from '../MyNotionDocument/BlockType';
import { ColorSelector } from './ColorSelector';
import { IconSelector } from './IconSelector';
import { db } from '../db';
import { IdentifierEditor } from './IdentifierEditor';
import { PropertyField } from './PropertyField';
import { createRootValuePoint } from '../glue/main';
import { openWindow } from '../osHelpers';
import { WindowType } from '../etc/WindowType';
import { NavContext, NavTitle, Route } from './registerRoute';
import { CodaIconSelectorItem, DropdownItem, NavItem, Section, TextInput } from './QueryViewEditor';
import { lightColors, mainColors } from './mainColors';
import { codaIcons } from '../codaIcons';
import { setAppInspect } from '../etc/appState';
import { InspectState } from './InspectState';
import { PaneType } from '../types/PaneType';


@component
export class DocumentBlockEditor extends Component<{ block; parent; }> {
  static contextType = NavContext;
  context: any;
  render() {
    const { block, parent } = this.props;

    const c = (() => {
      if (block.type == BlockType2.query) return (
        <div>



          {!block.query && (
          <NavItem
            label="Init"
            onClick={() => {
              const query = createQuery(null, null, this.props.parent, true);
              block.query = query._id;
              this.context.push([Route.object, { type: ObjectType.query, id: block.query }]);

            }}
          />
          )}


          {block.query && (
            <>
              <NavItem
                label="Edit"
                onClick={() => {
                  this.context.push([Route.object, { type: ObjectType.query, id: block.query }]);
                }}

              />
            </>
          )}


        </div>
      )

      if (block.type == BlockType2.document) return (
        <div>
          <button
            onClick={() => {
              const doc = XObject.obj({
                name: '',
                blocks: [],
                type: 'de89bc9c-9fb1-579d-8954-53f1519cbb33',
              });
              db.notionDocuments.push(doc);

              block.document = doc._id;
            }}
          >Init</button>


          {!block.identifier && <button
            onClick={() => {
              const id = XObject.obj();
              db.identifiers.push(id);
              block.identifier = id._id;
            }}
          >Create ID</button>}

          {block.identifier && <IdentifierEditor id={block.identifier} />}

          <div>
            Placeholder: <PropertyField object={block} property="placeholder" />
          </div>

        </div>
      )

      if (block.type == BlockType2.glue || block.type == BlockType2.template) return (() => {
        return (
          <>
            <TextInput
              setValue={v => block.valuePoint = v}
              value={block.valuePoint}
            />
            {!block.valuePoint && (
              <NavItem
                label="Init"
                onClick={() => {
                  const vp = createRootValuePoint();
                  block.valuePoint = vp._id;
                  setAppInspect({
                    mode: InspectState.valuePoint,
                    id: block.valuePoint,
                  });
                }}
              />
            )}

            {block.valuePoint && (
              <NavItem
                label="Edit"
                onClick={() => {
                  setAppInspect({
                    mode: InspectState.valuePoint,
                    id: block.valuePoint,
                  });
                }}
              />
            )}

            {block.valuePoint && (
              <NavItem
                label="Edit"
                onClick={() => {
                  this.context.navigate({
                    type: PaneType.valuePoint,
                    valuePoint: block.valuePoint,
                  })
                }}
              />
            )}

          </>
        )
      })()
      if (block.type == BlockType2.callout) return (
        <>
          {/* <div>
            Icon: <IconSelector get={() => block.icon} set={v => block.icon = v} />
          </div> */}

          <DropdownItem
            label="Color"
            options={lightColors.map(c => ({
              text: c.name,
              value: c.key,
            }))}
            value={block.color}
            setValue={v => block.color = v}
          />

          <CodaIconSelectorItem label="Icon" value={block.icon} setValue={v => block.icon = v} />

          {/* <DropdownItem
            label="Icon"
            options={codaIcons.map(c => ({
              text: c.name,
              value: c.name,
            }))}
            value={block.icon}
            setValue={v => block.icon = v}
          /> */}
        </>
      )
    })();

    return (
      <div>
        <Section>
          <NavTitle><h3>Block</h3></NavTitle>
          <DropdownItem
            label="Type"
            options={
              [{text:'None', value: null}].concat(Object.keys(BlockType2).map(key => {
                return {
                  value: BlockType2[key],
                  text: _.startCase(key),
                }
              }))
            }
            value={block.type}
            setValue={v => block.type = v}
          />
          <DropdownItem
            label="Text Alignment"
            options={[
              { text: 'Left', value: null },
              { text: 'Right', value: 'right' },
              { text: 'Middle', value: 'middle' },
            ]}
            value={block.textAlign}
            setValue={v => block.textAlign = v}
          />
          <DropdownItem
            label="Text Color"
            options={[
              { text: 'Default', value: null },
              { text: 'Red', value: 'red' },
            ]}
            value={block.textColor}
            setValue={v => block.textColor = v}
          />
          {/* <button
            onClick={() => {
              block.textColor = 'red';
            }}
          >{block.textColor || 'Color'}</button>
          <button
            onClick={() => {
              block.bgColor = 'red';
            }}
          >{block.bgColor}</button>
          <button
            onClick={() => {
              console.log(x(block));
            }}
          >Debug</button> */}
        </Section>
        {c && (
          <Section header={_.startCase(_.invert(BlockType2)[block.type])}>
            {c}
          </Section>
        )}
      </div>
    );
  }
}
