const valueAccessors = {};

export function registerValueAccessor(id, obj) {
  valueAccessors[id] = obj;
}

export function getValue(path) {
  return valueAccessors[path[0]].get(path.slice(1));
}

export function setValue(path, value) {
  return valueAccessors[path[0]].set(path.slice(1), value);
}
