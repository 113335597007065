import _ from "lodash";

export type DataArray = any[];
export type Data = DataArray | string;

export type DataCtx = {
  types: any;
  args?: any
};

// DATA FUNCS
function contractFromHtml(html) {
  return [];
}
function stringToBinary(str: any) {
  return Array.from(str).map((char:any) => {
    return char.charCodeAt(0).toString(10)//.padStart(4, '0');
  }).join(' ');
}

function strip(str) {
  return str.replace(new RegExp(String.fromCharCode(8288), 'g'), '')
}

export function extractFromEl(ctx: DataCtx, el: HTMLElement): Data {
  const { types } = ctx;
  const parts = [];
  const processeEl = (el: HTMLElement, styles = []) => {
    if (el) {

      if (!el.getAttribute?.('data-terminal')) {
        if (el.nodeType === Node.TEXT_NODE || (el.getAttribute('data-presentation') && el.firstChild instanceof Text)) {
          parts.push([strip(el.textContent), ...(styles.length ? [styles] : [])]);
        }
        else if ((el.firstChild as any)?.getAttribute?.('data-type')) {
          const t = types[(el.firstChild as any).getAttribute('data-type')];
          if (t) {
            parts.push(t.part(el.firstChild));
          }
        }
        else if (el.nodeType === Node.ELEMENT_NODE) {
          const t = types[el.getAttribute('data-type')];
          if (t) {
            parts.push(t.part(el));
          }
          else if (el.tagName == 'B') {
            processeEl(el.firstChild as HTMLElement, _.uniq([...styles, 'b']));
          }
          else if (el.tagName == 'I') {
            processeEl(el.firstChild as HTMLElement, _.uniq([...styles, 'i']));
          }
          else if (el.tagName == 'U') {
            processeEl(el.firstChild as HTMLElement, _.uniq([...styles, 'u']));
          }
        }
      }

      processeEl(el.nextSibling as HTMLElement, styles);
    }
  };
  processeEl(el.firstChild as HTMLElement);

  let plainText = true;
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].length != 1) {
      plainText = false;
      break;
    }
  }

  if (plainText) {
    return parts.map(p => p[0]).join('');
  }

  return parts;
}

function replace(str: string) {
  return str.replace(/\</g, '&lt;').replace(/\>/g, '&gt;').replace(/ /g, '&nbsp;');
}
export function expandToHtml(ctx: DataCtx, data: Data, comps = {}, args?, process?, xData?) {
  if (_.isString(data)) {
    const r = replace(data);
    if (process) {
      return process(r);
    }
    return r;
  }
  const { types } = ctx;
  let html = '';

  const currentStyles = [];
  // let lastAtomic = false;
  try {
    for (let i = 0; i < data.length; ++ i) {
      const part = data[i];
      if (!part) continue;
      if (_.isString(part)) {
        html += replace(part);
      }
      else {
        const t = types[part[1]];
        if (t) {
          // if (t?.type == Type.atomic) {
          //   lastAtomic = true;
          // }
  
          for (const style of currentStyles) {
            html += `</${style}>`;
          }
          currentStyles.splice(0, currentStyles.length);
          html += t.html(part[0], comps, {
            ...(args || {}),
            ...(ctx.args || {}),
          }, part, xData?.[i]);

          // if (t?.type == Type.atomic) {
          //   html += '&#8288;';
          // }
        }
        else {
          const [text, styles = []] = part;
          const newStyles = _.difference(styles, currentStyles);
          const removedStyles = _.difference(currentStyles, styles);
          for (const style of newStyles) {
            html += `<${style}>`;
          }
          for (const style of removedStyles) {
            html += `</${style}>`;
          }
          html += replace(text);
          currentStyles.splice(0, currentStyles.length, ...styles);
        }
      }

    }
  }
  catch (e) {
    console.log(data);
    console.log(e);
    throw e;
  }


  if (html == '<br />') return '';

  // TODO: fix
  // if (lastAtomic) {
  //   html += `&#8203;`
  // }

  // html += `<span contenteditable="false" data-terminal="true"></span>`

  return html;
}

export function expandToText(ctx: DataCtx, data: Data, comps = {}, args?) {
  if (_.isString(data)) {
    return data;
  }
  if (!data) return '';
  const { types } = ctx;
  let text = '';

  try {
    for (const part of data) {
      if (!part) continue;
      if (_.isString(part)) {
        text += part;
      }
      else {
        const t = types[part[1]];
        if (t) {
          text += t.text(part[0], comps, args, part);
        }
        else {
          const [str, styles = []] = part;
          text += str;
        }
      }

    }
  }
  catch (e) {
    console.log(data);
    console.log(e);
    throw e;
  }


  if (text == '<br />') return '';

  return text;
}
  

export function createChunked(ctx: DataCtx, data: Data = []): DataArray {
  const { types } = ctx;
  const chunked = [];
  let i = 0;
  for (const part of data) {
    if (_.isString(part)) {
      const str = part;
      for (let j = 0; j < str.length; j++) {
        chunked[i + j] = [str[j], []];
      }
      i += str.length;
    }
    else {
      const t = types[part[1]];
      if (t) {
        if (t.type == Type.atomic) {
          chunked[i] = part;
          i++;
        }
        else if (t.type == Type.text) {
          const [str, c, args] = part;
          for (let j = 0; j < str.length; j++) {
            chunked[i + j] = [str[j], c, args];
          }
          i += str.length;
        }
      }
      else {
        const [str, styles = []] = part;
        const styleMap = {};
        for (const style of styles) {
          styleMap[style] = true;
        }
        for (let j = 0; j < str.length; j++) {
          chunked[i + j] = [str[j], styleMap];
        }
        i += str.length;

      }
    }
  }

  return chunked;
}

function mergeChunked(ctx: DataCtx, chunked: Data, start: number, end: number): Data {
  const { types } = ctx;
  const slicedData = [];
  let prevStyles = {}, prevTypeArgs;
  let curStr = '';

  function fin() {
    if (_.isString(prevStyles) && types[prevStyles]?.type == Type.text) {
      slicedData.push([curStr, prevStyles].concat(prevTypeArgs ? [prevTypeArgs] : []));
    }
    else {
      const styles = [];
      for (const style in prevStyles) {
        styles.push(style);
      }
      slicedData.push([curStr, styles]);
    }
  }

  for (let i = start; i < end; i++) {
    const [str, type, typeArgs] = chunked[i] || ['', {}];
    const t = types[type];
    if (t) {
      if (t.type == Type.atomic) {
        if (curStr) {
          fin();
          curStr = '';
          prevStyles = {};
        }

        slicedData.push(chunked[i]);
      }
      else if (t.type == Type.text) {
        if (curStr && (prevStyles != type || !_.isEqual(typeArgs, prevTypeArgs))) {
          fin();
          curStr = '';
        }

        curStr += str;
        prevStyles = type;
        prevTypeArgs = typeArgs;
      }
    }
    else if (str) {
      if (_.isEqual(prevStyles, type)) {
        curStr += str;
      }
      else {
        fin();
        curStr = str;
        prevStyles = type;
      }
    }
  }
  if (curStr) {
    fin();
  }

  return slicedData;
}

export function sliceData(ctx: DataCtx, data: Data, start: number, end: number): Data {
  const chunked = createChunked(ctx, data);
  return mergeChunked(ctx, chunked, start, end);
}

export function dataLength(ctx: DataCtx, data: Data): number {
  const chunked = createChunked(ctx, data);
  return chunked.length;
}

export function concatData(ctx: DataCtx, data: Data, newData: Data): Data {
  const chunked = createChunked(ctx, data).concat(createChunked(ctx, newData));
  return mergeChunked(ctx, chunked, 0, chunked.length);
}

export function dataToString(ctx: DataCtx, data: Data): string {
  const chunked = createChunked(ctx, data);
  let str = '';
  for (const [char] of chunked) {
    str += char;
  }
  return str;
}
export enum Type {
  atomic,
  text
}


export function isInDataEditor(rootEl, which='anchor') {
  const selection = window.getSelection();
  if (!selection[which + 'Node']) return false;
  const node = selection[which + 'Node'];
  if (!rootEl?.contains?.(node)) return false;
  return true;
}

export function getPositionInDataEditor(rootEl, ctx, which='anchor', debug?): number {
  const selection = window.getSelection();
  if (!selection[which + 'Node']) return -1;
  let position = selection[which + 'Offset'];
  let pos = 0;

  const node = selection[which + 'Node'];
  if (!rootEl.contains(node)) return null;
  if (node == rootEl) {
    for (let i = 0; i < position; ++ i)  {
      const n = rootEl.childNodes[i];
      if (n.nodeType == Node.TEXT_NODE) {
        pos += strip(n.textContent)?.length || 0;
      }
      else if (ctx.types[n.getAttribute?.('data-type')]?.type == Type.atomic) {
        pos += 1;
      }
      else if (n.getAttribute('data-presentation') && n.firstChild?.getAttribute?.('data-type')) {
        if (ctx.types[n.firstChild.getAttribute?.('data-type')]?.type == Type.atomic) {
          pos += 1;
        }
      }
      else if (n.getAttribute('data-presentation')) {
        pos += strip(n.textContent)?.length || 0;
      }

    }
    return pos;
  }

  if (!node) return 0;


  const findNode = n => {
    if (n == node) {
      pos += position;
      if (debug) console.log(pos);
      return true;
    }
    else if (ctx.types[n.getAttribute?.('data-type')]?.type == Type.atomic) {
      pos += 1;
    }
    else if (n.nodeType == Node.TEXT_NODE) {
      pos += strip(n.textContent)?.length || 0;
    }
    else if (n.nodeType == Node.ELEMENT_NODE) {
      if (n.getAttribute('data-presentation') && n.firstChild?.getAttribute?.('data-type')) {
        if (ctx.types[n.firstChild.getAttribute?.('data-type')]?.type == Type.atomic) {
          pos += 1;
        }
      }
      else {
        if (n.childNodes) {
          for (let i = 0; i < n.childNodes.length; i++) {
            if (findNode(n.childNodes[i]) === true) {
      if (debug) console.log(pos);

              return true;
            }
          }
        }

      }
    }
  }

  const r = findNode(rootEl);
  if (debug) {
    console.log(r);
  }

  return pos;
}

export function getExpandedPositionInDataEditor(ctx, rootEl, which='anchor') {
  if (!isInDataEditor(rootEl)) return null;
  const selection = window.getSelection();
  if (!selection[which + 'Node']) return -1;
  let position = selection[which + 'Offset'];

  const node = selection[which + 'Node'];
  if (node == rootEl) {
    return position;
  }

  if (!node) return 0;

  let pos = 0;

  const findNode = n => {
    if (!n) return null;
    const dataType = n.getAttribute?.('data-type');
    if (n == node || n.getAttribute?.('data-presentation') && n.firstChild == node) {
      pos += position;
      return true;
    }
    else if (
      // dataType == 'entity' ||
      // dataType == 'query' ||
      // dataType == 'identifier' ||
      // dataType == 'attribute' ||
      // dataType == 'valuePoint' ||
      // dataType == 'glue' ||
      ctx.types[dataType]?.type == Type.atomic
    ) {
      const t = ctx.types[dataType];
      const part = t.part(n);
      const text = t.text(part[0]);
      pos += text.length;
    }
    else if (n.nodeType == Node.TEXT_NODE || n.getAttribute?.('data-presentation')) {
      pos += n.textContent?.length || 0;
    }
    else if (n.nodeType == Node.ELEMENT_NODE) {
      if (n.childNodes) {
        for (let i = 0; i < n.childNodes.length; i++) {
          if (findNode(n.childNodes[i]) === true) {
            return true;
          }
        }
      }
    }
  }

  const r = findNode(rootEl);
  return pos;
}

/*export function expandPositionInDataEditor(ctx, rootEl, position) {
  if (!isInDataEditor(rootEl)) return null;

  let pos = 0;

  const findNode = n => {
    if (!n) return null;
    const dataType = n.getAttribute?.('data-type');
    if (n == node || n.getAttribute?.('data-presentation') && n.firstChild == node) {
      pos += position;
      return true;
    }
    else if (
      // dataType == 'entity' ||
      // dataType == 'query' ||
      // dataType == 'identifier' ||
      // dataType == 'attribute' ||
      // dataType == 'valuePoint' ||
      // dataType == 'glue' ||
      ctx.types[dataType]?.type == Type.atomic
    ) {
      const t = ctx.types[dataType];
      const part = t.part(n);
      const text = t.text(part[0]);
      pos += text.length;
    }
    else if (n.nodeType == Node.TEXT_NODE || n.getAttribute?.('data-presentation')) {
      pos += n.textContent?.length || 0;
    }
    else if (n.nodeType == Node.ELEMENT_NODE) {
      if (n.childNodes) {
        for (let i = 0; i < n.childNodes.length; i++) {
          if (findNode(n.childNodes[i]) === true) {
            return true;
          }
        }
      }
    }
  }

  const r = findNode(rootEl);
  return pos;
}*/