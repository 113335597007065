import { Component } from 'react';
import { component } from './component2';


@component
export class DevProjectInspector extends Component<{ devProject; }> {
  render() {
    return '';
  }
}
