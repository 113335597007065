import React, { Component } from 'react';
import { DevRuntime } from './DevRuntime';
import { styled } from './component';
import { component } from './component2';
import { Tag } from './components/Tag';


@component
export class ComponentEntry extends Component<{ devRuntime: DevRuntime; comp; }> {
  static styles = styled.div`
    padding: 3px 0;
    ${Tag} {
      margin-left: 4px;
    }

    .path {
      color: #b8b8b8;
      font-size: 9px;
      .comp {
        &:not(:last-child) {
          margin-right: 4px;
          &:after {
            content: '/';
            margin-left: 4px;
          }
        }
      }
    }
  `;
  render() {
    const c = this.props.comp;
    const type = this.props.devRuntime.devProject.builderComponentTypes.find(t => c.builderComponentType == t._id);
    const path = this.props.devRuntime.getComponentPath(c._id);
    return <>
      <div>

        {c.name} {type && <Tag text={type.name} />}

      </div>
      <div className="path">
        {path.map(id => {
          const c = this.props.devRuntime.getComponent(id);
          return <span className="comp">{c?.name}</span>;
        })}

      </div>
    </>;
  }
}
