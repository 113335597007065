import { XClone, XObject } from "../XObject";
import { MyBlockManager } from "./MyBlockManager";

type Events = 'internalBlocks' | 'setBlocks' | 'blockChanged';
export interface ScriptWrapper {
  blocks();

  // setBlocks(blocks);

  blockManager();

  addEventListener(event: Events, observer)
  removeEventListener(event: Events, observer)

  forBlock(id: string): ScriptWrapper

  cleanup()
}

const getBlock = (id, blocks) => {
  for (const block of blocks) {
    if (block._id == id) {
      return block;
    }
    else {
      const r = getBlock(id, block.children);
      if (r) return r;
    }
  }
}

export function createScriptWrapper(args: {
  blocks()
  setBlocks,
  baseEntity?,
  extendEntity?,
  onChange?
  dontClone?
}): ScriptWrapper {
  const memory = {};

  if (!args.blocks().length) {
    args.blocks().push(XObject.obj({
      data: [],
      children: [],
    }));
  }

  let blocks = args.dontClone ? args.blocks() : XClone(args.blocks());

  const observer = () => {
    args.setBlocks(args.dontClone ? blocks : XClone(blocks));
    callObservers('internalBlocks');
  }

  XObject.observe(blocks, observer);

  const eventListeners = {};

  const callObservers = (event: Events, ...args) => {
    if (eventListeners[event]) {
      for (const func of eventListeners[event]) {
        func(...args);
      }
    }
  }

  const sw: ScriptWrapper =  {
    forBlock: id => {
      const blks = () => {
        return [
          getBlock(id, blocks),
        ]

      }
      return {
        ...sw,
        blocks: () => {
          return blks();
        },
        setBlocks: blocks => {

        },
        blockManager() {
          return new MyBlockManager(
            () => blks(),
            blks => {
              // args.setBlocks(blks);
              // blocks = XClone(blks);
              // XObject.observe(blocks, (change) => {
              //   args.setBlocks(XClone(blocks));
              //   callObservers('internalBlocks');
              // });    
              // callObservers('setBlocks', blocks);
            },
            {
              baseEntity: args.baseEntity,
              extendEntity: args.extendEntity,
              memory: memory,
              onSetContent: (id, client) => {
                callObservers('blockChanged', id, client);
                args.onChange?.(id);
              }
            }
          )
        },
    
      }
    },
    blocks: () => blocks,
    blockManager() {
      return new MyBlockManager(
        () => blocks,
        blks => {
          args.setBlocks(blks);
          if (!args.dontClone) {
            blocks = XClone(blks);
            XObject.observe(blocks, (change) => {
              args.setBlocks(XClone(blocks));
              callObservers('internalBlocks');
            });    
  
          }
          callObservers('setBlocks', blocks);
        },
        {
          baseEntity: args.baseEntity,
          extendEntity: args.extendEntity,
          memory: memory,
          onSetContent: (id, client) => {
            callObservers('blockChanged', id, client);
            args.onChange?.(id);
          }
        }
      )
    },

    addEventListener(event, observer) {
      if (!eventListeners[event]) eventListeners[event] = [];
      eventListeners[event].push(observer);
    },
    removeEventListener(event, observer) {
      if (eventListeners[event]) {
        const index = eventListeners[event].indexOf(observer);
        if (index != -1) {
          eventListeners[event].splice(index, 1);
        }
      }
    },

    cleanup() {
      XObject.removeObserver(blocks, observer);
    }
  };

  return sw;
}
