import React, { Component } from 'react';
import { component } from './component2';
import { XInit } from './XObject';
import { ObjectDisplay } from './components/ObjectDisplay';
import { ObjectType } from './types/ObjectRef';
import { ObjectPicker } from './components/ObjectPicker';
import { setObjectParent } from './components/objectFuncs';
import { viewObject } from './viewObject';
import { showContextMenu } from './helpers';
import copyToClipboard from 'copy-to-clipboard';
import { ObjectRenamer } from './ObjectRenamer';

@component
export class ObjectEditor3 extends Component<{ obj; onView? }> {
  state = XInit(class {
    mode;
  });
  render() {
    const { obj } = this.props;
    const contextMenu = [
      {
        text: 'Rename',
        onClick: () => {
          this.state.mode = 'renaming';
        },
      },
      {
        text: 'Move',
        onClick: () => {
          this.state.mode = 'moving';
        },
      },
      {
        text: 'View',
        onClick: () => {
          if (this.props.onView) {
            this.props.onView(obj);
          }
          else {
            viewObject(obj, this.context);

          }
        },
      },
      {
        text: 'Copy ID',
        onClick: () => {
          copyToClipboard(obj.id);
        }
      },
    ]
    return (
      <>
        <div className="top">
          <div className="title">
            <ObjectDisplay
              _onClick={e => {
                showContextMenu(e, contextMenu);
              }}
              onContextMenu={e => {
                e.preventDefault();
                showContextMenu(e, contextMenu);
              }}
              obj={obj}
              showPath />
          </div>
          {this.state.mode == 'moving' && (
            <ObjectPicker
              _onSelect={(ref, clear) => {
                if (ref.type == ObjectType.global) {
                  setObjectParent(obj, null);
                }
                else {
                  setObjectParent(obj, ref);
                }
                delete this.state.mode;
              }} />
          )}
          {this.state.mode == 'renaming' && (
            <ObjectRenamer obj={obj} onRenamed={() => {
              delete this.state.mode;
            }} />
          )}
        </div>
      </>
    );
  }
}
