import { X, XObject } from '../XObject';
import { db } from '../db';
import { ObjectRef, ObjectType } from '../types/ObjectRef';

export function objectResource(ownerRef: ObjectRef, handleId: string, {
  create = true,
  affix = undefined,
}={}) {
  const handle = db.objectHandles.findById(handleId);
  if (!handle) return null;
  const key = ownerRef.id + ':' + handleId + (affix ? `:${affix}` : '');

  let obj;
  if (handle.type == ObjectType.table) {
    obj = db.tables.find(t => t.key === key);
  }

  if (obj) {
    return obj;
  }
  else if (create) {
    if (handle.type == ObjectType.table) {
      const doc = XObject.obj<any>({
        relative: true,
      });

      doc.handle = handleId;
      doc.parent = X(ownerRef);
      doc.key = key;

      db.tables.push(doc);
      return doc;
    }
  }
}
