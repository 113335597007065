import React, { Component, useEffect } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { component } from '../component2';
import CytoscapeComponent from 'react-cytoscapejs';
import { styled } from '../component';
import { entityDisplayName } from './entityDisplayName';
import { getEmbeddings, getEmbeddingsReactive, getSummary, getSummaryReact } from '../getEmbeddings';
import { convert } from 'html-to-text';

import * as ml from 'ml-distance';
import { getEntityById } from '../etc/createEntity';
import popper from 'cytoscape-popper';
import cytoscape from 'cytoscape';
import { EntityRow } from './EntityRow';
import copy from 'copy-to-clipboard';
import { relatedEntities } from './relatedEntities';
import { XInit, x } from '../XObject';

cytoscape.use(popper);

// const min = 0, max = 1;
const len = 20;
const min = .4, max = .9;
const allTopics = [
  "Technology",
  "Science",
  "History",
  "Politics",
  "Psychology",
  "Mental Health",
  "Social Dynamics",
  "Relationships",
  "Philosophy",
  "Ethics",
  "Personal Development",
  "Gender Studies",
  "Religious Studies",
  "Education",
  "Leadership",
  "Geopolitics",
  "Existentialism",
  "Behavioral Science",
  "Cultural Studies",
  "Art and Legacy",
  "Economics",
  "Health and Medicine",
  "Power Dynamics",
  "Inequality",
  "Conflict and War",
  "Brain Science",
  "Social Justice",
  "Emotional Stability",
  "Interpersonal Communication",
  "Work and Values",
  "Self-Improvement",
  "Social Critique",
  "Marriage and Dating Dynamics",
  "Attraction and Gender Perceptions",
  "Life Challenges and Coping",
  "Moral Foundations",
  "Social Cohesion",
  "Violence and Aggression",
  "Social Hierarchies",
  "Cognitive Science",
  "Pharmaceutical Studies",
  "Quantum Physics",
  "Artistic Influence",
  "Historical Analysis",
  "Interfaith Perspectives",
  "Ethical Practice",
  "Theism and Philosophy",
  "Self-Protection",
  "Interconnectedness",
  "Personal Accountability"
]


@component
class NetworkCluster extends Component<{ nameLength?, nodes, active?, getName, getSimilarity, onClickNode, config }> {
  cy
  counts = {}

  layout = {
    name: 'd3-force',
    animate: true,
    maxIterations: 1000,
    fixedAfterDragging: false,
    linkId: d => d.id,
    manyBodyStrength: -300,
    ready: function(){},
    stop: function(){},
    tick: (progress) => {},
    randomize: true,
    infinite: false,
    linkDistance: (link) => {

      return len / (Math.min(Math.max(link.sim, min), max) - min)/(max - min)
    },
    // linkDistance: 80,
  } as any

  render() {


    let _cy = this.cy;

    const initCy = () => {
      _cy.on('tap', 'edge', e => {
        console.log(e);

        const node = e.target;

        const data = node.data();

        console.log(data);

        if (data.type == 'entity') {
          this.props.onClickNode(data.id);
        }

        console.log(node.data().sim);

      })

      _cy.on('tap', 'entity', async e => {
        console.log(e);

        const node = e.target;

        const data = node.data();

        if (data.type == 'entity') {
          this.props.onClickNode(data.id);
          // let entities = [];

          // node.component().depthFirstSearch({
          //   roots: data.id,
          //   visit: (v, e, u, i, depth) => {
          //     if (v.data().id != data.id) {
          //       entities.push(v.data().id);
          //     }
          //   },
          // });


          // const text = [getEmbeddingContent(data.id), ...entities.map(e => getEmbeddingContent(e))];

          // const embeddings = await getEmbeddings(text);
          // const sims = [];
          // const baseEmbed = embeddings[0];
          // const asdf = [];
          // for (let i = 0; i < entities.length; ++ i) {
          //   const entityEmbedding = embeddings[i + 1];
          //   const sim = ml.similarity.cosine(baseEmbed, entityEmbedding);
          //   asdf.push([entities[i], sim])
          // }

          // asdf.sort((a, b) => b[1] - a[1])
          // entities = asdf;//.map(a => a[0]);

          
          // // this.entities = [[data.id, 1], ...entities];
          // // this.activeEntity = data.id;

          // this.forceUpdate();
        }
        else {
          console.log(data.sim)
        }


      })


      let popper;
      let cleanup;
      _cy.on('mouseover', 'entity', e => {
        cleanup?.();
        const node = e.target;
        if (node.data().type != 'entity')  return;
        let div;

        popper = node.popper({
          content: () => {
            div = document.createElement('div');
        
            div.innerHTML = `${this.props.getName(node.data().id)} (${node.connectedEdges().length})`;
            div.style.backgroundColor = 'white';
            div.style.zIndex = '9999999999';
            div.style.border = '1px solid black';
            div.style.maxWidth = '200px';
            div.style.pointerEvents = 'none';
        
            document.body.appendChild(div);
        
            return div;
          },
          popper: {} // my popper options here
        });

        let update = () => {
          popper.update();
        };

        console.log(popper);

        
        node.on('position', update);
        
        _cy.on('pan zoom resize', update);
        
        cleanup = () => {
          node.removeListener('position', update);
          _cy.removeListener('pan zoom resize', update);
          popper.destroy();
          div.parentNode.removeChild(div);
        }
        
        

        // const data = node.data();

        // console.log(data);

      });

      _cy.on('mouseout', 'entity', e => {
        cleanup?.();
        cleanup = null;
        

      });

    }

    useEffect(() => {


      if (_cy) initCy();
      
    }, [_cy]);

    const nodes = this.props.nodes;



    const { getName, getSimilarity, config } = this.props;




    const elements: { data: {
      id: string

      type?

      label?

      active?

      highlight?

      source?
      target?

      directed?


      sim?

    } }[] = [];



    const nameLength = this.props.nameLength || 13;
    for (const id of nodes) {
      const name = getName(id);
      elements.push({
        data: {
          id: id,
          type: 'entity',
          // highlight: this.entities.find(x => x[0] == id),
          active: id == this.props.active,// || id == this.activeEntity,
  
          label: name.length > nameLength ? name.slice(0, nameLength) + '...' : name,
        }
      })
    }



    const addedEdges = {};

    const edgeAdded = (source, target) => {
      return addedEdges[key(source, target)]
    }

    const key = (source, target) => {
      let first, second;
      if (source < target) {
        first = source;
        second = target;
      }
      else {
        first = target;
        second = source;
      }

      return first + second;
    }


    this.counts ={};
    const addEdge = (source, target) => {
      if (!this.counts[source]) this.counts[source] = 0;
      this.counts[source]++;
      if (!this.counts[target]) this.counts[target] = 0;
      this.counts[target]++;

      addedEdges[key(source, target)] = true;
    }



    for (let i = 0; i < nodes.length; ++ i) {
      if (config.mostSimilar?.connect) {
        let cur;
        for (let j = 0; j < nodes.length; ++ j) {
          if (j == i) continue;
          const sim = getSimilarity(nodes[i], nodes[j]);

          if (!cur || sim > cur.sim) cur = { index: j, sim };
        }

        if (cur.sim >= config.mostSimilar.threshold) {

          if (!edgeAdded(nodes[i],nodes[cur.index])) {
            addEdge(nodes[i],nodes[cur.index])
            elements.push({
              data: {
                id: nodes[i] + 'edge',
                type: 'edge',

    
                source: nodes[i],
                target: nodes[cur.index],
                directed: true,
    
                sim: cur.sim,
              }
            })
          }
        }

      }


      if (config.all?.connect) {
        for (let j = 0; j < nodes.length; ++ j) {
          if (j == i) continue;
          const sim = getSimilarity(nodes[i], nodes[j]);

          if (sim >= config.all.threshold) {
            if (!edgeAdded(nodes[i], nodes[j])) {
              addEdge(nodes[i], nodes[j]);
              elements.push({
                data: {
                  id: i.toString() + j,
                  type: 'edge',
      
                  source: nodes[i],
                  target: nodes[j],
                  directed: true,
      
                  sim: sim,
                }
              })
            }

          }

        }
      }
    }




    return (
      <>

        <CytoscapeComponent
          
          elements={elements}
          style={ { width: '100%', height: '100%' } }
          stylesheet={[
            {
              selector: 'node',
              style: {
                'background-color': '#666',
                color: 'green',
                'label': 'data(label)',
                'text-wrap': 'wrap',
                'text-max-width': '200px',
                'width': node => {
                  // return 10;
                  return Math.max(6, node.degree() * 1);
                },
                'height': (node: any) => {
                  // return 10;
                  return Math.max(6, node.degree() * 1);
                },
              }
            },


            {
              selector: 'node[?highlight]',
              style: {
                'background-color': '#be9421',
                'color': '#be9421',
              }
            },

            {
              selector: 'node[?active]',
              style: {
                'background-color': '#bb2e2e',
                'color': '#bb2e2e',
              }
            },


            {
              selector: 'edge',
              style: {
                'width': 2,
                'line-color': '#959595',
                'curve-style': 'bezier',
                'font-size': 8,
                "text-rotation": "autorotate",
              }
            },
          ]}
          layout={this.layout}


          cy={x => {
            if (this.cy != x) {
              this.cy = x;
            this.forceUpdate();

            }


                    
          }}
          
        />


      </>
    );
  }
}

@component
export class Clustering extends Component<{ view, active, connectAll, allThreshold, connectClosest, closestThreshold, entities: string[], attribute, onClickEntity }> {
  static styles = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .topics {
      display: flex;
      flex-wrap: wrap;
      .topic {
        margin: 6px;
        border: 1px solid black;
        border-radius: 3px;
        &.active {
          background-color: gray;
        }
      }
    }
    .top {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 200px;
    }

    .bottom {
      position: absolute;
      bottom: 0;
      height: 200px;
      left: 0;
      right: 0;
      border-top: 1px solid black;
      overflow: auto;
    }
  `;
  counts = {}

  entities = [];
  activeEntity


  elements

  layout = {
    name: 'd3-force',
    animate: true,
    maxIterations: 1000,
    fixedAfterDragging: false,
    linkId: d => d.id,
    manyBodyStrength: -300,
    ready: function(){},
    stop: function(){},
    tick: (progress) => {},
    randomize: true,
    infinite: false,
    linkDistance: (link) => {

      return len / (Math.min(Math.max(link.sim, min), max) - min)/(max - min)
    },
    // linkDistance: 80,
  } as any

  cy

  state = XInit(class {
    activeTopics = {}
  });

  componentDidMount(): void {
    window['g_clustering'] = this;
  }

  updateLayout() {
    this.cy.layout(this.layout).run();
  }

  cluster = []
  renderClusters() {
    const { entities } = this.props;
    const getEmbeddingContent = id => {
      if (this.props.attribute) {
        const entity = getEntityById(id);
        return convert(entity.attributes?.[this.props.attribute] || '');
      }
      else {
        return entityDisplayName(id);
      }
    }

    const text = this.props.entities.map(x => getEmbeddingContent(x));
    const cache = {};

    const embedding = getEmbeddingsReactive(text);
    if (!embedding) return;

    for (let i = 0; i < entities.length; ++ i) {
      for (let j = 0; j < entities.length; ++ j) {
        if (i == j) continue;
        const iEntity = entities[i];
        const jEntity = entities[j];

        let first, second;
        if (iEntity < jEntity) {
          first = iEntity;
          second = jEntity;
        }
        else {
          first = jEntity;
          second = iEntity;
        }

        const sim = ml.similarity.cosine(embedding[i], embedding[j]);
        cache[first+second] = sim;

      }
    }

    // console.log(cache);

    const getEntitySimilarity = (iEntity, jEntity) => {
      if (iEntity == jEntity) return 1;
      let first, second;
      if (iEntity < jEntity) {
        first = iEntity;
        second = jEntity;
      }
      else {
        first = jEntity;
        second = iEntity;
      }

      if (!((first + second) in cache)) throw new Error(`${iEntity}, ${jEntity}`)
      return cache[first + second];
    }

    const getEntitySimilarityToCuster = (entity: string, cluster: string[]) => {
      let max;
      for (const id of cluster) {
        const sim = getEntitySimilarity(id, entity);
        if (!max || sim > max) max = sim;
      }
      return max;
      // let t = 0;
      // for (const id of cluster) {
      //   t += getSimilarity(id, entity);
      // }
      // return t/cluster.length;
    }

    const getClusterSimilarityToCluster = (a: string[], b: string[]) => {
      let t = 0;
      for (const id of a) {
        t += getEntitySimilarityToCuster(id, b);
      }
      return t/a.length;
    }


    const added = {};

    let clusters: string[][] = [];

    const add = (entities: string[]) => {
      for (const id of entities) added[id] = true;
    }

    const getClosestEntityToEntity = (id: string) => {
      let cur: { id: string, similarity }
      for (const x of entities) {
        if (x == id || added[x]) continue;
        const sim = getEntitySimilarity(id, x);
        if (!cur || sim > cur.similarity) cur = { id: x, similarity: sim };
      }
      return cur;
    }

    const getClosestEntityToCluster = (cluster: string[]) => {
      let cur: { id: string, similarity };
      for (const x of entities) {
        if (added[x] || cluster.includes(x)) continue;
        const similarity = getEntitySimilarityToCuster(x, cluster);
        if (!cur || similarity > cur.similarity) cur = { id: x, similarity };
      }

      return cur;
    }

    const getClosestClusterToEntity = (id: string) => {
      let cur: { cluster: string[], similarity }
      for (const cluster of clusters) {
        const similarity = getEntitySimilarityToCuster(id, cluster);
        if (!cur || similarity > cur.similarity) cur = { cluster, similarity };
      }
      return cur;
    }

    const getClosestClusterToCluster = (cluster: string[]) => {
      let cur: { cluster: string[], similarity }
      for (const c of clusters) {
        if (cluster == c) continue;
        const similarity = getClusterSimilarityToCluster(cluster, c);
        if (!cur || similarity > cur.similarity) cur = { cluster: c, similarity };
      }
      return cur;
    }

    const averageSimilarityOfCluster = (cluster: string[]) => {
      let tAvg = 0;
      for (let i = 0; i < cluster.length; ++ i) {
        let t = 0;
        for (let j = 0; j < cluster.length; ++ j) {
          if (i == j) continue;

          t += getEntitySimilarity(cluster[i], cluster[j]);
        }
        tAvg += t/(cluster.length - 1);
      }
      return tAvg/cluster.length;
    }

    // const initial = .8;
    // const followup = .77;
    const initial = .8;
    const followup = .81;
    const straggler = .8;

    // initial pairing
    for (let i = 0; i < entities.length; ++ i) {
      const id = entities[i];
      if (added[id]) continue;
      const {id: closest, similarity } = getClosestEntityToEntity(id);
      if (closest && similarity >= initial) {
        if (getClosestEntityToEntity(closest).id == id) {
          clusters.push([ closest, id ])
          add([closest, id]);
        }
      }
    }

    // building out clusters
    while (Object.keys(added).length < entities.length) {
      let added = false;
      for (const cluster of clusters) {
        const r = getClosestEntityToCluster(cluster);
        if (!r) break;
        const {id: entity, } = r;
  
        const avg = averageSimilarityOfCluster(cluster.concat(entity));

        if (entity && avg >= followup) {
          if (getClosestClusterToEntity(entity).cluster == cluster) {
            cluster.push(entity);
            add([entity]);
            added = true;
          }
        }
      }

      if (!added) break;
    }

    const removeCluster = cluster => {
      const index = clusters.indexOf(cluster);
      if (index != -1) clusters.splice(index, 1);
    }

    // merging clusters 
    while (true) {
      const newClusters = [];
      let added;
      while (clusters.length) {
        const cluster = clusters[0];

        const closest = getClosestClusterToCluster(cluster);
        if (!closest) {
          clusters.shift();
          newClusters.push(cluster);
          continue;
        }

        const avg = averageSimilarityOfCluster(cluster.concat(closest.cluster));

        if (avg >= followup) {
          if (getClosestClusterToCluster(closest.cluster)?.cluster == cluster) {
            removeCluster(closest.cluster);
            cluster.push(...closest.cluster);
            added = true;
          }
        }

        clusters.shift();
        newClusters.push(cluster);

      }

      clusters = newClusters;

      if (!added) break;
    }

    // incorporating stragglers
    while (Object.keys(added).length < entities.length) {
      let added = false;
      for (const cluster of clusters) {
        const r = getClosestEntityToCluster(cluster);
        if (!r) break;
        const {id: entity, } = r;
  
        const avg = averageSimilarityOfCluster(cluster.concat(entity));

        if (entity && avg >= straggler) {
          // if (getClosestClusterToEntity(entity).cluster == cluster) {
            cluster.push(entity);
            add([entity]);
            added = true;
          // }
        }
      }

      if (!added) break;
    }




    const config = {
      mostSimilar: {
        connect: this.props.connectClosest,
        threshold: this.props.closestThreshold,
      },
      all: {
        connect: this.props.connectAll,
        threshold: this.props.allThreshold,
      }
    }

    

    const stragglers = entities.filter(id => !added[id])
    const c = [];
    for (let i = 0; i < clusters.length; ++ i) c.push(i);

    let relatedToCluster = [];
    if (this.cluster?.length) {
      for (const id of this.cluster) {
        const related = relatedEntities(id, .8).map(x => x[0]._id)
        relatedToCluster.push(...related);
      }
    }

    relatedToCluster = _.uniq(relatedToCluster).filter(id => !this.cluster.includes(id)).map(id => {
      return [id, getEntitySimilarityToCuster(id, this.cluster)]
    });

    relatedToCluster.sort((a, b) => b[1] - a[1])

    const clusterTitle = getSummaryReact(this.cluster.map(id => entityDisplayName(id)));
    const topics = [];
    const embeddings = getEmbeddingsReactive([clusterTitle, ...allTopics]);
    const asdf = [];
    if (embeddings) {
      for (let i = 0; i < allTopics.length; ++ i) {
        const sim = ml.similarity.cosine(embeddings[0], embeddings[i + 1]);
        asdf.push([allTopics[i], sim]);
      }
      asdf.sort((a, b) => b[1] - a[1]);
    }

    return (
      <>
        <div className="topics">
          {allTopics.map(topic => <span key={topic}
          
            onClick={() => {
              this.state.activeTopics[topic] = !this.state.activeTopics[topic];
            }}
          className={cx('topic', { active: this.state.activeTopics[topic] })}>{topic}</span>)}
        </div>
        <ul>
        {clusters.map((cluster, i) => {
          const clusterTitle = getSummaryReact(cluster.map(id => entityDisplayName(id))) || '...';
          const active = Object.keys(x(this.state.activeTopics)).filter(topic => this.state.activeTopics[topic]);
          const embeddings = getEmbeddingsReactive([clusterTitle, ...allTopics]);
          if (!embeddings) return;
          const asdf = [];

          for (let i = 0; i < allTopics.length; ++ i) {
            const sim = ml.similarity.cosine(embeddings[0], embeddings[i + 1]);
            asdf.push([allTopics[i], sim]);
          }
          console.log(asdf);
    
          asdf.sort((a, b) => b[1] - a[1]);

          const topics = asdf.slice(0, 3).map(x => x[0]);

          if (active.length) {
            let found;
            for (const topic of active) {
              if (topics.includes(topic)) {
                found = true;
              }
            }
            if (!found) return;
          }

      
          return (
            <li key={i}> 
              <h3>{clusterTitle} ({averageSimilarityOfCluster(cluster)})</h3>
              <ul>
                {cluster.map(id => {
                  return (
                    <li key={id}>
                      <EntityRow id={id} />
                    </li>
                  )
                })}
              </ul>

            </li>
          )
        })}

        <li><h3>Stragglers</h3>

        <ul>
        {stragglers.map(id => {
                  return (
                    <li key={id}>
                      <EntityRow id={id} />
                    </li>
                  )
                })}
            </ul> 
        </li>
        </ul>
      </>
    )


    /*return (
      <>
      <div className="top">
        <button
          onClick={() => {
            copy(clusters.map(cluster => getSummaryReact(cluster.map(id => entityDisplayName(id)))).join('\n') )
          }}
        >.</button>
      <NetworkCluster
      nameLength={40}
        config={config}
        getName={id => {
          return getSummaryReact(clusters[id].map(id => entityDisplayName(id))) || '...';
          // return entityDisplayName(clusters[id][0]);
        }}
        getSimilarity={(a, b) => {
          return getClusterSimilarityToCluster(clusters[a], clusters[b]);
        }}
        nodes={c}
        onClickNode={id => {
          this.cluster = clusters[id];
          this.forceUpdate();
        }}
      />
      </div>
      <div className="bottom">
        <h3>{getSummaryReact(this.cluster.map(id => entityDisplayName(id))) || '...'}</h3>
        <h4>{asdf.slice(0, 3).map(x => `${x[0]} (${x[1]})`).join(', ')}</h4>
        {this.cluster.map(id => {
          return <EntityRow key={id} id={id} />
        })}

          <hr />
            {relatedToCluster.map(([id, sim]) => {
          return <div key={sim}>{sim} <EntityRow key={id} id={id} /></div>
        })}
      </div>
      </>
    )*/

    return (
      <>
        <ul>
          {clusters.map((c, i) => {
            return (
              <li key={i}>
                <h3>{i} ({averageSimilarityOfCluster(c)})</h3>
                <ul>
                  {c.map(id => <li key={id}><EntityRow id={id} /></li>)}
                </ul>
              </li>
            )
          })}

          <li><h3>Stragglers ({stragglers.length})</h3>
          
          <ul>
          {stragglers.map(id => <li key={id}><EntityRow id={id} /></li>)}
          </ul>
          </li>

        </ul>
      </>
    )
  }

  renderGraph() {


    let _cy = this.cy;

    const initCy = () => {
      _cy.on('tap', 'edge', e => {
        console.log(e);

        const node = e.target;

        const data = node.data();

        console.log(data);

        if (data.type == 'entity') {
          this.props.onClickEntity(data.id);
        }

        console.log(node.data().sim);

      })

      _cy.on('tap', 'entity', async e => {
        console.log(e);

        const node = e.target;

        const data = node.data();

        if (data.type == 'entity') {
          this.props.onClickEntity(data.id);

          // const entities = node.component().filter(c => c.data().type == 'entity').map(c => c.data().id);
          // const entities = node.connectedEdges().map(e => {
          //   const { source, target } = e.data();
          //   let other;
          //   if (source == data.id) other = target;
          //   else other = source;

          //   return other;
          // });

          let entities = [];

          node.component().depthFirstSearch({
            roots: data.id,
            visit: (v, e, u, i, depth) => {
              if (v.data().id != data.id) {
                entities.push(v.data().id);
              }
            },
          });


          const text = [getEmbeddingContent(data.id), ...entities.map(e => getEmbeddingContent(e))];

          const embeddings = await getEmbeddings(text);
          const sims = [];
          const baseEmbed = embeddings[0];
          const asdf = [];
          for (let i = 0; i < entities.length; ++ i) {
            const entityEmbedding = embeddings[i + 1];
            const sim = ml.similarity.cosine(baseEmbed, entityEmbedding);
            asdf.push([entities[i], sim])
          }

          asdf.sort((a, b) => b[1] - a[1])
          entities = asdf;//.map(a => a[0]);

          
          this.entities = [[data.id, 1], ...entities];
          this.activeEntity = data.id;

          this.forceUpdate();
        }
        else {
          console.log(data.sim)
        }


      })


      let popper;
      let cleanup;
      _cy.on('mouseover', 'entity', e => {
        cleanup?.();
        const node = e.target;
        if (node.data().type != 'entity')  return;
        let div;

        popper = node.popper({
          content: () => {
            div = document.createElement('div');
        
            div.innerHTML = `${entityDisplayName(node.data().id)} (${node.connectedEdges().length})`;
            div.style.backgroundColor = 'white';
            div.style.zIndex = '9999999999';
            div.style.border = '1px solid black';
            div.style.maxWidth = '200px';
            div.style.pointerEvents = 'none';
        
            document.body.appendChild(div);
        
            return div;
          },
          popper: {} // my popper options here
        });

        let update = () => {
          popper.update();
        };

        console.log(popper);

        
        node.on('position', update);
        
        _cy.on('pan zoom resize', update);
        
        cleanup = () => {
          node.removeListener('position', update);
          _cy.removeListener('pan zoom resize', update);
          popper.destroy();
          div.parentNode.removeChild(div);
        }
        
        

        // const data = node.data();

        // console.log(data);

      });

      _cy.on('mouseout', 'entity', e => {
        cleanup?.();
        cleanup = null;
        

      });

    }

    useEffect(() => {


      if (_cy) initCy();
      
    }, [_cy]);

    const nodes = this.props.entities;

      
    const getEmbeddingContent = id => {
      if (this.props.attribute) {
        const entity = getEntityById(id);
        return convert(entity.attributes?.[this.props.attribute] || '');
      }
      else {
        return entityDisplayName(id);
      }

    }


    const text = nodes.map(id => {
      return getEmbeddingContent(id);
    });

    const embeddings = getEmbeddingsReactive(text);
    if (!embeddings) return;

    const getSimilarity = (a, b) => {
      const aIndex = nodes.indexOf(a);
      const bIndex = nodes.indexOf(b);
      if (aIndex == -1 || bIndex == -1) throw new Error();

      return ml.similarity.cosine(embeddings[aIndex], embeddings[bIndex]);
    }

    const getName = id => {
      return entityDisplayName(id);
    }


    const config = {
      mostSimilar: {
        connect: this.props.connectClosest,
        threshold: this.props.closestThreshold,
      },
      all: {
        connect: this.props.connectAll,
        threshold: this.props.allThreshold,
      }
    }

    

    return (
      <>
        <NetworkCluster
          config={config}
          getName={getName}
          getSimilarity={getSimilarity}
          nodes={nodes}
          active={this.props.active}
          onClickNode={(id) => {
            this.props.onClickEntity(id);
          }}
        />
      </>
    )

  }

  render() {
    if (this.props.view.clusterViewType == '1ba52e45-2e21-5346-8dc5-9662ee43794c') {
      return this.renderGraph();
    }
    else {
      return this.renderClusters();
    }
  }
}
