import { Component } from 'react';
import { component } from './component';
import sanitizeHtml from 'sanitize-html';
import { XObject } from './XObject';

export function htmlToBlocks(d) {
  const cont = document.createElement('div');
  cont.innerHTML = sanitizeHtml(d);

  const processBlockEl = (el: Element) => {
    const content = [];
    const blocks = [];
    for (const child of el.childNodes) {
      if (child instanceof Element) {
        if (child.tagName == 'STRONG' || child.tagName == 'P') {
          content.push(child.textContent);
        }
        else if (child.tagName == 'UL' || child.tagName == 'OL') {
          blocks.push(...processListEl(child));
        }
      }
      else if (child instanceof Text) {
        content.push(child.textContent);
      }
    }

    return {
      _id: XObject.id(),
      data: content.join(''),
      children: blocks,
    }
  }

  const processListEl = (el: Element) => {
    const blocks = [];
    for (const child of el.children) {
      blocks.push(toBlocks(child));
    }
    return blocks;
  }

  const toBlocks = (el: Element) => {
    if (el.tagName == 'UL' || el.tagName == 'OL') {
      return processListEl(el);
    }
    else if (el.tagName == 'LI') {
      return processBlockEl(el);
    }
  }

  return toBlocks(cont.firstChild as any);
}

@component
export class WIP extends Component {
  render() {
    return (
      <>
        <input
          onPaste={e => {
            e.preventDefault();
            const d = e.clipboardData.getData('text/html')
            console.log(htmlToBlocks(d));
          }}
        />
      </>
    )
  }
}
