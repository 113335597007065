import { extractFromEl } from "../richTextHelpers";
import { flexGrammar, grammar } from "../shorthand/formula";
import { expandFormula } from '../shorthand/formula';
import _, { range } from 'lodash';
import { unwrapCst } from "./unwrapCst";
import { getCst } from "./getCst";

export const identifierTypes = ['identifier', 'oldRhinos', 'function', 'jsxTag'];
export const identifierCategory = { identifier: 'var', jsxTag: 'tag', function: 'identifier'}

export function getParts(text, pos, type) {
  if (_.isNumber(pos) && pos < 0) pos = null;

  let bracketPos;

  if (_.isNumber(pos)) {
    const brackets = ['[', ']', '(', ')', '{', '}'];
    if (brackets.includes(text[pos])) {
      bracketPos = pos;
    }
    else if (brackets.includes(text[pos - 1])) {
      bracketPos = pos - 1;
    }
  }


  const match = grammar.match(text);
  const flexMatch = flexGrammar.match(text);
  const unwrappedCst = unwrapCst(flexMatch['_cst'], text);

  const extraStyles = {};
  const classes = {};


  const parts: {
    range: [number, number];
    type: string;
  }[] = [];

  if (type == 'comment') {
    parts.push({
      range: [0, text.length],
      type: 'comment',
    });
  }
  else if (type == 'styledComponent') {
  }
  else {
    interface Node {
      childOffsets: number[];
      children: Node[];
      matchLength: number;
      ruleName: string;
      isIteration;
    }

    let functionCall = false;

    const iterate = (node: Node, start: number) => {
      if (!node) return;

      function getOffset(parent, childIndex) {
        if (parent.children[childIndex].isIteration()) return 0;
        else return parent.childOffsets[childIndex];
      }

      const terminal = ['integer', 'oldRhinos', 'identifier', 'Element_onlyOpening', 'TagName', 'Attributes', 'AttributeValue_noSyntaxString'];

      function getRange(index): [number, number] {
        return [start + node.childOffsets[index], node.children[index].matchLength];
      }

      function pushBrackets(first, last) {
        const firstPos = start + node.childOffsets[first];
        const lastPos = start + node.childOffsets[last];

        let type;

        let count = 0;
        type = 'bracket';
        if (node.children[first].matchLength) {
          parts.push({
            range: [start + node.childOffsets[first], node.children[first].matchLength],
            type,
          });
          ++count;
        }
        if (node.children[last].matchLength) {
          ++count;
          parts.push({
            range: [start + node.childOffsets[last], node.children[last].matchLength],
            type,
          });
        }
        if (count == 2) {
          if (_.isNumber(bracketPos) && (bracketPos === firstPos || bracketPos === lastPos)) {
            // extraStyles[firstPos] = { color: 'black'};
            // extraStyles[lastPos] = { color: 'black'};
            classes[firstPos] = ['bracketHighlight'];
            classes[lastPos] = ['bracketHighlight'];

          }
        }
      }

      function pushBracketsPoses(firstPos, lastPos) {

        let type;

        let count = 0;
        type = 'bracket';

          parts.push({
            range: [firstPos, 1],
            type,
          });
          ++count;


          ++count;
          parts.push({
            range: [lastPos, 1],
            type,
          });

        if (count == 2) {
          if (_.isNumber(bracketPos) && (bracketPos === firstPos || bracketPos === lastPos)) {
            // extraStyles[firstPos] = { color: 'black'};
            // extraStyles[lastPos] = { color: 'black'};
            classes[firstPos] = ['bracketHighlight'];
            classes[lastPos] = ['bracketHighlight'];
          }
        }
      }

      const rules = {
        DeclareConst: () => {
          parts.push({
            range: [start + node.childOffsets[0], node.children[0].matchLength],
            type: 'identifier',
          });

          parts.push({
            range: [start + node.childOffsets[1], node.children[1].matchLength],
            type: 'operator',
          });

          iterate(node.children[2], start + getOffset(node, 2));
        },
        InterpolatedString() {
          const segments = node.children[2];
          const nonStringRanges = [];
          for (let i = 0; i < segments.children.length; ++i) {
            const segment = segments.children[i];
            if (segment.ruleName == 'InterpolatedSegment') {
              if (segment.children[0].ruleName == 'InterpolatedSegment_interpolation') {
                const child = segment.children[0].children[2];
                pushBracketsPoses(start + segments.childOffsets[i], start + segments.childOffsets[i] + child.matchLength + 1);
                nonStringRanges.push([
                  start + segments.childOffsets[i],
                  start + segments.childOffsets[i] + child.matchLength + 2,
                ]);
                iterate(child, start + segments.childOffsets[i] + 1);
              }
            }
          }

          if (nonStringRanges.length) {
            let s = 0;
            for (let i = 0; i < nonStringRanges.length; ++i) {
              parts.push({
                range: [start + s, nonStringRanges[i][0] - (start + s)],
                type: 'string',
              });
              s = nonStringRanges[i][1] + 1;
            }

            parts.push({
              range: [nonStringRanges[nonStringRanges.length - 1][1], start + node.matchLength - nonStringRanges[nonStringRanges.length - 1][1]],
              type: 'string',
            });

          }
          else {
            parts.push({
              range: [start, node.matchLength],
              type: 'string',
            });
          }
        },
        Assign: () => {
          iterate(node.children[0], start + getOffset(node, 0));

          parts.push({
            range: getRange(1),
            type: 'operator',
          });

          iterate(node.children[2], start + getOffset(node, 2));
        },
        PlusEquals: () => {
          parts.push({
            range: getRange(0),
            type: 'identifier',
          });

          parts.push({
            range: getRange(1),
            type: 'operator',
          });

          iterate(node.children[2], start + getOffset(node, 2));
        },
        Array_ary() {
          pushBrackets(1, 5);
          iterate(node.children[3], start + getOffset(node, 3));
        },
        NonemptyListOf() {
          for (let i = 0; i < node.children.length; ++i) {
            iterate(node.children[i], start + getOffset(node, i));
          }
        },
        string() {
          // console.log(node);
          parts.push({
            range: [start + node.childOffsets[0], node.matchLength],
            type: 'string',
          });
        },
        integer() {
          // console.log(node);
          parts.push({
            range: [start + node.childOffsets[0], node.matchLength],
            type: 'number',
          });
        },
        identifier() {
          parts.push({
            range: [start + node.childOffsets[0], node.matchLength],
            type: functionCall ? 'function' : 'identifier',
          });
        },
        oldRhinos() {
          parts.push({
            range: [start + node.childOffsets[0], node.matchLength],
            type: functionCall ? 'function' : 'oldRhinos',
          });
        },
        Expr_comment() {
          parts.push({
            range: [start + node.childOffsets[0], node.matchLength],
            type: 'comment',
          });
        },
        Expr_for() {
          parts.push({
            range: getRange(0),
            type: 'controlFlow',
          });
          parts.push({
            range: getRange(1),
            type: 'identifier',
          });
          parts.push({
            range: getRange(2),
            type: 'controlFlow',
          });
          iterate(node.children[3], getOffset(node, 3));
        },
        Expr_if() {
          parts.push({
            range: getRange(0),
            type: 'controlFlow',
          });
          iterate(node.children[1], getOffset(node, 1));
        },
        Expr_elseIf() {
          parts.push({
            range: getRange(0),
            type: 'controlFlow',
          });
          iterate(node.children[1], getOffset(node, 1));
        },
        Expr_else() {
          parts.push({
            range: getRange(0),
            type: 'controlFlow',
          });
        },
        Boolean() {
          parts.push({
            range: getRange(0),
            type: 'keyword',
          });
        },
        Null() {
          parts.push({
            range: getRange(0),
            type: 'keyword',
          });
        },
        Additive_add() {
          iterate(node.children[0], start + getOffset(node, 0));
          iterate(node.children[2], start + getOffset(node, 2));
        },
        Lte_one() {
          iterate(node.children[0], start + getOffset(node, 0));
          iterate(node.children[2], start + getOffset(node, 2));
        },
        Lt_one() {
          iterate(node.children[0], start + getOffset(node, 0));
          iterate(node.children[2], start + getOffset(node, 2));
        },
        Negate() {
          iterate(node.children[1], start + getOffset(node, 1));
        },
        Gt_one() {
          iterate(node.children[0], start + getOffset(node, 0));
          iterate(node.children[2], start + getOffset(node, 2));
        },
        Not() {

          iterate(node.children[1], start + getOffset(node, 1));
        },
        Additive_subtract() {
          iterate(node.children[0], start + getOffset(node, 0));
          iterate(node.children[2], start + getOffset(node, 2));

        },
        Labeled() {
          // console.log(node);
          parts.push({
            range: [start + node.childOffsets[0], node.children[0].matchLength + node.children[1].matchLength],
            type: 'oldRhinos',
          });

          iterate(node.children[2], start + getOffset(node, 2));
        },
        Element_onlyOpening() {
          parts.push({
            range: getRange(0),
            type: 'jsxBracket',
          });
          iterate(node.children[1], start + getOffset(node, 1));
          iterate(node.children[2], start + getOffset(node, 2));
          parts.push({
            range: getRange(3),
            type: 'jsxBracket',
          });
          iterate(node.children[4], start + getOffset(node, 4));
        },
        Child_expr() {
          pushBrackets(0, 2);
          iterate(node.children[1], start + getOffset(node, 1));
        },
        Element_asdf() {
          parts.push({
            range: getRange(0),
            type: 'jsxBracket',
          });
          parts.push({
            range: getRange(1),
            type: 'jsxBracket',
          });
          iterate(node.children[2], start + getOffset(node, 2));
        },
        Primary_tertiary() {
          iterate(node.children[0], start + getOffset(node, 0));
          iterate(node.children[4], start + getOffset(node, 4));
          iterate(node.children[8], start + getOffset(node, 8));
        },
        Primary_parens() {
          pushBrackets(0, 2);
          iterate(node.children[1], start + getOffset(node, 1));
        },
        TagName() {
          if (node.children[0].ruleName != 'ObjectRef') {
            const part = text.slice(start, start + node.matchLength);

            if (part[0].toUpperCase() == part[0]) {
              parts.push({
                range: [start, node.matchLength],
                type: 'jsxTag',
              });

            }
            else {

              parts.push({
                range: [start, node.matchLength],
                type: 'jsxTag',
              });

            }
          }
          else {
            parts.push({
              range: [start, node.matchLength],
              type: 'jsxTag',
            });

          }

        },
        Attributes() {
          for (let i = 0; i < node.children.length; ++i) {
            iterate(node.children[i], start + getOffset(node, i));
          }
        },

        Attribute_pair() {
          parts.push({
            range: getRange(0),
            type: 'oldRhinos',
          });

          iterate(node.children[2], start + getOffset(node, 2));
        },
        AttributeValue_expr() {
          pushBrackets(0, 2);
          iterate(node.children[1], start + getOffset(node, 1));
        },
        AttributeValue_noSyntaxString() {
          parts.push({
            range: [start + node.childOffsets[0], node.matchLength],
            type: 'string',
          });
        },
        PipedLine() {
          iterate(node.children[1], start + getOffset(node, 1));
        },
        Primary_accessor() {
          iterate(node.children[0], start + getOffset(node, 0));


          iterate(node.children[2], start + getOffset(node, 2));
        },
        Primary_bracketAccessor() {
          iterate(node.children[0], start + getOffset(node, 0));
          iterate(node.children[2], start + getOffset(node, 2));
          pushBrackets(1, 3);
        },
        FunctionCall_noArgs() {
          if (node.children[0].children[0].ruleName == 'identifier') {



            // functionCall = true;
            // iterate(node.children[0], start + getOffset(node, 0));
            // functionCall = false;
          }
          else if (node.children[0].children[0].ruleName == 'Primary_accessor') {
            iterate(node.children[0], start + getOffset(node, 0));


            // const first = node.children[0].children[0].children[0];
            // iterate(first, start + getOffset(node.children[0].children[0], 0));

            // const last = node.children[0].children[0].children[2];
            // functionCall = true;
            // iterate(last, start + getOffset(node.children[0].children[0], 2));
            // functionCall = false;
          }
          else {
            iterate(node.children[0], start + getOffset(node, 0));
          }

          pushBrackets(1, 3);
        },
        FunctionCall_withArgs() {
          if (node.children[0].children[0].ruleName == 'identifier') {
            functionCall = true;
            iterate(node.children[0], start + getOffset(node, 0));
            functionCall = false;
          }
          else if (node.children[0].children[0].ruleName == 'Primary_accessor') {
            iterate(node.children[0], start + getOffset(node, 0));

            // const first = node.children[0].children[0].children[0];
            // iterate(first, start + getOffset(node.children[0].children[0], 0));

            // const last = node.children[0].children[0].children[2];
            // functionCall = true;
            // iterate(last, start + getOffset(node.children[0].children[0], 2));
            // functionCall = false;
          }
          else {
            iterate(node.children[0], start + getOffset(node, 0));
          }

          pushBrackets(1, 5);

          iterate(node.children[3], start + getOffset(node, 3));
        },
        Closure_singleIdentifier() {
          parts.push({
            range: getRange(0),
            type: 'identifier',
          });

          iterate(node.children[4], start + getOffset(node, 4));
        },
        Closure_noParams() {
          iterate(node.children[2], start + getOffset(node, 2));
        },
        Closure_identifierList() {
          pushBrackets(0, 5);
          iterate(node.children[9], start + getOffset(node, 9));
          iterate(node.children[2], start + getOffset(node, 2));
        },
        Dictionary_dict() {
          pushBrackets(1, 5);
          iterate(node.children[3], start + getOffset(node, 3));
        },
        DictionaryEntry() {
          iterate(node.children[0], start + getOffset(node, 0));
          iterate(node.children[2], start + getOffset(node, 2));
        },
        Dictionary_emptyDict() {
          pushBrackets(1, 3);
        },
        Array_emptyArray() {
          pushBrackets(1, 3);
        },
        And_asfd() {
          iterate(node.children[0], start + getOffset(node, 0));
          iterate(node.children[2], start + getOffset(node, 2));
        },
        Eq_add() {
          iterate(node.children[0], start + getOffset(node, 0));
          iterate(node.children[2], start + getOffset(node, 2));
        },
        ObjectRef() {
          // parts.push({
          //   range: [start, node.matchLength],
          //   type: 'string',
          // })
        },
        ObjectRefId() {

        },
        StyledComponent() {
          parts.push({
            range: getRange(1),
            type: 'jsxTag',
          });
        },
      };

      const a = { test: 'asdf' };

      if (!terminal.includes(node.ruleName) && node.children && node.children.length == 1 && node.childOffsets[0] == 0 && node.children[0].matchLength == node.matchLength) {
        iterate(node.children[0], start);
      }
      else {
        // console.log(node.ruleName);
        if (node.ruleName) {
          if (rules[node.ruleName]) {
            rules[node.ruleName]();
          }
          else {
            console.log('no handler', node.ruleName, node);
          }
        }
        else if (node.children) {
          for (let i = 0; i < node.children.length; ++i) {
            iterate(node.children[i], start + getOffset(node, i));
          }
        }
      }
    };

    iterate(match['_cst'], 0);


  }

  return parts;
}

export function highlight({ctx, el, type, pos, getIdentifierId, getType, getTagId = undefined}) {
  const styles = {
    identifier: {
      color: '#4f67cd',
    },
    oldRhinos: {
      color: '#4f67cd',
    },
    typeProp: {
      color: '#0e289c',
    },
    typeFunc: {
      color: '#964d03',
    },
    string: {
      color: 'red',
    },
    comment: {
      color: 'green'
    },
    bracket: {
      color: '#6f6f6f',
    },
    number: {
      color: '#b9b950'
    },
    controlFlow: {
      color: 'blue',
    },
    jsxBracket: {
      color: 'gray',
    },
    jsxTag: {
      color: 'purple',
    },
    function: {
      color: '#b97f44',
    },
    keyword: {
      color: '#446bb9',
    },

    styleName: {
      color: 'green',
    },
    styleValue: {
      color: 'orange',
    }
  };


  if (_.isNumber(pos) && pos < 0) pos = null;

  const child: Text = el.firstChild as any;
  if (!child) return;


  // let pos;
  // if (isInDataEditor(el)) {
  //   pos = getExpandedPositionInDataEditor(ctx, el);
  //   console.log(pos);
  // }


  function getEl(start) {
    let pos = 0;
    let node = child as any;
    while (node) {
      if (node.getAttribute?.('data-type')) {
        if (start == pos) {
          return [node, pos];
        }
        const type = node.getAttribute('data-type');
        const part = ctx.types[type].part(node);
        const txt = ctx.types[type].text(part[0]);
        pos += txt.length;

        if (start == pos - 1) {
          return [node, pos - 1];
        }
        node = node.nextSibling as any;
      }
      else if (node.firstChild?.getAttribute?.('data-type')) {
        const type = node.firstChild.getAttribute('data-type');
        const part = ctx.types[type].part(node.firstChild);
        const txt = ctx.types[type].text(part[0]);
        pos += txt.length;
        node = node.nextSibling as any;

      }
      else if (start < pos + node.textContent.length) {
        return [node, pos];
      }
      else {
        // if (node.firstChild?.getAttribute?.('data-type')) {
        //   const type = node.firstChild.getAttribute('data-type');
        //   const part = ctx.types[type].part(node.firstChild);
        //   const txt = ctx.types[type].text(part[0]);
        //   pos += txt.length;
        // }
        // else {
          pos += node.textContent.length;
        // }

        node = node.nextSibling as any;
      }
    }

    return [];

  }
  
  function wrap(start, end, styles) {
    if (getEl(start)?.[0] == getEl(end)?.[0]) {
      const [node, pos] = getEl(start);
      if (node) {
        if (node.splitText) {
          const first = node.splitText(start - pos);
          first.splitText(end - start + 1);
          const el = document.createElement('span');
          for (const name in styles) {
            el.style[name] = styles[name];
          }
          el.textContent = first.textContent;
          el.setAttribute('data-presentation', 'true');
          first.replaceWith(el);
          return el;
        }
        else {
          const el = document.createElement('span');
          for (const name in styles) {
            el.style[name] = styles[name];
          }
          el.setAttribute('data-presentation', 'true');
          el.setAttribute('contenteditable', 'false');
          
          node.parentNode.insertBefore(el, node);
          el.appendChild(node);
          return el;
        }
      }

    }
    else {
      const startNode = getEl(start);
      const endNode = getEl(end);
      if (startNode && endNode) {
        let [node, pos] = startNode;
        while (node) {
          if (node instanceof Text) {
            const el = document.createElement('span');
            for (const name in styles) {
              el.style[name] = styles[name];
            }
            el.textContent = node.textContent;
            el.setAttribute('data-presentation', 'true');
            node.replaceWith(el);
            node = el;
          }
  
          if (node == endNode) break;
          node = node.nextSibling;
        }
      }
    }
  }

  const data = extractFromEl(ctx, el);
  const text = expandFormula(data, {
    types: ctx.types,
  });

  let bracketPos;

  if (_.isNumber(pos)) {
    const brackets = ['[', ']', '(', ')', '{', '}'];
    if (brackets.includes(text[pos])) {
      bracketPos = pos;
    }
    else if (brackets.includes(text[pos - 1])) {
      bracketPos = pos - 1;
    }
  }


  const match = grammar.match(text);
  const flexMatch = flexGrammar.match(text);
  const unwrappedCst = unwrapCst(flexMatch['_cst'], text);


  const extraStyles = {};
  const classes = {};


  const parts: {
    range: [number, number];
    type: string;
    fullRange?;
  }[] = [];

  if (type == 'comment') {
    parts.push({
      range: [0, text.length],
      type: 'comment',
    });
  }
  else if (type == 'styledComponent') {
    const p = text.split(':')
    if (p.length == 2) {
      parts.push({
        range: [0, p[0].length],
        type: 'styleName',
      })

      parts.push({
        range: [p[0].length + 1, p[1].length],
        type: 'styleValue',
      })
    }
  }
  else {
    interface Node {
      childOffsets: number[];
      children: Node[];
      matchLength: number;
      ruleName: string;
      isIteration;
    }

    let functionCall = false;

    const iterate = (node: Node, start: number) => {
      if (!node) return;

      function getOffset(parent, childIndex) {
        if (parent.children[childIndex].isIteration()) return 0;
        else return parent.childOffsets[childIndex];
      }

      const terminal = ['integer', 'identifier', 'oldRhinos', 'Element_onlyOpening', 'TagName', 'Attributes', 'AttributeValue_noSyntaxString', 'Expr_else', 'Boolean', 'Null', 'FunctionCall_noArgs'];

      function getRange(index): [number, number] {
        return [start + node.childOffsets[index], node.children[index].matchLength];
      }

      function pushBrackets(first, last) {
        const firstPos = start + node.childOffsets[first];
        const lastPos = start + node.childOffsets[last];

        let type;

        let count = 0;
        type = 'bracket';
        if (node.children[first].matchLength) {
          parts.push({
            range: [start + node.childOffsets[first], node.children[first].matchLength],
            type,
          });
          ++count;
        }
        if (node.children[last].matchLength) {
          ++count;
          parts.push({
            range: [start + node.childOffsets[last], node.children[last].matchLength],
            type,
          });
        }
        if (count == 2) {
          if (_.isNumber(bracketPos) && (bracketPos === firstPos || bracketPos === lastPos)) {
            // extraStyles[firstPos] = { color: 'black'};
            // extraStyles[lastPos] = { color: 'black'};
            classes[firstPos] = ['bracketHighlight'];
            classes[lastPos] = ['bracketHighlight'];
          }
        }
      }

      function pushBracketsPoses(firstPos, lastPos) {

        let type;

        let count = 0;
        type = 'bracket';

          parts.push({
            range: [firstPos, 1],
            type,
          });
          ++count;


          ++count;
          parts.push({
            range: [lastPos, 1],
            type,
          });

        if (count == 2) {
          if (_.isNumber(bracketPos) && (bracketPos === firstPos || bracketPos === lastPos)) {
            // extraStyles[firstPos] = { color: 'black'};
            // extraStyles[lastPos] = { color: 'black'};
            classes[firstPos] = ['bracketHighlight', 'left'];
            classes[lastPos] = ['bracketHighlight', 'right'];
          }
        }
      }

      const rules = {
        DeclareConst: () => {
          parts.push({
            range: [start + node.childOffsets[0], node.children[0].matchLength],
            type: 'identifier',
          });

          parts.push({
            range: [start + node.childOffsets[1], node.children[1].matchLength],
            type: 'operator',
          });

          iterate(node.children[2], start + getOffset(node, 2));
        },
        InterpolatedString() {
          const segments = node.children[2];
          const nonStringRanges = [];
          for (let i = 0; i < segments.children.length; ++i) {
            const segment = segments.children[i];
            if (segment.ruleName == 'InterpolatedSegment') {
              if (segment.children[0].ruleName == 'InterpolatedSegment_interpolation') {
                const child = segment.children[0].children[2];
                pushBracketsPoses(start + segments.childOffsets[i], start + segments.childOffsets[i] + child.matchLength + 1);
                nonStringRanges.push([
                  start + segments.childOffsets[i],
                  start + segments.childOffsets[i] + child.matchLength + 2,
                ]);
                iterate(child, start + segments.childOffsets[i] + 1);
              }
            }
          }

          if (nonStringRanges.length) {
            let s = 0;
            for (let i = 0; i < nonStringRanges.length; ++i) {
              parts.push({
                range: [start + s, nonStringRanges[i][0] - (start + s)],
                type: 'string',
              });
              s = nonStringRanges[i][1] + 1;
            }

            parts.push({
              range: [nonStringRanges[nonStringRanges.length - 1][1], start + node.matchLength - nonStringRanges[nonStringRanges.length - 1][1]],
              type: 'string',
            });

          }
          else {
            parts.push({
              range: [start, node.matchLength],
              type: 'string',
            });
          }
        },
        Assign: () => {
          iterate(node.children[0], start + getOffset(node, 0));

          parts.push({
            range: getRange(1),
            type: 'operator',
          });

          iterate(node.children[2], start + getOffset(node, 2));
        },
        PlusEquals: () => {
          parts.push({
            range: getRange(0),
            type: 'identifier',
          });

          parts.push({
            range: getRange(1),
            type: 'operator',
          });

          iterate(node.children[2], start + getOffset(node, 2));
        },
        Array_ary() {
          pushBrackets(1, 5);
          iterate(node.children[3], start + getOffset(node, 3));
        },
        NonemptyListOf() {
          for (let i = 0; i < node.children.length; ++i) {
            iterate(node.children[i], start + getOffset(node, i));
          }
        },
        string() {
          // console.log(node);
          parts.push({
            range: [start + node.childOffsets[0], node.matchLength],
            type: 'string',
          });
        },
        integer() {
          // console.log(node);
          parts.push({
            range: [start + node.childOffsets[0], node.matchLength],
            type: 'number',
          });
        },
        identifier() {
          parts.push({
            range: [start + node.childOffsets[0], node.matchLength],
            type: functionCall ? 'function' : 'identifier',
          });
        },
        oldRhinos() {
          parts.push({
            range: [start + node.childOffsets[0], node.matchLength],
            type: functionCall ? 'function' : 'oldRhinos',
          });
        },
        Expr_comment() {
          parts.push({
            range: [start + node.childOffsets[0], node.matchLength],
            type: 'comment',
          });
        },
        Expr_for() {
          parts.push({
            range: getRange(0),
            type: 'controlFlow',
          });
          parts.push({
            range: getRange(1),
            type: 'identifier',
          });
          parts.push({
            range: getRange(2),
            type: 'controlFlow',
          });
          iterate(node.children[3], getOffset(node, 3));
        },
        Expr_if() {
          parts.push({
            range: getRange(0),
            type: 'controlFlow',
          });
          iterate(node.children[1], getOffset(node, 1));
        },
        Expr_elseIf() {
          parts.push({
            range: getRange(0),
            type: 'controlFlow',
          });
          iterate(node.children[1], getOffset(node, 1));
        },
        Expr_else() {
          parts.push({
            range: getRange(0),
            type: 'controlFlow',
          });
        },
        Boolean() {
          parts.push({
            range: getRange(0),
            type: 'keyword',
          });
        },
        Null() {
          parts.push({
            range: getRange(0),
            type: 'keyword',
          });
        },
        Additive_add() {
          iterate(node.children[0], start + getOffset(node, 0));
          iterate(node.children[2], start + getOffset(node, 2));
        },
        Lte_one() {
          iterate(node.children[0], start + getOffset(node, 0));
          iterate(node.children[2], start + getOffset(node, 2));
        },
        Lt_one() {
          iterate(node.children[0], start + getOffset(node, 0));
          iterate(node.children[2], start + getOffset(node, 2));
        },
        Negate() {
          iterate(node.children[1], start + getOffset(node, 1));
        },
        Gt_one() {
          iterate(node.children[0], start + getOffset(node, 0));
          iterate(node.children[2], start + getOffset(node, 2));
        },
        Not() {

          iterate(node.children[1], start + getOffset(node, 1));
        },
        Additive_subtract() {
          iterate(node.children[0], start + getOffset(node, 0));
          iterate(node.children[2], start + getOffset(node, 2));

        },
        Labeled() {
          parts.push({
            range: [start + node.childOffsets[0], node.children[0].matchLength + node.children[1].matchLength],
            type: 'oldRhinos',
          });

          iterate(node.children[2], start + getOffset(node, 2));
        },
        Element_onlyOpening() {
          parts.push({
            range: getRange(0),
            type: 'jsxBracket',
          });
          iterate(node.children[1], start + getOffset(node, 1));
          iterate(node.children[2], start + getOffset(node, 2));
          parts.push({
            range: getRange(3),
            type: 'jsxBracket',
          });
          iterate(node.children[4], start + getOffset(node, 4));
        },
        Child_expr() {
          pushBrackets(0, 2);
          iterate(node.children[1], start + getOffset(node, 1));
        },
        Element_asdf() {
          parts.push({
            range: getRange(0),
            type: 'jsxBracket',
          });
          parts.push({
            range: getRange(1),
            type: 'jsxBracket',
          });
          iterate(node.children[2], start + getOffset(node, 2));
        },
        Primary_tertiary() {
          iterate(node.children[0], start + getOffset(node, 0));
          iterate(node.children[4], start + getOffset(node, 4));
          iterate(node.children[8], start + getOffset(node, 8));
        },
        Primary_parens() {
          pushBrackets(0, 2);
          iterate(node.children[1], start + getOffset(node, 1));
        },
        TagName() {
          if (node.children[0].ruleName != 'ObjectRef') {
            const part = text.slice(start, start + node.matchLength);

            if (part[0].toUpperCase() == part[0]) {
              parts.push({
                range: [start, node.matchLength],
                type: 'jsxTag',
              });

            }
            else {

              parts.push({
                range: [start, node.matchLength],
                type: 'jsxTag',
              });

            }
          }
          else {
            parts.push({
              range: [start, node.matchLength],
              type: 'jsxTag',
            });

          }

        },
        Attributes() {
          for (let i = 0; i < node.children.length; ++i) {
            iterate(node.children[i], start + getOffset(node, i));
          }
        },
        Binding() {
          parts.push({
            range: [start + node.childOffsets[0], node.children[0].matchLength],
            type: 'oldRhinos',
          });

          parts.push({
            range: getRange(1),
            type: 'operator',
          });

          iterate(node.children[3], start + getOffset(node, 3));
        },

        Attribute_pair() {
          parts.push({
            range: getRange(0),
            type: 'oldRhinos',
          });

          iterate(node.children[2], start + getOffset(node, 2));
        },
        AttributeValue_expr() {
          pushBrackets(0, 2);
          iterate(node.children[1], start + getOffset(node, 1));
        },
        AttributeValue_noSyntaxString() {
          parts.push({
            range: [start + node.childOffsets[0], node.matchLength],
            type: 'string',
          });
        },
        PipedLine() {
          iterate(node.children[1], start + getOffset(node, 1));
        },
        Primary_accessor() {
          iterate(node.children[0], start + getOffset(node, 0));
          if (node.children[2]?.children[0]?.children?.[0]?.ruleName == 'oldRhinos') {
            const first = getCst(unwrappedCst, start + getOffset(node, 0) + node.children[0].matchLength)['findLast'](cst => cst[0]?.ruleName == 'Primary_accessor')?.[0]?.children?.[0];
            if (first) {
              const type = getType(first);
              const prop = text.slice(start + node.childOffsets[2], start + node.childOffsets[2] + node.children[2].matchLength);
  
              let t;
              if (type) {
                const propType = type.getProperty(prop);
                if (propType) {
                  if (propType.constructor.name == 'FunctionType') {
                    t = 'typeFunc'
                  }
                  else {
                    t = 'typeProp';
                  }
                }
              }
  
              if (!t) {
                t = functionCall ? 'function' : 'oldRhinos';
              }
  
              parts.push({
                fullRange: [ start, node.children[0].matchLength + node.children[1].matchLength + node.children[2].matchLength ],
                range: [start + node.childOffsets[2], node.children[2].matchLength],
                type: t,
              });
              return;
            }
            
            

          }
          // else {
            iterate(node.children[2], start + getOffset(node, 2));
          // }
        },
        Primary_bracketAccessor() {
          iterate(node.children[0], start + getOffset(node, 0));
          iterate(node.children[2], start + getOffset(node, 2));
          pushBrackets(1, 3);
        },
        FunctionCall_noArgs() {
          if (node.children[0].children[0].ruleName == 'identifier') {
            functionCall = true;
            iterate(node.children[0], start + getOffset(node, 0));
            functionCall = false;
          }
          else if (node.children[0].children[0].ruleName == 'Primary_accessor') {
            iterate(node.children[0], start + getOffset(node, 0));

            // const first = node.children[0].children[0].children[0];
            // iterate(first, start + getOffset(node.children[0].children[0], 0));

            // const last = node.children[0].children[0].children[2];
            // functionCall = true;
            // iterate(last, start + getOffset(node.children[0].children[0], 2));
            // functionCall = false;
          }
          else {
            iterate(node.children[0], start + getOffset(node, 0));
          }


          pushBrackets(1, 3);
        },
        FunctionCall_withArgs() {
          if (node.children[0].children[0].ruleName == 'identifier') {
            functionCall = true;
            iterate(node.children[0], start + getOffset(node, 0));
            functionCall = false;
          }
          else if (node.children[0].children[0].ruleName == 'Primary_accessor') {
            iterate(node.children[0], start + getOffset(node, 0));

            // const first = node.children[0].children[0].children[0];
            // iterate(first, start + getOffset(node.children[0].children[0], 0));

            // const last = node.children[0].children[0].children[2];
            // functionCall = true;
            // iterate(last, start + getOffset(node.children[0].children[0], 2));
            // functionCall = false;
          }
          else {
            iterate(node.children[0], start + getOffset(node, 0));
          }

          pushBrackets(1, 5);

          iterate(node.children[3], start + getOffset(node, 3));
        },
        Closure_singleIdentifier() {
          parts.push({
            range: getRange(0),
            type: 'identifier',
          });

          iterate(node.children[4], start + getOffset(node, 4));
        },
        Closure_noParams() {
          iterate(node.children[2], start + getOffset(node, 2));
        },
        Closure_identifierList() {
          pushBrackets(0, 5);
          iterate(node.children[9], start + getOffset(node, 9));
          iterate(node.children[2], start + getOffset(node, 2));
        },
        Dictionary_dict() {
          pushBrackets(1, 5);
          iterate(node.children[3], start + getOffset(node, 3));
        },
        DictionaryEntry() {
          iterate(node.children[0], start + getOffset(node, 0));
          iterate(node.children[2], start + getOffset(node, 2));
        },
        Dictionary_emptyDict() {
          pushBrackets(1, 3);
        },
        Array_emptyArray() {
          pushBrackets(1, 3);
        },
        And_asfd() {
          iterate(node.children[0], start + getOffset(node, 0));
          iterate(node.children[2], start + getOffset(node, 2));
        },
        Eq_add() {
          iterate(node.children[0], start + getOffset(node, 0));
          iterate(node.children[2], start + getOffset(node, 2));
        },
        ObjectRef() {
          // parts.push({
          //   range: [start, node.matchLength],
          //   type: 'string',
          // })
        },
        ObjectRefId() {

        },
        StyledComponent() {
          parts.push({
            range: getRange(1),
            type: 'jsxTag',
          });
        },
      };

      if (!terminal.includes(node.ruleName) && node.children && node.children.length == 1 && node.childOffsets[0] == 0 && node.children[0].matchLength == node.matchLength) {
        iterate(node.children[0], start);
      }
      else {
        if (node.ruleName) {
          if (rules[node.ruleName]) {
            rules[node.ruleName]();
          }
          else {
            console.log('no handler', node.ruleName, node);
          }
        }
        else if (node.children) {
          for (let i = 0; i < node.children.length; ++i) {
            iterate(node.children[i], start + getOffset(node, i));
          }
        }
      }
    };

    iterate(match['_cst'], 0);
  }

  parts.sort((a, b) => {
    return a.range[0] - b.range[0];
  });

  for (const part of parts) {
    const st = {
      ...styles[part.type],
      ...(extraStyles[part.range[0]] || {}),
    };
    const newEl = wrap(part.range[0], part.range[0] + part.range[1] - 1, st);
    if (!newEl) continue;
    if (classes[part.range[0]]) {
      newEl.classList.add(...classes[part.range[0]]);
    }

    if (part.fullRange) {
      const txt = text.slice(part.fullRange[0], part.fullRange[0] + part.fullRange[1]);
      newEl.setAttribute('data-prop-path', `${txt}:${part.fullRange[0]}`);

    }

    if (identifierTypes.includes(part.type)) {
      const txt = text.slice(part.range[0], part.range[0] + part.range[1]);
      newEl.setAttribute('data-identifier', txt);
      if (part.type == 'identifier') {
        newEl.setAttribute('data-identifier-id', getIdentifierId(txt, part.range[0]));
        newEl.setAttribute('data-identifier-col', part.range[0] as any);
      }
      newEl.setAttribute('data-identifier-category', identifierCategory[part.type]);
    } 
    // else if (part.type == 'typeProp') {
    //   const txt = text.slice(part.range[0], part.range[0] + part.range[1]);
    //   newEl.setAttribute('data-prop', txt);
    // }
    
    if (part.type == 'jsxTag') {
      newEl.setAttribute('data-id', getTagId?.(part.range[0]));
    }
  }
}
