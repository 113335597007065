import React from 'react';
import cx from 'classnames';
import { styled } from './component2';
import { x } from './XObject';
import { RemoteSvg } from './components/Svg';
import { executeScriptFromData } from './shorthand/executeScriptFromData';



export function renderDesignComponent(obj, args: { onClick?, className?; slots?; classes?; }) {
  const root = obj.root;
  const renderElCanvas = (el, tag?) => {

    if (el.type == 'slot') {
      if (args.slots) {
        if (el.name in args.slots) {
          return args.slots[el.name];
        }
        else {
          return;
        }
      }
      if (el.children?.[0]) {
        return renderElCanvas(el.children[0]);
      }
      return null;
    }

    if (el.type == 'svg') {
      return el.url && <RemoteSvg url={el.url} />;
    }


    if (el.type == 'text') return el.content;

    return React.createElement(tag || 'div', {
      key: el._id,
      'data-id': el._id,
      onClick: args.onClick,
      className: cx(el.class, args.className, args?.classes?.(el)),
      [`data-scope-${obj._id}`]: true,
    }, el.children?.map?.(child => {
      return renderElCanvas(child);
    }));
    // return (
    //   <div key={el._id} data-id={el._id}
    //     className={cx({ active: el._id == state.activeEl })}
    //   >
    //     {el.children?.map?.(child => renderElCanvas(child))}
    //   </div>
    // )
  };

  let css = '';

  const buildCss = node => {
    if (node.properties) {
      css += `
      ${node == root ? '&' : `[data-id="${node._id}"]`} {
        ${x(Object.keys(node.properties)).map(prop => {
        return `${prop}: ${node.properties[prop]}`;
      }).join(';')}
      }
      `;
    }

    if (node.children) {
      for (const child of node.children) {
        buildCss(child);
      }
    }
  };
  buildCss(root);

  const Wrapper = styled.div`${css}`;

  const r = executeScriptFromData(obj.styles || [], {}, {
    __scopeId: obj._id,
    Wrapper,
  });

  return renderElCanvas(root, r);

}
