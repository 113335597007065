import _ from 'lodash';
import { component } from '../component';
import React, { Component } from 'react';
import { ObjectType } from '../types/ObjectRef';
import { Type, expandToText } from '../richTextHelpers';
import { db } from '../db';
import { types as types2 } from '../MyNotionDocument/types';
import { setAppInspect } from '../etc/appState';
import { InspectState } from '../components/InspectState';
import { explicitInspectObj } from '../inspectObj';
import { objectName } from '../components/objectFuncs';
import { getValuePoint } from '../glue/main';
import { execFormulaFromData } from './execFormulaFromData';
import { getEntityById } from '../etc/createEntity';

// const vpParamGUID = 'd506dc93-e480-572d-94f9-3e26f8a5288b';

@component
class FormulaMount extends Component<{ id; base; }> {
  render() {
    const { id } = this.props;
    const formula = db.formulas.findById(id);

    const r = execFormulaFromData(formula.formula, { base: this.props.base });

    if (_.isObjectLike(r) && !_.isArray(r)) {
      console.log(r);
      return 'no';
    }

    return (
      <span
        onContextMenu={e => {
          e.preventDefault();
          setAppInspect({
            mode: InspectState.formula,
            id,
          });
          explicitInspectObj({
            type: ObjectType.formula,
            id: id,
          });
        }}
      >{r}</span>
    );
  }
}

@component
class ValuePointMount extends Component<{ id; }> {

  render() {
    const { id } = this.props;
    const valuePoint = getValuePoint(id);
    return (
      <>
        <span
          onClick={() => {
            // openWindow({
            //   type: WindowType.GlueDev,
            //   valuePoint: id,
            // })
            explicitInspectObj({
              type: ObjectType.valuePoint,
              id: id,
            });
          }}
        >{valuePoint.name}</span>
      </>
    );
  }
}
types2['formula'] = {
  type: Type.atomic,
  html: (id, comps, args) => {
    const n = Math.random();
    comps[n] = {
      mount: <FormulaMount id={id.component || id.formula} base={args.entity} />,
    };
    return `<span class="--code--" contenteditable="false" data-type="formula" data-formula-data="${btoa(JSON.stringify(id))}"><span data-mount-point="${n}"></span></span>`;

    // return `<span class="--code--" contenteditable="false" data-type="formula" data-formula-data="${btoa(JSON.stringify(id))}">Test</span>`;
  },
  part: el => {
    const data = JSON.parse(atob(el.getAttribute('data-formula-data')));
    return [data, 'formula'];
  }
};

export const types = {
  entity: {
    type: Type.atomic,
    html: (id) => {
      const entity = getEntityById(id);
      return `<span class="--entity--" contenteditable="false" data-type="entity" data-entity-data="${btoa(JSON.stringify(id))}">${expandToText({ types }, entity?.name)
        // 'no'
        }</span>`;
    },
    text: (id) => {
      return `_$${ObjectType.entity}:${id}$_`;
      // const entity = getEntityById(id);
      // if (!entity) return 'error';
      // return expandToText({ types }, entity.name);
    },
    part: el => {
      const data = JSON.parse(atob(el.getAttribute('data-entity-data')));
      return [data, 'entity'];
    }
  },
  query: {
    type: Type.atomic,
    html: (id) => {
      return `<span class="--query--" contenteditable="false" data-type="query" data-entity-data="${btoa(JSON.stringify(id))}">${db.queries.findById(id).name
        // 'no'
        }</span>`;
    },
    text: (id) => {
      return `_$${ObjectType.query}:${id}$_`;
    },
    part: el => {
      const data = JSON.parse(atob(el.getAttribute('data-entity-data')));
      return [data, 'query'];
    }
  },
  identifier: {
    type: Type.atomic,
    html: (id, comps, args) => {
      const ident = db.identifiers.findById(id);
      // const entity = getEntityById(id);
      const n = Math.random();
      comps[n] = {
        mount: <span onClick={() => {
          explicitInspectObj({
            type: ObjectType.identifier,
            id: id,
          });
        }}>{ident.relative ? '.' : ''}{ident.name}</span>
      };
      return `<span class="--identifier--" contenteditable="false" data-type="formula" data-formula-data="${btoa(JSON.stringify(id))}"><span data-mount-point="${n}"></span></span>`;

      // return `<span class="--identifier--" contenteditable="false" data-type="identifier" data-identifier-data="${btoa(JSON.stringify(id))}">${ident.relative ? '.' : ''}${
      //   ident.name
      //   // 'no'
      // }</span>`;
    },
    text: (id) => {
      return `_$${ObjectType.identifier}:${id}$_`;
    },
    part: el => {
      const data = JSON.parse(atob(el.getAttribute('data-identifier-data')));
      return [data, 'identifier'];
    }
  },
  attribute: {
    type: Type.atomic,
    html: (id, comps, args) => {
      return `<span class="--attribute--" contenteditable="false" data-type="attribute" data-attribute-data="${btoa(JSON.stringify(id))}">${db.attributeTypes.findById(id).name}</span>`;
    },
    text: (id) => {
      return `_$${ObjectType.attribute}:${id}$_`;
    },
    part: el => {
      const data = JSON.parse(atob(el.getAttribute('data-attribute-data')));
      return [data, 'attribute'];
    }
  },
  objectHandle: {
    type: Type.atomic,
    html: (id, comps, args) => {
      return `<span class="--badge--" contenteditable="false" data-type="objectHandle" data-objectHandle-data="${btoa(JSON.stringify(id))}">${db.objectHandles.findById(id).name}</span>`;
    },
    text: (id) => {
      return `_$${ObjectType.objectHandle}:${id}$_`;
    },
    part: el => {
      const data = JSON.parse(atob(el.getAttribute('data-objectHandle-data')));
      return [data, 'objectHandle'];
    }
  },
  object: {
    type: Type.atomic,
    html: (id, comps, args) => {
      return `<span class="--badge--" data-object-type=${id.type} contenteditable="false" data-type="object" data-object-data="${btoa(JSON.stringify(id))}">${objectName(id)}</span>`;
    },
    text: (id) => {
      return `_$${id.type}:${id.id}$_`;
    },
    part: el => {
      const data = JSON.parse(atob(el.getAttribute('data-object-data')));
      return [data, 'object'];
    }
  },
  valuePoint: {
    type: Type.atomic,
    html: (id, comps, args) => {
      const n = Math.random();
      comps[n] = {
        mount: <ValuePointMount id={id} />,
      };
      return `<span class="--valuePoint--" contenteditable="false" data-type="valuePoint" data-valuePoint-data="${btoa(JSON.stringify(id))}"><span data-mount-point="${n}"></span></span>`;

      // return `<span class="--code--" contenteditable="false" data-type="formula" data-formula-data="${btoa(JSON.stringify(id))}">Test</span>`;
    },
    text: (id) => {
      return `_$${ObjectType.valuePoint}:${id}$_`;
    },

    part: el => {
      const data = JSON.parse(atob(el.getAttribute('data-valuePoint-data')));
      return [data, 'valuePoint'];
    }
  },
};
