import styled from 'styled-components';
import React, { Component } from 'react';
import { component } from '../component';
import { Svg } from './Svg';
import { attributeTypeDefs } from './attributeTypes';


@component
export class AttributeField extends Component<{
  title;
  type;
  field;
}> {
  static styles = styled.div`
        display: flex;
        height: 29px;
        .name {
          svg {
            width: 14px;
            height: 14px;
            margin-right: 4px;
            fill: #969696;
          }
          font-size: 12px;
          width: 160px;
          border-radius: 3px;
          padding: 0px 3px;
          display: flex;
          align-items: center;
          color: #969696;
          &:hover {
            background: #f3f3f3;
          }
        }

        .value {
          color: #4a4a4a;
          flex: 1 1 auto;
          > * {
            width: 100%;
          }

          border-radius: 3px;
          padding: 0px 3px;
          display: flex;
          align-items: center;
          &:hover {
            background: #f3f3f3;
          }
        }
  `;
  render() {
    const tt = attributeTypeDefs.find(tt => tt.value == this.props.type);

    return (
      <>
        <span className="name">
          {tt?.icon && <Svg name={tt.icon} />}
          {this.props.title}
        </span>
        <span className="value">
          {this.props.field}
        </span>
      </>
    );
  }
}
