import { appState, setAppInspect } from './etc/appState';
import { ObjectType } from './types/ObjectRef';
import { InspectState } from './components/InspectState';

export function viewObject(obj, ctx) {
  if (obj.type == ObjectType.codeComponent) {
    setAppInspect({
      mode: InspectState.code,
      component: obj.id,
    });
  }
  else if (obj.type == ObjectType.document) {
    ctx.navigate({
      type: 'page',
      id: obj.id,
    })
  }
  else if (obj.type == ObjectType.valuePoint) {
    ctx.navigate({
      type: 'glue',
      id: obj.id,
    })
  }
}
