import React, { Component } from 'react';
import { component } from '../component2';
import { styled } from '../component';
import { Cell } from './Cell';
import { EntityCellType, EventCellType } from './cells';
import { X, XInit, XObject } from '../XObject';
import { db } from '../db';
import { startEvent } from './startEvent';
import { getCurrentOccurrence, modeOccurrences, occurranceArguments } from './getCurrentOccurrence';
import juration from '../juration';
import { entityDisplayName } from './entityDisplayName';

@component
class TopBar extends Component {
  static debounce = false;
  static styles = styled.div`
    height: 84px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 20px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 6px 0px; */
    background: white;
    z-index: 999;

    input[type="text"] {
      border: none;
      height: 70px;
      flex: 1 1 auto;
      &:focus {
        outline: none;
      }
    }

    .right {
      margin-left: auto;
      display: flex;
      align-items: center;
    }

    ${Cell} {
      border: 1px solid #ccc;
    }
  `;
  state = XInit(class {
    arguments
    description
    event
  });

  timerId
  componentDidMount(): void {
    this.timerId = setInterval(() => {
      this.forceUpdate();
    }, 1000);
  }
  componentWillUnmount(): void {
    clearInterval(this.timerId);
  }
  render() {
    const occurrence = getCurrentOccurrence();
    const state = occurrence || this.state;
    const duration = occurrence ? juration.stringify(((occurrence.stop || new Date()).getTime() - occurrence.start.getTime())/1000) : null;
    return (
      <>
        <input
          type="text"
          placeholder="What are you doing?"
          defaultValue={state.description || ''}
          onChange={e => {
            state.description = e.target['value'];
          }}
          onKeyDown={e => {
            if (e.key == 'Enter') {
              startEvent({
                description: e.target['value'],
                event: state.event,
                arguments: state.arguments,
              });
              this.state.description = '';
              this.state.event = null;
              this.state.arguments = null;
            }
          }}
        />

        <div className="right">
          <Cell
            cell={new EventCellType({})}
            get={() => state.event}
            set={event => state.event = event}
            title="Event"
          />
          <Cell
            cell={new EntityCellType({})}
            get={() => state.arguments}
            set={entity => state.arguments = entity}
            title="Entity"
          />

          {duration && <div>{duration}</div>}

          {occurrence ? <button
            onClick={() => {
              occurrence.stop = new Date();
            }}
          >Stop</button> : <button>Start</button>}
        </div>
      </>
    )
  }
}

@component
class WeekView extends Component {
  static styles = styled.div`
    /* padding-top: 30px; */
    .days {
      display: flex;
    }

    .header {
      background-color: white;
      z-index: 9999999;
      border-bottom: 1px solid #e0e0e0;
      position: sticky;
      top: 0;
      height: 30px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      padding-left: 66px;
      .days {
        display: flex;
        width: 100%;
        .day {
          width: 100%;
          box-sizing: border-box;
          border-left: 1px solid #e0e0e0;
          flex: 1 1 auto;
        }
      }
    }

    .now {
      height: 1px;
      background-color: red;
      width: 100%;
      z-index: 999999;
    }
    .content {
      display: flex;
      width: 100%;

      > .timeline {
        width: 66px;
        flex: 0 0 auto;
        .hour {
          height: 60px;
        }
      }
      .days {
        display: flex;
        width: 100%;
        .day {
          width: 100%;
          flex: 1 1 auto;
          position: relative;
          border-left: 1px solid #e0e0e0;
          box-sizing: border-box;
          
          .hour {
            box-sizing: border-box;
            height: 60px;
            position: relative;
            z-index: 1;
            border-bottom: 1px solid #e0e0e0;
            box-sizing: border-box;
          }

          .occurrence {
            background-color: #f0f0f0;
            height: 20px;
            width: 100%;
            z-index: 4;
            padding: 0 4px;

            box-sizing: border-box;

            overflow: hidden;

            > *:first-child {
              margin-top: 4px;
            }
            > *:last-child {
              margin-bottom: 4px;
            }
          }
        }
      }
    }

  `;
  timerId
  componentDidMount(): void {
    this.timerId = setInterval(() => {
      this.forceUpdate();
    }, 1000);
  }
  componentWillUnmount(): void {
    clearInterval(this.timerId);
  }
  render() {
    let beginningOfWeek:any = new Date();

    beginningOfWeek.addDays(-(new Date().getDay() - 1));
    console.log(beginningOfWeek);

    const days = [
      {
        name: 'Mon',
      },
      {
        name: 'Tue',
      },
      {
        name: 'Wed',
      },
      {
        name: 'Thu',
      },
      {
        name: 'Fri',
      },
      {
        name: 'Sat',
      },
      {
        name: 'Sun',
      },
    ]
    return (
      <>
        <div className="header">
          <div className="days">
            {days.map(day => (
              <span className="day">{day.name}</span>
            ))}
          </div>
        </div>
        <div className="content">
          <div className="timeline">
            {[...Array(24)].map((_, i) => (
              <div className="hour">
                {i}
              </div>
            ))}
          </div>
          <div className="days">
            {days.map((day, i) => {
              const date = beginningOfWeek.clone().addDays(i).beginningOfDay();
              const occurrences = modeOccurrences().filter(occurrence => {
                return occurrence.start >= date && occurrence.start < date.clone().addDays(1);
              })
              const totalHeight = 60 * 24;
              const isToday = date.isToday();
              return (
                <div className="day" key={i}>

                  {occurrences.map(occurrence => {
                    const duration = (occurrence.stop || new Date()).getTime() - occurrence.start.getTime();
                    const args = occurranceArguments(occurrence);
                    const event = occurrence.event && db.events.findById(occurrence.event);


                    return (
                      <div className="occurrence" style={{
                        position: 'absolute',
                        top: (occurrence.start.getTime() - date.getTime()) / (1000 * 60 * 60) * 60,
                        height: Math.max(0, (duration) / (1000 * 60 * 60) * 60),

                      }}>
                        <div>{occurrence.description}</div>
                        <div>{event && event.name}</div>
                        <div>{args && entityDisplayName(args)}</div>
                        <div>{juration.stringify(duration/1000)}</div>
                      </div>
                    )
                  })}

                  {[...Array(24)].map((_, i) => (
                    <div className="hour">
                      <span className="hour"></span>
                    </div>
                  ))}
                  {isToday && (
                    <>
                      <div className="now" style={{
                        position: 'absolute',
                        top: (new Date().getTime() - date.getTime()) / (1000 * 60 * 60) * 60,
                      }} />
                    </>
                  )}

                </div>
              )
            })}
          </div>
        </div>
        {/* <div className="timeline">
          {[...Array(24)].map((_, i) => (
            <div className="hour">
              <span className="hour">{i}</span>
            </div>
          ))}
        </div> */}
        {/* <div className="days">
          {days.map(day => (
            <div className="day">
              <span className="day">{day.name}</span>
            </div>
          ))}
        </div> */}
      </>
    );
  }
}

@component
export class TrackRoot extends Component<{}> {
  static styles = styled.div`
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    /* width: 100%; */
    /* height: 100%; */
    /* padding-top: 84px; */
    transform: translateX(0);
    ${TopBar} {
      /* position: sticky;
      top: 0;
      width: 100%;
      left: 0; */
    }
  `;
  render() {
    return (
      <>
        <TopBar />
        <WeekView />
      </>
    );
  }
}
