import React from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import { component } from '../../component2';
import { DraftSelect } from '../../etc/draftHelpers';
import { XInit, XObject } from '../../XObject';
import { showContextMenu } from '../../helpers';
import { ValuePoint } from '../ValuePoint';
import { ValuePointCont } from '../ValuePointCont';
import { borderColor, identColor, indentWidth, propColor } from '../borderColor';
import { Comp } from '../Comp';
import {
  _findValue,
  _iterate,
  _matches,
  addStructToMap,
  addValuePoint,
  CompiledValuePoint,
  evaluate,
  execute,
  getStructureById,
  isBlock,
  ReferenceType,
  valueForProperty,
  ValuePointProps,
  ValuePointType
} from '../main';
import { registerChangeAction, trackChange as trackChange, UndoActions as ChangeType } from "../changes";
import { Runtime } from "../Runtime";
import { registerType } from "../__typeRegistry";
import { ValueType } from "../ValueType";
import { structEvalutors, structParamRenderers, structRenderers, structScriptEvaluators } from "../structRenderers";
import { typeRegistry } from '../typeRegistry.1';
import { registerTypeRegister } from '../typeRegistering';

const AddStructureProperty = 'b5cf8474-e3d2-5dd1-bf52-e62f5448a313';
const DeletedProperty = '959413f9-d53a-545a-a5c0-89a4a352c106';
registerChangeAction(AddStructureProperty, {
  name: 'Add structure property',
})

registerChangeAction(DeletedProperty, {
  name: 'Delete structure property',
})

function addProperty(value, v, init={}) {
  const properties = XObject.get(value, 'content', []);
  const newValuePoint = addValuePoint(valueForProperty(v, undefined, value._id, init));
  trackChange(AddStructureProperty, [v], [
    [ChangeType.modifiedValuePoint, value._id],
    [ChangeType.createdNestedValuePoint, newValuePoint],
  ]);
  properties.push(XObject.obj({
    prop: v,
    value: newValuePoint,
  }));

  return newValuePoint;

}

export function getProperty(value, v, add?) {
  const properties = XObject.get(value, 'content', []);
  const prop = properties.find((p) => p.prop == v);
  if (prop) {
    return prop.value;
  }
  else if (add) {
    return addProperty(value, v, add === true ? {} : add);
  }
}


@component
export class StructureValuePoint extends Comp<ValuePointProps> {
  static styles = styled.div`
    > ul {
      margin: 0;
      padding-left: ${indentWidth};

      list-style-type: none;



      li.block {
        > ${ValuePointCont} {
          padding-left: 4px;
          border-left: 2px solid ${borderColor};
          /* > * {
            margin-left: 4px;
          } */
        }
      }

    }

    .blockProp {
      padding-left: 4px;
          border-left: 2px solid ${borderColor};

    }

    &.compact {
      &.inline {
        display: inline;
      }
      > ul {
        &:before {
          content: ' ';
        }
        &:after {
          content: ' ';
        }
        display: inline;
        list-style-type: none;
        margin: 0;
        padding: 0;
        > li {
          display: inline;
          &:not(:last-child) {
            &:after {
              content: ', ';
            }
          }
        }
      }
    }
  `;

  state = XInit(class { });

  render(Container?) {
    const state = this.props.state;

    const properties = XObject.get(state, 'content', []);
    const structure = getStructureById(state.type[1]);

    const blockPropertyDef = structure.blockProperty && structure.definition.find((f) => f.id == structure.blockProperty);
    const blockProperty = blockPropertyDef && properties.find((p) => p.prop == structure.blockProperty);

    return (
      <Container className={cx({ compact: state.presentation?.compact, inline: state.presentation?.compact && !isBlock(state) })}>
        <span style={{ color: identColor }}>{structure.name}</span> {'{'}
        <ul>
          {properties.map((prop, i) => {
            if (prop.prop == structure.blockProperty)
              return null;
            const field = structure.definition.find((f) => f.id == prop.prop);
            return (
              <li key={prop._id} className={prop.value.type && isBlock(prop.value) && 'block'}>
                <span
                style={{
                  color: propColor,
                }}
                onContextMenu={e => {
                  e.preventDefault();
                  showContextMenu(e, [
                    {
                      text: 'Remove',
                      onClick: () => {
                        trackChange(DeletedProperty, [prop.prop], [
                          [ChangeType.modifiedValuePoint, this.props.state._id],
                        ]);
                        properties.splice(i, 1);
                      },
                    },
                  ]);
                }}>{field.name.toLowerCase()}</span> = <ValuePoint id={prop.value._id} path={this.props.path && this.props.path.concat(prop._id)} />
              </li>
            );
          })}
          {properties.length < structure.definition.length && (
            <li>
              <DraftSelect
                inline
                id={this.props.elId}
                options={structure.definition.filter(p => !properties.find(pp => pp.prop == p.id)).map(({ name, id }) => ({
                  display: name.toLowerCase(),
                  value: id,
                }))}
                onSelect={(v) => {
                  addProperty(state, v);
                }}
              />
            </li>
          )}
        </ul>
        {'}'}

        {blockProperty && (
          <div className="blockProp" onContextMenu={e => {
            if (e.target == e.currentTarget) {
              e.preventDefault();
              showContextMenu(e, [
                {
                  text: 'Remove',
                  onClick: () => {
                    trackChange(DeletedProperty, [blockProperty], [
                      [ChangeType.modifiedValuePoint, this.props.state._id],
                    ]);
                    properties.splice(properties.indexOf(blockProperty), 1);
                  }
                }
              ]);
            }
          }}>
            <ValuePoint id={blockProperty.value._id} />
          </div>
        )}
      </Container>
    );
  }
}

// Structure
export const registerStructureType = () => {
  function evaluateStructure(value: CompiledValuePoint, map = {}) {
    const structure = getStructureById(value.type[1]);

    const result = {
      _id: value._id,
    };

    for (const prop of structure.definition) {
      const propValue = value.content.find(x => x.prop === prop.id);

      if (propValue) {
        if (prop.scope) {
          result[prop.property] = propValue.value;
        } else {
          const evaluated = propValue.value && evaluate(propValue.value, map);
          result[prop.property] = evaluated;
        }
      }
    }

    return result;
  }

  function executeStructure(value: ValuePointType, rt: Runtime): CompiledValuePoint {
    const newValue = {
      _id: value._id,
      type: value.type,
      content: [],
      rt,
      isState: false,
      presentation: value.presentation,
    };

    if (value.content) for (const prop of value.content) {
      newValue.content.push({
        _id: prop._id,
        prop: prop.prop,
        value: execute(prop.value._id, rt.appendPath([value._id, prop._id])),
      });
    }

    return newValue;
  }

  registerType(ValueType.Structure, {
    execute: (value, rt) => {
      return executeStructure(value, rt);
    },
    evaluate: (value, map) => {
      if (structEvalutors[value.type[1]]) {
        return structEvalutors[value.type[1]](value, map);
      }
      else return evaluateStructure(value, map);
    },
    scriptEvaluate: ({value, map, rt}) => {
      if (structScriptEvaluators[value.type[1]]) {
        return structScriptEvaluators[value.type[1]]({value, map, rt});
      }
      else return evaluateStructure(value, map);
    },
    render: (value, map, state, renderType) => {
      if (structRenderers[value.type[1]]) {
        return structRenderers[value.type[1]](value, map, state, renderType);
      }
      else if (structParamRenderers[value.type[1]]) {
        return structParamRenderers[value.type[1]]({value, map, state, renderType})
      }
      else {
        return <>No renderer for <b>{typeRegistry.getType(value.type[1])?.name || value.type[1]}</b></>;
      }
    },
    renderWithParams: (params) => {
      const { value, map, state, renderType } = params;
      if (structRenderers[value.type[1]]) {
        return structRenderers[value.type[1]](value, map, state, renderType);
      }
      else if (structParamRenderers[value.type[1]]) {
        return structParamRenderers[value.type[1]](params);
      }
      else {
        return <>No renderer for <b>{typeRegistry.getType(value.type[1])?.name || value.type[1]}</b></>;
      }
    },
    isBlock: value => {
      if (value.presentation?.compact) {
        const struct = getStructureById(value.type[1]);
        if (struct.blockProperty && !value.content?.find?.(p => p.prop == struct.blockProperty) || !struct.blockProperty) {
          return false;
        }
      }
      return true;

    },
    findValue: (value, pattern) => {
      for (const prop of value.content) {
        if (_matches(prop.value, pattern)) {
          return prop.value;
        } else {
          const r = _findValue(prop.value, pattern);
          if (r)
            return r;
        }
      }
    },
    iterate: (value, parent, func, set) => {
      const list = value.content || [];
      for (let i = 0; i < list.length; i++) {
        const r = _iterate(list[i].value, value, func, value => {
          list[i].value = value;
        });
        if (r !== undefined) {
          return r;
        }
      }
    },
    scope: (value, parentValue) => {
      let scope = [];
      const parentStruct = getStructureById(parentValue.type[1]);
      if (parentStruct) {
        if (parentStruct.scope) {
          scope = scope.concat(parentStruct.scope.map(s => ({
            type: ReferenceType.StructureParameter,
            id: s.id
          })));
        }

        const parentProp = parentValue.content.find(p => p.value?._id == value._id);
        const prop = parentStruct.definition.find(p => p.id == parentProp.prop);

        if (prop?.scope) {
          scope = scope.concat(prop.scope.map(s => ({ type: ReferenceType.StructureParameter, id: s.id })));
        }
      }
      return scope;
    },
    addStructToMap: (field) => {
      addStructToMap(field.type[1], { definition: field.type[2] });
    },
    editorComp: StructureValuePoint,
  });
};

registerTypeRegister(registerStructureType);