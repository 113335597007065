import React, { Component } from 'react';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import jQuery from 'jquery';
import { component } from '../component2';
import { Column, ColumnManager, RowManager, Row } from './notionDatabase/ColumnManager';
import { NotionDatabase } from './notionDatabase/NotionDatabase';
import { CheckboxCellType, DurationCellType, EntityCellType, EventCellType, GlueCellType, MediaCellType, MultiSelectCellType, SelectCellType, TextCellType, TitleCellType, URLCellType } from './cells';
import { CellType } from "./notionDatabase/CellType";
import { X, XInit, XObject, x } from '../XObject';
import { styled } from '../component';
import { Svg } from './Svg';
import { PropertyField } from './PropertyField';
import { NotionButton } from './AddButton';
import { showContextMenu } from '../etc/showContextMenu';
import { SystemContext, SystemContextProps } from '../etc/SystemContext';
import { componentSystem } from '../componentSystem';
import { db } from '../db';
import { ObjectType } from '../types/ObjectRef';
import { getCodeComponent } from '../pushCode';
import { Type } from './Type';
import { appState, memoryAppState, setAppInspect } from '../etc/appState';
import { InspectState } from './InspectState';
import classNames from 'classnames';
import { uploadedFileUrl } from './FileUpload';
import { ViewsList } from './ViewsList';
import { CodeComponenType } from '../ideIndex';
import { createRootValuePoint } from '../glue/main';
import { registerContextVar } from '../glue/typeRegistering';
import { WithContextArgs } from './WithContextArgs';
import { registerFormulaAccessorHandler } from '../shorthand/registerFormulaAccessorHandler';

const RowContextVar = '935f0972-e71e-5fa9-83a0-c0f1f0907ad0';
registerContextVar(RowContextVar, 'Row');

class MyRowManager extends RowManager {
  constructor(public table: Table, public ids) {
    super();
  }
  addRow() {
    throw new Error('unimplemented');
    return null;
  }
  deleteRow(id) {
    const index = this.table.rows.findIndex(r => r._id == id);
    if (index >= 0) {
      this.table.rows.splice(index, 1);
    }
  }
  canAddDeleteRows = true;
  rows() {
    const r = this.table.rows?.map?.(r => new MyRowThing(r, this.table)) || [];

    if (this.ids) {
      return r.filter(r => this.ids.includes(r.id()));
    }
    else {
      return r;
    }
  }
}

function tableColumns(table: Table) {
  if (table.schema) {
    const schema = db.tableSchemas.findById(table.schema);
    return XObject.get(schema, 'columns', []);
  }
  else if (table.handle) {
    const handle = db.objectHandles.findById(table.handle);
    return XObject.get(handle, 'columns', []);
  }
  else {
    return XObject.get(table, 'columns', []);
  }
}

function tableViews(table: Table) {
  if (table.handle) {
    const handle = db.objectHandles.findById(table.handle);
    // return [];
    return XObject.get(handle, 'views', []);
  }
  else {
    return XObject.get(table, 'views', []);
  }
}



function setTableColumns(table: Table, columns) {
  if (table.schema) {
    const schema = db.tableSchemas.findById(table.schema);
    schema.columns = X(columns);
  }
  else {
    table.columns = X(columns);
  }
}

interface Table {
  _id
  columns
  rows
  views
  handle
  schema
}

class MyColManager extends ColumnManager {
  constructor(public table: Table) {
    super();
  }
  addColumn(): void {

  }
  canAddDeleteColumns = true;

  columns() {
    return tableColumns(this.table).map(c => new MyColThing(c)) || [];
  }

  deleteColumn(id: any): void {
    const cols = tableColumns(this.table);
    const colIndex = cols.findIndex(c => c._id == id);
    cols.splice(colIndex, 1);
  }

  move(oldIndex: any, newIndex: any): void {
    const columns = x(tableColumns(this.table));
    const column = columns[oldIndex];
    columns.splice(oldIndex, 1);
    columns.splice(newIndex, 0, column);
    setTableColumns(this.table, columns);
  }
}

registerFormulaAccessorHandler({
  test: obj => obj instanceof MyRowThing,
  perform: (obj: MyRowThing, prop) => {
    const col = tableColumns(obj.table).find(c => c.title === prop);

    return obj.row.values[col._id];
  }
});

class MyRowThing extends Row {
  constructor(public row, public table: Table) {
    super();
  }
  id() {
    return this.row._id;
  }
  setValue(colId: any, value: any) {
    if (!this.row.values) {
      this.row.values = X({
        [colId]: value
      })
    }
    else {
      this.row.values[colId] = value;
    }
  }

  value(colId: any, defaultValue?: any, context?: any) {
    return this.row.values?.[colId] || defaultValue;
  }
}

class CodeCellType extends CellType {
  constructor(public metaData: any, public row) {
    super(metaData);
  }
  renderEditor({ frame, value, setValue, close, state }: { frame: any; value: any; setValue: any; close: any; state: any; }) {
    
  }

  renderValue(value: any) {
    try {
      return getCodeComponent(this.metaData.codeComponent)?.(this.row);
    }
    catch (e) {
      console.log(e);
      return 'error';
    }
  }
}



export function cellForCol(col, row?: MyRowThing) {
  switch (col.type) {
    case Type.text: return new TextCellType(col);
    case Type.name: return new TitleCellType(col);
    case Type.select: return new SelectCellType({
      options: col.options || [],
      editOption: (e, option) => {
        showContextMenu(e, [
          {
            text: 'Edit',
            onClick: () => {

            }
          },
          {
            text: 'Delete',
            onClick: () => {
              const index = col.options.findIndex(o => o._id == option._id);
              console.log('index', index);
              if (index >= 0) {
                col.options.splice(index, 1);
              }
            },
          }
        ])
      },
      addOption: value => {
        const newOption = XObject.obj({
          title: value,
        });
        XObject.push(col, 'options', newOption);
        return newOption._id;
      },
    });
    case Type.multiSelect: return new MultiSelectCellType({
      options: col.options || [],
      editOption: (e, option) => {
        showContextMenu(e, [
          {
            text: 'Edit',
            onClick: () => {

            }
          },
          {
            text: 'Delete',
            onClick: () => {
              const index = col.options.findIndex(o => o._id == option._id);
              console.log('index', index);
              if (index >= 0) {
                col.options.splice(index, 1);
              }
            },
          }
        ])
      },
      addOption: value => {
        const newOption = XObject.obj({
          title: value,
        });
        XObject.push(col, 'options', newOption);
        return newOption._id;
      },
    });
    case Type.code: return new CodeCellType(col, row);
    case Type.media: return new MediaCellType(col);
    case Type.url: return new URLCellType(col);
    case Type.duration: return new DurationCellType(col);
    case Type.event: return new EventCellType(col);
    case Type.checkbox: return new CheckboxCellType(col);
    case Type.entity: return new EntityCellType(col);
    case Type.glue: return new GlueCellType({
      valuePoint: col.valuePoint,
      map: {
        [RowContextVar]: row,
      }
    });
  }
}

class MyColThing extends Column {
  constructor(public col) {
    super();
  }

  getIcon() {
    return null;
  }

  id() {
    return this.col._id;
  }

  metaData() {
    return this.col;
  }

  setTitle(value: any): void {
    this.col.title = value;
  }

  setWidth(value: any): void {
    this.col.width = value;
  }

  title() {
    return this.col.title;
  }

  type() {
    switch (this.col.type) {
      case Type.text: return 'text';
      case Type.name: return 'title';
      case Type.select: return 'select';
      case Type.multiSelect: return 'multiSelect';
      case Type.media: return 'media';
    }
  }

  cellObj(row: any) {
    return cellForCol(this.col, row);
  }

  width() {
    return this.col.width || 200;
  }
}

@component
class Sidebar extends Component<{
  onClickClose?: () => void;
  table: Table
  nav
  curNav
}> {
  static styles = styled.div`
    width: 300px;
    border-left: 1px solid rgba(55,53,47,0.09);
    background: #fff;


    .top {
      padding: 14px 16px 6px;
      display: flex;
      align-items: center;
      .back {
        margin-right: 8px;
        cursor: pointer;
        width: 18px;
        height: 18px;
        /* background: #f3f3f3; */
        display: flex;
        align-items: center;
        justify-content: center;
        /* border-radius: 50%; */
        svg {
          fill: #969696;
          width: 14px;
          height: 14px;
        }

      }
      .title {
        font-weight: 600;
      }
      .close {
        cursor: pointer;
        margin-left: auto;
        width: 18px;
        height: 18px;
        background: #f3f3f3;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        svg {
          fill: #969696;
          width: 14px;
          height: 14px;
        }
      }
    }

    .content {
      padding: 16px;
    }
  `;
  static contextType = SystemContext;
  context: SystemContextProps;
  render() {
    let c;
    let title;
    const cur = this.props.curNav[this.props.curNav.length - 1]
    if (cur[0] === 'editCol') {
      const col = tableColumns(this.props.table).find(c => c._id === cur[1]);
      title = 'Edit property';
      c = (
        <>
          <PropertyField
            object={col}
            property="title"
          />

          <select
            value={col.type || ''}
            onChange={e => {
              col.type = e.target.value;
            }}
          >
            <option value=""></option>
            <option value={Type.name}>Name</option>
            <option value={Type.text}>Text</option>
            <option value={Type.select}>Select</option>
            <option value={Type.multiSelect}>Multi Select</option>
            <option value={Type.code}>Code</option>
            <option value={Type.media}>Media</option>
            <option value={Type.url}>URL</option>
            <option value={Type.duration}>Duration</option>
            <option value={Type.event}>Event</option>
            <option value={Type.glue}>Glue</option>
            <option value={Type.checkbox}>Checkbox</option>
            <option value={Type.entity}>Entity</option>
          </select>

          {col.type === Type.code && (
            <>

              {!col.codeComponent && (
                <>
                  <button
                    onClick={() => {
                      const c = componentSystem.createComponent(CodeComponenType.function);
                      XObject.push(c, 'scope', XObject.obj({
                        type: ObjectType.document,
                        id: this.props.table._id,
                      }))
                      col.codeComponent = c._id;
                    }}
                  >Func</button>
                  <button
                    onClick={() => {
                      const c = componentSystem.createComponent(CodeComponenType.component);
                      XObject.push(c, 'scope', XObject.obj({
                        type: ObjectType.document,
                        id: this.props.table._id,
                      }))

                      col.codeComponent = c._id;
                    }}

                  >Comp</button>
                </>
              )}

              {col.codeComponent && (
                <>
                  <span
                    onClick={() => {
                      setAppInspect({
                        mode: InspectState.code,
                        component: col.codeComponent,
                      });
                    }}
                  >{db.codeComponents.findById(col.codeComponent).name || '(hello)'}</span>
                </>
              )}

            </>
          )}

          {col.type == Type.glue && (
            <>
              <div>

                <button onClick={() => {
                  if (!col.valuePoint) {
                    const vp = createRootValuePoint();
                    col.valuePoint = vp._id;
                  }
                  setAppInspect({
                    mode: InspectState.valuePoint,
                    id: col.valuePoint,
                  }, this);

                }}>edit</button>

              </div>
            </>
          )}

          {/* <button
            onClick={() => {
              this.props.curNav.push(['editColType', col._id]);
            }}
          >Type</button> */}
        </>
      )
    }
    else if (cur[0] === 'editColType') {
      // const col = this.props.table.columns.find(c => c._id === cur[1]);
      title = 'Property type';
      c = (
        <>
          
        </>
      )
    }
    else {
      title = 'Add a property!'
      c = (
        <>
          <button
            onClick={() => {
              const newColumn = XObject.obj();
              // XObject.push(this.props.table, 'columns', newColumn);
              tableColumns(this.props.table).push(newColumn);

              this.props.curNav.push(['editCol', newColumn._id]);
            }}
          >+</button>

        </>
      )
    }
    return (
      <>
        <div className="top">
          {this.props.curNav.length > 1 && <span
            onClick={() => {
              this.props.curNav.pop();
            }}
            className="back">
            <Svg name="arrowLeftThick" />
          </span>}
          <div className="title">{title}</div>
          <span className="close" onClick={this.props.onClickClose}><Svg name="closeSmall" /></span>
        </div>
        <div className="content">
          {c}
        </div>
      </>
    )
  }
}

@component
class Toolbar extends Component<{ onClickAI?, table: Table, onClickSettings, editView?, state, views }> {
  static styles = styled.div`
    display: flex;
    align-items: center;
    height: 35px;
    .right {
      display: flex;
      align-items: center;
      margin-left: auto;
      .aiSearch {
        svg {
          width: 15px;
          height: 15px;
          fill: rgb(167, 130, 195);
        }
      }

      svg {
        width: 16px;
        height: 16px;
      }

      .settings {
        height: 28px;
        svg {
          width: 13px;
          height: 13px;
          fill: rgba(55, 53, 47, 0.45);
        }
      }
    }
    .create {
      height: 28px;
      svg {
        fill: #88a4ff;
      }
    }
  `;
  static contextType = SystemContext;
  context: SystemContextProps;
  render() {
    const views = this.props.views;// || tableViews(this.props.table);
    return (
      <>
        <ViewsList
          active={this.props.state.activeView}
          contextMenu={id => {
            return [
              {
                text: 'Edit',
                onClick: () => {
                  this.props.editView(id);
                },
              },
              {
                text: 'Delete',
                onClick: () => {
                  views.splice(views.findIndex(v => v._id === id), 1);
                }
              }
            ]
          }}
          onAdd={() => {
            views.push(XObject.obj());

          }}
          onClickView={id => {
            this.props.state.activeView = id;
            console.log('view', id, x(this.props.state));

          }}
          onDelete={id => {

          }}
          views={views.map(view => {
            let icon;
            if (view.type == 'c777c657-6257-5cfc-a0f2-7e735bb50d49') {
              icon = 'icons8-grid-view (1)';
            }
            else if (view.type == 'e81bab60-bdcf-5755-9e85-a1863fbf3a2a') {
              icon = 'icons8-table';
            }

            return ({
              _id: view._id,
              name: 'View',
              icon,
            })
          })}
        />
        {/* <InsertionCont
          className="views"
          onInsert={(i,e) => {
            views.push(XObject.obj());
          }}
          orientation="horizontal"
          tag="div"
        >
          {views.map((view, i) => {
            let icon;
            if (view.type == 'c777c657-6257-5cfc-a0f2-7e735bb50d49') {
              icon = 'icons8-grid-view (1)';
            }
            else if (view.type == 'e81bab60-bdcf-5755-9e85-a1863fbf3a2a') {
              icon = 'icons8-table';
            }
            return (
              <span
                key={i}
                className={classNames('view', {
                  active: this.props.state.activeView == view._id,
                })}
                onContextMenu={e => {
                  e.preventDefault();
                  this.props.editView && showContextMenu(e, [
                    {
                      text: 'Edit',
                      onClick: () => {
                        this.props.editView(view._id);
                      },
                    },
                    {
                      text: 'Delete',
                      onClick: () => {
                        views.splice(i, 1);
                      }
                    }
                  ])
                }}
                onClick={() => {
                  this.props.state.activeView = view._id;
                  console.log('view', view._id, x(this.props.state));
                }}
              >
                <Svg name={icon} /> View
              </span>
            )
          })}
        </InsertionCont> */}
        <div className="right">
          {/* <NotionButton
            className="aiSearch"
            img="ai"
            onClick={this.props.onClickAI}
          /> */}
          <NotionButton
            onClick={this.props.onClickSettings}
            className="settings"
            img="dots"
          />
                  <NotionButton
                    className="create"
                    img="icons8-create (2)"
                    onClick={() => {
                      XObject.push(this.props.table, 'rows', XObject.obj());

                    }}
                  />
          {/* <span
            className="new"
            onClick={() => {
              XObject.push(this.props.table, 'rows', XObject.obj());
            }}
          >
            New
          </span> */}
        </div>
      </>
    )
  }
}


const margin = 10;
@component
class GalleryView extends Component<{ table: Table, view, onClickRow, active }> {
  static styles = styled.div`
    > .card {
      box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 2px 4px;
      border-radius: 3px;
      background: white;
      margin: ${margin/2}px;

      cursor: pointer;  

      transition: background 100ms;


      &:hover {
        background: #f9f9f8;
      }

      &.active {
        background: #eceff9;
      }


      .preview {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: block;


        &:hover {
          position: relative;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            background-color: rgba(0,0,0,0.1);
          }

        }
      }

      .name {
        padding: 8px 10px 10px;
        display: block;
        font-size: 14px;
    line-height: 1.5;
    min-height: 21px;
    font-weight: 500;
      }
    }
    display: flex;
    flex-wrap: wrap;
    margin-left: -${margin/2}px;
    margin-right: -${margin/2}px;
  `;

  state = XInit(class {
    cardWidth
  });

  timerId
  componentDidMount() {
    const update = () => {
      const width = jQuery(ReactDOM.findDOMNode(this)).width();
      const cols = 3;
      this.state.cardWidth = (width - margin*cols) / cols;
    }
    this.timerId = setInterval(() => {
      update();
    }, 10);
  }

  componentWillUnmount(): void {
    clearInterval(this.timerId);
  }

  render() {
    if (_.isNil(this.state.cardWidth)) return null;
    const { table } = this.props;
    return (
      <>
        {table.rows.map(row => {
          const nameCol = tableColumns(table).find(c => c.type == Type.name);
          const previewCol = tableColumns(table).find(c => c._id == this.props.view.galleryProperty);
          return (
            <div key={row._id}
              onClick={() => {
                this.props.onClickRow(row._id);
              }}
            className={classNames('card', {
              active: this.props.active == row._id,
            })} style={{ width: this.state.cardWidth }}>

              {previewCol && <span className="preview"
              onClick={e => {
                e.stopPropagation();
                memoryAppState.image = uploadedFileUrl(row.values[previewCol._id]);
              }}
                style={{
                  backgroundImage: `url(${uploadedFileUrl(row.values[previewCol._id])})`,
                  height: this.state.cardWidth/1.5,
                }}
              />}

              <span className="name">{row.values[nameCol._id]}</span>

            </div>
          );
        })}
      </>
    )
  }
}

@component
export class NotionTable2 extends Component<{
  onClickAI?,
  table: Table
  active,
  onClickRow?,
  ids?,
  hideToolbar?
  editView?
  state,
  views?
}> {
  static styles = styled.div`
    position: relative;
    ${Sidebar} {
      position: absolute;
      z-index: 999999;
      top: 0;
      right: 0;
      bottom: 0;
    }
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    > .content {
      position: relative;
      flex: 1 1 auto;
    }
  `;
  state = XInit(class {
    sideBar: any
  })
  render() {
    const views = this.props.views || tableViews(this.props.table);
    if (!this.props.state.activeView && views?.[0]?._id) {
      this.props.state.activeView = views?.[0]?._id;
    }
    const activeView = views?.find?.(view => view._id === this.props.state.activeView);
    
    return (
      <>
        {!this.props.hideToolbar && (
          <Toolbar
            views={views}
            state={this.props.state}
            onClickSettings={() => {
              this.state.sideBar = ['settings'];
            }}
            table={this.props.table}
            onClickAI={this.props.onClickAI}
            editView={this.props.editView}
          />
        )}
        <div className="content">
          {activeView?.type == 'e81bab60-bdcf-5755-9e85-a1863fbf3a2a' && (
            <>
              <NotionDatabase
                onClickAdd={() => {
                  XObject.push(this.props.table, 'rows', XObject.obj());
                }}
                onClickAddCol={() => {
                  this.state.sideBar = X([['root']]);
                }}
                columnManager={new MyColManager(this.props.table)}
                rowManager={new MyRowManager(this.props.table, this.props.ids)}
                onRightClickCol={(e, col) => {

                }}
                onEditCol={(col) => {
                  this.state.sideBar = X([['editCol', col.id()]]);
                }}
                onClickRow={this.props.onClickRow}
                activeRowId={this.props.active}
              />
            </>
          )}

          {activeView?.type == 'c777c657-6257-5cfc-a0f2-7e735bb50d49' && (
            <>
              <GalleryView
                table={this.props.table}
                view={activeView}
                onClickRow={this.props.onClickRow}
                active={this.props.active}
              />
            </>
          )}

          {this.state.sideBar && (
            <>
              <Sidebar
                table={this.props.table}
                onClickClose={() => {
                  this.state.sideBar = false;
                }}
                nav={a => {
                  this.state.sideBar.push(a);
                }}
                curNav={this.state.sideBar}
              />
            </>
          )}
        </div>

      </>
    );
  }
}
