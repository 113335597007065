import { XInit } from '../XObject';
import { InspectState } from '../components/InspectState';
import { getStorageState } from './getStorageState';

export const appState = getStorageState('state1', 0, class {
  currentPage;
  panes = [];
  rightSidebar = false;
  leftSidebar = true;

  inspecting: any = null;

  focus = null;

  glue

  glueInspectStack

  lastViewState

  appInspectOverlay
  _appInspect

  appInspectState

  graphView

  lastActivePane

  currentMode

  showGraphView = false

  mobileStack = []

  addMobileStack = []

  mobileTab
  
  addTab = {}
  homeTab = {}
  notesTab = {}

  user

  mobile_firebaseUserId

  eventsState = {}

  focusedObjectInSelectMenu

  entityInspectTabStates = {}

  // leftSideBar = true
});


export function setAppInspect(arg, comp?) {
  appState._appInspect = arg;
  if (arg.mode == InspectState.valuePoint) {
    appState.appInspectState = {
      valuePoint: arg.id
    }
  }
}

export const memoryAppState = XInit(class {
  hoverValuePoint
  hoveringValuePoints = {}

  user

  image = null
  showSidebar = false

  search = false

  firebaseUserId

  fromUrl

  copiedEventOccurrence

  openWith
})