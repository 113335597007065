import _ from 'lodash';
import { css, styled } from './component';
import { Select } from './Select';
import { ColorPicker, Labeled, UnitInput } from './Labeled';
import { Component } from "react";
import { isId, showContextMenu } from "./helpers";
import { component } from './component2';
import { DevRuntime } from './DevRuntime';
import { ColorDisplay } from './ColorDisplay';


@component
export class ModeSelector extends Component<{ options?; onSelectOption?; }> {
  static styles = styled.div`
    cursor: pointer;
    width: 19px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto !important;
    > span {
      display: block;
      width: 6px;
      height: 6px;
      border-right: 1px solid;
      border-bottom: 1px solid;
      border-color: #b6b6b6;
      transform: rotate(45deg);
      margin-top: -4px;
    }
  `;
  render(Container?) {
    const options = this.props.options || [];
    return (
      <Container
        onClick={e => {
          const labels = {
            [Type.TopRightBottomLeft]: 'Top Right Bottom Left',
            [Type.TopBottom_LeftRight]: 'Top/Bottom Left/Right',

            [Type.BorderAll]: 'All',
            [Type.BorderTop]: 'Top',
            [Type.BorderRight]: 'Right',
            [Type.BorderBottom]: 'Bottom',
            [Type.BorderLeft]: 'Left',
          }


          showContextMenu(e, options.map(o => ({
            text: labels[o] || _.invert(Type)[o],
            onClick: () => {
              this.props.onSelectOption(o);
            }
          })), {
            alignX: -1,
            alignY: 2,
          });
        }}

      >
        <span />
      </Container>
    );
  }
}
interface Value {
  type;
  value?;
}
interface TTTTT {
  render(devRuntime: DevRuntime, args: { value; setValue; });
  renderText(devRuntime: DevRuntime, value);
  styles?;
  hasValue?(value)
}
const typeRegistry: {
  [key: string]: TTTTT;
} = {};
function registerType(type, args: TTTTT) {
  typeRegistry[type] = args;
}
export function renderText(devRuntime: DevRuntime, value: { type; value; }, defaultValue = '') {
  if (!value) return defaultValue;
  const t = typeRegistry[value.type];
  if (!t) {
    console.log(value)
  }
  if (t.hasValue && !t.hasValue(value.value)) return defaultValue;

  if (!t) return `Invalid: ${_.invert(Type)[value.type] || value.type}`;

  return t.renderText(devRuntime, value.value);
}

@component
class RenderWrapper extends Component<{ devRuntime, args }> {
  render() {
    const { devRuntime, args } = this.props;

    const type = typeRegistry[args.type];

    if (!type) {
      return `Invalid: ${_.invert(Type)[args.type]}`;
    }
  
    const r = type.render(devRuntime, {
      value: args.value,
      setValue: args.setValue,
    });
  
    if (type.styles) {
      const Tag = type.styles;
      return <Tag>{r}</Tag>;
    }
    else {
      return r;
    }
  }
}

export class PropValuePair {
  constructor(public prop, public value) {}
}

export function render(devRuntime, args: {
  type?;
  value?;
  setValue?;
}) {
  return <RenderWrapper devRuntime={devRuntime} args={args} />;

  const type = typeRegistry[args.type];

  if (!type) {
    return `Invalid: ${_.invert(Type)[args.type]}`;
  }

  const r = type.render(devRuntime, {
    value: args.value,
    setValue: args.setValue,
  });

  if (type.styles) {
    const Tag = type.styles;
    return <Tag>{r}</Tag>;
  }
  else {
    return r;
  }
}
export enum Type {
  // Properties
  BoxSizing = '4c40caef-9a23-5b65-9c40-20cf99d944aa',
  Opacity = '7a8d9f38-15e8-59e6-ae5b-d4fa63ba69c2',
  Width = '4d1577cd-60d3-5e0d-9181-f3cd4e928f24',
  Height = 'd1154217-f838-553c-ae24-38fbd18095a5',
  Margin = '30ebcf1d-efce-562b-a9df-ada8980e4bcc',
  Padding = 'eca115d5-5af7-539d-9867-3455375a0bfd',
  Display = '98b1bd7a-ef5c-5ca7-90ab-98167e45a6e0',
  Border = '49117ec5-6591-5a69-9978-511a843dbc6c',
  BorderRadius = '06463ba6-61e9-507d-8ae7-568f8356daec',
  Overflow = 'aae3b1c3-697d-5473-940f-a611f815c464',

  WhiteSpace = 'eb0137b7-e9c1-5a7f-a1d4-f05a520e6b94',


  FontFamily = '2be9e654-5dc2-5f7a-9877-b5e7666c10d1',
  FontWeight = '20f5b2a1-5c58-5ecf-872d-bb09afab2b17',
  FontStyle = '933071d5-64a1-5251-9c29-23db44c030d3',
  FontSize = 'b04f7515-536c-5081-a04e-250517d6820b',
  Color = '48dae123-72ee-5b1d-a639-676c5f719cbf',
  TextAlign = '94ce8184-1e1a-5c0a-8834-693192094366',

  Position = '7b07b602-1a41-5d6e-8075-2df2ad3f2062',
  Top = '811e38a6-93dc-51f1-a088-63c138f0d11d',
  Right = '52e7927e-720e-530f-b096-5f9cc0324bf2',
  Bottom = '83c6b3e5-5dc8-56fd-82b5-2f4ad987cfdb',
  Left = '6c8ce29f-cee0-5b94-9b08-f06773d57b8d',
  ZIndex = '5aba8037-0827-5d33-8fb2-ba9370a9446f',

  FlexDirection = 'd4119d04-b017-51db-b0fe-b01fc2bc0009',
  JustifyContent = '2e953926-5d05-55e5-a550-1df5d09a01cf',
  AlignItems = '28764c7c-b6e6-5e6c-87b1-3c36918aa92d',
  AlignContent = '34f45cdc-ba05-5959-b77b-5ab91d3e92ec',
  Gap = 'b5d40261-9a82-5fd0-ac1c-209a75d74580',

  Cursor = '1e213900-75e4-59a6-a914-ed7a3cbd5f00',



  Asf = 'bb8da36a-df1f-5dc9-b6d6-ed6de87d6747',

  // atomic type
  Auto = '5d1ed7df-df7d-5d33-a82e-16c07f836a22',
  Literal = 'e1e0a1a1-6688-5d25-ac9c-a087567ca1f8',
  Px = '9d66705f-d534-57ea-8fe2-f53ff026088e',
  Percent = '325e6f86-2a63-5361-9f9e-c8ab5994489f',
  Decimal = '48988327-c375-5f09-baa5-4a6a5aede124',
  BorderType = '55166a91-ba9e-51dd-a335-29f146c9f354',
  ColorValue = '5dd430e0-b711-5bca-8049-7419745b6c97',
  FontFamilyValue = '416c49d9-1ffa-5b08-8387-c7ea712e4e42',
  FontWeightValue = '3a10e95f-be79-59de-a79b-c671e28547a5',
  FontSizeValue = '8dd30311-752c-501f-9abe-7d3f24fee24e',

  ColorPreset = '791336c0-07cb-54e6-928c-9339ad8362a6',


  // composite type
  TopRightBottomLeft = '4ae7559d-b250-5610-b30f-b612455f8d1b',
  TopBottom_LeftRight = 'add48286-9635-5bc9-9e9f-db0a55e2f44c',
  All = 'b48fca0f-8ab4-56f9-8960-a80ecc2701a7',
  BorderValue = 'f1efeae6-ddf9-5c78-adcd-9f1c804e6318',


  BackgroundColor = '01116482-aabe-5440-9a3a-61590bd6d64b',


  BorderAll = 'ce3fa7d6-dcbb-5415-b0cb-a2f24bd1060e',
  BorderRight = '1a4eaa85-636f-5fb6-8434-bba0a7a0e7c4',
  BorderTop = 'd363b13b-6f87-511a-adfc-b9abd7026b44',
  BorderBottom = '0fed7ff0-e980-5b41-9b94-94af4adb43c8',
  BorderLeft = '43e9d771-b477-515b-8ca1-30088211e099',

  BoxShadow = '90746655-c2a0-5ae6-9b5c-1f3a39af7fcd',
  BoxShadowValue = '4df61cf0-4ae0-5af7-b484-2b6addb4d076',
}



const grow = css`
    display: flex;
    > * {
      flex: 1 1 0;
    }
`
const asf = {
  styles: styled.div`
    display: flex;
    align-items: center;
    > * {
      flex: 1 1 auto;
    }
  `,
  renderText(devRuntime, value) {
    return renderText(devRuntime, value, '');
  },
  hasValue(value) {
    return !_.isNil(value) && value !== '';
  },
  render(devRuntime: DevRuntime, { value, setValue }) {
    value = value || { type: Type.Px };
    return (
      <>

        {render(devRuntime, {
          type: value?.type,
          value: value?.value,
          setValue: v => setValue({ type: value.type, value: v }),
        })}
        <ModeSelector
          options={[Type.Auto, Type.Px, Type.Percent]}
          onSelectOption={type => {
            setValue({ type });
          }}
        />
      </>
    );
  },
}

registerType(Type.Asf, asf);


// properties

registerType(Type.BoxSizing, {
  styles: styled.div`
    ${grow}
  `,
  renderText(devRuntime, value) {
    return renderText(devRuntime, value);
  },
  render(devRuntime, { value, setValue }) {
    return (
      <Select
        value={value}
        onChangeValue={setValue}
        options={[
          {
            value: {
              type: Type.Literal,
              value: 'border-box',
            },
            display: 'Border Box',
          },
          {
            value: {
              type: Type.Literal,
              value: 'content-box',
            },
            display: 'Content Box',
          },
        ]} />
    );
  }
});
registerType(Type.Overflow, {
  styles: styled.div`
    ${grow}
  `,
  renderText(devRuntime, value) {
    return renderText(devRuntime, value);
  },
  render(devRuntime, { value, setValue }) {
    return (
      <Select
        value={value}
        onChangeValue={setValue}
        options={[
          {
            value: {
              type: Type.Literal,
              value: 'visible',
            },
            display: 'Visible',
          },
          {
            value: {
              type: Type.Literal,
              value: 'hidden',
            },
            display: 'Hidden',
          },
          {
            value: {
              type: Type.Literal,
              value: 'auto',
            },
            display: 'Auto',
          },
        ]} />
    );
  }
});

registerType(Type.TextAlign, {
  styles: styled.div`
    ${grow}
  `,
  renderText(devRuntime, value) {
    return renderText(devRuntime, value);
  },
  render(devRuntime, { value, setValue }) {
    return (
      <Select
        value={value}
        onChangeValue={setValue}
        options={[
          {
            value: {
              type: Type.Literal,
              value: 'center',
            },
            display: 'Center',
          },
        ]}
      />
    );
  }
});

registerType(Type.Opacity, {
  styles: styled.div`
    ${grow}
    input {
      width: 0;
    }
  `,
  renderText(devRuntime, value) {
    return renderText(devRuntime, value);
  },
  render(devRuntime, { value, setValue }) {
    return (
      <input type="text"
        value={value?.value}
        onChange={e => {
          setValue({ type: Type.Decimal, value: e.target.value });
        }} />
    );
  },
});
registerType(Type.Margin, {
  styles: styled.div`
    display: flex;
    align-items: flex-end;
    > * {
      flex: 1 1 0;
    }
  `,
  renderText(devRuntime, value) {
    return renderText(devRuntime, value);
  },
  render(devRuntime, { value, setValue }) {

    value = value || {
      type: Type.All,
    };

    return (
      <>
        {value && render(devRuntime, {
          type: value.type,
          value: value.value,
          setValue: v => {
            setValue({
              type: value.type,
              value: v,
            });
          }
        })}
        <ModeSelector
          onSelectOption={op => {
            setValue({ type: op });
          }}
          options={[
            Type.All,
            Type.TopRightBottomLeft,
            Type.TopBottom_LeftRight,
          ]} />
      </>
    );
  }
});
registerType(Type.Padding, {
  styles: styled.div`
    display: flex;
    align-items: flex-end;
    > * {
      flex: 1 1 0;
    }
  `,

  renderText(devRuntime, value) {
    return renderText(devRuntime, value);
  },
  render(devRuntime, { value, setValue }) {

    value = value || {
      type: Type.All,
    };

    return (
      <>
        {value && render(devRuntime, {
          type: value.type,
          value: value.value,
          setValue: v => {
            setValue({
              type: value.type,
              value: v,
            });
          }
        })}
        <ModeSelector
          onSelectOption={op => {
            setValue({ type: op });
          }}
          options={[
            Type.All,
            Type.TopRightBottomLeft,
            Type.TopBottom_LeftRight,
          ]} />
      </>
    );
  }
});
registerType(Type.Width, asf);
registerType(Type.Height, asf);

registerType(Type.Display, {
  renderText(devRuntime, value) {
    return renderText(devRuntime, value);
  },
  render(devRuntime, { value, setValue }) {
    return (
      <Select
        placeholder=""
        value={value}
        onChangeValue={setValue}
        options={[
          { display: 'Inline', value: { type: Type.Literal, value: 'inline' } },
          { display: 'Inline Block', value: { type: Type.Literal, value: 'inline-block' } },
          { display: 'Block', value: { type: Type.Literal, value: 'block' } },
          { display: 'Inline Flex', value: { type: Type.Literal, value: 'inline-flex' } },
          { display: 'Flex', value: { type: Type.Literal, value: 'flex' } },
          { display: 'Inline Grid', value: { type: Type.Literal, value: 'inline-grid' } },
          { display: 'Grid', value: { type: Type.Literal, value: 'grid' } },
        ]}
      />
    )
  },
});

registerType(Type.BorderRadius, asf);

registerType(Type.BorderAll, {
  styles: styled.div`
    ${grow}
  `,
  render(devRuntime, { value, setValue }) {
    return (
      <>
        <Labeled
          label="Top"
          input={render(devRuntime, {
            type: Type.BorderValue,
            value: value?.value?.value,
            setValue: v => setValue({ type: Type.BorderAll, value: { type: Type.BorderValue, value: v } }),
          })}
        />
      </>
    )
  },
  renderText(devRuntime, value) {
    return value?.value&& new PropValuePair('border', renderText(devRuntime, value?.value));
  }
});

registerType(Type.BorderTop, {
  styles: styled.div`
    ${grow}
  `,
  render(devRuntime, { value, setValue }) {
    return (
      <>
        <Labeled
          label="Top"
          input={render(devRuntime, {
            type: Type.BorderValue,
            value: value?.value?.value,
            setValue: v => setValue({ type: Type.BorderTop, value: { type: Type.BorderValue, value: v } }),
          })}
        />
      </>
    )
  },
  renderText(devRuntime, value) {
    return value?.value&& new PropValuePair('border-top', renderText(devRuntime, value?.value));
  }
});
registerType(Type.BorderRight, {
  styles: styled.div`
    ${grow}
  `,
  render(devRuntime, { value, setValue }) {
    return (
      <>
        <Labeled
          label="Right"
          input={render(devRuntime, {
            type: Type.BorderValue,
            value: value?.value?.value,
            setValue: v => setValue({ type: Type.BorderRight, value: { type: Type.BorderValue, value: v } }),
          })}
        />
      </>
    )
  },
  renderText(devRuntime, value) {
    return value?.value&& new PropValuePair('border-right', renderText(devRuntime, value?.value));
  }
});

registerType(Type.BorderBottom, {
  styles: styled.div`
    ${grow}
  `,
  render(devRuntime, { value, setValue }) {
    return (
      <>
        <Labeled
          label="Bottom"
          input={render(devRuntime, {
            type: Type.BorderValue,
            value: value?.value?.value,
            setValue: v => setValue({ type: Type.BorderBottom, value: { type: Type.BorderValue, value: v } }),
          })}
        />
      </>
    )
  },
  renderText(devRuntime, value) {
    return value?.value&& new PropValuePair('border-bottom', renderText(devRuntime, value?.value));
  }
});


registerType(Type.BorderLeft, {
  styles: styled.div`
    ${grow}
  `,
  render(devRuntime, { value, setValue }) {
    return (
      <>
        <Labeled
          label="Left"
          input={render(devRuntime, {
            type: Type.BorderValue,
            value: value?.value?.value,
            setValue: v => setValue({ type: Type.BorderLeft, value: { type: Type.BorderValue, value: v } }),
          })}
        />
      </>
    )
  },
  renderText(devRuntime, value) {
    return value?.value&& new PropValuePair('border-left', renderText(devRuntime, value?.value));
  }
});

registerType(Type.Border, {
  styles: styled.div`
    ${grow}

    ${ModeSelector} {
      align-self: flex-end;
    }
  `,
  render(devRuntime, { value, setValue }) {
    return (
      <>
        {/*  */}

        {!value?.type && (
          <>
            {render(devRuntime, {
              type: Type.BorderValue,
              value: value?.value,
              setValue: v => setValue({ type: Type.BorderValue, value: v }),
            })}
          </>
        )}


          {value?.type && (
            <>
              {render(devRuntime, {
                type: value?.type,
                value: value?.value,
                setValue: v => setValue({ type: value.type, value: v }),
              })}
            </>
          )}


      
        
        <ModeSelector
          options={[Type.BorderAll, Type.BorderTop, Type.BorderRight, Type.BorderBottom, Type.BorderLeft]}
          onSelectOption={type => {
            setValue({ type });
          }}
        />

      </>
    )
  },
  renderText(devRuntime, value) {
    return renderText(devRuntime, value);
  }
});

registerType(Type.BoxShadow, {
  styles: styled.div`
    ${grow}

    ${ModeSelector} {
      align-self: flex-end;
    }
  `,
  render(devRuntime, { value, setValue }) {
    return (
      <>

            {render(devRuntime, {
              type: Type.BoxShadowValue,
              value: value?.value,
              setValue: v => setValue({ type: Type.BoxShadowValue, value: v }),
            })}





      </>
    )
  },
  renderText(devRuntime, value) {
    return renderText(devRuntime, value);
  }
});



registerType(Type.Position, {
  renderText(devRuntime, value) {
    return renderText(devRuntime, value);
  },
  render(devRuntime, { value, setValue }) {
    return (
      <Select
        placeholder=""
        value={value}
        onChangeValue={setValue}
        options={[
          { display: 'Static', value: { type: Type.Literal, value: 'static' } },
          { display: 'Relative', value: { type: Type.Literal, value: 'relative' } },
          { display: 'Absolute', value: { type: Type.Literal, value: 'absolute' } },
          { display: 'Fixed', value: { type: Type.Literal, value: 'fixed' } },
          { display: 'Sticky', value: { type: Type.Literal, value: 'sticky' } },
        ]}
      />
    )
  },
})

registerType(Type.Top, asf);
registerType(Type.Right, asf);
registerType(Type.Bottom, asf);
registerType(Type.Left, asf);
registerType(Type.Gap, asf);


registerType(Type.ZIndex, {
  renderText(devRuntime, value) {
    return renderText(devRuntime, value);
  },
  render(devRuntime, { value, setValue }) {
    return (
      <input type="text"
        value={value?.value}
        onChange={e => {
          setValue({ type: Type.Decimal, value: e.target.value });
        }} />
    );
  },
});


registerType(Type.FontFamily, {
  styles: styled.div`
    ${grow}
  `,
  renderText(devRuntime, value) {
    return renderText(devRuntime, value);
  },
  render(devRuntime, { value, setValue }) {
    return (
      <input type="text"
        value={value?.value}
        onChange={e => {
          setValue({ type: Type.FontFamilyValue, value: e.target.value });
        }} />
    );
  },
});

registerType(Type.FontWeight, {
  styles: styled.div`
    ${grow}
  `,
  renderText(devRuntime, value) {
    return renderText(devRuntime, value);
  },
  render(devRuntime, { value, setValue }) {
    return (
      <input type="text"
        value={value?.value}
        onChange={e => {
          setValue({ type: Type.FontWeightValue, value: e.target.value });
        }} />
    );
  },
});

registerType(Type.FontSize, {
  styles: styled.div`
    ${grow}
  `,
  renderText(devRuntime, value) {
    return renderText(devRuntime, value);
  },
  render(devRuntime, { value, setValue }) {

    value = value || { type: Type.Px };

    return (
      <>
        {render(devRuntime, {
          type: value.type,
          value: value.value,
          setValue: v => setValue({ type: value.type, value: v }),
        })}
        <ModeSelector options={[Type.Px]} onSelectOption={type => setValue({ type })} />
      </>

    )


    return (
      <input type="text"
        value={value?.value}
        onChange={e => {
          setValue({ type: Type.FontSizeValue, value: e.target.value });
        }} />
    );
  },
});

registerType(Type.ColorPreset, {
  styles: styled.div`
    display: flex;
    align-items: center;
    .swatch {
      width: 16px;
      height: 16px;
    }
  `,
  renderText(devRuntime, value) {
    if (value) {
      return devRuntime.devProject.colors?.find?.(c => c._id == value)?.color;
    }
  },
  render(devRuntime, { value, setValue }) {
    return (
      <>
      <span className="swatch" style={{
        backgroundColor: devRuntime.devProject.colors?.find?.(c => c._id == value)?.color
      }} />
      <select
        value={value}
        onChange={e => setValue(e.target.value)}
      >
        <option />
        {devRuntime.devProject?.colors?.map?.(c => {
          return (
            <option key={c._id} value={c._id}>{c.name}</option>
          )
        })}
      </select>
      </>
    )
  },
})

registerType(Type.BackgroundColor, {
  styles: styled.div`
    ${grow}
  `,
  renderText(devRuntime, value) {
    return renderText(devRuntime, value);
  },
  render(devRuntime, { value, setValue }) {
    value = value || {
      type: Type.ColorValue,
    }
    return (
      <>
        {render(devRuntime, {
          type: value.type,
          value: value?.value,
          setValue: v => setValue({ type: value.type, value: v }),
        })}
        <ModeSelector
          options={[Type.ColorValue, Type.ColorPreset]}
          onSelectOption={type => setValue({ type })}
        />
      </>
    )
  }
})

registerType(Type.Color, {
  styles: styled.div`
    ${grow}
  `,
  renderText(devRuntime, value) {
    return renderText(devRuntime, value);
  },
  render(devRuntime, { value, setValue }) {
    value = value || {
      type: Type.ColorValue,
    }
    return (
      <>
        {render(devRuntime, {
          type: value.type,
          value: value?.value,
          setValue: v => setValue({ type: value.type, value: v }),
        })}
        <ModeSelector
          options={[Type.ColorValue, Type.ColorPreset]}
          onSelectOption={type => setValue({ type })}
        />
      </>
    )
  }
})

registerType(Type.FontStyle, {
  styles: styled.div`
    ${grow}
  `,
  renderText(devRuntime, value) {
    return renderText(devRuntime, value);
  },
  render(devRuntime, { value, setValue }) {
    return (
      <Select
        value={value}
        onChangeValue={setValue}
        options={[
          {
            value: {
              type: Type.Literal,
              value: 'italic',
            },
            display: 'Italic',
          },

        ]} />
    );
  }
});


registerType(Type.JustifyContent, {
  renderText(devRuntime, value) {
    return renderText(devRuntime, value);
  },
  render(devRuntime, { value, setValue }) {
    return (
      <Select
        value={value}
        onChangeValue={setValue}
        options={[
          {
            value: { type: Type.Literal, value: 'center' },
            display: 'Center',
          },
          {
            value: { type: Type.Literal, value: 'flex-start' },
            display: 'Flex Start',
          },
          {
            value: { type: Type.Literal, value: 'flex-end' },
            display: 'Flex End',
          },
          {
            value: { type: Type.Literal, value: 'space-between' },
            display: 'Space Between',
          },
          {
            value: { type: Type.Literal, value: 'space-around' },
            display: 'Space Around',
          },

        ]} />
    );
  }
});

registerType(Type.FlexDirection, {
  renderText(devRuntime, value) {
    return renderText(devRuntime, value);
  },
  render(devRuntime, { value, setValue }) {
    return (
      <Select
        value={value}
        onChangeValue={setValue}
        options={[
          {
            value: { type: Type.Literal, value: 'row' },
            display: 'Row',
          },
          {
            value: { type: Type.Literal, value: 'row-reverse' },
            display: 'Row Reverse',
          },
          {
            value: { type: Type.Literal, value: 'column' },
            display: 'Column',
          },
          {
            value: { type: Type.Literal, value: 'column-reverse' },
            display: 'Column Reverse',
          },
        ]}
      />
    );
  }
});

registerType(Type.AlignItems, {
  styles: styled.div`
    ${grow}
  `,
  renderText(devRuntime, value) {
    return renderText(devRuntime, value);
  },
  render(devRuntime, { value, setValue }) {
    return (
      <Select
        value={value}
        onChangeValue={setValue}
        options={[
          {
            value: { type: Type.Literal, value: 'center' },
            display: 'Center',
          },
          {
            value: { type: Type.Literal, value: 'flex-start' },
            display: 'Flex Start',
          },
          {
            value: { type: Type.Literal, value: 'flex-end' },
            display: 'Flex End',
          },
          {
            value: { type: Type.Literal, value: 'stretch' },
            display: 'Stretch',
          },
        ]}
      />
    );
  }
});

registerType(Type.AlignContent, {
  styles: styled.div`
    ${grow}
  `,
  renderText(devRuntime, value) {
    return renderText(devRuntime, value);
  },
  render(devRuntime, { value, setValue }) {
    return (
      <Select
        value={value}
        onChangeValue={setValue}
        options={[
          {
            value: { type: Type.Literal, value: 'center' },
            display: 'Center',
          },
          {
            value: { type: Type.Literal, value: 'flex-start' },
            display: 'Flex Start',
          },
          {
            value: { type: Type.Literal, value: 'flex-end' },
            display: 'Flex End',
          },
          {
            value: { type: Type.Literal, value: 'stretch' },
            display: 'Stretch',
          },
        ]}
      />
    );
  }
});


registerType(Type.Cursor, {
  styles: styled.div`
    ${grow}

  `,
  renderText(devRuntime, value) {
    return renderText(devRuntime, value);
  },
  render(devRuntime, { value, setValue }) {
    return (
      <Select
        value={value}
        onChangeValue={setValue}
        options={[
          {
            value: {
              type: Type.Literal,
              value: 'default',
            },
            display: 'Default',
          },
          {
            value: {
              type: Type.Literal,
              value: 'pointer',
            },
            display: 'Pointer',
          },
        ]} />
    );
  }
});

registerType(Type.WhiteSpace, {
  styles: styled.div`
    ${grow}

  `,
  renderText(devRuntime, value) {
    return renderText(devRuntime, value);
  },
  render(devRuntime, { value, setValue }) {
    return (
      <Select
        value={value}
        onChangeValue={setValue}
        options={[
          {
            value: {
              type: Type.Literal,
              value: 'nowrap',
            },
            display: 'No Wrap',
          },
          // {
          //   value: {
          //     type: Type.Literal,
          //     value: 'pointer',
          //   },
          //   display: 'Pointer',
          // },
        ]} />
    );
  }
});


// atomic type
registerType(Type.Px, {
  styles: styled.div`
    display: flex;
    align-items: center;
    input {
      flex: 1 1 0;
      width: 0;
      margin-right: 3px;
    }
  `,
  render(devRuntime, { value, setValue }) {
    return <><input
      onKeyDown={e => {
        if (e.key == 'ArrowUp') {
          e.preventDefault();
          setValue((parseInt(value) || 0) + 1 * (e.shiftKey ? 10 : 1));
        }
        else if (e.key == 'ArrowDown') {
          e.preventDefault();
          setValue((parseInt(value) || 0) - 1 * (e.shiftKey ? 10 : 1));
        }

      }}
    
    type="text" value={value} onChange={e => setValue(e.target.value)} />px</>;
  },
  hasValue(value) {
    return !_.isNil(value) && value !== '';
  },
  renderText(devRuntime, value) {
    return `${value || 0}px`;
  },
});

registerType(Type.Percent, {
  styles: styled.div`
    display: flex;
    align-items: center;
    input {
      flex: 1 1 0;
      width: 0;
      margin-right: 3px;
    }
  `,
  render(devRuntime, { value, setValue }) {
    return <><input
    
    onKeyDown={e => {
      if (e.key == 'ArrowUp') {
        e.preventDefault();
        setValue((parseInt(value) || 0) + 1 * (e.shiftKey ? 10 : 1));
      }
      else if (e.key == 'ArrowDown') {
        e.preventDefault();
        setValue((parseInt(value) || 0) - 1 * (e.shiftKey ? 10 : 1));
      }

    }}
    type="text" value={value} onChange={e => setValue(e.target.value)} />%</>;
  },
  hasValue(value) {
    return value;
  },
  renderText(devRuntime, value) {
    return value ? `${value}%` : '';
  },
});
registerType(Type.Decimal, {
  styles: styled.div`
    display: flex;
    input {
      width: 0;
    }
  `,
  render(devRuntime, { value, setValue }) {
    return <><input type="text" value={value} onChange={e => setValue(e.target.value)} /></>;
  },
  renderText(devRuntime, value) {
    return value ? `${value}` : '0';
  },
});
registerType(Type.Literal, {
  render() {
    return '!!!!';
  },
  renderText(devRuntime, value) {
    return value;
  },
});
registerType(Type.Auto, {
  renderText(devRuntime, value) {
    return 'auto';
  },
  render() {
    return 'auto';
  }
});
registerType(Type.ColorValue, {
  styles: styled.div`
    ${grow}
    align-items: center;
    ${ColorDisplay} {
      cursor: pointer;
      display: flex;
      align-items: center;
      > * {
        flex: 1 1 auto;
      }
    }
    /* input {
      width: 0;
    } */
  `,
  render(devRuntime, { value, setValue }) {
    return <>
      <ColorDisplay
        devRuntime={devRuntime}
        colorValue={() => value}
        setColorValue={setValue}
      />

      {/* <input type="text" value={value} onChange={e => setValue(e.target.value)} /> */}
    </>;
  },
  renderText(devRuntime, value) {
    if (isId(value)) {
      return devRuntime.devProject.colors.find(c => c._id == value)?.color;
    }
    else {
      return value || '';
    }
    return value ? `${value}` : '';
  },
});

registerType(Type.FontFamilyValue, {
  styles: styled.div`
    ${grow}
    input {
      width: 0;
    }
  `,
  render(devRuntime, { value, setValue }) {
    return <><input type="text" value={value} onChange={e => setValue(e.target.value)} /></>;
  },
  renderText(devRuntime, value) {
    return value ? `${value}` : '';
  },
});
registerType(Type.FontSizeValue, {
  styles: styled.div`
    ${grow}
    input {
      width: 0
    }
  `,
  render(devRuntime, { value, setValue }) {
    return <><input type="text" value={value} onChange={e => setValue(e.target.value)} /></>;
  },
  renderText(devRuntime, value) {
    return value ? `${value}` : '';
  },
});
registerType(Type.FontWeightValue, {
  styles: styled.div`
    display: flex;
    input {
      width: 50px;
    }
  `,
  render(devRuntime, { value, setValue }) {
    return <><input type="text" value={value} onChange={e => setValue(e.target.value)} /></>;
  },
  renderText(devRuntime, value) {
    return value ? `${value}` : '';
  },
});


registerType(Type.BorderType, {
  renderText(devRuntime, value) {
    return renderText(devRuntime, value);
  },
  render(devRuntime, { value, setValue }) {
    return (
      <Select
        value={value}
        onChangeValue={setValue}
        options={[
          {
            value: { type: Type.Literal, value: 'solid' },
            display: 'Solid',
          },
          {
            value: { type: Type.Literal, value: 'dashed' },
            display: 'Dashed',
          },
        ]} />
    );
  }
});

// composite type
registerType(Type.TopRightBottomLeft, {
  styles: styled.div`
    input {
      width: 100%;
    }
  `,
  renderText(devRuntime, value) {
    return `${renderText(devRuntime, value?.top, '0')} ${renderText(devRuntime, value?.right, '0')} ${renderText(devRuntime, value?.bottom, '0')} ${renderText(devRuntime, value?.left, '0')}`;
  },
  render(devRuntime, { value, setValue }) {
    value ||= {};
    return (
      <HStack>
        <Labeled
          label="Top"
          input={
            render(devRuntime, {
              type: Type.Asf,
              value: value.top,
              setValue: v => {
                setValue({
                  ...value,
                  top: v,
                })
              }
            })
          }
        />
        <Labeled
          label="Right"
          input={
            render(devRuntime, {
              type: Type.Asf,
              value: value.right,
              setValue: v => {
                setValue({
                  ...value,
                  right: v,
                })
              }
            })
          }
        />
        <Labeled
          label="Bottom"
          input={
            render(devRuntime, {
              type: Type.Asf,
              value: value.bottom,
              setValue: v => {
                setValue({
                  ...value,
                  bottom: v,
                })
              }
            })
          }
        />
        <Labeled
          label="Left"
          input={
            render(devRuntime, {
              type: Type.Asf,
              value: value.left,
              setValue: v => {
                setValue({
                  ...value,
                  left: v,
                })
              }
            })
          }
        />

      </HStack>
    );
  }
});


registerType(Type.TopBottom_LeftRight, {
  styles: styled.div`
    input {
      width: 100%;
    }
  `,
  renderText(devRuntime, value) {
    return `${renderText(devRuntime, value?.topBottom, '0')} ${renderText(devRuntime, value?.leftRight, '0')}`;
  },
  render(devRuntime, { value, setValue }) {
    value ||= {};
    return (
      <HStack>
        <Labeled
          label="Top/Bottom"
          input={
            render(devRuntime, {
              type: Type.Asf,
              value: value.topBottom,
              setValue: v => {
                setValue({
                  ...value,
                  topBottom: v,
                })
              }
            })
          }
        />
        <Labeled
          label="Left/Right"
          input={
            render(devRuntime, {
              type: Type.Asf,
              value: value.leftRight,
              setValue: v => {
                setValue({
                  ...value,
                  leftRight: v,
                })
              }
            })
          }
        />


      </HStack>
    );
  }
});


registerType(Type.All, asf);



registerType(Type.BoxShadowValue, {
  render(devRuntime, { value, setValue }) {
    return (
      <>
        <HStack>
          <Labeled
            label="Offset X"
            input={render(devRuntime, {
              type: Type.Px,
              value: value?.xOffset?.value,
              setValue: v => setValue({ ...value, xOffset: { type: Type.Px, value: v } }),
            })}
          />

<Labeled
            label="Offset Y"
            input={render(devRuntime, {
            type: Type.Px,
            value: value?.yOffset?.value,
            setValue: v => setValue({ ...value, yOffset: { type: Type.Px, value: v } }),
          })}/>

          <Labeled
            label="Blur"
            input={render(devRuntime, {
            type: Type.Px,
            value: value?.blur?.value,
            setValue: v => setValue({ ...value, blur: { type: Type.Px, value: v } }),
          })}/>


          <Labeled
            label="Spread"
            input={render(devRuntime, {
            type: Type.Px,
            value: value?.spread?.value,
            setValue: v => setValue({ ...value, spread: { type: Type.Px, value: v } }),
          })}/>



          <Labeled
            label="Color"
            input={render(devRuntime, {
            type: Type.ColorValue,
            value: value?.color?.value,
            setValue: v => setValue({ ...value, color: { type: Type.ColorValue, value: v } }),
          })}/>

        </HStack>
      </>
    )
  },
  renderText(devRuntime, value) {
    return `${renderText(devRuntime, value?.xOffset, '0')} ${renderText(devRuntime, value?.yOffset, '0')}  ${renderText(devRuntime, value?.blur, '0')} ${renderText(devRuntime, value?.spread, '0')} ${renderText(devRuntime, value?.color, '')}`.trim();
  }
});


registerType(Type.BorderValue, {
  render(devRuntime, { value, setValue }) {
    return (
      <>
        <HStack>
          {render(devRuntime, {
            type: Type.Asf,
            value: value?.width,
            setValue: v => setValue({ ...value, width: v }),
          })}

          {render(devRuntime, {
            type: Type.BorderType,
            value: value?.type,
            setValue: v => setValue({ ...value, type: v }),
          })}


          {render(devRuntime, {
            type: Type.ColorValue,
            value: value?.color?.value,
            setValue: v => setValue({ ...value, color: { type: Type.ColorValue, value: v } }),
          })}

        </HStack>
      </>
    )
  },
  renderText(devRuntime, value) {
    return `${renderText(devRuntime, value?.width, '')} ${renderText(devRuntime, value?.type, '')} ${renderText(devRuntime, value?.color, '')}`.trim();
  }
});

export const HStack = styled.div`
  display: flex;
  gap: 5px;
  align-items: flex-end;
  > * {
    flex: 1 1 0;
    width: 100%;
  }
`;

