import { db } from "../../db";
import { getAttributeKey } from './renderAttributeValue';
import { getAttributeOrder } from './$EntityQueryView';
import { getEntityById } from "../../etc/createEntity";

export function groupById(entities, attr) {
  const groups = {};

  for (const id of entities) {
    const entity = getEntityById(id);
    let group;
    if (entity?.attributes?.[attr]) {
      group = JSON.stringify([getAttributeKey(attr, id), entity.attributes[attr]]);
    }
    else {
      group = JSON.stringify([null, 'None']);
    }

    if (!groups[group]) {
      groups[group] = [];
    }

    groups[group].push(id);
  }

  // sort groups by attribute order
  const groupsSorted = Object.keys(groups).sort((a, b) => {
    const aOrder = (getAttributeOrder as any)(attr, ...JSON.parse(a));
    const bOrder = (getAttributeOrder as any)(attr, ...JSON.parse(b));
    return aOrder - bOrder;
  });

  return groupsSorted.map(a => {
    const [key, group] = JSON.parse(a);

    return {
      key,
      group,
      entities: groups[a],
    };

  });
}
