import { defaultWorkspace } from '../etc/defaultWorkspace';
import { evaluate, execute, getValuePoint, mapStructure } from '../glue/main';
import { Runtime } from '../glue/Runtime';
import { db } from '../db';
import { sendSignal } from '../glue/signalsRegistry';
import { ADD_ATTRIBUTE_SIGNAL, ADD_MATCH_SIGNAL, REMOVE_ATTRIBUTE_SIGNAL } from '../glue/structs/$EntityTemplate';
import { getEntityById } from '../etc/createEntity';

export function attributesForType(id): string[] {
  const templateSupplierId = defaultWorkspace().entityTemplateSupplier;
  const executed = execute(templateSupplierId);
  const mapped = mapStructure(executed);
  for (const match of mapped.matches.content) {
    const matchMapped = mapStructure(match);
    if (id == matchMapped.match?.content) {
      return evaluate(matchMapped.attributes);
    }
  }

  return [];
}

export function entitySchema(id, ctx = {}) {
  const entity = getEntityById(id);
  if (!entity) return;
  const templateSupplierId = defaultWorkspace().entityTemplateSupplier;
  if (!templateSupplierId) return null;
  
  const executed = execute(templateSupplierId, new Runtime(ctx));
  const mapped = mapStructure(executed);
  let template;
  let _match;
  if (mapped?.matches?.content) for (const match of mapped.matches.content) {
    const matchMapped = mapStructure(match);
    if (entity.type == matchMapped.match?.content) {
      _match = match;
      template = matchMapped.template;

      return {
        match,
        name: matchMapped.name?.content,
        template: matchMapped.template,
        elements: matchMapped.elements,
        rowElements: matchMapped.rowElements,
        attributes: matchMapped.attributes,
        states: matchMapped.states,
        titleAttribute: matchMapped.titleAttribute,
        showCreator: matchMapped.showCreator?.content,
        showTimestamp: matchMapped.showTimestamp?.content,
        sets: matchMapped.sets,
        actions: matchMapped.actions,
        queries: matchMapped.queries,
        views: matchMapped.views,
      };
    }
  }
}

export async function addAttrToSchema(id: string, type: string, value: string) {
  const schema = entitySchema(id);
  if (schema?.match) {
    sendSignal(schema.match, ADD_ATTRIBUTE_SIGNAL, value);
  }
  else {
    const m = await sendSignal(getValuePoint(defaultWorkspace().entityTemplateSupplier), ADD_MATCH_SIGNAL, type);
    sendSignal(m, ADD_ATTRIBUTE_SIGNAL, value);
  }  

}

export async function removeAttrFromSchema(id, attrid) {
  const schema = entitySchema(id);

  if (schema?.match) sendSignal(schema.match, REMOVE_ATTRIBUTE_SIGNAL, attrid);

}

export function getTemplate(type) {
  const templateSupplierId = defaultWorkspace().entityTemplateSupplier;

  const executed = templateSupplierId && execute(templateSupplierId);

  const mapped = executed && mapStructure(executed);

  let template;
  // let _match;
  if (mapped?.matches?.content) for (const match of mapped.matches.content) {
    const matchMapped = mapStructure(match);
    if (type == matchMapped.match?.content) {
      // _match = match;
      template = matchMapped.template;
    }
  }

  return template;
}
