export enum BlockType {
  query = 'edb1672f-df94-5fd7-8fab-717492e85986',
  formula = '889999a5-0ca7-5a3f-9c92-53cafb8183d0',
  callout = '1fb91cda-8341-5ed0-be01-6fd97f361164',
  horizontalLine = '39e80120-461c-5f18-b91d-bbc77a2fb0a3',
  code = 'code',
  media = 'media',

  heading_1 = 'heading_1',
  heading_2 = 'heading_2',

  document = 'f6165d09-415a-54b9-acba-ef625650abd4',

  
  glue = 'cdf65a00-5194-50d9-9dca-d0749ee5b853',

  template = '3bb19665-f770-552f-b088-190501c15e2e',

  
}
