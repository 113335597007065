import { db } from '../db';
import { getEntityById } from '../etc/createEntity';

export function inArchivedSpace(entity, fromEntityId: string) {
  if (entity.space) {
    const space = db.spaces.findById(entity.space);
    if (space?.archived) {
      const fromEntity = fromEntityId && getEntityById(fromEntityId);
      if (!fromEntity || fromEntity.space != entity.space) {
        return true;
      }

    }
  }
  return false;
}
