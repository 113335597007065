import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import jQuery from 'jquery';
import _ from 'lodash';
import cx from 'classnames';
import { component } from './component2';
import { X, XClone, XInit, XObject, x } from './XObject';
import { styled } from './component';
import { ScriptWrapper, createScriptWrapper } from './MyNotionDocument/ScriptWrapper';
import { ScriptEditor } from './MyNotionDocument/ScriptEditor';
import { showContextMenu } from './helpers';
import { Pass, RenderElHooks, _hasChildren, renderEl, scriptEmpty } from './shorthand/formula';
import { RuntimeContext, RuntimeTrace } from './shorthand/RuntimeTrace';
import { PropertyField } from './components/PropertyField';
import { uploadedFileUrl } from './components/FileUpload';
import { RemoteSvg, Svg } from './components/Svg';
import { DevProjectFormulaEditor, DevProjectScriptEditor } from './DevProjectScriptEditor';
import { DevRuntime } from './DevRuntime';
import { toast } from 'react-toastify';
import { Tree, TreeCompNode } from './Tree';
import { CSSDesigner, generateCssDesignerLines2, generateDesignerCss } from './CSSDesigner';
import { Labeled } from './Labeled';
import { FormulaEditor } from './shorthandEditor/FormulaEditor';
import { getActiveEditorState, getComponentEditorState, getDevProjectEditorState } from './DevProject.1';
import { OverlayManager } from './OverlayManager';
import { NotionDocument2 } from './components/notionDocument/NotionDocument2';
import { MyBlockManager } from './MyNotionDocument/MyBlockManager';
import { renderBlock } from './MyNotionDocument/renderBlock';
import { findNodeInComponent } from './findNodeInComponent';
import { sectionStyles } from './sectionStyles';
import { baseStyles } from './baseStyles';
import { Related } from './ImportMenu';
import { findWorkspace } from './findWorkspace';
import { ComponentEntry } from './ComponentEntry';
import { Tag } from './components/Tag';

const findNode = (start, id) => {
  if (start._id == id) {
    return start;
  }

  if (start.children) {
    for (const child of start.children) {
      const r = findNode(child, id);
      if (r) return r;
    }
  }
}

@component
class StylesSection extends Component<{ node, devRuntime: DevRuntime, importedComponents, state }> {
  static styles = styled.div`
    .css {
      font-family: Monaco;
      margin: 0;

      .property {
        cursor: pointer;

        &:hover {
          background-color: #f3f3f3;
        }

        &.active {
          background-color: #f0f0f0;
        }
      }
    }

    .selector {
    }

    .nodeStyleTree {
      .component:not(.editingSelectors) {
        ${FormulaEditor} {
          pointer-events: none;
        }

      }
    }
    .styleNode {

      &.selected {
        > .name {
          font-weight: bold;
        }
      }
      .name {
        cursor: pointer;
        position: relative;
      }

      &:not(.editingSelector) {
        > .name {
          ${FormulaEditor} {
            pointer-events: none;
          }
        }
      }
    }
  `;

  state = XInit(class {
    editingSelectors = {}
    tree = {}
  });

  overlay = new OverlayManager();

  componentWillUnmount(): void {
    this.overlay.cleanup();
  }

  render() {
    const generateTree = (node, parent?) => {
      const lines = generateCssDesignerLines2(this.props.devRuntime, node.properties || {});

      const nodeId = node._id// || this.props.node._id;

      return {
        key: node._id,
        elId: node._id,
        className: this.state.editingSelectors[node._id] && 'editingSelectors',

        contextMenu: [
          parent && {
            text: 'Delete',
            onClick: () => {
              const index = parent.children.indexOf(node);
              parent.children.splice(index, 1);
            },
          },
          {
            text: 'Add child', 
            onClick: () => {
              XObject.push(node, 'children', XObject.obj({
                properties: {},
              }))
            },
          },
          {
            text: 'Edit selector',
            onClick: () => {
              this.state.editingSelectors[node._id] = true;
            },
          },
        ],

        content: () => {
          return (
            <div
              style={{
                padding: '1px 0',
              }}
            >
          {lines.length > 0 && (
            <div className="css">
              {lines.map(line => (
                <div
                  key={line.join(': ')}
                  onClick={() => {
                    this.props.state.styleNodeState = {
                      id: node._id,
                      prop: line[0],
                    }
                  }}
                  className={cx('property', {
                    active: nodeId == this.props.state.styleNodeState.id && this.props.state.styleNodeState.prop == line[0]
                  })}
                ><span style={{color:'green'}}>{line[0]}</span>: <span style={{color: 'orange'}}>{line[1]}</span></div>
              ))}
            </div>
          )}
            </div>
          )
        },

        text: node._id ? (
          <div className="selector">
            <DevProjectFormulaEditor
              devRuntime={this.props.devRuntime}
              onValuChanged={v => node.selector = v}
              value={node.selector || []}
              importedComponents={this.props.importedComponents}
              onEnter={() => {
                this.state.editingSelectors[node._id] = false;
              }}
            />
          </div>
        ) : 'Node',
        children: node.children?.map?.(c => generateTree(c, node)) || [],
      } as TreeCompNode;
    }

    const rootStyleNode = XObject.get(this.props.node, 'designer', {});


    const findNode = (id, start, path=[]) => {
      if (start._id == id) {
        return path.concat(start);
      }
      else {
        if (start.children) {
          for (const child of start.children) {
            const r = findNode(id, child, path.concat(start));
            if (r) return r;
          }
        }
      }
    }


    return (
      <Tree
        className="nodeStyleTree"
        defaultOpen
        nodes={[generateTree(rootStyleNode)]}
        onClickNode={id => {
          this.props.state.selectedStyleNode = id;
          this.props.state.styleNodeState = { id }
        }}
        state={this.state.tree}
        active={this.props.state.styleNodeState?.id}
        onNodeMouseOver={node => {
          const path = findNode(node.key, rootStyleNode);
          const selector = this.props.devRuntime.buildSelector(this.props.node, path);

          this.overlay.setOverlays([
            {
              selector: selector,
              styles: {
                outline: '2px solid #0b99ff',
              }
            }
          ], -2)
        }}
        onNodeMouseOut={node => {
          this.overlay.setOverlays([]);
        }}
      />
    )
  }
}

@component
class NodeEditor extends Component<{
  node;
  importedComponents;
  component;
  devRuntime: DevRuntime;
  nodeDesignerChanged
  state?
}> {
  stylesScriptWrapper: ScriptWrapper;
  conditionScriptWrapper: ScriptWrapper;
  scriptWrapper: ScriptWrapper;
  state = X({
    styles: {},
    script: {},
    condition: {},
    selectedStyleNode: null,
    showAll: false,
    editingPointer: false,
  });

  updateTimer

  triggerUpdate() {
    clearTimeout(this.updateTimer);
    this.updateTimer = setTimeout(() => {
      this.props.devRuntime.updateComponents([this.props.component._id]);
    }, 500);
  }

  constructor(props) {
    super(props);
    if (this.props.node.type == 'el') {
      const designer = XObject.get(this.props.node, 'designer', {});

      XObject.observe(designer, () => {
        if (this.props.devRuntime.designerEls[this.props.node._id]) {
          this.props.devRuntime.designerEls[this.props.node._id].innerHTML = generateDesignerCss(this.props.devRuntime, this.props.node);
        }
        this.props.nodeDesignerChanged();
      });

      const compChanged = () => {
        this.props.devRuntime.invalidateCache(this.props.component._id);
        if (this.props.component.template) {
          this.props.devRuntime.invalidateCache(this.props.component.template);
        }
        this.props.devRuntime.changed.components[this.props.component._id] = true;
        this.props.component.lastUpdated = Date.now();
        this.props.nodeDesignerChanged();
      }
      this.stylesScriptWrapper = createScriptWrapper({
        blocks: () => XObject.get(this.props.node, 'styles', []),
        setBlocks: b => {
          this.props.node.styles = b;
          // this.props.devRuntime.changedNode(this.props.node._id);
          this.triggerUpdate();
          
          compChanged()
        },
        onChange: (id) => {
          // this.props.devRuntime.changedNode(this.props.node._id);
          // this.props.devRuntime.changedBlock(id);
          this.triggerUpdate();
          compChanged()
        },
      });

      this.conditionScriptWrapper = createScriptWrapper({
        blocks: () => XObject.get(this.props.node, 'condition', []),
        setBlocks: b => {
          this.props.node.condition = b;
          this.props.devRuntime.changedNode(this.props.node._id);
          compChanged()
        },
        onChange: (id) => {
          this.props.devRuntime.changedNode(this.props.node._id);
          this.props.devRuntime.changedBlock(id);
          compChanged()
        },

      });

      this.scriptWrapper = this.props.devRuntime.getNodeScriptWrapper(this.props.component, this.props.node, 'script');

      // this.scriptWrapper = createScriptWrapper({
      //   blocks: () => XObject.get(this.props.node, 'script', []),
      //   setBlocks: b => {
      //     this.props.node.script = b;
      //     compChanged();
      //   },
      //   onChange: (id) => {
      //     this.props.devRuntime.changedBlock(id);
      //     compChanged();
      //   },

      // });
    }

    if (window['g_selectedStyleNode']) {
      this.state.selectedStyleNode = window['g_selectedStyleNode'];
      delete window['g_selectedStyleNode'];
    }
  }

  static styles = styled.div`
    > .top {
      ${sectionStyles}
      overflow: auto;
      position: absolute;
      top: 0;
      right: 0;
      height: 40%;
      left: 0;
    }

    > .bottom {
      ${sectionStyles}
      overflow: auto;
      /* border-top: 1px solid #c9c9c9; */
      box-sizing: border-box;
      position: absolute;
      height: 60%;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .section.styles {
      .add {
        margin-left: auto;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: #f3f3f3;
        }
        cursor: pointer;
        svg {
          width: 10px;
        }
      }
    }

    .section.script {
      > .header {
        select {
          margin-left: auto;
        }
      }
    }

    .section.designer {
      &.showAll {
        > .header > .toggle {
          transform: rotate(45deg);
          margin-top: -3px;
        }
      }
      > .header {
        &:hover .toggle {
          border-color: black;
        }
        cursor: pointer;
        >.toggle {
          display: block;
          pointer-events: auto;
          width: 6px;
          height: 6px;
          cursor: pointer;
          border-right: 1px solid #c4c4c4;
          border-bottom: 1px solid #c4c4c4;
          /* position: absolute; */
          margin-left: auto;
          transform: rotate(-45deg);
          margin-right: 4px;
        }
      }
    }
    input.input {
      width: 100%;
      box-sizing: border-box;
    }
    .toggle {
      margin-left: auto;
      display: flex;
      align-items: center;
      margin-right: 2px;
      cursor: pointer;
      svg {
        width: 15px;
        path {
          fill: #1a1a1a;
        }
      }
    }


    > .top, > .bottom {
      position: static;
      height: auto;
    }
  `;

  render() {
    const editorState = getDevProjectEditorState(this.props.devRuntime.devProject)
    if (this.props.node.type == 'el') {
      const { node } = this.props;
      const rootStyleNode = XObject.get(this.props.node, 'designer', {});
      const selectedNode = findNode(rootStyleNode, this.props.state.styleNodeState?.id);

      const instances = this.props.devRuntime.getComponentInstances().filter(i => i.componentId == this.props.component._id)

      return (
        <>
          <div className="top">
          <div className="section">
              <span className="header"
                onContextMenu={e => {
                  e.preventDefault();
                  showContextMenu(e, [
                    {
                      text: 'Edit pointer',
                      onClick: () => {
                        this.state.editingPointer = true;
                      },
                    }
                  ])
                }}
              >
                Node
                <span className="toggle"
                  onClick={() => {
                    this.props.node.disabled = !this.props.node.disabled;
                    this.props.devRuntime.updateComponents([this.props.component._id])
                  }}
                >
                  <Svg name={this.props.node.disabled ? "icons8-closed-eye" : "icons8-eye"} />
                </span>  
              </span>
              <div className="content">
                {this.state.editingPointer && (
                  <Labeled
                    label="Component"
                    input={
                      <PropertyField
                      object={this.props.node}
                      property="pointingToComponent"
                      type="selector"
                      entries={this.props.devRuntime.devProject.components.map(c => ({
                        _id: c._id,
                        display: c.name,
                        key: c._id,
                      }))}
                    />
                    }
                  />
                )}
                {/* <Labeled
                  label="Name"
                  input={
                    <input
                      defaultValue={this.props.node.data}
                      onChange={e => {
                        this.props.node.data = e.target.value;
                      }}
                      style={{ boxSizing: 'border-box', width: '100%'}}
                      type="text"
                    />
                  }
                /> */}
                <Labeled
                  label="Class"
                  input={
                    <input
                      className="input"
                      defaultValue={this.props.node.class}
                      onChange={e => {
                        this.props.node.class = e.target.value;
                      }}
                      type="text"
                      style={{ boxSizing: 'border-box', width: '100%'}}
                    />
                  }
                />
              </div>
            </div>

            <div className={cx("section designer", { showAll: this.state.showAll})}>
              <span
                className="header"
                onClick={() => {
                  this.state.showAll = !this.state.showAll;
                }} 
              >
                Style Editor
                <span className="toggle" />
              </span>
              <div className="content">
                {selectedNode && <CSSDesigner
                  key={selectedNode._id + this.state.showAll}
                  properties={XObject.get(selectedNode, 'properties', {})}
                  showAll={this.state.showAll}
                  devRuntime={this.props.devRuntime}
                  state={this.props.state.styleNodeState}
                />}
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="section styles">
              <span className="header">Styles

                  <span className="add"
                    onClick={e => {
                      // XObject.push(node, 'attachedStyles', this.props.devRuntime.devProject.styles[0]._id)

                      showContextMenu(e, this.props.devRuntime.devProject.styles.map(s => {
                        return {
                          text: s.name,
                          onClick: () => {
                            XObject.push(node, 'attachedStyles', s._id)
                            this.props.devRuntime.updateComponents([this.props.component._id])

                          }
                        }
                      }).concat({
                        text: 'New style',
                        onClick: () => {
                          const newStyle = XObject.obj({
                            name: 'Untitled',
                          })

                          this.props.devRuntime.devProject.styles.push(newStyle);

                          XObject.push(node, 'attachedStyles', newStyle._id);
                          this.props.devRuntime.updateComponents([this.props.component._id])

                        }
                      }) )

                    }}
                  >
                    <Svg name="plus" />
                  </span>

              </span>
              <div className="content">

                {node.attachedStyles?.length > 0 && <div
                
                    style={{
                      marginBottom: '8px',
                    }}
                >
                <Tree
                  nodes={node.attachedStyles?.map?.((id, i) => {
                    const style = this.props.devRuntime.devProject.styles.find(s => s._id == id);
                    return {
                      key: id,
                      elId: id,
                      text: style.name,
                      contextMenu: [
                        {
                          text: 'Remove',
                          onClick: () => {
                            node.attachedStyles.splice(i, 1);
                            this.props.devRuntime.updateComponents([this.props.component._id])
                          }
                        }
                      ]
                    }
                  })}
                  state={{}}
                  onClickNode={id => {
                    editorState.editorTabs.push(XObject.obj({
                      type: 'style',
                      id: id
                    }))
                  }}
                />
                </div>}

                {/* <ul>
                  {node.attachedStyles?.map?.(id => {
                    const style = this.props.devRuntime.devProject.styles.find(s => s._id == id);
                    return (
                      <li key={id}
                        onClick={() => {
                          editorState.editorTabs.push(XObject.obj({
                            type: 'style',
                            id: id
                          }))
                        }}
                        onContextMenu={e => {
                          e.preventDefault();
                          showContextMenu(e, [
                            {
                              text: 'Delete',
                              onClick: () => {
                                node.attachedStyles.splice(node.attachedStyles.indexOf(id), 1);
                              }
                            }
                          ])
                        }}
                      >
                        {style.name}
                      </li>
                    )
                  })}
                </ul> */}
                <StylesSection
                  state={this.props.state}
                  devRuntime={this.props.devRuntime}
                  importedComponents={this.props.importedComponents}
                  node={node}
                />
              </div>
            </div>
            <div className="section">
              <span className="header">Element</span>
              <div className="content">
                <DevProjectScriptEditor
                  devRuntime={this.props.devRuntime}
                  scriptWrapper={this.stylesScriptWrapper}
                  state={this.state.styles}
                  importedComponents={this.props.importedComponents}
                  component={this.props.component}
                />
              </div>
            </div>
            <div className="section">
              <span className="header">Condition</span>
              <div className="content">
                <DevProjectScriptEditor
                  devRuntime={this.props.devRuntime}
                  scriptWrapper={this.conditionScriptWrapper}
                  importedComponents={this.props.importedComponents}
                  component={this.props.component}
                />
              </div>
            </div>
            <div className="section script">
              <span className="header">Script
              
              {instances.length > 0 && <select
                value={(this.props.devRuntime.ticks.activeInstance, this.props.devRuntime.getActiveInstance()) || ''}
                onChange={e => {
                  this.props.devRuntime.selectInstance(e.target.value);
                }}
              >
                <option value="">Not Attached</option>
                {instances.map(inst => {
                  const comp = this.props.devRuntime.getComponent(inst.componentId);
                  return (
                    <option value={inst._pathStr()} key={inst._pathStr()}>{comp.name} #{inst.instanceNumber}</option>
                  )
                })}
              </select>}

              </span>
              <div className="content" data-node-script>
                <DevProjectScriptEditor
                  fadeOutNoDebugData
                  devRuntime={this.props.devRuntime}
                  scriptWrapper={this.scriptWrapper}
                  importedComponents={this.props.importedComponents}
                  state={this.state.script}
                  component={this.props.component}
                  client="sidebar"
                />
              </div>
            </div>
          </div>
        </>
      );
    }
    else if (this.props.node.type == 'svg') {
      return (
        <>
          <PropertyField object={this.props.node} property="asset" />
        </>
      );
    }
    else if (this.props.node.type == 'text') {
      return (
        <>
          <PropertyField object={this.props.node} property="content" />
        </>
      );
    }
  }
}

export const renderWrapperBuilderNode = (devRuntime: DevRuntime, component, node, children, className?, ref?, extraAttrs={}) => {
  const stylesTag = !scriptEmpty(node.styles) && devRuntime.getNodeTag(node);
  const stylesClass = devRuntime.getNodeStylesClass(component, node);
  const designerClass = devRuntime.getNodeDesignerClass(node, true);

  return React.createElement(stylesTag || 'div', {
    ref,
    className: cx(stylesClass, designerClass, className),
    'data-wrapper': true,
    'data-node-id': node._id,
    ...extraAttrs,
  }, children);
}

export const renderBuilderNode = (
  devRuntime: DevRuntime,
  component,
  state,
  node,
  scope,
  _rootAttrs:any={},
  hooks?: RenderElHooks,
  level=0,
  extra?,
  callStack?: RuntimeTrace,
  runtimeContext?: RuntimeContext,
  path?
) => {
  // return '';
  node = x(node);
  if (node.disabled) return;
  const assets = devRuntime.devProject.assets;

  if (node.type == 'text') {
    return node.content;
  }
  else if (node.type == 'svg') {
    const asset = assets.find(asset => asset.name == node.asset);
    if (asset?.file) {
      return <RemoteSvg url={uploadedFileUrl(asset.file)} />;
    }
    return <div></div>;
  }
  else if (node.type == 'el') {
    if (!scriptEmpty(node.condition)) {
      if (!devRuntime.executeBlocks(node.condition, {
        __scopeId: node._id,
        state,
        ...scope,
      })) {
        return ''
      }
    }

    const { classes, ...rootAttrs } = _rootAttrs || {};

    const attrs = {
      key: node._id,
      'data-node-id': node._id,
      // 'data-highlight-id': node._id,
      'data-instance-id': scope.__instanceId,
      ...(rootAttrs || {}),
    };

    // let stylesTag = devRuntime.executeBlocks(node.styles || [], {
    //   __scopeId: node._id,
    //   ...scope,
    // });
    // let stylesTag = !scriptEmpty(node.styles) && devRuntime.getNodeStyles(component, node, {...scope, state });
    let stylesTag = !scriptEmpty(node.styles) && devRuntime.getNodeTag(node) || 'div';

    if ((stylesTag instanceof Pass) || _.isBoolean(stylesTag)) {
      stylesTag = null;
    }

    const stylesClass = devRuntime.getNodeStylesClass(component, node, { state, ...scope });

    const designerClass = devRuntime.getNodeDesignerClass(node);

    const styleClasses = [];

    if (node.attachedStyles) {
      for (const id of node.attachedStyles) {
        const style = devRuntime.devProject.styles.find(s => s._id == id);
        styleClasses.push(devRuntime.getStylesObjClass(style));
      }
    }

    if (node.script?.[0]?.data?.length) {

      const r = devRuntime.executeBlocks(node.script, {
        state,
        stateBinding: name => {
          return {
            get: () => state[name],
            set: value => state[name] = value,
          }
        },
        Tag: stylesTag,
        ...scope,
      }, extra, callStack?.push?.(`${node._id} (node)`), runtimeContext);

      path = path.concat(`node:${node._id}`);

      return renderEl(
        r,
        {
          callStack,
          devRuntime,
        },
        attrs,
        {
          additionaClasses: [node.class, classes, 'test', stylesClass, designerClass, ...styleClasses],
          childrenOverride: node.children?.map?.(child => renderBuilderNode(devRuntime, component, state, child, scope, null, hooks, level + 1, extra, callStack, runtimeContext, path)),
        },
        hooks,
        path,
      );

    }
    else {
      return React.createElement(
        stylesTag || 'div',
        { ...attrs, className: cx(node.class, styleClasses, ['test', stylesClass, designerClass]) },
        _hasChildren(stylesTag || 'div') && node.children?.map?.(child => renderBuilderNode(devRuntime, component, state, child, scope, null, hooks, level + 1, extra, callStack, runtimeContext, path))
      );
    }
  }
};

@component
class BuilderComponentCoreEditor extends Component<{ devRuntime: DevRuntime, component, importedComponents, frame }> {
  initialStateScriptWrapper: ScriptWrapper
  setupScriptWrapper: ScriptWrapper
  propTypeScriptWrapper: ScriptWrapper
  stateTypeScriptWrapper: ScriptWrapper
  defaultsScriptWrapper: ScriptWrapper
  editorStylesScriptWrapper: ScriptWrapper
  defaultPropsScriptWrapper: ScriptWrapper
  dbScriptWrapper: ScriptWrapper
  constructor(props) {
    super(props);

    const frame = this.props.devRuntime.findWorkspaceFrame(this.props.frame);


    this.initialStateScriptWrapper = createScriptWrapper({
      blocks: () => XObject.get(this.props.component, 'initialState', []),
      setBlocks: b => this.props.component.initialState = b,
    });
    this.setupScriptWrapper = createScriptWrapper({
      blocks: () => XObject.get(this.props.component, 'setup', []),
      setBlocks: b => this.props.component.setup = b,
    });
    this.propTypeScriptWrapper = createScriptWrapper({
      blocks: () => XObject.get(this.props.component, 'propsType', []),
      setBlocks: b => this.props.component.propsType = b,
    });
    this.stateTypeScriptWrapper = createScriptWrapper({
      blocks: () => XObject.get(this.props.component, 'stateType', []),
      setBlocks: b => this.props.component.stateType = b,
    });


    this.defaultsScriptWrapper = createScriptWrapper({
      blocks: () => XObject.get(this.props.component, 'defaults', []),
      setBlocks: b => this.props.component.defaults = b,
    });

    if (frame) {
      this.editorStylesScriptWrapper = createScriptWrapper({
        blocks: () => XObject.get(frame, 'editorStyles', []),
        setBlocks: b => frame.editorStyles = b,
      });
      this.defaultPropsScriptWrapper = createScriptWrapper({
        blocks: () => XObject.get(frame, 'componentProps', []),
        setBlocks: b => frame.componentProps = b,
      });
    }

    this.dbScriptWrapper = createScriptWrapper({
      blocks: () => XObject.get(this.props.component, 'db', []),
      setBlocks: b => this.props.component.db = b,
    });
  }

  static styles = styled.div`
    ${sectionStyles}

    .header.slots {
      .add {
        margin-left: auto;
        width: 10px;
      }
    }
  `;

  render() {
    // const frame = this.props.devRuntime.findFrame(this.props.frame);
    return (
      <>
        <span className="sectionHeader">Component</span>
        <span className="section">
          <span className="header">Name</span>
          <div className="content">
            <PropertyField
              object={this.props.component}
              property="name"
            />
          </div>
        </span>
        <span className="section">
          <span className="header">Template</span>
          <div className="content">
            <PropertyField
              object={this.props.component}
              property="template"
              type="selector"
              entries={this.props.devRuntime.devProject.components.map(c => ({
                _id: c._id,
                display: c.name,
                key: c._id,
              }))}
            />
          </div>
        </span>
        <div className="section">
          <span className="header">Parent</span>
          <div className="content">
            <PropertyField
              get={() => this.props.component.parent}
              set={value => this.props.component.parent = value}
              type="selector"
              entries={this.props.devRuntime._components().map(c => {
                return {
                  key: c._id,
                  display: <ComponentEntry comp={c} devRuntime={this.props.devRuntime} />,
                  filter: c.name,
                }
              })}
            />
          </div>
        </div>
        <div className="section">
          <span className="header">Type</span>
          <div className="content">
            <PropertyField
              object={this.props.component}
              property="builderComponentType"
              type="selector"
              entries={XObject.get(this.props.devRuntime.devProject, 'builderComponentTypes', []).map(t => {
                return {
                  key: t._id,
                  display: t.name,
                }
              })}
              create={(input) => {
                const type = XObject.obj({
                  name: input,
                });
                XObject.push(this.props.devRuntime.devProject, 'builderComponentTypes', type);
                return type._id;
              }}
            />
          </div>
        </div>
        <div className="section">
          <span className="header">Fragment</span>
          <div className="content">
            <input type="checkbox"
              checked={this.props.component.detachedNode || false}
              onChange={() => {
                this.props.component.detachedNode = !this.props.component.detachedNode;
              }}
            />
            {this.props.component.detachedNode && (
              <PropertyField
                object={this.props.component}
                property="baseComponent"
                type="selector"
                entries={this.props.devRuntime.devProject.components.map(c => ({
                  _id: c._id,
                  display: c.name,
                  key: c._id,
                }))}
              />
            )}
          </div>
        </div>
        <div className="section">
          <span className="header">Exclude From Library</span>
          <div className="content">
            <input type="checkbox"
              checked={this.props.component.excludeFromLibrary || false}
              onChange={() => {
                this.props.component.excludeFromLibrary = !this.props.component.excludeFromLibrary;
              }}
            />
          </div>
        </div>
        <div className="section">
          <span className="header">Initial State</span>
          <div className="content">
            <DevProjectScriptEditor
              devRuntime={this.props.devRuntime}
              scriptWrapper={this.initialStateScriptWrapper}
              importedComponents={this.props.importedComponents}
            />
          </div>
        </div>
        <div className="section">
          <span className="header">Setup</span>
          <div className="content">
            <DevProjectScriptEditor
              devRuntime={this.props.devRuntime}
              scriptWrapper={this.setupScriptWrapper}
              importedComponents={this.props.importedComponents}
            />
          </div>
        </div>
        <div className="section">
        <span className="header slots">Slots <Svg className="add" name="plus"
          onClick={() => {
            XObject.push(this.props.component, 'slots', XObject.obj({
              name: 'Untitled',
            }))

          }}
        /></span>
        <div className="content">
          <ul>
            {this.props.component.slots?.map?.(s => {
              return (
                <li key={s._id}>
                  <PropertyField object={s} property="name" /> <input type="checkbox"
                    checked={s.required}

                    onChange={() => {
                      s.required = !s.required;
                    }}
                  
                  />
                </li>
              )
            })}
          </ul>
        </div>
        </div>
        <span className="sectionHeader">Editor</span>
        <div className="section">
          <span className="header">Props Type</span>
          <div className="content">
            <ScriptEditor
              scriptWrapper={this.propTypeScriptWrapper}
            />
          </div>
        </div>
        <div className="section">
          <span className="header">State Type</span>
          <div className="content">
            <ScriptEditor
              scriptWrapper={this.stateTypeScriptWrapper}
            />
          </div>
        </div>
        <div className="section">
          <span className="header">State</span>
          <div className="content">
            <ScriptEditor scriptWrapper={this.defaultsScriptWrapper} />
          </div>
        </div>
        <div className="section">
          <span className="header">Props</span>
          <div className="content">
            <DevProjectScriptEditor
              devRuntime={this.props.devRuntime}
              scriptWrapper={this.defaultPropsScriptWrapper}
              importedComponents={this.props.importedComponents}
            />
          </div>
        </div>
        <div className="section">
          <span className="header">Fixtures</span>
          <div className="content">
            <DevProjectScriptEditor
              devRuntime={this.props.devRuntime}
              scriptWrapper={this.dbScriptWrapper}
              importedComponents={this.props.importedComponents}
            />
          </div>
        </div>
        {this.editorStylesScriptWrapper && (
          <div className="section">
            <span className="header">Canvas Styles</span>
            <div className="content">
              <ScriptEditor
                scriptWrapper={this.editorStylesScriptWrapper}
              />
            </div>
          </div>
        )}
      </>
    )
  }
}

@component
export class _NodeTreeEditor extends Component<{
  devRuntime: DevRuntime
  onClickNode
  activeNode
  scriptWrapper: ScriptWrapper
  onDeleted?
}> {
  static styles = styled.div`
    z-index: 0;
    [data-identifier-category="tag"] {
      cursor: pointer;
      color: green !important;
    }

    [data-identifier-category="tag"][data-id="${props => props.activeNode}"] {
      text-decoration: underline;
    }

    ${NotionDocument2} {
      padding-bottom: 0;
      .editor {
        &:empty {
          &:before {
            content: 'Node'
          }
        }
      }
      .children {
        border-left: 1px solid #d7d7d7;
        &:hover {
          border-left-color: #b0b0b0;
        }
      }
    }
  `;
  
  componentDidMount(): void {
    jQuery(ReactDOM.findDOMNode(this)).on('mousedown', '[data-identifier-category="tag"]', e => {
      this.props.onClickNode(e.target.getAttribute('data-id'));
    })
  }

  render(Container?) {
    return (
      <Container activeNode={this.props.activeNode}>
        <DevProjectScriptEditor
          devRuntime={this.props.devRuntime}
          scriptWrapper={this.props.scriptWrapper}
          importedComponents={[]}
          disableAutoComplete
          onDeleted={this.props.onDeleted}
        />
      </Container>
    )
  }
}

@component
export class NodeTreeEditor extends Component<{
  component
  rootNode
  devRuntime: DevRuntime
  onClickNode
  activeNode
}> {
  scriptWrapper: ScriptWrapper

  blocks = X([]);
  constructor(props) {
    super(props);
    const rootNode = this.props.component.root;

    this.blocks = X([rootNode])

    this.scriptWrapper = createScriptWrapper({
      dontClone: true,
      blocks: () => this.blocks,
      setBlocks: b => {
      },
      onChange: (id) => {

      },
    });

  }

  render() {
    return (
      <>
        <_NodeTreeEditor
          activeNode={this.props.activeNode}
          devRuntime={this.props.devRuntime}
          onClickNode={this.props.onClickNode}
          scriptWrapper={this.scriptWrapper}
          onDeleted={() => {
            this.props.devRuntime.updateComponents([this.props.component._id]);
          }}
        />
      </>
    )
  }
}

@component
export class NodeSlotTreeEditor extends Component<{
  component
  slot: string
  devRuntime: DevRuntime
  onClickNode
  activeNode
}> {
  scriptWrapper: ScriptWrapper

  blocks = X([]);
  constructor(props) {
    super(props);
    const slot = this.props.component.providedSlots.find(pv => pv.slot == this.props.slot);
    

    this.blocks = slot.nodes;

    this.scriptWrapper = createScriptWrapper({
      dontClone: true,
      blocks: () => this.blocks,
      setBlocks: b => {

      },
      onChange: (id) => {

      },
    });

  }

  render() {
    return (
      <>
        <_NodeTreeEditor
          activeNode={this.props.activeNode}
          devRuntime={this.props.devRuntime}
          onClickNode={this.props.onClickNode}
          scriptWrapper={this.scriptWrapper}
          onDeleted={() => {
            this.props.devRuntime.updateComponents([this.props.component._id]);
          }}
        />
      </>
    )
  }
}

let copiedNode;

@component
export class BuilderComponentNodesTree extends Component<{
  component
  rootNode?
  active
  onClickNode
  onNodeMouseOut?
  onNodeMouseOver?
  devRuntime: DevRuntime
  onClickEditTree
}> {
  static styles = styled.div`
    .nodes {
      ul {
        padding-left: 17px;
      }
    }

    .component {
      min-height: 18px;
      .disabled {
        position: absolute;
        right: 4px;
        top: 0;
        width: 12px;
        height: 12px;
        bottom: 0;
        margin: auto;
      }


    }
    .component:not(.disabled) {
      &:not(:hover) {
        .disabled {
          display: none;
        }
      }
    }

    .node {
      .name {
        cursor: pointer;
      }
      &.disabled {
        > .name {
          color: gray;
        }
      }
      &.selected {
        > .name {
          font-weight: bold;
        }
      }
    }
  `;

  state = X({});

  render() {
    const genTree = (node, parents=[]) => {
      // if (node.pointingToComponent) {
      //   const comp = this.props.devRuntime.getComponent(node.pointingToComponent);
      //   return genTree(comp.root, parents);
      // }
      const parent = parents[parents.length - 1];

      return {
        key: node._id,
        elId: node._id,
        styles: {
          color: 'green',
          opacity: .5,
        },
        className: node.disabled && 'disabled',
        text: (
          <>
            {node.data}
            {!node.data && <>{node.type}{node.class ? `.${node.class}` : ''}</>}
            <Svg
              onClick={e => {
                e.stopPropagation();
                node.disabled = !node.disabled;
              }}
              className="disabled" name={node.disabled ? "icons8-closed-eye" : "icons8-eye"}
            />
          </>
        ),
        contextMenu: [

          {
            text: 'Prepend child',
            onClick: () => {
              XObject.unshift(node, 'children', XObject.obj({
                type: 'el',
                children: [],
              }));
            }
          },
          {
            text: 'Append child',
            onClick: () => {
              XObject.push(node, 'children', XObject.obj({
                type: 'el',
                children: [],
              }));
            }
          },
          {
            text: 'Insert before',
            onClick: () => {
              const index = parent.children.indexOf(node);
              const newEl = XObject.obj({
                type: 'el',
                children: [],
              });
              parent.children.splice(index, 0, newEl);
            },
          },
          {
            text: 'Insert after',
            onClick: () => {
              const index = parent.children.indexOf(node);
              const newEl = XObject.obj({
                type: 'el',
                children: [],
              });
              parent.children.splice(index + 1, 0, newEl);

            },
          },
          {
            text: 'Wrap',
            onClick: () => {
              const index = parent.children.indexOf(node);

              const newEl = XObject.obj({
                type: 'el',
                children: [node],
              });
              const newChildren = x(parent.children);
              newChildren.splice(index, 1, newEl);
              parent.children = X(newChildren);
            },
          },
          {
            text: 'Unwrap',
            onClick: () => {
              const grandparent = parents[parents.length - 2];
              const index = grandparent.children.indexOf(parent);
              const newChildren = x(grandparent.children);
              newChildren[index] = node;
              grandparent.children = X(newChildren);
            },
          },
          // {
          //   text: 'Extract',
          //   onClick: () => {
          //     // const newComp = XObject.obj({
          //     //   name: 'Extracted',
          //     //   type: 'builderComponent',
          //     //   root: node,
          //     //   parent: this.props.builderComponent._id,
          //     // })
          //     // parent.children.splice(parent.children.indexOf(node), 1);
          //     // components.push(newComp);
          //   },
          // },
          // {
          //   text: 'Add text node',
          //   onClick: () => {
          //     XObject.push(node, 'children', XObject.obj({
          //       type: 'text'
          //     }));
          //   }
          // },
          // {
          //   text: 'Add svg node',
          //   onClick: () => {
          //     XObject.push(node, 'children', XObject.obj({
          //       type: 'svg'
          //     }));

          //   }
          // },
          {
            text: 'Delete',
            onClick: () => {
              parent.children.splice(parent.children.indexOf(node), 1);
            }
          },

          {
            text: 'Copy',
            onClick: () => {
              copiedNode = node._id;
            },
          },

          {
            text: 'Paste',
            onClick: () => {
              const [n] = this.props.devRuntime.findNode(copiedNode);
              console.log(x(n.obj));
              for (const prop in n.obj) {
                if (prop == '_id') continue;

                node[prop] = n.obj[prop];
              }
            },
          },

          {
            text: 'Edit tree',
            onClick: () => {
              this.props.onClickEditTree();
            },
          },

        ],
        children: node.children?.map?.(c => genTree(c, parents.concat(node))) || [],
      }
    }


    let root;
    if (this.props.rootNode) {
      root = findNodeInComponent(this.props.rootNode, this.props.component);
    }
    else {
      root = this.props.component.root;
    }
    const tree = genTree(root);

    return (
      <Tree
        className="nodeTree"
        defaultOpen
        nodes={[tree]}
        onNodeMouseOut={this.props.onNodeMouseOut}
        onNodeMouseOver={this.props.onNodeMouseOver}
        onClickNode={id => {
          if (this.props.active == id) {
            this.props.onClickNode(null);
          }
          else {
            this.props.onClickNode(id);
          }
        }}
        state={this.state}
        active={this.props.active}
      />
    );
  }
}

interface BuilderComponentEditorState {
  selectedNode
  selectedStyleNode
  selectedStyleProp
}


@component
export class BuilderComponentEditor extends Component<{
  devRuntime: DevRuntime,
  devProject,
  selectedNode,
  builderComponent,
  nodeDesignerChanged,
  state?
  frame?
  parent,
}> {
  static styles = styled.div`
    > .left {
      .sectionHeader.slots {
        svg {
          margin-left: auto;
          width: 11px;
          cursor: pointer;
        }
      }
      ${baseStyles}
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 200px;
      border-right: 1px solid #c9c9c9;
      box-sizing: border-box;
      z-index: 9999;
      background: white;
      ${sectionStyles}
      /* > .componentHeader {
        position: sticky;
        top: 0;
        display: flex;
        height: 24px;
        display: flex;
        font-weight: 600;
        align-items: center;
        background-color: #f4f4f4;
        padding: 0 4px;
        z-index: 9999999;
        ${Related} {
          margin-left: auto;
        }
      } */
    }

    > .right {
      ${baseStyles}
      position: absolute;
      top: 0;
      left: 200px;
      bottom: 0;
      width: 350px;
      box-sizing: border-box;
      overflow: auto;
      z-index: 99;

    }

    position: static;
    /* top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto; */
    > .left {
      position: static;
      width: auto;
    }

    > .right {
      position: static;
      width: auto;
    }
  `

  state = XInit(class {

  })
  render2() {
    const { builderComponent, devProject } = this.props;
    const components = devProject.components;

    const selectedNode = findNodeInComponent(this.props.selectedNode, builderComponent);
    // const selectedNode = this.props.devRuntime.findNode(this.props.selectedNode).find(i => i instanceof Node)

    // const findNode = (id, start = builderComponent.root) => {
    //   if (id == start._id) return start;
    //   if (start.children) {
    //     for (const child of start.children) {
    //       const r = findNode(id, child);
    //       if (r) return r;
    //     }
    //   }
    // };

    // let selectedNode = findNode(this.props.selectedNode);
    // if (!selectedNode && this.props.builderComponent.detachedNodes) {
    //   for (const node of this.props.builderComponent.detachedNodes) {
    //     selectedNode = findNode(this.props.selectedNode, node);
    //     if (selectedNode) break;
    //   }
    // }

    let importedComponents = components
      .filter(c => c.parent == builderComponent._id)
      .concat(this.props.builderComponent?.imported?.map?.(id => components.find(c => c._id == id)) || [])


    const editorState = getDevProjectEditorState(devProject);
    if (editorState.mode == 'workspace') {
      const workspace = findWorkspace(editorState.activeWorkspace, this.props.devRuntime.devProject.workspaceStructure);
      
      let asfd = workspace.frames.map(f => {
        return components.find(c => c._id == f.component)
      });


      importedComponents = importedComponents.concat(asfd);

      importedComponents = _.uniq(importedComponents);
    }


    return (
      <>
        {selectedNode && (
          <NodeEditor
            state={this.props.state}
            nodeDesignerChanged={this.props.nodeDesignerChanged}
            devRuntime={this.props.devRuntime}
            component={this.props.builderComponent}
            key={selectedNode._id}
            node={selectedNode}
            importedComponents={importedComponents}
          />
        )}
        {!selectedNode && (
          <BuilderComponentCoreEditor
            devRuntime={this.props.devRuntime}
            component={this.props.builderComponent}
            importedComponents={importedComponents}
            frame={this.props.frame}
          />
        )}
      </>
    );
  }

  render() {
    const { devRuntime, parent } = this.props;
    const { devProject } = devRuntime;
    const editorState = getDevProjectEditorState(devProject);
    // const styles = XObject.get(devProject, 'styles', []);

    const activeComponent = devProject.components.find(c => c._id == getActiveEditorState(devProject)?.id);
    // const activeStyle = styles.find(c => c._id == editorState.styleState?.id);
    // const asdf = XObject.get(this.state, 'asdf', {});
    // const editorState2 = XObject.get(activeComponent, 'editorState', {});

    const template = activeComponent.template && devRuntime.getComponent(activeComponent.template);
    const onClickNode = id => {
      console.log(id);
      if (getComponentEditorState(activeComponent).selectedNode == id) {
        getComponentEditorState(activeComponent).selectedNode = null;
        getActiveEditorState(devProject).nodeState = { id: null, styleNodeState: {} };
        parent.overlayManager_selectedNode.setOverlays([]);
      }
      else {
        getActiveEditorState(devProject).nodeState = { id, styleNodeState: {} };
        getComponentEditorState(activeComponent).selectedNode = id;
        parent.overlayManager_selectedNode.setOverlays([
          {
            selector: `[data-node-id="${id}"]`,
            styles: {
              outline: '1px solid #4bd4825f',
            },
          }
        ], -1);
      }
    }
    return (
      <>
        <div className="left">
          {/* <div className="componentHeader">
            <span className="componentName">
              <PropertyField object={activeComponent} property="name" dblClickEdit /> {activeComponent.builderComponentType && <Tag text={this.props.devRuntime.devProject.builderComponentTypes.find(t => t._id == activeComponent.builderComponentType)?.name} />}
            </span>
          </div> */}

          {template && (
            <>
              <span className="sectionHeader slots">{template.name} <Svg
              name="plus"
                onClick={e => {
                  showContextMenu(e, template.slots.map(s => ({
                    text: s.name,
                    onClick: () => {
                      XObject.push(activeComponent, 'providedSlots', {
                        slot: s._id,
                        nodes: [
                          XObject.obj({
                            type: 'el',
                            data: '<node>'
                          })
                        ]
                      })
                    }
                  })))
                }}
               /></span>
              {template.slots?.map?.(slot => {
                const pv = activeComponent.providedSlots?.find?.(pv => pv.slot == slot._id);
                if (pv) {
                  return (
                    <div className="section" key={pv.slot}>
                      <span className="header"
                        onContextMenu={e => {
                          e.preventDefault();
                          showContextMenu(e, [
                            {
                              text: 'Delete',
                              onClick: () => {
                                const index = activeComponent.providedSlots.indexOf(pv);
                                activeComponent.providedSlots.splice(index, 1);
                              },
                            }
                          ])
                        }}
                      >{slot.name}</span>
                      <div className="content">
                        <NodeSlotTreeEditor
                          activeNode={getComponentEditorState(activeComponent).selectedNode}
                          component={activeComponent}
                          devRuntime={this.props.devRuntime}
                          onClickNode={onClickNode}
                          slot={pv.slot}
                        />
                      </div>
                    </div>
                  )
  
                }
              })}
              {/* {activeComponent.providedSlots?.map?.(pv => {
                const slot = template.slots.find(s => s._id == pv.slot);
                return (
                  <div className="section" key={pv.slot}>
                    <span className="header">{slot.name}</span>
                    <div className="content">
                      <NodeSlotTreeEditor
                        activeNode={getComponentEditorState(activeComponent).selectedNode}
                        component={activeComponent}
                        devRuntime={this.props.devRuntime}
                        onClickNode={onClickNode}
                        slot={pv.slot}
                      />
                    </div>
                  </div>
                )
              })} */}
            </>
          )}
          {!template && (
            <NodeTreeEditor
              key={activeComponent._id}
              activeNode={getComponentEditorState(activeComponent).selectedNode}
              devRuntime={this.props.devRuntime} component={activeComponent} rootNode={getActiveEditorState(devProject)?.rootNode}
              onClickNode={onClickNode}
            />
          )}

        </div>
        <div className="right">
          {this.render2()}
        </div>
      </>
    )
  }
}

/*@component
export class BuilderComponentCanvasEditor extends Component<{ devRuntime: DevRuntime; devProject; builderComponent; config?; style?; className?; nodeDesignerChanged }> {
  static debounce = false;

  static styles = styled.div`
    position: relative;
    > .left {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 200px;
      border-right: 1px solid #eeeeee;
      overflow: auto;

      .nodes {
        ul {
          padding-left: 17px;
        }
      }


      .node {
        &.disabled {
          > .name {
            color: gray;
          }
        }
        &.selected {
          > .name {
            font-weight: bold;
          }
        }
      }
    }

    > .right {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 400px;
      border-left: 1px solid #eeeeee;
      overflow: auto;
    }

    > .canvas {
      position: absolute;
      left: 200px;
      top: 0;
      bottom: 0;
      right: 400px;
      background-color: gray;
      .react-transform-component {

      }
      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99999;
        background-color: rgba(0, 0, 0, .1);
      }
      .react-transform-wrapper {
        position: absolute;
        user-select: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        width: auto;
        height: auto;
      }
    }

    > .left {
      bottom: 0;
      left: 0;
      width: 30%;
      top: auto;
      height: 50%;
    }
    > .right {
      bottom: 0;
      right: 0;
      width: 70%;
      top: auto;
      height: 50%;
      border-left: none;
    }

    > .canvas {
      top: 0;
      left: 0;
      right: 0;
      height: 50%;
      border-bottom: 1px solid #eeeeee;
    }

    &.hideSidebar {
      > .left {
        display: none;
      }
      > .right {
        left: 0;
        width: 100%;
      }
    }

    &.fullHeight {
      height: auto !important;
      > .canvas {
        position: relative;
        height: 350px;

      }
      > .right {
        position: relative;
        height: auto;
      }
    }

  `;

  styleManager = new StyleManager();
  editorStylesWrapper

  db

  designerState: CompType['editorState']

  constructor(props) {
    super(props);
    this.designerState = getComponentEditorState(this.props.builderComponent);

    if (!scriptEmpty(this.props.builderComponent.defaults)) {
      this.state.state = X(executeScriptFromData(this.props.builderComponent.defaults));
    }

    if (!scriptEmpty(this.props.builderComponent.editorStyles)) {
      this.editorStylesWrapper = executeScriptFromData(this.props.builderComponent.editorStyles);
    }

    if (!scriptEmpty(this.props.builderComponent.db)) {
      this.db = X(this.props.devRuntime.executeBlocks(this.props.builderComponent.db));
    } 
  }

  state = XInit(class {
    overlay;
    highlight;

    state = {
    };
  });

  componentDidMount() {

    function inspectKeys(e) {
      return e.ctrlKey && e.altKey;
    }
    jQuery(window).keydown(e => {
      if (inspectKeys(e)) {
        this.state.overlay = true;
        console.log('inspect');
      }
    });

    jQuery(window).keyup(e => {
      if (!(inspectKeys(e))) {
        this.state.overlay = false;
      }
    });

    jQuery(window).contextmenu(e => {
      if (this.state.overlay) {
        e.preventDefault();
      }
    });

    jQuery(window).mousedown(e => {

      if (inspectKeys(e)) {
        e.preventDefault();
        const els = document.elementsFromPoint(e.clientX, e.clientY).filter(el => el.getAttribute('data-node-id'));
        if (els.length) {
          this.designerState.selectedNode = els[0].getAttribute('data-node-id');
        }

      }


    });

    jQuery(window).mousemove(e => {
    });

  }

  highlightEls(ids, all) {
    // console.log('highlight');
    return;
    this.styleManager.setStyles(`
    ${ids.map(id => {
      return `
      
      ${all ? '' : '.--highlight--'} [data-highlight-id="${id}"] {
        outline: 1px solid blue !important;
      }
      
      
      `;
    }).join('\n')}`);
  }

  devRuntime() {
    return this.db ? this.props.devRuntime.withDb(this.db) : this.props.devRuntime;
  }

  render(Container?) {
    const { devProject, builderComponent } = this.props;
    const components = devProject.components;
    const assets = devProject.assets;

    const Wrapper = this.editorStylesWrapper || 'div';

    const renderSidebarNode = (node, parents=[]) => {
      const parent = parents[parents.length - 1];
      return (
        <div key={node._id}
          className={cx('node', node.type, { disabled: node.disabled, selected: this.designerState.selectedNode == node._id })}
        >
          <span
            className="name"
            // data-highlight-id={node._id}
            onClick={() => {
              if (this.designerState.selectedNode == node._id) {
                this.designerState.selectedNode = null;
              }
              else {
                this.designerState.selectedNode = node._id;
              }
            }}
            onMouseOver={() => {
              this.highlightEls([node._id], true);
            }}
            onMouseOut={() => {
              this.styleManager.setStyles('');

            }}
            onContextMenu={e => {
              e.preventDefault();
              showContextMenu(e, [
                {
                  text: 'Add child beginning',
                  onClick: () => {
                    XObject.unshift(node, 'children', XObject.obj({
                      type: 'el'
                    }));
                  }
                },
                {
                  text: 'Add child end',
                  onClick: () => {
                    XObject.push(node, 'children', XObject.obj({
                      type: 'el'
                    }));
                  }
                },
                {
                  text: 'Wrap',
                  onClick: () => {
                    const index = parent.children.indexOf(node);

                    const newEl = XObject.obj({
                      type: 'el',
                      children: [node],
                    });
                    const newChildren = x(parent.children);
                    newChildren.splice(index, 1, newEl);
                    parent.children = X(newChildren);
                  },
                },
                {
                  text: 'Unwrap',
                  onClick: () => {
                    const grandparent = parents[parents.length - 2];
                    const index = grandparent.children.indexOf(parent);
                    const newChildren = x(grandparent.children);
                    newChildren[index] = node;
                    grandparent.children = X(newChildren);
                  },
                },
                {
                  text: 'Extract',
                  onClick: () => {
                    const newComp = XObject.obj({
                      name: 'Extracted',
                      type: 'builderComponent',
                      root: node,
                      parent: this.props.builderComponent._id,
                    })
                    parent.children.splice(parent.children.indexOf(node), 1);
                    components.push(newComp);

                  },
                },
                {
                  text: 'Add text node',
                  onClick: () => {
                    XObject.push(node, 'children', XObject.obj({
                      type: 'text'
                    }));
                  }
                },
                {
                  text: 'Add svg node',
                  onClick: () => {
                    XObject.push(node, 'children', XObject.obj({
                      type: 'svg'
                    }));

                  }
                },
                {
                  text: 'Delete',
                  onClick: () => {
                    parent.children.splice(parent.children.indexOf(node), 1);
                  }
                }
              ]);
            }}
          >{node.type}{node.class ? `.${node.class}` : ''}</span>

          {node.children && (
            <ul className="children">
              {node.children.map(child => <li key={child._id}>{renderSidebarNode(child, parents.concat(node))}</li>)}
            </ul>
          )}
        </div>
      );
    };


    const findNode = (id, start = builderComponent.root) => {
      if (id == start._id) return start;
      if (start.children) {
        for (const child of start.children) {
          const r = findNode(id, child);
          if (r) return r;
        }
      }
    };

    const selectedNode = findNode(this.designerState.selectedNode);

    const disabled = false;

    XTouch(this.state.state);

    let defaultProps = {}
    if (!scriptEmpty(this.props.builderComponent.defaultProps)) {
      defaultProps = this.devRuntime().executeBlocks(this.props.builderComponent.defaultProps);
    }

    return (
      <Container
        className={cx(this.props.className, {

          hideSidebar: this.props.config?.hideSidebar,
        })}

        style={this.props.style}
      >
        <div className="left --highlight--">
          <BuilderComponentNodesTree
            devRuntime={this.props.devRuntime}
            active={this.designerState.selectedNode}
            component={builderComponent}
            onClickNode={id => {
              this.designerState.selectedNode = id;
            }}

          />

        </div>
        <div className={cx('canvas', { '--highlight--': this.state.overlay })}
          onMouseMove={e => {
            const els = document.elementsFromPoint(e.clientX, e.clientY).filter(el => el.getAttribute('data-node-id'));

            const ids = els.map(el => el.getAttribute('data-node-id'));

            this.highlightEls(ids, false);
          }}
          onContextMenu={e => {
            e.preventDefault();
            showContextMenu(e, [
              {
                text: 'Reset',
                onClick: () => {
                  this.designerState.scale = 1;
                  this.designerState.x = 0;
                  this.designerState.y = 0;
                },
              }
            ])
          }}
        >
          <TransformWrapper
            minScale={0.01}
            limitToBounds={false}

            initialScale={this.designerState.scale}
            initialPositionX={this.designerState.x}
            initialPositionY={this.designerState.y}
            disabled={disabled}

            panning={{
              disabled: disabled,
            }}
            wheel={{
              step: 0.9,
              smoothStep: 0.01,
              wheelDisabled: true,
            }}

            pinch={{ step: 100 }}

            doubleClick={{
              disabled: true
            }}
            onTransformed={(el, state) => {
              this.designerState.scale = state.scale;
              this.designerState.x = state.positionX;
              this.designerState.y = state.positionY;
            }}
          >
            <TransformComponent>
              <Wrapper>
              {renderBuilderNode(this.devRuntime(), builderComponent, this.state.state, builderComponent.root, {
                props: defaultProps || {},
                emit: (signal, ...args) => {
                  // toast(JSON.stringify({ signal, args }), { position: 'bottom-right' });
                  console.log('emit', signal, args);
                }
              })}
              </Wrapper>
            </TransformComponent>
          </TransformWrapper>
          {this.state.overlay && <div className="overlay" />}
        </div>

        <div className="right">
          {selectedNode && (
            <>
              <NodeEditor
              nodeDesignerChanged={this.props.nodeDesignerChanged}
              devRuntime={this.devRuntime()}
              component={this.props.builderComponent}
                key={selectedNode._id}
                node={selectedNode}
                importedComponents={
                  components.filter(c => c.parent == builderComponent._id).concat(
                  this.props.builderComponent?.imported?.map?.(id => components.find(c => c._id == id)) || [])
                
                }
              />
            </>
          )}
          {!selectedNode && (
            <>
              <BuilderComponentCoreEditor
              devRuntime={this.props.devProject}
                component={this.props.builderComponent}

                importedComponents={
                  components.filter(c => c.parent == builderComponent._id).concat(
                  this.props.builderComponent?.imported?.map?.(id => components.find(c => c._id == id)) || [])
                
                }
              />
            </>
          )}
        </div>
      </Container>
    );
  }
}*/
